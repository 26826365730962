

import { Descriptivo } from 'src/app/common/model/Descriptivo';


export class CuentaContable extends Descriptivo {
    constructor(id?: number, codigo?: string, descripcion?: string,
        		public categoria ?: string,


    ) {
        super(codigo, descripcion,id)
    }
   
    public static fromData(data: any): CuentaContable {
        if (!data) return null;
        const o: CuentaContable = new CuentaContable(
            data.id,
            data.codigo,
            data.descripcion,

					 data.categoria, 
        );

        o.version = data.version; 
return o;

    }

}