import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ConfirmationService, MenuItem } from "primeng/api"
import { Subscription } from "rxjs"
import { MonedaEntorno } from "src/app/app.module"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Cliente } from "src/app/model/Cliente"
import { Cobranza } from "src/app/model/Cobranza"
import { Comprobante } from "src/app/model/Comprobante"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { FormaPago } from "src/app/model/FormaPago"
import { Moneda } from "src/app/model/Moneda"
import { CajaService } from "src/app/services/caja.service"
import { CentroCostoService } from "src/app/services/centro-costo.service"
import { ClienteService } from "src/app/services/cliente.service"
import { CobranzaService } from "src/app/services/cobranza.service"
import { ComprobanteService } from "src/app/services/comprobante.service"
import { FormaPagoService } from "src/app/services/forma-pago.service"
import { ProveedorService } from "src/app/services/proveedor.service"
import { Cobro } from "../../../model/cobros/Cobro"
import { CobroConCaja } from "../../../model/cobros/CobroConCaja"
import { BreadcrumbService } from "./../../../breadcrumb.service"
import { SessionComponent } from "./../../../common/components/session-component.component"
import { LoadingService } from "./../../../common/services/loading-data-service.service"
import { ComprobanteResumen } from "./../../../model/Comprobante"
import { FiltroComprobante } from "./../../../model/FiltroComprobantes"
import { ItemCobranza } from "./../../../model/ItemCobranza"
import { MonedaService } from "./../../../services/moneda.service"
import { MotivoCancelacionService } from "./../../../services/motivo-cancelacion.service"

@Component({
	selector: "gestor-cobranza",
	templateUrl: "gestor-cobranza.component.html",
	styleUrls: ["gestor-cobranza.component.less"]
})
export class GestorCobranzaComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	public menuItemAcciones: MenuItem[] = []
	public verAnulacion = false
	@Input()
	public item: Cobranza
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false
	public comprobante: Comprobante
	@Output()
	public onGuardado: EventEmitter<Cobranza> = new EventEmitter<Cobranza>()

	@Output()
	public onCancelado = new EventEmitter()
	@Input()
	public verAcciones: boolean = false
	public centroCostoOptions: Descriptivo[] = []

	public comprobantesImpagos: ComprobanteResumen[] = []
	public seleccionados: ComprobanteResumen[] = []

	public verComprobantes: boolean = false

	public filtro: FiltroComprobante = new FiltroComprobante(null)

	public customLoading: LoadingService = new LoadingService()
	public formasPago: FormaPago[] = []
	public cajas: Descriptivo[] = []

	public visualizarComprobante: boolean = false
	@Input()
	public readonly: boolean = false

	public comprobantes: number[]

	private cliente: Cliente
	private proveedor: Descriptivo

	public itemCobranzaOptions: ItemCobranza[] = []
	public importeImputar: number
	public mostrarCotizacion: boolean = false
	public idsReserva: number[]
	public idCliente
	public loading = true
	constructor(
		messagesService: MessagesService,
		public service: CobranzaService,
		private route: ActivatedRoute,
		private router: Router,
		private confService: ConfirmationService,
		public clienteService: ClienteService,
		public centroCostoService: CentroCostoService,
		private comprobanteService: ComprobanteService,
		private formaPagoService: FormaPagoService,
		public monedaService: MonedaService,
		public motivoService: MotivoCancelacionService,
		public cajasService: CajaService,
		private breadcrumbService: BreadcrumbService,
		private proveedorService: ProveedorService
	) {
		super(messagesService)

		this.comprobantes = this.router.getCurrentNavigation()?.extras?.state?.comprobantes
		this.idsReserva = this.router.getCurrentNavigation()?.extras?.state?.reservaId
		this.idCliente = this.router.getCurrentNavigation()?.extras?.state?.idCliente

		this.filtro.fechaDesde = null
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}
	public cambiarFormaPago(forma: FormaPago, c: Cobro) {
		if (c.formaPago != forma) {
			switch (forma.codigo) {
				case "EF":
					if (c.formaPago != forma) this.item.reemplazarCobro(c, new CobroConCaja(null, forma, null, c.monto))
					break

				default:
					break
			}
		}
	}
	public getCotizacion(monedaDesde: Moneda, monedaHasta: Moneda) {
		return this.monedaService.getCotizacion(monedaDesde, monedaHasta)
	}
	public async updateCliente(cliente: Descriptivo) {
		if (this.filtro?.cliente?.id != cliente?.id && !this.item?.id) {
			this.filtro.cliente = cliente
			this.filtro.fechaDesde = null
			this.filtro.sortField = "fecha"
			this.filtro.sortOrder = 2
			if (!cliente?.id) {
				this.comprobantes = []
				return
			}
			await this.buscarComprobantes(this.filtro)
			this.cliente = await this.clienteService.getByIdForced(cliente.id)
			if (this.cliente.proveedor) {
				this.proveedor = this.cliente.proveedor
			} else {
				this.proveedor = null
			}
		}
	}
	public async isValid() {
		return this.item.totalCobrado == this.item.totalCalculado && this.item.items.length > 0
	}

	public agregarCobro() {
		this.item.agregarCobro(new CobroConCaja(null, this.formasPago[0], null, this.item.saldo))
	}

	public async updateCotizacion(item: ItemCobranza, valor: number = 1) {
		if (valor <= 0) return
		if (this.item.moneda.codigo != item.comprobante.moneda.codigo) {
			/* this.monedaService.getCotizacion(item.comprobante.moneda, this.item.moneda).then(c => {
                item.cotizacion = c;
                item.importe = this.updateImporte(item,valor)
            })*/
			item.cotizacion = item.comprobante.cotizacionAfip

			item.importe = this.updateImporte(item, valor)
		} else {
			item.cotizacion = item.comprobante.cotizacionAfip || 1
			item.importe = this.updateImporte(item, valor)
		}
		// this.monedaService.getCotizacion(item.comprobante.moneda, this.item.moneda).then(
		//     c=>
		//     item.cotizacion = c)
	}

	public updateImporte(item: ItemCobranza, valor: number = 1) {
		// if (item.comprobante.moneda.codigo != this.item.moneda.codigo) {
		//     item.importe = item.comprobante.saldo * item.cotizacion
		// } else {
		//     item.importe = item.comprobante.saldo * valor
		// }
		if (this.item.moneda.codigo == this.configuracionEntorno?.moneda?.codigo) {
			item.importe = item.comprobante.saldo * item.comprobante.cotizacionAfip
		} else {
			item.importe = item.comprobante.saldo * item.cotizacion || 1
		}
		return item.importe
	}

	public updateAcciones() {
		this.verAcciones = this.item.id && this.item.estado?.codigo != "AN" && this.item.estado?.codigo != "C"
		this.menuItemAcciones = [
			{
				label: "Anular",
				visible: this.item?.id != undefined,
				command: () => {
					this.anular()
				}
			},

			{
				label: "Volver",
				command: () => {
					this.volver()
				}
			}
		]
		this.editable = (!this.item.estado || !this.item.estado?.esTerminado) && !this.readonly
	}
	ngOnInit() {
		this.loading = true
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
					this.editable = false
				}
			})
		)

		if (this.route.snapshot.url.some((u) => u.path == "cobranza")) {
			this.breadcrumbService.setItems([{ label: "Ventas" }, { label: "Cobranzas", routerLink: ["/cobranza"] }])
			this.routeSub = this.route.queryParams.subscribe(async (params) => {
				let id: number = <number>params["id"]
				this.idCliente = this.idCliente || <number>params["clienteId"]
				if (!this.service) return
				if (id) {
					this.service.getById(id).then((r) => {
						this.item = r
						this.itemCobranzaOptions = this.item.items
						this.updateAcciones()
						this.loading = false
					})
				} else {
					this.item = this.service ? this.service.newEnt() : null
					this.item.cliente = this.idCliente ? Descriptivo.fromData(await this.clienteService.getById(this.idCliente)) : null
					this.item.moneda = MonedaEntorno()
					if (this.idCliente) {
						await this.updateCliente(this.item.cliente)
						this.loading = false
					} else if (this.comprobantes && !this.idsReserva?.length) {
						this.comprobanteService.getByIds(this.comprobantes).then(async (comps) => {
							this.item.cliente = Descriptivo.fromData(comps[0]?.cliente)
							await this.updateCliente(this.item.cliente)
							this.crearBaseCobranza(comps)
							this.loading = false
						})
					} else {
						this.loading = false
					}
					this.editable = !this.readonly
				}
			})
		} else this.loading = false

		this.centroCostoService.getDescriptivos().then((r) => {
			this.centroCostoOptions = r
		})
		this.formaPagoService.getHabilitados().then((r) => {
			this.formasPago = r
		})
		this.cajasService.getAll().then((r) => {
			this.cajas = r
		})
	}
	public crearBaseCobranza(comps: ComprobanteResumen[] | Comprobante[]) {
		comps.forEach((r) => {
			// this.getCotizacion(r.moneda, this.item.moneda).then(cc => {
			let i = new ItemCobranza(null, null, r.descripcion, r, r.saldo * r.cotizacionAfip, null, this.item.moneda, r.cotizacionAfip)
			this.itemCobranzaOptions.push(i)
			// })
		})
		if (comps?.length) this.updateCliente(Descriptivo.fromData(comps[0].cliente))
		this.itemCobranzaOptions = [...this.itemCobranzaOptions]
		this.item.items = this.itemCobranzaOptions
		this.updateAcciones()
	}
	public confirmarAnulacion() {
		let $this = this
		this.service.anular(this.item).then((res) => {
			this.success(this.translateService.get("Cobranza anulada"))
			$this.service.getById(this.item.id).then((r) => {
				this.item.estado = new EstadoDocumento(null, "AN", "Anulado")
			})
			$this.verAnulacion = false
			$this.updateAcciones()
		})
	}
	public anular() {
		let $this = this
		this.confService.confirm({
			key: "genConf",
			header: "Anular",
			message: this.translateService.get("Está por anular la cobranza {$1}. ¿Desea continuar?").replace("{$1}", this.item.id),
			accept: () => {
				$this.verAnulacion = true
			}
		})
	}

	public async buscarComprobantes(filtro) {
		if (this.comprobantes?.length) {
			return
		}
		this.item.items = []
		this.itemCobranzaOptions = []
		this.comprobantesImpagos = await this.comprobanteService.getImpagos(filtro, this.customLoading)
		this.comprobantesImpagos = this.comprobantesImpagos.filter((c) => !this.item.tieneComprobante(c))
		this.comprobantesImpagos.forEach((r) => {
			const item = new ItemCobranza(null, null, r.descripcion, r, r.saldo * r.cotizacionAfip, null, this.item.moneda, r.cotizacionAfip)
			// item.cotizacion = r.cotizacionAfip
			if (this.idsReserva?.length && this.idsReserva.some((r) => r == item.comprobante.idReserva) && item.comprobante.saldo != 0) {
				this.item.agregarItem(item)
			}
			this.itemCobranzaOptions = [item, ...this.itemCobranzaOptions]

			// if(r.moneda?.codigo != this.item.moneda?.codigo){
			//     this.monedaService.getCotizacion(r.moneda,this.item.moneda).then(cot =>{
			//         item.cotizacion = cot;
			//     })
			// }
		})
		this.itemCobranzaOptions = [...this.itemCobranzaOptions]
	}

	public verComprobante(item: ItemCobranza) {
		this.comprobanteService.getById(item.comprobante?.id).then((r) => {
			this.comprobante = r
			this.visualizarComprobante = true
		})
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}

	public imputar() {
		if (this.importeImputar > 0) {
			this.item.items = []
			let saldo = this.importeImputar

			let index = 0
			while (saldo > 0 && index < this.itemCobranzaOptions.length) {
				if (this.itemCobranzaOptions[index].importe <= 0) {
					index++
					continue
				}

				let aQuitar = saldo < this.itemCobranzaOptions[index].importe ? saldo : this.itemCobranzaOptions[index].importe
				this.itemCobranzaOptions[index].importe = aQuitar

				this.item.agregarItem(this.itemCobranzaOptions[index])
				index++
				saldo -= aQuitar
			}
		}
	}

	importeItemChange(event, iop: ItemCobranza) {
		let saved = this.item.items.find((i) => i.comprobante.id == iop.comprobante.id)
		if (saved) {
			saved.importe = iop.importe
		}
	}

	getDescripcionComprobante(it) {
		return it.comprobante.tipoComprobante?.codigo + (it.comprobante.numeroDocumento ? it.comprobante.numeroDocumento : it.comprobante.id)
	}

	goToComprobante(event, item) {
		event.stopPropagation()
		this.router.navigate(["comprobante/vista"], { queryParams: { id: item.comprobante.id } })
	}
}
