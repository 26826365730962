import { Descriptivo } from "src/app/common/model/Descriptivo"
import { ProfilePic } from "../common/model/ProfilePic"
import { DisponibilidadDiaria } from "./DisponibilidadDiaria"
import { FileData } from "./FileData"
import { ImagenProducto } from "./ImagenProducto"
import { Inclusion } from "./Inclusion"
import { OpcionPublicacion } from "./OpcionPublicacion"
import { ProductoPublicadoIntegracion } from "./ProductoPublicadoIntegracion"
import { Region } from "./Region"
import { TagProducto } from "./TagProducto"

export class ProductoPublicado extends Descriptivo {
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public habilitado: boolean = true,
		public codigoIdioma: string = "ES",
		public nombreServicio?: string,
		public descripcionCorta?: string,
		public detalle?: string,
		public preparativos?: string,
		public condicionesEspeciales?: string,
		public politicasCancelacion?: string,
		public incluidos: Inclusion[] = [],
		public noIncluidos: Inclusion[] = [],
		public region?: Region,
		public duracion?: number,
		public minimoDias?: number,
		public maximoDias?: number,
		public imagenes: ImagenProducto[] = [],
		public disponibilidad: DisponibilidadDiaria = new DisponibilidadDiaria([]),
		public opciones: OpcionPublicacion[] = [],
		public estado?: string,
		public tags?: TagProducto[],
		public version?: number,
		public integraciones?: ProductoPublicadoIntegracion[]
	) {
		super(codigo, descripcion, id)
	}

	public publicar = false;

	public static fromData(data: any): ProductoPublicado {
		if (!data) return null
		const o: ProductoPublicado = new ProductoPublicado(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			data.codigoIdioma,
			data.nombreServicio,
			data.descripcionCorta,
			data.detalle,
			data.preparativos,
			data.condicionesEspeciales,
			data.politicasCancelacion,
			data.incluidos ? data.incluidos.map((c) => Inclusion.fromData(c)) : [],
			data.noIncluidos ? data.noIncluidos.map((c) => Inclusion.fromData(c)) : [],
			data.region ? Region.fromData(data.region) : null,
			data.duracion,
			data.minimoDias,
			data.maximoDias,
			data.imagenes ? data.imagenes.map((c) => ImagenProducto.fromData(c)) : [],
			data.disponibilidad ? DisponibilidadDiaria.fromData(data.disponibilidad) : new DisponibilidadDiaria([]),
			data.opciones ? data.opciones.map((c) => OpcionPublicacion.fromData(c)) : [],
			data.estado,
			data.tags ? data.tags.map((c) => TagProducto.fromData(c)) : [],
			data.version,
			data.integraciones ? data.integraciones.map((c) => ProductoPublicadoIntegracion.fromData(c)) : [],
		)

		return o
	}

	public get imagenPortada() {
		var imagen = this.imagenes.filter((i) => i.esPortada)[0]
		return imagen && imagen.profilePic ? imagen.profilePic.picPath : "assets/images/default_image.png"
	}

	agregarImagen(f: FileData) {
		this.imagenes.push(new ImagenProducto(new ProfilePic(null, f.url, null, null), f.id, false))
		if (this.imagenes.length == 1) this.imagenes[0].esPortada = true
	}

	quitarImagen(imagen: ImagenProducto) {
		this.imagenes = this.imagenes.filter((i) => i != imagen)
	}
}
