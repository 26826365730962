import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import { MenuItem } from 'primeng/api';
import { MenuConfigService } from './../../../../common/services/menu-config.service';
import { TranslateService } from './../../../../common/services/translate.service';
import { DashboardConfiguration } from './../../dashboard-element/DashboardConfiguration';
import { IDashboardElement } from './../../dashboard-element/IDashboardElement';

@Component({
  selector: 'app-shortcut',
  templateUrl: './shortcut.component.html',
  styleUrls: ['./../dashboard-element-common.component.less', './shortcut.component.less']
})
export class ShortcutComponent implements OnInit, IDashboardElement {
  public static TIPO = "shortcut";
  private _config: DashboardConfiguration;
  public data: {
    icono?: string,
    titulo?: string,
    tooltip?: string,
    ruta?: string
  }
  public static defaultConfig = {

    fixedHeader: false,
    x: null,
    y: null,
    rows: 1,
    cols: 1,
    tipo: "shortcut",
    params: {}

  }
  public get config(): DashboardConfiguration {
    return this._config;
  }
  @Input()
  public set config(v: DashboardConfiguration) {
    this._config = v;
    if (!v) {
      this.data = {};
      return
    };
    this.data = {
      icono: v.params['icono'],
      titulo: v.params['titulo'],
      tooltip: v.params['tooltip'],
      ruta: v.params['ruta']
    }

  }
  public get icono() {
    return this.data ? this.data.icono : '';
  }
  constructor(private translateService: TranslateService,
    public menuConfService: MenuConfigService,
    private router: Router) {

  }

  @ViewChild(OverlayPanel, { static: true }) op: OverlayPanel;
  public goTo(ruta: string) {
    this.router.navigate([ruta]);
  }
  showParams(event) {
    if (this.op) this.op.toggle(event);
  }
  hideParams() {
    if (this.op) this.op.hide()
  }
  onChange = (conf: DashboardConfiguration) => {

  }
  public opciones: MenuItem[] = [];

  ngOnInit() {

  }
  public aplicarSeleccion(item: MenuItem) {
    this.op.hide();
    this.data = {
      icono: item.icon,
      titulo: this.translateService.get(item.label),
      tooltip: this.translateService.get(item.label),
      ruta: item.routerLink[0]
    }
    this.config.params = this.data;
    this.onChange(this.config);
  }

  public getTitle = () => {
    return this.data ? this.translateService.get(this.data.titulo) : "Sin definir";
  }

}
