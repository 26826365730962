import { Component, Input, OnInit } from "@angular/core"

@Component({
	selector: "contactos",
	templateUrl: "./contactos.component.html",
	styleUrls: ["./contactos.component.less"]
})
export class ContactosComponent implements OnInit {
	private _data: any
	public get data(): any {
		return this._data
	}
	@Input()
	public set data(v: any) {
		this._data = v
	}

	@Input()
	public containerClass = "col-12"
	constructor() {}

	public whatsapp(event) {
		event && event.stopPropagation()
		if (this.data["whatsapp"]) {
			if (this.isMobile()) window.open("whatsapp://send?phone=" + this.data["whatsapp"].replace(/[^0-9]/g, ""), "_blank")
			else window.open("https://api.whatsapp.com/send/?phone=" + this.data["whatsapp"].replace(/[^0-9]/g, ""), "_blank")
		}
	}
	public sendEmail(event) {
		event && event.stopPropagation()
		if (this.data["email"]) {
			window.open("mailto:" + this.data["email"], "_blank")
		}
	}
	public call(event) {
		event && event.stopPropagation()
		if (this.data["telefono"]) {
			window.open("tel:" + this.data["telefono"].replace(/[^0-9]/g, ""), "_blank")
		}
	}
	ngOnInit() {}

	isMobile() {
		return window.innerWidth <= 640
	}
}
