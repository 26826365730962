

import { Descriptivo } from 'src/app/common/model/Descriptivo';


export class PlantillaPregunta extends Descriptivo {
    constructor(id?: number, codigo?: string, descripcion?: string,
		public codigoIdioma:string ='ES',
		public tipoPregunta ?: Descriptivo, 


    ) {
        super(codigo, descripcion,id)
    }
   
    public static fromData(data: any): PlantillaPregunta {
        if (!data) return null;
        const o: PlantillaPregunta = new PlantillaPregunta(
            data.id,
            data.codigo,
            data.descripcion,
				data.codigoIdioma, 
			Descriptivo.fromData(data.tipoPregunta), 

        );

        o.version = data.version; 
return o;

    }

}