
import { Proveedor} from './Proveedor';


export class Disponibilidad  {
    constructor(
		public id ?: number, 
		public fechaDesde ?: Date, 
		public fechaHasta ?: Date, 
		public proveedor ?: Proveedor, 
    public version?: number,

    ) {
    }
    public static fromData(data: any): Disponibilidad {
        if (!data) return null;
        const o: Disponibilidad = new Disponibilidad(
			data.id,      
			data.fechaDesde? new Date(data.fechaDesde) : null , 
			data.fechaHasta? new Date(data.fechaHasta) : null , 
			Proveedor.fromData(data.proveedor), 
        );

        o.version = data.version; 
return o;

    }

}