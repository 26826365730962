<div class="parametros content">
	<div class="grid">
		<div class="col-12" *ngIf="paramId">
			<model-list
				[listado]="genericParamService.data | async"
				[service]="genericParamService"
				[modelName]="paramId"
				[columns]="columns"
				[title]="paramId?.toUpperCase() + '_PARAM_TITULO' | translate"
				[(listado)]="listado"
				[isLazy]="false"
				[filtro]="filtro"
				[handler]="handler"
				(onEliminar)="refresh($event)"
				[acciones]="acciones"
			>
			</model-list>
		</div>
	</div>
</div>
<p-dialog
	header=""
	[(visible)]="editandoParametro"
	appendTo="body"
	[modal]="true"
	#dialDetail
	[styleClass]="'no-header gestor-dialog'"
	(onShow)="onDialogShow($event, dialDetail)"
>
	<ng-container *ngIf="editandoParametro">
		<gestor-parametrico
			[itemEditado]="itemEditado"
			[tipo]="paramId"
			(onGuardar)="refresh($event)"
			[editable]="!itemEditado?.esSistema"
			(onCancelar)="onCancelar()"
			[goBack]="false"
			[isModal]="true"
		>
		</gestor-parametrico>
	</ng-container>
</p-dialog>
