import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { SessionComponent } from '../session-component.component';

import { FiltroGrupo } from '../../../pages/reserva/gestor-reserva/FiltroGrupo';
import { Nota } from '../../../model/Nota';
import { NotaService } from '../../../services/nota.service';
import * as moment from 'moment';

@Component({
  selector: 'app-nota-card',
  templateUrl: './nota-card.component.html',
  styleUrls: ['./nota-card.component.less']
})
export class NotaCardComponent extends SessionComponent implements OnInit {

  
  public mostrarNota: boolean;
  public notaEditada: Nota;
  public editandoNota: boolean;
  @Input() public nota: Nota;
  @Input() public filtro: FiltroGrupo;

  constructor(private notaService: NotaService) {
        super()
  }

  ngOnInit() {
  }

  public verNota(nota: Nota) {
    
    this.notaEditada = Nota.fromData(nota);
    this.mostrarNota = true;
    this.editandoNota = false;
    
  }

  public  mismaFecha(): boolean {
   
    return moment(this.nota.fechaNota).startOf('date').isSame(moment(this.nota.fechaHasta).startOf('date'));
    
  }

  public agregarNota() {
     
    this.notaEditada = this.notaService.newEnt();
		this.mostrarNota = true;
    this.editandoNota = true;

  }

  handleGuardado = (nota: Nota) => {
    this.mostrarNota = false;
    this.filtro.forceUpdate();
    if(this.nota && nota) this.nota.version=nota.version;
  }

}
