import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { NavigationExtras, Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { AccionesTabla } from "src/app/common/model/AccionesTabla"
import { Filtro } from "src/app/common/model/Filtro"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { ProviderConfiguration } from "src/app/model/ProviderConfiguration"
import { ProviderConfigurationService } from "src/app/services/provider-configuration.service"

@Component({
	selector: "listado-provider-configuration",
	templateUrl: "listado-provider-configuration.component.html",
	styleUrls: ["listado-provider-configuration.component.less"]
})
export class ListadoProviderConfigurationComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: Filtro

	@Input()
	public guardarFiltro: boolean = true
	@Input()
	public ocultarCabecera: boolean = false
	@Input()
	public editable: boolean = true
	public listado: ProviderConfiguration[] = []
	@Input()
	public readonly: boolean = false
	@Input()
	public modoSeleccion: "single" | "multiple" | "none" = "none"

	public acciones: AccionesTabla[] = []

	@Output()
	public onSelect: EventEmitter<ProviderConfiguration[]> = new EventEmitter()

	public columns = []
	constructor(
		messagesService: MessagesService,
		public service: ProviderConfigurationService,
		public router: Router,
		public confirmationService: ConfirmationService
	) {
		super(messagesService)
		console.log(JSON.stringify(this.columns))
	}
	ngOnInit() {
		this.filtro = new Filtro("provider-configuration_lista", {}, 0, 20, "id", 1, this.guardarFiltro)
		this.columns = [
			{
				field: "provider",
				header: "Provider",
				class: "descriptivo-tabla",
				role: "descriptivo-tabla",
				sortable: true,
				order: 40
			},
			{
				field: "apiPath",
				header: "Api Path",
				class: "text-tabla",
				role: "text-tabla",
				sortable: true,
				order: 40
			}
		]
		this.acciones.push(
			new AccionesTabla(
				"Ver Configuración",
				"fa fa-eye",
				"accion-tabla",
				(item) => {
					this.ver(item.id)
				},
				(item: ProviderConfiguration) => {
					return true
				}
			)
		)
		this.acciones.push(
			new AccionesTabla(
				"Editar Configuración",
				"fa fa-pencil",
				"accion-tabla",
				(item) => {
					this.editar(item.id)
				},
				(item: ProviderConfiguration) => {
					return this.esAdministrador && !item.provider.esIterpax
				}
			)
		)
		this.acciones.push(
			new AccionesTabla(
				"Eliminar Configuración",
				"fa fa-trash",
				"accion-tabla",
				(item) => {
					this.eliminar(item)
				},
				(item: ProviderConfiguration) => {
					return this.esAdministrador && !item.provider.esIterpax
				}
			)
		)
	}

	public updateSeleccion(seleccion: any) {
		if (seleccion?.length || seleccion["id"]) {
			this.onSelect.emit(seleccion?.length ? seleccion : [seleccion])
		}
	}

	ngOnDestroy() {}

	public editar(id: number) {
		let query: NavigationExtras = {
			queryParams: {
				id: id
			}
		}
		this.router.navigate(["provider-configuration/edit"], query)
	}
	public ver(id: number) {
		let query: NavigationExtras = {
			queryParams: {
				id: id
			}
		}
		this.router.navigate(["provider-configuration/vista"], query)
	}

	public eliminar(p: ProviderConfiguration) {
		this.confirmationService.confirm({
			key: "genConf",
			header: "Eliminar",
			message: this.translateService.get("DESEA_ELIMINAR_EL__18"),
			accept: () => {
				let $this = this
				$this.service.eliminar(p.id).then((res) => {
					$this.filtro.forceUpdate()
					$this.success(this.translateService.get("EL_ITEM_FUE_ELIMINA_15"))
				})
			}
		})
	}
}
