import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { Subscription } from "rxjs"
import { ProfilePic } from "src/app/common/model/ProfilePic"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Caja } from "src/app/model/Caja"
import { DefaultFormaPago } from "src/app/model/DefaultFormaPago"
import { FormaPago } from "src/app/model/FormaPago"
import { CajaService } from "src/app/services/caja.service"
import { FormaPagoService } from "src/app/services/forma-pago.service"
import { MonedaService } from "src/app/services/moneda.service"
import { SessionComponent } from "./../../../common/components/session-component.component"

@Component({
	selector: "gestor-forma-pago",
	templateUrl: "gestor-forma-pago.component.html",
	styleUrls: ["gestor-forma-pago.component.less"]
})
export class GestorFormaPagoComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	@Input()
	public item: FormaPago
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false

	public tiposOperacion = ["COMPRA", "VENTA"]
	public defaults = {}

	@Input()
	public readonly: boolean = false

	@Output()
	public onGuardado: EventEmitter<FormaPago> = new EventEmitter<FormaPago>()

	@Output()
	public onCancelado = new EventEmitter()

	public file: File
	public onPicSelected(file: File) {
		const reader = new FileReader()
		let $this = this
		if (file.size > 1000000) {
			return this.error(this.translateService.get("EL_ARCHIVO_NO_PUEDE_16"))
		}
		this.file = file
		reader.onload = (e: any) => {
			$this.item.icono = new ProfilePic(null, e.target.result)
		}
		reader.readAsDataURL(file)
	}

	constructor(
		messagesService: MessagesService,
		public service: FormaPagoService,
		private route: ActivatedRoute,
		private router: Router,
		public cajaService: CajaService,
		private confService: ConfirmationService,
		private monedaService: MonedaService
	) {
		super(messagesService)
	}
	public preSave = (item: FormaPago) => {
		item.defaultsCajas = this.tiposOperacion.flatMap((to) => this.defaults[to].filter((d) => d.caja))
	}
	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}

	public async isValid() {
		return true
	}

	public onEdit(event) {
		if (this.item?.id) this.router.navigate(["'forma-pago/edit"], { queryParams: { id: this.item.id } })
	}
	public cajasDisponibles: Caja[] = []
	ngOnInit() {
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
				}
			})
		)
		if (this.route.snapshot.url.some((u) => u.path == "forma-pago")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				if (!this.service) return
				if (id) {
					this.service.getById(id, null, this.translateService.idioma ? this.translateService.idioma : "ES").then((r) => {
						this.item = r
						this.prepararDefaults()
					})
				} else {
					this.item = this.service ? this.service.newEnt() : null
					this.item["codigoIdioma"] = this.translateService.idioma ? this.translateService.idioma : "ES"
					this.prepararDefaults()
				}
				this.cajaService.getAll().then((r) => {
					this.cajasDisponibles = r.filter((c) => c.codigo !== Caja.CODIGO_COMPENSACION_SALDO)
				})
			})
		}
	}
	prepararDefaults = async () => {
		const monedas = await this.monedaService.getAll()
		if (this.item) {
			this.tiposOperacion.forEach((t) => {
				this.defaults[t] = monedas.map((m) => new DefaultFormaPago(m, t, this.item.getCajaDefault(t, m)))
			})
		}
	}

	public get esEditable() {
		return this.esAdministrador
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}
}
