import { Injectable } from "@angular/core"
import { CanalComunicacion } from "src/app/model/CanalComunicacion"
import { MessagesService } from "../common/services/messages-data-service.service"
import { CanalComunicacionFactory } from "./../model/CanalComunicacionFactory"
import { BufferedDescriptivoService } from "./BufferedDescriptivoService"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class CanalComunicacionService extends BufferedDescriptivoService<CanalComunicacion> {
	constructor(protected novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "canal-comunicacion"
	}
	public parseToEnt(data: any): CanalComunicacion {
		return CanalComunicacionFactory.newCanal(data)
	}
	public newEnt(): CanalComunicacion {
		return new CanalComunicacion()
	}
}
