import { Injectable } from "@angular/core"
import { PuntoDeVenta } from "src/app/model/PuntoDeVenta"
import { BufferedDescriptivoService } from "src/app/services/BufferedDescriptivoService"
import { MessagesService } from "../common/services/messages-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class PuntoDeVentaService extends BufferedDescriptivoService<PuntoDeVenta> {
	constructor(protected novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "punto-de-venta"
	}
	public parseToEnt(data: any): PuntoDeVenta {
		return PuntoDeVenta.fromData(data)
	}
	public newEnt(): PuntoDeVenta {
		return new PuntoDeVenta()
	}
}
