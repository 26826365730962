import { Parametrico } from "src/app/common/model/Parametrico"

export class TipoComprobante extends Parametrico {
	static PAGO_ADELANTADO_CODIGO: string = "PA"
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado: boolean = true,
		esSistema: boolean = false,
		peso: number = 99,
		esDefault: boolean = false,
		public esACuenta?: boolean,
		public codigoAfip?: string,
		public esNota?: boolean,
		public letra?: string,
		public discriminaIva?: boolean,
		public numeracionActual?: number,
		public notaTipoComprobante?: string
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
	}
	public tipoParametro(): string {
		return "tipo-comprobante"
	}
	public static fromData(data: any): TipoComprobante {
		if (!data) return null
		const o: TipoComprobante = new TipoComprobante(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			data.esSistema,
			data.peso,
			data.esDefault,
			data.esACuenta,
			data.codigoAfip,
			data.esNota,
			data.letra,
			data.discriminaIva,
			data.numeracionActual,
			data.notaTipoComprobante
		)

		return o
	}

	public get esOficial() {
		return this.codigoAfip != undefined
	}
}
