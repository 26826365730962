import { Injectable } from "@angular/core";
import { IMessage } from "@stomp/stompjs";
import * as moment from "moment";
import { BehaviorSubject, Subscription, filter, lastValueFrom } from "rxjs";
import { BaseService } from "../common/services/base.service";
import { environment } from "./../../environments/environment";
import { Novedad } from "./../model/Novedad";
import { AuthService } from "./auth.service";
import { WebsocketService } from "./websockets.service";
@Injectable({
	providedIn: "root",
})
export class NovedadService extends BaseService {
	update() {
		this.wsService.send("/app/chat", "Hola");
	}

	marcarVista(novedad: Novedad): Promise<Novedad> {
		return lastValueFrom(this.http.post(this.getBaseURL("marcarVista"), novedad))
			.then((n) => {
				return Novedad.fromData(n);
			})
			.catch((e) => {
				console.debug(e);
				return Promise.reject(e);
			});
	}
	unregister(tipo: string, suscriber?: IActualizable, idEntidad?: number, idLocal?: number, key?: string) {
		const obs = this.observers.filter((o) => {
			return o.tipo == tipo && o.idEntidad == idEntidad;
		})[0];
		if (obs) {
			obs.observadores = obs.observadores.filter((i) => i.getKey() != (suscriber ? suscriber.getKey() : key));
		}
	}
	public baseName(): string {
		return "novedades";
	}
	public initiated: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _pushToken: string;
	private unsuscribe() {
		this.socketSub && this.socketSub.unsubscribe();
		return Promise.resolve(true); /*this.http
            .post(
                this.getApiURL() + 'novedades/desuscribir/all',
                this.observers.map((ob) => {
                    return {
                        tipo: ob.tipo,
                        idEntidad: ob.idEntidad,
                        destinatario: this._pushToken
                    }
                })
            )
            )
            .catch((e) => {})*/
	}
	private _currentLocal: number;
	public get currentLocal(): number {
		return this._currentLocal;
	}
	public set currentLocal(v: number) {
		if ((!this._currentLocal || this.currentLocal != v) && this.pushToken) {
			this.observers
				.filter((o) => !o.idEntidad)
				.forEach(async (o) => {
					if (this.currentLocal) {
						this.unsuscribe();
					}
					if (v) {
						lastValueFrom(this.http.post(this.getBaseURL("suscribir"), { tipo: o.tipo, destinatario: this.pushToken, idLocal: v })).catch(
							(e) => {}
						);
					}
				});
		}
		this._currentLocal = v;
	}

	public get pushToken(): string {
		return this._pushToken || localStorage.getItem(environment.tokenKey + "_push");
	}
	public set pushToken(v: string) {
		this._pushToken = v;
		localStorage.setItem(environment.tokenKey + "_push", v);
	}
	private observers: ObseKey[] = [];
	private static updateNovedades: BehaviorSubject<Novedad[]> = new BehaviorSubject([]);
	socketSub: Subscription;
	constructor(private authService: AuthService, private wsService: WebsocketService) {
		super();
		NovedadService.updateNovedades.subscribe(this.notify);
		this.authService
			.getAutenticateState()
			.pipe(filter((i) => i === AuthService.LOGUEADO))
			.subscribe((l) => {
				this._pushToken = localStorage.getItem(environment.tokenKey + "_push");
				if (!this.wsService.isConnected()) this.wsService.connect();
				if (this.socketSub && !this.socketSub.closed) return;
				this.socketSub = this.wsService.subscribe("/topic/updates").subscribe((message: IMessage) => {
					this.nuevoPush(JSON.parse(message.body));
				});
			});
		this.authService.onLogout.pipe(filter((u) => u == true)).subscribe((l) => {
			this.unsuscribe();
			this.wsService.disconnect();
			this.socketSub && this.socketSub.unsubscribe();
			this.socketSub = null;
		});
	}
	public updateToken(token: string) {
		const prevToken = this._pushToken || localStorage.getItem(environment.tokenKey + "_push");
		if (prevToken != token) {
			this.pushToken = token;
			if (prevToken) {
				lastValueFrom(this.http.post(this.getBaseURL("updateToken"), { anterior: prevToken, nuevo: token }));
			} else {
				this.observers.forEach((o) => {
					o.observadores.forEach((obs) => {
						this.registrarObservador(o.tipo, obs, o.idEntidad);
					});
				});
			}
		}
	}
	public nuevoPush(msg: Novedad) {
		if (msg) {
			this.lastUpdate = moment().format("YYYYMMDDHHmmss");
			NovedadService.updateNovedades.next([msg]);
			if (document.visibilityState === "visible") {
				return;
			}
		}
	}
	private async _register(tipo: string, suscriber: IActualizable, idEntidad?: number, idLocal?: number, pushObserver: boolean = false) {
		let subs = this.observers.filter((o) => o.tipo == tipo && idEntidad == o.idEntidad)[0];
		if (!subs || subs.observadores.indexOf(suscriber) < 0) {
			if (!subs) {
				subs = new ObseKey({ tipo: tipo, idEntidad: idEntidad, idLocal: idLocal });
				this.observers.push(subs);
			}
			subs.observadores.push(suscriber);
			if (pushObserver) {
				if (this.pushToken) {
					lastValueFrom(
						this.http.post(this.getBaseURL("suscribir"), {
							tipo: tipo,
							idEntidad: idEntidad,
							destinatario: this.pushToken,
							idLocal: this.currentLocal,
						})
					).catch((e) => {});
				}
			}
		}
	}
	public registrarObservador(tipo: string, suscriber: IActualizable, idEntidad?: number, idLocal?: number) {
		this._register(tipo, suscriber, idEntidad, idLocal);
	}
	public registrarPushObserver(tipo: string, suscriber: IActualizable, idEntidad?: number, idLocal?: number) {
		this._register(tipo, suscriber, idEntidad, idLocal, true);
	}
	private checking: Promise<any>;
	private lastUpdate: string;
	public async check(): Promise<Novedad[]> {
		if (!this.lastUpdate) {
			this.lastUpdate = moment().format("YYYYMMDDHHmmss");
		}
		if (!this.checking && moment().diff(moment(this.lastUpdate, "YYYYMMDDHHmmss"), "minutes") > 5) {
			this.checking = lastValueFrom(this.http.get(this.getBaseURL("all") + (this.lastUpdate ? "?lastCheck=" + this.lastUpdate : "")))
				.then((n: Novedad[]) => {
					this.lastUpdate = moment().format("YYYYMMDDHHmmss");
					this.notify(n);
					this.checking = null;
					return n;
				})
				.catch((e) => {
					this.checking = null;
					return Promise.reject(e);
				});
		} else {
			return Promise.resolve([]);
		}
		return this.checking;
	}
	private notify = (r: Novedad[]) => {
		r.forEach((n: Novedad) => {
			const observadoresAplicados = this.observers.filter((o) => o.tipo == n.tipo && (o.idEntidad == n.idEntidad || !o.idEntidad));
			if (observadoresAplicados) {
				observadoresAplicados.forEach((oo) => oo.observadores.forEach((a) => a.next(n)));
			}
		});
	};
}
export class ObseKey {
	tipo: string;
	idEntidad?: number;
	observadores: IActualizable[] = [];
	constructor(data: any) {
		this.tipo = data.tipo;
		this.idEntidad = data.idEntidad;
	}
}
export interface IActualizable {
	next(n: Novedad);
	destroy();
	getKey();
}
