import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { LogService } from "src/app/services/log.service"

@Component({
	selector: "logs",
	templateUrl: "logs.component.html",
	styleUrls: ["logs.component.less"]
})
export class LogsComponent implements OnInit, OnDestroy {
	logs: string[] = []
	private timer

	private _lines: number = 2000
	public get lines(): number {
		return this._lines
	}
	public set lines(v: number) {
		this._lines = v
		this.logs = []
		this.lastLine = null
	}
	public onBlur(event) {
		this.initLog()
	}
	public stopScroll: boolean = false
	public searchText: string = ""
	lastLine: string = ""
	@ViewChild("scrollContainer") private scrollContainer: ElementRef
	constructor(private logService: LogService) {}

	scrollToBottom(): void {
		try {
			this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight
		} catch (err) {}
	}
	ngOnDestroy() {
		this.timer && clearInterval(this.timer)
	}
	initLog() {
		if (this.timer) clearInterval(this.timer)
		this.loadLogs(this._lines)
		if (!this.stopScroll) this.scrollToBottom()
		this.timer = setInterval(() => {
			this.loadLogs(50)
			if (!this.stopScroll) this.scrollToBottom()
		}, 3000)
	}
	ngOnInit() {
		this.initLog()
	}

	loadLogs(lines: number = 1) {
		this.logService.getLogTail(lines).subscribe({
			next: (data: string[]) => {
				if (this.logs.length === 0) {
					this.logs = data
				} else {
					const lastLineIndex = data.indexOf(this.lastLine)
					if (lastLineIndex !== -1 && lastLineIndex < data.length - 1) {
						this.logs = [...this.logs, ...data.slice(lastLineIndex + 1)]
					}
				}
				this.lastLine = this.logs[this.logs.length - 1]
			},
			error: (error) => console.error("Error fetching logs:", error)
		})
	}

	search(query: string) {
		this.logService.searchLogs(query).subscribe((data) => {
			this.logs = data
		})
	}
}
