import { CanalComunicacionEmail } from "./CanalComunicacionEmail"
import { CanalComunicacionInterno } from "./CanalComunicacionInterno"
import { CanalComunicacionWhatsapp } from "./CanalComunicacionWhatsapp"

export class CanalComunicacionFactory {
	public static types = [CanalComunicacionEmail, CanalComunicacionWhatsapp, CanalComunicacionInterno]
	public static factory

	static newCanal(data?: any) {
		let o = null
		if (!CanalComunicacionFactory.factory) {
			CanalComunicacionFactory.factory = {}
			CanalComunicacionFactory.types.forEach((tt) => (CanalComunicacionFactory.factory[tt.TIPO] = tt.fromData))
		}
		return CanalComunicacionFactory.factory[data.tipo](data)
	}

	public static fromData(data) {
		return CanalComunicacionFactory.newCanal(data)
	}
}
