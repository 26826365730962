import { Descriptivo } from "./../common/model/Descriptivo"
export class Pax {
	constructor(
		public nombre?: string,
		public apellido?: string,
		public tipoDocumento?: Descriptivo,
		public numeroDocumento?: string,
		public observaciones?: string,
		public fechaNacimiento?: Date,
		public id?: number,
		public nacionalidad?: Descriptivo
	) {}

	public static fromData(data): Pax {
		return new Pax(
			data.nombre,
			data.apellido,
			Descriptivo.fromData(data.tipoDocumento),
			data.numeroDocumento,
			data.observaciones,
			data.fechaNacimiento ? new Date(data.fechaNacimiento) : null,
			data.id,
			Descriptivo.fromData(data.nacionalidad)
		)
	}
	esIncompleto(): boolean {
		return !this.nombre || !this.apellido || !this.tipoDocumento || !this.numeroDocumento || !this.nacionalidad
	}
}
