import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { NavigationExtras, Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { AccionesTabla } from "src/app/common/model/AccionesTabla"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Filtro } from "src/app/common/model/Filtro"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { ProductoPublicado } from "src/app/model/ProductoPublicado"
import { ProductoPublicadoIntegracion } from "src/app/model/ProductoPublicadoIntegracion"
import { ProductoPublicadoService } from "src/app/services/producto-publicado.service"
import { ProviderService } from "src/app/services/provider.service"

@Component({
	selector: "listado-producto-publicado",
	templateUrl: "listado-producto-publicado.component.html",
	styleUrls: ["listado-producto-publicado.component.less"]
})
export class ListadoProductoPublicadoComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: Filtro

	@Input()
	public guardarFiltro: boolean = true
	@Input()
	public ocultarCabecera: boolean = false
	@Input()
	public editable: boolean = true
	public listado: ProductoPublicado[] = []
	@Input()
	public readonly: boolean = false
	@Input()
	public modoSeleccion: "single" | "multiple" | "none" = "none"

	@Output()
	public onSelect: EventEmitter<ProductoPublicado[]> = new EventEmitter()

	public columns = []
	public acciones = []
	public integraciones: ProductoPublicadoIntegracion[]
	public productoIntegrado
	public isVerIntegraciones = false
	public providers: Descriptivo[]

	constructor(
		messagesService: MessagesService,
		public service: ProductoPublicadoService,
		private router: Router,
		private confService: ConfirmationService,
		private providerService: ProviderService
	) {
		super(messagesService)
		console.log(JSON.stringify(this.columns))
	}

	ngOnInit() {
		this.providerService.getAllPortales().then((data) => (this.providers = data))
		this.filtro = new Filtro("producto-publicado_lista", {}, 0, 20, "id", 1, this.guardarFiltro)
		;(this.columns = [
			{
				field: "nombreServicio",
				header: "Nombre Servicio",
				class: "text-tabla",
				role: "text-tabla",
				sortable: true,
				order: 40
			},
			{
				field: "region",
				header: "Region",
				class: "text-tabla",
				role: "text-tabla",
				sortable: true,
				order: 40
			},
			{
				field: "habilitado",
				header: "Habilitado",
				class: "is-boolean",
				role: "boolean-tabla",
				sortable: false,
				order: 40
			}
		]),
			(this.acciones = [
				new AccionesTabla(this.translateService.get("Editar"), "fa fa-pencil", "accion-tabla", (item) => {
					this.editar(item)
				}),

				new AccionesTabla(this.translateService.get("Eliminar"), "fa fa-trash", "accion-tabla", (item) => {
					this.eliminar(item)
				}),

				new AccionesTabla(this.translateService.get("Ver"), "fa fa-eye", "accion-tabla", (item) => {
					this.ver(item)
				}),
				new AccionesTabla(this.translateService.get("Integraciones"), "fa fa-link", "accion-tabla", (item) => {
					this.verIntegraciones(item)
				})
			])
	}

	public updateSeleccion(seleccion: any) {
		if (seleccion?.length || seleccion["id"]) {
			this.onSelect.emit(seleccion?.length ? seleccion : [seleccion])
		}
	}

	ngOnDestroy() {}

	public editar(item: ProductoPublicado) {
		let query: NavigationExtras = {
			queryParams: {
				id: item.id
			}
		}
		this.router.navigate(["producto-publicado/edit"], query)
	}

	public ver(item: ProductoPublicado) {
		let query: NavigationExtras = {
			queryParams: {
				id: item.id
			}
		}
		this.router.navigate(["producto-publicado/vista"], query)
	}

	public eliminar(item: ProductoPublicado) {
		event.stopPropagation()
		this.confService.confirm({
			key: "genConf",
			header: "Eliminar",
			message: "Va a eliminar el producto " + item.descripcion + ". Desea continuar?",
			accept: () => {
				let $this = this
				$this.service.eliminar(item.id).then((res) => {
					$this.filtro.forceUpdate()
					$this.success(item.descripcion + " fue eliminado.")
				})
			}
		})
	}

	public integrar(provider) {
		event.stopPropagation()
		let integracion = this.integracionProvider(provider.codigo)
		if (!integracion) {
			integracion = new ProductoPublicadoIntegracion(null, this.productoIntegrado.id, provider, true, false, [])
		}
		this.service.integrar(integracion).then((res) => {
			this.verIntegraciones(this.productoIntegrado)
		})
	}

	public verIntegraciones(item: ProductoPublicado) {
		this.productoIntegrado = item
		this.service.verIntegraciones(item.id).then((i) => {
			this.integraciones = i
			this.isVerIntegraciones = true
		})
	}

	public integracionProvider(codigo: string) {
		return this.integraciones.find((i) => i.provider.codigo == codigo)
	}
}
