import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { DescriptivosService } from "src/app/common/services/descriptivos.service"
import { Descriptivo } from "../common/model/Descriptivo"
import { LoadingService } from "../common/services/loading-data-service.service"

@Injectable({
	providedIn: "root"
})
export class ProviderService extends DescriptivosService<Descriptivo> {
	public baseName(): string {
		return "provider"
	}
	public parseToEnt(data: any): Descriptivo {
		return Descriptivo.fromData(data)
	}
	public newEnt(): Descriptivo {
		return new Descriptivo()
	}

	public getAllPortales(customLoading?: LoadingService) {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL("portales")))
			.then((r) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return this.parse(r)
			})
			.catch((e) => this.handleError(e, customLoading))
	}

}
