<div class="layout-container" *ngIf="!proveedor">
	<div class="layout-main">
		<div class="logo-top">
			<img src="{{ mainLogo }}" alt="" />
		</div>
		<div class="layout-content">
			<ng-container *ngTemplateOutlet="list"></ng-container>
		</div>
	</div>
	<public-footer></public-footer>
</div>

<ng-container *ngIf="proveedor">
	<ng-container *ngTemplateOutlet="list"></ng-container>
</ng-container>

<p-dialog
	[(visible)]="mostrarMotivo"
	appendTo="body"
	[modal]="true"
	#dialDetail
	[styleClass]="'no-header gestor-dialog'"
	(onShow)="onDialogShow($event, dialDetail)"
	[style]="{ width: '50vw' }"
>
	<div class="grid">
		<div class="col-12 lg:col-12">
			<span class="p-float-label">
				<input
					pInputText
					placeholder="{{ 'INGRESE_MOTIVO' | translate }}"
					[(ngModel)]="motivoRechazo"
					tooltipPosition="bottom"
					pTooltip="{{ 'INGRESE_MOTIVO' | translate }}"
					type="text"
					class="p-inputwrapper-filled"
				/>
				<label>{{ "INGRESE_MOTIVO" | translate }}</label>
			</span>
		</div>
	</div>
	<p-footer>
		<button pButton (click)="cancelarRechazar()" label="{{ 'CANCELAR' | translate }}"></button>
		<button pButton cclass="p-btn-success" (click)="rechazar()" label="{{ 'CONFIRMAR' | translate }}"></button>
	</p-footer>
</p-dialog>

<ng-template #list>
	<model-list
		[service]="service"
		[modelName]="'AprobacionProveedor'"
		[columns]="columns"
		[hideTitle]="hideTitle"
		title="{{ titulo }}"
		[getData]="proveedor ? getDataByProveedor : getData"
		[filtro]="filtro"
		[permiteNuevo]="false"
		[acciones]="acciones"
		[isLazy]="true"
		[modoSeleccion]="'multiple'"
		[updateRowImplementation]="updateReferencia"
		[menuItemAcciones]="menuAcciones"
		(seleccionChange)="updateMenuAcciones($event)"
		[filterContent]="filterContent"
		[styleClass]="mostrarDatosPaxs ? 'print-hidden' : ''"
		(listadoChange)="handleListadoChange($event)"
		[hasDetail]="false"
	>
		<ng-template #filterContent>
			<div class="grid">
				<div class="col-6 field">
					<span class="p-float-label">
						<p-calendar [(ngModel)]="filtro.fechaDesde" name="rH" dateFormat="dd/mm/yy" appendTo="body" [showIcon]="true"></p-calendar>
						<label for="">{{ "FECHA_DESDE" | translate }}</label>
					</span>
				</div>
				<div class="col-6 field">
					<span class="p-float-label">
						<p-calendar [(ngModel)]="filtro.fechaHasta" name="rH" dateFormat="dd/mm/yy" appendTo="body" [showIcon]="true"></p-calendar>
						<label for="">{{ "HASTA" | translate }}</label>
					</span>
				</div>

				<div class="col-12 botonera">
					<div class="grid">
						<div class="col-12 lg:col-6 text-center" *ngFor="let estado of allEstados">
							<button
								pButton
								[label]="estado?.descripcion"
								[ngClass]="{ 'no-seleccionado': !filtro?.tieneEstado(estado) }"
								[ngStyle]="{ 'border-bottom-color': estado?.color, 'background-color': estado?.color }"
								(click)="filtro.toggleEstado(estado)"
							></button>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</model-list>
</ng-template>

<ng-template #producto let-item>
	<div class="producto">
		<span class="overflow" tooltipPosition="bottom" pTooltip="{{ item.producto?.descripcion }}">{{ item.producto?.descripcion }} </span>
		<i *ngIf="item.modificado" pTooltip="{{ getTooltip(item) }}" class="pi pi-exclamation-triangle warning"> </i>
	</div>
</ng-template>

<ng-template #paxs let-data let-col="col">
	<div class="text-center" id="step1Pax" (click)="verDatosPaxs(data); $event.stopPropagation()">
		<span class="text-center" tooltipPosition="bottom" pTooltip="{{ data[col.field] }}" style="margin-right: 10px">{{ data[col.field] }}</span>
		<i pTooltip="{{ 'DATOS_PAXS' | translate }}" class="fa fa-id-card-o"></i>
	</div>
</ng-template>

<p-dialog [(visible)]="mostrarDatosPaxs" [modal]="true" header="{{ 'DATOS_PAXS' | translate }}" [style]="{ width: '70vw' }">
	<div *ngIf="paxs">
		<p-table [value]="_paxs">
			<ng-template pTemplate="header">
				<tr>
					<th style="width: 30%">{{ "Nombre" | translate }}</th>
					<th style="width: 20%">{{ "Documento" | translate }}</th>
					<th style="width: 20%">{{ "Fecha Nac." | translate }}</th>
					<th style="width: 30%">{{ "Observaciones" | translate }}</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-pax>
				<tr>
					<td>{{ pax.nombre + " " + pax.apellido }}</td>
					<td>{{ pax.tipoDocumento.descripcion + " " + pax.numeroDocumento }}</td>
					<td>
						<span *ngIf="pax.fechaNacimiento">{{ pax.fechaNacimiento | date : "dd/MM/yyyy" }}</span>
					</td>
					<td>{{ pax.observaciones }}</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
	<p-footer>
		<div class="botonera">
			<div class="grid justify-content-end grid-nogutter">
				<button pButton label="{{ 'IMPRIMIR' | translate }}" (click)="imprimirDatosPaxs()" class="p-button-secondary"></button>
				<button pButton label="{{ 'CERRAR' | translate }}" (click)="cerrarDatosPaxs()"></button>
			</div>
		</div>
	</p-footer>
</p-dialog>
