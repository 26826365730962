import * as moment from "moment";

export class OpcionPuestoOcupacion {

    constructor(
        public idGrupo?: number,
        public fechaActividad?: Date,
        public idProducto?: number,
        public codigoProducto?: string,
        public descripcionProducto?: string,
        public idTipoPuesto?: number,
        public codigoTipoPuesto?: string,
        public descripcionTipoPuesto?: string,
        public idPersonal?: number,
        public nombrePersonal?: string,
        public imagen?: string

    ) {
        
    }

    public static fromData(data: any): OpcionPuestoOcupacion {
        if (!data) return null;
        let o = new OpcionPuestoOcupacion(
            data.idGrupo,
            data.fechaActividad,
            data.idProducto,
            data.codigoProducto,
            data.descripcionProducto,
            data.idTipoPuesto,
            data.codigoTipoPuesto,
            data.descripcionTipoPuesto,
            data.idPersonal,
            data.nombrePersonal,
            data.imagenUrl

        );
       return o;
    }

    get imagenUrl() {
        return this.imagen? this.imagen : 'assets/images/default_profile.png'
    }

    public toString() {
        return "Asignado a " + this.descripcionProducto + " como " + this.descripcionTipoPuesto + " en la fecha: " + moment(this.fechaActividad).format('DD/MM/YYYY hh:mm');
    }

}