<div class="rankin-producto content">
	<div class="model-list">
		<div class="top-section grid">
			<div class="col-12 model-title custom">
				<div class="grid">
					<div class="col-8">
						<h2 autofocus>{{ "Encuestas" | translate }}</h2>
					</div>
				</div>
			</div>

			<div class="col-12 model-filters">
				<filtro [(filter)]="filtro" [isFixed]="false" [isMinimized]="true" [useSidebar]="true" [filterTemplateContent]="filterTemplateContent">
					<ng-template #filterTemplateContent>
						<div class="grid">
							<div class="col-12">
								<descriptivo-material-selector
									[label]="'PERSONAL' | translate"
									[limpiable]="true"
									[(ngModel)]="filtro.personal"
									name="personal"
									isLazy="true"
									[service]="proveedorService"
								>
								</descriptivo-material-selector>
							</div>

							<div class="col-12">
								<descriptivo-material-selector
									[label]="'VEHICULO' | translate"
									[limpiable]="true"
									[(ngModel)]="filtro.vehiculo"
									name="vehiculo"
									isLazy="true"
									[service]="vehiculoService"
								>
								</descriptivo-material-selector>
							</div>
							<div class="col-12">
								<selector-multiple-chips
									[(ngModel)]="filtro.productos"
									name="productos"
									[opciones]="productos"
									[permiteNuevo]="false"
									[limpiable]="true"
									[titulo]="'PRODUCTOS' | translate"
								>
								</selector-multiple-chips>
							</div>
							<div class="col-12">
								<parametrico-selector
									[service]="tipoProductoService"
									[(ngModel)]="filtro.tipoProducto"
									[limpiable]="true"
									[permiteNuevo]="false"
									[titulo]="'TIPOPRODUCTO' | translate"
								>
								</parametrico-selector>
							</div>
							<div class="col-12">
								<parametrico-selector
									[service]="categoriaProductoService"
									[(ngModel)]="filtro.categoriaProducto"
									[limpiable]="true"
									[permiteNuevo]="false"
									[titulo]="'CategoriaProducto' | translate"
								>
								</parametrico-selector>
							</div>
							<div class="col-12">
								<div class="grid">
									<div class="col-6 p-fluid field">
										<span class="p-float-label">
											<p-calendar
												[(ngModel)]="filtro.fecha"
												dateFormat="dd/mm/yy"
												appendTo="body"
												name="fecha"
												[showIcon]="true"
											></p-calendar>
											<label>{{ "FECHA_ACTIVIDAD_DESDE" | translate }}</label>
										</span>
									</div>
									<div class="col-6 p-fluid field">
										<span class="p-float-label">
											<p-calendar
												[(ngModel)]="filtro.fechaHasta"
												dateFormat="dd/mm/yy"
												appendTo="body"
												name="fechaVencimientoHasta"
												[showIcon]="true"
											></p-calendar>
											<label>{{ "HASTA" | translate }}</label>
										</span>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</filtro>
			</div>
		</div>
		<div class="bottom-section">
			<div class="grid">
				<div class="col-12 lg:col-4" *ngFor="let item of reporte">
					<div class="card">
						<div class="titulo-pregunta">
							<div>
								{{ item.descripcionPregunta }}
								<div class="cantidad-respuestas">{{ item.cantidad }} {{ "RESPUESTAS" | translate }}</div>
							</div>
							<div class="promedio">
								{{ item.promedio }}
								<i class="pi pi-star-fill"></i>
							</div>
						</div>

						<div class="grid puntajes">
							<div class="col-12" *ngFor="let puntaje of item.puntajes">
								<div class="grid puntaje" (click)="onClickPuntaje(puntaje, item)">
									<div class="col-fixed titulo-rating" style="width: 4em">
										<span>
											{{ puntaje.valor }}
											<i class="pi pi-star-fill"></i>
										</span>
									</div>
									<div class="col">
										<p-progressBar
											pTooltip="{{ puntaje.cantidad }} {{ 'RESPUESTAS' | translate }}"
											value="{{ getValue(puntaje, item) }}"
											[showValue]="true"
											[style]="{ height: '3em' }"
										></p-progressBar>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="grid">
				<div class="col-12">
					<div class="card">
						<p-virtualScroller [value]="respuestas" scrollHeight="500px" [itemSize]="100">
							<ng-template pTemplate="header">
								<div [ngClass]="{ 'd-flex align-items-center': !isMobile() }">
									<i class="pi pi-filter filtro"></i>
									<div class="d-flex buttons">
										<button
											pButton
											pRipple
											label="{{ i + 1 }}"
											icon="pi pi-star-fill"
											iconPos="left"
											class="scroller"
											[ngClass]="{ 'p-button-lg': !isMobile(), 'p-button-outlined': puntajeSeleccionado != i + 1 }"
											*ngFor="let item of [].constructor(5); let i = index"
											(click)="onClickEstrella(i)"
										></button>
									</div>

									<input class="busqueda" pInputText placeholder="{{ 'BUSQUEDA' | translate }}" [(ngModel)]="searchStr" />
								</div>
							</ng-template>

							<ng-template let-respuesta pTemplate="item" let-i="index">
								<div class="grid">
									<div class="col-12">
										<span class="titulo-pregunta scroller">{{ respuesta.pregunta.descripcion }} - </span>
										<span>
											{{ respuesta.producto }} - {{ respuesta.fechaCompletado | date : "dd/MM/yyyy" }} - {{ respuesta.nombrePax }}</span
										>
									</div>

									<div class="col-12">
										<p>
											{{ respuesta.respuesta }}
										</p>
									</div>
								</div>
							</ng-template>
						</p-virtualScroller>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
