import moment from "moment"
import { round } from "src/app/common/utils/MathUtils"
import { Descriptivo } from "../common/model/Descriptivo"
import { Identificable } from "../common/model/Identficable"
import { IRango } from "./IRango"
import { Insumo } from "./Insumo"
import { Moneda } from "./Moneda"
import { TipoIVAGrabado } from "./TipoIVAGrabado"

export class PrecioInsumo implements IRango {
	public static MARKUP = "M"
	public static COMISION = "C"
	public static GANANCIA = "G"
	key
	constructor(
		public id?: number,
		public fechaDesde?: Date,
		public fechaHasta?: Date,
		public precio?: number,
		public markup?: number,
		public moneda?: Moneda,
		public minPax?: number,
		public maxPax?: number,
		public proveedor?: Descriptivo,
		public insumo?: Insumo,
		public tipoIVA?: TipoIVAGrabado,
		public precioMinimo?: number,
		public tipoMarkup: string = PrecioInsumo.MARKUP
	) {
		this.key = this.id || Math.floor(Math.random() * (200000 - 100000 + 1)) + 100000
	}
	public static fromData(data: any): PrecioInsumo {
		if (!data) return null
		const o: PrecioInsumo = new PrecioInsumo(
			data.id,
			data.fechaDesde ? new Date(data.fechaDesde) : null,
			data.fechaHasta ? new Date(data.fechaHasta) : null,
			data.precio,
			data.markup
		)
		o.minPax = data.minPax
		o.maxPax = data.maxPax
		o.moneda = Moneda.fromData(data.moneda)
		o.proveedor = Descriptivo.fromData(data.proveedor)
		o.insumo = Insumo.fromData(data.insumo)
		o.tipoIVA = TipoIVAGrabado.fromData(data.tipoIVA || TipoIVAGrabado.ZERO)
		o.precioMinimo = data.precioMinimo
		o.tipoMarkup = data.tipoMarkup || PrecioInsumo.MARKUP
		return o
	}

	public aplica(insumo: Identificable, fecha: Date = new Date(), cantidad: number, moneda: Moneda) {
		return (
			this.insumo.id == insumo.id &&
			moment(this.fechaDesde).isSameOrBefore(fecha) &&
			(this.fechaHasta == null || moment(this.fechaHasta).isSameOrAfter(fecha)) &&
			(this.minPax == null || this.minPax <= cantidad) &&
			(this.maxPax == null || this.maxPax >= cantidad) &&
			this.moneda.id == moneda.id
		)
	}
	public precioVenta(cantidad: number) {
		const precio = this.precioMinimo && this.precio * cantidad < this.precioMinimo ? this.precioMinimo / cantidad : this.precio
		switch (this.tipoMarkup) {
			case PrecioInsumo.MARKUP:
				return round(precio + (precio * this.markup) / 100, 2)
			case PrecioInsumo.COMISION:
				return precio
			case PrecioInsumo.GANANCIA:
				return this.markup > 0 ? round(precio / (1 - this.markup / 100), 2) : 0
		}
		return 0
	}
	public aplicaRango(desde: Date, hasta: Date) {
		const _hasta = this.fechaHasta || moment().add(10, "year").toDate()
		return moment(desde).isSameOrBefore(moment(_hasta)) && moment(this.fechaDesde).isSameOrBefore(moment(hasta || _hasta))
	}

	public superponeMaxMin(min: number, max: number) {
		let min1 = this.minPax
		let min2 = min
		let max1 = this.maxPax
		let max2 = max

		if (min1 === null) min1 = 0
		if (max1 === null) max1 = Infinity
		if (min2 === null) min2 = 0
		if (max2 === null) max2 = Infinity

		return !(max1 < min2 || min1 > max2)
	}
	public getCosto(cantidad: number) {
		const precio = this.precioMinimo && this.precio * cantidad < this.precioMinimo ? this.precioMinimo / cantidad : this.precio
		switch (this.tipoMarkup) {
			case PrecioInsumo.MARKUP:
				return precio
			case PrecioInsumo.COMISION:
				return round(precio * (1 - this.markup / 100), 2)
			case PrecioInsumo.GANANCIA:
				return precio
		}
		return 0
	}
}
