import { Component, OnDestroy, OnInit } from "@angular/core";
import { SessionComponent } from "src/app/common/components/session-component.component";
import { Filtro } from "src/app/common/model/Filtro";
import { MessagesService } from "src/app/common/services/messages-data-service.service";
import { Insumo } from "src/app/model/Insumo";
import { InsumoService } from "src/app/services/insumo.service";

@Component({
	selector: "listado-insumo",
	templateUrl: "listado-insumo.component.html",
	styleUrls: ["listado-insumo.component.less"],
})
export class ListadoInsumoComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: Filtro = new Filtro("insumo_filtros", {}, 0, 10);
	public listado: Insumo[] = [];
	public columns = [
		{
			field: "codigo",
			header: "Código",
			class: "text-center",
			width: "8rem",
			role: "text-tabla",
			sortable: true,
		},
		{
			field: "descripcion",
			header: "Descripcion",
			role: "text-tabla",
			sortable: true,
		},
		{
			field: "unidad",
			header: "Unidad",
			role: "descriptivo-tabla",
			sortable: false,
		},
		{
			field: "categoria",
			header: "Categoría",
			role: "descriptivo-tabla",
			sortable: true,
		},
		{
			field: "habilitado",
			header: "Habilitado",
			role: "boolean-tabla",
			class: "is-boolean",
		},
		{
			field: "esCostoFijo",
			header: "Es costo fijo",
			role: "boolean-tabla",
			class: "is-boolean",
		},
	];
	constructor(messagesService: MessagesService, public service: InsumoService) {
		super(messagesService);
	}
	ngOnInit() {}
	ngOnDestroy() {}
}
