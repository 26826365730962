import { Injectable } from "@angular/core"
import { AdministracionAuthGuard } from "./AdministracionAuthGuard"

@Injectable({
	providedIn: "root"
})
export class ReservasAG extends AdministracionAuthGuard {
	public getRoles(): string[] {
		return super.getRoles().concat(["ROLE_RESERVAS", "ROLE_CLIENTE"])
	}
}
@Injectable({
	providedIn: "root"
})
export class CancelacionReservaAG extends ReservasAG {
	public getRoles(): string[] {
		return super.getRoles().concat(["ROLE_CANCELAR_RESERVAS"])
	}
}
