import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { Subscription } from "rxjs"
import { ID } from "src/app/app.module"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { ItemComprobante } from "src/app/model/ItemComprobante"
import { ListaPrecios } from "src/app/model/ListaPrecios"
import { Moneda } from "src/app/model/Moneda"
import { Producto } from "src/app/model/Producto"
import { TipoIVAGrabado } from "src/app/model/TipoIVAGrabado"
import { CentroCostoService } from "src/app/services/centro-costo.service"
import { ItemComprobanteService } from "src/app/services/item-comprobante.service"
import { ProductoService } from "src/app/services/producto.service"
import { TipoIVAGrabadoService } from "src/app/services/tipo-iva-grabado.service"
import { SessionComponent } from "./../../../common/components/session-component.component"
import { round } from "./../../../common/utils/MathUtils"

@Component({
	selector: "gestor-item-comprobante",
	templateUrl: "gestor-item-comprobante.component.html",
	styleUrls: ["gestor-item-comprobante.component.less"]
})
export class GestorItemComprobanteComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription

	private _item: ItemComprobante
	public get item(): ItemComprobante {
		return this._item
	}
	@Input()
	public set item(v: ItemComprobante) {
		this._item = v
		this.productoPrevio = v?.producto
	}

	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false

	private _moneda: Moneda
	public get moneda(): Moneda {
		return this._moneda
	}
	@Input()
	public set moneda(v: Moneda) {
		this._moneda = v ? v : this.configuracionEntorno?.moneda || Moneda.PESOS
	}

	@Output()
	public onGuardado: EventEmitter<ItemComprobante> = new EventEmitter<ItemComprobante>()

	@Output()
	public onCancelado = new EventEmitter()

	public centroCostoOptions: Descriptivo[] = []
	public productoOptions: Descriptivo[] = []
	@Input()
	public readonly: boolean = false
	@Input()
	public listaPrecios: ListaPrecios

	@Input()
	public fecha: Date = new Date()
	@Input()
	public incluyeImpuestos: boolean = false

	@Input()
	public esNota: boolean = false
	private productoPrevio: Producto | Descriptivo
	constructor(
		messagesService: MessagesService,
		public service: ItemComprobanteService,
		private route: ActivatedRoute,
		private confService: ConfirmationService,
		private centroCostoService: CentroCostoService,
		public productoService: ProductoService,
		public tipoIVAGrabadoService: TipoIVAGrabadoService
	) {
		super(messagesService)
		if (!this.moneda) this.moneda = this.configuracionEntorno?.moneda || Moneda.PESOS
	}

	public async handleGuardado(item: ItemComprobante) {
		if (item.tipoIVA == null) {
			const p = await this.productoService.getById(item.producto.id)
			item.tipoIVA = p.tipoIVA
		}
		const valorIva = (item.tipoIVA?.valorIva || 0) / 100
		let acumulado = 0
		if (!this.incluyeImpuestos) {
			this.item.subcategorias.forEach((s) => {
				const base = (s.proporcion / 100) * item.precioUnitarioBruto
				s.precioUnitarioBruto = item.precioUnitarioBruto - acumulado - base < 0 ? item.precioUnitarioBruto - acumulado : base
				acumulado += s.precioUnitarioBruto
				s.impuestos = round((s.proporcion / 100) * item.precioUnitarioBruto * (1 + item.descuento / 100) * s.multiplicadorIVA)
				s.precioUnitario = s.precioUnitarioBruto + s.impuestos
			})
			item.impuestos = item.esSubcategorizado
				? this.item.subcategorias.reduce((a, b) => (a += b.impuestos), 0)
				: round(item.precioUnitarioBruto * (1 + item.descuento / 100) * valorIva)

			item.precioUnitario = round(item.precioUnitarioBruto + item.impuestos)
			item.valorDescuento = round((item.precioUnitarioBruto * item.descuento) / 100, 2)
		} else {
			this.item.subcategorias.forEach((s) => {
				const base = (s.proporcion / 100) * item.precioUnitario
				s.precioUnitario = item.precioUnitario - acumulado - base < 0 ? item.precioUnitario - acumulado : base
				acumulado += s.precioUnitario
				s.impuestos = round(s.precioUnitario - (s.precioUnitario * (1 + item.descuento / 100)) / (1 + s.multiplicadorIVA))
				s.precioUnitarioBruto = s.precioUnitario - s.impuestos
			})
			item.impuestos = item.esSubcategorizado
				? this.item.subcategorias.reduce((a, b) => (a += b.impuestos), 0)
				: round(item.precioUnitario - (item.precioUnitario * (1 + item.descuento / 100)) / (1 + valorIva))
			item.precioUnitarioBruto = item.precioUnitario - item.impuestos / (1 + item.descuento / 100)
			item.valorDescuento = round((item.precioUnitario * item.descuento) / 100)
		}

		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}

	public async isValid() {
		return true
	}
	ngOnInit() {
		if (this.route.snapshot.url.some((u) => u.path == "item-comprobante")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				if (!this.service) return
				if (id) {
					this.service.getById(id).then((r) => {
						this.item = r
					})
				} else {
					this.item = this.service ? this.service.newEnt() : null
					this.item.tipoIVA = this.tipoIVAGrabadoService.default
				}
			})
		}

		this.centroCostoService.getDescriptivos().then((r) => {
			this.centroCostoOptions = r
		})
		//	this.productoService.getDescriptivos().then(r => { this.productoOptions = r; })
	}

	public seleccionarProducto(producto: Descriptivo) {
		if (!this.item.descripcion || this.item.descripcion == this.item.producto?.descripcion) {
			this.item.descripcion = producto.descripcion
		}
		this.updatePrecio(producto)
	}
	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}

	public updatePrecio = async (producto: Descriptivo) => {
		if (this.item.producto != producto && this.listaPrecios) {
			this.item.precioUnitario = this.listaPrecios.getPrecioValor(producto, this.fecha, this.item.cantidad)
		}
		const p: Producto = await this.productoService.getById(producto.id)
		if (this.productoPrevio?.id != producto?.id) {
			this.item.esSubcategorizado = p.esSubcategorizado
			this.item.esSubcategoria = false
			const key = ID()
			this.item.referenciaPadre = key
			this.item.subcategorias = []
			p.subcategorias.forEach((s) => {
				const item = new ItemComprobante()
				item.producto = p
				item.proporcion = s.proporcion
				item.descripcion = s.subcategoriaImponible?.descripcion || p.descripcion
				item.tipoIVA = s.tipoIVA
				item.esSubcategoria = true
				item.referenciaPadre = key
				item.subcategoria = s.subcategoriaImponible
				this.item.subcategorias.push(item)
			})
			if (p.esSubcategorizado) {
				this.item.tipoIVA = TipoIVAGrabado.ZERO
			}
			this.productoPrevio = p
		}
	}
}
