<div class="content-badge" [ngClass]="{ condetalle: verDetalle, small: small }">
	<div
		class="circular-badge {{ estado }}"
		tooltipPosition="bottom"
		pTooltip="{{ tooltip }}"
		[ngClass]="{ 'sin-asignar': !vehiculo, small: small }"
		[ngStyle]="{ 'background-image': ' url(' + urlImagen + ')' }"
	></div>
	<div class="detalle" *ngIf="verDetalle">
		<label for=""
			>{{ requerimiento?.tipoVehiculo?.descripcion }}
			<span *ngIf="!vehiculo?.proveedor">{{ vehiculo ? vehiculo.capacidad : requerimiento?.capacidadMinima }}<i class="fa fa-users"></i></span>
			<span *ngIf="vehiculo ? vehiculo.es4x4 : requerimiento?.es4x4" class="es4x4">4x4</span>
		</label>
		<span *ngIf="vehiculo">{{ vehiculo?.descripcion }}</span>
		<small class="w-100 mt-1" *ngIf="vehiculo?.patente != vehiculo?.descripcion">{{ vehiculo?.patente }}</small>
	</div>
</div>
