import { Component, EventEmitter, forwardRef, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output } from "@angular/core"
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms"
import { debounceTime } from "rxjs/operators"
import { LocalizacionEntorno } from "src/app/app.module"
import { Moneda } from "src/app/model/Moneda"
import { ConfiguracionEntornoService } from "src/app/services/configuracion-entorno.service"
import { MonedaService } from "src/app/services/moneda.service"
import { TranslateService } from "./../../common/services/translate.service"
import { ErrorHandler } from "./../../common/utils/ErrorsHandler"
import { IMoneda } from "./IMoneda"

@Component({
	selector: "span-dinero-editable",
	templateUrl: "./span-dinero-editable.component.html",
	styleUrls: ["./span-dinero-editable.component.less"],
	providers: [
		MonedaService,
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SpanDineroEditableComponent),
			multi: true
		},
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => SpanDineroEditableComponent), multi: true }
	]
})
export class SpanDineroEditableComponent implements OnInit, ControlValueAccessor, OnDestroy, Validator {
	@Input()
	public label: string
	public localeEnvironment: string = LocalizacionEntorno() || "es-AR"
	public importeForm: FormControl
	@Input()
	public readonly: boolean = false
	public codigoSafe: string = "USD"
	@Input()
	public tooltip: string
	@Input()
	public editable: boolean = true

	public editando: boolean = false

	private _moneda: IMoneda
	public get moneda(): IMoneda {
		return this._moneda
	}
	@Input()
	public set moneda(v: IMoneda) {
		this._moneda = v
		if (!v?.codigo || v?.codigo.length < 3) this.codigoSafe = "USD"
		else this.codigoSafe = v?.codigo
	}

	@Input()
	public styleClass: string

	private _maxValue: number
	public get maxValue(): number {
		return this._maxValue
	}
	@Input()
	public set maxValue(v: number) {
		this._maxValue = v
	}
	@Output()
	public onKeyDown: EventEmitter<any> = new EventEmitter()
	@Output()
	public onModificado: EventEmitter<any> = new EventEmitter()

	@Output()
	public onInput: EventEmitter<any> = new EventEmitter()

	private _minValue: number
	public get minValue(): number {
		return this._minValue
	}
	@Input()
	public set minValue(v: number) {
		this._minValue = v
	}

	@Inject(LOCALE_ID) public locale: string

	@Input()
	public decimals: number = 2

	@Output()
	public onChange: EventEmitter<number> = new EventEmitter()
	public required: boolean = false
	public defaultMoneda
	public disabled: boolean = false
	@Input()
	public hideLabel: boolean = false

	private _importeMuestra: number
	public get importeMuestra(): number {
		return this._importeMuestra
	}

	@Input()
	public set importeMuestra(v: number) {
		this._importeMuestra = v
		if (this.importeForm) this.importeForm.setValue(v)
	}
	constructor(
		private monedaService: MonedaService,
		private translationService: TranslateService,
		private errorHandler: ErrorHandler,
		private configuracionEntornoService: ConfiguracionEntornoService
	) {
		this.defaultMoneda = this.configuracionEntornoService.configuracionEntorno?.moneda?.codigo || "USD"
		if (!this.moneda) {
			this.monedaService.getByCodigo(this.defaultMoneda).then((m) => {
				if (!this.moneda) this.moneda = m || Moneda.PESOS
			})
		}
	}

	public getDecimals() {
		return this.decimals > 2 ? this.decimals : 2
	}

	private onChangeCallback: (_: any) => void = () => {}
	private onTouchedCallback: (_: any) => void = () => {}

	writeValue(obj: any): void {
		this.importeForm.setValue(obj)
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled
	}
	public editarPrecio(event) {
		if (this.editable && !this.disabled && !this.readonly) this.editando = true
	}
	private minValidator = (control: AbstractControl) => {
		return control && control.value && this.minValue && control.value < this.minValue ? { min: { min: this.minValue } } : null
	}
	private maxValidator = (control: AbstractControl) => {
		return control && control.value && this.maxValue && control.value > this.maxValue ? { max: { max: this.maxValue } } : null
	}
	ngOnDestroy(): void {}

	ngOnInit() {
		this.importeForm = new FormControl(null, [this.minValidator, this.maxValidator])
		if (this.importeMuestra) {
			this.importeForm.setValue(this.importeMuestra)
		}
		this.importeForm.valueChanges.pipe(debounceTime(200)).subscribe((r) => {
			this.onChangeCallback(r)
			this.onChange.emit(r)
		})
	}
	onKeyDownEvent = (event) => {
		this.onKeyDown.emit(event)
		if (event.key == "Tab") {
			this.onBlur(event)
		}
	}
	validate(control: AbstractControl): ValidationErrors {
		control.setErrors(this.importeForm.errors)
		return control ? control.errors : null
	}
	getErrorMessage(control: AbstractControl) {
		if (control?.errors) {
			return this.errorHandler.getErrorMessage(control)
		}
	}
	onBlur(event) {
		if (this.importeForm.dirty) this.onModificado.emit(true)
		this.onChangeCallback(this.importeForm.value)
	}
	public onInputEvent(event) {
		this.importeForm.setValue(event.value)
		this.importeForm.markAsDirty()
		this.onInput.emit(this.importeForm.value)
	}

	public onFocus(event: any) {
		/* event.srcElement.select();
    event.stopPropagation();*/
	}
}
