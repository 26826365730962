import { Injectable } from '@angular/core';
import { Subject, filter } from 'rxjs';
import { ConfiguracionUsuarioService } from './configuracion-usuario.service';
import { environment } from 'src/environments/environment';

export interface AppConfig {
    inputStyle: string;
    colorScheme: string;
    componentTheme: string;
    ripple: boolean;
    menuMode: string;
    scale: number;
    menuTheme: string;
    topbarTheme: string;
    menuProfilePosition: string;
}

interface LayoutState {
    staticMenuMobileActive: boolean;
    overlayMenuActive: boolean;
    staticMenuDesktopInactive: boolean;
    configSidebarVisible: boolean;
    menuHoverActive: boolean;
    rightMenuActive: boolean;
    topbarMenuActive: boolean;
    menuProfileActive: boolean;
    revealMenuActive: boolean;
    anchored: boolean;
}

type LayoutConfig = {
    componentTheme: any;
    colorScheme: any;
};

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
   

    config: AppConfig = {
        ripple: true,
        inputStyle: 'outlined',
        menuMode:'static',
        colorScheme: 'light',
        componentTheme: 'iterpax',
        scale: 14,
        menuTheme: 'iterpax',
        topbarTheme: 'iterpax',
        menuProfilePosition: 'end'
    };

    state: LayoutState = {
        staticMenuDesktopInactive: false,
        overlayMenuActive: false,
        configSidebarVisible: false,
        staticMenuMobileActive: false,
        menuHoverActive: false,
        rightMenuActive: false,
        topbarMenuActive: false,
        menuProfileActive: false,
        revealMenuActive: false,
        anchored: false
    };
    constructor(private usuarioConfigService:ConfiguracionUsuarioService){
      
    }
    private configUpdate = new Subject<AppConfig>();

    private overlayOpen = new Subject<any>();

    private topbarMenuOpen = new Subject<any>();

    private menuProfileOpen = new Subject<any>();
    
    configUpdate$ = this.configUpdate.asObservable();

    overlayOpen$ = this.overlayOpen.asObservable();

    topbarMenuOpen$ = this.topbarMenuOpen.asObservable();

    menuProfileOpen$ = this.menuProfileOpen.asObservable();

    onMenuToggle() {
        if (this.isOverlay()) {
            this.state.overlayMenuActive = !this.state.overlayMenuActive;

            if (this.state.overlayMenuActive) {
                this.overlayOpen.next(null);
            }
        }

        if (this.isDesktop()) {
            this.state.staticMenuDesktopInactive = !this.state.staticMenuDesktopInactive;
        }
        else {
            this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;

            if (this.state.staticMenuMobileActive) {
                this.overlayOpen.next(null);
            }
        }
    }

    onTopbarMenuToggle() {
        this.state.topbarMenuActive = !this.state.topbarMenuActive;
        if (this.state.topbarMenuActive) {
            this.topbarMenuOpen.next(null);
        }
    }

    onOverlaySubmenuOpen() {
        this.overlayOpen.next(null);
    }

    showConfigSidebar() {
        this.state.configSidebarVisible = true;
    }

    isOverlay() {
        return this.config.menuMode === 'overlay';
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isSlim() {
        return this.config.menuMode === 'slim';
    }

    isHorizontal() {
        return this.config.menuMode === 'horizontal';
    }

    isMobile() {
        return !this.isDesktop();
    }

    onConfigUpdate() {
        this.configUpdate.next(this.config);
    }

    isRightMenuActive(): boolean {
        return this.state.rightMenuActive;
    }

    openRightSidebar(): void {
        this.state.rightMenuActive = true;
    }

    onMenuProfileToggle() {
        this.state.menuProfileActive = !this.state.menuProfileActive;
        if (this.state.menuProfileActive && this.isHorizontal() && this.isDesktop()) {
            this.menuProfileOpen.next(null);
        }
    }
    replaceThemeLink(href: string, id = 'theme-link'): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const styleElement = document.createElement('link');
            styleElement.rel = 'stylesheet';
            styleElement.href = href;
            styleElement.onload = () => resolve();
            styleElement.onerror = () => reject(new Error(`Error loading style: ${href}`));
            document.head.appendChild(styleElement);
          });
      }
      
   
    updateLayout(conf: LayoutConfig) :Promise<any>{
        if(this.config.colorScheme !== conf.colorScheme){            
            this.onColorSchemeChange(conf.colorScheme)
            this.config.colorScheme = conf.colorScheme;
        }       
        if(this.config.componentTheme !== conf.componentTheme){
            
            const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
           return this.replaceThemeLink(themeLink.getAttribute('href')!.replace(this.config.componentTheme, conf.componentTheme),'theme-link').then( r=>{
                this.onConfigUpdate();
                this.config.componentTheme = conf.componentTheme;
            })
        }      
        return Promise.resolve();
    }
    onColorSchemeChange(colorScheme: string) {
        const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
        const themeLinkHref = themeLink.getAttribute('href');
        const currentColorScheme = 'theme-' + this.config.colorScheme;
        const newColorScheme = 'theme-' + colorScheme;
        const newHref = themeLinkHref!.replace(currentColorScheme, newColorScheme);
        return this.replaceThemeLink(newHref, 'theme-link').then(()=>{
            this.config.colorScheme = colorScheme;
            if (colorScheme === 'dark') {
                this.config.menuTheme = 'dark';
            }
            this.onConfigUpdate();
        });
        
    }
}
