import { FamiliaProducto } from 'src/app/model/FamiliaProducto';
import { Producto } from 'src/app/model/Producto';
import { GrupoPax } from './GrupoPax';

import { ItemEncuestaActividades } from './ItemEncuestaActividades'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { GrupoReserva } from './GrupoReserva'
import { Reserva } from './Reserva';

export class RespuestaEncuestaActividades {
    public static COMPLETA = { codigo: 'COM', descripcion: 'Completa', color: '#1bb61b' }
    public static PENDIENTE = { codigo: 'PEN', descripcion: 'Pendiente', color: '#d6a765' }

    constructor(
        public id?: number,
        public fechaCompletado?: Date,
        public grupo?: GrupoPax,
        public reserva?: Reserva,
        public producto?: Producto,
        public familia?: FamiliaProducto,
        public items: ItemEncuestaActividades[] = [],
        public encuesta?: Descriptivo,
        public idGrupoReserva?: number,
        public estado?: any
    ) {}
    public static fromData(data: any): RespuestaEncuestaActividades {
        if (!data) return null
        const o: RespuestaEncuestaActividades = new RespuestaEncuestaActividades(
            data.id,
            data.fechaCompletado ? new Date(data.fechaCompletado) : null,
            GrupoPax.fromData(data.grupo),
            Reserva.fromData(data.reserva),
            Producto.fromData(data.producto),
            FamiliaProducto.fromData(data.familia),
            data.items ? data.items.map((c) => ItemEncuestaActividades.fromData(c)) : [],
            Descriptivo.fromData(data.encuesta)
        )

        if (o.fechaCompletado) {
            o.estado = this.COMPLETA
        } else {
            o.estado = this.PENDIENTE
        }
        return o
    }

    // public get idItem() {
    //     return this.grupoReserva.itemReserva?.id
    // }

    public get guias() {
        let guias = []

        this.grupo?.puestos
            ?.filter((puesto) => puesto?.tipoPuesto?.mostrarEncuesta)
            ?.forEach((p) => {
                guias.push(p?.personal?.descripcion)
            })
        return guias
    }

    public static get ESTADOS() {
        return [RespuestaEncuestaActividades.COMPLETA, RespuestaEncuestaActividades.PENDIENTE]
    }

    public get cantidadPax() {
        let c = 0
        this.grupo.gruposReserva
            .filter((grupo) => grupo.itemReserva.idReserva == this.reserva.id && grupo.itemReserva.producto.keyFamilia == this.producto.keyFamilia)
            ?.forEach((g) => {
                c += g.cantidadPax
            })
        return c
    }

    public get idReserva() {
        return this.reserva?.id
    }

    public get nombrePax() {
        return this.reserva?.nombrePax
    }

    public get descripcionProducto() {
        return this.familia? this.familia.descripcion : this.producto?.descripcion
    }

    get fechaActividad() {
        return this.grupo.fechaActividad
    }

}
