import { PuestoServicioReserva } from './../../model/PuestoServicioReserva';
import * as moment from 'moment';
import { EstadoDocumento } from 'src/app/model/EstadoDocumento';
import { Descriptivo } from './Descriptivo';
import { Filtro } from './Filtro';

export class FiltroProveedor extends Filtro {

    constructor(name: string,
        filterMap = {
            proveedor: "Proveedor",
            tipoProveedor: "Tipo Proveedor",
            puestos: "Puestos",
            impagas: "Impagas",
            deshabilitados: "Incluir Deshabilitados"

        },page=0,size=20,sortfield="id",order=1,defaults?:any) {
        super(name, filterMap,page,size,sortfield,order);
        this.guardable = true;
        if(defaults) this.setMultiple(defaults)
    }

    private _proveedor: Descriptivo;
    private _tipoProveedor : Descriptivo;
    

    
    public get tipoProveedor() : Descriptivo {
        return this._tipoProveedor;
    }
    public set tipoProveedor(v : Descriptivo) {

        if (v?.id && v?.id != this._tipoProveedor?.id) {
            this._tipoProveedor = v;
            this.update();
        }else if(!v && this._tipoProveedor?.id){
            this._tipoProveedor = null;
            this.update()            
        }
    }
    
    public get proveedor(): Descriptivo {
        return this._proveedor;
    }
    public set proveedor(v: Descriptivo) {
        this._proveedor = v;
        this.update();
    }

    
    private _puestos : Descriptivo[] = [];
    public get puestos() : Descriptivo[] {
        return this._puestos;
    }
    public set puestos(v : Descriptivo[]) {
        this._puestos = v;
        this.update();

    }
    
    private _impagas: boolean;
    public get impagas(): boolean {
        return this._impagas;
    }
    public set impagas(v: boolean) {
        this._impagas = v;
        this.update();
    }

    
    private _deshabilitados : boolean = false;
    public get deshabilitados() : boolean {
        return this._deshabilitados;
    }
    public set deshabilitados(v : boolean) {
        this._deshabilitados = v;
        this.update();

    }
    
    public clean() {
        let cleanVal = {
            proveedor: null,
            tipoProveedor: null,
            puestos: [],
            impagas:false,
            deshabilitados: false
        }
        this.setMultiple(cleanVal, false);
        super.clean(true)

    }

    public patchValue(v: any) {
        super.patchValue(v, false);
        this._proveedor = v.proveedor ? Descriptivo.fromData(v.proveedor) : null;
        this._tipoProveedor = v.tipoProveedor;
        this._puestos = v.puestos ? v.puestos.map(t => Descriptivo.fromData(t)) : [];
        this._impagas = v.impagas;
        this._deshabilitados = v.deshabilitados
        this.update();
    }
}