import { IOpcionChip } from "src/app/common-components/selector-multiple-chips/IOpcionChip"
import { StringUtils } from "./../utils/string-utils"
import { Descriptivo } from "./Descriptivo"
import { ResumenModel } from "./ResumenModel"
export class Parametrico extends Descriptivo implements ResumenModel, IOpcionChip {
	public static UNIDAD_PRODUCTO_ID = "unidadproducto"
	static NUEVO: Parametrico = new Parametrico(null, "NN", "Nuevo", true, true, 0, "fa fa-plus")
	public static comparador = (a: { peso?: number; descripcion?: string; id?: number }, b: { peso?: number; descripcion?: string; id?: number }) => {
		const pesoA = a.peso == undefined ? 9999 : a.peso
		const pesoB = b.peso == undefined ? 9999 : b.peso

		if (pesoA !== pesoB) {
			return pesoA - pesoB
		} else {
			const dA = a.descripcion || ""
			const dB = b.descripcion || ""
			const descripcionComparison = dA.localeCompare(dB)
			if (descripcionComparison !== 0) {
				return descripcionComparison
			} else {
				return a.id - b.id
			}
		}
	}
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public habilitado: boolean = true,
		public esSistema: boolean = false,
		public peso: number = 99,
		public icon?: string,
		public esDefault: boolean = false
	) {
		super(codigo, descripcion, id)
		this.searchStr = StringUtils.getSinTildes([this.id, this.codigo, this.descripcion].join("_"))
	}
	entityName(): string {
		return this.tipoParametro()
	}

	public tipoParametro(): string {
		return "parametrico"
	}
	public static fromData(data: any) {
		if (!data) return null

		let o: Parametrico = new Parametrico(data.id, data.codigo, data.descripcion, data.habilitado, data.esSistema, data.peso, data.icono, data.esBoolean)
		o.version = data.version
		return o
	}

	public get iconoClass() {
		return this.icon
	}
}
