import { Descriptivo } from "src/app/common/model/Descriptivo"

export class Provider extends Descriptivo {
	constructor(public codigo?: string, public descripcion: string = "", public id?: number, public version?: number, public esIterpax?: boolean) {
		super(codigo, descripcion, id, version)
		this.esIterpax = esIterpax
	}

	public static fromData(data: any): Provider {
		if (!data) return null
		const o: Provider = new Provider(data.codigo, data.descripcion, data.id, data.version, data.esIterpax)

		return o
	}
}
