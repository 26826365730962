import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ProveedoresAG } from "src/app/authguards/ProveedoresAG"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { AccionesTabla } from "src/app/common/model/AccionesTabla"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Filtro } from "src/app/common/model/Filtro"
import { FiltroProveedor } from "src/app/common/model/FiltroProveedor"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Proveedor } from "src/app/model/Proveedor"
import { TipoProveedor } from "src/app/model/TipoProveedor"
import { ProveedorService } from "src/app/services/proveedor.service"
import { TipoPuestoService } from "src/app/services/tipo-puesto.service"
import { AdministracionAuthGuard } from "./../../../authguards/AdministracionAuthGuard"
import { ModelListComponent } from "./../../../common/components/model-list/model-list.component"
import { FilterService } from "./../../../common/services/filter-service"
import { LoadingService } from "./../../../common/services/loading-data-service.service"
import { Totales } from "./../../../model/Totales"
import { TipoProveedorService } from "./../../../services/tipo-proveedor.service"

@Component({
	selector: "listado-proveedor",
	templateUrl: "listado-proveedor.component.html",
	styleUrls: ["listado-proveedor.component.less"]
})
export class ListadoProveedorComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: FiltroProveedor

	@ViewChild("contactos", { static: true })
	public contactosRef: TemplateRef<any>

	@ViewChild(ModelListComponent, { static: true })
	public listadoComponent: ModelListComponent
	@ViewChild("datosProveedorTemplate", { static: true })
	public datosProveedorRef: TemplateRef<any>
	public listado: Proveedor[] = []
	public totales: Totales
	public columns = []
	public titulo = "Proveedores"

	public acciones: AccionesTabla[] = []
	vendedor: boolean
	modelName: string
	proveedorVendedor: TipoProveedor
	puestos: Descriptivo[] = []
	constructor(
		messagesService: MessagesService,
		public router: Router,
		public administracionAg: AdministracionAuthGuard,
		public service: ProveedorService,
		public tpService: TipoProveedorService,
		private route: ActivatedRoute,
		public tipoPuestoService: TipoPuestoService,
		public filterService: FilterService,
		public proveedorAg: ProveedoresAG
	) {
		super(messagesService)
	}

	public getData = (f: Filtro, l: LoadingService) => {
		return this.service.getCabeceras(f, l)
	}
	public getCount = (f: FiltroProveedor, l: LoadingService) => {
		if (!this.proveedorAg.esVisible(this.usuario)) return new Totales()
		return this.service.getTotalesSaldo(f, l).then((t: Totales) => {
			this.totales = t
			return Promise.resolve(t.count)
		})
	}
	ngOnInit() {
		this.modelName = undefined
		this.tipoPuestoService.getDescriptivos().then((r) => (this.puestos = r))
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u.vendedor) {
					this.titulo = "Vendedores"
					this.modelName = "vendedor"
					this.vendedor = true
					this.tpService.getByCodigo("VE").then((tp) => {
						this.filtro = new FiltroProveedor("proveedor_filtros_vendedor_lst")
						this.filtro.guardable = false
						this.filtro.tipoProveedor = new Descriptivo(tp?.codigo, null, tp?.id)
					})
				} else {
					this.filtro = new FiltroProveedor("proveedor_filtros_lst")
					this.filtro.guardable = false
					this.filtro.tipoProveedor = null
					this.modelName = "proveedor"
				}
			})
		)

		this.columns = [
			{
				field: "id",
				header: "#",
				class: "id-column",
				role: "text-tabla",
				sortable: true
			},
			{
				field: "imagen",
				header: "",
				class: "profile-pic",
				role: "profile-tabla",
				sortable: false
			},
			{
				field: "nombre",
				header: "Nombre",
				role: "template",
				template: this.datosProveedorRef
			},
			{
				field: "tipoProveedor",
				header: "Tipo Proveedor",
				role: "descriptivo-tabla",
				sortable: true
			},
			{
				field: "numeroDocumento",
				header: "Número Doc.",
				role: "text-tabla",
				sortable: true
			},
			{
				field: "email",
				header: "Contactos",
				role: "template",
				esMobile: true,
				template: this.contactosRef
			},
			{
				field: "saldo",
				header: "Saldo",
				role: "money-tabla",
				class: "text-right",
				sortable: true,
				esMobile: true
			},
			{
				field: "habilitado",
				header: "Habilitado",
				class: "is-boolean",
				role: "habilitable-tabla",
				width: "5rem",
				command: (event, item) => {
					event?.stopPropagation()
					if (item?.habilitado)
						this.service.eliminar(item?.id).then((v) => {
							this.filtro.forceUpdate()
							this.success(this.translateService.get("DESHABILITADO_CORRECTAMENTE"))
						})
					else
						this.service.habilitar(item?.id).then((v) => {
							this.filtro.forceUpdate()
							this.success(this.translateService.get("HABILITADO_CORRECTAMENTE"))
						})
				},
				order: 99
			}
		]
		if (!this.proveedorAg.esVisible(this.usuario)) {
			this.columns = this.columns.filter((c) => c.field !== "saldo")
		}
		this.acciones = this.listadoComponent.defaultAcciones
		this.acciones[0].esVisible = (data) => {
			return this.proveedorAg.esVisible(this.usuario)
		}
		this.acciones[1].esVisible = (data) => {
			return this.proveedorAg.esVisible(this.usuario) && data.habilitado
		}
		this.acciones.push(
			new AccionesTabla(
				this.translateService.get("CARGAR_COMPROBANTE"),
				"fa fa-shopping-cart",
				"",
				(da, event) => {
					this.cargarFactura(da.id)
					if (event) event.stopPropagation()
				},
				(data) => {
					return this.proveedorAg.esVisible(this.usuario)
				}
			),
			new AccionesTabla(
				this.translateService.get("CARGAR_ORDEN_DE_PAGO"),
				"fa fa-money",
				"",
				(da, event) => {
					this.cargarOP(da.id)
					if (event) event.stopPropagation()
				},
				(data) => {
					return this.proveedorAg.esVisible(this.usuario)
				}
			),
			new AccionesTabla(
				this.translateService.get("Enviar confirmación de asignación"),
				"fa fa-envelope",
				"",
				(da, event) => {
					this.service.enviarResumen(da.id).then((r) => this.messagesService.success("RESUMEN_ENVIADO"))
					if (event) event.stopPropagation()
				},
				(data) => {
					return this.proveedorAg.esVisible(this.usuario)
				}
			),
			new AccionesTabla(
				this.translateService.get("HABILITAR"),
				"fa fa-check",
				"",
				(da, event) => {
					this.habilitar(da.id)
					if (event) event.stopPropagation()
				},
				(data) => {
					return this.proveedorAg.esVisible(this.usuario) && !data.habilitado
				}
			)
		)
	}
	ngOnDestroy() {}

	public cargarFactura(id) {
		this.router.navigate(["factura/nuevo"], { queryParams: { proveedorId: id } })
	}
	public cargarOP(id) {
		this.router.navigate(["orden-pago/nuevo"], { queryParams: { proveedorId: id } })
	}

	public habilitar(id) {
		return this.service.habilitar(id).then(() => {
			this.success(this.translateService.get("ITEM_HABILITADO"))
			this.filtro.forceUpdate()
		})
	}
}
