import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router"
import * as moment from "moment"
import { MenuItem } from "primeng/api"
import { Subscription } from "rxjs"
import { IngresosEgresosAG } from "src/app/authguards/MovimientoCajaAG"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { FiltroMovimiento } from "src/app/common/model/FiltroMovimiento"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { UsuarioService } from "src/app/common/services/usuario.service"
import { ArqueoCaja } from "src/app/model/ArqueoCaja"
import { CambioSaldoCaja } from "src/app/model/CambioSaldoCaja"
import { MovimientoCaja } from "src/app/model/MovimientoCaja"
import { CajaService } from "src/app/services/caja.service"
import { FormaPagoService } from "src/app/services/forma-pago.service"
import { MovimientoCajaService } from "src/app/services/movimiento-caja.service"
import { TipoMovimientoService } from "src/app/services/tipo-movimiento.service"
import { TraspasoDeCajaService } from "src/app/services/traspaso-de-caja.service"
import { BreadcrumbService } from "./../../../breadcrumb.service"
import { AccionesTabla } from "./../../../common/model/AccionesTabla"
import { FilterService } from "./../../../common/services/filter-service"
import { Caja } from "./../../../model/Caja"
import { CambioEstadoCaja } from "./../../../model/CambioEstadoCaja"
import { TraspasoDeCaja } from "./../../../model/TraspasoDeCaja"
import { BilleteService } from "./../../../services/billete.service"
import { SaldosTotales } from "./../../../services/movimiento-caja.service"

@Component({
	selector: "listado-movimiento-caja",
	templateUrl: "listado-movimiento-caja.component.html",
	styleUrls: ["listado-movimiento-caja.component.less"]
})
export class ListadoMovimientoCajaComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: FiltroMovimiento
	public listado: MovimientoCaja[] = []
	public verTraspasoCaja: boolean = false
	public displayFilterBar: boolean = false
	public columns = [
		{
			field: "id",
			header: "#",
			class: "id-column",
			role: "text-tabla",
			sortable: true,
			order: 1
		},
		{
			field: "imagenUrl",
			header: "Forma de Pago",
			class: "profile-pic",
			role: "profile-tabla",
			sortable: true,
			order: 3,
			width: "4em"
		},
		{
			field: "fecha",
			header: "Fecha",
			class: "date-tabla",
			role: "date-tabla",
			sortable: false,
			esMobile: true,
			order: 40,
			width: "8em"
		},
		{
			field: "detalle",
			header: "Detalle",
			class: "text-tabla",
			role: "text-tabla",
			sortable: false,
			esMobile: true,
			order: 40
		},

		{
			field: "referencia",
			header: "Referencia",
			class: "link-tabla text-center",
			role: "link-tabla",
			//"tooltip":"N° ",
			navegar: (r) => {
				this.navegar(r)
			},
			href: "href",
			esMobile: true,
			sortable: true,
			width: "6em",
			order: 40
		},
		{
			field: "responsable",
			header: "Responsable",
			class: "descriptivo-tabla text-center",
			role: "descriptivo-tabla",
			sortable: true,
			esMobile: true,
			width: "16em",
			order: 40
		},
		{
			field: "salida",
			header: "Salida",
			class: "money-tabla",
			role: "money-tabla",
			sortable: false,
			esMobile: true,
			width: "12em",
			order: 40
		},
		{
			field: "entrada",
			header: "Entrada",
			class: "money-tabla",
			role: "money-tabla",
			sortable: false,
			esMobile: true,
			width: "12em",
			order: 40
		},
		{
			field: "saldo",
			header: "Saldo en Caja",
			class: "money-tabla",
			role: "money-tabla",
			sortable: false,
			width: "12em",
			order: 40
		}
	]

	public cajas: Caja[] = []
	public caja: Caja
	public tiposMovimiento: Descriptivo[] = []
	public responsables: Descriptivo[] = []

	public saldos: SaldosTotales

	public pendientes: TraspasoDeCaja[] = []

	public itemSeleccionado: TraspasoDeCaja

	public totalSalida: number = 0
	public totalEntrada: number = 0
	public totalSaldo: number = 0
	public accionesModelList: AccionesTabla[] = []
	rechazarMovimiento: boolean = false

	constructor(
		messagesService: MessagesService,
		public service: MovimientoCajaService,
		private router: Router,
		private billeteService: BilleteService,
		private aRoute: ActivatedRoute,
		private cajaService: CajaService,
		private tipoService: TipoMovimientoService,
		private respService: UsuarioService,
		public formasPagoService: FormaPagoService,
		private breadcrumbService: BreadcrumbService,
		private traspasoDeCajaService: TraspasoDeCajaService,
		private filterService: FilterService,
		private ingresosEgresosAg: IngresosEgresosAG
	) {
		super(messagesService)
		this.breadcrumbService.setItems([{ label: "Finanzas" }])
	}
	public editandoMovimiento: boolean = false
	public acciones: MenuItem[] = []
	public aplicarSeleccion(caja: Caja) {
		this.caja = caja
		this._cajaSeleccionada = Descriptivo.fromData(caja)
		this.filtro.caja = this._cajaSeleccionada
		this.buildMenu()
		this.op.hide()
		this.getTraspasos()
	}
	private _cajaSeleccionada: Descriptivo
	public get cajaSeleccionada(): Descriptivo {
		if (!this._cajaSeleccionada) {
			this._cajaSeleccionada = this.filtro.caja
		}
		return this._cajaSeleccionada
	}
	public set cajaSeleccionada(v: Descriptivo) {
		this._cajaSeleccionada = v
		this.filtro.caja = v
		this.cajaService.getById(v.id).then((r) => {
			this.aplicarSeleccion(r)
		})
	}
	@ViewChild("op", { static: true }) op
	private routeSub: Subscription
	public verCajas(event) {
		if (this.cajas.length > 1) {
			this.op.toggle(event)
		}
	}
	public itemEditado: ArqueoCaja
	public buildMenu() {
		this.acciones = []
		if (this.caja) {
			this.acciones.push(
				new AccionesTabla("Arqueo", "fa fa-balance-scale", "", (item) => {
					this.arqueo()
				})
			)

			this.acciones.push(
				new AccionesTabla("Traspaso", "fa fa-exchange", "", (item) => {
					this.nuevoTraspaso()
				})
			)
			if (this.ingresosEgresosAg.esVisible(this.usuario)) {
				this.acciones.push(
					new AccionesTabla("Ingreso", "fa fa-sign-in", "", (item) => {
						this.cambiarSaldo(false)
					})
				)
				this.acciones.push(
					new AccionesTabla("Retiro", "fa fa-sign-out", "", (item) => {
						this.cambiarSaldo(true)
					})
				)
			}
		}
	}
	arqueo = () => {
		if (!this.caja.habilitada) return this.error("La caja se encuentra deshabilitada")
		this.arqueando = true
		this.itemEditado = new ArqueoCaja(null, new Date(), null, this.usuario, this.caja, 0, 0, null)
		this.billeteService.iniciarBilletes().then((r) => {
			this.itemEditado.valores = r
			this.editandoMovimiento = true
		})
	}

	public traspaso: TraspasoDeCaja
	public arqueando: boolean = false
	public cambioSaldo: CambioSaldoCaja
	public onCancelar(event) {
		this.itemEditado = null
		this.editandoMovimiento = false
		this.traspaso = null
		this.cambioSaldo = null
	}
	public refresh(event: CambioEstadoCaja) {
		this.onCancelar(event)
		setTimeout(() => {
			this.cajaSeleccionada = event.caja
			this.filtro.forceUpdate()
		}, 200)
		this.update()
	}
	apertura = () => {
		if (!this.caja.habilitada) return this.error("La caja se encuentra deshabilitada")
		if (!this.caja || !this.caja.estaCerrada) return this.error("La caja debe estar cerrada")
		this.itemEditado = new CambioEstadoCaja(null, new Date(), null, this.usuario, this.caja, 0, 0, null, false)
		this.billeteService.iniciarBilletes().then((r) => {
			this.itemEditado.valores = r
			this.editandoMovimiento = true
		})
	}

	cierre = () => {
		if (!this.caja.habilitada) return this.error("La caja se encuentra deshabilitada")
		if (!this.caja || this.caja.estaCerrada) return this.error("La caja debe estar abierta")
		this.itemEditado = new CambioEstadoCaja(null, new Date(), null, this.usuario, this.caja, 0, 0, null, true)
		this.billeteService.iniciarBilletes().then((r) => {
			this.itemEditado.valores = r
			this.editandoMovimiento = true
		})
	}
	nuevoTraspaso = () => {
		if (!this.caja) return this.error("Seleccione una caja")
		if (!this.caja.habilitada) return this.error("La caja se encuentra deshabilitada")
		this.traspaso = new TraspasoDeCaja(null, new Date(), null, this.usuario, this.caja)
		this.editandoMovimiento = true
	}
	cambiarSaldo = (esRetiro: boolean) => {
		if (!this.caja || !this.ingresosEgresosAg.esVisible(this.usuario))
			return this.error("solo un administrador puede realizar esta operación o debe tener el permiso Ingresos y Egresos de caja")
		if (!this.caja.habilitada) return this.error("La caja se encuentra deshabilitada")
		this.cambioSaldo = new CambioSaldoCaja(null, new Date(), null, this.usuario, this.caja, 0, null, esRetiro)
		this.editandoMovimiento = true
	}

	ngOnInit() {
		this.filtro = this.filterService.getFilter(
			"movimiento-caja_filtros",
			new FiltroMovimiento("movimiento-caja_filtros", {
				fechaDesde: moment().startOf("month").subtract(1, "month").toDate(),
				fechaHasta: moment().endOf("month")
			})
		)

		this.cajaService.getAutorizados().then((cs) => {
			this.cajas = cs
			if (!this.filtro.caja || !this.cajas.some((c) => c.id == this.filtro.caja.id)) {
				this.cleanFiltro()
			}
			if (this.cajas.length == 1) {
				this.aplicarSeleccion(this.cajas[0])
			} else {
				if (this.filtro.caja) {
					this.aplicarSeleccion(this.cajas.find((c) => c.id == this.filtro.caja.id))
				}
			}
		})
		this.tipoService.getDescriptivos().then((ts) => (this.tiposMovimiento = ts))
		this.respService.getDescriptivos().then((us) => (this.responsables = us))
		this.routeSub = this.aRoute.queryParams.subscribe((params) => {
			let id: number = <number>params["id"]
			if (id) {
				setTimeout(() => {
					this.cajaService.getById(id).then((r) => {
						this.aplicarSeleccion(r)
						//this.filtro.forceUpdate();
					})
				}, 100)
			}
		})
		this.getTraspasos()
		this.buildMenu()
	}
	getData = (filtro, loading): Promise<MovimientoCaja[]> => {
		if (!this.filtro.caja) {
			return Promise.resolve([])
		}
		return this.service.getAll(filtro, loading).then((r) => {
			this.service.getSaldos(this.filtro).then((s) => (this.saldos = s))
			this.totalEntrada = this.totalSaldo = this.totalSalida = 0
			r.forEach((s) => {
				this.totalEntrada += s.entrada
				this.totalSalida += s.salida
				this.totalSaldo += s.entrada - s.salida
			})
			return r
		})
	}
	ngOnDestroy() {}
	onDialogHide(event, dialog) {
		this.arqueando = false
		this.itemEditado = null
		this.traspaso = null
		this.cambioSaldo = null
	}
	cleanFiltro() {
		if (this.cajas.length > 0) {
			this.filtro.clean()
			this.filtro.caja = Descriptivo.fromData(this.cajas[0])
		}
	}

	navegar = (movimiento: MovimientoCaja) => {
		let query: NavigationExtras = {
			queryParams: {
				id: movimiento.referencia
			}
		}
		switch (movimiento.tipoMovimiento.codigo) {
			case "PO":
				this.router.navigate(["orden-pago/vista"], query)
				break
			case "PC":
				this.router.navigate(["cobranza/vista"], query)
				break
			case "TC":
				this.router.navigate(["traspaso-de-caja/vista"], query)
				break
			case "DEC":
				this.router.navigate(["cambio-estado-caja/vista"], query)
				break
			case "CS":
				this.router.navigate(["cambio-saldo-caja/vista"], query)
				break
			default:
				console.log("navegacion no disponible para " + movimiento.tipoMovimiento.descripcion + " " + movimiento.referencia)
				break
		}
	}
	public showFilterbar(event) {
		event?.stopPropagation && event.stopPropagation()
		this.displayFilterBar = true
	}

	aprobar(item: TraspasoDeCaja) {
		this.traspasoDeCajaService.aprobar(item).then((r) => {
			if (r) {
				this.messagesService.success("Aprobado correctamente")
				this.update()
			}
		})
	}

	abrirRechazar(item) {
		this.itemSeleccionado = item
		this.rechazarMovimiento = true
	}

	rechazar() {
		this.traspasoDeCajaService.rechazar(this.itemSeleccionado).then((r) => {
			if (r) {
				this.messagesService.success("Rechazado correctamente")
				this.filtro.forceUpdate()
				this.getTraspasos()
				this.rechazarMovimiento = false
				this.itemSeleccionado = null
			}
		})
	}

	cancelarRechazo() {
		this.rechazarMovimiento = false
		this.itemSeleccionado = null
	}

	getTraspasos() {
		if (this.caja) {
			let f = new FiltroMovimiento("traspasos-pendientes")
			f.caja = this.caja
			this.traspasoDeCajaService.getAllPendientes(f).then((t) => (this.pendientes = [...t]))
		}
	}

	editar(item) {
		this.traspaso = item
		this.editandoMovimiento = true
	}

	eliminar(item) {
		this.traspasoDeCajaService.eliminar(item.id).then((r) => {
			this.messagesService.success("Eliminado correctamente")
			this.update()
		})
	}

	update() {
		this.filtro.forceUpdate()
		this.getTraspasos()
	}
}
