import { formatCurrency } from "@angular/common"
import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import ChartDataLabels from "chartjs-plugin-datalabels"
import * as moment from "moment"
import { debounceTime, filter } from "rxjs/operators"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { ExcelDownloader } from "src/app/common/utils/excelDownloader"
import { Moneda } from "src/app/model/Moneda"
import { CategoriaProductoService } from "src/app/services/categoria-producto.service"
import { MonedaService } from "src/app/services/moneda.service"
import { ProductoService } from "src/app/services/producto.service"
import { FiltroRanking } from "../producto/FiltroRanking"
import { COLORS, MonedaEntorno } from "./../../app.module"
import { commonAnimations } from "./../../common-animations"
import { SessionComponent } from "./../../common/components/session-component.component"
import { MessagesService } from "./../../common/services/messages-data-service.service"
import { ProductoRanking } from "./../../model/Producto"
import { ClienteService } from "./../../services/cliente.service"

@Component({
	selector: "ranking-producto",
	templateUrl: "./rankin-producto.component.html",
	styleUrls: ["./rankin-producto.component.less"],
	animations: [commonAnimations]
})
export class RankinProductoComponent extends SessionComponent implements OnInit {
	private _filtro: FiltroRanking
	public plugins = ChartDataLabels
	cols: { field: string; header: string }[]
	agruparOptions: any[] = []

	private _moneda: Moneda = MonedaEntorno() || Moneda.PESOS
	public get moneda(): Moneda {
		return this._moneda
	}
	@Input()
	public set moneda(v: Moneda) {
		this._moneda = v
		this.actualizarRanking()
	}

	public get filtro(): FiltroRanking {
		return this._filtro
	}
	@Input()
	public set filtro(v: FiltroRanking) {
		this._filtro = v
	}
	rowGroupMetadata: any

	public productos: ProductoRanking[]

	public dataPie: any

	public ranking = []
	public ranking2 = []

	@Input()
	public cliente: Descriptivo

	@Input()
	public showCharts: boolean = true

	@Input()
	public isFilterFixed: boolean = false

	public maxGrafico = 31

	private _mostrar: string = "importe"
	public get mostrar(): string {
		return this._mostrar
	}
	public set mostrar(v: string) {
		this._mostrar = v
		this.actualizarRanking()
	}

	private _agrupar2: Descriptivo
	public get agrupar2(): Descriptivo {
		return this._agrupar2
	}
	public set agrupar2(v: Descriptivo) {
		if (!v) {
			this._agrupar2 = v
			this.actualizarRanking()
		} else {
			if (v.codigo && v.codigo == this.agrupar.codigo) {
				this.error("Seleccionar una categoría distinta de la primera")
			} else {
				this._agrupar2 = v
				this.actualizarRanking()
			}
		}
	}
	formatterPie = (value, ctx) => {
		let percentage = value.toFixed(2) + "%"
		return percentage
	}
	private _agrupar = new Descriptivo("codigo", "Producto")

	public get agrupar(): Descriptivo {
		return this._agrupar
	}
	public set agrupar(v: Descriptivo) {
		this._agrupar = v
		this.actualizarRanking()
	}

	private _cantidad: number = 10
	public get cantidad(): number {
		return this._cantidad
	}
	public set cantidad(v: number) {
		if (!v) v = 0
		this._cantidad = v
		this.actualizarRanking()
	}

	public dataBars: any
	public optionsPie = {
		responsive: true,

		animation: {
			animateScale: true,
			animateRotate: true
		},
		plugins: {
			legend: {
				display: false
			},
			datalabels: {
				display: "auto",
				formatter: this.formatterPie,
				color: "#fff"
			}
		}
	}
	public optionsBar = {
		scales: {
			x: {
				ticks: {
					display: false
				}
			}
		},
		responsive: true,

		animation: {
			animateScale: true,
			animateRotate: true
		},
		plugins: {
			legend: {
				display: false
			},
			datalabels: {
				display: "auto",
				align: "end",
				offset: "25",
				anchor: "end",
				formatter: (value, ctx) => {
					let percentage =
						this.mostrar == "importe"
							? formatCurrency(
									value,
									this.getUsersLocale("es-ar"),
									this.configuracionEntorno?.moneda?.simbolo || "$",
									this.configuracionEntorno?.moneda?.codigo || "USD"
							  )
							: value
					return percentage
				}
			}
		}
	}
	constructor(
		private productoService: ProductoService,
		messagesService: MessagesService,
		public clienteService: ClienteService,
		private router: Router,
		public excelService: ExcelDownloader,
		public monedaService: MonedaService,
		public route: ActivatedRoute,
		public catProductoService: CategoriaProductoService
	) {
		super(messagesService)
	}
	public total: number = 0
	public get colors() {
		return COLORS
	}
	ngOnInit() {
		this.subs.push(
			this.route.queryParams.subscribe((params) => {
				const desde = params["desde"]
				const hasta = params["hasta"]
				this.filtro = this.filtro ? this.filtro : new FiltroRanking("filtro_ranking", 0, 99999999)
				if (desde) {
					this.filtro.fechaFacturacionDesde = moment(desde, "DDMMYYYY").toDate()
				}
				if (hasta) {
					this.filtro.fechaFacturacionHasta = moment(hasta, "DDMMYYYY").toDate()
				}
				this.filtro.excluirRapidos = false
				this.agruparOptions = [
					new Descriptivo("codigo", "Producto"),
					new Descriptivo("idCliente", "Cliente"),
					new Descriptivo("fechaFacturacion", "Fecha Facturación"),
					new Descriptivo("fechaActividad", "Fecha Actividad"),
					new Descriptivo("idCanalVenta", "Canal de Venta"),
					new Descriptivo("tipoCliente", "Tipo de Cliente"),
					new Descriptivo("cantidad", "Cantidad de paxs"),
					new Descriptivo("codigoNacionalidad", "Nacionalidad")
				]
				this.filtro.setMultiple({
					excluirRapidos: false,
					fechaFacturacionDesde: this.filtro.fechaFacturacionDesde || moment().startOf("month").subtract(1, "month").toDate()
				})

				this.filtro.dataChange
					.pipe(
						filter((f) => f != undefined),
						debounceTime(400)
					)
					.subscribe((f: any) => {
						this.productoService.getRanking(f).then((r) => {
							this.productos = r
							this.cols = [
								{ field: "id", header: "Id producto" },
								{ field: "codigo", header: "Codigo producto" },
								{ field: "descripcion", header: "Descripcion producto" },
								{ field: "cantidad", header: "Cantidad" },
								{ field: "moneda", header: "Moneda" },
								{ field: "precioPerCapita", header: "Precio per capita" },
								{ field: "ajuste", header: "Ajuste" },
								{ field: "importeOriginalStr", header: "Importe en moneda original" },
								{ field: "importeStr", header: "Importe en Pesos" },
								{ field: "fechaFacturacion", header: "Fecha de Facturacion" },
								{ field: "comprobanteId", header: "Comprobante ID" },
								{ field: "fechaActividad", header: "Fecha de Actividad" },
								{ field: "idCliente", header: "Id Cliente" },
								{ field: "nombreCliente", header: "Nombre Cliente" },
								{ field: "apellidoCliente", header: "Apellido Cliente" },
								{ field: "razonSocialCliente", header: "Razon Social Cliente" },
								{ field: "esPropioStr", header: "Propio" },
								{ field: "tipoCliente", header: "Tipo de Cliente" },
								{ field: "nombreNacionalidad", header: "Nacionalidad" }
							]
							this.productos.forEach((p) => {
								p.fechaActividad = moment(p.fechaActividad).startOf("day").format("DD/MM/YYYY")
								p.fechaFacturacion = moment(p.fechaFacturacion).startOf("day").format("DD/MM/YYYY")
							})
							this.actualizarRanking()
						})
					})
				setTimeout(() => {
					if (!this.productos?.length) {
						this.filtro.forceUpdate()
					}
				}, 1000)
			})
		)
	}

	public goToProducto(event, id) {
		event.stopPropagation()
		this.router.navigate(["producto/vista"], { queryParams: { id: id } })
	}

	public goToCliente(event, id) {
		event.stopPropagation()
		this.router.navigate(["cliente/vista"], { queryParams: { id: id } })
	}

	public actualizarRanking() {
		var labels = []
		var vals = []
		this.total = 0
		let prod
		if (this.mostrar == "importe") {
			prod = [...this.actualizarListadoPorMoneda(this.productos, this.moneda.codigo == MonedaEntorno().codigo)]
		} else prod = this.productos

		if (this.agrupar && !this.agrupar2) {
			this.ranking = this.groupBy(prod, this.agrupar, this.mostrar)

			this.ranking.forEach((p) => {
				labels.push(p.label)
				vals.push(p.value)
				this.total += p.value
			})
			this.dataPie = {
				labels: labels,
				datasets: [
					{
						data: vals.map((v) => Math.round((v * 100) / this.total)),
						backgroundColor: COLORS
					}
				]
			}
			this.dataBars = {
				labels: labels,
				datasets: [
					{
						label: "Ventas",
						data: vals,
						backgroundColor: COLORS
					}
				]
			}
		} else {
			this.ranking2 = this.groupBy2(prod, this.agrupar, this.agrupar2, this.mostrar)
			this.updateRowGroupMetaData()
		}
	}
	actualizarListadoPorMoneda(productos: ProductoRanking[], monedaLocal = false): ProductoRanking[] {
		if (!productos || !productos.length) return []

		if (!monedaLocal) {
			productos = productos.filter((p) => p.moneda == this.moneda.codigo)
		} else {
		}

		productos.forEach((p) => {
			p.importe = (p.precioPerCapita * p.cantidad + (p.precioPerCapita * p.cantidad * p.ajuste) / 100) * (monedaLocal ? p.cotizacionSafe : 1)
			let ajusteTotal = 0
			if (p.ajusteTotal) {
				ajusteTotal = p.tipoAjuste == "suma" ? p.ajusteTotal : (p.importe * p.ajusteTotal) / 100
			}
			p.importe = p.importe + ajusteTotal
		})
		return productos
	}

	groupBy(list, key, valorAmostrar) {
		const ranking = {}

		if (key.codigo == "idCanalVenta" && valorAmostrar == "cantidad") {
			valorAmostrar = "cantidadPaxReserva"
		}

		if (valorAmostrar == "cantidadPaxReserva") {
			const ids = list.map((o) => o.reservaId)
			list = list.filter(({ reservaId }, index) => !ids.includes(reservaId, index + 1))
		}
		list.forEach((item) => {
			if (!ranking[item[key.codigo]]) {
				const r = {
					value: item[valorAmostrar],
					label: this.getDescripcion(item, key.codigo)
				}
				ranking[item[key.codigo]] = r
			} else {
				ranking[item[key.codigo]].value += item[valorAmostrar]
			}
		})
		const keys = Object.keys(ranking)
		let result = []

		keys.forEach((k) => {
			result.push({ key: k, value: ranking[k].value, label: ranking[k].label })
		})

		result.sort(function (a, b) {
			return b.value - a.value
		})

		if (this.cantidad < result.length) {
			result = result.slice(0, this.cantidad)
		}
		return result
	}
	groupBy2(list, key, key2, valorAmostrar) {
		const ranking = {}
		const totales = {}

		if ((key.codigo == "idCanalVenta" || key2.codigo == "idCanalVenta") && valorAmostrar == "cantidad") {
			valorAmostrar = "cantidadPaxReserva"
		}
		if (valorAmostrar == "cantidadPaxReserva") {
			const ids = list.map((o) => o.reservaId)
			list = list.filter(({ reservaId }, index) => !ids.includes(reservaId, index + 1))
		}

		list.forEach((item) => {
			let r = {}
			if (!ranking[item[key.codigo]]) {
				r[item[key2.codigo]] = {
					value: item[valorAmostrar],
					label: this.getDescripcion(item, key2.codigo),
					labelHeader: this.getDescripcion(item, key.codigo)
				}
				ranking[item[key.codigo]] = { ...r }
			} else {
				if (ranking[item[key.codigo]][item[key2.codigo]]) {
					ranking[item[key.codigo]][item[key2.codigo]].value += item[valorAmostrar]
				} else {
					r[item[key2.codigo]] = {
						value: item[valorAmostrar],
						label: this.getDescripcion(item, key2.codigo),
						labelHeader: this.getDescripcion(item, key.codigo)
					}
					ranking[item[key.codigo]] = { ...ranking[item[key.codigo]], ...r }
				}
			}
			if (!totales[item[key.codigo]]) {
				totales[item[key.codigo]] = {
					value: item[valorAmostrar]
				}
			} else {
				totales[item[key.codigo]].value += item[valorAmostrar]
			}
		})
		const keys = Object.keys(ranking)
		let result = []

		keys.forEach((k) => {
			const keys2 = Object.keys(ranking[k])
			keys2.forEach((k2) => {
				result.push({
					key: k,
					labelHeader: ranking[k][k2].labelHeader,
					totalHeader: totales[k].value,
					label: ranking[k][k2].label,
					value: ranking[k][k2].value
				})
			})
		})

		result.sort(function (a, b) {
			if (a.totalHeader > b.totalHeader) {
				return -1
			}
			if (b.totalHeader > a.totalHeader) {
				return 1
			}

			if (a.totalHeader == b.totalHeader) {
				if (a.value > b.value) {
					return -1
				}
				if (b.value >= a.value) {
					return 1
				}
			}
		})

		if (this.cantidad < result.length) {
			let countSlice = 0
			let previous
			let index = 0
			result.forEach((r) => {
				if (countSlice <= this.cantidad) {
					index++
					if (previous != r.key) {
						countSlice++
					}
					previous = r.key
				}
			})
			result = result.slice(0, index - 1)
		}

		return result
	}
	exportToExcel() {
		this.excelService.downloadData(this.productos, "ranking_venta_" + moment().format("YYYYMMDD"), {}, this.cols)
	}
	getDescripcion(item, key) {
		let descripcion
		switch (key) {
			case "codigo":
				descripcion = this.getDescripcionProducto
				break
			case "idCliente":
				descripcion = this.getDescripcionCliente
				break
			case "fechaFacturacion":
				descripcion = this.getDescripcionFechaFacturacion
				break
			case "fechaActividad":
				descripcion = this.getDescripcionFechaActividad
				break
			case "idCanalVenta":
				descripcion = this.getDescripcionCanalVenta
				break
			case "tipoCliente":
				descripcion = this.getDescripcionTipoCliente
			case "cantidad":
				descripcion = (data) => data.cantidad
			case "codigoNacionalidad":
				descripcion = (data) => data.nombreNacionalidad
			default:
				break
		}

		return descripcion(item)
	}

	getDescripcionCliente(data) {
		if (data.nombreCliente) {
			return data.nombreCliente
		} else {
			return data.razonSocialCliente
		}
	}
	getDescripcionFechaActividad(data) {
		return data.fechaActividad
	}

	getDescripcionCanalVenta(data) {
		return data.descripcionCanalVenta
	}

	getDescripcionTipoCliente(data) {
		return data.tipoCliente
	}

	getDescripcionFechaFacturacion(data) {
		return data.fechaFacturacion
	}

	getDescripcionProducto(data) {
		return data.descripcion
	}

	goToItem(event, item) {
		let id
		switch (this.agrupar.codigo) {
			case "codigo":
				id = this.productos.find((p) => p.codigo == item.key).id
				if (id) return this.goToProducto(event, id)

			case "idCliente":
				return this.goToCliente(event, item.key)
			default:
				break
		}
	}

	updateRowGroupMetaData() {
		this.rowGroupMetadata = {}
		if (this.ranking2) {
			for (let i = 0; i < this.ranking2.length; i++) {
				let rowData = this.ranking2[i]
				let key = `${rowData.key}-${rowData.totalHeader}`
				if (i == 0) {
					this.rowGroupMetadata[key] = { index: 0, size: 1 }
				} else {
					let previousRowData = this.ranking2[i - 1]
					let previousRowGroup = `${previousRowData.key}-${previousRowData.totalHeader}`
					if (key === previousRowGroup) this.rowGroupMetadata[key].size++
					else this.rowGroupMetadata[key] = { index: i, size: 1 }
				}
			}
		}
	}

	getTotal(key, ranking): number {
		let v = 0

		this.ranking2.filter((r) => r.key == key).forEach((i) => (v += i.value))

		return v
	}
}
