import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { ControlValueAccessor } from "@angular/forms"
import { DomSanitizer } from "@angular/platform-browser"
import { commonAnimations } from "src/app/common-animations"
import { TranslateService } from "./../../services/translate.service"

@Component({
	selector: "profile-badge",
	templateUrl: "profile-badge.component.html",
	styleUrls: ["profile-badge.component.less"],
	animations: [commonAnimations]
})
export class ProfileBadgeComponent implements OnInit, ControlValueAccessor {
	constructor(private translateService: TranslateService, private sanitizer: DomSanitizer) {
		var sub = this.translateService.upateData.subscribe((r) => {
			if (!this.defaultImage) {
				this.defaultImage = this.translateService.get("DEFAULT_PROFILE")
				sub.unsubscribe()
			}
		})
	}

	@Input()
	public selected: boolean = false

	public onLoad(e, u: string) {
		this._url = u
	}

	private _url: string
	public get url(): string {
		return this._url
	}
	@Input()
	public set url(v: string) {
		if (v && v != this._url) {
			this._url = v
		}
	}

	@Input()
	public class: string = ""

	@Input()
	public seleccionable: boolean = false

	public fileUpload: File

	@Output()
	public onFileSelected: EventEmitter<File> = new EventEmitter<File>()
	@Input()
	public defaultImage: string = "assets/images/default_image.png"
	private safeUrl: string
	@Input()
	public disabled: boolean = false

	public get readonly(): boolean {
		return this.disabled
	}
	@Input()
	public set readonly(v: boolean) {
		this.disabled = v
	}

	@Input()
	public tooltip: string
	ngOnInit() {}

	@ViewChild("file", { static: true }) public file: ElementRef
	@ViewChild("inputFile", { static: false }) public inputFile: ElementRef
	private onChangeCallback: (_: any) => void = () => {}
	private onTouchedCallback: (_: any) => void = () => {}
	writeValue(obj: any): void {
		this.fileUpload = obj
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled
	}

	public get profilePic() {
		return this.foto ? this.foto : this.defaultImage
	}
	public get foto() {
		return this.safeUrl ? this.safeUrl : this.url
	}
	handleFileInput(files: FileList) {
		this.fileUpload = files.item(0)
		this.onFileSelected.emit(this.fileUpload)
		this.onChangeCallback(this.fileUpload)
	}

	public openInput() {
		this.inputFile?.nativeElement && this.inputFile.nativeElement.click()
	}
}
