import { Component, Input, OnInit } from "@angular/core"
import { HelpService } from "../../../services/help.service"

@Component({
	selector: "help-link",
	templateUrl: "./help-link.component.html",
	styleUrls: ["./help-link.component.less"]
})
export class HelpLinkComponent implements OnInit {
	@Input()
	public linkFinal: string = ""
	@Input()
	public textoLink: string = ""

	constructor(public helpService: HelpService) {}

	ngOnInit(): void {
		this.linkFinal = "/help/" + this.linkFinal
	}
}
