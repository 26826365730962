import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import moment from "moment"
import { ConfirmationService } from "primeng/api"
import { Subscription } from "rxjs"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Filtro } from "src/app/common/model/Filtro"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Idioma } from "src/app/model/Idioma"
import { Insumo } from "src/app/model/Insumo"
import { ConfiguracionPaquete } from "src/app/model/Paquete"
import { Producto } from "src/app/model/Producto"
import { ProductoAsociado } from "src/app/model/ProductoAsociado"
import { ProductoInsumo } from "src/app/model/ProductoInsumo"
import { PuestoInsumo } from "src/app/model/PuestoInsumo"
import { SubcategoriaImponible } from "src/app/model/SubcategoriaImponible"
import { SubcategoriaImponibleItem } from "src/app/model/SubcategoriaImponibleItem"
import { TipoNotificacion } from "src/app/model/TipoNotificacion"
import { TipoProducto } from "src/app/model/TipoProducto"
import { EncuestaActividadesService } from "src/app/services/encuesta-actividades.service"
import { FamiliaProductoService } from "src/app/services/familia-producto.service"
import { ProductoService } from "src/app/services/producto.service"
import { RegionService } from "src/app/services/region.service"
import { TipoVarianteService } from "src/app/services/tipoVariante.service"
import { SessionComponent } from "./../../../common/components/session-component.component"
import { ProfilePic } from "./../../../common/model/ProfilePic"
import { CronogramaProducto } from "./../../../model/CronogramaProducto"
import { ProductoFactory } from "./../../../model/ProductoFactory"
import { PuestoACubrir } from "./../../../model/PuestoACubrir"
import { TipoPuesto } from "./../../../model/TipoPuesto"
import { TipoVehiculo } from "./../../../model/TipoVehiculo"
import { VehiculoRequerido } from "./../../../model/VehiculoRequerido"
import { CategoriaProductoService } from "./../../../services/categoria-producto.service"
import { InsumoService } from "./../../../services/insumo.service"
import { TipoIVAGrabadoService } from "./../../../services/tipo-iva-grabado.service"
import { TipoNotificacionService } from "./../../../services/tipo-notificacion.service"
import { TipoProductoService } from "./../../../services/tipo-producto.service"
import { TipoPuestoService } from "./../../../services/tipo-puesto.service"
import { TipoVehiculoService } from "./../../../services/tipovehiculo.service"

@Component({
	selector: "gestor-producto",
	templateUrl: "gestor-producto.component.html",
	styleUrls: ["gestor-producto.component.less"]
})
export class GestorProductoComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	@Input()
	public item: Producto = new Producto()
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false
	public productoOrigen: Descriptivo
	public displayCopiarConfiguracion: boolean = false
	@Output()
	public onGuardado: EventEmitter<Producto> = new EventEmitter<Producto>()

	@Output()
	public onCancelado = new EventEmitter()
	public nuevoPuesto: PuestoACubrir = new PuestoACubrir()
	public productoOptions: Descriptivo[] = []
	public tipoPuestos: TipoPuesto[] = []
	public mostrarSeleccionPuesto = false
	public tipoPuestoElegido: TipoPuesto
	public tipoVehiculoElegido: TipoPuesto
	public mostrarSeleccionarVehiculo: boolean = false
	public tiposVehiculo: TipoVehiculo[] = []
	public adicionables: Descriptivo[] = []
	public tiposRecordatorio: TipoNotificacion[] = []

	public insumos: Insumo[] = []
	@Input()
	public readonly: boolean = false
	public OchoAm: Date = moment().startOf("date").add(8, "hours").toDate()
	private fromInsumo: { insumo: Descriptivo; tipoPuesto: Descriptivo }

	constructor(
		messagesService: MessagesService,
		public service: ProductoService,
		private route: ActivatedRoute,
		private router: Router,
		private confService: ConfirmationService,
		public tipoIvaGrabadoService: TipoIVAGrabadoService,
		public tiposPuestos: TipoPuestoService,
		public tipoProductoService: TipoProductoService,
		public insumoService: InsumoService,
		public productoService: ProductoService,
		public tipoVehiculoService: TipoVehiculoService,
		public categoriaService: CategoriaProductoService,
		public tipoNotificacionService: TipoNotificacionService,
		public encuestaService: EncuestaActividadesService,
		public familiaService: FamiliaProductoService,
		public tipoVarianteService: TipoVarianteService,
		public regionService: RegionService
	) {
		super(messagesService)
		this.fromInsumo = this.router.getCurrentNavigation()?.extras?.state?.fromInsumo
	}
	public file
	public onPicSelected(file: File) {
		const reader = new FileReader()
		let $this = this
		if (file.size > 1000000) {
			return this.error(this.translateService.get("EL_ARCHIVO_NO_PUEDE_16"))
		}
		this.file = file
		reader.onload = (e: any) => {
			$this.item.icono = new ProfilePic(null, e.target.result)
		}
		reader.readAsDataURL(file)
	}
	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}
	public idioma: string = Idioma.DEFAULT_CODIGO
	public isValid = async () => {
		if (this.item.esProducto() && this.item.puestosACubrir.some((pa) => !pa.personalDefault)) {
			return this.error(this.translateService.get("ERROR_PUESTOS_PROVEEDOR"))
		}
		if (this.item.esTercerizado() && !this.item.puestosACubrir?.length) {
			return this.error(this.translateService.get("ERROR_PUESTOS_TERCERIZADO"))
		}
		// if (this.validarPrograma())
		return true
	}
	verCopiarConfiguracion() {
		this.displayCopiarConfiguracion = true
	}
	validarPrograma() {
		let valid
		if (this.item.duracion) {
			for (let index = 1; index <= this.item.duracion; index++) {
				if (!this.item["productosPrograma"].find((p) => p.dia == index)) return this.error(`No hay servicio seleccionado para el día ${index}`)
			}
		}
		return true
	}
	public loadEntity = async (id: number, clone: boolean, idioma?: string) => {
		return this.service.getById(id, null, idioma).then((r) => {
			let p: Producto
			if (clone) {
				const p = r.clonar()
				p.clonado = id
				return p
			} else {
				p = r
			}

			p.codigoIdioma = idioma
			this.idioma = idioma
			return p
		})
	}
	ngOnInit() {
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
				}
			})
		)
		this.routeSub = this.route.queryParams.subscribe((params) => {
			let id: number = <number>params["id"]
			let clone: boolean = <boolean>params["clonar"]

			if (!this.service) return
			if (id) {
				this.loadEntity(id, clone, this.translateService.idioma ? this.translateService.idioma : "ES").then((p) => (this.item = p))
			} else {
				if (this.fromInsumo) {
					this.tipoProductoService.getByCodigo(TipoProducto.TERCERIZADO).then(async (tp) => {
						this.item = new Producto()
						this.item.descripcion = this.fromInsumo.insumo.descripcion
						this.item.codigo = this.fromInsumo.insumo.codigo
						const puesto = new PuestoACubrir()
						const insumo = await this.insumoService.getById(this.fromInsumo.insumo.id)
						const tipoPuesto = await this.tiposPuestos.getById(this.fromInsumo.tipoPuesto.id)
						puesto.insumos = [new PuestoInsumo(null, insumo, 1, 1, false, tipoPuesto)]
						puesto.tipoPuesto = tipoPuesto
						this.confirmarPuesto(tipoPuesto, puesto)
						this.item.esSubcategorizado = insumo.esSubcategorizado
						this.item.subcategorias = insumo.subcategorias.map((s) => {
							return SubcategoriaImponibleItem.fromData({ ...s, id: null })
						})
						this.item.tipoProducto = tp
					})
				} else {
					this.item = this.service ? this.service.newEnt() : null
				}
			}
		})

		this.tiposPuestos.getHabilitados().then((r) => {
			this.tipoPuestos = r
		})
		let f = new Filtro("", {})
		this.tipoNotificacionService.getRecordatorioPax().then((r) => {
			this.tiposRecordatorio = r
		})
		this.insumoService.getHabilitados().then((r) => {
			this.insumos = r
		})
		this.tipoVehiculoService.getHabilitados().then((r) => {
			this.tiposVehiculo = r
		})
		this.service.getAdicionables().then((r) => {
			this.adicionables = r.filter((p) => p.id != this.item.id)
		})
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}

	public confirmarPuesto(p: TipoPuesto, puestoACubrir?: PuestoACubrir) {
		if (p && p.codigo) {
			this.nuevoPuesto.tipoPuesto = p
			this.item.puestosACubrir.push(puestoACubrir || this.nuevoPuesto)
			this.item.puestosACubrir = [...this.item.puestosACubrir]
			this.mostrarSeleccionPuesto = false
		}
	}

	public quitarInsumo(insumoPuesto: PuestoInsumo, puesto: PuestoACubrir) {
		puesto.quitarInsumo(insumoPuesto)
	}

	public agregarProducto() {
		this.item.productosAsociados.push(new ProductoAsociado(null, null, 1, 1))
	}

	public agregarInsumoProducto() {
		this.item.insumos.push(new ProductoInsumo(null, null, 1))
	}

	public agregarInsumo(puesto: PuestoACubrir) {
		puesto.insumos.push(new PuestoInsumo(null, null, 1))
	}
	public eliminarVehiculo(ve: VehiculoRequerido) {
		this.item.eliminarVehiculo(ve)
	}
	public eliminarPuesto(puesto: PuestoACubrir) {
		this.item.eliminarPuesto(puesto)
	}
	public agregarVehiculo() {
		this.tipoVehiculoElegido = null
		this.item.vehiculosACubrir.push(new VehiculoRequerido(null, false, 0, null, [], this.item.vehiculoPrincipal ? false : true))
	}

	public getTipoByCategoria(tipo: string) {
		return this.tipoPuestos.filter((p) => p.categoria && p.categoria.codigo == tipo)[0]
	}
	public agregarNuevoPuesto(tipo: string) {
		this.tipoPuestoElegido = null
		this.nuevoPuesto = new PuestoACubrir()

		this.mostrarSeleccionPuesto = true
	}

	public updateAdicionable() {}

	toggleVehiculoPrincipal(event: any, v: VehiculoRequerido) {
		if (event) {
			this.item.vehiculosACubrir.filter((r) => r != v).forEach((r) => (r.esPrincipal = false))
		} else {
			v.esPrincipal = true
		}
	}
	public borrarImagen() {
		this.item.icono = null
	}
	public editar() {
		this.readonly = false
	}

	onSelectTipoProducto(tipo) {
		if (!this.fromInsumo) {
			this.updateAdicionable()
			if (!this.item?.id && !this.item?.clonado) {
				const t = tipo instanceof TipoProducto ? tipo : TipoProducto.fromData(tipo)
				this.item = ProductoFactory.newProductoByTipo(t || new TipoProducto(null, "PRD"))
				this.item.tipoProducto = t
				if (this.item.tipoProducto.codigo == TipoProducto.PRODUCTO) {
					this.item.adicionable = true
				}

				if (this.item.tipoProducto.codigo == TipoProducto.HOSPEDAJE) {
					this.item.puestosACubrir.push(
						new PuestoACubrir(
							null,
							Descriptivo.fromData(this.item),
							null,
							this.tipoPuestos.find((t) => t.codigo == TipoPuesto.ALOJAMIENTO)
						)
					)
					this.item.adicionable = false
				}
			}
		}
	}
	newItem = (item) => {
		return () => item.newItem()
	}
	agregarNuevoProductoPrograma() {
		this.item["productosPrograma"].push(new CronogramaProducto())
	}

	quitarProductoPrograma(prod, index) {
		this.item["productosPrograma"].splice(index, 1)
	}

	agregarNuevoProductoPaquete() {
		this.item["configuracionPaquete"].push(new ConfiguracionPaquete())
	}

	quitarProductoPaquete(prod, index) {
		this.item["configuracionPaquete"].splice(index, 1)
	}
	actualizarTipoIva(seleccionado: SubcategoriaImponible, item: SubcategoriaImponibleItem) {
		if (!item?.tipoIVA) {
			item.tipoIVA = seleccionado.tipoIVA
		}
	}
	copiarConfiguracion(event) {
		event && event.stopPropagation()
		if (!this.productoOrigen) return
		this.service.getById(this.productoOrigen.id).then((p) => {
			this.item.puestosACubrir = p.puestosACubrir.map((puesto) => {
				puesto.id = null
				return puesto
			})
			this.item.productosAsociados = p.productosAsociados.map((pr) => {
				pr.id = null
				return pr
			})
			this.item.vehiculosACubrir = p.vehiculosACubrir.map((v) => {
				v.id = null
				return v
			})
			this.item.insumos = p.puestosACubrir.map((i) => {
				i.id = null
				return i
			})
			this.displayCopiarConfiguracion = false
			this.productoOrigen = null
		})
	}
	esEliminablePuesto(p: PuestoACubrir) {
		return this.item.tipoProducto.codigo != TipoProducto.HOSPEDAJE && p.tipoPuesto?.codigo != TipoPuesto.ALOJAMIENTO
	}
}
