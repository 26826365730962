import { Moneda } from './Moneda'

export class Cotizacion {
    constructor(public desde: Moneda, public hasta: Moneda, public valor: number = 0) {}

    public static fromData(data): Cotizacion {
        if (!data) return null

        const o = new Cotizacion(Moneda.fromData(data.desde), Moneda.fromData(data.hasta), data.valor)
        return o
    }
}
