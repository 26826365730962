<div class="gestor-estado-documento content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'EstadoDocumento' | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="EstadoDocumentoForm"
		[multilenguaje]="false"
		(onEditar)="editar($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #EstadoDocumentoForm="ngForm" id="EstadoDocumentoform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-2">
						<span class="p-float-label field">
							<input
								pInputText
								placeholder="{{ 'CODIGO_PH' | translate }}"
								[(ngModel)]="item.codigo"
								tooltipPosition="bottom"
								pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
								type="$TYPE"
								#codigo="ngModel"
								name="codigo"
								[readonly]="readonly"
							/><label>{{ "CODIGO" | translate }}</label>
							<small *ngIf="getErrorMessage(codigo)">
								{{ getErrorMessage(codigo) }}
							</small>
						</span>
					</div>

					<div class="col-12 lg:col-6">
						<span class="p-float-label field">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="$TYPE"
								#descripcion="ngModel"
								name="descripcion"
								[readonly]="readonly"
							/><label>{{ "DESCRIPCION" | translate }}</label>
							<small *ngIf="getErrorMessage(descripcion)">
								{{ getErrorMessage(descripcion) }}
							</small>
						</span>
					</div>
					<div class="col-12 lg:col-2">
						<color-picker [(ngModel)]="item.color" name="color" [readonly]="readonly"> </color-picker>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
