import { Injectable } from "@angular/core";
import { environment } from "./../../../environments/environment";
import { ConfiguracionUsuarioService } from "./../../services/configuracion-usuario.service";
import { Descriptivo } from "./../model/Descriptivo";

@Injectable({
	providedIn: "root",
})
export class DefaultService {
	private static KEY = "defaults" + environment.tokenKey;
	private _map: Map<string, Descriptivo> = new Map();
	constructor(private confService: ConfiguracionUsuarioService) {}
	async cleanDefault(name: string) {
		let conf = await this.confService.get();
		delete conf.defaults[name];
		this.confService.save();
	}

	public clean() {
		this.confService.get().then((d) => {
			d.defaults = {};
			this.confService.save();
		});
	}

	public async getDefault(name) {
		const def = await this.confService.get();
		return def?.defaults[name] || null;
	}
	public async setDefault(name: string, data: any) {
		let conf = await this.confService.get();
		conf.defaults[name] = Descriptivo.fromData(data);
		this.confService.save();
	}
}
