<model-help [title]="'Gestión de Vehículos'">
    <p>
        Los vehículos son utilizados para brindar servicios a clientes.<br />
        Los vehiculos pueden establecerse como propios o de terceros (proveedores de servicios).<br />
        La pantalla de gestor de vehículos permite agregar nuevos, así también como modificar ó visualizar datos de los mismos.<br />
    </p>
    <h3>Campos destacados</h3>
    <p>
        <strong>Capacidad: </strong> Determinará la capacidad máxima de pasajeros habilitados para viajar en este vehículo.<br /><br />
        <strong>Vencimiento Habilitación:</strong> Información legal relevante para la adjudicación de servicios. <br /><br />
        <strong>Tipos de Vehículos:</strong> Tenemos una lista desplegable con los tipos de vehículos previamente cargados en el apartado de configuración -> Tipos de vehículos.<br />
        También podemos abrir la pantalla auxiliar para cargar un nuevo Tipo de vehículo desde la opción "<mark>Nuevo +</mark>" ubicada en la primera posición de opciones desplegadas.<br />
        Ayuda sobre <help-link [linkFinal]="'tipo-vehiculo'" [textoLink]="'Tipos de Vehículos'"></help-link><br /><br />
        <strong>Bloqueos:</strong> Este campo / listado estará disponible solo en modo edición y permite agregar, modificar o eliminar bloqueos de rango de fechas en las cuales no estará disponible
        este vehículo para brindar servicio, así también como especificar el motivo.<br /><br />
    </p>
    <p>
        <mark>Pantalla Auxiliar de Bloqueos:</mark> Al dar click en Agregar o sobre un bloqueo previamente cargado nos aparecerá la siguiente pantalla.
        <img src="assets/images/help/bloqueos.jpg" /><br /><br />
        La misma nos permitirá cargar o modificar una fecha de inicio y finalización del bloqueo del vehículo.<br /><br />
        Para eliminar un bloqueo deberá accionar el icono. <i class="pi pi-trash"></i>
    </p>
    <br />
    <h4>Recordatorios</h4>
    <p>ver <a href="#/help/recordatorio">Recordatorios</a></p>
</model-help>
