import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { Component, OnDestroy, OnInit, Input, EventEmitter, Output } from '@angular/core'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { SessionComponent } from 'src/app/common/components/session-component.component'
import { Filtro } from 'src/app/common/model/Filtro'
import { ColumnGenerator } from 'src/app/common/utils/ColumnGenerator'
import { ConfiguracionEntorno } from 'src/app/model/ConfiguracionEntorno'
import { ConfiguracionEntornoService } from 'src/app/services/configuracion-entorno.service'
import { LoadingService } from 'src/app/common/services/loading-data-service.service'

@Component({
    selector: 'listado-configuracion-entorno',
    templateUrl: 'listado-configuracion-entorno.component.html',
    styleUrls: ['listado-configuracion-entorno.component.less'],
})
export class ListadoConfiguracionEntornoComponent extends SessionComponent implements OnInit, OnDestroy {
    public filtro: Filtro

    @Input()
    public guardarFiltro: boolean = true
    @Input()
    public ocultarCabecera: boolean = false
    @Input()
    public editable: boolean = true
    public listado: ConfiguracionEntorno[] = []
    @Input()
    public readonly: boolean = false
    @Input()
    public modoSeleccion: 'single' | 'multiple' | 'none' = 'none'

    @Output()
    public onSelect: EventEmitter<ConfiguracionEntorno[]> = new EventEmitter()

    public columns = []
    constructor(messagesService: MessagesService, public service: ConfiguracionEntornoService) {
        super(messagesService)
    }
    ngOnInit() {
        this.filtro = new Filtro('configuracion-entorno_lista', {}, 0, 20, 'id', 1, this.guardarFiltro)
        this.columns = [
            {
                field: 'id',
                header: '#',
                class: 'id-column',
                role: 'text-tabla',
                sortable: true,
                order: 1,
            },
            {
                field: 'nombreEntorno',
                header: 'Nombre Entorno',
                class: 'text-tabla',
                role: 'text-tabla',
                sortable: true,
                order: 40,
            },
            {
                field: 'profilePic',
                header: 'Profile Pic',
                class: 'profile-pic',
                role: 'profile-tabla',
                sortable: false,
                order: 40,
            },
            {
                field: 'version',
                header: 'Version',
                class: 'number-tabla',
                role: 'number-tabla',
                sortable: false,
                order: 40,
            },
        ]
    }

    public updateSeleccion(seleccion: any) {
        if (seleccion?.length || seleccion['id']) {
            this.onSelect.emit(seleccion?.length ? seleccion : [seleccion])
        }
    }
    ngOnDestroy() {}
}
