import { Component, Input, OnInit } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { Subscription, filter, merge, mergeMap, of } from "rxjs"
import { ErrorHandler } from "../utils/ErrorsHandler"

@Component({
	selector: "error-tag",
	templateUrl: "error-tag.component.html"
})
export class ErrorTagComponent implements OnInit {
	public sus: Subscription

	private _model: AbstractControl
	public get model(): AbstractControl {
		return this._model
	}
	@Input()
	public set model(v: AbstractControl) {
		this._model = v
		if (v) {
			if (this.sus) this.sus.unsubscribe()
			const $this = this
			this.sus = merge(
				$this.model.statusChanges.pipe(
					filter(() => $this.model.touched),
					mergeMap(() => of(null))
				),
				$this.model.valueChanges.pipe(mergeMap((value) => of(value)))
			).subscribe((data) => {
				$this.error = $this._model?.invalid && $this._model?.touched
			})
		}
	}

	public error: boolean = false
	constructor(private errorHandlerUtils: ErrorHandler) {}
	public getErrorMessage(control: AbstractControl) {
		if (control?.errors) {
			return this.errorHandlerUtils.getErrorMessage(control)
		}
	}
	ngOnInit() {}
}
