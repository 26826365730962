import { Injectable } from "@angular/core"
import { AdminAuthGuard } from "./AdminAuthGuard"

@Injectable({
	providedIn: "root"
})
export class ProductoPublicadoAG extends AdminAuthGuard {
	public getRoles(): string[] {
		return super.getRoles().concat(["ROLE_PUBLICADOR"])
	}
}
