import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { Subscription } from "rxjs"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Insumo } from "src/app/model/Insumo"
import { PuestoACubrir } from "src/app/model/PuestoACubrir"
import { SubcategoriaImponible } from "src/app/model/SubcategoriaImponible"
import { SubcategoriaImponibleItem } from "src/app/model/SubcategoriaImponibleItem"
import { CuentaContableService } from "src/app/services/cuenta-contable.service"
import { InsumoService } from "src/app/services/insumo.service"
import { SubcategoriaImponibleService } from "src/app/services/subcategoria-imponible.service"
import { TipoIVAGrabadoService } from "src/app/services/tipo-iva-grabado.service"
import { TipoPuestoService } from "src/app/services/tipo-puesto.service"
import { SessionComponent } from "./../../../common/components/session-component.component"

@Component({
	selector: "gestor-insumo",
	templateUrl: "gestor-insumo.component.html",
	styleUrls: ["gestor-insumo.component.less"]
})
export class GestorInsumoComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	@Input()
	public item: Insumo
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false
	@Input()
	public titulo: string

	@Input() readonly = false

	@Output()
	public onGuardado: EventEmitter<Insumo> = new EventEmitter<Insumo>()

	@Output()
	public onCancelado = new EventEmitter()

	public cuentaContableOptions: Descriptivo[] = []
	public tipoPuestoElegido: PuestoACubrir
	public mostrarSeleccionPuesto: boolean = false
	public subcategorias: SubcategoriaImponible[] = []

	constructor(
		messagesService: MessagesService,
		public service: InsumoService,
		private route: ActivatedRoute,
		private confService: ConfirmationService,
		public cuentaContableService: CuentaContableService,
		public router: Router,
		public tiposPuestos: TipoPuestoService,
		public tipoIvaGrabadoService: TipoIVAGrabadoService,
		public subcategoriasService: SubcategoriaImponibleService
	) {
		super(messagesService)
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}

	public isValid = async () => {
		if (this.item.esSubcategorizado) {
			if (!this.item.subcategorias.length) {
				return Promise.resolve(this.error(this.translateService.get("DEBE_SELECCIONAR_AL_MENOS_UNA_SUBCATEGORÍA")))
			}
			if (this.item.subcategorias.reduce((a, b) => (a += b.proporcion), 0) != 100) {
				return Promise.resolve(this.error(this.translateService.get("LA_SUMA_DE_LAS_PROPORCIONES_DEBE_SER_100")))
			}
		}
		return Promise.resolve(true)
	}
	newItem = (item) => {
		return () => item.newItem()
	}
	ngOnInit() {
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
					this.editable = false
				}
			})
		)
		this.subs.push(
			this.subcategoriasService.data.subscribe((r) => {
				this.subcategorias = r
			})
		)
		if (this.route.snapshot.url.some((u) => u.path == "insumo")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				if (!this.service) return
				if (id) {
					this.service.getById(id).then((r) => {
						this.item = r
					})
				} else {
					this.item = this.service ? this.service.newEnt() : null
				}
			})
		}
		this.cuentaContableService.getDescriptivos().then((r) => {
			this.cuentaContableOptions = r
		})
	}
	crearServicioTercerizado() {
		this.tipoPuestoElegido = null
		this.mostrarSeleccionPuesto = true
	}
	confirmarPuesto = async (puesto: PuestoACubrir) => {
		this.mostrarSeleccionPuesto = false
		const tipoPuesto = await this.tiposPuestos.getById(this.tipoPuestoElegido.id)
		const data = {
			insumo: Descriptivo.fromData(this.item),
			tipoPuesto: Descriptivo.fromData(tipoPuesto)
		}
		this.router.navigate(["producto/nuevo"], {
			state: { fromInsumo: data }
		})
	}
	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}
	actualizarTipoIva(seleccionado: SubcategoriaImponible, item: SubcategoriaImponibleItem) {
		if (!item?.tipoIVA) {
			item.tipoIVA = seleccionado.tipoIVA
		}
	}
}
