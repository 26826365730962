import { Injectable } from "@angular/core"
import { Subscription, filter } from "rxjs"
import { BaseService } from "src/app/common/services/base.service"
import { FormaPagoService } from "src/app/services/forma-pago.service"
import { MonedaService } from "src/app/services/moneda.service"
import { AuthService } from "./../../services/auth.service"
import { ClienteService } from "./../../services/cliente.service"
import { NacionalidadService } from "./../../services/nacionalidad.service"
import { NovedadService } from "./../../services/novedades.service"
import { ProveedorService } from "./../../services/proveedor.service"

@Injectable({
	providedIn: "root"
})
export class InitializerService {
	private services: BaseService[] = []
	private iniciado = false
	private suscription: Subscription = null
	constructor(
		protected auth: AuthService,
		novedadesService: NovedadService,
		monedaService: MonedaService,
		nacionalidadService: NacionalidadService,
		clienteService: ClienteService,
		proveedorService: ProveedorService,
		formaPagoService: FormaPagoService
	) {
		this.services.push(novedadesService)
		this.services.push(nacionalidadService)
		this.services.push(monedaService)
		this.services.push(clienteService)
		this.services.push(proveedorService)
		this.services.push(formaPagoService)
		this.suscription = this.auth.onLogin.pipe(filter((u) => u?.id != undefined)).subscribe((u) => {
			if (!this.iniciado) {
				this.init()
				this.suscription && this.suscription.unsubscribe()
			}
		})
		this.auth.onLogout.subscribe((u) => {
			this.iniciado = false
			this.suscription && this.suscription.unsubscribe()
		})
	}

	public init() {
		this.services.forEach((s) => {
			s.init()
		})
	}
}
