import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { ConfirmationService, MenuItem } from "primeng/api"
import { Subscription } from "rxjs"
import { MonedaEntorno } from "src/app/app.module"
import { AccionesTabla } from "src/app/common/model/AccionesTabla"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { UsuarioService } from "src/app/common/services/usuario.service"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { Factura } from "src/app/model/Factura"
import { FacturaResumen } from "src/app/model/FacturaResumen"
import { ItemOrdenPago } from "src/app/model/ItemOrdenPago"
import { OrdenPago } from "src/app/model/OrdenPago"
import { Pago } from "src/app/model/Pago"
import { Proveedor } from "src/app/model/Proveedor"
import { CajaService } from "src/app/services/caja.service"
import { CentroCostoService } from "src/app/services/centro-costo.service"
import { ClienteService } from "src/app/services/cliente.service"
import { FacturaResumenService } from "src/app/services/factura-resumen.service"
import { FormaPagoService } from "src/app/services/forma-pago.service"
import { OrdenPagoResumenService } from "src/app/services/orden-pago-resumen.service"
import { OrdenPagoService } from "src/app/services/orden-pago.service"
import { ProveedorService } from "src/app/services/proveedor.service"
import { BreadcrumbService } from "./../../../breadcrumb.service"
import { SessionComponent } from "./../../../common/components/session-component.component"

@Component({
	selector: "gestor-orden-pago",
	templateUrl: "gestor-orden-pago.component.html",
	styleUrls: ["gestor-orden-pago.component.less"]
})
export class GestorOrdenPagoComponent extends SessionComponent implements OnDestroy, OnInit {
	public formNotificarProveedor: FormGroup
	private routeSub: Subscription
	@Input()
	public item: OrdenPago = new OrdenPago()
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false
	@Output()
	public onGuardado: EventEmitter<OrdenPago> = new EventEmitter<OrdenPago>()
	@Output()
	public onCancelado = new EventEmitter()
	private _readonly: boolean
	public importeImputar: number = 0
	public get readonly(): boolean {
		return this._readonly
	}
	@Input()
	public set readonly(v: boolean) {
		this._readonly = v
	}
	public editable: boolean = false
	public proveedorOptions: Descriptivo[] = []
	public centroCostoOptions: Descriptivo[] = []
	public itemOrdenPagoOptions: ItemOrdenPago[] = []
	public formaPagoOptions: Descriptivo[] = []
	public cajaOptions: Descriptivo[] = []
	public motivosCancelacion: Descriptivo[] = []
	public motivoSeleccionado: Descriptivo
	public eliminando: boolean = false
	public itemOrdenPagoSaved: ItemOrdenPago[] = []
	public facturas: FacturaResumen[] = []

	private _proveedor: Proveedor
	public get proveedor(): Proveedor {
		return this._proveedor
	}
	public set proveedor(v: Proveedor) {
		this._proveedor = v
		if (v && v.cliente) {
			this.cliente = v.cliente
		}
	}

	cliente: Descriptivo

	constructor(
		messagesService: MessagesService,
		public service: OrdenPagoService,
		public serviceResumen: OrdenPagoResumenService,
		private route: ActivatedRoute,
		private router: Router,
		private confService: ConfirmationService,
		private proveedorService: ProveedorService,
		private usuarioService: UsuarioService,
		private centroCostoService: CentroCostoService,
		private facturaService: FacturaResumenService,
		private formaPagoService: FormaPagoService,
		private cajaService: CajaService,
		private breadcrumbService: BreadcrumbService,
		public serviceOrdenPagoResumen: OrdenPagoResumenService,
		private clienteService: ClienteService
	) {
		super(messagesService)

		this.facturas = this.router.getCurrentNavigation()?.extras?.state?.facturas
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}

	public handleCancelar(item) {
		this.onCancelado.emit()
	}
	public imputar() {
		if (this.importeImputar > 0) {
			this.item.items = []
			let saldo = this.importeImputar
			this.item.pagoACuenta = 0
			this.itemOrdenPagoOptions.forEach((io) => {
				io.importe = io.factura.saldoTransformado
			})
			let index = 0
			while (saldo > 0 && index < this.itemOrdenPagoOptions.length) {
				if (this.itemOrdenPagoOptions[index].factura.saldoTransformado <= 0) {
					index++
					continue
				}
				let aQuitar = saldo < this.itemOrdenPagoOptions[index].importe ? saldo : this.itemOrdenPagoOptions[index].importe
				this.itemOrdenPagoOptions[index].importe = aQuitar
				this.item.items.push(this.itemOrdenPagoOptions[index])
				index++
				saldo -= aQuitar
			}
			if (saldo > 0) {
				this.item.pagoACuenta = saldo
			}
		}
	}
	public isValid() {
		let valid = true
		if (this.item.id && this.item.pagos?.length > 0) return true
		if (this.item.items.length === 0 && this.item.pagoACuenta <= 0) {
			this.error("Debe incluir al menos 1 item a pagar o un pago a cuenta")
			valid = false
		}
		if (this.item.items.some((i) => i.importe == 0)) {
			this.error("Los importes de items no pueden ser 0")
			valid = false
		}
		if (this.item.items.some((i) => i.importe > Math.abs(i.factura.saldoTransformado))) {
			this.error("Los importes de items no pueden superar el saldo de la factura")
			valid = false
		}
		if (this.item.pagos.some((p) => !p.monto || p.monto <= 0)) {
			valid = false
			this.error("Los importes de pagos deben ser mayores a 0")
		}
		if (!this.item.pagosValidos) {
			valid = false
			this.error("El total de pagos no coincide con el total de facturas a pagar")
		}
		if (this.item.total < 0) {
			valid = false
			this.error("El total no puede ser negativo")
		}

		return (
			this.item.proveedor &&
			this.item.fecha &&
			this.item.pagos.every((p) => p.formaPago && (p.tipoCobro == "S" || p.caja) && p.monto && p.monto > 0) &&
			valid
		)
	}
	public get puedeEditar() {
		return (this.item.id && this.item?.estado?.codigo == EstadoDocumento.PENDIENTE_PAGO) || !this.item.id
	}
	ngOnInit() {
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
					this.editable = false
				}
			})
		)
		this.centroCostoService.getDescriptivos().then((cs) => {
			this.centroCostoOptions = cs
			if (this.item && !this.item.centroCosto) {
				this.item.centroCosto = cs[0]
			}
		})
		if (this.route.snapshot.url.some((u) => u.path == "orden-pago")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				let idProveedor: number = <number>params["proveedorId"]
				if (this.usuario?.esExterno) {
					this.proveedorService.name = "proveedorExterno"
				}
				if (!this.service) return
				if (id) {
					this.getEntidad(id)
				} else {
					this.item = this.service ? this.service.newEnt() : null
					this.item.moneda = MonedaEntorno()
					this.editable = !this.usuario?.esExterno
					if (!this.usuario?.esExterno)
						this.proveedorService.getDescriptivos().then((ps) => {
							this.proveedorOptions = ps

							if (this.facturas?.length) {
								//this.item.centroCosto = this.facturas[0].centroCosto;
								this.facturas.forEach((f) => {
									this.item.items.push(new ItemOrdenPago(null, f, f.saldoTransformado))
								})
								//this.item.moneda = this.facturas[0].moneda
								this.itemOrdenPagoSaved = [...this.item.items]
								this.item.proveedor = this.facturas[0].proveedor
								this.item.centroCosto = this.facturas[0].centroCosto
								this.proveedorChange(this.item.proveedor)
							} else {
								if (idProveedor) {
									this.proveedorService.getById(idProveedor).then((p) => {
										this.item.proveedor = p
										this.proveedor = p
										this.proveedorChange(this.item.proveedor)
									})
								}
							}
						})
				}
			})
		}

		this.formaPagoService.getAll().then((fs) => (this.formaPagoOptions = fs.filter((f) => f.codigo == "EF")))
		this.cajaService.getAll().then((cs) => (this.cajaOptions = cs))
	}
	getEntidad = (id) => {
		this.service.getById(id).then((r) => {
			this.item = r
			this.itemOrdenPagoSaved = [...this.item.items]
			this.editable = (this.item.estado?.codigo == "P" || this.item.estado?.codigo == "PP") && !this.readonly && !this.usuario?.esExterno
			if (!this.usuario?.esExterno)
				this.proveedorService.getDescriptivos().then((ps) => {
					this.proveedorOptions = ps
					this.proveedorChange(this.item.proveedor)

					this.updateAcciones()

					this.formNotificarProveedor = new FormGroup({ destinatarioControl: new FormControl(null, Validators.required) })
				})
			else if (this.item?.proveedor) this.proveedorChange(this.item.proveedor)
		})
	}
	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}

	nuevoPago() {
		this.item.pagos.push(new Pago(null, null, null, this.item.saldoRestante))
	}

	quitarPago(p: Pago) {
		let i = this.item.pagos.indexOf(p)
		if (i > -1) {
			this.item.pagos.splice(i, 1)
		}
	}

	proveedorChange(p: any) {
		let $this = this
		const proveedor = p?.prev || p
		if (proveedor?.id != this.item?.proveedor?.id) {
			this.item.items = []
			this.itemOrdenPagoSaved = []
		}
		if (proveedor?.id) {
			if (this.editable) {
				this.facturaService.getFacturasImpagas(proveedor.id, this.item.centroCosto.id).then((fs) => {
					this.itemOrdenPagoOptions = [...this.itemOrdenPagoSaved]
					this.item.items = [...this.itemOrdenPagoSaved]
					fs.forEach((f) => {
						if (!this.itemOrdenPagoSaved.find((s) => s.factura.id == f.id)) {
							this.itemOrdenPagoOptions.push(new ItemOrdenPago(null, f, f.saldoTransformado))
						}
					})
				})
				if (!this.item.id && !proveedor?.emailAdministrativo) {
					this.proveedorService.getById(proveedor.id, new LoadingService()).then((p) => {
						if (p.emailAdministrativo) {
							this.item.destinatario = p.emailAdministrativo
						} else if (p.email) {
							this.item.destinatario = p.email
						}

						this.proveedor = p
					})
				} else if (proveedor?.emailAdministrativo) {
					this.item.destinatario = proveedor.emailAdministrativo
				} else if (proveedor?.email) {
					this.item.destinatario = proveedor.email
				}
			} else {
				this.itemOrdenPagoOptions = this.itemOrdenPagoSaved
			}
		}

		this.proveedorService.getById(proveedor.id).then((p) => {
			this.proveedor = p
		})
	}

	//fix del componente de prime q no actualiza cuando el item seleccionado viene de la base
	importeItemChange(event, iop: ItemOrdenPago) {
		let saved = this.item.items.find((i) => i.factura.id == iop.factura.id)
		if (saved) {
			saved.importe = iop.importe
		}
	}

	public menuItemAcciones: MenuItem[] = []
	public verAcciones: boolean
	public eliminar() {
		this.serviceResumen.eliminarOrden(this.item.id, this.motivoSeleccionado.id).then((res) => {
			this.success(this.translateService.get("EL_ITEM_FUE_ELIMINA_15"))
			this.getEntidad(this.item.id)
			this.eliminando = false
		})
	}
	public goToFactura = (event, factura: FacturaResumen | Factura | Descriptivo) => {
		event && event.stopPropagation()
		this.router.navigate(["factura/vista"], { queryParams: { id: factura.id } })
	}
	public updateAcciones() {
		this.menuItemAcciones = [
			{
				label: "Enviar al proveedor",
				icon: "fa fa-envelope",
				visible: this.item.estado?.codigo == "A" || this.item.estado?.codigo == "F",
				command: () => {
					this.mostrarNotificarProveedor(this.item)
				}
			},
			new AccionesTabla("Eliminar Orden de Pago", "fa fa-trash", "accion-tabla", (item) => {
				this.motivoSeleccionado = null
				this.eliminando = true
			})
		]

		this.verAcciones = this.menuItemAcciones.some((item) => item.visible)
	}

	public verNotificarProveedor: boolean = false

	public mostrarNotificarProveedor(item: OrdenPago) {
		this.formNotificarProveedor.patchValue({ destinatarioControl: "" })

		if (this.proveedor.emailAdministrativo) {
			this.formNotificarProveedor.patchValue({ destinatarioControl: this.proveedor.emailAdministrativo })
		} else {
			this.formNotificarProveedor.patchValue({ destinatarioControl: this.proveedor.email })
		}
		this.verNotificarProveedor = true
	}

	public notificarProveedor() {
		const formValue = this.formNotificarProveedor.value

		if (this.formNotificarProveedor.valid) {
			this.serviceOrdenPagoResumen.notificarProveedor(this.item, formValue.detinatarioControl).then((r) => {
				this.success("Se envió la notificación")
				this.verNotificarProveedor = false
			})
		} else {
			this.error("Verifique todos los campos")
		}
	}

	esNotificable() {
		return (!this.item?.id || this.item.estado.codigo == "PP") && this.item?.pagos?.length > 0
	}
}
