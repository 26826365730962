import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'selector-checkbox',
  templateUrl: './selector-checkbox.component.html',
  styleUrls: ['./selector-checkbox.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectorCheckboxComponent),
      multi: true
    }
  ]

})
export class SelectorCheckboxComponent implements OnInit, ControlValueAccessor {

  constructor() { }
  @Input()
  public checkboxClass: string = "col-12 lg:col-3";

  @Input()
  public header: string = "";
  private _options: SelectItem[] = [];
  public get options(): SelectItem[] {
    return this._options;
  }

  @Input()
  public set options(v: SelectItem[]) {
    this._options = v;
    this.updateSelectedState();
  }

  @Input()
  public readonly: boolean = false;
  private _selected: SelectItem[] = [];
  public get selected(): SelectItem[] {
    return this._selected;
  }
  @Input()
  public set selected(v: SelectItem[]) {
    this._selected = v;
    this.updateSelectedState();
    this.onChangeCallback(this._selected);
  }


  public updateSelectedState() {
    this._allSelected = this._options.length > 0 && this.selected.filter(s => this.options.some(o => o == s))?.length == this._options.length;
  }

  private _allSelected: boolean;
  public get allSelected(): boolean {
    return this._allSelected;
  }
  public set allSelected(v: boolean) {
    this.updateSelectedState();
  }





  public updateSelection(event) {

    if (event) {
      this.options.filter(o => this.selected.indexOf(o) < 0).forEach(o => {
        this.selected = this.selected.concat(o);
      });
    } else {
      this.options.forEach(o => {
        this.selected = this.selected.filter(e => e != o);
      })
    }

  }
  ngOnInit() {
  }

  private onChangeCallback: (_: any) => void = () => { };
  private onTouchedCallback: (_: any) => void = () => { };
  public disabled: boolean = false;


  writeValue(obj: any): void {
    this.selected = obj ? obj : [];

  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
