import { Descriptivo } from "src/app/common/model/Descriptivo"
import { ProfilePic } from "src/app/common/model/ProfilePic"
import { Region } from "src/app/model/Region"
import { TipoProducto } from "src/app/model/TipoProducto"
import { CategoriaProducto } from "./../../model/CategoriaProducto"
export class ProductoCabecera extends Descriptivo {
	public icon: string
	public tipoVarianteStr: string
	public fullDescripcion: string
	public descripcionGeneral: string
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public habilitado?: boolean,
		public tipoProducto?: Descriptivo,
		public categorias: CategoriaProducto[] = [],
		public icono?: ProfilePic,
		public nombreComercial?: string,
		public adicionable?: boolean,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
		public detalle?: string,
		public tipoProductoDesc?: string,
		public familiaProducto?: Descriptivo,
		public variante?: Descriptivo,
		public peso?: number,
		public regiones: Region[] = [],
		public requiereConsentimiento?: boolean
	) {
		super(codigo, descripcion, id)
		this.searchStr = this.searchStr + "_" + this.nombreComercial
		this.tipoVarianteStr = this.variante?.descripcion
		switch (this.tipoProducto?.codigo) {
			case TipoProducto.PAQUETE:
				this.icon = "fa fa-gift"
				break
			case TipoProducto.PROGRAMA:
				this.icon = "fa fa-calendar-check-o"
				break
			case TipoProducto.FAMILIA:
				this.icon = "fa fa-users"
				break
		}
		this.peso = this.peso != null ? this?.peso : 9999
		this.fullDescripcion = this.descripcion + (this.variante ? " - " + this.variante.codigo : "")
		this.descripcionGeneral = this.familiaProducto ? this.familiaProducto.descripcion : this.fullDescripcion
	}

	public static fromData(data: any): ProductoCabecera {
		if (!data) return null
		const o: ProductoCabecera = new ProductoCabecera(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			Descriptivo.fromData(data.tipoProducto),
			data.categorias ? data.categorias.map((cat) => CategoriaProducto.fromData(cat)) : [],
			ProfilePic.fromData(data.icono),
			data.nombreComercial,
			data.adicionable,
			data.lastModifiedBy,
			data.lastModifiedDate,
			data.detalle,
			data.tipoProductoDesc,
			Descriptivo.fromData(data.familiaProducto),
			data?.variante ? Descriptivo.fromData(data.variante) : data?.tipoVariante ? Descriptivo.fromData(data.tipoVariante) : null,
			data.peso,
			data.regiones ? data.regiones.map((r) => Region.fromData(r)) : [],
			data.requiereConsentimiento
		)

		o.version = data.version
		return o
	}

	public get imagenUrl() {
		return this.icono?.picPathVersion || "assets/images/default_image.png"
	}

	public get imagen() {
		return this.imagenUrl
	}

	public get nombre(): string {
		return this.nombreComercial ?? this.descripcion
	}

	get nombreComercialCompuesto() {
		return this.nombre + " - " + this.textoVariante
	}

	get textoVariante() {
		return this.variante && this.variante.codigo != "SD" ? this.variante.descripcion : "General"
	}

	get keyFamilia() {
		return this.familiaProducto ? "f" + this.familiaProducto.id : "p" + this.id
	}
	public aplica(p: ProductoCabecera) {
		return this.id == p.id || (p.familiaProducto && this.familiaProducto && p.familiaProducto.id == this.familiaProducto.id)
	}
	public esTercerizado(): boolean {
		return this.esTipo(TipoProducto.TERCERIZADO)
	}

	public esPaquete(): boolean {
		return this.esTipo(TipoProducto.PAQUETE)
	}

	public esProducto(): boolean {
		return this.esTipo(TipoProducto.PRODUCTO)
	}
	public esTransfer(): boolean {
		return this.esTipo(TipoProducto.TRANSFER)
	}

	public esServicio(): boolean {
		return this.esTipo(TipoProducto.SERVICIO)
	}

	public get esPrograma(): boolean {
		return this.esTipo(TipoProducto.PROGRAMA)
	}

	public esHospedaje(): boolean {
		return this.esTipo(TipoProducto.HOSPEDAJE)
	}

	private esTipo(codigo: string): boolean {
		return this.tipoProducto?.codigo === codigo
	}
}
