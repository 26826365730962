import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { TagProducto } from "src/app/model/TagProducto"
import { LoadingService } from "./../common/services/loading-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class TagProductoService extends ParametricoService<TagProducto> {
	public baseName(): string {
		return "tag-producto"
	}
	public parseToEnt(data: any): TagProducto {
		return TagProducto.fromData(data)
	}
	public newEnt(): TagProducto {
		return new TagProducto()
	}

	constructor(public novedadesService: NovedadService, public authService: AuthService) {
		super(novedadesService, authService)
	}
	public actualizarDescripcion(data: TagProducto, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		var p = this.http.put(this.getBaseURL("actualizar-descripcion"), data).toPromise()
		p.then((r) => {
			this.loadData(customLoading)
			if (customLoading) {
				customLoading.susLoadingCount()
			} else this.loadingService.susLoadingCount()
		})
		return p
	}
}
