// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	nombre: "Tierra Turismo ",
	production: false,
	timezone: "-0300",
	apiUrl: "https://localhost:8443/tierra-backend/",
	tokenKey: "tierra-dev",
	googleApiKey: "6Lf1TqsUAAAAACnWY1ryBv7ortfHjJERCLUNF5Ei",
	logo: "assets/images/logo-blanco.png",
	defaultLayout: "iterpax",
	websocketUrl: "ws://localhost:8080/tierra-backend/websocket",
	recaptchaKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
	isOverlay: false,
	darkMenu: false,
	offlineMode: false
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/*
export const environment = {
  production: false,
  apiUrl: "https://tierra-turismo-back.costazini.com.ar/tierra-turismo-backend/",
  tokenKey: "tierra-test",
  googleApiKey: "6Lf1TqsUAAAAACnWY1ryBv7ortfHjJERCLUNF5Ei",
  firebase: {
    apiKey: "AIzaSyDcwts4Q6EESUsJgZU2yowo0q3Q4uxw7fs",
    authDomain: "tierra-test.firebaseapp.com",
    databaseURL: "https://tierra-test.firebaseio.com",
    projectId: "tierra-test",
    storageBucket: "tierra-test.appspot.com",
    messagingSenderId: "936540328546",
    appId: "1:936540328546:web:0f92c471e941af2cbef73b"
  },
  recaptchaKey: '6Le6b60ZAAAAAG6LzmtiC4vxYgYpQIdcKyZ5zUPn',
  isOverlay: false,
  darkMenu: false,
};
*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
