import { Parametrico } from "src/app/common/model/Parametrico"
import { Pais } from "./Pais"

export class Region extends Parametrico {
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado: boolean = true,
		esSistema: boolean = false,
		peso: number = 99,
		esDefault: boolean = false,
		public codigoIdioma: string = "ES",
		public pais: Pais = null
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
	}
	public tipoParametro(): string {
		return "REGION"
	}
	public static fromData(data: any): Region {
		if (!data) return null
		const o: Region = new Region(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			data.esSistema,
			data.peso,
			data.esDefault,
			data.codigoIdioma,
			Pais.fromData(data.pais)
		)

		return o
	}
}
