import { FamiliaProducto } from "src/app/model/FamiliaProducto"
import { ItemReservaConsentimiento } from "src/app/model/ItemReservaConsentimiento"
import { Producto } from "src/app/model/Producto"
import { ProductoAsociadoReserva } from "src/app/model/ProductoAsociadoReserva"
import { PuestoServicioReserva } from "src/app/model/PuestoServicioReserva"
import { Restriccion } from "src/app/model/Restriccion"
import { IActividad } from "./IActividad"

export class FamiliaConsentimiento implements IActividad {
	private puestos: PuestoServicioReserva[] = []
	private itemBase: ItemReservaConsentimiento
	private restricciones: Restriccion[] = []
	private variantes: { cantidad: number; variante: string }[] = []
	private productosAsociados: ProductoAsociadoReserva[] = []
	constructor(
		public keyFamilia?: string,
		public producto?: Producto,
		public familia?: FamiliaProducto,
		public fecha?: Date,
		public items?: ItemReservaConsentimiento[],
		public encuestaRespondida?: boolean,
		public nombrePax?: string,
		public cantidadPaxReserva?: number
	) {
		this.extractDataFromItems()
	}

	addItem(item: ItemReservaConsentimiento) {
		this.items.push(item)
		this.extractDataFromItems()
	}
	private extractDataFromItems() {
		this.itemBase = this.items?.length > 0 ? this.items.find((i) => i.producto.tipoVariante?.codigo == "ADT") || this.items[0] : null
		this.puestos = Array.from(
			this.items
				.flatMap((i) => i.reservas.flatMap((r) => r.grupoResumen.puestos.flatMap((p) => p)))
				.reduce((acc, puesto) => {
					// Suponemos que `puesto.id` es un identificador único de cada puesto
					acc.set(puesto.id, puesto)
					return acc
				}, new Map<number, PuestoServicioReserva>())
				.values()
		)
		this.restricciones = this.items.flatMap((i) => i.restricciones)
		this.variantes = this.items.map((i) => ({ cantidad: i.cantidadPax, variante: i.producto.textoVariante }))
		this.productosAsociados = this.items
			.flatMap((i) => i.productosAsociados)
			.reduce((acc, curr) => {
				const existingProduct = acc.find((p) => p.producto.id === curr.producto.id)
				if (existingProduct) {
					existingProduct.cantidad += curr.cantidad
				} else {
					acc.push(curr)
				}
				return acc
			}, [])
	}
	public static fromData(data: any): FamiliaConsentimiento {
		if (!data) return null
		const o: FamiliaConsentimiento = new FamiliaConsentimiento(
			data.keyFamilia,
			Producto.fromData(data.producto),
			FamiliaProducto.fromData(data.familia),
			data.fecha ? new Date(data.fecha) : null,
			data.items ? data.items.map((i) => ItemReservaConsentimiento.fromData(i)) : [],
			data.encuestaRespondida,
			data.nombrePax,
			data.cantidadPaxReserva
		)
		return o
	}
	getProductoAsociados(): ProductoAsociadoReserva[] {
		return this.productosAsociados
	}
	getRestricciones(): Restriccion[] {
		return this.restricciones
	}
	getReferenciaDireccion(): string {
		return this.itemBase?.referenciaDireccion
	}
	getDescripcion(): string {
		return this.familia?.nombre || this.producto?.nombre
	}
	getItemBase(): ItemReservaConsentimiento {
		return this.itemBase
	}
	getPuestos(): PuestoServicioReserva[] {
		return this.puestos
	}
	getRequiereConsentimiento(): boolean {
		return (this.familia && this.familia.requiereConsentimiento) || this.items.some((i) => i.producto.requiereConsentimiento)
	}
	getDetalle(): string {
		return this.familia && this.familia.detalle ? this.familia?.detalle : this.producto.detalle
	}
	getVariantes() {
		return this.variantes
	}
	get puestoTercerizado() {
		return this.getPuestos().find((p) => p.esPrestador())
	}
	set confirmado(value: boolean) {
		this.items.forEach((i) => {
			i.aceptadoConsentimiento = value
		})
	}
	get confirmado() {
		return this.items.every((i) => i.aceptadoConsentimiento || !i.requiereConsentimiento)
	}
	get fechaActividad(): Date {
		return this.fecha
	}
	get tipoVoucher(): string {
		return "F"
	}
	getId(): number {
		return this.itemBase?.id || this.familia?.id || this.producto?.id
	}

	public get requiereConsentimiento() {
		return this.familia ? this.familia.requiereConsentimiento : this.producto.requiereConsentimiento
	}

	public getNombreReferencia() {
		return this.nombrePax
	}
	public getCantidadBasePaxs() {
		return this.cantidadPaxReserva
	}
}
