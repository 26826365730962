import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { TipoAusencia } from "src/app/model/TipoAusencia";
import { TipoAusenciaService } from 'src/app/services/tipo-ausencia.service';
import { SessionComponent } from './../../../common/components/session-component.component';




@Component({
	selector: 'gestor-tipo-ausencia',
	templateUrl: 'gestor-tipo-ausencia.component.html',
	styleUrls: ['gestor-tipo-ausencia.component.less',]
})

export class GestorTipoAusenciaComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription;
	@Input()
	public item: TipoAusencia;
	@Input()
	public goBack: boolean = true;
	@Input()
	public isModal: boolean = false;
	
	@Input()
	public readonly:boolean = false;

	@Output()
	public onGuardado: EventEmitter<TipoAusencia> = new EventEmitter<TipoAusencia>();

	@Output()
	public onCancelado = new EventEmitter();

	

	

	constructor(messagesService: MessagesService,
		public service: TipoAusenciaService,
		private route: ActivatedRoute,
		private router: Router,
		private confService: ConfirmationService,
		
	) {
		super(messagesService);
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item);
	}
	public handleCancelar(item) {
		this.onCancelado.emit();
	}

	public async isValid() {
		return true;
	}
	
	public onEdit(event) {
		if (this.item?.id)
			this.router.navigate(["'tipo-ausencia/edit"], { queryParams: { id: this.item.id } });
	}
	
	ngOnInit() {
		this.subs.push(this.route.data.subscribe(u => {
			if (u?.vista) {
				this.readonly = true;
			}
		}));
		if (this.route.snapshot.url.some(u => u.path == 'tipo-ausencia')) {
			this.routeSub = this.route.queryParams.subscribe(params => {
				let id: number = <number>params['id'];
				if (!this.service) return;
				if (id) {
					this.service.getById(id,null,this.translateService.idioma ? this.translateService.idioma : 'ES').then(r => {
						this.item = r;
					})
				} else {
					if (!this.item && this.service) {
						this.item = this.service.newEnt();
					}
					this.item["codigoIdioma"] = this.translateService.idioma ? this.translateService.idioma : 'ES';
				}

			});
		}

		
	}
	
	public get esEditable() {
		return this.esAdministrador;
	}


	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe();
	}
}