import { Parametrico } from "src/app/common/model/Parametrico"

export class Pais extends Parametrico {
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado: boolean = true,
		esSistema: boolean = false,
		peso: number = 99,
		esDefault: boolean = false,
		public codigoIdioma: string = "ES"
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
	}
	public tipoParametro(): string {
		return "$MODELNAMELOW"
	}
	public static fromData(data: any): Pais {
		if (!data) return null
		const o: Pais = new Pais(data.id, data.codigo, data.descripcion, data.habilitado, data.esSistema, data.peso, data.esDefault, data.codigoIdioma)

		return o
	}
}
