import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import moment from "moment"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { FiltroFecha } from "src/app/common/model/FiltroFecha"

@Component({
	selector: "selector-periodo-dinamico",
	templateUrl: "selector-periodo-dinamico.component.html",
	styleUrls: ["selector-periodo-dinamico.component.less"]
})
export class SelectorPeriodoDinamicoComponent implements OnInit {
	public opcionesTexts: string[] = ["ULTIMOS_7_DÍAS", "ESTE_MES", "ULTIMOS_3_MESES", "ESTE_ANIO", "RANGO_ESPECÍFICO"]
	public opciones: Descriptivo[] = [
		new Descriptivo("1", "ULTIMOS_7_DÍAS"),
		new Descriptivo("2", "ESTE_MES"),
		new Descriptivo("3", "ULTIMOS_3_MESES"),
		new Descriptivo("4", "ESTE_ANIO"),
		new Descriptivo("RE", "RANGO_ESPECÍFICO")
	]
	@Output()
	public onPeriodoChange: EventEmitter<any> = new EventEmitter()

	@Input()
	public desde: string = "fechaDesde"

	@Input()
	public hasta: string = "fechaHasta"
	private _periodoSeleccionado: Descriptivo
	public get periodoSeleccionado(): Descriptivo {
		return this._periodoSeleccionado
	}
	@Input()
	public set periodoSeleccionado(v: Descriptivo) {
		if (v && this._periodoSeleccionado != v) {
			this._periodoSeleccionado = v
			this.seleccionarPeriodo(this._periodoSeleccionado)
		} else {
			this._periodoSeleccionado = v
		}
	}
	@Input()
	public filtro: FiltroFecha
	public seleccionarPeriodoCodigo = (codigo: string) => {
		this.periodoSeleccionado = this.opciones.find((o) => o.codigo == codigo)
		this.seleccionarPeriodo(this.periodoSeleccionado)
	}
	public seleccionarPeriodo = (d: Descriptivo) => {
		if (!d && !this.filtro) return
		switch (d.codigo) {
			case "1":
				this.filtro.setMultiple({
					[this.desde]: moment().subtract(7, "days").startOf("day").toDate(),
					[this.hasta]: moment().endOf("day").toDate()
				})

				break
			case "2":
				this.filtro.setMultiple({
					[this.desde]: moment().subtract(1, "months").startOf("day").toDate(),
					[this.hasta]: moment().endOf("day").toDate()
				})
				break
			case "3":
				this.filtro.setMultiple({
					[this.desde]: moment().subtract(3, "months").startOf("day").toDate(),
					[this.hasta]: moment().endOf("day").toDate()
				})

				break
			case "4":
				this.filtro.setMultiple({
					[this.desde]: moment().startOf("year").toDate(),
					[this.hasta]: moment().endOf("day").toDate()
				})
				break
			default:
				break
		}
		this.onPeriodoChange.emit(this.periodoSeleccionado)
	}
	ngOnInit() {
		/*if (!this.periodoSeleccionado) {
			this.periodoSeleccionado = this.opciones[0]
		}*/
	}
}
