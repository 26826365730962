<div class="gestor-vehiculos content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[file]="file"
		[conArchivo]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		(onCancelar)="handleCancelar($event)"
	>
		<div class="gestor-form" model-type="dataContent" *ngIf="item">
			<div class="grid">
				<div class="grid">
					<div class="lg:col-2 pic-wrapper col-4">
						<div class="pic">
							<profile-badge [seleccionable]="true" [url]="item?.bandera?.picPathVersion" class="" (onFileSelected)="onPicSelected($event)">
							</profile-badge>
							<i class="fa fa-pencil edit-icon"></i>
						</div>
					</div>
					<div class="lg:col-10 col-8">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<input pInputText [(ngModel)]="item.descripcion" [required]="true" [readonly]="readonly" />
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
					</div>
					<div class="col-6">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<input pInputText placeholder="{{ 'PH_CODIGO' | translate }}" [(ngModel)]="item.codigo" [disabled]="item.id" />
							<label>{{ "CODIGO" | translate }}</label>
						</span>
					</div>

					<div class="col-6">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<input pInputText TYPE="number" placeholder="{{ 'PH_PESO' | translate }}" [(ngModel)]="item.peso" />
							<label>{{ "PESO" | translate }}</label>
						</span>
					</div>
					<div class="col-4">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<input pInputText TYPE="number" placeholder="{{ 'CUIT_PFISICA' | translate }}" [(ngModel)]="item.cuitFisica" />
							<label>{{ "CUIT_PFISICA" | translate }}</label>
						</span>
					</div>
					<div class="col-4">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<input pInputText TYPE="number" placeholder="{{ 'CUIT_PJURIDICA' | translate }}" [(ngModel)]="item.cuitJuridica" />
							<label>{{ "CUIT_PJURIDICA" | translate }}</label>
						</span>
					</div>
					<div class="col-4">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<input pInputText TYPE="number" placeholder="{{ 'CUIT_OTROS' | translate }}" [(ngModel)]="item.cuitOtros" />
							<label>{{ "CUIT_OTROS" | translate }}</label>
						</span>
					</div>
				</div>
			</div>
		</div>
	</model-gestor>
</div>
