import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core"
import { OverlayPanel } from "primeng/overlaypanel"
import { filter } from "rxjs"
import { ReservasAG } from "src/app/authguards/ReservasAG"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { TranslateService } from "src/app/common/services/translate.service"
import { ConfiguracionEntorno } from "src/app/model/ConfiguracionEntorno"
import { TotalCliente } from "src/app/model/TotalCliente"
import { Totales } from "src/app/model/Totales"
import { FiltroReserva } from "src/app/pages/reserva/listado-reserva/FiltroReserva"
import { AuthService } from "src/app/services/auth.service"
import { ConfiguracionEntornoService } from "src/app/services/configuracion-entorno.service"
import { PuntoDeVentaService } from "src/app/services/punto-de-venta.service"
import { ReservaService } from "src/app/services/reserva.service"
import { DashboardConfiguration } from "../../dashboard-element/DashboardConfiguration"
import { IDashboardElement } from "../../dashboard-element/IDashboardElement"
import { SelectorPeriodoDinamicoComponent } from "../selector-periodo-dinamico/selector-periodo-dinamico.component"

@Component({
	selector: "estado-reservas",
	templateUrl: "estado-reservas.component.html",
	styleUrls: ["estado-reservas.component.less"]
})
export class EstadoReservasComponent implements OnInit, IDashboardElement, AfterViewInit {
	public static TIPO = "estado-reservas"
	public data: TotalCliente
	@ViewChild("selectorPeriodo", { static: false })
	public selectorPeriodo: SelectorPeriodoDinamicoComponent
	public customLoading: LoadingService = new LoadingService()
	title: string = this.translateService.get("TOTALES")
	filtro: FiltroReserva
	showParams(event) {
		if (this.op) this.op.toggle(event)
	}
	hideParams() {
		if (this.op) this.op.hide()
	}
	onChange: (conf: DashboardConfiguration) => {}
	public static defaultConfig: DashboardConfiguration = {
		fixedHeader: false,
		x: null,
		y: null,
		resizeEnabled: false,
		rows: 1,
		cols: 2,
		tipo: "estado-reservas",
		params: { periodo: "1", filtro: new FiltroReserva("filtro_estado_reservas_home", 0, 100000000).json }
	}

	@ViewChild("op", { static: true }) op: OverlayPanel
	public configuracionEntorno: ConfiguracionEntorno
	periodoSeleccionado: Descriptivo
	constructor(
		private reporteService: ReservaService,
		private authService: AuthService,
		private authAG: ReservasAG,
		private configuracionEntornoService: ConfiguracionEntornoService,
		private translateService: TranslateService,
		public puntoDeVentaService: PuntoDeVentaService
	) {
		this.configuracionEntornoService.getConfiguracionObs.subscribe((c) => (this.configuracionEntorno = c))
		//this.fechaDesde = moment().subtract(3, "months").startOf("date").toDate()
	}
	ngAfterViewInit(): void {
		if (this._config.params["periodo"] && this.selectorPeriodo) {
			this.selectorPeriodo.seleccionarPeriodoCodigo(this._config.params["periodo"])
		}
	}
	textoPeriodo: string
	public _config: DashboardConfiguration
	public get config(): DashboardConfiguration {
		return this._config || EstadoReservasComponent.defaultConfig
	}
	@Input()
	public set config(v: DashboardConfiguration) {
		this._config = v
		if (v.params && v.params["filtro"] && !this.filtro) {
			this.filtro = new FiltroReserva("filtro_estado_reservas_home", 0, 100000000)
			this.filtro.soloFacturadas = true
			this.filtro.setMultiple(v.params["filtro"])
			this.filtro.estados = [new Descriptivo("A", "A"), new Descriptivo("C", "C"), new Descriptivo("F", "F")]
			this.registrarFiltro()
		}
		if (this._config?.params["periodo"]) {
			if (!this.selectorPeriodo)
				setTimeout(() => {
					this.selectorPeriodo && this.selectorPeriodo.seleccionarPeriodoCodigo(this._config.params["periodo"])
				}, 100)
			else this.selectorPeriodo.seleccionarPeriodoCodigo(this._config.params["periodo"])
		}
	}

	public onPeriodoSeleccionado = (periodo: Descriptivo) => {
		if (periodo && this.periodoSeleccionado?.codigo != periodo?.codigo) {
			this.periodoSeleccionado = periodo
			this.config.params["periodo"] = periodo.codigo
			this.textoPeriodo = this.periodoSeleccionado ? this.periodoSeleccionado.descripcion : this.translateService.get("SELECCIONE_UN_PERIO_1")
			this.title = this.translateService.get("TOTALES")
			this.onChange(this.config)
		}
	}
	ngOnInit() {
		if (!this.authAG.esVisible(this.authService.getCurrentUser)) return
	}

	public getTitle = () => {
		return this.translateService.get("SALDO_RESERVAS")
	}

	public getTextoPeriodo() {
		return
	}
	private registrarFiltro = () => {
		const $this = this
		this.filtro.dataChange.pipe(filter((d) => d != undefined)).subscribe((d) => {
			$this.customLoading.addLoadingCount()
			$this.config.params["filtro"] = d.json
			$this.onChange && $this.onChange(this.config)
			$this.reporteService
				.getTotales(d)
				.then((r: Totales) => {
					$this.data = r
				})
				.finally(() => $this.customLoading.susLoadingCount())
		})
		$this.filtro.forceUpdate()
	}
}
