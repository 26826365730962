<div class="profile" [ngClass]="{'profile-expanded':active}">
    <div style="width:100%; height:6em; overflow:hidden">
        <profile-badge [url]="mainLogo" [disabled]="true"> </profile-badge>
    </div>

</div>
<div class="usuario" *ngIf="isMobile()">
    <profile-badge [url]="usuario?.imagen?.picPath" class="small-badge circular desktop-badge" (click)="verUsuario()">
    </profile-badge>
    <span *ngIf="app.usuario" class="profile-name">{{app.usuario?.nombreCompleto}}</span>
</div>
