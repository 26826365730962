import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { share } from "rxjs/operators"
import { DescriptivosService } from "src/app/common/services/descriptivos.service"
import { Cobranza } from "src/app/model/Cobranza"
import { MonedaEntorno } from "../app.module"
import { Comprobante } from "../model/Comprobante"
import { Moneda } from "../model/Moneda"
import { Cobro } from "../model/cobros/Cobro"
import { LoadingService } from "./../common/services/loading-data-service.service"
import { FiltroComprobante } from "./../model/FiltroComprobantes"
import { Totales } from "./../model/Totales"

@Injectable({
	providedIn: "root"
})
export class CobranzaService extends DescriptivosService<Cobranza> {
	public accionAnular(documento: Cobranza) {
		return this.anular(documento).then((c: Cobranza) => {
			return c
		})
	}

	public esAccionAnular(documento: Cobranza): boolean {
		return true
	}

	public baseName(): string {
		return "cobranza"
	}
	public parseToEnt(data: any): Cobranza {
		return Cobranza.fromData(data)
	}
	public newEnt(): Cobranza {
		const c = new Cobranza()
		c.moneda = MonedaEntorno() || Moneda.PESOS
		return c
	}
	public aprobar(c: Cobranza, customLoading?: LoadingService): Promise<Cobranza> {
		let $this = this
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL() + "aprobar", c)).then(
			(r) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return this.parseSingle(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	public rechazar(c: Cobranza, customLoading?: LoadingService): Promise<Cobranza> {
		let $this = this
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL() + "rechazar", c)).then(
			(r) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return this.parseSingle(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	public anular(c: Cobranza) {
		this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL() + "anular", c)).then(
			(r) => {
				this.loadingService.susLoadingCount()
				return this.parseSingle(r)
			},
			(e) => this.handleError(e)
		)
	}
	getResumen(filter: FiltroComprobante = new FiltroComprobante(null, 0, 100), customLoading?: LoadingService): Promise<Cobranza[]> {
		let $this = this
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL() + "resumen", filter.json)).then(
			(r) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return this.parse(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	getByReserva(idReserva: number, customLoading?: LoadingService): Promise<Cobranza[]> {
		let $this = this
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL() + "byReserva/" + idReserva)).then(
			(r: any) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return this.parse(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	getByComprobante(idComprobante: number, customLoading?: LoadingService): Promise<Cobranza[]> {
		let $this = this
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL() + "byComprobante/" + idComprobante)).then(
			(r) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return this.parse(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	public getTotales(filtro: FiltroComprobante, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("totales"), filtro.json).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				const tot = new Totales()
				r.forEach((t) => tot.agregarTotal(t))
				return tot
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	public async devolver(cobranza: Cobranza, comprobante: Comprobante, customLoading?: LoadingService): Promise<any> {
		cobranza.cobros.forEach((c: Cobro) => {
			c.monto = c.monto * -1
		})
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return await lastValueFrom(this.http.post(this.getBaseURL("devolver"), { comprobante: comprobante, cobranza: cobranza }).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r
			},
			(e) => this.handleError(e, customLoading)
		)
	}
}
