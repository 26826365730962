<div class="gestor-moneda content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'Moneda' | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="MonedaForm"
		[multilenguaje]="false"
		(onEditar)="editar($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #MonedaForm="ngForm" id="Monedaform">
				<div class="grid" *ngIf="item">
					<div class="col-4 lg:col-1">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<input
								pInputText
								[(ngModel)]="item.codigo"
								tooltipPosition="bottom"
								pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
								type="$TYPE"
								#codigo="ngModel"
								name="codigo"
								[readonly]="readonly"
							/>
							<label>{{ "CODIGO" | translate }}</label>
						</span>
						<error-tag [model]="codigo"></error-tag>
					</div>

					<div class="col-8 lg:col-4">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="$TYPE"
								#descripcion="ngModel"
								name="descripcion"
								[readonly]="readonly"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>
					<div class="col-4 lg:col-2">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'SIMBOLO_PH' | translate }}"
								[(ngModel)]="item.simbolo"
								tooltipPosition="bottom"
								pTooltip="{{ 'SIMBOLO_TOOLTIP' | translate }}"
								type="text"
								#simbolo="ngModel"
								name="simbolo"
								[readonly]="readonly"
							/>
							<label>{{ "SIMBOLO" | translate }}</label>
						</span>
						<error-tag [model]="simbolo"></error-tag>
					</div>
					<div class="col-4 lg:col-2">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'CODIGOAFIP_PH' | translate }}"
								[(ngModel)]="item.codigoAfip"
								tooltipPosition="bottom"
								pTooltip="{{ 'CODIGOAFIP_TOOLTIP' | translate }}"
								type="text"
								#codigoAfip="ngModel"
								name="codigoAfip"
								[readonly]="readonly"
							/>
							<label>{{ "CODIGOAFIP" | translate }}</label>
						</span>
						<error-tag [model]="codigoAfip"></error-tag>
					</div>

					<div class="col-4 lg:col-2 checkbox-container">
						<p-checkbox
							[(ngModel)]="item.esDefault"
							[binary]="true"
							name="esDefault"
							[readonly]="readonly"
							label="{{ 'ESDEFAULT' | translate }}"
						></p-checkbox>
					</div>

					<div class="col-4 lg:col-1">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'PESO_PH' | translate }}"
								[(ngModel)]="item.peso"
								tooltipPosition="bottom"
								pTooltip="{{ 'PESO_TOOLTIP' | translate }}"
								type="number"
								#peso="ngModel"
								name="peso"
								[readonly]="readonly"
							/>
							<label>{{ "PESO" | translate }}</label>
						</span>
						<error-tag [model]="peso"></error-tag>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
