import { BehaviorSubject, filter } from "rxjs"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { ServicioAbstract } from "src/app/common/services/service.service"
import { IdiomaEntorno } from "../app.module"
import { GlobalInjector } from "../common/GlobalInjector"
import { Filtro } from "../common/model/Filtro"
import { Parametrico } from "../common/model/Parametrico"
import { Identificable } from "./../common/model/Identficable"
import { Novedad } from "./../model/Novedad"
import { AuthService } from "./auth.service"
import { CacheService } from "./cache.service"
import { IActualizable, NovedadService } from "./novedades.service"

export abstract class BufferedService<E extends Identificable> extends ServicioAbstract<E> implements IActualizable {
	addToCache<E extends Identificable>(idioma: string, r: E) {
		this.cacheService.create(this.baseName(), idioma, r)
	}
	public cantidad: number = 0
	protected cacheService: CacheService
	private _ultimaNovedad: Novedad

	protected _dataBS: BehaviorSubject<any[]> = new BehaviorSubject([])
	public esMultilenguaje() {
		return false
	}
	public get data(): BehaviorSubject<any[]> {
		if (!this.cacheService.isLoaded(this.baseName())) {
			this.init().then((r) => this._dataBS.next(r))
		}
		return this._dataBS
	}
	public set data(v: BehaviorSubject<any[]>) {
		this._dataBS = v
	}

	constructor(protected novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(messServ)
		this.cacheService = GlobalInjector.InjectorInstance.get<CacheService>(CacheService)
		if (this.novedadesService && this.baseName()) {
			this.novedadesService.registrarObservador(this.baseName(), this)
		}
		authService.onLogout.pipe(filter((v) => v === true)).subscribe((v) => {
			this.destroy()
		})
	}
	public count = (filtro: Filtro = new Filtro(null, {}, 0, 100000), customLoading?: LoadingService): Promise<number> => {
		const f: Filtro = filtro.clonar()
		f.setMultiple({
			page: 0,
			size: 1000000
		})
		return this.getData(f, customLoading).then((r) => r.length)
	}
	public init() {
		return this.loadData(new LoadingService())
	}
	public destroy() {
		this.cacheService.destroy(this.baseName())
	}
	public getTotalCount(idioma: string = IdiomaEntorno()) {
		const idiomaSafe = idioma ? idioma.toLowerCase() : IdiomaEntorno()
		return this.getCache(idiomaSafe)?.length || 0
	}
	public getData(filtro: Filtro = new Filtro(null, {}, 0, 10000, "id"), customLoading?: LoadingService, idioma: string = IdiomaEntorno()): Promise<E[]> {
		const idiomaSafe = idioma ? idioma.toLowerCase() : IdiomaEntorno()
		if (this.cacheService.isUpdating(this.baseName(), idiomaSafe))
			return this.cacheService.getUpdating(this.baseName(), idiomaSafe).then((r) => (filtro ? filtro.apply(r) : r))
		if (!this.cacheService.isLoaded(this.baseName(), idiomaSafe))
			return this.loadData(this.loadingService, idiomaSafe).then((r) => (filtro ? filtro.apply(r) : r))
		return Promise.resolve(filtro ? this.paginado(filtro, filtro.apply(this.getCache(idiomaSafe))) : this.getCache(idiomaSafe))
	}
	public paginado(f: Filtro = new Filtro(null, {}, 0, 1000000), r: E[]) {
		if (!f["isLazy"]) return r
		const from = f.page * f.size > r.length ? 0 : f.page * f.size
		const to = from + f.size > r.length ? r.length : from + f.size
		return r.slice(from, to)
	}
	async completeData(filter: Filtro = new Filtro(null, {}, 0, 10000, "id"), customLoading?: LoadingService, idioma: string = IdiomaEntorno()): Promise<E[]> {
		const idiomaSafe = idioma ? idioma.toLowerCase() : IdiomaEntorno()
		const size = filter?.size || 9000
		const page = filter?.page || 0
		let resultado = this.getCache(idiomaSafe)?.length ? this.getCache(idiomaSafe) : []
		if (resultado.length == 0) resultado = await this.fill(filter, customLoading, idiomaSafe)
		resultado = (filter ? filter.apply(resultado) : resultado) || []
		const from = page * size
		const to = from + size > resultado.length ? resultado.length : from + size
		return Promise.resolve(resultado.slice(from, to))
	}
	protected fill(filtro = new Filtro(null, {}, 0, 10000, "id"), customLoading?, idioma: string = IdiomaEntorno()) {
		const idiomaSafe = idioma ? idioma.toLowerCase() : IdiomaEntorno()
		return super
			.getAll(filtro, customLoading, idiomaSafe)
			.then((r) => {
				this.setCache(this.parse(r), idiomaSafe)
				this.data.next(r)
				return r
			})
			.catch((e) => {
				this.setCache([], idiomaSafe)
				console.error(e)
				return []
			})
	}
	findAndUpdate(r: E, addIfabsent: boolean = false, idioma: string = IdiomaEntorno()) {
		const idiomaSafe = idioma ? idioma.toLowerCase() : IdiomaEntorno()
		const index = this.getCache(idioma).findIndex((e) => e.id == r.id)
		if (index < 0) {
			if (addIfabsent) {
				this.addToCache(idiomaSafe, r)
			} else return
		} else {
			this.getCache(idiomaSafe)[index] = r
		}

		this.data.next(this.getCache(idiomaSafe))
	}
	protected getCache(idioma: string = IdiomaEntorno()) {
		return [...this.cacheService.getCache(this.baseName(), idioma)]
	}
	protected setCache(data: E[], idioma: string = IdiomaEntorno()) {
		const i = idioma.toLowerCase()
		this.cacheService.setCache(this.baseName(), data, i)
	}

	updateById(id: number, customLoadingService?: LoadingService, idioma: string = IdiomaEntorno()) {
		return super.getById(id, customLoadingService, idioma).then((r) => {
			this.findAndUpdate(this.parseSingle(r), true, idioma)
		})
	}
	public getAll(filter?: Filtro, customLoading?: LoadingService, idioma: string = IdiomaEntorno()): Promise<E[]> {
		const i = (filter && filter["idioma"]) || idioma
		const idiomaSafe = i ? i.toLowerCase() : IdiomaEntorno()
		return this.getData(filter, customLoading, idiomaSafe)
	}
	public forceRefresh() {
		this.cacheService.destroy(this.baseName())
		this.loadData(new LoadingService())
	}
	public next = (n: Novedad) => {
		// if (n && n.key != this._ultimaNovedad?.key) {
		const $this = this
		const idioma = n.idioma || IdiomaEntorno()
		if (n.idEntidad && $this.getCache(idioma).some((e) => e.id == n.idEntidad)) {
			super.getById(n.idEntidad, new LoadingService()).then((r) => {
				$this.data.next(this.cacheService.udpdate(this.baseName(), idioma, r))
			})
		} else if (!n.idEntidad) {
			$this.loadData(null, idioma)
		} else if (n.tipoOperacion === "D") {
			$this.data.next(this.cacheService.eliminar(n.tipo, n.idioma, n.idEntidad))
		} else {
			super.getById(n.idEntidad, new LoadingService(), idioma).then((r) => {
				if (n.idEntidad && !$this.getCache(idioma).some((e) => e.id == n.idEntidad)) {
					$this.addToCache(idioma, r)
					$this.data.next($this.getCache(idioma))
				}
			})
		}
		// }
	}
	public async guardarConArchivo(e: E, file: File, customLoading?: LoadingService, idioma: string = IdiomaEntorno()) {
		const id = e.id
		var p = super.guardarConArchivo(e, file, customLoading).then((v) => {
			if (e["default"] || e["esDefault"]) {
				this.forceRefresh()
			} else {
				if (id) this.data.next(this.cacheService.udpdate(this.baseName(), idioma, v))
				else if (!this.getCache(idioma).some((e) => e.id == v.id)) {
					this.data.next(this.cacheService.create(this.baseName(), idioma, v))
				}
			}
			return v
		})
		return p
	}
	public async guardar(e: E, customLoading?: LoadingService, idioma: string = IdiomaEntorno()) {
		const id = e.id
		var p = super.guardar(e, customLoading).then((v) => {
			if (e["default"] || e["esDefault"]) {
				this.forceRefresh()
			} else {
				if (id) this.data.next(this.cacheService.udpdate(this.baseName(), idioma, v))
				else if (!this.getCache(idioma).some((e) => e.id == v.id)) {
					this.data.next(this.cacheService.create(this.baseName(), idioma, v))
				}
			}
			return v
		})
		return p
	}

	public async eliminar(e: number, customLoading?: LoadingService, idioma: string = IdiomaEntorno()) {
		var p = super.eliminar(e, customLoading).then(async (v) => {
			const idiomaSafe = idioma ? idioma.toLowerCase() : IdiomaEntorno()
			if (v["fueBorradoLogico"]) {
				const d = await this.getById(e)
				d["habilitado"] = false
				this.findAndUpdate(d, d !== undefined, idioma)
			} else {
				this.data.next(this.cacheService.eliminar(this.baseName(), idiomaSafe, e))
			}
			return v
		})
		return p
	}

	getById(id: number, customLoading?: LoadingService, idioma: string = IdiomaEntorno()): Promise<E> {
		if (!id) return Promise.reject(undefined)
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		const idiomaSafe = idioma ? idioma.toLowerCase() : IdiomaEntorno().toLowerCase()
		return this.getData(undefined, customLoading, idioma)
			.then((r) => this.parseSingle(r.filter((e) => e.id == id)[0]))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}
	getByIdForced(id: number, customLoading?: LoadingService, idioma: string = IdiomaEntorno()): Promise<E> {
		return super.getById(id, customLoading, idioma).then((r) => {
			const d = this.parseSingle(r)
			this.findAndUpdate(d, d !== undefined, idioma)
			return d
		})
	}

	protected fillData: (f, l, i) => Promise<any[]> = this.fill
	protected loadData(customLoading?: LoadingService, idioma: string = IdiomaEntorno()): Promise<E[]> {
		if (!this.cacheService.isUpdating(this.baseName(), idioma)) {
			const $this = this
			this.cacheService.setUpdating(
				new Promise((resolve, reject) => {
					this.fillData(new Filtro(null, {}, 0, 9000), customLoading, idioma)
						.then((r: any[]) => {
							const idiomaSafe = idioma ? idioma.toLowerCase() : IdiomaEntorno()
							$this.setCache([...r.map((a) => this.parseToEnt(a))].sort(Parametrico.comparador), idiomaSafe)
							$this.data.next($this.getCache(idiomaSafe))
							resolve($this.getCache(idiomaSafe))
						})
						.catch((e) => {
							reject(e)
						})
						.finally(() => {
							if (customLoading) {
								customLoading.susLoadingCount()
							} else $this.loadingService.susLoadingCount()
						})
				}),
				this.baseName(),
				idioma
			)
		}
		return this.cacheService.getUpdating(this.baseName(), idioma)
	}
}
