import { Injectable } from "@angular/core"
import { Filtro } from "src/app/common/model/Filtro"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { MessagesService } from "../common/services/messages-data-service.service"
import { TipoNotificacion } from "../model/TipoNotificacion"
import { LoadingService } from "./../common/services/loading-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"

@Injectable()
export class TipoNotificacionService extends ParametricoService<TipoNotificacion> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "tiponotificacion"
	}
	public newEnt(): TipoNotificacion {
		return new TipoNotificacion()
	}
	public parseToEnt(data: any): TipoNotificacion {
		return TipoNotificacion.fromData(data)
	}
	public parser(e: any): TipoNotificacion {
		return TipoNotificacion.fromData(e)
	}
	public getRecordatorioPax(filter?: Filtro, customLoading?: LoadingService) {
		return this.getAll(filter, customLoading).then((r) => {
			return r.filter((t) => t.esRecordatorioPax)
		})
	}
}
