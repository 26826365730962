import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Caja } from "./Caja"
import { Moneda } from "./Moneda"

export class DefaultFormaPago {
	constructor(public moneda?: Moneda, public tipoOperacion?: string, public caja?: Descriptivo) {}
	public static fromData(data: any): DefaultFormaPago {
		if (!data) return null
		const o: DefaultFormaPago = new DefaultFormaPago(Moneda.fromData(data.moneda), data.tipoOperacion, Caja.fromData(data.caja))

		return o
	}
}
