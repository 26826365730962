<div class="listado-respuesta-encuesta-actividades content">
	<model-list
		[service]="service"
		[modelName]="'respuesta-encuesta-actividades'"
		[columns]="columns"
		[title]="'RespuestaEncuestaActividades_PLR' | translate"
		[(listado)]="listado"
		[isLazy]="true"
		[filtro]="filtro"
		[multilenguaje]="false"
		[hideHeader]="ocultarCabecera"
		[editable]="false"
		[borrable]="false"
		(seleccionChange)="updateSeleccion($event)"
		[readonly]="readonly"
		[onClick]="onClick"
		[permiteNuevo]="false"
		[acciones]="acciones"
		[filterContent]="customFilter"
	>
		<ng-template #customFilter>
			<div class="grid">
				<div class="col-12">
					<div class="p-inputgroup">
						<span class="p-float-label">
							<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
							<label>{{ "BUSCAR" | translate }}...</label>
						</span>
						<button type="button" pButton icon="pi pi-search"></button>
					</div>
				</div>
				<div class="col-12">
					<selector-multiple-chips
						[(ngModel)]="filtro.productos"
						name="productos"
						[servicio]="productoService"
						[permiteNuevo]="false"
						[titulo]="'PRODUCTOS' | translate"
					>
					</selector-multiple-chips>
				</div>
				<div class="col-6 p-fluid field">
					<span class="p-float-label">
						<p-calendar [(ngModel)]="filtro.fecha" dateFormat="dd/mm/yy" appendTo="body" name="fecha" [showIcon]="true"></p-calendar>
						<label>{{ "FECHA_ACTIVIDAD_DESDE" | translate }}</label>
					</span>
				</div>
				<div class="col-6 p-fluid field">
					<span class="p-float-label">
						<p-calendar [(ngModel)]="filtro.fechaHasta" dateFormat="dd/mm/yy" appendTo="body" name="fechaHasta" [showIcon]="true"></p-calendar>
						<label>{{ "FECHA_ACTIVIDAD_HASTA" | translate }}</label>
					</span>
				</div>
				<div class="col-12 botonera">
					<div class="grid">
						<div class="col text-center" *ngFor="let estado of estados">
							<button
								pButton
								[label]="estado?.descripcion"
								[ngClass]="{ 'no-seleccionado': !filtro?.tieneEstado(estado) }"
								[ngStyle]="{ 'border-bottom-color': estado?.color, 'background-color': estado?.color }"
								(click)="filtro.toggleEstado(estado)"
							></button>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</model-list>
</div>
