
import { Parametrico } from 'src/app/common/model/Parametrico';
import { ProfilePic } from 'src/app/common/model/ProfilePic';
import { environment } from 'src/environments/environment';


export class Idioma extends Parametrico {
    static DEFAULT: Idioma = new Idioma(null, "ES");
    static DEFAULT_CODIGO = this.DEFAULT.codigo;
    constructor(id?: number, codigo?: string, descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,
        public bandera: ProfilePic = new ProfilePic(null, 'assets/images/default_image.png'),
        esDefault?: boolean,


    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
    }
    public tipoParametro(): string {
        return "IdiomaLOW";
    }
    public static fromData(data: any): Idioma {
        if (!data) return null;
        const o: Idioma = new Idioma(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,

            ProfilePic.fromData(data.bandera),
            data.esDefault,
        );

        o.version = data.version; 
return o;

    }
    public get imagenUrl() {
        return this.imagen;
    }
    public get imagen() {
        return this.bandera ? (this.bandera.previewPicPath ? this.bandera.previewPicPath : this.bandera.picPathVersion) : 'assets/images/default_image.png';
    }

}