import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { BaseService } from "src/app/common/services/base.service"
import { Cotizacion } from "./../model/Cotizacion"
import { Moneda } from "./../model/Moneda"

@Injectable({
	providedIn: "root"
})
export class CotizacionService extends BaseService {
	public cotizaciones: Map<string, { date: Date; cotizacion: Cotizacion | Promise<Cotizacion> }> = new Map()

	public baseName(): string {
		return "cotizacion"
	}
	constructor() {
		super()
	}
	private updateSingle(monedaDesde: Moneda, monedaHasta: Moneda): Promise<Cotizacion> {
		return lastValueFrom(this.http.get(this.getBaseURL() + "?desde=" + monedaDesde.codigo + "&hasta=" + monedaHasta.codigo)).then((c) =>
			Cotizacion.fromData(c)
		)
	}
	async getCotizacion(monedaDesde: Moneda, monedaHasta: Moneda): Promise<Cotizacion> {
		const key = `${monedaDesde.codigo}-${monedaHasta.codigo}`
		if (this.cotizaciones.has(key)) {
			const cotizacion = this.cotizaciones.get(key)
			if (cotizacion.cotizacion instanceof Promise) {
				return cotizacion.cotizacion
			} else {
				return Promise.resolve(cotizacion.cotizacion)
			}
		} else {
			const cotizacionPromise = this.updateSingle(monedaDesde, monedaHasta)
			this.cotizaciones.set(key, { date: new Date(), cotizacion: cotizacionPromise })
			return cotizacionPromise
		}
	}
}
