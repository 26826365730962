import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { ServicioAbstract } from "src/app/common/services/service.service"
import { ItemReserva } from "src/app/model/ItemReserva"
import { LoadingService } from "../common/services/loading-data-service.service"
import { Idioma } from "../model/Idioma"
import { FiltroGrupo } from "../pages/reserva/gestor-reserva/FiltroGrupo"
@Injectable({
	providedIn: "root"
})
export class ItemReservaService extends ServicioAbstract<ItemReserva> {
	public baseName(): string {
		return "item-reserva"
	}
	public parseToEnt(data: any): ItemReserva {
		return ItemReserva.fromData(data)
	}
	public newEnt(): ItemReserva {
		return new ItemReserva()
	}

	public actualizarReserva(itemReserva: ItemReserva) {
		return lastValueFrom(this.http.put(this.getBaseURL("actualizar"), itemReserva)).then((r) => {
			return this.parseSingle(r)
		})
	}
	public getAll(
		filtro: FiltroGrupo = new FiltroGrupo("dummy"),
		customLoading?: LoadingService,
		idioma: string = Idioma.DEFAULT_CODIGO
	): Promise<ItemReserva[]> {
		const $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		if (!filtro["idioma"]) filtro["idioma"] = idioma
		return lastValueFrom(this.http.post(this.getBaseURL("all"), filtro.json), { defaultValue: [] })
			.then((r) => $this.parse(r))
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}
}
