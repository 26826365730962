<model-help [title]="'Listados / Generalidades'">
    <h3>Encabezados y Filtros</h3>

    <p>El encabezado de un listado generalmente tiene las siguientes información y funcionalidades</p>
    <img src="assets/images/help/listado-general.jpg" />
    <p>El ícono <i class="pi pi-plus"></i> nos permite agregar un nuevo elemento al listado mediante el gestor correspondiente</p>
    <p>El campo de "Búsqueda General" nos permite filtrar el listado mediante texto (palabra, nombre, etc.)</p>

    <p>
        La barra de <strong>Resumen de filtros</strong> nos dicen que filtros están aplicados al listado, podemos limpiarlos todos a la vez con el icono <i class="pi pi-trash"></i> ubicado al final de
        la barra. <br>
        Si hacemos click en la barra de filtros, se nos abre la pantalla complementaria siguiente.
    </p>
    <img src="assets/images/help/filtros.jpg" /><br><br>
    <p>La pantalla complementaria de Filtros, puede contener diferentes campos y opciones de acuerdo al listado donde estemos posicionados. <br>
    Puede incluir diferentes búsquedas por téxtos, rangos de fecha, estados, etc.</p>


    <p>El ícono <i class="pi pi-refresh"></i> refresca el listado, ya sea para limpiar una búsqueda o un filtro.</p>
    <p>Luego tenemos el ícono <i class="pi pi-filter"></i> que despliega la siguiente pantalla</p>
    <img src="assets/images/help/filtro.jpg" />
    <p>
        en este apartado de filtros las opciones pueden varias dependiendo del listado, pero siempre tenemos las opciones de <i class="pi pi-trash"></i> para limpiar el filtro;
        <i class="pi pi-refresh"></i> para refrescar y el campo de búsqueda para filtrar por texto.
    </p>

    <h3>Cuerpo</h3>

    <p>El cuerpo de listado generalmente contiene las columnas de mayor uso para la entidad correspondiente.</p>

    <img src="assets/images/help/listado-cuerpo.jpg" />

    <p>Los titulos de las columnas, permiten ordenar el contenido alfabeticamente de acuerdo a los datos que contiene (orden ascendente o descendente <i class="pi pi-fw pi-sort-alt"></i>).</p>
    <p>
        También encontrarnos con el ícono de exportación en excel. <i class="pi pi-file-excel"></i> Que nos descargará automáticamente una planilla de cálculo con todos los datos del listado actual.
    </p>
    <p>
        En algunos listados nos encontramos con las columnas: <strong> "Contactos", "Email" ó "Télefono" </strong>los cuales contienen links directos para hacer llamados o enviar un mail de manera
        inmediata.
    </p>

    <p>
        Las acciones rápidas <i class="pi pi-pencil"></i> (Editar) | <i class="pi pi-trash"></i> (Eliminar) <i class="pi pi-eye"></i>(Ver) estarán disponibles en todos los listados
        <strong>excepto cuando las acciones rápidas sean 4 o más</strong>; en ese caso aparecerá el icono <i class="pi pi-cog"></i> del cual se desplegará el listado de acciones disponible.
    </p>
</model-help>
