import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ProfilePic } from 'src/app/common/model/ProfilePic';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { TipoPuesto } from "src/app/model/TipoPuesto";
import { TipoPuestoService } from 'src/app/services/tipo-puesto.service';
import { SessionComponent } from './../../../common/components/session-component.component';
import { CategoriaPuesto } from './../../../model/CategoriaPuesto';
import { CategoriaPuestoService } from './../../../services/categoria-puesto.service';



@Component({
	selector: 'gestor-tipo-puesto',
	templateUrl: 'gestor-tipo-puesto.component.html',
	styleUrls: ['gestor-tipo-puesto.component.less',]
})

export class GestorTipoPuestoComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription;

	private _item: TipoPuesto;
	public get item(): TipoPuesto {
		return this._item;
	}
	@Input()
	public set item(v: TipoPuesto) {
		this._item = v;
		if (this.categoriaSeleccionada)
			this._item.categoria = this.categoriaSeleccionada;
	}

	@Input()
	public goBack: boolean = true;
	@Input()
	public isModal: boolean = false;

	@Input()
	public readonly: boolean = false;

	@Output()
	public onGuardado: EventEmitter<TipoPuesto> = new EventEmitter<TipoPuesto>();

	@Output()
	public onCancelado = new EventEmitter();

	public categoriaSeleccionada: CategoriaPuesto;
	private _categoria: string;
	public get categoria(): string {
		return this._categoria;
	}
	@Input()
	public set categoria(v: string) {
		this._categoria = v;
		if (this._categoria)
			this.categoriaService.getByCodigo(v).then(r => {
				this.categoriaSeleccionada = r;
			})
	}


	public file: File;
	public onPicSelected(file: File) {
		const reader = new FileReader();
		let $this = this;
		if (file.size > 1000000) {
			return this.error(this.translateService.get('EL_ARCHIVO_NO_PUEDE_16'));
		}
		this.file = file;
		reader.onload = (e: any) => {
			$this.item.icono = new ProfilePic(null, e.target.result);
		};
		reader.readAsDataURL(file);
	}

	constructor(public service: TipoPuestoService,
		private route: ActivatedRoute,
		private confService: ConfirmationService,
		public categoriaService: CategoriaPuestoService,
		messagesService: MessagesService,
	) {
		super(messagesService);
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item);
	}
	public handleCancelar(item) {
		this.onCancelado.emit();
	}

	public isValid() {
		return true;
	}
	ngOnInit() {
		if (!this.route.snapshot.url.some(u => u.path == 'tipo-puesto')) return;
		this.routeSub = this.route.queryParams.subscribe(params => {
			let id: number = <number>params['id'];
			if (!this.service) return;
			if (id) {
				this.service.getById(id).then(r => {
					this.item = this.service.parseSingle(r);

				})
			} else {
				this.item = this.service ? this.service.newEnt() : null;
				this.item.categoria = this.categoriaSeleccionada;
			}

		});


	}


	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe();
	}
}