import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { UsuarioService } from './../../common/services/usuario.service';
import { ConfiguracionEntornoService } from 'src/app/services/configuracion-entorno.service';
import { DEF_LOGO } from 'src/app/app.module';

@Component({
	selector: 'password-reset',
	templateUrl: 'password-reset.component.html',
	styleUrls: ['./../login/login.component.less']
})
export class PasswordResetComponent extends SessionComponent implements OnInit {
	public confPass: string = "";
	public pass: string = "";
	public valido: boolean = false;
	private token: string = "";

    public mainLogo = DEF_LOGO;

	constructor(private usuarioService: UsuarioService, private router: Router, private route: ActivatedRoute, messagesService: MessagesService,
		    private confEntorno:ConfiguracionEntornoService
		) {
			super(messagesService, "reset-password")
			this.confEntorno.getConfiguracion().then(c=>{
				this.mainLogo = c.profilePic?.picPath || DEF_LOGO;
			})
		
	}
	ngOnInit() {
		this.route.params.pipe(first()).subscribe(params => {
			if (params["token"]) {
				this.token = params["token"];
				this.usuarioService.validarToken(this.token).then(r => {
					if (r) {
						this.valido = true;

					}
				})
			}
		})

	}

	actualizarPassword() {
		if (this.confPass !== this.pass) {
			return this.error("No coinciden los valores ingresados");
		}
		this.finalizado = true;
		this.usuarioService.actualizarPassword(this.pass, this.token).then(r => {
			if (r) {
				this.success("Contraseña actualziada. Por favor vuelva a loguearse.")
				this.router.navigate(["login"]);
			} else {
				this.error("Hubo un error al actualizar la contraseña. Comuniquese con administración");

			}
		})

	}
}