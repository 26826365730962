<div class="estado-clientes flex align-items-center" style="height: 100%" pTooltip="{{ 'SALDO_A_LA_FECHA' | translate }}">
	<div class="col-fixed icon" style="height: 100%">
		<i class="fa fa-dollar fa-2x bold w-100"></i>
	</div>
	<div class="col text-center">
		<strong class="font-large" *ngIf="data">{{ data.totalSaldo | number : "1.2-2" }}</strong>
	</div>
</div>

<!--p-overlayPanel #op [dismissable]="true" appendTo="body" [styleClass]="'options'">
	<span class="close" (click)="$event.stopPropagation(); hideParams()" style="position: absolute; top: 3px; right: 3px; cursor: pointer; padding: 0.25em"
		><i class="pi pi-times"></i
	></span>
	<div class="grid">
		<selector-periodo-dinamico #selectorPeriodo (onPeriodoChange)="onPeriodoSeleccionado($event)" [filtro]="filtro"> </selector-periodo-dinamico>
	</div>
</p-overlayPanel-->
