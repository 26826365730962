import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { Subscription } from "rxjs"
import { MonedaEntorno } from "src/app/app.module"
import { Caja } from "src/app/model/Caja"
import { Cliente } from "src/app/model/Cliente"
import { ComprobanteResumen } from "src/app/model/Comprobante"
import { FacturaResumen } from "src/app/model/FacturaResumen"
import { FiltroComprobante } from "src/app/model/FiltroComprobantes"
import { FormaPago } from "src/app/model/FormaPago"
import { ListadoDocumentos } from "src/app/model/ListadoDocumentos"
import { Moneda } from "src/app/model/Moneda"
import { TipoCuotasTarjeta } from "src/app/model/TipoCuotasTarjeta"
import { Cobro } from "src/app/model/cobros/Cobro"
import { CobroCompensacionSaldo } from "src/app/model/cobros/CobroCompensacionSaldo"
import { CobroTarjetaCredito } from "src/app/model/cobros/CobroTarjetaCredito"
import { CajaService } from "src/app/services/caja.service"
import { ComprobanteService } from "src/app/services/comprobante.service"
import { CotizacionService } from "src/app/services/cotizacion.service"
import { FacturaResumenService } from "src/app/services/factura-resumen.service"
import { FormaPagoService } from "src/app/services/forma-pago.service"
import { MonedaService } from "src/app/services/moneda.service"
import { CobroConCaja } from "../../../model/cobros/CobroConCaja"
import { CobroEnEfectivo } from "../../../model/cobros/CobroEnEfectivo"
import { CobroMercadoPago } from "../../../model/cobros/CobroMercadoPago"
import { CobroNotaCredito } from "../../../model/cobros/CobroNotaCredito"
import { MessagesService } from "../../services/messages-data-service.service"
import { TranslateService } from "../../services/translate.service"
import { round } from "../../utils/MathUtils"
import { FileData } from "./../../../model/FileData"
import { TipoTarjetaCredito } from "./../../../model/TipoTarjetaCredito"
import { AuthService } from "./../../../services/auth.service"
import { TipoCuotasTarjetaService } from "./../../../services/tipo-cuotas-tarjeta.service"
import { TipoTarjetaCreditoService } from "./../../../services/tipo-tarjeta-credito.service"
import { FileService } from "./../../services/file.service"
import { IConCobros, IValor } from "./IConCobros"

@Component({
	selector: "abm-cobros",
	templateUrl: "./abm-cobros.component.html",
	styleUrls: ["./abm-cobros.component.less"]
})
export class AbmCobrosComponent implements OnInit, OnDestroy {
	@ViewChild("inputFile")
	public inputFile: ElementRef
	public formasPago: FormaPago[] = []
	public loading: boolean = true
	private itemEditado: IValor
	public cajas: Caja[] = []
	public cajaCompensacion: Caja
	public tarjetas: TipoTarjetaCredito[]
	public cuotas: TipoCuotasTarjeta[]
	public comprobantes: ComprobanteResumen[] = []
	monedaDefault = MonedaEntorno()
	@Input()
	public permitirNota: boolean = false
	valores: IValor[] = []
	@Input()
	public readonly: boolean = false

	private _clienteAsociado: number
	facturas: FacturaResumen[] = []
	importeImputar: number = 0
	public get clienteAsociado(): number {
		return this._clienteAsociado
	}
	@Input()
	public set clienteAsociado(v: number) {
		this._clienteAsociado = v
		this.getFormasPago()
		if (v) {
			let f = new FiltroComprobante("")
			f.cliente = new Cliente(v)
			f.fechaDesde = null
			f.fechaHasta = null
			this.comprobanteService.getImpagos(f).then((r) => {
				this.comprobantes = r
				this.comprobantes?.forEach((c) => (c.importe = c.saldoTransformado))
			})
		}
	}

	private _proveedorAsociado: number
	public get proveedorAsociado(): number {
		return this._proveedorAsociado
	}
	@Input()
	public set proveedorAsociado(v: number) {
		this._proveedorAsociado = v
		this.getFormasPago()

		if (v) {
			this.facturaService.getFacturasImpagas(v, this.item.centroCosto?.id).then((fs) => {
				this.facturas = fs
				this.facturas?.forEach((f) => (f.importe = f.saldoTransformado))
			})
		} else {
			this.facturas = []
		}
	}

	public opcionesEdicion = [
		{ tooltip: "Porcentaje", value: "P", icon: "pi pi-percentage" },
		{ value: "V", tooltip: "Importe fijo", icon: "pi pi-dollar" }
	]
	private _item: IConCobros
	public get item(): IConCobros {
		return this._item
	}
	@Input()
	public set item(v: IConCobros) {
		this._item = v
		this.actualizarValores()
	}
	@Input()
	public admiteGastos: boolean = false
	@Input()
	public mostrarTotales: boolean = false
	@Input()
	public devolucion = false
	@Input() public monedaOrigen: Moneda
	@Input()
	public verMoneda: boolean = false
	private _monedaRef: Moneda = MonedaEntorno()
	public get monedaRef(): Moneda {
		return this._monedaRef
	}
	@Input()
	public set monedaRef(v: Moneda) {
		this._monedaRef = v
	}
	@Input()
	public cotizacion: number = 1

	private sus: Subscription
	@Input()
	public unico: boolean = false
	@Input()
	public totalACobrar: number = 0

	mostrarCompensacionSaldo: boolean = false

	private _itemsCompensacion: ComprobanteResumen[] | FacturaResumen[]
	public get itemsCompensacion(): ComprobanteResumen[] | FacturaResumen[] {
		return this._itemsCompensacion
	}
	public set itemsCompensacion(v: ComprobanteResumen[] | FacturaResumen[]) {
		this._itemsCompensacion = v
	}

	constructor(
		public formaPagoService: FormaPagoService,
		public fileService: FileService,
		public monedaService: MonedaService,
		public cajasService: CajaService,
		public router: Router,
		public tipoTarjetaCreditoService: TipoTarjetaCreditoService,
		public tipoCuotasTarjetaService: TipoCuotasTarjetaService,
		private messsageService: MessagesService,
		private translateService: TranslateService,
		private authService: AuthService,
		private cotizacionService: CotizacionService,
		private facturaService: FacturaResumenService,
		private comprobanteService: ComprobanteService
	) {}

	ngOnInit() {
		if (!this.monedaRef) this.monedaRef = this.item?.moneda ? this.item.moneda : this.monedaService.default
		if (!this.monedaOrigen) this.monedaOrigen = this.item?.moneda ? this.item.moneda : this.monedaService.default
		this.getFormasPago()
		this.cajasService.getAutorizados().then((c) => {
			this.cajaCompensacion = c.find((c) => c.codigo == Caja.CODIGO_COMPENSACION_SALDO)
			this.cajas = c.filter((c) => c.codigo != Caja.CODIGO_COMPENSACION_SALDO)
		})
	}

	getFormasPago() {
		this.formaPagoService.getHabilitados().then((r) => {
			this.formasPago = r.filter(
				(fp) => (this.permitirNota == true || fp.codigo != "NC") && (this.clienteAsociado || this.proveedorAsociado || fp.codigo != "CS")
			)
		})
	}
	ngOnDestroy() {
		if (this.sus) this.sus.unsubscribe()
	}

	public adjuntar(item: IValor, event?) {
		event.stopPropagation()
		this.itemEditado = item
		this.inputFile.nativeElement.click()
	}
	handleFileInput(files: FileList) {
		this.fileService.postFile(files[0], "adjunto").then((r) => {
			this.itemEditado.adjunto = r
		})
	}

	public verNota(event, idComprobante) {
		event?.stopPropagation && event.stopPropagation()
		this.router.navigate(["comprobante/vista"], {
			queryParams: { id: idComprobante }
		})
	}
	public verAdjunto(file: FileData, event?) {
		window.open(file.url, "_blank")
		event.stopPropagation()
	}
	public quitarAdjunto(item: IValor, event) {
		event.stopPropagation()
		item.adjunto = null
	}
	public getCotizacion() {
		return this.cotizacion && this.cotizacion > 0 ? this.cotizacion : 1
	}
	public agregarCobro = async (f: FormaPago) => {
		if (this.readonly) return

		let total = 0
		total = this.totalAPagarLocal - this.total
		const cajaDefault = f.getCajaDefault(this.item.tipoOperacion, this.item?.moneda || this._monedaRef || this.monedaEntorno)
		const caja = cajaDefault ? await this.cajasService.getById(cajaDefault.id) : null
		let cobro = null
		switch (f.codigo) {
			case "EF":
				cobro = new CobroEnEfectivo(null, f, null, total, caja, null, 1)
				this.actualizarValores()
				break
			case "MP":
				cobro = new CobroMercadoPago(null, f, null, total, caja, null, 1)
				this.actualizarValores()
				break
			case "TC":
				cobro = new CobroTarjetaCredito(null, f, null, total, caja, null, 1)
				this.actualizarValores()
				break
			case "NC":
				cobro = new CobroNotaCredito(null, f, null, total, null, 1)
				this.actualizarValores()
				break
			case "CS":
				this.mostrarCompensacionSaldo = true
				this.importeImputar = total
				break
			default:
				cobro = new CobroConCaja(null, f, null, total, caja, null, 1)
				this.actualizarValores()
				break
		}
		if (cobro) this.item.agregarCobro(cobro)
		if (caja && cobro) this.onSelectCaja(caja, cobro)
	}
	public updateIncluyeGatos(event, item: IValor) {
		if (!item.incluyeGasto) {
			item.importeGasto = 0
			item.porcentajeGasto = 0
			item.monto = item.montoBase
		}
	}
	public updateImporte(event, item: IValor) {
		item.porcentajeGasto = event
		item.importeGasto = (item.porcentajeGasto * item.montoBase) / 100
		item.monto = item.montoBase + (item.importeGasto || 0)
	}
	numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false
		}
		return true
	}

	actualizarValores() {
		this.valores = this.item.valores
	}

	get monedaEntorno() {
		return MonedaEntorno()
	}

	get totalAPagarLocal() {
		return (this.totalACobrar || this.item.totalALocal) + this.item.valores.map((v) => v.importeGasto * v.cotizacionSafe || 0).reduce((a, b) => a + b, 0)
	}
	onSelectCaja(caja: Caja, cobro: Cobro) {
		if (cobro?.id || caja.moneda?.codigo == cobro?.moneda?.codigo) return
		cobro.moneda = caja.moneda || this.monedaEntorno || Moneda.PESOS
		if (!caja?.moneda) {
			cobro.cotizacion = 1
			return
		}
		if (caja.moneda?.codigo == this.item.moneda?.codigo) {
			cobro.cotizacion = this.cotizacion || 1
			return
		}
		this.cotizacionService.getCotizacion(caja.moneda, this.monedaRef || MonedaEntorno()).then((c) => {
			cobro.cotizacion = c.valor
		})
	}

	onChangeCotizacion(cotizacion: number, cobro: Cobro) {
		if (!cotizacion || cotizacion == 0 || this.readonly) return
		cobro.montoBase = round(cobro.saldoBase / cotizacion, 2)
	}

	get total() {
		return this.item.valores.map((v) => v.montoALocal).reduce((a, b) => a + b, 0)
	}

	agregarCobroCompensacion() {
		const f = this.formasPago.find((fp) => fp.codigo == "CS")
		const i: any = this.itemsCompensacion
		const monto = i.reduce((a, b) => a + b.importe, 0)

		const index = this.item.valores?.findIndex((v) => v.tipoCobro == CobroCompensacionSaldo.TIPO)

		if (index >= 0) {
			this.item.valores.splice(index, 1)
		}

		let documentos = {}

		this.itemsCompensacion.forEach((i) => {
			documentos[i.id] = i.importe
		})

		let l = new ListadoDocumentos(documentos, Object.keys(documentos))

		if (monto != 0) {
			this.item.agregarCobro(new CobroCompensacionSaldo(null, f, null, monto, this.cajaCompensacion, this.monedaDefault, 1, null, l))
		}

		this.mostrarCompensacionSaldo = false
	}

	goToOp(id: number) {
		window.open(`/#/orden-pago/vista?id=${id}`, "_blank")
	}

	goToCobranza(id: number) {
		window.open(`/#/cobranza/vista?id=${id}`, "_blank")
	}

	public imputar() {
		if (this.importeImputar > 0) {
			this.itemsCompensacion = []
			let saldo = this.importeImputar
			let index = 0

			let items = this.comprobantes?.length ? this.comprobantes : this.facturas

			while (saldo > 0 && index < items.length) {
				if (items[index].saldoTransformado <= 0) {
					index++
					continue
				}
				let aQuitar = saldo < items[index].importe ? saldo : items[index].importe
				items[index].importe = aQuitar
				this.itemsCompensacion.push(items[index] as FacturaResumen & ComprobanteResumen)
				index++
				saldo -= aQuitar
			}
			// if (saldo > 0) {
			// 	this.item.pagoACuenta = saldo
			// }
		}
	}

	get totalSeleccionado() {
		let importe = 0
		this.itemsCompensacion?.forEach((element) => {
			importe += element.importe
		})

		return importe
	}

	onRowCompensacionSelect(event) {
		if (event.type == "checkbox") return

		let items: any[] = this.itemsCompensacion
		if (event.data.importe > this.totalAPagarLocal - this.totalSeleccionado + event.data.importe) {
			items.find((i) => i.id == event.data.id).importe = this.totalAPagarLocal - this.totalSeleccionado + event.data.importe
		}
	}

	onRowCompensacionUnselect(event) {
		if (this.clienteAsociado) {
			let c = this.comprobantes.find((c) => c.id == event.data.id)
			c.importe = c.importeTransformado
			this.comprobantes = [...this.comprobantes]
		} else {
			let f = this.facturas.find((f) => f.id == event.data.id)
			f.importe = f.importeTransformado
			this.facturas = [...this.facturas]
		}
	}

	quitarCobro(item, cobro: IValor) {
		item.quitarCobro(cobro)
	}
}
