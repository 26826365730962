import { EventInput } from "@fullcalendar/core"
import * as moment from "moment"
import { Descriptivo } from "./../common/model/Descriptivo"

export class Evento {
	static toEventInput(ev: Evento): EventInput {
		return {
			id: ev.id.toString(),
			title: ev.title,
			start: ev.start,
			end: ev.end,
			textColor: ev.textColor,
			borderColor: ev.borderColor,
			className: ev.className,
			backgroundColor: "#ffffff",
			extendedProps: ev.extendedProps
		}
	}

	public static AUSENCIA: Descriptivo = new Descriptivo("AUS", "Ausencia", 1)
	public static VENCIMIENTO_FACTURA: Descriptivo = new Descriptivo("FAC", "Vencimiento Factura", 2)
	public static BLOQUEO_VEHICULO: Descriptivo = new Descriptivo("BVE", "Bloqueo Vehículo", 3)
	public static NOTA: Descriptivo = new Descriptivo("NOT", "Nota", 4)
	public static DISPONIBILIDAD: Descriptivo = new Descriptivo("DIS", "Disponibilidad", 5)
	public static DUPLICAR_FACTRA: Descriptivo = new Descriptivo("DFC", "Factura programada", 6)
	public static DUPLICAR_COMPROBANTE: Descriptivo = new Descriptivo("DCC", "Comprobante Programado", 7)

	constructor(
		public id?: number,
		public title?: string,
		public start?: string,
		public end?: string,
		public extendedProps?: any,
		public textColor?: string,
		public borderColor?: string,
		public className?: string
	) {}

	public static fromData(data: any) {
		if (!data) return null

		let e = new Evento()
		e.id = data.id
		e.title = data.title
		e.start = moment(data.start).format("YYYY-MM-DD")
		e.extendedProps = { tipo: data.tipo, props: data.props }
		e.end = moment(data.end).add(1, "days").format("YYYY-MM-DD")
		e.textColor = data.textColor
		e.borderColor = data.borderColor
		e.className = e.extendedProps.tipo

		return e
	}
}
