<model-help [title]="'Reporte Previsión de Servicios'">
    <p>Reporte de Previsión de Servicios</p>

    <h3>Filtros</h3>
    Se pueden filtrar los datos por fecha desde - hasta y un listado de productos. Es obligatorio ingresar una fecha
    desde y hasta
    <h3>Items</h3>
    <p>Consiste en una tabla que contiene como columnas todos los días entre la fecha desde hasta la fecha hasta. Las
        filas representan los productos seleccionados.
        Cada celda contiene la cantidad de servicios que se tienen previstos para ese día y producto.</p>
    <p>Al pasar el mouse por encima de una celda, se resalta el día y producto correspondiente.</p>
</model-help>