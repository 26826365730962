import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { TipoPuesto } from 'src/app/model/TipoPuesto';
import { TipoPuestoService } from 'src/app/services/tipo-puesto.service';
import { ModelListHandler } from './../../../common/interfaces/ModelListHandler';
import { AccionesTabla } from './../../../common/model/AccionesTabla';

@Component({
	selector: 'listado-tipo-puesto',
	templateUrl: 'listado-tipo-puesto.component.html',
	styleUrls: ['listado-tipo-puesto.component.less']
})

export class ListadoTipoPuestoComponent extends SessionComponent implements OnInit, OnDestroy {

	private routeSub: Subscription;
	public paramId: string;
	public editandoParametro: boolean = false;
	public itemEditado: TipoPuesto = new TipoPuesto();
	public listado: TipoPuesto[] = [];
	public filtro: Filtro = new Filtro("param_filter", {}, 0, 10);
	public handler: ModelListHandler;
	constructor(public service: TipoPuestoService,
		private confService: ConfirmationService,
		private route: ActivatedRoute,
		messagesService: MessagesService,
	) {
		super(messagesService);

	}
	public columns = [{ "field": "codigo", "header": "Código", "role": "text-tabla", "sortable": true, "width": "10em" },
	{ "field": "icono", "header": "Icono", "class": "profile-pic", "role": "profile-tabla", "width": "5em" },
	{ "field": "descripcion", "header": "Descripción", "role": "text-tabla", "sortable": true },
	{ "field": "habilitado", "header": "Habilitado", "class": "is-boolean", "role": "boolean-tabla", "width": "10em" }
	]

	public acciones: AccionesTabla[] = [
		new AccionesTabla("Editar Tipo Puesto", "fa fa-pencil", "accion-tabla",
			(item) => { this.editar(item.id) },
			(item) => { return this.esAdministrador }
		),
		new AccionesTabla("Borrar Tipo Puesto", "fa fa-trash", "accion-tabla",
			(item) => { this.eliminar(item) },
			(item: TipoPuesto) => { return this.esAdministrador && item.habilitado && !item.esSistema }
		),
		new AccionesTabla("Habilitar Tipo Puesto", "fa fa-check", "accion-tabla",
			(item) => { this.habilitar(item) },
			(item: TipoPuesto) => { return this.esAdministrador && !item.habilitado && !item.esSistema }
		),
	]

	ngOnInit() {
		let $this = this;
		this.handler = {
			nuevo: () => {
				$this.nuevo();
			},
			editar: (id) => {
				$this.editar(id);
			},
			eliminar: null
		};
	}

	public isValid(item: TipoPuesto) {
		return item.descripcion != undefined;
	}
	ngOnDestroy() {
		{
			if (this.routeSub) this.routeSub.unsubscribe();
		}
	}
	public refresh(item?: TipoPuesto) {
		this.service.getAll(this.filtro).then(r => {
			this.listado = r;
			this.editandoParametro = false;
			this.itemEditado = new TipoPuesto();
		})


	}
	public eliminar(item: TipoPuesto) {

		this.confService.confirm({
			key: 'genConf',
			header: "Eliminar",
			message: "Va a eliminar el tipo de puesto " + item.descripcion + ". Desea continuar?",
			accept: () => {
				let $this = this;
				$this.service.eliminar(item.id).then((res) => {
					$this.refresh(item);
					$this.success("El tipo de puesto fue eliminada")
				});

			}
		});


	}

	public habilitar(item: TipoPuesto) {
		this.confService.confirm({
			key: 'genConf',
			header: "Habilitar",
			message: "Va a habilitar el parametro " + item.descripcion + ". Desea continuar?",
			accept: () => {
				let $this = this;
				$this.service.habilitar(item.id).then((res) => {
					$this.refresh(item);
					$this.success("El parametro fue habilitado")
				});

			}
		});


	}
	public onDialogShow(event, dialog) {
		if (this.isMobile()) {
			dialog.maximized = false;
			dialog.toggleMaximize(event);
		}
	}
	public onCancelar() {
		this.editandoParametro = false;
		this.itemEditado = new TipoPuesto();
	}
	private nuevo() {
		this.itemEditado = new TipoPuesto();
		this.editandoParametro = true;
	}
	private editar(id: number) {
		this.service.getById(id).then(r => {
			this.itemEditado = r;
			this.editandoParametro = true;
		})
	}

}