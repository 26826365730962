import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core"
import { FileUpload } from "primeng/fileupload"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { FileData } from "src/app/model/FileData"
import { IConAdjunto } from "../IConAdjunto"

@Component({
	selector: "adjunto",
	templateUrl: "adjunto.component.html",
	styleUrls: ["adjunto.component.less"]
})
export class AdjuntoComponent implements OnInit {
	@Input()
	public label: string = "ADJUNTO"

	@Input()
	public item: IConAdjunto

	@Input()
	public readonly: boolean = false
	@Input()
	public uploadUrl: string
	@Input()
	public maxFileSize: number = 1048576 // 1MB
	@ViewChild("dragDropContainer") dragDropContainer: ElementRef
	@Input()
	public typesAllowed: string = "image/*, application/pdf"
	@ViewChild("fileInput", { static: false })
	private fileUpload: FileUpload
	isDragging = false

	constructor(private messagesService: MessagesService) {}

	ngOnInit() {}

	handleDragEnter(event: DragEvent) {
		event.preventDefault()
		this.isDragging = true
	}

	handleDragLeave(event: DragEvent) {
		event.preventDefault()
		this.isDragging = false
	}
	onUploadError(error) {
		this.fileUpload.clear()
		this.messagesService.error(error?.message || error)
	}

	uploadEnd(data, destino) {
		this.item.adjunto = data.originalEvent.body[0]
	}

	open(file: FileData) {
		if (file.url) {
			window.open(file.url, "_blank")
		}
	}

	handleFileDrop(event: DragEvent) {
		event.preventDefault()
		this.isDragging = false
		this.uploadFiles(event.dataTransfer.files)
	}

	allowDrop(event: DragEvent) {
		event.preventDefault()
		this.isDragging = true
	}

	uploadFiles(files: FileList) {
		this.fileUpload.files = [files.item(0)]
		this.fileUpload.upload()
	}
}
