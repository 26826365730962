import { RespuestaEncuestaActividades } from './RespuestaEncuestaActividades';
import { PlantillaPreguntaEncuesta } from './PlantillaPreguntaEncuesta';


import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { PlantillaPregunta} from './PlantillaPregunta';


export class EncuestaActividades extends Descriptivo {
    constructor(id?: number, codigo?: string, descripcion?: string,
		public preguntas : PlantillaPreguntaEncuesta[] = [], 

    ) {
        super(codigo, descripcion,id)
    }
   
    public static fromData(data: any): EncuestaActividades {
        if (!data) return null;
        const o: EncuestaActividades = new EncuestaActividades(
            data.id,
            data.codigo,
            data.descripcion,
			data.preguntas? data.preguntas.map(c => PlantillaPreguntaEncuesta.fromData(c)) : [], 
        );

        o.version = data.version; 
return o;

    }

}