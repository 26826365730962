import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { Subscription } from "rxjs"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Vehiculo } from "src/app/model/Vehiculo"
import { VehiculoService } from "src/app/services/vehiculo.service"
import { SessionComponent } from "./../../../common/components/session-component.component"
import { Parametrico } from "./../../../common/model/Parametrico"
import { ProfilePic } from "./../../../common/model/ProfilePic"
import { BloqueoVehiculoService } from "./../../../services/bloqueo-vehiculo.service"
import { EstadoVehiculoService } from "./../../../services/estado-vehiculo.service"
import { TipoVehiculoService } from "./../../../services/tipovehiculo.service"
@Component({
	selector: "gestor-vehiculos",
	templateUrl: "gestor-vehiculos.component.html",
	styleUrls: ["gestor-vehiculos.component.less"]
})
export class GestorVehiculoComponent extends SessionComponent implements OnDestroy, OnInit {
	constructor(
		public service: VehiculoService,
		public tipoVehiculoService: TipoVehiculoService,
		public estadoVehiculoService: EstadoVehiculoService,
		public bloqueoService: BloqueoVehiculoService,
		private route: ActivatedRoute,
		private confService: ConfirmationService,
		messagesService: MessagesService
	) {
		super(messagesService)
	}

	private routeSub: Subscription
	public item: Vehiculo = new Vehiculo()
	public tiposVehiculo: Parametrico[] = []
	public estadosVehiculo: Parametrico[] = []
	public file: File
	@Input()
	public readonly: boolean = false
	public onGuardado(item) {
		return
	}
	public onPicSelected(file: File) {
		const reader = new FileReader()
		let $this = this
		if (file.size > 1000000) {
			return this.error(this.translateService.get("EL_ARCHIVO_NO_PUEDE_16"))
		}
		this.file = file
		reader.onload = (e: any) => {
			$this.item.profilePic = new ProfilePic(null, e.target.result)
		}
		reader.readAsDataURL(file)
	}
	public isValid = async () => {
		if (!this.item.nombre && !this.item.patente) {
			return this.error(this.translateService.get("DEBE_INGRESAR_NOMBRE_O_PATENTE"))
		}
		return true
	}
	ngOnInit() {
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
				}
			})
		)
		this.routeSub = this.route.queryParams.subscribe((params) => {
			let id: number = <number>params["id"]
			if (!this.service) return
			if (id) {
				this.service.getById(id).then((r) => {
					this.item = this.service.parseSingle(r)
				})
			} else {
				this.item = this.service ? this.service.newEnt() : null
			}
		})
		this.tipoVehiculoService.getHabilitados().then((r) => {
			this.tiposVehiculo = r
		})
		this.estadoVehiculoService.getHabilitados().then((r) => {
			this.estadosVehiculo = r
		})
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}
}
