import { Injectable } from "@angular/core"
import { saveAs } from "file-saver"
import * as moment from "moment"
import { Descriptivo } from "src/app/common/model/Descriptivo"

@Injectable({
	providedIn: "root"
})
export class ExcelDownloader {
	public downloadData(toDownload: any[], name?: string, skip: any = {}, columns?: any[]) {
		import("xlsx").then(async (xlsx) => {
			const data = toDownload
			const transformed = data.map((d) => {
				let el = {}
				if (columns?.length) {
					columns
						.filter((field) => !skip[field])
						.forEach((element) => {
							const val = this.parseElement(d, element.field)
							el[element.header || element.field] = val
							if (typeof val === "string" && val.toUpperCase().startsWith("HTTP")) {
								el[element.header || element.field] = { v: val, l: { Target: d[element.field] } }
							}
						})
				} else {
					for (const key in d) {
						if (Object.prototype.hasOwnProperty.call(d, key) && !skip[key]) {
							const val = this.parseElement(d, key)
							el[key] = val
							if (typeof val === "string" && val.toUpperCase().startsWith("HTTP")) {
								el[key] = { l: { Target: d[key] }, v: val }
							}
						}
					}
				}

				return el
			})
			const worksheet = xlsx.utils.json_to_sheet(transformed)
			const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] }
			const excelBuffer: any = xlsx.write(workbook, { bookType: "xlsx", type: "array" })
			this.saveAsExcelFile(excelBuffer, name || "datosExportados" + moment().format("YYYYMMDDhhss"))
		})
	}
	parseElement(d, key) {
		const element = d[key]
		if (element instanceof Descriptivo) {
			return element["descripcion"]
		} else if (element instanceof Date) {
			return moment(element).format("DD-MM-YYYY")
		} else {
			return element
		}
	}
	saveAsExcelFile(buffer: any, fileName: string): void {
		let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
		let EXCEL_EXTENSION = ".xlsx"
		const data: Blob = new Blob([buffer], {
			type: EXCEL_TYPE
		})
		saveAs(data, fileName + "_" + new Date().getTime() + EXCEL_EXTENSION)
	}
}
