import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import moment from "moment"
import { ConfirmationService } from "primeng/api"
import { Subscription } from "rxjs"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { ProfilePic } from "src/app/common/model/ProfilePic"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Hotel } from "src/app/model/Hotel"
import { HotelHabitacion } from "src/app/model/HotelHabitacion"
import { Producto } from "src/app/model/Producto"
import { Proveedor } from "src/app/model/Proveedor"
import { CiudadService } from "src/app/services/ciudad.service"
import { HotelService } from "src/app/services/hotel.service"
import { ProductoService } from "src/app/services/producto.service"
import { ProveedorService } from "src/app/services/proveedor.service"
import { ProvinciaService } from "src/app/services/provincia.service"
import { HotelesAG } from "./../../../authguards/HotelesAG"
import { SessionComponent } from "./../../../common/components/session-component.component"

@Component({
	selector: "gestor-hotel",
	templateUrl: "gestor-hotel.component.html",
	styleUrls: ["gestor-hotel.component.less"]
})
export class GestorHotelComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription

	private _item: Hotel = new Hotel()

	public get item(): Hotel {
		return this._item
	}
	@Input()
	public set item(v: Hotel) {
		this._item = v
		this.habitaciones = [...this.item.habitaciones?.map((h) => Descriptivo.fromData(h.habitacion))]
		this.itemChange.emit(this._item)
	}

	@Output()
	itemChange: EventEmitter<Hotel> = new EventEmitter<Hotel>()

	@Output()
	nombreChange: EventEmitter<string> = new EventEmitter<string>()

	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false
	@Input()
	public readonly: boolean = false
	@Output()
	public onGuardado: EventEmitter<Hotel> = new EventEmitter<Hotel>()
	@Input() esProveedor: boolean = false
	@Output()
	public onCancelado = new EventEmitter()

	public provinciaOptions: Descriptivo[] = []
	public ciudadOptions: Descriptivo[] = []

	public hospedajes: Descriptivo[] = []
	public habitaciones: Descriptivo[] = []
	public relacionandoProveedor: boolean = false

	public file: File
	public onPicSelected(file: File) {
		const reader = new FileReader()
		let $this = this
		if (file.size > 1000000) {
			return this.error(this.translateService.get("EL_ARCHIVO_NO_PUEDE_16"))
		}
		this.file = file
		reader.onload = (e: any) => {
			$this.item.profilePic = new ProfilePic(null, e.target.result)
		}
		reader.readAsDataURL(file)
	}

	constructor(
		messagesService: MessagesService,
		public service: HotelService,
		private route: ActivatedRoute,
		private confService: ConfirmationService,
		private provinciaService: ProvinciaService,
		private ciudadService: CiudadService,
		private hotelAG: HotelesAG,
		public productoService: ProductoService,
		public proveedorService: ProveedorService
	) {
		super(messagesService)
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}

	public isValid() {
		return true
	}
	ngOnInit() {
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
					this.editable = this.hotelAG.esVisible(this.usuario)
				} else {
					this.editable = false
				}
			})
		)
		if (this.route.snapshot.url.some((u) => u.path == "hotel")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				if (!this.service) return
				if (id) {
					this.service.getById(id).then((r) => {
						this.item = r
					})
				} else {
					this.item = this.service ? this.service.newEnt() : null
					this.item.checkIn = moment().set({ hour: 14, minute: 0, second: 0, millisecond: 0 }).toDate()
					this.item.checkOut = moment().set({ hour: 10, minute: 0, second: 0, millisecond: 0 }).toDate()
				}
			})
		}

		this.provinciaService.getAll().then((r) => {
			this.provinciaOptions = r
		})
		this.ciudadService.getAll().then((r) => {
			this.ciudadOptions = r
		})

		this.getHospedajes()
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}

	getHospedajes() {
		return this.productoService.getHospedajes().then((r) => {
			this.hospedajes = r
		})
	}

	onAgregarHabitacion(habitacion: Producto) {
		if (this.item.habitaciones.some((h) => h.habitacion.id == habitacion.id)) {
			return this.error(this.translateService.get("LA_HABITACION_YA_ESTA_ASIGNADA"))
		}

		this.item.habitaciones.push(new HotelHabitacion(null, null, habitacion))
	}

	onQuitarHabitacion(habitacion: Producto) {
		this.item.habitaciones = this.item.habitaciones.filter((h) => h.habitacion.id != habitacion.id)
	}

	onSelectProveedor(proveedor: Proveedor) {
		this.relacionandoProveedor = true
		this.item.proveedor = proveedor.id
		this.item.provRelacionado = proveedor
	}

	changeNombre() {
		this.nombreChange.emit(this.item.nombre)
	}
}
