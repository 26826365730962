<div class="gestor-inclusion content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'Inclusion' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="InclusionForm"
		[multilenguaje]="true"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #InclusionForm="ngForm" id="Inclusionform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-2 field">
						<span class="p-float-label">
							<input pInputText [(ngModel)]="item.codigo" type="text" #codigo="ngModel" name="codigo" [readonly]="readonly" />
							<label>{{ "CODIGO" | translate }}</label>
							<error-tag [model]="$CODIGO"></error-tag>
						</span>
					</div>

					<div class="col-12 lg:col-6">
						<span class="p-float-label">
							<input pInputText [(ngModel)]="item.descripcion" type="text" #descripcion="ngModel" name="descripcion" [readonly]="readonly" />
							<label>{{ "DESCRIPCION" | translate }}</label>
							<error-tag [model]="$DESCRIPCION"></error-tag>
						</span>
					</div>
					<div class="col-12 lg:col-4 checkbox-container">
						<p-toggleButton
							onLabel="{{ 'HABILITADO' | translate }}"
							offLabel="{{ 'HABILITADO' | translate }}"
							iconPos="left"
							name="habilitado"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[disabled]="readonly"
							[(ngModel)]="item.habilitado"
						></p-toggleButton>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
