import { Descriptivo } from "src/app/common/model/Descriptivo"
import { CategoriaProducto } from "src/app/model/CategoriaProducto"
import { EncuestaActividades } from "src/app/model/EncuestaActividades"
import { TipoVariante } from "src/app/model/TipoVariante"
import { Vehiculo } from "src/app/model/Vehiculo"
import { IConSubcategoriasImponibles } from "../common-components/abm-subcategorias-imponibles/IConSubcategoriasImponibles"
import { ProfilePic } from "./../common/model/ProfilePic"
import { AsociacionInsumo } from "./AsociacionInsumo"
import { FamiliaProducto } from "./FamiliaProducto"
import { Idioma } from "./Idioma"
import { ProductoAsociado } from "./ProductoAsociado"
import { ProductoInsumo } from "./ProductoInsumo"
import { PuestoACubrir } from "./PuestoACubrir"
import { Region } from "./Region"
import { SubcategoriaImponibleItem } from "./SubcategoriaImponibleItem"
import { TipoIVAGrabado } from "./TipoIVAGrabado"
import { TipoProducto } from "./TipoProducto"
import { VehiculoRequerido } from "./VehiculoRequerido"

export class Producto extends Descriptivo implements IConSubcategoriasImponibles {
	public static NOTA: string = "$NOTA$"

	public static TIPO: string = "S"

	public static esMismaFamilia(producto1, producto2): boolean {
		return producto1?.familiaProducto ? producto2.familiaProducto?.id === producto1?.familiaProducto?.id : producto2.id === producto1.id
	}
	public get fullDescripcion() {
		return this.descripcion + (this.tipoVariante?.descripcion ? " - " + this.tipoVariante?.descripcion : "")
	}
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public habilitado?: boolean,
		public tipoProducto?: Descriptivo,
		public categorias: CategoriaProducto[] = [],
		public peso?: number,
		public fechaVigencia?: Date,
		public duracion?: number,
		public puestosACubrir: PuestoACubrir[] = [],
		public vehiculosACubrir: VehiculoRequerido[] = [],
		public basePersonas: number = 1,
		public cantidad: number = 1,
		public insumos: ProductoInsumo[] = [],
		public productosAsociados: ProductoAsociado[] = [],
		public adicionable: boolean = true,
		public esDivisible?: boolean,
		public esPropio?: boolean,
		public cantidadMaxGrupo: number = 6,
		public icono?: ProfilePic,
		public detalle?: string,
		public codigoIdioma: string = Idioma.DEFAULT_CODIGO,
		public tipoIVA?: TipoIVAGrabado,
		public tipoRecordatorio?: Descriptivo,
		public requiereConsentimiento: boolean = false,
		public nombreComercial?: string,
		public encuesta?: EncuestaActividades,
		public tipoProductoDesc: string = Producto.TIPO,
		public horaInicio?: Date,
		public familiaProducto?: FamiliaProducto,
		public tipoVariante?: TipoVariante,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
		public clonado?: number,
		public notificaHotel: boolean = true,
		public esSubcategorizado: boolean = false,
		public subcategorias: SubcategoriaImponibleItem[] = [],
		public regiones: Region[] = [],
		public destacarComoAdicional: boolean = false
	) {
		super(codigo, descripcion, id)
	}
	getAgrupado(): boolean {
		return this.familiaProducto?.agrupado || false
	}
	public static fromData(data: any): Producto {
		if (!data) return null
		const o: Producto = new Producto(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			Descriptivo.fromData(data.tipoProducto),
			data.categorias ? data.categorias.map((c) => CategoriaProducto.fromData(c)) : [],
			data.peso,
			data.fechaVigencia ? new Date(data.fechaVigencia) : null,
			data.duracion,
			data.puestosACubrir?.map((c) => PuestoACubrir.fromData(c)) || [],
			data.vehiculosACubrir?.map((c) => VehiculoRequerido.fromData(c)) || [],
			data.basePersonas,
			data.cantidad,
			data.insumos?.map((c) => ProductoInsumo.fromData(c)) || [],
			data.productosAsociados?.map((c) => ProductoAsociado.fromData(c)) || [],
			data.adicionable,
			data.esDivisible,
			data.esPropio,
			data.cantidadMaxGrupo,
			ProfilePic.fromData(data.icono),
			data.detalle,
			data.codigoIdioma,
			TipoIVAGrabado.fromData(data.tipoIVA),
			Descriptivo.fromData(data.tipoRecordatorio),
			data.requiereConsentimiento,
			data.nombreComercial,
			EncuestaActividades.fromData(data.encuesta),
			Producto.TIPO,
			data.horaInicio ? new Date(data.horaInicio) : null,
			data.familiaProducto ? FamiliaProducto.fromData(data.familiaProducto) : null,
			data.tipoVariante ? TipoVariante.fromData(data.tipoVariante) : null,
			data.lastModifiedBy,
			data.lastModifiedDate ? new Date(data.lastModifiedDate) : null,
			data.clonado,
			data.notificaHotel,
			data.esSubcategorizado,
			data.subcategorias?.map((s) => SubcategoriaImponibleItem.fromData(s)) || [],
			data.regiones?.map((s) => Region.fromData(s)) || [],
			data.destacarComoAdicional
		)
		o.version = data.version
		return o
	}
	public quitarItem(item: any) {
		this.subcategorias = this.subcategorias.filter((i) => i != item)
	}
	public agregarItem(item: any) {
		if (this.subcategorias.indexOf(item) < 0) {
			this.subcategorias = [...this.subcategorias, item]
		}
	}
	public newItem() {
		const s = new SubcategoriaImponibleItem()
		s.proporcion = 100 - this.subcategorias.reduce((a, b) => (a += b.proporcion), 0)
		return s
	}

	public get resumenImpuestos() {
		if (!this.esSubcategorizado) return this.tipoIVA?.descripcion || "IVA Sin definir"
		return this.subcategorias.map((s) => s.subcategoriaImponible?.descripcion + " (" + s.tipoIVA?.descripcion + ") " + s.proporcion + "%").join(", ")
	}
	public get esAdulto() {
		return this.tipoVariante?.codigo === TipoVariante.ADULTO
	}

	public get esPrograma() {
		return this.checkTipoProducto(TipoProducto.PROGRAMA)
	}
	public get esPaquete() {
		return this.checkTipoProducto(TipoProducto.PAQUETE)
	}
	public get esFamilia() {
		return this.checkTipoProducto(TipoProducto.FAMILIA)
	}
	public esGestionAmplia() {
		return this.tipoProducto && !this.esInsumo() && !this.esProducto() && !this.esPaquete
	}
	public esServicio() {
		return this.checkTipoProducto(TipoProducto.SERVICIO)
	}

	public esProducto() {
		return this.checkTipoProducto(TipoProducto.PRODUCTO)
	}

	public esHospedaje() {
		return this.checkTipoProducto(TipoProducto.HOSPEDAJE)
	}

	public esInsumo() {
		return this.checkTipoProducto(TipoProducto.INSUMO)
	}
	public esTransfer() {
		return this.checkTipoProducto(TipoProducto.TRANSFER)
	}

	public esTercerizado() {
		return this.checkTipoProducto(TipoProducto.TERCERIZADO)
	}

	private checkTipoProducto(tipo: string) {
		return this.tipoProducto && this.tipoProducto?.codigo == tipo
	}

	public getPuestoByCategoria(tipo: string) {
		return this.puestosACubrir.filter((p) => p.getCategoria() == tipo)[0]
	}

	public getInsumos(tipo: string) {
		const puesto = this.getPuestoByCategoria(tipo)
		return puesto ? puesto.insumos : []
	}

	public eliminarPuesto(puesto: PuestoACubrir) {
		this.puestosACubrir = this.puestosACubrir.filter((p) => p != puesto)
	}
	public eliminarVehiculo(puesto: VehiculoRequerido) {
		this.vehiculosACubrir = this.vehiculosACubrir.filter((p) => p != puesto)
	}

	public quitarInsumo(insumo: AsociacionInsumo) {
		this.insumos = this.insumos.filter((i) => i != insumo)
	}
	public quitarProducto(prod: ProductoAsociado) {
		this.productosAsociados = this.productosAsociados.filter((i) => i != prod)
	}

	public get proporcion() {
		if (this.basePersonas <= 0) return 0
		return this.cantidad / this.basePersonas
	}

	public get vehiculoPrincipal(): VehiculoRequerido {
		return this.vehiculosACubrir.filter((v) => v.esPrincipal)[0]
	}

	public get iconoUrl() {
		return this.icono ? this.icono.picPathVersion : "assets/images/default_image.png"
	}
	public get imagen() {
		return this.iconoUrl
	}

	public clonar(): Producto {
		var p = Producto.fromData(this)
		p.id = null
		p.puestosACubrir.forEach((c) => {
			c.id = null
			;(c.producto = null), c.insumos.forEach((i) => (i.id = null))
		})
		p.vehiculosACubrir.forEach((v) => {
			v.id = null
			v.insumos.forEach((i) => (i.id = null))
		})
		p.insumos.forEach((i) => (i.id = null))
		p.productosAsociados.forEach((pa) => (pa.id = null))
		p.codigo = null
		p.esPropio = this.esPropio
		p.familiaProducto = this.familiaProducto
		p.tipoVariante = this.tipoVariante
		p.tipoProductoDesc = this.tipoProductoDesc
		p.requiereConsentimiento = this.requiereConsentimiento
		p.tipoRecordatorio = this.tipoRecordatorio
		p.encuesta = this.encuesta
		p.regiones = this.regiones
		p.esSubcategorizado = this.esSubcategorizado
		p.subcategorias = this.subcategorias.map((s) => {
			const a = SubcategoriaImponibleItem.fromData(s)
			a.id = null
			return a
		})
		p.notificaHotel = this.notificaHotel
		return p
	}

	public get nombre(): string {
		return this.nombreComercial ?? this.descripcion
	}
	public cumpleVehiculo(vehiculo: Vehiculo) {
		return !this.vehiculosACubrir?.length || this.vehiculosACubrir.some((v) => v.cumple(vehiculo))
	}

	get tipo() {
		return Producto.TIPO
	}
	get nombreComercialCompuesto() {
		return this.nombre + " - " + this.textoVariante
	}

	get textoVariante() {
		return this.tipoVariante ? this.tipoVariante.descripcion : "General"
	}
	insumoPrincipal() {
		return !this.esTercerizado() ? null : this.puestosACubrir[0]?.insumos.filter((i) => !i.esPorServicio)[0]
	}
	get keyFamilia() {
		return this.familiaProducto ? "f" + this.familiaProducto.id : "p" + this.id
	}
	aplicaTercerizado(v: Vehiculo) {
		if (this.esTercerizado()) {
			const p = this.puestosACubrir[0]?.personalDefault || this.puestosACubrir[0]?.proveedor
			return p && v.proveedor && p.id == v.proveedor.id
		} else return false
	}
}

export class ProductoRanking extends Descriptivo {
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public imagenPortada: string = "assets/images/default_image.png",
		public cantidad?: number,
		public precioPerCapita?: number,
		public ajuste?: number,
		public fechaFacturacion?: any,
		public fechaActividad?: any,
		public idCliente?: number,
		public nombreCliente?: string,
		public apellidoCliente?: string,
		public razonSocialCliente?: string,
		public reservaId?: number,
		public comprobanteId?: number,
		public esPropio?: boolean,
		public cotizacioniLocal?: number,
		public moneda?: string,
		public ajusteTotal?: number,
		public tipoAjuste?: string,
		public cantidadPaxReserva?: number,
		public idCanalVenta?: number,
		public descripcionCanalVenta?: string,
		public importeStr?: string,
		public importeOriginalStr?: string,
		public importe?: number,
		public tipoCliente?: string,
		public familiaProducto?: Descriptivo,
		public vehiculo?: Descriptivo,
		public nombreNacionalidad?: string,
		public codigoNacionalidad?: string
	) {
		super(codigo, descripcion, id)
		let imp = this.precioPerCapita * this.cantidad + (this.precioPerCapita * this.cantidad * this.ajuste) / 100
		let aj = 0
		if (this.ajusteTotal) {
			aj = this.tipoAjuste == "suma" ? this.ajusteTotal : (imp * this.ajusteTotal) / 100
		}
		imp = imp + aj
		this.importeOriginalStr = imp.toFixed(2)
		imp = imp * (this.cotizacioniLocal ? this.cotizacioniLocal : 1)
		this.importeStr = imp.toFixed(2)
		this.importe = imp
	}
	public static fromData(data: any): ProductoRanking {
		if (!data) return null
		const o: ProductoRanking = new ProductoRanking(
			data.id,
			data.codigo,
			data.descripcion,
			data.imagenPortada,
			data.cantidadPax,
			data.precioPerCapita,
			data.ajuste,
			data.fechaFacturacion,
			data.fechaActividad,
			data.idCliente,
			data.nombreCliente,
			data.apellidoCliente,
			data.razonSocialCliente,
			data.reservaId,
			data.comprobanteId,
			data.esPropio,
			data.cotizacioniLocal || data.cotizacionAfip,
			data.moneda,
			data.ajusteTotal,
			data.tipoAjuste,
			data.cantidadPaxReserva,
			data.idCanalVenta,
			data.descripcionCanalVenta
		)
		o.tipoCliente = data.tipoCliente
		o.familiaProducto = data.familiaProducto ? Descriptivo.fromData(data.familiaProducto) : null
		o.vehiculo = Descriptivo.fromData(data.vehiculo)
		o.version = data.version
		o.nombreNacionalidad = data.nombreNacionalidad
		o.codigoNacionalidad = data.codigoNacionalidad
		return o
	}
	public get esPropioStr() {
		return this.esPropio ? "SI" : "NO"
	}
	public get imagen() {
		return this.imagenPortada
	}
	public get cotizacionSafe() {
		return this.cotizacioniLocal > 0 ? this.cotizacioniLocal : 1
	}
}
