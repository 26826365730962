<div class="listado-inclusion content">
	<model-list
		[service]="service"
		[modelName]="'inclusion'"
		[columns]="columns"
		[title]="'Inclusion_PLR' | translate"
		[(listado)]="listado"
		[isLazy]="true"
		[filtro]="filtro"
		[multilenguaje]="true"
		[modoSeleccion]="modoSeleccion"
		[hideHeader]="ocultarCabecera"
		[editable]="editable"
		(seleccionChange)="updateSeleccion($event)"
		[readonly]="readonly"
		[updateRowImplementation]="actualizarDescricpion"
	>
	</model-list>
</div>
