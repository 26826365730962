import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core"
import { NavigationExtras, Router } from "@angular/router"
import { ConfirmationService, MenuItem } from "primeng/api"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { AccionesTabla } from "src/app/common/model/AccionesTabla"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { FiltroFactura } from "src/app/common/model/FiltroFactura"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { UsuarioService } from "src/app/common/services/usuario.service"
import { Factura } from "src/app/model/Factura"
import { FacturaResumen } from "src/app/model/FacturaResumen"
import { Totales } from "src/app/model/Totales"
import { EstadoDocumentoService } from "src/app/services/estado-documento.service"
import { FacturaResumenService } from "src/app/services/factura-resumen.service"
import { NovedadService } from "src/app/services/novedades.service"
import { ProveedorService } from "src/app/services/proveedor.service"
import { TipoComprobanteService } from "src/app/services/tipo-comprobante.service"
import { TipoProveedorService } from "./../../../services/tipo-proveedor.service"
import { VendedorService } from "./../../../services/vendedor.service"

@Component({
	selector: "listado-factura",
	templateUrl: "listado-factura.component.html",
	styleUrls: ["listado-factura.component.less"]
})
export class ListadoFacturaComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: FiltroFactura
	public listado: FacturaResumen[] = []
	public externasPendientes: FacturaResumen[] = []
	public nuevaFactura: boolean = false
	@ViewChild("comprobante", { static: true }) comprobanteRef: TemplateRef<any>
	public columns = []

	public updateSeleccion(items: FacturaResumen[]) {
		this.menuAcciones = []
		if (this.esPagable(items)) {
			this.menuAcciones.push({
				label: this.translateService.get("Pagar facturas"),
				icon: "fa fa-money",
				command: (e) => {
					this.pagarFacturas(items)
				}
			})
		}
	}
	public esPagable(items: FacturaResumen[]) {
		return !this.usuario?.esExterno && items?.length && items.every((i) => i.saldo != 0 && i.proveedor?.id == items[0]?.proveedor?.id)
	}
	public menuAcciones: MenuItem[] = []

	public acciones: AccionesTabla[] = [
		new AccionesTabla(
			"Aprobar Factura",
			"fa fa-check",
			"accion-tabla",
			(item) => {
				this.service.aprobarFactura(item)
			},
			(item: FacturaResumen) => {
				return this.esJefeAdministracion && item.tipoComprobante?.codigo != "PA" && item.estado?.codigo == "P"
			}
		),
		new AccionesTabla(
			"Rechazar Factura",
			"fa fa-close",
			"accion-tabla",
			(item) => {
				this.service.rechazarFactura(item)
			},
			(item: FacturaResumen) => {
				return this.esJefeAdministracion && item.tipoComprobante?.codigo != "PA" && item.estado?.codigo == "P"
			}
		),
		new AccionesTabla(
			"Editar Factura",
			"fa fa-pencil",
			"accion-tabla",
			(item) => {
				this.editar(item.id)
			},
			(item: FacturaResumen) => {
				return (
					!["C", "AN", "F"].some((e) => e == item.estado?.codigo) &&
					item.tipoComprobante?.codigo != "PA" &&
					item.saldo == item.importe &&
					(this.esJefeAdministracion || ["P", "N", "R"].some((e) => e == item.estado?.codigo))
				)
			}
		),
		new AccionesTabla(
			"Eliminar Factura",
			"fa fa-trash",
			"accion-tabla",
			(item) => {
				this.eliminar(item.id)
			},
			(item: FacturaResumen) => {
				return (
					this.esJefeAdministracion &&
					item.tipoComprobante?.codigo != "PA" &&
					((item.saldo == 0 && item.importe == 0) || (item.saldo != 0 && item.saldo == item.importe))
				)
			}
		),
		new AccionesTabla(
			"Ver Factura",
			"fa fa-eye",
			"accion-tabla",
			(item) => {
				this.ver(item.id)
			},
			(item: FacturaResumen) => {
				return true
			}
		),
		new AccionesTabla(
			"Rendir Factura",
			"fa fa-envelope-open-o",
			"accion-tabla",
			(item) => {
				this.tipoComprobanteSeleccionado = null
				this.puntoVenta = null
				this.numeroComprobante = null
				this.facturaRindiendo = item
			},
			(item: FacturaResumen) => {
				return item.tipoComprobante?.codigo == "AR"
			}
		),
		new AccionesTabla(
			"Pagar Factura",
			"fa fa-money",
			"accion-tabla",
			(item) => {
				this.pagarFacturas([item])
			},
			(item: FacturaResumen) => {
				return this.esPagable([item])
			}
		),
		new AccionesTabla(
			"Clonar Factura",
			"fa fa-copy",
			"accion-tabla",
			(item) => {
				this.clonarFactura(item)
			},
			(item: FacturaResumen) => {
				return true
			}
		)
	]

	public proveedores: Descriptivo[] = []
	public responsables: Descriptivo[] = []

	public facturaRindiendo: FacturaResumen
	public tiposComprobante: Descriptivo[]
	public tipoComprobanteSeleccionado: Descriptivo
	public puntoVenta: string
	public numeroComprobante: string
	public filtroProveedor: FiltroFactura
	public totalesMonedas: Totales[] = []
	constructor(
		messagesService: MessagesService,
		public service: FacturaResumenService,
		public provService: ProveedorService,
		public vendedorService: VendedorService,
		public userService: UsuarioService,
		private router: Router,
		public estadosService: EstadoDocumentoService,
		public confirmationService: ConfirmationService,
		public tipoComprobanteService: TipoComprobanteService,
		public tipoProveedorService: TipoProveedorService,
		public novedadesService: NovedadService
	) {
		super(messagesService)
	}

	ngOnInit() {
		this.columns = [
			{
				field: "id",
				header: "",
				role: "text-tabla",
				class: "text-center",
				width: "6em",
				sortable: true,
				order: 40
			},
			{
				field: "proveedorStr",
				header: "Proveedor",
				class: "descriptivo-tabla",
				role: "link-tabla",
				esMobile: true,
				sortable: true,
				navegar: (item) => {
					if (item?.proveedor) this.router.navigate(["proveedor/vista"], { queryParams: { id: item.proveedor.id } })
				},
				order: 40,

				width: "14em"
			},
			{
				field: "fecha",
				header: "Fecha",
				class: "date-tabla text-center",
				role: "date-tabla",
				esMobile: true,
				sortable: true,

				width: "7em",
				order: 40
			},
			{
				field: "fechaVencimiento",
				header: "FECHA_VENCIMIENTO",
				class: "date-tabla  text-center",
				role: "date-tabla",
				esMobile: true,
				sortable: true,
				width: "7em",
				order: 40
			},
			{
				field: "tipoNumero",
				header: "N. Comprobante",
				class: "text-tabla",
				classFn: (r) => {
					return r.tipoComprobante == "AR" ? "color-warning" : null
				},
				role: "template",
				template: this.comprobanteRef,
				sortable: false,
				order: 40,
				esMobile: true,
				width: "170px"
			},
			{
				field: "descripcion",
				header: "Descripcion",
				class: "text-tabla",
				role: "text-tabla",
				sortable: false,
				width: "12rem",
				order: 40
			},
			{
				field: "monedaStr",
				header: "Moneda",
				downloadOnly: true
			},
			{
				field: "importe",
				header: "Importe",
				class: "money-tabla",
				role: "money-tabla",
				sortable: true,
				order: 40
			},
			{
				field: "pagos",
				header: "Pagos",
				class: "money-tabla",
				role: "money-tabla",
				sortable: false,
				order: 40
			},
			{
				field: "saldo",
				header: "Saldo",
				class: "money-tabla",
				role: "money-tabla",
				esMobile: true,
				sortable: true,
				order: 40
			}
		]
		this.filtro = this.fitlerServices.getFilter("factura-filer", new FiltroFactura("factura-filer"))
		this.filtroProveedor = this.filtro.clonar()
		this.userService.getDescriptivos().then((us) => (this.responsables = us))
		this.tipoComprobanteService.getAll().then((ts) => (this.tiposComprobante = ts.filter((t) => t.codigo != "AR")))
		this.novedadesService.registrarObservador("factura", {
			next: (n) => {
				this.updateData()
			},
			destroy: () => {
				this.novedadesService.unregister("factura")
			},
			getKey: () => Factura.name
		})
		this.subs.push(
			this.filtro.dataChange.subscribe((f) => {
				this.service.getTotales(f).then((t) => {
					this.totalesMonedas = t
				})
			})
		)
		if (!this.usuario?.esExterno) this.getExternasPendientes()
	}

	private getExternasPendientes(): Promise<any[]> {
		if (this.esExterno) return Promise.resolve([])
		return this.service.getExternasPendientes(this.filtro).then((exs) => {
			this.externasPendientes = exs
			return exs
		})
	}

	ngOnDestroy() {}

	public editar(id: number) {
		let query: NavigationExtras = {
			queryParams: {
				id: id
			}
		}
		this.router.navigate(["factura/edit"], query)
	}

	public ver(id: number) {
		let query: NavigationExtras = {
			queryParams: {
				id: id
			}
		}
		this.router.navigate(["factura/vista"], query)
	}
	public updateData() {
		const current = this.externasPendientes?.length
		this.getExternasPendientes().then((n) => {
			if (n.length != current) {
				this.nuevaFactura = true
				setTimeout(() => {
					this.nuevaFactura = false
				}, 500)
			}
		})
	}
	public eliminar(id: number) {
		this.confirmationService.confirm({
			key: "genConf",
			header: "Eliminar",
			message: this.translateService.get("DESEA_ELIMINAR_EL__18"),
			accept: () => {
				let $this = this
				$this.service.eliminar(id).then((res) => {
					this.updateData()
					$this.success(this.translateService.get("EL_ITEM_FUE_ELIMINA_15"))
				})
			}
		})
	}

	public rendir() {
		let f = { ...this.facturaRindiendo }
		f.tipoComprobante = this.tipoComprobanteSeleccionado
		f.puntoVenta = this.puntoVenta
		f.numero = this.numeroComprobante
		this.service.rendir(f).then((res) => {
			this.updateData()
			this.facturaRindiendo = null
			this.tipoComprobanteSeleccionado = null
			this.puntoVenta = null
			this.numeroComprobante = null
		})
	}

	public pagarFacturas(items: FacturaResumen[]) {
		this.router.navigate(["orden-pago/nuevo"], { state: { facturas: items.map((i) => FacturaResumen.fromData(i)) } })
	}
	puntoVentaChange(event) {
		this.puntoVenta = this.puntoVenta.toString().padStart(5, "0")
	}

	numeroChange(event) {
		this.numeroComprobante = this.numeroComprobante.toString().padStart(8, "0")
	}

	clonarFactura(item: FacturaResumen) {
		let query: NavigationExtras = {
			queryParams: {
				id: item.id,
				clonar: true
			}
		}
		this.router.navigate(["factura/nuevo"], query)
	}

	public aprobarExterna(item) {
		this.service.aprobarFactura(item).then((r) => {
			this.success(this.translateService.get("FACTURA_APROBADA"))
			this.getExternasPendientes()
		})
	}

	public rechazarExterna(item) {
		this.service.rechazarFactura(item).then((r) => {
			this.success(this.translateService.get("FACTURA_RECHAZADA"))
			this.getExternasPendientes()
		})
	}
}
