<model-help [title]="'Listas de precios'">
	<h3>Carga Masiva</h3>
	<p>
		En la parte superior del listado de lista de precios encontramos el icono <i class="pi pi-upload"></i> mediante el cual podremos cargar masivamente un
		listado de proveedores.<br />
		Para ver el instructivo y descargar de archivo modelo, siga este <help-link [linkFinal]="'carga-masiva'" [textoLink]="'Link'"></help-link>
	</p>

	<h3>Información general</h3>

	<p>
		Este ícono <i class="fa fa-pencil"></i> aparece junto al nombre de la lista en caso de que estemos en modo vista y nos permite editar la lista
		actual.<br /><br />
		Esta pantalla de gestión cuenta con 2 secciones que pueden ser editadas de manera independiente: <br /><br />
		- La sección superior o <mark>Encabezado</mark> que incluye la descripción y las características generales de la lista.<br />
		* obligatorios: Descripción y Moneda asignada, además puede seleccionarse si la lista incluirá o no impuestos.
		<img src="assets/images/help/lista-precios-sup.jpg" /><br /><br />
		- La sección inferior o <mark>Detalle de productos y servicios</mark> incluidos en la lista, con sus precios asignados.<br />
		<img src="assets/images/help/lista-precios-inf.jpg" /><br /><br />
	</p>
	<h3>Seccion inferior o Detalle de productos en lista</h3>
	<p>
		De izquierda a derecha en primer lugar tenemos la posibilidad de seleccionar la fecha para visualizar los precios vigentes. (por defecto la fecha
		actual)<br />
		Luego tenemos una barra de búsqueda de productos incluídos en la lista, por último siguiendo la misma línea tenemos los íconos de
		<i class="pi pi-cog"></i> que nos permite agregar productos a la lista. Y el ícono <i class="fa fa-pencil"></i> que nos permite la edición masiva de
		precios.
	</p>

	<h3>Agregar o quitar productos o servicios a la lista</h3>
	<p>
		Al clickear en el ícono <i class="pi pi-cog"></i> mencionado anteriormente se nos despliega la siguiente pantalla complementaria:
		<img src="assets/images/help/gestion-prod-ofrecidos.jpg" /><br /><br />
		En la columna izquierda tenemos los productos o servicios disponibles para agregar a la lista actual.<br />
		Se pueden seleccionar de a uno o en selección múltiple con las teclas Ctrl ó Mayús, una vez seleccionado lo agregamos a la columna derecha donde
		tendremos los productos que ya pertenecen a la lista. <br />
		Con el botón <i class="pi pi-angle-right"></i> agregamos 1 prod o la selección múltiple. <br />
		Con el botón <i class="pi pi-angle-double-right"></i> agregamos TODOS los productos o servicios disponibles.<br /><br />
		De la misma manera con los botones de manera inversa. <i class="pi pi-angle-left"></i><i class="pi pi-angle-double-left"></i>
	</p>

	<h3>Edición masiva de precios</h3>
	<p>
		Al clickear en el ícono <i class="fa fa-pencil"></i> mencionado anteriormente se nos despliega la siguiente pantalla complementaria:
		<img src="assets/images/help/edicion-masiva-precios.jpg" /><br /><br />
		<strong>Fecha Desde / Fecha Hasta:</strong> Aquí se especifica el rango en el cual la modificación de precio estará vigente.<br />
		<strong>Tipo operacion:</strong> Debemos seleccionar una opción.<br /><br />

		Adicionar un monto fijo. <i class="pi pi-plus"></i><br />
		Cambiar el precio por un otro valor fijo. <i class="fa fa-pencil"></i><br />
		Adicionar un porcentaje. <i class="fa fa-percent"></i><br /><br />

		<strong>Monto operación: Especifica el valor en dinero o porcentaje, según el tipo de operación seleccionada.</strong><br /><br />
		En las columnas que se encuentran debajo, se seleccionarán los productos o servicios que serán modificados. <br /><br />
		Al hacer click en aplicar, se le mostrará al usuario la opción de elegir un tipo de redondeo que dependerá de los "digitos significativos"
		seleccionados: 0 implica que no hay redondeo, 1 que se redondea hasta la primer decena de decimal, 2 implica sin decimales, 3 hasta la primer decena y
		así sucesivamente.
	</p>

	<h3>Edición individual de precios</h3>
	<p>
		En el listado de precios podemos seleccionar y editar un precio individualmente haciendo click en el icono a la derecha de la fila correspondiente.
		<i class="fa fa-pencil"></i><br />
		Esto nos abrirá una pantalla adicional para establecer precios y vigencia de los mismos.
		<img src="assets/images/help/agregar-precio.jpg" /><br /><br />
		Se podrán agregar varios precios con distinta vigencia (desde / hasta).
	</p>
</model-help>
