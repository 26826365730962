
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { EstadoReserva } from 'src/app/model/EstadoReserva';
import { EstadoReservaService } from 'src/app/services/estado-reserva.service';

@Component({
  selector: 'listado-estado-reserva',
  templateUrl: 'listado-estado-reserva.component.html',
  styleUrls: ['listado-estado-reserva.component.less']
})

export class ListadoEstadoReservaComponent extends SessionComponent implements OnInit, OnDestroy {

  public filtro: Filtro = new Filtro("estado-reserva_filtros", {}, 0, 10);
  public listado: EstadoReserva[] = [];
  public columns = [
    {
      "field": "codigo",
      "header": "Codigo",
      "class": "codigo-tabla text-center",
      "role": "text-tabla",
      "sortable": true,
      "order": 1
    },
    {
      "field": "descripcion",
      "header": "Descripcion",
      "class": "text-tabla",
      "role": "text-tabla",
      "sortable": true,
      "order": 3
    },
    {
      "field": "icono",
      "header": "Icono",
      "class": "icon-tabla",
      "role": "icon-tabla",
      "sortable": false,
      "order": 40
    },
    {
      "field": "color",
      "header": "Color",
      "class": "color-tabla",
      "role": "color-tabla",
      "sortable": false,
      "order": 40
    },
    {
      "field": "habilitado",
      "header": "Habilitado",
      "class": "is-boolean",
      "role": "boolean-tabla",
      "sortable": false,
      "order": 99
    }
  ];
  constructor(messagesService: MessagesService,
    public service: EstadoReservaService) {
    super(messagesService)

  }
  ngOnInit() { }
  ngOnDestroy() { }


}