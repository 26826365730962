import * as moment from 'moment'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { TranslateService } from '../services/translate.service'

export class FilterSummary {
    constructor(public key: string, public titulo: string, public valor: string) {}

    public static parseForm(v: any, filtroMap: any): FilterSummary[] {
        let resumen = []

        for (var pName in filtroMap) {
            if (v[pName]) {
                if (v[pName] instanceof Date) {
                    if (!moment(v[pName]).isValid()) continue
                    resumen.push(new FilterSummary(pName, filtroMap[pName], moment(v[pName]).format('DD/MM/YYYY')))
                } else if (typeof v[pName] === 'boolean' && v[pName]) {
                    resumen.push(new FilterSummary(pName, null, filtroMap[pName]))
                } else if (v[pName] instanceof Descriptivo || (v[pName]?.codigo && v[pName]?.descripcion)) {
                    resumen.push(new FilterSummary(pName, filtroMap[pName], v[pName].descripcion))
                } else if (Array.isArray(v[pName])) {
                    if (v[pName].length > 4) {
                        resumen.push(new FilterSummary(pName, filtroMap[pName], v[pName].length + ' seleccionados'))
                    } else if (v[pName].length > 0) {
                        resumen.push(new FilterSummary(pName, filtroMap[pName], v[pName].toString()))
                    }
                } else {
                    resumen.push(new FilterSummary(pName, filtroMap[pName], v[pName]))
                }
            }
        }
        return resumen
    }
}
