import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ProfilePic } from 'src/app/common/model/ProfilePic';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { Nacionalidad } from 'src/app/model/Nacionalidad';
import { HelpService } from 'src/app/services/help.service';
import { AppComponent } from './../../../app.component';
import { ModelGestorComponent } from './../../../common/components/model-gestor/model-gestor.component';
import { IdiomaService } from './../../../common/services/idioma.service';
import { NacionalidadService } from './../../../services/nacionalidad.service';
import { NovedadService } from 'src/app/services/novedades.service';
@Component({
	selector: 'gestor-nacionalidad',
	templateUrl: 'gestor-nacionalidad.component.html',
	styleUrls: ['gestor-nacionalidad.component.less',]
})

export class GestorNacionalidadComponent extends ModelGestorComponent implements OnDestroy, OnInit {
	private routeSub: Subscription;




	public defTitulo: string = "";

	@Input()
	public goBack: boolean = true;
	@Input()
	public isModal: boolean = false;

	@Output()
	public onGuardado: EventEmitter<Nacionalidad> = new EventEmitter<Nacionalidad>();

	@Output()
	public onCancelado = new EventEmitter();

	constructor(
		confService: ConfirmationService,
		public service: NacionalidadService,
		public appComponent: AppComponent,
		route: ActivatedRoute,
		idiomaService: IdiomaService,
		_location: Location,
		router: Router, messagesService: MessagesService,
		helpService: HelpService, novedadService:NovedadService
		) {
		super(route, confService, route, appComponent, idiomaService, _location, messagesService, router, helpService,novedadService);
	}

	public onPicSelected(file: File) {
		const reader = new FileReader();
		let $this = this;
		if (file.size > 1000000) {
			return this.error(this.translateService.get('EL_ARCHIVO_NO_PUEDE_16'));
		}
		this.file = file;
		reader.onload = (e: any) => {
			$this.item['bandera'] = new ProfilePic(null, e.target.result);
		};
		reader.readAsDataURL(file);
	}
	public isValid(item) {
		let valido = true;
		if (item.descripcion == undefined || !item.descripcion) {
			valido = this.error("Indique una descripción");
		}
		return valido;
	}
	ngOnInit() {
		if (this.route.snapshot.url.some(u => u.path == 'nacionalidad')) {
			this.routeSub = this.route.queryParams.subscribe(params => {
				let id: number = <number>params['id'];
				if (!this.service) return;
				if (id) {
					this.service.getById(id).then(r => {
						this.item = this.service.parseSingle(r);
						//this.esExtranjero = this.item.nacionalidad.codigo != "AR";
					})
				} else {
					this.item = this.service ? this.service.newEnt() : null;
				}


			});
		}

	}
	ngOnDestroy() {

	}

	public handleGuardado(item) {
		this.onGuardado.emit(item);
	}
	public handleCancelar(item) {
		this.onCancelado.emit();
	}




}