import { ClipboardModule } from "@angular/cdk/clipboard"
import { AsyncPipe, registerLocaleData } from "@angular/common"
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http"
import localeAr from "@angular/common/locales/es-AR"
import localeEsUS from "@angular/common/locales/es-US"
import localeRuRu from "@angular/common/locales/ru"
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, Injector, LOCALE_ID, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { DateAdapter } from "@angular/material/core"
import { MatIconRegistry } from "@angular/material/icon"
import { MatTabsModule } from "@angular/material/tabs"
import { BrowserModule } from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { ServiceWorkerModule } from "@angular/service-worker"
import { FullCalendarModule } from "@fullcalendar/angular"
import { GridsterModule } from "angular-gridster2"
import { QRCodeModule } from "angularx-qrcode"
import { RecaptchaModule } from "ng-recaptcha"
import { ColorPickerModule } from "ngx-color-picker"
import { NgxPrintModule } from "ngx-print"
import { ConfiguracionEntornoService } from "src/app/services/configuracion-entorno.service"
import { EmailTemplateService } from "src/app/services/emailtemplate.service"
import { GestorBloqueoVehiculoComponent } from "../app/pages/bloqueo-vehiculo/gestor-bloqueo-vehiculo/gestor-bloqueo-vehiculo.component"
import { ListadoBloqueoVehiculoComponent } from "../app/pages/bloqueo-vehiculo/listado-bloqueo-vehiculo/listado-bloqueo-vehiculo.component"
import { GestorCategoriaProductoComponent } from "../app/pages/categoria-producto/gestor-categoria-producto/gestor-categoria-producto.component"
import { ListadoCategoriaProductoComponent } from "../app/pages/categoria-producto/listado-categoria-producto/listado-categoria-producto.component"
import { GestorCobranzaComponent } from "../app/pages/cobranza/gestor-cobranza/gestor-cobranza.component"
import { ListadoCobranzaComponent } from "../app/pages/cobranza/listado-cobranza/listado-cobranza.component"
import { GestorComprobanteComponent } from "../app/pages/comprobante/gestor-comprobante/gestor-comprobante.component"
import { ListadoComprobanteComponent } from "../app/pages/comprobante/listado-comprobante/listado-comprobante.component"
import { GestorEstadoDocumentoComponent } from "../app/pages/estado-documento/gestor-estado-documento/gestor-estado-documento.component"
import { ListadoEstadoDocumentoComponent } from "../app/pages/estado-documento/listado-estado-documento/listado-estado-documento.component"
import { GestorItemComprobanteComponent } from "../app/pages/item-comprobante/gestor-item-comprobante/gestor-item-comprobante.component"
import { GestorMonedaComponent } from "../app/pages/moneda/gestor-moneda/gestor-moneda.component"
import { ListadoMonedaComponent } from "../app/pages/moneda/listado-moneda/listado-moneda.component"
import { GestorNotaComponent } from "../app/pages/nota/gestor-nota/gestor-nota.component"
import { ListadoNotaComponent } from "../app/pages/nota/listado-nota/listado-nota.component"
import { GestorRestriccionComponent } from "../app/pages/restriccion/gestor-restriccion/gestor-restriccion.component"
import { ListadoRestriccionComponent } from "../app/pages/restriccion/listado-restriccion/listado-restriccion.component"
import { GestorTipoCuotasTarjetaComponent } from "../app/pages/tipo-cuotas-tarjeta/gestor-tipo-cuotas-tarjeta/gestor-tipo-cuotas-tarjeta.component"
import { ListadoTipoCuotasTarjetaComponent } from "../app/pages/tipo-cuotas-tarjeta/listado-tipo-cuotas-tarjeta/listado-tipo-cuotas-tarjeta.component"
import { GestorTipoTarjetaCreditoComponent } from "../app/pages/tipo-tarjeta-credito/gestor-tipo-tarjeta-credito/gestor-tipo-tarjeta-credito.component"
import { ListadoTipoTarjetaCreditoComponent } from "../app/pages/tipo-tarjeta-credito/listado-tipo-tarjeta-credito/listado-tipo-tarjeta-credito.component"
import { AppRoutingModule } from "./app-routing.module"
import { AppBreadcrumbComponent } from "./app.breadcrumb.component"
import { AppComponent } from "./app.component"
import { AppFooterComponent } from "./app.footer.component"
import { AppMenuComponent, AppSubMenuComponent } from "./app.menu.component"
import { AppMenuitemComponent } from "./app.menuitem.component"
import { AppInlineProfileComponent } from "./app.profile.component"
import { AppTopbarComponent } from "./app.topbar.component"
import { AdminAuthGuard } from "./authguards/AdminAuthGuard"
import { AdministracionAuthGuard } from "./authguards/AdministracionAuthGuard"
import { EdicionPerfilAG } from "./authguards/EdicionPerfilAG"
import { EmpleadoAuthGuard } from "./authguards/EmpleadoAuthGuard"
import { GestorCajaAuthGuard } from "./authguards/GestorCajaAuthGuard"
import { GestorPlantillasAG } from "./authguards/GestorPlantillasAG"
import { NotificacionAG } from "./authguards/NotificacionesAG"
import { ReportesComprasAG } from "./authguards/ReporteComprasAG"
import { ReportesVentasAG } from "./authguards/ReporteVentasAG"
import { RestriccionesAG } from "./authguards/RestriccionesAG"
import { VisualizadorAuthGuard } from "./authguards/VisualizadorAuthGuard"
import { VisualizadorProveedorAG } from "./authguards/VisualizadorProveedorAG"
import { SelectorIdiomaComponent } from "./common-component/selector-idioma/selector-idioma.component"
import { AbmHijoComponent } from "./common-components/abm-hijo/abm-hijo.component"
import { AbmSubcategoriasImponiblesComponent } from "./common-components/abm-subcategorias-imponibles/abm-subcategorias-imponibles.component"
import { AdjuntoComponent } from "./common-components/adjunto/adjunto.component"
import { AutofocusDirective } from "./common-components/autofocus.directive"
import { ColorPickerComponent } from "./common-components/color-picker/color-picker.component"
import { ContactosComponent } from "./common-components/contactos/contactos.component"
import { CotizacionComponent } from "./common-components/cotizacion/cotizacion.component"
import { DelayRenderDirective } from "./common-components/delay-render.directive"
import { DisponibilidadPuestoComponent } from "./common-components/disponibilidad-puesto/disponibilidad-puesto.component"
import { LinkToMapComponent } from "./common-components/link-to-map/link-to-map.component"
import { OpcionPuestoComponent } from "./common-components/opcion-puesto/opcion-puesto.component"
import { PuestoBadgeComponent } from "./common-components/puesto-badge/puesto-badge.component"
import { SelectorCheckboxComponent } from "./common-components/selector-checkbox/selector-checkbox.component"
import { SelectorDiaComponent } from "./common-components/selector-dia/selector-dia.component"
import { SelectorEmailComponent } from "./common-components/selector-email/selector-email.component"
import { SelectorMultipleChipsComponent } from "./common-components/selector-multiple-chips/selector-multiple-chips.component"
import { SelectorVehiculoComponent } from "./common-components/selector-vehiculo/selector-vehiculo.component"
import { SpanDineroEditableComponent } from "./common-components/span-dinero-editable/span-dinero-editable.component"
import { SpanListComponent } from "./common-components/span-list/span-list.component"
import { TotalesComponent } from "./common-components/totales/totales.component"
import { GlobalInjector } from "./common/GlobalInjector"
import { AbmCobrosComponent } from "./common/components/abm-cobros/abm-cobros.component"
import { DefaultToolbarComponent } from "./common/components/default-toolbar/default-toolbar.component"
import { DescriptivoMaterialSelectorComponent } from "./common/components/descriptivo-material-selector/descriptivo-material-selector.component"
import { DescriptivoLabelComponent } from "./common/components/descriptivo-selector/descriptivo-label.component"
import { DescriptivoSelectorComponent } from "./common/components/descriptivo-selector/descriptivo-selector.component"
import { FiltroCompoenent } from "./common/components/filtro/filtro.component"
import { LayoutPickerComponent } from "./common/components/layout-picker/layout-picker.component"
import { LoadingPanelComponent } from "./common/components/loading-panel/loading-panel.component"
import { LogoTopbarComponent } from "./common/components/logo-topbar/logo-topbar.component"
import { ModelGestorComponent } from "./common/components/model-gestor/model-gestor.component"
import { ModelListComponent } from "./common/components/model-list/model-list.component"
import { NotaCardComponent } from "./common/components/nota-card/nota-card.component"
import { ParametricoSelectorComponent } from "./common/components/parametrico-selector/parametrico-selector.component"
import { ProfileBadgeComponent } from "./common/components/profile-badge/profile-badge.component"
import { TelefonoSelectorComponent } from "./common/components/telefono-selector/telefono-selector.component"
import { AllowedDirective } from "./common/directives/allowed.directive"
import { DragAndDropDirective } from "./common/directives/drag-and-drop.directive"
import { HideExterno } from "./common/directives/hideExterno.directive"
import { NacionalidadDependiente } from "./common/directives/nacionalidad-dependiente.directive"
import { ErrorTagComponent } from "./common/error-tag/error-tag.component"
import { AccionesVisiblesPipe } from "./common/pipes/accionesVisibles.pipe"
import { FormatLogPipe } from "./common/pipes/formatLog.pipe"
import { PipesModule } from "./common/pipes/pipes.module"
import { Safe } from "./common/pipes/safe.directive"
import { CommonValidator } from "./common/services/common-validator.service"
import { RolService } from "./common/services/rol.service"
import { UsuarioService } from "./common/services/usuario.service"
import { UsuarioResumenService } from "./common/services/usuarioresumen.service"
import { CuitValidator } from "./common/utils/CuitValidator"
import { MultiEmailValidatorDirective } from "./common/utils/multi-email-validator.directive"
import { ImplementacionModule } from "./implementacion/implementacion-app.module"
import { JwtInterceptor } from "./interceptors/JWTInterceptor"
import { MaterialModule } from "./material.module"
import { CustomDateAdapter } from "./model/CustomDateAdapter"
import { Idioma } from "./model/Idioma"
import { Nacionalidad } from "./model/Nacionalidad"
import { GestorBloqueoFechaComponent } from "./pages/bloqueo-fecha/gestor-bloqueo-fecha/gestor-bloqueo-fecha.component"
import { GestorCajaComponent } from "./pages/caja/gestor-caja/gestor-caja.component"
import { ListadoCajaComponent } from "./pages/caja/listado-caja/listado-caja.component"
import { CalendarioComponent } from "./pages/calendario/calendario.component"
import { GestorArqueoCajaComponent } from "./pages/cambio-estado-caja/gestor-cambio-estado-caja/gestor-cambio-estado-caja.component"
import { ListadoArqueoCajaComponent } from "./pages/cambio-estado-caja/listado-cambio-estado-caja/listado-cambio-estado-caja.component"
import { GestorCambioSaldoCajaComponent } from "./pages/cambio-saldo-caja/gestor-cambio-saldo-caja/gestor-cambio-saldo-caja.component"
import { ListadoCambioSaldoCajaComponent } from "./pages/cambio-saldo-caja/listado-cambio-saldo-caja/listado-cambio-saldo-caja.component"
import { GestorCanalComunicacionComponent } from "./pages/canal-comunicacion/gestor-canal-comunicacion/gestor-canal-comunicacion.component"
import { ListadoCanalComunicacionComponent } from "./pages/canal-comunicacion/listado-canal-comunicacion/listado-canal-comunicacion.component"
import { ListadoCargaMasivaComponent } from "./pages/carga-masiva/listado-carga-masiva/listado-carga-masiva.component"
import { GestorCategoriaHospedajeComponent } from "./pages/categoria-hospedaje/gestor-categoria-hospedaje/gestor-categoria-hospedaje.component"
import { ListadoCategoriaHospedajeComponent } from "./pages/categoria-hospedaje/listado-categoria-hospedaje/listado-categoria-hospedaje.component"
import { GestorCentroCostoComponent } from "./pages/centro-costo/gestor-centro-costo/gestor-centro-costo.component"
import { ListadoCentroCostoComponent } from "./pages/centro-costo/listado-centro-costo/listado-centro-costo.component"
import { GestorClienteComponent } from "./pages/cliente/gestor-cliente/gestor-cliente.component"
import { ListadoClienteComponent } from "./pages/cliente/listado-cliente/listado-cliente.component"
import { CuentaCorrienteComponent } from "./pages/components/cuenta-corriente/cuenta-corriente.component"
import { ComprobanteImprimirComponent } from "./pages/comprobante/gestor-comprobante/comprobante-imprimir/comprobante-imprimir.component"
import { GestorConfiguracionEntornoComponent } from "./pages/configuracion-entorno/gestor-configuracion-entorno/gestor-configuracion-entorno.component"
import { ListadoConfiguracionEntornoComponent } from "./pages/configuracion-entorno/listado-configuracion-entorno/listado-configuracion-entorno.component"
import { ConsentimientoActividadesComponent } from "./pages/consentimiento-actividades/consentimiento-actividades.component"
import { GestorConsentimientoComponent } from "./pages/consentimiento-actividades/gestor-consentimiento/gestor-consentimiento.component"
import { ListadoConsentimientoComponent } from "./pages/consentimiento-actividades/listado-consentimiento/listado-consentimiento.component"
import { GestorCuentaContableComponent } from "./pages/cuenta-contable/gestor-cuenta-contable/gestor-cuenta-contable.component"
import { ListadoCuentaContableComponent } from "./pages/cuenta-contable/listado-cuenta-contable/listado-cuenta-contable.component"
import { GestorEncuestaActividadesComponent } from "./pages/encuesta-actividades/gestor-encuesta-actividades/gestor-encuesta-actividades.component"
import { ListadoEncuestaActividadesComponent } from "./pages/encuesta-actividades/listado-encuesta-actividades/listado-encuesta-actividades.component"
import { EncuestaServicioFamiliaComponent } from "./pages/encuesta-servicio-familia/encuesta-servicio-familia.component"
import { EncuestaServicioComponent } from "./pages/encuesta-servicio/encuesta-servicio.component"
import { GestorEstadoReservaComponent } from "./pages/estado-reserva/gestor-estado-reserva/gestor-estado-reserva.component"
import { ListadoEstadoReservaComponent } from "./pages/estado-reserva/listado-estado-reserva/listado-estado-reserva.component"
import { GestorFacturaComponent } from "./pages/factura/gestor-factura/gestor-factura.component"
import { ListadoFacturaComponent } from "./pages/factura/listado-factura/listado-factura.component"
import { GestorFamiliaProductoComponent } from "./pages/familia-producto/gestor-familia-producto/gestor-familia-producto.component"
import { ListadoFamiliaProductoComponent } from "./pages/familia-producto/listado-familia-producto/listado-familia-producto.component"
import { GestorFormaPagoComponent } from "./pages/forma-pago/gestor-forma-pago/gestor-forma-pago.component"
import { ListadoFormaPagoComponent } from "./pages/forma-pago/listado-forma-pago/listado-forma-pago.component"
import { HelpModule } from "./pages/help/help.module"
import { AperturaCierreComponent } from "./pages/home/components/apertura-cierre/apertura-cierre.component"
import { BuscadorPrecioComponent } from "./pages/home/components/buscador-precio/buscador-precio.component"
import { CalendarioHomeComponent } from "./pages/home/components/calendario-home/calendario-home.component"
import { DisponibilidadHomeComponent } from "./pages/home/components/disponibilidad-home/disponibilidad-home.component"
import { DisponibilidadComponent } from "./pages/home/components/disponibilidad/disponibilidad.component"
import { EstadoClientesComponent } from "./pages/home/components/estado-clientes/estado-clientes.component"
import { EstadoIngresosEgresosComponent } from "./pages/home/components/estado-ingresos-egresos/estado-ingresos-egresos.component"
import { EstadoReservasComponent } from "./pages/home/components/estado-reservas/estado-reservas.component"
import { NotasComponent } from "./pages/home/components/notas/notas.component"
import { ReporteActividadesComponent } from "./pages/home/components/reporte-actividades/reporte-actividades.component"
import { ReportePrevisionServiciosHomeComponent } from "./pages/home/components/reporte-prevision-servicios-home/reporte-prevision-servicios-home.component"
import { SelectorPeriodoDinamicoComponent } from "./pages/home/components/selector-periodo-dinamico/selector-periodo-dinamico.component"
import { ShortcutComponent } from "./pages/home/components/shortcut/shortcut.component"
import { DashboardElementComponent } from "./pages/home/dashboard-element/dashboard-element.component"
import { DashBoardElementHostDirective } from "./pages/home/dashboard-element/dashboard-element.directive"
import { HomeComponent } from "./pages/home/home.component"
import { GestorHotelComponent } from "./pages/hotel/gestor-hotel/gestor-hotel.component"
import { ListadoHotelComponent } from "./pages/hotel/listado-hotel/listado-hotel.component"
import { GestorIdiomaComponent } from "./pages/idioma/gestor-idioma/gestor-idioma.component"
import { ListadoIdiomaComponent } from "./pages/idioma/listado-idioma/listado-idioma.component"
import { GestorInclusionComponent } from "./pages/inclusion/gestor-inclusion/gestor-inclusion.component"
import { ListadoInclusionComponent } from "./pages/inclusion/listado-inclusion/listado-inclusion.component"
import { GestorInsumoComponent } from "./pages/insumo/gestor-insumo/gestor-insumo.component"
import { ListadoInsumoComponent } from "./pages/insumo/listado-insumo/listado-insumo.component"
import { GestorJobConfigurationComponent } from "./pages/job-configuration/gestor-job-configuration/gestor-job-configuration.component"
import { ListadoJobConfigurationComponent } from "./pages/job-configuration/listado-job-configuration/listado-job-configuration.component"
import { EdicionListaPreciosComponent } from "./pages/lista-precios/edicion-lista-precios/edicion-lista-precios.component"
import { ListadoListaPreciosComponent } from "./pages/lista-precios/listado-lista-precios/listado-lista-precios.component"
import { LoginComponent } from "./pages/login/login.component"
import { LogsComponent } from "./pages/logs/logs.component"
import { ListadoMargenReporteComponent } from "./pages/margen-reporte/listado-margen-reporte/listado-margen-reporte.component"
import { VistaMargenReporteComponent } from "./pages/margen-reporte/vista-margen-reporte/vista-margen-reporte.component"
import { MessagesComponent } from "./pages/messages/messages.component"
import { ListadoMovimientoCajaComponent } from "./pages/movimiento-caja/listado-movimiento-caja/listado-movimiento-caja.component"
import { GestorNacionalidadComponent } from "./pages/nacionalidad/gestor-nacionalidad/gestor-nacionalidad.component"
import { ListadoNacionalidadComponent } from "./pages/nacionalidad/listado-nacionalidad/listado-nacionalidad.component"
import { ListadoNotificacionComponent } from "./pages/notificacion/listado-notificacion/listado-notificacion.component"
import { GestorOrdenPagoComponent } from "./pages/orden-pago/gestor-orden-pago/gestor-orden-pago.component"
import { ListadoOrdenPagoComponent } from "./pages/orden-pago/listado-orden-pago/listado-orden-pago.component"
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component"
import { GestorPaisComponent } from "./pages/pais/gestor-pais/gestor-pais.component"
import { ListadoPaisComponent } from "./pages/pais/listado-pais/listado-pais.component"
import { GestorEmailTemplateComponent } from "./pages/parametricos/email-template/gestor-email-template/gestor-email-template.component"
import { ListaEmailTemplatesComponent } from "./pages/parametricos/email-template/lista-email-template/lista-email-template.component"
import { GenericParametricoComponent } from "./pages/parametricos/generic-parametrico/generic-parametrico.component"
import { GestorParametricoComponent } from "./pages/parametricos/gestor-parametrico/gestor-parametrico.component"
import { ParametricosComponent } from "./pages/parametricos/parametricos/parametricos.component"
import { PasswordResetComponent } from "./pages/password-reset/password-reset.component"
import { GestorPlantillaPreguntaComponent } from "./pages/plantilla-pregunta/gestor-plantilla-pregunta/gestor-plantilla-pregunta.component"
import { ListadoPlantillaPreguntaComponent } from "./pages/plantilla-pregunta/listado-plantilla-pregunta/listado-plantilla-pregunta.component"
import { GestorProductoPublicadoComponent } from "./pages/producto-publicado/gestor-producto-publicado/gestor-producto-publicado.component"
import { ListadoProductoPublicadoComponent } from "./pages/producto-publicado/listado-producto-publicado/listado-producto-publicado.component"
import { GestorProductoComponent } from "./pages/producto/gestor-producto/gestor-producto.component"
import { InsumoComponent } from "./pages/producto/insumo/insumo.component"
import { ListadoProductoComponent } from "./pages/producto/listado-producto/listado-producto.component"
import { GestorProveedorComponent } from "./pages/proveedor/gestor-proveedor/gestor-proveedor.component"
import { ListadoProveedorComponent } from "./pages/proveedor/listado-proveedor/listado-proveedor.component"
import { PreciosProveedorComponent } from "./pages/proveedor/precios-proveedor/precios-proveedor.component"
import { GestorProviderConfigurationComponent } from "./pages/provider-configuration/gestor-provider-configuration/gestor-provider-configuration.component"
import { ListadoProviderConfigurationComponent } from "./pages/provider-configuration/listado-provider-configuration/listado-provider-configuration.component"
import { PublicFooterComponent } from "./pages/public-footer/public-footer.component"
import { GestorPuntoDeVentaComponent } from "./pages/punto-de-venta/gestor-punto-de-venta/gestor-punto-de-venta.component"
import { ListadoPuntoDeVentaComponent } from "./pages/punto-de-venta/listado-punto-de-venta/listado-punto-de-venta.component"
import { RankinComprasComponent } from "./pages/rankin-compra/rankin-compras.component"
import { RankinProductoComponent } from "./pages/rankin-producto/rankin-producto.component"
import { AbmRecordatorioComponent } from "./pages/recordatorio/abm-recordatorio/abm-recordatorio.component"
import { GestorRecordatorioComponent } from "./pages/recordatorio/gestor-recordatorio/gestor-recordatorio.component"
import { ListadoRecordatorioComponent } from "./pages/recordatorio/listado-recordatorio/listado-recordatorio.component"
import { RecuperarPasswordComponent } from "./pages/recuperar-password/recuperar-password.component"
import { GestorRegionComponent } from "./pages/region/gestor-region/gestor-region.component"
import { ListadoRegionComponent } from "./pages/region/listado-region/listado-region.component"
import { RegistrarseComponent } from "./pages/registrarse/registrarse.component"
import { ComprasComponent } from "./pages/reportes/compras/compras.component"
import { EstadisticasActividadesComponent } from "./pages/reportes/estadisticas-actividades/estadisticas-actividades.component"
import { ReporteActividadesCardComponent } from "./pages/reportes/reporte-actividades-card/reporte-actividades-card.component"
import { ReporteConsentimientoComponent } from "./pages/reportes/reporte-consentimiento/reporte-consentimiento.component"
import { ReporteEncuestasComponent } from "./pages/reportes/reporte-encuestas/reporte-encuestas.component"
import { ReporteIngresosEgresosComponent } from "./pages/reportes/reporte-ingresos-egresos/reporte-ingresos-egresos.component"
import { ReportePrevisionServiciosComponent } from "./pages/reportes/reporte-prevision-servicios/reporte-prevision-servicios.component"
import { ReporteServiciosComponent } from "./pages/reportes/reporte-servicios/reporte-servicios.component"
import { TotalesFormaPagoComponent } from "./pages/reportes/totales-forma-pago/totales-forma-pago.component"
import { VentasComponent } from "./pages/reportes/ventas/ventas.component"
import { AccionesReservaComponent } from "./pages/reserva/acciones-reserva/acciones-reserva.component"
import { ActividadesComponent } from "./pages/reserva/actividades/actividades.component"
import { CancelacionReservaComponent } from "./pages/reserva/cancelacion-reserva/cancelacion-reserva.component"
import { DetalleActividadComponent } from "./pages/reserva/detalle-actividad/detalle-actividad.component"
import { GestorReservaComponent } from "./pages/reserva/gestor-reserva/gestor-reserva.component"
import { GrupoItinerarioComponent } from "./pages/reserva/grupo-itinerario/grupo-itinerario.component"
import { GrupoComponent } from "./pages/reserva/grupo/grupo.component"
import { ItinerarioReservaComponent } from "./pages/reserva/itinerario/itinerario-reserva/itinerario-reserva.component"
import { ItinerarioComponent } from "./pages/reserva/itinerario/itinerario.component"
import { ListadoReservaComponent } from "./pages/reserva/listado-reserva/listado-reserva.component"
import { ByTipoGrupoPipe } from "./pages/reserva/pipes/by-tipo-grupo.pipe"
import { ByVehiculoPipe } from "./pages/reserva/pipes/by-vehiculo.pipe"
import { GestorRespuestaEncuestaActividadesComponent } from "./pages/respuesta-encuesta-actividades/gestor-respuesta-encuesta-actividades/gestor-respuesta-encuesta-actividades.component"
import { ListadoRespuestaEncuestaActividadesComponent } from "./pages/respuesta-encuesta-actividades/listado-respuesta-encuesta-actividades/listado-respuesta-encuesta-actividades.component"
import { ServiciosProveedorComponent } from "./pages/servicios-proveedor/servicios-proveedor.component"
import { GestorSubcategoriaImponibleComponent } from "./pages/subcategoria-imponible/gestor-subcategoria-imponible/gestor-subcategoria-imponible.component"
import { ListadoSubcategoriaImponibleComponent } from "./pages/subcategoria-imponible/listado-subcategoria-imponible/listado-subcategoria-imponible.component"
import { GestorTagProductoComponent } from "./pages/tag-producto/gestor-tag-producto/gestor-tag-producto.component"
import { ListadoTagProductoComponent } from "./pages/tag-producto/listado-tag-producto/listado-tag-producto.component"
import { GestorTagUsuarioComponent } from "./pages/tag-usuario/gestor-tag-usuario/gestor-tag-usuario.component"
import { ListadoTagUsuarioComponent } from "./pages/tag-usuario/listado-tag-usuario/listado-tag-usuario.component"
import { GestorTipoAusenciaComponent } from "./pages/tipo-ausencia/gestor-tipo-ausencia/gestor-tipo-ausencia.component"
import { ListadoTipoAusenciaComponent } from "./pages/tipo-ausencia/listado-tipo-ausencia/listado-tipo-ausencia.component"
import { GestorTipoComprobanteComponent } from "./pages/tipo-comprobante/gestor-tipo-comprobante/gestor-tipo-comprobante.component"
import { ListadoTipoComprobanteComponent } from "./pages/tipo-comprobante/listado-tipo-comprobante/listado-tipo-comprobante.component"
import { GestorTipoIVAGrabadoComponent } from "./pages/tipo-iva-grabado/gestor-tipo-iva-grabado/gestor-tipo-iva-grabado.component"
import { GestorTipoNotificacionComponent } from "./pages/tipo-notificacion/gestor-tipo-notificacion/gestor-tipo-notificacion.component"
import { ListadoTipoNotificacionComponent } from "./pages/tipo-notificacion/listado-tipo-notificacion/listado-tipo-notificacion.component"
import { GestorTipoPuestoComponent } from "./pages/tipo-puesto/gestor-tipo-puesto/gestor-tipo-puesto.component"
import { ListadoTipoPuestoComponent } from "./pages/tipo-puesto/listado-tipo-puesto/listado-tipo-puesto.component"
import { GestorTipoVarianteComponent } from "./pages/tipo-variante/gestor-tipo-variante/gestor-tipo-variante.component"
import { ListadoTipoVarianteComponent } from "./pages/tipo-variante/listado-tipo-variante/listado-tipo-variante.component"
import { GestorTraspasoDeCajaComponent } from "./pages/traspaso-de-caja/gestor-traspaso-de-caja/gestor-traspaso-de-caja.component"
import { ListadoTraspasoDeCajaComponent } from "./pages/traspaso-de-caja/listado-traspaso-de-caja/listado-traspaso-de-caja.component"
import { GestorUnidadComponent } from "./pages/unidad/gestor-unidad/gestor-unidad.component"
import { ListadoUnidadComponent } from "./pages/unidad/listado-unidad/listado-unidad.component"
import { GestionUserComponent } from "./pages/user/gestion-user/gestion-user.component"
import { UserComponent } from "./pages/user/user.component"
import { GestorVehiculoComponent } from "./pages/vehiculos/gestor-vehiculos/gestor-vehiculos.component"
import { ListadoVehiculosComponent } from "./pages/vehiculos/listado-vehiculos/listado-vehiculos.component"
import { ListadoVendedorComponent } from "./pages/vendedor/listado-vendedor/listado-vendedor.component"
import { ActividadesCardComponent } from "./pages/voucher/actividades-card/actividades-card.component"
import { HospedajeCardComponent } from "./pages/voucher/hospedaje-card/hospedaje-card.component"
import { VoucherActividadComponent } from "./pages/voucher/voucher-actividad/voucher-actividad.component"
import { ByMonedaPipe } from "./pipes/by-moneda.pipe"
import { ByProductoDescripcion } from "./pipes/by-producto-descripcion.pipe"
import { ByRestriccionPipe } from "./pipes/by-restriccion.pipe"
import { ByTipoProducto } from "./pipes/by-tipoProducto"
import { ColumnasVisiblesPipe } from "./pipes/columnas-visibles.pipe"
import { DescriptivoPipe } from "./pipes/descriptivoPipe.pipe"
import { ExcludeByIdPipe } from "./pipes/exludeById.pipe"
import { FillPipe } from "./pipes/fill.pipe"
import { HabilitadosPipe } from "./pipes/habilitados.pipe"
import { HospedajePipe } from "./pipes/hospedaje.pipe"
import { NoHabitualPipe } from "./pipes/no-habitual.pipe"
import { PrestadorPipe } from "./pipes/prestador.pipe"
import { PuestoByTipoPipe } from "./pipes/puesto-by-tipo.pipe"
import { VisiblePipe } from "./pipes/visible.pipe"
import { PrimeNgModule } from "./prime-ng.module"
import { DownloadFileComponent } from "./public/download-file/download-file.component"
import { ConfigService, initConfig } from "./services/config.service"
import { EstadoVehiculoService } from "./services/estado-vehiculo.service"
import { ProviderConfigurationService } from "./services/provider-configuration.service"
import { ProviderService } from "./services/provider.service"
import { StorageService } from "./services/storage.service"
import { TipoNotificacionService } from "./services/tipo-notificacion.service"
import { TipoVehiculoService } from "./services/tipovehiculo.service"
import { TokenService } from "./services/token.service"
import { VehiculoService } from "./services/vehiculo.service"

registerLocaleData(localeAr)
registerLocaleData(localeEsUS)
registerLocaleData(localeRuRu)
export function ID() {
	// Math.random should be unique because of its seeding algorithm.
	// Convert it to base 36 (numbers + letters), and grab the first 9 characters
	// after the decimal.
	return "_" + Math.random().toString(36).substring(2, 9)
}

export const DEF_LOGO = "assets/implementacion/images/logo.png"
export const COLORS = [
	"#003f5c",
	"#f43081",
	"#665191",
	"#a05195",
	"#d45087",
	"#f95d6a",
	"#ff7c43",
	"#ffa600",
	"#ce99ff",
	"#8eaaff",
	"#2db7ff",
	"#00bffb",
	"#00c3dd",
	"#00c3ba",
	"#55c099",
	"#83bb7f"
]

@NgModule({
	declarations: [
		AppComponent,
		PageNotFoundComponent,
		HomeComponent,
		LoginComponent,
		RegistrarseComponent,
		MessagesComponent,
		AppFooterComponent,
		AppMenuComponent,
		AppSubMenuComponent,
		AppInlineProfileComponent,
		AppTopbarComponent,
		DescriptivoLabelComponent,
		DescriptivoSelectorComponent,
		PasswordResetComponent,
		ListaEmailTemplatesComponent,
		LoadingPanelComponent,
		ByTipoProducto,
		AccionesVisiblesPipe,
		UserComponent,
		ModelListComponent,
		ModelGestorComponent,
		GestionUserComponent,
		FiltroCompoenent,
		ProfileBadgeComponent,
		ParametricosComponent,
		GenericParametricoComponent,
		ListadoVehiculosComponent,
		GestorVehiculoComponent,
		GestorParametricoComponent,
		ParametricoSelectorComponent,
		DescriptivoMaterialSelectorComponent,
		ListadoClienteComponent,
		GestorClienteComponent,
		ListadoHotelComponent,
		GestorHotelComponent,
		ListadoNacionalidadComponent,
		ColorPickerComponent,
		GestorNacionalidadComponent,
		ListadoTipoPuestoComponent,
		GestorTipoPuestoComponent,
		ListadoProveedorComponent,
		GestorProveedorComponent,
		SelectorPeriodoDinamicoComponent,
		ListadoCuentaContableComponent,
		GestorCuentaContableComponent,
		ListadoInsumoComponent,
		GestorInsumoComponent,
		ListadoProductoComponent,
		GestorProductoComponent,
		GestorUnidadComponent,
		ListadoUnidadComponent,
		ListadoIdiomaComponent,
		GestorIdiomaComponent,
		CuitValidator,
		PuestoByTipoPipe,
		InsumoComponent,
		GestorCentroCostoComponent,
		ListadoCentroCostoComponent,
		ListadoFacturaComponent,
		GestorFacturaComponent,
		GestorOrdenPagoComponent,
		ListadoOrdenPagoComponent,
		ListadoCajaComponent,
		GestorCajaComponent,
		ListadoVendedorComponent,
		ListadoReservaComponent,
		GestorReservaComponent,
		ListadoListaPreciosComponent,
		EdicionListaPreciosComponent,
		PuestoBadgeComponent,
		PuestoBadgeComponent,
		DefaultToolbarComponent,
		DescriptivoPipe,
		SelectorVehiculoComponent,
		GestorEstadoReservaComponent,
		ListadoEstadoReservaComponent,
		ListadoMovimientoCajaComponent,
		DetalleActividadComponent,
		DefaultToolbarComponent,
		SelectorIdiomaComponent,
		SelectorMultipleChipsComponent,
		SelectorDiaComponent,
		SelectorIdiomaComponent,
		GestorEmailTemplateComponent,
		FillPipe,
		SpanListComponent,
		ByVehiculoPipe,
		ByRestriccionPipe,
		ActividadesComponent,
		GestorBloqueoFechaComponent,
		GrupoComponent,
		GrupoItinerarioComponent,
		FillPipe,
		SpanDineroEditableComponent,
		SelectorIdiomaComponent,
		SelectorMultipleChipsComponent,
		AbmHijoComponent,
		RecuperarPasswordComponent,
		SelectorCheckboxComponent,
		AllowedDirective,
		HideExterno,
		NacionalidadDependiente,
		ListadoComprobanteComponent,
		GestorComprobanteComponent,
		GestorItemComprobanteComponent,
		ListadoCobranzaComponent,
		AbmCobrosComponent,
		GestorCobranzaComponent,
		ContactosComponent,
		ListadoEstadoDocumentoComponent,
		GestorEstadoDocumentoComponent,
		ListadoMonedaComponent,
		GestorMonedaComponent,
		CotizacionComponent,
		ListadoNotificacionComponent,
		ByMonedaPipe,
		ListadoCategoriaProductoComponent,
		SelectorMultipleChipsComponent,
		GestorCategoriaProductoComponent,
		ListadoRestriccionComponent,
		GestorRestriccionComponent,
		AutofocusDirective,
		DelayRenderDirective,
		ContactosComponent,
		ContactosComponent,
		RecuperarPasswordComponent,
		SelectorCheckboxComponent,
		ContactosComponent,
		HabilitadosPipe,
		LayoutPickerComponent,
		ShortcutComponent,
		AppBreadcrumbComponent,
		DashboardElementComponent,
		DashBoardElementHostDirective,
		ListadoArqueoCajaComponent,
		GestorArqueoCajaComponent,
		ListadoCambioSaldoCajaComponent,
		GestorCambioSaldoCajaComponent,
		ListadoTraspasoDeCajaComponent,
		GestorTraspasoDeCajaComponent,
		AperturaCierreComponent,
		BuscadorPrecioComponent,
		RankinProductoComponent,
		RankinComprasComponent,
		ReporteIngresosEgresosComponent,
		VentasComponent,
		ComprasComponent,
		DownloadFileComponent,
		GestorFormaPagoComponent,
		ListadoFormaPagoComponent,
		CuentaCorrienteComponent,
		ListadoNotaComponent,
		NotasComponent,
		GestorNotaComponent,
		ListadoBloqueoVehiculoComponent,
		Safe,
		GestorBloqueoVehiculoComponent,
		NotaCardComponent,
		ListadoTipoTarjetaCreditoComponent,
		GestorTipoTarjetaCreditoComponent,
		ListadoTipoCuotasTarjetaComponent,
		GestorTipoCuotasTarjetaComponent,
		CancelacionReservaComponent,
		AccionesReservaComponent,
		ItinerarioComponent,
		MultiEmailValidatorDirective,
		ListadoCanalComunicacionComponent,
		GestorCanalComunicacionComponent,
		ListadoTipoNotificacionComponent,
		GestorTipoNotificacionComponent,
		ConsentimientoActividadesComponent,
		MultiEmailValidatorDirective,
		ListadoConsentimientoComponent,
		GestorConsentimientoComponent,
		ReporteConsentimientoComponent,
		CalendarioComponent,
		ListadoTipoAusenciaComponent,
		ListadoConfiguracionEntornoComponent,
		GestorConfiguracionEntornoComponent,
		GestorTipoAusenciaComponent,
		ReporteActividadesCardComponent,
		EstadisticasActividadesComponent,
		ByProductoDescripcion,
		ReporteActividadesComponent,
		ListadoPuntoDeVentaComponent,
		GestorPuntoDeVentaComponent,
		ActividadesCardComponent,
		VoucherActividadComponent,
		PrestadorPipe,
		FormatLogPipe,
		LogoTopbarComponent,
		NoHabitualPipe,
		CalendarioHomeComponent,
		DisponibilidadPuestoComponent,
		OpcionPuestoComponent,
		DisponibilidadHomeComponent,
		EstadoClientesComponent,
		ServiciosProveedorComponent,
		ListadoMargenReporteComponent,
		VistaMargenReporteComponent,
		ListadoPlantillaPreguntaComponent,
		GestorPlantillaPreguntaComponent,
		ListadoEncuestaActividadesComponent,
		GestorEncuestaActividadesComponent,
		EncuestaServicioComponent,
		PublicFooterComponent,
		GestorRespuestaEncuestaActividadesComponent,
		ListadoRespuestaEncuestaActividadesComponent,
		PublicFooterComponent,
		AppMenuitemComponent,
		ListadoFamiliaProductoComponent,
		GestorFamiliaProductoComponent,
		ListadoJobConfigurationComponent,
		GestorJobConfigurationComponent,
		ReporteEncuestasComponent,
		ListadoCargaMasivaComponent,
		EstadoIngresosEgresosComponent,
		EstadoIngresosEgresosComponent,
		DragAndDropDirective,
		ByTipoGrupoPipe,
		ColumnasVisiblesPipe,
		TotalesFormaPagoComponent,
		ListadoTipoVarianteComponent,
		GestorTipoVarianteComponent,
		EncuestaServicioFamiliaComponent,
		TelefonoSelectorComponent,
		GestorRecordatorioComponent,
		ListadoRecordatorioComponent,
		AbmRecordatorioComponent,
		ListadoTagUsuarioComponent,
		GestorTagUsuarioComponent,
		ErrorTagComponent,
		ReporteServiciosComponent,
		LinkToMapComponent,
		AdjuntoComponent,
		SelectorEmailComponent,
		GestorTipoComprobanteComponent,
		GestorTipoIVAGrabadoComponent,
		ListadoTipoComprobanteComponent,
		GestorProviderConfigurationComponent,
		ListadoProviderConfigurationComponent,
		ListadoSubcategoriaImponibleComponent,
		GestorSubcategoriaImponibleComponent,
		PreciosProveedorComponent,
		ListadoProductoPublicadoComponent,
		GestorProductoPublicadoComponent,
		GestorInclusionComponent,
		ListadoInclusionComponent,
		DisponibilidadComponent,
		TotalesComponent,
		AbmSubcategoriasImponiblesComponent,
		ReportePrevisionServiciosComponent,
		ReportePrevisionServiciosHomeComponent,
		GestorTagProductoComponent,
		ListadoTagProductoComponent,
		HospedajePipe,
		ExcludeByIdPipe,
		HospedajeCardComponent,
		GestorRegionComponent,
		ListadoRegionComponent,
		ListadoPaisComponent,
		GestorPaisComponent,
		ItinerarioReservaComponent,
		ComprobanteImprimirComponent,
		VisiblePipe,
		LogsComponent,
		EstadoReservasComponent,
		ListadoCategoriaHospedajeComponent,
		GestorCategoriaHospedajeComponent
	],
	imports: [
		BrowserModule,
		PrimeNgModule,
		MaterialModule,
		ImplementacionModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		ColorPickerModule,
		RecaptchaModule,
		PipesModule,
		HelpModule,
		// AngularFireAuthModule,
		// AngularFireMessagingModule,
		// AngularFireModule.initializeApp(environment.firebase),
		ServiceWorkerModule.register("combined-sw.js", {
			enabled: true,
			registrationStrategy: "registerImmediately"
		}),
		RecaptchaModule,
		MatTabsModule,
		GridsterModule,
		QRCodeModule,
		FullCalendarModule,
		NgxPrintModule,
		ClipboardModule
	],
	providers: [
		AdminAuthGuard,
		GestorPlantillasAG,
		NotificacionAG,
		EmpleadoAuthGuard,
		VisualizadorAuthGuard,
		EdicionPerfilAG,
		TokenService,
		CommonValidator,
		UsuarioService,
		StorageService,
		UsuarioResumenService,
		RolService,
		EmailTemplateService,
		TipoNotificacionService,
		VehiculoService,
		TipoVehiculoService,
		EstadoVehiculoService,
		ProviderConfigurationService,
		ProviderService,
		AsyncPipe,
		AdministracionAuthGuard,
		ReportesComprasAG,
		ReportesVentasAG,
		VisualizadorProveedorAG,
		GestorCajaAuthGuard,
		RestriccionesAG,
		{ provide: DateAdapter, useClass: CustomDateAdapter },

		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		},
		{ provide: DEFAULT_CURRENCY_CODE, useValue: "$" },
		{ provide: LOCALE_ID, useValue: "es-AR" },
		MatIconRegistry,
		ConfigService,
		{
			provide: APP_INITIALIZER,
			useFactory: initConfig,
			deps: [ConfigService],
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(
		private injector: Injector,
		public matIconRegistry: MatIconRegistry
	) {
		GlobalInjector.InjectorInstance = this.injector
		matIconRegistry.registerFontClassAlias("fontawesome", "fa")
	}
}
export function LocalizacionEntorno() {
	const idioma = IdiomaEntorno() || "es"
	const pais = NacionalidadEntorno()?.codigo || "AR"
	return idioma + "-" + pais
}
export function MonedaEntorno() {
	return ConfiguracionEntornoService.CONFIGURACION_ENTORNO?.moneda
}

export function LogoEntorno() {
	return ConfiguracionEntornoService.CONFIGURACION_ENTORNO?.profilePic?.picPath || "assets/images/logo.png"
}

export function NacionalidadEntorno() {
	return ConfiguracionEntornoService.CONFIGURACION_ENTORNO?.pais || Nacionalidad.ARGENTINA
}
export function IdiomaEntorno() {
	return ConfiguracionEntornoService.CONFIGURACION_ENTORNO?.idioma?.codigo?.toLowerCase() || Idioma.DEFAULT_CODIGO.toLowerCase()
}
export function MostrarImpuestos(): boolean {
	return ConfiguracionEntornoService.CONFIGURACION_ENTORNO?.mostrarSiempreImpuestos || false
}
