import { Component, OnDestroy, OnInit } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { NavigationExtras, Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { filter } from "rxjs"
import { MonedaEntorno } from "src/app/app.module"
import { OrdenPagoAG } from "src/app/authguards/OrdenPagoAG"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { AccionesTabla } from "src/app/common/model/AccionesTabla"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { FiltroFactura } from "src/app/common/model/FiltroFactura"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { UsuarioService } from "src/app/common/services/usuario.service"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { Moneda } from "src/app/model/Moneda"
import { OrdenPagoResumen } from "src/app/model/OrdenPagoResumen"
import { Totales } from "src/app/model/Totales"
import { MotivoCancelacionService } from "src/app/services/motivo-cancelacion.service"
import { OrdenPagoResumenService } from "src/app/services/orden-pago-resumen.service"
import { ProveedorService } from "src/app/services/proveedor.service"
import { BreadcrumbService } from "./../../../breadcrumb.service"
import { EstadoDocumentoService } from "./../../../common/services/estado-documento.service"

@Component({
	selector: "listado-orden-pago",
	templateUrl: "listado-orden-pago.component.html",
	styleUrls: ["listado-orden-pago.component.less"]
})
export class ListadoOrdenPagoComponent extends SessionComponent implements OnInit, OnDestroy {
	public formNotificarProveedor: FormGroup
	public filtro: FiltroFactura = new FiltroFactura("orden-pago-filter")
	public listado: OrdenPagoResumen[] = []
	public verNotificarProveedor: boolean = false
	public moneda: Moneda = MonedaEntorno()
	public itemEditado: OrdenPagoResumen
	public columns = [
		{
			field: "id",
			header: "#",
			class: "id-column",
			role: "estado",
			sortable: true,
			order: 40
		},
		{
			field: "proveedorStr",
			header: "Proveedor",
			class: "descriptivo-tabla",
			role: "link-tabla",
			sortable: true,
			navegar: (item) => {
				if (item?.proveedor) this.router.navigate(["proveedor/vista"], { queryParams: { id: item.proveedor.id } })
			},
			order: 40
		},
		{
			field: "fecha",
			header: "Fecha",
			class: "date-tabla",
			role: "date-tabla",
			sortable: true,
			order: 40
		},
		{
			field: "descripcion",
			header: "Descripcion",
			class: "text-tabla",
			role: "text-tabla",
			width: "20rem",
			sortable: true,
			order: 40
		},
		{
			field: "observaciones",
			header: "Observaciones",
			class: "text-tabla",
			role: "text-tabla",
			width: "20rem",
			sortable: false,
			order: 40
		},
		{
			field: "responsable",
			header: "Responsable",
			class: "text-tabla",
			role: "text-tabla",
			sortable: true,
			order: 40
		},
		{
			field: "importe",
			header: "Importe",
			class: "money-tabla text-right",
			role: "money-tabla",
			monedaField: "moneda",
			sortable: true,
			order: 40
		}
	]
	public get permiteNuevo() {
		return this.ordenPagoAG.esVisible(this.usuario) && !this.usuario.esExterno
	}
	public menuAcciones: AccionesTabla[] = [
		new AccionesTabla(
			"Aprobar Orden de Pago",
			"fa fa-check",
			"accion-tabla",
			(items: OrdenPagoResumen[]) => {
				items.forEach((item) => {
					this.service.aprobarOrdenPago(item)
				})
			},
			(items: OrdenPagoResumen[]) => {
				return this.esJefeAdministracion && items.length && items.every((ii) => ii.estado?.codigo == "P") && !this.usuario?.esExterno
			}
		),
		new AccionesTabla(
			"Rechazar Orden de Pago",
			"fa fa-close",
			"accion-tabla",
			(items: OrdenPagoResumen[]) => {
				items.forEach((item) => {
					this.service.rechazarOrdenPago(item)
				})
			},
			(items: OrdenPagoResumen[]) => {
				return this.esJefeAdministracion && items.length && items.every((ii) => ii.estado?.codigo == "P") && !this.usuario?.esExterno
			}
		)
	]
	public acciones: AccionesTabla[] = [
		new AccionesTabla(
			"Aprobar Orden de Pago",
			"fa fa-check",
			"accion-tabla",
			(item) => {
				this.service.aprobarOrdenPago(item)
			},
			(item: OrdenPagoResumen) => {
				return this.esJefeAdministracion && item.estado?.codigo == "P" && !this.usuario?.esExterno
			}
		),
		new AccionesTabla(
			"Rechazar Orden de Pago",
			"fa fa-close",
			"accion-tabla",
			(item) => {
				this.service.rechazarOrdenPago(item)
			},
			(item: OrdenPagoResumen) => {
				return this.esJefeAdministracion && item.estado?.codigo == "P" && !this.usuario?.esExterno
			}
		),
		new AccionesTabla(
			"Editar Orden de Pago",
			"fa fa-pencil",
			"accion-tabla",
			(item) => {
				this.editar(item.id)
			},
			(item: OrdenPagoResumen) => {
				return item.estado && (item.estado.codigo == "P" || item.estado.codigo == "PP") && !this.usuario?.esExterno
			}
		),
		new AccionesTabla(
			"Eliminar Orden de Pago",
			"fa fa-trash",
			"accion-tabla",
			(item) => {
				this.motivoSeleccionado = null
				this.ordenEliminando = item
			},
			(item: OrdenPagoResumen) => {
				return this.esJefeAdministracion && item.estado?.codigo != "C" && !this.usuario?.esExterno
			}
		),
		new AccionesTabla(
			"Ver Orden de Pago",
			"fa fa-eye",
			"accion-tabla",
			(item) => {
				this.ver(item.id)
			},
			(item: OrdenPagoResumen) => {
				return true
			}
		),
		new AccionesTabla(
			"Enviar al proveedor",
			"fa fa-envelope",
			"accion-tabla",
			(item) => {
				this.mostrarNotificarProveedor(item)
			},
			(item: OrdenPagoResumen) => {
				return !this.usuario?.esExterno && (item.estado?.codigo == "A" || item.estado?.codigo == "F")
			}
		)
	]
	public colspan = 0
	public proveedores: Descriptivo[] = []
	public responsables: Descriptivo[] = []

	public ordenEliminando: OrdenPagoResumen
	public motivosCancelacion: Descriptivo[] = []
	public motivoSeleccionado: Descriptivo
	public estadosOrdenes: EstadoDocumento[]
	public totales: Totales
	private lastSearchTotales: string
	constructor(
		messagesService: MessagesService,
		public service: OrdenPagoResumenService,
		public provService: ProveedorService,
		public userService: UsuarioService,
		private router: Router,
		public estadosService: EstadoDocumentoService,
		public confirmationService: ConfirmationService,
		public motivoService: MotivoCancelacionService,
		private breadcrumbService: BreadcrumbService,
		private ordenPagoAG: OrdenPagoAG
	) {
		super(messagesService)
		this.breadcrumbService.setItems([
			{
				label: "Compras"
			}
		])
	}
	destinatario: string = ""
	ngOnInit() {
		this.userService.getDescriptivos().then((us) => (this.responsables = us))
		this.motivoService.getAll().then((ms) => (this.motivosCancelacion = ms))
		this.formNotificarProveedor = new FormGroup({ destinatarioControl: new FormControl(null, Validators.required) })
		this.filtro.dataChange.pipe(filter((f) => f != undefined)).subscribe((f) => {
			if (this.lastSearchTotales === JSON.stringify(f.summary)) return
			this.lastSearchTotales = JSON.stringify(f.summary) || null
			this.service.getTotales(f).then((t) => {
				this.totales = t
			})
		})
		if (this.usuario.esExterno) {
			this.columns = this.columns.filter((c) => c.field != "responsable" && c.field != "observaciones" && c.field != "proveedorStr")
		}
		this.colspan = this.columns.length
		this.estadosService
			.getAll()
			.then(
				(estados) =>
					(this.estadosOrdenes = estados.filter(
						(est) => est.codigo == "A" || (!this.usuario?.esExterno && (est.codigo == "PP" || est.codigo == "C"))
					))
			)
	}

	ngOnDestroy() {}

	public totalImporte() {
		return this.totales?.totalConsumido || 0
	}

	public editar(id: number) {
		let query: NavigationExtras = {
			queryParams: {
				id: id
			}
		}
		this.router.navigate(["orden-pago/edit"], query)
	}

	public ver(id: number) {
		let query: NavigationExtras = {
			queryParams: {
				id: id
			}
		}
		this.router.navigate(["orden-pago/vista"], query)
	}

	public mostrarNotificarProveedor(item: OrdenPagoResumen) {
		this.itemEditado = item
		this.provService.getById(item.proveedor.id).then((r) => {
			if (r.emailAdministrativo) {
				this.formNotificarProveedor.patchValue({ destinatarioControl: r.emailAdministrativo })
			} else {
				this.formNotificarProveedor.patchValue({ destinatarioControl: r.email })
			}
			this.verNotificarProveedor = true
		})
	}

	public notificarProveedor() {
		const formValue = this.formNotificarProveedor.value

		if (this.formNotificarProveedor.valid) {
			this.service.notificarProveedor(this.itemEditado, formValue.detinatarioControl).then((r) => {
				this.success("Se envió la notificación")
				this.verNotificarProveedor = false
			})
		} else {
			this.error("Verifique todos los campos")
		}
	}
	public eliminar() {
		this.service.eliminarOrden(this.ordenEliminando.id, this.motivoSeleccionado.id).then((res) => {
			this.ordenEliminando = null
			this.service.getAll(this.filtro).then((fs) => (this.listado = fs))
			this.success(this.translateService.get("EL_ITEM_FUE_ELIMINA_15"))
		})
	}

	cancelacionShow() {
		//   (<HTMLElement>document.querySelector('.motivoSelector mat-form-field')).style.width = '100%';
	}
}
