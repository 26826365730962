import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { Subscription } from "rxjs"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { UsuarioService } from "src/app/common/services/usuario.service"
import { Caja } from "src/app/model/Caja"
import { CajaCuentaContable } from "src/app/model/CajaCuentaContable"
import { CuentaContable } from "src/app/model/CuentaContable"
import { CajaService } from "src/app/services/caja.service"
import { CentroCostoService } from "src/app/services/centro-costo.service"
import { CuentaContableService } from "src/app/services/cuenta-contable.service"
import { MonedaService } from "src/app/services/moneda.service"
import { SessionComponent } from "./../../../common/components/session-component.component"

@Component({
	selector: "gestor-caja",
	templateUrl: "gestor-caja.component.html",
	styleUrls: ["gestor-caja.component.less"]
})
export class GestorCajaComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	@Input()
	public item: Caja = new Caja()
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false
	@Input()
	public readonly: boolean = false

	@Output()
	public onGuardado: EventEmitter<Caja> = new EventEmitter<Caja>()

	@Output()
	public onCancelado = new EventEmitter()

	public cuentaContableOptions: Descriptivo[] = []
	public usuarioOptions: Descriptivo[] = []

	constructor(
		messagesService: MessagesService,
		public service: CajaService,
		private router: Router,
		private route: ActivatedRoute,
		private confService: ConfirmationService,
		private cuentaContableService: CuentaContableService,
		private usuarioService: UsuarioService,
		private centroService: CentroCostoService,
		public monedaService: MonedaService
	) {
		super(messagesService)
	}
	public onEditar(event) {
		this.router.navigate(["caja/edit"], { queryParams: { id: this.item.id } })
	}
	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}

	public isValid() {
		return this.item.descripcion && this.item.usuariosAutorizados.length > 0 && this.item.cuentas.every((c) => c.cuenta)
	}
	ngOnInit() {
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
				}
			})
		)
		const $this = this
		if (this.route.snapshot.url.some((u) => u.path == "caja")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				if (!$this.service) return
				if (id) {
					$this.service.getById(id).then((r) => {
						$this.item = r
						if ($this.item.esSistema) {
							this.readonly = true
						}
						$this.centroService.getDescriptivos().then((cs) =>
							cs.forEach((c) => {
								if (!$this.item.cuentas.some((ci) => ci.centro.id == c.id)) {
									$this.item.cuentas.push(new CajaCuentaContable(null, c))
								}
							})
						)
					})
				} else {
					$this.item = $this.service ? $this.service.newEnt() : null
					$this.centroService.getDescriptivos().then((cs) =>
						cs.forEach((c) => {
							$this.item.cuentas.push(new CajaCuentaContable(null, c))
						})
					)
				}
			})
		}

		this.cuentaContableService.getDescriptivos().then((r) => {
			this.cuentaContableOptions = r
		})
		this.usuarioService.getDescriptivos().then((r) => {
			this.usuarioOptions = r
		})
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}

	onCuentaGuardada(cuenta: CuentaContable) {
		this.cuentaContableOptions.push(Descriptivo.fromData(cuenta))
	}
}
