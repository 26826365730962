import { animate, style, transition, trigger } from "@angular/animations"
import { Location } from "@angular/common"
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core"
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router"
import * as moment from "moment"
import { ConfirmationService, MenuItem } from "primeng/api"
import { Subscription } from "rxjs"
import { debounceTime, filter } from "rxjs/operators"
import { commonAnimations } from "src/app/common-animations"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { Idioma } from "src/app/common/model/Idioma"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { ServicioAbstract } from "src/app/common/services/service.service"
import { Auditable } from "src/app/model/Auditable"
import { ConfiguracionUsuarioService } from "src/app/services/configuracion-usuario.service"
import { HelpService } from "src/app/services/help.service"
import { NovedadService } from "src/app/services/novedades.service"
import { CargaMasivaService } from "./../../../services/carga-masiva.service"
import { ModelListHandler } from "./../../interfaces/ModelListHandler"
import { AccionesTabla } from "./../../model/AccionesTabla"
import { Filtro } from "./../../model/Filtro"
import { ResumenModel } from "./../../model/ResumenModel"
import { FilterService } from "./../../services/filter-service"
import { IdiomaService } from "./../../services/idioma.service"
import { LoadingService } from "./../../services/loading-data-service.service"
import { ColorUtils } from "./../../utils/color-utils"
import { ExcelDownloader } from "./../../utils/excelDownloader"

export const DEFAULT_SKIP: any = { searchStr: true, rowClass: true, imageUrl: true, profilePic: true, peso: true }

@Component({
	selector: "model-list",
	templateUrl: "model-list.component.html",
	styleUrls: ["./model-list.component.less"],
	animations: [
		commonAnimations,

		trigger("floatIn", [
			transition(":enter", [
				style({ bottom: "-6em" }),
				animate("0.2s ease-out", style({ bottom: "1em" })),
				animate("0.1s ease-out", style({ bottom: "0.5em" }))
			]),
			transition(":leave", [
				style({ bottom: "0.75em" }),
				animate("0.1s ease-in", style({ bottom: "1em" })),
				animate("0.2s ease-in", style({ bottom: "-6em" }))
			])
		])
	]
})
export class ModelListComponent extends SessionComponent {
	@ViewChild("defaultMobileDataContent", { static: false })
	public defaultMobileDataContent: TemplateRef<any>
	@ViewChild("defaultDesktopDataContent", { static: false })
	public defaultDesktopDataContent: TemplateRef<any>
	@Input()
	public itemDataViewTemplate: TemplateRef<any>
	@Input()
	public buscarTraduccion: boolean = false
	@ViewChild("menuAccionesDefault", { static: true }) public menuAccionesDefault
	@Input()
	public menuAcciones
	@Input()
	public navegable: boolean = true
	@ViewChild("menu", { static: true })
	private menu: any
	public items: MenuItem[] = []
	@Input()
	public showDataview: boolean = false
	@Input()
	public notificarNovedades: boolean = false
	mostrarAuditar: boolean = false
	selectedItem: Auditable
	@Input()
	verAcciones = false
	@Input() dataClass: string = "data"
	@Input() addDefaultAcciones: boolean = false
	@Input()
	public headerActionsContent: TemplateRef<any>
	@Input() public updateRoute: boolean = true
	@Input() public helpPath: string = ""
	@Input()
	public loading: boolean = false
	private _menuItemAcciones: MenuItem[] = [
		{
			label: "Eliminar",
			command: () => this.eliminarMasivo
		}
	]
	currentQuery: string
	public get menuItemAcciones(): MenuItem[] {
		return this._menuItemAcciones
	}
	@Input()
	public set menuItemAcciones(v: MenuItem[]) {
		this._menuItemAcciones = v
	}
	public menuItemMultiple: MenuItem[] = []
	@Input()
	public permiteCargaMasiva: boolean = false

	public get defaultAcciones() {
		return [
			new AccionesTabla(
				"EDITAR",
				"fa fa-pencil",
				"",
				(da, event) => {
					this.editar(da.id, da)
					event.stopPropagation()
				},
				(data) => {
					return this.editable
				}
			),
			new AccionesTabla(
				"BORRAR",
				"fa fa-trash",
				"",
				(da, event) => {
					this.eliminar(da.id, event, da)
				},
				(data) => {
					return this.borrable
				}
			),
			//new AccionesTabla(this.translateService.get('HABILITAR'),"fa fa-check","",this.habilitar, (data)=>{return this.ha}),
			new AccionesTabla(
				"VER",
				"fa fa-eye",
				"",
				(da, event) => {
					this.ver(da.id, da)
					event.stopPropagation()
				},
				(data) => {
					return true
				}
			)
		]
	}

	public getTemplateContent() {
		return this.isMobile() || this.filtro?.layout == "G"
			? this.mobileDataContent
				? this.mobileDataContent
				: this.defaultMobileDataContent
			: this.dataContent
			? this.dataContent
			: this.defaultDesktopDataContent
	}
	@Input()
	public modoSeleccion: "single" | "multiple" | "none" = "none"
	public rows = 20
	@Input()
	public onClick: (item, event?) => void = (item, event) => {
		var selection = window.getSelection()
		if (!selection.isCollapsed) {
			// "isCollapsed" es falso si hay una selección de texto. En este caso, se evita el evento de click.
			event.preventDefault()
		} else {
			if (!this.isMobile() && this.modoSeleccion == "none" && this.navegable && this.visualizable) return this.ver(item.id, item)
			if (this.isMobile()) return this.ver(item.id, item)
		}
	}
	@Input()
	public hideHeader: boolean = false
	@Input()
	public hideTitle: boolean = false
	public seleccion: ResumenModel[] = []
	@Input()
	public styleClass: string = ""
	@Output()
	public seleccionChange: EventEmitter<ResumenModel[]> = new EventEmitter<ResumenModel[]>()

	public subFiltro: Subscription
	public subColumnas: Subscription
	@Input()
	public footer: TemplateRef<any>
	@Input()
	public caption: TemplateRef<any>
	@Input()
	public previousContent: TemplateRef<any>
	@Input()
	public header: TemplateRef<any>
	@Input()
	public subheader: TemplateRef<any>
	@Input()
	public dataContent: TemplateRef<any>

	@Input()
	public mobileDataContent: TemplateRef<any>

	@Input()
	public filterContent: TemplateRef<any>

	@Input()
	public titleContent: TemplateRef<any>

	@Input()
	public borrable: boolean = true

	@Input()
	public visualizable: boolean = true

	@Input()
	public handler: ModelListHandler

	@Input()
	public hasDetail: boolean = true

	public displayFilterBar: boolean = false
	public displaySavedFilterBar: boolean = false

	@Input()
	public getRowClass: (any) => string | void = (r) => {
		return
	}
	private _acciones: AccionesTabla[]
	public get acciones(): AccionesTabla[] {
		return this._acciones
	}
	@Input()
	public set acciones(v: AccionesTabla[]) {
		this._acciones = v

		if (!this._acciones.some((accion) => accion.label == "AUDITAR")) {
			this.addAccionAudit()
		}
	}

	@Input()
	public isLazy: boolean = false
	@Input()
	public isPaginator: boolean = true

	@Input()
	public editable: boolean = true

	@Input()
	public conResumen: boolean = true

	@Input()
	public permiteNuevo: boolean = true

	@Input()
	public customLoadingService: LoadingService = new LoadingService()

	public loadingVisible: boolean = false
	public mensajeLoading: string = "Guardando..."
	@Input()
	public multilenguaje: boolean = false
	private _listado: ResumenModel[] = []
	public get listado(): ResumenModel[] {
		return this._listado
	}
	@Input()
	public set listado(v: ResumenModel[]) {
		this._listado = v
	}
	@Input()
	updateRowImplementation = (data: ResumenModel, custoLoadingService?: LoadingService) => {
		this.service.guardar(data, custoLoadingService)
	}

	public updateRow(data) {
		data["codigoIdioma"] = this.idiomaSeleccionado ? this.idiomaSeleccionado.codigo : "ES"
		this.updateRowImplementation(data, this.customLoadingService)
	}

	@Input()
	public readonly: boolean = false
	@Input()
	public totalRecords: number = 0
	@Input()
	public allowLayoutSwitch: boolean = true

	@Input()
	public emptymessage: string = "Sin registros"
	@Output()
	public totalRecordsChange: EventEmitter<number> = new EventEmitter<number>()

	@Input()
	public editModal: boolean = false

	@Output()
	public listadoChange: EventEmitter<ResumenModel[]> = new EventEmitter<ResumenModel[]>()
	@Input()
	public service: ServicioAbstract<any>

	public filtroCargado: boolean = false
	private _filtro: Filtro
	public get filtro(): Filtro {
		return this._filtro
	}
	@Input()
	public set filtro(v: Filtro) {
		this._filtro = v
		if (this._filtro?.apply) {
			this.extraFilter = (r) => this._filtro.apply(r)
		}
		if (this.subFiltro) {
			this.subFiltro.unsubscribe()
		}
		this.registerFilter()
		this.columnsVisibles = [...this.columns.filter((c) => !c.downloadOnly && this.filtro?.tieneColumna(c.field))]
	}

	private _camposMobile: any[]
	public get camposMobile(): any[] {
		return this._camposMobile
	}
	public set camposMobile(v: any[]) {
		this._camposMobile = v
	}
	public columnsVisibles: any[] = []
	public columnsVisiblesOriginal: any[] = []
	public columnsDisplay: any[] = []
	private _columns: any[]
	public get columns(): any[] {
		return this._columns
	}
	@Input()
	public set columns(v: any[]) {
		this._columns = v
		if (this.filtro && this.filtro?.columnasVisibles?.length <= 1)
			this.filtro.columnasVisibles = v.filter((c) => !c.downloadOnly && !c.optional).map((c) => c.field)
		this.camposMobile = this.columns.filter((c) => c.esMobile)
		this.columnsVisibles = [...this.columns.filter((c) => !c.downloadOnly && this.filtro?.tieneColumna(c.field))]
	}

	private _download: boolean = true
	public get download(): boolean {
		return this._download
	}
	@Input()
	public set download(v: boolean) {
		this._download = v
	}
	@Input()
	public skipedFieldsDownload: any = DEFAULT_SKIP
	@Input()
	public txtEliminar: string

	@Input()
	public dataViewGridStyle: string = "col-12 lg:col-4 md:col-6"
	@Input()
	public txtOkEliminar: string = this.translateService.get("EL_ITEM_FUE_ELIMINA_15")
	@Input()
	public extraFilter: (r: any[]) => any[] = (r) => {
		return r
	}

	@ViewChild("table", { static: false }) table
	private defaultTxtEliminar: string

	private defaultTxtOkEliminar: string
	@Input()
	public modelName: string = ""

	@Input()
	public title: string = ""

	private defaultGetData = (f: Filtro, l: LoadingService) => {
		return f && this.service?.getAll ? this.service.getAll(f, this.customLoadingService) : Promise.resolve([])
	}

	private defaultCount = (f: Filtro, l: LoadingService) => {
		return f && this.service?.count ? this.service.count(f, this.customLoadingService) : Promise.resolve(!this.isLazy ? this.listado.length : 0)
	}

	@Input()
	public getData: (f: Filtro, l: LoadingService) => Promise<any[]> = this.defaultGetData

	public onHeaderCheckboxToggle(event) {
		this.seleccionChange.emit(this.seleccion)
	}
	@Input()
	public getCount: (f: Filtro, l?: LoadingService) => Promise<number> = this.defaultCount

	private _idiomaSeleccionado: Idioma
	public get idiomaSeleccionado(): Idioma {
		return this._idiomaSeleccionado
	}
	public set idiomaSeleccionado(v: Idioma) {
		this._idiomaSeleccionado = v ? v : this.idiomaService.default
		this.filtro.idioma = v?.codigo || "ES"
	}

	public get finalTitle() {
		return this.title ? this.title : this.modelName ? this.modelName.toUpperCase() : this.translateService.get("SIN_TITULO")
	}
	@Input()
	public acceptedTypes: string = ".csv"

	route: string
	constructor(
		public activeRoute: ActivatedRoute,
		public confirmationService: ConfirmationService,
		public idiomaService: IdiomaService,
		public messagesService: MessagesService,
		public filterService: FilterService,
		public excelDownloader: ExcelDownloader,
		protected router: Router,
		public helpService: HelpService,
		private cargaMasivaService: CargaMasivaService,
		private novedadService: NovedadService,
		protected _location: Location,
		public configUsuario: ConfiguracionUsuarioService
	) {
		super(messagesService)
		if (!this.menuAcciones) this.menuAcciones = this.menuAccionesDefault
	}

	registerFilter() {
		if (!this.filtro) return
		if (this.filtro.columnasVisibles.length == 0)
			this.filtro.columnasVisibles = this.columns.filter((c) => !c.downloadOnly && !c.optional).map((c) => c.field)
		this.subFiltro = this.filtro.dataChange
			.pipe(
				debounceTime(600),
				filter((v) => v != undefined)
			)
			.subscribe((r: Filtro) => {
				this.currentQuery = this.filtro.toEncodedJson()
				this.columnsVisibles = [...this.columns.filter((c) => !c.downloadOnly && this.filtro?.tieneColumna(c.field))]
				if (this.currentQuery) {
					const l = this.getLocationName()
					this._location.replaceState(l)
				}

				if (r?.idioma && (!this.idiomaSeleccionado || this.idiomaSeleccionado?.codigo != r?.idioma)) {
					this.idiomaService.getByCodigo(r.idioma).then((r) => {
						this.idiomaSeleccionado = r
					})
				}
				this.getItems(r).then((v) => {
					this.updateTotalRecords()
				})
				this.filterService.setFilter(this.filtro ? this.filtro.name : this.modelName, r)
			})
		this.subColumnas = this.filtro.columnasChange.subscribe((r) => {
			this.columnsVisibles = [...this.columns.filter((c) => !c.downloadOnly && this.filtro?.tieneColumna(c.field))]
		})
		this.subs.push(this.subFiltro, this.subColumnas)
		this.filtro.forceUpdate()
	}
	public get columnasLen() {
		let colSpan = this.columns?.length || 0
		if (this.modoSeleccion == "multiple") colSpan++
		if (!this.readonly && this.acciones?.length >= 1) colSpan++
		return colSpan
	}
	public getTextColor(color: String) {
		return ColorUtils.contraste(color)
	}
	public getPageSize(filtro?: Filtro): number {
		return this.isLazy ? filtro.size : 10
	}
	ngOnDestroy() {
		super.ngOnDestroy()
		this.filtro && this.filtro.unsuscribe()
	}
	public getFirstPage = () => {
		return this.filtro?.page && this.filtro.size ? this.filtro.page * this.filtro.size : 0
	}
	ngOnInit() {
		this.route = this.activeRoute.routeConfig.path

		this.generarColumnsDisplay()

		this.updateTexts()
		if (!this.acciones) {
			this.acciones = this.defaultAcciones
		} else if (this.addDefaultAcciones) {
			this.acciones = [...this.acciones, ...this.defaultAcciones]
		}
		// if (this.acciones?.length) this.addAccionAudit()
		if (this.filterService.hasFilter(this.filtro?.name || this.modelName)) {
			const f = this.filterService.getFilter(this.filtro ? this.filtro.name : this.modelName)
			if (f) {
				this.filtro.patchValue(f.json, true)
			}
		}

		this.subs.push(
			this.customLoadingService.loading.subscribe((l) => {
				this.loading = l
			})
		)
		this.activeRoute.queryParams.subscribe((params) => {
			// if (params["search"] && this.filtro && this.filtro.searchStr !== params["search"]) {
			// 	this.filtro.searchStr = params["search"]
			// }

			this.filtro.fromQueryParams(params)
		})

		this.customLoadingService.loading.subscribe((r) => {
			if (!r) {
				this.mensajeLoading = "Guardado"
				setTimeout(() => {
					this.loadingVisible = false
				}, 1000)
			} else {
				this.mensajeLoading = "Guardando..."
				setTimeout(() => {
					this.loadingVisible = true
				})
			}
		})
		if (this.notificarNovedades) {
			const s = this.novedadService.registrarObservador(this.modelName, {
				next: (n) => {
					if (this.filtro && !this.usuario?.esExterno) this.filtro.updateAvailable = true
				},
				destroy: () => {
					this.novedadService.unregister(this.modelName)
				},
				getKey: () => this.modelName
			})
		}
		this.filtroCargado = true
	}

	public mostrarMenu(event, item) {
		this.items = []
		item["acciones"].forEach((a) => {
			this.items.push({
				label: this.translateService.get(a.tooltip),
				icon: a.icon,
				visible: a.esVisible(item),
				command: function (ev) {
					let f = a.command
					return f(item, event)
				}
			})
		})
		this.menu.toggle(event)
	}

	loadData = (event, f) => {
		let page = event.first === 0 || event.rows === 0 ? 0 : Math.floor(event.first / event.rows)
		if (f.ready) f.setPage(page, event.rows, event.sortField ? event.sortField : f.sortField, event.sortOrder ? event.sortOrder : f.sortOrder, this.isLazy)
		//this.getItems(f);

		this.seleccion = []
	}
	public updateCount(event) {
		this.filtro.count = event.filteredValue ? event.filteredValue.length : 0
	}

	public updateTotalRecords() {
		if (this.isLazy) {
			this.getCount(this.filtro).then((r) => {
				this.totalRecords = r
			})
		} else {
			this.totalRecords = this.filtro.count
		}
	}
	public executeAccion(v, item) {
		const func = v
		return func(item)
	}
	public updateAcciones(item) {
		return this.acciones.filter((a) => a.esVisible(item))
	}
	getItems = async (filtro) => {
		if (!this.service || !filtro) return this._listado
		this.mensajeLoading = "Buscando..."
		const $this = this
		const f = this.getData ? this.getData : this.defaultGetData
		filtro["isLazy"] = this.isLazy
		return f(filtro, this.customLoadingService).then((r) => {
			$this.listado = $this.extraFilter(r)
			$this.listado.forEach((data) => (data["acciones"] = this.updateAcciones(data)))
			$this.listadoChange.emit($this.listado)
			if (!$this.isLazy) {
				$this.totalRecords = $this.listado.length
			}
			$this.totalRecordsChange.emit($this.totalRecords)
			$this.mensajeLoading = ""
			$this.listado = [...$this.listado]
		})
	}

	public nuevo() {
		if (this.handler && this.handler?.nuevo) {
			this.handler.nuevo()
		} else {
			this.router.navigate([this.modelName + "/nuevo"])
		}
	}
	public eliminar(id: number, event?, item?) {
		event.stopPropagation()
		if (this.handler && this.handler?.eliminar) {
			this.handler.eliminar(id, item)
		} else {
			this.confirmationService.confirm({
				key: "genConf",
				header: "Eliminar",
				message: this.qEliminar,
				accept: () => {
					let $this = this
					$this.service.eliminar(id).then((res) => {
						$this.getItems(this.filtro)
						$this.success(this.okEliminar)
					})
				}
			})
		}
	}

	public editar(id: number, item?) {
		if (this.handler && this.handler?.editar) {
			this.handler.editar(id, item)
		} else {
			let query: NavigationExtras = {
				queryParams: {
					id: id
				}
			}
			this.router.navigate([this.modelName + "/edit"], query)
		}
	}

	public ver(id: number, item?) {
		if (this.handler && this.handler.editar) {
			this.handler.editar(id, item)
		} else if (this.hasDetail) {
			let query: NavigationExtras = {
				queryParams: {
					id: id
				}
			}
			this.router.navigate([this.modelName + "/vista"], query)
		}
	}

	private get qEliminar() {
		return this.txtEliminar ? this.txtEliminar : this.defaultTxtEliminar
	}
	private get okEliminar() {
		return this.txtOkEliminar ? this.txtOkEliminar : this.defaultTxtOkEliminar
	}
	public updateTexts() {
		this.defaultTxtEliminar = this.translateService.get("DESEA_ELIMINAR_EL__18")
		this.defaultTxtOkEliminar = this.translateService.get("EL_ITEM_FUE_ELIMINA_15")
		if (!this.emptymessage) this.emptymessage = this.translateService.get("SIN_REGISTROS")
	}

	public rowSelectUnselect(event) {
		this.seleccionChange.emit(this.seleccion)
	}
	public eliminarMasivo() {
		this.success("En desarrollo")
	}
	public showAcciones(event, menu) {
		this.menuItemAcciones = []
		const vals = this.seleccion?.length == 1 ? this.seleccion[0] : this.seleccion
		this.acciones.forEach((a) => {
			let visible = false
			try {
				visible = a.esVisible(vals) || false
			} catch (error) {
				visible = false
			}
			this.menuItemAcciones.push({
				label: this.translateService.get(a.tooltip),
				icon: a.icon,
				visible: visible,
				command: (ev) => {
					let f = a.command
					return f(vals, event)
				}
			})
		})
		if (this.menuItemAcciones.length == 0) {
			this.menuItemAcciones.push({
				label: this.translateService.get("NO_HAY_ACCIONES_DISPONIBLES")
			})
		}
		menu.toggle(event)
	}
	public get todosSeleccionados() {
		return this.seleccion.length == this.listado.length && this.seleccion.length > 0
	}
	public seleccionarTodos() {
		if (this.modoSeleccion == "multiple") {
			if (this.todosSeleccionados) {
				this.seleccion = []
				this.listado.forEach((s) => (s["selected"] = false))
			} else {
				this.seleccion = [...this.listado]
				this.seleccion.forEach((s) => (s["selected"] = true))
			}
		}
	}

	public toggleSelection(item, event) {
		if (this.modoSeleccion != "multiple") return
		event.stopPropagation()
		if (this.seleccion.some((i) => i?.id == item?.id)) {
			this.seleccion = this.seleccion.filter((i) => i?.id != item?.id)
			item["selected"] = false
		} else {
			this.seleccion.push(item)
			item["selected"] = true
		}
		this.rowSelectUnselect(event)
	}
	public showFilterbar(event) {
		event?.stopPropagation && event.stopPropagation()
		this.displayFilterBar = true
	}

	public showSavedFilterBar(event) {
		event?.stopPropagation && event.stopPropagation()
		this.displaySavedFilterBar = true
	}

	auditar(data) {
		this.mostrarAuditar = true
		if (data.lastModifiedBy && data.lastModifiedDate) {
			this.selectedItem = {
				lastModifiedBy: data.lastModifiedBy,
				lastModifiedDate: data.lastModifiedDate ? moment(data.lastModifiedDate).format("DD/MM/YYYY HH:mm:ss") : "",
				createdBy: data.createdBy,
				createdDate: data.createdDate ? moment(data.createdDate).format("DD/MM/YYYY HH:mm:ss") : ""
			}
		}
	}

	esAuditable = (data: any) => {
		return data?.id && data["lastModifiedBy"]
	}

	addAccionAudit() {
		this.acciones.push(
			new AccionesTabla(
				"AUDITAR",
				"fa fa-search",
				"",
				(da, event) => {
					this.auditar(da)
					event.stopPropagation()
				},
				(da) => {
					return !this.usuario?.esExterno && this.esAuditable(da)
				}
			)
		)
	}
	getFullData() {
		let f = this.filtro.clonar()
		f.size = 100000
		f.page = 0
		return this.getData(f, this.customLoadingService)
	}
	async exportExcel() {
		const data = await this.getFullData()
		this.excelDownloader.downloadData(data, this.modelName || "datosExportados", this.skipedFieldsDownload, this.columns)
	}
	switchHandler(event, col, data) {
		event?.originalEvent?.stopPropagation && event.originalEvent.stopPropagation()
		col?.switchChange && col.switchChange(event, data)
	}

	handleFileInput(files: Event) {
		if (files?.target["files"]) {
			const f = files?.target["files"][0]
			this.cargaMasivaService.cargaMasiva(this.modelName, f).then((result) => {
				if (result) {
					this.success(`${this.translateService.get("CARGADO_CORRECTAMENTE")} ${result?.id}`)
					this.goTo("carga-masiva", { queryParams: { search: result?.id } })
				}
			})
		}
	}
	public saveColumns(event) {
		console.log(event)
	}
	public getLocationName() {
		const fullPath = this._location.path()
		const url = fullPath.split("?")[0]
		const queryParams = fullPath.split("?")[1]?.split("&") ?? []

		const queryIndex = queryParams.findIndex((q) => q.startsWith("query="))

		if (queryIndex != -1) {
			queryParams[queryIndex] = `query=${this.currentQuery}`
		} else {
			queryParams.push(`query=${this.currentQuery}`)
		}

		return `${url}?${queryParams.join("&")}`
	}

	onColToggle() {
		this.configUsuario.cols[this.route] = this.columnsVisibles.map((c) => {
			return {
				field: c.field,
				display: c.display
			}
		})
		this.configUsuario.save()
		this.generarColumnsDisplay()
	}
	public goToHelp(modelName: string): void {
		if (this.helpPath) {
			this.helpService.goSpecificHelp(this.helpPath)
		}
	}
	public hasHelp = (modelName: string): boolean => {
		return this.helpPath != undefined || this.helpService.hasHelp(modelName)
	}
	generarColumnsDisplay() {
		this.columnsVisibles = []
		const cols = this.configUsuario.cols[this.route]

		this.columns.forEach((column) => {
			if (!column.downloadOnly) {
				const col = cols?.find((co) => co.field == column.field)

				if (col) {
					this.columnsVisibles.push({ ...column, display: col.display })
				} else {
					this.columnsVisibles.push({ ...column, display: true })
				}
			}
		})

		this.configUsuario.cols[this.route] = this.columnsVisibles.map((c) => {
			return {
				field: c.field,
				display: c.display
			}
		})

		this.configUsuario.save()
	}
}
