import { FiltroAcciones } from './../common/model/FiltroAcciones';
import { Evento } from 'src/app/model/Evento';
import { lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { AccionAutomatica } from 'src/app/model/AccionAutomatica';
import { LoadingService } from './../common/services/loading-data-service.service';
import { FiltroFecha } from '../common/model/FiltroFecha';
@Injectable({
  providedIn: 'root',
})
export class AccionAutomaticaService extends ServicioAbstract<AccionAutomatica>{
    getEventos(filtroFecha: FiltroAcciones) :Promise<Evento[]> {
        return this.getAll(filtroFecha).then((acciones: AccionAutomatica[])=>{
            return AccionAutomatica.getFactory(filtroFecha.tipoAccion)(acciones);
        });
    }
   
    public baseName(): string {
        return "accion-automatica";
    }
    public parseToEnt(data: any): AccionAutomatica {
        return AccionAutomatica.fromData(data);
    }
    public newEnt(): AccionAutomatica {
        return new AccionAutomatica();
    }

    eliminar(id: number, customLoading?: LoadingService) {

        if (customLoading) { customLoading.addLoadingCount(); }
        else this.loadingService.addLoadingCount()
        return lastValueFrom( this.http.delete(this.getBaseURL() + id)) 
            .then((r) => {
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
                return r
            });
    }


}