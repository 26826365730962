import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-help-lista-precios',
    templateUrl: './help-lista-precios.component.html',
    styleUrls: ['./help-lista-precios.component.less'],
})
export class HelpListaPreciosComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
