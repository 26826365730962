<div class="gestor-producto-publicado content content-white">
	<model-gestor [service]="service" [(item)]="item" [validar]="isValid" [modelName]="'PRODUCTO_PUBLICADO' | translate"
		[verAcciones]="true" [isModal]="isModal" [goBack]="goBack" (onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable" (onCancelar)="handleCancelar($event)" [form]="ProductoPublicadoForm"
		[multilenguaje]="true" (onEditar)="onEdit($event)" [hideButtons]="true" #modelGestor>
		<div class="gestor-form" model-type="dataContent">
			<form #ProductoPublicadoForm="ngForm" id="ProductoPublicadoform">
				<div *ngIf="item">
					<p-tabView>
						<p-tabPanel header="{{ 'GENERAL' | translate }}">
							<div class="grid">
								<div class="col-12">
									<div class="grid">
										<div class="col-12 lg:col-4 pic-wrapper">
											<label style="padding: 10px; font-size: 14px">{{ "PORTADA" | translate
												}}</label>
											<div class="col-12 imagen">
												<img [src]="item.imagenPortada" />
											</div>
										</div>
										<div class="col-12 lg:col-8">
											<label style="padding: 10px; font-size: 14px">{{ "IMAGENES_ADICIONALES" |
												translate }}</label>
											<p-carousel [value]="imagenes" [numVisible]="4" [numScroll]="3"
												[circular]="false">
												<ng-template let-imagen pTemplate="item">
													<div class="imagen-carousel-detalle"
														(click)="!readonly ? setPortada(imagen) : return"
														*ngIf="imagen.profilePic">
														<div class="grid grid-nogutter"
															[ngClass]="{ 'es-perfil': imagen.esPortada }">
															<button class="delete" pButton icon="pi pi-trash"
																(click)="eliminarImagen($event, imagen)"
																*ngIf="!imagen.esNuevo && !readonly"></button>
															<div class="col-12 imagen" [tooltipPosition]="'bottom'"
																[pTooltip]="'SELECCIONAR_PORTADA' | translate">
																<img [src]="imagen?.imagenUrl" />
															</div>
														</div>
													</div>
													<div *ngIf="!imagen.profilePic">
														<img src="assets/images/plus.png" (click)="imagen.clic()"
															style="cursor: pointer" />
													</div>
												</ng-template>
											</p-carousel>
											<profile-badge #fileInput [seleccionable]="true"
												[url]="'assets/images/plus.png'" class="hidden" name="profile"
												[disabled]="readonly" type="portrait"
												(onFileSelected)="uploadEnd($event)">
											</profile-badge>
										</div>
									</div>
								</div>
								<div class="col-12 lg:col-7 p-fluid field">
									<span class="p-float-label">
										<input pInputText placeholder="{{ 'NOMBRESERVICIO' | translate }}"
											[(ngModel)]="item.nombreServicio" type="text" #nombreServicio="ngModel"
											name="nombreServicio" [readonly]="readonly" />
										<label>{{ "NOMBRESERVICIO" | translate }}</label>
										<error-tag [model]="nombreServicio"></error-tag>
									</span>
								</div>
								<div class="col-12 lg:col-3 p-fluid field">
									<parametrico-selector [tipoParametro]="'region'" #region="ngModel"
										[(ngModel)]="item.region" [limpiable]="true" [permiteNuevo]="true"
										[readonly]="readonly" name="region" [titulo]="'REGION' | translate">
									</parametrico-selector>
								</div>
								<div class="col-12 lg:col-2 checkbox-container">
									<p-toggleButton onLabel="{{ 'HABILITADO' | translate }}"
										offLabel="{{ 'HABILITADO' | translate }}" iconPos="left" name="habilitado"
										onIcon="{{ 'fa fa-check-square-o' }} " offIcon="{{ 'fa fa-square-o' }} "
										[disabled]="readonly" [(ngModel)]="item.habilitado"></p-toggleButton>
								</div>
								<div class="col-12 lg:col-6 p-fluid field">
									<selector-multiple-chips [(ngModel)]="item.tags" name="tags" #tags="ngModel"
										[servicio]="tagService" [permiteNuevo]="true" [titulo]="'TAG' | translate"
										[readonly]="readonly" [gestor]="gestorTags">
										<ng-template #gestorTags let-handler="handler" let-itemEditado="itemEditado">
											<gestor-tag-producto [item]="itemEditado"
												(onGuardado)="handler.onGuardado($event)"
												(onCancelado)="handler.onCancelado($event)" [isModal]="true"
												[goBack]="false">
											</gestor-tag-producto>
										</ng-template>
									</selector-multiple-chips>
								</div>
								<div class="col-12 lg:col-2 p-fluid field">
									<span class="p-float-label">
										<input pInputText placeholder="{{ 'DURACION_HS' | translate }}"
											[(ngModel)]="item.duracion" type="number" #duracion="ngModel"
											name="duracion" [readonly]="readonly" />
										<label>{{ "DURACION" | translate }}</label>
										<error-tag [model]="duracion"></error-tag>
									</span>
								</div>
								<div class="col-12 lg:col-2 p-fluid field">
									<span class="p-float-label">
										<input pInputText placeholder="{{ 'MINIMO_DIAS' | translate }}"
											[(ngModel)]="item.minimoDias" type="number" #minimoDias="ngModel"
											name="minimoDias" [readonly]="readonly" [tooltipPosition]="'bottom'"
											[pTooltip]="'MINIMO_DIAS_TOOLTIP' | translate" />
										<label>{{ "MINIMO_DIAS" | translate }}</label>
										<error-tag [model]="minimoDias"></error-tag>
									</span>
								</div>
								<div class="col-12 lg:col-2 p-fluid field">
									<span class="p-float-label">
										<input pInputText placeholder="{{ 'MAXIMO_DIAS' | translate }}"
											[(ngModel)]="item.maximoDias" type="number" #maximoDias="ngModel"
											name="maximoDias" [readonly]="readonly" [tooltipPosition]="'bottom'"
											[pTooltip]="'MAXIMO_DIAS_TOOLTIP' | translate" />
										<label>{{ "MAXIMO_DIAS" | translate }}</label>
										<error-tag [model]="maximoDias"></error-tag>
									</span>
								</div>
								<div class="col-12 lg:col-12 grid-nogutter">
									<label>{{ "DESCRIPCIONCORTA" | translate }}</label>
									<p-editor [(ngModel)]="item.descripcionCorta" name="descripcionCorta" tabindex="10"
										[readonly]="readonly"
										[style]="{ height: '100%', 'max-height': '40vh', 'min-height': '5vh', overflow: 'auto', 'background-color': 'white' }">
									</p-editor>

									<p-header *ngIf="readonly">
										<span></span>
									</p-header>
									<small>
										{{ getErrorMessage(descripcionCorta) }}
									</small>
								</div>
								<div class="col-12 lg:col-12 grid-nogutter">
									<label>{{ "DETALLE" | translate }}</label>
									<p-editor [(ngModel)]="item.detalle" name="detalle" tabindex="10"
										[readonly]="readonly"
										[style]="{ height: '100%', 'max-height': '40vh', 'min-height': '20vh', overflow: 'auto', 'background-color': 'white' }">
									</p-editor>

									<p-header *ngIf="readonly">
										<span></span>
									</p-header>
									<small>
										{{ getErrorMessage(detalle) }}
									</small>
								</div>
								<div class="col-12 lg:col-12 grid-nogutter">
									<label>{{ "PREPARATIVOS" | translate }}</label>
									<p-editor [(ngModel)]="item.preparativos" name="preparativos" tabindex="10"
										[readonly]="readonly"
										[style]="{ height: '100%', 'max-height': '40vh', 'min-height': '10vh', overflow: 'auto', 'background-color': 'white' }">
									</p-editor>

									<p-header *ngIf="readonly">
										<span></span>
									</p-header>
									<small>
										{{ getErrorMessage(preparativos) }}
									</small>
								</div>
								<div class="col-12 lg:col-12 grid-nogutter">
									<label>{{ "CONDICIONES_ESPECIALES" | translate }}</label>
									<p-editor [(ngModel)]="item.condicionesEspeciales" name="condicionesEspeciales"
										tabindex="10" [readonly]="readonly"
										[style]="{ height: '100%', 'max-height': '40vh', 'min-height': '10vh', overflow: 'auto', 'background-color': 'white' }">
									</p-editor>

									<p-header *ngIf="readonly">
										<span></span>
									</p-header>
									<small>
										{{ getErrorMessage(condicionesEspeciales) }}
									</small>
								</div>
								<div class="col-12 lg:col-12 grid-nogutter">
									<label>{{ "POLITICAS_CANCELACION" | translate }}</label>
									<p-editor [(ngModel)]="item.politicasCancelacion" name="politicasCancelacion"
										tabindex="10" [readonly]="readonly"
										[style]="{ height: '100%', 'max-height': '40vh', 'min-height': '10vh', overflow: 'auto', 'background-color': 'white' }">
									</p-editor>

									<p-header *ngIf="readonly">
										<span></span>
									</p-header>
									<small>
										{{ getErrorMessage(politicasCancelacion) }}
									</small>
								</div>
								<div class="col-12 lg:col-6 p-fluid field">
									<selector-multiple-chips [(ngModel)]="item.incluidos" name="incluidos"
										#incluidos="ngModel" [servicio]="inclusionService" [permiteNuevo]="true"
										[titulo]="'Incluidos' | translate" [readonly]="readonly"
										[gestor]="gestorIncluidos">
										<ng-template #gestorIncluidos let-handler="handler"
											let-itemEditado="itemEditado">
											<gestor-inclusion [item]="itemEditado"
												(onGuardado)="handler.onGuardado($event)"
												(onCancelado)="handler.onCancelado($event)" [isModal]="true"
												[goBack]="false">
											</gestor-inclusion>
										</ng-template>
									</selector-multiple-chips>
								</div>
								<div class="col-12 lg:col-6 p-fluid field">
									<selector-multiple-chips [(ngModel)]="item.noIncluidos" name="noIncluidos"
										#noIncluidos="ngModel" [servicio]="inclusionService" [permiteNuevo]="true"
										[titulo]="'No incluidos' | translate" [readonly]="readonly"
										[gestor]="gestorNoIncluidos">
										<ng-template #gestorNoIncluidos let-handler="handler"
											let-itemEditado="itemEditado">
											<gestor-inclusion [item]="itemEditado"
												(onGuardado)="handler.onGuardado($event)"
												(onCancelado)="handler.onCancelado($event)" [isModal]="true"
												[goBack]="false">
											</gestor-inclusion>
										</ng-template>
									</selector-multiple-chips>
								</div>
								<div class="col-12 disponibilidad-contenedor">
									<label>{{ "DISPONIBILIDAD" | translate }}</label>
									<disponibilidad [disponibilidad]="item.disponibilidad" [readonly]="readonly">
									</disponibilidad>
								</div>
							</div>
						</p-tabPanel>
						<p-tabPanel header="{{ 'OPCIONES' | translate }}">
							<div class="grid">
								<div class="col-12">
									<button pButton (click)="agregarOpcion()" class="mb-3" *ngIf="!readonly">Agregar
										Opción</button>
									<p-accordion [multiple]="true">
										<p-accordionTab *ngFor="let op of item.opciones; index as io"
											[selected]="op.selected">
											<ng-template pTemplate="header">
												<span style="font-weight: bold;">{{ op.descripcion }}</span>
												<button pButton (click)="quitarOpcion(op)" icon="fa fa-trash"
													class="p-button-danger p-button-outlined"
													style="position: absolute; right: 60px" *ngIf="!readonly"></button>
											</ng-template>
											<ng-template pTemplate="content">
												<div class="grid">
													<div class="col-12 p-fluid field">
														<span class="p-float-label">
															<input pInputText
																placeholder="{{ 'NOMBRE_OPCION' | translate }}"
																[(ngModel)]="op.descripcion" type="text"
																#descripcion="ngModel"
																name="{{ 'descripcion_op_' + io }}"
																[readonly]="readonly" />
															<label>{{ "NOMBRE_OPCION" | translate }}</label>
														</span>
													</div>
													<div class="col-12 p-fluid field">
														<label>{{ "DESCRIPCIONCORTA" | translate }}</label>
														<p-editor [(ngModel)]="op.descripcionCorta"
															name="{{ 'descripcionCorta_op_' + io }}" tabindex="10"
															[readonly]="readonly" [style]="{
																height: '100%',
																'max-height': '40vh',
																'min-height': '5vh',
																overflow: 'auto',
																'background-color': 'white'
															}">
														</p-editor>
														<p-header *ngIf="readonly">
															<span></span>
														</p-header>
														<small>
															{{ getErrorMessage(op.descripcionCorta) }}
														</small>
													</div>
													<div class="col-12 disponibilidad-contenedor">
														<label>{{ "DISPONIBILIDAD" | translate }}</label>
														<div class="col-12 field" style="text-align: left">
															<label class="ml-2 mr-2">{{
																"HEREDAR_DISPONIBILIDAD" | translate
																}}</label>
															<p-inputSwitch [readonly]="readonly"
																name="{{ 'isHeredar_op_' + io }}"
																[(ngModel)]="op.heredaDisponibilidad"
																(click)="readonly ? return : heredarDisponibilidad(op)"></p-inputSwitch>
														</div>
														<disponibilidad [disponibilidad]="op.disponibilidad"
															*ngIf="op.disponibilidad" [readonly]="readonly">
														</disponibilidad>
													</div>
													<div class="col-12 disponibilidad-contenedor">
														<label>{{ "INCLUIDOS" | translate }}/{{ "NO_INCLUIDOS" |
															translate }}</label>
														<div class="col-12 field" style="text-align: left">
															<label class="ml-2 mr-2">{{
																"HEREDAR_INCLUSIONES" | translate
																}}</label>
															<p-inputSwitch [readonly]="readonly"
																name="{{ 'isHeredarInc_op_' + io }}"
																[(ngModel)]="op.heredaInclusiones"
																(click)="readonly ? return : heradarInclusiones(op)"></p-inputSwitch>
														</div>
													</div>
													<div class="col-12 lg:col-6 p-fluid field"
														*ngIf="op.incluidos.length || op.noIncluidos.length">
														<selector-multiple-chips [(ngModel)]="op.incluidos"
															name="{{ 'incluidos_op_' + io }}" #incluidos="ngModel"
															[servicio]="inclusionService" [permiteNuevo]="true"
															[titulo]="'INCLUIDOS' | translate" [readonly]="readonly"
															[gestor]="gestorIncluidos">
															<ng-template #gestorIncluidos let-handler="handler"
																let-itemEditado="itemEditado">
																<gestor-inclusion [item]="itemEditado"
																	(onGuardado)="handler.onGuardado($event)"
																	(onCancelado)="handler.onCancelado($event)"
																	[isModal]="true" [goBack]="false">
																</gestor-inclusion>
															</ng-template>
														</selector-multiple-chips>
													</div>
													<div class="col-12 lg:col-6 p-fluid field"
														*ngIf="op.incluidos.length || op.noIncluidos.length">
														<selector-multiple-chips [(ngModel)]="op.noIncluidos"
															name="{{ 'noIncluidos_op_' + io }}" #noIncluidos="ngModel"
															[servicio]="inclusionService" [permiteNuevo]="true"
															[titulo]="'NO_INCLUIDOS' | translate" [readonly]="readonly"
															[gestor]="gestorNoIncluidos">
															<ng-template #gestorNoIncluidos let-handler="handler"
																let-itemEditado="itemEditado">
																<gestor-inclusion [item]="itemEditado"
																	(onGuardado)="handler.onGuardado($event)"
																	(onCancelado)="handler.onCancelado($event)"
																	[isModal]="true" [goBack]="false">
																</gestor-inclusion>
															</ng-template>
														</selector-multiple-chips>
													</div>

													<div class="col-12">
														<label style="font-size: 14px">{{ "VARIANTES" | translate
															}}</label>
														<button pButton (click)="agregarVariante(op)"
															[disabled]="op.variantes.length >= maxVariantes"
															icon="pi pi-plus" style="vertical-align: baseline;"
															class="p-button-success p-button-outlined ml-2"
															*ngIf="!readonly"></button>
													</div>

													<div class="col-12 lg:col-4 p-fluid field"
														*ngFor="let vr of op.variantes; index as iv">
														<p-card>
															<ng-template pTemplate="header">
																<button pButton (click)="quitarVariante(op, vr)"
																	class="p-button-danger p-button-outlined"
																	icon="fa fa-trash" *ngIf="!readonly"></button>
															</ng-template>
															<div class="grid">
																<div class="col-12">
																	<descriptivo-material-selector
																		[service]="tipoVarianteService"
																		[label]="'TIPO_VARIANTE' | translate"
																		[limpiable]="false" [(ngModel)]="vr.variante"
																		[permiteNuevo]="false"
																		name="{{ 'tipoVariante_vr_' + io + iv }}"
																		[readonly]="readonly">
																	</descriptivo-material-selector>
																</div>
																<div class="col-12">
																	<descriptivo-material-selector
																		[service]="productoService"
																		[label]="'PRODUCTO' | translate"
																		[limpiable]="false" [(ngModel)]="vr.producto"
																		[permiteNuevo]="false"
																		name="{{ 'producto_vr_' + io + iv }}"
																		[readonly]="readonly">
																	</descriptivo-material-selector>
																</div>
																<div class="col-12">
																	<span class="p-float-label">
																		<input pInputText
																			placeholder="{{ 'ACLARACION' | translate }}"
																			[(ngModel)]="vr.aclaracion" type="text"
																			#aclaracion="ngModel"
																			name="{{ 'aclaracion_vr_' + io + iv }}"
																			[readonly]="readonly" />
																		<label>{{ "ACLARACION" | translate }}</label>
																		<error-tag [model]="aclaracion"></error-tag>
																	</span>
																</div>
																<div class="col-12 checkbox-container">
																	<p-toggleButton
																		onLabel="{{ 'OCUPA_LUGAR' | translate }}"
																		offLabel="{{ 'OCUPA_LUGAR' | translate }}"
																		iconPos="left"
																		name="{{ 'ocupaLugar_vr_' + io + iv }}"
																		onIcon="{{ 'fa fa-check-square-o' }} "
																		offIcon="{{ 'fa fa-square-o' }} "
																		[disabled]="readonly"
																		[(ngModel)]="vr.ocupaLugar"></p-toggleButton>
																</div>
															</div>
														</p-card>
													</div>
												</div>
											</ng-template>
										</p-accordionTab>
									</p-accordion>
								</div>
							</div>
						</p-tabPanel>
						<p-tabPanel header="{{ 'INTEGRACIONES' | translate }}">
							<button pButton (click)="agregarIntegracion()" class="mb-3" *ngIf="!readonly">Agregar
								integración</button>
							<div class="grid">

								<div class="col-12 md:col-6" *ngFor="let i of item.integraciones; index as ii">
									<p-card [ngClass]="{ 
										'card-sin-integrar': !i.idiomas?.length, 
										'card-desactualizado': i.idiomas?.length && !estaActualizado(i),
										'card-actualizado': estaActualizado(i)}">
										<ng-template pTemplate="header">
											<div class="grid">
												<div class="col-9" style="text-align: left;">
													<span (click)="i.id ? verDetalleIntegracion(i) : return"
														class="ml-2" [ngClass]="{ clickable: i.id }"
														title="Ver detalle">
														{{ estadoIntegracion(i) }}
														{{ i.id ? (' - ' + ((estaActualizado(i) ?
														'PUBLICACION_ACTUALIZADA' :
														'PUBLICACION_NO_ACTUALIZADA') | translate))
														: '' }}
													</span>
												</div>
												<div class="col-3 pr-3"
													style="text-align: right; padding-right: 0 !important;">
													<button pButton icon="pi pi-trash"
														class="p-button-text p-button-danger p-0"
														(click)="eliminarIntegracion(i)" *ngIf="!i.id"></button>
												</div>
											</div>
										</ng-template>
										<div class="grid">
											<div class="col-12">
												<parametrico-selector [listado]="providersDisponibles()"
													#provider="ngModel" [(ngModel)]="i.provider"
													[readonly]="readonly || i.id" name="{{ 'provider_' + ii }}"
													[titulo]="'PROVEEDOR' | translate">
												</parametrico-selector>
											</div>
											<p-panel header="{{'DISPONIBILIDAD' | translate}}" [toggleable]="true"
												[collapsed]="true" styleClass="no-shadow"
												expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
												<p-panel header="{{op.descripcion}}"
													*ngFor="let op of item.opciones; index as iop"
													styleClass="no-shadow ">
													<div class="col-12 disponibilidad-contenedor">
														<div class="col-12 field" style="text-align: left">
															<label class="ml-2 mr-2">{{
																"HEREDAR_DISPONIBILIDAD" | translate
																}}</label>
															<p-inputSwitch [readonly]="readonly"
																name="{{ 'isHeredar_' + ii + '_' + io }}"
																[(ngModel)]="i.heredaDisponibilidad[op.descripcion]"
																(click)="readonly ? return : heredarDisponibilidadIntegracion(i, op)"></p-inputSwitch>
														</div>
														<disponibilidad
															[disponibilidad]="i.disponibilidades[op.descripcion]"
															*ngIf="i.disponibilidades[op.descripcion]"
															[readonly]="readonly" [small]="true">
														</disponibilidad>
													</div>
												</p-panel>
											</p-panel>
											<div class="col-12"></div>
											<p-panel header="{{'LISTAS_PRECIOS' | translate}}" [toggleable]="true"
												[collapsed]="true" styleClass="no-shadow"
												expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
												<ul>
													<li *ngFor="let m of monedas">
														<div class="grid">
															<div class="col-5">
																{{m.descripcion}}:
															</div>
															<div class="col-5" *ngIf="listas">
																{{listaMoneda(m, i.provider) || '-'}}
															</div>
															<div class="col-2" *ngIf="listas">
																<button pButton icon="fa fa-warning"
																	class="p-button-warning p-button-outlined"
																	(click)="verProductoFaltanteEnLista(listaMoneda(m, i.provider))"
																	*ngIf="listaMoneda(m, i.provider) && productosFaltantesEnLista(listaMoneda(m, i.provider))?.length"></button>
															</div>
														</div>
													</li>
												</ul>
											</p-panel>
											<div class="col-12 mt-3">
												<label class="ml-2 mr-2" for="habilitado">{{ "HABILITADO" | translate
													}}</label>
												<p-inputSwitch id="habilitado" name="{{ 'habilitado_' + ii }}"
													#habilitado="ngModel" [(ngModel)]="i.habilitado"
													[readonly]="readonly"></p-inputSwitch>
											</div>
											<div class="col-12" style="text-align: right;">
												<button pButton icon="pi pi-link mr-2" (click)="integrar(i)"
													*ngIf="!readonly && puedeIntegrar(i)">{{
													'INTEGRAR' | translate}}</button>
											</div>
										</div>
									</p-card>
								</div>
							</div>
						</p-tabPanel>
					</p-tabView>
					<div class="col-12 botonera">
						<button pButton label="{{ (readonly ? 'VOLVER' : 'CANCELAR') | translate }}"
							(click)="modelGestor.cancelar()" class="p-button-danger"></button>
						<button class="p-button-success" pButton label="{{ 'GUARDAR' | translate }}"
							(click)="modelGestor.guardar()" *ngIf="!readonly"></button>
						<button class="p-button-success" pButton label="{{ 'GUARDAR_Y_PUBLICAR' | translate }}"
							(click)="isSaving = true; guardarPublicar()" [disabled]="isSaving"
							*ngIf="!readonly"></button>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>

<p-dialog header="{{detalleIntegracion.provider.descripcion}}" [(visible)]="isVerDetalleIntegracion" appendTo="body"
	[modal]="true" [style]="{ width: '60vw' }" *ngIf="detalleIntegracion">
	<p-table [value]="detalleIntegracion.idiomas" *ngIf="detalleIntegracion.idiomas">
		<ng-template pTemplate="header">
			<tr>
				<th style="width: 33%">{{ "IDIOMA" | translate }}</th>
				<th style="width: 33%">{{ "PUBLICACION_ACTUALIZADA" | translate }}</th>
				<th style="width: 33%">{{ "ESTADO" | translate }}</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-idi let-i="rowIndex">
			<tr>
				<td>
					{{ idi?.idioma?.codigo }}
				</td>
				<td>
					{{ idi?.actualizado && estaActualizado(detalleIntegracion)}}
				</td>
				<td>
					{{ idi?.estado }}
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-dialog>

<p-dialog header="Faltantes en lista {{detalleFaltanteLista.descripcion}}" [(visible)]="isVerProductoFaltanteEnLista"
	appendTo="body" [modal]="true" [style]="{ width: '60vw' }" *ngIf="detalleFaltanteLista">
	<p-table [value]="productosFaltantesEnLista(detalleFaltanteLista)">
		<ng-template pTemplate="header">
			<tr>
				<th style="width: 33%">{{ "ID" | translate }}</th>
				<th style="width: 33%">{{ "CODIGO" | translate }}</th>
				<th style="width: 33%">{{ "DESCRIPCION" | translate }}</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-prod let-i="rowIndex">
			<tr>
				<td>
					{{ prod?.id }}
				</td>
				<td>
					{{ prod?.codigo}}
				</td>
				<td>
					{{ prod?.descripcion }}
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-dialog>