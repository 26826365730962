
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { Component, OnDestroy, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { ColumnGenerator } from 'src/app/common/utils/ColumnGenerator';
import { TipoCuotasTarjeta } from 'src/app/model/TipoCuotasTarjeta';
import { TipoCuotasTarjetaService } from 'src/app/services/tipo-cuotas-tarjeta.service';
import { LoadingService } from 'src/app/common/services/loading-data-service.service';

@Component({
	selector: 'listado-tipo-cuotas-tarjeta',
	templateUrl: 'listado-tipo-cuotas-tarjeta.component.html',
	styleUrls: ['listado-tipo-cuotas-tarjeta.component.less']
})

export class ListadoTipoCuotasTarjetaComponent extends SessionComponent implements OnInit, OnDestroy {

	public filtro: Filtro;
	
	@Input()
	public guardarFiltro: boolean = true;	
	@Input()
	public ocultarCabecera: boolean = false;  	
	@Input()
	public editable : boolean = true;
	public listado: TipoCuotasTarjeta[] = [];
	@Input()
  	public readonly: boolean = false;
  	@Input()
  	public modoSeleccion: 'single' | 'multiple' | 'none' = 'none';
  	
  	@Output()
  	public onSelect: EventEmitter<TipoCuotasTarjeta[]> = new EventEmitter();
  	
	public columns = [];
	constructor(messagesService : MessagesService,
				public service: TipoCuotasTarjetaService) {
		super(messagesService)
		console.log(JSON.stringify(this.columns));
	}
	ngOnInit() { 
	this.filtro = new Filtro("tipo-cuotas-tarjeta_lista", {}, 0, 20,'id',1,this.guardarFiltro)
		this.columns = [
  {
    "field": "codigo",
    "header": "Codigo",
    "class": "codigo-tabla",
    "role": "text-tabla",
    "sortable": true,
    "order": 1
  },
  {
    "field": "id",
    "header": "#",
    "class": "id-column",
    "role": "text-tabla",
    "sortable": true,
    "order": 1
  },
  {
    "field": "descripcion",
    "header": "Descripcion",
    "class": "text-tabla",
    "role": "text-tabla",
    "sortable": true,
    "order": 3
  },
  {
    "field": "esDefault",
    "header": "Es Default",
    "class": "is-boolean",
    "role": "boolean-tabla",
    "sortable": false,
    "order": 40
  },
  {
    "field": "icono",
    "header": "Icono",
    "class": "profile-pic",
    "role": "profile-tabla",
    "sortable": false,
    "order": 40
  },
  {
    "field": "habilitado",
    "header": "Habilitado",
    "class": "is-boolean",
    "role": "boolean-tabla",
    "sortable": false,
    "order": 99
  }
];
	}
	
	 public updateSeleccion(seleccion: any) {
	    if (seleccion?.length || seleccion['id']) {
	      this.onSelect.emit(seleccion?.length ? seleccion : [seleccion]);
	    }
	  }
	ngOnDestroy() { }

	
	
}