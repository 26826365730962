<div class="gestor-tipo-comprobante content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'TipoComprobante' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="TipoComprobanteForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #TipoComprobanteForm="ngForm" id="TipoComprobanteform">
				<div class="grid" *ngIf="item">
					<div class="col-fixed w-8rem field">
						<span class="p-float-label">
							<input pInputText [(ngModel)]="item.codigo" type="text" #codigo="ngModel" name="codigo" [readonly]="readonly" />
							<label>{{ "CODIGO" | translate }}</label>
							<error-tag [model]="$CODIGO"></error-tag>
						</span>
					</div>
					<div class="col-fixed w-8rem p-fluid field">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'PESO_PH' | translate }}"
								[(ngModel)]="item.peso"
								pTooltip="{{ 'PESO_TOOLTIP' | translate }}"
								type="number"
								#peso="ngModel"
								name="peso"
								[readonly]="readonly"
							/>
							<label>{{ "PESO" | translate }}</label>
							<error-tag [model]="peso"></error-tag>
						</span>
					</div>
					<div class="col-12 lg:col-4">
						<span class="p-float-label">
							<input pInputText [(ngModel)]="item.descripcion" type="text" #descripcion="ngModel" name="descripcion" [readonly]="readonly" />
							<label>{{ "DESCRIPCION" | translate }}</label>
							<error-tag [model]="$DESCRIPCION"></error-tag>
						</span>
					</div>
					<div class="col-12 lg:col-4">
						<span class="p-float-label">
							<input
								pInputText
								[(ngModel)]="item.notaTipoComprobante"
								type="text"
								#notaTipoComprobante="ngModel"
								name="notaTipoComprobante"
								[readonly]="readonly"
							/>
							<label>{{ "Nota tipo comprobante" | translate }}</label>
							<error-tag [model]="$DESCRIPCION"></error-tag>
						</span>
					</div>
					<div class="col-12 lg:col-2 p-fluid field">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'NUMERACIONACTUAL_PH' | translate }}"
								[ngModel]="item.numeracionActual"
								pTooltip="{{ 'NUMERACIONACTUAL_TOOLTIP' | translate }}"
								type="number"
								#numeracionActual="ngModel"
								name="numeracionActual"
								[readonly]="true"
							/>
							<label>{{ "NUMERACIONACTUAL" | translate }}</label>
							<error-tag [model]="numeracionActual"></error-tag>
						</span>
					</div>
					<div class="col-fixed w-8rem p-fluid field">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'LETRA_PH' | translate }}"
								[(ngModel)]="item.letra"
								pTooltip="{{ 'LETRA_TOOLTIP' | translate }}"
								type="text"
								#letra="ngModel"
								name="letra"
								[readonly]="readonly"
							/>
							<label>{{ "LETRA" | translate }}</label>
							<error-tag [model]="letra"></error-tag>
						</span>
					</div>
					<div class="col-fixed w-8rem p-fluid field" [paises-habilitados]="['AR']">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'CODIGOAFIP_PH' | translate }}"
								[(ngModel)]="item.codigoAfip"
								pTooltip="{{ 'CODIGOAFIP_TOOLTIP' | translate }}"
								type="text"
								#codigoAfip="ngModel"
								name="codigoAfip"
								[readonly]="readonly"
							/>
							<label>{{ "CODIGOAFIP" | translate }}</label>
							<error-tag [model]="codigoAfip"></error-tag>
						</span>
					</div>
					<div class="col-6 lg:col-3 checkbox-container">
						<p-toggleButton
							onLabel="{{ 'ESACUENTA' | translate }}"
							offLabel="{{ 'ESACUENTA' | translate }}"
							iconPos="left"
							name="esACuenta"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[disabled]="readonly"
							[(ngModel)]="item.esACuenta"
						></p-toggleButton>
					</div>

					<div class="col-6 lg:col-3 checkbox-container">
						<p-toggleButton
							onLabel="{{ 'ESNOTA' | translate }}"
							offLabel="{{ 'ESNOTA' | translate }}"
							iconPos="left"
							name="esNota"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[disabled]="readonly"
							[(ngModel)]="item.esNota"
						></p-toggleButton>
					</div>

					<div class="col-6 lg:col-3 checkbox-container">
						<p-toggleButton
							onLabel="{{ 'DISCRIMINAIVA' | translate }}"
							offLabel="{{ 'DISCRIMINAIVA' | translate }}"
							iconPos="left"
							name="discriminaIva"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[disabled]="readonly"
							[(ngModel)]="item.discriminaIva"
						></p-toggleButton>
					</div>

					<div class="col-6 lg:col-3 checkbox-container">
						<p-toggleButton
							onLabel="{{ 'HABILITADO' | translate }}"
							offLabel="{{ 'HABILITADO' | translate }}"
							iconPos="left"
							name="habilitado"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[disabled]="readonly"
							[(ngModel)]="item.habilitado"
						></p-toggleButton>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
