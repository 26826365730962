import { IConCategoria } from './IConCategoria';
import { RestriccionVehiculo } from './RestriccionVehiculo';
import { Vehiculo } from './Vehiculo';



export class VehiculoServicioReserva implements IConCategoria {
    constructor(
        public id?: number,
        public vehiculo?: Vehiculo,
        public requerimiento?: RestriccionVehiculo,
        public esPrincipal: boolean = false

    ) {
    }
    getCategoria(): string {
        return "VEHI";
    }
    public static fromData(data: any): VehiculoServicioReserva {
        if (!data) return null;
        const o: VehiculoServicioReserva = new VehiculoServicioReserva(

            data.id,
            Vehiculo.fromData(data.vehiculo),
            RestriccionVehiculo.fromData(data.requerimiento),
            data.esPrincipal
        );

return o;

    }

}
