import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { Vehiculo } from 'src/app/model/Vehiculo';



export class RestriccionVehiculo {
	constructor(
		public es4x4?: boolean,
		public esPrincipal?: boolean,
		public capacidadMinima: number = 0,
		public id?: number,
		public tipoVehiculo?: Descriptivo,

	) {
	}
	public static fromData(data: any): RestriccionVehiculo {
		if (!data) return null;
		const o: RestriccionVehiculo = new RestriccionVehiculo(
			data.es4x4,
			data.esPrincipal,
			data.capacidadMinima || 0,
			data.id,
			Descriptivo.fromData(data.tipoVehiculo),

		);
		return o;

	}
	public cumple(vehiculo: Vehiculo) {
		return vehiculo ? vehiculo.cumple(this) : false;
	}

}