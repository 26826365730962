
import { Descriptivo } from '../common/model/Descriptivo';
import { Proveedor} from './Proveedor';
import { SerieAusencia} from './SerieAusencia';


export class Ausencia  {
    constructor(
		public id ?: number, 
		public tipo ?: Descriptivo, 
		public fechaDesde ?: Date, 
		public fechaHasta ?: Date, 
		public proveedor ?: Proveedor, 
		public serie ?: SerieAusencia, 
		public idSerie?: number,
		public version?: number,

    ) {
    }
    public static fromData(data: any): Ausencia {
        if (!data) return null;
        const o: Ausencia = new Ausencia(
			data.id,     
			Descriptivo.fromData(data.tipo),  
			data.fechaDesde? new Date(data.fechaDesde) : null , 
			data.fechaHasta? new Date(data.fechaHasta) : null , 
			Proveedor.fromData(data.proveedor), 
			SerieAusencia.fromData(data.serie), 
			data.idSerie,

        );

        o.version = data.version; 
return o;

    }

}