import { CurrencyPipe } from "@angular/common"
import { NgModule } from "@angular/core"
import { CurrencyCustomPipe } from "src/app/pipes/currencyCustom.pipe"
import { TranslatePipe } from "./translate.pipe"

const pipe = []
const directives = [TranslatePipe, CurrencyCustomPipe]
const imports = []
const components = []

@NgModule({
	imports: [...imports],
	providers: [CurrencyPipe],
	declarations: [...pipe, ...directives, ...components],
	exports: [...pipe, ...directives, ...components]
})
export class PipesModule {}
