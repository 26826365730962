import { FamiliaProducto } from "./FamiliaProducto"
import { Producto } from "./Producto"

import { Descriptivo } from "src/app/common/model/Descriptivo"
import { ProfilePic } from "../common/model/ProfilePic"
import { CategoriaProducto } from "./CategoriaProducto"
import { EncuestaActividades } from "./EncuestaActividades"
import { ProductoAsociado } from "./ProductoAsociado"
import { ProductoInsumo } from "./ProductoInsumo"
import { PuestoACubrir } from "./PuestoACubrir"
import { Region } from "./Region"
import { SubcategoriaImponibleItem } from "./SubcategoriaImponibleItem"
import { TipoIVAGrabado } from "./TipoIVAGrabado"
import { TipoProducto } from "./TipoProducto"
import { TipoVariante } from "./TipoVariante"
import { VehiculoRequerido } from "./VehiculoRequerido"

export class Paquete extends Producto {
	public static TIPO: string = "Q"
	public icon: string = "fa fa-gift"
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado?: boolean,
		tipoProducto?: Descriptivo,
		categorias: CategoriaProducto[] = [],
		peso?: number,
		fechaVigencia?: Date,
		duracion?: number,
		puestosACubrir: PuestoACubrir[] = [],
		vehiculosACubrir: VehiculoRequerido[] = [],
		basePersonas: number = 1,
		cantidad: number = 1,
		insumos: ProductoInsumo[] = [],
		productosAsociados: ProductoAsociado[] = [],
		adicionable: boolean = false,
		esDivisible?: boolean,
		esPropio?: boolean,
		cantidadMaxGrupo: number = 6,
		icono?: ProfilePic,
		detalle?: string,
		codigoIdioma: string = "ES",
		tipoIVA?: TipoIVAGrabado,
		tipoRecordatorio?: Descriptivo,
		requiereConsentimiento: boolean = true,
		nombreComercial?: string,
		encuesta?: EncuestaActividades,
		tipoProductoDesc?: string,
		familiaProducto?: FamiliaProducto,
		tipoVariante?: TipoVariante,
		lastModifiedBy?: string,
		lastModifiedDate?: Date,
		public configuracionPaquete: ConfiguracionPaquete[] = [],
		public clonado?: number,
		public notificaHotel: boolean = true,
		public esSubcategorizado: boolean = false,
		public subcategorias: SubcategoriaImponibleItem[] = [],
		public regiones: Region[] = []
	) {
		super(
			id,
			codigo,
			descripcion,
			habilitado,
			Descriptivo.fromData(tipoProducto),
			categorias ? categorias.map((c) => CategoriaProducto.fromData(c)) : [],
			peso,
			fechaVigencia ? new Date(fechaVigencia) : null,
			duracion,
			puestosACubrir?.map((c) => PuestoACubrir.fromData(c)) || [],
			vehiculosACubrir?.map((c) => VehiculoRequerido.fromData(c)) || [],
			basePersonas,
			cantidad,
			insumos?.map((c) => ProductoInsumo.fromData(c)) || [],
			productosAsociados?.map((c) => ProductoAsociado.fromData(c)) || [],
			adicionable,
			esDivisible,
			esPropio,
			cantidadMaxGrupo,
			ProfilePic.fromData(icono),
			detalle,
			codigoIdioma,
			TipoIVAGrabado.fromData(tipoIVA),
			Descriptivo.fromData(tipoRecordatorio),
			requiereConsentimiento,
			nombreComercial,
			EncuestaActividades.fromData(encuesta),
			Paquete.TIPO,
			null,
			familiaProducto,
			tipoVariante,
			lastModifiedBy,
			lastModifiedDate ? new Date(lastModifiedDate) : null,
			clonado,
			notificaHotel,
			esSubcategorizado,
			subcategorias,
			regiones
		)
	}
	public override clonar(): Paquete {
		var p: Paquete = <Paquete>super.clonar()
		p.configuracionPaquete = this.configuracionPaquete.map((c) => {
			c.id = null
			return c
		})
		return p
	}
	public static fromData(data: any): Paquete {
		if (!data) return null
		const o: Paquete = new Paquete(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			Descriptivo.fromData(data.tipoProducto),
			data.categorias ? data.categorias.map((c) => CategoriaProducto.fromData(c)) : [],
			data.peso,
			data.fechaVigencia ? new Date(data.fechaVigencia) : null,
			data.duracion,
			data.puestosACubrir?.map((c) => PuestoACubrir.fromData(c)) || [],
			data.vehiculosACubrir?.map((c) => VehiculoRequerido.fromData(c)) || [],
			data.basePersonas,
			data.cantidad,
			data.insumos?.map((c) => ProductoInsumo.fromData(c)) || [],
			data.productosAsociados?.map((c) => ProductoAsociado.fromData(c)) || [],
			data.adicionable,
			data.esDivisible,
			data.esPropio,
			data.cantidadMaxGrupo,
			ProfilePic.fromData(data.icono),
			data.detalle,
			data.codigoIdioma,
			TipoIVAGrabado.fromData(data.tipoIVA),
			Descriptivo.fromData(data.tipoRecordatorio),
			data.requiereConsentimiento,
			data.nombreComercial,
			EncuestaActividades.fromData(data.encuesta),
			Paquete.TIPO,
			data.familiaProducto ? FamiliaProducto.fromData(data.familiaProducto) : null,
			data.tipoVariante ? TipoVariante.fromData(data.tipoVariante) : null,
			data.lastModifiedBy,
			data.lastModifiedDate ? new Date(data.lastModifiedDate) : null,
			data.configuracionPaquete ? data.configuracionPaquete.map((c) => ConfiguracionPaquete.fromData(c)) : [],
			data.clonado,
			data.notificaHotel,
			data.esSubcategorizado,
			data.subcategorias?.map((s) => SubcategoriaImponibleItem.fromData(s)) || [],
			data.regiones?.map((s) => Region.fromData(s)) || []
		)
		o.version = data.version
		o.icon = o.esFamilia ? "fa fa-users" : "fa fa-gift"
		return o
	}
	get tipo() {
		return Paquete.TIPO
	}
	get esFamilia() {
		return TipoProducto.FAMILIA === this.tipoProducto?.codigo
	}
	get esPaquete() {
		return this.esFamilia || true
	}
}

export class ConfiguracionPaquete {
	constructor(public id?: number, public producto?: Descriptivo, public autoasignar?: boolean, public fullDescripcion?: string) {}

	public static fromData(data: any): ConfiguracionPaquete {
		if (!data) return null
		const producto = data.producto ? Descriptivo.fromData(data.producto) : null
		return new ConfiguracionPaquete(data.id, producto, data.autoasignar, data.fullDescripcion)
	}
}
