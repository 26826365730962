import { Descriptivo } from "src/app/common/model/Descriptivo"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { StringUtils } from "../common/utils/string-utils"

export class Notificacion {
	public searchStr
	public idEntidadOrigen
	public tipoEntidadOrigen
	public nombreEntidadOrigen
	constructor(
		public id?: number,
		public encabezado?: string,
		public key?: string,
		public fechaProgramada?: Date,
		public fechaVencimiento?: Date,
		public destinatario?: string,
		public estado?: EstadoDocumento,
		public emailTemplate?: Descriptivo,
		public parametros?: any,
		public parametrosAdjunto?: Descriptivo,
		public tipoCanal?: string,
		public logEstado?: string,
		public intentos?: number,
		public tipoNotificacion?: Descriptivo,
		public codigoIdioma?: string,
		public lastModifiedDate?: Date,
		public lastModifiedBy?: string,
		public originador?: string
	) {
		this.searchStr = (
			this.encabezado +
			"_" +
			this.destinatario +
			"_" +
			this.tipoNotificacion.codigo +
			"_" +
			this.tipoNotificacion.descripcion
		).toUpperCase()
		this.idEntidadOrigen = this.parametros["idEntidadOrigen"]
		this.tipoEntidadOrigen = this.parametros["tipoEntidadOrigen"]
		this.nombreEntidadOrigen = this.tipoEntidadOrigen
			? StringUtils.toTitleCase(this.tipoEntidadOrigen.replace("VO", "")) + (this.idEntidadOrigen ? " N° " + this.idEntidadOrigen : "")
			: "Recordatorio"
	}
	public static fromData(data: any): Notificacion {
		if (!data) return null
		const o: Notificacion = new Notificacion(
			data.id,
			data.encabezado,
			data.key,
			data.fechaProgramada ? new Date(data.fechaProgramada) : null,
			data.fechaVencimiento ? new Date(data.fechaVencimiento) : null,
			data.destinatario,
			EstadoDocumento.fromData(data.estadoNotificacion),
			Descriptivo.fromData(data.emailTemplate),
			data.parametros,
			data.parametrosAdjunto,
			data.tipoCanal,
			data.logEstado,
			data.intentos,
			Descriptivo.fromData(data.tipoNotificacion),
			data.codigoIdioma,
			data.lastModifiedDate ? new Date(data.lastModifiedDate) : null,
			data.lastModifiedBy,
			data.originador
		)

		return o
	}

	public getDetalleNovedad(): string {
		let regex = /<p>(.*?)<\/p>/
		return regex.exec(this.parametros.$DETALLE$)[1]
	}
}
