<div class="email-temaplate">
	<model-list
		[service]="emailTemplateService"
		[modelName]="'emailtemplate'"
		[columns]="columns"
		[acciones]="acciones"
		[addDefaultAcciones]="true"
		[filterContent]="customFilter"
		[title]="'EMAIL_TEMPLATE' | translate"
		[filtro]="f"
		[isLazy]="true"
		[helpPath]="'configuracion/plantillas'"
	>
		<ng-template #customFilter>
			<filtro [filter]="f">
				<div class="grid align-content-center">
					<div class="col-12">
						<descriptivo-material-selector
							[isLazy]="true"
							[service]="puntoDeVentaService"
							[label]="'PuntoDeVenta' | translate"
							name="puntoDeVenta"
							[limpiable]="true"
							[(ngModel)]="f.puntoDeVenta"
						>
						</descriptivo-material-selector>
					</div>
				</div>
			</filtro>
		</ng-template>
	</model-list>
</div>

<p-dialog
	[(visible)]="copiar"
	appendTo="body"
	[modal]="true"
	#copiarDialog
	[style]="{ width: '50%' }"
	[closeOnEscape]="true"
	[dismissableMask]="true"
	[closable]="true"
	[showHeader]="true"
	header="Copiar Plantilla"
>
	<ng-container>
		<div class="grid margin">
			<div class="col-12 lg:col-6">
				<span class="p-float-label">
					<input id="float-input" type="text" pInputText size="30" [(ngModel)]="descripcion" />
					<label for="float-input">{{ "DESCRIPCION" | translate }}</label>
				</span>
			</div>
			<div class="col-12 lg:col-6">
				<descriptivo-material-selector
					[isLazy]="true"
					[service]="puntoDeVentaService"
					[label]="'PuntoDeVenta' | translate"
					name="puntoDeVenta"
					[limpiable]="true"
					[(ngModel)]="puntoDeVenta"
				>
				</descriptivo-material-selector>
			</div>
		</div>
	</ng-container>
	<p-footer>
		<p-button icon="pi pi-check" (click)="copiarPlantilla()" label="Guardar" styleClass="p-button-text"></p-button>
	</p-footer>
</p-dialog>
