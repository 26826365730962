import { Descriptivo } from "src/app/common/model/Descriptivo"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { MonedaEntorno } from "../app.module"
import { IConCobros, IValor } from "../common/components/abm-cobros/IConCobros"
import { Usuario } from "../common/model/Usuario"
import { Auditable } from "./Auditable"
import { ItemOrdenPago } from "./ItemOrdenPago"
import { Moneda } from "./Moneda"
import { Pago } from "./Pago"
import { Proveedor } from "./Proveedor"
import { CobroCompensacionSaldo } from "./cobros/CobroCompensacionSaldo"

export class OrdenPago extends Auditable implements IConCobros {
	get tipoOperacion(): "COMPRA" | "VENTA" {
		return "COMPRA"
	}
	constructor(
		public id?: number,
		public proveedor?: Descriptivo,
		public items: ItemOrdenPago[] = [],
		public pagoACuenta: number = 0,
		public pagos: Pago[] = [],
		public descripcion?: string,
		public responsable?: Descriptivo,
		public fecha: Date = new Date(),
		public centroCosto?: Descriptivo,
		public estado?: EstadoDocumento,
		public importe?: number,
		public enviarNotificacion: boolean = true,
		public destinatario?: string,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
		public version?: number,
		public moneda: Moneda = MonedaEntorno(),
		public observaciones?: string
	) {
		super(lastModifiedBy, lastModifiedDate)
		if (!this.moneda) this.moneda = MonedaEntorno()
	}

	get totalALocal(): number {
		return this.total
	}
	getSaldo(): number {
		return this.saldoRestante
	}
	agregarCobro(c: IValor) {
		if (c.tipoCobro == CobroCompensacionSaldo.TIPO) {
			c["listadoComprobantes"] = c["listadoFacturas"]
		}
		this.pagos.push(c)
	}
	quitarCobro(c: IValor) {
		this.pagos = this.pagos.filter((cc) => cc != c)
	}

	buscarCobro(ref) {
		return this.pagos.find((c) => c["refComprobante"] == ref)
	}

	reemplazarCobro(item: IValor, item2: IValor) {
		if (!item && this.pagos.indexOf(item2) < 0) {
			this.pagos.push(item2)
		} else {
			var i = this.pagos.indexOf(item)
			if (i >= 0 && item2) {
				this.pagos[i] = item2
			}
		}
	}
	public static fromData(data: any): OrdenPago {
		if (!data) return null
		const o: OrdenPago = new OrdenPago(
			data.id,
			Proveedor.fromData(data.proveedor),
			data.items.map((c) => ItemOrdenPago.fromData(c)),
			data.pagoACuenta,
			data.pagos.map((c) => Pago.fromData(c)),
			data.descripcion,
			Usuario.fromData(data.responsable),
			data.fecha ? new Date(data.fecha) : null,
			Descriptivo.fromData(data.centroCosto),
			EstadoDocumento.fromData(data.estado),
			data.importe,
			null,
			null,
			data.lastModifiedBy,
			data.lastModifiedDate,
			data.version,
			Moneda.fromData(data.moneda) || MonedaEntorno(),
			data.observaciones
		)
		return o
	}

	get total(): number {
		return this.totalItems + this.pagoACuenta
	}

	get totalItems(): number {
		return Math.round(this.items.reduce((a, b) => a + b.importe, 0) * 100) / 100
	}

	get totalPagos(): number {
		return Math.round(this.pagos.reduce((a, b) => a + b.monto * b.cotizacionSafe, 0) * 100) / 100
	}

	get pagosValidos() {
		return this.pagos.length === 0 || Math.round((this.totalPagos - this.total) * 100) / 100 === 0
	}

	get saldoRestante() {
		return this.total > 0 ? this.total - this.totalPagos : 0
	}
	public get valores(): IValor[] {
		return this.pagos
	}
	public set valores(v: IValor[]) {
		this.pagos = v
	}
}
