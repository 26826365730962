import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { TipoTarjetaCredito } from "src/app/model/TipoTarjetaCredito"
import { MessagesService } from "../common/services/messages-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class TipoTarjetaCreditoService extends ParametricoService<TipoTarjetaCredito> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "tipo-tarjeta-credito"
	}
	public parseToEnt(data: any): TipoTarjetaCredito {
		return TipoTarjetaCredito.fromData(data)
	}
	public newEnt(): TipoTarjetaCredito {
		return new TipoTarjetaCredito()
	}
}
