import { FacturaResumen } from 'src/app/model/FacturaResumen'
import { ResumenReserva } from './ResumenReserva'
import { ItemResumenReserva } from 'src/app/model/ItemReservaResumen'
import { Factura } from './Factura'
import { Comprobante } from './Comprobante'
import { Producto } from './Producto'
import { ItemReserva } from './ItemReserva'
import { Identificable } from './../common/model/Identficable'
import { Proveedor } from './Proveedor'
import { Reserva } from './Reserva'

export class Comision implements Identificable {
    constructor(
        public id?: number,
        public reserva?: ResumenReserva,
        public itemReserva?: ItemResumenReserva,
        public vendedor?: Proveedor,
        public producto?: Producto,
        public fecha?: Date,
        public importe?: number,
        public comprobante?: FacturaResumen
    ) {}

    public static fromData(data) {
        let o = new Comision(
            data.id,
            ResumenReserva.fromData(data.reserva),
            ItemResumenReserva.fromData(data.itemReserva),
            Proveedor.fromData(data.proveedor),
            Producto.fromData(data.producto),
            data.fecha,
            data.importe,
            FacturaResumen.fromData(data.comprobante)
        )
        return o
    }
}
