import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { Subscription } from "rxjs"
import { filter } from "rxjs/operators"
import { IngresosEgresosAG } from "src/app/authguards/MovimientoCajaAG"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { ArqueoCaja } from "src/app/model/ArqueoCaja"
import { BilletesCaja } from "src/app/model/BilletesCaja"
import { Caja } from "src/app/model/Caja"
import { BilleteService } from "src/app/services/billete.service"
import { CajaService } from "src/app/services/caja.service"
import { CambioEstadoCajaService } from "src/app/services/cambio-estado-caja.service"
import { SessionComponent } from "./../../../common/components/session-component.component"

@Component({
	selector: "gestor-cambio-estado-caja",
	templateUrl: "gestor-cambio-estado-caja.component.html",
	styleUrls: ["gestor-cambio-estado-caja.component.less"]
})
export class GestorArqueoCajaComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	@Input()
	public item: ArqueoCaja
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false

	@Input()
	public esCierre = false

	private _caja: Caja
	public get caja(): Caja {
		return this._caja
	}
	@Input()
	public set caja(v: Caja) {
		this._caja = v
		this.cajaService.getSaldo(v).then((r) => {
			this.saldoAnterior = r
		})
	}

	@Input()
	public readonly: boolean = false

	@Output()
	public onGuardado: EventEmitter<ArqueoCaja> = new EventEmitter<ArqueoCaja>()

	@Output()
	public onCancelado = new EventEmitter()

	@Input()
	public cajaEditable: boolean = true

	public usuarioOptions: Descriptivo[] = []
	public cajaOptions: Descriptivo[] = []
	public billetesCajaOptions: Descriptivo[] = []

	public saldoAnterior: number = 0

	public get cajaSeleccionada(): Caja {
		return this.item.caja
	}
	public set cajaSeleccionada(v: Caja) {
		this.item.caja = v
		this.cajaService.getSaldo(v).then((r) => {
			this.saldoAnterior = r
		})
	}

	public get monto(): number {
		return this.item.monto
	}
	public set monto(v: number) {
		this.item.monto = v
		this.item.diferencia = this.monto - this.saldoAnterior
	}

	constructor(
		messagesService: MessagesService,
		public service: CambioEstadoCajaService,
		private route: ActivatedRoute,
		private router: Router,
		private confService: ConfirmationService,
		private billetesService: BilleteService,
		private cajaService: CajaService,
		private ingresosEgresosAg: IngresosEgresosAG
	) {
		super(messagesService)
	}

	public handleGuardado(item: ArqueoCaja) {
		//this.caja.estaCerrada = item.esCierre;
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}

	public async isValid() {
		if (this.item.diferencia != 0 && !this.item.justificacion) {
			return this.error(this.translateService.get("INDIQUE_UNA_JUSTIFI_22"))
		}
		return true
	}

	public onEdit(event) {
		if (this.item?.id) this.router.navigate(["'cambio-estado-caja/edit"], { queryParams: { id: this.item.id } })
	}

	ngOnInit() {
		this.readonly = (this.item && this.item.id != undefined) || !this.ingresosEgresosAg.esVisible(this.usuario)
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
				}
			})
		)
		if (this.route.snapshot.url.some((u) => u.path == "cambio-estado-caja")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				if (!this.service) return
				if (id) {
					this.service.getById(id, null, this.translateService.idioma ? this.translateService.idioma : "ES").then((r) => {
						this.item = r
						this.readonly = true
					})
				} else {
					if (!this.item && this.service) {
						this.item = this.service.newEnt()
					}
					this.item["codigoIdioma"] = this.translateService.idioma ? this.translateService.idioma : "ES"
					this.item.caja = this.caja
					this.subs.push(
						this.billetesService.data.pipe(filter((r) => r != undefined)).subscribe((r) => {
							this.item.valores = r.map((b) => new BilletesCaja(null, b, 0))
							//susBilletes.unsubscribe();
						})
					)
				}
			})
		}

		this.cajaService.getAll().then((r) => {
			this.cajaOptions = r
		})
	}
	public updateMonto() {
		this.item.monto = this.item.valores.reduce((a, b) => (a += b.billete.valor * b.cantidad), 0)
	}
	public get esEditable() {
		return this.esAdministrador
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}
}
