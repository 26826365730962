import { Moneda } from "src/app/model/Moneda"
import { MonedaEntorno } from "../app.module"
export class Totales {
	public totalesPorMoneda: TotalMoneda[] = []
	public totalConsumido: number = 0
	public totalPagado: number = 0
	public totalSaldo: number = 0
	public totalImpuestos: number = 0
	public totalOtrosImpuestos: number = 0
	public totalBruto: number = 0
	public count: number = 0
	public moneda: Moneda = MonedaEntorno() || Moneda.PESOS
	constructor(data?) {
		if (data) Object.assign(this, data)
		if (!this.moneda) this.moneda = MonedaEntorno() || Moneda.PESOS
	}
	public get pagos() {
		return this.totalPagado ? this.totalPagado : this.totalSaldo ? Math.abs(this.totalConsumido - this.totalSaldo) : this.totalConsumido
	}
	public agregarTotal(tot: TotalMoneda) {
		this.totalConsumido += tot.monto
		this.totalSaldo += tot.saldo
		this.count += tot.count
		this.totalesPorMoneda.push(tot)
	}

	public static fromData(data: any) {
		if (!data) return null

		return Object.assign(new Totales(), data)
	}
}
export class TotalMoneda {
	constructor(public moneda: Moneda, public monto: number, public saldo: number, public count: number) {}
}
