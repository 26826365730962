<div class="gestor-factura content content-white">
	<model-gestor
		[readonly]="readonly"
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[title]="'COMPROBANTE' | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		(onCancelar)="handleCancelar($event)"
		[form]="FacturaForm"
		[title]="finalTitle"
		[editable]="editable"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #FacturaForm="ngForm" id="Facturaform" class="has-footer">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-3 field" [hideExterno]>
						<descriptivo-material-selector
							[allowDefault]="!item?.id"
							[service]="proveedorService"
							[isLazy]="true"
							[label]="'PROVEEDOR' | translate"
							[limpiable]="limpiable"
							[(ngModel)]="item.proveedor"
							[permiteNuevo]="true"
							name="proveedor"
							[gestor]="gestorProveedor"
							[readonly]="readonly || item.id || item.saldo != item.importe"
							required
							(onSelect)="proveedorChange($event)"
						>
						</descriptivo-material-selector>
					</div>
					<div class="col-6 lg:col-2 field">
						<descriptivo-material-selector
							[allowDefault]="!item?.id"
							[options]="tipoComprobanteOptions"
							[label]="'TIPOCOMPROBANTE' | translate"
							[limpiable]="limpiable"
							[(ngModel)]="item.tipoComprobante"
							name="tipoComprobante"
							[readonly]="readonly"
							required
						>
						</descriptivo-material-selector>
					</div>
					<div class="col-12 lg:col-3">
						<div class="grid">
							<div class="col field">
								<parametrico-selector
									[service]="monedaService"
									#moneda="ngModel"
									[(ngModel)]="item.moneda"
									[limpiable]="false"
									[readonly]="readonly || item.id || item.items?.length"
									name="moneda"
									[titulo]="'MONEDA' | translate"
									required
								>
								</parametrico-selector>
								<error-tag [model]="moneda"></error-tag>
							</div>
							<div class="col-fixed w-12rem pl-2 p-fluid" *ngIf="item && monedaExtranjera(item.moneda)">
								<span-dinero-editable
									[(ngModel)]="item.cotizacion"
									[editable]="!readonly"
									label="{{ 'COTIZACION_A_LOCAL' | translate }}"
									name="{{ 'cotizacion' }}"
									[minValue]="0"
									[decimals]="8"
									[moneda]="monedaEntorno"
									[mostrarTotales]="!readonly && !item?.id && item?.total > 0 && item?.pagos?.length > 0"
								>
								</span-dinero-editable>
							</div>
						</div>
					</div>

					<div class="col-12 lg:col-4">
						<div class="grid">
							<div class="col-4 field">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'PUNTO_VENTA' | translate }}"
										[(ngModel)]="item.puntoVenta"
										type="text"
										#puntoVenta="ngModel"
										name="puntoVenta"
										[readonly]="readonly"
										[required]="item.tipoComprobante?.codigo != 'TI' && item.tipoComprobante?.codigo != 'AR'"
										maxLength="5"
										(change)="puntoVentaChange($event)"
									/>
									<label>{{ "PUNTO_VENTA" | translate }}</label>
								</span>
								<error-tag [model]="puntoVenta"></error-tag>
							</div>
							<div class="col-8 field">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'NUMERO' | translate }}"
										[(ngModel)]="item.numero"
										type="search"
										#numeroFactura="ngModel"
										autocomplete="off"
										name="numeroFactura"
										[readonly]="readonly"
										[required]="item.tipoComprobante?.codigo != 'TI' && item.tipoComprobante?.codigo != 'AR'"
										(change)="numeroChange($event)"
									/>
									<label>{{ "NUMERO" | translate }}</label>
								</span>
								<error-tag [model]="numeroFactura"></error-tag>
							</div>
						</div>
					</div>

					<div class="col-12 lg:col-4 md:col-6 field">
						<div class="grid">
							<div class="col-6 p-fluid">
								<span class="p-float-label">
									<p-calendar
										[(ngModel)]="item.fecha"
										[showIcon]="true"
										[readonlyInput]="true"
										dateFormat="dd/mm/yy"
										[disabled]="readonly"
										name="fecha"
										[required]="true"
									></p-calendar>
									<label>{{ "FECHA" | translate }}</label>
								</span>
								<error-tag [model]="fecha"></error-tag>
							</div>

							<div class="col-6 p-fluid field">
								<span class="p-float-label">
									<p-calendar
										[(ngModel)]="item.fechaVencimiento"
										[showIcon]="true"
										[readonlyInput]="true"
										[disabled]="readonly"
										dateFormat="dd/mm/yy"
										name="fechaVencimiento"
										[required]="true"
									></p-calendar>
									<label class="required">{{ "FECHAVENCIMIENTO" | translate }}</label>
								</span>
								<error-tag [model]="fechaVencimientoFactura"></error-tag>
							</div>
						</div>
					</div>

					<div class="col-12 lg:col-4 p-fluid field">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'FACTURA_RELACIONADA' | translate }}"
								[(ngModel)]="item.numeroFacturaRelacionado"
								tooltipPosition="bottom"
								pTooltip="{{ 'FACTURA_RELACIONADA' | translate }}"
								type="text"
								#numeroFacturaRelacionado="ngModel"
								name="numeroFacturaRelacionado"
								[readonly]="readonly"
							/>
							<label>{{ "FACTURA_RELACIONADA" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>

					<div class="col-12 lg:col-4 field">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="text"
								#descripcion="ngModel"
								name="descripcion"
								autocomplete="off"
								[readonly]="readonly"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>

					<div class="col-12 nowrap">
						<h3 class="subtitle">{{ "ITEMS" | translate }}</h3>
						<button *ngIf="!edicionRestringidaSaldo && item?.proveedor" pButton icon="pi pi-plus" (click)="nuevoItem()"></button>
					</div>
					<div class="col-12" *ngIf="!item?.proveedor">
						<h4>{{ "ELIJA_UN_PROVEEDOR" | translate }}</h4>
					</div>
					<div class="col-12" *ngIf="item?.proveedor">
						<p-table [value]="item.items">
							<ng-template pTemplate="header">
								<tr>
									<th>{{ "INSUMO" | translate }}</th>

									<th>{{ "DESCRIPCION" | translate }}</th>
									<th style="width: 8rem">{{ "CANTIDAD" | translate }}</th>
									<th style="width: 12rem">{{ "IMPORTE_UNITARIO" | translate }}</th>
									<th style="width: 12rem">{{ "IMPORTE" | translate }}</th>
									<th style="width: 12rem">{{ "IMPUESTOS" | translate }}</th>
									<th style="width: 8rem">{{ "TIPO_IVA" | translate }}</th>
									<th style="width: 60px"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-it let-i="rowIndex">
								<tr [ngClass]="{ small: it?.esSubcategoria }">
									<td class="p-fluid" [ngClass]="{ pendingHomologation: it?.insumo?.codigo == 'HOMO' }" *ngIf="!it?.esSubcategoria">
										<p-autoComplete
											*ngIf="!edicionRestringidaSaldo && item?.proveedor?.id"
											[(ngModel)]="it.insumo"
											name="insumo_{{ i }}"
											[group]="true"
											appendTo="body"
											dropdown="true"
											[suggestions]="insumosGroupOptions"
											(completeMethod)="filterGroup($event)"
											(onSelect)="insumoElegido($event, it)"
											(onBlur)="validarItem($event, it)"
										>
											<ng-template let-group pTemplate="group">
												<div class="flex align-items-center">
													<span>{{ group.label }}</span>
												</div>
											</ng-template>
											<ng-template let-item pTemplate="item">
												<div class="flex align-items-center">
													<i
														*ngIf="it?.insumo?.codigo == 'HOMO'"
														class="fa fa-warning"
														tooltipPosition="bottom"
														pTooltip="{{ 'PENDING_HOMOLOGATION' | translate }}"
													></i>
													<span><i class="fa fa-plus" *ngIf="item.value == '$new$'"></i>{{ item.label }}</span>
												</div>
											</ng-template>
										</p-autoComplete>
										<span *ngIf="edicionRestringidaSaldo">
											{{ it.insumo.descripcion }}
											<i
												*ngIf="it?.insumo?.codigo == 'HOMO'"
												class="fa fa-warning"
												tooltipPosition="bottom"
												pTooltip="{{ 'PENDING_HOMOLOGATION' | translate }}"
											></i>
										</span>
									</td>
									<td class="p-fluid" [ngClass]="{ pendingHomologation: it?.insumo?.codigo == 'HOMO' }" *ngIf="it?.esSubcategoria">
										<i
											*ngIf="it?.subcategoria?.codigo == 'HOMO'"
											class="fa fa-warning"
											tooltipPosition="bottom"
											pTooltip="{{ 'PENDING_HOMOLOGATION' | translate }}"
										></i>
										<descriptivo-material-selector
											[(ngModel)]="it.subcategoria"
											[options]="subcategoriaOptions"
											[isLazy]="false"
											[hideLabel]="true"
											[placeHolder]="'SUBCATEGORIA_IMPONIBLE' | translate"
											[permiteNuevo]="false"
											[ngModelOptions]="{ standalone: true }"
											[required]="true"
											[readonly]="edicionRestringidaSaldo"
										></descriptivo-material-selector>
									</td>
									<td class="p-fluid">
										<input
											pInputText
											placeholder="{{ 'DESCRIPCION' | translate }}"
											[(ngModel)]="it.descripcion"
											type="text"
											name="descripcion_{{ i }}"
											#descripcion="ngModel"
											[readonly]="readonly"
											pTooltip="{{ it.descripcion }}"
										/>
									</td>
									<td class="p-fluid">
										<div class="p-inputgroup" *ngIf="!it?.esSubcategoria">
											<span class="p-float-label">
												<input
													pInputText
													[(ngModel)]="it.cantidad"
													type="number"
													name="cantidad_{{ i }}"
													#cantidad="ngModel"
													[readonly]="readonly || edicionRestringidaSaldo || it?.esSubcategoria"
													(keyup)="cantidadChange($event, it)"
													(change)="cantidadChange($event, it)"
												/>
											</span>
											<span class="p-inputgroup-addon" style="border: none">{{ it?.insumo?.unidad?.codigo }}</span>
											<error-tag [model]="cantidad"></error-tag>
										</div>

										<span class="text-center small block" *ngIf="it?.esSubcategoria">{{ it?.proporcion }} %</span>
									</td>

									<td class="p-fluid">
										<span-dinero-editable
											[(ngModel)]="it.importeUnitario"
											#importeUnitario="ngModel"
											name="unitario_{{ i }}"
											[styleClass]="'text-right'"
											[moneda]="item.moneda"
											(onModificado)="unitarioChange($event, it)"
											[readonly]="readonly || edicionRestringidaSaldo || it?.esSubcategoria"
										>
										</span-dinero-editable>
										<error-tag [model]="importeUnitario"></error-tag>

										<p-checkbox
											[hideExterno]
											*ngIf="it.importeModificado && !it.esSubcategoria"
											[(ngModel)]="it.actualizarPrecio"
											tooltipPosition="bottom"
											pTooltip="{{ 'ACTUALIZAR_PRECIO' | translate }}"
											binary="true"
											inputId="binary"
											label="{{ 'Modificar precio' | translate }}"
											name="actualizar_{{ i }}"
										>
										</p-checkbox>
									</td>
									<td class="p-fluid">
										<span-dinero-editable
											[(ngModel)]="it.importe"
											#importe="ngModel"
											name="importe_{{ i }}"
											[styleClass]="'text-right'"
											(onModificado)="totalChange($event, it)"
											[moneda]="item.moneda"
											[readonly]="readonly || edicionRestringidaSaldo || it?.esSubcategoria"
										>
										</span-dinero-editable>
										<error-tag [model]="importe"></error-tag>
									</td>
									<td class="p-fluid">
										<span-dinero-editable
											[styleClass]="'text-right'"
											[ngModel]="it.impuestos"
											name="impuestos_{{ i }}"
											[moneda]="item.moneda"
											[readonly]="true"
										>
										</span-dinero-editable>
									</td>
									<td class="text-center p-fluid">
										<descriptivo-material-selector
											[allowDefault]="!item?.id"
											*ngIf="!edicionRestringidaSaldo && (it?.esSubcategoria || !it?.esCategorizado())"
											[options]="tiposIVAOptions"
											[label]="'' | translate"
											[limpiable]="limpiable"
											[default]="defaultIVA"
											[(ngModel)]="it.tipoIVA"
											[readonly]="readonly || edicionRestringidaSaldo || it?.esSubcategoria"
											name="tipoiva_{{ i }}"
										>
										</descriptivo-material-selector>

										<span *ngIf="edicionRestringidaSaldo && !it?.esCategorizado()">{{ it.tipoIVA.descripcion }}</span>
									</td>
									<td>
										<button
											*ngIf="!edicionRestringidaSaldo && !it?.esSubcategoria"
											pButton
											icon="pi pi-trash"
											(click)="item.quitarItem(it)"
										></button>
									</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
					<ng-container *ngIf="item?.proveedor">
						<div class="col-12 impuestos">
							<div class="grid">
								<div class="col-6">
									<ng-container *ngFor="let iva of item.ivas">
										<div class="grid" [ngClass]="i % 2 ? 'odd' : 'even'">
											<div class="col-4">
												<label>IVA {{ iva.descripcion }}</label>
											</div>
											<div class="col-8">
												<span>{{ item.totalIva(iva) | currencyCustom : item?.moneda }}</span>
											</div>
										</div>
									</ng-container>
									<div class="grid">
										<div class="col-12" [ngClass]="item.ivas.length % 2 ? 'odd' : 'even'">
											<div class="grid">
												<div class="col-4">
													<label>Total IVA</label>
												</div>
												<div class="col-8">
													<span>{{ item.totalIvas | currencyCustom : item?.moneda }}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-6 even" [paises-habilitados]="['AR']" *ngIf="!edicionRestringidaSaldo">
									<span-dinero-editable
										[(ngModel)]="item.iibbOtros"
										#importe="ngModel"
										label="{{ 'IIBB' | translate }}"
										name="iibbOtros"
										[moneda]="item.moneda"
										[readonly]="readonly || edicionRestringidaSaldo"
									>
									</span-dinero-editable>

									<span *ngIf="readonly">{{ item.iibbOtros | currencyCustom : item?.moneda }}</span>
								</div>

								<div *ngFor="let f of filasExtrasImpuestos; let i = index">
									<div class="col-12" [ngClass]="i % 2 ? 'even' : 'odd'" style="height: 47px"></div>
								</div>
							</div>
						</div>
						<div class="col-12 p-widget-header total small">
							<div class="grid">
								<div class="col text-right">{{ "SUBTOTAL" | translate }}</div>
								<div class="col col-fixed text-right importe">
									{{ item.subtotal | currencyCustom : item?.moneda }}
								</div>
							</div>
						</div>
						<div class="col-12 p-widget-header total small">
							<div class="grid">
								<div class="col text-right">{{ "IMPUESTOS" | translate }}</div>
								<div class="col col-fixed text-right importe">
									{{ item.totalImpuestos | currencyCustom : item?.moneda }}
								</div>
							</div>
						</div>
						<div class="col-12 p-widget-header total">
							<div class="grid">
								<div class="col text-right">{{ "TOTAL" | translate }}</div>
								<div class="col col-fixed text-right importe">
									{{ item.total | currencyCustom : item?.moneda }}
								</div>
							</div>
						</div>
						<div class="col-12 p-widget-header total" *ngIf="item?.id">
							<div class="grid">
								<div class="col text-right">{{ "SALDO" | translate }}</div>
								<div class="col col-fixed text-right importe">
									{{ item.saldo | currencyCustom : item?.moneda }}
								</div>
							</div>
						</div>

						<div class="col-12">
							<span class="p-float-label pb-2" *ngIf="item?.items?.length || item?.itemsPagos?.length">
								<input type="text" hidden name="dummy" class="p-inputwrapper-filled" />

								<ng-container *ngIf="edicionRestringidaSaldo && item?.itemsPagos?.length">
									<p-table [value]="item.itemsPagos">
										<ng-template pTemplate="header">
											<tr>
												<th style="width: 15rem">Fecha</th>
												<th style="width: 8rem">Orden de pago</th>
												<th style="width: 8rem">Estado</th>
												<th style="width: 15rem">Descripción</th>
												<th style="width: 15rem; text-align: right">Importe</th>
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-itemPago>
											<tr>
												<td>{{ itemPago.fechaOrdenPago | date : "dd/MM/yyyy" }}</td>
												<td>
													<span
														class="clickable chip text-center"
														*ngIf="itemPago?.idOrdenPago"
														(click)="verOrdenPago(itemPago?.idOrdenPago)"
														>{{ itemPago?.idOrdenPago }}</span
													>
												</td>
												<td>
													<span class="chip text-center" [ngStyle]="{ 'background-color': itemPago.estadoOrdenPago.color }">
														{{ itemPago.estadoOrdenPago.descripcion }}
													</span>
												</td>
												<td>{{ itemPago.descripcionOrdenPago }}</td>
												<td class="text-right">{{ itemPago.importe | currencyCustom }}</td>
											</tr>
										</ng-template>
									</p-table>
								</ng-container>
								<ng-container *ngIf="!edicionRestringidaSaldo" [hideExterno]>
									<abm-cobros
										[item]="item"
										[readonly]="readonly || !item.items?.length || item?.id"
										[monedaRef]="monedaEntorno"
										[cotizacion]="item?.cotizacion || 1"
										[mostrarTotales]="!readonly && item?.pagos?.length > 0"
										[clienteAsociado]="cliente?.id"
									></abm-cobros>
								</ng-container>
								<ng-container *ngIf="edicionRestringidaSaldo && item?.itemsPagos?.length == 0">
									<span class="text-center block w-100">{{ "NO_HAY_PAGOS_REGISTRADOS" | translate }}</span>
								</ng-container>
								<label>{{ "Pagos" | translate }}</label>
							</span>
						</div>

						<div class="col-12 lg:col-6" [hideExterno]>
							<span class="fw-500">{{ "RECORDATORIOS" | translate }}</span>
							<abm-recordatorios [itemConRecordatorios]="item" [readonly]="readonly" [fechaBase]="item.fechaVencimiento"></abm-recordatorios>
						</div>
						<div class="col-12 lg:col-6">
							<adjunto [item]="item" [readonly]="readonly" [uploadUrl]="uploadUrl"></adjunto>
						</div>
						<div class="col-12" *ngIf="!item?.id && item?.pagos?.length > 0" [hideExterno]>
							<div class="grid">
								<div class="col-12 lg:col-2">
									<p-checkbox
										binary="true"
										[(ngModel)]="item.enviarNotificacion"
										name="notificar"
										tooltipPosition="bottom"
										pTooltip="{{ 'ENVIAR_UN_MAIL_AL_P_28' | translate }}"
										label="{{ 'NOTIFICAR_AL_PROVEE_2' | translate }}"
									></p-checkbox>
								</div>
								<div class="col-12 lg:col-8 field" *ngIf="item.enviarNotificacion">
									<span class="p-float-label">
										<form #MailForm="ngForm" id="MailForm">
											<selector-email [(ngModel)]="item.destinatario" name="d" #d="ngModel"></selector-email>
										</form>
									</span>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
				<p-dialog
					[(visible)]="editando"
					appendTo="body"
					[modal]="true"
					#dialDetail
					[styleClass]="'no-header gestor-dialog'"
					(onShow)="onDialogShow($event, dialDetail)"
				>
					<gestor-insumo
						(onGuardado)="onGuardadoInsumo($event)"
						(onCancelado)="onCanceladoInsumo($event)"
						[isModal]="true"
						[goBack]="false"
						[item]="insumoEditado"
					>
					</gestor-insumo>
				</p-dialog>
			</form>
		</div>
	</model-gestor>
</div>

<ng-template #gestorProveedor let-handler="handler">
	<gestor-proveedor
		(onGuardado)="handler.onGuardado($event); onProveedorGuardado($event)"
		(onCancelado)="handler.onCancelado($event)"
		[isModal]="true"
		[goBack]="false"
	>
	</gestor-proveedor>
</ng-template>
