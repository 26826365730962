import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'span-list',
    templateUrl: './span-list.component.html',
    styleUrls: ['./span-list.component.less']
})
export class SpanListComponent implements OnInit {

    @Input()
    public init: number = 1;
    
    @Input('value') set value(value: string[]) {
        this.messages = value;
        this.initMessages = value.length < this.init ? [] : value.slice(0, this.init);
        this.hiddenMessages = value.length < this.init + 1 ? [] : value.slice(this.init);
    }
    
    public messages: any[] = [];
    public initMessages: any[] = [];
    public hiddenMessages: any[] = [];
    public showHiddenMessages: boolean = false;

    constructor() { }

    ngOnInit() { 
    }

}