import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { MenuItem } from "primeng/api"
import { BehaviorSubject } from "rxjs"
import { ActividadesAG } from "src/app/authguards/ActividadesAG"
import { ClientesAG } from "src/app/authguards/ClientesAG"
import { CobranzasAG } from "src/app/authguards/CobranzasAG"
import { ComprobantesAG } from "src/app/authguards/ComprobantesAG"
import { ConsentimientoAG } from "src/app/authguards/ConsentimientoAG"
import { EncuestasAG } from "src/app/authguards/EncuestasAG"
import { FinancieroAG } from "src/app/authguards/FinancieroAG"
import { HotelesAG } from "src/app/authguards/HotelesAG"
import { ItinerarioAG } from "src/app/authguards/ItinerarioAG"
import { ListaPreciosAG } from "src/app/authguards/ListaPreciosAG"
import { MisFacturasProveedorAG, MisOPSProveedorAG, ProveedorExternoAG } from "src/app/authguards/PermisosProveedorExterno"
import { ProductoPublicadoAG } from "src/app/authguards/ProductoPublicadoAG"
import { ProductosAG } from "src/app/authguards/ProductosAG"
import { ProveedoresAG } from "src/app/authguards/ProveedoresAG"
import { ReservasAG } from "src/app/authguards/ReservasAG"
import { SoporteAG } from "src/app/authguards/SoporteAG"
import { VehiculosAG } from "src/app/authguards/VehiculosAG"
import { VentasAG } from "src/app/authguards/VentasAG"
import { Usuario } from "src/app/common/model/Usuario"
import { BaseService } from "src/app/common/services/base.service"
import { Novedad } from "src/app/model/Novedad"
import { AdminAuthGuard } from "./../../authguards/AdminAuthGuard"
import { AdministracionAuthGuard } from "./../../authguards/AdministracionAuthGuard"
import { EmpleadoAuthGuard } from "./../../authguards/EmpleadoAuthGuard"
import { FacturasAG } from "./../../authguards/FacturasAG"
import { MovimientoCajaAG } from "./../../authguards/MovimientoCajaAG"
import { OrdenPagoAG } from "./../../authguards/OrdenPagoAG"
import { MiCuentaProveedorAG } from "./../../authguards/PermisosProveedorExterno"
import { PaxPorServiciosAG, ReporteAG } from "./../../authguards/ReporteAG"
import { ReportesComprasAG } from "./../../authguards/ReporteComprasAG"
import { ReportesVentasAG } from "./../../authguards/ReporteVentasAG"
import { UsuarioAG } from "./../../authguards/UsuarioAG"
import { VendedoresAG } from "./../../authguards/VendedoresAG"
import { VisualizadorProveedorAG } from "./../../authguards/VisualizadorProveedorAG"
import { AuthService } from "./../../services/auth.service"
import { IActualizable, NovedadService } from "./../../services/novedades.service"
import { TranslateService } from "./translate.service"
@Injectable({
	providedIn: "root"
})
export class MenuConfigService extends BaseService implements IActualizable {
	public baseName(): string {
		return "menu-config"
	}
	constructor(
		private novedadService: NovedadService,
		private authService: AuthService,
		private adminAuthGuard: AdminAuthGuard,
		private administracionAG: AdministracionAuthGuard,
		private empleadoAuthGuard: EmpleadoAuthGuard,
		private router: Router,
		private reportesAG: ReporteAG,
		private actividadesAG: ActividadesAG,
		private listaPreciosAG: ListaPreciosAG,
		private userAG: UsuarioAG,
		private proveedoresAG: ProveedoresAG,
		private productosAG: ProductosAG,
		private clientesAG: ClientesAG,
		private reservasAG: ReservasAG,
		private encuestasAG: EncuestasAG,
		private vehiculosAG: VehiculosAG,
		private hotelesAG: HotelesAG,
		private ventasAG: VentasAG,
		private financieroAG: FinancieroAG,
		private itinerarioAG: ItinerarioAG,
		private comprobantesAG: ComprobantesAG,
		private cobranzasAG: CobranzasAG,
		private facturasAG: FacturasAG,
		private ordenPagoAG: OrdenPagoAG,
		public vendedoresAG: VendedoresAG,
		public jefeAdminAG: AdministracionAuthGuard,
		private movimientoCajaAG: MovimientoCajaAG,
		private reporteVentasAG: ReportesVentasAG,
		private reporteComprasAG: ReportesComprasAG,
		private visualizadorProveedorAG: VisualizadorProveedorAG,
		private consentimientoAG: ConsentimientoAG,
		private paxPorServiciosAG: PaxPorServiciosAG,
		private productoPublicadoAG: ProductoPublicadoAG,
		private proveedorExternoAG: ProveedorExternoAG,
		private translateService: TranslateService,
		private MiCuentaProveedorAG: MiCuentaProveedorAG,
		private MisFacturasProveedorAG: MisFacturasProveedorAG,
		private MisOPSProveedorAG: MisOPSProveedorAG,
		private soprteAG: SoporteAG
	) {
		super()
		setTimeout(() => {
			this.init()
		}, 1)
	}
	next(n: Novedad) {
		this.updateBS(this.generarMenu(this.authService.usuario))
	}
	destroy() {
		this.menuBS.next([])
	}
	logout = () => {
		this.authService.logout().then((l) => {
			this.router.navigate(["login"])
		})
	}
	public menuBS: BehaviorSubject<MenuItem[]> = new BehaviorSubject([])
	public rutasBS: BehaviorSubject<MenuItem[]> = new BehaviorSubject([])
	private generarMenu(usuario: Usuario) {
		var items: any[] = []
		let $this = this
		items = []
		if (usuario && this.translateService.data) {
			items = [
				{
					label: this.translateService.get("HOME"),
					icon: "fa fa-home",
					visible: this.empleadoAuthGuard.esVisible(usuario),
					routerLink: ["home"],
					skipRouting: true
				},
				{
					label: this.translateService.get("RESERVAS"),
					icon: "fa fa-calendar-plus-o",
					visible: this.reservasAG.esVisible(usuario),
					routerLink: ["reserva"]
				},
				{
					label: this.translateService.get("COTIZACIONES"),
					icon: "fa fa-cart-plus",
					visible: this.reservasAG.esVisible(usuario),
					routerLink: ["cotizacion"]
				},
				{
					label: this.translateService.get("ACTIVIDADES"),
					icon: "fa fa-calendar",
					visible: this.actividadesAG.esVisible(usuario),
					routerLink: ["actividades"]
				},
				{
					label: this.translateService.get("ITINERARIO"),
					icon: "fa fa-calendar",
					visible: this.itinerarioAG.esVisible(usuario),
					routerLink: ["itinerario"]
				},
				{
					label: this.translateService.get("CONSENTIMIENTOS"),
					icon: "fa fa-sticky-note",
					visible: this.consentimientoAG.esVisible(usuario),
					routerLink: ["consentimiento"]
				},
				{
					label: this.translateService.get("ENCUESTAS"),
					icon: "fa fa-pencil-square-o",
					visible: this.encuestasAG.esVisible(usuario),
					routerLink: ["respuesta-encuesta-actividades"]
				},
				{
					label: this.translateService.get("LISTAS_PRECIOS"),
					icon: "fa fa-list",
					visible: this.listaPreciosAG.esVisible(usuario),
					routerLink: ["lista-precios"]
				},
				{
					label: this.translateService.get("USUARIOS"),
					icon: "fa fa-list",
					visible: this.userAG.esVisible(usuario),
					routerLink: ["usuario"]
				},
				{
					label: this.translateService.get("CLIENTE_TITLE"),
					icon: "fa fa-users",
					visible: this.clientesAG.esVisible(usuario),
					routerLink: ["cliente"]
				},
				{
					label: this.translateService.get("PROVEEDOR_TITLE"),
					icon: "fa fa-users",
					visible: this.proveedoresAG.esVisible(usuario) && !this.proveedorExternoAG.esVisible(usuario),
					routerLink: ["proveedor"]
				},

				{
					label: this.translateService.get("CALENDARIO"),
					icon: "fa fa-calendar-o",
					visible: this.empleadoAuthGuard.esVisible(usuario),
					routerLink: ["calendario"]
				},
				{
					label: this.translateService.get("VENDEDOR_TITLE"),
					icon: "fa fa-users",
					visible: this.vendedoresAG.esVisible(usuario),
					routerLink: ["vendedor"]
				},
				{
					label: this.translateService.get("VEHICULOS"),
					icon: "fa fa-car",
					visible: this.vehiculosAG.esVisible(usuario),
					routerLink: ["vehiculo"]
				},
				{
					label: this.translateService.get("PRODUCTO_TITLE"),
					icon: "fa fa-gift",
					visible: this.productosAG.esVisible(usuario),
					routerLink: ["producto"]
				},
				{
					label: this.translateService.get("HOTEL_TITLE"),
					icon: "fa fa-building",
					visible: this.hotelesAG.esVisible(usuario),
					routerLink: ["hotel"]
				},
				/**{
					label: this.translateService.get("PRODUCTO_PUBLICADO_PLR"),
					icon: "fa fa-cart-plus",
					visible: this.productoPublicadoAG.esVisible(usuario),
					routerLink: ["producto-publicado"]
				},**/
				{
					label: this.translateService.get("Ventas"),
					icon: "fa fa-money",
					visible: this.cobranzasAG.esVisible(usuario) || this.comprobantesAG.esVisible(usuario),
					items: [
						{
							label: this.translateService.get("COBRANZAS"),
							icon: "fa fa-credit-card",
							visible: this.cobranzasAG.esVisible(usuario),
							routerLink: ["cobranza"]
						},

						{
							label: this.translateService.get("COMPROBANTES"),
							icon: "fa fa-barcode",
							visible: this.comprobantesAG.esVisible(usuario),
							routerLink: ["comprobante"]
						}
					]
				},
				{
					label: this.translateService.get("MI_CUENTA_PROVEEDOR"),
					icon: "fa fa-user",
					visible: this.MiCuentaProveedorAG.esVisible(usuario),
					command: () => this.navegar("proveedor/vista", { id: usuario?.proveedor?.id })
				},
				{
					label: this.translateService.get("MIS_FACTURAS"),
					icon: "fa fa-barcode",
					routerLink: ["factura"],
					visible: this.MisFacturasProveedorAG.esVisible(usuario)
				},
				{
					label: this.translateService.get("MIS_OP"),
					icon: "fa fa-money",
					routerLink: ["orden-pago"],
					visible: this.MisOPSProveedorAG.esVisible(usuario)
				},
				{
					label: this.translateService.get("FINANCIERO_TITLE"),
					icon: "fa fa-money",
					visible:
						!this.proveedorExternoAG.esVisible(usuario) &&
						(this.facturasAG.esVisible(usuario) || this.ordenPagoAG.esVisible(usuario) || this.movimientoCajaAG.esVisible(usuario)),
					items: [
						{
							label: this.translateService.get("FACTURA_TITLE"),
							icon: "fa fa-barcode",
							routerLink: ["factura"],
							visible: this.facturasAG.esVisible(usuario)
						},
						{
							label: this.translateService.get("INSUMOS"),
							icon: "fa fa-tag",
							routerLink: ["insumo"],
							visible: this.facturasAG.esVisible(usuario)
						},
						{
							label: this.translateService.get("ORDEN-PAGO_TITLE"),
							icon: "fa fa-money",
							routerLink: ["orden-pago"],
							visible: this.ordenPagoAG.esVisible(usuario)
						},
						{
							label: this.translateService.get("MOVIMIENTOS_CAJA"),
							icon: "fa fa-exchange",
							routerLink: ["movimiento-caja"],
							visible: this.movimientoCajaAG.esVisible(usuario)
						}
					]
				},
				{
					label: this.translateService.get("Reportes"),
					icon: "fa fa-bar-chart",
					visible: this.reportesAG.esVisible(usuario) || this.consentimientoAG.esVisible(usuario) || this.jefeAdminAG.esVisible(usuario),
					items: [
						{
							label: this.translateService.get("Estadísticas de Ventas"),
							visible: this.reportesAG.esVisible(usuario),
							routerLink: ["reportes/ranking-productos"]
						},
						{
							label: this.translateService.get("Estadísticas de compras"),
							visible: this.reportesAG.esVisible(usuario),
							routerLink: ["reportes/ranking-compras"]
						},
						{
							label: this.translateService.get("Ingresos y egresos"),
							visible: this.reportesAG.esVisible(usuario),
							routerLink: ["reportes/ingresos-egresos"]
						},
						{
							label: this.translateService.get("Ventas"),
							visible: this.reporteVentasAG.esVisible(usuario),
							routerLink: ["reportes/ventas"]
						},
						{
							label: this.translateService.get("Compras"),
							visible: this.reporteComprasAG.esVisible(usuario),
							routerLink: ["reportes/compras"]
						},
						{
							label: this.translateService.get("TOTALES_FORMA_PAGO"),
							visible: this.reportesAG.esVisible(usuario),
							routerLink: ["reportes/totales-forma-pago"]
						},
						{
							label: this.translateService.get("Consentimientos"),
							visible: this.consentimientoAG.esVisible(usuario),
							routerLink: ["reportes/consentimiento"]
						},
						{
							label: this.translateService.get("PAXS_POR_SERVICIO"),
							visible: this.paxPorServiciosAG.esVisible(usuario),
							routerLink: ["reportes/servicios"]
						},
						{
							label: this.translateService.get("ESTADISTICAS_ACTIVIDADES"),
							visible: this.reportesAG.esVisible(usuario),
							routerLink: ["reportes/estadisticas-actividades"]
						},
						{
							label: this.translateService.get("Margenes de Reservas"),
							visible: this.reportesAG.esVisible(usuario),
							routerLink: ["reportes/margen-reporte"]
						},
						{
							label: this.translateService.get("Encuestas"),
							visible: this.reportesAG.esVisible(usuario),
							routerLink: ["reportes/encuestas"]
						},
						{
							label: this.translateService.get("PREVISION_RESERVAS"),
							visible: this.reportesAG.esVisible(usuario),
							routerLink: ["reportes/prevision-reservas"]
						},
						{
							label: this.translateService.get("LOGS"),
							visible: this.soprteAG.esVisible(usuario),
							routerLink: ["logs"]
						}
					]
				},
				{
					label: this.translateService.get("CERRAR_SESION"),
					visible: true,
					command: () => {
						this.authService.logout()
						this.router.navigate(["login"])
					},
					icon: "pi pi-sign-out "
				}
			]
		} else {
		}

		return items
	}
	public navegar = (path, query) => {
		this.router.navigate([path], { queryParams: query })
	}
	public init() {
		this.novedadService.registrarObservador("usuario", this)
		this.authService.onLogin.subscribe((u) => {
			const menu = this.generarMenu(this.authService.usuario)
			this.updateBS(menu)
		})
		this.translateService.upateData.subscribe((r) => {
			const menu = this.generarMenu(this.authService.usuario)
			this.updateBS(menu)
		})
	}
	private updateBS(menu: any[]) {
		this.menuBS.next(menu)
		this.rutasBS.next(
			menu.reduce(
				(flat, toFlat) => flat.concat(toFlat.items ? toFlat.items.filter((i) => !i.items && !i.skipRouting) : toFlat.skipRouting ? [] : [toFlat]),
				[]
			)
		)
	}
}
