<model-help [title]="'Tipo de Notificaciones'">
    <p>
        El sistema permite manejar distintos tipos de notificación destinadas a la comunicación entre los actores del rubro mediante distintos canales de emisión via email o Whatsapp (momentanemente
        no disponible).
    </p>
    <p>Las notificaciones pueden ser <help-link [linkFinal]="'job-configuration'" [textoLink]="'automatizadas'"></help-link> o manuales.</p>
    <p>
        El sistema trae por defecto varias notificaciones ya incorporadas y seteadas. Además el usuario puede crear sus propias notificaciones personalizadas.<br />
        Haciendo Click en <i class="pi pi-plus"></i> podemos generar una nueva.
    </p>
    <h3>Campos especiales</h3>
    <p>
        <strong>Canales:</strong> El canal de comunicación puede ser uno o más medios previamente cargados en la pantalla de
        <help-link [linkFinal]="'canales-comunicacion'" [textoLink]="'Canales de comunicación'"></help-link>.
    </p>
    <p>
        <strong>Destinatario default:</strong> Aquí podremos cargar uno o más email (separados por punto y coma ;) que tomará el sistema en caso de no existir un destinatario seteado en el momento de
        enviar la notificación. Generalmente este campo se usará para comunicaciones internas entre usuarios del sistema (ej: email de administracion).
    </p>
    <p>
        <strong>Modo prueba: </strong>Este check sirve para probar / testear la notificación. Al darle click se abre un campo de email para ingresar la casilla donde llegará la notificación de
        prueba.<br />
        Este email prevalecerá sobre todos los demás en este modo. (no será enviada la notificación a ninguna otra casilla hasta ser deshabilitada esta opción)
    </p>
    <p>
        <strong>Es recordatorio PAX: </strong>Este check habilita que la notificación aparezca como opción de recordatorio al PAX, cuando cargamos o modificamos un
        <help-link [linkFinal]="'producto'" [textoLink]="'Servicio'"></help-link>
    </p>

    <h3>Notificaciones incluidas por defecto en el sistema.</h3>

    <p>
        Las <strong>Notificaciones Manuales </strong> son las que requieren de una acción del usuario para ser enviadas.<br />
        En cambio las <strong>Notificaciones Automáticas </strong> son enviadas de manera automatizada, cada una de estas notificaciones puede ser activada o desactivada desde
        <help-link [linkFinal]="'job-configuration'" [textoLink]="'Automatizaciones'"></help-link> mediante el switch correspondiente.<br />
        (mediante el Job Id en el listado de automatizaciones, podremos identificar a que notificación corresponde cada uno)
    </p>
    <h4>Notificaciones Manuales</h4>
    <p-accordion>
        <p-accordionTab header="Recuperar password">
            <p><strong>Código:</strong> RPS</p>
            <p><strong>Template default:</strong> Recuperar contraseña (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p><strong>Emisión:</strong></p>
            <ul>
                <li>Al hacer clic en recuperar contraseña desde la gestión de usuario.</li>
                <li>Al hacer clic en recuperar contraseña en la pantalla de login.</li>
            </ul>
        </p-accordionTab>

        <p-accordionTab header="Envío de Factura de la Reserva">
            <p><strong>Código:</strong> EFR</p>
            <p><strong>Plantilla:</strong> Factura Electrónica. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p><strong>Emisión:</strong></p>
            <ul>
                <li>Al crear el comprobante desde reserva marcando “enviar comprobante por mail”.</li>
                <li>Al hacer clic en reenviar comprobante en la lista de reservas.</li>
            </ul>
        </p-accordionTab>

        <p-accordionTab header="Pickup Pasajero">
            <p><strong>Código:</strong> PICKUP</p>
            <p><strong>Plantilla:</strong> Pickup Pasajero. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p><strong>Emisión:</strong> Se configura en un servicio y se dispara automáticamente el día anterior a la Actividad que corresponde con el servicio.</p>
        </p-accordionTab>

        <!-- <p-accordionTab header="Itinerario para el Pax">
            <p><strong>Código:</strong> ITP</p>
            <p><strong>Plantilla:</strong> Itinerario para el Pax. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p><strong>Emisión:</strong> Manual al hacer clic en “enviar itinerario” desde el listado de reservas.</p>
        </p-accordionTab> -->

        <!-- <p-accordionTab header="Resumen Vouchers">
            <p><strong>Código:</strong> RVO</p>
            <p><strong>Plantilla:</strong> Resumen Vouchers. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p><strong>Emisión:</strong> Manual al hacer clic en “reenviar vouchers”.</p>
        </p-accordionTab> -->

        <p-accordionTab header="Estado de cuenta Cliente">
            <p><strong>Código:</strong> ECC</p>
            <p><strong>Plantilla:</strong> Estado de cuenta Cliente. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p><strong>Emisión:</strong> Manual desde gestor cliente “notificar deuda”.</p>
        </p-accordionTab>

        <p-accordionTab header="Pago Emitido">
            <p><strong>Código:</strong> PAGO</p>
            <p><strong>Plantilla:</strong> Pago Emitido. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p><strong>Emisión:</strong></p>
            <ul>
                <li>Manual desde Orden de pago -> notificar al proveedor.</li>
                <li>Cuenta corriente de proveedor -> notificar pago.</li>
                <li>Al crear la Operación y tildando “notificar proveedor”</li>
                <li>Al crear una factura con pago y tildando “notificar proveedor”</li>
            </ul>
        </p-accordionTab>

        <p-accordionTab header="Consentimiento">
            <p><strong>Código:</strong> CPX</p>
            <p><strong>Plantilla:</strong> Consentimiento e Itinerario. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p><strong>Emisión:</strong> Se envía manual al hacer clic en “Enviar itinerario desde reservas</p>
        </p-accordionTab>
    </p-accordion>

    <h4>Notificaciones Automáticas</h4>
    <p-accordion>
        <p-accordionTab header="Resumen Pickup para el hotel">
            <p><strong>Código:</strong> RH</p>
            <p><strong>Plantilla:</strong> Resumen Pickup para el hotel. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p><strong>Emisión:</strong> Se envía al hotel seleccionado en la reserva automáticamente un día antes de la actividad.</p>
        </p-accordionTab>

        <p-accordionTab header="Resumen de reservas para proveedores">
            <p><strong>Código:</strong> RP</p>
            <p><strong>Plantilla:</strong> Resumen de reservas para proveedores. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p>
                <strong>Emisión:</strong> Se envía al final del día a cada proveedor que tenga marca de “Recibe mail con confirmación de reserva” recordando todas las actividades Confirmadas o
                Pendientes de Confirmación que tenga el proveedor para el día siguiente.
            </p>
        </p-accordionTab>

        <p-accordionTab header="Itinerario para el guía">
            <p><strong>Código:</strong> ITG</p>
            <p><strong>Plantilla:</strong> Itinerario para el guía. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p>
                <strong>Emisión:</strong> Se envía al final del día a todos los proveedores con algún puesto que tenga la marca de “ Recibe OT” con todas las actividades que tenga asignadas para el
                día siguiente.
            </p>
        </p-accordionTab>

        <p-accordionTab header="Cancelación de Reserva para Proveedores">
            <p><strong>Código:</strong> ARSP</p>
            <p><strong>Plantilla:</strong> Cancelación de Reserva para Proveedores. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p>
                <strong>Emisión:</strong> Automático cuando un proveedor con puesto marcado con “Recibe confirmación de reserva” es desasignado, el grupo de la reserva se elimina o la reserva o
                actividad se cancelan.
            </p>
        </p-accordionTab>

        <p-accordionTab header="Contrato Consentimiento">
            <p><strong>Código:</strong> CONS_TEMP</p>
            <p><strong>Plantilla:</strong> Contrato Consentimiento. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p>
                <strong>Emisión:</strong> Este texto se muestra en la pantalla de Consentimiento. (Ayuda sobre
                <help-link [linkFinal]="'consentimiento-actividades'" [textoLink]="'Consentimientos'"></help-link>)
            </p>
        </p-accordionTab>

        <p-accordionTab header="Resumen del día">
            <p><strong>Código:</strong> RD</p>
            <p><strong>Plantilla:</strong> Resumen del día. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p><strong>Emisión:</strong> Se envía automáticamente al mail default configurado en el tipo de notificación al finalizar el día.</p>
        </p-accordionTab>

        <p-accordionTab header="Rechazo de servicio por proveedor">
            <p><strong>Código:</strong> REP</p>
            <p><strong>Plantilla:</strong> Rechazo de servicio por proveedor. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p>
                <strong>Emisión:</strong> Se envía automáticamente si un proveedor rechaza la asignación de una actividad mediante la Confirmación de reserva a proveedores (CRSP). Se envía al mail
                configurado Default en el tipo de notificación.
            </p>
        </p-accordionTab>

        <p-accordionTab header="Recordatorio Encuesta">
            <p><strong>Código:</strong> REN</p>
            <p><strong>Plantilla:</strong> Recordatorio Encuesta. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p>
                <strong>Emisión:</strong> Se envía automáticamente si el proceso automático “enviarRecordatorioEncuesta” está activo. (Ayuda sobre
                <help-link [linkFinal]="'job-configuration'" [textoLink]="'Automatizaciones'"></help-link>) <br />
                Se envía al final del día al contacto de la reserva un recordatorio de encuesta si: hay encuestas sin responder para alguna actividad y la ultima actividad de la reserva es ese mismo
                día. Siete días después se repite si hay encuestas sin responder.
            </p>
        </p-accordionTab>
    </p-accordion>

    <h4>Notificaciones que pueden ser Manuales o Automáticas</h4>
    <p-accordion>
        <p-accordionTab header="Confirmación de Reserva para Proveedores">
            <p><strong>Código:</strong> CRSP</p>
            <p><strong>Plantilla:</strong> Confirmación de Reserva para Proveedores. (Ayuda sobre <help-link [linkFinal]="'emailtemplate'" [textoLink]="'Plantillas'"></help-link>)</p>
            <p><strong>Emisión:</strong></p>
            <ul>
                <li>Manual desde listado de reserva -> notificar proveedor ( envía pendientes de confirmación ya notificados).</li>
                <li>Manual desde proveedor -> notificar proveedor (idem el de arriba).</li>
                <li>
                    Automático: al final del día se envía a todos los proveedores con algun puesto que tenga “Recibe mail de confirmación” marcado con el listado de todos los pendientes de
                    confirmación o pendientes de envio que NO hayan sido previamente notificados.
                </li>
            </ul>
        </p-accordionTab>
    </p-accordion>
</model-help>
