
import { PuestoServicioPrograma} from './PuestoServicioPrograma';
import { Vehiculo} from './Vehiculo';


export class ConfiguracionPrograma  {
    constructor(
		public id ?: number, 
		public puestos : PuestoServicioPrograma[] = [], 
		public vehiculoAsignado ?: Vehiculo, 

    ) {
    }
    public static fromData(data: any): ConfiguracionPrograma {
        if (!data) return null;
        const o: ConfiguracionPrograma = new ConfiguracionPrograma(
			data.id, 
			data.puestos? data.puestos.map(c => PuestoServicioPrograma.fromData(c)) : [], 
			Vehiculo.fromData(data.vehiculoAsignado), 

        );

        return o;

    }

}