import { lastValueFrom } from 'rxjs';
import { FiltroFecha } from './../common/model/FiltroFecha';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { IngresosEgresos } from '../pages/reportes/reporte-ingresos-egresos/IngresosEgresos';
@Injectable({
    providedIn: 'root',
  })
export class ReporteService extends BaseService{
    public baseName(): string {
        return "reportes"
    }

    public getIngresosEgresos(filtro: FiltroFecha = new FiltroFecha("ingresos-egresos")):Promise<IngresosEgresos[]>{
        return lastValueFrom(this.http.post(this.getBaseURL("ingresos-egresos"),filtro.json)).then((r:any)=>{
            return r.map(r=> IngresosEgresos.fromData(r));
        })
    }

}