export class ConfiguracionUsuario {
	public tipo: string = "ConfiguracionUsuarioVO"
	static themeDefault: any = { componentTheme: "iterpax", colorScheme: "light" }
	constructor(
		public id?: number,
		public opcionesLayout?: string,
		public defaults: any = {},
		public themeConfig: any = {},
		public filtros: any = {},
		public cols: any = {}
	) {}
	public static fromData(data: any): ConfiguracionUsuario {
		if (!data) return null
		const o: ConfiguracionUsuario = new ConfiguracionUsuario(data.id, data.opcionesLayout)
		if (data.themeConfig) {
			if (typeof data.themeConfig === "string") {
				try {
					o.themeConfig = JSON.parse(data.themeConfig) || {}
				} catch {
					o.themeConfig = ConfiguracionUsuario.themeDefault
				}
			} else {
				o.themeConfig = data.themeConfig || {}
			}
		} else o.themeConfig = ConfiguracionUsuario.themeDefault

		if (data.defaults) {
			if (typeof data.defaults === "string") {
				o.defaults = JSON.parse(data.defaults)
			} else {
				o.defaults = data.defaults
			}
		} else {
			o.defaults = {}
		}

		if (data.filtros) {
			if (typeof data.filtros === "string") {
				o.filtros = JSON.parse(data.filtros)
			} else {
				o.filtros = data.filtros
			}
		} else {
			o.filtros = {}
		}

		if (data.cols) {
			if (typeof data.cols === "string") {
				o.cols = JSON.parse(data.cols)
			} else {
				o.cols = data.cols
			}
		} else {
			o.cols = {}
		}

		return o
	}

	public get json() {
		return {
			id: this.id,
			tipo: this.tipo,
			opcionesLayout: this.opcionesLayout,
			themeConfig: JSON.stringify(this.themeConfig ? this.themeConfig : ConfiguracionUsuario.themeDefault),
			filtros: JSON.stringify(this.filtros ? this.filtros : null),
			cols: JSON.stringify(this.cols ? this.cols : null),
			defaults: this.defaults ? JSON.stringify(this.defaults) : null
		}
	}
}
