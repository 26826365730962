import { Component, OnDestroy, OnInit } from "@angular/core"
import { NavigationExtras, Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { CategoriaProductoService } from "src/app/services/categoria-producto.service"
import { PaisService } from "src/app/services/pais.service"
import { ProductoService } from "src/app/services/producto.service"
import { RegionService } from "src/app/services/region.service"
import { AccionesTabla } from "./../../../common/model/AccionesTabla"
import { LoadingService } from "./../../../common/services/loading-data-service.service"
import { TipoProductoService } from "./../../../services/tipo-producto.service"
import { FiltroProducto } from "./../FiltroProducto"
import { ProductoCabecera } from "./../ProductoCabecera"

@Component({
	selector: "listado-producto",
	templateUrl: "listado-producto.component.html",
	styleUrls: ["listado-producto.component.less"]
})
export class ListadoProductoComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: FiltroProducto
	public listado: ProductoCabecera[] = []
	public columns = []
	public acciones = []
	constructor(
		messagesService: MessagesService,
		public tipoProductoService: TipoProductoService,
		public categoriaService: CategoriaProductoService,
		private confService: ConfirmationService,
		private router: Router,
		public service: ProductoService,
		public regionService: RegionService,
		public paisService: PaisService
	) {
		super(messagesService)
	}
	public getData = (f: FiltroProducto, l: LoadingService) => {
		return f ? this.service.getCabeceras(f, l) : []
	}
	ngOnInit() {
		this.filtro = new FiltroProducto("producto_filtros_listado", 0, 100)
		this.columns = [
			{
				field: "codigo",
				header: "Codigo",
				class: "id-column",
				role: "text-tabla",
				sortable: true,
				mobile: true
			},
			{
				field: "peso",
				header: "Peso",
				class: "data-codigo text-center w-2rem",
				role: "text-tabla",
				sortable: true,
				mobile: false
			},
			{
				field: "imagenUrl",
				header: "",
				class: "profile-pic",
				role: "profile-tabla",
				sortable: false
			},
			{
				field: "tipoProducto",
				header: "Tipo Producto",
				width: "17%",
				role: "descriptivo-tabla",
				sortable: true,
				mobile: true
			},
			{
				field: "descripcion",
				header: "Nombre de Producto",
				role: "text-tabla",
				sortable: true,
				mobile: true
			},
			{
				field: "tipoVarianteStr",
				header: "Variante",
				role: "text-tabla",
				sortable: true,
				mobile: true
			},
			{
				field: "regiones",
				header: "REGION_PLR",
				role: "tags",
				sortable: false,
				mobile: true,
				width: "25em"
			},
			{
				field: "categorias",
				header: "Categorias",
				role: "tags",
				sortable: false,
				mobile: true,
				width: "25em"
			},
			{
				field: "habilitado",
				header: "Habilitado",
				class: "is-boolean",
				role: "boolean-tabla",
				sortable: false
			}
		]
		this.acciones = [
			new AccionesTabla(this.translateService.get("Editar"), "fa fa-pencil", "accion-tabla", (item) => {
				this.editar(item)
			}),

			new AccionesTabla(this.translateService.get("Eliminar"), "fa fa-trash", "accion-tabla", (item) => {
				this.eliminar(item)
			}),

			new AccionesTabla(this.translateService.get("Ver"), "fa fa-eye", "accion-tabla", (item) => {
				this.ver(item)
			}),
			new AccionesTabla(this.translateService.get("Clonar"), "fa fa-copy", "accion-tabla", (item) => {
				this.clonar(item)
			}),
			new AccionesTabla(this.translateService.get("Generar Publicado"), "fa fa-link", "accion-tabla", (item) => {
				this.generarPublicado(item)
			})
		]
	}
	ngOnDestroy() { }

	public eliminar(item: ProductoCabecera) {
		this.confService.confirm({
			key: "genConf",
			header: "Eliminar",
			message: "Va a eliminar el producto " + item.descripcion + ". Desea continuar?",
			accept: () => {
				let $this = this
				$this.service.eliminar(item.id).then((res) => {
					$this.filtro.forceUpdate()
					$this.success(item.descripcion + " fue eliminado.")
				})
			}
		})
	}

	public clonar(item: ProductoCabecera) {
		let query: NavigationExtras = {
			queryParams: {
				id: item.id,
				clonar: true
			}
		}
		this.router.navigate(["producto/clonar"], query)
	}

	public generarPublicado(item: ProductoCabecera) {
		let query: NavigationExtras = {
			queryParams: {
				productoId: item.id,
			}
		}
		this.router.navigate(["producto-publicado/autogenerar"], query)
	}

	public editar(item: ProductoCabecera) {
		let query: NavigationExtras = {
			queryParams: {
				id: item.id
			}
		}
		this.router.navigate(["producto/edit"], query)
	}

	public ver(item: ProductoCabecera) {
		let query: NavigationExtras = {
			queryParams: {
				id: item.id
			}
		}
		this.router.navigate(["producto/vista"], query)
	}
}
