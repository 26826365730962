import * as moment from "moment"
import { Evento } from "src/app/model/Evento"
import { SerieAccion } from "src/app/model/SerieAccion"
import { Descriptivo } from "../common/model/Descriptivo"

export class AccionAutomatica {
	static CLONAR_FACTURA = "CF"
	static CLONAR_COMPROBANTE = "CC"

	static parserToEvento = {
		CF: (acciones: AccionAutomatica[]) => {
			return acciones.map((a) => {
				const p = a.contexto.proveedor ? Descriptivo.fromData(JSON.parse(a.contexto.proveedor)) : null
				return new Evento(
					a.id,
					`Factura programada - ${p ? p.descripcion : ""}`,
					moment(a.fechaProgramada).format("YYYY-MM-DD"),
					null,
					{ tipo: Evento.DUPLICAR_FACTRA.codigo, props: a },
					"blue",
					"blue",
					"accion-duplicar-factura"
				)
			})
		},
		CC: (acciones: AccionAutomatica[]) => {
			return acciones.map((a) => {
				const p = a.contexto.cliente ? Descriptivo.fromData(JSON.parse(a.contexto.cliente)) : null
				return new Evento(
					a.id,
					`Comprobante programado - ${p ? p.descripcion : ""}`,
					moment(a.fechaProgramada).format("YYYY-MM-DD"),
					null,
					{ tipo: Evento.DUPLICAR_COMPROBANTE.codigo },
					"green",
					"green",
					"accion-duplicar-comprobante"
				)
			})
		},
		DEF: (acciones: AccionAutomatica[]) => {
			return acciones.map((a) => Evento.fromData(a))
		}
	}

	static getFactory(tipo: string): any {
		return this.parserToEvento[tipo] || this.parserToEvento["DEF"]
	}

	constructor(
		public id?: number,
		public fechaProgramada?: Date,
		public ejecutada?: boolean,
		public ejecutadoPor?: string,
		public errorEjecucion?: string,
		public logErrorEjecucion?: string,
		public contexto: any = {},
		public tipoAccion?: string,
		public serie?: SerieAccion,
		public idSerie?: number,
		public version?: number
	) {}
	public static fromData(data: any): AccionAutomatica {
		if (!data) return null
		const o: AccionAutomatica = new AccionAutomatica(
			data.id,
			data.fechaProgramada ? new Date(data.fechaProgramada) : null,
			data.ejecutada,
			data.ejecutadoPor,
			data.errorEjecucion,
			data.logErrorEjecucion,
			data.contexto || {},
			data.tipoAccion
		)

		o.idSerie = data.idSerie

		o.version = data.version
		return o
	}
}
