import { HttpClient, HttpErrorResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { BehaviorSubject, Observable, lastValueFrom, of } from "rxjs"
import { catchError, map, tap } from "rxjs/operators"
import { environment } from "src/environments/environment"
import { GlobalInjector } from "../common/GlobalInjector"
import { Usuario } from "../common/model/Usuario"
import { MessagesService } from "../common/services/messages-data-service.service"
import { TranslateService } from "./../common/services/translate.service"

@Injectable({
	providedIn: "root"
})
export class AuthService {
	protected http: HttpClient
	public interval
	private maxRefreshAttempts = 10
	private refreshAttempts = 0
	private isRefreshing = false
	private static _readyOb: BehaviorSubject<boolean> = new BehaviorSubject(false)
	public static AUTENTICANDO: string = "IP"
	public static LOGUEADO: string = "L"
	public static NO_LOGUEADO: string = "N"
	public static curUser: Usuario
	private authenticateState: BehaviorSubject<string> = new BehaviorSubject<string>(AuthService.AUTENTICANDO)
	public authenticateState$: Observable<string> = this.authenticateState.asObservable()
	private static userBS: BehaviorSubject<Usuario> = new BehaviorSubject(AuthService.curUser)
	private static _onLogout: BehaviorSubject<boolean> = new BehaviorSubject(false)
	private static _onLogin: BehaviorSubject<Usuario> = new BehaviorSubject(null)
	private refreshSource = new BehaviorSubject<Usuario | null>(null)
	public refreshAction$ = this.refreshSource.asObservable()
	public get onLogout() {
		return AuthService._onLogout
	}
	public get onLogin() {
		return AuthService._onLogin
	}
	public get readyOb() {
		return AuthService._readyOb
	}
	private static refreshing: BehaviorSubject<any>
	constructor(private router: Router, protected messages: MessagesService, protected transService: TranslateService) {
		this.http = GlobalInjector.InjectorInstance.get<HttpClient>(HttpClient)
	}
	getApiURL() {
		return environment.apiUrl
	}
	public getAutenticateState() {
		if (!this.authenticateState) this.authenticateState = new BehaviorSubject<string>(AuthService.AUTENTICANDO)
		return this.authenticateState
	}

	public get usuario(): Usuario {
		return AuthService.curUser
	}
	public set usuario(v: Usuario) {
		AuthService.curUser = v
		AuthService.userBS.next(v)
		this.authenticateState.next(v != null ? AuthService.LOGUEADO : AuthService.NO_LOGUEADO)

		this.username = v?.username
	}

	private _token: string
	public get token(): string {
		return localStorage.getItem(environment.tokenKey + "_token")
	}
	public set token(val: string) {
		if (val) {
			localStorage.setItem(environment.tokenKey + "_token", val)
		} else {
			localStorage.removeItem(environment.tokenKey + "_token")
			this.authenticateState.next(AuthService.NO_LOGUEADO)
		}
	}

	public set renewToken(val: string) {
		if (val) {
			localStorage.setItem(environment.tokenKey + "_renewToken", val)
		} else {
			localStorage.removeItem(environment.tokenKey + "_renewToken")
		}
	}
	public get renewToken() {
		return localStorage.getItem(environment.tokenKey + "_renewToken")
	}

	public set username(val: string) {
		if (val) {
			localStorage.setItem(environment.tokenKey + "_username", val)
		} else {
			localStorage.removeItem(environment.tokenKey + "_username")
		}
	}
	public get username() {
		return localStorage.getItem(environment.tokenKey + "_username")
	}

	public set push(val: string) {
		if (val) {
			localStorage.setItem(environment.tokenKey + "_push", val)
		} else {
			localStorage.removeItem(environment.tokenKey + "_push")
		}
	}
	public get push() {
		return localStorage.getItem(environment.tokenKey + "_push")
	}
	getUser(): Observable<Usuario> {
		return AuthService.userBS.asObservable()
	}

	protected handleOk = (response: any): string => {
		let res = response
		return res.mensaje
	}
	protected handleError(error: any): Promise<any> {
		return Promise.reject(error.error || error)
	}
	recuperarPass(email): Promise<any> {
		let $this = this
		return lastValueFrom(this.http.post(this.getApiURL() + "login/recuperar-password", email))
			.then((r: any) => {
				return Promise.resolve(r ? Usuario.fromData(r) : null)
			})
			.catch(this.messages.errorHandler)
	}
	private storeTokens(r: any) {
		this.token = r.token
		if (r.renewToken) {
			this.renewToken = r.renewToken
		}
		this.usuario = Usuario.fromData(r.usuario)
		this.authenticateState.next(r.token ? AuthService.LOGUEADO : AuthService.NO_LOGUEADO)
		return this.token
	}
	async login(user: string, password: string): Promise<Usuario> {
		let $this = this
		return lastValueFrom(this.http.post(this.getApiURL() + "login", { username: user, password: password }).pipe(tap((r) => this.storeTokens(r))))
			.then((r: any) => {
				if (r) {
					this.onLogin.next(this.usuario)
					return Promise.resolve($this.usuario)
				}
				return Promise.resolve(null)
			})
			.catch(this.messages.errorHandler)
	}

	async logout(returnUrl?: string): Promise<any> {
		this.token = null
		this.push = null
		this.usuario = null
		this.username = null
		AuthService.curUser = null
		this.usuario = null
		this.renewToken = null
		if (this.interval) {
			clearInterval(this.interval)
		}
		this.onLogout.next(true)
		if (returnUrl) {
			this.router.navigate(["login"], { queryParams: { returnUrl: returnUrl } })
		} else {
			this.router.navigate(["login"])
		}

		AuthService.refreshing = null
		return Promise.resolve(true)
	}

	public fetchUserData(): Observable<Usuario> {
		if (!this.token) return of(null)
		return this.http.get<Usuario>(this.getApiURL() + "getUser").pipe(
			map((user) => {
				this.usuario = user ? Usuario.fromData(user) : null
				return this.usuario
			}),
			catchError((err) => {
				this.usuario = null
				return of(null)
			})
		)
	}
	public refreshToken(andLogin: boolean = false, callbackUrl?: string): Observable<any> {
		let $this = this
		if (this.isRefreshing) {
			return this.refreshAction$
		}

		this.isRefreshing = true

		return this.http.post(this.getApiURL() + "reauthenticate", { username: this.username, renewToken: this.renewToken }).pipe(
			tap(this.storeTokens.bind(this)),
			catchError((error: HttpErrorResponse) => {
				$this.isRefreshing = false
				$this.refreshAttempts++
				if (this.refreshAttempts >= this.maxRefreshAttempts) {
					$this.logout()
					return this.messages.errorHandler(error)
				}
				return this.messages.errorHandler(error)
			}),
			tap(() => {
				$this.isRefreshing = false
				$this.refreshSource.next(null) // Limpiar el BehaviorSubject
			})
		)
	}

	get isLoggedIn(): boolean {
		return this.usuario != undefined && this.token != undefined
	}
	get getCurrentUser(): Usuario {
		return this.usuario
	}
	get esAdministrador() {
		return this.getCurrentUser && this.getCurrentUser.roles.some((r) => r.codigo === "ROLE_ADMIN")
	}
	get esJefeAdministracion() {
		return this.getCurrentUser && (this.esAdministrador || this.getCurrentUser.roles.some((r) => r.codigo === "ROLE_JEFE_ADMIN"))
	}
	public tieneRol(rol: string) {
		return this.getCurrentUser && this.getCurrentUser.tieneRol(["ROLE_ADMIN", rol])
	}
	get esSoloVisualizador() {
		return this.usuario && this.usuario.esSoloVisualizador()
	}

	getUserFullName(): string {
		let user = this.getCurrentUser ? this.getCurrentUser : null
		return user?.username || "SIN INFORMAR"
	}

	get esClienteExterno() {
		return this.getCurrentUser && this.getCurrentUser.roles.some((r) => r.codigo === "ROLE_CLIENTE")
	}
}
