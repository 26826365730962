import { VehiculoService } from 'src/app/services/vehiculo.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { BloqueoVehiculo } from "src/app/model/BloqueoVehiculo";
import { BloqueoVehiculoService } from 'src/app/services/bloqueo-vehiculo.service';
import { SessionComponent } from './../../../common/components/session-component.component';




@Component({
	selector: 'gestor-bloqueo-vehiculo',
	templateUrl: 'gestor-bloqueo-vehiculo.component.html',
	styleUrls: ['gestor-bloqueo-vehiculo.component.less',]
})

export class GestorBloqueoVehiculoComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription;
	@Input()
	public item: BloqueoVehiculo;
	@Input()
	public goBack: boolean = true;
	@Input()
	public isModal: boolean = false;
	@Input()
	public persist: boolean = true;
	@Input()
	public readonly: boolean = false;

	@Output()
	public onGuardado: EventEmitter<BloqueoVehiculo> = new EventEmitter<BloqueoVehiculo>();

	@Output()
	public onCancelado = new EventEmitter();

	public usuarioOptions: Descriptivo[] = [];

	@Input()
	public hideTitle: boolean = false;


	constructor(messagesService: MessagesService,
		public service: BloqueoVehiculoService,
		public vehiculoService:VehiculoService,
		private route: ActivatedRoute,
		private router: Router,
		private confService: ConfirmationService,

	) {
		super(messagesService);
	}

	public handleGuardado(item:BloqueoVehiculo) {
		this.vehiculoService.updateById(item.vehiculo.id).then(r=>{
			this.onGuardado.emit(item);
		});
		
	}
	public handleCancelar(item) {
		this.onCancelado.emit();
	}
	@Input()
	public isValid = async () => {
		if (this.item.fechaHasta && moment(this.item.fechaHasta).isBefore(this.item.fechaDesde)) {
			return Promise.resolve(this.error("Fecha hasta es menor a desde"));
		}
		return this.service.verficarFechas(this.item).then(r => {

			return r ? r : this.error("Existen actividades para las fechas seleccionadas. No se puede bloquear hasta que se muevan.");
		});
	}

	public onEdit(event) {
		if (this.isModal) {
			this.readonly = false;
		} else if (this.item?.id) {
			this.router.navigate(["'bloqueo-vehiculo/edit"], { queryParams: { id: this.item.id } });
		}

	}
	public delete(event?) {
		event.stopPropagation();
		this.confService.confirm({
			key: 'genConf',
			header: "Desbloqueado",
			message: "Está desbloqueando el vehiculo " + this.item.vehiculo?.descripcion + ". Desea continuar?",
			accept: () => {
				let $this = this;
				$this.service.eliminar(this.item.id).then((res) => {
					$this.success("Bloqueo levantado");
					this.vehiculoService.updateById(this.item.vehiculo.id).then(r=>{
						this.onGuardado.emit(this.item);
					});
				});

			}
		});
	}

	ngOnInit() {
		this.subs.push(this.route.data.subscribe(u => {
			if (u?.vista) {
				this.readonly = true;
			}
		}));
		if (this.route.snapshot.url.some(u => u.path == 'bloqueo-vehiculo')) {
			this.routeSub = this.route.queryParams.subscribe(params => {
				let id: number = <number>params['id'];
				if (!this.service) return;
				if (id) {
					this.service.getById(id, null, this.translateService.idioma ? this.translateService.idioma : 'ES').then(r => {
						this.item = r;
					})
				} else {
					if (!this.item && this.service) {
						this.item = this.service.newEnt();
					}
					this.item["codigoIdioma"] = this.translateService.idioma ? this.translateService.idioma : 'ES';
				}

			});
		}
	}

	public get esEditable() {
		return this.esAdministrador;
	}


	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe();
	}
}