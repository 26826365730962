import { Producto } from './Producto';
import * as moment from 'moment'
import { Descriptivo } from 'src/app/common/model/Descriptivo'

export class CronogramaProducto {
    constructor(public id?: number, public producto?: Producto, public dia?: number, public hora?: Date, public autoasignar?: boolean) {
      this._horaActividad = hora? new Date(hora): moment().set('hour',8,).set('minute',0).toDate();

    }
    public static fromData(data: any): CronogramaProducto {
        if (!data) return null
        const o: CronogramaProducto = new CronogramaProducto(data.id, Producto.fromData(data.producto), data.dia, data.hora ? new Date(data.hora) : null)
        o.autoasignar = data.autoasignar
        return o
    }

    private _horaActividad: Date
    public get horaActividad(): Date {
        return this._horaActividad
    }
    public set horaActividad(v: Date) {
        this._horaActividad = moment(v).toDate();

        if (v) {           
            this.hora = moment(v).toDate();
        }
    }
}
