<ng-container *ngTemplateOutlet="previousContent"> </ng-container>
<div class="model-list {{ styleClass }}">
	<div class="top-section grid">
		<div class="col-12 model-title" *ngIf="!hideHeader">
			<ng-container *ngTemplateOutlet="titleContent ? titleContent : defaultTitleContent; context: this"> </ng-container>
			<ng-template #defaultTitleContent>
				<div class="grid">
					<button
						pButton
						*ngIf="hasHelp() && isMobile()"
						tooltipPosition="bottom"
						pTooltip="{{ 'AYUDA' | translate }}"
						class="p-ripple p-button-text fixed-mobile"
						icon="fa fa-question"
						(click)="goToHelp()"
					></button>
					<div class="col-12 lg:col-4" *ngIf="!hideTitle">
						<h2 autofocus>{{ finalTitle }}</h2>
					</div>
					<div class="col text-right filter-container">
						<div class="grid align-content-center justify-content-end">
							<div class="col-fixed actions">
								<ng-container *ngTemplateOutlet="headerActionsContent || defaultHeaderActions"></ng-container>
							</div>
							<div class="flex" [ngClass]="{ 'col-12': isMobile(), 'col-fixed': !isMobile() }">
								<div class="p-inputgroup" *ngIf="filtro">
									<input
										[(ngModel)]="filtro.searchStr"
										pInputText
										placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}"
										[(ngModel)]="filtro.searchStr"
									/>
									<!--input
                                        *ngIf="!isLazy"
                                        (input)="table.filterGlobal($event.target.value.toUpperCase(), 'contains')"
                                        pInputText
                                        placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}"
                                        [(ngModel)]="filtro.searchStr"
                                    /-->
									<button
										pButton
										pTooltip="{{ filtro?.updateAvailable ? ('Hay actualizaciones disponibles' | translate) : '' }}"
										tooltipPosition="bottom"
										icon="pi pi-refresh"
										class="p-button-sm  {{ filtro?.updateAvailable ? 'p-button-warning' : 'p-button-outlined' }}"
										(click)="filtro.forceUpdate(); $event.stopPropagation()"
									></button>
									<button
										*ngIf="filterContent"
										pButton
										icon="pi pi-filter"
										class="p-button-sm"
										[ngClass]="{ 'p-button-outlined': !filtro?.hasFilters() }"
										(click)="showFilterbar($event)"
									></button>
								</div>

								<selector-idioma [(ngModel)]="idiomaSeleccionado" name="idioma" *ngIf="multilenguaje" class="mr-2"></selector-idioma>
								<layout-picker
									[(ngModel)]="filtro.layout"
									class="layout-switch"
									name="layout"
									*ngIf="allowLayoutSwitch && !isMobile() && filtro"
								></layout-picker>
							</div>

							<div class="col-fixed actions" *ngIf="hasHelp(modelName) && !isMobile()">
								<button
									pButton
									tooltipPosition="bottom"
									pTooltip="{{ 'AYUDA' | translate }}"
									class="p-button-primary"
									icon="fa fa-question"
									(click)="goToHelp(modelName)"
								></button>
							</div>
						</div>
					</div>
					<div class="col-12 filter-summary" *ngIf="filtro?.summary?.length" (click)="filtro && showFilterbar($event)">
						<button
							*ngIf="filterContent"
							pButton
							icon="pi pi-trash"
							class="p-button-sm clean-filters"
							style="right: 2px"
							(click)="filtro.clean(true); $event.stopPropagation()"
						></button>
						<div class="grid align-content-center">
							<div class="col-fixed">{{ "RESÚMEN_DE_FILTROS" | translate }}:</div>
							<div class="col-fixed" *ngFor="let item of filtro?.summary">
								<span
									>{{ item?.titulo }} <strong tooltipPosition="bottom" pTooltip="{{ item?.valor }}">{{ item?.valor }}</strong></span
								>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
			<ng-template #defaultHeaderActions>
				<button
					*ngIf="permiteNuevo"
					pButton
					tooltipPosition="bottom"
					pTooltip="{{ 'NUEVO' | translate }}"
					class="p-button-primary"
					icon="pi pi-plus"
					(click)="nuevo()"
				></button>
				<button
					*ngIf="permiteCargaMasiva"
					pButton
					class="p-button-outlined p-button-icon-only ml-3"
					tooltipPosition="bottom"
					icon="pi pi-upload"
					pTooltip="{{ 'CARGA_MASIVA' | translate }}"
					(click)="inputFile.click()"
				></button>
			</ng-template>
		</div>
	</div>
	<div class="bottom-section grid" [ngClass]="{ 'grid-nogutter': isMobile() }">
		<div class="col-12 {{ dataClass }}" *ngIf="filtro">
			<ng-container *ngTemplateOutlet="getTemplateContent(); context: this"> </ng-container>
			<ng-template #defaultMobileDataContent>
				<p-dataView
					*ngIf="filtro"
					[value]="listado"
					[lazy]="isLazy"
					(onLazyLoad)="loadData($event, filtro)"
					[paginator]="isPaginator"
					[rowsPerPageOptions]="[5, 10, 20, 50, 100]"
					[emptyMessage]="emptymessage"
					[rows]="filtro?.size ? filtro.size : 20"
					[totalRecords]="totalRecords"
					[first]="getFirstPage()"
					paginatorDropdownAppendTo="body"
					dataKey="id"
				>
					<ng-template let-data pTemplate="listItem">
						<ng-container
							*ngTemplateOutlet="itemDataViewTemplate ? itemDataViewTemplate : defaultItemDataviewTemplate; context: { $implicit: data }"
						>
						</ng-container>
					</ng-template>
				</p-dataView>
				<button
					pButton
					class="floating"
					icon="fa fa-bars"
					[@floatIn]
					(click)="showAcciones($event, menuAcciones ? menuAcciones : menuAccionesDefault)"
					*ngIf="seleccion?.length"
				></button>
			</ng-template>
			<ng-template #defaultDesktopDataContent>
				<p-table
					*ngIf="filtro"
					#table
					[globalFilterFields]="['id', 'searchStr']"
					[value]="listado"
					[paginator]="isPaginator"
					[selectionMode]="modoSeleccion"
					[resizableColumns]="true"
					(onFilter)="updateCount($event)"
					[columns]="columnsVisibles"
					[scrollable]="true"
					scrollHeight="flex"
					[lazy]="isLazy"
					[sortField]="filtro.sortField"
					[sortOrder]="filtro.sortOrder"
					(onLazyLoad)="loadData($event, filtro)"
					[loading]="loading"
					[rowsPerPageOptions]="[10, 20, 50, 100]"
					(onHeaderCheckboxToggle)="rowSelectUnselect($event)"
					[rows]="getPageSize(filtro)"
					[totalRecords]="totalRecords"
					[reorderableColumns]="true"
					[(selection)]="seleccion"
					dataKey="id"
					(onRowSelect)="rowSelectUnselect($event)"
					(onRowUnselect)="rowSelectUnselect($event)"
					[first]="getFirstPage()"
					paginatorDropdownAppendTo="body"
					(onColReorder)="saveColumns($event)"
				>
					<ng-template pTemplate="caption" *ngIf="caption">
						<div *ngIf="caption">
							<ng-container *ngTemplateOutlet="caption; context: { $implicit: table }"> </ng-container>
						</div>
					</ng-template>
					<ng-template pTemplate="emptymessage" *ngIf="emptymessage">
						<tr>
							<td [attr.colspan]="columnasLen" class="emptymessage text-center">
								<h3 class="title">
									<b>{{ emptymessage }}</b>
								</h3>
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="header" let-data>
						<ng-container *ngTemplateOutlet="header"> </ng-container>
						<tr>
							<th *ngIf="modoSeleccion == 'multiple'" style="width: 3em; text-overflow: clip" class="print-hidden">
								<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
							</th>
							<th
								*ngFor="let col of columnsVisibles"
								pReorderableColumn
								[ngStyle]="{ width: col.width }"
								class="{{ col.class }}"
								pResizableColumn
								tooltipPosition="bottom"
								pTooltip="{{ col.tooltip || col.header | translate }}"
								[pSortableColumn]="col.sortable ? col.field + (col.role == 'descriptivo-tabla' ? '.descripcion' : '') : null"
							>
								<p-sortIcon
									[field]="col.field + (col.role == 'descriptivo-tabla' ? '.descripcion' : '')"
									*ngIf="col.sortable"
									class=""
								></p-sortIcon>
								{{ col.header | translate }}
							</th>
							<th
								style="text-align: center; width: 10em; text-overflow: unset"
								*ngIf="(editable || borrable || conResumen) && !readonly"
								class="print-hidden"
							>
								<button
									*ngIf="modoSeleccion == 'multiple'"
									pButton
									[disabled]="!seleccion?.length"
									[icon]="'fa fa-cogs'"
									class="p-button-text"
									(click)="showAcciones($event, menuAcciones ? menuAcciones : menuAccionesDefault)"
								></button>
								<button
									type="button"
									*ngIf="download"
									pButton
									icon="pi pi-file-excel"
									tooltipPosition="bottom"
									pTooltip="Descargar a excel"
									iconPos="left"
									(click)="exportExcel()"
								></button>
							</th>
						</tr>
						<ng-container *ngTemplateOutlet="subheader"> </ng-container>
					</ng-template>
					<ng-template pTemplate="body" let-data>
						<tr
							[pSelectableRow]="data"
							(click)="onClick(data, $event)"
							(dblclick)="ver(data['id'])"
							class="{{ data['rowClass'] ? data['rowClass'] : '' }}"
						>
							<td
								*ngIf="modoSeleccion == 'multiple'"
								class="table-checkbox text-center estado print-hidden {{ data?.estado?.codigo }}"
								[ngStyle]="{ 'background-color': data?.estado?.color }"
								tooltipPosition="bottom"
								pTooltip="{{ data?.estado?.descripcion }}"
							>
								<p-tableCheckbox [value]="data"></p-tableCheckbox>
							</td>
							<td
								*ngFor="let col of columnsVisibles"
								[ngStyle]="{ width: col.width, position: 'relative' }"
								class="p-resizable-column {{ col.classFn ? col.classFn(data) : col.class }}"
							>
								<ng-container *ngTemplateOutlet="dataField; context: { $implicit: data, col: col }"> </ng-container>
							</td>

							<td class="p-resizable-column print-hidden" *ngIf="(editable || borrable || conResumen) && !readonly">
								<div class="acciones-tabla" *ngIf="data.acciones?.length < 4 && !readonly">
									<button
										pButton
										*ngFor="let accion of acciones | visibles : data"
										[icon]="accion.icon"
										(click)="accion.command(data, $event); $event.stopPropagation()"
										[tooltipPosition]="'bottom'"
										[pTooltip]="accion.tooltip | translate"
										[class]="'accion-tabla p-button-text ' + accion.styleClass"
									></button>
								</div>

								<div class="acciones-tabla" *ngIf="data.acciones?.length >= 4 && !readonly">
									<button
										pButton
										icon="fa fa-cog"
										(click)="mostrarMenu($event, data); $event.stopPropagation()"
										tooltipPosition="bottom"
										pTooltip="{{ 'MENÚ_DE_ACCIONES' | translate }}"
										class="accion-tabla p-button-text"
									></button>
								</div>
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="footer" let-data>
						<ng-container *ngTemplateOutlet="footer"> </ng-container>
					</ng-template>
				</p-table>
			</ng-template>
		</div>
	</div>
</div>
<p-menu #menu [model]="items" [popup]="true" [appendTo]="'body'"></p-menu>

<ng-template #estadoCol let-data>
	<div
		class=" text-center {{ modoSeleccion != 'multiple' && data.estado ? 'estado-container ' + data?.estado?.codigo : '' }}"
		tooltipPosition="bottom"
		pTooltip="{{ data?.estado?.descripcion }}"
		[ngStyle]="{ 'border-left-color': modoSeleccion != 'multiple' && data.estado ? data.estado.color : '' }"
	>
		<span class="estado">{{ data?.id }}</span>
	</div>
</ng-template>
<ng-template #dataField let-data let-col="col">
	<profile-badge
		[url]="data[col.field]?.picPathVersion || data[col.field]"
		class="small-badge circular desktop-badge"
		*ngIf="col.role == 'profile-tabla'"
		(click)="toggleSelection(data)"
	>
	</profile-badge>
	<div class="text-tabla overflow" *ngIf="col.role == 'text-tabla'" [ngStyle]="{ width: col.width || '100%' }">
		<span class="overflow oneline" tooltipPosition="bottom" pTooltip="{{ data[col.field] | translate }}">{{
			buscarTraduccion ? (data[col.field] | translate) : data[col.field]
		}}</span>
	</div>
	<input
		type="text"
		*ngIf="col.role == 'text-editable-tabla' && (!col.editable || col.editable(data))"
		[(ngModel)]="data[col.field]"
		(click)="$event.stopPropagation()"
		(blur)="updateRow(data)"
	/>

	<span *ngIf="col.role == 'number-tabla'" class="overflow oneline text-center" tooltipPosition="bottom" pTooltip="{{ data[col.field] }}">{{
		data[col.field]
	}}</span>

	<span *ngIf="col.role == 'descriptivo-tabla'" class="overflow oneline" tooltipPosition="bottom" pTooltip="{{ data[col.field]?.descripcion }}">{{
		buscarTraduccion ? (data[col.field]?.descripcion | translate) : data[col.field]?.descripcion
	}}</span>
	<span *ngIf="col.role == 'codigo-tabla'" class="overflow oneline" tooltipPosition="bottom" pTooltip="{{ data[col.field]?.descripcion }}">{{
		data[col.field]?.codigo
	}}</span>
	<span *ngIf="col.role == 'color-tabla'" class="overflow color" [ngStyle]="{ 'background-color': data[col.field] }"></span>

	<i *ngIf="col.role == 'boolean-tabla'" class="fa {{ data[col.field] == true ? 'fa-check succes ' : 'fa-ban error' }}"></i>
	<span *ngIf="col.role == 'habilitable-tabla'" class="block w-100 p-1 text-center clickable" (click)="col.command($event, data)">
		<i class="fa {{ data[col.field] == true ? 'fa-check succes ' : 'fa-ban error' }} accion"></i>
	</span>

	<i *ngIf="col.role == 'icon-tabla'" class="{{ data[col.field] }}"></i>
	<a
		class="overflow oneline"
		target="_blank"
		href="mailto:{{ data[col.field] }}"
		title="data[col.field]"
		tooltipPosition="bottom"
		pTooltip="{{ data[col.field] }}"
		*ngIf="col.role == 'email-tabla'"
		(click)="$event.stopPropagation()"
		>{{ data[col.field] }}</a
	>
	<span class="color" [ngStyle]="{ 'background-color': data[col.field] }" *ngIf="col.role == 'color-tabla'"></span>
	<span *ngIf="col.role == 'date-tabla'" class="overflow">{{ data[col.field] | date : "dd/MM/yyyy" }}</span>
	<span *ngIf="col.role == 'hora-tabla'" class="overflow">{{ data[col.field] | date : "HH:mm" }}</span>
	<span *ngIf="col.role == 'date-completa-tabla'" class="overflow">{{ data[col.field] | date : "dd/MM/yyyy HH:mm:ss" }}</span>

	<a
		class="overflow oneline"
		href="{{ data[col.href] }}"
		tooltipPosition="bottom"
		pTooltip="{{ col?.tooltip ? col.tooltip : ('NAVEGAR' | translate) }}"
		*ngIf="col.role == 'link-tabla' && data[col.href]"
		>{{ data[col.field] }}</a
	>
	<span *ngIf="col.role == 'switch-tabla'" class="text-center">
		<p-inputSwitch [(ngModel)]="data[col.field]" (onChange)="switchHandler($event, col, data)"></p-inputSwitch>
	</span>
	<span
		class="clickable"
		(click)="col.navegar(data); $event.stopPropagation()"
		tooltipPosition="bottom"
		pTooltip="{{ col?.tooltip ? col.tooltip : ('NAVEGAR' | translate) }}"
		*ngIf="col.role == 'link-tabla' && !data[col.href]"
		>{{ data[col.field] }}</span
	>
	<ng-container *ngIf="col.role == 'contactos'">
		<ng-container *ngTemplateOutlet="contactos; context: { $implicit: data, col: col }"> </ng-container>
	</ng-container>
	<ng-container *ngIf="col.role == 'tags'">
		<div class="flex p-jc-left flex-wrap">
			<span class="chip m-1" *ngFor="let tag of data[col.field]">{{ tag?.descripcion }}</span>
		</div>
	</ng-container>
	<ng-container *ngIf="col.role == 'template'">
		<ng-container *ngTemplateOutlet="col.template; context: { $implicit: data, col: col }"> </ng-container>
	</ng-container>

	<ng-container *ngIf="col.role == 'money-tabla'">
		<ng-container *ngTemplateOutlet="dineroTemplate; context: { $implicit: data, col: col }"> </ng-container>
	</ng-container>
	<ng-container *ngIf="col.role == 'donwload-tabla'">
		<a href="data[col.field]" target="_blank"><i class="pi pi-dowload"></i></a>
	</ng-container>

	<ng-container *ngIf="col.role == 'estado'">
		<ng-container *ngTemplateOutlet="estadoCol; context: { $implicit: data, col: col }"> </ng-container>
	</ng-container>
</ng-template>
<div class="loading" *ngIf="loadingVisible" [@inOutAnimation]>
	<span>{{ mensajeLoading }}</span>
</div>

<ng-template #contactos let-data let-col="col">
	<contactos [data]="data"></contactos>
</ng-template>
<ng-template #dineroTemplate let-data let-col="col">
	<span class="overflow text-right">{{ data[col.field] | currencyCustom : data[col.monedaField || "moneda"] }}</span>
</ng-template>
<ng-template #defaultItemDataviewTemplate let-data>
	<div class="{{ dataViewGridStyle }}" style="padding: 4px">
		<div class="card animal" (click)="onClick(data, $event)">
			<div class="grid">
				<div
					class="col profile"
					style="width: 20vw; max-width: 30%"
					*ngIf="data['imagen']"
					(click)="toggleSelection(data, $event)"
					[ngStyle]="{ 'background-image': 'url(' + (data['imagen']?.picPathVersion ? data['imagen'].picPathVersion : data['imagen']) + ')' }"
				></div>
				<div
					class="col profile"
					style="width: 20vw; max-width: 20%"
					*ngIf="!data['descripcion'] && data['codigo'] && !data['imagen']"
					(click)="toggleSelection(data, $event)"
				>
					<span class="id" *ngIf="!data['imagen'] && (data['id'] || data['codigo'])"># {{ data["codigo"] ? data["codigo"] : data["id"] }}</span>
				</div>
				<div class="col data-section">
					<div class="grid" [ngClass]="{ 'grid-nogutter': isMobile() }">
						<div
							class="col-12 header {{ data?.estado?.codigo ? data?.estado?.codigo + ' estado' : '' }}"
							*ngIf="data['descripcion']"
							tooltipPosition="bottom"
							pTooltip="{{ data?.estado?.descripcion }}"
						>
							<div class="grid">
								<div class="col header-wrapper">
									<h3>{{ data["descripcion"] }}</h3>
								</div>
								<div
									class="col-fixed text-right id-wrapper"
									style="width: 6em"
									[ngStyle]="{ 'background-color': data.estado?.color, color: getTextColor(data?.estado?.color) }"
									*ngIf="data['id'] || data['codigo']"
								>
									<span class="id">{{ data["codigo"] ? data["codigo"] : data["id"] }}</span>
								</div>
							</div>
						</div>
						<div class="col-12 info">
							<div class="grid align-content-center">
								<div class="col-12 campo {{ campo['class'] }}'" *ngFor="let campo of camposMobile">
									<ng-container *ngTemplateOutlet="dataField; context: { $implicit: data, col: campo }"> </ng-container>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 acciones" *ngIf="data.acciones?.length >= 6 && !readonly">
					<button
						pButton
						icon="fa fa-cog"
						(click)="mostrarMenu($event, data); $event.stopPropagation()"
						tooltipPosition="bottom"
						pTooltip="{{ 'MENÚ_DE_ACCIONES' | translate }}"
						class="p-button-text p-button-tercery p-ripple"
					></button>
				</div>
				<div class="col-12 acciones" *ngIf="data.acciones?.length < 6 && !readonly">
					<button
						*ngFor="let accion of data.acciones"
						pButton
						class="p-button-text p-button-tercery p-ripple"
						(click)="accion.command(data); $event.stopPropagation()"
						tooltipPosition="bottom"
						pTooltip="{{ accion.tooltip | translate }}"
						icon="{{ accion.icon }}"
					></button>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<p-menu #menuAccionesDefault [popup]="true" [model]="menuItemAcciones" appendTo="body"></p-menu>
<p-sidebar [(visible)]="displayFilterBar" #filterBar position="right" [style]="{ width: isMobile() ? '100vw' : '30vw' }">
	<filtro [columns]="columns" [filter]="filtro" [filterTemplateContent]="filterContent"></filtro>
</p-sidebar>

<p-dialog *ngIf="mostrarAuditar && selectedItem" header="Auditoría" [(visible)]="mostrarAuditar" [modal]="true" closable="false" [style]="{ width: '50vw' }">
	<div class="grid">
		<div class="col-12">
			<span class="overflow oneline">{{ "CREADO_POR" | translate }}</span>
			<input type="text" disabled [ngModel]="selectedItem.createdBy" />

			<span class="overflow oneline">{{ "FECHA_CREACION" | translate }}</span>
			<input type="text" disabled [ngModel]="selectedItem.createdDate" />
		</div>
		<div class="col-12">
			<span class="overflow oneline">{{ "MODIFICADO_POR" | translate }}</span>
			<input type="text" disabled [ngModel]="selectedItem.lastModifiedBy" />

			<span class="overflow oneline">{{ "FECHA_MODIFICACION" | translate }}</span>
			<input type="text" disabled [ngModel]="selectedItem.lastModifiedDate" />
		</div>
	</div>
	<p-footer>
		<button pButton class="boton" (click)="mostrarAuditar = false; this.selectedItem = null" label="Cerrar"></button>
	</p-footer>
</p-dialog>

<input type="file" (change)="handleFileInput($event)" #inputFile name="inputfile" [hidden]="true" [accept]="acceptedTypes" />
