<div class="reporte-prevision-servicios content">
	<div class="top-section grid" *ngIf="!isHome">
		<div class="col-12 model-title custom">
			<div class="grid">
				<div class="col-8">
					<h2 autofocus>{{ "PREVISION_RESERVAS" | translate }}</h2>
				</div>
				<div class="col-4">
					<div class="flex justify-content-end help-button">
						<button
							pButton
							*ngIf="!isHome"
							tooltipPosition="bottom"
							pTooltip="{{ 'AYUDA' | translate }}"
							class="p-element p-button-primary p-button p-component p-button-icon-only"
							icon="fa fa-question"
							(click)="goToHelp()"
						></button>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 model-filters" *ngIf="filtro">
			<filtro [(filter)]="filtro" [columns]="columns" [useSidebar]="true" [isFixed]="true" [filterTemplateContent]="filterTemplate"> </filtro>
			<ng-template #filterTemplate>
				<div class="grid">
					<div class="col-6 lg:col-2">
						<span class="p-float-label p-fluid">
							<p-calendar [(ngModel)]="filtro.fecha" name="fechaDesde" dateFormat="dd/mm/yy" appendTo="body" [showIcon]="true"></p-calendar>
							<label for="">{{ "FECHADESDE" | translate }}</label>
						</span>
					</div>
					<div class="col-6 lg:col-2">
						<span class="p-float-label p-fluid">
							<p-calendar [(ngModel)]="filtro.fechaHasta" name="fechaHasta" dateFormat="dd/mm/yy" appendTo="body" [showIcon]="true"></p-calendar>
							<label for="">{{ "HASTA" | translate }}</label>
						</span>
					</div>
					<div class="col-12 lg:col-2 p-fluid">
						<p-toggleButton
							onLabel="{{ 'SOLO_CON_ASIGANCIÓN' | translate }}"
							offLabel="{{ 'TODOS' | translate }}"
							iconPos="left"
							name="modoTest"
							styleClass="p-button-primary"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[(ngModel)]="filtro.soloConAsignacion"
						></p-toggleButton>
					</div>
					<div class="col-12 lg:col">
						<span class="p-float-label p-fluid">
							<p-multiSelect
								[options]="productos | async"
								[showToggleAll]="true"
								appendTo="body"
								[showClear]="true"
								[styleClass]="'prime-material'"
								[dropdownIcon]="'fa fa-chevron-down'"
								[(ngModel)]="filtro.productos"
								[virtualScroll]="true"
								[virtualScrollItemSize]="34"
								[selectedItemsLabel]="'ITEMS_SELECCIONADOS' | translate"
								[filter]="true"
								[emptyFilterMessage]="'SIN_RESULTADOS' | translate"
							>
							</p-multiSelect>
							<label for="">{{ "PRODUCTOS" | translate }}</label>
						</span>
					</div>
					<div class="col-fixed w-5rem">
						<button pButton icon="pi pi-save" (click)="saveFiltro(filtro)"></button>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
	<div class="model-list">
		<div class="bottom-section">
			<div class="grid bg-white">
				<div class="col-12">
					<loading-panel [loading]="loadingService.loading | async"></loading-panel>
					<p-table
						#dt
						[columns]="dias"
						[value]="valores"
						[paginator]="true"
						[rows]="10"
						[rowsPerPageOptions]="[10, 25, 50]"
						[scrollable]="true"
						styleClass="p-datatable-gridlines"
					>
						<ng-template pTemplate="caption">
							<div class="flex justify-content-end">
								<button
									type="button"
									*ngIf="!isHome"
									pButton
									pRipple
									icon="pi pi-file-excel"
									(click)="exportExcel()"
									class="mr-2"
									pTooltip="Excel"
									tooltipPosition="bottom"
								></button>
							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
								<th style="width: 25rem"></th>
								<ng-container *ngFor="let mes of meses">
									<th [attr.colspan]="mes.span" class="text-center">
										{{ mes.mes | titlecase }}
									</th>
								</ng-container>
							</tr>
							<tr>
								<th style="width: 25rem"></th>
								<th
									*ngFor="let d of dias"
									style="width: 2.5rem"
									class="text-center fecha"
									[ngClass]="{ 'fw-700 text-primary hover': hoveredItem?.fecha == d }"
								>
									{{ d | date : "EE" | titlecase }}
								</th>
							</tr>
							<tr>
								<th style="width: 25rem"></th>

								<th *ngFor="let d of dias" class="text-center fecha" [ngClass]="{ 'fw-700 text-primary hover': hoveredItem?.fecha == d }">
									{{ d | date : "dd" }}
								</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-product>
							<tr>
								<td style="width: 25rem" class="producto" [ngClass]="{ 'fw-700 text-primary hover': product['hover'] }">
									{{ product.producto.descripcion }}
								</td>
								<td
									*ngFor="let item of product.fechas"
									(mouseenter)="onHover(product, item)"
									(mouseleave)="onOffHover()"
									class="cantidad-cell text-center"
								>
									{{ item.cantidad }}
								</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</div>
		</div>
	</div>
</div>
