import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-help-en-construccion',
    templateUrl: './help-en-construccion.component.html',
    styleUrls: ['./help-en-construccion.component.less'],
})
export class HelpEnConstruccionComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
