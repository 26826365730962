import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { Subscription } from "rxjs"
import { DEF_LOGO } from "src/app/app.module"
import { Usuario } from "src/app/common/model/Usuario"
import { ItemReservaConsentimiento } from "src/app/model/ItemReservaConsentimiento"
import { AuthService } from "src/app/services/auth.service"
import { ConfiguracionEntornoService } from "src/app/services/configuracion-entorno.service"
import { IActividad } from "../../consentimiento-actividades/IActividad"

@Component({
	selector: "actividades-card",
	templateUrl: "./actividades-card.component.html",
	styleUrls: ["./actividades-card.component.less"]
})
export class ActividadesCardComponent implements OnInit, OnDestroy {
	private _actividad: IActividad
	public mostrarVerMas: boolean = false
	public get actividad(): IActividad {
		return this._actividad
	}
	@Input()
	public set actividad(v: IActividad) {
		this._actividad = v
		this._actividad["verMas"] = false
		this.itemReserva = v.getItemBase()
		setTimeout(() => {
			this.mostrarVerMas = this.detalleGrupoHTML?.nativeElement.offsetHeight > 100
		})
	}

	// @Input() encuestaRespondida = false
	@Input() token: string
	@Input() esVoucher: boolean = false
	@Input() verHotel: boolean = true
	@Input() readonly = false
	@Input() consentimientoPendiente: boolean
	@ViewChild("actividad", { static: false }) actividadHTML: ElementRef
	@ViewChild("detalleGrupo") detalleGrupoHTML: ElementRef
	private userSub: Subscription
	@Output()
	public onMostrarEncuesta: EventEmitter<number> = new EventEmitter(null)
	@Output()
	public verConsentimiento: EventEmitter<boolean> = new EventEmitter<boolean>()
	verMas = false
	public usuario: Usuario
	constructor(private confEntorno: ConfiguracionEntornoService, private authService: AuthService) {
		this.confEntorno.getConfiguracion().then((c) => {
			this.mainLogo = c.profilePic?.picPath || DEF_LOGO
		})
		this.userSub = this.authService.getUser().subscribe((u) => {
			this.usuario = u
		})
	}
	ngOnDestroy(): void {
		if (this.userSub) {
			this.userSub.unsubscribe()
		}
	}
	public mainLogo = DEF_LOGO
	public itemReserva: ItemReservaConsentimiento = null
	ngOnInit() {}

	get url() {
		const u = window.location.origin + "/#/public/voucher/" + this.token + "/" + this.actividad.tipoVoucher + "/" + this.actividad.getId()
		return u
	}

	get hayPrestador() {
		return this.actividad?.getPuestos().some((p) => p.proveedor && p.esPrestador())
	}

	onPrint() {
		var mywindow = window.open("", "PRINT", "width=284")
		setTimeout(() => {
			mywindow.document.write(this.actividadHTML.nativeElement.innerHTML)
			mywindow.document.close() // necessary for IE >= 10
			mywindow.focus() // necessary for IE >= 10*/
			/* setTimeout(()=>{
        mywindow.print();

      },1000)*/
		})
	}
	get fechaActividad() {
		return this.actividad.fechaActividad
	}

	// mostrarEncuesta() {
	//   this.onMostrarEncuesta.emit(this.actividad.id)
	// }

	public isMobile() {
		return window.innerWidth <= 640
	}

	public firmarConsentimiento() {
		this.verConsentimiento.emit(true)
	}
}
