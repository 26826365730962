<div class="model-list actividades">
	<div class="top-section">
		<div class="grid">
			<div class="model-filters col-12 lg:col-7">
				<filtro [filter]="filtro" [isFixed]="false" [isMinimized]="true" [filterTemplateContent]="filterTemplate" [useSidebar]="true"> </filtro>
			</div>
			<div class="col-12 lg:col-5">
				<div class="grid align-items-center justify-content-center acciones-lateral">
					<div class="col" *ngIf="esBloqueada(filtro.fecha)">
						<div class="bloqueo card text-center col-12" *ngIf="esBloqueada(filtro.fecha)">
							<div class="grid grid-nogutter align-items-center">
								<div class="col-2 warning"><i class="fa fa-warning"></i></div>
								<div class="col-8">
									<div class="grid grid-nogutter">
										<div class="col-12">
											<button pButton label="{{ 'DESBLOQUEAR_FECHA' | translate }}" (click)="desbloquear(filtro.fecha)"></button>
										</div>
										<div class="col-12" style="margin-top: 0.5em">
											<span class="motivo">{{ getBloqueo(filtro.fecha)?.motivo }}</span>
										</div>
									</div>
								</div>
								<div class="col-2 warning"><i class="fa fa-warning"></i></div>
							</div>
						</div>
					</div>
					<div class="col text-center" *ngIf="!esBloqueada(filtro.fecha) && layout == 'G' && !isMobile()">
						<button pButton label="{{ 'AGREGAR NOTA' | translate }}" (click)="agregarNota()" class="{{ getButtonClass() }}"></button>
					</div>
					<div class="col" *ngIf="!esBloqueada(filtro.fecha) && layout == 'G' && !isMobile()">
						<button
							pButton
							label="{{ 'BLOQUEAR_FECHA' | translate }}"
							(click)="bloquearFecha(filtro.fecha)"
							class="{{ getButtonClass() }}"
						></button>
					</div>
					<div class="col">
						<p-button
							styleClass="disponibilidad-button"
							label="{{ 'DISPONIBILIDAD' | translate }}"
							badge="{{ disponibilidadLength ? '' + disponibilidadLength : '' }}"
							(click)="mostrarDisponibilidad = true"
							class="{{ getButtonClass() }}"
							pTooltip="{{ puesto ? puesto.descripcion : '' }}"
						></p-button>
					</div>
					<div class="col-fixed w-4rem">
						<button
							pButton
							*ngIf="!isMobile()"
							tooltipPosition="bottom"
							pTooltip="{{ 'AYUDA' | translate }}"
							class="p-button-primary"
							icon="fa fa-question"
							(click)="goToHelp('actividades')"
						></button>
					</div>

					<div class="col-fixed text-right">
						<p-selectButton
							[options]="layoutOptions"
							[(ngModel)]="filtro.layout"
							optionLabel="lebel"
							optionValue="value"
							(onChange)="actualizarLayout($event)"
						></p-selectButton>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="bottom-section">
		<div class="grid">
			<div class="col-12 notas" *ngIf="notas?.length">
				<ng-container [ngTemplateOutlet]="notasPanel"> </ng-container>
			</div>
			<div class="col-12" *ngIf="layout == 'P'">
				<div class="grid">
					<div class="col-12">
						<p-accordion
							[multiple]="true"
							*ngFor="let proveedorG of proveedoresAgrupados; let i = index"
							(onClose)="proveedorG.abierto = false"
							(onOpen)="proveedorG.abierto = true"
						>
							<p-accordionTab class="accordion">
								<ng-template pTemplate="header">
									<div class="grid no-gutter">
										<div class="col-12">
											<strong
												>{{ proveedorG?.proveedor?.descripcion }}
												<p-badge class="ml-1 inline" [value]="proveedorG?.grupos?.length"></p-badge
											></strong>
										</div>
										<ng-container *ngIf="!proveedorG?.abierto && proveedorG?.proveedor?.id != null">
											<div class="col-10 ml-5" *ngFor="let actividad of proveedorG?.actividades">
												<span>{{ actividad?.descripcionGeneral }}</span>
											</div>
										</ng-container>
									</div>
								</ng-template>
								<div class="grid">
									<div class="col-12 lg:col-4 grupo" *ngFor="let grupo of proveedorG.grupos; trackBy: trackByFn">
										<grupo
											[verInfoContacto]="filtro.verInfoContacto"
											[grupo]="grupo"
											[verFecha]="true"
											(onVehiculoIntercambiado)="actualizarVehiculos($event)"
											[verCliente]="filtro.mostrarCliente"
											[verObservaciones]="filtro.mostrarObservaciones"
											[full]="true"
											(onPuestoSeleccionado)="actualizarGrupos($event)"
											(onGrupoChange)="filtro.forceUpdate()"
											(onItemClick)="ver($event)"
											[bloqueable]="true"
											[ausencias]="ausencias"
											[disponibilidad]="disponibilidad"
											[permiteGestionCostos]="grupo['permiteGestionCostos']"
											[mostrarVehiculo]="true"
											[borrable]="grupo?.esVacio"
											[vehiculoEditable]="true"
										>
										</grupo>
									</div>
								</div>
							</p-accordionTab>
						</p-accordion>
					</div>
				</div>
			</div>
			<div class="col-12" *ngIf="layout == 'A'">
				<div class="grid">
					<div class="col-12">
						<p-accordion
							#actividadesAgrupadasAc
							[multiple]="true"
							*ngFor="let actividadG of actividadesAgrupadas; let i = index"
							(onClose)="actividadG.abierto = false"
							(onOpen)="actividadG.abierto = true"
						>
							<p-accordionTab class="accordion">
								<ng-template pTemplate="header">
									<div class="grid">
										<div class="col-12">
											<div class="flex align-items-center">
												<strong
													>{{ actividadG?.producto?.descripcionGeneral }}
													<p-badge class="ml-1 inline" [value]="actividadG?.grupos?.length"></p-badge
												></strong>
											</div>
										</div>
										<!-- <ng-container *ngIf="!actividadG?.abierto">
											<div class="col-10 ml-5" *ngFor="let actividad of actividadG?.actividades">
												<span>{{ actividad?.descripcion }}</span>
											</div>
										</ng-container> -->
									</div>
								</ng-template>
								<div class="grid">
									<div class="col-12 lg:col-4 grupo" *ngFor="let grupo of actividadG.grupos; trackBy: trackByFn">
										<grupo
											[verInfoContacto]="filtro.verInfoContacto"
											[grupo]="grupo"
											[verFecha]="true"
											(onVehiculoIntercambiado)="actualizarVehiculos($event)"
											[verCliente]="filtro.mostrarCliente"
											[verObservaciones]="filtro.mostrarObservaciones"
											[full]="true"
											(onPuestoSeleccionado)="actualizarGrupos($event)"
											(onGrupoChange)="filtro.forceUpdate()"
											(onItemClick)="ver($event)"
											[bloqueable]="true"
											[ausencias]="ausencias"
											[disponibilidad]="disponibilidad"
											[permiteGestionCostos]="grupo['permiteGestionCostos']"
											[mostrarVehiculo]="true"
											[borrable]="grupo?.esVacio"
											[vehiculoEditable]="true"
										>
										</grupo>
									</div>
								</div>
							</p-accordionTab>
						</p-accordion>
					</div>
				</div>
			</div>

			<div class="col-12" *ngIf="layout == 'V'">
				<div class="grid">
					<div class="col-12">
						<p-accordion
							#vehiculosAgrupadosAc
							[multiple]="true"
							*ngFor="let vehiculoG of vehiculosAgrupados; let i = index"
							(onClose)="vehiculoG.abierto = false"
							(onOpen)="vehiculoG.abierto = true"
						>
							<p-accordionTab class="accordion">
								<ng-template pTemplate="header">
									<div class="grid">
										<div class="col-12">
											<strong
												>{{ vehiculoG?.vehiculo?.descripcion }}
												<p-badge class="ml-1 inline" [value]="vehiculoG?.grupos?.length"></p-badge
											></strong>
										</div>
										<ng-container *ngIf="!vehiculoG?.abierto">
											<div class="col-10 ml-5" *ngFor="let actividad of vehiculoG?.actividades">
												<span>{{ actividad?.descripcion }}</span>
											</div>
										</ng-container>
									</div>
								</ng-template>
								<div class="grid">
									<div class="col-12 lg:col-4 grupo" *ngFor="let grupo of vehiculoG.grupos; trackBy: trackByFn">
										<grupo
											[verInfoContacto]="filtro.verInfoContacto"
											[grupo]="grupo"
											[verFecha]="true"
											(onVehiculoIntercambiado)="actualizarVehiculos($event)"
											[verCliente]="filtro.mostrarCliente"
											[verObservaciones]="filtro.mostrarObservaciones"
											[full]="true"
											(onPuestoSeleccionado)="actualizarGrupos($event)"
											(onGrupoChange)="filtro.forceUpdate()"
											(onItemClick)="ver($event)"
											[bloqueable]="true"
											[ausencias]="ausencias"
											[disponibilidad]="disponibilidad"
											[permiteGestionCostos]="grupo['permiteGestionCostos']"
											[mostrarVehiculo]="true"
											[borrable]="grupo?.esVacio"
											[vehiculoEditable]="true"
										>
										</grupo>
									</div>
								</div>
							</p-accordionTab>
						</p-accordion>
					</div>
				</div>
			</div>
			<div class="col-12 list" *ngIf="layout == 'L' || isMobile()">
				<div class="grid">
					<div class="col-12" *ngFor="let fecha of fechas">
						<div class="grid">
							<div class="col-12 clickable" (click)="irAFecha(fecha.fecha)">
								<div class="subtitulo" style="width: 100%">
									<h3>{{ fecha.fecha | date : "EEEE dd/MM/yyyy" | titlecase }}</h3>
								</div>
							</div>
							<div class="col-12" *ngIf="fecha.actividades?.length">
								<div class="grid">
									<div class="col-12">
										<span>{{ "ACTIVIDADES" | translate }}</span>
									</div>
									<div class="col-12 lg:col-4 grupo" *ngFor="let grupo of fecha.actividades; trackBy: trackByFn">
										<grupo
											[verInfoContacto]="filtro.verInfoContacto"
											[grupo]="grupo"
											(onVehiculoIntercambiado)="actualizarVehiculos($event)"
											[verCliente]="filtro.mostrarCliente"
											[verObservaciones]="filtro.mostrarObservaciones"
											[full]="true"
											(onPuestoSeleccionado)="actualizarGrupos($event)"
											(onGrupoChange)="filtro.forceUpdate()"
											(onItemClick)="ver($event)"
											(onBuscaItem)="mostrarItem($event)"
											[bloqueable]="true"
											[ausencias]="ausencias"
											[disponibilidad]="disponibilidad"
											[permiteGestionCostos]="grupo['permiteGestionCostos']"
											[mostrarVehiculo]="true"
											[borrable]="grupo?.esVacio"
											[vehiculoEditable]="true"
										>
										</grupo>
									</div>
								</div>
							</div>
							<div class="col-12" *ngIf="fecha.transfers?.length">
								<div class="grid">
									<div class="col-12">
										<span>{{ "TRANSFERS" | translate }}</span>
									</div>
									<div class="col-12 lg:col-4 grupo" *ngFor="let grupo of fecha.transfers; trackBy: trackByFn">
										<grupo
											[verInfoContacto]="filtro.verInfoContacto"
											[grupo]="grupo"
											[verCliente]="filtro.mostrarCliente"
											[verObservaciones]="filtro.mostrarObservaciones"
											[full]="true"
											(onPuestoSeleccionado)="actualizarGrupos($event)"
											(onGrupoChange)="filtro.forceUpdate()"
											(onItemClick)="ver($event)"
											(onBuscaItem)="mostrarItem($event)"
											[bloqueable]="true"
											[ausencias]="ausencias"
											[disponibilidad]="disponibilidad"
											[permiteGestionCostos]="grupo['permiteGestionCostos']"
											[mostrarVehiculo]="true"
											[vehiculoEditable]="true"
											(onVehiculoIntercambiado)="actualizarVehiculos($event)"
											[borrable]="grupo?.esVacio"
											[vehiculoEditable]="true"
										>
										</grupo>
									</div>
								</div>
							</div>
							<div class="col-12" *ngIf="fecha.tercerizados?.length">
								<div class="grid">
									<div class="col-12">
										<span>{{ "TERCERIZADOS" | translate }}</span>
									</div>
									<div class="col-12 lg:col-4 grupo" *ngFor="let grupo of fecha.tercerizados; trackBy: trackByFn">
										<grupo
											[verInfoContacto]="filtro.verInfoContacto"
											[grupo]="grupo"
											(onVehiculoIntercambiado)="actualizarVehiculos($event)"
											[verCliente]="filtro.mostrarCliente"
											[verObservaciones]="filtro.mostrarObservaciones"
											[full]="true"
											(onPuestoSeleccionado)="actualizarGrupos($event)"
											(onGrupoChange)="filtro.forceUpdate()"
											(onItemClick)="ver($event)"
											(onBuscaItem)="mostrarItem($event)"
											[bloqueable]="true"
											[ausencias]="ausencias"
											[disponibilidad]="disponibilidad"
											[permiteGestionCostos]="grupo['permiteGestionCostos']"
											[mostrarVehiculo]="true"
											[borrable]="grupo?.esVacio"
										>
										</grupo>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12" *ngIf="layout == 'G' && !isMobile()">
				<div class="titulo-fecha text-center" style="width: 100%">
					<p-calendar
						[(ngModel)]="fechaDesdeSafe"
						name="fechaDesde"
						dateFormat="D dd/mm/yy"
						[readonlyInput]="true"
						inputStyleClass="titulo-fecha-input"
						appendTo="body"
						#fechaDesde="ngModel"
					></p-calendar>
				</div>
				<div class="relative">
					<div
						class="scroll-bottom"
						(dragover)="onBottomOver($event, actividadesContainer)"
						(dragenter)="onDragOver($event)"
						(dragleave)="onDragLeave($event)"
					></div>
					<div class="grid-container" #actividadesContainer [ngClass]="{ big: filtro?.mostrarObservaciones }">
						<span class="no-data" *ngIf="vehiculosFiltrados?.length == 0">{{ "SIN_RESERVAS_PARA_E_6" | translate }}</span>
						<div class="vertice" [ngStyle]="{ 'grid-column': 1, 'grid-row': 1 }">
							<button pButton label="{{ 'IR_A_HOY' | translate }}" class="p-button-sm p-button-outlined hoy" (click)="irAHoy()"></button>
							<button pButton icon="fa fa-chevron-left " class="p-button-sm p-button-text" (click)="navegarDias(-1)"></button>

							<button pButton icon="fa fa-chevron-right  " class="p-button-sm p-button-text" (click)="navegarDias(1)"></button>
						</div>
						<div class="hora hora-header" *ngFor="let hora of horas; let c = index" [ngStyle]="{ 'grid-column': c + 2, 'grid-row': 1 }">
							<span class="label-hora">{{ hora | number : "2.0-0" }}</span>
						</div>

						<ng-container
							[ngTemplateOutlet]="filaVehiculo"
							[ngTemplateOutletContext]="{ vehiculo: vehiculo, row: r }"
							*ngFor="let vehiculo of vehiculosFiltrados; let r = index"
						>
						</ng-container>
						<grupo
							[verInfoContacto]="filtro.verInfoContacto"
							[grupo]="grupo"
							[verCliente]="filtro.mostrarCliente"
							[verObservaciones]="filtro.mostrarObservaciones"
							*ngFor="let grupo of conVehiculo"
							[style.grid-row]="getFila(grupo) + 2"
							[borrable]="grupo?.esVacio"
							[style.grid-column-start]="grupo.horaDesde + 2"
							[style.grid-column-end]="grupo.colHasta + 2"
							(onVehiculoIntercambiado)="actualizarVehiculos($event)"
							(onPuestoSeleccionado)="actualizarGrupos($event)"
							(onItemClick)="ver($event)"
							(onBuscaItem)="mostrarItem($event)"
							(onGrupoChange)="filtro.forceUpdate()"
							[bloqueable]="true"
							[ausencias]="ausencias"
							[permiteGestionCostos]="grupo['permiteGestionCostos']"
							[esActividad]="esActividad"
							[vehiculoEditable]="true"
						></grupo>

						<div class="vehiculo" [ngStyle]="{ 'grid-row': vehiculosFiltrados.length + 2, 'grid-column': 1 }">
							<span class="alquilado">Alquilado</span>
						</div>
						<ng-container
							[ngTemplateOutlet]="filaAlquiler"
							[ngTemplateOutletContext]="{ row: vehiculosFiltrados.length + 2 + r, tipo: 'alquiler' }"
							*ngFor="let a of alquilados; let r = index"
						>
						</ng-container>
						<grupo
							[verInfoContacto]="filtro.verInfoContacto"
							[grupo]="grupo"
							[verCliente]="filtro.mostrarCliente"
							[verObservaciones]="filtro.mostrarObservaciones"
							*ngFor="let grupo of alquilados; let r = index"
							[style.grid-row]="vehiculosFiltrados.length + 2 + r"
							[borrable]="grupo?.esVacio"
							[style.grid-column-start]="grupo.horaDesde + 2"
							[style.grid-column-end]="grupo.colHasta + 2"
							(onVehiculoIntercambiado)="actualizarVehiculos($event)"
							(onPuestoSeleccionado)="actualizarGrupos($event)"
							(onItemClick)="ver($event)"
							(onBuscaItem)="mostrarItem($event)"
							[bloqueable]="true"
							[ausencias]="ausencias"
							[permiteGestionCostos]="grupo['permiteGestionCostos']"
							(onCantidad)="changeCantidad($event)"
						></grupo>

						<!-- <div class="vehiculo" [ngStyle]="{ 'grid-row': vehiculosFiltrados.length + 3 + alquilados.length, 'grid-column': 1 }">
									<span class="alquilado">Delegados</span>
								</div>
								<ng-container
									[ngTemplateOutlet]="filaAlquiler"
									[ngTemplateOutletContext]="{ row: vehiculosFiltrados.length + alquilados.length + 3 + r, tipo: 'delegado' }"
									*ngFor="let d of delegados; let r = index"
								>
								</ng-container> -->
					</div>
					<div *ngIf="!cargandoFechas">
						<!--p-tabView>
							<p-tabPanel header="{{ 'TRANSFERS' | translate }}"-->

						<p-panel [toggleable]="true" toggler="header" [collapsed]="true" #panel1>
							<ng-template pTemplate="header">
								<span class="block w-50"
									>{{ "TERCERIZADOS" | translate }}
									<p-badge [value]="tercerizados.length" [styleClass]="'pb-2 ml-2'"></p-badge>
								</span>
							</ng-template>
							<div
								class="flex align-items-center mb-4 fila-tercerizado"
								*ngFor="let tercerizado of tercerizados; let r = index"
								pDraggable="tercerizado"
								pDroppable="tercerizado"
								(drop)="dropFilaTercerizado(tercerizado)"
								(onDragEnter)="dragEnter(r)"
								(onDragEnd)="dragEnd()"
								[@liftEffect]="isRowLiftedTercerizado(r) ? 'lifted' : 'normal'"
								(onDragStart)="dragStartTercerizado(tercerizado)"
							>
								<div class="vehiculo w-12rem">
									<selector-vehiculo
										[ngModel]="tercerizado.vehiculo"
										[verDetalle]="true"
										[small]="true"
										name="{{ 'v_' + r }}"
									></selector-vehiculo>
								</div>
								<div class="flex overflow-auto">
									<div class="mr-3" *ngFor="let grupo of tercerizado.grupos | byVehiculo : [tercerizado.vehiculo]; let i = index">
										<grupo
											[verInfoContacto]="filtro.verInfoContacto"
											[grupo]="grupo"
											[item]="itemEditado"
											[verCliente]="filtro.mostrarCliente"
											[verObservaciones]="filtro.mostrarObservaciones"
											(onVehiculoIntercambiado)="actualizarVehiculos($event)"
											(onPuestoSeleccionado)="actualizarGrupos($event)"
											(onItemClick)="ver($event)"
											(onBuscaItem)="mostrarItem($event)"
											(onGrupoChange)="filtro.forceUpdate()"
											[mostrarVehiculo]="true"
											(onAsignacion)="aplicarFiltros(filtro)"
											[full]="true"
											[bloqueable]="true"
											[ausencias]="ausencias"
											styleClass="w-30rem"
											[permiteGestionCostos]="grupo['permiteGestionCostos']"
											(onCantidad)="changeCantidad($event)"
											[borrable]="grupo?.esVacio"
										>
										</grupo>
									</div>
								</div>
							</div>
						</p-panel>
						<p-panel [toggleable]="true" #transfP toggler="header">
							<ng-template pTemplate="header">
								<span class="block w-100"
									>{{ "TRANSFERS" | translate }}
									<p-badge [value]="transfers.length" [styleClass]="'pb-2 ml-2'"></p-badge>
								</span>
							</ng-template>
							<div class="grid">
								<div class="col-12 lg:col-4" *ngFor="let grupo of transfers; let i = index">
									<grupo
										[verInfoContacto]="filtro.verInfoContacto"
										[grupo]="grupo"
										[verCliente]="filtro.mostrarCliente"
										[verObservaciones]="filtro.mostrarObservaciones"
										(onPuestoSeleccionado)="actualizarGrupos($event)"
										(onItemClick)="ver($event)"
										(onBuscaItem)="mostrarItem($event)"
										(onGrupoChange)="filtro.forceUpdate()"
										(onAsignacion)="aplicarFiltros(filtro)"
										[full]="true"
										[bloqueable]="true"
										[ausencias]="ausencias"
										[mostrarVehiculo]="true"
										[permiteGestionCostos]="grupo['permiteGestionCostos']"
										(onCantidad)="changeCantidad($event)"
										[fechaEditable]="true"
										(onFechaChange)="cambioHorarioTransfer(grupo, $event)"
										[vehiculoEditable]="true"
										(onVehiculoIntercambiado)="actualizarVehiculos($event)"
										[borrable]="grupo?.esVacio"
									>
									</grupo>
								</div>
							</div>
						</p-panel>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="loading" *ngIf="customLoading.loading | async" [@inOutAnimation]>
		<span>{{ customLoading.status }}</span>
	</div>
	<ng-template #filaAlquiler let-r="row" let-tipo="tipo">
		<div
			class="hora-data"
			*ngFor="let hora of horas; let cv = index"
			[ngClass]="{ odd: r % 2 > 0 }"
			[ngStyle]="{ 'grid-row': r, 'grid-column': cv + 2 }"
			pDroppable="grupo"
			(drop)="cambioHorarioAlquiler(hora, tipo, $event)"
			(dragenter)="onDragOver($event)"
			(dragleave)="onDragLeave($event)"
			(dragend)="onDragEnd($event, hora)"
		></div>
	</ng-template>
	<ng-template #filaVehiculo let-vehiculo="vehiculo" let-r="row">
		<div
			class="vehiculo"
			[ngStyle]="{ 'grid-row': r + 2, 'grid-column': 1 }"
			*ngIf="vehiculo?.id != -1"
			pDraggable="vehiculo"
			pDroppable="vehiculo"
			(drop)="dropFilaVehiculo(vehiculo)"
			(onDragEnter)="dragEnter(vehiculo?.id)"
			(onDragEnd)="dragEnd()"
			[@liftEffect]="isRowLifted(vehiculo?.id) ? 'lifted' : 'normal'"
			(onDragStart)="dragStartVehiculo(vehiculo)"
			[ngClass]="{ bloqueado: vehiculo.esBloqueado(filtro.fecha), t: !isRowLifted(vehiculo?.id) }"
		>
			<selector-vehiculo [ngModel]="vehiculo" [verDetalle]="true" [small]="true"></selector-vehiculo>
			<i class="fa fa-ban bloqueo-action clickeable" (click)="bloquearVehiculo(vehiculo)" *ngIf="!vehiculo.esBloqueado(filtro.fecha)"></i>
		</div>
		<div class="bloqueo" [ngStyle]="{ 'grid-row': r + 2 }" *ngIf="vehiculo.esBloqueado(filtro.fecha)" (click)="verBloqueo(vehiculo)">
			<span>BLOQUEADO</span>
		</div>
		<div
			class="hora-data"
			*ngFor="let hora of horas; let cv = index"
			[ngStyle]="{ 'grid-row': r + 2, 'grid-column': cv + 2 }"
			[ngClass]="{ odd: (r + 2) % 2 > 0 }"
			pDroppable="grupo"
			(drop)="cambioHorario(hora, vehiculo, $event)"
			(dragenter)="onDragOver($event)"
			(dragleave)="onDragLeave($event)"
			(dragend)="onDragEnd($event, hora)"
			(click)="accionHora($event, hora, vehiculo)"
		></div>
	</ng-template>
	<p-dialog
		[(visible)]="mostrarDetalleActividad"
		[modal]="true"
		#dialDetail
		header="{{ itemEditado?.producto?.descripcion }}"
		[styleClass]="'gestor-dialog'"
		(onShow)="onDialogShow($event, dialDetail)"
		(onHide)="cancelar()"
		[breakpoints]="{ '650px': '95vw' }"
	>
		<detalle-actividad [(ngModel)]="itemEditado" [editable]="false" *ngIf="itemEditado"> </detalle-actividad>
		<ng-template pTemplate="footer">
			<div class="botonera text-center" *ngIf="itemEditado">
				<button pButton label="{{ 'ACTUALIZAR_ACTIVIDAD' | translate }}" (click)="actualizarItemReserva(itemEditado)"></button>
			</div>
		</ng-template>
	</p-dialog>
	<p-dialog
		[(visible)]="mostrarDetalleBloqueo"
		[modal]="true"
		#dialBloqueo
		header="Bloqueo"
		[styleClass]="'gestor-dialog'"
		(onShow)="onDialogShow($event, dialBloqueo)"
		(onHide)="mostrarDetalleBloqueo = false"
	>
		<gestor-bloqueo-vehiculo
			[isModal]="true"
			[goBack]="false"
			[item]="bloqueoEditado"
			[hideTitle]="false"
			*ngIf="bloqueoEditado"
			(onGuardado)="actualizarBloqueosVehiculos()"
			(onCancelado)="mostrarDetalleBloqueo = false; bloqueoEditado = null"
			[readonly]="bloqueoEditado?.id"
		>
		</gestor-bloqueo-vehiculo>
	</p-dialog>
	<p-dialog
		[(visible)]="actualizarFechasPickupVisible"
		[modal]="true"
		#actualizarFechasPickupD
		header="{{ 'PICKUP' | translate }}"
		[styleClass]="'gestor-dialog'"
	>
		<ng-template pTemplate="footer">
			<button pButton (click)="actualizarFechasPickupD.close()" class="p-button-outlined" [label]="'CANCELAR' | translate"></button>
			<button pButton (click)="actualizarPickup()" [label]="'ACTUALIZAR' | translate"></button>
		</ng-template>
		<div class="grid">
			<div class="col-12">
				<span>
					{{ "ACTUALIZAR_PICKUPS" | translate }}
				</span>
			</div>
			<div class="col-12">
				<p-radioButton
					name="gapickup"
					value="i"
					[(ngModel)]="opcionActualizacion"
					pTooltip="{{ 'IGUALAR_TT' | translate }}"
					label="{{ 'IGUALAR' | translate }}"
				></p-radioButton>
			</div>
			<div class="col-12">
				<p-radioButton
					name="gapickup"
					pTooltip="{{ 'ACTUALIZAR_PICKUPS_TT' | translate }}"
					value="a"
					[(ngModel)]="opcionActualizacion"
					label="{{ 'ACTUALIZAR' | translate }}"
				></p-radioButton>
			</div>
		</div>
	</p-dialog>

	<p-dialog
		[(visible)]="mostrarBloquearFecha"
		[modal]="true"
		#dialBloqueFecha
		header="{{ 'BLOQUEAR_FECHA' | translate }}"
		[styleClass]="'gestor-dialog'"
		(onShow)="onDialogShow($event, dialBloqueFecha)"
	>
		<gestor-bloqueo-fecha
			[isModal]="true"
			[goBack]="false"
			[fecha]="filtro.fecha"
			[hideTitle]="true"
			(onGuardado)="mostrarBloquearFecha = false; actualizarBloqueos()"
			(onCancelado)="mostrarBloquearFecha = false"
		>
		</gestor-bloqueo-fecha>
	</p-dialog>

	<p-dialog
		[(visible)]="mostrarNota"
		[modal]="true"
		#dialNotaFecha
		header="{{ 'Notas del día' | translate }}"
		[styleClass]="'gestor-dialog'"
		(onShow)="onDialogShow($event, dialNotaFecha)"
		(onHide)="mostrarNota = false; notaEditada = null"
		appendTo="body"
		[style]="{ width: '60vw' }"
	>
		<gestor-nota
			[isModal]="true"
			[goBack]="false"
			[fecha]="filtro.fecha"
			[hideTitle]="false"
			[item]="notaEditada"
			[readonly]="!editandoNota"
			(onGuardado)="mostrarNota = false; filtro.forceUpdate(); notaEditada = null"
			(onCancelado)="mostrarNota = false; notaEditada = null"
			*ngIf="notaEditada"
		>
		</gestor-nota>
	</p-dialog>

	<p-dialog
		[(visible)]="mostrarDisponibilidad"
		[modal]="true"
		#dialDisponibilidad
		header="{{ 'DISPONIBILIDAD' | translate }}"
		[resizable]="false"
		[modal]="true"
		[closable]="true"
		[styleClass]="'puesto-selector-dialog'"
	>
		<disponibilidad-puesto [fecha]="filtro.fecha" (length)="this.disponibilidadLength = $event" (puestoChange)="puesto = $event"></disponibilidad-puesto>
	</p-dialog>

	<ng-template #filterTemplate>
		<div class="grid mt-2">
			<div class="col-12 p-fluid">
				<div class="p-inputgroup">
					<input pInputText [(ngModel)]="filtro.searchStr" placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" [(ngModel)]="filtro.searchStr" />
					<span class="p-inputgroup-addon">
						<i class="pi pi-search"></i>
					</span>
				</div>
			</div>
			<div class="col-12 field p-fluid" *ngIf="layout == 'G' && !isMobile()">
				<span class="p-float-label">
					<p-calendar
						[(ngModel)]="fechaDesdeSafe"
						name="fechaDesde"
						dateFormat="dd/mm/yy"
						#fechaDesde
						appendTo="body"
						[showIcon]="true"
						#fecha="ngModel"
						required
					></p-calendar>
					<label for="">{{ "FECHA_DESDE" | translate }}</label>
					<small *ngIf="getErrorMessage(fecha)" class="p-error block">{{ getErrorMessage(fecha) }}</small>
				</span>
			</div>
			<div class="col-12" *ngIf="layout != 'G' || isMobile()">
				<div class="grid p-fluid">
					<div class="col-6 field">
						<span class="p-float-label">
							<p-calendar
								[(ngModel)]="fechaDesdeSafe"
								name="fechaDesde"
								dateFormat="dd/mm/yy"
								appendTo="body"
								[showIcon]="true"
								#fecha="ngModel"
								required
							></p-calendar>
							<label for="">{{ "FECHA_DESDE" | translate }}</label>
							<small *ngIf="getErrorMessage(fecha)" class="p-error block">{{ getErrorMessage(fecha) }}</small>
						</span>
					</div>
					<div class="col-6 field">
						<span class="p-float-label">
							<p-calendar
								[(ngModel)]="fechaHastaSafe"
								name="fechaHasta"
								dateFormat="dd/mm/yy"
								appendTo="body"
								[showIcon]="true"
								#fechaHasta="ngModel"
								required
							></p-calendar>
							<label for="">{{ "HASTA" | translate }}</label>
							<small *ngIf="getErrorMessage(fecha)" class="p-error block">{{ getErrorMessage(fecha) }}</small>
						</span>
					</div>
				</div>
			</div>

			<div class="col-12 lg:col-6 field">
				<descriptivo-material-selector
					[label]="'CLIENTE' | translate"
					[limpiable]="true"
					[(ngModel)]="filtro.cliente"
					name="cliente_filt"
					isLazy="true"
					[service]="clienteService"
				>
				</descriptivo-material-selector>
			</div>
			<div class="col-12 lg:col-6 field">
				<parametrico-selector
					[service]="tipoVehiculoService"
					[(ngModel)]="filtro.tipoVehiculo"
					[titulo]="'TIPO_DE_VEHÍCULO' | translate"
					[limpiable]="true"
				>
				</parametrico-selector>
			</div>
			<div class="col-12 lg:col-6 field">
				<parametrico-selector
					[service]="tipoProductoService"
					[(ngModel)]="filtro.tipoProducto"
					[titulo]="'TIPO_PRODUCTO' | translate"
					[limpiable]="true"
				>
				</parametrico-selector>
			</div>
			<div class="col-12 lg:col-6 field">
				<descriptivo-material-selector [service]="vehiculosService" [(ngModel)]="filtro.vehiculo" [label]="'VEHICULOS' | translate" [limpiable]="true">
				</descriptivo-material-selector>
			</div>
			<div class="col-12 field p-fluid">
				<span class="p-float-label">
					<p-multiSelect
						[options]="idiomas"
						[showToggleAll]="true"
						[showClear]="true"
						[dataKey]="'codigo'"
						appendTo="body"
						[dropdownIcon]="'fa fa-chevron-down'"
						[(ngModel)]="filtro.idiomas"
						[selectedItemsLabel]="'ITEMS_SELECCIONADOS' | translate"
						[filter]="true"
						[emptyFilterMessage]="'SIN_RESULTADOS' | translate"
					>
					</p-multiSelect>
					<label for="">{{ "IDIOMAS" | translate }}</label>
				</span>
			</div>
			<div class="col-12 field p-fluid">
				<span class="p-float-label">
					<p-multiSelect
						[options]="productos | async"
						[showToggleAll]="true"
						[dataKey]="'codigo'"
						appendTo="body"
						[showClear]="true"
						[styleClass]="'prime-material'"
						[dropdownIcon]="'fa fa-chevron-down'"
						[(ngModel)]="filtro.productos"
						[virtualScroll]="true"
						[virtualScrollItemSize]="34"
						[selectedItemsLabel]="'ITEMS_SELECCIONADOS' | translate"
						[filter]="true"
						[emptyFilterMessage]="'SIN_RESULTADOS' | translate"
					>
					</p-multiSelect>
					<label for="">{{ "PRODUCTOS" | translate }}</label>
				</span>
			</div>

			<div class="col-12 field p-fluid">
				<span class="p-float-label">
					<p-multiSelect
						[options]="hoteles"
						[showToggleAll]="true"
						[dataKey]="'codigo'"
						[showClear]="true"
						appendTo="body"
						[dropdownIcon]="'fa fa-chevron-down'"
						[(ngModel)]="filtro.hoteles"
						[virtualScroll]="true"
						[virtualScrollItemSize]="34"
						[selectedItemsLabel]="'ITEMS_SELECCIONADOS' | translate"
						[filter]="true"
						[emptyFilterMessage]="'SIN_RESULTADOS' | translate"
					>
					</p-multiSelect>
					<label for="">{{ "HOTELES" | translate }}</label>
				</span>
			</div>
			<div class="col-12 field p-fluid">
				<span class="p-float-label">
					<p-multiSelect
						[options]="paises"
						[dataKey]="'codigo'"
						[showToggleAll]="true"
						[showClear]="true"
						appendTo="body"
						[dropdownIcon]="'fa fa-chevron-down'"
						[(ngModel)]="filtro.paises"
						[virtualScroll]="true"
						[virtualScrollItemSize]="34"
						[selectedItemsLabel]="'ITEMS_SELECCIONADOS' | translate"
						[filter]="true"
						[emptyFilterMessage]="'SIN_RESULTADOS' | translate"
					>
					</p-multiSelect>
					<label for="">{{ "PAIS_PLR" | translate }}</label>
				</span>
			</div>
			<div class="col-12 field p-fluid">
				<span class="p-float-label">
					<p-multiSelect
						[options]="regiones"
						[showToggleAll]="true"
						[showClear]="true"
						appendTo="body"
						[dataKey]="'codigo'"
						[dropdownIcon]="'fa fa-chevron-down'"
						[(ngModel)]="filtro.regiones"
						[virtualScroll]="true"
						[virtualScrollItemSize]="34"
						[selectedItemsLabel]="'ITEMS_SELECCIONADOS' | translate"
						[filter]="true"
						[emptyFilterMessage]="'SIN_RESULTADOS' | translate"
					>
					</p-multiSelect>
					<label for="">{{ "REGION_PLR" | translate }}</label>
				</span>
			</div>

			<div class="col-12">
				<div class="grid">
					<div class="col-12 lg:col-6 checkbox-container">
						<p-checkbox
							[(ngModel)]="filtro.mostrarSinOcupacion"
							name="mostrarSinOcupacion"
							tooltipPosition="bottom"
							pTooltip="{{ 'MOSTRAR_VEHICULOS_S_11' | translate }}"
							binary="true"
							label="{{ 'VEHICULOS_SIN_OCUPAR' | translate }}"
						>
						</p-checkbox>
					</div>
					<div class="col-12 lg:col-6 checkbox-container">
						<p-checkbox
							[(ngModel)]="filtro.soloSinAsignar"
							name="sinAsignar"
							tooltipPosition="bottom"
							pTooltip="{{ 'VER_SOLO_SIN_ASIGNAR' | translate }}"
							binary="true"
							label="{{ 'VER_SOLO_SIN_ASIGNAR' | translate }}"
						>
						</p-checkbox>
					</div>
					<div class="col-12 lg:col-6 checkbox-container">
						<p-checkbox
							[(ngModel)]="filtro.datosIncompletos"
							name="sinAsignar"
							tooltipPosition="bottom"
							pTooltip="{{ 'VER_SOLO_CON_DATOS_INCOMPLETOS' | translate }}"
							binary="true"
							label="{{ 'DATOS_INCOMPLETOS' | translate }}"
						>
						</p-checkbox>
					</div>
					<div class="col-12 lg:col-6 checkbox-container">
						<p-checkbox
							[(ngModel)]="filtro.mostrarCliente"
							name="mostrarCliente"
							tooltipPosition="bottom"
							pTooltip="{{ 'MUESTRA_EL_NOMBRE_DEL_CLIENTE_ADEMAS_DEL_PAX' | translate }}"
							binary="true"
							label="{{ 'VER_CLIENTE' | translate }}"
						>
						</p-checkbox>
					</div>
					<div class="col-12 lg:col-6 checkbox-container">
						<p-checkbox
							[(ngModel)]="filtro.mostrarObservaciones"
							name="mostrarObservaciones"
							tooltipPosition="bottom"
							pTooltip="{{ 'MUESTRA_LAS_OBSERVACIONES_DEL_SERVICIO' | translate }}"
							binary="true"
							label="{{ 'VER_OBSERVACIONES' | translate }}"
						>
						</p-checkbox>
					</div>
					<div class="col-12 lg:col-6 checkbox-container">
						<p-checkbox
							[(ngModel)]="filtro.verInfoContacto"
							name="verInfoContacto"
							tooltipPosition="bottom"
							pTooltip="{{ 'VER_INFO_CONTACTO_TT' | translate }}"
							binary="true"
							label="{{ 'VER_INFO_CONTACTO' | translate }}"
						>
						</p-checkbox>
					</div>
				</div>
			</div>
		</div>
	</ng-template>

	<ng-template #notasPanel>
		<p-panel [toggleable]="true">
			<ng-template pTemplate="header">
				<span class="text-primary">Notas<p-badge [value]="notas?.length" class="ml-2"></p-badge></span
			></ng-template>
			<div class="grid">
				<div class="col-12 lg:col-3 md:col-6" *ngFor="let nota of notas">
					<app-nota-card [nota]="nota" [filtro]="filtro"></app-nota-card>
				</div>
			</div>
		</p-panel>
	</ng-template>
</div>

<p-dialog
	[(visible)]="nuevoGrupoDialog"
	[modal]="true"
	#nuevoGrupoD
	header="{{ 'NUEVO_GRUPO' | translate }}"
	[styleClass]="'gestor-dialog'"
	(onShow)="onDialogShow($event, nuevoGrupoD)"
	(onHide)="nuevoGrupoDialog = false"
>
	<ng-container *ngIf="nuevoGrupoDialog">
		<descriptivo-material-selector
			[label]="'ACTIVIDADES' | translate"
			[limpiable]="true"
			[(ngModel)]="nuevoGrupo.actividad"
			name="actividadSeleccionada"
			isLazy="true"
			[field]="'fullDescripcion'"
			[service]="productoService"
			[getData]="productoService.getActividades"
		>
		</descriptivo-material-selector>
	</ng-container>
	<ng-template pTemplate="footer">
		<button pButton [label]="'CREAR_GRUPO' | translate" (click)="crearGrupo($event)"></button>
	</ng-template>
</p-dialog>
