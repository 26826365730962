import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { BehaviorSubject, Observable, of } from "rxjs"
import { catchError, filter, map, switchMap, take } from "rxjs/operators"
import { environment } from "src/environments/environment"
import { AuthService } from "../services/auth.service"
import { LoadingService } from "./../common/services/loading-data-service.service"
import { MessagesService } from "./../common/services/messages-data-service.service"

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	private loadingService: LoadingService
	private skippedError: string[] = ["/novedades"]
	private messageService: MessagesService
	private isRefreshing = false
	private errorCount: number = 0
	private isReconnecting = false
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)
	private addError(request: HttpRequest<any>, actionErrorCount: number) {
		actionErrorCount += actionErrorCount
		this.errorCount++
		if ((this.errorCount > 15 || actionErrorCount > 4) && !request.url.includes("login")) {
			this.authenticationService.logout()
			this.errorCount = 0
		}
	}
	constructor(private authenticationService: AuthService, messageService: MessagesService, private router: Router) {
		//this.loadingService = LoadingService.injector.get(LoadingService);
		this.messageService = messageService
	}
	private inSkipped(url: string) {
		return this.skippedError.some((s) => url.includes(s))
	}
	private _checkTokenExpiryErr(error: HttpErrorResponse): boolean {
		const message: string = (
			error?.error instanceof Blob
				? "expired"
				: null ||
				  error?.error?.message ||
				  (typeof error?.error === "string" && error?.error) ||
				  (error?.error?.errorCode && error?.error?.errorMessage) ||
				  (!error?.ok && error?.statusText) ||
				  (error?.ok === false && error?.status == 401) ||
				  ""
		).toLowerCase()
		return error.status && error.status === 401 && (message?.includes("expired") || message?.includes("401BAD"))
	}
	/*delay(error:HttpErrorResponse,retryCount, request) : Observable<any>{
		return (!request.url.includes("login") &&!this._checkTokenExpiryErr(error) && retryCount <= 3 ) ? timer(2000) : of({});
	}*/
	handleRequest(next, request): Observable<HttpEvent<any>> {
		let retryCount = 0
		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					//this.loadingService.susLoadingCount();
					retryCount = 0
					this.errorCount = 0
				}
				return event
			}),
			catchError((error: HttpErrorResponse) => {
				return this.handleError(error, request, next, retryCount)
			})
		)
	}
	private handleError = (error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler, retryCount: number) => {
		if (!request.url.includes("i18n") && !this.inSkipped(request.url)) {
			if (error.status === 0 && !error.ok && error.message.includes("Http failure response")) {
				this.addError(request, retryCount)

				if ((this.authenticationService.getCurrentUser == null || (retryCount >= 10 && !environment.offlineMode)) && !request.url.includes("login")) {
					this.authenticationService.logout()
					this.errorCount = 0 // Reset the counter after logout.
				}
			}
			if (!request.url.includes("login") && !this._checkTokenExpiryErr(error)) {
				if (this.authenticationService.getCurrentUser == null) {
					if (request.url.includes("/reauthenticate") && !request.url.includes("login")) {
						this.authenticationService.logout(this.router.url)
					}
				}
				if (this._isServerError(error)) {
					if (request.url.includes("/reauthenticate") && !request.url.includes("login") && !request.url.includes("/public/")) {
						this.authenticationService.logout(this.router.url)
					}
				}
				this.messageService.errorHandler(error)
			} else if (!request.url.includes("login")) {
				return this.handle401Error(request, next, retryCount)
			} else {
				this.messageService.errorHandler(error)
			}
		} else {
			// this.loadingService.susLoadingCount();
		}

		return of(error)
	}
	private handle401Error(request: HttpRequest<any>, next: HttpHandler, retryCount: number) {
		if (!this.isRefreshing) {
			this.isRefreshing = true
			this.refreshTokenSubject.next(null)

			return this.authenticationService.refreshToken().pipe(
				switchMap((token: any) => {
					this.isRefreshing = false
					this.refreshTokenSubject.next(token.token)
					return this.handleRequest(next, this.addToken(request, token.token))
				}),
				catchError((err) => {
					if (request.url.includes("/public")) {
						this.messageService.errorHandler(err)
						return
					} else if (request.url.includes("/login") || request.url.includes("/reauthenticate")) {
						console.log("Login JWTInterceptor - 401 Error")
						this.authenticationService.logout()
						this.messageService.errorHandler(err)
						this.errorCount = 0
						return
					} else {
						return this.handleError(err, request, next, retryCount)
					}
				})
			)
		} else {
			return this.refreshTokenSubject.pipe(
				filter((token) => token != null),
				take(1),
				switchMap((jwt) => {
					return next.handle(this.addToken(request, jwt.token || jwt))
				})
			)
		}
	}
	private addToken(request: HttpRequest<any>, token: string) {
		const req = request.clone({
			setHeaders: {
				Authorization: `Bearer ${this.authenticationService.token}`
			}
		})
		return req
	}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// add authorization header with jwt token if available
		if (this.authenticationService.token) {
			request = this.addToken(request, this.authenticationService.token)
			return this.handleRequest(next, request)
		} else if (
			request.url.includes("/login") ||
			request.url.includes("/register") ||
			request.url.endsWith("/reauthenticate") ||
			request.url.includes("/public") ||
			(request.url.includes("assets") && request.url.includes("i18n"))
		) {
			const xhr = request.clone({
				withCredentials: true
			})
			return this.handleRequest(next, xhr)
		}
		//this.loadingService.addLoadingCount();
	}
	_isServerError(error: HttpErrorResponse) {
		return error.ok == false && error.statusText === "Unknown Error" //&& environment.offlineMode == false
	}
}
