import { Hotel } from "src/app/model/Hotel"
import { Restriccion } from "src/app/model/Restriccion"
import { ID } from "../app.module"
import { Descriptivo } from "./../common/model/Descriptivo"
import { ProductoCabecera } from "./../pages/producto/ProductoCabecera"
import { Auditable } from "./Auditable"
import { EstadoReserva } from "./EstadoReserva"
import { Idioma } from "./Idioma"
import { Moneda } from "./Moneda"
import { ProductoAsociadoReserva } from "./ProductoAsociadoReserva"
import { TipoVariante } from "./TipoVariante"

export class ItemResumenReserva extends Auditable {
	constructor(
		public id?: number,
		public idReserva?: number,
		public nombrePax?: string,
		public observaciones?: string,
		public cantidadPax?: number,
		public idiomaServicio?: Idioma,
		public pickUp?: Date,
		public cliente?: Descriptivo,
		public restricciones: Restriccion[] = [],
		public productosAsociados: ProductoAsociadoReserva[] = [],
		public UUID?: string,
		public producto?: ProductoCabecera,
		public hotel?: Hotel,
		public direccionHotel?: string,
		public telefonoContacto?: string,
		public saldo: number = 0,
		public monedaReserva?: Moneda,
		public estadoConsentimiento?: EstadoReserva,
		public notaCliente?: string,
		public token?: string,
		public direccionAlternativa?: string,
		lastModifiedBy?: string,
		lastModifiedDate?: Date,
		public nombreCliente?: string,
		public fechaActividad?: Date,
		public cantidadPaxReserva?: number,
		public variante?: TipoVariante,
		public referencia?: string,
		public email?: string
	) {
		super(lastModifiedBy, lastModifiedDate)
		if (!UUID) this.UUID = this.id ? this.id + "" : ID()
	}
	public static fromData(data: any): ItemResumenReserva {
		if (!data) return null
		const o: ItemResumenReserva = new ItemResumenReserva(
			data.id,
			data.idReserva,
			data.nombrePax,
			data.observaciones,
			data.cantidadPax,
			Idioma.fromData(data.idiomaServicio),
			data.pickUp ? new Date(data.pickUp) : null,
			Descriptivo.fromData(data.cliente),
			data.restricciones ? data.restricciones.map((r) => Restriccion.fromData(r)) : [],
			data.productosAsociados ? data.productosAsociados.map((r) => ProductoAsociadoReserva.fromData(r)) : [],
			data.UUID,
			ProductoCabecera.fromData(data.producto),
			Hotel.fromData(data.hotel),
			data.direccionHotel,
			data.telefonoContacto,
			data.saldo,
			Moneda.fromData(data.monedaReserva),
			EstadoReserva.fromData(data.estadoConsentimiento),
			data.notaCliente,
			data.token,
			data.direccionAlternativa
		)
		o.variante = TipoVariante.fromData(data.variante)
		o.nombreCliente = data.nombreCliente
		o.fechaActividad = data.fechaActividad ? new Date(data.fechaActividad) : null
		o.referencia = data.referencia
		o.email = data.email
		return o
	}
	get colorVariante() {
		return this.variante?.color || "#000000"
	}
	get descVariante() {
		return this.variante?.descripcion || ""
	}
	public get descripcion() {
		return this.nombrePax
	}

	public get direccion() {
		return this.direccionAlternativa || this.hotel?.direccion
	}
	public get referenciaDireccion() {
		return this.direccionAlternativa || this.hotel?.descripcion + " - " + this.hotel?.direccion
	}
	public get descripcionServicio() {
		return this.producto?.nombreComercialCompuesto
	}
	public get contactos() {
		return {
			whatsapp: this.telefonoContacto,
			telefono: this.telefonoContacto,
			email: this.email
		}
	}
}
