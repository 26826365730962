import { GrupoPax } from 'src/app/model/GrupoPax';
import { GrupoReserva } from './../../../model/GrupoReserva';
export class MovimientoDragDrop {
    constructor(
        public grupoOrigen: GrupoPax,
        public grupoReservaOrigen: GrupoReserva,
        public cantidad: number = 0,
        public tipo?: string,
        public grupoDestino?: GrupoPax,
        public grupoReservaDestino?: GrupoReserva,
    ) {

    }

    public static fromData(data) {
        if (!data) return;
        let m = new MovimientoDragDrop(
            GrupoPax.fromData(data.grupoOrigen),
            GrupoReserva.fromData(data.grupoReservaOrigen),
            data.cantidad,
            data.tipo,
            GrupoPax.fromData(data.grupoOrigen),
            GrupoReserva.fromData(data.grupoReservaOrigen),
        );
        return m
    }
}