import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-unidad',
  templateUrl: './help-unidad.component.html',
  styleUrls: ['./help-unidad.component.less']
})
export class HelpUnidadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
