import * as moment from "moment"
import { Idioma } from "src/app/model/Idioma"
import { Auditable } from "./Auditable"
import { Consentimiento } from "./Consentimiento"
import { ItemReservaConsentimiento } from "./ItemReservaConsentimiento"
import { Moneda } from "./Moneda"
import { PuntoDeVenta } from "./PuntoDeVenta"
import { RespuestaEncuestaActividades } from "./RespuestaEncuestaActividades"

export class ReservaConsentimiento extends Auditable {
	constructor(
		public id?: number,
		public nombrePax?: string,
		public cantidadPax?: number,
		public idiomaPax?: Idioma,
		public itemsReserva: ItemReservaConsentimiento[] = [],
		public consentimiento?: Consentimiento,
		public encuestas?: RespuestaEncuestaActividades[],
		public puntoDeVenta?: PuntoDeVenta,
		lastModifiedBy?: string,
		lastModifiedDate?: Date,
		public version?: number,
		public observaciones?: string,
		public moneda?: Moneda,
		public saldo?: number,
		public importeTotal?: number,
		public verEstadoCuenta: boolean = false
	) {
		super(lastModifiedBy, lastModifiedDate)
		this.ordenarItems()
	}
	public static fromData(data: any): ReservaConsentimiento {
		if (!data) return null
		const o: ReservaConsentimiento = new ReservaConsentimiento(
			data.id,
			data.nombrePax,
			data.cantidadPax,
			Idioma.fromData(data.idiomaPax),
			data.itemsReserva?.length ? data.itemsReserva.map((i) => ItemReservaConsentimiento.fromData(i)) : [],
			Consentimiento.fromData(data.consentimiento),
			data.encuestas?.length ? data.encuestas?.map((e) => RespuestaEncuestaActividades.fromData(e)) : [],
			PuntoDeVenta.fromData(data.puntoDeVenta)
		)
		o.version = data.version
		o.moneda = Moneda.fromData(data.moneda)
		o.saldo = data.saldo
		o.importeTotal = data.importeTotal
		o.verEstadoCuenta = data.verEstadoCuenta || false
		o.observaciones = data.observaciones
		return o
	}
	public ordenarItems() {
		this.itemsReserva = this.itemsReserva.sort((a, b) => (moment(a.fechaActividad).isAfter(moment(b.fechaActividad)) ? 1 : -1))
	}
	public agregarItem(item: ItemReservaConsentimiento) {
		this.itemsReserva.push(item)
		this.ordenarItems()
	}

	public quitarItem(item: ItemReservaConsentimiento, index?: number) {
		this.itemsReserva = this.itemsReserva.filter((o, i) => (o.id ? item.id != o.id : index != i))
	}
	public get requiereConsentimiento(): boolean {
		return this.itemsReserva?.some((i) => i.producto.requiereConsentimiento)
	}
}
