import { Parametrico } from "./../common/model/Parametrico"

export class TipoIVA extends Parametrico {
	public static CONSUMIDOR_FINAL: string = "5"
	public static CLIENTE_EXTERIOR: string = "9"
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado: boolean = true,
		esSistema: boolean = false,
		peso: number = 99,

		public valorIva?: number
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso)
	}
	public tipoParametro(): string {
		return "tipo-iva"
	}
	public static fromData(data: any): TipoIVA {
		if (!data) return null
		let o: TipoIVA = new TipoIVA(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			data.esSistema,
			data.peso,

			data.valorIva
		)

		o.version = data.version
		return o
	}
}
