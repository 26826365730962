import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { ConfirmationService, MenuItem } from "primeng/api"
import { AppComponent } from "./app.component"
import { DEF_LOGO } from "./app.module"
import { AdminAuthGuard } from "./authguards/AdminAuthGuard"
import { AdministracionAuthGuard } from "./authguards/AdministracionAuthGuard"
import { FacturasAG } from "./authguards/FacturasAG"
import { GestorCajaAuthGuard } from "./authguards/GestorCajaAuthGuard"
import { GestorPlantillasAG } from "./authguards/GestorPlantillasAG"
import { NacionalidadAG } from "./authguards/NacionalidadAG"
import { NotificacionAG } from "./authguards/NotificacionesAG"
import { ProductosAG } from "./authguards/ProductosAG"
import { PuntoDeVentaAG } from "./authguards/PuntoDeVentaAG"
import { RestriccionesAG } from "./authguards/RestriccionesAG"
import { IdiomaService } from "./common/services/idioma.service"
import { LoadingService } from "./common/services/loading-data-service.service"
import { MessagesService } from "./common/services/messages-data-service.service"
import { NotificacionService } from "./common/services/notificaciones.service"
import { TranslateService } from "./common/services/translate.service"
import { StringUtils } from "./common/utils/string-utils"
import { ConfiguracionUsuario } from "./model/ConfiguracionUsuario"
import { Idioma } from "./model/Idioma"
import { Notificacion } from "./model/Notificacion"
import { Novedad } from "./model/Novedad"
import { Recordatorio } from "./model/Recordatorio"
import { LayoutService } from "./services/app.layout.service"
import { AuthService } from "./services/auth.service"
import { ConfiguracionUsuarioService } from "./services/configuracion-usuario.service"
import { IActualizable, NovedadService } from "./services/novedades.service"

import packageJson from "../../package.json"
import { HotelesAG } from "./authguards/HotelesAG"
import { ConfiguracionEntornoService } from "./services/configuracion-entorno.service"
@Component({
	selector: "app-topbar",
	templateUrl: "app.topbar.component.html",
	styleUrls: ["app.topbar.component.less"]
})
export class AppTopbarComponent implements OnInit, IActualizable {
	public itemsConfiguracion: MenuItem[] = []
	public version: string
	public get usuario() {
		return this.authService.usuario
	}
	public logo = DEF_LOGO
	public verConfiguracion: boolean = false
	private _idiomaSeleccionado: string
	public get idiomaSeleccionado(): Idioma {
		return this.idiomas.find((i) => i.codigo == this._idiomaSeleccionado)
	}
	public set idiomaSeleccionado(v: Idioma) {
		this._idiomaSeleccionado = v?.codigo || Idioma.DEFAULT_CODIGO
		this.translateService && this.translateService.use(v.codigo)
		if (v?.codigo) localStorage.setItem("IDIOMA", v.codigo)
	}
	public idiomas: Idioma[] = []
	public componentThemes = []
	public componentTheme
	public colorScheme
	public configUsuario: ConfiguracionUsuario
	public notificaciones: Novedad[] = []

	private _novedades: Notificacion[]
	public get novedades(): Notificacion[] {
		return this._novedades
	}
	public set novedades(v: Notificacion[]) {
		this._novedades = v
	}
	loadingNotificaciones = false
	public notificacionesNuevas: number = 0
	public verNotificacion

	constructor(
		public app: AppComponent,
		private authService: AuthService,
		private gestorCajaAG: GestorCajaAuthGuard,
		private gestorPlantillasAG: GestorPlantillasAG,
		private facturasAG: FacturasAG,
		private adminAuthGuard: AdminAuthGuard,
		private router: Router,
		private gestorNotificacion: NotificacionAG,
		private productoAg: ProductosAG,
		private jefeAdministracionAG: AdministracionAuthGuard,
		private puntoVentaAG: PuntoDeVentaAG,
		private nacionalidadAG: NacionalidadAG,
		private translateService: TranslateService,
		private idiomaService: IdiomaService,
		private confUsuario: ConfiguracionUsuarioService,
		private restriccionesAG: RestriccionesAG,
		public layoutService: LayoutService,
		private novedadesService: NovedadService,
		private notificacionesService: NotificacionService,
		private messagesService: MessagesService,
		private confirmationService: ConfirmationService,
		private ceService: ConfiguracionEntornoService,
		private hotelesAg: HotelesAG
	) {
		this.version = packageJson.version
	}
	next(n: Novedad) {
		//if (n.destinatario == this.authService.usuario?.email) {
		//this.notificaciones = [...this.notificaciones, n];
		//this.messagesService.info(StringUtils.htmlToPlainText(n.cabecera), StringUtils.htmlToPlainText(n.body));
		this.notificacionesNuevas++
		//}
	}
	public mainLogo: string = DEF_LOGO
	destroy() {}
	getKey() {
		"recordatorio"
	}
	ngOnInit() {
		this.idiomaService.getAll().then((i) => {
			this.idiomas = i
			this.idiomaSeleccionado = this.idiomas.find((i) => i.codigo == (localStorage.getItem("IDIOMA") || Idioma.DEFAULT_CODIGO))
		})
		this.componentThemes = [
			{ name: "indigo", color: "#3F51B5" },
			{ name: "pink", color: "#E91E63" },
			{ name: "purple", color: "#9C27B0" },
			{ name: "deeppurple", color: "#673AB7" },
			{ name: "blue", color: "#2196F3" },
			{ name: "lightblue", color: "#03A9F4" },
			{ name: "cyan", color: "#00BCD4" },
			{ name: "teal", color: "#009688" },
			{ name: "iterpax", color: "#50b587" },
			{ name: "green", color: "#4CAF50" },
			{ name: "lightgreen", color: "#8BC34A" },
			{ name: "lime", color: "#CDDC39" },
			{ name: "yellow", color: "#FFEB3B" },
			{ name: "tierra", color: "#E36A25" },
			{ name: "amber", color: "#FFC107" },
			{ name: "orange", color: "#FF9800" },
			{ name: "deeporange", color: "#FF5722" },
			{ name: "brown", color: "#795548" },
			{ name: "bluegrey", color: "#607D8B" }
		]
		this.app.translateService.upateData.subscribe((r) => {
			this.generarMenu()
		})
		this.notificacionesService.suscribirAlerta((n) => this.next(n))
		this.novedadesService.registrarObservador(Recordatorio.NOMBRE_ENTIDAD, this)
		this.ceService.getConfiguracion().then((r) => {
			this.mainLogo = r.profilePic?.picPath || "assets/image/iterpax.png"
		})
		this.authService.getAutenticateState().subscribe((r) => {
			if (r == AuthService.LOGUEADO) {
				this.generarMenu()
				this.getMisNotificaciones()
			}
		})
	}
	changeThemelight(event, style) {
		this.layoutService.updateLayout({ colorScheme: style, componentTheme: this.layoutService.config.componentTheme }).then((r) => {
			this.layoutService.config.colorScheme = style?.replace("--", "-")

			this.colorScheme = style
			this.confUsuario.updateThemeConfig("colorScheme", style?.replace("--", "-"))
			this.layoutService.onConfigUpdate()
		})
	}
	changeTheme(theme: string) {
		const themeLink = <HTMLLinkElement>document.getElementById("theme-link")
		const newHref = themeLink.getAttribute("href")!.replace(this.layoutService.config.componentTheme, theme)
		this.layoutService.replaceThemeLink(newHref, "theme-link").then(() => {
			this.layoutService.config.componentTheme = theme
			this.componentTheme = theme
			this.confUsuario.updateThemeConfig("componentTheme", theme)
			this.layoutService.onConfigUpdate()
		})
	}
	public sendRefresh() {
		this.getMisNotificaciones()
	}
	public isMobile() {
		return window.innerWidth <= 640
	}
	public verUsuario() {
		this.router.navigate(["usuario/edit"], { queryParams: { id: this.usuario.id } })
	}
	public irAHome() {
		this.usuario && this.router.navigate(["home"])
	}
	public navegarParam(path, multilenguaje: boolean = false) {
		this.router.navigate(["/parametros"], { queryParams: { tipo: path, multi: multilenguaje } })
	}
	public esAdministrador() {
		return this.usuario.esAdmin()
	}
	public visible() {
		return this.itemsConfiguracion.some((i) => i.visible)
	}
	public displayNotificaciones() {
		alert("Hola")
	}
	private generarMenu() {
		let $this = this
		const items = [
			{
				label: this.app.translateService.get("CATEGORIA-PUESTO_PARAM_TITULO"),
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				icon: "fa fa-cog",
				command: () => {
					$this.navegarParam("categoria-puesto")
				}
			},
			{
				label: this.app.translateService.get("Restricciones"),
				icon: "fa fa-wheelchair",
				visible: this.restriccionesAG.esVisible(this.authService.usuario),
				routerLink: ["restriccion"]
			},
			{
				label: this.app.translateService.get("CONFIGURACIÓN_DE_ENTORNO"),
				icon: "fa fa-cog",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["configuracion-entorno"]
			},
			{
				label: this.app.translateService.get("Notificaciones"),
				icon: "fa fa-envelope-o",
				visible: this.gestorNotificacion.esVisible(this.authService.usuario),
				routerLink: ["notificacion"]
			},
			{
				label: this.app.translateService.get("Automatizaciones"),
				icon: "fa fa-play",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["job-configuration"]
			},
			{
				label: this.app.translateService.get("FAMILIA_PRODUCTO"),
				icon: "fa fa-cubes",
				visible: this.productoAg.esVisible(this.authService.usuario),
				routerLink: ["familia-producto"]
			},
			{
				label: this.app.translateService.get("TIPO-PROVEEDOR_PARAM_TITULO"),
				icon: "fa fa-user",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				command: () => {
					$this.navegarParam("tipo-proveedor")
				}
			},
			{
				label: this.app.translateService.get("TIPO-PUESTO_PARAM_TITULO"),
				icon: "fa fa-user",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["parametros/tipo-puesto"]
			},
			{
				label: this.app.translateService.get("Tags"),
				icon: "fa fa-tag",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["tag-usuario"]
			},
			{
				label: this.app.translateService.get("EMAIL_TEMPLATE"),
				icon: "fa fa-envelope",
				visible: this.gestorPlantillasAG.esVisible(this.authService.usuario),
				routerLink: ["emailtemplate"]
			},
			{
				label: this.app.translateService.get("FORMA-PAGO_PARAM_TITULO"),
				icon: "fa fa-money",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["forma-pago"]
			},
			{
				label: this.app.translateService.get("NACIONALIDAD_TITULO"),
				icon: "fa fa-flag",
				visible: this.nacionalidadAG.esVisible(this.authService.usuario),
				routerLink: ["parametros/nacionalidad"]
			},
			{
				label: this.app.translateService.get("MONEDAS"),
				icon: "fa fa-money",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["moneda"]
			},
			{
				label: this.app.translateService.get("IDIOMA_TITULO"),
				icon: "fa fa-commenting",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["idioma"]
			},
			{
				label: this.app.translateService.get("TIPO-CLIENTE_PARAM_TITULO"),
				icon: "fa fa-user",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				command: () => {
					$this.navegarParam("tipocliente")
				}
			},
			{
				label: this.app.translateService.get("TIPO-VEHICULO_PARAM_TITULO"),
				icon: "fa fa-car",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				command: () => {
					$this.navegarParam("tipovehiculo")
				}
			},
			{
				label: this.app.translateService.get("TIPO-IVA_PARAM_TITULO"),
				icon: "fa fa-dollar",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				command: () => {
					$this.navegarParam("tipo-iva")
				}
			},
			{
				label: this.app.translateService.get("CANAL-VENTA_PARAM_TITULO"),
				icon: "fa fa-user",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				command: () => {
					$this.navegarParam("canal-venta")
				}
			},
			{
				label: this.app.translateService.get("ESTADO-VEHICULO_PARAM_TITULO"),
				icon: "fa fa-check",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				command: () => {
					$this.navegarParam("estado-vehiculo")
				}
			},
			{
				label: this.app.translateService.get("CUENTA-CONTABLE_TITLE"),
				icon: "fa fa-book",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["cuenta-contable"]
			},
			{
				label: this.app.translateService.get("TipoTarjetaCredito_PLR"),
				icon: "fa fa-credit-card",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["tipo-tarjeta-credito"]
			},
			{
				label: this.app.translateService.get("Cuotas con Tarjeta"),
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				icon: "fa fa-credit-card",
				routerLink: ["tipo-cuotas-tarjeta"]
			},

			{
				label: this.app.translateService.get("INSUMO_TITLE"),
				icon: "fa fa-gift",
				visible: this.facturasAG.esVisible(this.authService.usuario),
				routerLink: ["insumo"]
			},
			{
				label: this.app.translateService.get("UNIDAD_TITLE"),
				icon: "fa fa-balance-scale ",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["unidad"]
			},
			{
				label: this.app.translateService.get("ESTADOS_RESERVA"),
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["estado-reserva"]
			},
			{
				label: this.app.translateService.get("TIPO-INGRESO-BRUTO_PARAM_TITULO"),
				icon: "fa fa-money",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				command: () => {
					$this.navegarParam("tipo-ingreso-bruto")
				}
			},
			{
				label: this.app.translateService.get("TIPO_DOCUMENTO"),
				icon: "fa fa-id",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				command: () => {
					$this.navegarParam("tipoDocumento")
				}
			},
			{
				label: this.app.translateService.get("Categorías de producto"),
				icon: "fa fa-cog",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["categoria-producto"]
			},
			{
				label: this.app.translateService.get("Variantes de producto"),
				icon: "fa fa-gift",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["tipo-variante"]
			},
			{
				label: this.app.translateService.get("CENTRO-COSTO_TITLE"),
				icon: "fa fa-money",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["centro-costo"]
			},
			{
				label: this.app.translateService.get("PUNTOS_VENTA"),
				icon: "fa fa-map",
				visible: this.puntoVentaAG.esVisible(this.authService.usuario),
				routerLink: ["punto-de-venta"]
			},
			{
				label: this.app.translateService.get("TIPO-COMPROBANTE_PARAM_TITULO"),
				icon: "fa fa-money",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["tipo-comprobante"]
			},
			{
				label: this.app.translateService.get("CAJA_TITLE"),
				visible: this.gestorCajaAG.esVisible(this.authService.usuario) || this.adminAuthGuard.esVisible(this.authService.usuario),
				icon: "fa fa-money",
				routerLink: ["caja"]
			},
			{
				label: this.app.translateService.get("CANALES_COMUNICACION"),
				icon: "fa fa-phone",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["canal-comunicacion"]
			},
			{
				label: this.app.translateService.get("TipoNotificacion_PLR"),
				icon: "fa fa-envelope",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["tipo-notificacion"]
			},
			{
				label: this.app.translateService.get("TIPO_AUSENCIA"),
				icon: "fa fa-calendar-times-o",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["tipo-ausencia"]
			},
			{
				label: this.app.translateService.get("SUBCATEGORIA_IMPONIBLE"),
				icon: "fa fa-tag",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["subcategoria-imponible"]
			},
			{
				label: this.app.translateService.get("PlantillaPregunta"),
				icon: "fa fa-question",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["plantilla-pregunta"]
			},
			{
				label: this.app.translateService.get("EncuestaActividades"),
				icon: "fa fa-list",
				visible: this.adminAuthGuard.esVisible(this.authService.usuario),
				routerLink: ["encuesta-actividades"]
			},
			{
				label: this.app.translateService.get("CARGA_MASIVA"),
				icon: "pi pi-upload",
				visible: this.jefeAdministracionAG.esVisible(this.authService.usuario),
				routerLink: ["carga-masiva"]
			},
			{
				label: this.app.translateService.get("PROVIDER_CONFIGURATION_PLR"),
				icon: "fa fa-link",
				visible: this.jefeAdministracionAG.esVisible(this.authService.usuario),
				routerLink: ["provider-configuration"]
			},
			{
				label: this.app.translateService.get("INCLUSION_PLR"),
				icon: "fa fa-plus",
				visible: this.jefeAdministracionAG.esVisible(this.authService.usuario),
				routerLink: ["inclusion"]
			},
			{
				label: this.translateService.get("TagProducto_PLR"),
				icon: "fa fa-tag",
				routerLink: ["tag-producto"],
				visible: this.jefeAdministracionAG.esVisible(this.authService.usuario)
			},
			{
				label: this.translateService.get("REGION_PLR"),
				icon: "fa fa-map",
				routerLink: ["region"],
				visible: this.jefeAdministracionAG.esVisible(this.authService.usuario)
			},
			{
				label: this.translateService.get("PAIS_PLR"),
				icon: "fa fa-map",
				routerLink: ["pais"],
				visible: this.jefeAdministracionAG.esVisible(this.authService.usuario)
			},
			{
				label: this.app.translateService.get("CategoriaHospedaje_PLR"),
				icon: "fa fa-building",
				visible: this.hotelesAg.esVisible(this.authService.usuario),
				routerLink: ["categoria-hospedaje"]
			}
		].sort((a, b) => (a.label > b.label ? 1 : -1))
		this.itemsConfiguracion = items
		this.verConfiguracion = this.itemsConfiguracion.length > 0
	}
	navegarEntidad = (notificacion: Notificacion) => {
		if (notificacion.idEntidadOrigen && notificacion.tipoEntidadOrigen) {
			this.router.navigate([StringUtils.toPathFormat(notificacion.tipoEntidadOrigen), "vista"], { queryParams: { id: notificacion.idEntidadOrigen } })
		} else {
			this.verNotificacion = notificacion
		}
	}
	eliminarNotificaciones() {
		this.confirmationService.confirm({
			key: "genConf",
			header: this.translateService.get("Quitar notificaciones"),
			message: this.translateService.get("Esta operación no se puede deshacer. Ya no verá las notificaciones eliminadas. ¿Desea continuar?"),
			accept: () => {
				const promesas = []
				this.novedades.forEach((n) => {
					promesas.push(this.notificacionesService.ocultarNotificacion(n.id, new LoadingService()))
				})
				Promise.all(promesas).then(() => this.getMisNotificaciones())
			}
		})
	}
	mostrarNotificaciones() {
		if (this.notificacionesNuevas > this.novedades.length) this.getMisNotificaciones()
	}
	getMisNotificaciones() {
		this.loadingNotificaciones = true
		this.notificacionesService.getMisNotificaciones().then((n) => {
			this.novedades = n
			this.loadingNotificaciones = false
			this.notificacionesNuevas = n.length
		})
	}
	public ocultarNotificacion(notificacion: Notificacion) {
		this.notificacionesService.ocultarNotificacion(notificacion.id).then((n) => {
			this.novedades = this.novedades.filter((nov) => nov.id != notificacion.id)
			this.notificacionesNuevas = this.novedades.length
		})
	}
}
