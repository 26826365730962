import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { Filtro } from "src/app/common/model/Filtro"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { FormaPago } from "src/app/model/FormaPago"
import { FormaPagoService } from "src/app/services/forma-pago.service"

@Component({
	selector: "listado-forma-pago",
	templateUrl: "listado-forma-pago.component.html",
	styleUrls: ["listado-forma-pago.component.less"]
})
export class ListadoFormaPagoComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: Filtro

	@Input()
	public guardarFiltro: boolean = true
	@Input()
	public ocultarCabecera: boolean = false
	@Input()
	public editable: boolean = true
	public listado: FormaPago[] = []
	@Input()
	public readonly: boolean = false
	@Input()
	public modoSeleccion: "single" | "multiple" | "none" = "none"

	@Output()
	public onSelect: EventEmitter<FormaPago[]> = new EventEmitter()

	public columns = []
	constructor(messagesService: MessagesService, public service: FormaPagoService) {
		super(messagesService)
	}
	ngOnInit() {
		this.filtro = new Filtro("forma-pago_lista", {}, 0, 20, "id", 1, this.guardarFiltro)
		this.columns = [
			{
				field: "icono",
				header: "Icono",
				class: "profile-pic",
				role: "profile-tabla",
				sortable: false,
				order: 40
			},
			{
				field: "codigo",
				header: "Codigo",
				class: "codigo-tabla",
				role: "text-tabla",
				sortable: true,
				order: 1
			},
			{
				field: "descripcion",
				header: "Descripcion",
				class: "text-tabla",
				role: "text-tabla",
				sortable: true,
				order: 3
			},
			{
				field: "habilitado",
				header: "Habilitado",
				class: "is-boolean",
				role: "boolean-tabla",
				sortable: false,
				order: 99
			}
		]
	}

	public updateSeleccion(seleccion: any) {
		if (seleccion?.length || seleccion["id"]) {
			this.onSelect.emit(seleccion?.length ? seleccion : [seleccion])
		}
	}
	ngOnDestroy() {}
}
