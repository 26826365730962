import { Telefono } from './Telefono';
import * as moment from "moment";
import { EstadoReserva } from "./EstadoReserva";

export class ConsentimientoReporte {
    constructor(
        public idConsentimiento: number,
        public fechaActividad: string,
        public idReserva: number,
        public idProducto: number,
        public nombrePax: string,
        public apellidoPax: string,
        public fechaNacimientoPax: string,
        public tipoDocumentoPax: string,
        public numeroDocumentoPax: string,
        public nombreCliente: string,
        public aceptado: boolean,
        public observaciones: string,
        public descripcionProducto?: string,
        public estadoConsentimiento?:EstadoReserva,
        public telefonoContacto?: string,

    ) {}

    public static fromData(data): ConsentimientoReporte {
        return new ConsentimientoReporte(
            data.idConsentimiento,
            data.fechaActividad ? moment(data.fechaActividad).format('DD/MM/YYYY') : null,
            data.idReserva,
            data.idProducto,
            data.nombrePax, 
            data.apellidoPax,
            data.fechaNacimientoPax ? moment(data.fechaNacimientoPax).format('DD/MM/YYYY') : null,
            data.tipoDocumentoPax,
            data.numeroDocumentoPax ,
            data.nombreCliente ,
            data.aceptado ,
            data.observaciones ,
            data.descripcionProducto,
            EstadoReserva.fromData(data.estadoConsentimiento),
            data.telefonoContacto
        )   
    }

    public get nombre(): string {

        return this.nombrePax && this.apellidoPax ? `${this.nombrePax} ${this.apellidoPax}` : this.nombreCliente
    }

    public get documento(): string {
        return this.tipoDocumentoPax && this.numeroDocumentoPax ? `${this.tipoDocumentoPax} ${this.numeroDocumentoPax}` : ""
    }
}

