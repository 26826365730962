import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { CobranzasAG } from "src/app/authguards/CobranzasAG"
import { ComprobantesAG } from "src/app/authguards/ComprobantesAG"
import { FacturasAG } from "src/app/authguards/FacturasAG"
import { OrdenPagoAG } from "src/app/authguards/OrdenPagoAG"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { AccionesTabla } from "src/app/common/model/AccionesTabla"
import { Filtro } from "src/app/common/model/Filtro"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { MotivoCancelacionService } from "src/app/services/motivo-cancelacion.service"
import { AdministracionAuthGuard } from "./../../../authguards/AdministracionAuthGuard"
import { CuentaCorriente } from "./../../../model/CuentaCorriente"
import { IServicioCuentaCorriente } from "./../../../services/IServicioCuentaCorriente"
import { AccionesDocumentoService } from "./../../../services/acciones-documento.service"
import { ProveedorService } from "./../../../services/proveedor.service"

@Component({
	selector: "cuenta-corriente",
	templateUrl: "./cuenta-corriente.component.html",
	styleUrls: ["./cuenta-corriente.component.less"]
})
export class CuentaCorrienteComponent extends SessionComponent implements OnInit {
	public columns
	public anularDocumento: any
	public anularDocumentoOperacion: string
	public verAnulacionDocumento: boolean = false
	@Input()
	public esEmbebida: boolean = true
	@ViewChild("documentoRef", { static: true })
	public documentoRef: TemplateRef<any>

	@ViewChild("entradaRef", { static: true })
	public entradaRef: TemplateRef<any>

	@ViewChild("salidaRef", { static: true })
	public salidaRef: TemplateRef<any>

	@Output()
	public accionEjecutada: EventEmitter<any> = new EventEmitter()
	constructor(
		messageService: MessagesService,
		private router: Router,
		public accionesDocumentoService: AccionesDocumentoService,
		public proveedorService: ProveedorService,
		public confirmationService: ConfirmationService,
		public administadorAG: AdministracionAuthGuard,
		public motivoService: MotivoCancelacionService,
		public facturaAG: FacturasAG,
		public ordenesDePagoAG: OrdenPagoAG,
		public comprobantesAG: ComprobantesAG,
		public cobranzaAG: CobranzasAG
	) {
		super(messageService)
	}

	public cuentaCorriente: CuentaCorriente[] = []
	public verComprobante(data: CuentaCorriente, event?) {
		switch (data.tipoOperacion) {
			case CuentaCorriente.COBRANZA:
			case CuentaCorriente.DEVOLUCION:
				if (this.cobranzaAG.esVisible(this.usuario)) this.router.navigate(["cobranza/vista"], { queryParams: { id: data.idOperacion } })
				break
			case CuentaCorriente.VENTA:
				if (this.comprobantesAG.esVisible(this.usuario)) this.router.navigate(["comprobante/vista"], { queryParams: { id: data.idOperacion } })
				break
			case CuentaCorriente.ORDENPAGO:
				if (this.ordenesDePagoAG.esVisible(this.usuario)) this.router.navigate(["orden-pago/vista"], { queryParams: { id: data.idOperacion } })
				break
			case CuentaCorriente.COMPRA:
				if (this.facturaAG.esVisible(this.usuario)) this.router.navigate(["factura/vista"], { queryParams: { id: data.idOperacion } })
				break

			default:
				break
		}
	}
	@Input()
	public servicio: IServicioCuentaCorriente

	public customLoading: LoadingService = new LoadingService()
	public totalPagos: number = 0
	@Input()
	public tipoCuenta: string = "cliente"

	private _filtro: Filtro
	public get filtro(): Filtro {
		return this._filtro
	}
	@Input()
	public set filtro(v: Filtro) {
		this._filtro = v
	}

	ngOnInit() {
		this.columns = [
			{
				field: "fechaDisplay",
				header: "Fecha",
				role: "text-tabla",
				sortable: false,
				esMobile: true,
				width: "7rem"
			},
			{
				field: "operacion",
				header: "Operación",
				role: "template",
				esMobile: true,
				width: "12rem",
				template: this.documentoRef
			},
			{
				field: "descripcion",
				header: "Descripcion",
				role: "text-tabla",
				esMobile: true,
				class: "overflow"
			},
			{
				field: "salida",
				header: "Debe",
				role: "template",
				class: "text-right",
				width: "10rem",
				template: this.salidaRef
			},
			{
				field: "entrada",
				header: "Haber",
				role: "template",
				class: "text-right",
				width: "10rem",
				template: this.entradaRef
			}
		]
		this.acciones.push(
			new AccionesTabla("Anular", "pi pi-ban", "accion-tabla", this.anularDocumentoAccion, this.esAnularDocumentoAccion),
			new AccionesTabla(
				"Descargar comprobante",
				"pi pi-download",
				"accion-tabla",
				(data, event) => {
					this.accionesDocumentoService.getService(CuentaCorriente.VENTA).descargar(data.idOperacion, data.numeroOperacion)
					if (event) event.stopPropagation()
				},
				(data: CuentaCorriente) => {
					return data.tipoOperacion === CuentaCorriente.VENTA
				}
			),
			new AccionesTabla(
				"Ver comprobante PDF",
				"pi pi-file-pdf",
				"accion-tabla",
				(data, event) => {
					this.accionesDocumentoService.getService(CuentaCorriente.VENTA).verPDF(data.idOperacion)
					if (event) event.stopPropagation()
				},
				(data: CuentaCorriente) => {
					return data.tipoOperacion === CuentaCorriente.VENTA
				}
			),

			new AccionesTabla(
				"Enviar al proveedor",
				"pi pi-envelope",
				"accion-tabla",
				(data) => {
					this.notificarProveedor(data)
				},
				(data) => {
					return data.tipoOperacion === CuentaCorriente.ORDENPAGO
				}
			)
		)
	}
	public getData = (f: Filtro, l?: LoadingService) => {
		if (!f) Promise.resolve([])
		f.size = 9999
		return this.servicio
			? this.servicio.getCuentaCorriente(f, this.customLoading).then((c) => {
					this.cuentaCorriente = c
						.filter((cc) => cc.tieneValor)
						.sort((a, b) => {
							return a.fechaOperacion > b.fechaOperacion ? -1 : 1
						})
					if (this.filtro.page == 0) {
						this.filtro.count = this.cuentaCorriente.length
					}
					return this.cuentaCorriente
			  })
			: Promise.resolve([])
	}

	public getCount = (f: Filtro, l?: LoadingService) => {
		return Promise.resolve(this.filtro.count)
	}
	public acciones: AccionesTabla[] = []
	public onClick = (item, event?) => {
		this.verComprobante(item)
		event?.stopPropagation && event.stopPropagation()
	}

	public notificarProveedor(c: CuentaCorriente) {
		this.accionesDocumentoService
			.getService(c.tipoOperacion)
			.getById(c.idOperacion)
			.then((doc) => {
				let emailDdestinatario: string = ""
				this.proveedorService.getById(c.cliente.id).then((r) => {
					if (r.emailAdministrativo) {
						emailDdestinatario = r.emailAdministrativo
					} else {
						emailDdestinatario = r.email
					}
					this.accionesDocumentoService
						.getService(c.tipoOperacion)
						.notificarProveedor(doc, emailDdestinatario)
						.then((r) => {
							this.success("Se envió la notificación")
						})
				})
			})
	}

	public anularDocumentoAccion = (c: CuentaCorriente) => {
		let $this = this

		this.accionesDocumentoService
			.getService(c.tipoOperacion)
			.getById(c.idOperacion)
			.then((doc) => {
				let esAnular: boolean = false
				let message: string = "No se Permite anular"

				switch (c.tipoOperacion) {
					case CuentaCorriente.COBRANZA:
						esAnular = doc.estado?.codigo != "P" && doc.estado?.codigo != "C"
						message = "Solo se puede anular cobros pendientes de aprobación"
						break
					case CuentaCorriente.VENTA:
						esAnular = doc.id && doc.saldo == doc.importe
						message = "Solo se pueden anular comprobantes con cobros asociados"
						break
					case CuentaCorriente.ORDENPAGO:
						doc.estado?.codigo != "C" ? (esAnular = true) : (esAnular = false)
						message = "No se pueden anular ordenes de pago canceladas"
						break
					case CuentaCorriente.COMPRA:
						doc.tipoComprobante?.codigo != "PA" && doc.saldo != 0 && doc.saldo == doc.importe && doc.estado.codigo != "AN"
							? (esAnular = true)
							: (esAnular = false)
						message = "Solo se pueden anular facturas con saldos pendientes que no esten anuladas"
						break

					default:
						break
				}
				if (esAnular) {
					this.confirmationService.confirm({
						key: "genConf",
						header: "Anular",
						message: this.translateService.get("DESEA_ELIMINAR_EL__18"),
						accept: () => {
							$this.anularDocumento = doc
							$this.verAnulacionDocumento = true
							$this.anularDocumentoOperacion = c.tipoOperacion
							$this.filtro.forceUpdate()
						}
					})
				} else {
					$this.error(message)
				}
			})
	}

	public esAnularDocumentoAccion = (c: CuentaCorriente): boolean => {
		return this.administadorAG.esVisible(this.usuario)
	}

	public confirmarAnulacionCoranza() {
		let $this = this
		this.accionesDocumentoService
			.getService(this.anularDocumentoOperacion)
			.accionAnular(this.anularDocumento)
			.then((res) => {
				$this.success(this.translateService.get("EL_ITEM_FUE_ELIMINA_15"))
				$this.verAnulacionDocumento = false
				$this.anularDocumento = null
				$this.filtro.forceUpdate()
			})
	}
}
