<div class="gestor-centro-costo content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'CentroCosto' | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		[file]="file"
		[conArchivo]="true"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="CentroCostoForm"
		[multilenguaje]="false"
		(onEditar)="editar()"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #CentroCostoForm="ngForm" id="CentroCostoform">
				<div class="grid" *ngIf="item">
					<div class="lg:col-2 pic-wrapper col-4">
						<div class="pic">
							<profile-badge
								[seleccionable]="!readonly"
								[url]="item?.profilePic?.picPathVersion"
								class=""
								name="profilePic"
								(onFileSelected)="onPicSelected($event)"
							>
							</profile-badge>
							<i class="fa fa-pencil edit-icon" *ngIf="!readonly"></i>
						</div>
					</div>
					<div class="col-12 lg:col-6">
						<div class="p-inputgroup">
							<span class="p-float-label">
								<input
									pInputText
									placeholder="{{ 'DESCRIPCION_PH' | translate }}"
									[(ngModel)]="item.descripcion"
									[readonly]="readonly"
									#descripcion="ngModel"
									name="descripcion"
								/>
								<label>{{ "DESCRIPCION" | translate }}</label>
							</span>
						</div>
						<error-tag [model]="descripcion"></error-tag>
					</div>

					<div class="col-12 lg:col-4">
						<div class="p-inputgroup">
							<span class="p-float-label">
								<input
									pInputText
									placeholder="{{ 'DOMICILIOFISCAL_PH' | translate }}"
									[(ngModel)]="item.domicilioFiscal"
									[readonly]="readonly"
									#domicilioFiscal="ngModel"
									name="domicilioFiscal"
								/>
								<label>{{ "DOMICILIOFISCAL" | translate }}</label>
							</span>
						</div>
						<error-tag [model]="domicilioFiscal"></error-tag>
					</div>

					<div class="col-12 lg:col-4">
						<div class="p-inputgroup">
							<span class="p-float-label">
								<input
									pInputText
									placeholder="{{ 'TELEFONO_PH' | translate }}"
									[(ngModel)]="item.telefono"
									[readonly]="readonly"
									#telefono="ngModel"
									name="telefono"
								/>
								<label>{{ "TELEFONO" | translate }}</label>
							</span>
						</div>
						<error-tag [model]="telefono"></error-tag>
					</div>

					<div class="col-12 lg:col-4">
						<div class="p-inputgroup">
							<span class="p-float-label">
								<input
									pInputText
									placeholder="{{ 'SITIOWEB_PH' | translate }}"
									[(ngModel)]="item.sitioWeb"
									[readonly]="readonly"
									#sitioWeb="ngModel"
									name="sitioWeb"
									inputType="url"
								/>
								<label>{{ "SITIOWEB" | translate }}</label>
							</span>
						</div>
						<error-tag [model]="sitioWeb"></error-tag>
					</div>

					<div class="col-12 lg:col-6" *ngIf="item?.id">
						<div class="p-inputgroup">
							<span class="p-float-label">
								<descriptivo-material-selector
									[allowDefault]="!item?.id"
									[options]="puntos"
									[label]="'PUNTO_VENTA' | translate"
									name="puntoventa"
									[limpiable]="true"
									[(ngModel)]="item.puntoDeVenta"
									[readonly]="readonly"
								></descriptivo-material-selector>
								<label>{{ "PUNTO_VENTA" | translate }}</label>
							</span>
						</div>
					</div>

					<div class="col-12 lg:col-4 checkbox-container">
						<p-checkbox
							[(ngModel)]="item.esDefault"
							name="esDefault"
							[disabled]="readonly"
							binary="true"
							label="{{ 'ESDEFAULT' | translate }}"
						></p-checkbox>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
