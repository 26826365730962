import { Injectable } from "@angular/core"
import { Message, MessageService } from "primeng/api"
import { Subscription } from "rxjs"
import { LoadingService } from "./loading-data-service.service"
import { TranslateService } from "./translate.service"

@Injectable({
	providedIn: "root"
})
export class MessagesService {
	public loadingService: LoadingService
	public _life: number = 5000
	public subWs: Subscription
	constructor(private mService: MessageService, private translator: TranslateService) {
		this.loadingService = LoadingService.injector.get(LoadingService)
	}
	get life(): number {
		return this._life
	}
	set life(val: number) {
		this._life = val
	}
	public errorHandler = (error, msgs?: Message[]) => {
		let _error = { message: "" }
		//if (error.status === 401 && error.statu) {
		if (error.status === 401 && error.error?.errorMessage === "401DIS") {
			_error.message = this.translator.get("Usuario inactivo. Debe recuperar la contraseña para loguearse")
		} else if (error.status === 401 && error.error?.errorMessage === "401BAD") {
			_error.message = this.translator.get("Credenciales inválidas")
		} else if (error.status === 401) {
			_error.message = this.translator.get("Usuario no autorizado")
		} else if (error.statusText === "Unknown Error") {
			_error.message = this.translator.get("Error de comunicación con el servidor")
		} else if (error.error) {
			if (error.error.message) {
				_error.message = this.translator.get(error.error.message)
			} else if (error.error.errorMessage) {
				_error.message = this.translator.get(error.error.errorMessage)
				if (error.error.cause) {
					_error.message += " - " + this.translator.get(error.error.cause)
				}
			} else {
				_error.message = this.translator.get("Error Interno")
			}
		} else {
			_error.message = this.translator.get("Error Interno")
		}
		this.loadingService.resetLoadingCount()
		msgs = []
		msgs.push({
			severity: "error",
			life: this.life,
			detail: this.translator.get(_error.message)
		})
		this.cleanMessages()
		this.mService.add({
			severity: "error",
			life: this.life,
			detail: this.translator.get(_error.message)
		})
		throw error
	}
	public cleanMessages() {
		this.mService.clear()
	}
	public success(message?: string, titulo?: string, customLife?: number) {
		this.mService.add({
			severity: "success",
			life: customLife ? customLife : this.life,
			detail: this.translator.get(message),
			summary: this.translator.get(titulo)
		})
	}
	public error(message?: string, titulo?: string, customLife?: number) {
		this.mService.add({
			severity: "error",
			life: customLife ? customLife : 20000,
			detail: this.translator.get(message),
			summary: this.translator.get(titulo)
		})
		return false
	}
	public info(message?: string, titulo?: string, customLife?: number) {
		this.mService.add({
			severity: "info",
			life: customLife ? customLife : this.life,
			detail: this.translator.get(message),
			summary: this.translator.get(titulo)
		})
	}
	public warning(message?: string, titulo?: string, customLife?: number) {
		this.mService.add({
			severity: "warn",
			life: customLife ? customLife : 8000,
			detail: this.translator.get(message),
			summary: this.translator.get(titulo)
		})
	}
}
