


export class VariablesWhatsapp  {
    constructor(
		public variables : string[] = [], 
    public botones: string[] = []
 
    ) {
    }
    public static fromData(data: any): VariablesWhatsapp {
        if (!data) return null;
        const o: VariablesWhatsapp = new VariablesWhatsapp(
			  data.variables? data.variables : [], 
        data.botones? data.botones : []
        );
return o;

    }

}