<model-help [title]="'Gestión de Clientes'">
    <h3>Carga Masiva</h3>
    <p>
        En la parte superior del listado de clientes encontramos el icono <i class="pi pi-upload"></i> mediante el cual podremos cargar masivamente un listado de clientes preexistentes.<br />
        Para ver el instructivo y descargar de archivo modelo, siga este <help-link [linkFinal]="'carga-masiva'" [textoLink]="'Link'"></help-link>
    </p>

    <h3>Gestor de clientes</h3>
    <p>
        La pantalla de gestor de clientes permite agregar nuevos clientes así también como modificar ó visualizar datos de los mismos.<br />
        Además de los datos generales, podemos encontrar en esta pantalla: <br /><br />
        - Comprobantes relacionados y sus estados.<br />
        - Estado de cuenta corriente.<br />
        - Estadísticas de ventas.<br /><br />

        Para intercambiar entre las vistas de datos generales o cuenta corriente seleccionamos la pestaña correspondiente. <br />
        <img src="assets/images/help/pestanas2.jpg" /><br /><br />
        Importante: Si estamos en modo de creación de nuevo cliente, solo estará disponible la pestaña "datos"<br />
    </p>
    <h3>Iconos rápidos de acciones</h3>

    <img src="assets/images/help/accesos-cliente.jpg" /><br /><br />

    <p>
        De izquierda a derecha los íconos nos permiten editar los datos del cliente actual, generar una reserva nueva, generar una cobranza y enviar una notificación por mail de deuda actual. <br />
        Ver ayuda de <help-link [linkFinal]="'cobranza'" [textoLink]="'Cobranzas'"></help-link> | <help-link [linkFinal]="'reserva'" [textoLink]="'Reservas'"></help-link>
    </p>

    <h3>Datos Generales</h3>
    <p>
        Los datos a completar serán diferentes de acuerdo a si el cliente es una empresa, o es un particular. <br />
        Los campos requeridos están marcados por un *
    </p>
    <br />

    <h3>Algunos campos particulares</h3>
    <p>
        <strong>Emails</strong><br /><br />
        Debemos prestar especial atención en los 3 emails que debemos agregar al cliente.<br /><br />
        <mark>Email Administrativo:</mark> Aquí se enviarán todos los mails que resulten de facturación, cobros y otros temas administrativos.<br />
        <mark>Email reservas:</mark> A esta dirección se enviarán todos los mails referidos a las reservas realizadas de servicios solicitados por el cliente.<br />
        <mark>Email contacto:</mark> A esta dirección se enviarán todos los mails dirigidos al pax que contrató los servicios mediante el cliente.<br /><br />

        <strong>Descuento</strong><br />
        Este campo determina un descuento que se aplicará a todos los productos o servicios que adquiera el cliente.
    </p>

    <h3>Listado de comprobantes</h3>

    <p>
        En este listado encontraremos Facturas, recibos, remitos, Notas de Crédito, etc. con información relevante y estados.<br />
        Pudiendo ingresar al detalle con un click sobre los mismos. Ver ayuda <help-link [linkFinal]="'comprobante'" [textoLink]="'Comprobantes'"></help-link> |
        <help-link [linkFinal]="'listados'" [textoLink]="'General de listados'"></help-link>
    </p>

    <h3>Estado cuenta corriente</h3>
    <p>La pantalla nos muestra de manera simple el estado actual de la cuenta corriente.</p>

    <h3>Estadísticas de ventas</h3>
    <p>Aquí podremos optar por 2 pestañas Reportes y Datos. En datos podremos descargar la información estadística en archivo en formato xls (Excel).</p>
    <br />
    <h4>Recordatorios</h4>
    <p>ver <a href="#/help/recordatorio">Recordatorios</a></p>
</model-help>
