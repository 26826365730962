import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { AdminAuthGuard } from "./authguards/AdminAuthGuard"
import { EdicionPerfilAG } from "./authguards/EdicionPerfilAG"
import { SoporteAG } from "./authguards/SoporteAG"
import { VisualizadorAuthGuard } from "./authguards/VisualizadorAuthGuard"
import { ConsentimientoActividadesComponent } from "./pages/consentimiento-actividades/consentimiento-actividades.component"
import { EncuestaServicioFamiliaComponent } from "./pages/encuesta-servicio-familia/encuesta-servicio-familia.component"
import { EncuestaServicioComponent } from "./pages/encuesta-servicio/encuesta-servicio.component"
import { HomeComponent } from "./pages/home/home.component"
import { LoginComponent } from "./pages/login/login.component"
import { LogsComponent } from "./pages/logs/logs.component"
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component"
import { GenericParametricoComponent } from "./pages/parametricos/generic-parametrico/generic-parametrico.component"
import { GestorParametricoComponent } from "./pages/parametricos/gestor-parametrico/gestor-parametrico.component"
import { PasswordResetComponent } from "./pages/password-reset/password-reset.component"
import { RecuperarPasswordComponent } from "./pages/recuperar-password/recuperar-password.component"
import { ServiciosProveedorComponent } from "./pages/servicios-proveedor/servicios-proveedor.component"
import { GestionUserComponent } from "./pages/user/gestion-user/gestion-user.component"
import { UserComponent } from "./pages/user/user.component"
import { VoucherActividadComponent } from "./pages/voucher/voucher-actividad/voucher-actividad.component"
import { UnsavedChangesGuard } from "./path-filters/UnsavedChangesGuard"
import { DownloadFileComponent } from "./public/download-file/download-file.component"

const routes: Routes = [
	{ path: "", component: HomeComponent, canActivate: [VisualizadorAuthGuard] },
	{ path: "home", component: HomeComponent, canActivate: [VisualizadorAuthGuard] },
	{ path: "login", component: LoginComponent },
	{ path: "public/recupero-contrasenia/:token", component: PasswordResetComponent, data: { animation: "Item" } },
	{ path: "public/consentimiento-actividades/:reserva/:token", component: ConsentimientoActividadesComponent },
	{ path: "public/reserva-finalizada/:reserva/:tokenEncuesta", component: ConsentimientoActividadesComponent },
	{ path: "public/mi-reserva/:reserva/:tokenEncuesta", component: ConsentimientoActividadesComponent },
	{ path: "public/encuesta/:reserva/:token", component: EncuestaServicioComponent },
	{ path: "public/encuesta/v2", component: EncuestaServicioFamiliaComponent },
	{ path: "public/:tipo/:token", component: DownloadFileComponent },
	{ path: "public/envio-recupero", component: RecuperarPasswordComponent },
	{ path: "public/voucher/:token/:tipoVoucher/:id", component: VoucherActividadComponent },
	{ path: "public/servicios-proveedor/:id/:token", component: ServiciosProveedorComponent },

	//Usuarios
	{ path: "usuario", component: UserComponent, canActivate: [AdminAuthGuard] },
	{ path: "usuario/edit", canDeactivate: [UnsavedChangesGuard], component: GestionUserComponent, canActivate: [EdicionPerfilAG] },
	{ path: "usuario/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestionUserComponent, canActivate: [AdminAuthGuard] },
	{ path: "usuario/vista", component: GestionUserComponent, canActivate: [AdminAuthGuard], data: { vista: true } },

	//Paramtros
	{ path: "parametros", component: GenericParametricoComponent, canActivate: [AdminAuthGuard] },
	{ path: "parametros/edit", canDeactivate: [UnsavedChangesGuard], component: GestorParametricoComponent, canActivate: [AdminAuthGuard] },
	{ path: "parametros/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorParametricoComponent, canActivate: [AdminAuthGuard] },
	{ path: "logs", component: LogsComponent, canActivate: [SoporteAG] },
	{ path: "**", component: PageNotFoundComponent }
	//Logs
]
@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: "legacy" })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
