<model-help [title]="'Gestores / Generalidades'">
    <p>
        En las pantallas de gestión o gestores, podemos ver la información completa de una entidad (ej: cliente, proveedor, reserva, etc) y también editar si estamos en modo vista y clickeamos en el
        ícono <i class="pi pi-pencil"></i>
    </p>
    <h3>Opcionales en Encabezado</h3>
    <p>En algunos casos el encabezado del gestor tiene acciones representados por íconos, estas acciones varían dependiendo de la entidad</p>
    <img src="assets/images/help/gestor.jpg" />
    <p>También podremos encontrar pestañas que nos llevan a más datos disponibles o relacionados con la entidad en cuestión</p>
    <img src="assets/images/help/pestana.jpg" />
    <p>Los datos requeridos están señalados con un asterisco en cada campo. <i class="pi">*</i></p>
</model-help>
