import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { OpcionPuesto } from 'src/app/model/OpcionPuesto';
import { TipoPuesto } from 'src/app/model/TipoPuesto';

@Component({
  selector: 'opcion-puesto',
  templateUrl: './opcion-puesto.component.html',
  styleUrls: ['./opcion-puesto.component.less']
})
export class OpcionPuestoComponent implements OnInit {

  @Input() opcion: OpcionPuesto
  @Input() seleccion: boolean = false
  @Input() puesto: TipoPuesto

  @Input() permiteGestionCostos  : boolean = false;

  @Output()
  public onVerCostos : EventEmitter<OpcionPuesto> = new EventEmitter();
  private defaultDeseleccionar = () => {
    return
}
public verCostos = ()=>{
  this.onVerCostos.emit(this.opcion);
}

@Input()
public deseleccionar: () => void = this.defaultDeseleccionar

  constructor() { }

  ngOnInit() {
  }

}
