import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { DescriptivosService } from 'src/app/common/services/descriptivos.service';
import { StringUtils } from 'src/app/common/utils/string-utils';
import { Parametrico } from './../../model/Parametrico';

@Component({
  selector: 'descriptivo-selector',
  templateUrl: './descriptivo-selector.component.html',
  styleUrls: ['./descriptivo-selector.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DescriptivoSelectorComponent),
      multi: true
    }
  ]
})

export class DescriptivoSelectorComponent implements OnInit, ControlValueAccessor {


  private _data: Descriptivo[] = [];

  @Input()
  public textoSeleccionar: string = "Seleccionar...";

  @Input()
  public sort: boolean = true;

  @Input()
  public tooltip: string = "Seleccione una opción";
  @Input()
  set data(val: Descriptivo[]) {
    this._data = !val ? [] : val;
    if (this.sort)
      this._data = this._data.sort((a, b) => (a && b) ? (a.descripcionComparable > b.descripcionComparable ? 1 : -1) : (a ? 1 : -1));

    if (val && this.rSinAdicionales.length === 1) {
      this.seleccionado = val[0];

    }
  }
  get data(): Descriptivo[] {
    return this._data;
  }
  @Input()
  appendTo: string = "body";

  @Input()
  inputClass: string = "col-12";

  @Input()
  labelClass: string = "col-12";

  @Input()
  limpiable: boolean = true;

  @Input()
  default: Descriptivo;

  @Input()
  permiteNuevo: boolean = false;

  @Input()
  nombre: string;

  @Input()
  service: DescriptivosService<any>;

  @Input()
  public editable: boolean = true;

  public nuevo: Parametrico = Parametrico.NUEVO;

  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNew: EventEmitter<any> = new EventEmitter();

  private onChangeCallback: (_: any) => void = () => { };

  @ViewChild('autocomplete', { static: false }) autocomplete;

  _seleccionado: Descriptivo;

  disabled: boolean;

  recomendaciones: Descriptivo[];

  constructor() {
    this.recomendaciones = [];

  }
  select(event: Descriptivo) {
    this.seleccionado = event;
  }

  clear() {
    this.seleccionado = null;
  }
  get seleccionado() {
    return this._seleccionado;
  }
  set seleccionado(val: Descriptivo) {
    if (val !== this._seleccionado) {
      let prev = this._seleccionado;
      this._seleccionado = val;

      if (val && val.codigo == Parametrico.NUEVO.codigo && (!prev || val.codigo != prev.codigo)) {
        this.onNew.emit();
      } else {
        this.onChangeCallback(val);
        this.onSelect.emit({ prev: prev, new: val });

      }


    }

    //this.propagateChange(this._seleccionado);
  }
  public get rSinAdicionales(): Descriptivo[] {
    return this.recomendaciones.filter(r => r.codigo != Parametrico.NUEVO.codigo && (!this.default || this.default.codigo != r.codigo));
  }
  public autoselectOpcion() {
    if (!this.seleccionado || !this.seleccionado.codigo) {
      this.seleccionado = null;
    }
    if (this.recomendaciones && this.rSinAdicionales.length === 1) {
      this.seleccionado = this.rSinAdicionales[0];
    }
  }
  todos() {
    this.recomendaciones = [];

    setTimeout(() => {
      this.recomendaciones = this.generarBase(this.data);
    }, 100)
  }

  ngOnInit() {
  }
  propagateChange = (_: any) => { };

  writeValue(obj: Descriptivo): void {
    if (obj !== this._seleccionado) {
      this.seleccionado = obj;
      if (!obj) {
        this.clear();
      }
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void { }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  selectItem(value: Descriptivo) {
    this.seleccionado = value;
  }

  get sinDatos() {
    return !this.service && (this.data === undefined || this.data.length == 0);
  }

  public onFocus(event: any) {
    event.srcElement.select();
  }

  private generarBase(val: Descriptivo[]) {
    let data = [];
    if (this.permiteNuevo) {
      data.push(Parametrico.NUEVO);
    }
    data = data.concat(val);
    if (this.default) {
      data.push(this.default);
    }
    return data;
  }

  filtrar(event) {
    if (this.service) {
      if (event.query && event.query.length >= 3) {
        this.service.getDescriptivosByDesc(event.query).then(r => {

          this.recomendaciones = this.generarBase(r);

        });
      } else {
        this.recomendaciones = this.generarBase([]);
      }

    } else {

      this.recomendaciones = this.generarBase(this.data
        .filter(s => s && (s.codigo.toLowerCase().includes(event.query.toLowerCase())
          || s.descripcionComparable.includes(StringUtils.getSinTildes(event.query)))));

    }


  }

}