import { Pipe, PipeTransform } from "@angular/core"
import { GrupoPax } from "src/app/model/GrupoPax"
import { Vehiculo } from "src/app/model/Vehiculo"

@Pipe({
	name: "byVehiculo"
})
export class ByVehiculoPipe implements PipeTransform {
	transform(value: GrupoPax[], args: Vehiculo[] = []): any {
		let r = value.filter(
			(g) =>
				(args?.length == 0 && g.esAlquiler) ||
				args.some(
					(v) =>
						(v != undefined &&
							((g.vehiculoAsignado && v?.id == g.vehiculoAsignado?.id) || (v.proveedor && g.puestos[0]?.personal?.id == v.proveedor?.id))) ||
						g.esAlquiler
				)
		)
		return r
	}
}
