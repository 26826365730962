import { Pipe, PipeTransform } from "@angular/core"
import { ItemReserva } from "../model/ItemReserva"

@Pipe({
	name: "hospedaje",
	pure: false
})
export class HospedajePipe implements PipeTransform {
	transform(value: ItemReserva[], hotel: boolean = true): any {
		return value.filter((v) => {
			if (hotel) return v.checkIn
			else return !v.checkIn
		})
	}
}
