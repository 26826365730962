<div class="gestor-hotel content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'HOTEL' | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		[file]="file"
		[conArchivo]="true"
		[readonly]="readonly"
		[editable]="editable"
		(onItemGuardado)="handleGuardado($event)"
		(onCancelar)="handleCancelar($event)"
		[form]="HotelForm"
		[hideTitle]="esProveedor"
		[hideButtons]="esProveedor"
		styleClass="{{ esProveedor ? 'gestor-hotel-proveedor' : 'white' }}"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #HotelForm="ngForm" id="Hotelform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-3">
						<div class="grid">
							<div class="col-12 pic-wrapper">
								<div class="pic">
									<profile-badge
										[seleccionable]="true"
										[url]="item?.profilePic?.picPathVersion"
										[readonly]="readonly"
										name="fotoHotel"
										class="full-badge"
										(onFileSelected)="onPicSelected($event)"
									>
									</profile-badge>
									<i class="fa fa-pencil edit-icon" *ngIf="!readonly"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 lg:col-7">
						<div class="grid">
							<div class="col-12 lg:col-3 p-fluid">
								<span class="p-float-label field">
									<input
										pInputText
										placeholder="{{ 'NOMBRE' | translate }}"
										[(ngModel)]="item.nombre"
										tooltipPosition="bottom"
										pTooltip="{{ 'NOMBRE_TOOLTIP' | translate }}"
										type="text"
										#nombre="ngModel"
										name="nombre"
										[disabled]="readonly"
										(change)="changeNombre($event)"
										required
									/><label>{{ "NOMBRE" | translate }}</label>
									<small *ngIf="getErrorMessage(nombre)">
										{{ getErrorMessage(nombre) }}
									</small>
								</span>
							</div>
							<div class="col-12 lg:col-9 p-fluid">
								<span class="p-float-label field">
									<input
										pInputText
										placeholder="{{ 'DIRECCION_PH' | translate }}"
										[(ngModel)]="item.direccion"
										tooltipPosition="bottom"
										pTooltip="{{ 'DIRECCION_PH' | translate }}"
										type="text"
										#direccion="ngModel"
										name="direccion"
										[disabled]="readonly"
									/><label>{{ "DIRECCION" | translate }}</label>
								</span>
							</div>
							<div class="col-12 lg:col-6 p-fluid">
								<span class="p-float-label field">
									<input
										pInputText
										placeholder="{{ 'EMAIL' | translate }}"
										[(ngModel)]="item.email"
										tooltipPosition="bottom"
										pTooltip="{{ 'EMAIL_TOOLTIP' | translate }}"
										type="text"
										#email="ngModel"
										name="email"
										[disabled]="readonly"
									/><label>{{ "EMAIL" | translate }}</label>
								</span>
							</div>
							<div class="col-12 lg:col-6 p-fluid">
								<span class="p-float-label field">
									<input
										pInputText
										placeholder="{{ 'TELEFONO' | translate }}"
										[(ngModel)]="item.telefonoContacto"
										tooltipPosition="bottom"
										pTooltip="{{ 'TELEFONO_TOOLTIP' | translate }}"
										type="text"
										#telefonoContacto="ngModel"
										name="telefonoContacto"
										[disabled]="readonly"
									/><label>{{ "TELEFONO" | translate }}</label>
								</span>
								<!-- <telefono-selector [disabled]="readonly" [(ngModel)]="item.telefonoContacto" [ngClass]="{'readonly':readonly}" name="telefonoContacto" #telefonoContacto="ngModel"></telefono-selector> -->
							</div>
							<div class="col-12 lg:col-6 p-fluid">
								<span class="p-float-label field">
									<input
										pInputText
										placeholder="{{ 'FACEBOOK_PH' | translate }}"
										[(ngModel)]="item.facebook"
										tooltipPosition="bottom"
										pTooltip="{{ 'FACEBOOK_TOOLTIP' | translate }}"
										type="text"
										#facebook="ngModel"
										name="facebook"
										[disabled]="readonly"
									/><label>{{ "FACEBOOK" | translate }}</label>
								</span>
							</div>
							<div class="col-12 lg:col-6 p-fluid">
								<span class="p-float-label field">
									<input
										pInputText
										placeholder="{{ 'WEBSITE_PH' | translate }}"
										[(ngModel)]="item.website"
										tooltipPosition="bottom"
										pTooltip="{{ 'WEBSITE_TOOLTIP' | translate }}"
										type="text"
										#website="ngModel"
										name="website"
										[disabled]="readonly"
									/><label>{{ "WEBSITE" | translate }}</label>
								</span>
							</div>

							<div class="col-12 lg:col-4 p-fluid field" *ngIf="!esProveedor && (!item.proveedor || relacionandoProveedor)">
								<descriptivo-material-selector
									[service]="proveedorService"
									[getData]="proveedorService.getHospedajes"
									[(ngModel)]="proveedor"
									[ngModelOptions]="{ standalone: true }"
									(onSelect)="onSelectProveedor($event)"
									[isLazy]="true"
									[label]="'PROVEEDOR_ASOCIADO' | translate"
									[limpiable]="true"
									[permiteNuevo]="false"
									[readonly]="readonly"
								>
								</descriptivo-material-selector>
							</div>
							<div class="col-12" *ngIf="esProveedor || item?.proveedor">
								<span class="p-float-label">
									<selector-multiple-chips
										[seleccionados]="habitaciones"
										ngModelOptions="{standalone: true}"
										[opciones]="hospedajes"
										[permiteNuevo]="false"
										[readonly]="readonly"
										placeholder="{{ !hospedajes?.length ? ('NO_HAY_HABITACIONES' | translate) : '' }}"
										[titulo]="'HABITACIONES' | translate"
										(onAgregar)="onAgregarHabitacion($event)"
										(onQuitar)="onQuitarHabitacion($event)"
									>
									</selector-multiple-chips>
								</span>
							</div>
							<div class="col-3">
								<span class="p-float-label">
									<p-calendar
										[(ngModel)]="item.checkIn"
										showTime="true"
										[hourFormat]="24"
										[stepMinute]="5"
										#checkIn="ngModel"
										name="checkIn"
										[disabled]="readonly"
										timeOnly="true"
									></p-calendar>
									<label for="checkIn">Check In</label>
								</span>
							</div>
							<div class="col-3">
								<span class="p-float-label">
									<p-calendar
										[(ngModel)]="item.checkOut"
										showTime="true"
										[hourFormat]="24"
										[stepMinute]="5"
										#checkOut="ngModel"
										name="checkOut"
										[disabled]="readonly"
										timeOnly="true"
									></p-calendar>
									<label for="checkOut">Check Out</label>
								</span>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
