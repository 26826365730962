<div class="layout-topbar p-shadow-4">
	<div class="layout-topbar-left">
		<small class="text-right version">v{{ version }}</small>
		<a class="layout-topbar-logo text-center" routerLink="/">
			<img src="assets/images/iterpax.png" alt="iterpax" style="height: 100%" class="print-hidden" />
			<img src="{{ mainLogo }}" alt="iterpax" style="height: 100%" class="print-only" />
		</a>

		<a class="layout-menu-button shadow-6" (click)="app.onMenuButtonClick($event)" pRipple>
			<i class="pi pi-bars"></i>
		</a>

		<a class="layout-topbar-mobile-button" (click)="menu.toggle($event); $event.preventDefault()" *ngIf="itemsConfiguracion?.length" pRipple>
			<i class="pi fs-large pi-cog"></i>
		</a>
	</div>
	<div class="layout-topbar-right">
		<div class="layout-topbar-actions-left"></div>
		<div class="layout-topbar-actions-right">
			<ul class="layout-topbar-items">
				<li class="layout-topbar-item">
					<a
						href="#"
						class="layout-topbar-action rounded-circle"
						(click)="nots.toggle($event); mostrarNotificaciones(); $event.preventDefault()"
						*ngIf="!usuario?.esExterno"
					>
						<i class="pi pi-bell fs-large clickable" pBadge [value]="notificacionesNuevas" severity="warning" *ngIf="notificacionesNuevas > 0"> </i>
						<i class="pi pi-bell fs-large clickable" *ngIf="!notificacionesNuevas"> </i>
					</a>

					<a
						href="#"
						class="layout-topbar-action rounded-circle"
						(click)="op.toggle($event); $event.preventDefault()"
						*ngIf="itemsConfiguracion?.length"
					>
						<i class="pi pi-palette fs-large"></i>
					</a>
				</li>
				<li class="layout-topbar-item" (click)="verUsuario()">
					<span style="margin-right: 5px" *ngIf="usuario?.nombreCompleto">{{ usuario?.nombreCompleto }}</span>
					<profile-badge [url]="usuario?.imagen?.picPath" class="small-badge circular desktop-badge"> </profile-badge>
				</li>

				<li class="layout-topbar-item" *ngIf="idiomas?.length && idiomaSeleccionado" style="padding-left: 1rem">
					<selector-idioma [autoselect]="false" [(ngModel)]="idiomaSeleccionado" name="idioma"></selector-idioma>
				</li>
				<li class="layout-topbar-item">
					<a
						href="#"
						class="layout-topbar-action rounded-circle"
						(click)="menu.toggle($event); $event.preventDefault()"
						*ngIf="itemsConfiguracion?.length && !usuario?.esExterno"
					>
						<i class="pi fs-large pi-cog clickable"></i>
					</a>
				</li>
			</ul>
		</div>
	</div>
</div>
<p-overlayPanel #op>
	<ng-template pTemplate="content">
		<div class="grid">
			<div class="col col-fixed" style="width: 2.5rem" *ngFor="let theme of componentThemes">
				<button
					type="button"
					class="cursor-pointer p-link w-2rem h-2rem border-round shadow-2 flex-shrink-0 flex justify-content-center align-items-center border-circle"
					(click)="changeTheme(theme.name)"
					[ngStyle]="{ 'background-color': theme.color }"
				>
					<i *ngIf="componentTheme === theme.name" class="pi pi-check text-lg" [ngClass]="{ 'text-white': componentTheme !== 'light' }"></i>
				</button>
			</div>
		</div>
	</ng-template>
</p-overlayPanel>

<p-overlayPanel #nots styleClass="w-30rem novedades-panel" contentStyleClass="p-4 surface-overlay shadow-2 border-round">
	<ng-template pTemplate="content" let-option>
		<div class="p-lg-12" style="margin-top: 4.5em" *ngIf="loadingNotificaciones">
			<h2>Verificando <i class="fa fa-spinner fa-spin"></i></h2>
		</div>

		<div class="mb-2 mt-2" *ngFor="let notificacion of novedades">
			<p-card [styleClass]="'clickable'" (click)="navegarEntidad(notificacion)">
				<ng-template pTemplate="header">
					<div class="d-flex align-items-center p-fluid">
						<div class="fw-700 ml-2 mt-2">
							<span
								><strong>{{ notificacion?.nombreEntidadOrigen || "Recordatorio" | translate }}</strong></span
							>
						</div>
						<span class="ml-2 mt-2 text-overflow" style="max-height: 90%">
							{{ "- " }}
							{{ notificacion.fechaVencimiento | date : "dd/MM/yyyy" }}
						</span>
						<i
							class="pi pi-trash clickable"
							style="margin-left: auto; margin-right: 2px"
							(click)="ocultarNotificacion(notificacion); $event.stopPropagation()"
						></i>
					</div>
				</ng-template>
				<ng-template pTemplate="content">
					<div [innerHTML]="notificacion?.parametros['$CABECERA$']"></div>
					<div
						class="detalleNovedad"
						[innerHTML]="notificacion?.parametros['$DETALLE$']"
						[tooltipPosition]="'right'"
						[pTooltip]="'Click para ver el detalle'"
					></div>
				</ng-template>
			</p-card>
		</div>

		<div class="d-flex align-items-center justify-content-center" *ngIf="!novedades?.length">No hay notificaciones</div>
		<div class="d-flex align-items-center justify-content-center p-fluid" *ngIf="novedades?.length">
			<button
				pButton
				class="p-button-outlined"
				[icon]="'fa fa-check-square-o'"
				(click)="eliminarNotificaciones()"
				[label]="'QUITAR_TODAS' | translate"
			></button>
		</div>
	</ng-template>
</p-overlayPanel>

<p-menu #menu [model]="itemsConfiguracion" [popup]="true" [style]="{ 'max-height': '90vh', overflow: 'auto' }"> </p-menu>

<p-dialog
	[(visible)]="verNotificacion"
	appendTo="body"
	[modal]="true"
	#dialDetail
	header="{{ verNotificacion?.nombreEntidadOrigen || ('Recordatorio' | translate) }}"
	styleClass="{{ !isMobile() ? 'notificationDialog' : '' }}"
	[closable]="false"
>
	<p>
		<strong>{{ verNotificacion?.parametros["$CABECERA$"] }}</strong>
	</p>
	<p [innerHTML]="verNotificacion?.parametros['$DETALLE$']"></p>
	<p-footer>
		<button pButton class="boton" (click)="verNotificacion = null" label="Cerrar"></button>
	</p-footer>
</p-dialog>
