<div class="section-wrapper itinerario-reserva">
	<p-table [value]="datos" styleClass="p-datatable-gridlines" [scrollable]="true" scrollHeight="80vh">
		<ng-template pTemplate="header">
			<tr>
				<th style="width: 16%">{{ "RESERVA" | translate }}</th>
				<th *ngFor="let fecha of fechas; let i = index" style="width: 12%" [ngClass]="{ hoy: esHoy(fecha) }">
					<div class="grid">
						<div class="col-fixed">
							<button pButton icon="fa fa-chevron-left" (click)="navegarFechas(-1)" class="p-button-text" *ngIf="i == 0"></button>
						</div>
						<div class="col text-center">
							<strong>{{ fecha | date : "dd/MM/yyyy" }}</strong>
							<small class="block w-100 text-center">{{ fecha | date : "EEEE" }}</small>
						</div>
						<div class="col-fixed text-right">
							<button pButton icon="fa fa-chevron-right" (click)="navegarFechas(1)" class="p-button-text" *ngIf="i == 6"></button>
						</div>
					</div>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-dato>
			<tr>
				<td>
					<div class="col-reserva clickable w-100 p-2" (click)="goToReserva(dato?.reserva?.id)">
						<strong class="block">{{ dato?.reserva?.nombrePax }} x {{ dato?.reserva?.cantidadPax }}</strong>
						<span class="w-100 text-center">N° {{ dato?.reserva?.id }}</span>
						<small class="observaciones span-clamp mt-2">{{ dato?.reserva?.observaciones }}</small>
					</div>
				</td>
				<td *ngFor="let fecha of fechas" class="text-center" [ngClass]="{ hoy: esHoy(fecha) }">
					<ng-container *ngIf="dato[fecha.toString()]">
						<div *ngFor="let actividad of dato[fecha.toString()]" class="actividad">
							<div class="checkin actividad" *ngIf="actividad?.tipo === 'CO' || actividad?.tipo === 'CI'">
								<strong
									><i class="fa fa-sign-in mr-1" *ngIf="actividad?.tipo === 'CI'"></i> {{ actividad?.hotel?.descripcion }}
									<i class="fa fa-sign-out ml-1" *ngIf="actividad?.tipo === 'CO'"></i
								></strong>
								<span class="block"
									><i class="fa fa-clock-o mr-1"></i
									>{{ (actividad?.tipo === "CO" ? actividad?.checkOut : actividad?.checkIn) | date : "HH:mm" }}</span
								>
							</div>
							<div class="actividad" *ngIf="actividad?.tipo === 'AC'">
								<strong class="block">{{ actividad?.producto?.descripcion }}</strong>
								<span class="block mt-1"><i class="fa fa-clock-o mr-1"></i>{{ actividad?.fechaActividad | date : "HH:mm" }}</span>
								<small class="block mt-1" *ngIf="actividad?.referenciaDireccion"
									><i class="fa fa-building mr-1"></i>{{ actividad?.referenciaDireccion }}</small
								>

								<div class="flex align-items-center justify-content-center mt-1" *ngIf="actividad?.tipo === 'AC'">
									<puesto-badge
										[mostrarEstado]="false"
										[(ngModel)]="puesto.personal"
										[puesto]="puesto"
										[verDetalle]="false"
										[disabled]="true"
										*ngFor="let puesto of actividad?.puestos"
										class="text-center"
										[grupo]="grupo"
										[size]="'small'"
										[permiteGestionCostos]="false"
									>
									</puesto-badge>
								</div>
								<small class="mt-1 span-clamp" *ngIf="actividad?.observaciones" pTooltip="{{ actividad?.observaciones }}"
									><i class="fa fa-comment mr-1"></i>{{ actividad?.observaciones }}</small
								>
								<div class="restricciones flex mt-1 justify-content-center" *ngIf="actividad?.restricciones?.length > 0">
									<img
										class="restriccion"
										src="{{ r?.imagenUrl }}"
										alt=""
										tooltipPosition="bottom"
										pTooltip="{{ r.descripcion }}"
										style="height: 1.25rem"
										*ngFor="let r of actividad.restricciones"
									/>
								</div>
							</div>
						</div>
					</ng-container>
				</td>
			</tr>
		</ng-template>
	</p-table>
</div>
