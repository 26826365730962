<div class="ranking-compras content">
	<div class="model-list">
		<div class="top-section grid">
			<div class="col-12 model-title custom">
				<div class="grid">
					<div class="col-8">
						<h2 autofocus>{{ "Estadísticas de compras" | translate }}</h2>
					</div>
				</div>
			</div>

			<div class="col-12 model-filters">
				<filtro [(filter)]="filtro" [isFixed]="false" [isMinimized]="true" [useSidebar]="true" [filterTemplateContent]="filterTemplateContent">
					<ng-template #filterTemplateContent>
						<div class="grid">
							<div class="col-12">
								<div class="p-inputgroup">
									<span class="p-float-label">
										<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
										<label>{{ "BUSCAR" | translate }}...</label>
									</span>
									<button type="button" pButton icon="pi pi-search"></button>
								</div>
							</div>
							<div class="col-12" *ngIf="!proveedor">
								<descriptivo-material-selector
									[label]="'Proveedor' | translate"
									[limpiable]="true"
									[(ngModel)]="filtro.proveedor"
									name="proveedor"
									isLazy="true"
									[service]="proveedorService"
								>
								</descriptivo-material-selector>
							</div>
							<div class="col-12" *ngIf="!proveedor">
								<descriptivo-material-selector
									[label]="'Tipo proveedor' | translate"
									[limpiable]="true"
									[(ngModel)]="filtro.tipoProveedor"
									name="tipoProveedor"
									isLazy="true"
									[service]="tipoProveedorService"
								>
								</descriptivo-material-selector>
							</div>
							<div class="col-12">
								<selector-multiple-chips
									[(ngModel)]="filtro.categorias"
									name="cats"
									[servicio]="categoriasInsumoService"
									[permiteNuevo]="false"
									[limpiable]="true"
									[titulo]="'CATEGORIA' | translate"
									[maxItems]="3"
								>
								</selector-multiple-chips>
							</div>
							<div class="col-12 p-fluid">
								<div>
									<span class="p-float-label field">
										<input
											pInputText
											[(ngModel)]="cantidad"
											placeholder="{{ 'Cantidad de insumos a mostrar' | translate }}"
											autocomplete="off"
											type="number"
										/>
										<label>{{ "Cantidad" | translate }}</label>
									</span>
								</div>
							</div>
							<div class="col-12">
								<div class="grid">
									<div class="col-6">
										<span class="p-float-label">
											<p-calendar
												[(ngModel)]="filtro.fechaDesde"
												dateFormat="dd/mm/yy"
												appendTo="body"
												name="fechaDesde"
												[showIcon]="true"
												tooltipPosition="bottom"
												pTooltip="{{ 'FECHA_DESDE' | translate }}"
												#fechaFacturacionDesde="ngModel"
											></p-calendar>
											<label>{{ "FECHA_DESDE" | translate }}</label>
											<error-tag [model]="fechaDesde"></error-tag>
										</span>
									</div>
									<div class="col-6">
										<span class="p-float-label">
											<p-calendar
												[(ngModel)]="filtro.fechaHasta"
												dateFormat="dd/mm/yy"
												appendTo="body"
												name="fechaFacturacionHasta"
												[showIcon]="true"
												tooltipPosition="bottom"
												pTooltip="{{ 'FECHA_HASTA' | translate }}"
												#fechaFacturacionHasta="ngModel"
											></p-calendar>
											<label>{{ "FECHA_HASTA" | translate }}</label>
											<error-tag [model]="fechaFacturacionHasta"></error-tag>
										</span>
									</div>
								</div>
							</div>
							<!--div class="col-12">
                            <descriptivo-material-selector [label]="'Moneda' | translate" [limpiable]="true" [(ngModel)]="moneda" name="moneda" isLazy="true" [service]="monedaService">
                            </descriptivo-material-selector>
                        </div-->
							<div class="col-12">
								<span>
									<div class="grid">
										<div class="col-12">
											<label class="fw-500">
												{{ "Mostrar valores" | translate }}
											</label>
										</div>
										<div class="col">
											<p-radioButton name="groupname" value="cantidad" label="{{ 'Cantidad' | translate }}" [(ngModel)]="mostrar">
											</p-radioButton>
										</div>
										<div class="col">
											<p-radioButton name="groupname" value="importe" label="{{ 'IMPORTE' | translate }}" [(ngModel)]="mostrar">
											</p-radioButton>
										</div>
									</div>
								</span>
							</div>
							<div class="col-12">
								<span>
									<div class="grid">
										<div class="col-12">
											<label class="fw-500">
												{{ "Agrupar por" | translate }}
											</label>
										</div>
										<descriptivo-material-selector
											[class]="'col-6'"
											[limpiable]="false"
											[(ngModel)]="agrupar"
											name="agrupar"
											[options]="agruparOptions"
										>
										</descriptivo-material-selector>

										<descriptivo-material-selector
											[class]="'col-6'"
											[limpiable]="true"
											[(ngModel)]="agrupar2"
											name="agrupar2"
											[options]="agruparOptions"
										>
										</descriptivo-material-selector>
									</div>
								</span>
							</div>
						</div>
					</ng-template>
				</filtro>
			</div>
		</div>
		<div class="bottom-section">
			<p-tabView orientation="top" [styleClass]="'ranking-tabs'">
				<p-tabPanel header="{{ 'REPORTES' | translate }}">
					<div class="grid align-content-around" *ngIf="agrupar && !agrupar2">
						<div class="col-12 lg:col-6" *ngIf="showCharts && insumos?.length > 1 && cantidad < maxGrafico">
							<div class="grid p-dir-col align-content-around">
								<div class="col-12 charts card">
									<h3 class="p-text-center p-mt-4 p-mb-4">{{ "Distribucción de compras" | translate }}</h3>
									<p-chart type="pie" [data]="dataPie" [options]="optionsPie" [plugins]="plugins" height="40vh"> </p-chart>
								</div>
								<div class="col-12 charts card">
									<h3 class="p-text-center p-mt-4 p-mb-4">{{ "Comparación de compras" | translate }}</h3>
									<p-chart type="bar" [data]="dataBars" [options]="optionsBar" [plugins]="plugins" height="40vh"></p-chart>
								</div>
							</div>
						</div>
						<div class="col">
							<div class="card listado-ranking">
								<p-dataView [value]="ranking" dataKey="id">
									<ng-template let-data let-i="rowIndex" pTemplate="listItem">
										<div class="col-12 p-mb-3">
											<div class="card">
												<div class="grid">
													<div
														class="col-8 descripcion card-container"
														[ngStyle]="{ 'border-left-color': colors[i] ? colors[i] : 'white' }"
													>
														<span class="clickable" (click)="goToItem($event, data)">{{ data?.label }}</span>
													</div>
													<div class="col-4 valor text-right">
														<span class="cantidad" *ngIf="mostrar == 'cantidad'">{{ data?.value }}</span>
														<span class="cantidad" *ngIf="mostrar == 'cantidadPaxReserva'">{{ data?.value }}</span>
														<span class="cantidad" *ngIf="mostrar == 'importe'">{{
															data?.value | currency : moneda?.codigo || "USD" : moneda?.simbolo || "$"
														}}</span>
													</div>
												</div>
											</div>
										</div>
									</ng-template>
									<p-header>
										<div class="total">
											<h3>Compras</h3>
											<span *ngIf="mostrar == 'cantidad'">Total: {{ total }}</span>
											<span *ngIf="mostrar == 'importe'"
												>Total: {{ total | currency : moneda?.codigo || "USD" : moneda?.simbolo || "$" }}</span
											>
										</div>
									</p-header>
								</p-dataView>
							</div>
						</div>
					</div>
					<div class="bottom-section grid" *ngIf="agrupar && agrupar2">
						<p-table [value]="ranking2" dataKey="key">
							<ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded" let-columns="columns">
								<tr class="p-widget-header" *ngIf="rowGroupMetadata[rowData.key + '-' + rowData.totalHeader].index === rowIndex">
									<td>
										<a href="#" [pRowToggler]="rowData">
											<i
												[ngClass]="expanded ? 'pi pi-fw pi-chevron-circle-down' : 'pi pi-fw pi-chevron-circle-right'"
												style="vertical-align: middle; padding-bottom: 0.1em"
												class="total"
											></i>
											<span>{{ rowData.labelHeader }}</span>
										</a>
									</td>
									<th style="background-color: white">
										<span *ngIf="mostrar == 'cantidad'" class="total-black">{{ rowData.totalHeader }}</span>
										<span *ngIf="mostrar == 'importe'" class="total-black">{{
											rowData.totalHeader | currency : moneda?.codigo || "USD" : moneda?.simbolo || "$"
										}}</span>
									</th>
									<th style="width: 15%; background-color: white"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="rowexpansion" let-rowData let-rowIndex="rowIndex">
								<tr>
									<td style="padding-left: 4em">{{ rowData.label }}</td>
									<td *ngIf="mostrar == 'cantidad'">{{ rowData.value }}</td>
									<td *ngIf="mostrar == 'importe'">
										{{ rowData.value | currency : moneda?.codigo || "USD" : moneda?.simbolo || "$" }} ({{
											(rowData.value / rowData.totalHeader) * 100 | number : "1.0-2"
										}}%)
									</td>

									<td style="width: 20%"></td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</p-tabPanel>

				<p-tabPanel header="{{ 'DATOS' | translate }}">
					<p-table
						[columns]="cols"
						[scrollable]="true"
						[value]="insumos"
						[rows]="10"
						[showCurrentPageReport]="true"
						[rowsPerPageOptions]="[10, 25, 50]"
						[paginator]="true"
					>
						<ng-template pTemplate="caption">
							<div class="p-helper-clearfix" style="text-align: left">
								<button type="button" pButton icon="pi pi-xlsx-o" iconPos="left" label="XLSX" (click)="exportToExcel()"></button>
							</div>
						</ng-template>
						<!-- <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col *ngFor="let col of cols" style="width:100px">
                            </colgroup>
                        </ng-template> -->
						<ng-template pTemplate="header">
							<tr></tr>
							<tr>
								<th *ngFor="let col of cols" tooltipPosition="bottom" pTooltip="{{ col.header }}">
									{{ col.header }}
								</th>
							</tr>
						</ng-template>

						<ng-template pTemplate="body" let-data>
							<tr>
								<td *ngFor="let col of cols">
									{{ data[col.field] }}
								</td>
							</tr>
						</ng-template>
					</p-table>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>
</div>
