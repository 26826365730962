import { Injectable } from '@angular/core';
import { AdminAuthGuard } from './AdminAuthGuard';






@Injectable()
export class AdministracionAuthGuard extends AdminAuthGuard {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_JEFE_ADMIN"]) };




}