import { Descriptivo } from "src/app/common/model/Descriptivo"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { MonedaEntorno } from "../app.module"
import { Auditable } from "./Auditable"
import { Moneda } from "./Moneda"

export class OrdenPagoResumen extends Auditable {
	public proveedorStr: string
	public moneda: Moneda = MonedaEntorno()
	public searchStr: string = ""
	constructor(
		public id?: number,
		public estado?: EstadoDocumento,
		public proveedor?: Descriptivo,
		public fecha?: Date,
		public descripcion?: string,
		public responsable?: string,
		public importe?: number,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
		public tipoProveedor?: Descriptivo,
		public version?: number,
		public observaciones?: string
	) {
		super(lastModifiedBy, lastModifiedDate)
		this.proveedorStr = this.proveedor ? this.proveedor.descripcion : ""
		this.searchStr = this.proveedorStr + "_" + this.descripcion + "_" + this.responsable + "_" + this.observaciones
	}
	public static fromData(data: any): OrdenPagoResumen {
		if (!data) return null
		const o: OrdenPagoResumen = new OrdenPagoResumen(
			data.id,
			EstadoDocumento.fromData(data.estado),
			Descriptivo.fromData(data.proveedor),
			data.fecha ? new Date(data.fecha) : null,
			data.descripcion,
			data.responsable,
			data.importe,
			data.lastModifiedBy,
			data.lastModifiedDate,
			Descriptivo.fromData(data.tipoProveedor),
			data.version,
			data.observaciones
		)
		return o
	}
}
