<div class="gestor-categoria-producto content content-white" #gestor *ngIf="item">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'Consentimiento' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		[editable]="false"
		(onCancelar)="handleCancelar($event)"
		[form]="ConsentimientoForm"
		[multilenguaje]="!isModal"
		[menuItemAcciones]="menuItemAcciones"
	>
		<div class="gestor-form" model-type="dataContent">
			<div class="grid" *ngIf="item">
				<div class="col-12 lg:col-6">
					<span class="p-float-label field" [ngClass]="{ readonly: readonly }">
						<input
							pInputText
							placeholder="{{ 'NOMBRE_PAX' | translate }}"
							[(ngModel)]="item.nombrePax"
							type="$TYPE"
							#descripcion="ngModel"
							name="nombrePax"
							[readonly]="readonly"
						/><label>{{ "NOMBRE_PAX" | translate }}</label>
						<error-tag [model]="descripcion"></error-tag>
					</span>
				</div>
				<div class="col-12 lg:col-6">
					<span class="p-float-label field" [ngClass]="{ readonly: readonly }">
						<input
							pInputText
							placeholder="{{ 'EMAIL' | translate }}"
							[(ngModel)]="item.emailContacto"
							type="$TYPE"
							#descripcion="ngModel"
							name="emailContacto"
							[readonly]="readonly"
						/><label>{{ "EMAIL" | translate }}</label>
						<error-tag [model]="emailContacto"></error-tag>
					</span>
				</div>
				<div class="col-12 lg:col-2">
					<span class="p-float-label" (click)="navigateToReserva(item?.idReserva)">
						<input class="cursor-pointer" pInputText [(ngModel)]="item.idReserva" readonly />
						<label>{{ "RESERVA" | translate }}</label>
					</span>
				</div>
				<div class="col-12 lg:col-2">
					<span class="p-float-label" [ngClass]="{ readonly: readonly }">
						<input pInputText value="{{ item.telefonoContacto.telefono }}" readonly />
						<label>{{ "TELEFONO" | translate }}</label>
					</span>
				</div>
				<div class="col-12 lg:col-2">
					<span class="p-float-label" [ngClass]="{ readonly: readonly }">
						<input class="bloqueada" *ngIf="item?.fechaEnviado" pInputText value="{{ item.fechaEnviado | date : 'dd/MM/yyyy' }}" readonly />
						<input class="bloqueada" *ngIf="!item.fechaEnviado" pInputText value="{{ dummy }}" readonly />
						<label>{{ "FECHA_ENVIADO" | translate }}</label>
					</span>
				</div>

				<div class="col-12 lg:col-2">
					<span class="p-float-label" [ngClass]="{ readonly: readonly }">
						<input class="bloqueada" *ngIf="item.fechaCompletado" pInputText value="{{ item.fechaCompletado | date : 'dd/MM/yyyy' }}" readonly />
						<input class="bloqueada" *ngIf="!item.fechaCompletado" pInputText value="{{ dummy }}" readonly />
						<label>{{ "FECHA_COMPLETADO" | translate }}</label>
					</span>
				</div>
				<div class="col-12 subtitulo" *ngIf="item?.paxs?.length">
					<span>{{ "PASAJEROS" | translate }}</span>
				</div>
				<div class="col-12" *ngIf="item?.paxs?.length">
					<div class="grid">
						<div class="col-12 lg:col-4" *ngFor="let pax of item.paxs">
							<p-card styleClass="pax">
								<div class="grid p-fluid">
									<div class="col-12">
										<span for="inputtext">{{ "NOMBRE" | translate }}: </span><strong>{{ pax.nombre }}</strong>
									</div>
									<div class="col-12">
										<span for="inputtext">{{ "Apellido" | translate }}:</span> <strong>{{ pax.apellido }}</strong>
									</div>
									<div class="col-12">
										<span for="inputtext">{{ "FECHA_NACIMIENTO" | translate }}:</span>
										<strong>{{ pax.fechaNacimiento | date : "dd/MM/yyyy" }}</strong>
									</div>
									<div class="col-12">
										<span for="inputtext">{{ "Documento" | translate }}: </span
										><strong>{{ pax.tipoDocumento?.descripcion }} {{ pax.numeroDocumento }}</strong>
									</div>

									<div class="col-12">
										<span for="inputtext">{{ "Observaciones" | translate }}:</span> <strong>{{ pax.observaciones }}</strong>
									</div>
								</div>
							</p-card>
						</div>
					</div>
				</div>
			</div>
		</div>
	</model-gestor>
</div>
