`
<div class="listado-canal-comunicacion content">
	<model-list
		[service]="service"
		[modelName]="'canal-comunicacion'"
		[columns]="columns"
		[title]="'CanalComunicacion_PLR' | translate"
		[filtro]="filtro"
		[multilenguaje]="false"
		[modoSeleccion]="modoSeleccion"
		[hideHeader]="ocultarCabecera"
		[filterContent]="customFilter"
		[editable]="editable"
		(seleccionChange)="updateSeleccion($event)"
		[readonly]="readonly"
	>
		<ng-template #customFilter>
			<div class="grid align-content-center">
				<div class="col-12">
					<descriptivo-material-selector
						[isLazy]="true"
						[service]="puntoDeVentaService"
						[label]="'PuntoDeVenta' | translate"
						name="puntoDeVenta"
						[limpiable]="true"
						[(ngModel)]="filtro.puntoDeVenta"
					>
					</descriptivo-material-selector>
				</div>
			</div>
		</ng-template>
	</model-list>
</div>
