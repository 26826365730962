import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { OrdenPago } from 'src/app/model/OrdenPago';
@Injectable({
    providedIn: 'root',
})
export class OrdenPagoService extends ServicioAbstract<OrdenPago>{
    public baseName(): string {
        return "orden-pago";
    }
    public parseToEnt(data: any): OrdenPago {
        return OrdenPago.fromData(data);
    }
    public newEnt(): OrdenPago {
        return new OrdenPago();
    }


}