import { Descriptivo } from './../common/model/Descriptivo';



export class Ciudad extends Descriptivo {
    constructor(id?: number, codigo?: string, descripcion?: string,
        
		public nombre ?: string,
		public cp ?: number,
		public provinciaId ?: number,

    ) {
        super(codigo, descripcion,id)
    }
   
    public static fromData(data: any): Ciudad {
        if (!data) return null;
        let o: Ciudad = new Ciudad(
            data.id,
            data.codigo,
            data.descripcion,
            
					 data.nombre, 
					 data.cp, 
					 data.provinciaId, 
        );

        o.version = data.version; 
        return o;

    }

}