
import { Parametrico } from 'src/app/common/model/Parametrico';
import { ProfilePic } from 'src/app/common/model/ProfilePic';


export class Billete extends Parametrico {
    constructor(id?: number, codigo?: string, descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,
        esDefault: boolean = false,
        public valor?: number,
        public imagenBillete: ProfilePic = new ProfilePic(null, 'assets/images/default_profile.png'),


    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
    }
    public tipoParametro(): string {
        return "$MODELNAMELOW";
    }
    public static fromData(data: any): Billete {
        if (!data) return null;
        const o: Billete = new Billete(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,
            data.esDefault,
            data.valor,
            ProfilePic.fromData(data.imagenBillete),

        );

        o.version = data.version; 
        return o;

    }

}