import { Injectable } from "@angular/core"
import { lastValueFrom, share } from "rxjs"
import { BufferedDescriptivoService } from "src/app/services/BufferedDescriptivoService"
import { FiltroVehiculo } from "../common/model/FiltroVehiculo"
import { LoadingService } from "../common/services/loading-data-service.service"
import { MessagesService } from "../common/services/messages-data-service.service"
import { Vehiculo } from "./../model/Vehiculo"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class VehiculoService extends BufferedDescriptivoService<Vehiculo> {
	constructor(protected novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "vehiculo"
	}
	public parseToEnt(data: any): Vehiculo {
		return Vehiculo.fromData(data)
	}
	public newEnt(): Vehiculo {
		return new Vehiculo()
	}
	public getDisponiblesPrograma(filtro: FiltroVehiculo, customLoading?: LoadingService) {
		const $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("getDisponiblesPrograma"), filtro.json).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return $this.parse(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}
}
