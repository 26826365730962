import { Descriptivo } from "src/app/common/model/Descriptivo"
import { ID } from "../app.module"
import { ContenedorPuesto } from "./../common-components/puesto-badge/ContenedorPuesto"
import { IPuesto } from "./../services/IPuesto"
import { AsociacionInsumo } from "./AsociacionInsumo"
import { IConCategoria } from "./IConCategoria"
import { InsumoContainer } from "./InsumoContainer"
import { Proveedor } from "./Proveedor"
import { PuestoInsumo } from "./PuestoInsumo"
import { TipoPuesto } from "./TipoPuesto"

export class PuestoACubrir implements IConCategoria, InsumoContainer, IPuesto, ContenedorPuesto {
	constructor(
		public id?: number,
		public producto?: Descriptivo,
		public personalDefault?: Proveedor,
		public tipoPuesto?: TipoPuesto,
		public insumos: PuestoInsumo[] = [],
		public esOpcional: boolean = false,
		public keyOriginal?: string,
		public version?: number,
		public uid?: string
	) {
		this.uid = (this.uid || this.id || ID()) + ""
	}

	gastos?: import("./GastoPuesto").GastoPuesto[]

	public static fromData(data: any): PuestoACubrir {
		if (!data) return null
		const o: PuestoACubrir = new PuestoACubrir(
			data.id,
			Descriptivo.fromData(data.producto),
			Proveedor.fromData(data.personalDefault),
			TipoPuesto.fromData(data.tipoPuesto),
			data.insumos ? data.insumos.map((c) => PuestoInsumo.fromData(c)) : [],
			data.esOpcional,
			data.keyOriginal,
			data.version,
			data.uid
		)

		o.version = data.version
		return o
	}
	public getCategoria() {
		return this.tipoPuesto?.categoria?.codigo
	}
	public get imagen() {
		return this.getCategoria() == "VEHI" ? "assets/images/vehiculo.png" : this.tipoPuesto.imagen
	}

	public quitarInsumo(insumo: AsociacionInsumo) {
		this.insumos = this.insumos.filter((i) => i != insumo)
	}
	public get descripcion() {
		return this.tipoPuesto ? this.tipoPuesto.descripcion : ""
	}

	public get ocupante() {
		return this.personalDefault ? this.personalDefault : null
	}
	public get esExterno() {
		return this.tipoPuesto.codigo == TipoPuesto.ALQUILER_AUTO
	}
	public clonar(nueva: boolean = false) {
		let a = PuestoACubrir.fromData(this)
		if (nueva) {
			a.id = null
			a.insumos.forEach((i) => (i.id = null))
			a.keyOriginal = this.id + ""
		}
		return a
	}

	public get proveedor(): Proveedor {
		return this.personalDefault
	}
	public set proveedor(v: Proveedor) {
		this.personalDefault = v
	}
}
