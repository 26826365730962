<div class="servicios content">
	<div class="filter-content">
		<filtro [(filter)]="filtro" [columns]="columns" [useSidebar]="true" [isFixed]="true" [filterTemplateContent]="filterTemplate"> </filtro>
		<ng-template #filterTemplate>
			<div class="grid">
				<div class="col-12">
					<div class="grid">
						<div class="col-12 lg:col-4">
							<descriptivo-material-selector
								[(ngModel)]="filtro.actividad"
								[service]="productoService"
								[limpiable]="true"
								[label]="'Actividad' | translate"
								[permiteNuevo]="false"
								[isLazy]="true"
								[getData]="productoService.getServicios"
							>
							</descriptivo-material-selector>
						</div>
						<div class="col-12 lg:col-3">
							<descriptivo-material-selector
								[(ngModel)]="filtro.proveedor"
								[service]="proveedorService"
								[limpiable]="true"
								[label]="'Proveedor' | translate"
								[permiteNuevo]="false"
								[isLazy]="true"
							>
							</descriptivo-material-selector>
						</div>
						<div class="col-12 lg:col-3">
							<descriptivo-material-selector
								[(ngModel)]="filtro.vehiculo"
								[service]="vehiculoService"
								[limpiable]="true"
								[label]="'Vehiculo' | translate"
								[permiteNuevo]="false"
								[isLazy]="true"
							>
							</descriptivo-material-selector>
						</div>
						<div class="col-12 lg:col-2 checkbox-container">
							<p-checkbox
								[label]="'SOLO_CON_PAXS' | translate"
								[binary]="true"
								[(ngModel)]="filtro.soloConPaxs"
								pTooltip="{{ 'SOLO_CON_PAXS_TOOLTIP' | translate }}"
							></p-checkbox>
						</div>
						<div class="col-12 lg:col-6">
							<div class="grid">
								<div class="col-6 p-fluid p-field">
									<span class="p-float-label">
										<p-calendar
											[readonlyInput]="true"
											[(ngModel)]="filtro.fechaActividadDesde"
											name="fechaActividadDesde"
											dateFormat="dd/mm/yy"
											appendTo="body"
											[showIcon]="true"
											#fechaActividadDesde="ngModel"
											required
										></p-calendar>
										<label for="">{{ "FECHA_DESDE" | translate }}</label>
										<small *ngIf="getErrorMessage(fechaActividadDesde)" class="p-error block">{{
											getErrorMessage(fechaActividadDesde)
										}}</small>
									</span>
								</div>
								<div class="col-6 p-fluid p-field">
									<span class="p-float-label">
										<p-calendar
											[readonlyInput]="true"
											[(ngModel)]="filtro.fechaActividadHasta"
											name="fechaActividadHasta"
											dateFormat="dd/mm/yy"
											appendTo="body"
											[showIcon]="true"
											#fechaActividadHasta="ngModel"
											required
										></p-calendar>
										<label for="">{{ "HASTA" | translate }}</label>
										<small *ngIf="getErrorMessage(fechaActividadHasta)" class="p-error block">{{
											getErrorMessage(fechaActividadHasta)
										}}</small>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
	<div class="tabla">
		<p-table
			#table
			[value]="servicios"
			[columns]="columns"
			[paginator]="true"
			[rows]="20"
			[rowsPerPageOptions]="[5, 10, 20, 50, 100]"
			rowGroupMode="subheader"
			groupRowsBy="grupoPaxId"
		>
			<ng-template pTemplate="groupheader" let-rowData>
				<tr class="centro-costo">
					<td colspan="7">
						<img src="{{ rowData.centroCosto.profilePic.picPath || 'assets/implementacion/images/iterpax.png' }}" />
						<div>
							<p>Nombre: {{ rowData.centroCosto.descripcion }}</p>
							<p>Telefono: {{ rowData.centroCosto.telefono }}</p>
							<p>Domicilio fiscal: {{ rowData.centroCosto.domicilioFiscal }}</p>
						</div>
					</td>
				</tr>
				<tr pRowGroupHeader>
					<td colspan="8">
						<div class="cabecera-derecha">
							<p>{{ rowData.fechaActividad | date : "dd/MM/yyyy" }}</p>
							<p>
								<strong>{{ "Actividad" | translate }}:</strong> {{ rowData.tipoActividad }}
							</p>
						</div>
						<p>
							<strong>{{ "Grupo Nro." | translate }}:</strong> {{ rowData.grupoPaxId }}
						</p>
						<p>
							<strong>{{ "Vehiculo" | translate }}:</strong> {{ rowData.nombre || rowData.modeloVehiculo || ("SIN_ASIGNAR" | translate) }}
							<strong>{{ "Patente" | translate }}:</strong> {{ rowData.patenteVehiculo || "-" }}
						</p>
						<p>
							<strong>{{ "Personal_a_bordo" | translate }}:</strong>
						</p>
						<div class="grid">
							<div class="col-12 lg:col-3 md:col-4 content-badge" *ngFor="let prov of rowData.proveedores">
								<div class="circular-badge" [ngStyle]="{ 'background-image': 'url(' + prov.imagenUrl + ')' }"></div>
								<div class="detalle grid grid-nogutter">
									<label class="col-12" for=""
										>{{ (prov.nombre | titlecase) || ("SIN_ASIGNAR" | translate) }} - {{ prov.comentario | titlecase }}</label
									>
								</div>
							</div>
						</div>

						<div class="content-badge" *ngIf="rowData.proveedores?.length == 0">
							<div class="detalle grid grid-nogutter">
								<label class="col-12" for="">{{ "SIN_ASIGNAR" | translate }}</label>
							</div>
						</div>
					</td>
				</tr>
				<tr class="header">
					<th style="width: 20%">{{ "Nombre" | translate }}</th>
					<th style="width: 10%">{{ "Nacionalidad" | translate }}</th>
					<th style="width: 10%">{{ "Documento" | translate }}</th>
					<th style="width: 10%">{{ "Fecha Nac." | translate }}</th>
					<th style="width: 5%">{{ "Edad" | translate }}</th>
					<th style="width: 20%">{{ "Observaciones" | translate }}</th>
					<th style="width: 10%">{{ "Telefono" | translate }}</th>
					<th style="width: 25%">{{ "Alojamiento" | translate }}</th>
					<!-- <th style="width: 10%">{{ 'Nro. Reserva' | translate }}</th> -->
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
				<tr class="body" *ngIf="filtro.soloConPaxs">
					<td>{{ rowData.nombrePax + " " + rowData.apellidoPax }}</td>
					<td>{{ rowData.nacionalidad }}</td>
					<td>{{ rowData.documentoPax }}</td>
					<td>
						<span *ngIf="rowData.fechaNacPax">{{ rowData.fechaNacPax | date : "dd/MM/yyyy" }}</span>
					</td>

					<td>{{ rowData.edadPax }}</td>
					<td>{{ rowData.observaciones }}</td>
					<td>{{ rowData.telefonoPax }}</td>
					<td>
						<link-to-map [address]="rowData.alojamiento"></link-to-map>
					</td>
					<!-- <td>{{ rowData.nroReserva }}</td> -->
				</tr>
				<tr *ngIf="servicios[rowIndex + 1]?.grupoPaxId != rowData.grupoPaxId" class="footer">
					<td colspan="8" class="text-right font-bold pr-6">Cantidad Pax: {{ getCantidadByGrupo(rowData.grupoPaxId) }}</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="groupfooter" let-rowData>
				<div style="height: 25px"></div>
			</ng-template>
		</p-table>
	</div>
</div>
