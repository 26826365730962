import { Parametrico } from "src/app/common/model/Parametrico"
import { Descriptivo } from "../common/model/Descriptivo"

export class EstadoReserva extends Parametrico {
	public static CERRADA: string = "C"
	public static APROBADA: string = "A"
	public static CONFIRMADA: string = "CONF"
	public static FINALIZADA: string = "F"
	public static CANCELADA: string = "CANC"
	public static CANCEL: string = "CAN"
	public static COMPLETADO: string = "OK"
	public static COTIZACION: string = "COT"
	public static descriptivo(val: string) {
		return new Descriptivo(val, val)
	}
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado: boolean = true,
		esSistema: boolean = false,
		peso: number = 99,
		public color?: string,
		public icono?: string
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso)
	}
	public esConfirmable() {
		return (
			this.codigo != EstadoReserva.CERRADA &&
			this.codigo != EstadoReserva.CANCELADA &&
			this.codigo != EstadoReserva.CONFIRMADA &&
			this.codigo != EstadoReserva.FINALIZADA
		)
	}
	public
	esCerrada() {
		return this.codigo == EstadoReserva.CERRADA || this.codigo == EstadoReserva.FINALIZADA
	}
	esFinalizable() {
		return this.codigo == EstadoReserva.CERRADA
	}

	public esAprobada() {
		return this.codigo == EstadoReserva.APROBADA
	}
	public esCancelada() {
		return this.codigo == EstadoReserva.CANCELADA || this.codigo === EstadoReserva.CANCEL
	}

	public esCompletado() {
		return this.codigo == EstadoReserva.COMPLETADO
	}
	public tipoParametro(): string {
		return "EstadoReservaLOW"
	}
	public static fromData(data: any): EstadoReserva {
		if (!data) return null
		const o: EstadoReserva = new EstadoReserva(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			data.esSistema,
			data.peso,

			data.color,
			data.icono
		)

		o.version = data.version
		return o
	}
}
