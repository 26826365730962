import { Descriptivo } from "src/app/common/model/Descriptivo"
import { ProfilePic } from "src/app/common/model/ProfilePic"
import { Insumo } from "src/app/model/Insumo"
import { PrecioInsumo } from "src/app/model/PrecioInsumo"
import { TipoPuesto } from "src/app/model/TipoPuesto"
import { IOcupante } from "./../services/IPuesto"
import { Hotel } from "./Hotel"
import { Idioma } from "./Idioma"
import { Moneda } from "./Moneda"

export class Proveedor extends Descriptivo implements IOcupante {
	public get whatsapp(): string {
		return this.telefono?.replace(/[^0-9]/g, "")
	}
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public nombre?: string,
		public tipoProveedor?: Descriptivo,
		public numeroDocumento?: string,
		public email?: string,
		public emailAdministrativo?: string,
		public telefono?: string,
		public comentario?: string,
		public activo?: boolean,
		public facebook?: string,
		public instagram?: string,
		public iva?: Descriptivo,
		public domicilio?: string,
		public formaPagoDefault?: Descriptivo,
		public nacionalidad?: Descriptivo,
		public profilePic: ProfilePic = new ProfilePic(null, "assets/images/default_profile.png"),
		public tiposPuesto: TipoPuesto[] = [],
		public insumos: PrecioInsumo[] = [],
		public razonSocial?: string,
		public plazoCtaCte: number = 0,
		public idiomas: Idioma[] = [],
		public noHabitual: boolean = false,
		public habilitado: boolean = true,
		public tipoDocumento?: Descriptivo,
		public cliente?: Descriptivo,
		public hotelRelacionado?: Hotel
	) {
		super(codigo, nombre || razonSocial, id)
		this.searchStr = [this.id, this.descripcion, this.razonSocial, this.numeroDocumento, this.email].join("_")
	}

	public static fromData(data: any): Proveedor {
		if (!data) return null
		const o: Proveedor = new Proveedor(
			data.id,
			data.codigo,
			data.descripcion,
			data.nombre,
			Descriptivo.fromData(data.tipoProveedor),
			data.numeroDocumento,
			data.email,
			data.emailAdministrativo,
			data.telefono,
			data.comentario,
			data.activo,
			data.facebook,
			data.instagram,
			Descriptivo.fromData(data.iva),
			data.domicilio,
			Descriptivo.fromData(data.formaPagoDefault),
			Descriptivo.fromData(data.nacionalidad),
			ProfilePic.fromData(data.profilePic) || new ProfilePic(null, data.imagenUrl),
			data.tiposPuesto ? data.tiposPuesto.map((t) => TipoPuesto.fromData(t)) : [],
			data.insumos ? data.insumos.map((i) => PrecioInsumo.fromData(i)) : [],
			data.razonSocial,
			data.plazoCtaCte,
			data.idiomas ? data.idiomas.map((i) => Idioma.fromData(i)) : [],
			data.noHabitual,
			data.habilitado
		)
		o.version = data.version
		o.tipoDocumento = Descriptivo.fromData(data.tipoDocumento)
		o.hotelRelacionado = Hotel.fromData(data.hotelRelacionado)
		o.cliente = Descriptivo.fromData(data.cliente)
		return o
	}

	public quitarPuesto(tp: TipoPuesto) {
		this.tiposPuesto = this.tiposPuesto.filter((t) => t.id != tp.id)
	}
	public tieneInsumo(i: Insumo) {
		return this.insumos.some((ii) => ii.insumo && ii.insumo.id == i.id)
	}

	public getPrecio(idInsumo: number, fechaVigencia: Date, moneda?: Moneda, cantidad?: number): PrecioInsumo {
		return this.insumos.find((i) => i.aplica({ id: idInsumo }, fechaVigencia, cantidad, moneda)) || new PrecioInsumo(null, null, null, 0, 0)
	}
	public getPrecioVenta(idInsumo: number, fechaVigencia: Date, moneda?: Moneda, cantidad?: number): number {
		const precio = this.getPrecio(idInsumo, fechaVigencia, moneda, cantidad)

		const importe = precio.precioMinimo && precio.precio * cantidad < precio.precioMinimo ? precio.precioMinimo / cantidad : precio.precio
		if (precio.tipoMarkup == PrecioInsumo.MARKUP) return importe * (1 + precio.markup / 100)
		else if (precio.tipoMarkup == PrecioInsumo.COMISION) return importe
		else if (precio.tipoMarkup == PrecioInsumo.GANANCIA) return precio.markup ? importe / (1 - precio.markup / 100) : 0
	}
	public get imagen() {
		return this.profilePic ? this.profilePic.picPathVersion : "assets/images/default.png"
	}
	public get imagenUrl() {
		return this.profilePic ? this.profilePic.picPathVersion : "assets/images/default.png"
	}

	public get esPrestador() {
		return this.tipoProveedor.codigo == "SE" || this.tipoProveedor.codigo == "ST" || this.tipoProveedor.codigo == "SL"
	}

	public get esHospedaje() {
		return this.hotelRelacionado != null
	}
}
