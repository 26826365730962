<div class="gestor-reserva content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="(esCotizacion ? 'COTIZACION' : 'RESERVA') | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		(onCancelar)="handleCancelar($event)"
		[form]="ReservaForm"
		[verAcciones]="!creando && !authService.esClienteExterno"
		[hideButtons]="true"
		[menuItemAcciones]="acciones"
		[editable]="esEditable()"
		[customGuardar]="customGuardar"
		#modelGestor
	>
		<div class="gestor-form" model-type="dataContent">
			<form #ReservaForm="ngForm" id="Reservaform" autocomplete="off">
				<input autocomplete="false" name="hidden" type="text" style="display: none" />
				<div class="grid" *ngIf="item">
					<div class="col-12 subtitulo">
						<span>
							{{ "DATOSRESERVA" | translate }}
						</span>
						<p-checkbox
							*ngIf="!item.id && !authService.esClienteExterno"
							[binary]="true"
							name="esCotizacion"
							[label]="'ES_COTIZACIÓN' | translate"
							[(ngModel)]="esCotizacion"
							[disabled]="readonly || !esEditable()"
						></p-checkbox>

						<button
							pButton
							(click)="convertirAReserva(true)"
							label="{{ 'PASAR_A_RESERVA' | translate }}"
							*ngIf="item.id && item.esCotizacion && !authService.esClienteExterno"
						></button>
						<button
							pButton
							(click)="convertirAReserva(false)"
							label="{{ 'PASAR_A_RESERVA_SIN_AUTOASIGNAR' | translate }}"
							*ngIf="item.id && item.esCotizacion && !authService.esClienteExterno"
						></button>
						<button
							*ngIf="item.id && !esClienteExterno"
							pButton
							(click)="verHistorialComprobante()"
							label="{{ 'HISTORIAL_COMPROBANTE' | translate }}"
						></button>
						<span *ngIf="item?.estado?.esCancelada()" style="margin-left: 4rem">
							Motivo de cancelación:
							{{ item.motivoCancelacion || "Sin Motivo" }}
						</span>
					</div>
					<div class="col-3 p-fluid field" *ngIf="!authService.esClienteExterno">
						<descriptivo-material-selector
							[allowDefault]="!item?.id"
							[label]="'PUNTO_VENTA' | translate"
							[limpiable]="false"
							[readonly]="readonly"
							[(ngModel)]="item.puntoDeVenta"
							name="punto_de_venta"
							isLazy="true"
							[service]="puntoDeVentaService"
							[disabled]="readonly || !esEditable()"
							[autohide]="true"
							required
						>
						</descriptivo-material-selector>
					</div>

					<div class="col-12 p-fluid field lg:col-5" *ngIf="!authService.esClienteExterno">
						<descriptivo-material-selector
							[allowDefault]="!item?.id"
							[service]="clienteService"
							[label]="'CLIENTE' | translate"
							[limpiable]="limpiable"
							[(ngModel)]="clienteSeleccionado"
							[permiteNuevo]="true"
							[readonly]="readonly || authService.esClienteExterno"
							name="cliente"
							[gestor]="gestorCliente"
							[isLazy]="true"
							(onSelect)="updateDatosContacto($event)"
							[disabled]="readonly || !esEditable()"
							[default]="clienteExterno"
							[required]="true"
						>
							<ng-template #gestorCliente let-itemEditado="itemEditado" let-handler="handler">
								<gestor-cliente
									(onGuardado)="handler.onGuardado($event)"
									(onCancelado)="handler.onCancelado($event)"
									[isModal]="true"
									[goBack]="false"
								>
								</gestor-cliente>
							</ng-template>
						</descriptivo-material-selector>
					</div>

					<div class="col-12 p-fluid field lg:col-2">
						<span class="p-float-label">
							<p-calendar
								[(ngModel)]="item.fechaRegistro"
								name="fechaRegistro"
								dateFormat="dd/mm/yy"
								appendTo="body"
								[showIcon]="true"
								#fechaRegistro="ngModel"
								required
								[disabled]="readonly || !esEditable()"
							></p-calendar>
							<label for="">{{ "FECHAREGISTRO" | translate }}</label>
						</span>
						<error-tag [model]="fechaRegistro"></error-tag>
					</div>
					<div class="col-12 p-fluid field lg:col-2">
						<span class="p-float-label">
							<p-calendar
								[(ngModel)]="item.fechaVencimiento"
								name="fechaVencimiento"
								dateFormat="dd/mm/yy"
								appendTo="body"
								[showIcon]="true"
								#fechaVencimiento="ngModel"
								required
								[disabled]="readonly || !esEditable()"
							></p-calendar>
							<label for="">{{ "FECHAVENCIMIENTO" | translate }}</label>
						</span>
						<error-tag [model]="fechaVencimiento"></error-tag>
					</div>

					<div class="col-12 p-fluid field lg:col-4" *ngIf="!authService.esClienteExterno">
						<descriptivo-material-selector
							[allowDefault]="!item?.id"
							[options]="vendedores"
							[isLazy]="false"
							[label]="'VENDEDOR' | translate"
							[limpiable]="limpiable"
							[(ngModel)]="item.vendedor"
							[permiteNuevo]="false"
							[gestor]="gestorProveedor"
							name="vendedor"
							[readonly]="readonly || !esEditable(false)"
						>
						</descriptivo-material-selector>
					</div>
					<div class="col-12 p-fluid field lg:col-3" *ngIf="!authService.esClienteExterno">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[service]="canalVentaService"
							#canalVenta="ngModel"
							[(ngModel)]="item.canalVenta"
							[limpiable]="true"
							[permiteNuevo]="true"
							name="canalVenta"
							[titulo]="'CANALVENTA' | translate"
							[readonly]="readonly || !esEditable()"
						>
						</parametrico-selector>
					</div>
					<div class="col-12 p-fluid field lg:col-3">
						<descriptivo-material-selector
							[allowDefault]="!item?.id"
							[options]="listasPrecios"
							[isLazy]="false"
							[label]="'LISTA_PRECIOS' | translate"
							[limpiable]="limpiable"
							[(ngModel)]="item.listadoPrecio"
							[permiteNuevo]="false"
							name="listadoPrecio"
							[required]="true"
							(onSelect)="onSelectListado($event)"
							[readonly]="readonly || !puedeEditarListado"
						>
						</descriptivo-material-selector>
					</div>
					<div class="col-12 lg:col-2 p-fluid field" *ngIf="item.moneda && item.moneda?.codigo != configuracionEntorno?.moneda?.codigo">
						<span-dinero-editable
							[(ngModel)]="item.cotizacion"
							[editable]="!readonly || cambioListado || item?.id == null"
							label="{{ !item?.moneda ? ('COTIZACION_A_LOCAL' | translate) : monedaEntorno?.codigo + ' ► ' + item?.moneda?.codigo }}"
							name="cotizacion"
							[minValue]="0"
							[decimals]="6"
						>
						</span-dinero-editable>
					</div>

					<div class="col-12 lg:col-3 p-fluid field">
						<span class="p-float-label">
							<input
								pInputText
								[(ngModel)]="item.nombrePax"
								tooltipPosition="bottom"
								type="text"
								#nombrePax="ngModel"
								name="nombrePax"
								[readonly]="readonly"
								required
							/>
							<label>{{ "NOMBREPAX" | translate }}</label>
						</span>
						<error-tag [model]="nombrePax"></error-tag>
					</div>
					<div class="col-4 lg:col-fixed p-fluid field w-8rem nowrap">
						<span class="p-float-label w-5rem">
							<input
								pInputText
								placeholder="{{ 'CANTIDADPAX_PH' | translate }}"
								[(ngModel)]="item.cantidadPax"
								tooltipPosition="bottom"
								pTooltip="{{ 'CANTIDADPAX_TOOLTIP' | translate }}"
								type="number"
								name="cantidadPax"
								#cantidadPax="ngModel"
								required
								[readonly]="readonly || !esEditable()"
								min="1"
								(change)="controlarDatosPaxs()"
							/>
							<label>{{ "Paxs" | translate }}</label>
						</span>
						<error-tag [model]="cantidadPax"></error-tag>
						<button
							pButton
							class="ml-1 pax-button"
							(click)="ingresarDatosPaxs()"
							pTooltip="{{ 'INGRESAR_DATOS_DE_LOS_PASAJEROS' | translate }}"
							icon="fa fa-pencil-square-o"
							[disabled]="item.cantidadPax < 1"
						></button>
					</div>
					<div class="col-12 lg:col-2 p-fluid field">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'EMAILCONTACTO_PH' | translate }}"
								[(ngModel)]="item.emailContacto"
								tooltipPosition="bottom"
								pTooltip="{{ 'EMAILCONTACTO_TOOLTIP' | translate }}"
								type="text"
								name="emailContacto"
								#emailContacto="ngModel"
								appMultiEmailValidator
								[readonly]="readonly"
							/>
							<label>{{ "EMAILCONTACTO" | translate }}</label>
						</span>
						<error-tag [model]="emailContacto"></error-tag>
					</div>
					<div class="col-12 lg:col-2 p-fluid field">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'TELEFONOCONTACTO_PH' | translate }}"
								[(ngModel)]="item.telefonoContacto"
								tooltipPosition="bottom"
								pTooltip="{{ 'TELEFONOCONTACTO_TOOLTIP' | translate }}"
								type="text"
								#telefonoContacto="ngModel"
								name="telefonoContacto"
								telefono
								[readonly]="readonly"
							/>
							<label>{{ "TELEFONOCONTACTO" | translate }}</label>
						</span>
						<error-tag [model]="telefonoContacto"></error-tag>

						<!-- <telefono-selector [(ngModel)]="item.telefonoContacto" name="telefonoContacto" #telefonoContacto="ngModel" [disabled]="readonly" ></telefono-selector> -->
					</div>
					<div class="col-12 lg:col-2 p-fluid field">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[service]="nacionalidadService"
							#nacionalidadPax="ngModel"
							[(ngModel)]="item.nacionalidadPax"
							[limpiable]="true"
							[permiteNuevo]="false"
							name="nacionalidad"
							[titulo]="'NACIONALIDADPAX' | translate"
							[readonly]="readonly"
							[required]="true"
						>
						</parametrico-selector>
					</div>
					<div class="col-12 lg:col-2 p-fluid field" *ngIf="esArgentina && item.nacionalidadPax?.codigo != 'AR'">
						<parametrico-selector
							[allowDefault]="!item?.id"
							#tipoCliente="ngModel"
							[listado]="tiposCliente"
							[(ngModel)]="item.tipoCliente"
							[limpiable]="true"
							[permiteNuevo]="false"
							name="tipoClienteReserva"
							[readonly]="readonly"
							[titulo]="'Tipo de cliente' | translate"
							[required]="true"
						>
						</parametrico-selector>
					</div>

					<div class="col-12 lg:col-2 p-fluid field">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[service]="idiomaService"
							#idiomaPax="ngModel"
							[(ngModel)]="item.idiomaPax"
							[limpiable]="true"
							[permiteNuevo]="false"
							name="idioma"
							[readonly]="readonly"
							[titulo]="'IDIOMAPAX' | translate"
							(onSelect)="cambiarIdiomaReserva($event)"
						>
						</parametrico-selector>
					</div>

					<div class="col-12 lg:col-3 p-fluid field">
						<div class="grid grid-nogutter">
							<div class="col-12">
								<descriptivo-material-selector
									[allowDefault]="!item?.id"
									[service]="hotelService"
									[isLazy]="true"
									[label]="'HOTEL' | translate"
									[limpiable]="limpiable"
									[(ngModel)]="hotelSeleccionado"
									[permiteNuevo]="!esClienteExterno"
									[getData]="hotelService.getHabilitados"
									[readonly]="readonly || hayHospedaje"
									[gestor]="gestorHotel"
									name="hotel"
								>
									<ng-template #gestorHotel let-itemEditado="itemEditado" let-handler="handler">
										<gestor-hotel
											[item]="itemEditado"
											(onGuardado)="handler.onGuardado($event)"
											(onCancelado)="handler.onCancelado($event)"
											[isModal]="true"
											[goBack]="false"
										>
										</gestor-hotel>
									</ng-template>
								</descriptivo-material-selector>
							</div>
							<div class="col-12" *ngIf="item?.hotel?.codigoPostal == 'OTRA'">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'DIRECCION' | translate }}"
										[readonly]="readonly"
										[(ngModel)]="item.direccionAlternativa"
										tooltipPosition="bottom"
										pTooltip="{{ 'DIRECCION' | translate }}"
										type="text"
										required
										#direccionAlternativa="ngModel"
										(blur)="updateDireccion($event)"
										name="direccionAlternativa"
									/>
									<label>{{ "DIRECCION" | translate }}</label>
								</span>
								<error-tag [model]="direccionAlternativa"></error-tag>
							</div>
						</div>
					</div>

					<div class="col p-fluid">
						<span class="p-float-label">
							<textarea
								pInputText
								placeholder="{{ 'OBSERVACIONES_PH' | translate }}"
								[readonly]="readonly"
								[(ngModel)]="item.observaciones"
								tooltipPosition="bottom"
								pTooltip="{{ 'OBSERVACIONES_RESERVA' | translate }}"
								type="text"
								#observaciones="ngModel"
								name="observaciones"
							></textarea>
							<label>{{ "OBSERVACIONES" | translate }}</label>
						</span>
					</div>
					<div class="col p-fluid">
						<span class="p-float-label">
							<textarea
								pInputText
								placeholder="{{ 'Notas para el cliente' | translate }}"
								[readonly]="readonly"
								[(ngModel)]="item.notaCliente"
								tooltipPosition="bottom"
								pTooltip="{{ 'Estas Notas serán visibles para el cliente' | translate }}"
								type="text"
								#notaCliente="ngModel"
								name="notaCliente"
							></textarea>
							<label>{{ "Notas Cliente" | translate }}</label>
						</span>
					</div>

					<div class="col-12 subtitulo">
						<div class="grid col-12">
							<div class="col-6 lg:col-8 nowrap flex align-items-center">
								<span>{{ "Productos y servicios" | translate }} </span>
								<button
									pButton
									(click)="agregarServicios()"
									icon="fa fa-plus"
									[disabled]="!cabeceraCompleta || !servicioEditable"
									*ngIf="!readonly"
								></button>
							</div>
							<div class="col-6 lg:col-4 total">
								<span>Total: {{ item.totalSinHospedajes | currencyCustom : item?.moneda }}</span>
							</div>
						</div>
					</div>
					<div class="col-12 servicios">
						<div class="grid grid-nogutter">
							<div class="col-12 text-center mb-5" *ngIf="item?.itemsReserva?.length == 0">
								<strong>{{ "NO_HAY_SERVICIOS_RE_8" | translate }}</strong>
							</div>
							<div
								class="servicio-wrapper col-12"
								*ngFor="let servicio of item.itemsReserva | hospedaje : false; let i = index"
								(click)="agregarServicios(servicio, i)"
							>
								<div
									class="servicio card"
									[ngClass]="{
										error: !item.esCotizacion && servicio.porUbicar > 0,
										cancelada: servicio?.esCancelada,
										programa: servicio?.esPrograma
									}"
								>
									<div class="grid grid-nogutter align-content-center">
										<div class="total-seccion col-12">
											<div class="grid">
												<div class="servicio-titulo col-12" [ngClass]="impuestosIncluidos ? 'lg:col-6' : 'lg:col-5'">
													<span class="block"> {{ servicio?.fullProductoDescripcion }}</span>
													<small class="block" *ngIf="servicio?.programaId">{{ getDescripcionPrograma(servicio) }}</small>
												</div>

												<div class="col lg:col-2 importes">
													<span>{{ servicio?.precioPerCapita | currencyCustom : item?.moneda }}</span>
													<span> x </span>
													<span>{{ servicio?.cantidadPax }}</span>
												</div>
												<div class="col lg:col-1 importes">
													<span>{{ servicio?.neto | currencyCustom : item?.moneda }}</span>
												</div>
												<div class="col importes total" *ngIf="servicio?.ajuste">
													<span>{{ servicio?.ajuste | number : "1.0" }} %</span>
												</div>
												<div class="col importes total" *ngIf="!impuestosIncluidos">
													<span *ngIf="!servicio?.esSubcategorizado"
														>IVA:
														{{ servicio?.tipoIVA?.valorIva | number : "1.0" }}
														%</span
													>
													<small *ngIf="servicio?.esSubcategorizado">{{ servicio?.resumenImpuestos }} </small>
												</div>
												<div class="col lg:col-2 importes total">
													<span>{{ servicio?.total | currencyCustom : item?.moneda }}</span>
												</div>
											</div>
										</div>
										<div class="col-12 data-seccion">
											<div class="grid">
												<div
													class="col-12 lg:col-4"
													*ngIf="servicio?.esServicio() || servicio?.esTercerizado() || servicio?.esTransfer()"
												>
													<span>{{ servicio?.fechaActividad | date : "dd/MM/yyyy" }}</span>
													<span style="margin-left: 1em" *ngIf="servicio?.pickUp"
														>Pickup {{ servicio?.pickUp | date : "HH:mm" }}</span
													>
												</div>

												<div
													class="col lg:col-2 paxs"
													*ngIf="servicio?.esServicio() || servicio?.esTercerizado() || servicio?.esTransfer()"
												>
													<span
														><i class="fa fa-user" [style.color]="itemReserva?.colorVariante"></i>{{ servicio?.cantidadPax }}</span
													>
													<span><i class="fa fa-clock-o"></i>{{ servicio?.duracion }}</span>
													<i class="fa fa-comment"></i>
													<span class="idioma">
														<selector-idioma [idioma]="servicio.idiomaServicio" [readOnly]="readonly"> </selector-idioma>
													</span>
												</div>
												<div
													class="col lg:col-2 restricciones"
													*ngIf="servicio?.esServicio() || servicio?.esTercerizado() || servicio?.esTransfer()"
												>
													<profile-badge
														[url]="r.imagenUrl"
														[class]="'small-badge'"
														[disabled]="true"
														*ngFor="let r of servicio?.restricciones"
													>
													</profile-badge>
												</div>
												<div class="col lg:col-2" *ngIf="servicio?.comision > 0">
													<label for="comision">{{ "COMISIÓN" | translate }}</label>
													<span name="comision" style="margin-left: 1em">{{ servicio?.comision }}%</span>
												</div>
												<div class="col" *ngIf="servicio?.hotel?.id != item?.hotel?.id">
													<span>
														<i class="fa fa-building" style="margin-right: 0.25rem"></i>{{ servicio?.referenciaDireccion }}</span
													>
												</div>
												<div
													class="col-12 lg:col-7 productos"
													*ngIf="servicio?.esServicio() || servicio?.esTercerizado() || servicio?.esTransfer()"
												>
													<div class="grid grid-nogutter" *ngFor="let prod of servicio?.productosAsociados; let j = index">
														<div class="text-right" [ngClass]="impuestosIncluidos ? 'col-6' : 'col-5'">
															<span>{{ prod?.producto?.descripcion }}</span>
															<span style="margin: 0 0.25em"> x </span>
															<span>{{ prod?.cantidad }}</span>
														</div>
														<div class="col-3 importes" *ngIf="prod.esAdicional || prod.conPrecio">
															<span>{{ prod?.importe | currencyCustom : item?.moneda }}</span>
														</div>
														<div class="col-1 importes total" *ngIf="(prod.esAdicional || prod.conPrecio) && !impuestosIncluidos">
															<span *ngIf="!prod?.esSubcategorizado">{{ prod?.tipoIva?.valorIva | number : "1.0" }} %</span>
															<small *ngIf="prod?.esSubcategorizado">Impuestos compuestos </small>
														</div>
														<div class="col-2 importes total" *ngIf="prod.esAdicional || prod.conPrecio">
															<span>{{ prod?.total | currencyCustom : item?.moneda }}</span>
														</div>
														<div class="col-6 text-center" *ngIf="!prod.esAdicional && !prod.conPrecio">
															<strong>{{ "INCLUIDO" | translate }}</strong>
														</div>
													</div>
												</div>
												<div class="col-12 lg:col-5 observaciones">
													<span *ngIf="servicio?.referencia" class="block mb-1"
														><strong class="mr-1">Referencia Pickup: </strong>{{ servicio?.referencia }}</span
													>
													<span class="block" *ngIf="servicio?.observaciones"
														><strong>Observaciones:</strong> {{ servicio?.observaciones }}</span
													>
													<span class="block" *ngIf="servicio?.notaCliente"
														><strong>Notas cliente:</strong> {{ servicio?.notaCliente }}</span
													>
												</div>
												<div class="col-12 lg:col-8" *ngIf="!esClienteExterno">
													<div class="grid align-content-center">
														<!-- <div class="col col-fixed"  *ngFor="let conf of servicio?.confirmaciones; let i = index"> -->
														<div class="col col-fixed" *ngFor="let grupoReserva of servicio?.reservas; let grIndex = index">
															<div
																class="d-flex align-items-center justify-content-between"
																*ngFor="let puesto of grupoReserva.grupoResumen?.puestos; let j = index"
															>
																<puesto-badge
																	[mostrarEstado]="true"
																	[(ngModel)]="puesto.personal"
																	[puesto]="puesto"
																	[verDetalle]="true"
																	[disabled]="readonly"
																	[estadoBadge]="getEstado(servicio?.confirmaciones, puesto)"
																	[size]="'small'"
																	[ngModelOptions]="{ standalone: true }"
																	(onSeleccion)="onSeleccionPuesto($event, puesto, servicio?.confirmaciones)"
																>
																</puesto-badge>
																<button
																	class="mr-2 confirm-button p-button-sm"
																	label="{{ isMobile() ? '' : ('CONFIRMAR' | translate) }}"
																	pButton
																	(click)="mostrarCambioEstado(puesto, servicio, $event)"
																	icon="fa fa-check"
																	[disabled]="confirmado(servicio?.confirmaciones, puesto)"
																	*ngIf="!readonly && servicio?.id && !convirtiendoAReserva && puesto.permiteConfirmar"
																></button>
																<div class="d-flex" *ngIf="puesto.permiteConfirmar && (!servicio?.id || convirtiendoAReserva)">
																	<label class="fw-500 mr-2" for="confirmar">{{ "CONFIRMAR" | translate }}</label>
																	<p-inputSwitch
																		id="confirmar"
																		name="confirmar"
																		#confirmar="ngModel"
																		[(ngModel)]="puesto.confirmar"
																		(click)="$event?.stopPropagation()"
																	></p-inputSwitch>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="col-12 lg:col-8" *ngIf="servicio.esPrograma">
													<div class="grid align-content-center">
														<div class="col col-fixed" *ngFor="let puesto of servicio?.configuracionPrograma?.puestos">
															<puesto-badge
																[mostrarEstado]="true"
																[puesto]="puesto.puesto"
																[verDetalle]="true"
																[disabled]="readonly"
																[size]="'small'"
																[(ngModel)]="puesto.personal"
																(onSeleccion)="asignarPuestoPrograma($event, puesto, servicio)"
																[ngModelOptions]="{ standalone: true }"
																[disabled]="!servicio.configuracionPrograma?.vehiculoAsignado"
															>
															</puesto-badge>
														</div>
													</div>
													<div class="grid">
														<div class="col-12" *ngIf="item?.tieneProgramaConVehiculo">
															<div class="content-badge">
																<div class="p-element circular-badge" (click)="mostrarSeleccionVehiculo($event, servicio)">
																	<i class="pi pi-car" style="font-size: 2rem"></i>
																</div>
																<div class="detalle grid grid-nogutter">
																	<label class="col-12">
																		{{ "VEHICULO" | translate }}
																		{{ servicio.configuracionPrograma?.vehiculoAsignado?.descripcion }}
																	</label>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="col-12 botonera">
											<p-checkbox
												class="mr-2"
												*ngIf="servicio?.familiaProducto && !servicio.esCancelada"
												[disabled]="readonly"
												[(ngModel)]="servicio.agrupado"
												(onChange)="setAgrupado($event, servicio)"
												[binary]="true"
												[ngModelOptions]="{ standalone: true }"
												[label]="'AGRUPAR_VOUCHER' | translate"
												pTooltip="{{ 'AGRUPAR_VOUCHER_TOOLTIP' | translate }}"
												inputId="binary"
											></p-checkbox>
											<button
												class="icon-button"
												pButton
												icon="fa fa-pencil-square-o"
												pTooltip="{{ 'VER_ENCUESTA' | translate }}"
												*ngIf="servicio?.actividadCompletada && servicio?.tieneEncuesta && servicio?.id"
												(click)="verEncuesta($event, servicio)"
											></button>
											<button
												class="icon-button"
												pButton
												icon="fa fa-qrcode"
												pTooltip="{{ 'VER_VOUCHER' | translate }}"
												*ngIf="servicio?.id"
												(click)="verVoucher($event, servicio)"
											></button>
											<button
												pButton
												*ngIf="!servicio?.esCancelada && !creando && esGestorReserva && !readonly && servicio?.id"
												(click)="$event.stopPropagation(); mostrarDialogoMotivo(servicio)"
												icon="fa fa-times-circle"
												label="{{ 'Cancelar' | translate }}"
												class="cancelar"
											></button>
											<span *ngIf="servicio?.esCancelada">Cancelado: {{ servicio.motivoCancelacion }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<ng-container #hospedajes>
						<div class="servicios w-100">
							<div class="subtitulo hospedaje">
								<div class="col-6 lg:col-8 nowraps flex align-items-center">
									<span>{{ "HOSPEDAJES" | translate }} </span>
									<button
										pButton
										(click)="agregarServicios(null, -1, true)"
										icon="fa fa-plus"
										[disabled]="!cabeceraCompleta || !servicioEditable"
										*ngIf="!readonly && hotelesProveedor?.length > 0"
									></button>
									<strong *ngIf="!readonly && hotelesProveedor?.length == 0" class="ml-3">{{
										"NO_HAY_HOTELES_CON_PROVEEDOR" | translate
									}}</strong>
								</div>
								<div class="col-6 lg:col-4 total justify-content-end">Total: {{ item.totalHospedajes | currencyCustom : item?.moneda }}</div>
							</div>

							<div class="grid grid-nogutter">
								<div
									class="servicio-wrapper col-12"
									*ngFor="let servicio of item.itemsReserva | hospedaje; let i = index"
									(click)="agregarServicios(servicio, i, true)"
								>
									<div
										class="servicio card p-2"
										[ngClass]="{
											cancelada: servicio?.esCancelada
										}"
									>
										<div class="grid grid-nogutter align-content-center">
											<div class="total-seccion col-12">
												<div class="grid">
													<div class="servicio-titulo col-12" [ngClass]="impuestosIncluidos ? 'lg:col-7' : 'lg:col-6'">
														<span>
															{{ servicio?.producto?.descripcion }}
															{{ servicio?.categoriaHospedaje ? " - " + servicio?.categoriaHospedaje?.descripcion : "" }}</span
														>
														<!-- <span class="mx-2"> - </span>
														<span>{{ servicio?.hotel?.descripcion }}</span> -->
													</div>

													<div class="col lg:col-1 importes">
														<span>{{ servicio?.neto | currencyCustom : item?.moneda }}</span>
													</div>
													<div class="col importes total" *ngIf="servicio?.ajuste">
														<span>{{ servicio?.ajuste | number : "1.0" }} %</span>
													</div>
													<div class="col importes total" *ngIf="!impuestosIncluidos">
														<span *ngIf="!servicio?.esSubcategorizado"
															>IVA:
															{{ servicio?.tipoIVA?.valorIva | number : "1.0" }}
															%</span
														>
														<small *ngIf="servicio?.esSubcategorizado">{{ servicio?.resumenImpuestos }} </small>
													</div>
													<div class="col lg:col-2 importes total">
														<span>{{ servicio?.total | currencyCustom : item?.moneda }}</span>
													</div>
												</div>
											</div>
											<div class="col-12 data-seccion">
												<div class="flex align-items-start">
													<div class="flex align-items-center alojamiento-section">
														<div class="content-badge">
															<div
																class="circular-badge {{
																	getEstadoHospedaje(servicio.confirmaciones)?.codigo || 'sin-estado'
																}} {{ size }}"
																tooltipPosition="bottom"
																pTooltip="{{ getEstadoHospedaje(servicio.confirmaciones) }}"
																[ngStyle]="{ 'background-image': 'url(' + servicio.hotel?.profilePic?.picPath + ')' }"
															></div>
															<div class="detalle grid grid-nogutter" *ngIf="servicio?.hotel">
																<label class="col-12" for="">{{ "ALOJAMIENTO" }}</label>
																<span class="col-12">{{ servicio.hotel.descripcion }}</span>
															</div>
														</div>
														<button
															class="mr-2 confirm-button p-button-sm"
															label="{{ isMobile() ? '' : ('CONFIRMAR' | translate) }}"
															pButton
															(click)="mostrarCambioEstadoHospedaje(servicio, $event)"
															icon="fa fa-check"
															[disabled]="hospedajeConfirmado(servicio?.confirmaciones)"
															*ngIf="!readonly && !convirtiendoAReserva && servicio.id"
														></button>
														<div class="d-flex" *ngIf="!servicio?.id || convirtiendoAReserva">
															<label class="fw-500 mr-2" for="confirmar">{{ "CONFIRMAR" | translate }}</label>
															<p-inputSwitch
																id="confirmar"
																name="confirmar"
																#confirmar="ngModel"
																[(ngModel)]="servicio.confirmarHospedaje"
																(click)="$event?.stopPropagation()"
															></p-inputSwitch>
														</div>
													</div>
													<div class="data-section">
														<div class="mb-2 grid">
															<div class="col-12 lg:col-4">
																<i class="fa fa-map-marker" style="margin-right: 0.25rem"></i
																>{{ servicio?.hotel?.direccion || ("SIN_DIRECCION" | translate) }}
															</div>
															<div class="paxs col-12 lg:col-4">
																<span
																	><i class="fa fa-user"></i>{{ servicio?.cantidadPax }}

																	x {{ servicio.cantidadNoches }} noches
																</span>

																<div class="flex align-items-center">
																	<i class="fa fa-comment mr-4"></i>
																	<span class="idioma">
																		<selector-idioma [idioma]="servicio.idiomaServicio" [readOnly]="readonly">
																		</selector-idioma>
																	</span>
																</div>
															</div>
															<div class="restricciones mr-2 col-12 lg:col-2">
																<profile-badge
																	[url]="r.imagenUrl"
																	[class]="'small-badge'"
																	[disabled]="true"
																	*ngFor="let r of servicio?.restricciones"
																>
																</profile-badge>
															</div>
															<div class="col-12 lg:col-2" *ngIf="servicio?.comision > 0">
																<label for="comision">{{ "COMISIÓN" | translate }}</label>
																<span name="comision" style="margin-left: 1em">{{ servicio?.comision }}%</span>
															</div>
														</div>
														<div class="flex mt-1">
															<div class="mr-2">
																<span> <b>Check In: </b>{{ servicio?.checkIn | date : "dd/MM/yyyy HH:mm" }} </span>
															</div>

															<div class="mr-2">
																<span
																	><b>Check Out: </b
																	>{{
																		servicio?.checkOut
																			| date
																				: "dd/MM/yyyy
																	HH:mm"
																	}}</span
																>
															</div>
														</div>
														<div class="mt-1" *ngIf="servicio.referencia">
															<b>{{ "REF" | translate }}:</b> {{ servicio.referencia }}
														</div>

														<div class="productos">
															<div class="grid grid-nogutter" *ngFor="let prod of servicio?.productosAsociados; let j = index">
																<div class="text-right" [ngClass]="impuestosIncluidos ? 'col-6' : 'col-5'">
																	<span>{{ prod?.producto?.descripcion }}</span>
																	<span style="margin: 0 0.25em"> x </span>
																	<span>{{ prod?.cantidad }}</span>
																</div>
																<div class="col-3 importes" *ngIf="prod.esAdicional || prod.conPrecio">
																	<span>{{ prod?.importe | currencyCustom : item?.moneda }}</span>
																</div>
																<div
																	class="col-1 importes total"
																	*ngIf="(prod.esAdicional || prod.conPrecio) && !impuestosIncluidos"
																>
																	<span *ngIf="!prod?.esSubcategorizado"
																		>{{ prod?.tipoIva?.valorIva | number : "1.0" }} %</span
																	>
																	<small *ngIf="prod?.esSubcategorizado">Impuestos compuestos </small>
																</div>
																<div class="col-2 importes total" *ngIf="prod.esAdicional || prod.conPrecio">
																	<span>{{ prod?.total | currencyCustom : item?.moneda }}</span>
																</div>
																<div class="col-6 text-center" *ngIf="!prod.esAdicional && !prod.conPrecio">
																	<strong>{{ "INCLUIDO" | translate }}</strong>
																</div>
															</div>
														</div>
														<div class="observaciones">
															<span style="display: block" *ngIf="servicio?.observaciones"
																><strong>Observaciones:</strong> {{ servicio?.observaciones }}</span
															>
															<span style="display: block" *ngIf="servicio?.notaCliente"
																><strong>Notas cliente:</strong> {{ servicio?.notaCliente }}</span
															>
														</div>
													</div>
												</div>

												<div class="col-12 botonera" *ngIf="servicio?.id">
													<button
														class="icon-button"
														pButton
														icon="fa fa-qrcode"
														pTooltip="{{ 'VER_VOUCHER' | translate }}"
														*ngIf="servicio?.id"
														(click)="verVoucher($event, servicio)"
													></button>
													<button
														pButton
														*ngIf="!servicio?.esCancelada && !creando && esGestorReserva && !readonly"
														(click)="$event.stopPropagation(); mostrarDialogoMotivo(servicio)"
														icon="fa fa-times-circle"
														label="{{ 'Cancelar' | translate }}"
														class="cancelar"
													></button>
													<span *ngIf="servicio?.esCancelada">Cancelado: {{ servicio.motivoCancelacion }}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>

					<div class="col-12 lg:col-2 lg:col-offset-10" *ngIf="!esClienteExterno">
						<div class="p-inputgroup">
							<span class="p-float-label">
								<input
									pInputText
									placeholder="{{ 'AJUSTE' | translate }}"
									[(ngModel)]="item.ajusteTotal"
									(focusin)="onFocus($event)"
									tooltipPosition="bottom"
									pTooltip="{{ 'AJUSTE' | translate }}"
									type="number"
									#ajuste="ngModel"
									name="valorAjuste"
									[disabled]="readonly || !esEditable() || facturada"
								/>

								<label>{{ "AJUSTE" | translate }}</label>
							</span>
							<error-tag [model]="ajusteTotal"></error-tag>
							<span class="p-inputgroup-addon">
								<i class="fa fa-percent" *ngIf="item.tipoAjuste == 'porcentaje'"></i>
							</span>
						</div>
					</div>

					<div class="col-12 subtitulo">
						<div class="grid">
							<div class="col-12 lg:col-offset-8 lg:col-4 total">
								<div class="col-12">
									<div class="grid grid-nogutter">
										<div class="col-6">
											<span>{{ "Subtotal" | translate }}:</span>
										</div>
										<div class="col-6">
											<strong>{{ item.neto | currencyCustom : item?.moneda }}</strong>
										</div>
									</div>
								</div>
								<div class="col-12">
									<div class="grid grid-nogutter">
										<div class="col-6">
											<span>{{ "Ajuste" | translate }}:</span>
										</div>
										<div class="col-6">
											<strong>{{ item.ajuste | currencyCustom : item?.moneda }}</strong>
										</div>
									</div>
								</div>
								<div class="col-12" *ngIf="!impuestosIncluidos">
									<div class="grid grid-nogutter">
										<div class="col-6">
											<span>{{ "Impuestos" | translate }}:</span>
										</div>
										<div class="col-6">
											<strong>{{ item.impuestos | currencyCustom : item?.moneda }}</strong>
										</div>
									</div>
								</div>
								<div class="col-12">
									<div class="grid grid-nogutter big">
										<div class="col-6">
											<span>Total:</span>
										</div>
										<div class="col-6">
											<strong>{{ item.total | currencyCustom : item?.moneda }}</strong>
										</div>
									</div>
								</div>
								<div class="col-12" *ngIf="item.id">
									<div class="grid grid-nogutter big">
										<div class="col-6">
											<span>{{ "SALDO" | translate }}:</span>
										</div>
										<div class="col-6">
											<strong>{{ item.saldo | currencyCustom : item?.moneda }}</strong>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 lg:col-6">
						<span class="p-float-label">
							<textarea
								pInputText
								[readonly]="readonly"
								[(ngModel)]="item.condiciones"
								tooltipPosition="bottom"
								pTooltip="{{ 'CONDICIONES_TOOLTIP' | translate }}"
								type="text"
								#observaciones="ngModel"
								rows="6"
								name="condiciones"
							></textarea>
							<label>{{ "CONDICIONES" | translate }}</label>
						</span>
						<p-checkbox
							class="block mt-3 mb-2"
							tooltipPosition="bottom"
							[disabled]="readonly"
							name="verEnFactura"
							[binary]="true"
							[(ngModel)]="item.verEnFactura"
							[label]="'VER_EN_COMPROBANTE' | translate"
							pTooltip="{{ 'VER_EN_COMPROBANTE_TP' | translate }}"
						></p-checkbox>
					</div>
					<div class="col-12 lg:col-6">
						<p-checkbox
							class="block mt-3 mb-2"
							tooltipPosition="bottom"
							[disabled]="readonly"
							name="mostrarCotizacion"
							[binary]="true"
							[(ngModel)]="item.mostrarCotizacion"
							[label]="'VER_COTIZACION_EN_LIQUIDACION' | translate"
							pTooltip="{{ 'VER_COTIZACION_EN_LIQUIDACION_TP' | translate }}"
						></p-checkbox>
					</div>
					<div class="col-12 lg:col-6">
						<p-checkbox
							class="block mt-3 mb-2"
							tooltipPosition="bottom"
							[disabled]="readonly"
							name="mostrarCotizacion"
							[binary]="true"
							[(ngModel)]="item.verEstadoCuenta"
							[label]="'MOSTRAR_SALDO' | translate"
							pTooltip="{{ 'EL_SALDO_SE_MOSTRARÁ_EN_EL_ITINERARIO_DEL_PASAJERO' | translate }}"
						></p-checkbox>
					</div>
					<div class="col-12">
						<div class="grid">
							<div class="col-12 subtitulo">
								<span>
									{{ "RECORDATORIOS" | translate }}
								</span>
							</div>
							<div class="col-12">
								<abm-recordatorios [itemConRecordatorios]="item" [readonly]="readonly" [fechaBase]="item.fechaVencimiento"></abm-recordatorios>
							</div>
						</div>
					</div>
					<div class="col-12 botonera">
						<button pButton label="{{ 'CANCELAR' | translate }}" (click)="modelGestor.cancelar()" class="p-button-danger"></button>
						<button class="p-button-success" pButton label="{{ 'GUARDAR' | translate }}" (click)="modelGestor.guardar()" *ngIf="!readonly"></button>
						<button
							class="p-button-info"
							pButton
							label="{{ 'GUARDAR_Y_GENERAR_COMPROBANTE' | translate }}"
							(click)="guardarYGenerarComprobante()"
							*ngIf="!readonly && !item?.comprobante && !item?.esCotizacion"
						></button>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>

<p-dialog
	[(visible)]="mostrarNuevoProducto"
	[modal]="true"
	#dialDetail
	*ngIf="mostrarNuevoProducto"
	[styleClass]="'gestor-dialog large detalle'"
	(onShow)="onDialogShow($event, dialDetail)"
	(onHide)="cancelar()"
>
	<ng-template pTemplate="header">
		<div class="full-header p-dialog-title" (dragover)="scrollDown(dialDetail, 'up')">
			{{ !seleccionProducto ? ("ELIJA_UN_SERVICIO" | translate) : seleccionProducto.fullDescripcion }}
		</div>
	</ng-template>

	<form #nuevoServicioForm="ngForm" id="nuevoServicioForm">
		<div class="grid" *ngIf="itemEditado">
			<div class="col-12 lg:col-6">
				<div class="grid" [ngClass]="{ readonly: itemEditado.esCancelada }">
					<div class="col-12 subtitulo">
						<span>{{ "DETALLE_SERVICIO" | translate }} </span>
					</div>
					<div class="col-12">
						<div class="grid">
							<div class="col-12" *ngIf="multiplesRegiones">
								<div class="grid">
									<div class="col">
										<descriptivo-material-selector
											[(ngModel)]="filtroProducto.pais"
											[service]="paisService"
											[limpiable]="true"
											[label]="'PAIS' | translate"
											[name]="'pais'"
											[permiteNuevo]="false"
											[isLazy]="true"
											[readonly]="readonly || !servicioEditable"
											[ngModelOptions]="{ standalone: true }"
										>
										</descriptivo-material-selector>
									</div>
									<div class="col">
										<descriptivo-material-selector
											[(ngModel)]="filtroProducto.region"
											[service]="regionService"
											[limpiable]="true"
											[name]="'region'"
											[label]="'REGION' | translate"
											[permiteNuevo]="false"
											[isLazy]="true"
											[readonly]="readonly || !servicioEditable"
											[ngModelOptions]="{ standalone: true }"
										>
										</descriptivo-material-selector>
									</div>
								</div>
							</div>
							<div class="col-12 lg:col">
								<descriptivo-material-selector
									[allowDefault]="!item?.id"
									[(ngModel)]="seleccionProducto"
									[service]="productoService"
									[limpiable]="false"
									[label]="'SERVICIO' | translate"
									[getData]="getProductos"
									[permiteNuevo]="false"
									[field]="'fullDescripcion'"
									[filter]="filtroProducto"
									[isLazy]="true"
									[readonly]="readonly || !servicioEditable"
									[ngModelOptions]="{ standalone: true }"
									required
									[disabled]="readonly || !servicioEditable"
								>
								</descriptivo-material-selector>
							</div>
							<div class="col-fixed w-10rem" *ngIf="productoEditado?.esFamilia || !productoEditado?.esPaquete">
								<span class="p-float-label">
									<p-calendar
										name="fechaActividad"
										appendTo="body"
										tooltipPosition="bottom"
										pTooltip="{{
											porUbicar == itemEditado?.cantidadPax
												? 'Fecha de actividad'
												: 'Solo se puede cambiar la fecha si los paxs no están asignados'
										}}"
										dateFormat="dd/mm/yy"
										[disabledDates]="fechasBloqueadasDate"
										[locale]="calendarioLocale"
										[formControl]="fechaActividadSeleccionada"
										[required]="true"
										[disabled]="deshabilitarFecha(itemEditado)"
									>
										<ng-template pTemplate="disabledDate" let-date>
											<span class="bloqueada" tooltipPosition="bottom" pTooltip="{{ getBloqueo(date)?.motivo }}">{{ date.day }}</span>
										</ng-template>
										<ng-template pTemplate="date" let-date>
											<span
												[ngClass]="{
													'con-reserva': tieneReserva(date)
												}"
												>{{ date.day }}</span
											>
										</ng-template>
									</p-calendar>
									<label>{{ "FECHA" | translate }}</label>
								</span>
							</div>
							<div class="col-fixed w-7rem p-fluid" *ngIf="!productoEditado?.esPaquete">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'CANTIDADPAX_PH' | translate }}"
										[(ngModel)]="itemEditado.cantidadPax"
										(change)="onCantidadPaxChange($event)"
										tooltipPosition="bottom"
										pTooltip="{{ 'CANTIDADPAX_TOOLTIP' | translate }}"
										type="number"
										#cantidadPax="ngModel"
										name="cantidadPax"
										required
										[min]="1"
										[disabled]="readonly || !esEditable()"
									/>
									<label>{{ "PAXS" | translate }}</label>
								</span>
								<error-tag [model]="cantidadPax"></error-tag>
							</div>
							<div class="col-6 lg:col-3">
								<parametrico-selector
									[allowDefault]="!item?.id"
									[service]="idiomaService"
									[(ngModel)]="itemEditado.idiomaServicio"
									[limpiable]="true"
									[permiteNuevo]="true"
									name="idiomaServicio"
									[titulo]="'IDIOMASERVICIO' | translate"
									[required]="true"
									[disabled]="readonly"
									[readonly]="readonly"
								>
								</parametrico-selector>
							</div>
						</div>
					</div>
					<div class="col-12">
						<div class="grid">
							<div class="col-4 lg:col-2" *ngIf="productoEditado?.esServicio && !esClienteExterno">
								<span class="p-float-label">
									<p-calendar
										[(ngModel)]="itemEditado.pickUp"
										showTime="true"
										[hourFormat]="24"
										[stepMinute]="5"
										#horaActividad="ngModel"
										name="hora"
										[required]="!productoEditado?.esPaquete"
										[disabled]="readonly"
										timeOnly="true"
									></p-calendar>
									<label for="horapickup">Hora de pick up</label>
								</span>
							</div>
							<div class="col-12 lg:col">
								<div class="grid">
									<div class="col">
										<descriptivo-material-selector
											[allowDefault]="!item?.id"
											[service]="hotelService"
											[isLazy]="true"
											[label]="'HOTEL' | translate"
											[limpiable]="limpiable"
											[(ngModel)]="itemEditado.hotel"
											[permiteNuevo]="true"
											[readonly]="readonly"
											[gestor]="gestorHotel"
											[getData]="hotelService.getHabilitados"
											name="hotelItem"
										>
											<ng-template #gestorHotel let-itemEditado="itemEditado" let-handler="handler">
												<gestor-hotel
													[item]="itemEditado"
													(onGuardado)="handler.onGuardado($event)"
													(onCancelado)="handler.onCancelado($event)"
													[isModal]="true"
													[goBack]="false"
												>
												</gestor-hotel>
											</ng-template>
										</descriptivo-material-selector>
									</div>
									<div class="col-12 lg:col-7 p-fluid" *ngIf="itemEditado?.hotel?.codigoPostal == 'OTRA'">
										<span class="p-float-label">
											<input
												pInputText
												placeholder="{{ 'DIRECCION' | translate }}"
												[readonly]="readonly"
												[(ngModel)]="itemEditado.direccionAlternativa"
												tooltipPosition="bottom"
												pTooltip="{{ 'DIRECCION' | translate }}"
												type="text"
												#direccionAlternativaItem="ngModel"
												name="direccionAlternativaItem"
											/>
											<label for="direccionAlternativaItem">{{ "DIRECCION" | translate }}</label>
										</span>
									</div>
									<div class="col-12 lg:col-6 p-fluid" *ngIf="productoEditado?.esTransfer() || productoEditado?.esHospedaje()">
										<span class="p-float-label">
											<input
												pInputText
												placeholder="{{ 'REFERENCIA' | translate }}"
												[readonly]="readonly"
												[(ngModel)]="itemEditado.referencia"
												tooltipPosition="bottom"
												pTooltip="{{ 'REFERENCIA_TT' | translate }}"
												type="text"
												#referencia="ngModel"
												name="referencia"
											/>
											<label for="referencia">{{ "REFERENCIA" | translate }}</label>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12">
						<div class="grid">
							<div class="col-2">
								<span class="p-float-label" pTooltip="{{ 'BASE_CALCULO_TT' | translate }}">
									<input
										pInputText
										[(ngModel)]="itemEditado.baseCalculo"
										(change)="onCantidadPaxChange($event)"
										tooltipPosition="bottom"
										type="number"
										#baseCalculo="ngModel"
										name="baseCalculo"
										[min]="1"
										[disabled]="readonly || !esEditable()"
									/>
									<label for="">{{ "BASE_CALCULO" | translate }}</label>
								</span>
							</div>
							<div class="col p-inputgroup" *ngIf="!productoEditado?.esPaquete">
								<span-dinero-editable
									[(ngModel)]="itemEditado.precioPerCapita"
									[disabled]="readonly || !esEditable()"
									[editable]="esGestorPrecios && servicioEditable && itemEditado.precioEditado"
									[label]="'PRECIO' | translate"
									[moneda]="item.moneda"
									name="precioPerCapita"
									[styleClass]="'no-rounded-right'"
									[required]="!productoEditado?.esPaquete"
									(ngModelChange)="onPrecioChange($event)"
								>
								</span-dinero-editable>
								<button
									*ngIf="puedeEditarPrecio() && itemEditado.precioEditado"
									pButton
									pTooltip="{{ 'RECALCULAR' | translate }}"
									icon="pi pi-calculator"
									(click)="itemEditado.precioEditado = false; recalcularPrecio()"
									style="max-height: 2.75rem"
								></button>
								<button
									*ngIf="puedeEditarPrecio() && !itemEditado.precioEditado"
									pButton
									pTooltip="{{ 'PRECIO_MANUAL' | translate }}"
									icon="pi pi-pencil"
									(click)="itemEditado.precioEditado = true"
									style="max-height: 2.75rem"
								></button>
								<span *ngIf="!puedeEditarPrecio()"><i class="{{ itemEditado.precioEditado ? 'pi pi-pencil' : 'pi pi-calculator' }}"></i></span>
							</div>
							<div class="col-6 lg:col-fixed w-10rem p-inputgroup" *ngIf="!esClienteExterno">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'AJUSTE' | translate }}"
										[(ngModel)]="itemEditado.ajuste"
										(focusin)="onFocus($event)"
										tooltipPosition="bottom"
										pTooltip="{{ 'AJUSTE' | translate }}"
										type="number"
										#ajuste="ngModel"
										name="ajuste"
										[disabled]="readonly || !servicioEditable || facturada"
									/>

									<label for="">{{ "AJUSTE" | translate }}</label>
								</span>
								<error-tag [model]="ajuste"></error-tag>
								<span class="p-inputgroup-addon"><i class="fa fa-percent"></i></span>
							</div>
							<div class="col-6 lg:col-fixed w-10rem p-fluid" *ngIf="!esClienteExterno && !productoEditado?.esPaquete">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'COMISION' | translate }}"
										[(ngModel)]="itemEditado.comision"
										(focusin)="onFocus($event)"
										tooltipPosition="bottom"
										pTooltip="{{ 'COMISION' | translate }}"
										type="number"
										#comision="ngModel"
										name="comision"
										min="0"
										[disabled]="readonly || !servicioEditable || facturada"
									/>

									<label for="">{{ "COMISIÓN" | translate }}</label>
								</span>
								<error-tag [model]="comision"></error-tag>
							</div>
							<div class="col-6 lg:col-3" *ngIf="!impuestosIncluidos && !productoEditado?.esPaquete">
								<parametrico-selector
									*ngIf="!productoEditado?.esSubcategorizado"
									[allowDefault]="!item?.id"
									[service]="tipoIvaGrabadoService"
									#marca="ngModel"
									[readonly]="readonly || esClienteExterno"
									[(ngModel)]="itemEditado.tipoIVA"
									name="tipoIVA"
									[titulo]="'TIPO_DE_IVA' | translate"
									required
								>
								</parametrico-selector>
								<small class="block" style="padding-top: 0.75rem" *ngIf="productoEditado?.esSubcategorizado"
									>{{ productoEditado.resumenImpuestos }}
								</small>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 lg:col-6" *ngIf="!productoEditado?.esPaquete">
				<div class="grid">
					<div class="col-12 subtitulo">
						<span
							>{{ "SERVICIOS_Y_PRODUCTOS_ADICIONALES" | translate }}
							<button pButton (click)="agregarProducto()" icon="fa fa-plus" *ngIf="servicioEditable && !readonly && !esClienteExterno"></button>
						</span>
					</div>
					<div class="col-12" *ngFor="let producto of itemEditado?.productosAsociados; let i = index">
						<div class="grid">
							<div class="p-fluid col-3 lg:col-3">
								<descriptivo-material-selector
									[allowDefault]="!item?.id"
									[service]="productoService"
									[isLazy]="true"
									[getData]="getAdicionables"
									[placeHolder]="'NOMBRE_DEL_PRODUCTO' | translate"
									[floatLabel]="i == 0 ? 'auto' : 'never'"
									[aplicaIcono]="true"
									name=" {{ 'asociado_' + i }}"
									[limpiable]="false"
									[(ngModel)]="producto.producto"
									[label]="index == 0 ? ('NOMBRE_DEL_PRODUCTO' | translate) : null"
									[required]="true"
									[field]="'fullDescripcion'"
									[readonly]="!producto.esAdicional"
									(onSelect)="updateProductoAdicional($event, producto)"
									[disabled]="readonly || !esEditable()"
								>
								</descriptivo-material-selector>
							</div>

							<div class="p-fluid col-fixed w-10rem">
								<div class="p-inputgroup">
									<span class="p-float-label">
										<input
											pInputText
											placeholder="{{ 'CANTIDAD' | translate }}"
											[(ngModel)]="producto.cantidad"
											tooltipPosition="bottom"
											pTooltip="{{ 'CANTIDADBASE' | translate }}"
											type="number"
											name="'canidad_pa'{{ i }}"
											required
											[disabled]="readonly || !esEditable()"
											(change)="updateProductoAdicional(itemEditado.producto, producto)"
										/>
										<label>{{ "CANTIDAD" | translate }}</label>
									</span>
									<span class="p-inputgroup-addon">
										<i
											*ngIf="!producto.esPorServicio"
											class="fa fa-user"
											tooltipPosition="bottom"
											pTooltip="{{ 'INDIVIDUAL' | translate }}"
										></i>
										<i
											*ngIf="producto.esPorServicio"
											class="fa fa-users"
											tooltipPosition="bottom"
											pTooltip="{{ 'POR_SERVICIO' | translate }}"
										></i>
									</span>
								</div>
							</div>
							<div class="col p-fluid" *ngIf="!producto.esAdicional && !producto.conPrecio">
								<input pInputText readonly [value]="'INCLUDO' | translate" class="text-center" />
							</div>
							<div class="col p-fluid" *ngIf="producto.esAdicional || producto.conPrecio">
								<span-dinero-editable
									[(ngModel)]="producto.importe"
									[required]="true"
									[disabled]="readonly || !esEditable()"
									[label]="'PRECIO_UNITARIO' | translate"
									name="'percapita_pa'{{ i }}"
									[moneda]="item.moneda"
								>
								</span-dinero-editable>
							</div>
							<div class="col-fixed p-fluid w-10rem" *ngIf="(producto.esAdicional || producto.conPrecio) && !impuestosIncluidos">
								<parametrico-selector
									*ngIf="!producto?.esSubcategorizado"
									[allowDefault]="!item?.id"
									[service]="tipoIvaGrabadoService"
									#tipoIVAAdicional="ngModel"
									[readonly]="readonly"
									[(ngModel)]="producto.tipoIva"
									[limpiable]="true"
									[permiteNuevo]="true"
									name="'tipoIvaAdicional'{{ i }}"
									[titulo]="'TIPO_DE_IVA' | translate"
									required
								>
								</parametrico-selector>
								<span *ngIf="producto?.esSubcategorizado" pTooltip="{{ produto?.resumenImpuestos }}">Imp. Compuestos</span>
							</div>
							<div class="col p-fluid" *ngIf="producto.esAdicional || producto.conPrecio">
								<span-dinero-editable
									[ngModel]="producto.total"
									[required]="true"
									[disabled]="readonly"
									[moneda]="item.moneda"
									[label]="'TOTAL' | translate"
									name="'total'{{ i }}"
								>
								</span-dinero-editable>
							</div>
							<div class="col-fixed ml-auto">
								<button
									pButton
									icon="fa fa-trash "
									style="height: calc(100% - 2px)"
									*ngIf="servicioEditable && !esClienteExterno"
									(click)="itemEditado.quitarProducto(producto)"
								></button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="grid">
					<div class="col-12 lg:col-4">
						<selector-multiple-chips
							[(ngModel)]="itemEditado.restricciones"
							name="restricciones"
							tooltipPosition="bottom"
							pTooltip="{{ 'Restricciones de los pasajeros' | translate }}"
							[servicio]="restriccionService"
							[permiteNuevo]="true"
							[readonly]="readonly"
							[gestor]="gestorRestriccion"
							[titulo]="'Restricciones' | translate"
						>
						</selector-multiple-chips>
					</div>
					<div class="col p-fluid">
						<span class="p-float-label">
							<textarea
								pInputText
								placeholder="{{ 'OBSERVACIONES_PH' | translate }}"
								[readonly]="readonly"
								[(ngModel)]="itemEditado.observaciones"
								tooltipPosition="bottom"
								pTooltip="{{ 'OBSERVACIONES_RESERVA' | translate }}"
								type="text"
								#observaciones="ngModel"
								name="observaciones"
							></textarea>
							<label>{{ "OBSERVACIONES" | translate }}</label>
						</span>
					</div>
					<div class="col p-fluid">
						<span class="p-float-label">
							<textarea
								pInputText
								placeholder="{{ 'Notas para el cliente' | translate }}"
								[readonly]="readonly"
								[(ngModel)]="itemEditado.notaCliente"
								tooltipPosition="bottom"
								pTooltip="{{ 'Estas Notas serán visibles para el cliente' | translate }}"
								type="text"
								#notaCliente="ngModel"
								name="notaCliente"
							></textarea>
							<label>{{ "Notas Cliente" | translate }}</label>
						</span>
					</div>
				</div>
			</div>
			<div class="col-12" *ngIf="(productoEditado?.esFamilia && itemEditado?.fechaActividad) || productoEditado?.esPaquete">
				<ng-container [ngTemplateOutlet]="configuracionPaqueteTemplate" [ngTemplateOutletContext]="{itemEditado,productoEditado}"> </ng-container>
			</div>

			<div class="col-12" *ngIf="item?.esCotizacion && productoEditado?.esTercerizado() && !esClienteExterno">
				<p-card *ngIf="opcionesProveedor?.length == 0">
					<p class="pt-1 pl-1">
						{{ "NO_HAY_PROVEEDORES" | translate }}
					</p>
				</p-card>
				<div class="grid">
					<div class="col-12 lg:col-4" *ngFor="let opcion of opcionesProveedor">
						<p-card
							(click)="seleccionarProveedor($event, opcion)"
							[styleClass]="'clickable opcion-proveedor'"
							[ngClass]="{ seleccionado: itemEditado?.proveedorTentativo === opcion?.proveedor?.id }"
						>
							<p-header>
								<div class="flex align-items-center">
									<div class="col-6">
										<puesto-badge
											[ngModel]="opcion.proveedor"
											[verDetalle]="true"
											[disabled]="readonly"
											[ngModelOptions]="{ standalone: true }"
										>
										</puesto-badge>
									</div>
									<div class="col-6 text-right pr-2">
										<strong class="block w-100 text-lg">{{
											opcion?.precioActual?.precioVenta(itemEditado.cantidadPax) | currencyCustom : opcion?.precioActual?.moneda
										}}</strong>
									</div>
								</div>
							</p-header>
							<div class="grid" *ngIf="puedeVerCostos">
								<div class="col-12 text-center" *ngIf="!opcion?.precioActual?.markup">
									<strong>{{ "NO_HAY_MARKUP_CONFIGURADO" | translate }}</strong>
								</div>
								<div class="col-6 small">
									<span class="block w-100"
										><strong>{{ "COSTO" | translate }}:</strong>
										{{ opcion?.precioActual?.getCosto(itemEditado.cantidadPax) | currencyCustom : opcion?.precioActual?.moneda }}</span
									>
									<small class="block" *ngIf="opcion?.precioActual?.insumo?.descripcion != productoEditado?.descripcion">
										{{ opcion?.precioActual?.insumo?.descripcion }}
									</small>
								</div>
								<div class="col-6 small">
									<span class="block w-100" *ngIf="opcion?.precioActual?.markup"
										><strong>{{ "MARGEN" | translate }}:</strong> {{ opcion?.getMargen(itemEditado.cantidadPax) }} %</span
									>
									<small class="xsmall">{{ "TIPO_MARKUP" | translate }}: {{ opcion.tipoMarkup | translate }}</small>
								</div>
								<div class="col-12" *ngIf="opcion?.proveedor?.observaciones">
									{{ "OBSERVACIONES" | translate }}: {{ opcion?.proveedor?.observaciones }}
								</div>
							</div>
						</p-card>
					</div>
				</div>
			</div>
			<div
				class="col-12 subtitulo disponibilidad-section"
				[ngClass]="{ sticky: !readonly }"
				pDroppable="paxs"
				(drop)="onDropPaxs($event)"
				*ngIf="
					!item?.esCotizacion &&
					esGestorReserva &&
					(productoEditado?.esServicio() || productoEditado?.esTercerizado() || productoEditado?.esTransfer()) &&
					!esClienteExterno
				"
			>
				<div class="grid" *ngIf="!readonly">
					<div class="col-3 lg:col-2 autoasignar-wrapper">
						<span class="title">{{ "DISPONIBILIDAD" | translate }} </span>
						<button
							pButton
							[label]="'Autoasignar' | translate"
							(click)="autoasignar($event, itemEditado)"
							class="autoasignar"
							*ngIf="itemEditado?.fechaActividad && porUbicar == itemEditado?.cantidadPax && !readonly"
							[disabled]="getAutoasignarDisabled()"
						></button>
					</div>
					<div class="lg:col-4 col-6">
						<span class="p-float-label area-arrastre">
							<div class="item-reserva p-inputwrapper-filled pt-1">
								<span class="nombre" pDraggable="pax" (onDragStart)="setDragGrupoPax($event, porUbicar, false, false)" *ngIf="porUbicar > 0">{{
									item?.nombrePax ? item?.nombrePax : "Sin nombre"
								}}</span>
								<span
									class="pax p-2 w-5rem"
									pDraggable="pax"
									(onDragStart)="setDragGrupoPax($event, porUbicar, false, true)"
									*ngIf="porUbicar > 0"
								>
									<i class="fa fa-users"></i> x {{ porUbicar }}</span
								>
								<span class="hint" *ngIf="!porUbicar">{{
									"Arrastre aquí los paxs para quitar de un
									grupo" | translate
								}}</span>
							</div>
							<label>{{ "PAXS" | translate }}</label>
						</span>
					</div>
					<div
						class="col-3 lg:col-6 {{ area?.isHover ? 'hover' : '' }}"
						#area
						pDroppable="grupo"
						(drop)="quitarGrupoTemporal($event); area.isHover = false"
						(dragenter)="area.isHover = true"
						(dragleave)="area.isHover = false"
					>
						<span class="p-float-label area-arrastre">
							<div pTooltip="{{ 'Arrastrar un grupo aquí para cambiarlo de fecha' | translate }}" class="p-inputwrapper-filled cambio-fecha-drop">
								<div
									class="handle-grupo-temporal grid card grid-nogutter p-inputwrapper-filled"
									pDraggable="grupo"
									*ngIf="grupoTemporal"
									(onDragStart)="startDragCambioFecha($event)"
								>
									<div class="col-12">
										<strong
											>{{ grupoTemporal?.producto?.descripcion }} <i class="fa fa-clock-o"></i
											>{{ grupoTemporal?.fechaActividad | date : "HH:mm" }}</strong
										>
									</div>
									<div class="col-6">
										<span><i class="fa fa-car"></i>{{ grupoTemporal?.vehiculoAsignado?.patente }}</span>
									</div>
									<div class="col-6">
										<span> <i class="fa fa-user"></i>{{ grupoTemporal?.lugaresOcupados }}</span>
									</div>
								</div>
								<span class="hint" *ngIf="!grupoTemporal">{{
									"Arrastre aquí un grupo para cambiarlo de
									fecha" | translate
								}}</span>
							</div>
							<label for="">{{ "Área cambio de fecha" | translate }}</label>
						</span>
					</div>
				</div>
			</div>

			<div
				class="col-12"
				*ngIf="!item?.esCotizacion && esGestorReserva && (productoEditado?.esServicio() || productoEditado?.esTransfer()) && !esClienteExterno"
			>
				<span *ngIf="!itemCompleto(itemEditado)">{{ "COMPLETE_EL_DETALLE_12" | translate }}</span>
				<div class="grid-container" *ngIf="itemCompleto(itemEditado)">
					<div class="vertice" [ngStyle]="{ 'grid-column': 1, 'grid-row': 1 }"></div>
					<div class="hora" *ngFor="let hora of horas; let c = index" [ngStyle]="{ 'grid-column': c + 2, 'grid-row': 1 }">
						<span class="label-hora">{{ hora | number : "2.0-0" }}</span>
					</div>
					<ng-container
						[ngTemplateOutlet]="filaVehiculo"
						[ngTemplateOutletContext]="{
							vehiculo: vehiculo,
							row: r
						}"
						*ngFor="let vehiculo of vehiculosDisponibles; let r = index"
					>
					</ng-container>
					<div
						class="vehiculo d-flex align-items-center"
						[ngStyle]="{
							'grid-row': vehiculosDisponibles.length + 2,
							'grid-column': 1
						}"
					>
						<span class="alquilado mr-2">{{ "ALQUILADO" | translate }}</span>
						<button pButton icon="fa fa-plus" [disabled]="readonly" (click)="agregarAlquiler()"></button>
					</div>
					<ng-container
						[ngTemplateOutlet]="filaAlquiler"
						[ngTemplateOutletContext]="{
							row: vehiculosDisponibles.length + 2 + r
						}"
						*ngFor="let a of alquilados; let r = index"
					>
					</ng-container>
					<!--<div class="vehiculo" [ngStyle]="{ 'grid-row': vehiculosDisponibles.length + 3 + alquilados.length, 'grid-column': 1 }">
                        <span class="alquilado">Delegados</span>
                    </div>
                     <ng-container
                        [ngTemplateOutlet]="filaAlquiler"
                        [ngTemplateOutletContext]="{ row: vehiculosDisponibles.length + alquilados.length + 3 + r, tipo: 'delegado' }"
                        *ngFor="let d of delegados; let r = index"
                    >
                    </ng-container> -->
					<grupo
						[grupo]="grupo"
						[item]="itemEditado"
						*ngFor="let grupo of grupos | byVehiculo : vehiculosDisponibles"
						[style.grid-row]="getFila(grupo) + 2"
						[borrable]="grupoBorrable(grupo)"
						[mostrarPuestos]="true"
						[badgeSize]="'small'"
						[persistirAsignacion]="false"
						[permiteAsignacion]="grupoBorrable(grupo) || !grupo.id"
						[porUbicar]="porUbicar"
						(onGrupoChange)="updateGrupos($event)"
						[draggable]="grupoBorrable(grupo)"
						(onBorrar)="deleteGrupo($event)"
						[itemReservaActual]="itemEditado"
						[style.grid-column-start]="grupo.horaDesde + 2"
						[style.grid-column-end]="grupo.colHasta + 2"
						(onPuestoSeleccionado)="ocuparLugar($event)"
						(onAsignacion)="actualizarGrupo($event, grupo)"
						[readonly]="readonly"
						[isIcon]="isIcon"
						[isIconGroup]="isIconGroup"
						(onIsIconGroup)="changeIcon($event)"
						[cantidadPax]="itemEditado.cantidadPax"
						[permiteGestionCostos]="grupo['permiteGestionCostos']"
					>
					</grupo>
				</div>
			</div>
			<div class="col-12" *ngIf="delegados?.length">
				<div class="d-flex align-items-center mb-4 fila-tercerizado">
					<div class="vehiculo mr-7">
						<span class="alquilado">Delegados</span>
					</div>

					<div class="mr-3" *ngFor="let grupo of delegados; let i = index">
						<grupo
							[grupo]="grupo"
							[item]="itemEditado"
							[borrable]="grupoBorrable(grupo)"
							[mostrarPuestos]="true"
							[full]="true"
							[badgeSize]="'small'"
							[porUbicar]="porUbicar"
							[draggable]="grupoBorrable(grupo)"
							(onBorrar)="deleteGrupo($event)"
							[itemReservaActual]="itemEditado"
							(onPuestoSeleccionado)="ocuparLugar($event)"
							(onGrupoChange)="updateGrupos($event)"
							[readonly]="readonly"
							styleClass="w-30rem "
							[persistirAsignacion]="false"
							[permiteAsignacion]="grupoBorrable(grupo) && !grupo.id"
							name="{{ i }}"
							[fechaEditable]="true"
							(onFechaChange)="onFechaChange(grupo, $event)"
							(onAsignacion)="actualizarGrupo($event, grupo)"
							[isIcon]="isIcon"
							[isIconGroup]="isIconGroup"
							(onIsIconGroup)="changeIcon($event)"
							[cantidadPax]="itemEditado.cantidadPax"
							[permiteGestionCostos]="grupo['permiteGestionCostos']"
						>
						</grupo>
					</div>
				</div>
			</div>
			<div class="col-12" *ngIf="!item?.esCotizacion && esGestorReserva && productoEditado?.esTercerizado() && !esClienteExterno">
				<span *ngIf="!itemCompleto(itemEditado)">{{ "COMPLETE_EL_DETALLE_12" | translate }}</span>
				<div *ngIf="itemCompleto(itemEditado)">
					<!-- <div class="vertice" [ngStyle]="{ 'grid-column': 1, 'grid-row': 1 }"></div> -->
					<div class="d-flex align-items-center mb-4 fila-tercerizado" *ngFor="let vehiculo of vehiculosDisponibles; let r = index">
						<div class="vehiculo w-10rem">
							<selector-vehiculo [ngModel]="vehiculo" [verDetalle]="true" [small]="true" name="{{ 'v_' + r }}"></selector-vehiculo>
						</div>

						<button
							pButton
							icon="pi pi-plus"
							class="p-button-icon mr-3"
							(click)="agregarGrupoPaxTercerizado(r + 2, getHoraTercerizado(), vehiculo)"
						></button>

						<div class="mr-3" *ngFor="let grupo of grupos | byVehiculo : [vehiculo]; let i = index">
							<grupo
								[grupo]="grupo"
								[full]="true"
								[item]="itemEditado"
								[borrable]="grupoBorrable(grupo)"
								[mostrarPuestos]="false"
								[porUbicar]="porUbicar"
								[badgeSize]="'small'"
								(onGrupoChange)="updateGrupos($event)"
								[draggable]="grupoBorrable(grupo)"
								(onBorrar)="deleteGrupo($event)"
								[itemReservaActual]="itemEditado"
								(onPuestoSeleccionado)="ocuparLugar($event)"
								[persistirAsignacion]="false"
								[permiteAsignacion]="grupoBorrable(grupo) && !grupo.id"
								[readonly]="readonly"
								styleClass="w-25rem h-12rem"
								name="{{ grupo.key }}"
								[fechaEditable]="true"
								(onFechaChange)="onFechaChange(grupo, $event)"
								[isIcon]="isIcon"
								[isIconGroup]="isIconGroup"
								(onIsIconGroup)="changeIcon($event)"
								[cantidadPax]="itemEditado.cantidadPax"
								[permiteGestionCostos]="grupo['permiteGestionCostos']"
							>
							</grupo>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
	<p-footer>
		<div class="botonera" (dragover)="scrollDown(dialDetail, 'down')">
			<div class="grid justify-content-end grid-nogutter" *ngIf="itemEditado">
				<button
					pButton
					label="{{ 'QUITAR_RESERVA' | translate }}"
					(click)="eliminarItem()"
					class="p-button-danger"
					*ngIf="indexServicioEditado >= 0 && servicioEditable && !readonly"
				></button>
				<button pButton label="{{ 'CANCELAR' | translate }}" (click)="cancelar()" class="p-button-secondary"></button>
				<button pButton [label]="opearcionDialogo" *ngIf="!readonly" (click)="guardar()" [disabled]="!validarNuevoServicio(nuevoServicioForm)"></button>
			</div>
		</div>
	</p-footer>
</p-dialog>

<p-dialog
	[(visible)]="mostrarNuevoHospedaje"
	[modal]="true"
	#dialDetail
	[styleClass]="'gestor-dialog large detalle'"
	(onShow)="onDialogShow($event, dialDetail)"
	(onHide)="cancelarHospedaje()"
>
	<ng-template pTemplate="header">
		<div class="full-header p-dialog-title" (dragover)="scrollDown(dialDetail, 'up')">
			{{ !seleccionProducto ? ("ELIJA_UNA_HABITACION" | translate) : seleccionProducto.descripcion }}
		</div>
	</ng-template>

	<form #nuevoHospedajeForm="ngForm" id="nuevoHospedajeForm">
		<div class="grid" *ngIf="itemEditado">
			<div class="col-12 lg:col-6">
				<div class="grid" *ngIf="mostrarNuevoHospedaje" [ngClass]="{ readonly: itemEditado.esCancelada }">
					<div class="col-12 subtitulo">
						<span>{{ "DETALLE_HABITACION" | translate }} </span>
					</div>
					<div class="col-12">
						<div class="grid">
							<div class="col-12 lg:col-6">
								<descriptivo-material-selector
									[allowDefault]="!item?.id"
									[options]="hotelesProveedor"
									[isLazy]="false"
									[label]="'HOTEL' | translate"
									[limpiable]="false"
									[(ngModel)]="itemEditado.hotel"
									[readonly]="readonly"
									name="hotel"
									(onSelect)="onSelectHotel($event)"
								>
								</descriptivo-material-selector>
							</div>

							<div class="col-12 lg:col-6">
								<descriptivo-material-selector
									[allowDefault]="!item?.id"
									[(ngModel)]="seleccionProducto"
									[options]="habitaciones"
									[limpiable]="false"
									[label]="'HABITACION' | translate"
									[permiteNuevo]="false"
									[isLazy]="true"
									[readonly]="readonly || !servicioEditable"
									[ngModelOptions]="{ standalone: true }"
									required
									[disabled]="readonly || !servicioEditable || !itemEditado.hotel"
								>
								</descriptivo-material-selector>
							</div>

							<div class="col-12 lg:col-4">
								<descriptivo-material-selector
									[(ngModel)]="itemEditado.categoriaHospedaje"
									[service]="categoriaHospedajeService"
									[label]="'CATEGORIA' | translate"
									[isLazy]="true"
									[readonly]="readonly || !servicioEditable"
									[ngModelOptions]="{ standalone: true }"
									required
									#categoriaHospedaje="ngModel"
									[disabled]="readonly || !servicioEditable || !itemEditado.hotel"
								>
								</descriptivo-material-selector>
							</div>
							<div class="col-fixed w-12rem p-fluid field">
								<span class="p-float-label">
									<p-calendar
										name="checkIn"
										appendTo="body"
										tooltipPosition="bottom"
										pTooltip="{{ 'Check-in' }}"
										required
										dateFormat="dd/mm/yy"
										[locale]="calendarioLocale"
										[(ngModel)]="itemEditado.checkIn"
										[disabled]="readonly"
										[showTime]="true"
										name="checkIn"
										#checkIn="ngModel"
										required
									>
									</p-calendar>
									<label for="">{{ "Check In" | translate }}</label>
								</span>
								<error-tag [model]="fechaRegistro"></error-tag>
							</div>

							<div class="col-fixed w-12rem p-fluid field">
								<span class="p-float-label">
									<p-calendar
										name="checkOut"
										appendTo="body"
										tooltipPosition="bottom"
										pTooltip="{{ 'Check-out' }}"
										required
										dateFormat="dd/mm/yy"
										[showTime]="true"
										[locale]="calendarioLocale"
										name="checkOut"
										#checkOut="ngModel"
										[(ngModel)]="itemEditado.checkOut"
										[disabled]="readonly"
										required
									>
									</p-calendar>
									<label for="">{{ "Check Out" | translate }}</label>
								</span>
								<error-tag [model]="fechaRegistro"></error-tag>
							</div>

							<div class="col-6 lg:col-3">
								<parametrico-selector
									[allowDefault]="!item?.id"
									[service]="idiomaService"
									[(ngModel)]="itemEditado.idiomaServicio"
									[limpiable]="true"
									[permiteNuevo]="true"
									name="idiomaServicio"
									[titulo]="'IDIOMASERVICIO' | translate"
									[required]="true"
									[disabled]="readonly"
									[readonly]="readonly"
									#idiomaServicio="ngModel"
								>
								</parametrico-selector>
							</div>
						</div>
					</div>

					<div class="col-12">
						<div class="grid">
							<div class="col">
								<div class="p-inputgroup">
									<span-dinero-editable
										[(ngModel)]="itemEditado.precioPerCapita"
										[disabled]="readonly || !esEditable()"
										[editable]="esGestorPrecios && servicioEditable && itemEditado.precioEditado"
										[label]="'PRECIO_POR_ESTANCIA' | translate"
										[moneda]="item.moneda"
										name="precioPerCapita"
										[styleClass]="'no-rounded-right'"
										[required]="true"
										#precioPerCapita="ngModel"
										(ngModelChange)="onPrecioChange($event)"
									>
									</span-dinero-editable>
									<button
										*ngIf="esGestorPrecios && servicioEditable && itemEditado.precioEditado"
										pButton
										pTooltip="{{ 'RECALCULAR' | translate }}"
										icon="pi pi-calculator"
										(click)="recalcularPrecio(); itemEditado.precioEditado = false"
										style="max-height: 2.75rem"
									></button>
									<button
										*ngIf="esGestorPrecios && servicioEditable && !itemEditado.precioEditado"
										pButton
										pTooltip="{{ 'PRECIO_MANUAL' | translate }}"
										icon="pi pi-pencil"
										(click)="itemEditado.precioEditado = true"
										style="max-height: 2.75rem"
									></button>
								</div>
							</div>

							<div class="col-6 lg:col-fixed w-10rem" *ngIf="!esClienteExterno">
								<div class="p-inputgroup">
									<span class="p-float-label">
										<input
											pInputText
											placeholder="{{ 'AJUSTE' | translate }}"
											[(ngModel)]="itemEditado.ajuste"
											(focusin)="onFocus($event)"
											tooltipPosition="bottom"
											pTooltip="{{ 'AJUSTE' | translate }}"
											type="number"
											#ajuste="ngModel"
											name="ajuste"
											[disabled]="readonly || !servicioEditable || facturada"
										/>

										<label for="">{{ "AJUSTE" | translate }}</label>
									</span>
									<error-tag [model]="ajuste"></error-tag>
									<span class="p-inputgroup-addon"><i class="fa fa-percent"></i></span>
								</div>
							</div>
							<div class="col-6 lg:col-fixed w-10rem p-fluid" *ngIf="!esClienteExterno">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'COMISION' | translate }}"
										[(ngModel)]="itemEditado.comision"
										(focusin)="onFocus($event)"
										tooltipPosition="bottom"
										pTooltip="{{ 'COMISION' | translate }}"
										type="number"
										#comision="ngModel"
										name="comision"
										min="0"
										[disabled]="readonly || !servicioEditable || facturada"
									/>

									<label for="">{{ "COMISIÓN" | translate }}</label>
								</span>
								<error-tag [model]="comision"></error-tag>
							</div>
							<div class="col-6 lg:col-3" *ngIf="!impuestosIncluidos">
								<parametrico-selector
									*ngIf="!productoEditado?.esSubcategorizado"
									[allowDefault]="!item?.id"
									[service]="tipoIvaGrabadoService"
									#marca="ngModel"
									[readonly]="readonly || esClienteExterno"
									[(ngModel)]="itemEditado.tipoIVA"
									name="tipoIVA"
									[titulo]="'TIPO_DE_IVA' | translate"
									required
								>
								</parametrico-selector>
								<small class="block" style="padding-top: 0.75rem" *ngIf="productoEditado?.esSubcategorizado"
									>{{ productoEditado.resumenImpuestos }}
								</small>
							</div>
							<div class="col-12 lg:col-6 p-fluid" *ngIf="productoEditado?.esTransfer() || productoEditado?.esHospedaje()">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'REF' | translate }}"
										[readonly]="readonly"
										[(ngModel)]="itemEditado.referencia"
										tooltipPosition="bottom"
										pTooltip="{{ 'REF' | translate }}"
										type="text"
										#referencia="ngModel"
										name="referencia"
									/>
									<label for="referencia">{{ "REF" | translate }}</label>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 lg:col-6">
				<div class="grid">
					<div class="col-12 subtitulo">
						<span
							>{{ "SERVICIOS_Y_PRODUCTOS_ADICIONALES" | translate }}
							<button pButton (click)="agregarProducto()" icon="fa fa-plus" *ngIf="servicioEditable && !readonly && !esClienteExterno"></button>
						</span>
					</div>
					<div class="col-12" *ngFor="let producto of itemEditado?.productosAsociados; let i = index">
						<div class="grid">
							<div class="p-fluid col-3 lg:col-3">
								<descriptivo-material-selector
									[allowDefault]="!item?.id"
									[service]="productoService"
									[isLazy]="true"
									[getData]="getAdicionables"
									[placeHolder]="'NOMBRE_DEL_PRODUCTO' | translate"
									[floatLabel]="i == 0 ? 'auto' : 'never'"
									name=" {{ 'asociado_' + i }}"
									[limpiable]="false"
									[(ngModel)]="producto.producto"
									[label]="index == 0 ? ('NOMBRE_DEL_PRODUCTO' | translate) : null"
									[required]="true"
									[field]="'fullDescripcion'"
									[readonly]="!producto.esAdicional"
									(onSelect)="updateProductoAdicional($event, producto)"
									[disabled]="readonly || !esEditable()"
								>
								</descriptivo-material-selector>
							</div>

							<div class="p-fluid col-fixed w-10rem">
								<div class="p-inputgroup">
									<span class="p-float-label">
										<input
											pInputText
											placeholder="{{ 'CANTIDAD' | translate }}"
											[(ngModel)]="producto.cantidad"
											tooltipPosition="bottom"
											pTooltip="{{ 'CANTIDADBASE' | translate }}"
											type="number"
											name="'canidad_pa'{{ i }}"
											required
											[disabled]="readonly || !esEditable()"
											(change)="updateProductoAdicional(itemEditado.producto, producto)"
										/>
										<label>{{ "CANTIDAD" | translate }}</label>
									</span>
									<span class="p-inputgroup-addon">
										<i
											*ngIf="!producto.esPorServicio"
											class="fa fa-user"
											tooltipPosition="bottom"
											pTooltip="{{ 'INDIVIDUAL' | translate }}"
										></i>
										<i
											*ngIf="producto.esPorServicio"
											class="fa fa-users"
											tooltipPosition="bottom"
											pTooltip="{{ 'POR_SERVICIO' | translate }}"
										></i>
									</span>
								</div>
							</div>
							<div class="col p-fluid" *ngIf="!producto.esAdicional && !producto.conPrecio">
								<input pInputText readonly [value]="'INCLUDO' | translate" class="text-center" />
							</div>
							<div class="col p-fluid" *ngIf="producto.esAdicional || producto.conPrecio">
								<span-dinero-editable
									[(ngModel)]="producto.importe"
									[required]="true"
									[disabled]="readonly || !esEditable()"
									[label]="'PRECIO_UNITARIO' | translate"
									name="'percapita_pa'{{ i }}"
									[moneda]="item.moneda"
								>
								</span-dinero-editable>
							</div>
							<div class="col-fixed p-fluid w-10rem" *ngIf="(producto.esAdicional || producto.conPrecio) && !impuestosIncluidos">
								<parametrico-selector
									*ngIf="!producto?.esSubcategorizado"
									[allowDefault]="!item?.id"
									[service]="tipoIvaGrabadoService"
									#tipoIVAAdicional="ngModel"
									[readonly]="readonly"
									[(ngModel)]="producto.tipoIva"
									[limpiable]="true"
									[permiteNuevo]="true"
									name="'tipoIvaAdicional'{{ i }}"
									[titulo]="'TIPO_DE_IVA' | translate"
									required
								>
								</parametrico-selector>
								<span *ngIf="producto?.esSubcategorizado" pTooltip="{{ produto?.resumenImpuestos }}">Imp. Compuestos</span>
							</div>
							<div class="col p-fluid" *ngIf="producto.esAdicional || producto.conPrecio">
								<span-dinero-editable
									[ngModel]="producto.total"
									[required]="true"
									[disabled]="readonly"
									[moneda]="item.moneda"
									[label]="'TOTAL' | translate"
									name="'total'{{ i }}"
								>
								</span-dinero-editable>
							</div>
							<div class="col-fixed ml-auto">
								<button
									pButton
									icon="fa fa-trash "
									style="height: calc(100% - 2px)"
									*ngIf="servicioEditable && !esClienteExterno"
									(click)="itemEditado.quitarProducto(producto)"
								></button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="grid">
					<div class="col-12 lg:col-4">
						<selector-multiple-chips
							[(ngModel)]="itemEditado.restricciones"
							name="restricciones"
							tooltipPosition="bottom"
							pTooltip="{{ 'Restricciones de los pasajeros' | translate }}"
							[servicio]="restriccionService"
							[permiteNuevo]="true"
							[readonly]="readonly"
							[gestor]="gestorRestriccion"
							[titulo]="'Restricciones' | translate"
						>
						</selector-multiple-chips>
					</div>
					<div class="col p-fluid">
						<span class="p-float-label">
							<textarea
								pInputText
								placeholder="{{ 'OBSERVACIONES_PH' | translate }}"
								[readonly]="readonly"
								[(ngModel)]="itemEditado.observaciones"
								tooltipPosition="bottom"
								pTooltip="{{ 'OBSERVACIONES_RESERVA' | translate }}"
								type="text"
								#observaciones="ngModel"
								name="observaciones"
							></textarea>
							<label>{{ "OBSERVACIONES" | translate }}</label>
						</span>
					</div>
					<div class="col p-fluid">
						<span class="p-float-label">
							<textarea
								pInputText
								placeholder="{{ 'Notas para el cliente' | translate }}"
								[readonly]="readonly"
								[(ngModel)]="itemEditado.notaCliente"
								tooltipPosition="bottom"
								pTooltip="{{ 'Estas Notas serán visibles para el cliente' | translate }}"
								type="text"
								#notaCliente="ngModel"
								name="notaCliente"
							></textarea>
							<label>{{ "Notas Cliente" | translate }}</label>
						</span>
					</div>
				</div>
			</div>
		</div>
	</form>
	<p-footer>
		<div class="botonera" (dragover)="scrollDown(dialDetail, 'down')">
			<div class="grid justify-content-end grid-nogutter" *ngIf="itemEditado">
				<button
					pButton
					label="{{ 'QUITAR_RESERVA' | translate }}"
					(click)="eliminarItem()"
					class="p-button-danger"
					*ngIf="indexServicioEditado >= 0 && servicioEditable && !item.comprobante && !readonly"
				></button>
				<button pButton label="{{ 'CANCELAR' | translate }}" (click)="cancelarHospedaje()" class="p-button-secondary"></button>
				<button
					pButton
					[label]="opearcionDialogo"
					*ngIf="!readonly"
					(click)="guardar(true)"
					[disabled]="!validarNuevoServicio(nuevoHospedajeForm)"
				></button>
			</div>
		</div>
	</p-footer>
</p-dialog>

<ng-template #filaAlquiler let-r="row">
	<div
		class="hora-data"
		*ngFor="let hora of horas; let cv = index"
		[ngStyle]="{ 'grid-row': r, 'grid-column': cv + 2 }"
		pDroppable="grupo"
		(click)="agregarGrupoAlquilado(r, cv)"
		[ngClass]="{ odd: r % 2 > 0 }"
		(drop)="cambioHorario(hora, null, $event)"
		(dragenter)="onDragOver($event)"
		(dragleave)="onDragLeave($event)"
		(dragend)="onDragEnd($event, hora)"
	></div>
</ng-template>

<ng-template #filaVehiculo let-vehiculo="vehiculo" let-r="row">
	<div class="vehiculo" [ngStyle]="{ 'grid-row': r + 2, 'grid-column': 1 }" *ngIf="vehiculo?.id != -1">
		<selector-vehiculo [ngModel]="vehiculo" [verDetalle]="true" [small]="true"></selector-vehiculo>
	</div>
	<div
		class="hora-data"
		*ngFor="let hora of horas; let cv = index"
		[ngStyle]="{ 'grid-row': r + 2, 'grid-column': cv + 2 }"
		pDroppable="grupo"
		(click)="agregarGrupoPax(r + 2, cv, vehiculo)"
		[ngClass]="{ odd: (r + 2) % 2 > 0 }"
		(drop)="cambioHorario(hora, vehiculo, $event)"
		(dragenter)="onDragOver($event)"
		(dragleave)="onDragLeave($event)"
		(dragend)="onDragEnd($event, hora)"
	></div>
</ng-template>

<ng-template #gestorRestriccion let-handler="handler" let-itemEditado="itemEditado">
	<gestor-restriccion
		[item]="itemEditado"
		(onGuardado)="handler.onGuardado($event)"
		(onCancelado)="handler.onCancelado($event)"
		[isModal]="true"
		[goBack]="false"
	>
	</gestor-restriccion>
</ng-template>

<p-dialog [(visible)]="mostrarHistorialComprobante" [modal]="true" [style]="{ width: '50vw' }" header="{{ 'HISTORIAL_COMPROBANTE' | translate }}">
	<div class="grid container">
		<loading-panel [loadingService]="customLoading"> </loading-panel>

		<div class="col-12">
			<div class="grid content">
				<div class="col-12" *ngFor="let comprobante of historialComprobantes">
					<div class="card clickable" (click)="verComprobante($event, comprobante)" [ngClass]="{ esActivo: comprobante.estado.esTerminado }">
						<div class="grid">
							<div class="col-12 lg:col-1 p-fluid">
								<span class="p-float-label">
									<input [readonly]="true" type="text" pInputText [(ngModel)]="comprobante.id" />
									<label for="">{{ "ID" | translate }}</label>
								</span>
							</div>

							<div class="col-12 lg:col-3 p-fluid">
								<span class="p-float-label">
									<input
										[readonly]="true"
										type="text"
										pInputText
										[value]="
											comprobante.tipoComprobante.codigo + (comprobante.numeroDocumento === null ? '' : ' ' + comprobante.numeroDocumento)
										"
									/>
									<label>{{ "TIPO_NRO" | translate }}</label>
								</span>
							</div>

							<div class="col-12 lg:col-2 p-fluid">
								<span class="p-float-label">
									<p-calendar
										[(ngModel)]="comprobante.fechaVencimiento"
										dateFormat="dd/mm/yy"
										appendTo="body"
										[locale]="calendarioLocale"
										name="fechaVencimiento"
										#fechaVencimiento="ngModel"
										[showIcon]="true"
										[disabled]="true"
									></p-calendar>
									<label>{{ "FECHAVENCIMIENTO" | translate }}</label>
								</span>
								<error-tag [model]="fechaVencimiento"></error-tag>
							</div>

							<div class="col-12 lg:col-2 p-fluid">
								<span class="p-float-label">
									<input pInputText type="text" [ngModel]="comprobante.importe | currency : 'USD' : comprobante?.moneda?.simbolo" />
									<label>{{ "IMPORTE" | translate }}</label>
								</span>
							</div>

							<div class="col-12 lg:col-2 p-fluid">
								<span class="p-float-label">
									<input pInputText type="text" [ngModel]="comprobante.bonificacion | currency : 'USD' : comprobante?.moneda?.simbolo" />
									<label>{{ "BONIFICACIÓN" | translate }}</label>
								</span>
							</div>

							<div class="col-12 lg:col-2 p-fluid">
								<span class="p-float-label">
									<input pInputText type="text" [ngModel]="comprobante.saldo | currency : 'USD' : comprobante?.moneda?.simbolo" />
									<label>{{ "SALDO" | translate }}</label>
								</span>
							</div>

							<div class="col-3 p-fluid">
								<span class="p-float-label">
									<span class="input p-inputwrapper-filled pt-4 text-center">{{ comprobante?.estado?.descripcion }}</span>
									<label>{{ "ESTADO" | translate }}</label>
								</span>
							</div>
							<div class="col-9 p-fluid">
								<span class="p-float-label">
									<input [readonly]="true" type="text" pInputText [(ngModel)]="comprobante.descripcion" />
									<label>{{ "DESCRIPCION" | translate }}</label>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 p-4 text-center" *ngIf="!historialComprobantes?.length">
					<span>{{ "NO_SE_ENCONTRARON_COMPROBANTES" | translate }}</span>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<ng-container *ngIf="item?.id">
	<cancelacion-reserva
		(onCancelar)="itemEditado = null"
		[pregunta]="'¿Desea cancelar el servicio ' + itemEditado?.producto?.descripcion + '?'"
		(onRerservaCancelada)="onCancelacionReserva($event)"
	></cancelacion-reserva>
</ng-container>

<ng-template #gestorProveedor let-handler="handler">
	<gestor-proveedor
		(onGuardado)="handler.onGuardado($event); onProveedorGuardado($event)"
		(onCancelado)="handler.onCancelado($event)"
		[isModal]="true"
		[goBack]="false"
		[vendedor]="true"
	>
	</gestor-proveedor>
</ng-template>

<acciones-reserva
	*ngIf="item && !authService.esClienteExterno"
	(onDataUpdate)="updateData($event)"
	[reserva]="item"
	(onAccionesChange)="updateAcciones($event)"
	[editandoReserva]="true"
>
</acciones-reserva>

<p-dialog [(visible)]="mostrarVoucher" [modal]="true" [style]="{ width: '40vw' }" header="{{ 'VOUCHER' | translate }}" [focusOnShow]="false">
	<div class="grid" *ngIf="itemEditado">
		<div class="col-12" *ngFor="let gr of itemEditado?.reservas">
			<actividades-card [actividad]="gr" [token]="itemEditado.token" [readonly]="true"></actividades-card>
		</div>
		<div class="col-12" *ngIf="itemEditado?.esHospedaje()">
			<hospedaje-card [itemReserva]="itemEditado"></hospedaje-card>
		</div>
	</div>
</p-dialog>

<p-dialog [(visible)]="abrirSeleccionarVehiculo" [modal]="true" [style]="{ width: '40vw' }" header="{{ 'VEHICULO' | translate }}">
	<div class="grid" *ngIf="itemEditado?.producto?.id && itemEditado?.configuracionPrograma">
		<div class="col-12" *ngIf="vehiculosPrograma.get(itemEditado.producto.id)?.length">
			<descriptivo-material-selector
				[allowDefault]="!item?.id"
				[(ngModel)]="itemEditado.configuracionPrograma.vehiculoAsignado"
				[options]="vehiculosPrograma.get(itemEditado.producto.id)"
				[limpiable]="false"
				[label]="'VEHICULO' | translate"
				[permiteNuevo]="false"
				[isLazy]="false"
				[readonly]="readonly"
				[disabled]="readonly"
				(onSelect)="abrirSeleccionarVehiculo = false; onSelectVehiculo($event, itemEditado)"
				[ngModelOptions]="{ standalone: true }"
			>
			</descriptivo-material-selector>
		</div>
		<div class="col-12" *ngIf="!vehiculosPrograma.get(itemEditado.producto.id)?.length">
			<span>{{ "NO_SE_ENCONTRARON_VEHICULOS_DISPONIBLES_PARA_TODOS_LOS_DÍAS_DEL_PROGRAMA" | translate }}</span>
		</div>
	</div>
</p-dialog>

<p-dialog [(visible)]="mostrarDatosPaxs" [modal]="true" [style]="{ width: '70vw' }" [closeOnEscape]="false" [closable]="false">
	<ng-template pTemplate="header">
		<span class="paxsTitle">
			{{ "DATOS_PAXS" | translate }}
		</span>
		<button type="button" class="paxsCloseButton p-dialog-header-icon p-dialog-header-close p-link" (click)="cancelarDatosPaxs()">
			<i class="pi pi-times"></i>
		</button>
	</ng-template>
	<div class="paxs p-fluid" [formGroup]="formPaxs" *ngIf="mostrarDatosPaxs">
		<ng-container formArrayName="paxs">
			<div class="message-eliminar">
				<span *ngIf="isEliminar"><i class="fa fa-question-circle"></i>{{ "DATOS_PAXS_DELETE" | translate }}</span>
			</div>
			<div class="grid">
				<div class="col-12 lg:col-4" *ngFor="let pax of paxs.controls; let i = index">
					<div class="card" [ngClass]="{ 'deleted-pax': pax.removido }">
						<button
							*ngIf="isEliminar && !pax.removido && !readonly"
							class="p-button-danger"
							pButton
							icon="fa fa-trash"
							(click)="pax.removido = true"
							tooltipPosition="bottom"
							pTooltip="{{ 'ELIMINAR' | translate }}"
						></button>
						<button
							*ngIf="isEliminar && pax.removido && !readonly"
							class="p-button-warning"
							pButton
							icon="fa fa-rotate-left"
							(click)="pax.removido = false"
							tooltipPosition="bottom"
							pTooltip="{{ 'CANCELAR' | translate }}"
						></button>
						<div class="grid no-bottom">
							<div class="col-12 bold orange">{{ "PASAJERO" | translate }} {{ i + 1 }}</div>
							<div class="col-12" [formGroup]="pax">
								<input type="text" pInputText formControlName="nombre" placeholder="{{ 'NOMBRE' | translate }} " [readonly]="readonly" />
							</div>
							<div class="col-12" [formGroup]="pax">
								<input type="text" pInputText formControlName="apellido" placeholder="{{ 'APELLIDO' | translate }}" [readonly]="readonly" />
							</div>
							<div class="col-12" [formGroup]="pax">
								<span class="p-float-label">
									<p-calendar
										[showIcon]="true"
										formControlName="fechaNacimiento"
										[readonlyInput]="true"
										dataType="date"
										dateFormat="dd/mm/yy"
										[disabled]="isEliminar || readonly"
										[appendTo]="'body'"
										class="p-inputwrapper-filled"
									></p-calendar>
									<label>{{ "FECHA_NACIMIENTO" | translate }}</label>
								</span>
							</div>
							<div class="col-12" [formGroup]="pax">
								<p-dropdown
									appendTo="body"
									[options]="nacionalidades"
									formControlName="nacionalidad"
									placeholder="{{ 'NACIONALIDAD' | translate }}"
									optionLabel="descripcion"
									dataKey="codigo"
									[required]="true"
									styleClass="small-width"
									[disabled]="isEliminar || readonly"
								></p-dropdown>
							</div>

							<div class="col-12">
								<div class="grid">
									<div class="col-fixed" style="width: 10em" [formGroup]="pax">
										<p-dropdown
											appendTo="body"
											[options]="documentos"
											formControlName="tipoDocumento"
											placeholder="{{ 'SELECCIONAR_UN_TIPO_12' | translate }}"
											optionLabel="descripcion"
											dataKey="codigo"
											[required]="true"
											styleClass="small-width"
											[disabled]="isEliminar || readonly"
										></p-dropdown>
									</div>
									<div class="col" [formGroup]="pax">
										<input
											type="text"
											pInputText
											formControlName="numeroDocumento"
											placeholder="{{ 'NUMERO_DE_DOCUMENTO' | translate }}"
											[readonly]="readonly"
										/>
									</div>
								</div>
							</div>

							<div class="col-12 p-fluid" [formGroup]="pax">
								<span class="p-float-label">
									<textarea
										rows="5"
										pInputTextarea
										formControlName="observaciones"
										class="p-inputwrapper-filled w100"
										style="resize: none; width: 100%; padding: 0.5rem"
										[readonly]="readonly"
									></textarea>
									<label>{{ "OBSERVACIONES" | translate }}</label>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<p-footer>
		<div class="botonera">
			<div class="grid justify-content-end grid-nogutter">
				<button pButton label="{{ 'CANCELAR' | translate }}" (click)="cancelarDatosPaxs()" class="p-button-secondary"></button>
				<button pButton label="{{ 'GUARDAR' | translate }}" (click)="guardarDatosPaxs()" [disabled]="!isEliminar && !datosPaxsModificados()"></button>
			</div>
		</div>
	</p-footer>
</p-dialog>

<ng-template #configuracionPaqueteTemplate let-itemEditado="itemEditado" let-productoEditado="productoEditado">
	<p-table [value]="itemEditado.configuracionPaquete">
		<ng-template pTemplate="header">
			<tr>
				<th>{{ "SERVICIO" | translate }}</th>
				<th style="width: 15rem" *ngIf="!itemEditado?.producto?.esFamilia">{{ "FECHA" | translate }}</th>
				<th style="width: 5rem">{{ "CANTIDAD" | translate }}</th>
				<th style="width: 14rem">{{ "PRECIO" | translate }}</th>
				<th style="width: 10rem">{{ "AUTOASIGNAR" | translate }}</th>
				<th style="width: 3rem; text-align: center"></th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-i>
			<tr>
				<td>{{ i?.fullDescripcion }}</td>
				<td *ngIf="!itemEditado?.producto?.esFamilia">
					<p-calendar
						[(ngModel)]="i.fechaActividad"
						dateFormat="dd/mm/yy"
						appendTo="body"
						[showIcon]="true"
						required
						[disabled]="readonly || !esEditable()"
						(onSelect)="updatePrecioPaquete(i)"
					></p-calendar>
				</td>
				<td>
					<p-inputNumber
						[(ngModel)]="i.cantidad"
						[max]="itemEditado?.cantidadPax"
						(onInput)="updatePrecioPaquete(i)"
						(focusin)="onFocus($event)"
						[required]="true"
					></p-inputNumber>
				</td>
				<td>
					<div class="grid">
						<div class="col-12 p-inputgroup">
							<ng-container [ngTemplateOutlet]="editorPrecio" [ngTemplateOutletContext]="{ itemEditado: i }"></ng-container>
						</div>
					</div>
				</td>
				<td class="text-center">
					<p-checkbox [binary]="true" [(ngModel)]="i.autoasignar" [disabled]="readonly || !esEditable()"></p-checkbox>
				</td>
				<td clasS="text-center">
					<button pButton class="p-button-danger" icon="fa fa-trash" (click)="quitarItemConfiguracionPaquete(i)"></button>
				</td>
			</tr>
		</ng-template>
	</p-table>
</ng-template>
<ng-template #editorPrecio let-itemEditado="itemEditado">
	<span-dinero-editable
		[(ngModel)]="itemEditado.precioPerCapita"
		[disabled]="readonly || !esEditable()"
		[editable]="esGestorPrecios && servicioEditable && itemEditado.precioEditado"
		[label]="'PRECIO' | translate"
		[moneda]="item.moneda"
		name="precioPerCapita"
		[styleClass]="'no-rounded-right'"
		[required]="!productoEditado?.esPaquete"
		(ngModelChange)="onPrecioChange($event)"
	>
	</span-dinero-editable>
	<button
		*ngIf="puedeEditarPrecio() && itemEditado.precioEditado"
		pButton
		pTooltip="{{ 'RECALCULAR' | translate }}"
		icon="pi pi-calculator"
		(click)="itemEditado.precioEditado = false; recalcularPrecio()"
		style="max-height: 2.75rem"
	></button>
	<button
		*ngIf="puedeEditarPrecio() && !itemEditado.precioEditado"
		pButton
		pTooltip="{{ 'PRECIO_MANUAL' | translate }}"
		icon="pi pi-pencil"
		(click)="itemEditado.precioEditado = true"
		style="max-height: 2.75rem"
	></button>
	<span *ngIf="!puedeEditarPrecio()"><i class="{{ itemEditado.precioEditado ? 'pi pi-pencil' : 'pi pi-calculator' }}"></i></span>
</ng-template>
