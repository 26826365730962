export class IngresosEgresos{
    constructor(
        public periodo: string,
        public ingresos: number,
        public egresos: number
    ){
        
    }

    public static fromData(data){
        if(!data) return;
        return new IngresosEgresos(
            data.periodo,
            data.ingresos || 0,
            data.egresos || 0
        )
    }
}