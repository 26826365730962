import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { StringUtils } from '../utils/string-utils';

@Injectable({
  providedIn: "root"
})
export class TranslateService {
  data: any = {};
  dataCodebase: any = {};
  public idioma: string = 'es'
  private traducciones = {};
  constructor(private http: HttpClient, private primeConfig:PrimeNGConfig) {

  }
  get(key: string) {
    let valor = this.data[key] || this.data[StringUtils.uppercaseWithUnderscore(key)] || this.data[StringUtils.uppercaseWithUnderscoreStripped(key)];
    if (valor)
      return valor;
    return this.dataCodebase[key] || this.dataCodebase[StringUtils.uppercaseWithUnderscore(key)] || this.dataCodebase[StringUtils.uppercaseWithUnderscoreStripped(key)] || key ;
  }
  getOrKey(key: string) {
    let valor = this.data[key] || key;
    return valor;
  }
  upateData: BehaviorSubject<Boolean> = new BehaviorSubject(null);

  use(lang: string): Promise<{}> {
    //this.idioma = lang;
    if(lang.toLowerCase().includes("es-")) lang = "es";
    if(lang.toLowerCase().includes("en-")) lang = "en";
    this.cargarDataCodebase(lang);
    if(this.traducciones[lang]){
      if(this.traducciones[lang] instanceof Promise){
        return this.traducciones[lang];
      }else {
        this.data = Object.assign({}, this.traducciones[lang] || {});
        this.upateData.next(true);
        return Promise.resolve(this.traducciones[lang]);
      }
    }
    this.traducciones[lang] = new Promise<{}>((resolve, reject) => {
      const langPath = `assets/implementacion/i18n/${lang? lang.toLowerCase() : 'es'}.json`;
      lastValueFrom(this.http.get<{}>(langPath)).then(
        translation => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
          this.upateData.next(true);
          this.idioma = lang;
          this.traducciones[lang] = this.data;
        }).catch(
        error => {
          this.data = {};
          if (lang == "es") {
            resolve(this.data);
            console.log("NO EXISTE ARCHIVO DEFAULT DE TEXTOS");
          } else {
            this.use("es");
            console.log("NO EXISTE " + lang + " DEFAULT DE TEXTOS");
          }
        }
      );
    });
    return this.traducciones[lang];
  }

  cargarDataCodebase(lang: string): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${lang? lang.toLowerCase() : 'es'}.json`;
      lastValueFrom(this.http.get<{}>(langPath)).then(
        translation => {
          this.dataCodebase = Object.assign({}, translation || {});
          resolve(this.dataCodebase);
          this.upateData.next(true);
          this.primeConfig.setTranslation(this.dataCodebase);
        }).catch(
        error => {
          this.dataCodebase = {};
          if (lang == "es") {
            resolve(this.dataCodebase);
            this.primeConfig.setTranslation(this.dataCodebase);
            console.log("NO EXISTE ARCHIVO DEFAULT DE TEXTOS");
          } else {
            this.cargarDataCodebase("es");
            console.log("NO EXISTE " + lang + " DEFAULT DE TEXTOS");
          }
        }
      );
    });
  }
}



