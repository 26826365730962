import { Parametrico } from "./../common/model/Parametrico";
import { ProfilePic } from "./../common/model/ProfilePic";
import { ImputacionPorNacion } from "./ImputacionPorNacion";

export class Nacionalidad extends Parametrico {
	static ARGENTINA: Nacionalidad = new Nacionalidad(null, "AR", "Argentina", true, true);
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado: boolean = true,
		esSistema: boolean = false,
		peso: number = 99,
		public bandera: ProfilePic = new ProfilePic(null, "assets/images/default_image.png"),
		public imputaciones: ImputacionPorNacion[] = []
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso);
	}
	public tipoParametro(): string {
		return "nacionalidad";
	}
	public static fromData(data: any): Nacionalidad {
		if (!data) return null;
		let o: Nacionalidad = new Nacionalidad(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			data.esSistema,
			data.peso,

			ProfilePic.fromData(data.bandera),
			data.imputaciones ? data.imputaciones.map((n) => ImputacionPorNacion.fromData(n)) : []
		);

		o.version = data.version;
		return o;
	}

	public getByTipoJuridico(tipo: string) {
		let v = this.imputaciones.filter((i) => i.codigoTipoJuridico == tipo)[0];
		if (!v) return null;
		return v;
	}
	get cuitFisica(): string {
		let v = this.getByTipoJuridico("PF");
		return v ? v.cuitImputacaion : null;
	}

	get cuitJuridica(): string {
		let v = this.getByTipoJuridico("PJ");
		return v ? v.cuitImputacaion : null;
	}
	get cuitOtros(): string {
		let v = this.getByTipoJuridico("O");
		return v ? v.cuitImputacaion : null;
	}

	set cuitFisica(val: string) {
		let v = this.getByTipoJuridico("PF");
		if (!v) {
			v = new ImputacionPorNacion(null, "PF");
			this.imputaciones.push(v);
		}
		v.cuitImputacaion = val;
	}

	set cuitJuridica(val: string) {
		let v = this.getByTipoJuridico("PJ");
		if (!v) {
			v = new ImputacionPorNacion(null, "PJ");
			this.imputaciones.push(v);
		}
		v.cuitImputacaion = val;
	}

	set cuitOtros(val: string) {
		let v = this.getByTipoJuridico("O");
		if (!v) {
			v = new ImputacionPorNacion(null, "O");
			this.imputaciones.push(v);
		}
		v.cuitImputacaion = val;
	}
}
