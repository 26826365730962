<div class="gestor-tipo-cuotas-tarjeta content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'TipoCuotasTarjeta' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		[file]="file"
		[conArchivo]="true"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="TipoCuotasTarjetaForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #TipoCuotasTarjetaForm="ngForm" id="TipoCuotasTarjetaform">
				<div class="grid" *ngIf="item">
					<div class="lg:col-2 pic-wrapper col-4">
						<div class="pic">
							<profile-badge
								[seleccionable]="!readonly"
								[url]="item?.icono?.picPathVersion"
								class=""
								name="icono"
								(onFileSelected)="onPicSelected($event)"
							>
							</profile-badge>
							<i class="fa fa-pencil edit-icon" *ngIf="!readonly"></i>
						</div>
					</div>
					<div class="col-12 lg:col-2">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'CODIGO_PH' | translate }}"
								[(ngModel)]="item.codigo"
								tooltipPosition="bottom"
								pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
								type="text"
								#codigo="ngModel"
								name="codigo"
								[readonly]="readonly"
							/>
							<label>{{ "CODIGO" | translate }}</label>
						</span>
						<error-tag [model]="codigo"></error-tag>
					</div>

					<div class="col-12 lg:col-6">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="$TYPE"
								#descripcion="ngModel"
								name="descripcion"
								[readonly]="readonly"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>
					<div class="col-12 lg:col-1">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'PESO_PH' | translate }}"
								[(ngModel)]="item.peso"
								tooltipPosition="bottom"
								pTooltip="{{ 'PESO_TOOLTIP' | translate }}"
								type="number"
								#peso="ngModel"
								name="peso"
								[readonly]="readonly"
							/>
							<label>{{ "PESO" | translate }}</label>
						</span>
						<error-tag [model]="peso"></error-tag>
					</div>
					<div class="col-12 lg:col-4 checkbox-container">
						<p-toggleButton
							onLabel="{{ 'HABILITADO' | translate }}"
							offLabel="{{ 'HABILITADO' | translate }}"
							iconPos="left"
							name="habilitado"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[(ngModel)]="item.habilitado"
							[disabled]="readonly"
						></p-toggleButton>
					</div>

					<div class="col-12 lg:col-4 checkbox-container">
						<p-toggleButton
							onLabel="{{ 'ESSISTEMA' | translate }}"
							offLabel="{{ 'ESSISTEMA' | translate }}"
							iconPos="left"
							name="esSistema"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[(ngModel)]="item.esSistema"
							[disabled]="readonly"
						></p-toggleButton>
					</div>

					<div class="col-12 lg:col-4 checkbox-container">
						<p-toggleButton
							onLabel="{{ 'ESDEFAULT' | translate }}"
							offLabel="{{ 'ESDEFAULT' | translate }}"
							iconPos="left"
							name="esDefault"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[(ngModel)]="item.esDefault"
							[disabled]="readonly"
						></p-toggleButton>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
