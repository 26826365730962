import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationService } from "primeng/api";
import { Subscription } from "rxjs";
import { SessionComponent } from "src/app/common/components/session-component.component";
import { MessagesService } from "src/app/common/services/messages-data-service.service";
import { ModelListHandler } from "./../../../common/interfaces/ModelListHandler";
import { AccionesTabla } from "./../../../common/model/AccionesTabla";
import { Filtro } from "./../../../common/model/Filtro";
import { Parametrico } from "./../../../common/model/Parametrico";
import { GenericParemtricoService } from "./../../../common/services/generic-parametrico.service";
import { NovedadService } from "./../../../services/novedades.service";

@Component({
	selector: "generic-parametrico",
	templateUrl: "generic-parametrico.component.html",
	styleUrls: ["generic-parametrico.component.less"],
})
export class GenericParametricoComponent extends SessionComponent implements OnInit, OnDestroy {
	private routeSub: Subscription;
	public paramId: string;
	public editandoParametro: boolean = false;
	public itemEditado: Parametrico = new Parametrico();
	public listado: Parametrico[] = [];
	public filtro: Filtro = new Filtro("param_filter", {}, 0, 3000, "peso", 1);
	public handler: ModelListHandler;
	public genericParamService: GenericParemtricoService;
	constructor(
		private novedadService: NovedadService,
		private confService: ConfirmationService,
		private route: ActivatedRoute,
		messagesService: MessagesService
	) {
		super(messagesService);
	}
	public columns = [
		{ field: "codigo", header: "Código", role: "text-tabla", sortable: true, width: "10em" },
		{ field: "peso", header: "Peso", role: "text-tabla", sortable: true, class: "text-center", width: "10em" },
		{ field: "descripcion", header: "Descripción", role: "text-tabla", sortable: true },
		{ field: "habilitado", header: "Habilitado", class: "is-boolean", role: "boolean-tabla", width: "10em" },
	];

	public acciones: AccionesTabla[] = [
		new AccionesTabla(
			"Editar Parametro",
			"fa fa-pencil",
			"accion-tabla",
			(item) => {
				this.editar(item.id);
			},
			(item) => {
				return this.esAdministrador && !item.esSistema;
			}
		),
		new AccionesTabla(
			"Borrar Parametro",
			"fa fa-trash",
			"accion-tabla",
			(item) => {
				this.eliminar(item);
			},
			(item: Parametrico) => {
				return this.esAdministrador && item.habilitado && !item.esSistema;
			}
		),
		new AccionesTabla(
			"Habilitar Parametro",
			"fa fa-check",
			"accion-tabla",
			(item) => {
				this.habilitar(item);
			},
			(item: Parametrico) => {
				return this.esAdministrador && !item.habilitado && !item.esSistema;
			}
		),
	];

	ngOnInit() {
		let $this = this;
		this.handler = {
			nuevo: () => {
				$this.nuevo();
			},
			editar: (id) => {
				$this.editar(id);
			},
			eliminar: null,
		};

		this.routeSub = this.route.queryParams.subscribe((params) => {
			let tipo: string = <string>params["tipo"];
			if (tipo) {
				this.genericParamService = new GenericParemtricoService(this.novedadService, this.authService, this.messagesService, tipo);

				this.paramId = tipo;
				this.filtro.name = this.paramId + "_filter";
				this.refresh();
				this.genericParamService.data.subscribe((d) => {
					this.listado = [...d];
				});
			} else {
				this.paramId = null;
				this.genericParamService = null;
			}
		});
	}

	public isValid(item: Parametrico) {
		return item.descripcion != undefined;
	}
	ngOnDestroy() {
		{
			if (this.routeSub) this.routeSub.unsubscribe();
		}
	}
	public refresh(item?: Parametrico) {
		this.editandoParametro = false;
		this.itemEditado = null;
	}
	public eliminar(item: Parametrico) {
		this.confService.confirm({
			key: "genConf",
			header: "Eliminar",
			message: "Va a eliminar el parametro " + item.descripcion + ". Desea continuar?",
			accept: () => {
				let $this = this;
				$this.genericParamService.eliminar(item.id).then((res) => {
					$this.refresh(item);
					$this.success("El parametro fue eliminado");
				});
			},
		});
	}

	public habilitar(item: Parametrico) {
		this.confService.confirm({
			key: "genConf",
			header: "Habilitar",
			message: "Va a habilitar el parametro " + item.descripcion + ". Desea continuar?",
			accept: () => {
				let $this = this;
				$this.genericParamService.habilitar(item.id).then((res) => {
					$this.refresh(item);
					$this.success("El parametro fue eliminado");
				});
			},
		});
	}
	public onDialogShow(event, dialog) {
		if (this.isMobile()) {
			dialog.maximized = true;
		}
	}
	public onCancelar() {
		this.editandoParametro = false;
		this.itemEditado = null;
	}
	private nuevo() {
		this.itemEditado = new Parametrico();
		this.editandoParametro = true;
	}
	private editar(id: number) {
		this.genericParamService.getById(id).then((r) => {
			this.itemEditado = r;
			this.editandoParametro = true;
		});
	}
}
