import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { Subscription } from "rxjs"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { PuntoDeVenta } from "src/app/model/PuntoDeVenta"
import { PuntoDeVentaService } from "src/app/services/punto-de-venta.service"
import { SessionComponent } from "./../../../common/components/session-component.component"

import { Descriptivo } from "src/app/common/model/Descriptivo"
import { ProfilePic } from "src/app/common/model/ProfilePic"
import { CentroCostoService } from "src/app/services/centro-costo.service"
import { TipoIngresoBrutoService } from "src/app/services/tipo-ingreso-bruto.service"
import { TipoIVAService } from "src/app/services/tipo-iva.service"
@Component({
	selector: "gestor-punto-de-venta",
	templateUrl: "gestor-punto-de-venta.component.html",
	styleUrls: ["gestor-punto-de-venta.component.less"]
})
export class GestorPuntoDeVentaComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	@Input()
	public item: PuntoDeVenta
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false

	@Input()
	public readonly: boolean = false

	@Output()
	public onGuardado: EventEmitter<PuntoDeVenta> = new EventEmitter<PuntoDeVenta>()

	@Output()
	public onCancelado = new EventEmitter()

	public fileDataOptions: Descriptivo[] = []
	public centroCostoOptions: Descriptivo[] = []

	public uploadUrl: string = ""

	public file: File

	public onPicSelected(file: File) {
		const reader = new FileReader()
		let $this = this
		if (file.size > 1000000) {
			return this.error(this.translateService.get("EL_ARCHIVO_NO_PUEDE_16"))
		}
		this.file = file
		reader.onload = (e: any) => {
			$this.item.profilePic = new ProfilePic(null, e.target.result)
		}
		reader.readAsDataURL(file)
	}

	constructor(
		messagesService: MessagesService,
		public service: PuntoDeVentaService,
		private route: ActivatedRoute,
		private router: Router,
		private confService: ConfirmationService,
		private centroCostoService: CentroCostoService,
		public tipoIngresoBrutoService: TipoIngresoBrutoService,
		public tipoIVAService: TipoIVAService
	) {
		super(messagesService)
		this.uploadUrl = this.service.getApiURL() + "fileUpload/adjuntos"
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}

	public async isValid() {
		if (this.item.maximoFacturacion < 0) {
			return this.error("El valor de maximo facturacion no puede ser negativo")
		}
		return true
	}

	public onEdit(event) {
		if (this.item?.id) this.router.navigate(["'punto-de-venta/edit"], { queryParams: { id: this.item.id } })
	}

	ngOnInit() {
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
				}
			})
		)
		if (this.route.snapshot.url.some((u) => u.path == "punto-de-venta")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				if (!this.service) return
				if (id) {
					this.service.getById(id, null, this.translateService.idioma ? this.translateService.idioma : "ES").then((r) => {
						this.item = r
					})
				} else {
					if (!this.item && this.service) {
						this.item = this.service.newEnt()
					}
					this.item["codigoIdioma"] = this.translateService.idioma ? this.translateService.idioma : "ES"
				}
			})
		}
		this.centroCostoService.getDescriptivos().then((r) => {
			this.centroCostoOptions = r
		})
	}

	public get esEditable() {
		return this.esAdministrador
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}

	onUploadError(error) {
		this.error(error)
	}

	uploadEnd(data, destino) {
		this.item.certificado = data.originalEvent.body[0]
	}
}
