import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { Pais } from "src/app/model/Pais"
import { LoadingService } from "./../common/services/loading-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class PaisService extends ParametricoService<Pais> {
	constructor(public novedadesService: NovedadService, public authService: AuthService) {
		super(novedadesService, authService)
	}
	public baseName(): string {
		return "pais"
	}
	public parseToEnt(data: any): Pais {
		return Pais.fromData(data)
	}
	public newEnt(): Pais {
		return new Pais()
	}
	public actualizarDescripcion(data: Pais, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		var p = this.http.put(this.getBaseURL("actualizar-descripcion"), data).toPromise()
		p.then((r) => {
			this.loadData(customLoading)
			if (customLoading) {
				customLoading.susLoadingCount()
			} else this.loadingService.susLoadingCount()
		})
		return p
	}
}
