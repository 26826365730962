import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { Filtro } from '../common/model/Filtro';
export class FiltroPuntoDeVenta extends Filtro {

    constructor(name: string, page?: number, size?: number, sortField: string = 'descripcion', order: number = 1, guardable: boolean = true, localSearch: boolean = false ) {
        super(name, {
            centro: "Centro de costo",
        }, page, size, sortField, order, guardable,localSearch);
    }
    private _centro: Descriptivo;

    public get centro(): Descriptivo {
        return this._centro;
    }
    public set centro(v: Descriptivo) {
        if (v != this._centro) {
            this._centro = v;
            this.update(); 
        }
    }


    private _ordenadoPor: string = "cantidad";
    public get ordenadoPor(): string {

        return this._ordenadoPor;

    }

    public set ordenadoPor(v: string) {
        this._ordenadoPor = v;
        this.sortField = v;
        this.update();

    }



    private _cantidad: number;
    public get cantidad(): number {
        return this.size;
    }
    public set cantidad(v: number) {
        this.size = v;
        this.update();
    }


    public clean() {
        super.clean(false);
        let cleanVal = {
            ordenadoPro: "cantidad",
            centros: [],

        }
        this.patchValue(cleanVal);
    }

    public patchValue(v: any) {
        super.patchValue(v, false);
        this._searchStr = v.searchStr;
        this._ordenadoPor = v.ordenadoPro ? v.ordenadoPor : "cantidad";
        this._centro = v.centro ? v.centro : null;
        this.update();
    }

    public apply(data: any[]): any[] {
        const searchLike = this.searchStr ? this.searchStr.toUpperCase().split(" ") : null;
        return data.filter(value => (!searchLike || !value['descripcion']
            || searchLike.every(v => value['descripcion'].toUpperCase().includes(v))));

    }

}