import { Parametrico } from "src/app/common/model/Parametrico"

export class TipoProducto extends Parametrico {
	public static readonly TERCERIZADO = "TER"
	public static readonly PROGRAMA: string = "PGM"
	public static readonly PRODUCTO: string = "PRD"
	public static readonly SERVICIO: string = "SER"
	public static readonly INSUMO: string = "INS"
	public static readonly HOSPEDAJE: string = "HOS"
	public static readonly TRANSFER: string = "TRF"
	public static readonly PAQUETE: string = "PQT"
	public static readonly FAMILIA: string = "FML"
	public static readonly TERCERIZADO_SHORT: string = "T"
	public static readonly TRANSFER_SHORT: string = "F"
	public static readonly PROGRAMA_SHORT: string = "P"
	public static readonly PRODUCTO_SHORT: string = "S"
	public static readonly SERVICIO_SHORT: string = "S"
	public static readonly INSUMO_SHORT: string = "I"
	public static readonly HOSPEDAJE_SHORT: string = "H"
	public static readonly PAQUETE_SHORT: string = "Q"

	constructor(id?: number, codigo?: string, descripcion?: string, habilitado: boolean = true, esSistema: boolean = false, peso: number = 99) {
		super(id, codigo, descripcion, habilitado, esSistema, peso)
	}
	static esActividad(codigo: any) {
		return codigo === TipoProducto.SERVICIO || codigo === TipoProducto.TRANSFER
	}
	public esPrograma() {
		return this.codigo === TipoProducto.PROGRAMA
	}
	public esPaquete() {
		return this.codigo === TipoProducto.PAQUETE
	}
	public esTransfer() {
		return this.codigo === TipoProducto.TRANSFER
	}
	public esFamilia() {
		return this.codigo === TipoProducto.FAMILIA
	}

	public esHospedaje() {
		return this.codigo === TipoProducto.HOSPEDAJE
	}
	public tipoParametro(): string {
		return "TipoProductoLOW"
	}
	public static fromData(data: any): TipoProducto {
		if (!data) return null
		const o: TipoProducto = new TipoProducto(data.id, data.codigo, data.descripcion, data.habilitado, data.esSistema, data.peso)

		o.version = data.version
		return o
	}

	public get codigoCorto() {
		if (this.codigo === TipoProducto.PRODUCTO) {
			return TipoProducto.PRODUCTO_SHORT
		} else if (this.codigo === TipoProducto.PROGRAMA) {
			return TipoProducto.PROGRAMA_SHORT
		} else if (this.codigo === TipoProducto.SERVICIO) {
			return TipoProducto.SERVICIO_SHORT
		} else if (this.codigo === TipoProducto.INSUMO) {
			return TipoProducto.INSUMO_SHORT
		} else if (this.codigo === TipoProducto.HOSPEDAJE) {
			return TipoProducto.HOSPEDAJE_SHORT
		} else if (this.codigo === TipoProducto.PAQUETE) {
			return TipoProducto.PAQUETE_SHORT
		} else if (this.codigo === TipoProducto.TERCERIZADO) {
			return TipoProducto.TERCERIZADO_SHORT
		} else if (this.codigo === TipoProducto.TRANSFER) {
			return TipoProducto.TRANSFER_SHORT
		}
		return this.codigo
	}
	public static esAdicionable(codigo: string) {
		return [
			TipoProducto.PRODUCTO,
			TipoProducto.SERVICIO,
			TipoProducto.TERCERIZADO,
			TipoProducto.PAQUETE,
			TipoProducto.PROGRAMA,
			TipoProducto.FAMILIA,
			TipoProducto.TRANSFER
		].includes(codigo)
	}
}
