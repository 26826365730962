import { HttpRequest, HttpResponse } from "@angular/common/http"
import { lastValueFrom } from "rxjs"
import { Auditable } from "src/app/model/Auditable"
import { Descriptivo } from "../../common/model/Descriptivo"
import { Idioma } from "./../../model/Idioma"
import { Filtro } from "./../model/Filtro"
import { BaseService } from "./base.service"
import { LoadingService } from "./loading-data-service.service"
import { MessagesService } from "./messages-data-service.service"

export abstract class ServicioAbstract<E> extends BaseService {
	constructor(messages?: MessagesService, loadingService?: LoadingService) {
		super(messages, loadingService)
	}

	protected parseDescriptivos(res: any): Descriptivo[] {
		let descriptivos: Descriptivo[] = []
		if (res !== null) {
			var $this = this
			descriptivos = res.map((d) => Descriptivo.fromData(d))
		}
		return descriptivos
	}

	public abstract baseName(): string
	public abstract parseToEnt(data): E
	public abstract newEnt(): E
	public count(filtro: Filtro, customLoading?: LoadingService): Promise<number> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("count"), filtro.json))
			.then((r: any) => {
				return r ? r : 0
			})
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}

	public getAll(filtro: Filtro = new Filtro("dummy", {}, 0, 10000), customLoading?: LoadingService, idioma: string = Idioma.DEFAULT_CODIGO): Promise<E[]> {
		const $this = this

		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		if (!filtro["idioma"]) filtro["idioma"] = idioma
		return lastValueFrom(this.http.post(this.getBaseURL("all"), filtro.json), { defaultValue: [] })
			.then((r) => $this.parse(r))
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}
	getById(id: number, customLoading?: LoadingService, idioma?: string): Promise<E> {
		const $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL() + id + (idioma ? "?idioma=" + idioma : "")))
			.then((r) => $this.parseSingle(r))
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}

	parse = (res: any): any[] => {
		if (res) {
			let result = res.map((f) => {
				const o = this.parseToEnt(f)
				if (!o) return null
				o["version"] = f ? f["version"] : 0
				if (o instanceof Auditable) {
					o["lastModifiedBy"] = f["lastModifiedBy"]
					o["lastModifiedDate"] = f["lastModifiedDate"] ? new Date(f["lastModifiedDate"]) : null
					o["createdBy"] = f["createdBy"]
					o["createdDate"] = f["createdDate"] ? new Date(f["createdDate"]) : null
				}
				return o
			})
			return result
		}
		return []
	}

	parseSingle = (res: any): E => {
		if (res !== null) {
			const o = this.parseToEnt(res)
			if (!o) return null
			o["version"] = res ? res["version"] : 0
			return o
		}
	}
	guardarConArchivo(e: E, file: File, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		if (e["id"]) {
			const frmData = new FormData()
			if (file) frmData.append("file", file)
			let vo = this.parseToEnt(e)
			if (vo["profilePic"]) vo["profilePic"]["previewPicPath"] = null
			frmData.append("vo", encodeURIComponent(JSON.stringify(vo)))
			const request = new HttpRequest("PUT", this.getBaseURL(), frmData)
			return lastValueFrom(this.http.request(request))
				.then((r: HttpResponse<E>) => {
					const p = this.parseSingle(r.body)
					return this.postGuardar(p)
				})
				.catch((e) => this.handleError(e, customLoading))
				.finally(() => {
					if (customLoading) {
						customLoading.susLoadingCount()
					} else this.loadingService.susLoadingCount()
				})
		} else {
			const frmData = new FormData()
			if (file) frmData.append("file", file)
			let vo = this.parseToEnt(e)
			if (vo["profilePic"]) vo["profilePic"]["previewPicPath"] = null
			frmData.append("vo", encodeURIComponent(JSON.stringify(vo)))
			const request = new HttpRequest("POST", this.getBaseURL(), frmData)
			return lastValueFrom(this.http.request(request))
				.then((r: HttpResponse<E>) => {
					const p = this.parseSingle(r.body)
					return this.postGuardar(p)
				})
				.catch((e) => this.handleError(e, customLoading))
				.finally(() => {
					if (customLoading) {
						customLoading.susLoadingCount()
					} else this.loadingService.susLoadingCount()
				})
		}
	}
	postGuardar(e: E) {
		return e
	}
	guardar(e: E, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		if (e["id"]) {
			return lastValueFrom(this.http.put(this.getBaseURL(), e))
				.then((r) => {
					const p = this.parseSingle(r)
					return this.postGuardar(p)
				})
				.catch((e) => this.handleError(e, customLoading))
				.finally(() => {
					if (customLoading) {
						customLoading.susLoadingCount()
					} else this.loadingService.susLoadingCount()
				})
		} else {
			return lastValueFrom(this.http.post(this.getBaseURL(), e))
				.then((r) => {
					return this.parseSingle(r)
				})
				.catch((e) => this.handleError(e, customLoading))
				.finally(() => {
					if (customLoading) {
						customLoading.susLoadingCount()
					} else this.loadingService.susLoadingCount()
				})
		}
	}
	eliminar(id: number, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.delete(this.getBaseURL() + id))
			.then((r) => {
				this.handleOk(r, customLoading)
				return r
			})
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}

	bulkUpload(file: File): any {}
}
