import { Descriptivo } from "src/app/common/model/Descriptivo"
import { round } from "../common/utils/MathUtils"
import { Moneda } from "./Moneda"
import { TipoIVAGrabado } from "./TipoIVAGrabado"

export class ItemFacturaReporte {
	public monedaCodigo: string
	public tipoComprobanteCodigo: string
	public subcategoriaStr: string
	constructor(
		public id?: number,
		public idFactura?: number,
		public numeroFactura?: string,
		public tipoComprobante?: Descriptivo,
		public fecha?: Date,
		public moneda?: Moneda,
		public cotizacion?: number,
		public precioUnitario?: number,
		public cantidad?: number,
		public totalFactura?: number,
		public subcategoria?: Descriptivo,
		public precioUnitarioBruto?: number,
		public tipoIVA?: TipoIVAGrabado,
		public insumo?: Descriptivo,
		public esSubcategoria?: boolean,
		public descripcion?: string,
		public impuestos: number = 0,
		public categoria?: Descriptivo,
		public proveedor?: Descriptivo
	) {
		this.tipoComprobanteCodigo = tipoComprobante?.codigo || ""
		this.monedaCodigo = moneda?.codigo || ""
	}
	public static fromData(data: any): ItemFacturaReporte {
		if (!data) return null
		const o: ItemFacturaReporte = new ItemFacturaReporte(
			data.id,
			data.idFactura,
			data.numeroFactura,
			Descriptivo.fromData(data.tipoComprobante),
			data.fecha ? new Date(data.fecha) : null,
			Moneda.fromData(data.moneda),
			data.cotizacion,
			data.precioUnitario,
			data.cantidad,
			data.totalFactura,
			Descriptivo.fromData(data.subcategoria),
			data.precioUnitarioBruto,
			TipoIVAGrabado.fromData(data.tipoIVA),
			Descriptivo.fromData(data.insumo),
			data.esSubcategoria,
			data.descripcion,
			data.impuestos,
			Descriptivo.fromData(data.categoria),
			Descriptivo.fromData(data.proveedor)
		)
		o.subcategoriaStr = o.subcategoria?.descripcion || o.descripcion || ""
		return o
	}

	public getTotal(discrimina) {
		if (discrimina) {
			return this.total
		} else return this.cantidad * this.precioUnitario
	}

	public get totalDiscriminado() {
		const valorIva = this.tipoIVA?.valorIva || 0
		return round(this.precioUnitarioBruto * (1 + valorIva / 100) * this.cantidad)
	}
	public get total() {
		return round(this.subtotal, 2)
	}

	public get subtotal() {
		return this.cantidad * this.precioUnitario
	}

	public get ivaStr() {
		return this.tipoIVA?.valorIva + "%"
	}

	public get totalMonedaEntorno() {
		return this.total * this.cotizacion
	}
}
