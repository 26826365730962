import * as moment from "moment"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { Moneda } from "src/app/model/Moneda"
import { ConfiguracionEntornoService } from "../services/configuracion-entorno.service"
export class CuentaCorriente {
	public static VENTA = "VE"
	public static COBRANZA = "CO"
	public static COMPRA = "FA"
	public static ORDENPAGO = "OP"
	public static DEVOLUCION = "DEV"
	tipoOperacion: string
	numeroOperacion: string
	tipoComprobante: Descriptivo
	idOperacion: number
	fechaOperacion: Date
	cliente: Descriptivo
	importe: number
	descripcion: string
	estadoDocumento: EstadoDocumento
	fechaDisplay: string
	operacion: string
	moneda: Moneda
	cotizacion: number
	importeTransformado: number
	public color: string
	public searchStr
	public salida: number
	public entrada: number
	public get id() {
		return this.idOperacion
	}
	public static fromData(data): CuentaCorriente {
		if (!data) return
		let o = new CuentaCorriente()
		Object.assign(o, data)
		o.cliente = Descriptivo.fromData(data.cliente)
		o.fechaOperacion = new Date(data.fechaOperacion)
		o.tipoComprobante = Descriptivo.fromData(data.tipoComprobante)
		o.searchStr = o.descripcion + "-" + o.numeroOperacion + "-" + o.operacion
		o.estadoDocumento = EstadoDocumento.fromData(data.estadoDocumento)
		o.fechaDisplay = moment(o.fechaOperacion).format("DD/MM/YYYY")
		o.operacion = o.tipoComprobante?.codigo + o.numeroOperacion
		o.cotizacion = data.cotizacionAfip || data.cotizacion || 1
		o.moneda = data.moneda
		if (
			o.tipoOperacion == CuentaCorriente.COBRANZA ||
			(o.tipoOperacion == CuentaCorriente.VENTA && o.importe < 0) ||
			o.tipoOperacion == CuentaCorriente.ORDENPAGO
		) {
			o.entrada = Math.abs(o.importe)
		} else {
			o.salida = Math.abs(o.importe)
		}
		o.importeTransformado =
			o.moneda?.codigo == ConfiguracionEntornoService.CONFIGURACION_ENTORNO?.moneda?.codigo
				? Math.abs(o.importe)
				: Math.round(Math.abs(o.importe * o.cotizacion) * 100) / 100
		o.color = o.estadoDocumento?.color
		if (!o.color) {
			if (o.tipoOperacion == CuentaCorriente.DEVOLUCION) {
				o.color = "#df745c"
			} else if (o.tipoOperacion == CuentaCorriente.COBRANZA) {
				o.color = "#6e8e32"
			} else {
			}
		}

		return o
	}
	public get tieneValor() {
		return this.salida > 0 || this.entrada > 0
	}
}
