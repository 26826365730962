import { formatCurrency } from "@angular/common"
import { Component, Input, OnInit } from "@angular/core"
import * as moment from "moment"
import { filter } from "rxjs"
import { ConfiguracionEntorno } from "src/app/model/ConfiguracionEntorno"
import { ConfiguracionEntornoService } from "src/app/services/configuracion-entorno.service"
import { ReporteAG } from "./../../../../authguards/ReporteAG"
import { FiltroFecha } from "./../../../../common/model/FiltroFecha"
import { AuthService } from "./../../../../services/auth.service"
import { ReporteService } from "./../../../../services/reportes.service"
import { IngresosEgresos } from "./../../../reportes/reporte-ingresos-egresos/IngresosEgresos"
import { DashboardConfiguration } from "./../../dashboard-element/DashboardConfiguration"
import { IDashboardElement } from "./../../dashboard-element/IDashboardElement"

@Component({
	selector: "estado-ingresos-egresos",
	templateUrl: "estado-ingresos-egresos.component.html",
	styleUrls: ["estado-ingresos-egresos.component.less"]
})
export class EstadoIngresosEgresosComponent implements OnInit, IDashboardElement {
	public static TIPO = "ingresos-egresos"
	public data: {
		idListado?: number
	}
	public getUsersLocale(defaultValue: string): string {
		/*if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return defaultValue;
        }
        const wn = window.navigator as any;
        let lang = wn.languages ? wn.languages[0] : defaultValue;
        lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;*/
		return "es-AR"
	}
	public optionsBar = {
		responsive: true,

		animation: {
			animateScale: true,
			animateRotate: true
		},
		scales: {
			yAxis: {
				display: false
			}
		},
		layout: {
			padding: {
				top: 20
			}
		},
		plugins: {
			legend: {
				display: false
			},
			datalabels: {
				display: "auto",
				align: "end",
				anchor: "end",
				formatter: (value, ctx) => {
					let percentage = formatCurrency(value, this.getUsersLocale("es-ar"), "$", "ARS")
					return percentage
				}
			}
		}
	}
	public static defaultConfig: DashboardConfiguration = {
		fixedHeader: true,
		x: null,
		y: null,
		rows: 6,
		cols: 6,
		tipo: "ingresos-egresos"
	}
	public fechaDesde: Date
	public configuracionEntorno: ConfiguracionEntorno
	constructor(
		private reporteService: ReporteService,
		private authService: AuthService,
		private reporteAG: ReporteAG,
		private configuracionEntornoService: ConfiguracionEntornoService
	) {
		this.configuracionEntornoService.getConfiguracionObs.subscribe((c) => (this.configuracionEntorno = c))
		this.fechaDesde = moment().subtract(3, "months").startOf("date").toDate()
	}

	public _config: DashboardConfiguration
	public get config(): DashboardConfiguration {
		return EstadoIngresosEgresosComponent.defaultConfig
	}
	@Input()
	public set config(v: DashboardConfiguration) {
		this._config = v
		if (!v) {
			this.data = {}
			return
		}
	}
	public dataBars
	public reporteData: IngresosEgresos[] = []
	ngOnInit() {
		if (!this.reporteAG.esVisible(this.authService.getCurrentUser)) return
		const filtro = new FiltroFecha("ingresos-egresos_home")
		filtro.fechaDesde = moment().startOf("month").subtract(3, "month").toDate()
		filtro.fechaHasta = moment().endOf("month").toDate()
		filtro.dataChange.pipe(filter((d) => d != undefined)).subscribe((d) => {
			this.reporteService.getIngresosEgresos(d).then((r) => {
				this.reporteData = r
				this.dataBars = {
					labels: this.reporteData.map((d) => [
						moment(d.periodo, "MM yyyy").format("MMM yyyy"),
						formatCurrency(d.ingresos - d.egresos, this.getUsersLocale("es-ar"), "$", this.configuracionEntorno?.moneda?.codigo)
					]),
					datasets: [
						{
							label: "Ingresos",
							data: this.reporteData.map((d) => d.ingresos),
							backgroundColor: "#387f5f"
						},
						{
							label: "Egresos",
							data: this.reporteData.map((d) => d.egresos),
							backgroundColor: "#f44336"
						}
					]
				}
			})
		})
	}
	showParams(event: any) {
		return
	}
	hideParams() {
		return
	}
	onChange: (conf: DashboardConfiguration) => void
	getTitle() {
		return "Ingresos y egresos ultimos 3 meses"
	}
}
