import { FormaPago } from 'src/app/model/FormaPago';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { Moneda } from 'src/app/model/Moneda';
import { Usuario } from '../common/model/Usuario';
import { Caja } from './Caja';




export class MovimientoCaja extends Descriptivo {
    constructor(id?: number, codigo?: string, descripcion?: string,
        public fecha?: Date,
        public responsable?: Descriptivo,
        public monto?: number,
        public caja?: Caja,
        public tipoMovimiento?: Descriptivo,
        public referencia?: string,
        public saldo?: number,
        public moneda?: Moneda,
        public formaPago?: FormaPago

    ) {
        super(codigo, descripcion, id)
    }

    public static fromData(data: any): MovimientoCaja {
        if (!data) return null;
        const o: MovimientoCaja = new MovimientoCaja(
            data.id,
            data.codigo,
            data.descripcion,
            data.fecha ? new Date(data.fecha) : null,
            Usuario.fromData(data.responsable),
            data.monto,
            Caja.fromData(data.caja),
            Descriptivo.fromData(data.tipoMovimiento),
            data.referencia,
            data.saldo,
            Moneda.fromData(data.moneda),
            FormaPago.fromData(data.formaPago)
        );

        o.version = data.version; 
return o;

    }

    get salida() {
        return this.monto < 0 ? this.monto * -1 : null;
    }

    get entrada() {
        return this.monto > 0 ? this.monto : null;
    }

    get saldoCaja() {
        return 0;
    }

    get imagenUrl(){
        return this.formaPago?.imagenUrl
    }

    get detalle(){
        return this.descripcion
    }


}