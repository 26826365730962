import { BehaviorSubject } from 'rxjs';
import { TotalesActividad } from './../../../model/TotalesActividad';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'reporte-actividades-card',
  templateUrl: './reporte-actividades-card.component.html',
  styleUrls: ['./reporte-actividades-card.component.less']
})
export class ReporteActividadesCardComponent implements OnInit {

  @Input() item: TotalesActividad

  @Output() onEliminar: EventEmitter<TotalesActividad> = new EventEmitter(null)

  constructor() { }

  ngOnInit() {
  }

  eliminar(event: Event) {
    event.stopPropagation()
    this.onEliminar.emit(this.item)
  }

}
