<div class="gestor-cambio-saldo-caja content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[verAcciones]="false"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="!readonly"
		(onCancelar)="handleCancelar($event)"
		[form]="CambioSaldoCajaForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
	>
		<div class="title" model-type="title">
			<div class="grid">
				<div class="col title-content">
					<h2>{{ esRetiro ? "Retiro" : ("Ingreso" | translate) }}</h2>
				</div>
			</div>
		</div>
		<div class="gestor-form" model-type="dataContent">
			<form #CambioSaldoCajaForm="ngForm" id="CambioSaldoCajaform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-3 p-fluid">
						<span class="p-float-label">
							<p-calendar [ngModel]="item.fecha" name="fecha" [readonlyInput]="readonly"></p-calendar>
							<label>{{ "FECHA" | translate }}</label>
						</span>
					</div>
					<div class="col">
						<span class="p-float-label">
							<input pInputText [ngModel]="item.responsable?.descripcion" name="responsable" [readonly]="true" />
							<label>{{ "Responsable" | translate }}</label>
						</span>
					</div>
					<div class="col-12 lg:col-3 p-fluid" *ngIf="cajaEditable">
						<descriptivo-material-selector
							[allowDefault]="!item?.id"
							[options]="cajaService?.data | async"
							[label]="'CAJA' | translate"
							[limpiable]="false"
							[(ngModel)]="item.caja"
							[permiteNuevo]="false"
							name="caja"
							[readonly]="readonly"
							required
						>
						</descriptivo-material-selector>
					</div>
					<div class="col-12 lg:col-3 p-fluid">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[listado]="formaPagoService.data | async"
							#formaPago="ngModel"
							[required]="true"
							[(ngModel)]="item.formaPago"
							[limpiable]="true"
							[permiteNuevo]="true"
							name="forma-pago"
							[titulo]="'Forma de pago' | translate"
							[readonly]="readonly"
						>
						</parametrico-selector>
					</div>
					<div class="col-12 lg:col-3 p-fluid">
						<span-dinero-editable
							[(ngModel)]="item.monto"
							[label]="'Monto' | translate"
							[moneda]="item?.caja?.moneda"
							[editable]="!readonly"
							name="{{ 'monto' + r }}"
							[minValue]="0"
						>
						</span-dinero-editable>
					</div>
					<div class="col-12 p-fluid">
						<span class="p-float-label">
							<textarea
								pInputText
								[(ngModel)]="item.justificacion"
								name="justificacion"
								[readonly]="readonly"
								required
								rows="5"
								style="width: 100%"
							></textarea>
							<label>{{ "JUSTIFICACION" | translate }}</label>
						</span>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
