import { Pipe, PipeTransform } from '@angular/core';
import { Descriptivo } from 'src/app/common/model/Descriptivo';

@Pipe({
  name: 'byMoneda'
})
export class ByMonedaPipe implements PipeTransform {

  transform(value: any[], args?: Descriptivo): any {
    return args ? value.filter(v => v['moneda']?.codigo == args?.codigo) : value;
  }

}
