<div class="gestor-region content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'Region' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="RegionForm"
		[multilenguaje]="true"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #RegionForm="ngForm" id="Regionform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-3">
						<descriptivo-material-selector
							[label]="'PAIS' | translate"
							[limpiable]="false"
							[required]="true"
							[allowDefault]="true"
							[service]="paisService"
							[(ngModel)]="item.pais"
							[permiteNuevo]="true"
							[gestor]="gestorPais"
							[readonly]="readonly"
							name="pais"
						>
						</descriptivo-material-selector>
					</div>
					<div class="col-fixed col-codigo field">
						<span class="p-float-label">
							<input pInputText [(ngModel)]="item.codigo" type="text" #codigo="ngModel" name="codigo" [readonly]="readonly" />
							<label>{{ "CODIGO" | translate }}</label>
							<error-tag [model]="$CODIGO"></error-tag>
						</span>
					</div>

					<div class="col-12 lg:col-6">
						<span class="p-float-label">
							<input pInputText [(ngModel)]="item.descripcion" type="text" #descripcion="ngModel" name="descripcion" [readonly]="readonly" />
							<label>{{ "DESCRIPCION" | translate }}</label>
							<error-tag [model]="$DESCRIPCION"></error-tag>
						</span>
					</div>
					<div class="col-fixed lg:col-codigo p-fluid field">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'PESO_PH' | translate }}"
								[(ngModel)]="item.peso"
								pTooltip="{{ 'PESO_TOOLTIP' | translate }}"
								type="number"
								#peso="ngModel"
								name="peso"
								[readonly]="readonly"
							/>
							<label>{{ "PESO" | translate }}</label>
							<error-tag [model]="peso"></error-tag>
						</span>
					</div>
					<div class="col-12 lg:col-2 checkbox-container">
						<p-toggleButton
							onLabel="{{ 'HABILITADO' | translate }}"
							offLabel="{{ 'HABILITADO' | translate }}"
							iconPos="left"
							name="habilitado"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[disabled]="readonly"
							[(ngModel)]="item.habilitado"
						></p-toggleButton>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>

<ng-template #gestorPais let-handler="handler">
	<gestor-pais (onGuardado)="handler.onGuardado($event)" (onCancelado)="handler.onCancelado($event)" [isModal]="true" [goBack]="false"> </gestor-pais>
</ng-template>
