import { Hospedaje } from "./Hospedaje"
import { Paquete } from "./Paquete"
import { Producto } from "./Producto"
import { Programa } from "./Programa"
import { ServicioTercerizado } from "./ServicioTercerizado"
import { TipoProducto } from "./TipoProducto"
import { Transfer } from "./Transfer"

export class ProductoFactory {
	public static types = [Producto, Programa, ServicioTercerizado, Hospedaje, Paquete, Transfer]
	public static factory

	static newProducto(data?: any) {
		let o = null

		return data.tipoProductoDesc ? ProductoFactory.getFactory()[data.tipoProductoDesc](data) : Producto.fromData(data)
	}

	static newProductoByTipo(tipo?: TipoProducto) {
		return ProductoFactory.getFactory()[tipo?.codigoCorto]({ tipoProducto: tipo })
	}
	static getFactory() {
		if (!ProductoFactory.factory) {
			ProductoFactory.factory = {}
			ProductoFactory.types.forEach((tt) => (ProductoFactory.factory[tt.TIPO] = tt.fromData))
		}
		return ProductoFactory.factory
	}
	public static fromData(data) {
		return this.newProducto(data)
	}
}
