import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-reserva',
  templateUrl: './help-reserva.component.html',
  styleUrls: ['./help-reserva.component.less']
})
export class HelpReservaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
