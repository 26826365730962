import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { first } from "rxjs/operators"
import { DEF_LOGO } from "src/app/app.module"
import { ConfiguracionEntornoService } from "src/app/services/configuracion-entorno.service"
import { ComprobanteService } from "./../../services/comprobante.service"

@Component({
	selector: "app-download-file",
	templateUrl: "./download-file.component.html",
	styleUrls: ["./download-file.component.less"]
})
export class DownloadFileComponent implements OnInit {
	constructor(private route: ActivatedRoute, private comprobanteService: ComprobanteService, private confEntorno: ConfiguracionEntornoService) {
		this.confEntorno.getConfiguracion().then((c) => {
			this.mainLogo = c.profilePic?.picPath || DEF_LOGO
		})
	}
	public estado = "b"
	private _tipo
	private _token
	public mainLogo = DEF_LOGO
	public retryLink() {
		if (this._token && this._tipo) {
			switch (this._tipo) {
				case "C":
					this.comprobanteService
						.descargarToken(this._token)
						.then((c) => {
							this.estado = "d"
						})
						.catch((e) => {
							this.estado = "e"
						})
					break
				default:
					this.estado = "e"
					break
			}
		} else {
			this.estado = "e"
		}
	}
	ngOnInit() {
		this.route.params.pipe(first()).subscribe((params) => {
			this._token = params["token"]
			this._tipo = params["tipo"]
			this.retryLink()
		})
	}
}
