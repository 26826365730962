import {} from "@angular-devkit/schematics"
import { Injectable } from "@angular/core"
import { BehaviorSubject, filter, lastValueFrom } from "rxjs"
import { ClientesAG } from "src/app/authguards/ClientesAG"
import { ReservasAG } from "src/app/authguards/ReservasAG"
import { BaseService } from "src/app/common/services/base.service"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { TranslateService } from "src/app/common/services/translate.service"
import { Novedad } from "src/app/model/Novedad"
import { ConfiguracionUsuarioService } from "src/app/services/configuracion-usuario.service"
import { EstadoClientesComponent } from "../components/estado-clientes/estado-clientes.component"
import { EstadoReservasComponent } from "../components/estado-reservas/estado-reservas.component"
import { ReporteActividadesComponent } from "../components/reporte-actividades/reporte-actividades.component"
import { ReportePrevisionServiciosHomeComponent } from "../components/reporte-prevision-servicios-home/reporte-prevision-servicios-home.component"
import { ActividadesAG } from "./../../../authguards/ActividadesAG"
import { ReporteAG } from "./../../../authguards/ReporteAG"
import { Usuario } from "./../../../common/model/Usuario"
import { AuthService } from "./../../../services/auth.service"
import { IActualizable, NovedadService } from "./../../../services/novedades.service"
import { AperturaCierreComponent } from "./../components/apertura-cierre/apertura-cierre.component"
import { BuscadorPrecioComponent } from "./../components/buscador-precio/buscador-precio.component"
import { CalendarioHomeComponent } from "./../components/calendario-home/calendario-home.component"
import { DisponibilidadHomeComponent } from "./../components/disponibilidad-home/disponibilidad-home.component"
import { EstadoIngresosEgresosComponent } from "./../components/estado-ingresos-egresos/estado-ingresos-egresos.component"
import { NotasComponent } from "./../components/notas/notas.component"
import { ShortcutComponent } from "./../components/shortcut/shortcut.component"
import { DashboardConfiguration } from "./DashboardConfiguration"

@Injectable({
	providedIn: "root"
})
export class DashboardService extends BaseService implements IActualizable {
	private _ultimaNovedad: Novedad
	guardar(customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		lastValueFrom(this.http.post(this.getApiURL() + "configuracion-usuario/updateLayout", JSON.stringify(this.configs)))
			.then((r) => {
				/*	if (r) {
					this.configs = JSON.parse(r["opcionesLayout"] || "[{}]")
				} else {
					this.configs = [new DashboardConfiguration()]
				}*/
			})
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}
	public configsBS: BehaviorSubject<DashboardConfiguration[]> = new BehaviorSubject([])

	private _configs: DashboardConfiguration[] = []
	public get configs(): DashboardConfiguration[] {
		return this._configs
	}
	public set configs(v: DashboardConfiguration[]) {
		this._configs = v
		this.configsBS.next(this._configs)
	}
	public opciones: BehaviorSubject<any[]> = new BehaviorSubject([])

	public addConfig(tipo: string, customLoading?: LoadingService) {
		this.configs.push(this.crearConfig(tipo))
		this.guardar(customLoading)
	}
	public removeConfig(conf: DashboardConfiguration, customLoading?: LoadingService) {
		this.configs.splice(this.configs.indexOf(conf), 1)
		this.guardar(customLoading)
	}
	crearConfig(val: string): DashboardConfiguration {
		return DashboardService.newConfiguracion(val)
	}
	public baseName(): string {
		return "dashboard"
	}
	public getComponents() {
		return DashboardService.types.map((t) => {
			return { tipo: t.TIPO, type: t }
		})
	}

	constructor(
		messagesService: MessagesService,
		private authService: AuthService,
		private configUsuarioService: ConfiguracionUsuarioService,
		private novedadesService: NovedadService,
		private reporteAG: ReporteAG,
		private clienteAG: ClientesAG,
		private actividadesAG: ActividadesAG,
		private reservasAG: ReservasAG,
		private translateService: TranslateService
	) {
		super(messagesService)
	}
	next(n: Novedad) {
		if (!this._ultimaNovedad || this._ultimaNovedad.key != n.key) {
			this.update(this.authService.getCurrentUser)
		}
	}
	destroy() {
		this._ultimaNovedad = null
	}
	public init() {
		this.authService.getUser().subscribe((u) => this.update(u))
		this.configUsuarioService.configObs.pipe(filter((c) => c != undefined)).subscribe((c) => {
			if (c) {
				this.configs = JSON.parse(c.opcionesLayout || "{}")
			} else {
				this.configs = [new DashboardConfiguration()]
			}
		})
		this.novedadesService.registrarObservador("usuario", this)
	}
	private update(u: Usuario) {
		if (!u) this.opciones.next([])
		const opcionesDashboard: any[] = [
			{
				label: "Atajo",
				tipo: ShortcutComponent.TIPO
			},
			{
				label: "Arqueo de caja",
				tipo: AperturaCierreComponent.TIPO
			},
			{
				label: "Buscador Precios",
				tipo: BuscadorPrecioComponent.TIPO
			},
			{
				label: "Notas",
				tipo: NotasComponent.TIPO
			},

			{
				label: "Calendario",
				tipo: CalendarioHomeComponent.TIPO
			},
			{
				label: "Disponibilidad",
				tipo: DisponibilidadHomeComponent.TIPO
			}
		]
		if (this.reporteAG.esVisible(u)) {
			opcionesDashboard.push({
				label: "Ingresos y egresos",
				tipo: EstadoIngresosEgresosComponent.TIPO
			})
			opcionesDashboard.push({
				label: "Reporte Previsión Servicios",
				tipo: ReportePrevisionServiciosHomeComponent.TIPO
			})
		}
		if (this.clienteAG.esVisible(u)) {
			opcionesDashboard.push({
				label: "Estado de cuenta clientes",
				tipo: "estado-clientes"
			})
		}
		if (this.reservasAG.esVisible(u)) {
			opcionesDashboard.push({
				label: this.translateService.get("TOTALES_RESERVAS"),
				tipo: "estado-reservas"
			})
		}
		if (this.actividadesAG.esVisible(u)) {
			opcionesDashboard.push({
				label: "Actividades",
				tipo: ReporteActividadesComponent.TIPO
			})
		}
		this.opciones.next(opcionesDashboard)
	}

	public static types = [
		ShortcutComponent,
		BuscadorPrecioComponent,
		AperturaCierreComponent,
		NotasComponent,
		EstadoIngresosEgresosComponent,
		ReporteActividadesComponent,
		CalendarioHomeComponent,
		DisponibilidadHomeComponent,
		ReportePrevisionServiciosHomeComponent,
		EstadoClientesComponent,
		EstadoReservasComponent
	]
	public static factory

	static newConfiguracion(t: string) {
		let o = null
		if (!DashboardService.factory) {
			DashboardService.factory = {}
			DashboardService.types.forEach((tt) => (DashboardService.factory[tt.TIPO] = tt))
		}
		return Object.assign({}, DashboardService.factory[t].defaultConfig)
	}
}
