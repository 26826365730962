<div class="gestor-forma-pago content content-white">
	<model-gestor
		[readonly]="readonly"
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'FormaPago' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		[preSave]="preSave"
		[file]="file"
		[conArchivo]="true"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="FormaPagoForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #FormaPagoForm="ngForm" id="FormaPagoform">
				<div class="grid" *ngIf="item">
					<div class="lg:col-2 pic-wrapper col-4">
						<div class="pic">
							<profile-badge [seleccionable]="!readonly" [url]="item.imagenUrl" class="" name="icono" (onFileSelected)="onPicSelected($event)">
							</profile-badge>
							<i class="fa fa-pencil edit-icon" *ngIf="!readonly"></i>
						</div>
					</div>
					<div class="col-8">
						<div class="grid">
							<div class="col-fixed col-codigo">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'CODIGO_PH' | translate }}"
										[(ngModel)]="item.codigo"
										tooltipPosition="bottom"
										pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
										type="$TYPE"
										#codigo="ngModel"
										name="codigo"
										[readonly]="readonly"
									/>
									<label>{{ "CODIGO" | translate }}</label>
								</span>
								<error-tag [model]="codigo"></error-tag>
							</div>

							<div class="col-12 lg:col-5">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'DESCRIPCION_PH' | translate }}"
										[(ngModel)]="item.descripcion"
										tooltipPosition="bottom"
										pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
										type="$TYPE"
										#descripcion="ngModel"
										name="descripcion"
										[readonly]="readonly"
									/>
									<label>{{ "DESCRIPCION" | translate }}</label>
								</span>
								<error-tag [model]="descripcion"></error-tag>
							</div>

							<div class="col-fixed col-codigo">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'PESO_PH' | translate }}"
										[(ngModel)]="item.peso"
										tooltipPosition="bottom"
										pTooltip="{{ 'PESO_TOOLTIP' | translate }}"
										type="number"
										#peso="ngModel"
										name="peso"
										[readonly]="readonly"
									/>
									<label>{{ "PESO" | translate }}</label>
								</span>
								<error-tag [model]="peso"></error-tag>
							</div>
							<div class="col-12 lg:col-3 checkbox-container">
								<p-checkbox
									[(ngModel)]="item.habilitado"
									[binary]="true"
									name="habilitado"
									[disabled]="readonly"
									label="{{ 'HABILITADO' | translate }}"
								></p-checkbox>
							</div>
							<div class="col-12">
								<strong>{{ "CAJAS_PRETERMINADAS" | translate }}</strong>
							</div>
							<div class="col-12 lg:col-12">
								<div class="grid">
									<div class="col-6 text-center" *ngFor="let tipoOperacion of tiposOperacion">
										<strong>{{ tipoOperacion | translate }}</strong>
										<div class="grid">
											<div class="col-12" *ngFor="let default of defaults[tipoOperacion]">
												<div class="grid p-fluid align-items-center mt-3">
													<div class="col-fixed w-6rem pb-2">
														<span class="block">{{ default?.moneda?.descripcion }}</span>
													</div>
													<div class="col">
														<descriptivo-material-selector
															[options]="cajasDisponibles"
															[isLazy]="false"
															[(ngModel)]="default.caja"
															[permiteNuevo]="false"
															[readonly]="readonly"
															[limpiable]="true"
															[label]="'CAJA_DEFAULT' | translate"
															[ngModelOptions]="{ standalone: true }"
														>
														</descriptivo-material-selector>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
