import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { share } from "rxjs/operators"
import { Filtro } from "src/app/common/model/Filtro"
import { FiltroFactura } from "src/app/common/model/FiltroFactura"
import { DescriptivosService } from "src/app/common/services/descriptivos.service"
import { Proveedor } from "src/app/model/Proveedor"
import { FiltroProveedor } from "../common/model/FiltroProveedor"
import { OpcionPuesto } from "../model/OpcionPuesto"
import { TipoProveedor } from "../model/TipoProveedor"
import { LoadingService } from "./../common/services/loading-data-service.service"
import { CuentaCorriente } from "./../model/CuentaCorriente"
import { FiltroComprobante } from "./../model/FiltroComprobantes"
import { ProveedorCabecera } from "./../model/ProveedorCabecera"
import { Totales } from "./../model/Totales"
@Injectable({
	providedIn: "root"
})
export class ProveedorService extends DescriptivosService<Proveedor> {
	public name: "proveedor" | "proveedorExterno" = "proveedor"
	public baseName(): string {
		return this.name
	}
	public parseToEnt(data: any): Proveedor {
		return Proveedor.fromData(data)
	}
	public newEnt(): Proveedor {
		return new Proveedor()
	}
	getCuentaCorriente(filtro: FiltroComprobante, customLoading?: LoadingService): Promise<CuentaCorriente[]> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.put(this.getBaseURL("cuenta-corriente"), filtro.json).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r.map((d) => CuentaCorriente.fromData(d))
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	getTotales(filtro: FiltroFactura = new FiltroFactura(null), customLoading?: LoadingService): Promise<Totales> {
		let $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("totales"), filtro.json).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return new Totales(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	notificarDeuda(notificarA, customLoading?: LoadingService): Promise<boolean> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.put(this.getBaseURL("notificar-deuda"), notificarA).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	getTotalesSaldo(filtro: FiltroProveedor = new FiltroProveedor(null), customLoading?: LoadingService): Promise<Totales> {
		let $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("totales-saldo"), filtro.json).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return new Totales(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	public getOpcionesPuesto(idTipoPuesto: number): Promise<OpcionPuesto[]> {
		if (!idTipoPuesto) return Promise.resolve([])
		return lastValueFrom(this.http.get(this.getBaseURL() + "opcionesPuesto?idTipoPuesto=" + idTipoPuesto)).then((r: any) => {
			return r.map((o) => OpcionPuesto.fromData(o))
		})
	}
	eliminarGasto(id: number, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.delete(this.getBaseURL("gastoPuesto/") + id))
			.then(
				(r) => this.handleOk(r, customLoading),
				(e) => this.handleError(e, customLoading)
			)
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}
	getOpcionesPuestoReserva(idTipoPuesto: number, idReserva: number, fecha: Date): Promise<OpcionPuesto[]> {
		if (!idTipoPuesto || !fecha) return Promise.resolve([])
		return lastValueFrom(
			this.http.get(
				this.getBaseURL() +
					"opcionesPuestoReserva?idTipoPuesto=" +
					idTipoPuesto +
					"&fecha=" +
					fecha.getTime() +
					(idReserva ? "&idReserva=" + idReserva : "")
			)
		).then((r: any) => {
			return r.map((o) => OpcionPuesto.fromData(o))
		})
	}
	getCabeceras(filtro: Filtro = new Filtro("dummy", {}), customLoading?: LoadingService): Promise<ProveedorCabecera[]> {
		let $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("all"), filtro.json).pipe(share()))
			.then((r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r.map((p) => ProveedorCabecera.fromData(p))
			})
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}
	enviarResumen(id: any, customLoading?: LoadingService): Promise<boolean> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.put(this.getBaseURL("enviar-resumen"), id).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	public deshabilitar(id: number, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL() + "deshabilitar", id))
			.then(async (r) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				const d = await this.getById(id)
				d.habilitado = false
				return d
			})
			.catch((e) => this.handleError(e, customLoading))
	}
	habilitar(id, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()

		return lastValueFrom(this.http.post(`${this.getBaseURL()}habilitar/${id}`, {}))
			.then((r) => {
				return this.parseSingle(r)
			})
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}
	getHospedajes = (filtro: FiltroProveedor = new FiltroProveedor("dummy"), customLoading?: LoadingService) => {
		filtro.tipoProveedor = new TipoProveedor(null, "HTL")
		return this.getCabeceras(filtro, customLoading)
	}
}
