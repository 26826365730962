import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
	name: "excludeById"
})
export class ExcludeByIdPipe implements PipeTransform {
	transform(items: any[], excludedId: number): any[] {
		return items?.length ? items.filter((item) => item.id !== excludedId) : []
	}
}
