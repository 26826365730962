import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Moneda } from "src/app/model/Moneda"
import { Auditable } from "./Auditable"
import { EstadoReserva } from "./EstadoReserva"
import { Telefono } from "./Telefono"

export class ResumenReserva extends Auditable {
	public searchStr
	constructor(
		public id?: number,
		public fechaRegistro?: Date,
		public fechaVencimiento?: Date,
		public cliente?: Descriptivo,
		public nombrePax?: string,
		public emailContacto?: string,
		public telefonoContacto?: string,
		public cantidadPax?: number,
		public montoReserva?: number,
		public estado?: EstadoReserva,
		public esCotizacion: boolean = false,
		public comprobante?: number,
		public numeroComprobante?: string,
		public saldo?: number,
		public moneda?: Moneda,
		public errores: string[] = [],
		public canalVenta?: Descriptivo,
		public motivoCancelacion?: string,
		public consentimientoAceptado: boolean = false,
		public idConsentimiento?: number,
		public emailConsentimiento?: string,
		public telefonoConsentimiento?: Telefono,
		public estadoConsentimiento?: EstadoReserva,
		public puntoDeVenta?: Descriptivo,
		public cotizacion?: number,
		public esAdelanto?: boolean,
		public tokenEncuesta?: string,
		lastModifiedBy?: string,
		lastModifiedDate?: Date,
		public version?: number,
		public total?: number,
		public adjuntoComprobante?: string,
		public nombreVendedor?: string,
		public comprobanteIntegrado?: boolean,
		public listadoPrecio?: Descriptivo,
		public fechaUltimaActividad?: Date,
		public fechaPrimerActividad?: Date,
		public observaciones?: string
	) {
		super(lastModifiedBy, lastModifiedDate)
		this.searchStr = [this.id, this.descripcion, this.cliente ? this.cliente.descripcion : "", this.emailContacto, this.telefonoContacto].join("_")
	}
	public static fromData(data: any): ResumenReserva {
		if (!data) return null
		const o: ResumenReserva = new ResumenReserva(
			data.id,
			data.fechaRegistro ? new Date(data.fechaRegistro) : null,
			data.fechaVencimiento ? new Date(data.fechaVencimiento) : null,
			Descriptivo.fromData(data.cliente),
			data.nombrePax,
			data.emailContacto,
			data.telefonoContacto,
			data.cantidadPax,
			data.montoReserva,
			EstadoReserva.fromData(data.estado),
			data.esCotizacion,
			data.comprobante,
			data.numeroComprobante,
			data.saldo,
			Moneda.fromData(data.moneda),
			data.errores,
			Descriptivo.fromData(data.canalVenta),
			data.motivoCancelacion,
			data.consentimientoAceptado || false,
			data.idConsentimiento,
			null,
			null,
			EstadoReserva.fromData(data.estadoConsentimiento),
			Descriptivo.fromData(data.puntoDeVenta),
			data.cotizacion,
			data.esAdelanto,
			data.tokenEncuesta,
			data.lastModifiedBy,
			data.lastModifiedDate ? new Date(data.lastModifiedDate) : null
		)
		o.version = data.version
		o.total = data.total || data.montoReserva
		o.adjuntoComprobante = data.adjuntoComprobante
		o.nombreVendedor = data.nombreVendedor
		o.comprobanteIntegrado = data.comprobanteIntegrado
		o.listadoPrecio = Descriptivo.fromData(data.listadoPrecio)
		o.fechaPrimerActividad = data.fechaPrimerActividad ? new Date(data.fechaPrimerActividad) : null
		o.fechaUltimaActividad = data.fechaUltimaActividad ? new Date(data.fechaUltimaActividad) : null
		o.observaciones = data.observaciones
		o.createdBy = data.createdBy
		o.createdDate = data.createdDate ? new Date(data.createdDate) : null
		return o
	}
	public get descripcion() {
		return this.nombrePax + " x " + this.cantidadPax
	}
	public get esAdelantoSTR() {
		return this.comprobante && this.esAdelanto ? "X" : ""
	}
	public get monedaCodigo() {
		return this.moneda?.codigo || ""
	}
	public get rowClass() {
		return this.esCotizacion && this.canalVenta?.codigo == "CEXT" ? "reserva-externa" : ""
	}
}
