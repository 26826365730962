import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { Provincia } from 'src/app/model/Provincia';
@Injectable({
    providedIn: 'root',
})
export class ProvinciaService extends ServicioAbstract<Provincia>{
    public baseName(): string {
        return "provincia";
    }
    public parseToEnt(data: any): Provincia {
        return Provincia.fromData(data);
    }
    public newEnt(): Provincia {
        return new Provincia();
    }


}