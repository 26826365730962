<div class="gestor-orden-pago content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'ORDEN-PAGO' | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[subheader]="item?.id ? 'Estado: ' + item?.estado?.descripcion : null"
		(onCancelar)="handleCancelar($event)"
		[form]="OrdenPagoForm"
		[menuItemAcciones]="menuItemAcciones"
		[verAcciones]="verAcciones"
		[editable]="readonly && !usuario?.esExterno"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #OrdenPagoForm="ngForm" id="OrdenPagoform">
				<div *ngIf="item">
					<div class="grid">
						<div class="col-12 lg:col-4">
							<descriptivo-material-selector
								[allowDefault]="!item?.id"
								[options]="proveedorOptions"
								[label]="'PROVEEDOR' | translate"
								[limpiable]="limpiable"
								[(ngModel)]="item.proveedor"
								[permiteNuevo]="true"
								name="proveedor"
								[gestor]="gestorProveedor"
								[readonly]="item?.id || readonly"
								required
								(onSelect)="proveedorChange($event)"
							>
							</descriptivo-material-selector>
						</div>

						<div class="col-12 lg:col-4">
							<span class="p-float-label p-fluid">
								<p-calendar
									[showIcon]="true"
									[(ngModel)]="item.fecha"
									#fecha="ngModel"
									name="fecha"
									[readonlyInput]="!editable"
									dateFormat="dd/mm/yy"
									required
								></p-calendar>
								<label>{{ "FECHA" | translate }}</label>
							</span>
							<error-tag [model]="fecha"></error-tag>
						</div>

						<div class="col-12 lg:col-4 p-fluid">
							<span-dinero-editable
								[label]="'PAGOACUENTA' | translate"
								[readonly]="readonly"
								[(ngModel)]="item.pagoACuenta"
								[monedaRef]="moneda"
								[editable]="!readonly && !item.id"
								name="pagoACuenta"
							>
							</span-dinero-editable>
						</div>

						<div class="col-12">
							<span class="p-float-label">
								<input
									pInputText
									placeholder="{{ 'DESCRIPCION_PH' | translate }}"
									[(ngModel)]="item.descripcion"
									tooltipPosition="bottom"
									pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
									type="text"
									#descripcion="ngModel"
									name="descripcion"
									autocomplete="off"
									[readonly]="readonly"
								/>
								<label>{{ "DESCRIPCION" | translate }}</label>
							</span>
							<error-tag [model]="descripcion"></error-tag>
						</div>
					</div>
					<div class="grid">
						<div class="col-12">
							<h3 class="subtitle">{{ readonly ? "Facturas pagas" : "Facturas a pagar" }}</h3>
							<p-table
								[value]="itemOrdenPagoOptions"
								[(selection)]="item.items"
								dataKey="factura.id"
								[selectionMode]="editable ? 'multiple' : none"
								[paginator]="true"
								[rows]="10"
							>
								<ng-template pTemplate="emptymessage">
									<tr>
										<td colspan="7">
											<strong class="p-2 pl-3 block">{{
												(item?.id ? "SIN_FACTURAS_ASOCIADAS" : "NO_HAY_FACTURAS_POR_PAGAR_PARA_EL_PROVEEDOR") | translate
											}}</strong>
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="header">
									<tr *ngIf="!readonly && !item?.id && itemOrdenPagoOptions?.length > 0">
										<th colspan="5"><span class="text-right">Imputar pago</span></th>
										<th colspan="2">
											<div class="imputacion grid align-content-center">
												<div class="col no-space-mat-form">
													<span-dinero-editable
														[label]="'IMPORTE_A_IMPUTAR' | translate"
														[readonly]="readonly"
														[(ngModel)]="importeImputar"
														[editable]="!readonly"
														name="importeImputar"
													>
													</span-dinero-editable>
												</div>
												<div class="col-fixed" style="width: 6em">
													<button
														pButton
														label="{{ 'IMPUTAR' | translate }}"
														(click)="imputar()"
														[disabled]="!importeImputar || !itemOrdenPagoOptions?.length"
													></button>
												</div>
											</div>
										</th>
									</tr>
									<tr>
										<th style="width: 45px; text-overflow: unset" *ngIf="editable">
											<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
										</th>
										<th style="width: 15%">{{ "FECHA" | translate }}</th>
										<th style="width: 20%">{{ "NUMERO" | translate }}</th>
										<th style="width: 20%" class="overflow">{{ "DESCRIPCION" | translate }}</th>
										<th style="width: 15%">{{ "IMPORTE" | translate }}</th>
										<th style="width: 15%">{{ "SALDO" | translate }}</th>
										<th style="width: 15%">{{ "A_PAGAR" | translate }}</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-it let-i="rowIndex">
									<tr [pSelectableRow]="it">
										<td *ngIf="editable">
											<p-tableCheckbox [value]="it"></p-tableCheckbox>
										</td>
										<td>{{ it.factura.fecha | date : "dd/MM/yyyy" }}</td>
										<td>
											<span class="clickable" (click)="goToFactura($event, it.factura)">{{ it.factura.tipoNumero }}</span>
										</td>
										<td class="overflow" tooltipPosition="bottom" pTooltip="{{ it.factura.descripcion }}">{{ it.factura.descripcion }}</td>
										<td class="text-right">
											{{ it.factura.importeTransformado | currencyCustom }}
											<small class="block" *ngIf="monedaExtranjera(it?.factura?.moneda)"
												>{{ it.factura.importe | currencyCustom : it?.factura?.moneda }} x
												{{ it?.factura?.cotizacion | currencyCustom : item?.moneda : "1.1-6" }}</small
											>
										</td>
										<td class="text-right">
											{{ it.factura.saldoTransformado | currencyCustom }}
											<small class="block" *ngIf="monedaExtranjera(it?.factura?.moneda)"
												>{{ it.factura.saldo | currencyCustom : it?.factura?.moneda }} x
												{{ it?.factura?.cotizacion | currencyCustom : item?.moneda : "1.1-6" }}</small
											>
										</td>
										<td>
											<span-dinero-editable
												[label]="'IMPORTE' | translate"
												[readonly]="readonly"
												(onChange)="importeItemChange($event, it)"
												[required]="true"
												[editable]="editable"
												[moneda]="monedaEntorno"
												[(ngModel)]="it.importe"
												name="importe_{{ i }}"
											>
											</span-dinero-editable>
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="footer">
									<tr>
										<td *ngIf="editable"></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td *ngIf="editable" class="text-right">Total a Pagar:</td>
										<td *ngIf="!editable" class="text-right">Total:</td>
										<td class="text-right">
											{{ item.total | currencyCustom : item?.moneda }}
										</td>
									</tr>
								</ng-template>
							</p-table>
						</div>
					</div>
					<div class="grid">
						<div class="col-12">
							<span class="p-float-label p-field">
								<abm-cobros
									[item]="item"
									class="p-inputwrapper-filled"
									[readonly]="readonly || !puedeEditar || !item?.total || item?.total < 0"
									[monedaRef]="item?.moneda"
									[mostrarTotales]="!readonly && puedeEditar && item?.pagos?.length > 0"
									[clienteAsociado]="cliente?.id"
								></abm-cobros>
								<label for="pagos">{{ "PAGOS" | translate }}</label>
							</span>
						</div>
						<div class="col-12 p-fluid">
							<span class="p-float-label">
								<textarea
									pInputText
									[readonly]="readonly"
									[(ngModel)]="item.observaciones"
									tooltipPosition="bottom"
									pTooltip="{{ 'OBSERVACIONES_RESERVA' | translate }}"
									type="text"
									#observaciones="ngModel"
									name="observaciones"
								></textarea>
								<label>{{ "OBSERVACIONES" | translate }}</label>
							</span>
						</div>
					</div>
					<div class="grid" *ngIf="esNotificable()">
						<div class="col-12">
							<span class="p-float-label p-field">
								<div class="grid p-inputwrapper-filled">
									<div class="col-12 lg:col-3 checkbox-container">
										<p-toggleButton
											onLabel="{{ 'NOTIFICAR_AL_PROVEE_2' | translate }}"
											tooltipPosition="bottom"
											pTooltip="{{ 'ENVIAR_UN_MAIL_AL_P_28' | translate }}"
											offLabel="{{ 'No notificar' | translate }}"
											iconPos="right"
											name="notificar"
											onIcon="{{ 'fa fa-check-square-o' }} "
											offIcon="{{ 'fa fa-square-o' }} "
											[(ngModel)]="item.enviarNotificacion"
										></p-toggleButton>
									</div>

									<div class="col-12 lg:col-9" *ngIf="item.enviarNotificacion">
										<selector-email [(ngModel)]="item.destinatario" name="d" #d="ngModel"></selector-email>
									</div>
								</div>
								<label for="notificacion">{{ "Notificación" | translate }}</label>
							</span>
						</div>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>

<ng-template #gestorProveedor let-handler="handler">
	<gestor-proveedor
		(onGuardado)="handler.onGuardado($event); onProveedorGuardado($event)"
		(onCancelado)="handler.onCancelado($event)"
		[isModal]="true"
		[goBack]="false"
	>
	</gestor-proveedor>
</ng-template>
<p-dialog [(visible)]="verNotificarProveedor" [modal]="true" [style]="{ width: '60vw' }" header="{{ 'Enviar notificación' | translate }}">
	<form *ngIf="formNotificarProveedor" [formGroup]="formNotificarProveedor">
		<div class="grid">
			<div class="col-12">
				<div class="p-inputgroup">
					<button type="button" pButton icon="pi pi-at"></button>

					<span class="p-float-label">
						<input
							pInputText
							placeholder="{{ 'Destinatario' | translate }}"
							type="email"
							formControlName="destinatarioControl"
							name="destinatarioControl"
							appMultiEmailValidator
							required
						/>
						<label>{{ "Destinatario" | translate }}</label>
					</span>
				</div>

				<small *ngIf="getErrorMessage(codigo)">
					{{ getErrorMessage(formNotificarProveedor.controls["destinatarioControl"]) }}
				</small>
			</div>
		</div>
	</form>
	<p-footer>
		<button pButton (click)="verNotificarProveedor = false" label="{{ 'CANCELAR' | translate }}"></button>
		<button pButton cclass="p-btn-success " (click)="notificarProveedor()" label="{{ 'ENVIAR' | translate }}"></button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="eliminando" appendTo="body" [modal]="true" header="Eliminando Orden de Pago" [styleClass]="'cancelacion-dialog'">
	<div class="grid" *ngIf="eliminando">
		<div class="col-12">
			<parametrico-selector
				[tipoParametro]="'motivo-cancelacion'"
				#motivoCancelacion="ngModel"
				[(ngModel)]="motivoSeleccionado"
				[limpiable]="true"
				[permiteNuevo]="true"
				name="motivo-cancelacion"
				[titulo]="'MOTIVO_CANCELACION' | translate"
			>
			</parametrico-selector>
		</div>

		<div class="col-12 text-right">
			<button class="col" pButton label="{{ 'CONFIRMAR' | translate }}" (click)="eliminar()" [disabled]="!motivoSeleccionado"></button>
		</div>
	</div>
</p-dialog>
