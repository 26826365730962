<div class="grid p-fluid login align-content-center">
	<div class="col-12 lg:col-8 lg:col-offset-2 login-content">
		<div class="grid">
			<div class="col-12 lg:col-6 sm:col-12 left">
				<img src="{{ mainLogo }}" />
			</div>
			<div class="col-12 lg:col-6 sm:col-12 right">
				<form name="form" role="form">
					<div class="grid p-fluid field grid-nogutter">
						<div class="col-4 sm:col-12 sm:col-nopad">
							<label for="float-input">{{ "Usuario" | translate }}</label>
						</div>
						<div class="col-8 sm:col-12 sm:col-nopad">
							<input name="usuario" type="text" size="30" pInputText [(ngModel)]="username" required />
						</div>
					</div>
					<div class="grid p-fluid field grid-nogutter">
						<div class="col-4 sm:col-12 sm:col-nopad">
							<label for="float-input">{{ "Contraseña" | translate }}</label>
						</div>

						<div class="col-8 sm:col-12 sm:col-nopad">
							<p-password
								[styleClass]="'p-password-input-custom'"
								[(ngModel)]="password"
								[toggleMask]="true"
								name="password"
								required
								[feedback]="false"
							></p-password>
						</div>
					</div>
					<div class="buttons grid p-fluid">
						<div class="col-12 lg:col-6">
							<button type="submit" pButton class="p-button-success" label="{{ 'ingresar' | translate }}" (click)="login()"></button>
						</div>
						<div class="col-12 lg:col-6">
							<button type="button" pButton class="p-link" (click)="olvidePass()" label="{{ 'olvide_pass' | translate }}"></button>
						</div>
					</div>
				</form>
			</div>
			<div class="saludo col-12">
				<img class="logo-iterpax" src="assets/images/iterpax.png" alt="" />
				<span class="mensaje-bienvenida">Turismo hecho simple</span>
				<span class="version">Versión: {{ version }}</span>
			</div>
		</div>
	</div>
</div>
