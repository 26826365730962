import { Location } from "@angular/common"
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core"
import { FormBuilder, FormControl, NgForm, Validators } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { ConfirmationService, MenuItem } from "primeng/api"
import { Subscription } from "rxjs"
import { ModelGestorComponent } from "src/app/common/components/model-gestor/model-gestor.component"
import { FiltroFactura } from "src/app/common/model/FiltroFactura"
import { DefaultService } from "src/app/common/services/default.service"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Cliente } from "src/app/model/Cliente"
import { FiltroCliente } from "src/app/model/FiltroCliente"
import { ClienteService } from "src/app/services/cliente.service"
import { ComprobanteService } from "src/app/services/comprobante.service"
import { ListaPreciosService } from "src/app/services/lista-precios.service"
import { ProveedorService } from "src/app/services/proveedor.service"
import { SessionComponent } from "./../../../common/components/session-component.component"
import { Descriptivo } from "./../../../common/model/Descriptivo"
import { Parametrico } from "./../../../common/model/Parametrico"
import { IdiomaService } from "./../../../common/services/idioma.service"
import { ClienteResumen } from "./../../../model/Cliente"
import { CuentaCorriente } from "./../../../model/CuentaCorriente"
import { Nacionalidad } from "./../../../model/Nacionalidad"
import { TipoIVA } from "./../../../model/TipoIVA"
import { Totales } from "./../../../model/Totales"
import { FormaPagoService } from "./../../../services/forma-pago.service"
import { NacionalidadService } from "./../../../services/nacionalidad.service"
import { ProvinciaService } from "./../../../services/provincia.service"
import { TipoIVAService } from "./../../../services/tipo-iva.service"
import { FiltroProducto } from "./../../producto/FiltroProducto"

@Component({
	selector: "gestor-cliente",
	templateUrl: "gestor-cliente.component.html",
	styleUrls: ["gestor-cliente.component.less"]
})
export class GestorClienteComponent extends SessionComponent implements OnDestroy, OnInit {
	public menuItems: MenuItem[] = []

	private routeSub: Subscription
	@Input()
	public item: Cliente = new Cliente()
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false

	@ViewChild(ModelGestorComponent, { static: true })
	public modelGestor: ModelGestorComponent

	@ViewChild("gestorForm") gestorForm: NgForm
	@Output()
	public onGuardado: EventEmitter<Cliente> = new EventEmitter<Cliente>()

	@Output()
	public onCancelado = new EventEmitter()

	@Input()
	public readonly: boolean = false

	public email = new FormControl("", [Validators.email])
	public proveedor: Descriptivo
	public gestor: TemplateRef<any>
	public destinatario: string
	public verEnvioDeuda: boolean = false
	public editandoOpcion: boolean = false
	public esExtranjero: boolean = false
	public ctx: any = {
		itemEditado: null,
		handler: {
			onGuardado: (r) => {},
			onCancelado: (r) => {}
		}
	}
	filtroCuentaProv: FiltroFactura
	totalesProveedor: Totales
	public volver() {
		if (this.readonly) return this.location.back()
		this.modelGestor && this.modelGestor.cancelar()
	}
	public async updateNacionalidad() {
		this.esExtranjero = this.configuracionEntorno.pais?.codigo === "AR" && this.configuracionEntorno.pais?.codigo != this.item?.nacionalidad.codigo
		if (this.esExtranjero && this.configuracionEntorno.pais?.codigo === "AR") {
			this.updateCuit()
		}
	}
	public editarNacionalidad(n: Descriptivo) {
		let $this = this

		this.nacionalidadService.getById(n.id).then((r) => {
			this.ctx["itemEditado"] = r
			this.ctx["handler"] = {
				onGuardado: (r) => {
					$this.item.nacionalidad = r
					$this.updateCuit()
					$this.editandoOpcion = false
					$this.ctx = {}
				},
				onCancelado: (r) => {
					$this.editandoOpcion = false
				}
			}
			this.editandoOpcion = true
		})
	}

	constructor(
		public service: ClienteService,
		private route: ActivatedRoute,
		private confService: ConfirmationService,
		public nacionalidadService: NacionalidadService,
		public provinciaService: ProvinciaService,
		public formaPagoService: FormaPagoService,
		public tipoIVAService: TipoIVAService,
		fb: FormBuilder,
		public idiomaService: IdiomaService,
		private location: Location,
		public router: Router,
		public listaPreciosService: ListaPreciosService,
		messagesService: MessagesService,
		private defaultService: DefaultService,
		private comprobanteService: ComprobanteService,
		private confirmationService: ConfirmationService,
		public proveedorService: ProveedorService
	) {
		super(messagesService)
	}
	public get esVentas() {
		return this.usuario != undefined
	}
	public hacerPedido() {
		this.router.navigate(["reserva/nuevo"], { queryParams: { clienteId: this.item.id } })
	}
	public generarCobro() {
		this.router.navigate(["cobranza/nuevo"], { queryParams: { clienteId: this.item.id } })
	}
	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}
	public pantallaActiva: string = "data"
	public goTo(v) {
		this.pantallaActiva = v
	}
	public verCuentaCorriente() {
		//this.getCuentaCorriente().then(l => {
		this.pantallaActiva = "ctacte"
		//})
	}
	public totales: Totales
	public cuentaCorriente: CuentaCorriente[] = []
	public filtroCuenta: FiltroCliente
	public get finalTitle() {
		return this.item?.id ? this.item.descripcion : "Nuevo Cliente"
	}

	public loadingTotales: LoadingService = new LoadingService()
	public loadingRanking: LoadingService = new LoadingService()
	public filtroProducto: FiltroProducto
	ngOnInit() {
		this.subs.push(
			this.route.data.subscribe((u) => {
				this.defaultService.getDefault(this.environment.production ? "prod" : "dev" + "_tabClientes").then((r) => {
					if (r) {
						this.pantallaActiva = r.descripcion
					}
				})

				if (u?.vista) {
					this.readonly = true
					this.pantallaActiva = "ctacte"
				} else {
					this.pantallaActiva = "data"
				}
			})
		)
		if (this.route.snapshot.url.some((u) => u.path == "cliente")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				if (!this.service) return
				if (id) {
					this.updateData(id)
				} else {
					this.item = this.service ? this.service.newEnt() : null
					this.nacionalidadService.getDefault().then((r) => {
						this.item.nacionalidad = r
					})
				}
			})
		}

		this.menuItems = [
			{
				label: "Recordar",
				command: () => {
					this.defaultService.setDefault(this.environment.production ? "prod" : "dev" + "_tabClientes", { descripcion: this.pantallaActiva })
				}
			},
			{
				label: "Limpiar",
				command: () => {
					this.clean()
				}
			}
		]
	}
	public updateData(id) {
		this.service.getByIdSinBuffer(id).then((r) => {
			this.item = r

			if (this.item?.proveedor) {
				this.proveedor = this.item.proveedor
				this.filtroCuentaProv = new FiltroFactura("cuenta_prov_" + this.item.proveedor.codigo, null, 0, 20, "fecha", 1, {
					proveedor: this.item.proveedor
				})
				this.filtroCuentaProv.sortOrder = 2

				this.filtroCuentaProv.clean = () => {
					this.filtroCuenta.patchValue({
						searchStr: "",
						fechaDesde: null,
						fechaHasta: null,
						proveedor: this.item.proveedor
					})
				}
				this.proveedorService.getById(this.item.proveedor?.id).then((p) => {
					if (p.tipoProveedor?.codigo == "VE") {
						this.filtroCuentaProv.tipoProveedor = new Descriptivo(p.tipoProveedor.codigo, null, p.tipoProveedor.id)
					}

					this.proveedorService.getTotales(this.filtroCuentaProv).then((t) => {
						this.totalesProveedor = t
					})
				})
			}

			this.filtroProducto = new FiltroProducto("ranking_cliente_" + r.id, 0, 30, "cantidad", 1, false)
			this.filtroProducto.cliente = r
			this.filtroProducto.cantidad = 30
			this.filtroProducto.fechaDesde = null
			this.filtroProducto.fechaHasta = null
			this.filtroProducto.onClean((f) => {
				f.cliente = Descriptivo.fromData(this.item)
				f.fechaDesde = null
			})
			this.filtroCuenta = new FiltroCliente(r.codigo + "_cuenta_cliente", 0, 20, "fecha", 2, false, {
				cliente: r,
				fechaDesde: null,
				verArchivados: true
			})
			this.filtroCuenta.dataChange.subscribe((f) => {
				this.service.getTotales(f, this.loadingTotales).then((t) => (this.totales = t))
			})
			this.service.getTotales(this.filtroCuenta, this.loadingTotales).then((t) => (this.totales = t))
			this.filtroCuenta.onClean((f) => {
				f.cliente = Descriptivo.fromData(this.item)
				;(f.fechaDesde = null), (f.verArchivados = true)
			})
			if (this.item.esConsumidorFinal || !this.item.activo) {
				this.readonly = true
			}
		})
	}
	public habilitar() {
		this.service.habilitar(ClienteResumen.fromData(this.item)).then(async (r) => {
			this.success("Se habilitó el cliente")
			this.updateData(this.item.id)
		})
	}
	public clean(event?) {
		this.defaultService.cleanDefault(this.environment.production ? "prod" : "dev" + "_tabClientes")
		if (event) event.stopPropagation()
	}
	public notificarDeuda() {
		this.comprobanteService.hayNotasSinCompensar(this.item.id).then((hayNotas) => {
			if (hayNotas) {
				this.confirmationService.confirm({
					key: "genConf",
					header: this.translateService.get("NOTAS_SIN_COMPENSAR"),
					message: this.translateService.get("MENSAJE_NOTAS_SIN_COMPENSAR"),
					accept: () => {
						this.destinatario = this.item.emailAdministrativo || this.item.email
						this.verEnvioDeuda = true
					}
				})
			} else {
				this.destinatario = this.item.emailAdministrativo || this.item.email
				this.verEnvioDeuda = true
			}
		})
	}

	public confirmarEnvioDeuda() {
		this.service
			.notificarDeuda({
				destinatario: this.destinatario,
				idEntidad: this.item.id
			})
			.then((s) => {
				this.success("Se envió la notificación al cliente")
				this.verEnvioDeuda = false
			})
	}
	public updateCuit() {
		if (!this.item.tipoCliente || !this.item.nacionalidad) {
			return
		}
		if (this.esExtranjero) {
			if (!this.item.iva || (this.item.iva?.codigo != TipoIVA.CONSUMIDOR_FINAL && this.item.iva?.codigo != TipoIVA.CLIENTE_EXTERIOR)) {
				this.tipoIVAService.getByCodigo(TipoIVA.CLIENTE_EXTERIOR).then((r) => {
					this.item.iva = r
				})
			}
			if (!this.item.id) this.item.tipoDocumento = new Parametrico(null, Cliente.DOCUMENTO_CUIT, "CUIT")
			if (!this.item.numeroDocumento)
				this.nacionalidadService.getById(this.item.nacionalidad.id).then((n: Nacionalidad) => {
					switch (this.item.tipoCliente.codigo) {
						case "EMP":
							this.item.numeroDocumento = n.cuitJuridica

							break
						case "PART":
							this.item.numeroDocumento = n.cuitFisica
							break
						default:
							this.item.numeroDocumento = n.cuitOtros
							break
					}
				})
		}
	}
	public validar = (item: Cliente) => {
		return !this.gestorForm.valid
			? Promise.resolve(false)
			: this.service.getByTipoNumero(item.tipoDocumento?.codigo, item?.numeroDocumento).then((r: Cliente[]) => {
					if ((this.item?.id && r.some((c) => c.id != this.item.id)) || (!this.item?.id && r.length > 0)) {
						if (this.item.nacionalidad?.codigo != "AR") return true
						return this.error(
							this.translateService.get("CLIENTE_EXISTENTE").replace("{0}", item.tipoDocumento?.descripcion).replace("{1}", item.numeroDocumento)
						)
					}
					return true
			  })
	}
	public get cuitEditable() {
		return !this.esExtranjero
	}
	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}
	public editar() {
		this.readonly = false
	}
}
