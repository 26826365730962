<div class="gestor-idioma content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'IDIOMA' | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		[file]="file"
		[conArchivo]="true"
		(onItemGuardado)="handleGuardado($event)"
		(onCancelar)="handleCancelar($event)"
		[form]="IdiomaForm"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #IdiomaForm="ngForm" id="Idiomaform">
				<div class="grid" *ngIf="item">
					<div class="lg:col-2 pic-wrapper col-2">
						<div class="pic">
							<profile-badge [seleccionable]="true" [url]="url" class="" name="bandera" (onFileSelected)="onPicSelected($event)"> </profile-badge>
							<i class="fa fa-pencil edit-icon"></i>
						</div>
					</div>
					<div class="col-12 lg:col-2">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<input
								pInputText
								[(ngModel)]="item.codigo"
								tooltipPosition="bottom"
								pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
								type="text"
								#codigo="ngModel"
								name="codigo"
								[readonly]="readonly"
							/>
							<label>{{ "CODIGO" | translate }}</label>
						</span>
						<error-tag [model]="codigo"></error-tag>
					</div>
					<div class="col-12 lg:col-4">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="text"
								#descripcion="ngModel"
								name="descripcion"
								[readonly]="readonly"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>

					<div class="col-12 lg:col-2">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'PESO_PH' | translate }}"
								[(ngModel)]="item.peso"
								tooltipPosition="bottom"
								pTooltip="{{ 'PESO_TOOLTIP' | translate }}"
								type="number"
								#peso="ngModel"
								name="peso"
								[readonly]="readonly"
							/>
							<label>{{ "PESO" | translate }}</label>
						</span>
						<error-tag [model]="peso"></error-tag>
					</div>
					<div class="col-12 lg:col-2">
						<p-checkbox
							[(ngModel)]="item.esDefault"
							[binary]="true"
							name="esDefault"
							[disabled]="readonly"
							label="{{ 'ESDEFAULT' | translate }}"
						></p-checkbox>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
