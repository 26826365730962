import { Component } from "@angular/core"
import { ConfirmationService } from "primeng/api"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { ModelListHandler } from "./../../../common/interfaces/ModelListHandler"
import { Descriptivo } from "./../../../common/model/Descriptivo"
import { FiltroCargaMasiva } from "./../../../common/model/FiltroCargaMasiva"
import { ProcesoCargaMasiva } from "./../../../model/ProcesoCargaMasiva"
import { CargaMasivaService } from "./../../../services/carga-masiva.service"

@Component({
	selector: "app-listado-carga-masiva",
	templateUrl: "./listado-carga-masiva.component.html",
	styleUrls: ["./listado-carga-masiva.component.less"]
})
export class ListadoCargaMasivaComponent extends SessionComponent {
	mostrarNuevo = false
	filtro: FiltroCargaMasiva = new FiltroCargaMasiva("carga_masiva")
	verDetalle = false

	header = []
	filas = []
	editar = (id, item) => {
		this.itemEditado = item
		this.filas = []

		this.header = this.itemEditado?.header?.split(",")
		if (!this.itemEditado?.filasProcesadasError?.length) {
			return this.success("No hay errores en el proceso")
		}
		this.itemEditado?.filasProcesadasError?.forEach(
			(fila) =>
				(this.filas = [
					...this.filas,
					{
						contenido: fila?.contenido?.split(","),
						error: fila.mensajeError
					}
				])
		)

		this.verDetalle = true
	}

	itemEditado: ProcesoCargaMasiva

	public eliminar = (id: number, event?, item?) => {
		event?.stopPropagation()

		this.confirmationService.confirm({
			key: "genConf",
			header: "Eliminar",
			message: this.translateService.get("DESEA_ELIMINAR_EL__18"),
			accept: () => {
				let $this = this
				$this.service.eliminar(id).then((res) => {
					this.filtro.forceUpdate()
				})
			}
		})
	}

	handler: ModelListHandler = {
		nuevo: () => {
			this.mostrarNuevo = true
		},
		editar: this.editar,
		eliminar: (id) => {
			this.eliminar(id)
		}
	}

	columns: any[]

	tipoSeleccionado: Descriptivo
	soloVerificacion: boolean = false

	tipos: Descriptivo[] = []

	constructor(messagesService: MessagesService, public service: CargaMasivaService, public confirmationService: ConfirmationService) {
		super(messagesService)
		this.tipos = CargaMasivaService.TIPOS
	}

	ngOnInit(): void {
		this.columns = [
			{
				field: "id",
				header: "#",
				class: "id-column estado",
				role: "estado",
				sortable: true
			},
			{
				field: "estado",
				header: this.translateService.get("ESTADO"),
				class: "descriptivo-tabla",
				role: "descriptivo-tabla"
			},
			{
				field: "tipo",
				header: this.translateService.get("TIPO"),
				class: "text-column",
				role: "text-tabla"
			},
			{
				field: "inicioProceso",
				header: this.translateService.get("INICIO"),
				class: "text-column",
				role: "date-tabla",
				sortable: true
			},
			{
				field: "fechaFinProceso",
				header: this.translateService.get("FIN"),
				class: "text-column",
				role: "date-tabla",
				sortable: true
			},
			{
				field: "responsable",
				header: this.translateService.get("RESPONSABLE"),
				class: "text-column",
				role: "text-tabla"
			},
			{
				field: "progreso",
				header: this.translateService.get("PROGRESO"),
				class: "text-column",
				role: "text-tabla"
			}
		]
	}

	handleFileInput(files, dropped = false) {
		if (!this.tipoSeleccionado) {
			return this.error(this.translateService.get("INGRESE_TIPO"))
		}

		let f
		if (!dropped && files?.target["files"]) {
			f = files?.target["files"][0]
		} else {
			f = files[0]
		}
		if (this.soloVerificacion) {
			this.service.verificarArchivo(this.tipoSeleccionado.codigo, f).then((result) => {
				if (result) {
					this.success(`${this.translateService.get("CARGADO_CORRECTAMENTE")} ${result?.id}`)
				}
				this.filtro.forceUpdate()
				this.mostrarNuevo = false
			})
		} else {
			this.service.cargaMasiva(this.tipoSeleccionado.codigo, f).then((result) => {
				if (result) {
					this.success(`${this.translateService.get("CARGADO_CORRECTAMENTE")} ${result?.id}`)
				}
				this.filtro.forceUpdate()
				this.mostrarNuevo = false
			})
		}
	}

	getCount = () => {
		return Promise.resolve(0)
	}
}
