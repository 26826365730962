import { ListaPreciosAG } from "src/app/authguards/ListaPreciosAG"

import { Component, OnDestroy, OnInit } from "@angular/core"
import { NavigationExtras, Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { AccionesTabla } from "src/app/common/model/AccionesTabla"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Filtro } from "src/app/common/model/Filtro"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { ListaPrecios } from "src/app/model/ListaPrecios"
import { ListaPreciosIntegracion } from "src/app/model/ListaPreciosIntegracion"
import { ListaPreciosService } from "src/app/services/lista-precios.service"
import { ProviderService } from "src/app/services/provider.service"

@Component({
	selector: "listado-lista-precios",
	templateUrl: "listado-lista-precios.component.html",
	styleUrls: ["listado-lista-precios.component.less"]
})
export class ListadoListaPreciosComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: Filtro
	public listado: ListaPrecios[] = []
	public itemEditado: ListaPrecios = new ListaPrecios()
	copiar: boolean = false
	selectedItem: ListaPrecios
	descripcion: string

	public integraciones: ListaPreciosIntegracion[] = []
	public listadoIntegrado
	public isVerIntegraciones = false
	public providers: Descriptivo[]

	public columns = [
		{
			field: "codigoEstado",
			header: "",
			class: "icon-tabla",
			role: "icon-tabla",
			sortable: true,
			order: 40,
			width: "45px"
		},
		{
			field: "codigo",
			header: "#",
			class: "id-column",
			role: "text-tabla",
			sortable: true,
			order: 1
		},
		{
			field: "descripcion",
			header: "Descripcion",
			class: "text-tabla",
			role: "text-tabla",
			sortable: true,
			order: 3
		},
		{
			field: "moneda",
			header: "Moneda",
			class: "descriptivo-tabla",
			role: "descriptivo-tabla",
			sortable: true,
			order: 40
		},
		{
			field: "ultimaActualizacion",
			header: "Ultima Actualizacion",
			class: "date-tabla",
			role: "date-tabla",
			sortable: false,
			order: 40
		},
		{
			field: "integraciones",
			header: "Integraciones",
			role: "tags",
			sortable: false
		},
		{
			field: "lastModifiedBy",
			header: "Usuario",
			class: "text-tabla",
			role: "text-tabla",
			sortable: true,
			order: 40
		},
		{
			field: "habilitado",
			header: "Habilitado",
			class: "is-boolean",
			role: "boolean-tabla",
			sortable: false,
			order: 40
		}
	]

	public acciones: AccionesTabla[] = [
		new AccionesTabla("Clonar Lista", "fa fa-copy", "accion-tabla", (item) => {
			this.selectedItem = item
			this.copiar = true
			this.descripcion = item.descripcion + " - Copia"
		}),
		new AccionesTabla("Ver", "fa fa-eye", "accion-tabla", (item) => {
			this.ver(item.id)
		}),
		new AccionesTabla(
			"Editar",
			"fa fa-pencil",
			"accion-tabla",
			(item) => {
				this.editar(item.id)
			},
			(item: ListaPrecios) => {
				return this.preciosAG.esVisible(this.usuario)
			}
		),
		new AccionesTabla(
			"Eliminar Lista",
			"fa fa-trash",
			"accion-tabla",
			(item) => {
				this.eliminar(item)
			},
			(item: ListaPrecios) => {
				return this.preciosAG.esVisible(this.usuario)
			}
		),
		new AccionesTabla(
			this.translateService.get("Integraciones"),
			"fa fa-link",
			"accion-tabla",
			(item) => {
				this.verIntegraciones(item)
			},
			(item) => {
				return item.impuestosIncluidos
			}
		),
		new AccionesTabla(
			this.translateService.get("Habilitar"),
			"fa fa-check",
			"accion-tabla",
			(item) => {
				if (!item?.id) return
				this.service.habilitar(item?.id).then((res) => {
					item.habilitado = true
					this.filtro.forceUpdate()
					this.success(this.translateService.get("OK_GUARDADO"))
				})
			},
			(item) => {
				return !item.habilitado
			}
		),
		new AccionesTabla(
			this.translateService.get("Deshabilitar"),
			"fa fa-ban",
			"accion-tabla",
			(item) => {
				if (!item?.id) return
				this.service.deshabilitar(item?.id).then((res) => {
					item.habilitado = true
					this.filtro.forceUpdate()
					this.success(this.translateService.get("OK_GUARDADO"))
				})
			},
			(item) => {
				return item?.habilitado
			}
		)
	]

	constructor(
		messagesService: MessagesService,
		public service: ListaPreciosService,
		private router: Router,
		public confService: ConfirmationService,
		public preciosAG: ListaPreciosAG,
		private providerService: ProviderService,
		public integracioanesService: ProviderService
	) {
		super(messagesService)
	}

	ngOnInit() {
		this.filtro = new Filtro("lista-precios_filtros", {}, 0, 10)
		this.providerService.getAllPortales().then((data) => (this.providers = data))
	}
	ngOnDestroy() {}

	private editar(id: number) {
		let query: NavigationExtras = {
			queryParams: {
				id: id
			}
		}
		this.router.navigate(["lista-precios/edit"], query)
	}
	private ver(id: number) {
		let query: NavigationExtras = {
			queryParams: {
				id: id
			}
		}
		this.router.navigate(["lista-precios/vista"], query)
	}

	public eliminar(item: ListaPrecios) {
		this.confService.confirm({
			key: "genConf",
			header: "Eliminar",
			message: "Va a eliminar la lista " + item.descripcion + ". Desea continuar?",
			accept: () => {
				let $this = this
				$this.service.eliminar(item.id).then((res) => {
					$this.service.getAll($this.filtro).then((fs) => ($this.listado = fs))
					$this.success(this.translateService.get("EL_ITEM_FUE_ELIMINA_15"))
				})
			}
		})
	}
	public getCabeceras = (f) => {
		return this.service.getCabeceras(f)
	}
	public copiarLista() {
		this.service.copiar(this.selectedItem, this.descripcion).then((i) => {
			this.filtro.forceUpdate()
			this.success(this.translateService.get("ITEM_COPIADO"))
			this.copiar = false
		})
	}

	public verIntegraciones(item: ListaPrecios) {
		this.listadoIntegrado = item
		this.service.verIntegraciones(item.id).then((i) => {
			this.integraciones = i
			this.isVerIntegraciones = true
		})
	}

	public getIntegracion(provider: string) {
		return this.integraciones.find((i) => i.provider.codigo == provider)
	}

	public puedeIntegrar(provider: string) {
		let integracionProvider = this.getIntegracion(provider)
		return integracionProvider == null || integracionProvider.versionIntegrada < this.listadoIntegrado.version
	}

	public integrar(provider: string) {
		this.service.integrar(this.listadoIntegrado.id, provider).then((res) => {
			this.verIntegraciones(this.listadoIntegrado)
		})
	}
}
