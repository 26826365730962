import { Descriptivo } from "../common/model/Descriptivo";
import { ItemEncuestaActividades } from "./ItemEncuestaActividades";
import { PuestoServicioReserva } from "./PuestoServicioReserva";



export class ReporteEncuesta  {
    constructor(
		public fechaCompletado ?: Date, 
		public fechaActividad ?: Date, 
		public nombrePax ?: string, 
		public idCliente ?: number, 
		public nombreCliente ?: string, 
		public idProducto ?: number, 
		public descripcionProducto ?: string, 
		public categoriasProducto : Descriptivo[] = [], 
		public codigoTipoProducto ?: string, 
		public descripcionTipoProducto ?: string, 
		public puestos : PuestoServicioReserva[] = [], 
		public idVehiculo ?: number, 
		public descripcionVehiculo ?: string, 
		public items : ItemEncuestaActividades[] = [], 

    ) {
    }
    public static fromData(data: any): ReporteEncuesta {
        if (!data) return null;
        const o: ReporteEncuesta = new ReporteEncuesta(
			data.fechaCompletado? new Date(data.fechaCompletado) : null , 
			data.fechaActividad? new Date(data.fechaActividad) : null , 
			data.nombrePax, 
			data.idCliente, 
			data.nombreCliente, 
			data.idProducto, 
			data.descripcionProducto, 
			data.categoriasProducto? data.categoriasProducto.map(c => Descriptivo.fromData(c)) : [], 
			data.codigoTipoProducto, 
			data.descripcionTipoProducto, 
			data.puestos? data.puestos.map(c => PuestoServicioReserva.fromData(c)) : [], 
			data.idVehiculo, 
			data.descripcionVehiculo, 
			data.items? data.items.map(c => ItemEncuestaActividades.fromData(c)) : [], 

        );

return o;

    }

}

export class ReporteEncuestaSumarizado {

	constructor(public idPregunta: number, public descripcionPregunta: string, public cantidad?: number, public total?: number, public puntajes: Puntaje[] = [] ) {}

	public get promedio(): number {
		return Math.round((this.total / this.cantidad) * 100) / 100
	}
}

export class Puntaje {
	constructor(public valor: number, public cantidad: number, public respuestas: ItemEncuestaActividades[] =  []) {

	}
}