import { Descriptivo } from "../../common/model/Descriptivo"
import { FileData } from "../FileData"
import { FormaPago } from "../FormaPago"
import { ListadoDocumentos } from "../ListadoDocumentos"
import { Moneda } from "../Moneda"
import { Cobro } from "./Cobro"

export class CobroCompensacionSaldo extends Cobro {
	public static TIPO = "S"
	constructor(
		id?: number,
		formaPago?: FormaPago,
		comprobante?: string,
		monto?: number,
		caja?: Descriptivo,
		moneda?: Moneda,
		cotizacion: number = 1,
		adjunto?: FileData,
		public listadoFacturas?: ListadoDocumentos,
		public refOrdenPago?: number
	) {
		super(id, formaPago, comprobante, monto, moneda, cotizacion, CobroCompensacionSaldo.TIPO, caja, adjunto)
	}
	public static fromData(data: any): CobroCompensacionSaldo {
		if (!data) return null
		const o: CobroCompensacionSaldo = new CobroCompensacionSaldo(
			data.id,
			FormaPago.fromData(data.formaPago),
			data.comprobante,
			data.monto,
			Descriptivo.fromData(data.caja),
			Moneda.fromData(data.moneda),
			data.cotizacion,
			FileData.fromData(data.adjunto),
			ListadoDocumentos.fromData(data.listadoFacturas),
			data.refOrdenPago
		)

		return o
	}
}
