import { Descriptivo } from "src/app/common/model/Descriptivo"
import { IConRecordatorio } from "../pages/recordatorio/IConRecordatorio"
import { ProfilePic } from "./../common/model/ProfilePic"
import { Idioma } from "./Idioma"
import { Nacionalidad } from "./Nacionalidad"
import { Nota } from "./Nota"
import { Recordatorio } from "./Recordatorio"

export class Cliente extends Descriptivo implements IConRecordatorio {
	public static DOCUMENTO_CF = "9999999999999"
	public static DOCUMENTO_CUIT = "80"
	public static DOCUMENTO_CF_DESC = "Consumidor Final"

	public get tipoEntidad(): string {
		return "ClienteVO"
	}
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public nombre?: string,
		public tipoCliente?: Descriptivo,
		public numeroDocumento?: string,
		public email?: string,
		public telefono?: string,
		public comentario?: string,
		public notas?: Descriptivo,
		public activo?: boolean,
		public facebook?: string,
		public instagram?: string,
		public iva?: Descriptivo,
		public domicilio?: string,
		public formaPagoDefault?: Descriptivo,
		public descuento?: number,
		public nacionalidad?: Nacionalidad,
		public idioma?: Idioma,
		public emailReserva?: string,
		public emailAdministrativo?: string,
		public tipoDocumento?: Descriptivo,
		public razonSocial?: string,
		public esConsumidorFinal?: boolean,
		public listasPrecios?: Descriptivo[],
		public recordatorios: Recordatorio[] = [],
		public proveedor?: Descriptivo,
		public integrado?: boolean
	) {
		super(codigo, nombre ? nombre : razonSocial, id)
	}

	public static fromData(data: any): Cliente {
		if (!data) return null
		const o: Cliente = new Cliente(
			data.id,
			data.codigo,
			data.descripcion,

			data.nombre,
			Descriptivo.fromData(data.tipoCliente),
			data.numeroDocumento,
			data.email,
			data.telefono,
			data.comentario,
			data.notas ? data.notas.map((c) => Nota.fromData(c)) : [],
			data.activo,
			data.facebook,
			data.instagram,
			Descriptivo.fromData(data.iva),
			data.domicilio,
			Descriptivo.fromData(data.formaPagoDefault),
			data.descuento,
			Nacionalidad.fromData(data.nacionalidad),
			Idioma.fromData(data.idioma),
			data.emailReserva,
			data.emailAdministrativo,
			Descriptivo.fromData(data.tipoDocumento),
			data.razonSocial,
			data.esConsumidorFinal,
			data.listasPrecios?.map((l) => Descriptivo.fromData(l))
		)
		o.version = data.version
		o.recordatorios = data.recordatorios?.map((r) => Recordatorio.fromData(r)) || []
		o.proveedor = Descriptivo.fromData(data.proveedor)
		o.integrado = data.integrado
		return o
	}
}

export class ClienteResumen extends Descriptivo {
	public descripcion: string
	public searchStr: string
	public get whatsapp(): string {
		return this.telefono?.replace(/[^0-9]/g, "")
	}
	constructor(
		id?: number,
		public nombre?: string,
		public tipoCliente?: Descriptivo,
		public numeroDocumento?: string,
		public email?: string,
		public emailAdministrativo?: string,
		public telefono?: string,
		public comentario?: string,
		public activo?: boolean,
		public importe: number = 0,
		public saldo: number = 0,
		public nacionalidad?: ProfilePic,
		public razonSocial?: string,
		public integrado?: boolean
	) {
		super(id + "", nombre || razonSocial, id)
		this.searchStr = [
			this.id,
			this.descripcion,
			this.tipoCliente?.descripcion || "",
			this.numeroDocumento || "",
			this.nombre || "",
			this.razonSocial || ""
		].join("_")
	}

	public static fromData(data: any): ClienteResumen {
		if (!data) return null
		const o: ClienteResumen = new ClienteResumen(
			data.id,
			data.nombre,
			Descriptivo.fromData(data.tipoCliente),
			data.numeroDocumento,
			data.email,
			data.emailAdministrativo,
			data.telefono,
			data.comentario,
			data.activo,
			data.importe,
			data.saldo,
			ProfilePic.fromData(data.nacionalidad),
			data.razonSocial,
			data.integrado
		)
		o.version = data.version
		o.activo = o.activo == null ? true : o.activo
		return o
	}
	public get bandera(): string {
		return this.nacionalidad?.picPathVersion
	}
}
