import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Parametrico } from "src/app/common/model/Parametrico"
import { ProfilePic } from "src/app/common/model/ProfilePic"
import { IConCategoria } from "./IConCategoria"

export class TipoPuesto extends Parametrico implements IConCategoria {
	public static ALQUILER_AUTO: string = "AU"
	public static ALOJAMIENTO: string = "ALO"

	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado: boolean = true,
		esSistema: boolean = false,
		peso: number = 99,
		public categoria?: Descriptivo,
		public icono: ProfilePic = new ProfilePic(null, "assets/images/default_profile.png"),
		public recibeResumen: boolean = false,
		public recibeReserva: boolean = false,
		public mostrarEncuesta: boolean = false,
		public esPrestador: boolean = false,
		public recibeDatosPaxs: boolean = false
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso)
	}
	public tipoParametro(): string {
		return "TipoPuesto"
	}
	public static fromData(data: any): TipoPuesto {
		if (!data) return null
		const o: TipoPuesto = new TipoPuesto(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			data.esSistema,
			data.peso,
			Descriptivo.fromData(data.categoria),
			data.icono ? ProfilePic.fromData(data.icono) : new ProfilePic(null, "assets/images/default_profile.png"),
			data.recibeResumen,
			data.recibeReserva,
			data.mostrarEncuesta,
			data.esPrestador,
			data.recibeDatosPaxs
		)

		o.version = data.version
		return o
	}

	public getCategoria() {
		return this.categoria ? this.categoria.codigo : ""
	}

	public get imagen() {
		return this.icono ? this.icono.picPathVersion : "assets/images/default.png"
	}
}
