import { Provider } from "./Provider"

export class ProviderConfiguration {
	constructor(
		public id?: number,
		public version?: number,
		public apiPath?: string,
		public apiUser?: string,
		public apiPass?: string,
		public provider?: Provider
	) {}
	public static fromData(data: any): ProviderConfiguration {
		if (!data) return null
		const o: ProviderConfiguration = new ProviderConfiguration(
			data.id,
			data.version,
			data.apiPath,
			data.apiUser,
			data.apiPass,
			Provider.fromData(data.provider)
		)

		return o
	}
}
