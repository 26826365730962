import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { PlantillaPregunta } from './PlantillaPregunta';


export class ItemEncuestaActividades  {
    constructor(
		public id ?: number, 
		public respuestaEncuesta ?: Descriptivo, 
		public pregunta ?: PlantillaPregunta, 
		public respuesta ?: string, 
    public version?: number,
    ) {
    }
    public static fromData(data: any): ItemEncuestaActividades {
        if (!data) return null;
        const o: ItemEncuestaActividades = new ItemEncuestaActividades(
			data.id, 
			Descriptivo.fromData(data.respuestaEncuesta), 
			PlantillaPregunta.fromData(data.pregunta), 
			data.respuesta, 

        );

        o.version = data.version; 
return o;

    }

}