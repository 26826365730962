
import { Descriptivo } from 'src/app/common/model/Descriptivo';


export class ImputacionPorNacion  {
    constructor(
        		public id ?: number,
		public codigoTipoJuridico ?: string,
		public cuitImputacaion ?: string,
		public nacionalidad ?: Descriptivo,
        public version?: number,
    ) {
    }
    public static fromData(data: any): ImputacionPorNacion {
        if (!data) return null;
        const o: ImputacionPorNacion = new ImputacionPorNacion(

					 data.id, 
					 data.codigoTipoJuridico, 
					 data.cuitImputacaion, 
					 Descriptivo.fromData(data.nacionalidad), 
        );

        o.version = data.version; 
return o;

    }

}