import { Descriptivo } from "src/app/common/model/Descriptivo"
import { IConSubcategoriasImponibles } from "./../common-components/abm-subcategorias-imponibles/IConSubcategoriasImponibles"
import { IConCategoria } from "./IConCategoria"
import { SubcategoriaImponibleItem } from "./SubcategoriaImponibleItem"
import { TipoIVA } from "./TipoIVA"
import { Unidad } from "./Unidad"

export class Insumo extends Descriptivo implements IConCategoria, IConSubcategoriasImponibles {
	static COMISION: string = "COMI"
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public esCostoFijo: boolean = false,
		public cuentaContable?: Descriptivo,
		public categoria?: Descriptivo,
		public unidad?: Unidad,
		public habilitado: boolean = true,
		public tipoIVA?: TipoIVA,
		public subcategorias: SubcategoriaImponibleItem[] = [],
		public esSubcategorizado: boolean = false
	) {
		super(codigo, descripcion, id)
		const vals = [
			this.descripcion,
			"#" + this.id,
			this.codigo,
			this.categoria ? this.categoria.descripcion : "",
			this.unidad ? this.unidad.descripcion : ""
		]
		this.searchStr = vals.join("_")
	}

	public static fromData(data: any): Insumo {
		if (!data) return null
		const o: Insumo = new Insumo(
			data.id,
			data.codigo,
			data.descripcion,
			data.esCostoFijo,

			Descriptivo.fromData(data.cuentaContable),
			Descriptivo.fromData(data.categoria),
			Unidad.fromData(data.unidad),
			data.habilitado
		)
		if (o.id == null) data.habilitado = true
		o.tipoIVA = TipoIVA.fromData(data.tipoIVA)
		o.subcategorias = data.subcategorias?.map((s) => SubcategoriaImponibleItem.fromData(s)) || []
		o.esSubcategorizado = data.esSubcategorizado
		o.version = data.version
		return o
	}
	public quitarItem(item: any) {
		this.subcategorias = this.subcategorias.filter((i) => i != item)
	}
	public agregarItem(item: any) {
		if (this.subcategorias.indexOf(item) < 0) {
			this.subcategorias = [...this.subcategorias, item]
		}
	}
	public newItem() {
		const s = new SubcategoriaImponibleItem()
		s.proporcion = 100 - this.subcategorias.reduce((a, b) => (a += b.proporcion), 0)
		return s
	}
	public getCategoria() {
		return this.categoria ? this.categoria.codigo : ""
	}
	public get resumenImpuestos() {
		if (!this.esSubcategorizado) return this.tipoIVA?.descripcion || "IVA Sin definir"
		return this.subcategorias.map((s) => s.subcategoriaImponible?.descripcion + " (" + s.tipoIVA?.descripcion + ") " + s.proporcion + "%").join(", ")
	}
}

export class InsumoRanking extends Descriptivo {
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public cantidad?: number,
		public precio?: number,
		public fecha?: any,
		public idProveedor?: number,
		public nombreProveedor?: string,
		public razonSocial?: string,
		public facturaId?: number,
		public esNota?: boolean,
		public moneda?: string,
		public importeStr?: string,
		public importe?: number,
		public tipoProveedor?: string,
		public categoriaInsumo?: string
	) {
		super(codigo, descripcion, id)
		this.importe = this.precio
		this.importeStr = this.importe.toFixed(2)
	}
	public static fromData(data: any): InsumoRanking {
		if (!data) return null
		const o: InsumoRanking = new InsumoRanking(
			data.id,
			data.codigo,
			data.descripcion,
			data.cantidad,
			data.precio,
			data.fecha,
			data.idProveedor,
			data.nombreProveedor,
			data.razonSocialProveedor,
			data.facturaId,
			data.esNota
		)
		o.tipoProveedor = data.tipoProveedor
		if (o.esNota) {
			o.cantidad = o.cantidad * -1
			o.importe = o.importe * -1
			o.precio = o.precio * -1
		}
		o.version = data.version
		o.categoriaInsumo = data.categoriaInsumo
		return o
	}
}
