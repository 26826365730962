import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { ServicioAbstract } from "src/app/common/services/service.service"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { FacturaResumen } from "src/app/model/FacturaResumen"
import { Filtro } from "../common/model/Filtro"
import { FiltroFactura } from "../common/model/FiltroFactura"
import { LoadingService } from "../common/services/loading-data-service.service"
import { ItemFacturaReporte } from "../model/ItemFacturaReporte"
import { Totales } from "../model/Totales"
@Injectable({
	providedIn: "root"
})
export class FacturaResumenService extends ServicioAbstract<FacturaResumen> {
	public baseName(): string {
		return "factura"
	}
	public parseToEnt(data: any): FacturaResumen {
		return FacturaResumen.fromData(data)
	}
	public newEnt(): FacturaResumen {
		return new FacturaResumen()
	}

	getAll(filtro: Filtro, customLoading?: LoadingService): Promise<FacturaResumen[]> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL() + "getAllResumen", filtro.json))
			.then((r) => this.parse(r))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}

	getTotales(filtro: Filtro): Promise<Totales[]> {
		return lastValueFrom(this.http.post(this.getBaseURL() + "getTotales", filtro.json)).then((r: any[]) => r.map((t) => Totales.fromData(t)))
	}

	public aprobarFactura(factura: FacturaResumen): Promise<any> {
		factura.estado = new EstadoDocumento(null, "A", "Aprobar")
		return this.cambioEstadoFactura(factura)
	}
	public rechazarFactura(factura: FacturaResumen): Promise<any> {
		factura.estado = new EstadoDocumento(null, "R", "Rechazado")
		return this.cambioEstadoFactura(factura)
	}

	private cambioEstadoFactura(factura: FacturaResumen): Promise<any> {
		return lastValueFrom(this.http.post(this.getBaseURL() + "cambioEstadoFactura", factura)).then(this.handleOk, this.handleError)
	}

	public getFacturasImpagas(idProveedor: number, idCentro: number): Promise<any> {
		return lastValueFrom(this.http.get(this.getBaseURL() + "getImpagas/" + idProveedor + (idCentro ? `/${idCentro}` : ""))).then((r) => this.parse(r))
	}

	public rendir(factura): Promise<any> {
		return lastValueFrom(this.http.post(this.getBaseURL() + "rendir", factura)).then(this.handleOk, this.handleError)
	}
	public getItems(filtro: FiltroFactura, customLoading?: LoadingService): Promise<ItemFacturaReporte[]> {
		const $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("getItemsReporte"), filtro.json), { defaultValue: [] })
			.then((r: any) => filtro.apply(r || []).map((result) => ItemFacturaReporte.fromData(result)))
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}

	public getExternasPendientes(filtro: Filtro): Promise<FacturaResumen[]> {
		return lastValueFrom(this.http.post(this.getBaseURL() + "getExternasPendientes", filtro.json)).then((r) => this.parse(r))
	}
}
