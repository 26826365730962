import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { SessionComponent } from 'src/app/common/components/session-component.component'
import { Filtro } from 'src/app/common/model/Filtro'
import { ColumnGenerator } from 'src/app/common/utils/ColumnGenerator'
import { Consentimiento } from 'src/app/model/Consentimiento'
import { FiltroConsentimiento } from 'src/app/model/FiltroConsentimiento'
import { ConsentimientoService } from 'src/app/services/consentimiento.service'

@Component({
    selector: 'listado-consentimiento',
    templateUrl: 'listado-consentimiento.component.html',
    styleUrls: ['listado-consentimiento.component.less'],
})
export class ListadoConsentimientoComponent extends SessionComponent implements OnInit, OnDestroy {
    public filtro: FiltroConsentimiento = new FiltroConsentimiento('consentimiento_filtros',0,50)
	@ViewChild('cliente', { static: true }) clienteRef: TemplateRef<any>

    public listado: Consentimiento[] = []
    public columns = []
    
    constructor(public service: ConsentimientoService, public router: Router) {
        super()
    }
    ngOnInit() {

		this.columns = [ {
            field: 'idReserva',
            header: 'Reserva',
            class: 'descriptivo-tabla',
            role: 'link-tabla',
            sortable: true,
            navegar: (item) => {
                if (item?.idReserva) this.router.navigate(['reserva/vista'], { queryParams: { id: item.idReserva } })
            },
			width: '10%'
        },
		{
			field: 'nombrePax',
			header: 'Cliente',
			class: 'text-tabla',
			role: 'template',
			sortable: true,
			template: this.clienteRef,
			order: 2,
			width: '20%',
			esMobile: true,
		},
		{
			"field": "emailContacto",
			"header": "Email de contacto",
			"class": "text-tabla",
			"role": "text-tabla",
			"sortable": true,
			"order": 40
		  },
          {
			"field": "telefonoContacto",
			"header": "Telefono de contacto",
			"class": "text-tabla",
			"role": "text-tabla",
			"sortable": true,
			"order": 40
		  },
        {
            field: 'fechaEnviado',
            header: 'Fecha Enviado',
            class: 'date-tabla',
            role: 'date-tabla',
            sortable: true,
            order: 40,
        },
        {
            field: 'fechaCompletado',
            header: 'Fecha Completado',
            class: 'date-tabla',
            role: 'date-tabla',
            sortable: true,
            order: 40,
        },]

	}
    ngOnDestroy() {}
}
