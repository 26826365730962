import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { ServicioAbstract } from "src/app/common/services/service.service"
import { ProductoPublicado } from "src/app/model/ProductoPublicado"
import { LoadingService } from "../common/services/loading-data-service.service"
import { ProductoPublicadoIntegracion } from "../model/ProductoPublicadoIntegracion"

@Injectable({
	providedIn: "root"
})
export class ProductoPublicadoService extends ServicioAbstract<ProductoPublicado> {
	public baseName(): string {
		return "producto-publicado"
	}
	public parseToEnt(data: any): ProductoPublicado {
		return ProductoPublicado.fromData(data)
	}
	public newEnt(): ProductoPublicado {
		return new ProductoPublicado()
	}
	public integrar(integracion: ProductoPublicadoIntegracion, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		var p = this.http
			.post(this.getBaseURL("integrar"), integracion)
			.toPromise()
		p.then((r) => {
			if (customLoading) {
				customLoading.susLoadingCount()
			} else this.loadingService.susLoadingCount()
		}).catch((r) => {
			if (customLoading) {
				customLoading.susLoadingCount()
			} else this.loadingService.susLoadingCount()
		})
		return p
	}

	public verIntegraciones(id: number, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL("integraciones/" + id)), { defaultValue: [] })
			.then((data: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()

				return data.map((o: any) => ProductoPublicadoIntegracion.fromData(o))
			})
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}

	public autogenerarByProductoId(productoId: number, codigoIdioma: string, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL("autogenerar/" + productoId + '/' + codigoIdioma)), { defaultValue: [] })
			.then((data: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()

				return this.parseToEnt(data)
			})
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}
}
