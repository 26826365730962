import { Injectable } from "@angular/core"
@Injectable({
	providedIn: "root"
})
export class DragDropContext {
	public fromContext: any
	public toContext: any
	public type: string
	public paxSelection: boolean = false

	public reset() {
		this.fromContext = null
		this.toContext = null
		this.type == ""
		this.paxSelection = false
	}
}
