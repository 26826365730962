import { EncuestaActividades } from "src/app/model/EncuestaActividades"

import { Descriptivo } from "src/app/common/model/Descriptivo"

export class FamiliaProducto extends Descriptivo {
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public codigoIdioma: string = "ES",
		public detalle?: string,
		public nombreComercial?: string,
		public requiereConsentimiento?: boolean,
		public encuesta?: EncuestaActividades,
		public agrupado: boolean = true
	) {
		super(codigo, descripcion, id)
	}

	public static fromData(data: any): FamiliaProducto {
		if (!data) return null
		const o: FamiliaProducto = new FamiliaProducto(
			data.id,
			data.codigo,
			data.descripcion,
			data.codigoIdioma,
			data.detalle,
			data.nombreComercial,
			data.requiereConsentimiento,
			EncuestaActividades.fromData(data.encuesta),
			data.agrupado
		)

		o.version = data.version
		return o
	}
	public get nombre(): string {
		return this.nombreComercial ?? this.descripcion
	}
	get keyFamilia() {
		return "f" + this.id
	}
}
