import { Pipe, PipeTransform } from '@angular/core';
import { Descriptivo } from '../common/model/Descriptivo';
import { StringUtils } from '../common/utils/string-utils';
@Pipe({ name: 'descriptivoPipe' })
export class DescriptivoPipe implements PipeTransform {
    transform(opciones: Descriptivo[], buscado: string = '') {

        return opciones ? opciones.filter(o => !buscado ||
            StringUtils.getSinTildes(o.codigo).includes(StringUtils.getSinTildes(buscado)) ||
            StringUtils.getSinTildes(o.descripcion).includes(StringUtils.getSinTildes(buscado))) : [];
    }
}