import { PuntoDeVentaService } from "./../../../services/punto-de-venta.service"

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { CanalComunicacion } from "src/app/model/CanalComunicacion"
import { FiltroEmailTemplate } from "src/app/model/FiltroEmailTemplate"
import { CanalComunicacionService } from "src/app/services/canal-comunicacion.service"

@Component({
	selector: "listado-canal-comunicacion",
	templateUrl: "listado-canal-comunicacion.component.html",
	styleUrls: ["listado-canal-comunicacion.component.less"]
})
export class ListadoCanalComunicacionComponent extends SessionComponent implements OnInit, OnDestroy {
	@Input()
	public guardarFiltro: boolean = true
	@Input()
	public ocultarCabecera: boolean = false
	@Input()
	public editable: boolean = true
	@Input()
	public readonly: boolean = false
	@Input()
	public modoSeleccion: "single" | "multiple" | "none" = "none"

	@Output()
	public onSelect: EventEmitter<CanalComunicacion[]> = new EventEmitter()
	public filtro = new FiltroEmailTemplate("canal-comunicacion_lista", 1, 10, "id", 0, this.guardarFiltro)

	public columns = []
	constructor(messagesService: MessagesService, public service: CanalComunicacionService, public puntoDeVentaService: PuntoDeVentaService) {
		super(messagesService)
		console.log(JSON.stringify(this.columns))
	}
	ngOnInit() {
		this.columns = [
			{
				field: "id",
				header: "#",
				class: "id-column",
				role: "text-tabla",
				sortable: true,
				order: 1
			},
			{
				field: "tipo",
				header: "Tipo de Canal",
				role: "text-tabla",
				sortable: true,
				order: 2
			},
			{
				field: "puntoDeVenta",
				header: "Punto de Venta",
				role: "descriptivo-tabla",
				order: 3
			},
			{
				field: "descripcion",
				header: "Descripción",
				role: "text-tabla",
				sortable: true,
				order: 4
			},
			{
				field: "habilitado",
				header: "Habilitado",
				class: "is-boolean",
				role: "boolean-tabla",
				sortable: false,
				order: 5
			},
			{
				field: "esSistema",
				header: "Es Sistema",
				class: "is-boolean",
				role: "boolean-tabla",
				sortable: true,
				order: 6
			},
			{
				field: "esDefault",
				header: "Es Default",
				class: "is-boolean",
				role: "boolean-tabla",
				sortable: true,
				order: 7
			}
		]
	}

	public updateSeleccion(seleccion: any) {
		if (seleccion?.length || seleccion["id"]) {
			this.onSelect.emit(seleccion?.length ? seleccion : [seleccion])
		}
	}
	ngOnDestroy() {}
}
