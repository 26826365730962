<div class="listado-insumo content">
	<model-list
		[service]="service"
		[modelName]="'insumo'"
		[columns]="columns"
		[title]="'INSUMO_TITLE' | translate"
		[(listado)]="listado"
		[isLazy]="false"
		[filtro]="filtro"
	>
	</model-list>
</div>
