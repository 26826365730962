<div class="gestor-bloqueo-fecha content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'BLOQUEO-FECHA' | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		(onCancelar)="handleCancelar($event)"
		[form]="BloqueoFechaForm"
		[hideTitle]="hideTitle"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #BloqueoFechaForm="ngForm" id="BloqueoFechaform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-5">
						<div>
							<span class="p-float-label">
								<p-calendar
									[(ngModel)]="item.fecha"
									[showIcon]="true"
									#fecha="ngModel"
									name="fecha"
									dateFormat="dd/mm/yy"
									appendTo="body"
								></p-calendar>
								<label>{{ "FECHA" | translate }}</label>
								<small *ngIf="fecha.errors">{{ getErrorMessage(fecha) }}</small>
							</span>
						</div>
					</div>
					<div class="col-12 lg:col-8">
						<span class="p-float-label field">
							<input
								pInputText
								placeholder="{{ 'MOTIVO_PH' | translate }}"
								[(ngModel)]="item.motivo"
								tooltipPosition="bottom"
								pTooltip="{{ 'MOTIVO_TOOLTIP' | translate }}"
								type="text"
								#motivo="ngModel"
								name="motivo"
							/><label>{{ "MOTIVO" | translate }}</label>
							<small *ngIf="getErrorMessage(motivo)">
								{{ getErrorMessage(motivo) }}
							</small>
						</span>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
