<div class="listado-cliente content">
	<model-list
		[service]="service"
		[modelName]="'cliente'"
		[columns]="columns"
		[title]="'CLIENTE_TITLE' | translate"
		[(listado)]="listado"
		[isLazy]="true"
		[filtro]="filtro"
		[footer]="footerTemplate"
		[getCount]="getCount"
		[getData]="getData"
		[acciones]="acciones"
		[download]="esAdministrador"
		[skipedFieldsDownload]="skipFields"
		[filterContent]="filterTemplate"
		[permiteCargaMasiva]="true"
	>
		<ng-template #filterTemplate>
			<div class="grid small">
				<div class="col-12">
					<div class="p-inputgroup">
						<span class="p-float-label">
							<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
							<label>{{ "BUSCAR" | translate }}...</label>
						</span>
						<button type="button" pButton icon="pi pi-search"></button>
					</div>
				</div>
				<div class="col-12">
					<parametrico-selector
						[tipoParametro]="'tipocliente'"
						[(ngModel)]="filtro.tipoCliente"
						name="tipoCliente"
						[titulo]="'TIPOCLIENTE' | translate"
					>
					</parametrico-selector>
				</div>
				<div class="col-12 lg:col-4 p-fluid checkbox-container">
					<p-checkbox
						label="{{ 'Ver solo con Deuda' | translate }}"
						tooltipPosition="bottom"
						pTooltip="{{ 'Ver solo clientes deudores' | translate }}"
						binary="true"
						[(ngModel)]="filtro.soloImpagas"
					></p-checkbox>
				</div>
				<div class="col-12 lg:col-4 p-fluid checkbox-container">
					<p-checkbox
						label="{{ 'Ver archivados' | translate }}"
						tooltipPosition="bottom"
						pTooltip="{{ 'Ver clientes inactivos' | translate }}"
						binary="true"
						[(ngModel)]="filtro.verArchivados"
					></p-checkbox>
				</div>
			</div>
		</ng-template>
		<ng-template #footerTemplate>
			<tr class="totales">
				<td colspan="5" class="text-right total">
					<b>{{ "TOTAL" | translate }}</b>
				</td>
				<td class="text-right">{{ totales?.totalConsumido | currencyCustom }}</td>
				<td class="text-right">{{ totales?.totalSaldo | currencyCustom }}</td>
				<td></td>
			</tr>
		</ng-template>
	</model-list>
</div>
<ng-template #datosClienteTemplate let-data let-col="col">
	<div class="grid p-aling-center grid-nogutter">
		<div class="col">
			<div class="grid p-aling-center grid-nogutter">
				<div class="col-12">
					<div class="grid p-aling-center">
						<div class="col-10">
							<span>{{ data?.nombre }}</span>
						</div>
						<div class="col-2 nacionalidad" *ngIf="data?.nacionalidad">
							<profile-badge [url]="data?.bandera" class="small-badge"> </profile-badge>
						</div>
					</div>
				</div>
				<div class="col-12" *ngIf="data?.tipoCliente">
					<span class="small">
						{{ data?.tipoCliente?.descripcion }}
					</span>
				</div>
			</div>
		</div>
		<div class="col-fixed w-2rem text-center" *ngIf="!data?.activo">
			<i class="fa fa-ban error" style="margin-top: 50%"></i>
		</div>
	</div>
</ng-template>
<ng-template #contactos let-data let-col="col">
	<span class="w-15rem overflow block">
		<contactos [data]="data"></contactos>
	</span>
</ng-template>
<p-dialog [(visible)]="verEnvioDeuda" [modal]="true" [style]="{ width: '60vw' }" header="{{ 'ENVIAR_DEUDA' | translate }}">
	<form #MailForm="ngForm" id="MailForm">
		<selector-email [(ngModel)]="destinatario" name="d" #d="ngModel"></selector-email>
	</form>
	<p-footer>
		<button pButton (click)="verEnvioDeuda = false" label="{{ 'CANCELAR' | translate }}"></button>
		<button pButton cclass="p-btn-success" (click)="confirmarEnvioDeuda()" [disabled]="MailForm.invalid" label="{{ 'ENVIAR' | translate }}"></button>
	</p-footer>
</p-dialog>
