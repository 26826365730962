import { Filtro } from 'src/app/common/model/Filtro';
export class FiltroUsuario extends Filtro{

    private _soloActivos: boolean = true;
    public get soloActivos(): boolean {
        return this._soloActivos;
    }
    public set soloActivos(v: boolean) {
        this._soloActivos = v;
        this.update(true);
    }
    
    constructor(name) {
        super(name,
            {
                soloActivos: "Solo ativos",
            }, 0, 20, "id", 1, true);
    }
    public clean() {
        super.clean(false);
        this.patchValue({
            soloActivos: true
        })
    }

    public patchValue(v: any) {
        super.patchValue(v, false);
        this._soloActivos = v.soloActivos !=null ? v.soloActivos : true;
    }

    public apply(d: any[]) {
        return super.apply(d).filter(u =>
            !this.soloActivos || u.activo
        )
    }
}