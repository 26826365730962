import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Moneda } from "src/app/model/Moneda"
import { Usuario } from "../common/model/Usuario"
import { CajaCuentaContable } from "./CajaCuentaContable"
import { CambioEstadoCajaResumen } from "./CambioEstadoCajaResumen"

export class Caja extends Descriptivo {
	static readonly CODIGO_COMPENSACION_SALDO: string = "CC"
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public cuentas: CajaCuentaContable[] = [],
		public usuariosAutorizados: Descriptivo[] = [],
		public ultimoCambioEstado?: CambioEstadoCajaResumen,
		public estaCerrada: boolean = false,
		public moneda?: Moneda,
		public habilitada: boolean = true,
		public esSistema: boolean = false
	) {
		super(codigo, descripcion, id)
	}
	public get label() {
		return this.moneda ? this.moneda?.codigo + " " + this.descripcion : this.descripcion
	}
	public static fromData(data: any): Caja {
		if (!data) return null
		const o: Caja = new Caja(
			data.id,
			data.codigo,
			data.descripcion,
			data.cuentas ? data.cuentas.map((c) => CajaCuentaContable.fromData(c)) : [],
			data.usuariosAutorizados ? data.usuariosAutorizados.map((c) => Usuario.fromData(c)) : [],
			CambioEstadoCajaResumen.fromData(data.ultimoCambioEstado),
			data.estaCerrada,
			Moneda.fromData(data.moneda),
			data.habilitada,
			data.esSistema
		)
		o.version = data.version
		return o
	}
	tieneUsuario(usuario: Usuario): boolean {
		return this.usuariosAutorizados.some((u) => u.id === usuario.id)
	}
	get usuariosAutorizadosDesc() {
		return this.usuariosAutorizados.map((u) => u.descripcion)
	}

	get cuentasDesc() {
		return this.cuentas.map((u) => u.cuenta.descripcion)
	}
}
