import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { PlantillaPregunta } from 'src/app/model/PlantillaPregunta';
import { DescriptivosService } from 'src/app/common/services/descriptivos.service';
import { LoadingService } from './../common/services/loading-data-service.service';
@Injectable({
  providedIn: 'root',
})
export class PlantillaPreguntaService extends DescriptivosService<PlantillaPregunta>{
    public baseName(): string {
        return "plantilla-pregunta";
    }
    public parseToEnt(data: any): PlantillaPregunta {
        return PlantillaPregunta.fromData(data);
    }
    public newEnt(): PlantillaPregunta {
        return new PlantillaPregunta();
    }

 public actualizarDescripcion(data: PlantillaPregunta, customLoading?: LoadingService) {
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        var p = this.http.put(this.getBaseURL("actualizar-descripcion"), data).toPromise();
        p.then(r => {
            // this.loadData(customLoading);
            if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
        });
        return p
    };


}