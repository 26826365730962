<div class="listado-tipo-puesto content">
    <div class="grid">
        <div class="col-12">
            <model-list [service]="service" [modelName]="'TIPO-PUESTO' | translate" [columns]="columns"
                [title]="'TIPO-PUESTO_PARAM_TITULO' | translate" [(listado)]="listado" [isLazy]="true" [filtro]="filtro"
                [handler]="handler" (onEliminar)="refresh($event)" [acciones]="acciones">

            </model-list>
        </div>
    </div>
</div>
<p-dialog header="" [(visible)]="editandoParametro" appendTo="body" [modal]="true" #dialDetail
    [styleClass]="'no-header gestor-dialog'" (onShow)="onDialogShow($event,dialDetail)">
    <gestor-tipo-puesto [item]="itemEditado" (onGuardado)="refresh($event)" (onCancelado)="onCancelar()"
        [goBack]="false" [isModal]="true"></gestor-tipo-puesto>
</p-dialog>