import { Descriptivo } from "src/app/common/model/Descriptivo"

export class ProveedorCabecera {
	public searchStr
	public get whatsapp(): string {
		return this.telefono?.replace(/[^0-9]/g, "")
	}
	constructor(
		public id?: number,
		public nombre?: string,
		public razonSocial?: string,
		public tipoProveedor?: Descriptivo,
		public numeroDocumento?: string,
		public email?: string,
		public telefono?: string,
		public comentario?: string,
		public activo?: boolean,
		public iva?: Descriptivo,
		public imagenUrl?: string,
		public saldo?: number,
		public habilitado: boolean = true,
		public version?: number,
		public peso?: number
	) {
		this.searchStr = [this.id, this.descripcion, this.razonSocial, this.numeroDocumento, this.email].join("_")
	}
	public static fromData(data: any): ProveedorCabecera {
		if (!data) return null
		const o: ProveedorCabecera = new ProveedorCabecera(
			data.id,
			data.nombre,
			data.razonSocial,
			Descriptivo.fromData(data.tipoProveedor),
			data.numeroDocumento,
			data.email,
			data.telefono,
			data.comentario,
			data.activo,
			Descriptivo.fromData(data.iva),
			data.imagenUrl,
			data.saldo,
			data.habilitado
		)
		o.peso = data?.peso != null ? data?.peso : 9999
		o.version = data.version
		return o
	}
	public get imagen() {
		return this.imagenUrl
	}
	public get descripcion() {
		return this.nombre ? this.nombre : this.razonSocial
	}
	public get esPrestador() {
		return this.tipoProveedor.codigo == "SE" || this.tipoProveedor.codigo == "ST" || this.tipoProveedor.codigo == "SL"
	}
}
