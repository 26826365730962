import { Injectable } from "@angular/core"
import { CanDeactivate } from "@angular/router"
import { CheckModificado } from "../common/components/CheckModificado"
import { TranslateService } from "../common/services/translate.service"
import { StateManagementService } from "../services/StateManagementService.service"
@Injectable({
	providedIn: "root"
})
export class UnsavedChangesGuard implements CanDeactivate<CheckModificado> {
	private warning: string
	constructor(private stateService: StateManagementService, private translateService: TranslateService) {
		this.warning = this.translateService.get("WARNING_UNSAVED_CHANGES")
	}
	canDeactivate(component: CheckModificado): boolean {
		if (this.stateService.hasStateChanged()) {
			return confirm(this.warning)
		}
		return true
	}
}
