import { AdminAuthGuard } from './AdminAuthGuard';
import { Injectable } from "@angular/core";
@Injectable()
export class RestriccionesAG extends AdminAuthGuard {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_RESTRICCIONES"]) };



}