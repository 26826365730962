import { round } from "./../../../common/utils/MathUtils"
import { EstadoDocumentoService } from "./../../../services/estado-documento.service"

import { formatCurrency } from "@angular/common"
import { Component, Input, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import ChartDataLabels from "chartjs-plugin-datalabels"
import * as moment from "moment"
import { filter } from "rxjs"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { OrdenPagoResumen } from "src/app/model/OrdenPagoResumen"
import { COLORS, LocalizacionEntorno, MonedaEntorno } from "./../../../app.module"
import { FiltroFecha } from "./../../../common/model/FiltroFecha"
import { MessagesService } from "./../../../common/services/messages-data-service.service"
import { Cobranza } from "./../../../model/Cobranza"
import { FiltroComprobante } from "./../../../model/FiltroComprobantes"
import { CobranzaService } from "./../../../services/cobranza.service"
import { OrdenPagoResumenService } from "./../../../services/orden-pago-resumen.service"
import { ReporteService } from "./../../../services/reportes.service"
import { IngresosEgresos } from "./IngresosEgresos"

@Component({
	selector: "reporte-ingresos-egresos",
	styleUrls: ["reporte-ingresos-egresos.component.less"],
	templateUrl: "reporte-ingresos-egresos.component.html"
})
export class ReporteIngresosEgresosComponent extends SessionComponent implements OnInit {
	public filtro: FiltroFecha
	public dataBars = {}
	public plugins = ChartDataLabels
	public data: IngresosEgresos[] = []
	public cobranzas: Cobranza[] = []
	public ops: OrdenPagoResumen[] = []
	public showDetails = false
	public dataOps = {}
	public dataCobranzas = {}
	public filtroComp: FiltroComprobante
	public filtroFact: FiltroFecha
	public totalIngresos: number = 0
	public promedioIngresos: number = 0
	public promedioEgresos: number = 0
	public promedioMargen: number = 0
	public totalEgresos: number = 0

	private _fechaDesde: Date
	public get fechaDesde(): Date {
		return this._fechaDesde
	}
	@Input()
	public set fechaDesde(v: Date) {
		this._fechaDesde = v
	}

	public optionsBar = {
		responsive: true,

		animation: {
			animateScale: true,
			animateRotate: true
		},
		onClick: (e) => {
			console.log(e)
			const myChart = e.chart
			const points = e.chart.getElementsAtEventForMode(e, "nearest", { intersect: true }, true)

			if (points.length) {
				const firstPoint = points[0]
				const label = myChart.data.labels[firstPoint.index]
				const totalIngresos = myChart.data.datasets[0].data[firstPoint.index]
				const totalEgresos = myChart.data.datasets[1].data[firstPoint.index]
				const periodo: Date = moment(label[0], "MMM yyyyy").toDate()
				this.getDetalle(periodo, totalIngresos || 1, totalEgresos || 1)
			}
		},
		plugins: {
			legend: {
				position: "bottom"
			},
			datalabels: {
				display: "auto",
				align: "end",
				anchor: "end",
				formatter: (value, ctx) => {
					let percentage = formatCurrency(value, LocalizacionEntorno(), MonedaEntorno().simbolo, MonedaEntorno().codigo)
					return percentage
				}
			}
		}
	}
	formatterPie = (value, ctx) => {
		let percentage = value.toFixed(2) + "%"

		return percentage
	}
	public optionsPie = {
		responsive: true,
		animation: {
			animateScale: true,
			animateRotate: true
		},
		plugins: {
			legend: {
				position: "right"
			},
			datalabels: {
				display: "auto",
				formatter: this.formatterPie,
				color: "#fff"
			}
		}
	}
	private estadosActivos = []
	public queryProductos
	constructor(
		messagesService: MessagesService,
		private router: Router,
		private reporteService: ReporteService,
		private cobranzasService: CobranzaService,
		private estadoDocumentoSerivce: EstadoDocumentoService,
		private opService: OrdenPagoResumenService
	) {
		super(messagesService)
	}
	getDetalle = (periodo: Date, totalIngresos = 1, totalEgresos = 1) => {
		this.showDetails = true
		this.filtroFact = new FiltroFecha(null)
		this.filtroFact.fechaDesde = moment(periodo).startOf("month").toDate()
		this.filtroFact.fechaHasta = moment(periodo).endOf("month").toDate()
		this.filtroComp = new FiltroComprobante(null, 0, 10000)
		this.filtroComp.estados = this.estadosActivos
		this.filtroComp.fechaDesde = this.filtroFact.fechaDesde
		this.filtroComp.fechaHasta = this.filtroFact.fechaHasta
		this.queryProductos = {
			desde: moment(this.filtroComp.fechaDesde).format("DDMMYYYY"),
			hasta: moment(this.filtroComp.fechaHasta || new Date()).format("DDMMYYYY")
		}
		this.cobranzasService.getResumen(this.filtroComp).then((r) => {
			this.cobranzas = r.filter((c) => c.total != 0)
			const data = this.cobranzas.reduce((sum, value) => {
				const key = value.tipoCliente.codigo == "PART" ? "Particulares" : value.cliente.descripcion
				if (!sum[key]) sum[key] = 0
				sum[key] += value.total
				return sum
			}, {})
			const valores = Object.keys(data)
				.filter((v) => data[v] != 0)
				.map((k) => {
					return { key: k, val: data[k] }
				})
				.sort((a, b) => (a.val >= b.val ? 1 : -1))
			this.dataCobranzas = {
				labels: valores.map((v) => v.key),
				datasets: [
					{
						data: valores.map((k) => (k.val / totalIngresos) * 100),
						extended: valores.map((k) => k.val),
						backgroundColor: COLORS
					}
				]
			}
		})
		this.opService.getAll(this.filtroFact).then((r) => {
			this.ops = r
			const data = this.ops.reduce((sum, value) => {
				if (!sum[value.tipoProveedor.descripcion]) sum[value.tipoProveedor.descripcion] = 0
				sum[value.tipoProveedor.descripcion] += value.importe
				return sum
			}, {})
			const valores = Object.keys(data)
				.filter((v) => data[v] != 0)
				.map((k) => {
					return { key: k, val: data[k] }
				})
				.sort((a, b) => (a.val >= b.val ? 1 : -1))
			this.dataOps = {
				labels: valores.map((v) => v.key),
				datasets: [
					{
						data: valores.map((k) => (k.val / totalEgresos) * 100),
						extended: valores.map((k) => k.val),
						backgroundColor: COLORS
					}
				]
			}
		})
	}
	ngOnInit() {
		this.estadoDocumentoSerivce.getActivos().then((r) => {
			this.estadosActivos = r
		})
		this.filtro = new FiltroFecha("ingresos-egresos")
		this.filtro.fechaDesde = this._fechaDesde || moment().startOf("month").subtract(3, "month").toDate()
		this.filtro.fechaHasta = moment().endOf("month").toDate()
		this.filtro.dataChange.pipe(filter((d) => d != undefined)).subscribe((d) => {
			this.cobranzas = []
			this.ops = []
			this.showDetails = false
			this.queryProductos = null
			this.reporteService.getIngresosEgresos(d).then((r) => {
				this.data = r
				this.totalEgresos = 0
				this.totalIngresos = 0
				this.data.forEach((d) => {
					this.totalEgresos += d.egresos
					this.totalIngresos += d.ingresos
				})

				const labels = this.data.map((d) => [
					moment(d.periodo, "MM yyyy").format("MMM yyyy"),
					formatCurrency(d.ingresos - d.egresos, LocalizacionEntorno(), MonedaEntorno().simbolo, MonedaEntorno().codigo)
				])
				this.dataBars = {
					labels: labels,
					datasets: [
						{
							label: "Ingresos",
							data: this.data.map((d) => d.ingresos),
							backgroundColor: COLORS[0]
						},
						{
							label: "Egresos",
							data: this.data.map((d) => d.egresos),
							backgroundColor: COLORS[4]
						}
					]
				}
				const meses = labels.length
				this.promedioIngresos = round(this.totalIngresos / meses)
				this.promedioEgresos = round(this.totalEgresos / meses)
				this.promedioMargen = round(this.data.map((d) => d.ingresos - d.egresos).reduce((a, b) => a + b, 0) / meses)
			})
		})
	}
}
