<div class="grid">
	<div class="col-fix seleccionar-todos" *ngIf="!readonly">
		<p-checkbox
			[(ngModel)]="allSelected"
			binary="true"
			(onChange)="updateSelection($event)"
			ngModelOptions="{standalone:true}"
			[disabled]="!options?.length || readonly"
			tooltipPosition="bottom"
			pTooltip="{{ 'SELECCIONAR_TODOS' | translate }}"
		>
		</p-checkbox>
	</div>
	<div class="col">
		<span appearance="outline" floatLabel="always">
			<label>{{ header | translate }}</label>
			<div class="grid">
				<div class="{{ checkboxClass }}" *ngFor="let r of options; let i = index">
					<p-checkbox [value]="r" [label]="r.label" [(ngModel)]="selected" ngModelOptions="{standalone:true}" [disabled]="readonly"> </p-checkbox>
				</div>
			</div>
		</span>
	</div>
</div>
