import { Injectable, OnDestroy } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { NavigationExtras, Router } from "@angular/router"
import * as moment from "moment"
import { Message } from "primeng/api"
import { Observable, Subscription } from "rxjs"
import { filter } from "rxjs/operators"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { ConfiguracionEntorno } from "src/app/model/ConfiguracionEntorno"
import { ConfiguracionEntornoService } from "src/app/services/configuracion-entorno.service"
import { environment } from "src/environments/environment"
import { AuthService } from "../../services/auth.service"
import { Usuario } from "../model/Usuario"
import { FilterService } from "../services/filter-service"
import { GlobalInjector } from "./../GlobalInjector"
import { LoadingService } from "./../services/loading-data-service.service"
import { MessagesService } from "./../services/messages-data-service.service"
import { TranslateService } from "./../services/translate.service"
import { ErrorHandler } from "./../utils/ErrorsHandler"

import { MonedaEntorno } from "src/app/app.module"
import { Moneda } from "src/app/model/Moneda"
import { StateManagementService } from "src/app/services/StateManagementService.service"
import { HelpService } from "src/app/services/help.service"
import packageJson from "../../../../package.json"
import { CheckModificado } from "./CheckModificado"
@Injectable()
export abstract class SessionComponent implements OnDestroy, CheckModificado {
	protected subs: Subscription[] = []
	public yearRangeNacimiento: string
	public environment = environment
	public todos: Descriptivo = Descriptivo.TODOS()
	public finalizado = false
	public editable = false
	private loadingService: LoadingService
	protected authService: AuthService

	public version: string
	public permiteEdicion: boolean = true

	public now: Date = moment().startOf("day").toDate()
	public prevView: string
	public esArgentina: boolean = true

	private _isLoggedIn: boolean = false
	public get isLoggedIn(): boolean {
		return this._isLoggedIn
	}
	public set isLoggedIn(v: boolean) {
		this._isLoggedIn = v
	}

	locale: any[] = []
	calendarioLocale: any
	get tieneAyuda(): boolean {
		return false
	}
	private authSub: Subscription
	private routerNav: Router
	public fitlerServices: FilterService
	private translateSub: Subscription
	public translateService: TranslateService
	public customLoadingService: LoadingService = new LoadingService()
	public configuracionEntornoService: ConfiguracionEntornoService
	protected errorHandlerUtils: ErrorHandler
	protected configuracionEntorno: ConfiguracionEntorno
	private changeManager: StateManagementService
	public helpService: HelpService
	constructor(public messagesService?: MessagesService, public currentView?: string) {
		this.loadingService = LoadingService.injector.get(LoadingService)
		this.authService = GlobalInjector.InjectorInstance.get<AuthService>(AuthService)
		this.routerNav = GlobalInjector.InjectorInstance.get<Router>(Router)
		this.translateService = GlobalInjector.InjectorInstance.get<TranslateService>(TranslateService)
		this.errorHandlerUtils = GlobalInjector.InjectorInstance.get<ErrorHandler>(ErrorHandler)
		this.configuracionEntornoService = GlobalInjector.InjectorInstance.get<ConfiguracionEntornoService>(ConfiguracionEntornoService)
		this.translateService.upateData.pipe(filter((r) => r != null)).subscribe((r) => {})
		this.fitlerServices = GlobalInjector.InjectorInstance.get<FilterService>(FilterService)
		this.helpService = GlobalInjector.InjectorInstance.get<HelpService>(HelpService)
		const $this = this

		this.translateSub = this.translateService.upateData.subscribe((t) => {
			$this.updateTexts()
		})
		this.authService
			.getAutenticateState()
			.pipe(filter((u) => u === AuthService.LOGUEADO))
			.subscribe((u) => {
				this.isLoggedIn = u != undefined
				this.configuracionEntornoService.getConfiguracionObs.subscribe((r) => {
					$this.configuracionEntorno = r
					$this.esArgentina = r?.pais?.codigo === "AR"
				})
			})
		this.version = packageJson.version
		this.yearRangeNacimiento = this.getYearRange(65, 15)
		if (!this.messagesService) this.messagesService = GlobalInjector.InjectorInstance.get(MessagesService)
	}
	hayCambios(): boolean {
		return false
	}
	ngOnDestroy(): void {
		if (this.authSub) this.authSub.unsubscribe()
		if (this.translateSub) this.translateSub.unsubscribe()
		if (this.subs)
			this.subs.forEach((s) => {
				if (s) s.unsubscribe()
			})
	}
	public compareDescriptivos(v1: Descriptivo, v2: Descriptivo) {
		return !v1 || !v2 ? false : v1.id && v2.id ? v1.id == v2.id : v1.codigo == v2.codigo
	}

	public getUsersLocale(defaultValue: string): string {
		/* if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return defaultValue;
        }
        const wn = window.navigator as any;
        let lang = wn.languages ? wn.languages[0] : defaultValue;
        lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;*/
		return "es-AR"
	}

	public getYearRange(from: number, to: number) {
		return moment().startOf("year").subtract(from, "years").year() + ":" + moment().startOf("year").subtract(to, "years").year()
	}
	public onFocus(event: any) {
		event.srcElement.select()
	}

	addLoadingCount() {
		this.loadingService.addLoadingCount()
	}

	susLoadingCount() {
		this.loadingService.susLoadingCount()
	}

	isUserLogged = (): boolean => {
		return this.isLoggedIn
	}
	public get esExterno() {
		return this.usuario?.esExterno
	}
	public get unidadPeso() {
		return "KG"
	}
	public tieneRol(rol: string) {
		return this.authService.tieneRol(rol)
	}
	get esAdministrador() {
		return this.authService.esAdministrador
	}
	get esClienteExterno() {
		return this.authService.esClienteExterno
	}

	get esJefeAdministracion() {
		return this.authService.esJefeAdministracion || this.authService.esAdministrador
	}

	get esSoloVisualizador() {
		return this.authService.esSoloVisualizador
	}

	getCurrentUser(): Observable<Usuario> {
		return this.authService.getUser()
	}
	get monedaEntorno() {
		return MonedaEntorno()
	}
	public monedaExtranjera(moneda: Moneda) {
		return this.configuracionEntorno?.moneda?.codigo != moneda?.codigo
	}
	getUserFullName(): string {
		return this.authService.getUserFullName()
	}
	errorHandler = (error, msgs?: Message[]) => {
		this.messagesService.errorHandler(error, msgs)
	}
	protected clearCredentials(): Promise<any> {
		return this.authService.logout()
	}
	public get lenguaje() {
		return this.translateService.idioma
	}
	public success(mensaje?: string, titulo?: string) {
		this.messagesService.success(mensaje, titulo)
		return true
	}
	public error(mensaje?: string, titulo?: string) {
		this.messagesService.error(mensaje, titulo)
		return false
	}
	public info(mensaje?: string, titulo?: string) {
		this.messagesService.info(mensaje, titulo)
	}
	public warning(mensaje?: string, titulo?: string) {
		this.messagesService.warning(mensaje, titulo)
	}

	set messageLife(val: number) {
		this.messagesService.life = val
	}

	public goTo(vista: string, queryParams?: NavigationExtras) {
		this.routerNav.navigate([vista], { queryParams })
	}

	public goToEnt(event, vista: string, id: number) {
		event?.stopPropagation && event.stopPropagation()
		this.routerNav.navigate([vista + "/vista"], { queryParams: { id } })
	}

	public volver() {}
	get usuario(): Usuario {
		return this.authService.getCurrentUser
	}
	public openLink(event, url, target?) {
		if (!url) return
		event && event.stopPropagation()
		window.open(url, target || "_blank")
	}
	isTablet() {
		const width = window.innerWidth
		return width <= 1024 && width > 640
	}

	isDesktop() {
		return window.innerWidth > 1024
	}

	isMobile() {
		return window.innerWidth <= 640
	}

	public updateTexts() {}

	public onDialogShow(event, dialog) {
		if (this.isMobile()) {
			dialog.maximized = true
		}
	}
	getErrorMessage(control: AbstractControl) {
		if (control?.errors) {
			return this.errorHandlerUtils.getErrorMessage(control)
		}
	}
	public hasHelp = (modelName: string) => {
		return this.helpService.hasHelp(modelName) != undefined
	}
	public goToHelp(modelName: string) {
		this.helpService.goToHelp(modelName)
	}
	get timezone() {
		return environment.timezone
	}
}
