import { Descriptivo } from "src/app/common/model/Descriptivo"
import { TipoIVA } from "src/app/model/TipoIVA"
import { round } from "./../common/utils/MathUtils"
import { Auditable } from "./Auditable"
import { SubcategoriaImponible } from "./SubcategoriaImponible"

export class ItemComprobante extends Auditable {
	clonar(): ItemComprobante {
		let o = new ItemComprobante()
		Object.assign(o, this)
		o.id = null
		o.version = null
		return o
	}
	public get esSoloDisplay() {
		return this.esSubcategoria
	}
	public subcategorias: ItemComprobante[] = []
	public esSubcategoria: boolean = false
	public impuestosSubcategoria: number = 0
	constructor(
		public id?: number,
		public producto?: Descriptivo,
		public descripcion?: string,
		public tipoIVA?: TipoIVA,
		public precioUnitario: number = 0,
		public precioUnitarioBruto: number = 0,
		public cantidad: number = 1,
		public descuento: number = 0,
		public impuestos: number = 0,
		public valorDescuento: number = 0,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
		public version?: number,
		public proporcion?: number,
		public referenciaPadre?: string,
		public subcategoria?: SubcategoriaImponible,
		public esSubcategorizado: boolean = false
	) {
		super(lastModifiedBy, lastModifiedDate)
	}
	public static fromData(data: any): ItemComprobante {
		if (!data) return null
		const o: ItemComprobante = new ItemComprobante(
			data.id,
			Descriptivo.fromData(data.producto),
			data.descripcion,
			TipoIVA.fromData(data.tipoIVA),
			data.precioUnitario,
			data.precioUnitarioBruto,
			data.cantidad,
			data.descuento,
			data.impuestos,
			data.valorDescuento
		)
		o.esSubcategoria = data.esSubcategoria
		o.referenciaPadre = data.refreciaPadre
		o.subcategoria = SubcategoriaImponible.fromData(data.subcategoria)
		o.version = data.version
		o.esSubcategorizado = data.esSubcategorizado
		return o
	}
	public get totalBruto() {
		if (this.esSubcategorizado) return this.subtotalBruto
		const vIva = (this.tipoIVA?.valorIva || 0) / 100
		return round(this.subtotalBruto * (1 + vIva) * (1 + this.descuento / 100), 2)
	}
	public get totalNeto() {
		if (this.esSubcategorizado) return round(this.subtotalBruto * (1 + this.descuento / 100) + this.impuestos * this.cantidad)
		const vIva = (this.tipoIVA?.valorIva || 0) / 100
		const impuestos = this.impuestos
		return round(this.precioUnitarioBruto * (1 + this.descuento / 100) * this.cantidad * (1 + vIva), 2)
	}
	public get subtotalBruto() {
		return this.cantidad * this.precioUnitarioBruto
	}
	public get subtotalNeto() {
		return this.cantidad * this.precioUnitario
	}
	public get multiplicadorIVA() {
		return (this.tipoIVA?.valorIva || 0) / 100
	}
	public get total() {
		return this.totalNeto
	}
	public get subtotal() {
		return this.subtotalBruto
	}
}
