import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { GrupoPax } from 'src/app/model/GrupoPax'
import { ItemResumenReserva } from 'src/app/model/ItemReservaResumen'
import { MessagesService } from '../../../common/services/messages-data-service.service'
import { TranslateService } from '../../../common/services/translate.service'
import { GrupoReserva } from '../../../model/GrupoReserva'
import { ItemReserva } from '../../../model/ItemReserva'
import { GrupoPaxService } from '../../../services/grupo-pax.service'
@Component({
    selector: 'grupo-itinerario',
    templateUrl: './grupo-itinerario.component.html',
    styleUrls: ['./grupo-itinerario.component.less'],
})
export class GrupoItinerarioComponent implements OnInit {
    @Input()
    public grupo: GrupoPax

    @Input()
    public readonly: boolean = false
    @Input()
    public draggable: boolean = true

    @Input()
    public error: string = ''
    @Input()
    public borrable: boolean = false

    @Input()
    public bloqueable: boolean = true

    @Input()
    public porUbicar: number = 0

    @Input()
    public mostrarPuestos: boolean = true

    @Input()
    public itemReservaActual: ItemReserva
    mostrarVoucher: boolean = false
    itemEditado: GrupoReserva

    constructor(public messagesService: MessagesService, public routerNav: Router, public translateService: TranslateService, public grupoPaxService: GrupoPaxService) {}

    @Output()
    public onBloqueoGrupo: EventEmitter<GrupoPax> = new EventEmitter<GrupoPax>()

    ngOnInit() {}
    @Input()
    public full: boolean = false
    public esSeleccionable(item: GrupoReserva) {
        if (!item) return false
        return !this.itemReservaActual || item.tieneItem(this.itemReservaActual)
    }

    public showError(mensaje?: string, titulo?: string) {
        this.messagesService.error(mensaje, titulo)
        return false
    }

    public showsuccess(mensaje?: string, titulo?: string) {
        this.messagesService.success(mensaje, titulo)
        return false
    }
    public clickItem(event, item: GrupoReserva) {
        this.routerNav.navigate(['reserva/vista'], { queryParams: { id: item.itemReserva.idReserva } })
        event.stopPropagation()
    }
    public openLink(event, type, phone) {
        event && event.stopPropagation()
        if (type == 'whatsapp') {
            if (this.isMobile()) window.open('whatsapp://send?phone=' + phone, '_blank')
            else window.open('https://api.whatsapp.com/send/?phone=' + phone, '_blank')
        } else if (type == 'phone') {
            window.open('tel:' + phone, '_blank')
        }
    }

    isMobile() {
        return window.innerWidth <= 640
    }

    verVoucher(event: Event, item: GrupoReserva) {
        event.stopPropagation()
        this.mostrarVoucher = true
        this.itemEditado = item
    }
    public completar() {
        this.grupoPaxService.completar(this.grupo).then( r => {
          this.showsuccess("Actividad finalizada");
          this.grupo.actividadCompletada = true
        })
      }
}
