import * as moment from "moment"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Filtro } from "./../../common/model/Filtro"
export class FiltroProducto extends Filtro {
	constructor(
		name: string,
		page?: number,
		size?: number,
		sortField: string = "peso",
		order: number = 1,
		guardable: boolean = true,
		localSearch: boolean = false
	) {
		super(
			name,
			{
				categorias: "Categorías",
				tipo: "Tipo de producto",
				archivados: "Ver archivados",
				cliente: "Cliente",
				fechaDesde: "Fecha desde",
				fechaHasta: "Fecha hasta",
				ordenadoPor: "Ordenado por",
				soloRapidos: "Ver solo rápidos",
				locales: "Locales",
				centros: "Centro de costo",
				tipoPrestacion: "Tipo prestación",
				productos: "Productos",
				regiones: "Regiones",
				paises: "Países"
			},
			page,
			size,
			sortField,
			order,
			guardable,
			localSearch
		)
	}
	public incluirFamilias: boolean = false
	private _categorias: Descriptivo[] = []
	private _fechaDesde: Date
	private _fechaHasta: Date
	private _cliente: Descriptivo
	private _centros: Descriptivo[] = []
	private _tiposProducto: Descriptivo[] = []
	private _soloVenta: boolean = true
	private _propios: boolean
	private tipoPrestacion: string
	private _paises: Descriptivo[] = []
	private _regiones: Descriptivo[] = []
	public get propios(): boolean {
		return this._propios
	}
	public set propios(v: boolean) {
		if (v != this._propios) {
			this._propios = v
			this.update()
		}
		if (v == true) {
			this.tipoPrestacion = "Propios"
		} else if (v == false) {
			this.tipoPrestacion = "Externos"
		} else {
			this.tipoPrestacion = "Todos"
		}
	}
	public get soloVenta(): boolean {
		return this._soloVenta
	}
	public set soloVenta(v: boolean) {
		if (v != this._soloVenta) {
			this._soloVenta = v
			this.update()
		}
	}
	public get tiposProducto(): Descriptivo[] {
		return this._tiposProducto
	}
	public set tiposProducto(v: Descriptivo[]) {
		this._tiposProducto = v
		this.update()
	}

	public set tipoProducto(v: Descriptivo) {
		this._tiposProducto = v ? [v] : []
		this.update()
	}
	public get tipoProducto() {
		return this._tiposProducto ? this._tiposProducto[0] : null
	}

	public get paises(): Descriptivo[] {
		return this._paises
	}
	public set paises(v: Descriptivo[]) {
		this._paises = v
		this.update()
	}

	public set pais(v: Descriptivo) {
		this._paises = v ? [v] : []
		this.update()
	}
	public get pais() {
		return this._paises ? this._paises[0] : null
	}

	public get regiones(): Descriptivo[] {
		return this._regiones
	}
	public set regiones(v: Descriptivo[]) {
		this._regiones = v
		this.update()
	}

	public set region(v: Descriptivo) {
		this._regiones = v ? [v] : []
		this.update()
	}
	public get region() {
		return this._regiones ? this._regiones[0] : null
	}
	public get centros(): Descriptivo[] {
		return this._centros
	}
	public set centros(v: Descriptivo[]) {
		if (v != this._centros) {
			this._centros = v
			this.update()
		}
	}
	private _locales: Descriptivo[]
	public get locales(): Descriptivo[] {
		return this._locales
	}
	public set locales(v: Descriptivo[]) {
		if (v != this._locales) {
			this._locales = v
			this.update()
		}
	}
	private _soloRapidos: boolean
	public get soloRapidos(): boolean {
		return this._soloRapidos
	}
	public set soloRapidos(v: boolean) {
		if (v != this._soloRapidos) {
			this._soloRapidos = v
			this.update()
		}
	}
	private _excluirRapidos: boolean
	public get excluirRapidos(): boolean {
		return this._excluirRapidos
	}
	public set excluirRapidos(v: boolean) {
		this._excluirRapidos = v
		this.update()
	}

	private _ordenadoPor: string = "cantidad"
	public get ordenadoPor(): string {
		return this._ordenadoPor
	}

	public set ordenadoPor(v: string) {
		this._ordenadoPor = v
		this.sortField = v
		this.update()
	}

	private _cantidad: number
	public get cantidad(): number {
		return this.size
	}
	public set cantidad(v: number) {
		this.size = v
		this.update()
	}

	public get categorias(): Descriptivo[] {
		return this._categorias
	}
	public set categorias(v: Descriptivo[]) {
		this._categorias = v
		this.update()
	}

	public get cliente(): Descriptivo {
		return this._cliente
	}
	public set cliente(v: Descriptivo) {
		this._cliente = v
		this.update()
	}

	public get tipo(): Descriptivo {
		return this._tiposProducto ? this._tiposProducto[0] : null
	}
	public set tipo(v: Descriptivo) {
		if (!this._tiposProducto) this._tiposProducto = []
		if (this._tiposProducto[0] != v) {
			this._tiposProducto = [v]
			this.update()
		}
	}

	public get fechaDesde(): Date {
		return this._fechaDesde
	}
	public set fechaDesde(v: Date) {
		this._fechaDesde = v ? moment(v).startOf("day").toDate() : null
		this.update()
	}

	public get fechaHasta(): Date {
		return this._fechaHasta
	}
	public set fechaHasta(v: Date) {
		this._fechaHasta = v ? moment(v).endOf("day").toDate() : null
		this.update()
	}
	public get verArchivados(): boolean {
		return this._verArchivados
	}
	public set verArchivados(v: boolean) {
		if (v != this._verArchivados) {
			this._verArchivados = v
			this.update()
		}
	}

	private _productos: Descriptivo[] = []
	public get productos(): Descriptivo[] {
		return this._productos
	}
	public set productos(v: Descriptivo[]) {
		this._productos = v
		this.update()
	}

	public clean() {
		super.clean(false)
		let cleanVal = {
			categoria: [],
			verArchivados: false,
			tiposProducto: [],
			cliente: null,
			fechaDesde: moment().subtract(30, "days").toDate(),
			fechaHasta: null,
			ordenadoPro: "cantidad",
			solorapidos: false,
			centros: [],
			locales: [],
			soloVenta: true,
			propios: null,
			producto: null,
			regiones: [],
			paises: []
		}
		this.patchValue(cleanVal)
	}

	public patchValue(v: any) {
		super.patchValue(v, false)
		this._searchStr = v.searchStr
		this._verArchivados = v.verArchivados
		this._tiposProducto = v.tiposProducto ? v.tiposProducto.map((d) => Descriptivo.fromData(d)) : []
		this._categorias = v.categorias ? v.categorias.map((c) => Descriptivo.fromData(c)) : []
		this._cliente = v.cliente ? Descriptivo.fromData(v.cliente) : null
		this._fechaDesde = v.fechaDesde ? new Date(v.fechaDesde) : moment().subtract(30, "days").toDate()
		this._fechaHasta = v.fechaHasta ? new Date(v.fechaHasta) : null
		this._ordenadoPor = v.ordenadoPro ? v.ordenadoPor : "cantidad"
		this._soloRapidos = v.soloRapidos
		this._centros = v.centros ? v.centros.map((c) => Descriptivo.fromData(c)) : []
		this._locales = v.locales ? v.locales.map((c) => Descriptivo.fromData(c)) : []
		this._soloVenta = v.soloVenta
		this._propios = v.propios
		this._productos = v.productos ? v.productos.map((c) => Descriptivo.fromData(c)) : []
		this._regiones = v.regiones ? v.regiones.map((c) => Descriptivo.fromData(c)) : []
		this._paises = v.paises ? v.paises.map((c) => Descriptivo.fromData(c)) : []
		this.update()
	}

	public apply(data: any[]): any[] {
		const searchLike = this.searchStr ? this.searchStr.toUpperCase().split(" ") : null
		return data.filter(
			(value) =>
				(!searchLike || !value["searchStr"] || searchLike.every((v) => value["searchStr"].toUpperCase().includes(v))) &&
				(!this.propios || value["esPropio"]) &&
				(this.verArchivados || value["habilitado"]) &&
				(!this.regiones?.length ||
					!value["regiones"] ||
					value["regiones"] == 0 ||
					value["regiones"].some((r) => this.regiones.some((f) => r.codigo === f.codigo))) &&
				(!this.paises?.length ||
					!value["regiones"] ||
					value["regiones"] == 0 ||
					value["regiones"].some((r) => this.paises.some((f) => r.pais?.codigo === f.codigo))) &&
				(!this.categorias?.length ||
					!value["categorias"] ||
					value["categorias"].length == 0 ||
					value["categorias"].some((cat) => this.categorias.some((f) => cat.codigo === f.codigo))) &&
				(!value["tipoProducto"] || !this.tiposProducto?.length || this.tiposProducto.findIndex((d) => d.codigo === value["tipoProducto"].codigo) >= 0)
		)
	}
}
