<div class="gestor-bloqueo-vehiculo content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'BloqueoVehiculo' | translate"
		[verAcciones]="false"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="BloqueoVehiculoForm"
		[readonly]="readonly"
		[hideTitle]="hideTitle"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
		[persist]="persist"
	>
		<div model-type="title" *ngIf="isModal" class="text-right modal-actions">
			<button pButton icon="fa fa-pencil" (click)="onEdit($event)" *ngIf="readonly"></button>
			<button pButton icon="fa fa-trash" (click)="delete($event)" *ngIf="item?.id"></button>
		</div>
		<div class="gestor-form" model-type="dataContent">
			<form #BloqueoVehiculoForm="ngForm" id="BloqueoVehiculoform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-6">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<p-calendar
								[showIcon]="true"
								[(ngModel)]="item.fechaDesde"
								#fechaDesde="ngModel"
								name="fechaDesde"
								[readonly]="readonly"
								dateFormat="dd/mm/yy"
								required
							></p-calendar>
							<label>{{ "FECHADESDE" | translate }}</label>
							<error-tag [model]="fechaDesde"></error-tag>
						</span>
					</div>

					<div class="col-12 lg:col-6">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<p-calendar
								[showIcon]="true"
								[(ngModel)]="item.fechaHasta"
								#fechaHasta="ngModel"
								name="fechaHasta"
								dateFormat="dd/mm/yy"
								[readonly]="readonly"
							></p-calendar>
							<label>{{ "FECHAHASTA" | translate }}</label>
							<error-tag [model]="fechaHasta"></error-tag>
						</span>
					</div>
					<div class="col-12" [ngClass]="{ 'lg:col-8': !isModal }" *ngIf="!readonly">
						<label>{{ "Motivo" | translate }}</label>
						<p-editor
							[(ngModel)]="item.motivo"
							name="cabecera"
							tabindex="10"
							[readonly]="readonly"
							required
							[style]="{ height: '100%', 'max-height': '20vh', 'min-height': '15vh', overflow: 'auto', 'background-color': 'white' }"
						>
						</p-editor>
						<!--email-editor (loaded)="editorLoaded($event)" #editor [options]="options"></email-editor-->
						<small>
							{{ getErrorMessage(motivo) }}
						</small>
					</div>
					<div>
						<div class="col-12" [ngClass]="{ 'lg:col-8': !isModal }" *ngIf="readonly">
							<span class="p-float-label field">
								<label>{{ "Motivo" | translate }}</label>
							</span>
							<p-inputText type="text" name="dummy" [ngClass]="{ readonly: readonly }"></p-inputText>
							<div [innerHtml]="item.motivo | safeHtml"></div>
						</div>
					</div>
					<div class="col-12">
						<button pButton icon="fa fa-trash" (click)="delete($event)" [label]="'ELIMINAR_BLOQUEO' | translate" *ngIf="item?.id"></button>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
