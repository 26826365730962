import { Injectable } from "@angular/core"
import { BehaviorSubject, lastValueFrom } from "rxjs"
import { share } from "rxjs/operators"
import { DescriptivosService } from "src/app/common/services/descriptivos.service"
import { Producto } from "src/app/model/Producto"
import { Parametrico } from "../common/model/Parametrico"
import { Idioma } from "../model/Idioma"
import { Paquete } from "../model/Paquete"
import { ProductoFactory } from "../model/ProductoFactory"
import { TipoProducto } from "../model/TipoProducto"
import { GlobalInjector } from "./../common/GlobalInjector"
import { Descriptivo } from "./../common/model/Descriptivo"
import { LoadingService } from "./../common/services/loading-data-service.service"
import { MessagesService } from "./../common/services/messages-data-service.service"
import { ProductoRanking } from "./../model/Producto"
import { FiltroProducto } from "./../pages/producto/FiltroProducto"
import { ProductoCabecera } from "./../pages/producto/ProductoCabecera"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
import { StorageService } from "./storage.service"
@Injectable({
	providedIn: "root"
})
export class ProductoService extends DescriptivosService<Producto> {
	public baseName(): string {
		return "producto"
	}
	public parseToEnt(data: any): Producto {
		return ProductoFactory.fromData(data)
	}
	public newEnt(): Producto {
		return new Producto()
	}
	private _dataEntity: any = {}
	private _data: ProductoCabecera[] = []
	private _familias: Paquete[] = []
	public data: BehaviorSubject<ProductoCabecera[]> = new BehaviorSubject([])
	private updating
	private storageService: StorageService
	constructor(protected novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(messServ)
		this.storageService = GlobalInjector.InjectorInstance.get(StorageService)
		this.loadData()
		this.data.subscribe((d) => {
			if (d?.length) {
				this.storageService.saveCache(this.baseName(), d)
			}
		})
		this.novedadesService.registrarObservador(this.baseName(), {
			getKey: () => this.baseName(),
			next: (n) => {
				super.getById(n.idEntidad).then((p) => {
					this.updateCache(true, p)
				})
			},
			destroy: () => {
				this.novedadesService.unregister(this.baseName(), null, null, null, this.baseName())
			}
		})
	}

	public getData(incluirFamilias: boolean = false): Promise<(ProductoCabecera | Paquete)[]> {
		if (!this.updating && !this._data) {
			return this.loadData().then((p) => {
				if (incluirFamilias) {
					return [...p, ...this._familias]
				}
				return p
			})
		} else if (this.updating) {
			return this.updating
		} else {
			return Promise.resolve(incluirFamilias ? [...this._familias, ...this._data] : this._data)
		}
	}
	private loadData() {
		if (!this.updating)
			this.updating = this.fillCabeceras(new FiltroProducto(null), new LoadingService())
				.then((r: any) => {
					this._data = [...r]
					this.data.next(this._data)
					this._data = this._data.sort(Parametrico.comparador)
					this.fillFamilias(this._data)
					return this._data
				})
				.finally(() => {
					this.updating = null
				})

		if (!this._data?.length) {
			return this.storageService
				.getCache(this.baseName())
				.then((l) => {
					if (!this._data || this._data?.length == 0) {
						if (l) {
							this._data = this.parse(l)
						} else {
							this._data = []
							this.data.next(this._data)
						}
						this._data = this._data.sort(Parametrico.comparador)
						return this._data
					}
				})
				.then((d) => {
					if (!d?.length) {
						return this.updating
					} else {
						return d
					}
				})
		} else {
			return Promise.resolve(this._data)
		}
	}
	fillFamilias(p: ProductoCabecera[]) {
		const familyMap = new Map<number, ProductoCabecera[]>()
		p.filter((a) => a.familiaProducto && !a.esPaquete()).forEach((product) => {
			if (!familyMap.has(product.familiaProducto.id)) {
				familyMap.set(product.familiaProducto.id, [])
			}
			familyMap.get(product.familiaProducto.id)!.push(product)
		})

		// Convertir el mapa en un array de objetos FamilyGroup
		const paquetes: Paquete[] = Array.from(familyMap, ([f, prods]) => {
			const familia = prods[0]?.familiaProducto
			const descripcion = familia?.descripcion
			const codigo = familia?.codigo
			const configuracionPaquete = prods.map((product) => ({
				producto: product,
				autoasignar: true,
				fullDescripcion: product?.fullDescripcion
			}))

			return Paquete.fromData({
				id: null,
				descripcion: descripcion,
				codigo: codigo,
				configuracionPaquete: configuracionPaquete,
				esPaquete: true,
				habilitado: true,
				tipoProducto: Descriptivo.fromData({ codigo: TipoProducto.FAMILIA, descripcion: "Familia" })
			})
		})
		this._familias = paquetes.sort(Parametrico.comparador)
	}
	public getAdicionables = (filter: FiltroProducto = new FiltroProducto("", 0, 100000)): Promise<ProductoCabecera[]> => {
		let $this = this
		return this.getData().then((r) => filter.apply(r.filter((p) => p.adicionable)))
	}
	private vigente(key): boolean {
		return this._dataEntity[key]
	}
	public getById(id: number, customLoading?: LoadingService, idioma: string = Idioma.DEFAULT_CODIGO): Promise<Producto> {
		const idiomaSafe = idioma ? idioma.toLowerCase() : Idioma.DEFAULT_CODIGO.toLowerCase()
		if (id === null) return Promise.resolve(null)
		if (this.vigente(id + idiomaSafe)) return Promise.resolve(ProductoFactory.fromData(this._dataEntity[id + idiomaSafe]))
		return super.getById(id, customLoading, idiomaSafe).then((p) => {
			this._dataEntity[id + idiomaSafe] = p
			return this._dataEntity[id + idiomaSafe]
		})
	}

	public getServicios = (
		filter: FiltroProducto = new FiltroProducto(""),
		customLoading?: LoadingService,
		soloHabilitados = true
	): Promise<(ProductoCabecera | Paquete)[]> => {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return this.getData(filter.incluirFamilias).then((p) => {
			if (customLoading) customLoading.susLoadingCount()
			else this.loadingService.susLoadingCount()
			return filter
				.apply(p)
				.filter(
					(prod) =>
						TipoProducto.esAdicionable(prod.tipoProducto?.codigo) && (soloHabilitados ? prod.habilitado : true) && prod.tipoProducto.codigo != "HOS"
				)
		})
	}
	public getActividades = (filter: FiltroProducto = new FiltroProducto(""), customLoading?: LoadingService): Promise<(ProductoCabecera | Paquete)[]> => {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return this.getData(filter.incluirFamilias).then((p) => {
			if (customLoading) customLoading.susLoadingCount()
			else this.loadingService.susLoadingCount()
			return filter.apply(p).filter((prod) => TipoProducto.esActividad(prod.tipoProducto?.codigo) && prod.habilitado)
		})
	}

	public getHospedajes = (filter: FiltroProducto = new FiltroProducto(""), customLoading?: LoadingService): Promise<Descriptivo[]> => {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return this.getData().then((p) => {
			if (customLoading) customLoading.susLoadingCount()
			else this.loadingService.susLoadingCount()
			return filter
				.apply(p)
				.filter((prod) => prod.tipoProducto.codigo == "HOS" && prod.habilitado)
				.map((prod) => Descriptivo.fromData(prod))
		})
	}

	public getHabilitados = (filter: FiltroProducto = new FiltroProducto(""), customLoading?: LoadingService): Promise<Descriptivo[]> => {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		filter.habilitados = true
		return this.getData().then((p) => {
			if (customLoading) customLoading.susLoadingCount()
			else this.loadingService.susLoadingCount()
			return filter.apply(p).map((prod) => Descriptivo.fromData(prod))
		})
	}
	private fillCabeceras = (filter: FiltroProducto = new FiltroProducto(""), customLoading?: LoadingService): Promise<ProductoCabecera[]> => {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL() + "cabeceras", filter.json)).then((r: any) => {
			if (customLoading) customLoading.susLoadingCount()
			else this.loadingService.susLoadingCount()
			return r.map((p) => ProductoCabecera.fromData(p)).sort(Parametrico.comparador)
		})
	}
	public getCabeceras = (filter: FiltroProducto = new FiltroProducto(""), customLoading?: LoadingService): Promise<ProductoCabecera[]> => {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return this.getData().then((p) => {
			if (customLoading) customLoading.susLoadingCount()
			else this.loadingService.susLoadingCount()
			return filter.apply(p)
		})
	}

	getRanking = (
		filtro: FiltroProducto = new FiltroProducto(null, 0, 1000, null, null, false),
		customLoading?: LoadingService
	): Promise<ProductoRanking[]> => {
		let $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("ranking"), filtro.json).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r.map((p) => ProductoRanking.fromData(p))
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	public guardarConArchivo(e: Producto, file: File, customLoading?: LoadingService): Promise<Producto> {
		const isUpdate = e.id >= 1
		if (e.icono?.picPath?.startsWith("data:")) {
			e.icono.picPath = null
			e.icono.previewPicPath = null
		}
		return super.guardarConArchivo(e, file, customLoading).then((v) => {
			return this.updateCache(isUpdate, v)
		})
	}
	public guardar(e: Producto, customLoading?: LoadingService): Promise<Producto> {
		const isUpdate = e.id >= 1
		return super.guardar(e, customLoading).then((v) => {
			return this.updateCache(isUpdate, v)
		})
	}
	private updateCache(isUpdate: boolean, v: Producto) {
		const idiomaSafe = v.codigoIdioma ? v.codigoIdioma.toLowerCase() : Idioma.DEFAULT_CODIGO.toLowerCase()
		if (!isUpdate) {
			this._data.push(ProductoCabecera.fromData(v))
			this._dataEntity[v.id + idiomaSafe] = ProductoFactory.fromData(v)
		} else {
			if (idiomaSafe == Idioma.DEFAULT_CODIGO.toLowerCase()) {
				this._data[this._data.findIndex((d) => d.id == v.id)] = ProductoCabecera.fromData(v)
				this._dataEntity[v.id + idiomaSafe] = ProductoFactory.fromData(v)
			} else {
				if (v) {
					this._data[this._data.findIndex((d) => d.id == v.id)] = ProductoCabecera.fromData(v)
					this._dataEntity[v.id + idiomaSafe] = ProductoFactory.fromData(v)
				}
			}
		}
		this._data = this._data.sort(Parametrico.comparador)
		this.data.next(this._data)
		return v
	}
}
