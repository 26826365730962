import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { Subscription } from "rxjs"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { ProviderConfiguration } from "src/app/model/ProviderConfiguration"
import { ProviderConfigurationService } from "src/app/services/provider-configuration.service"
import { SessionComponent } from "./../../../common/components/session-component.component"

import { Descriptivo } from "src/app/common/model/Descriptivo"
import { ProviderService } from "src/app/services/provider.service"

@Component({
	selector: "gestor-provider-configuration",
	templateUrl: "gestor-provider-configuration.component.html",
	styleUrls: ["gestor-provider-configuration.component.less"]
})
export class GestorProviderConfigurationComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	@Input()
	public item: ProviderConfiguration
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false

	@Input()
	public readonly: boolean = false

	@Output()
	public onGuardado: EventEmitter<ProviderConfiguration> = new EventEmitter<ProviderConfiguration>()

	@Output()
	public onCancelado = new EventEmitter()

	public providerOptions: Descriptivo[] = []

	constructor(
		messagesService: MessagesService,
		public service: ProviderConfigurationService,
		private route: ActivatedRoute,
		private router: Router,
		private confService: ConfirmationService,
		private providerService: ProviderService
	) {
		super(messagesService)
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}

	public async isValid() {
		return true
	}

	public onEdit(event) {
		if (this.item?.id) this.router.navigate(["'provider-configuration/edit"], { queryParams: { id: this.item.id } })
	}

	ngOnInit() {
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
				}
			})
		)
		if (this.route.snapshot.url.some((u) => u.path == "provider-configuration")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				if (!this.service) return
				if (id) {
					this.service.getById(id, null, this.translateService.idioma ? this.translateService.idioma : "ES").then((r) => {
						this.item = r
					})
				} else {
					if (!this.item && this.service) {
						this.item = this.service.newEnt()
					}
					this.item["codigoIdioma"] = this.translateService.idioma ? this.translateService.idioma : "ES"
				}
			})
		}

		this.providerService.getDescriptivos().then((r) => {
			this.providerOptions = r
		})
	}

	public get esEditable() {
		return this.esAdministrador
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}
}
