import { lastValueFrom } from 'rxjs';
import { GastoPuesto } from 'src/app/model/GastoPuesto';
import { LoadingService } from './../common/services/loading-data-service.service';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
@Injectable({
    providedIn:"root"
})
export class GastoPuestoService extends BaseService{

    public baseName(): string {
        return "gasto-asociado";
    }

    public desasignarGasto(gasto: GastoPuesto, customLoadingService?:LoadingService)  : Promise<GastoPuesto>{
        if(customLoadingService){customLoadingService.addLoadingCount()} else this.loadingService.addLoadingCount();
       return lastValueFrom(this.http.post(this.getBaseURL("desasignar"), gasto )).then(r=>{
        return GastoPuesto.fromData(r);
       }).finally(()=>{
        if(customLoadingService){customLoadingService.susLoadingCount()} else this.loadingService.susLoadingCount();
       })
    }
    
}