<div class="gestor-tipo-variante content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'TipoVariante' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="TipoVarianteForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #TipoVarianteForm="ngForm" id="TipoVarianteform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-2">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'CODIGO_PH' | translate }}"
								[(ngModel)]="item.codigo"
								pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
								type="$TYPE"
								#codigo="ngModel"
								name="codigo"
								[readonly]="readonly"
							/>
							<label>{{ "CODIGO" | translate }}</label>
						</span>
						<error-tag [model]="codigo"></error-tag>
					</div>

					<div class="col-12 lg:col-6">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="$TYPE"
								#descripcion="ngModel"
								name="descripcion"
								[readonly]="readonly"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>
					<div class="col-12 lg:col-4 checkbox-container">
						<p-toggleButton
							onLabel="{{ 'HABILITADO' | translate }}"
							offLabel="{{ 'HABILITADO' | translate }}"
							iconPos="left"
							name="habilitado"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[disabled]="readonly"
							[(ngModel)]="item.habilitado"
						></p-toggleButton>
					</div>
					<div class="col-fixed col-codigo flex align-items-center">
						<color-picker [(ngModel)]="item.color" [readonly]="readonly" name="color"> </color-picker>
						<i class="fa fa-user ml-2 inline-block" [ngStyle]="{ color: item.color || 'black' }"></i>
					</div>
					<div class="col-12 lg:col-4">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'PESO_PH' | translate }}"
								[(ngModel)]="item.peso"
								pTooltip="{{ 'PESO_TOOLTIP' | translate }}"
								type="number"
								#peso="ngModel"
								name="peso"
								[readonly]="readonly"
							/>
							<label>{{ "PESO" | translate }}</label>
						</span>
						<error-tag [model]="peso"></error-tag>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
