import * as moment from "moment"
import { Descriptivo } from "./../../../common/model/Descriptivo"
import { Filtro } from "./../../../common/model/Filtro"
import { EstadoReserva } from "./../../../model/EstadoReserva"
export class FiltroReserva extends Filtro {
	private _soloCotizaciones: boolean = false
	public get soloCotizaciones(): boolean {
		return this._soloCotizaciones
	}
	public set soloCotizaciones(v: boolean) {
		this._soloCotizaciones = v
		this.update(true)
	}
	private _estados: Descriptivo[] = []
	public get estados(): Descriptivo[] {
		return this._estados
	}

	private _fechaUltimaActividadDesde: Date = null
	public get fechaUltimaActividadDesde(): Date {
		return this._fechaUltimaActividadDesde
	}
	public set fechaUltimaActividadDesde(v: Date) {
		this._fechaUltimaActividadDesde = v ? moment(v).startOf("day").toDate() : null
		this.update(true)
	}

	private _fechaUltimaActividadHasta: Date = null
	public get fechaUltimaActividadHasta(): Date {
		return this._fechaUltimaActividadHasta
	}
	public set fechaUltimaActividadHasta(v: Date) {
		this._fechaUltimaActividadHasta = v ? moment(v).endOf("day").toDate() : null
		this.update(true)
	}
	public set estados(v: Descriptivo[]) {
		this._estados = v
		this.update(true)
	}

	private _cliente: Descriptivo
	public get cliente(): Descriptivo {
		return this._cliente
	}
	public set cliente(v: Descriptivo) {
		this._cliente = v
		this.update(true)
	}
	private _fechaActividadDesde: Date = null
	public get fechaActividadDesde(): Date {
		return this._fechaActividadDesde
	}
	public set fechaActividadDesde(v: Date) {
		this._fechaActividadDesde = v ? moment(v).startOf("day").toDate() : null
		this.update(true)
	}

	private _fechaActividadHasta: Date = null
	public get fechaActividadHasta(): Date {
		return this._fechaActividadHasta
	}
	public set fechaActividadHasta(v: Date) {
		this._fechaActividadHasta = v ? moment(v).endOf("day").toDate() : null
		this.update(true)
	}

	private _fechaVencimientoDesde: Date = moment().subtract(1, "year").startOf("day").toDate()
	public get fechaVencimientoDesde(): Date {
		return this._fechaVencimientoDesde
	}
	public set fechaVencimientoDesde(v: Date) {
		this._fechaVencimientoDesde = v ? moment(v).startOf("day").toDate() : null
		this.update(true)
	}

	private _fechaVencimientoHasta: Date
	public get fechaVencimientoHasta(): Date {
		return this._fechaVencimientoHasta
	}
	public set fechaVencimientoHasta(v: Date) {
		this._fechaVencimientoHasta = v ? moment(v).endOf("day").toDate() : null
		this.update(true)
	}

	private _fechaRegistroDesde: Date = moment().subtract(1, "year").startOf("day").toDate()
	public get fechaRegistroDesde(): Date {
		return this._fechaRegistroDesde
	}
	public set fechaRegistroDesde(v: Date) {
		this._fechaRegistroDesde = v ? moment(v).startOf("day").toDate() : null

		this.update(true)
	}

	private _fechaRegistroHasta: Date
	public get fechaRegistroHasta(): Date {
		return this._fechaRegistroHasta
	}
	public set fechaRegistroHasta(v: Date) {
		this._fechaRegistroHasta = v ? moment(v).endOf("day").toDate() : null
		this.update(true)
	}

	private _soloImpagas: boolean

	private _soloSinComprobante: boolean
	public get soloSinComprobante(): boolean {
		return this._soloSinComprobante
	}
	public set soloSinComprobante(v: boolean) {
		if (v != this._soloSinComprobante) {
			this._soloSinComprobante = v
			if (this._soloSinComprobante) this._soloFacturadas = false
			this.update()
		}
	}
	public get soloImpagas(): boolean {
		return this._soloImpagas
	}
	public set soloImpagas(v: boolean) {
		if (v != this._soloImpagas) {
			this._soloImpagas = v
			this.update()
		}
	}
	private _soloFacturadas: boolean = false
	public get soloFacturadas(): boolean {
		return this._soloFacturadas
	}
	public set soloFacturadas(v: boolean) {
		if (v != this._soloFacturadas) {
			this._soloFacturadas = v
			if (this._soloFacturadas) this._soloSinComprobante = false
			this.update()
		}
	}

	private _canalVenta: Descriptivo
	public get canalVenta(): Descriptivo {
		return this._canalVenta
	}
	public set canalVenta(v: Descriptivo) {
		this._canalVenta = v
		this.update(true)
	}

	private _vendedores: Descriptivo[] = []
	public get vendedor(): Descriptivo {
		return this._vendedores ? this._vendedores[0] : null
	}
	public set vendedor(v: Descriptivo) {
		if (!this._vendedores) this._vendedores = []
		this._vendedores = v ? [v] : []
		this.update(true)
	}

	private _puntoDeVenta: Descriptivo
	public get puntoDeVenta(): Descriptivo {
		return this._puntoDeVenta
	}
	public set puntoDeVenta(v: Descriptivo) {
		this._puntoDeVenta = v
		this.update(true)
	}
	private _listaPrecios: Descriptivo
	public get listaPrecios(): Descriptivo {
		return this._listaPrecios
	}
	public set listaPrecios(v: Descriptivo) {
		this._listaPrecios = v
		this.update(true)
	}

	constructor(name, page = 0, size = 20, orderBy = "id", orderDirection = 2) {
		super(
			name,
			{
				fechaRegistroDesde: "Fecha desde Registro",
				fechaRegistroHasta: "hasta",
				fechaVencimientoDesde: "Fecha desde Vencimiento",
				fechaVencimientoHasta: "hasta",
				soloCotizaciones: "Solo cotizaciones",
				estados: "Estados seleccionados",
				cliente: "Cliente",
				soloSinComprobante: "Solo sin comprobantes",
				soloImpagas: "Solo impagas",
				canalVenta: "Canal de Venta",
				puntoDeVenta: "Punto de Venta",
				vendedor: "Vendedor",
				vendedores: "Vendedores",
				listaPrecios: "Lista de Precios",
				fechaActividadDesde: "Fecha desde Actividad",
				fechaActividadHasta: "hasta",
				fechaUltimaActividadDesde: "Fecha desde última Actividad",
				fechaUltimaActividadHasta: "hasta",
				soloFacturadas: "Solo facturadas"
			},
			page,
			size,
			orderBy,
			orderDirection,
			true
		)
	}

	public clean() {
		super.clean(false)
		this.patchValue({
			searchStr: "",
			fechaRegistroDesde: moment().subtract(1, "year").startOf("day").toDate(),
			fechaRegistroHasta: null,
			fechaVencimientoDesde: moment().subtract(1, "year").startOf("day").toDate(),
			fechaVencimientoHasta: null,
			cliente: null,
			soloCotizaciones: false,
			estados: [],
			size: 20,
			page: 0,
			soloSinComprobante: false,
			soloImpagas: false,
			canalVenta: null,
			puntoDeVenta: null,
			vendedor: null,
			vendedores: [],
			listaPrecios: null,
			fechaActividadDesde: null,
			fechaActividadHasta: null,
			fechaUltimaActividadDesde: null,
			fechaUltimaActividadHasta: null,
			soloFacturadas: false
		})
	}
	public patchValue(v: any) {
		super.patchValue(v, false)
		this._fechaRegistroDesde = v.fechaRegistroDesde ? new Date(v.fechaRegistroDesde) : moment().subtract(1, "year").startOf("day").toDate()
		this._fechaRegistroHasta = v.fechaRegistroHasta ? new Date(v.fechaRegistroHasta) : null
		this._fechaVencimientoDesde = v.fechaVencimientoDesde ? new Date(v.fechaVencimientoDesde) : moment().subtract(1, "year").startOf("day").toDate()
		this._fechaVencimientoHasta = v.fechaVencimientoHasta ? new Date(v.fechaVencimientoHasta) : null
		this._cliente = v.cliente ? Descriptivo.fromData(v.cliente) : null
		this._soloCotizaciones = v.soloCotizaciones
		this._estados = v.estados ? v.estados.map((e) => EstadoReserva.fromData(e)) : []
		this._soloSinComprobante = v.soloSinComprobante
		this._soloImpagas = v.soloImpagas
		this._canalVenta = v.canalVenta
		this._puntoDeVenta = v.puntoDeVenta ? Descriptivo.fromData(v.puntoDeVenta) : null
		this._vendedores = v.vendedores ? v.vendedores.map((d) => Descriptivo.fromData(d)) : []
		this._listaPrecios = v.listaPrecios ? Descriptivo.fromData(v.listaPrecios) : null
		this._fechaActividadDesde = v.fechaActividadDesde ? new Date(v.fechaActividadDesde) : null
		this._fechaActividadHasta = v.fechaActividadHasta ? new Date(v.fechaActividadHasta) : null
		this._fechaUltimaActividadDesde = v.fechaUltimaActividadDesde ? new Date(v.fechaUltimaActividadDesde) : null
		this._fechaUltimaActividadHasta = v.fechaUltimaActividadHasta ? new Date(v.fechaUltimaActividadHasta) : null
		this._soloFacturadas = v.soloFacturadas
		this.update(true, false)
	}

	public valid(): boolean {
		return this._fechaRegistroDesde != undefined && this._fechaVencimientoDesde != undefined
	}

	public tieneEstado(estado: EstadoReserva) {
		return this.estados.some((e) => e.id == estado.id)
	}
	public toggleEstado(estado: EstadoReserva) {
		if (this.tieneEstado(estado)) {
			this.estados = this.estados.filter((e) => e.id != estado.id)
		} else {
			this.estados.push(estado)
		}
		this.update(true)
	}
	clonar() {
		const f = new FiltroReserva(null, null)
		f.patchValue(this)
		return f
	}
}
