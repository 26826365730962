<model-help [title]="'Plantillas'">
	<p>
		Las plantillas se usan como base para enviar
		<help-link [linkFinal]="'tipo-notificacion'" [textoLink]="'Notificaciones'"></help-link>
		entre diferentes actores del sistema.<br />
		Se pueden crear nuevas haciendo click en el ícono
		<i class="pi pi-plus"></i> o modificar las existentes <i class="pi pi-pencil"></i>. También se podrán modificar
		las que incluye el sistema por defecto.
	</p>

	<h3>Traducciones</h3>
	Para cambiar el
	<help-link [linkFinal]="'idioma'" [textoLink]="'Idioma'"></help-link> de las plantillas se utiliza, como en la
	mayoria de las pantallas de gestión, <br />
	la bandera que se ubica al lado del nombre de la plantilla en la parte superior de la pantalla. <br />
	Para crear las traducciones de las diferentes plantillas existentes o nuevas creadas por el usuario, se deben ir
	completando los campos descripción y
	plantilla en cada
	<help-link [linkFinal]="'idioma'" [textoLink]="'Idioma'"></help-link> y guardando. <br />
	Es importante pulsar en el botón <strong>guardar</strong> antes de seleccionar otro idioma, caso contrario se
	perderán los cambios.

	<h3>Campos importantes</h3>
	<p>
		<strong>Canal:</strong> El
		<help-link [linkFinal]="'canales-comunicacion'" [textoLink]="'Canal de comunicación'"></help-link>
		por el cual será enviada la plantilla, cuando elijamos diferentes opciones los campos a completar en la pantalla
		cambiarán adecuandose al mismo.<br /><br />
		<strong>Tipo de notificación:</strong> El tipo de notificación al cual será asignada la plantilla
		actual.<br /><br />
		<strong>Plantilla:</strong> La plantilla propiamente dicha, aquí se podrá ingresar texto y variables. El texto
		lo podremos formatear con diferentes
		opciones del editor . <br /><br />
		<img src="assets/images/help/editor.jpg" /><br />
		Dentro de las múltiples opciones del editor de texto se encuentran las opciones para destacar texto con
		diferentes fuentes, negrita, etc.<br />
		Además se pueden insertar imagenes (ej: logo de la empresa), links externos, etc.<br />
	</p>
	<h3>Punto de venta</h3>
	<p>
		Si se define un punto de venta, cuando el mail que se está enviando cuanta con un punto deventa, se buscará el
		email template que coincida con el punto
		de venta en cuestión.
	</p>
	<p>
		El hecho de no tener un punto de venta seleccionado implica que será el email template default para los casos
		donde no haya uno definido para el punto
		de venta asociado al mensaje.
	</p>
	<p>
		Ej: Si tengo el punto de venta 1 y el punto de venta 2, el template "Itinerario" 1 sin punto de venta y el
		template "Itinerario" 2 con punto de venta
		asociado 1. Al enviar el itinerario para una reserva cargada para el punto de venta 1, se utilizará el template
		"Itinerario" 2. En cambio, si lo hago
		para una reserva donde el punto de venta es el 2, se utilizará el "itinerario" 1 por ser el default.
	</p>
	<h3>Variables</h3>
	<p>
		Las variables son marcas que pondremos en nuestras plantillas donde automáticamente se insertarán datos del
		sistema a elección para ser entregados
		mediante <br />
		las notificaciones a sus destinatarios. <br />
		(Por ejemplo: Nombre del cliente, monto total de una factura, listado de pasajeros para una excursión,
		etc.)<br /><br />
		Las variables pueden ser usadas dependiendo del tipo de notificación. En otras palabras, cada tipo de
		notificación tendrá disponible solo algunas
		variables para insertar en su plantilla.
	</p>
	<p>
		La forma de insertar una variable en la plantilla, es escibiendo dentro de su contenido, el nombre clave de la
		misma entre símbolos de peso ($)
		<br />(Ejemplo: $NRESERVA$ nos devolverá un número de reserva)<br /><br />
		En algunos casos las variables tienen <strong>más de un valor.</strong>
		<br />
		Por ejemplo: En la plantilla de Notificación de pago emitido, se incluyen los números de facturas incluídos en
		dicho pago, en ese caso no sabemos de
		antemano <br />
		si es una factura o son varias; para esos casos usamos una variable con un formato diferente: el nombre clave de
		la variable entre un asterisco y un
		guión bajo. (ej: *_items_*)
	</p>
	<h3>Variables condicionales</h3>
	<p>
		Se puede envolver una variable o sección de plantilla con tags condicionales para mostrar o no la sección en
		base a si una variable tiene o no
		contenido.
	</p>

	<p>Ej:</p>
	<p>
		<strong> &lt;GASTO&gt;</strong> Esto solo se visualiza si $GASTO$ tiene contenido
		<strong>&lt;/GASTO&gt;</strong>
	</p>
	<p>
		<strong> &lt;!GASTO&gt;</strong> Esto solo se visualiza si $GASTO$ <strong>NO</strong>; tiene contenido
		<strong>&lt;/!GASTO&gt;</strong>
	</p>
	<h3 class="mt-6">
		Listado de variables disponibles según
		<help-link [linkFinal]="'tipo-notificacion'" [textoLink]="'Tipos de notificación'"></help-link>:
	</h3>

	<p-accordion>
		<p-accordionTab header="Recuperar password">
			<ul class="listado-variables">
				<li>$$nombre Se usa para insertar nombre completo de usuario.</li>
				<li>$$username Nombre de usuario (nickname)</li>
				<li>$URL$ url base del sistema</li>
				<li>$$token Token de autenticación</li>
			</ul>
		</p-accordionTab>

		<p-accordionTab header="Envío de Factura de la Reserva">
			<ul class="listado-variables">
				<li>$NOMBRE$ Se usa para insertar nombre completo del cliente.</li>
				<li>$NRESERVA$ Número de reserva asociado</li>
				<li>$OBSERVACIONES$ Observaciones del comprobante</li>
				<li>$OBSERVACIONES_RESERVA$ Observaciones de la reserva</li>
				<li>$DESC_COMPROBANTE$ Descripción del comprobante</li>
				<li>$LINK_FACTURA$ Link para ver el comprobante</li>
			</ul>
		</p-accordionTab>

		<p-accordionTab header="Pickup Pasajero">
			<ul class="listado-variables">
				<li>$NOMBRE_CLIENTE$ Se usa para insertar nombre completo del cliente.</li>
				<li>$LUGAR_PICKUP$ Dirección del Hotel seleccionado en la reserva.</li>
				<li>$HORA_PICKUP$ Hora que figura en la actividad.</li>
				<li>$SERVICIO$ Nombre del servicio</li>
				<li>$SERVICIO_COMERCIAL$ Nombre comercialdel servicio</li>
				<li>$PRESTADOR$ Nombre del proveedor</li>
				<li>$NOTA_CLIENTE$ Notas que fueron cargadas en la reserva para el cliente</li>
			</ul>
		</p-accordionTab>

		<p-accordionTab header="Resumen Pickup para el hotel">
			<ul class="listado-variables">
				<li>$NOMBRE_HOTEL$ Nombre del Hotel seleccionado para el pickup.</li>
				<li>$DIA_PICKUP$ Fecha del pickup.</li>
				<li>$HORA$ Hora.</li>
				<li>$NOMBREPAX$ Nombre del titular de la reserva.</li>
				<li>$CANTIDAD$ Cantidad de pax que conforman la reserva.</li>
				<li>$SERVICIO$ Nombre del servicio</li>
				<li>$SERVICIO_COMERCIAL$ Nombre comercialdel servicio</li>
				<li>$PRESTADOR$ Nombre del prestador.</li>
				<li>*_itemPickup_* Los items que tengan como recordatorio PICKUP.</li>
				<li>*_itemNavegacion_* Items que tengan como recordatorio NAVEGACION.</li>
				<li>*_itemTransfer_* Items que tengan como recordatorio TRANSFERIN.</li>
			</ul>
		</p-accordionTab>

		<p-accordionTab header="Resumen de reservas para proveedores">
			<ul class="listado-variables">
				<li>$NOMBRE_PROVEEDOR$ Nombre proveedor.</li>
				<li>$DIA_PICKUP$ Fecha de pickup.</li>

				<li>
					*_item_* Esta estructura representará los items que se van a notificar, debe agrupar, y estár sobre
					y abajo de las variables que tienen
					repetición de listado. Tiene las siguientes variables:
					<ul>
						<li>$HORA$ Hora.</li>
						<li>$NOMBREPAX$ Nombre del titular de la reserva.</li>
						<li>$LUGAR_PICKUP$ Nombre del hotel o dirección alternativa</li>
						<li>$CANTIDAD$ Cantidad de pax que conforman la reserva.</li>
						<li>$OBSERVACIONES$ Observaciones de la reserva.</li>
						<li>$SERVICIO$ Nombre del servicio</li>
					</ul>
				</li>
				<li>$URL$ Url para el link al que puede entrar el proveedor para revisar sus confirmaciones</li>
			</ul>
		</p-accordionTab>

		<p-accordionTab header="Estado de cuenta Cliente">
			<ul class="listado-variables">
				<li>$NOMBRE_CLIENTE$ Nombre cliente.</li>
				<li>$FECHA$ Fecha.</li>
				<li>$NUMERO_COMPROBANTE$ Numero de comprobante (puede haber uno o más)</li>
				<li>$SALDO$ Saldo individual por cada comprobante.</li>
				<li>$SALDO_MONEDA_DOC$ Saldo individual por cada comprobante en la moneda del comprobante.</li>
				<li>$SALDO_TOTAL$ Suma total de todos los comprobantes.</li>
				<li>
					*_item_* Esta estructura representará los items que se van a notificar, debe agrupar, y estár sobre
					y abajo de las variables que tienen
					repetición de listado. ($NUMERO_COMPROBANTE$, $SALDO$ y $SALDO_MONEDA_DOC$)
				</li>
			</ul>
		</p-accordionTab>

		<p-accordionTab header="Pago Emitido">
			<ul class="listado-variables">
				<li>$NOMBRE_PROVEEDOR$ Nombre proveedor.</li>
				<li>$NUMERO_OP$ Numero de operación.</li>
				<li>$NUMERO_COMPROBANTE$ Numero de comprobante puede ser uno o más incluídos en el pago.</li>
				<li>$IMPORTE$ Importe de cada comprobante incluído.</li>
				<li>$PAGO$ Pago realizado sobre el importe de cada comprobante.</li>
				<li>$IMPORTE_A_CUENTA$ Importe, solo si se realizó un pago a cuenta.</li>
				<li>$TOTAL$ Monto total del pago.</li>
				<li>$TEXTO_ADICIONAL$ Titulo "Observaciones:" + Descripción de la orden de pago.</li>
				<li>$OBSERVACIONES$ Observaciones de la orden de pago</li>
				<li>$FORMA_PAGO$ Forma de pago.</li>
				<li>$IMPORTE_PAGO$ Importes pagados, puede ser uno o varios.</li>
				<li>$COMPROBANTE_PAGO$ Numero de comprobante de cada pago.</li>
				<li>
					*_item_* Lista de facturas pagadas. (poner sobre y abajo de cada variable que pueda tener
					repeticiones de comprobantes.
					$NUMERO_COMPROBANTE$, $IMPORTE$ y $PAGO$)
				</li>
				<li>*_aCuenta_* Listado de pagos a cuenta. (idem anterior con $IMPORTE_A_CUENTA$)</li>
				<li>*_pagos_* Listado de pagos realizados. (idem anterior con $FORMA_PAGO$, $IMPORTE_PAGO$ y
					$COMPROBANTE_PAGO$)</li>
			</ul>
		</p-accordionTab>
		<p-accordionTab header="Confirmación de Reserva para Proveedores">
			<ul class="listado-variables">
				<li><strong>$NOMBRE_PROVEEDOR$</strong> Nombre del proveedor.</li>
				<li><strong>$ID_PROVEEDOR$</strong> Id del proveedor para URL.</li>
				<li><strong>$URL$</strong> Enlace para que el proveedor pueda realizar la confirmación.</li>
				<li>
					<strong>$SIN_CAMBIOS$</strong> una variable condicional para mostrar un subtitulo "Cancelaciones" o
					vacio en caso de que no haya
					cancelaciones
				</li>
				<li>
					<strong>*_item_*</strong> Lista de items a confirmar (poner sobre y abajo de cada variable que pueda
					tener repeticiones). Tiene las
					siguientes variables:
					<ul>
						<li><strong>$NOMBRE_PAX$</strong> Nombre pasajero titular de la reserva.</li>
						<li><strong>$CANTIDAD_PAX$</strong> Cantidad total de pasajeros.</li>
						<li><strong>$FECHA$</strong> Fecha reserva y hora.</li>
						<li><strong>$SOLO_FECHA$</strong> Fecha reserva sin hora.</li>
						<li><strong>$PICKUP$</strong> Hora de pickup.</li>
						<li><strong>$HORA$</strong> Hora de la actividad.</li>
						<li><strong>$PRODUCTO$</strong> Servicios contratados.</li>
						<li><strong>$NACIONALIDAD$</strong> Nacionalidad de los pasajeros.</li>
						<li><strong>$NUMERO_RESERVA$</strong> Es el número de la reserva</li>
						<li><strong>$NUMERO_VOUCHER$</strong> Número Vaucher.</li>
						<li>
							<strong>$NUMERO_TELEFONO$</strong> Número de contacto de los pasajeros. Se puede utilizar
							dentro de un link con el tag "phone:" para
							utilizarlo como link a llamada
						</li>
						<li><strong>$HOTEL$</strong> es el hotel o dirección del pasajero</li>
						<li><strong>$OBSERVACIONES$</strong> Observaciones ingresadas en el Servicio.</li>
						<li><strong>$OBSERVACIONES_RESERVA$</strong> Observaciones sobre la reserva.</li>
						<li><strong>$GASTOS$</strong> Es el precio acordado con el proveedor según su lista de precios.
						</li>
						<li><strong>&lt;VER_DATOS_PAX&gt;</strong> Variable condicional para mostrar o no un titulo para
							los Datos Paxs.</li>
						<li>
							<strong>*_item_pax_*</strong> Lista de paxs para el item (poner sobre y abajo de cada
							variable que pueda tener repeticiones). Tiene
							las siguientes variables:
						</li>
						<ul>
							<li><strong>$NOMBRE_PAX$</strong> Nombre del pasajero.</li>
							<li><strong>$DOCUMENTO_PAX$</strong> Tipo y número de documento.</li>
							<li><strong>$NACIMIENTO_PAX$</strong> Fecha de nacimiento.</li>
							<li><strong>$OBSERVACIONES_PAX$</strong> Observaciones para el pasajero.</li>
						</ul>
						<li><strong>$URL$</strong> es la URL a ingresar para ver los servicios del proveedor</li>
					</ul>
				</li>
				<li>
					<strong>*_itemcancelaciones_*</strong> Tendrá los servicios que fueron cancelados (contando los
					reemplazados al modificar) y tiene las
					mismas variables que <strong>*_item_*</strong>
				</li>
			</ul>
		</p-accordionTab>

		<p-accordionTab header="Cancelación de Reserva para Proveedores">
			<ul class="listado-variables">
				<li>$NOMBRE_PROVEEDOR$ Nombre del proveedor.</li>
				<li>$NOMBRE_PAX$Nombre pasajero titular de la reserva.</li>
				<li>$CANTIDAD_PAX$ Cantidad total de PAXs.</li>
				<li>$FECHA$ Fecha reserva.</li>
				<li>$PRODUCTO$ Servicios Cancelados.</li>
				<li>$NUMERO_VOUCHER$ Numero Vaucher.</li>
				<li>
					*_item_* Lista de items cancelados. (poner sobre y abajo agrupando las variables que pueda tener
					repeticiones $NOMBRE_PAX$, $CANTIDAD_PAX$,
					$FECHA$, $PRODUCTO$ y $NUMERO_VOUCHER$)
				</li>
				<li>$NUMERO_RESERVA$ Número identificatorio de la reserva cancelada.</li>
			</ul>
		</p-accordionTab>

		<p-accordionTab header="Itinerario Pasajero">
			<ul class="listado-variables">
				<li>$NOMBRE$ Nombre PAX.</li>
				<li>$RESERVA$ Nro reserva para texto y para formar el link (url).</li>
				<li>$URL$ URL base del sistema.</li>
				<li>$NOTA_CLIENTE$ Son las Notas de Cliente que se hayan ingresado en la reserva a nivel cabecera</li>
				<li>
					*_item_* Lista de servicios en el itinerario. Debe estar por arriba y abajo de la sección que se
					quiere repetir. Las variables disponibles
					para el servicio son:
					<ul>
						<li><strong>$PRODUCTO$:</strong> Representa la descripción del servicio o familia de servicio
							(nombre comercial).</li>
						<li><strong>$FECHA$:</strong> Indica la fecha y hora de la actividad.</li>
						<li><strong>$SOLO_FECHA$:</strong> Representa solo la fecha de la actividad.</li>
						<li><strong>$HORA_PICKUP$:</strong> Corresponde a la hora de pickup.</li>
						<li><strong>$HORA$:</strong> Refleja la hora de la actividad.</li>
						<li><strong>$NOTA_CLIENTE$:</strong> Contiene la nota del cliente asociada al servicio.</li>
						<li><strong>$DETALLE$:</strong> Incluye los detalles del servicio. NO SE RECOMIENDA POR UN TEMA
							DE LONGITUD</li>
					</ul>
				</li>

				<li>
					*_itemHospedajes_* Lista de hospedajes en el itienerario. Debe estar por arriba y abajo de la
					sección que se quiere repetir. Las variables disponibles
					para el servicio son:
					<ul>
						<li><strong>$CHECK_IN$:</strong> Fecha y hora de Check in del hospedaje.</li>
						<li><strong>$CHECK_OUT$:</strong> Fecha y hora de Check out del hospedaje.</li>
						<li><strong>$NOMBRE_HOTEL$:</strong> Nombre del alojamiento.</li>
						<li><strong>$DIRECCION_HOTEL$:</strong> Direccion del alojamiento.</li>
						<li><strong>$HABITACION$:</strong> Tipo de habitación.</li>
						<li><strong>$NOTA_CLIENTE$:</strong> Contiene la nota del cliente asociada al hospedaje.</li>
					</ul>
				</li>
			</ul>
		</p-accordionTab>

		<p-accordionTab header="Itinerario para el guía">
			<ul class="listado-variables">
				<li><strong>$NOMBRE_PROVEEDOR$</strong> Nombre del proveedor</li>
				<li><strong>$DIA_PICKUP$</strong> Día de la actividad en formato 'dd/mm/yyyy'</li>
				<li><strong>$TABLA_PAXS$</strong> Contiene toda la info de todas las actividades del día con un formato
					predefinido.</li>
				<li><strong>*_items_actividad_*</strong> Lista de Actividades asignadas al guía. Por cada actividad
					tiene las siguientes variables:</li>
				<ul>
					<li><strong class="ml-2">$ACTIVIDAD$ </strong>Nombre interno de la actividad</li>
					<li><strong class="ml-2">$ACTIVIDAD_COMERCIAL$ </strong>Nombre comercial de la actividad</li>
					<li><strong class="ml-2">$PATENTE$</strong>Patente del vehiculo asignado</li>
					<li><strong class="ml-2">$HORA_ACTIVIDAD$</strong>Hora de la actividad en formato 'HH:mm'</li>
					<li><strong class="ml-2">$CANTIDAD_PAXS$</strong>Cantidad total de pasajeros</li>
					<li><strong class="ml-2">*_item_pax_*</strong>Listado con los pasajeros de la actividad. Cada item
						tiene las siguientes variables:</li>

					<ul>
						<li><strong class="ml-3">$PICKUP$ </strong>Hora de pickup en formato 'HH:mm'</li>
						<li><strong class="ml-3">$NOMBRE_PAX$ </strong>Nombre de los paxs de la reserva</li>

						<li><strong class="ml-3">$AGENCIA$</strong>Nombre del cliente</li>
						<li><strong class="ml-3">$CANTIDAD_PAX$</strong>Cantidad de pasajeros</li>
						<li><strong class="ml-3">$OBSERVACIONES$</strong>Observaciones internas en el item reserva</li>
						<li><strong class="ml-3">$NRO_RESERVA$</strong>Número de reserva</li>
						<li><strong class="ml-3">$DIRECCION$</strong>Dirección de pickup (con nombre de hotel si aplica)
						</li>
						<li><strong class="ml-3">$TELEFONO$</strong>Número de contacto</li>
						<li><strong class="ml-3">$EMAIL$</strong>Email de contacto</li>
						<li><strong class="ml-3">$RESTRICCIONES$</strong>Restricciones de los pasajeros (separados por
							',')</li>
						<li><strong class="ml-3">$IDIOMA$</strong>Idioma configurado en el servicio</li>
					</ul>
				</ul>
			</ul>
		</p-accordionTab>

		<p-accordionTab header="Resumen del día">
			<ul class="listado-variables">
				<li>$DIA_PICKUP$ Día del cual se está enviando el resumen.</li>
				<li>$TABLA_PAXS$ Contiene toda la info de todas las actividades del día.</li>
			</ul>
		</p-accordionTab>

		<p-accordionTab header="Rechazo de servicio por proveedor">
			<ul class="listado-variables">
				<li>*_item_* Lista de items rechazados.</li>
				<li>$IDGRUPO$ Id de grupo pax.</li>
				<li>$VEHICULO$: Patente del vehículo.</li>
				<li>$NUMERO_VOUCHER$: item reserva.</li>
			</ul>
		</p-accordionTab>
		<p-accordionTab header="Recordatorio Encuesta">
			<ul class="listado-variables">
				<li>$NOMBRE_PAX$ Nombre del PAX.</li>
				<li>$RESERVA$ en url.</li>
				<li>$TOKEN$: en url.</li>
				<p>Ambos son obligatorios y tienen que estar en el link como en el template original.</p>
			</ul>
		</p-accordionTab>
		<p-accordionTab header="Recordatorio Navegación">
			<ul class="listado-variables">
				<li>$NOMBRE_CLIENTE$ Se usa para insertar nombre completo del cliente.</li>
				<li>$LUGAR_PICKUP$ Dirección del Hotel seleccionado en la reserva.</li>
				<li>$HORA_PICKUP$ Hora que figura en la actividad.</li>
				<li>$SERVICIO$ Nombre del servicio</li>
				<li>$SERVICIO_COMERCIAL$ Nombre comercialdel servicio</li>
				<li>$PRESTADOR$ Nombre del proveedor</li>
				<li>$NOTA_CLIENTE$ Notas que fueron cargadas en la reserva para el cliente</li>
			</ul>
		</p-accordionTab>

		<p-accordionTab header="Recordatorio Transfer">
			<ul class="listado-variables">
				<li>$NOMBRE_CLIENTE$ Se usa para insertar nombre completo del cliente.</li>
				<li>$LUGAR_PICKUP$ Dirección del Hotel seleccionado en la reserva.</li>
				<li>$HORA_PICKUP$ Hora que figura en la actividad.</li>
				<li>$SERVICIO$ Nombre del servicio</li>
				<li>$SERVICIO_COMERCIAL$ Nombre comercialdel servicio</li>
				<li>$PRESTADOR$ Nombre del proveedor</li>
				<li>$NOTA_CLIENTE$ Notas que fueron cargadas en la reserva para el cliente</li>
			</ul>
		</p-accordionTab>
		<p-accordionTab header="Creación de Reserva desde cotización externa">
			<ul class="listado-variables">
				<li>$CLIENTE$ Se usa para insertar nombre completo del cliente.</li>
				<li>$NOMBRE_PAX$ Nombre pasajero titular de la reserva.</li>
				<li>$NACIONALIDAD_PAX$ Nacionalidad de los pasajeros.</li>
				<li>$IDIOMA_PAX$ Idioma configurado en la reserva.</li>
				<li>$CANT_TOTAL_PAX$ Cantidad total de pasajeros en la reserva.</li>
				<li><strong class="ml-3">*_itemServicio_*</strong>Lista de servicio. Cada item tiene las siguientes
					variables:</li>

				<ul>
					<li class="ml-3">$FECHA$ Fecha.</li>
					<li class="ml-3">$HORA$ Hora.</li>
					<li class="ml-3">$HORA_PICKUP$ Hora que figura en la actividad.</li>
					<li class="ml-3">$CANTIDAD_PAX$ Cantidad de pasajeros en el servicio.</li>
					<li class="ml-3">$IDIOMA$ Idioma configurado en el servicio.</li>
					<li class="ml-3">$HOTEL$ Es el hotel o dirección del pasajero</li>
					<li class="ml-3">$SERVICIO$ Nombre del servicio</li>
					<li class="ml-3">$SERVICIO_COMERCIAL$ Nombre comercial del servicio</li>
					<li><strong class="ml-3">*_adicionales_*</strong>Productos adicionales del servicio. Cada item tiene
						las siguientes variables:</li>
					<ul>
						<li class="ml-5">$PRODUCTO_ADICIONAL$ Nombre del producto adicional</li>
						<li class="ml-5">$PRODUCTO_ADICIONAL_COMERCIAL$ Nombre comercial del producto adicional</li>
						<li class="ml-5">$CANTIDAD_ADICIONAL$ Cantidad del producto adicional</li>
					</ul>
				</ul>
			</ul>
		</p-accordionTab>
	</p-accordion>
	<br /><br />

	<p><strong>Ejemplo de uso</strong></p>
	<img src="assets/images/help/template.jpg" /><br />
	<p>Para ver otros ejemplos de usos de variables más complejas, puede ver cualquiera de las plantillas por defecto
		del sistema.</p>
</model-help>