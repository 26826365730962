import { Component, Input, OnInit, ViewChild } from "@angular/core"
import * as moment from "moment"
import { OverlayPanel } from "primeng/overlaypanel"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { TipoPuesto } from "src/app/model/TipoPuesto"
import { DashboardConfiguration } from "../../dashboard-element/DashboardConfiguration"
import { IDashboardElement } from "../../dashboard-element/IDashboardElement"
import { TipoPuestoService } from "./../../../../services/tipo-puesto.service"

@Component({
	selector: "disponibilidad-home",
	templateUrl: "./disponibilidad-home.component.html",
	styleUrls: ["./../dashboard-element-common.component.less", "./disponibilidad-home.component.less"]
})
export class DisponibilidadHomeComponent implements OnInit, IDashboardElement {
	public static TIPO: any = "disponibilidad-home"
	public static defaultConfig: DashboardConfiguration = {
		fixedHeader: true,
		x: null,
		y: null,
		rows: 7,
		cols: 4,
		tipo: "disponibilidad-home",
		params: {}
	}

	public opciones: Descriptivo[] = [new Descriptivo("1", "HOY"), new Descriptivo("2", "MAÑANA"), new Descriptivo("FE", "Fecha específica")]

	constructor(public tipoPuestoService: TipoPuestoService) {}

	private _config: DashboardConfiguration

	public get config(): DashboardConfiguration {
		return this._config
	}

	private _fecha: Date
	public get fecha(): Date {
		return this._fecha
	}
	public set fecha(v: Date) {
		this._fecha = v
		if (v) this.buscar()
	}

	data: { fecha: Date; puesto: TipoPuesto; tipoFecha: Descriptivo } = {
		fecha: null,
		puesto: null,
		tipoFecha: null
	}

	@Input()
	public set config(v: DashboardConfiguration) {
		this._config = v
		if (!v) {
			return
		}

		if (v.params["tipoFecha"]) {
			this.data.tipoFecha = Descriptivo.fromData(v.params["tipoFecha"])
			if (v.params["fecha"]) {
				this.seleccionarFecha(this.data.tipoFecha, v.params["fecha"])
			}
		}

		if (v.params["puesto"]) {
			this.data.puesto = TipoPuesto.fromData(v.params["puesto"])
		}
		if (this.data.fecha && this.data.puesto) this.buscar()
	}

	@ViewChild(OverlayPanel, { static: true }) op: OverlayPanel

	mostrarDisponibilidad: boolean = false
	showParams(event) {
		if (this.op) this.op.toggle(event)
	}
	hideParams() {
		if (this.op) this.op.hide()
	}
	onChange = (conf: DashboardConfiguration) => {}
	getTitle() {
		return (
			"Disponibilidad" +
			(this.data.puesto && this.data.fecha
				? ` - ${this.data.puesto.descripcion} - ${
						this.data.tipoFecha.codigo != "FE" ? this.data.tipoFecha.descripcion : moment(this.data.fecha).format("DD/MM/YYYY")
				  }`
				: "")
		)
	}

	ngOnInit() {}

	buscar() {
		this.mostrarDisponibilidad = false
		setTimeout(() => {
			this.mostrarDisponibilidad = true
			this.hideParams()
			this.data.fecha = this.fecha
			this.config.params = this.data
			this.onChange(this.config)
		}, 300)
	}

	seleccionarFecha(tipo: Descriptivo, fecha?) {
		switch (tipo.codigo) {
			case "1":
				this.fecha = moment().startOf("day").toDate()
				break
			case "2":
				this.fecha = moment().add(1, "day").startOf("day").toDate()
				break
			case "FE":
				this.fecha = fecha ? new Date(fecha) : new Date()
			default:
				break
		}
	}

	onSelectTipoFecha(event: Descriptivo) {
		this.seleccionarFecha(event)
	}
}
