import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-carga-masiva',
  templateUrl: './help-carga-masiva.component.html',
  styleUrls: ['./help-carga-masiva.component.less']
})
export class HelpCargaMasivaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
