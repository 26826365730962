import { Injectable } from "@angular/core"
import { Idioma } from "src/app/common/model/Idioma"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { AuthService } from "src/app/services/auth.service"
import { NovedadService } from "src/app/services/novedades.service"
import { MessagesService } from "./messages-data-service.service"
@Injectable({
	providedIn: "root"
})
export class IdiomaService extends ParametricoService<Idioma> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "idioma"
	}
	public parseToEnt(data: any): Idioma {
		return Idioma.fromData(data)
	}
	public newEnt(): Idioma {
		return new Idioma()
	}
}
