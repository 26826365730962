import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from "@angular/forms"
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router"
import * as moment from "moment"
import { ConfirmationService, MenuItem } from "primeng/api"
import { Dialog } from "primeng/dialog"
import { BehaviorSubject, Subscription } from "rxjs"
import { debounceTime, map } from "rxjs/operators"
import { MostrarImpuestos } from "src/app/app.module"
import { ListaPreciosAG } from "src/app/authguards/ListaPreciosAG"
import { ProveedoresAG } from "src/app/authguards/ProveedoresAG"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { FiltroFactura } from "src/app/common/model/FiltroFactura"
import { Parametrico } from "src/app/common/model/Parametrico"
import { IdiomaService } from "src/app/common/services/idioma.service"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { AprobacionProveedor } from "src/app/model/AprobacionProveedor"
import { BloqueoFecha } from "src/app/model/BloqueoFecha"
import { CanalVenta } from "src/app/model/CanalVenta"
import { Cliente } from "src/app/model/Cliente"
import { ConfiguracionPrograma } from "src/app/model/ConfiguracionPrograma"
import { EstadoPuesto } from "src/app/model/EstadoPuesto"
import { GrupoPaxResumen } from "src/app/model/GrupoPaxResumen"
import { Hotel } from "src/app/model/Hotel"
import { Idioma } from "src/app/model/Idioma"
import { Insumo } from "src/app/model/Insumo"
import { ItemResumenReserva } from "src/app/model/ItemReservaResumen"
import { ListaPrecios } from "src/app/model/ListaPrecios"
import { OpcionPuesto } from "src/app/model/OpcionPuesto"
import { Paquete } from "src/app/model/Paquete"
import { Pax } from "src/app/model/Pax"
import { PrecioInsumo } from "src/app/model/PrecioInsumo"
import { ProductoAsociadoReserva } from "src/app/model/ProductoAsociadoReserva"
import { PuestoServicioReserva } from "src/app/model/PuestoServicioReserva"
import { Region } from "src/app/model/Region"
import { Reserva } from "src/app/model/Reserva"
import { ResumenReserva } from "src/app/model/ResumenReserva"
import { TipoProducto } from "src/app/model/TipoProducto"
import { Vehiculo } from "src/app/model/Vehiculo"
import { CanalVentaService } from "src/app/services/canal-venta.service"
import { CategoriaHospedajeService } from "src/app/services/categoria-hospedaje.service"
import { HotelService } from "src/app/services/hotel.service"
import { ItemReservaService } from "src/app/services/item-reserva.service"
import { ListaPreciosService } from "src/app/services/lista-precios.service"
import { PaisService } from "src/app/services/pais.service"
import { ProveedorService } from "src/app/services/proveedor.service"
import { PuestoServicioReservaService } from "src/app/services/puesto-servicio-reserva.service"
import { RegionService } from "src/app/services/region.service"
import { ReservaService } from "src/app/services/reserva.service"
import { VehiculoService } from "src/app/services/vehiculo.service"
import { VendedorService } from "src/app/services/vendedor.service"
import { LoadingService } from "../../../common/services/loading-data-service.service"
import { ComprobanteService } from "../../../services/comprobante.service"
import { FiltroProducto } from "../../producto/FiltroProducto"
import { ReservasAG } from "./../../../authguards/ReservasAG"
import { ModelGestorComponent } from "./../../../common/components/model-gestor/model-gestor.component"
import { SessionComponent } from "./../../../common/components/session-component.component"
import { AccionesTabla } from "./../../../common/model/AccionesTabla"
import { FiltroVehiculo } from "./../../../common/model/FiltroVehiculo"
import { DragDropContext } from "./../../../common/services/drag-drop-context.service"
import { Comprobante, ComprobanteResumen } from "./../../../model/Comprobante"
import { ConfirmacionReservaPuesto } from "./../../../model/ConfirmacionReservaPuesto"
import { CronogramaProducto } from "./../../../model/CronogramaProducto"
import { GrupoPax } from "./../../../model/GrupoPax"
import { GrupoReserva } from "./../../../model/GrupoReserva"
import { ConfiguracionPaqueteItem, ItemReserva } from "./../../../model/ItemReserva"
import { Producto } from "./../../../model/Producto"
import { PuestoACubrir } from "./../../../model/PuestoACubrir"
import { PuestoServicioPrograma } from "./../../../model/PuestoServicioPrograma"
import { TipoPuesto } from "./../../../model/TipoPuesto"
import { BloqueoFechaService } from "./../../../services/bloqueo-fecha.service"
import { ClienteService } from "./../../../services/cliente.service"
import { GrupoPaxService } from "./../../../services/grupo-pax.service"
import { NacionalidadService } from "./../../../services/nacionalidad.service"
import { ProductoService } from "./../../../services/producto.service"
import { PuntoDeVentaService } from "./../../../services/punto-de-venta.service"
import { RestriccionService } from "./../../../services/restriccion.service"
import { TipoIVAGrabadoService } from "./../../../services/tipo-iva-grabado.service"
import { TipoProveedorService } from "./../../../services/tipo-proveedor.service"
import { TipoPuestoService } from "./../../../services/tipo-puesto.service"
import { TipoVehiculoService } from "./../../../services/tipovehiculo.service"
import { ProductoCabecera } from "./../../producto/ProductoCabecera"
import { AccionesReservaComponent } from "./../acciones-reserva/acciones-reserva.component"
import { CancelacionReservaComponent } from "./../cancelacion-reserva/cancelacion-reserva.component"
import { MovimientoDragDrop } from "./../model/MovimientoDragDrop"
import { FiltroGrupo } from "./FiltroGrupo"
@Component({
	selector: "gestor-reserva",
	templateUrl: "gestor-reserva.component.html",
	styleUrls: ["gestor-reserva.component.less"]
})
export class GestorReservaComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	@Input()
	public item: Reserva
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false
	public fechaSub: Subscription
	public grupoTemporal: GrupoPax
	public cambioListado = false
	public clonar: boolean = false
	public puedeVerCostos: boolean = false
	public tiposCliente: Parametrico[] = [
		new Parametrico(null, "PJ", "Persona Jurídica"),
		new Parametrico(null, "PF", "Persona Física"),
		new Parametrico(null, "O", "Otros")
	]
	public filtroProducto: FiltroProducto = new FiltroProducto("producto_filtros_reserva", 0, 10000000, "peso", 1, false)
	public displayModalCantidadPaxs: boolean = false
	public dragEventCantidadPaxs: DragEvent
	public aMover: number = 0
	public fechasBloqueadas: BloqueoFecha[] = []
	public hotelesProveedor: Hotel[] = []
	fechasBloqueadasDate: Date[] = []
	//Nuevas variables
	public isIcon: boolean = false
	public isIconGroup: boolean = false
	public opcionesProveedor: OpcionPuesto[] = []
	//Modifica el valor de la variable isIconGroup
	public changeIcon(event) {
		this.isIcon = false
		this.isIconGroup = event.valor
	}
	@Output()
	public onGuardado: EventEmitter<Reserva> = new EventEmitter<Reserva>()

	@Output()
	public onCancelado = new EventEmitter()
	@ViewChild(AccionesReservaComponent)
	public accionesReservaComponente: AccionesReservaComponent
	@ViewChild(ModelGestorComponent)
	public modelGestor: ModelGestorComponent

	private direccionOriginal = ""
	@ViewChild(CancelacionReservaComponent, { static: false })
	private cancelacionComponent: CancelacionReservaComponent
	public esGestorReserva: boolean = false
	public nota: Comprobante
	public displayMotivo = false
	public creando = false
	public displayDevolucion = false
	public hacerDevolucion = false
	public opearcionDialogo: string = ""
	public clienteOptions: Descriptivo[] = []
	public hotelOptions: Descriptivo[] = []
	public servicios: Descriptivo[] = []
	public mostrarNuevoProducto = false
	public mostrarNuevoHospedaje = false

	public mostrarHistorialComprobante = false
	public historialComprobantes: ComprobanteResumen[] = []

	private _itemEditado: ItemReserva = new ItemReserva()
	public get itemEditado(): ItemReserva {
		return this._itemEditado
	}
	public set itemEditado(v: ItemReserva) {
		this._itemEditado = v
	}

	public productoEditado: Producto
	public indexServicioEditado: number = -1
	public vendedores: Descriptivo[] = []
	public canales: Descriptivo[] = []
	public adicionables: Descriptivo[] = []
	public mostrarSeleccionarVehiculo: boolean = false
	public mostrarSeleccionPuesto: boolean = false
	private _clienteSeleccionado: Cliente
	public listadosPrecio: Descriptivo[] = []
	public listadoSeleccionado: ListaPrecios
	public vehiculos: Vehiculo[] = []
	public vehiculosDisponibles: Vehiculo[] = []
	//public grillaVehiculo: { vehiculo: Vehiculo, grupos: GrupoPax[] }[] = [];
	public horas: number[] = []
	public grupos: GrupoPax[] = []
	public nuevoGrupos: GrupoPax[] = []
	public gruposEliminados: GrupoPax[] = []
	public scopeCambios: any = { grupos: [] }
	public editandoPrecio = false
	public puestoAlquiler: TipoPuesto
	public fechaActividadSeleccionada: FormControl = new FormControl()
	public customLoading: LoadingService = new LoadingService()
	public valorAjuste = 0
	public alquilados: GrupoPax[] = []
	public delegados: GrupoPax[] = []
	public rOriginal: Reserva
	@Input()
	public readonly: boolean = false
	public acciones: MenuItem[] = []
	comprobante: Comprobante
	mostrarVoucher: boolean = false
	convirtiendoAReserva: boolean = false
	vehiculosPrograma: Map<number, Vehiculo[]> = new Map()
	abrirSeleccionarVehiculo: boolean = false
	public listasPrecios: ListaPrecios[] = []
	private _idiomaPaxOriginal
	public mostrarDatosPaxs = false
	public isEliminar = false
	public formPaxs: FormGroup
	public formPaxsCopy: any
	documentos = [new Descriptivo("96", "DNI"), new Descriptivo("94", "Pasaporte")]
	precioChangeBS: BehaviorSubject<number> = new BehaviorSubject<number>(null)

	public get clienteSeleccionado(): Cliente {
		return this._clienteSeleccionado
	}
	public set clienteSeleccionado(v: Cliente) {
		const clienteAnterior = this.item?.cliente || null
		const emailAnterior = this.item?.emailContacto || null
		const telefonoContactoAnterior = this.item?.telefonoContacto || null
		this._clienteSeleccionado = v
		if (this.item.cliente?.id != v?.id) {
			if (v) {
				this.clienteService.getByIdForced(v.id).then((c) => {
					this.item.cliente = v
					if (v.tipoCliente?.codigo != "EMP") {
						this.item.emailContacto = c.emailReserva || c.email
						this.item.telefonoContacto = c.telefono
					}
					if (!this.item?.id) {
						if (c?.descuento) this.item.ajusteTotal = c.descuento * -1
						else this.item.ajusteTotal = 0
					}
					if (c?.listasPrecios?.length && !this.item?.listadoPrecio) {
						this.item.listadoPrecio = c.listasPrecios[0]
						this.onSelectListado(this.item.listadoPrecio)
					}
				})
			}
		}
	}
	public deshabilitarFecha(item: ItemReserva) {
		return
		;(item?.porUbicar != this.itemEditado?.cantidadPax && !this.grupoTemporal) || this.readonly
	}

	public get monedaSimbolo() {
		return this.item?.moneda ? this.item.moneda?.simbolo + " " : "$ "
	}
	private _seleccionProducto: ProductoCabecera
	public get seleccionProducto(): ProductoCabecera {
		return this._seleccionProducto
	}
	private async updateConfiguracionPaquete(producto?: Producto, itemReserva?: ItemReserva) {
		const prod = producto || this.productoEditado
		const item = itemReserva || this.itemEditado
		if (prod.esPaquete || prod["esFamilia"]) {
			item.configuracionPaquete = this.getConfiguracionPaquete(item, <Paquete>prod)
			for (const c of item.configuracionPaquete) {
				c.precioPerCapita = await this.updatePrecio(
					await this.productoService.getById(c.producto.id),
					item,
					null,
					item.baseCalculo || c.cantidad,
					c.fechaActividad
				)
			}
		} else {
			item.configuracionPaquete = []
		}
	}
	public set seleccionProducto(v: ProductoCabecera) {
		this._seleccionProducto = v
		let idActual = this.itemEditado?.producto?.id || null

		if (!v) return
		this.buscarProducto(v, idActual != v.id).then(async (p) => {
			this.itemEditado.producto = v
			this.itemEditado.tipoProducto = p?.tipoProducto || v?.tipoProducto
			this.updateConfiguracionPaquete(p, this.itemEditado)
		})
	}
	public quitarItemConfiguracionPaquete(i: ConfiguracionPaqueteItem) {
		this.itemEditado.configuracionPaquete = this.itemEditado.configuracionPaquete.filter((c) => c != i)
	}
	public getConfiguracionPaquete = (item: ItemReserva, paquete: Paquete) => {
		return paquete.configuracionPaquete.map((c) => ({
			producto: c.producto,
			fechaActividad: paquete?.esFamilia ? null : item.fechaActividad,
			cantidad: paquete?.esFamilia ? null : item.cantidadPax,
			precioPerCapita: 0,
			autoasignar: c.autoasignar,
			fullDescripcion: c.fullDescripcion
		}))
	}
	public seleccionarProveedor(event?, opcion?: OpcionPuesto) {
		this.itemEditado.proveedorTentativo = opcion.proveedor.id
		this.itemEditado.precioPerCapita = opcion.precioActual.precioVenta(this.itemEditado?.cantidadPax || 1)
	}
	public actualizarOpcionesProveedor(producto: Producto, fecha: Date) {
		if (!producto.esTercerizado() || producto.puestosACubrir.length == 0) {
			this.opcionesProveedor = []
		} else {
			const puesto = producto.puestosACubrir[0] || null
			this.puestoServicioReservaService.buscarPuestos(fecha, puesto?.tipoPuesto).then((r) => {
				this.opcionesProveedor = r
				this.actualizarPreciosOpcionesProveedor(this.opcionesProveedor, puesto, producto, fecha, this.itemEditado.cantidadPax)
			})
		}
	}
	actualizarPreciosOpcionesProveedor(opcionesProveedor: OpcionPuesto[], p: PuestoACubrir, producto: Producto, fecha: Date, cantidadPax: number) {
		const insumo: Insumo = producto.insumoPrincipal()?.insumo || null
		const puesto = p || producto?.puestosACubrir[0] || null
		opcionesProveedor.forEach((op) => {
			if (!this.itemEditado.proveedorTentativo && (puesto?.personalDefault?.id == op.proveedor?.id || opcionesProveedor.length == 1)) {
				this.itemEditado.proveedorTentativo = op.proveedor.id
			}
			op.precioActual = insumo
				? op.precioInsumo(insumo, fecha, this.item.moneda, cantidadPax || this.itemEditado.cantidadPax)
				: new PrecioInsumo(null, null, null, 0, 0)
		})
	}
	public guardarYGenerarComprobante() {
		this.modelGestor.guardar(false).then((r) => {
			this.item = r
			this.accionesReservaComponente.reserva = r
			this.accionesReservaComponente.abrirGenerarComprobante(ResumenReserva.fromData(r), false)
		})
	}
	public multiplesRegiones: boolean = false
	public nacionalidades: Descriptivo[] = []
	private _cantidadPax: number
	public get cantidadPax(): number {
		return this.itemEditado ? this.itemEditado.cantidadPax : 0
	}
	public set cantidadPax(v: number) {
		if (this.itemEditado) {
			this.itemEditado.cantidadPax = v
		}
	}
	public getUbicados(producto: Producto) {
		return this.itemEditado.porUbicar
	}
	constructor(
		messagesService: MessagesService,
		public restriccionService: RestriccionService,
		public service: ReservaService,
		private route: ActivatedRoute,
		private router: Router,
		public clienteService: ClienteService,
		private confService: ConfirmationService,
		public hotelService: HotelService,
		private productoService: ProductoService,
		public idiomaService: IdiomaService,
		public nacionalidadService: NacionalidadService,
		public vendedoresService: VendedorService,
		public canalVentaService: CanalVentaService,
		public tvService: TipoVehiculoService,
		public tipoPuestoService: TipoPuestoService,
		public listadoPreciosService: ListaPreciosService,
		public vehiculoService: VehiculoService,
		public grupoService: GrupoPaxService,
		public bloqueoFechaService: BloqueoFechaService,
		public dragDropContext: DragDropContext,
		public itemReservaService: ItemReservaService,
		public reservaService: ReservaService,
		public comprobanteService: ComprobanteService,
		public proveedorService: ProveedorService,
		public reservaAG: ReservasAG,
		public tipoProveedorService: TipoProveedorService,
		public tipoIvaGrabadoService: TipoIVAGrabadoService,
		public puntoDeVentaService: PuntoDeVentaService,
		public puestoServicioReservaService: PuestoServicioReservaService,
		private formBuilder: FormBuilder,
		public regionService: RegionService,
		public paisService: PaisService,
		public proveedorAG: ProveedoresAG,
		public preciosAG: ListaPreciosAG,
		public categoriaHospedajeService: CategoriaHospedajeService
	) {
		super(messagesService)
		for (let index = 0; index < 48; index++) {
			this.horas[index] = index / 2
		}
	}
	public set esCotizacion(val: boolean) {
		if (val && this.item?.itemsReserva.some((ir) => ir.reservas.length)) {
			this.error("No puede convertir en cotización una reserva con grupos creados. Elimine los grupos de los servicios.")
		} else {
			this.item.esCotizacion = val
		}
	}
	public get esCotizacion() {
		return this.item?.esCotizacion
	}
	convertirHoraAIndice(hora) {
		let partes = hora.split(":")
		let horas = parseInt(partes[0])
		let minutos = parseInt(partes[1])

		return horas * 2 + Math.floor(minutos / 30)
	}
	public cambioHorario(hora, vehiculo?: Vehiculo, evento?) {
		evento.target.classList.remove("over")
		evento.target.classList.remove("over-disabled")
		let data: GrupoPax = this.dragDropContext.fromContext
		data = this.grupos.find((g) => g?.key === data?.key) || (this.grupoTemporal?.key == data?.key ? this.grupoTemporal : null)
		if (!data) return
		evento.stopPropagation()
		if (data.duracion + hora > 24 || hora < 0) {
			data["hide"] = false
			return this.error(this.translateService.get("FUERA_DE_RANGO"))
		}
		var inter = this.getInterseccion(vehiculo, hora, data.duracion + hora)
		if (inter?.filter((g) => g != data).length > 0) {
			inter.forEach((g) => {
				g["error"] = "solapado"
				setTimeout(() => {
					g["error"] = ""
				}, 1000)
			})
			data["hide"] = false

			return this.error(this.translateService.get("HAY_GRUPO_SOLAPADO"))
		}

		if (vehiculo && !data.aplicaVehiculo(vehiculo)) {
			data["hide"] = false
			return this.error(this.translateService.get("EL_VEHICULO_NO_CUMPLE"))
		}
		vehiculo && data.setVehiculo(vehiculo)
		if (!moment(this.fechaActividadSeleccionada.value).isSame(data.fechaActividad, "date")) {
			data.fechaActividad = moment(this.fechaActividadSeleccionada.value).startOf("day").add(hora, "hour").toDate()
		} else {
			data.fechaActividad = moment(data.fechaActividad).startOf("day").add(hora, "hour").toDate()
		}

		data["hide"] = false
		this.itemEditado.reservas
			.filter((gr) => gr.grupoResumen.key === data.key)
			.forEach((gr) => {
				gr.grupoResumen = GrupoPaxResumen.fromData(data)
			})
		if (this.nuevoGrupos.indexOf(data) < 0) {
			this.nuevoGrupos.push(data)
			this.scopeCambios["grupos"] = [...this.scopeCambios["grupos"], data]
		}
		if (this.grupoTemporal?.key == data.key) {
			this.grupos.push(data)
			this.grupoTemporal = null
			this.grupos = [...this.grupos]
		}

		this.aplicarFiltrosVehiculos(this.productoEditado, true)
	}

	public onSelectListado(desc: Descriptivo) {
		const f = (desc, $this: GestorReservaComponent) => {
			$this.listadoPreciosService.getById(desc.id, $this.customLoadingService).then((r) => {
				if (r.moneda?.id != $this.listadoSeleccionado?.moneda?.id) {
					this.item.itemsReserva.forEach((ir) => {
						ir.precioEditado = false
						ir.precioPerCapita = 0
					})
				}

				$this.listadoSeleccionado = r

				$this.item.moneda = $this.listadoSeleccionado?.moneda
				if ($this.item?.moneda?.codigo === $this.monedaEntorno?.codigo) {
					$this.item.cotizacion = 1
				}
				if ($this.item?.itemsReserva?.length > 0) {
					$this.item?.itemsReserva.forEach((ir) => {
						if (!ir.programaId) $this.recalcularPrecio(ir)
					})
				}
			})
		}
		if (this.item.itemsReserva.length > 0) {
			this.confService.confirm({
				key: "genConf",
				header: this.translateService.get("Cambiar lista de precios"),
				message: this.translateService.get("WARNING_CAMBIO_LISTADO"),
				accept: () => {
					f(desc, this)
					this.cambioListado = true
				},
				reject: () => {
					this.item.listadoPrecio = this.rOriginal?.listadoPrecio || null
				}
			})
		} else {
			f(desc, this)
		}
	}
	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}
	public getInterseccion(vehiculo: Vehiculo, col: number, colHasta: number, fecha?: Date): GrupoPax[] {
		return this.grupos.filter(
			(g) =>
				((!vehiculo && !g.vehiculoAsignado) || g.vehiculoAsignado?.id == vehiculo?.id) &&
				(fecha == undefined || moment(g.fechaActividad).isSame(fecha, "date")) &&
				g.horaDesde < colHasta &&
				col < g.colHasta
		)
	}
	public clienteEsCF() {
		return this.clienteSeleccionado && this.clienteSeleccionado.descripcion === Cliente.DOCUMENTO_CF_DESC
	}
	public itemCompleto(item: ItemReserva) {
		return item.producto?.id && item.cantidadPax > 0 && item.fechaActividad
	}
	public agregarGrupoAlquilado(row: number, col: number) {
		var hora = this.horas[col]
		if (this.esBloqueada(this.itemEditado.fechaActividad)) {
			return this.error(this.translateService.get("FECHA_BLOQUEADA"))
		}
		var g = new GrupoPax(
			this.productoEditado.cantidadMaxGrupo,
			null,
			moment(this.itemEditado.fechaActividad).startOf("day").add(hora, "hour").toDate(),
			[],
			ProductoCabecera.fromData(this.productoEditado),
			[],
			[],
			this.productoEditado.duracion,
			true
		)
		g.puestos = this.productoEditado
			? this.productoEditado.puestosACubrir.map((p) => new PuestoServicioReserva(null, p.personalDefault, p.clonar(true)))
			: []
		g.puestos.push(new PuestoServicioReserva(null, null, new PuestoACubrir(null, this.productoEditado, null, this.puestoAlquiler, [])))
		if (g.colHasta > 48 || col < 0) {
			return this.error(this.translateService.get("FUERA_DE_RANGO"))
		}
		var inter = this.alquilados[row]
		if (inter) {
			this.error(this.translateService.get("HAY_GRUPO_SOLAPADO"))
			inter["error"] = "solapado"
			setTimeout(() => {
				g["error"] = ""
			}, 1000)

			return
		}
		this.updatePermiteEdicionCostost(g)
		this.nuevoGrupos = this.nuevoGrupos.concat(g)
		this.scopeCambios["grupos"] = [...this.scopeCambios["grupos"], g]
		this.grupos = this.grupos.concat(g)
		this.alquilados = this.grupos.filter((g) => g.esAlquiler && g.UUID !== g.UUID).concat(g)
	}

	agregarGrupoPax(row: number, col: number, vehiculo: Vehiculo) {
		if (this.readonly) return
		const hora = this.horas[col]
		this.agregarGrupo(vehiculo, this.itemEditado, this.productoEditado, hora, col)
	}

	public agregarGrupo = (vehiculo: Vehiculo, item: ItemReserva, producto: Producto, hora?, col?: number): GrupoPax => {
		if (this.esBloqueada(item.fechaActividad)) {
			this.error(this.translateService.get("FECHA_BLOQUEADA"))
			return null
		}
		hora = hora || this.horas[col] || this.getColByHora(producto?.horaInicio)

		const horaDef = hora || this.horas[col] || producto?.horaInicio

		var g = new GrupoPax(
			vehiculo?.capacidad < producto.cantidadMaxGrupo ? vehiculo.capacidad : producto.cantidadMaxGrupo,
			null,
			moment(item.fechaActividad).startOf("day").add(horaDef, "hour").toDate(),
			[],
			ProductoCabecera.fromData(producto),
			[],
			[],
			producto.duracion
		)

		g.puestos = producto ? producto.puestosACubrir.map((p) => new PuestoServicioReserva(null, p.personalDefault, p.clonar(true))) : []

		if (!vehiculo.proveedor && (g.vehiculoAsignado?.id != vehiculo?.id || !g.vehiculoAsignado)) {
			g.vehiculoAsignado = vehiculo
			this.updatePrecios(g)
		}

		if (col) {
			if (g.colHasta > 48 || col < 0) {
				this.error(this.translateService.get("FUERA_DE_RANGO"))
				return null
			}
			var inter = this.getInterseccion(vehiculo, col, g.colHasta, item.fechaActividad)
			if (inter?.length > 0 && !this.productoEditado?.esTercerizado()) {
				this.error(this.translateService.get("HAY_GRUPO_SOLAPADO"))
				inter.forEach((g) => {
					g["error"] = "solapado"
					setTimeout(() => {
						g["error"] = ""
					}, 1000)
				})
				return null
			}
		}
		this.updatePermiteEdicionCostost(g)
		//this.itemEditado.agregarGrupo(g);
		//this.grillaVehiculo.filter(v => v.vehiculo == vehiculo)[0].grupos.push(g);
		//this.itemEditado.agregarGrupo(g);
		this.nuevoGrupos = [...this.nuevoGrupos, g]
		this.scopeCambios["grupos"] = [...this.scopeCambios["grupos"], g]
		if (!this.grupos.length || moment(g.fechaActividad).isSame(this.itemEditado.fechaActividad, "date")) this.grupos = this.grupos.concat(g)
		//this.getGrupos(this.fechaActividadSeleccionada.value);
		return g
	}
	updatePermiteEdicionCostost(g: GrupoPax) {
		g["permiteGestionCostos"] =
			this.esAdministrador ||
			this.esJefeAdministracion ||
			moment(g.fechaActividad).startOf("date").isSameOrAfter(moment().subtract(14, "days").startOf("date"), "date")
	}
	updatePreciosInsumos(g: GrupoPax) {
		const promesas: Promise<any>[] = []
		g.puestos
			.filter((p) => p.personal)
			.forEach((p) => {
				promesas.push(
					this.proveedorService.getById(p.personal.id).then((prov) => {
						p.insumos
							.filter((i) => i.insumo)
							.forEach((i) => {
								i.precio = prov.getPrecioVenta(i.insumo.id, g.fechaActividad, this.item.moneda, this.itemEditado.cantidadPax) || 0
							})
					})
				)
			})
		return Promise.all(promesas)
	}
	public async isValid() {
		if (!this.item.esCotizacion) {
			if (
				this.item.itemsReserva.some((ir) => {
					return ir.porUbicar > 0 && !ir.esCancelada
				})
			) {
				return Promise.resolve(this.error("Hay activiades sin asignación."))
			}
		}
		if (this.item.itemsReserva.length == 0 && !this.item.esCotizacion) {
			return this.error("Debe tener al menos un servicio o producto cargado")
		}
		return Promise.resolve(true)
	}

	public deleteGrupo(grupo: GrupoPax): Promise<boolean> {
		//this.grillaVehiculo.filter(v => v.vehiculo == vehiculo)[0].grupos = this.grillaVehiculo.filter(v => v.vehiculo == vehiculo)[0].grupos.filter(g => g != grupo && g.id != grupo.id);
		//this.itemEditado.quitarGrupo(grupo);
		const borrar = (grupo) => {
			const gExistente = this.getGrupo(grupo)
			gExistente.quitarGruposReserva(this.itemEditado.quitarGrupo(gExistente))
			//this.updateModificados(grupo)

			if (!gExistente.gruposReserva?.length) {
				this.grupos = [...this.grupos.filter((g) => g?.UUID != gExistente?.UUID)]
				this.delegados = [...this.delegados.filter((g) => g?.UUID != gExistente?.UUID)]
				this.gruposEliminados = [...this.gruposEliminados, gExistente]
			} else if (this.nuevoGrupos.find((g) => g?.UUID == gExistente?.UUID) == undefined) {
				this.nuevoGrupos.push(gExistente)
			}

			this.alquilados = this.grupos.filter((g) => g.esAlquiler)
			this.aplicarFiltrosVehiculos(this.productoEditado, false)
			return Promise.resolve(true)
		}
		if (grupo.gruposReserva.length == 1 && grupo.id) {
			return new Promise((resolve) => {
				this.confService.confirm({
					key: "genConf",
					header: "Borrar grupo",
					message:
						"Al no quedar pasajeros en el grupo este será borrado. Esto implica eliminar gastos asociados y asignaciones. " +
						"Los proveedores ya confirmados que requieren confirmación serán notificados de la cancelación ¿Desea continuar?",
					accept: () => {
						return borrar(grupo)
					},
					reject: () => {
						return resolve(false)
					}
				})
			})
		} else {
			return borrar(grupo)
		}

		//this.nuevoGrupos = this.nuevoGrupos.filter((g) => g?.UUID != grupo?.UUID)
		//this.getGrupos(this.fechaActividadSeleccionada.value);
		//this.itemEditado.quitarGrupo(grupo);
	}
	public onDragOver(event) {
		event.target.classList.add("over")
	}

	public onDragLeave(event) {
		event.target.classList.remove("over")
		event.target.classList.remove("over-disabled")
	}
	public setDragGrupoPax(event: DragEvent, cantidad: number, esSelectorPaxs: boolean = false, paxSelect: boolean = false) {
		var itemReserva = ItemResumenReserva.fromData(this.itemEditado)
		if (itemReserva.id == null) itemReserva.id = -1
		this.dragDropContext.type = "paxs"
		this.dragDropContext.paxSelection = paxSelect && this.porUbicar > 1
		this.dragDropContext.fromContext = new MovimientoDragDrop(null, !esSelectorPaxs ? null : new GrupoReserva(null, cantidad), cantidad)
	}
	public recalcularPrecio = async (item?: ItemReserva, grupo?: GrupoPax, producto?: Producto) => {
		const _item = item || this.itemEditado
		const _grupo = grupo || (_item.reservas?.length && this.getGrupo(_item.reservas[0].grupoResumen))
		const _producto = producto || this.productoEditado || (await this.productoService.getById(_item.producto.id))
		if (_item.reservas.length) {
			this.updatePreciosInsumos(_grupo).then(async () => {
				_item.precioPerCapita = await this.updatePrecio(_producto, _item)
			})
		} else {
			_item.precioPerCapita = await this.updatePrecio(_producto, _item)
		}
	}
	public ocuparLugar(evento: MovimientoDragDrop, itemReserva?: ItemReserva) {
		const item: ItemReserva = itemReserva || this.itemEditado
		item.agregarGrupoReserva(evento.grupoReservaDestino)
		if (evento.grupoReservaOrigen) item.agregarGrupoReserva(evento.grupoReservaOrigen)
		if (evento.grupoReservaOrigen?.cantidadPax <= 0) {
			item.quitarGrupoReserva(evento.grupoReservaOrigen)
		}
		if (evento.grupoOrigen?.gruposReserva?.length == 0) {
			this.grupos = [...this.grupos.filter((g) => g.UUID !== evento?.grupoOrigen?.UUID)]
		}
		this.updateModificados(evento.grupoOrigen)
		this.updateModificados(evento.grupoDestino)
		if (this.productoEditado?.esTercerizado()) {
			this.updatePreciosInsumos(evento.grupoDestino).then(async () => {
				if (!this.readonly && !item.precioEditado) {
					item.precioPerCapita = await this.updatePrecio(this.productoEditado, item)
				}
			})
		}
		this.grupos = [...this.grupos]
		this.alquilados = this.grupos.filter((g) => g.esAlquiler)
		this.aplicarFiltrosVehiculos(this.productoEditado, true)
	}
	public updateModificados(grupo: GrupoPax) {
		if (!grupo) return
		const i = this.nuevoGrupos.findIndex((g) => g?.UUID == grupo?.UUID)
		if (i >= 0) {
			this.nuevoGrupos[i] = grupo
		} else {
			this.nuevoGrupos.push(grupo)
		}

		this.scopeCambios["grupos"] = [...this.scopeCambios["grupos"], grupo]
	}
	public actualizarBloqueos() {
		this.bloqueoFechaService.getAll().then((r) => {
			this.fechasBloqueadas = r
			this.fechasBloqueadasDate = r.map((d) => d.fecha)
		})
	}
	public esBloqueada(date: Date): boolean {
		return this.getBloqueo(date) != undefined
	}

	public get porUbicar() {
		return this.itemEditado?.porUbicar || 0
	}
	public regiones: Region[] = []
	ngOnInit() {
		const $this = this
		this.puedeVerCostos = this.proveedorAG.esVisible(this.usuario) || this.preciosAG.esVisible(this.usuario)
		this.regionService.getHabilitados().then((r) => {
			this.regiones = r || []
			this.multiplesRegiones = this.regiones.length > 1
		})
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					$this.readonly = true
				} else if (u?.clonar) {
					$this.clonar = true
				}
			})
		)
		this.nacionalidadService.getAll().then((r) => {
			this.nacionalidades = r || []
		})
		this.hotelService.getConProveedor().then((r) => {
			this.hotelesProveedor = r || []
		})
		this.esGestorReserva = this.reservaAG.esVisible(this.usuario)
		if (this.route.snapshot.url.some((u) => u.path == "reserva" || u.path == "cotizacion")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				let idCliente: number = <number>params["clienteId"]
				if (!this.service) return
				if (id) {
					this.updateData({ id: id })
				} else {
					this.creando = true
					this.item = this.service ? this.service.newEnt() : null
					this.item.fechaVencimiento = moment(this.item.fechaRegistro).add(1, "days").startOf("date").add(8, "hours").toDate()
					this.item.tipoAjuste = "porcentaje"
					if (this.route.snapshot.url.some((u) => u.path == "cotizacion")) {
						this.esCotizacion = true
						this.item.esCotizacion = true
					}
					if (idCliente) {
						this.clienteService.getById(idCliente).then((c) => {
							this.item.cliente = c
							this.clienteSeleccionado = c
						})
					}
				}
				if (this.authService.esClienteExterno) {
					this.item.cliente = this.authService.usuario.cliente
					this.item.canalVenta = CanalVenta.CLIENTE_EXTERNO
					this.esCotizacion = true
					this.listadoPreciosService.getByCliente(this.item.cliente.id).then((r) => {
						this.updateListados(r)
					})
				} else {
					this.listadoPreciosService.getHabilitados(null).then((r) => {
						this.updateListados(r)
					})
				}
			})
		}
		this.actualizarBloqueos()
		let f = new FiltroFactura("proveedor_filtros_lst", null)
		f.guardable = false
		f.size = 1000

		this.tipoProveedorService.getByCodigo("VE").then((v) => {
			if (v) {
				f.tipoProveedor = new Descriptivo("VE", null, v.id)
			}
			this.proveedorService.getDescriptivos(f).then((ps) => {
				this.vendedores = ps.map((p) => Descriptivo.fromData(p))
			})
		})

		this.tipoPuestoService.getByCodigo(TipoPuesto.ALQUILER_AUTO).then((r) => (this.puestoAlquiler = r))
		this.vehiculoService.getAll().then((r) => {
			this.vehiculos = r
		})

		this.precioChangeBS.pipe(debounceTime(500)).subscribe((r) => {
			if (this.itemEditado?.id && this.item.esFacturado && r != this.itemEditado.precioPerCapitaOriginal) {
				this.confService.confirm({
					key: "genConf",
					header: this.translateService.get("CONFIRMAR_CAMBIO_PRECIO"),
					message: this.translateService.get("MENSAJE_RESERVA_FACTURADA_PRECIO"),
					accept: () => {},
					reject: () => {
						this.itemEditado.precioPerCapita = this.itemEditado.precioPerCapitaOriginal
					}
				})
			}
		})
	}
	public getColByHora(date: Date) {
		if (!date || !moment(date).isValid()) return 16
		let col = moment(date).get("hour") * 2 + Math.round(moment(date).get("minutes") / 30)
		return col
	}
	public mostrarSeleccionVehiculo(event, servicio) {
		event && event.stopPropagation()
		this.itemEditado = servicio
		this.abrirSeleccionarVehiculo = true
	}
	public autoasignar = async (
		event,
		item?: ItemReserva,
		vehiculo?: Vehiculo,
		cantidadParam?: number,
		g?: GrupoPax[],
		hora?: Date,
		vehiculosEnBusqueda?: Vehiculo[],
		actualizarPrecio: boolean = true
	) => {
		event && event.stopPropagation()
		const grupos = g || this.grupos
		const cantidad = cantidadParam || this.porUbicar || item.cantidadPax
		const producto = await this.productoService.getById(item.producto?.id)
		const itemToAsign = item || this.itemEditado

		if (!producto.esServicio() && !producto.esTercerizado() && !producto.esTransfer()) {
			return
		}

		if (cantidad > producto.cantidadMaxGrupo) {
			return this.error(this.translateService.get("ERROR_ASIGNACION_BASE"))
		}

		let grupo: GrupoPax

		if (!vehiculo) {
			const opciones = grupos.filter(
				(g) =>
					Producto.esMismaFamilia(producto, g.producto) &&
					g.lugaresLibres >= cantidad &&
					!g.bloqueado &&
					moment(g.fechaActividad).isSameOrAfter(hora || item.pickUp) &&
					(!item?.proveedorTentativo || (item?.proveedorTentativo && g.puestos[0]?.proveedor?.id === item.proveedorTentativo))
			)
			grupo = opciones?.length ? opciones.find((g) => g.tieneReserva(item)) || opciones[0] : null
		}

		const vehiculos = vehiculosEnBusqueda || this.vehiculosDisponibles
		if (
			!grupo ||
			(!grupo.producto?.esTercerizado() && !grupo.aplicaVehiculo(grupo.vehiculoAsignado)) ||
			(grupo.producto?.esTercerizado() && grupo.puestos[0].proveedor && !vehiculos.some((v) => v.proveedor.id == grupo.puestos[0].proveedor.id))
		) {
			const v =
				vehiculo ||
				vehiculos.find(
					(v) =>
						(item?.proveedorTentativo && v.proveedor?.id === item.proveedorTentativo) ||
						(!item?.proveedorTentativo &&
							((producto.esTercerizado() && producto.aplicaTercerizado(v)) || (!producto.esTercerizado() && producto.cumpleVehiculo(v))) &&
							!this.grupos.find((g) => g.vehiculoAsignado?.id == v.id) &&
							v.capacidad >= cantidad)
				)

			if (v) {
				grupo = this.agregarGrupo(v, itemToAsign, producto, null, this.getColByHora(hora || producto.horaInicio))
				if (v.proveedor) {
					grupo.puestos[0].personal = v.proveedor
				}
			}
		}

		if (grupo) {
			const grupoReserva = new GrupoReserva(null, cantidad)
			//grupoReserva.itemReserva = ItemResumenReserva.fromData(itemToAsign);
			const m = new MovimientoDragDrop(null, null, cantidad, "paxs", grupo, grupoReserva)
			grupo.agregarGrupoReserva(grupoReserva)
			this.ocuparLugar(m, item)
		} else {
			return this.error(this.translateService.get("ERROR_ASIGNACION"))
		}
		if (producto.esTercerizado() && actualizarPrecio) {
			this.updatePreciosInsumos(grupo).then(async () => {
				item.precioPerCapita = await this.updatePrecio(producto, item)
			})
		}
	}
	public removePaxs(event) {
		var mov = this.dragDropContext.fromContext
		if (mov.grupoReservaOrigen && mov.cantidad > 0) {
			mov.grupoReservaOrigen.cantidadPax -= mov.cantidad
			if (mov.grupoReservaOrigen.cantidadPax <= 0) {
				if (mov.grupoOrigen.gruposReserva?.length == 1) {
					this.deleteGrupo(mov.grupoOrigen).then((r) => {
						if (!r) {
							mov.grupoReservaOrigen.cantidadPax += mov.cantidad
						}
					})
				} else {
					mov.grupoOrigen.quitarGrupoReserva(mov.grupoReservaOrigen)
					this.itemEditado.quitarGrupoReserva(mov.grupoReservaOrigen)
				}
			} else {
				this.itemEditado.agregarGrupoReserva(mov.grupoReservaOrigen)
			}
		}
		this.dragDropContext.reset()
		this.aplicarFiltrosVehiculos(this.productoEditado, true)
	}
	public updateDatosContacto(data) {
		if (!data || !this.item) return
		this.clienteService.getById(data.id, this.customLoadingService).then((c) => {
			if (!this.item.emailContacto) {
				this.item.emailContacto = c.email
			}
			if (!this.item.telefonoContacto) {
				this.item.telefonoContacto = c.telefono
			}
			if (!this.item.nacionalidadPax) {
				this.item.nacionalidadPax = c.nacionalidad
			}
			if (!this.item.idiomaPax) {
				this.item.idiomaPax = c.idioma
			}
		})
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}

	public agregarServicios = async (item?: ItemReserva, index?: number, esHospedaje: boolean = false) => {
		let actualizar = true
		this.filtroProducto.searchStr = null
		if (item) {
			actualizar = false
		}

		if (item && item.esCancelada) {
			this.error("No se puede modificar un servicio cancelado")
			return
		}
		if (this.grupoTemporal) {
			this.error("Debe mover el grupo de la zona de cambio de fecha para continuar")
			return
		}
		this.grupoTemporal = null
		if (this.fechaSub) this.fechaSub.unsubscribe()
		if (this.grupoTemporal) {
			return this.error("Debe terminar de mover el grupo en el cambio de fecha.")
		}
		this.grupoTemporal = null
		this.seleccionProducto = null
		this.fechaSub = this.fechaActividadSeleccionada.valueChanges
			.pipe(
				debounceTime(500),
				map((value) => {
					if (value && moment(value, ["MM/DD/YYYY", "DD/MM/YYYY"], true).isValid()) {
						return value
					} else {
						return null
					}
				})
			)
			.subscribe((r: Date) => {
				if (r) {
					const mismaFecha = this.itemEditado.fechaActividad && moment(this.itemEditado.fechaActividad).isSame(r, "date")
					if (!mismaFecha || this.grupos?.length == 0) {
						this.itemEditado.fechaActividad = r
						if (this.itemEditado.producto) {
							this.buscarProducto(this.itemEditado.producto, actualizar, mismaFecha).then(async (p) => {
								if (p?.esFamilia || p?.esPaquete) {
									this.itemEditado.configuracionPaquete.forEach((c) => {
										this.updatePrecioPaquete(c)
									})
								}
							})
						}
					}
				}
			})
		this.productoEditado = null
		if (index >= 0) {
			this.itemEditado = ItemReserva.fromData(item)
			this.cantidadPax = this.itemEditado.cantidadPax
			this.seleccionProducto = item.producto ? ProductoCabecera.fromData(await this.productoService.getById(item.producto?.id)) : null
			if (this.seleccionProducto) this.buscarProducto(this.seleccionProducto, false)
			this.opearcionDialogo = this.translateService.get("GUARDAR")
			this.indexServicioEditado = this.item.itemsReserva.findIndex((i) => i.UUID == item.UUID)
		} else {
			this.itemEditado = new ItemReserva()
			this.itemEditado.hotel = this.item.hotel ? await this.hotelService.getById(this.item.hotel.id) : null
			this.itemEditado.direccionAlternativa = this.item.direccionAlternativa
			this.indexServicioEditado = -1

			this.itemEditado.fechaActividad = this.configuracionEntorno.fechaVencimientoPorDefault ? this.item.fechaVencimiento : null
			this.itemEditado.nombrePax = this.item.nombrePax
			this.cantidadPax = this.item.cantidadPax
			this.itemEditado.baseCalculo = this.item.cantidadPax
			this.itemEditado.idiomaServicio = this.item.idiomaPax
			this.opearcionDialogo = this.translateService.get("AGREGAR")
			this.itemEditado.impuestosIncluidos = this.listadoSeleccionado?.impuestosIncluidos
		}
		if (!this.itemEditado.nombrePax) {
			this.itemEditado.nombrePax = this.item.nombrePax
		}
		this.fechaActividadSeleccionada.setValue(this.itemEditado.fechaActividad)
		this.scopeCambios = { grupos: [] }
		this.delegados = []

		if (esHospedaje) {
			this.mostrarNuevoHospedaje = true
			if (!item) {
				this.itemEditado.hotel = null
			}
		} else {
			this.mostrarNuevoProducto = true
		}
	}

	public get servicioEditable() {
		return this.permiteModificarReservaFacturada
	}
	public updateGrupos = (g: GrupoPax) => {
		const encontrado = this.grupos.find((gr) => gr.UUID === g.UUID) || g
		if (this.nuevoGrupos.find((gr) => gr.UUID != g.UUID)) {
			this.nuevoGrupos.push(g)
		}
		encontrado.esDelegado = g.esDelegado
		encontrado.esAlquiler = g.esAlquiler
		this.updateModificados(g)
		this.grupos = [...this.grupos]
		this.item.itemsReserva.forEach((ir) => {
			ir.updateGrupo(encontrado)
		})
		this.delegados = this.grupos.filter((g) => g.esDelegado)
		this.alquilados = this.grupos.filter((g) => g.esAlquiler && !g.esDelegado)
	}
	public getGrupos = (d: Date, producto?: Producto) => {
		var f = new FiltroGrupo(null)
		f.fecha = d //moment(d).startOf("day").toDate()
		f.fechaHasta = d //moment(d).toDate()
		//f.idItemReserva = this.itemEditado?.id ? this.itemEditado.id : null;
		return this.grupoService.getAll(f).then((r) => {
			this.grupos = r.filter((g) => !this.nuevoGrupos.some((gg) => gg?.UUID == g?.UUID) && !this.gruposEliminados.some((gg) => gg?.UUID == g?.UUID))
			this.grupos = this.grupos.concat(this.nuevoGrupos.filter((g) => g && moment(g.fechaActividad).isSame(d, "date") && g.gruposReserva?.length > 0))
			this.grupos.forEach((g) => {
				this.itemEditado.reservas
					.filter((ir) => ir.grupoResumen?.UUID == g.UUID)
					.forEach((ir) => {
						const found = g.gruposReserva.find((gr) => gr.UUID == ir.UUID)
						let indx = found ? g.gruposReserva.indexOf(found) : null
						if (indx >= 0) {
							g.gruposReserva[indx] = ir
						}
					})
				this.updatePermiteEdicionCostost(g)
			})

			this.alquilados = this.grupos.filter((g) => g.esAlquiler)
			this.delegados = this.grupos.filter((g) => g.esDelegado && !g.esAlquiler)

			this.aplicarFiltrosVehiculos(producto)
			return this.grupos
			//	this.updateGrilla();
		})
	}
	public esAgrupado(item: ItemReserva) {
		return item.agrupado
	}
	public setAgrupado(estado, item: ItemReserva) {
		estado?.originalEvent && estado?.originalEvent.stopPropagation()
		item.agrupado = estado.checked
		if (item.familiaProducto) {
			this.item.itemsReserva
				.filter((i) => i.familiaProducto?.id == item.familiaProducto?.id && moment(i.fechaActividad).isSame(item.fechaActividad, "date"))
				.forEach((i) => {
					i.agrupado = item.agrupado
				})
		}
	}

	public agregarAlquiler() {
		if (this.readonly) return
		this.alquilados.push(null)
	}
	public getFila(grupo: GrupoPax) {
		if (grupo.esAlquiler) return this.alquilados.indexOf(grupo) + this.vehiculosDisponibles.length
		if (grupo.esDelegado) return this.delegados.indexOf(grupo) + this.vehiculosDisponibles.length + this.alquilados.length + 1
		if (this.vehiculosDisponibles.some((v) => v?.proveedor)) {
			return this.vehiculosDisponibles.map((v) => v.proveedor.id).indexOf(grupo.puestos[0].proveedor.id)
		} else {
			return this.vehiculosDisponibles.map((v) => v.id).indexOf(grupo.vehiculoAsignado.id)
		}
	}
	public grupoBorrable(grupo: GrupoPax) {
		return grupo.id == null || grupo.tieneItem(this.itemEditado)
	}
	public guardar = async (esHospedaje: boolean = false) => {
		//this.itemEditado.gruposReservados = this.grupos.filter(g => g.tieneItem(this.itemEditado));

		if (this.grupoTemporal) {
			return this.error("Debe terminar de mover el grupo en el cambio de fecha.")
		}
		if (
			!this.itemEditado.fechaActividad &&
			!this.itemEditado.esTipoServicio(TipoProducto.PAQUETE) &&
			!this.itemEditado.esTipoServicio(TipoProducto.HOSPEDAJE) &&
			!this.itemEditado.esTipoServicio(TipoProducto.PRODUCTO)
		) {
			return this.error("Debe indicar una fecha de actividad")
		}
		//this.actualizarPickup()
		if (
			this.itemEditado.porUbicar > 0 &&
			!this.item.esCotizacion &&
			(this.productoEditado?.esTransfer() || this.productoEditado?.esServicio() || this.productoEditado.esTercerizado())
		) {
			return this.error(this.translateService.get("QUEDAN_PAXS_SIN_AS_5"))
		}
		if (
			!this.item.esCotizacion &&
			this.itemEditado.reservas.length > 0 &&
			this.itemEditado.reservas.reduce((a, gr) => (a += gr.cantidadPax), 0) != this.itemEditado.cantidadPax
		) {
			return this.error("LA_CANTIDAD_DE_PASAJEROS_ASIGNADOS_NO_COINCIDE_CON_LA_CANTIDAD_DE_PASAJEROS_TOTALES")
		}

		this.itemEditado.reservas.forEach((reserva) => {
			if (this.itemEditado.producto.codigo != reserva.grupoResumen.producto.codigo) {
				this.productoEditado.puestosACubrir.forEach((puesto) => {
					if (!reserva.grupoResumen.puestos.some((p) => p.puesto.tipoPuesto.id == puesto.tipoPuesto.id)) {
						reserva.grupoResumen.puestos.push(new PuestoServicioReserva(null, puesto.personalDefault, puesto.clonar(true)))
					}
					this.item.grupos
						?.filter((grupo) => grupo.UUID == reserva.grupoResumen.UUID)
						?.forEach((grupo) => {
							grupo.puestos = reserva.grupoResumen?.puestos
						})
				})
			}
			this.grupos
				.filter((g) => g.UUID == reserva.grupoResumen.UUID)
				.forEach((g) => {
					reserva.grupoResumen.puestos = g.puestos
				})
		})
		/*if (this.itemEditado.hayGruposAnteriores(this.itemEditado.pickUp)) {
			const confirmUpdateDate = confirm(this.translateService.get("FECHA_MENOR_PICKUP"))
			if (confirmUpdateDate) {
				this.itemEditado.pickUp = this.itemEditado.fechaActividad
			}
		}*/
		var precio = this.listadoSeleccionado
			? this.listadoSeleccionado.getPrecioValor(this.productoEditado, this.itemEditado.fechaActividad, this.itemEditado.cantidadPax || 0)
			: 0

		this.itemEditado.tipoProducto = this.productoEditado.tipoProducto

		if (this.indexServicioEditado < 0) {
			!this.productoEditado.esPaquete && this.item.itemsReserva.push(this.itemEditado)

			if (this.productoEditado.esPrograma) {
				this.procesarCreacionPrograma()
			} else if (this.productoEditado.esPaquete) {
				this.procesarCreacionPaquete(Paquete.fromData(this.productoEditado), this.itemEditado)
			}
		} else {
			this.itemEditado.reservas.forEach((gr) => {
				gr.grupoResumen = GrupoPaxResumen.fromData(this.getGrupo(gr.grupoResumen))
			})
			this.item.itemsReserva[this.indexServicioEditado] = this.itemEditado
		}

		if (esHospedaje) {
			if (this.item.itemsReserva.filter((ir) => ir.checkIn)?.length == 1) {
				this.item.hotel = this.itemEditado.hotel
			}

			this.item.itemsReserva
				.filter((ir) => !ir.checkIn && moment(ir.fechaActividad).isBetween(moment(this.itemEditado.checkIn), moment(this.itemEditado.checkOut)))
				.forEach((ir) => {
					ir.hotel = this.itemEditado.hotel
				})
		}

		this.itemEditado = new ItemReserva()
		this.itemEditado.hotel = this.item.hotel ? await this.hotelService.getById(this.item.hotel.id) : null
		this.itemEditado.direccionAlternativa = this.item.direccionAlternativa
		this.itemEditado.impuestosIncluidos = this.listadoSeleccionado?.impuestosIncluidos

		this.mostrarNuevoProducto = false
		this.mostrarNuevoHospedaje = false
		this.seleccionProducto = null
		this.editandoPrecio = false
		this.fechaSub.unsubscribe()
		this.grupos = []
		this.scopeCambios = { grupos: [] }
	}
	public actualizarGrupo = (asignado: boolean, grupo: GrupoPax) => {
		if (asignado) {
			if (this.itemEditado.reservas.find((r) => r.grupoResumen.key == grupo.key)) {
				this.itemEditado.reservas.find((r) => r.grupoResumen.key == grupo.key).grupoResumen = GrupoPaxResumen.fromData(grupo)
			}
			this.updateModificados(grupo)
		}
	}
	async procesarCreacionPaquete(paquete: Paquete, item: ItemReserva, cantidad?: number, configuracionPaquete?: ConfiguracionPaqueteItem[]) {
		const items: ItemReserva[] = []
		const configuraciones = configuracionPaquete || item.configuracionPaquete

		const confFiltradas = configuraciones.filter((c) => c.cantidad > 0)
		for (const p of confFiltradas) {
			const producto = await this.productoService.getById(p.producto.id)

			let i = new ItemReserva()
			i.observaciones = item.observaciones
			i.notaCliente = item.notaCliente
			i.cantidadPax = cantidad || p.cantidad
			i.fechaActividad = p.fechaActividad || item.fechaActividad
			i.nombrePax = this.item.nombrePax
			i.idiomaServicio = item.idiomaServicio || this.item.idiomaPax
			i.producto = producto
			i.baseCalculo = this.item.cantidadPax
			i.hotel = this.item.hotel ? await this.hotelService.getById(this.item.hotel.id) : null
			i.precioPerCapita = p.precioPerCapita || (await this.updatePrecio(producto, i, null, item.baseCalculo || p.cantidad))
			i.tipoProducto = producto.tipoProducto
			i.tipoIVA = producto.tipoIVA
			i.productosAsociados = []
			i.ajuste = item.ajuste
			i.impuestosIncluidos = this.listadoSeleccionado?.impuestosIncluidos
			i.restricciones = [...item.restricciones]
			i.variante = producto.tipoVariante
			i.pickUp =
				item.pickUp ||
				(producto.horaInicio
					? moment(i.fechaActividad)
							.set("hour", moment(producto.horaInicio).get("hour"))
							.set("minute", moment(producto.horaInicio).get("minute"))
							.toDate()
					: i.fechaActividad)
			i["productoEnt"] = producto

			producto.productosAsociados.forEach((p) => {
				const productoAsociado = new ProductoAsociadoReserva(
					null,
					p.asociado,
					false,
					0,
					p.esPorServicio ? p.cantidad : p.cantidad * i.cantidadPax,
					0,
					p.esPorServicio,
					p.cantidad,
					null,
					p.conPrecio,
					i.impuestosIncluidos
				)
				i.productosAsociados.push(productoAsociado)
			})
			i.productosAsociados = [...i.productosAsociados]

			this.item.agregarItem(i)
			if (producto.esPrograma) {
				this.procesarCreacionPrograma(producto, i.cantidad, item || this.itemEditado)
			} else if (p.autoasignar && !this.item.esCotizacion) {
				items.push(i)
			}
		}

		for (let i = 0; i < items.length; i++) {
			const ite = items[i]
			await this.autoasignar(
				null,
				ite,
				null,
				ite.cantidadPax,
				await this.getGrupos(ite.fechaActividad),
				ite.pickUp,
				await this.aplicarFiltrosVehiculos(ite["productoEnt"]),
				false
			)
		}
	}
	async procesarCreacionPrograma(_producto?: Producto, cantidad?: number, itemReserva?: ItemReserva) {
		const prod = _producto || this.productoEditado
		const item = itemReserva || this.itemEditado
		prod["productosPrograma"]?.forEach(async (producto: CronogramaProducto) => {
			item.configuracionPrograma = new ConfiguracionPrograma()
			prod.puestosACubrir.forEach((puesto) => {
				let p = new PuestoServicioPrograma()
				p.puesto = puesto.clonar(true)
				item.configuracionPrograma.puestos.push(p)
			})

			let i = new ItemReserva()
			i.observaciones = item.observaciones
			i.notaCliente = item.notaCliente
			i.baseCalculo = cantidad || item.baseCalculo || item.cantidadPax
			i.cantidadPax = cantidad || item.baseCalculo || item.cantidadPax
			i.fechaActividad = moment(item.fechaActividad)
				.add(producto.dia - 1, "day")
				.toDate()
			i.pickUp = moment(i.fechaActividad)
				.set("hour", moment(producto.horaActividad).get("hour"))
				.set("minutes", moment(producto.horaActividad).get("minutes"))
				.toDate()
			i.nombrePax = this.item.nombrePax
			i.impuestosIncluidos = this.listadoSeleccionado?.impuestosIncluidos
			i.idiomaServicio = item.idiomaServicio || this.item.idiomaPax
			i.producto = producto.producto
			i.programaId = prod.id
			i.idiomaServicio = item.idiomaServicio || this.item.idiomaPax
			i.hotel = this.item.hotel ? await this.hotelService.getById(this.item.hotel.id) : null
			i.precioPerCapita = 0
			i.tipoProducto = producto.producto.tipoProducto
			i.tipoIVA = producto.producto.tipoIVA
			i.productosAsociados = []
			producto.producto.productosAsociados.forEach((p) => {
				const productoAsociado = new ProductoAsociadoReserva(
					null,
					p.asociado,
					false,
					0,
					p.esPorServicio ? p.cantidad : p.cantidad * i.cantidadPax,
					0,
					p.esPorServicio,
					p.cantidad,
					null,
					p.conPrecio,
					i.impuestosIncluidos
				)
				if (p.conPrecio) {
					this.updateProductoAdicional(p.asociado, productoAsociado)
				}
				i.productosAsociados.push(productoAsociado)
			})
			i.productosAsociados = [...i.productosAsociados]

			this.item.agregarItem(i)
			//i.precioPerCapita = await this.updatePrecio(producto.producto, i, null)
			const $this = this
			if (producto.autoasignar) {
				if (!this.item.esCotizacion) {
					this.getGrupos(i.fechaActividad, await this.productoService.getById(i.producto?.id)).then(async (g) => {
						$this.autoasignar(null, i, null, i.cantidadPax, g, producto.horaActividad, await $this.aplicarFiltrosVehiculos(producto.producto))
					})
				}
			}
		})

		let f = new FiltroVehiculo("dummy")
		f.fechaDesde = item.fechaActividad
		f.fechaHasta = moment(item.fechaActividad).add(this.productoEditado.duracion, "days").toDate()
		f.cantidadPax = item.cantidadPax
		f.programa = Descriptivo.fromData(this.productoEditado)

		this.vehiculoService.getDisponiblesPrograma(f).then((r: Vehiculo[]) => {
			this.vehiculosPrograma.set(
				this.productoEditado.id,
				r.filter((v) => this.productoEditado.cumpleVehiculo(v))
			)
		})
	}

	public async blanquear() {
		if (this.grupoTemporal) {
			this.grupos.push(this.grupoTemporal)
		}
		this.grupoTemporal = null
		this.indexServicioEditado = null
		this.itemEditado = new ItemReserva()
		this.itemEditado.hotel = this.item.hotel ? await this.hotelService.getById(this.item.hotel.id) : null
		this.itemEditado.direccionAlternativa = this.item.direccionAlternativa
		this.itemEditado.impuestosIncluidos = this.listadoSeleccionado?.impuestosIncluidos
		this.mostrarNuevoProducto = false
		this.seleccionProducto = null
		this.editandoPrecio = false
		this.fechaSub.unsubscribe()
		this.grupos = []
		this.nuevoGrupos = this.nuevoGrupos.filter((g) => this.scopeCambios.grupos.indexOf(g) < 0)
		this.scopeCambios = { grupos: [] }
	}
	public cancelar() {
		this.blanquear()
	}
	public aplicarFiltrosVehiculos = async (productoParam?: Producto, incluirVehiculosNoDisponibles: boolean = true) => {
		const producto = productoParam || this.productoEditado

		if (producto?.esTercerizado()) {
			if (!producto?.puestosACubrir[0]) return []
			await this.puestoServicioReservaService
				.buscarPuestos(moment(this.itemEditado.fechaActividad).toDate(), producto?.puestosACubrir[0]?.tipoPuesto)
				.then((opc: OpcionPuesto[]) => {
					this.vehiculosDisponibles = opc
						?.sort((a, b) => {
							if (a.proveedor.id > b.proveedor.id) return 1
							else return -1
						})
						.map((o) => {
							let v = new Vehiculo()

							v.capacidad = 9999
							v.profilePic = o.proveedor.profilePic
							v.descripcion = o.proveedor.descripcion
							v.patente = o.proveedor.descripcion
							v.proveedor = o.proveedor

							return v
						})
					if (incluirVehiculosNoDisponibles) {
						let puestosItem = []
						this.itemEditado.reservas.forEach((r) => (puestosItem = [...puestosItem, ...r.grupoResumen.puestos]))

						puestosItem.forEach((p) => {
							if (!this.vehiculosDisponibles.some((v) => v.proveedor?.id == p.proveedor?.id)) {
								let v = new Vehiculo()
								v.capacidad = 9999
								v.profilePic = p.proveedor.profilePic
								v.descripcion = p.proveedor.descripcion
								v.patente = p.proveedor.descripcion
								v.proveedor = p.proveedor
								this.vehiculosDisponibles.splice(0, 0, v)
							}
						})
					}
					this.grupos = [...this.grupos.filter((g) => !producto.esTercerizado() || g.aplicaProducto(producto))]
				})
		} else {
			this.vehiculosDisponibles = this.vehiculos.filter(
				(v) => !v.esBloqueado(this.itemEditado.fechaActividad) && producto && (!producto.vehiculosACubrir?.length || producto.cumpleVehiculo(v))
			)
			if (incluirVehiculosNoDisponibles) {
				const vehiculosItem = this.itemEditado.reservas.map((r) => r.grupoResumen.vehiculoAsignado).filter((v) => v != undefined)

				vehiculosItem?.length &&
					vehiculosItem.forEach((vehiculo) => {
						if (!this.vehiculosDisponibles.some((v) => v.id == vehiculo?.id)) {
							this.vehiculosDisponibles.splice(0, 0, vehiculo)
						}
					})
			}
		}

		return this.vehiculosDisponibles
	}

	public buscarProducto = async (v: any, actualizar: boolean, mismaFecha?: boolean) => {
		if (!v?.id) {
			this.productoEditado = v?.esFamilia ? v : null
			return Promise.resolve(v)
		}
		const cambioProducto = v?.id != this.productoEditado?.id
		this.productoEditado = await this.productoService.getById(v.id)
		if (this.itemEditado?.producto?.id != v.id) {
			this.itemEditado.esSubcategorizado = this.productoEditado.esSubcategorizado
			this.itemEditado.subcategorias = this.productoEditado.subcategorias.map((s) => s.clonar())
			this.itemEditado.tipoIVA = this.productoEditado.tipoIVA
			this.itemEditado.familiaProducto = this.productoEditado.familiaProducto
		}
		if (this.itemEditado.fechaActividad) {
			if (
				((!this.productoEditado || this.productoEditado.id != v.id || !mismaFecha || this.grupos?.length == 0) && this.itemEditado.esTercerizado()) ||
				this.itemEditado.esServicio() ||
				this.itemEditado.esTransfer()
			) {
				await this.getGrupos(this.itemEditado.fechaActividad, this.productoEditado)
			}
		}

		if (v?.id != this.itemEditado?.producto?.id || actualizar) {
			this.itemEditado.productosAsociados = []
			this.productoEditado.productosAsociados.forEach((p) => {
				const productoAsociado = new ProductoAsociadoReserva(
					null,
					p.asociado,
					false,
					0,
					p.esPorServicio ? p.cantidad : p.cantidad * this.itemEditado.cantidadPax,
					0,
					p.esPorServicio,
					p.cantidad,
					null,
					p.conPrecio,
					this.itemEditado?.impuestosIncluidos
				)
				if (p.conPrecio) {
					this.updateProductoAdicional(p.asociado, productoAsociado)
				}
				this.itemEditado.productosAsociados.push(productoAsociado)
			})
			this.itemEditado.productosAsociados = [...this.itemEditado.productosAsociados]
		}
		//llamo a get precio
		//

		if (actualizar && !this.productoEditado.esPaquete && this.itemEditado.fechaActividad) {
			this.itemEditado.duracion = this.productoEditado.duracion
			this.recalcularPrecio()
			this.itemEditado.agrupado = this.productoEditado.getAgrupado()
		}
		if (this.item.esCotizacion && this.productoEditado.esTercerizado() && !this.productoEditado.esPaquete && this.itemEditado.fechaActividad) {
			this.actualizarOpcionesProveedor(this.productoEditado, this.itemEditado.fechaActividad)
		}
		if (cambioProducto && !this.itemEditado.pickUp) {
			this.itemEditado.pickUp = new Date(this.itemEditado.fechaActividad)
			if (this.productoEditado.horaInicio) {
				const horaInicio = new Date(this.productoEditado.horaInicio)
				this.itemEditado.pickUp.setHours(horaInicio.getHours(), horaInicio.getMinutes())
			} else {
				this.itemEditado.pickUp.setHours(8, 0)
			}
		}
		this.delegados = this.grupos.filter((g) => g.esDelegado && Producto.esMismaFamilia(this.productoEditado, g.producto))
		return this.productoEditado
	}
	public getGrupo(g: GrupoPaxResumen | GrupoPax): GrupoPax {
		return this.grupos.find((grupo) => grupo.UUID == g.UUID) || GrupoPax.fromData(g)
	}
	private getPreciosByPuestos = (puestosConCosto: PuestoServicioReserva[]) => {
		if (puestosConCosto.length && puestosConCosto.every((p) => p.proveedor && (!p.insumos?.length || p.insumos.every((i) => !i.esPorServicio)))) {
			return puestosConCosto.map((p) => p.insumos.map((i) => i.precio).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)
		}
		return 0
	}
	calcularCosto = async (p: PuestoACubrir, fechaActividad: Date, idProveedor: { id: number }): Promise<number> => {
		const prov = idProveedor || p.proveedor || p.personalDefault
		if (!prov) return 0
		return await this.proveedorService.getById(prov.id).then(async (prov) => {
			if (p.insumos?.some((i) => i.esPorServicio)) {
				return 0
			}
			return p.insumos
				.map((i) => {
					return prov.getPrecioVenta(i.insumo?.id, fechaActividad, this.item.moneda, this.itemEditado.cantidadPax)
				})
				.reduce((a, b) => a + b, 0)
		})
	}

	public updatePrecioPaquete = async (i: ConfiguracionPaqueteItem) => {
		if (i.producto) {
			i.precioPerCapita = await this.updatePrecio(
				await this.productoService.getById(i.producto.id),
				this.itemEditado,
				null,
				this.itemEditado.baseCalculo || this.itemEditado.cantidadPax,
				i.fechaActividad
			)
		}
	}

	public updatePrecio = async (
		impProducto: Producto | ProductoCabecera,
		itemReserva: ItemReserva,
		adicional?: ProductoAsociadoReserva,
		cantidad?: number,
		fecha?: Date,
		visitados: number[] = []
	): Promise<number> => {
		let cantidadPax = itemReserva.esHospedaje() ? 1 : cantidad || itemReserva.baseCalculo || itemReserva.cantidad || 1
		const producto: Producto | ProductoCabecera =
			impProducto || this.productoEditado || (this.itemEditado?.producto && (await this.productoService.getById(this.itemEditado.producto?.id)))
		let precio = this.listadoSeleccionado.getBasePrecio(producto, fecha || itemReserva.fechaActividad, cantidadPax, [])
		if (visitados.some((v) => v == producto.id)) return 0
		visitados.push(producto.id)
		let precioValor
		const item = itemReserva || this.itemEditado
		if (item.precioEditado && !adicional) return item.precioPerCapita
		if (precio && precio.tipoCalculo == "D") {
			if (!precio.productoDependiente) return Promise.reject(this.error("El producto dependiente no está definido"))
			const itemDependiente = this.item.getItemByProducto(precio.productoDependiente)
			if (itemDependiente) {
				precioValor = (itemDependiente.precioPerCapita * precio.porcentual) / 100
			} else {
				precioValor = await this.updatePrecio(
					await this.productoService.getById(precio.productoDependiente?.id),
					itemReserva,
					null,
					cantidadPax,
					fecha || item?.fechaActividad,
					visitados
				)
				precioValor = (precioValor * precio.porcentual) / 100
			}
		} else if (!precio || (precio?.esCalculado && !producto.esPrograma && !producto.esPaquete)) {
			if (!precio?.esCalculado && (producto?.esServicio() || producto?.esTransfer() || producto?.esProducto() || producto?.esHospedaje())) {
				precioValor = 0
			} else if (item.reservas[0]) {
				const grupo = this.getGrupo(item.reservas[0].grupoResumen)
				await this.updatePreciosInsumos(grupo)
				if (grupo?.id && grupo.producto?.id === item.producto?.id) {
					precioValor = (await this.getPreciosByPuestos(grupo.puestos.filter((p) => p.insumos?.length))) || adicional?.importe || 0
				} else {
					precioValor = await this.getPreciosByPuestosACubrir(producto, item, adicional, grupo.proveedorPrincipal)
				}
			} else {
				precioValor = await this.getPreciosByPuestosACubrir(producto, item, adicional)
			}
			if (precio?.esCalculado && precio.porcentual && precio.tipoCalculo != "D") {
				precioValor = (precioValor * precio.porcentual) / 100
			}
		} else if (precio?.esCalculado && producto.esPrograma) {
			precioValor = await this.calcularPrecioPrograma(producto, itemReserva)
		} else if (!item.programaId) {
			if (!cantidadPax) cantidadPax = itemReserva.cantidad || 1
			let vPrecio = precio.precio
			if (precio.precio * cantidadPax < precio.precioMinimo) {
				vPrecio = precio.precioMinimo / cantidadPax
			}
			if (adicional) {
				precioValor = vPrecio || adicional.importe
			} else {
				precioValor = vPrecio || item.precioPerCapita
			}
		}
		return precioValor || 0
	}
	private calcularPrecioPrograma = async (producto: Producto | ProductoCabecera, itemReserva: ItemReserva) => {
		let precioValor = 0
		const productosPrograma = producto["productosPrograma"]
		for (const productoPrograma of productosPrograma) {
			const precioActualizado = await this.updatePrecio(productoPrograma.producto, itemReserva, null, itemReserva.baseCalculo || itemReserva.cantidadPax)
			precioValor += precioActualizado
		}

		return precioValor
	}
	public get puedeEditarListado() {
		return this.item.comprobante == null && !this.item.esCancelada && !this.item.estado?.esCerrada() && (!this.item?.id || this.esJefeAdministracion)
	}
	public async getPreciosByPuestosACubrir(producto: Producto | ProductoCabecera | Descriptivo, item: ItemReserva, adicional, proveedor?) {
		let precio = 0
		const puestos: PuestoACubrir[] = item.reservas.flatMap((gr) =>
			this.getGrupo(gr.grupoResumen)
				.puestos.filter((p) => p.esAdicional)
				.flatMap((p) => p.puesto)
		)
		let fullProducto = producto instanceof ProductoCabecera || producto instanceof Descriptivo ? await this.productoService.getById(producto.id) : producto
		let proveedorAplicable = proveedor?.id || item?.proveedorTentativo || null
		let puestosACubrir = fullProducto ? puestos.concat(...fullProducto.puestosACubrir.filter((p) => p.insumos?.length)) : []

		if (puestosACubrir.length && puestosACubrir.some((p) => !p.proveedor || p.personalDefault)) {
			precio = (
				await Promise.all(
					puestosACubrir.map(async (p) => await this.calcularCosto(p, item.fechaActividad, proveedorAplicable ? { id: proveedorAplicable } : null))
				)
			).reduce((a, b) => a + b, 0)
		} else precio = adicional ? adicional.importe : item.precioPerCapita

		return precio
	}
	public onCantidadPaxChange(event) {
		this.itemEditado.productosAsociados
			.filter((p) => !p.esPorServicio)
			.forEach((p) => {
				p.cantidad = Number(event.srcElement.value) * p.base
			})
		if (this.item.esCotizacion && this.productoEditado.esTercerizado()) {
			this.actualizarPreciosOpcionesProveedor(
				this.opcionesProveedor,
				null,
				this.productoEditado,
				this.itemEditado.fechaActividad,
				this.itemEditado.cantidadPax
			)
		}
		this.updatePrecio(this.productoEditado, this.itemEditado).then((p) => (this.itemEditado.precioPerCapita = p))
	}
	private getProductoKey(producto: Producto | Descriptivo | ProductoCabecera, fecha: Date) {
		return producto.id + moment(fecha).format("DDMMYYYY")
	}
	public async updateProductoAdicional(prodSeleccionado: Descriptivo, productoAdicional: ProductoAsociadoReserva) {
		this.productoService.getById(prodSeleccionado.id).then((prod) => {
			if (!productoAdicional.cantidad) {
				productoAdicional.cantidad = this.itemEditado.cantidadPax * prod.proporcion
				if (!prod.esDivisible) {
					productoAdicional.cantidad = Math.ceil(productoAdicional.cantidad)
				}
			}
			productoAdicional.esSubcategorizado = prod.esSubcategorizado

			this.updatePrecio(Producto.fromData(productoAdicional.producto), this.itemEditado, productoAdicional, productoAdicional.cantidad).then((precio) => {
				productoAdicional.importe = precio || 0
				productoAdicional.tipoIva = prod.tipoIVA
				if (prod.esSubcategorizado) {
					productoAdicional.subcategorias = prod.subcategorias.map((s) => s.clonar())
				}
				productoAdicional.impuestosIncluidos = this.itemEditado?.impuestosIncluidos
				productoAdicional.prevKey = this.getProductoKey(productoAdicional.producto, this.itemEditado.fechaActividad)
			})
		})
	}

	public quitarItem = (item, index) => {
		this.grupos.filter((g) => g.tieneItem(item)).forEach((g) => this.deleteGrupo(g))
		this.item.quitarItem(item, index)
		this.reasignarHospedaje()
	}
	public eliminarItem() {
		this.confService.confirm({
			key: "genConf",
			header: "Eliminar",
			message: this.getMensajeEliminar(),
			accept: () => {
				if (this.itemEditado.esPrograma) {
					this.item.itemsReserva
						.filter((item, index) => item.programaId == this.itemEditado.producto.id)
						.forEach((item) => {
							this.quitarItem(item, this.item.itemsReserva.indexOf(item))
						})
				}
				this.quitarItem(this.itemEditado, this.indexServicioEditado)

				this.blanquear()
				this.cancelarHospedaje()
			}
		})
	}

	getMensajeEliminar() {
		if (this.itemEditado.esPrograma) {
			return this.translateService.get("QUITAR_PROGRAMA")
		} else if (this.itemEditado.esHospedaje()) {
			return this.translateService.get("QUITAR_HOSPEDAJE")
		}

		return this.translateService.get("ELIMINAR_ITEM_RESERVA")
	}
	public tieneReserva(date: Date) {
		var gg = moment(date).startOf("day")
		return this.itemEditado.reservas.some((g) => moment(g.grupoResumen?.fechaActividad).startOf("day").isSame(gg))
	}

	public agregarProducto() {
		this.itemEditado.productosAsociados.push(new ProductoAsociadoReserva(null, null, true, 0, 0, 0))
	}
	public agregarVehiculo() {
		this.mostrarSeleccionarVehiculo = true
	}
	public agregarPuesto() {
		this.mostrarSeleccionPuesto = true
	}
	public get cabeceraCompleta() {
		return this.item.cantidadPax > 0 && this.item.listadoPrecio && this.item.cliente && this.item.fechaVencimiento
	}
	public editarPrecio() {
		if (this.itemEditado && this.itemEditado.producto && this.esGestorPrecios) {
			this.editandoPrecio = true
		}
	}
	public get esGestorPrecios() {
		return this.usuario.tieneRolOAdmin(["ROLE_MD_LIST_PRECIO", "ROLE_JEFE_ADMIN"])
	}
	public verHistorialComprobante() {
		this.mostrarHistorialComprobante = true
		this.comprobanteService.getByReserva(this.item.id, this.customLoading).then((c) => (this.historialComprobantes = c))
	}

	public verComprobante(event, comprobante: ComprobanteResumen) {
		let newRelativeUrl = this.router.createUrlTree(["comprobante/vista"], {
			queryParams: { id: comprobante.id }
		})
		let baseUrl = window.location.href.replace(this.router.url, "")
		window.open(baseUrl + newRelativeUrl, "_blank")
		event.stopPropagation()
	}
	public onCancelacionReserva(r: Reserva) {
		this.item = r

		if (this.itemEditado.esHospedaje() && this.itemEditado.hotel?.id == r.hotel?.id) {
			let i = this.item.itemsReserva.find((i) => i.esHospedaje() && !i.esCancelada)
			if (i) {
				this.item.hotel = i.hotel
			} else {
				this.item.hotel = null
			}

			this.reasignarHospedaje()
		}
		this.itemEditado = new ItemReserva()
	}

	reasignarHospedaje() {
		this.item.itemsReserva
			.filter((ir) => !ir.esHospedaje() && !ir.esCancelada)
			.forEach((ir) => {
				let hospedaje = this.item.itemsReserva.find(
					(irr) => irr.esHospedaje() && !irr.esCancelada && moment(ir.fechaActividad).isBetween(moment(irr.checkIn), moment(irr.checkOut))
				)

				if (hospedaje) {
					ir.hotel = hospedaje.hotel
				} else {
					ir.hotel = null
				}
			})
	}

	public mostrarDialogoMotivo(servicio: ItemReserva) {
		if (this.item.itemsReserva.some((ir) => !ir.id)) {
			return this.error(this.translateService.get("NO_CANCELAR_SERVICIO_RESERVA_MODIFICADA"))
		}
		if (this.item.itemsReserva.filter((r) => !r.esCancelada && r.id).length === 1) {
			this.error("No puede dejar una reserva sin servicios, por favor cancele la reserva")
			return
		}

		if (servicio.esPrograma) {
			const itemsRestantes = this.item.itemsReserva.filter((ir) => ir.id != servicio.id && ir.programaId != servicio.producto.id && ir.id)
			if (!itemsRestantes || itemsRestantes.length == 0) {
				this.error("No puede dejar una reserva sin servicios, por favor cancele la reserva")
				return
			}
		}
		this.itemEditado = servicio
		this.cancelacionComponent.display(this.item, servicio)
	}

	cambiarDisplay(v: boolean) {
		this.displayDevolucion = v
	}

	updateReserva() {
		this.service.getById(this.item.id).then((r) => {
			this.item = r
		})
	}
	public getBloqueo(date: Date) {
		let fechasBloqueadas = this.fechasBloqueadas.filter((d) => moment(d.fecha).isSame(moment(date), "date"))[0]
		return fechasBloqueadas
	}
	public puedeEditarPrecio() {
		return !this.readonly && this.permiteModificarReservaFacturada
	}
	public esEditable(adminOnly: boolean = false): boolean {
		return this.permiteModificarReservaFacturada && (!adminOnly || (adminOnly && this.esAdministrador) || !this.item?.id)
	}

	onClickAjuste(seleccion) {
		this.item.tipoAjuste = seleccion
		this.item.ajusteTotal = 0
	}

	actualizarPickup() {
		if (this.itemEditado.fechaActividad && this.itemEditado.pickUp) {
			const hour = moment(this.itemEditado.pickUp).hours()
			const minute = moment(this.itemEditado.pickUp).minutes()
			this.itemEditado.pickUp = moment(this.itemEditado.fechaActividad).set("hour", Number(hour)).set("minute", Number(minute)).toDate()
		}
	}
	updateAcciones(acciones: AccionesTabla[] = []) {
		/*if (this.accionesReservaComponente) {
			this.acciones = []
			this.accionesReservaComponente.getVisibles().then((a) => {
				this.acciones = a.map((accion) => {
					return {
						label: accion.label,
						icon: accion.icon,
						command: (e) => {
							accion.command(this.item, e)
						},
						visible: true
					}
				})*/
		this.acciones = [
			...acciones
				.filter((a) => a.esVisible(this.item))
				.map((accion) => {
					return {
						label: accion.label,
						icon: accion.icon,
						command: (e) => {
							accion.command(this.item, e)
						},
						visible: true
					}
				})
		]
		/*	})
			if (this.accionesReservaComponente.reserva?.id != this.item?.id) {
				this.accionesReservaComponente.reserva = this.item
			}
		}*/
	}
	public updateData = (r: any) => {
		this.creando = false
		const $this = this
		this.service.getById(r["id"]).then(async (r) => {
			$this.item = this.clonar ? Reserva.clonar(r) : r
			$this.rOriginal = Reserva.fromData(r)
			if ($this.authService.esClienteExterno && !$this.item.esCotizacion) {
				$this.readonly = true
			}

			$this._clienteSeleccionado = await $this.clienteService.getById($this.item.cliente?.id)
			if ($this.clienteSeleccionado?.activo === false && !$this.item?.id) {
				$this.clienteSeleccionado = null
				$this.error("El cliente seleccionado se encuentra inactivo")
			}
			if (!$this.item.comprobante) {
				$this.hacerDevolucion = false
			}

			if ($this.item.listadoPrecio.id) {
				$this.listadoPreciosService.getById($this.item.listadoPrecio.id).then((l) => {
					$this.listadoSeleccionado = l
					if (!$this.listadoSeleccionado?.impuestosIncluidos) {
						$this.itemEditado.impuestosIncluidos = $this.listadoSeleccionado?.impuestosIncluidos
					}
				})
			}
			$this.direccionOriginal = $this.item.direccionAlternativa
			if ($this.item.tienePrograma) {
				$this.item.itemsPrograma.forEach((itemPrograma) => {
					$this.productoService.getById(itemPrograma.producto.id).then((p) => {
						let f = new FiltroVehiculo("dummy")
						f.fechaDesde = itemPrograma.fechaActividad
						f.cantidadPax = itemPrograma.cantidadPax
						f.programa = Descriptivo.fromData(p)

						$this.vehiculoService.getDisponiblesPrograma(f).then((r: Vehiculo[]) => {
							$this.vehiculosPrograma.set(
								p.id,
								r.filter((v) => p.cumpleVehiculo(v))
							)
						})
					})
				})
			}
			$this._idiomaPaxOriginal = $this.item.idiomaPax
			$this.formPaxs = $this.formBuilder.group({
				paxs: $this.formBuilder.array([])
			})
			$this.item.paxs.forEach((p) => {
				$this.addPax(p)
			})
			//$this.updateAcciones()
		})
	}
	public asignar = (puesto: PuestoServicioReserva, servicio: ItemReserva) => {
		if (!servicio?.id) return
		this.puestoServicioReservaService
			.asignar(puesto)
			.then((r) => {
				this.success(this.translateService.get("ASIGNACION_CORRECTA"))
				puesto.gastos = r.gastos
				puesto.personal = r.personal
				puesto.estado = r.estado
			})
			.catch((r) => {
				puesto.personal = null
			})
	}

	mostrarCambioEstado = (puesto: PuestoServicioReserva, item: ItemReserva, event) => {
		const confirmacion = item.confirmaciones?.find((conf) => conf.tipoPuesto.id == puesto.tipoPuesto.id && conf.proveedor?.id == puesto.proveedor?.id)
		event && event.stopPropagation()

		/*if (confirmacion?.estado?.codigo == "CO") {
			return this.warning(this.translateService.get("ERROR_ESTADO"))
		}*/ // Comentado por comportamiento inestable

		this.confService.confirm({
			key: "genConf",
			header: "Aceptar asignación",
			message: this.translateService.get("Va a cambiar el estado de asignación a Confirmado. ¿Desea continuar?"),
			accept: () => {
				if (this.item?.id) {
					const a = new AprobacionProveedor()
					if (!confirmacion) {
						puesto.confirmar = true
						this.info(this.translateService.get(this.translateService.get("DEBE_GUARDAR_RESERVA")))
					} else {
						a.idPuesto = confirmacion.id
						this.puestoServicioReservaService.aprobarAsignacion([a]).then((r) => {
							confirmacion.estado = new EstadoPuesto(null, "CO", "Confirmado")
							this.success(this.translateService.get("ASIGNACION_ACTUALIZADA"))
							confirmacion.proveedor = confirmacion.proveedor
						})
					}
				} else {
					puesto.confirmar = true
				}
			}
		})
	}

	mostrarCambioEstadoHospedaje = (item: ItemReserva, event) => {
		const confirmacion = item.confirmaciones?.find(
			(conf) => conf.tipoPuesto.codigo == TipoPuesto.ALOJAMIENTO && conf.proveedor?.id == item.hotel?.proveedor
		)
		event && event.stopPropagation()

		if (confirmacion?.estado?.codigo == "CO") {
			return this.warning(this.translateService.get("ERROR_ESTADO"))
		}

		this.confService.confirm({
			key: "genConf",
			header: "Aceptar asignación",
			message: this.translateService.get("Va a cambiar el estado de asignación a Confirmado. ¿Desea continuar?"),
			accept: () => {
				if (this.item?.id) {
					const a = new AprobacionProveedor()
					if (!confirmacion) {
						item.confirmarHospedaje = true
						this.info(this.translateService.get(this.translateService.get("DEBE_GUARDAR_RESERVA")))
					} else {
						a.idPuesto = confirmacion.id
						this.puestoServicioReservaService.aprobarAsignacion([a]).then((r) => {
							confirmacion.estado = new EstadoPuesto(null, "CO", "Confirmado")
							this.success(this.translateService.get("ASIGNACION_ACTUALIZADA"))
							confirmacion.proveedor = confirmacion.proveedor
						})
					}
				} else {
					item.confirmarHospedaje = true
				}
			}
		})
	}

	desasignar = (puesto: PuestoServicioReserva, servicio: ItemReserva) => {
		if (!servicio?.id) return

		this.puestoServicioReservaService
			.desasignar(puesto)
			.then((r) => {
				this.success(this.translateService.get("DESASIGNACION_CORRECTA"))
				puesto.estado = new EstadoPuesto(null, "SD", "Sin Definir")
			})
			.catch((r) => {
				puesto.personal = null
			})
	}
	public get impuestosIncluidos() {
		return !MostrarImpuestos() && this.listadoSeleccionado?.impuestosIncluidos
	}
	public get multiplesImpuestos() {
		return this.item?.itemsReserva && this.item.itemsReserva.some((it) => it.tipoIVA?.codigo !== this.item.itemsReserva[0]?.tipoIVA?.codigo)
	}
	public get permiteModificarReservaFacturada() {
		return !this.item?.esFacturado || this.usuario.tieneRolOAdmin(["ROLE_MOD_RESERVA_FACT", "ROLE_JEFE_ADMIN"]) || !this.item?.id
	}
	verVoucher(event, item: ItemReserva) {
		event?.stopPropagation()
		this.itemEditado = item
		this.mostrarVoucher = true
	}

	verEncuesta(event, item: ItemReserva) {
		event?.stopPropagation()

		let query: NavigationExtras = {
			queryParams: {
				idItemReserva: item.id
			}
		}
		this.router.navigate(["respuesta-encuesta-actividades" + "/vista"], query)
	}
	public quitarGrupoTemporal(event) {
		event?.stopPropagation()
		if (this.grupoTemporal != null) {
			return this.error("Ya hay un grupo para mover")
		}
		const grupo = this.dragDropContext.fromContext
		if (!grupo || grupo instanceof GrupoPax == false) return
		this.grupoTemporal = grupo
		this.grupos = this.grupos.filter((g) => g.key != grupo.key)
		this.aplicarFiltrosVehiculos(this.productoEditado, false)
	}
	public startDragCambioFecha(evento) {
		this.dragDropContext.type = "grupos"
		this.dragDropContext.fromContext = this.grupoTemporal
		evento.stopPropagation()
	}
	public scrolling

	public get hotelSeleccionado(): Descriptivo {
		return this.item?.hotel
	}
	updateDireccion(event) {
		this.item.itemsReserva
			.filter((ir) => ir.direccionAlternativa == this.direccionOriginal)
			.forEach((ir) => (ir.direccionAlternativa = this.item.direccionAlternativa))
		this.direccionOriginal = this.item.direccionAlternativa
	}
	public set hotelSeleccionado(v: Descriptivo) {
		if (v && this.item?.hotel?.codigo != v?.codigo) {
			this.actualizarHotel(v)
		}
		this.item.hotel = v
	}
	actualizarHotel = async (hotel: Descriptivo) => {
		const h = await this.hotelService.getById(hotel.id)
		this.item.itemsReserva.filter((ir) => ir.hotel?.codigo == this.item.hotel?.codigo).forEach((ir) => (ir.hotel = h))
	}
	scrollDown = (element: Dialog, direction: "up" | "down") => {
		if (!this.scrolling) {
			this.scrolling = true
			if (direction == "up") {
				element.contentViewChild.nativeElement.scrollTop -= 75
			} else {
				element.contentViewChild.nativeElement.scrollTop += 75
			}
			setTimeout(() => {
				this.scrolling = false
			}, 100)
		}
	}

	asignarPuestoPrograma(event, puesto: PuestoServicioPrograma, servicio: ItemReserva) {
		this.item.itemsReserva
			.filter((i) => i.programaId == servicio.producto.id)
			.forEach((item) => {
				let p = item.reservas[0].grupoResumen.puestos.find((p) => p.tipoPuesto.id == puesto.puesto.tipoPuesto.id)
				if (p) p.personal = puesto.personal
			})
	}

	onSelectVehiculo(event, servicio: ItemReserva) {
		this.item.itemsReserva
			.filter((i) => i.programaId == servicio.producto?.id && i.reservas.length == 0)
			.forEach(async (itm) => {
				await this.getGrupos(itm.fechaActividad, await this.productoService.getById(itm.producto?.id))
				if (!itm.reservas?.length) {
					this.autoasignar(null, itm, event, itm.cantidadPax, null, itm.pickUp)
				}
			})
	}
	validarNuevoServicio = (form: NgForm) => {
		if (this.itemEditado.esHospedaje() && moment(this.itemEditado.checkOut).isBefore(moment(this.itemEditado.checkIn))) {
			return false
		}
		if (
			!this.itemEditado.fechaActividad &&
			!this.itemEditado.esTipoServicio(TipoProducto.PAQUETE) &&
			!this.itemEditado.esTipoServicio(TipoProducto.HOSPEDAJE)
		) {
			return false
		}
		if (this.itemEditado.esTipoServicio(TipoProducto.FAMILIA) || this.itemEditado.esTipoServicio(TipoProducto.PAQUETE)) {
			if (this.itemEditado.configuracionPaquete.every((i) => !i.cantidad)) {
				return false
			}
		}

		return form?.valid && this.seleccionProducto
	}
	getDescripcionPrograma(item: ItemReserva) {
		return this.item.itemsReserva.find((i) => i.producto.id == item.programaId)?.fullProductoDescripcion
	}

	public get esClienteExterno() {
		return this.authService?.esClienteExterno
	}

	getProductos = (filtro: FiltroProducto) => {
		filtro.size = 1000000
		filtro.incluirFamilias = true
		return this.productoService.getServicios(filtro).then((r) => r.filter((p) => !this.esClienteExterno || this.listadoSeleccionado.tieneProducto(p)))
	}

	getAdicionables = (filtro: FiltroProducto) => {
		filtro.size = 1000000
		return this.productoService.getAdicionables(filtro).then((r) => r.filter((p) => !this.esClienteExterno || this.listadoSeleccionado.tieneProducto(p)))
	}
	updateListados = (r: ListaPrecios[]) => {
		this.listasPrecios = r.filter((l) => l.habilitado)
		if (r.length === 1) {
			if (this.item) this.item.listadoPrecio = Descriptivo.fromData(r[0])
			this.listadoSeleccionado = r[0]
		}
	}

	getEstado(confirmaciones: ConfirmacionReservaPuesto[], puesto: PuestoServicioReserva) {
		const c = confirmaciones?.find((c) => c.refPuestoServicioReserva == puesto.id)

		return c?.estado
	}
	public getErrorMessage(control) {
		return super.getErrorMessage(control)
	}
	public get facturada() {
		return this.item?.comprobante
	}

	getAutoasignarDisabled() {
		return this.readonly || (this.productoEditado?.esTercerizado() && !this.productoEditado?.puestosACubrir[0]?.proveedor)
	}

	getHoraTercerizado() {
		if (!this.productoEditado?.horaInicio) return this.horas[16]

		const h = moment(this.productoEditado.horaInicio).get("hour")
		const m = moment(this.productoEditado.horaInicio).get("minutes")

		if (m == 0) {
			return this.horas[h * 2]
		} else {
			return this.horas[h * 2 + 1]
		}
	}

	agregarGrupoPaxTercerizado(row: number, hora: number, vehiculo: Vehiculo) {
		if (this.readonly) return

		let g = this.agregarGrupo(vehiculo, this.itemEditado, this.productoEditado, hora)

		if (vehiculo.proveedor) {
			if (!g.puestos[0].personal || g.puestos[0]?.id != vehiculo.proveedor.id) {
				g.puestos[0].proveedor = vehiculo.proveedor
				//this.updatePrecios(g)
			}
		}
	}

	updatePrecios(g: GrupoPax) {
		if (!this.itemEditado.precioEditado) {
			this.updatePreciosInsumos(g).then(async () => {
				this.itemEditado.precioPerCapita = await this.updatePrecio(this.productoEditado, this.itemEditado)
			})
		}
	}
	convertirAReserva = async (autoasignar: boolean = true) => {
		if (!this.item.esCotizacion) return
		this.editable = true
		this.readonly = false
		this.convirtiendoAReserva = true
		this.item.esCotizacion = false
		if (this.item.canalVenta?.codigo == "CEXT") {
			this.item.notificarClienteExterno = true
		}
		if (autoasignar) {
			this.item.itemsReserva.forEach(async (ir) => {
				const producto = await this.productoService.getById(ir.producto.id)
				this.autoasignar(
					null,
					ir,
					null,
					ir.cantidadPax,
					await this.getGrupos(ir.fechaActividad, producto),
					ir.pickUp,
					await this.aplicarFiltrosVehiculos(producto),
					false
				)
			})
		}
	}
	onFechaChange(grupo: GrupoPax, event) {
		grupo.fechaActividad = event?.nueva

		this.updateModificados(grupo)

		let g = this.itemEditado.reservas.find((r) => r.grupoResumen.UUID == grupo.UUID)?.grupoResumen

		if (g) {
			g.fechaActividad = event?.nueva
			this.itemEditado.fechaActividad = event?.nueva
		}
	}

	cambiarIdiomaReserva(nuevoIdioma: Idioma) {
		this.item.itemsReserva.filter((ir) => ir.idiomaServicio?.codigo == this._idiomaPaxOriginal?.codigo).forEach((ir) => (ir.idiomaServicio = nuevoIdioma))
		this._idiomaPaxOriginal = nuevoIdioma
	}

	ingresarDatosPaxs(isEliminar) {
		this.mostrarDatosPaxs = true
		this.isEliminar = isEliminar
		if (!this.formPaxs) {
			this.formPaxs = this.formBuilder.group({
				paxs: this.formBuilder.array([])
			})
		} else {
			this.formPaxsCopy = this.formPaxs.getRawValue()
		}
		if (!isEliminar && !this.readonly) {
			const newPaxs = this.item.cantidadPax - this.paxs.controls.length
			for (let i = 0; i < newPaxs; i++) {
				this.addPax(null)
			}
			this.paxs.enable()
		} else {
			this.paxs.disable()
		}
	}

	addPax(p: Pax) {
		const pax: FormGroup = this.formBuilder.group({
			id: p ? p.id : null,
			index: this.paxs.length,
			nombre: [p ? p.nombre : "", Validators.required],
			apellido: [p ? p.apellido : "", Validators.required],
			tipoDocumento: [null, Validators.required],
			numeroDocumento: [p ? p.numeroDocumento : "", Validators.required],
			fechaNacimiento: [p ? p.fechaNacimiento : null, Validators.required],
			observaciones: [p ? p.observaciones : ""],
			nacionalidad: [p?.nacionalidad || this.item.nacionalidadPax || null]
		})
		pax.get("tipoDocumento").patchValue(p ? Descriptivo.fromData(p.tipoDocumento) : new Descriptivo("96", "DNI"))
		this.paxs.push(pax)
		if (this.readonly) {
			this.paxs.disable()
		}
	}

	get paxs() {
		return this.formPaxs ? (this.formPaxs.get("paxs") as FormArray) : null
	}

	cancelarDatosPaxs() {
		if (this.datosPaxsModificados()) {
			this.confService.confirm({
				key: "genConf",
				header: this.translateService.get("Cancelar"),
				message: this.translateService.get("CONFIRMAR_CANCELAR_CAMBIOS"),
				accept: () => {
					this.confirmarCancelarDatosPaxs()
				}
			})
		} else {
			this.confirmarCancelarDatosPaxs()
		}
	}

	confirmarCancelarDatosPaxs() {
		this.mostrarDatosPaxs = false
		if (!this.formPaxsCopy) {
			this.formPaxs = undefined
		} else {
			this.formPaxs.patchValue(this.formPaxsCopy)
			this.paxs.controls = this.paxs.controls.slice(0, this.formPaxsCopy.paxs.length)
		}
		if (this.isEliminar) {
			this.item.cantidadPax = this.paxs.controls.length
			this.paxs.controls.forEach((p: any) => (p.removido = false))
		}
	}

	guardarDatosPaxs() {
		this.mostrarDatosPaxs = false
		if (this.isEliminar) {
			this.paxs.controls = this.paxs.controls.filter((pax: any) => !pax.removido)
			this.paxs.setValue(this.paxs.value.filter((pax) => this.paxs.controls.some((paxc) => paxc.value.index == pax.index)))
			this.item.cantidadPax = this.paxs.controls.length
			for (let i = 0; i < this.item.cantidadPax; i++) {
				this.paxs.value[i].index = i
				this.paxs.controls[i].setValue(this.paxs.value[i])
			}
		}
		this.item.paxs = this.paxs.value.map((p) => Pax.fromData(p))
	}

	controlarDatosPaxs(event: Event) {
		if (this.paxs?.controls.length > this.item.cantidadPax) {
			this.ingresarDatosPaxs(true)
		}
	}

	datosPaxsModificados() {
		if (this.formPaxsCopy?.paxs?.length == 0) {
			return this.formPaxs.value.paxs.some((p) => p.nombre || p.apellido || p.fechaNacimiento || p.numeroDocumento || p.observaciones)
		} else {
			return JSON.stringify(this.formPaxs?.value) !== JSON.stringify(this.formPaxsCopy)
		}
	}

	confirmado(confirmaciones, puesto) {
		let e = this.getEstado(confirmaciones, puesto)
		return e?.codigo == "CO"
	}

	hospedajeConfirmado(confirmaciones: ConfirmacionReservaPuesto[]) {
		let e = confirmaciones?.find((c) => c.tipoPuesto.codigo == TipoPuesto.ALOJAMIENTO && c.estado?.codigo != "NV")?.estado

		return e?.codigo == "CO"
	}

	customGuardar = async (goBack: boolean = true) => {
		if (this.item?.esCotizacion) {
			this.item.itemsReserva.forEach((ir) => {
				ir.precioEditado = true
			})
		}
		if (!this.item.tipoCliente) {
			this.item.tipoCliente = null
		}
		if (!this.item.idiomaPax) {
			this.item.idiomaPax = null
		}
		this.item.itemsReserva
			.filter((ir) => ir.esHospedaje())
			.forEach((ir) => {
				ir.fechaActividad = ir.checkIn
				ir.pickUp = ir.checkIn
			})

		if (!this.item?.esFacturado || !this.item?.id || this.item.totalOriginal == this.item.total) {
			return this.modelGestor.defaultGuardar(goBack)
		} else if (this.item.totalOriginal != this.item.total && this.item?.esFacturado) {
			this.confService.confirm({
				key: "genConf",
				header: this.translateService.get("CONFIRMAR_CAMBIO_PRECIO"),
				message: this.translateService.get("MENSAJE_RESERVA_FACTURADA_TOTAL"),
				accept: () => {
					this.modelGestor.defaultGuardar(goBack)
				},
				reject: () => {}
			})
		}
	}
	public onDropPaxs(event) {
		if (this.dragDropContext.fromContext.cantidad == 1 || !this.dragDropContext.paxSelection) {
			this.removePaxs(event)
		} else {
			this.openModal(event)
		}
		event.stopPropagation()
	}
	public openModal(dragEvent: DragEvent) {
		this.displayModalCantidadPaxs = true
		this.dragEventCantidadPaxs = dragEvent
		this.dragDropContext.fromContext.cantidad = 1
		this.aMover = 1
	}
	public onConfirmCantidadPax(evento) {
		this.dragDropContext.fromContext.cantidad = this.aMover
		this.removePaxs(this.dragEventCantidadPaxs)
		this.onCancelarCantidadPax(evento)
	}
	public onCancelarCantidadPax(evento) {
		this.aMover = 0
		this.displayModalCantidadPaxs = false
		this.dragEventCantidadPaxs = null
		evento.stopPropagation()
	}
	onPrecioChange(event) {
		this.precioChangeBS.next(event)
	}

	onSeleccionPuesto(event, puesto: PuestoServicioReserva, confirmaciones: ConfirmacionReservaPuesto[]) {
		if (puesto?.tipoPuesto?.recibeReserva) {
			puesto.estado = EstadoPuesto.PENDIENTE_ENVIO

			confirmaciones
				?.filter((c) => c.refPuestoServicioReserva == puesto.id)
				?.forEach((c) => {
					c.estado = EstadoPuesto.PENDIENTE_ENVIO
				})
		}
	}

	cancelarHospedaje() {
		this.mostrarNuevoHospedaje = false
		this.itemEditado = new ItemReserva()
	}

	public get habitaciones() {
		return this.itemEditado?.hotel?.habitaciones?.map((h) => h.habitacion)
	}

	onSelectHotel(hotel: Hotel) {
		if (hotel.checkIn) {
			this.itemEditado.checkIn = moment(this.item.fechaVencimiento)
				.set("hour", moment(hotel.checkIn).get("hour"))
				.set("minute", moment(hotel.checkIn).get("minute"))
				.toDate()
		} else {
			this.itemEditado.checkIn = moment().set("hour", 14).set("minute", 0).toDate()
		}
		if (hotel.checkOut) {
			this.itemEditado.checkOut = moment(this.item.fechaVencimiento)
				.add(1, "day")
				.set("hour", moment(hotel.checkOut).get("hour"))
				.set("minute", moment(hotel.checkOut).get("minute"))
				.toDate()
		} else {
			this.itemEditado.checkOut = moment().add(1, "day").set("hour", 10).set("minute", 0).toDate()
		}

		this.seleccionProducto = null
		this.itemEditado.producto = null
	}

	get hayHospedaje() {
		return this.item.itemsReserva.some((ir) => {
			return ir.checkIn != null && !ir.esCancelada
		})
	}

	getEstadoHospedaje(confirmaciones: ConfirmacionReservaPuesto[]) {
		let e = confirmaciones?.find((c) => c.tipoPuesto.codigo == TipoPuesto.ALOJAMIENTO && c.estado?.codigo != "NV")?.estado

		return e
	}
}
