<div class="grid container align-content-center">
    <div class="col-12" *ngIf="!caja">
        <span> Elija una Caja</span>
    </div>

    <div class="col" *ngIf="caja">
        <div class="shortcut card " (click)="genererCambioEstado(caja)">
            <div class="grid grid-nogutter">
                <div class="col-12">
                    <span><i class="fa fa-balance-scale"></i>{{'Arqueo' | translate}}</span>
                </div>
                <div class="col-12">
                    <span class="detalle" *ngIf="caja && caja.ultimoCambioEstado">
                        {{'Último arqueo'| translate}}
                        {{caja.ultimoCambioEstado?.fecha | date :'dd/MM/yyy HH:mm'}} -
                        {{caja.ultimoCambioEstado?.responsable?.descripcion}}
                    </span>
                </div>

            </div>



        </div>
    </div>
    <div class="col-fixed" style="width: 3em;" *ngIf="caja">
        <button pButton (click)="verCaja()" icon="fa fa-eye">

        </button>
    </div>
</div>
<p-overlayPanel #op [dismissable]="true" appendTo="body">
    <ul class="options">
        <li *ngFor="let c of cajaService.data | async " (click)="aplicarSeleccion(c)">
            {{c.label}}
        </li>
    </ul>
</p-overlayPanel>

<p-dialog header="" [(visible)]="editandoMovimiento" appendTo="body" [modal]="true" #dialDetail
    [styleClass]="'no-header gestor-dialog'" (onShow)="onDialogShow($event,dialDetail)">
    <gestor-cambio-estado-caja #gestorEstado [item]="itemEditado" (onGuardado)="refresh($event)" [cajaEditable]="false"
        (onCancelado)="onCancelar($event)" [goBack]="false" [isModal]="true" [caja]="itemEditado?.caja"
        [esCierre]="itemEditado?.esCierre" *ngIf="itemEditado && editandoMovimiento">
    </gestor-cambio-estado-caja>
</p-dialog>
