import * as moment from "moment"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { TipoComprobante } from "src/app/model/TipoComprobante"
import { environment } from "src/environments/environment"
import { MonedaEntorno } from "../app.module"
import { IConAdjunto } from "../common-components/IConAdjunto"
import { IConRecordatorio } from "./../pages/recordatorio/IConRecordatorio"
import { Auditable } from "./Auditable"
import { ClienteResumen } from "./Cliente"
import { EstadoDocumento } from "./EstadoDocumento"
import { FileData } from "./FileData"
import { ItemComprobante } from "./ItemComprobante"
import { Moneda } from "./Moneda"
import { Nacionalidad } from "./Nacionalidad"
import { Recordatorio } from "./Recordatorio"
import { TipoCliente } from "./TipoCliente"
import { TipoIVA } from "./TipoIVA"

export class Comprobante extends Auditable implements IConRecordatorio, IConAdjunto {
	clonar(): Comprobante {
		let o = new Comprobante()
		Object.assign(o, this)
		o.id = null
		o.adjunto = null
		o.cae = null
		o.numeroComprobante = null
		o.estado = null
		o.recordatorios = o.recordatorios.filter((r) => !r.esFechaFija).map((r) => r.clonar())
		o.reserva = null
		o.responsable = null
		o.version = null
		const dif = moment(this.fechaVencimiento).diff(moment(this.fecha), "days")
		o.fecha = new Date()
		o.fechaVencimiento = moment().add(dif, "days").startOf("day").toDate()
		o.items = o.items.map((i) => i.clonar())
		return o
	}
	public get tipoEntidad(): string {
		return "CoprobanteVO"
	}
	public adjunto: FileData
	public recordatorios: Recordatorio[] = []
	public clienteStr: string
	public integrar: boolean

	constructor(
		public id?: number,
		public numeroComprobante?: string,
		public descripcion?: string,
		public fecha: Date = new Date(),
		public fechaVencimiento: Date = moment().add(10, "days").startOf("day").toDate(),
		public tipoComprobante?: TipoComprobante,
		public cliente?: ClienteResumen,
		public centroCosto?: Descriptivo,
		public items: ItemComprobante[] = [],
		public iibbOtros?: number,
		public saldo?: number,
		public importe?: number,
		public estado?: EstadoDocumento,
		public responsable?: Descriptivo,
		public reserva?: Descriptivo,
		public bonificacion: number = 0,
		public redondeo: number = 0,
		public observaciones: string = "",
		public moneda?: Moneda,
		public comprobanteAsociado?: Descriptivo,
		public monedaOrigen?: Moneda,
		public cotizacion: number = 1,
		public cotizacionAfip?: number,
		public puntoDeVenta?: Descriptivo,
		public cae?: string,
		public datosFacturacionDistintos: boolean = false,
		public tipoDocumentoFacturacion?: Descriptivo,
		public numeroDocumentoFacturacion?: string,
		public nombreFacturacion?: string,
		public emailFacturacion?: string,
		public ivaFacturacion?: TipoIVA,
		public domicilioFacturacion?: String,
		public nacionalidadFacturacion?: Nacionalidad,
		public tipoClienteFacturacion?: TipoCliente,
		public noActualizarCotizacion: boolean = false,
		lastModifiedBy?: string,
		lastModifiedDate?: Date,
		public estadoIntegracion?: string,
		public version?: number,
		public incluyeImpuestos: boolean = false,
		public integrado?: boolean
	) {
		super(lastModifiedBy, lastModifiedDate)
		this.clienteStr = this.cliente ? this.cliente.descripcion + (this.estadoIntegracion != null ? "(" + this.estadoIntegracion + ")" : "") : ""
	}
	public static fromData(data: any): Comprobante {
		if (!data) return null
		const o: Comprobante = new Comprobante(
			data.id,
			data.numeroComprobante,
			data.descripcion,
			data.fecha ? new Date(data.fecha) : null,
			data.fechaVencimiento ? new Date(data.fechaVencimiento) : null,
			TipoComprobante.fromData(data.tipoComprobante),
			ClienteResumen.fromData(data.cliente),
			Descriptivo.fromData(data.centroCosto),
			data.items ? data.items.map((c) => ItemComprobante.fromData(c)) : [],
			data.iibbOtros,
			data.saldo,
			data.importe,
			EstadoDocumento.fromData(data.estado),
			Descriptivo.fromData(data.responsable),
			Descriptivo.fromData(data.reserva),
			data.bonificacion * -1,
			data.redondeo,
			data.observaciones,
			Moneda.fromData(data.moneda),
			Descriptivo.fromData(data.comprobanteAsociado),
			Moneda.fromData(data.monedaOrigen),
			data.cotizacion,
			data.cotizacionAfip,
			Descriptivo.fromData(data.puntoDeVenta),
			data.cae,
			data.datosFacturacionDistintos,
			Descriptivo.fromData(data.tipoDocumentoFacturacion),
			data.numeroDocumentoFacturacion,
			data.nombreFacturacion,
			data.emailFacturacion,
			TipoIVA.fromData(data.ivaFacturacion),
			data.domicilioFacturacion,
			Nacionalidad.fromData(data.nacionalidadFacturacion),
			TipoCliente.fromData(data.tipoClienteFacturacion),
			data.noActualizarCotizacion,
			data.lastModifiedBy,
			data.lastModifiedDate,
			data.estadoIntegracion
		)
		o.recordatorios = data.recordatorios ? data.recordatorios.map((c) => Recordatorio.fromData(c)) : []
		o.version = data.version
		o.incluyeImpuestos = data.incluyeImpuestos
		o.adjunto = FileData.fromData(data.adjunto)
		o.integrado = data.integrado
		return o
	}
	public get subtotal() {
		return this.items.filter((a) => !a.esSubcategoria).reduce((a, b) => (a += b.subtotal), 0)
	}

	public get subtotalNeto() {
		return this.items.filter((a) => !a.esSubcategoria).reduce((a, b) => (a += b.precioUnitario * b.cantidad), 0)
	}

	public get subtotalBruto() {
		return this.items.filter((a) => !a.esSubcategoria).reduce((a, b) => (a += b.precioUnitarioBruto * b.cantidad), 0)
	}
	public get descuentos() {
		return this.items.filter((a) => !a.esSubcategoria).reduce((a, b) => (a += b.valorDescuento * b.cantidad), 0) + this.bonificacion + this.redondeo
	}
	public get impuestos() {
		return this.items.filter((a) => !a.esSubcategoria).reduce((a, b) => (a += b.impuestos * b.cantidad), 0)
	}
	public get total() {
		return this.items.filter((a) => !a.esSubcategoria).reduce((a, b) => (a += b.total), 0)
	}
	get esNoOficial() {
		return !this.tipoComprobante?.esOficial
	}
	public quitarItemComprobante(item: ItemComprobante) {
		if (item.esSubcategorizado) {
			this.items = this.items.filter((i) => i.referenciaPadre != item.referenciaPadre)
		} else {
			this.items = this.items.filter((i) => i != item)
		}
	}
	public get numeroComprobanteCalc() {
		return this.numeroComprobante ? this.numeroComprobante : this.id
	}
	public getPadre(item: ItemComprobante) {
		return this.items.find((it) => !it.esSubcategoria && it != item && it.referenciaPadre == item.referenciaPadre)
	}
	public agregarItem(item) {
		if (this.items.indexOf(item) < 0) {
			this.items = [...this.items, item]
		}
	}
	public eliminarSinReferencia(item?: ItemComprobante) {
		this.items = this.items.filter((it) => !it.esSubcategoria || (it.esSubcategoria && this.getPadre(it) != undefined))
	}
	public newItemComprobante() {
		return new ItemComprobante()
	}

	public get codigoComprobante() {
		return this.tipoComprobante?.codigo + this.numeroComprobante ? this.numeroComprobante : this.id
	}

	get esDocumentoExterno() {
		return this.tipoComprobante?.codigo == "DE"
	}
	get comprobanteCompleto() {
		return this.tipoComprobante?.descripcion + " - " + this.numeroComprobante
	}
	public static crearResumen(comp: Comprobante): ComprobanteResumen {
		return new ComprobanteResumen(
			comp.id,
			comp.numeroComprobante,
			comp.descripcion,
			comp.fecha,
			comp.fechaVencimiento,
			comp.tipoComprobante,
			comp.cliente,
			null,
			comp.saldo,
			comp.importe,
			comp.estado,
			null,
			comp.moneda,
			comp.impuestos,
			comp.iibbOtros,
			comp.bonificacion
		)
	}
	get totalALocal() {
		return this.total * (this.cotizacionAfip > 1 ? this.cotizacionAfip : this.cotizacion)
	}
}
export class ComprobanteResumen extends Auditable {
	public clienteStr: string
	public version?: number
	public searchStr: string
	private monedaLocal: string

	importeTransformado: number = 0
	constructor(
		public id?: number,
		public numeroDocumento?: string,
		public descripcion?: string,
		public fecha?: Date,
		public fechaVencimiento?: Date,
		public tipoComprobante?: TipoComprobante,
		public cliente?: ClienteResumen,
		public centroCosto?: Descriptivo,
		public saldo?: number,
		public importe?: number,
		public estado?: EstadoDocumento,
		public pedido?: Descriptivo,
		public moneda?: Moneda,
		public impuestos: number = 0,
		public otrosImpuestos: number = 0,
		public bonificacion: number = 0,
		lastModifiedBy?: string,
		lastModifiedDate?: Date,
		public cotizacionAfip = 1,
		public cotizacion: number = 1,
		public comprobanteAsociado?: ComprobanteResumen,
		public reserva?: Descriptivo,
		public adjunto?: FileData,
		public url?: string,
		public externalToken?: string,
		public cae?: string,
		public integrado?: boolean,
		public esAdelanto?: boolean,
		public numeroComprobante?: string,
		public recargos?: number
	) {
		super(lastModifiedBy, lastModifiedDate)
		this.clienteStr = this.cliente ? this.cliente.descripcion : ""
		this.searchStr = [this.id, this.descripcion, this.numeroDocumento, this.tipoComprobanteCodigo].join("_")
		this.monedaLocal = MonedaEntorno()?.codigo || Moneda.PESOS.codigo
		this.importeTransformado = this.importe * this.cotizacionSafe
	}
	public get cotizacionSafe() {
		if (this.moneda?.codigo == this.monedaLocal) return 1
		if (this.cotizacionAfip != 1 && this.cotizacionAfip != 0) {
			return this.cotizacionAfip
		}
		return this.cotizacion || 1
	}

	public get saldoTransformado() {
		return this.saldo * this.cotizacionSafe
	}
	public get fechaStr() {
		return moment(this.fecha).format("DD/MM/YYYY")
	}
	public get tipoComprobanteCodigo() {
		return this.tipoComprobante?.codigo || ""
	}
	public get nombreCliente() {
		return this.cliente ? this.cliente.razonSocial || this.cliente.descripcion : ""
	}

	public get codigoComprobante() {
		return this.tipoComprobante?.codigo + this.numeroDocumento ? this.numeroDocumento : this.id
	}

	public get cuitCliente() {
		return this.cliente ? this.cliente.numeroDocumento : ""
	}

	public get importeBruto() {
		return this.importe - this.impuestos
	}

	public get importeBrutoPesos() {
		return (this.importe - this.impuestos) * (MonedaEntorno()?.codigo != this.moneda?.codigo ? this.cotizacionSafe : 1)
	}

	public get impuestosPesos() {
		return this.impuestos * (MonedaEntorno()?.codigo != this.moneda?.codigo ? this.cotizacionSafe : 1)
	}

	public get otrosImpuestosPesos() {
		return this.otrosImpuestos * (MonedaEntorno()?.codigo != this.moneda?.codigo ? this.cotizacionSafe : 1)
	}

	public get importePesos() {
		return this.importe * (MonedaEntorno()?.codigo != this.moneda?.codigo ? this.cotizacionSafe : 1)
	}
	public get monedaStr() {
		return this.moneda.codigo
	}
	public get valorAjuste() {
		return this.bonificacion * -1
	}

	public static fromData(data: any): ComprobanteResumen {
		if (!data) return null
		const o: ComprobanteResumen = new ComprobanteResumen(
			data.id,
			data.numeroComprobante,
			data.descripcion,
			data.fecha ? new Date(data.fecha) : null,
			data.fechaVencimiento ? new Date(data.fechaVencimiento) : null,
			TipoComprobante.fromData(data.tipoComprobante),
			ClienteResumen.fromData(data.cliente),
			Descriptivo.fromData(data.centroCosto),
			data.saldo,
			data.importe,
			EstadoDocumento.fromData(data.estado),
			Descriptivo.fromData(data.pedido),
			Moneda.fromData(data.moneda),
			data.impuestos,
			data.otrosImpuestos,
			data.bonificacion,
			data.lastModifiedBy,
			data.lastModifiedDate,
			data.cotizacionAfip ? data.cotizacionAfip : 1,
			data.cotizacion ? data.cotizacion : 1,
			ComprobanteResumen.fromData(data.comprobanteAsociado),
			Descriptivo.fromData(data.reserva),
			FileData.fromData(data.adjunto)
		)
		o.externalToken = data.externalToken
		o.version = data.version
		o.url = data.url || o.externalToken ? environment.apiUrl + "public/comprobante/descargar/" + o.externalToken : null
		o.esAdelanto = data.esAdelanto
		o.cae = data.cae
		o.integrado = data.integrado
		o.numeroComprobante = data.numeroComprobante
		o.recargos = data.recargos || 0
		return o
	}
	get importeSinRecargos() {
		return this.importe - this.recargos
	}
	get idReserva() {
		return this.reserva?.id
	}
	get esDocumentoExterno() {
		return this.tipoComprobante?.codigo == "DE"
	}
	get esNoOficial() {
		return !this.tipoComprobante?.esOficial
	}

	get esVigente(): boolean {
		return this.estado?.codigo != "R" && this.estado?.codigo != "C" && this.estado?.codigo != "AN"
	}

	get saldoALocal() {
		return this.saldo * this.cotizacionSafe
	}
	get descripcionLarga() {
		return this.tipoComprobanteCodigo + " " + this.numeroDocumento + " - " + this.descripcion
	}
	public montoLocal(cotizacion?: number, moneda?: Moneda) {
		return this.moneda?.codigo != moneda?.codigo ? this.importe * (cotizacion || this.cotizacionSafe) : this.importe
	}
}
