import { Injectable } from "@angular/core"
import { IdiomaEntorno } from "../app.module"

@Injectable({
	providedIn: "root"
})
export class CacheService {
	isUpdating(tipo: string, idioma?: string): boolean {
		return this.getUpdating(tipo, idioma) !== undefined
	}
	eliminar(tipo: string, idioma: string, e: number): any[] {
		this.setCache(
			tipo,
			this.getCache(tipo, idioma).filter((d) => d.id != e),
			idioma
		)
		return this.getCache(tipo, idioma)
	}
	udpdate(tipo: string, idioma: string, v: any): any[] {
		idioma = this.safeIdioma(idioma)
		if (v?.id) {
			this.getCache(tipo, idioma)[this.getCache(tipo, idioma).findIndex((d) => d.id == v.id)] = v
		}

		return this.getCache(tipo, idioma)
	}
	isLoaded(tipo: string, idioma?: string): boolean {
		idioma = this.safeIdioma(idioma)
		const key = `${tipo}-${idioma}`
		return CacheService.cache[key] !== undefined
	}
	create(tipo: string, idioma: string, v: any): any[] {
		idioma = this.safeIdioma(idioma)
		this.getCache(tipo, idioma).push(v)
		return this.getCache(tipo, idioma)
	}
	destroy(tipo: string) {
		const keys = Object.keys(CacheService.cache)
		for (let key of keys) {
			if (key.startsWith(tipo + "-")) {
				delete CacheService.cache[key]
			}
		}
		const keysState = Object.keys(CacheService.state)
		for (let key of keysState) {
			if (key.startsWith(tipo + "-")) {
				delete CacheService.state[key]
			}
		}
	}
	setUpdating(p: Promise<any[]>, tipo: string, idioma: string) {
		idioma = this.safeIdioma(idioma)
		const key = `${tipo}-${idioma}`
		CacheService.state[key] = p.then((a) => {
			delete CacheService.state[key]
			return Promise.resolve(a)
		})
	}
	getUpdating(tipo: string, idioma?: string): Promise<any[]> {
		idioma = this.safeIdioma(idioma)
		const key = `${tipo}-${idioma}`
		return CacheService.state[key]
	}
	private static cache: { [key: string]: any } = {}
	private static state: { [key: string]: Promise<any[]> } = {}
	setCache(tipo: string, valor: any, idioma?: string) {
		idioma = this.safeIdioma(idioma)
		const key = `${tipo}-${idioma}`
		CacheService.cache[key] = valor
		delete CacheService.state[key]
	}

	// Consulta un valor en el caché basado en tipo e idioma
	getCache(tipo: string, idioma?: string): any {
		idioma = this.safeIdioma(idioma)
		const key = `${tipo}-${idioma}`
		return CacheService.cache[key] || []
	}

	private safeIdioma(idioma: string): string {
		return (idioma || IdiomaEntorno()).toLowerCase()
	}
}
