<div class="grid">
	<div class="col-12">
		<ng-container *ngIf="tipoCuenta == 'proveedor'">
			<ng-container *ngTemplateOutlet="prov; context: { $implicit: totalesProveedor }"></ng-container>

			<ng-container *ngTemplateOutlet="cli"></ng-container>
		</ng-container>

		<ng-container *ngIf="tipoCuenta == 'cliente'">
			<ng-container *ngTemplateOutlet="cli"></ng-container>
			<ng-container *ngTemplateOutlet="prov; context: { $implicit: totalesProveedor }"></ng-container>
		</ng-container>

		<div class="card tot print:no-border" *ngIf="totalesCliente && totalesProveedor">
			<loading-panel [loadingService]="loadingService"></loading-panel>
			<div class="grid">
				<div class="p-fluid col-12 section">
					<div class="grid">
						<div class="col-12 titulo">
							<div class="flex justify-content-between">
								<div>
									<span>Saldo</span>
									<span *ngIf="totalesCliente?.totalSaldo > totales?.totalSaldo">
										{{ "A_COBRAR" | translate }}
									</span>
									<span *ngIf="totalesCliente?.totalSaldo < totales?.totalSaldo">
										{{ "A_PAGAR" | translate }}
									</span>
								</div>

								<div class="valor">
									<span>{{ totalCuentas | currencyCustom : totalesCliente?.moneda }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #prov let-totales>
	<div class="card tot mb-1" *ngIf="totales && proveedor">
		<loading-panel [loadingService]="loadingService"></loading-panel>
		<div class="grid print-hidden">
			<div class="col-12 mb-1" *ngIf="cliente && tipoCuenta == 'cliente'">
				<span class="title-totales clickable" (click)="goToProveedor(proveedor)">
					{{ "TOTALES_PROVEEDOR" | translate }}: {{ proveedor.descripcion }}
				</span>
			</div>

			<div class="col-12 lg:col-6 section">
				<div class="grid">
					<div class="col-12 titulo">
						<span>Total Comprado</span>
					</div>
					<div class="col-12 text-right valor">
						<span>{{ totales.totalConsumido | currencyCustom : totales?.moneda }}</span>
					</div>
				</div>
			</div>
			<div class="col-12 lg:col-6 section">
				<div class="grid">
					<div class="col-12 titulo">
						<span>Total Pagos</span>
					</div>
					<div class="col-12 text-right valor">
						<span>{{ totales.pagos | currencyCustom : totales?.moneda }}</span>
					</div>
				</div>
			</div>
			<div class="col-12 section">
				<div class="grid">
					<div class="col-12 titulo">
						<span>Saldo</span>
					</div>
					<div
						class="col-12 text-right valor"
						[ngClass]="{
							danger: (tipoCuenta == 'cliente' && saldo < 0) || (tipoCuenta == 'proveedor' && saldo > 0)
						}"
					>
						<span>{{ totales.totalSaldo | currencyCustom : totales?.moneda }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="grid print-only">
			<div class="col-12 mb-1" *ngIf="cliente && tipoCuenta == 'cliente'">
				<strong> {{ "TOTALES_PROVEEDOR" | translate }}: {{ proveedor.descripcion }} </strong>
			</div>
			<div class="col-6 titulo">
				<span>Total Comprado</span>
			</div>
			<div class="col-6 text-right valor">
				<span>{{ totales.totalConsumido | currencyCustom : totales?.moneda }}</span>
			</div>
			<div class="col-6 titulo">
				<span>Total Pagos</span>
			</div>
			<div class="col-6 text-right valor">
				<span>{{ totales.pagos | currencyCustom : totales?.moneda }}</span>
			</div>
			<div class="col-6 titulo">
				<span>Saldo</span>
			</div>
			<div class="col-6 text-right valor" [ngClass]="{ danger: (tipoCuenta == 'cliente' && saldo < 0) || (tipoCuenta == 'proveedor' && saldo > 0) }">
				<span>{{ totales.totalSaldo | currencyCustom : totales?.moneda }}</span>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #cli>
	<div class="card tot mb-1" *ngIf="totalesCliente && cliente">
		<loading-panel [loadingService]="loadingService"></loading-panel>
		<div class="grid print-hidden">
			<div class="col-12 mb-1" *ngIf="proveedor && tipoCuenta == 'proveedor'">
				<span class="title-totales clickable" (click)="goToCliente(cliente)"> {{ "TOTALES_CLIENTE" | translate }}: {{ cliente.descripcion }} </span>
			</div>

			<div class="p-fluid col-12 lg:col-6 section">
				<div class="grid">
					<div class="p-fluid col-12 titulo">
						<span>Total Consumo</span>
					</div>
					<div class="p-fluid col-12 text-right valor">
						<span>{{ totalesCliente.totalConsumido | currencyCustom : totalesCliente?.moneda }}</span>
					</div>
				</div>
			</div>
			<div class="p-fluid col-12 lg:col-6 section">
				<div class="grid">
					<div class="p-fluid col-12 titulo">
						<span>Total Cobrado</span>
					</div>
					<div class="p-fluid col-12 text-right valor">
						<span>{{ totalesCliente.pagos | currencyCustom : totalesCliente?.moneda }}</span>
					</div>
				</div>
			</div>
			<div class="p-fluid col-12 section">
				<div class="grid">
					<div class="p-fluid col-12 titulo">
						<span>Saldo</span>
					</div>
					<div
						class="p-fluid col-12 text-right valor"
						[ngClass]="{ danger: (tipoCuenta == 'proveedor' && saldo < 0) || (tipoCuenta == 'cliente' && saldo > 0) }"
					>
						<span>{{ totalesCliente.totalSaldo | currencyCustom : totalesCliente?.moneda }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="grid print-only">
			<div class="col-12 mb-1" *ngIf="proveedor && tipoCuenta == 'proveedor'">
				<strong> {{ "TOTALES_CLIENTE" | translate }}: {{ cliente.descripcion }} </strong>
			</div>
			<div class="col-6 titulo">
				<span>Total Consumo</span>
			</div>
			<div class="col-6 text-right valor">
				<span>{{ totalesCliente.totalConsumido | currencyCustom : totalesCliente?.moneda }}</span>
			</div>
			<div class="col-6 titulo">
				<span>Total Cobrado</span>
			</div>
			<div class="col-6 text-right valor">
				<span>{{ totalesCliente.pagos | currencyCustom : totalesCliente?.moneda }}</span>
			</div>
			<div class="col-6 titulo">
				<span>Saldo</span>
			</div>
			<div class="col-6 text-right valor" [ngClass]="{ danger: (tipoCuenta == 'proveedor' && saldo < 0) || (tipoCuenta == 'cliente' && saldo > 0) }">
				<span>{{ totalesCliente.totalSaldo | currencyCustom : totalesCliente?.moneda }}</span>
			</div>
		</div>
	</div>
</ng-template>
