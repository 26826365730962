
import { Proveedor} from './Proveedor';
import { PuestoACubrir} from './PuestoACubrir';


export class PuestoServicioPrograma  {
    constructor(
		public id ?: number, 
		public personal ?: Proveedor, 
		public puesto ?: PuestoACubrir, 

    ) {
    }
    public static fromData(data: any): PuestoServicioPrograma {
        if (!data) return null;
        const o: PuestoServicioPrograma = new PuestoServicioPrograma(
			data.id, 
			Proveedor.fromData(data.personal), 
			PuestoACubrir.fromData(data.puesto), 

        );

return o;

    }


    public clonar() {
      return PuestoServicioPrograma.fromData(this)
  }

}