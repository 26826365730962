import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { PuntoDeVentaService } from './../../../services/punto-de-venta.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ProfilePic } from 'src/app/common/model/ProfilePic';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { CentroCosto } from "src/app/model/CentroCosto";
import { CentroCostoService } from 'src/app/services/centro-costo.service';
import { TipoIngresoBrutoService } from 'src/app/services/tipo-ingreso-bruto.service';
import { TipoIVAService } from 'src/app/services/tipo-iva.service';
import { SessionComponent } from './../../../common/components/session-component.component';
import { FiltroPuntoDeVenta } from 'src/app/model/FiltroPuntoDeVenta';

;
;



@Component({
	selector: 'gestor-centro-costo',
	templateUrl: 'gestor-centro-costo.component.html',
	styleUrls: ['gestor-centro-costo.component.less',]
})

export class GestorCentroCostoComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription;
	@Input()
	public item: CentroCosto;
	@Input()
	public goBack: boolean = true;
	@Input()
	public isModal: boolean = false;

	@Input()
	public readonly: boolean = false;

	@Output()
	public onGuardado: EventEmitter<CentroCosto> = new EventEmitter<CentroCosto>();

	@Output()
	public onCancelado = new EventEmitter();

	public uploadUrl: string = "";

	public file: File;
	puntos: Descriptivo[] = []

	public onPicSelected(file: File) {
		const reader = new FileReader();
		let $this = this;
		if (file.size > 1000000) {
			return this.error(this.translateService.get('EL_ARCHIVO_NO_PUEDE_16'));
		}
		this.file = file;
		reader.onload = (e: any) => {
			$this.item.profilePic = new ProfilePic(null, e.target.result);
		};
		reader.readAsDataURL(file);
	}

	constructor(messagesService: MessagesService,
		public service: CentroCostoService,
		private route: ActivatedRoute,
		private router: Router,
		private confService: ConfirmationService,
		public tipoIngresoBrutoService: TipoIngresoBrutoService,
		public tipoIVAService: TipoIVAService,
		public puntoDeVentaService: PuntoDeVentaService

	) {
		super(messagesService);
		this.uploadUrl = this.service.getApiURL() + "fileUpload/adjuntos";
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item);
	}
	public handleCancelar(item) {
		this.onCancelado.emit();
	}

	public async isValid() {
		return true;
	}

	ngOnInit() {
		this.subs.push(this.route.data.subscribe(u => {
			if (u?.vista) {
				this.readonly = true;
			}
		}));
		if (this.route.snapshot.url.some(u => u.path == 'centro-costo')) {
			this.routeSub = this.route.queryParams.subscribe(params => {
				let id: number = <number>params['id'];
				if (!this.service) return;
				if (id) {
					this.service.getById(id, null, this.translateService.idioma ? this.translateService.idioma : 'ES').then(r => {
						this.item = r;
						let f = new FiltroPuntoDeVenta('dummy')
						f.centro = Descriptivo.fromData(this.item)
						this.puntoDeVentaService.getDescriptivos(f).then( p => this.puntos = p)
					})
				} else {
					this.item = this.service ? this.service.newEnt() : null;
					this.item["codigoIdioma"] = this.translateService.idioma ? this.translateService.idioma : 'ES';
				}

			});
		}


	}

	public get esEditable() {
		return this.esAdministrador;
	}

	public editar() {
		this.router.navigate(["centro-costo/edit"], { queryParams: { id: this.item.id } });
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe();
	}

	// onUploadError(error) {
	// 	this.error(error);
	// }

	// uploadEnd(data, destino) {
	// 	this.item.certificado = data.originalEvent.body[0];
	// }
}