import { Parametrico } from "src/app/common/model/Parametrico"
import { ProfilePic } from "src/app/common/model/ProfilePic"
import { Descriptivo } from "../common/model/Descriptivo"
import { IConImagen } from "./../common/model/IConImagen"
import { DefaultFormaPago } from "./DefaultFormaPago"
import { Moneda } from "./Moneda"

export class FormaPago extends Parametrico implements IConImagen {
	getDefault(tipoOperacion: string, moneda: Moneda | Descriptivo) {
		return this.defaultsCajas.find((d) => d.tipoOperacion == tipoOperacion && d.moneda?.id == moneda?.id)
	}
	getCajaDefault(tipoOperacion: string, moneda: Moneda | Descriptivo) {
		const d = this.getDefault(tipoOperacion, moneda)
		return d ? d.caja : null
	}
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado: boolean = true,
		esSistema: boolean = false,
		peso: number = 99,
		esDefault: boolean = false,
		public icono: ProfilePic = new ProfilePic(null, "assets/images/default_image.png"),
		public defaultsCajas: DefaultFormaPago[] = []
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
	}
	public tipoParametro(): string {
		return "FORMAPAGO"
	}
	public static fromData(data: any): FormaPago {
		if (!data) return null
		const o: FormaPago = new FormaPago(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			data.esSistema,
			data.peso,
			data.esDefault,
			ProfilePic.fromData(data.icono),
			data.defaultsCajas ? data.defaultsCajas.map((d) => DefaultFormaPago.fromData(d)) : []
		)

		o.version = data.version
		return o
	}
	public get imagen() {
		return this.icono
	}

	public get imagenUrl() {
		return this.icono ? this.icono.picPath : "assets/images/default_image.png"
	}
}
