import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core"
import { Title } from "@angular/platform-browser"
import { NavigationEnd, Router, RouterOutlet } from "@angular/router"
import { SwUpdate } from "@angular/service-worker"
import { Chart } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { PrimeNGConfig } from "primeng/api"
import { ScrollPanel } from "primeng/scrollpanel"
import { Subscription } from "rxjs"
import { filter, first } from "rxjs/operators"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { environment } from "src/environments/environment"
import { MenuService } from "./app.menu.service"
import { MonedaEntorno } from "./app.module"
import { BreadcrumbService } from "./breadcrumb.service"
import { commonAnimations } from "./common-animations"
import { Usuario } from "./common/model/Usuario"
import { InitializerService } from "./common/services/initializer.service"
import { TranslateService } from "./common/services/translate.service"
import { StringUtils } from "./common/utils/string-utils"
import { DashboardService } from "./pages/home/dashboard-element/dashboard.service"
import { slideInAnimation } from "./route-animation"
import { LayoutService } from "./services/app.layout.service"
import { AuthService } from "./services/auth.service"
import { ConfiguracionUsuarioService } from "./services/configuracion-usuario.service"
import { NovedadService } from "./services/novedades.service"
export enum KEY_CODE {
	RIGHT_ARROW = 39,
	LEFT_ARROW = 37,
	PLUS = 107,
	ENTER = 13
}
@Component({
	selector: "front-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.less"],
	animations: [slideInAnimation, commonAnimations]
})
export class AppComponent implements OnInit, OnDestroy {
	public hideBreadcrumbs: boolean = false
	darkMenu = environment.darkMenu
	private userSub: Subscription
	title: string
	overlayMenuActive: boolean
	rotateMenuButton: boolean
	menuClick: boolean
	topbarMenuActive: boolean
	chartJs = Chart
	chartLabelPlugin = ChartDataLabels
	topbarItemClick: boolean
	menuDark: boolean = true
	activeTopbarItem: any
	profileMode = "inline"
	resetMenu: boolean
	staticMenuDesktopInactive: boolean
	public message
	staticMenuMobileActive: boolean
	menuHoverActive: boolean
	public get layoutTheme() {
		return this.layoutService.config.componentTheme || "indigo"
	}
	public get menuTheme() {
		return this.layoutService.config.componentTheme || "indigo"
	}
	public get topbarTheme() {
		return this.layoutService.config.componentTheme || "indigo"
	}
	layoutMode: string = "light"
	menuMode: string = "static"
	inlineMenuPosition: string = "bottom"
	inputStyle: string = "filled"
	ripple: boolean = true
	isRTL: boolean = false
	menuActive: boolean = true
	mobileMenuActive: boolean
	mobileTopbarActive: boolean
	topbarRightClick: boolean
	documentClickListener: () => void
	configActive: boolean
	configClick: boolean
	rightMenuActive: boolean
	searchClick = false
	search = false
	currentInlineMenuKey: string
	inlineMenuActive: any[] = []
	private socketSub: Subscription
	inlineMenuClick: boolean
	confSub: Subscription
	protected subs: Subscription[] = []
	get usuario(): Usuario {
		return this.authService.getCurrentUser
	}
	@ViewChild("layoutContainer", { static: false }) layourContainerViewChild: ElementRef

	@ViewChild("scrollPanel", { static: false }) layoutMenuScrollerViewChild: ScrollPanel
	constructor(
		private router: Router,
		private swUpdate: SwUpdate,
		private breadService: BreadcrumbService,
		private menuService: MenuService,
		private novedadesService: NovedadService,
		private configService: ConfiguracionUsuarioService,
		private primengConfig: PrimeNGConfig,
		private dbService: DashboardService,
		public renderer: Renderer2,
		public InitializerService: InitializerService,
		public layoutService: LayoutService,
		public messagesService: MessagesService,
		public titleService: Title,
		public translateService: TranslateService,
		public authService: AuthService
	) {
		this.translateService.use("es")
	}
	public resetTitle() {
		this.title = this.translateService.get("TITULO")
	}

	private _showMenu: boolean = false
	public get showMenu(): boolean {
		return this._showMenu
	}
	public set showMenu(v: boolean) {
		this._showMenu = v
	}

	ngOnInit(): void {
		if (this.swUpdate.isEnabled) {
			this.swUpdate.versionUpdates.pipe(filter((p) => p.type == "VERSION_READY")).subscribe((n) => {
				if (confirm("Hay una nueva versión, desea actualizarla?")) {
					window.location.href = window.location.href + "?upd=" + new Date().getTime()
					window.location.reload()
				}
			})
		}
		if (!this.authService.isLoggedIn) {
			MonedaEntorno()
			this.authService.fetchUserData().subscribe((user) => {})
		}
		this.subs.push(
			this.translateService.upateData
				.pipe(
					filter((r) => r != undefined),
					first()
				)
				.subscribe((r) => {
					this.resetTitle()
				})
		)
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.titleService.setTitle(StringUtils.middlescoreToTitleCase(event.urlAfterRedirects.split("/")[1]) + " - Iterpax")
			}
		})
		let windwUrl = window["cambioClave"]
		// this.chartJs.plugins.unregister(this.chartLabelPlugin)
		Chart.register(ChartDataLabels)
		if (!this.authService.token && windwUrl.indexOf("/public") < 0 && windwUrl.indexOf("/login") < 0) {
			//this.authService.refreshToken(true);
		}

		let a = this.authService
			.getAutenticateState()
			.pipe()
			.subscribe((u) => {
				this.showMenu = u === AuthService.LOGUEADO
				if (u == AuthService.LOGUEADO) {
					if (!this.confSub) {
						this.confSub = this.configService.configObs.pipe(filter((r) => r != undefined)).subscribe((r) => {
							const conf = { componentTheme: r.themeConfig.componentTheme, colorScheme: r.themeConfig.colorScheme }
							this.layoutService.updateLayout(conf)
						})
					}
				}

				this.dbService.init()
				this.configService.init()
			})
	}
	logout() {
		this.authService.logout().then((r) => {
			this.router.navigate(["login"])
		})
	}
	get username() {
		return this.authService.getUserFullName()
	}

	ngAfterViewInit() {
		// hides the horizontal submenus or top menu if outside is clicked
		this.documentClickListener = this.renderer.listen("body", "click", () => {
			if (!this.topbarItemClick) {
				this.activeTopbarItem = null
				this.topbarMenuActive = false
			}

			if (!this.menuClick && (this.isHorizontal() || this.isSlim())) {
				this.menuService.reset()
			}

			if (this.configActive && !this.configClick) {
				this.configActive = false
			}

			if (!this.menuClick) {
				if (this.mobileMenuActive) {
					this.mobileMenuActive = false
				}

				if (this.isOverlay()) {
					this.menuActive = false
				}

				this.menuHoverActive = false
				this.unblockBodyScroll()
			}

			if (!this.searchClick) {
				this.search = false
			}

			if (this.inlineMenuActive[this.currentInlineMenuKey] && !this.inlineMenuClick) {
				this.inlineMenuActive[this.currentInlineMenuKey] = false
			}

			this.inlineMenuClick = false
			this.searchClick = false
			this.configClick = false
			this.topbarItemClick = false
			this.topbarRightClick = false
			this.menuClick = false
		})
	}

	onMenuButtonClick(event) {
		this.menuActive = !this.menuActive
		this.topbarMenuActive = false
		this.topbarRightClick = true
		this.menuClick = true

		if (this.isDesktop()) {
			this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive
		} else {
			this.mobileMenuActive = !this.mobileMenuActive
			if (this.mobileMenuActive) {
				this.blockBodyScroll()
			} else {
				this.unblockBodyScroll()
			}
		}

		event.preventDefault()
	}

	onTopbarMobileButtonClick(event) {
		this.mobileTopbarActive = !this.mobileTopbarActive
		event.preventDefault()
	}

	onRightMenuButtonClick(event) {
		this.rightMenuActive = !this.rightMenuActive
		event.preventDefault()
	}

	onMenuClick($event) {
		this.menuClick = true

		if (this.inlineMenuActive[this.currentInlineMenuKey] && !this.inlineMenuClick) {
			this.inlineMenuActive[this.currentInlineMenuKey] = false
		}
	}

	onSearchKeydown(event) {
		if (event.keyCode == 27) {
			this.search = false
		}
	}

	onInlineMenuClick(event, key) {
		if (key !== this.currentInlineMenuKey) {
			this.inlineMenuActive[this.currentInlineMenuKey] = false
		}

		this.inlineMenuActive[key] = !this.inlineMenuActive[key]
		this.currentInlineMenuKey = key
		this.inlineMenuClick = true
	}

	onTopbarItemClick(event, item) {
		this.topbarItemClick = true

		if (this.activeTopbarItem === item) {
			this.activeTopbarItem = null
		} else {
			this.activeTopbarItem = item
		}

		if (item === "search") {
			this.search = !this.search
			this.searchClick = !this.searchClick
		}

		event.preventDefault()
	}

	onTopbarSubItemClick(event) {
		event.preventDefault()
	}

	onRippleChange(event) {
		this.ripple = event.checked
		this.primengConfig.ripple = event.checked
	}

	onConfigClick(event) {
		this.configClick = true
	}
	isTablet() {
		const width = window.innerWidth
		return width <= 1024 && width > 640
	}
	isDesktop() {
		//  return window.innerWidth > 991
		return window.innerWidth > 1024
	}

	isMobile() {
		//  return window.innerWidth <= 991
		return window.innerWidth <= 1024
	}

	isOverlay() {
		return this.menuMode === "overlay" || !this.isDesktop()
	}

	isStatic() {
		return this.menuMode === "static" && this.isDesktop()
	}

	isHorizontal() {
		return this.menuMode === "horizontal"
	}

	isSlim() {
		return this.menuMode === "slim"
	}

	blockBodyScroll(): void {
		if (document.body.classList) {
			document.body.classList.add("blocked-scroll")
		} else {
			document.body.className += " blocked-scroll"
		}
	}

	unblockBodyScroll(): void {
		if (document.body.classList) {
			document.body.classList.remove("blocked-scroll")
		} else {
			document.body.className = document.body.className.replace(new RegExp("(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)", "gi"), " ")
		}
	}

	ngOnDestroy() {
		if (this.userSub) this.userSub.unsubscribe()
		if (this.documentClickListener) {
			this.documentClickListener()
		}
		if (this.confSub) {
			this.confSub.unsubscribe()
			this.confSub = null
		}
	}
	prepareRoute(outlet: RouterOutlet) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData["animation"]
	}
}
