import { Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, Type, ViewChild } from "@angular/core"
import { DashboardConfiguration } from "./DashboardConfiguration"
import { IDashboardElement } from "./IDashboardElement"
import { DashBoardElementHostDirective } from "./dashboard-element.directive"
import { DashboardService } from "./dashboard.service"

@Component({
	selector: "dashboard-element",
	templateUrl: "dashboard-element.component.html",
	styleUrls: ["dashboard-element.component.less"]
})
export class DashboardElementComponent implements OnInit, OnDestroy {
	@ViewChild(DashBoardElementHostDirective, { static: true }) elementHost: DashBoardElementHostDirective
	@ViewChild("defElement", { static: true }) defTemplate: TemplateRef<any>
	components: { tipo: string; type: Type<IDashboardElement> }[] = []

	private _config: DashboardConfiguration
	public get config(): DashboardConfiguration {
		return this._config
	}
	@Input()
	public set config(v: DashboardConfiguration) {
		this._config = v
		this.loadComponent(v.tipo)
	}

	@Output()
	public onChange = new EventEmitter<DashboardConfiguration>()

	public element: IDashboardElement

	public get title() {
		return this.element ? this.element.getTitle() : "Sin titulo"
	}

	public verParams(event) {
		if (this.element) this.element.showParams(event)
	}

	constructor(private componentFactoryResolver: ComponentFactoryResolver, private dbService: DashboardService) {
		this.components = dbService.getComponents()
	}
	ngOnDestroy() {}
	public onDataChange = (data: DashboardConfiguration) => {
		this.onChange.emit(data)
	}
	loadComponent(tipo: string) {
		let c = this.components.filter((e) => e.tipo == tipo)[0]
		const viewContainerRef = this.elementHost.viewContainerRef
		viewContainerRef.clear()
		if (c) {
			const componentFactory = this.componentFactoryResolver.resolveComponentFactory(c.type)

			const componentRef = viewContainerRef.createComponent<IDashboardElement>(componentFactory)

			this.element = componentRef.instance
			this.element.onChange = this.onDataChange
			componentRef.instance.config = this.config
		} else {
			viewContainerRef.createEmbeddedView(this.defTemplate)
		}
	}
	ngOnInit() {}
}
