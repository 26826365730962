import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'help-recordatorios',
	templateUrl: 'help-recordatorios.component.html'
})

export class HelpRecordatoriosComponent implements OnInit {

	ngOnInit() { }
}