import * as moment from "moment"

export class Horario {
	private diasEnum = ["LU", "MA", "MI", "JU", "VI", "SA", "DO;"]
	public get diasSTR() {
		let str = this.dias.map((d) => this.diasEnum[d]).join("-")
		return str
	}
	public get horarioSTR() {
		let hhorario = this.horario ? moment(this.horario).format("HH:mm a") : null
		return !hhorario ? "SIN_HORARIO" : "HORARIO " + hhorario
	}
	constructor(
		public horario: Date = moment("2000-01-01 07:00").toDate(),
		public dias: number[] = [0, 1, 2, 3, 4, 5, 6],
		public desde: Date = null,
		public hasta: Date = null,
		public capacidadMaxima: number = null) { }
	public static fromData(data: any): Horario {
		if (!data) return null
		const o: Horario = new Horario(
			data.horario ? new Date(data.horario) : null,
			// fix para poder heredar de forma simple en el gestor de publicados
			data.dias ? data.dias.map(d => d) : [],
			data.desde ? new Date(data.desde) : null,
			data.hasta ? new Date(data.hasta) : null,
			data.capacidadMaxima)
		return o
	}
}
