import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { Filtro } from "src/app/common/model/Filtro"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Region } from "src/app/model/Region"
import { RegionService } from "src/app/services/region.service"

@Component({
	selector: "listado-region",
	templateUrl: "listado-region.component.html",
	styleUrls: ["listado-region.component.less"]
})
export class ListadoRegionComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: Filtro

	@Input()
	public guardarFiltro: boolean = true
	@Input()
	public ocultarCabecera: boolean = false
	@Input()
	public editable: boolean = true
	public listado: Region[] = []
	@Input()
	public readonly: boolean = false
	@Input()
	public modoSeleccion: "single" | "multiple" | "none" = "none"

	@Output()
	public onSelect: EventEmitter<Region[]> = new EventEmitter()

	public columns = []
	constructor(messagesService: MessagesService, public service: RegionService) {
		super(messagesService)
		console.log(JSON.stringify(this.columns))
	}
	ngOnInit() {
		this.filtro = new Filtro("region_lista", {}, 0, 20, "id", 1, this.guardarFiltro)
		this.columns = [
			{
				field: "codigo",
				header: "Codigo",
				class: "codigo-tabla",
				role: "text-tabla",
				sortable: true,
				order: 1
			},
			{
				field: "id",
				header: "#",
				class: "id-column",
				role: "text-tabla",
				sortable: true,
				order: 1
			},
			{
				field: "pais",
				header: "País",
				class: "descriptivo-column",
				role: "descriptivo-tabla",
				sortable: true,
				order: 1
			},
			{
				field: "descripcion",
				header: "Descripciones",
				class: "text-editable-tabla",
				role: "text-editable-tabla",
				sortable: true,
				order: 40
			},

			{
				field: "habilitado",
				header: "Habilitado",
				class: "is-boolean",
				role: "habilitable-tabla",
				sortable: false,
				command: (event, item) => {
					event?.stopPropagation()
					if (item?.habilitado)
						this.service.deshabilitar(item?.id).then((v) => {
							this.filtro.forceUpdate()
							this.success(this.translateService.get("DESHABILITADO_CORRECTAMENTE"))
						})
					else
						this.service.habilitar(item?.id).then((v) => {
							this.filtro.forceUpdate()
							this.success(this.translateService.get("HABILITADO_CORRECTAMENTE"))
						})
				},
				order: 99
			}
		]
	}

	public updateSeleccion(seleccion: any) {
		if (seleccion?.length || seleccion["id"]) {
			this.onSelect.emit(seleccion?.length ? seleccion : [seleccion])
		}
	}
	ngOnDestroy() {}

	public actualizarDescricpion(data: Region, customLoading?: LoadingService) {
		this.service.actualizarDescripcion(data, customLoading)
	}
}
