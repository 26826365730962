import { Parametrico } from "src/app/common/model/Parametrico"

export class TipoProveedor extends Parametrico {
	public static readonly HOTEL: string = "HTL"
	constructor(id?: number, codigo?: string, descripcion?: string, habilitado: boolean = true, esSistema: boolean = false, peso: number = 99) {
		super(id, codigo, descripcion, habilitado, esSistema, peso)
	}
	public tipoParametro(): string {
		return "TipoProveedorLOW"
	}
	public static fromData(data: any): TipoProveedor {
		if (!data) return null
		const o: TipoProveedor = new TipoProveedor(data.id, data.codigo, data.descripcion, data.habilitado, data.esSistema, data.peso)

		o.version = data.version
		return o
	}
}
