<div class="gestor-producto content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'PRODUCTO' | translate"
		[file]="file"
		[conArchivo]="true"
		[isModal]="isModal"
		[multilenguaje]="true"
		[editable]="permiteEdicion"
		(onEditar)="editar()"
		(onItemGuardado)="handleGuardado($event)"
		(onCancelar)="handleCancelar($event)"
		[form]="ProductoForm"
		[readonly]="readonly"
	>
		<div class="dataContent" model-type="dataContent">
			<div class="subtitulo">
				<span>{{ "INFORMACIÓN_DEL_PRO_4" | translate }}</span>
			</div>
			<div class="gestor-form">
				<form #ProductoForm="ngForm" id="Productoform">
					<div class="grid" *ngIf="item">
						<div class="col-12 lg:col-2 md:col-4" *ngIf="item.esGestionAmplia()">
							<div class="grid">
								<div class="col-12 pic-wrapper">
									<div class="pic">
										<profile-badge
											[seleccionable]="true"
											[url]="item.iconoUrl"
											class="full-badge"
											(onFileSelected)="onPicSelected($event)"
											[disabled]="readonly"
										>
										</profile-badge>
										<i class="fa fa-pencil edit-icon" *ngIf="!readonly"></i>
										<i class="fa fa-trash clean-icon clickable" *ngIf="!readonly && item?.icono" (click)="borrarImagen()"></i>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12" [ngClass]="{ 'lg:col-10': item.esGestionAmplia() }">
							<div class="grid p-fluid">
								<div class="col-12">
									<parametrico-selector
										[styleClass]="'w-16rem'"
										[allowDefault]="!item?.id"
										[service]="tipoProductoService"
										#tipoProducto="ngModel"
										[(ngModel)]="item.tipoProducto"
										[limpiable]="false"
										[permiteNuevo]="false"
										(onSelect)="onSelectTipoProducto($event)"
										name="tipoProducto"
										[titulo]="'TIPOPRODUCTO' | translate"
										[readonly]="item.id"
										[required]="true"
									>
									</parametrico-selector>
								</div>
								<ng-container *ngIf="item?.tipoProducto">
									<div class="col-fixed w-10rem p-fluid">
										<span class="p-float-label field">
											<input
												pInputText
												placeholder="{{ 'CODIGO_PH' | translate }}"
												[(ngModel)]="item.codigo"
												tooltipPosition="bottom"
												pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
												type="text"
												#codigo="ngModel"
												name="codigo"
												[disabled]="readonly"
											/><label>{{ "CODIGO" | translate }}</label>
											<small *ngIf="getErrorMessage(codigo)">
												{{ getErrorMessage(codigo) }}
											</small>
										</span>
									</div>
									<div class="col-fixed w-10rem p-fluid">
										<span class="p-float-label field p-input-icon-right">
											<input
												pInputText
												[(ngModel)]="item.peso"
												tooltipPosition="bottom"
												pTooltip="{{ 'PESO_TOOLTIP' | translate }}"
												type="number"
												#peso="ngModel"
												name="peso"
												class="text-right"
												[disabled]="readonly"
											/><label>{{ "PESO" | translate }}</label>

											<small>
												{{ getErrorMessage(peso) }}
											</small>
										</span>
									</div>
									<div class="col-8 lg:col p-fluid">
										<span class="p-float-label field">
											<input
												pInputText
												placeholder="{{ 'DESCRIPCION_PH' | translate }}"
												[(ngModel)]="item.descripcion"
												tooltipPosition="bottom"
												pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
												type="text"
												#descripcion="ngModel"
												name="descripcion"
												required
												[disabled]="readonly"
											/>
											<label>{{ "DESCRIPCION" | translate }}</label>
											<small>
												{{ getErrorMessage(descripcion) }}
											</small>
										</span>
									</div>
									<div class="col-8 lg:col">
										<span class="p-float-label field">
											<input
												pInputText
												placeholder="{{ 'NOMBRE_COMERCIAL' | translate }}"
												[(ngModel)]="item.nombreComercial"
												tooltipPosition="bottom"
												pTooltip="{{ 'NOMBRE_COMERCIAL' | translate }}"
												type="text"
												#descripcion="ngModel"
												name="nombreComercial"
												[disabled]="readonly"
											/><label>{{ "NOMBRE_COMERCIAL" | translate }}</label>
											<small>
												{{ getErrorMessage(nombreComercial) }}
											</small>
										</span>
									</div>
									<div class="col-12">
										<selector-multiple-chips
											[(ngModel)]="item.regiones"
											name="regiones"
											[servicio]="regionService"
											[permiteNuevo]="true"
											[readonly]="readonly"
											[titulo]="'REGION_PLR' | translate"
											[gestor]="gestorRegion"
										>
										</selector-multiple-chips>
									</div>
									<div class="col-6 lg:col-3 md:col-4" *ngIf="item && !item?.esPaquete">
										<descriptivo-material-selector
											[allowDefault]="!item?.id"
											[service]="familiaService"
											[limpiable]="true"
											[label]="'FAMILIA_PRODUCTO' | translate"
											[permiteNuevo]="true"
											[isLazy]="true"
											name="familiaProducto"
											[readonly]="readonly"
											[disabled]="readonly"
											[gestor]="gestorFamilia"
											[(ngModel)]="item.familiaProducto"
										>
											<ng-template #gestorFamilia let-handler="handler" let-itemEditado="itemEditado">
												<gestor-familia-producto
													[item]="itemEditado"
													(onGuardado)="handler.onGuardado($event)"
													(onCancelado)="handler.onCancelado($event)"
													[isModal]="true"
													[goBack]="false"
												>
												</gestor-familia-producto>
											</ng-template>
										</descriptivo-material-selector>
									</div>

									<div class="col-6 lg:col-3 md:col-4 p-fluid" *ngIf="item && !item?.esPaquete">
										<descriptivo-material-selector
											[allowDefault]="!item?.id"
											[service]="tipoVarianteService"
											[placeHolder]="'TIPO_VARIANTE' | translate"
											name="tipoVariante"
											[limpiable]="!item?.familiaProducto"
											[(ngModel)]="item.tipoVariante"
											[label]="'TIPO_VARIANTE' | translate"
											[readonly]="readonly"
											[permiteNuevo]="true"
											[gestor]="gestorVariante"
											[required]="item?.familiaProducto != undefined"
										>
											<ng-template #gestorVariante let-handler="handler" let-itemEditado="itemEditado">
												<gestor-tipo-variante
													[item]="itemEditado"
													(onGuardado)="handler.onGuardado($event)"
													(onCancelado)="handler.onCancelado($event)"
													[isModal]="true"
													[goBack]="false"
												>
												</gestor-tipo-variante>
											</ng-template>
										</descriptivo-material-selector>
									</div>

									<div class="col-12 lg:col-5 md:col-12">
										<div class="grid">
											<div class="col checkbox-container" *ngIf="item.esServicio() || item?.esPrograma">
												<p-checkbox
													[binary]="true"
													[(ngModel)]="item.esPropio"
													[label]="'PRODUCTO_PROPIO' | translate"
													name="esProdpio"
													tooltipPosition="bottom"
													pTooltip="{{ 'NO_SE_TERCERIZA_A_O_12' | translate }}"
													[disabled]="readonly"
												>
												</p-checkbox>
											</div>
											<div class="col checkbox-container" *ngIf="item.esProducto() || item.esServicio() || item.esTercerizado()">
												<div class="grid">
													<div class="col-12">
														<p-checkbox
															[binary]="true"
															[(ngModel)]="item.adicionable"
															name="adicionable"
															[disabled]="readonly"
															tooltipPosition="bottom"
															[label]="'ES_ADICIONABLE' | translate"
															pTooltip="{{ 'PUEDE_SER_AGREGADO__30' | translate }}"
														>
														</p-checkbox>
													</div>
													<div class="col-12" *ngIf="item.adicionable">
														<p-checkbox
															[binary]="true"
															[(ngModel)]="item.destacarComoAdicional"
															name="destacarComoAdicional"
															[disabled]="readonly"
															tooltipPosition="bottom"
															[label]="'DESTACAR' | translate"
															pTooltip="{{ 'DESTACAR_TT' | translate }}"
														>
														</p-checkbox>
													</div>
												</div>
											</div>
											<div class="col checkbox-container" *ngIf="item.esServicio() || item?.esPrograma || item?.esTercerizado()">
												<p-checkbox
													[binary]="true"
													[(ngModel)]="item.requiereConsentimiento"
													name="requiereConsentimiento"
													tooltipPosition="bottom"
													[label]="'Requiere consentimiento' | translate"
													pTooltip="{{ 'Requiere consentimiento de los paxs' | translate }}"
													[disabled]="readonly"
												>
												</p-checkbox>
											</div>
										</div>
									</div>

									<div class="col-12 lg:col-6">
										<selector-multiple-chips
											[(ngModel)]="item.categorias"
											name="categoria"
											[servicio]="categoriaService"
											[permiteNuevo]="true"
											[readonly]="readonly"
											selector-multiple-chips
											[titulo]="'CATEGORÍA' | translate"
											[gestor]="gestorCategoria"
										>
										</selector-multiple-chips>
									</div>

									<div class="col-fixed w-12rem" *ngIf="item.esServicio() || item.esTercerizado()">
										<span class="p-float-label field">
											<input
												pInputText
												placeholder="{{ 'BASE_POR_GRUPO' | translate }}"
												[(ngModel)]="item.cantidadMaxGrupo"
												tooltipPosition="bottom"
												pTooltip="{{ 'CANTIDAD_MÁXIMA_DE__17' | translate }}"
												type="number"
												#cantidadMaxGrupo="ngModel"
												name="cantidadMaxGrupo"
												required
												[disabled]="readonly"
											/>
											<label>{{ "BASE_POR_GRUPO" | translate }}</label>
											<small>
												{{ getErrorMessage(cantidadMaxGrupo) }}
											</small>
										</span>
									</div>
									<div class="field col-fixed w-10rem" *ngIf="item?.esServicio() || item?.esTercerizado()">
										<span class="p-float-label">
											<p-calendar
												[(ngModel)]="item.horaInicio"
												[timeOnly]="true"
												[hourFormat]="24"
												inputId="timeonly"
												name="horaInicio"
												#horaInicio="ngModel"
												[stepMinute]="30"
												[disabled]="readonly"
												[defaultDate]="OchoAm"
											></p-calendar>
											<label>{{ "Hora default inicio" | translate }}</label>
										</span>
									</div>
									<div class="col-fixed w-10rem field" *ngIf="item?.esServicio() || item?.esTercerizado()">
										<div class="p-inputgroup">
											<span class="p-float-label">
												<input
													pInputText
													placeholder="{{ 'DURACIÓN_DEL_PRODUC_7' | translate }}"
													[(ngModel)]="item.duracion"
													tooltipPosition="bottom"
													pTooltip="{{ 'DURACIÓN_DEL_PRODUC_7' | translate }}"
													type="number"
													#duracion="ngModel"
													[readonly]="readonly"
													name="duracion"
												/>
												<label>{{ "DURACIÓN" | translate }} {{ !item.esPrograma ? "hs" : "días" }}</label>

												<small>
													{{ getErrorMessage(duracion) }}
												</small>
											</span>
											<span class="p-inputgroup-addon">{{ !item.esPrograma ? "hs" : "días" }}</span>
										</div>
									</div>
								</ng-container>
								<div class="col-12 lg:col-4 md:col-6" *ngIf="item?.esProducto()">
									<div class="grid" tooltipPosition="bottom" pTooltip="{{ 'Cantidad X cada base Y personas' | translate }}">
										<div class="col">
											<span class="p-float-label field">
												<input
													pInputText
													placeholder="{{ 'CANTIDAD_PH' | translate }}"
													[(ngModel)]="item.cantidad"
													tooltipPosition="bottom"
													pTooltip="{{ 'CANTIDAD_PROP_TP' | translate }}"
													type="number"
													#cantidad="ngModel"
													name="cantidad"
													[disabled]="readonly"
												/><label>{{ "CANTIDAD" | translate }}</label>
												<small>
													{{ getErrorMessage(cantidad) }}
												</small>
											</span>
										</div>
										<div class="col">
											<span class="p-float-label field">
												<input
													pInputText
													placeholder="{{ 'BASEPAX_PH' | translate }}"
													[(ngModel)]="item.basePersonas"
													tooltipPosition="bottom"
													pTooltip="{{ 'BASEPAX_TP' | translate }}"
													type="number"
													#basePersonas="ngModel"
													name="basePersonas"
													[disabled]="readonly"
												/><label>{{ "BASEPAX" | translate }}</label>
												<small>
													{{ getErrorMessage(basePersonas) }}
												</small>
											</span>
										</div>
									</div>
								</div>
								<div class="col" *ngIf="item.esHospedaje()">
									<span class="p-float-label field">
										<input
											pInputText
											placeholder="{{ 'CAPACIDAD' | translate }}"
											[(ngModel)]="item.basePersonas"
											tooltipPosition="bottom"
											pTooltip="{{ 'CAPACIDAD' | translate }}"
											type="number"
											#basePersonas="ngModel"
											name="basePersonas"
											[disabled]="readonly"
										/><label>{{ "CAPACIDAD" | translate }}</label>
										<small>
											{{ getErrorMessage(basePersonas) }}
										</small>
									</span>
								</div>
							</div>
						</div>
						<div class="col-12 lg:col-12 grid-nogutter" *ngIf="item?.tipoProducto">
							<label>{{ "DETALLE_PRODUCTO" | translate }}</label>
							<p-editor
								[(ngModel)]="item.detalle"
								name="detalle"
								tabindex="10"
								[readonly]="readonly"
								[style]="{ height: '100%', 'max-height': '40vh', 'min-height': '20vh', overflow: 'auto', 'background-color': 'white' }"
							>
							</p-editor>

							<p-header *ngIf="readonly">
								<span></span>
							</p-header>
							<small>
								{{ getErrorMessage(detalle) }}
							</small>
						</div>
					</div>
				</form>
			</div>
			<div class="grid" *ngIf="item?.tipoProducto && !item?.esPaquete">
				<div class="col-12 subtitulo">
					<span>{{ "IMPUESTOS" | translate }}</span>
					<p-checkbox
						[(ngModel)]="item.esSubcategorizado"
						name="esSubcategorizado"
						[binary]="true"
						[disabled]="readonly"
						label="{{ 'ES_SUBCATEGORIZADO' | translate }}"
						pTooltip="{{ 'ES_SUBCATEGORIZADO_TOOLTIP' | translate }}"
					>
					</p-checkbox>
				</div>
				<div class="col-12 seccion">
					<div class="grid">
						<div class="col-12 lg:col-2 md:col-4" *ngIf="!item.esSubcategorizado">
							<parametrico-selector
								[allowDefault]="!item?.id"
								[service]="tipoIvaGrabadoService"
								name="tipoIVAP"
								[readonly]="readonly"
								[(ngModel)]="item.tipoIVA"
								#tipoIVAP="ngModel"
								[limpiable]="true"
								[titulo]="'TIPO_DE_IVA' | translate"
								[required]="true"
							>
							</parametrico-selector>
						</div>
						<div class="col-12" *ngIf="item?.esSubcategorizado">
							<abm-subcategorias-imponibles [item]="item" [readonly]="readonly"></abm-subcategorias-imponibles>
						</div>
					</div>
				</div>
			</div>

			<div class="grid" *ngIf="item?.esPaquete">
				<div class="subtitulo col-12">
					<span
						>{{ "CONFIGURACION_SERVICIOS" | translate }}
						<button pButton (click)="agregarNuevoProductoPaquete()" icon="fa fa-plus" *ngIf="!readonly"></button
					></span>
				</div>
				<div class="seccion col-12">
					<div class="grid">
						<div class="col-12 lg:col-4 md:col-6" *ngFor="let prod of item.configuracionPaquete; let i = index">
							<div class="card paquete">
								<button
									pButton
									class="accion quitar p-button-text p-button-danger"
									(click)="quitarProductoPaquete(prod, i)"
									icon="fa fa-trash"
									*ngIf="!readonly"
								></button>
								<div class="grid">
									<div class="col-11">
										<descriptivo-material-selector
											[allowDefault]="!item?.id"
											[service]="service"
											[limpiable]="false"
											[label]="'SERVICIO' | translate"
											[getData]="service.getServicios"
											[permiteNuevo]="false"
											[isLazy]="true"
											[readonly]="readonly"
											required
											[disabled]="readonly"
											[(ngModel)]="prod.producto"
										>
										</descriptivo-material-selector>
									</div>
									<div class="col-12">
										<p-checkbox
											[(ngModel)]="prod.autoasignar"
											[binary]="true"
											name="{{ 'autoasignar' }}"
											label="{{ 'AUTOASIGNAR' | translate }}"
											[disabled]="readonly"
										>
										</p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="grid" *ngIf="item?.esPrograma">
				<div class="subtitulo col-12">
					<span
						>{{ "CONFIGURACION_SERVICIOS" | translate }}
						<button pButton (click)="agregarNuevoProductoPrograma()" icon="fa fa-plus" *ngIf="!readonly"></button
					></span>
				</div>
				<div class="seccion col-12">
					<div *ngFor="let prod of item.productosPrograma; let i = index">
						<div class="grid">
							<div class="col-4">
								<descriptivo-material-selector
									[allowDefault]="!item?.id"
									[service]="service"
									[limpiable]="false"
									[label]="'SERVICIO' | translate"
									[getData]="service.getServicios"
									[permiteNuevo]="false"
									[isLazy]="true"
									[readonly]="readonly"
									required
									[disabled]="readonly"
									[(ngModel)]="prod.producto"
								>
								</descriptivo-material-selector>
							</div>
							<div class="col-2">
								<span class="p-float-label field">
									<input
										pInputText
										placeholder="{{ 'DIA' | translate }}"
										[(ngModel)]="prod.dia"
										tooltipPosition="bottom"
										pTooltip="{{ 'DIA' | translate }}"
										type="number"
										#dia="ngModel"
										[readonly]="readonly"
										name="{{ 'dia' + i }}"
										required
									/><label>{{ "DIA" | translate }}</label>
									<small>
										{{ getErrorMessage(dia) }}
									</small>
								</span>
							</div>
							<div class="col-3">
								<span class="p-float-label">
									<p-calendar
										[(ngModel)]="prod.horaActividad"
										name="{{ 'hora' + i }}"
										showTime="true"
										[hourFormat]="24"
										[stepMinute]="5"
										#horaInicio="ngModel"
										required
										[disabled]="readonly"
										timeOnly="true"
									></p-calendar>
									<label for="horapickup">Hora de inicio</label>
								</span>
							</div>
							<div class="col-2">
								<p-checkbox
									[(ngModel)]="prod.autoasignar"
									[binary]="true"
									name="{{ 'autoasignar' }}"
									label="{{ 'AUTOASIGNAR' | translate }}"
									[disabled]="readonly"
								>
								</p-checkbox>
							</div>
							<div class="col-1 text-center p-3">
								<button pButton class="accion" (click)="quitarProductoPrograma(prod, i)" icon="fa fa-trash" *ngIf="!readonly"></button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="grid m-0" *ngIf="item?.tipoProducto && !item?.esPaquete">
				<div class="subtitulo col-12">
					<span
						>{{ "Configuración de Proveedores" | translate }}
						<button
							pButton
							(click)="agregarNuevoPuesto('PE')"
							icon="fa fa-plus"
							*ngIf="!readonly"
							[disabled]="item?.esTercerizado() && item?.puestosACubrir?.length >= 1"
						></button>
						<button
							pButton
							(click)="verCopiarConfiguracion('PE')"
							icon="fa fa-copy"
							pTooltip="{{ 'COPIAR_CONFIGURACION_TT' | translate }}"
							label="{{ 'COPIAR_CONFIGURACION' | translate }}"
							*ngIf="!readonly"
						></button>
					</span>
				</div>
				<div class="seccion col-12">
					<div class="grid">
						<div class="col-12 lg:col-6 puesto-wrapper" *ngFor="let puesto of item.puestosACubrir; let i = index">
							<button
								pButton
								class="trash-card"
								icon="fa fa-trash"
								(click)="eliminarPuesto(puesto)"
								*ngIf="!readonly && esEliminablePuesto(puesto)"
							></button>
							<div class="puesto card">
								<div class="grid">
									<div class="col-6">
										<puesto-badge
											[puesto]="puesto"
											[(ngModel)]="puesto.personalDefault"
											[disabled]="readonly"
											[verDetalle]="true"
											name="{'personalDefaul'+i}"
											[permiteNoCompatibles]="true"
										>
										</puesto-badge>
									</div>
									<div class="col-6 checkbox-container align-content-center" *ngIf="item?.esServicio() || item?.esPrograma">
										<p-checkbox
											[(ngModel)]="puesto.esOpcional"
											[binary]="true"
											name="{{ 'esOpcional' + iPuesto }}"
											label="{{ 'Es opcional' | translate }}"
											[disabled]="readonly"
										>
										</p-checkbox>
									</div>
									<div class="col-12 subtitulo" *ngIf="!item.esPrograma">
										<span
											>{{ "COSTOS_ASOCIADOS" | translate }}
											<button pButton (click)="agregarInsumo(puesto)" icon="fa fa-plus" *ngIf="!readonly"></button>
										</span>
									</div>
									<div class="col-12 insumo-wrapper" *ngFor="let insumoPuesto of puesto.insumos; let i = index">
										<insumo
											[contenedor]="puesto"
											[insumo]="insumoPuesto"
											[index]="i"
											[tipo]="puesto.tipoPuesto"
											[insumos]="insumos"
											[proporcion]="false"
											[readonly]="readonly"
										>
										</insumo>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="subtitulo col-12" *ngIf="(item?.esServicio() || item?.esPrograma) && item?.tipoProducto && !item?.esPaquete">
					<span
						>{{ "RESTRICCION_VEHICULOS" | translate }} <button pButton (click)="agregarVehiculo()" *ngIf="!readonly" icon="fa fa-plus"></button
					></span>
				</div>
				<div class="seccion col-12" *ngIf="(item?.esServicio() || item?.esPrograma) && item?.tipoProducto">
					<div class="grid">
						<div class="col-12 lg:col-6 puesto-wrapper" *ngFor="let puesto of item.vehiculosACubrir; let iPuesto = index">
							<button pButton class="trash-card" icon="fa fa-trash" *ngIf="!readonly" (click)="eliminarVehiculo(puesto)"></button>

							<div class="puesto card">
								<div class="grid">
									<div class="col-5">
										<parametrico-selector
											[allowDefault]="!item?.id"
											[permiteNuevo]="true"
											[service]="tipoVehiculoService"
											#tipoVehiculo="ngModel"
											[(ngModel)]="puesto.tipoVehiculo"
											[limpiable]="true"
											name="{{ 'tipoVehiculo' + iPuesto }}"
											[titulo]="'TIPO_VEHICULO' | translate"
											[readonly]="readonly"
										>
										</parametrico-selector>
									</div>
									<div class="col-3 align-content-center justify-content-center text-center">
										<span class="p-float-label field">
											<input
												pInputText
												placeholder="{{ 'CAPACIDAD_MINIMA' | translate }}"
												[(ngModel)]="puesto.capacidadMinima"
												tooltipPosition="bottom"
												pTooltip="{{ 'CAPACIDAD_MINIMA' | translate }}"
												type="number"
												#capacidadMinima="ngModel"
												name="{{ 'capacidad' + iPuesto }}"
												min="0"
												[disabled]="readonly"
											/><label>{{ "CAPACIDAD_MINIMA" | translate }}</label>
											<small>
												{{ getErrorMessage(duracion) }}
											</small>
										</span>
									</div>
									<div class="col-2 align-content-center">
										<p-checkbox
											[(ngModel)]="puesto.es4x4"
											[binary]="true"
											(onChange)="toggleVehiculoPrincipal($event, puesto)"
											name="{{ 'es4x4' + iPuesto }}"
											label="{{ 'ES_4X4' | translate }}"
											[disabled]="readonly"
										>
										</p-checkbox>
									</div>

									<!--div class="col-12 subtitulo">
										<span
											>{{ "COSTOS_ASOCIADOS" | translate }}
											<button pButton (click)="agregarInsumo(puesto)" icon="fa fa-plus" *ngIf="!readonly"></button>
										</span>
									</div>
									<div class="col-12 insumo-wrapper" *ngFor="let insumoPuesto of puesto.insumos; let i = index">
										<insumo
											[contenedor]="puesto"
											[insumo]="insumoPuesto"
											[index]="i"
											[tipo]="'VEHI'"
											[insumos]="insumos | puestoByTipo : 'VEHI'"
											[proporcion]="false"
											[readonly]="readonly"
										></insumo>
									</div-->
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="subtitulo col-12" *ngIf="item?.tipoProducto && !item?.esPaquete">
					<span
						>{{ "SERVICIOS_Y_PRODUCTOS_ADICIONALES" | translate }}
						<button pButton (click)="agregarProducto()" icon="fa fa-plus" *ngIf="!readonly"></button>
					</span>
				</div>

				<div class="seccion col-12" *ngIf="item?.tipoProducto">
					<div class="grid grid-nogutter">
						<div class="insumo-wrapper col-12 lg:col-9" *ngFor="let pAsosciado of item?.productosAsociados; let i = index">
							<div class="grid insumo">
								<div class="col-4">
									<descriptivo-material-selector
										[allowDefault]="!item?.id"
										[options]="adicionables"
										[placeHolder]="'NOMBRE_DEL_PRODUCTO' | translate"
										[floatLabel]="i == 0 ? 'auto' : 'never'"
										name=" {{ 'asociado_' + i }}"
										[limpiable]="false"
										[(ngModel)]="pAsosciado.asociado"
										[label]="index == 0 ? ('NOMBRE_DEL_PRODUCTO' | translate) : null"
										required
										[readonly]="readonly"
									>
									</descriptivo-material-selector>
								</div>
								<div class="col-2 checkbox-container" *ngIf="!item.esTercerizado">
									<p-checkbox
										[binary]="true"
										[(ngModel)]="pAsosciado.esPorServicio"
										name="esPorServicio"
										[label]="'ES_POR_SERVICIO' | translate"
										tooltipPosition="bottom"
										pTooltip="{{ 'EL_COSTO_SE_APLICA__19' | translate }}"
										[disabled]="readonly"
									>
									</p-checkbox>
								</div>
								<div class="col-2 checkbox-container">
									<p-checkbox
										[binary]="true"
										[label]="'CON_PRECIO' | translate"
										[(ngModel)]="pAsosciado.conPrecio"
										name=" {{ 'conPrecio_' + i }}"
										tooltipPosition="bottom"
										pTooltip="{{ 'CON_PRECIO' | translate }}"
										[disabled]="readonly"
									>
									</p-checkbox>
								</div>
								<div class="col-2">
									<span class="p-float-label field">
										<input
											pInputText
											placeholder="{{ 'CANTIDAD_PH' | translate }}"
											[(ngModel)]="pAsosciado.cantidad"
											tooltipPosition="bottom"
											pTooltip="{{ 'CANTIDAD_TP' | translate }}"
											type="number"
											#cantidad="ngModel"
											name="cantidad"
											required
											min="1"
											[disabled]="readonly"
										/><label>{{ "CANTIDAD" | translate }}</label>
										<small>
											{{ getErrorMessage(cantidad) }}
										</small>
									</span>
								</div>
								<div class="col mt-2" *ngIf="!readonly">
									<button pButton icon="fa fa-close accion" (click)="item.quitarProducto(pAsosciado)"></button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="subtitulo col-12" *ngIf="item?.esProducto() && !item?.esPaquete">
					<span
						>{{ "INSUMOS" | translate }}
						<button pButton (click)="agregarInsumoProducto()" icon="fa fa-plus"></button>
					</span>
				</div>

				<div class="seccion col-12" *ngIf="item?.esProducto() && !item?.esPaquete">
					<div class="grid grid-nogutter">
						<div class="insumo-wrapper col-12 lg:col-7" *ngFor="let insumoProducto of item.insumos; let i = index">
							<insumo [index]="i" [contenedor]="item" [insumo]="insumoProducto" [insumos]="insumos"> </insumo>
						</div>
					</div>
				</div>
				<div class="col-12 lg:col-6" *ngIf="(item?.esServicio() || item?.esPrograma || item?.esTercerizado) && !item?.esPaquete">
					<div class="grid">
						<div class="subtitulo col-12">
							<span>{{ "RECORDATORIOS" | translate }} </span>
						</div>
						<div class="seccion col-12 lg:col-6">
							<parametrico-selector
								[allowDefault]="!item?.id"
								[listado]="tiposRecordatorio"
								#tipoNotificacion="ngModel"
								[(ngModel)]="item.tipoRecordatorio"
								[limpiable]="true"
								[permiteNuevo]="false"
								name="tipoNotificacion"
								[titulo]="'RECORDATORIO_PARA_E_9' | translate"
								[readonly]="readonly"
							>
							</parametrico-selector>
						</div>
						<div class="col-12 lg:col-6 checkbox-container">
							<p-checkbox
								[(ngModel)]="item.notificaHotel"
								[binary]="true"
								name="notificaHotel"
								label="{{ 'RECORDATORIO_PARA__HOSPEDAJE' | translate }}"
								[disabled]="readonly"
								pTooltip="{{ 'ENVIAR_RECORDATORIO_AL_HOSPEDAJE_PARA_ESTE_SERVICIO' | translate }}"
							>
							</p-checkbox>
						</div>
					</div>
				</div>
				<div class="col-12 lg:col-6" *ngIf="!item?.esProducto() && !item?.esInsumo() && !item?.esPaquete">
					<div class="grid">
						<div class="subtitulo col-12">
							<span>{{ "ENCUESTA" | translate }} </span>
						</div>
						<div class="seccion col-12 lg:col-6">
							<descriptivo-material-selector
								[allowDefault]="!item?.id"
								[service]="encuestaService"
								[placeHolder]="'ENCUESTA' | translate"
								[(ngModel)]="item.encuesta"
								[readonly]="readonly"
								#encuesta="ngModel"
								name="encuesta"
								[isLazy]="true"
								[limpiable]="true"
							>
							</descriptivo-material-selector>
						</div>
					</div>
				</div>
			</div>
		</div>
	</model-gestor>
</div>
<ng-template #gestorCategoria let-handler="handler" let-itemEditado="itemEditado">
	<gestor-categoria-producto
		[item]="itemEditado"
		(onGuardado)="handler.onGuardado($event)"
		(onCancelado)="handler.onCancelado($event)"
		[isModal]="true"
		[goBack]="false"
	>
	</gestor-categoria-producto>
</ng-template>

<p-dialog
	[(visible)]="mostrarSeleccionPuesto"
	[modal]="true"
	#dialDetail
	header="{{ 'ELIJA_UN_TIPO_DE_PU_3' | translate }}"
	[styleClass]="'gestor-dialog'"
	(onShow)="onDialogShow($event, dialDetail)"
>
	<ng-container *ngIf="mostrarSeleccionPuesto">
		<descriptivo-material-selector
			[allowDefault]="!item?.id"
			[(ngModel)]="tipoPuestoElegido"
			[service]="tiposPuestos"
			[isLazy]="true"
			[limpiable]="false"
			[label]="'TIPO_DE_PUESTO' | translate"
			[permiteNuevo]="true"
			name="puesto"
			[gestor]="gestorPuesto"
			required
			(onSelect)="confirmarPuesto($event)"
		>
			<ng-template #gestorPuesto let-handler="handler" let-itemEditado="itemEditado">
				<gestor-tipo-puesto
					[item]="itemEditado"
					(onGuardado)="handler.onGuardado($event)"
					(onCancelado)="handler.onCancelado($event); mostrarSeleccionPuesto = false"
					[isModal]="true"
					[goBack]="false"
					[categoria]="'PE'"
				>
				</gestor-tipo-puesto>
			</ng-template>
		</descriptivo-material-selector>
	</ng-container>
</p-dialog>

<p-dialog
	[(visible)]="displayCopiarConfiguracion"
	[modal]="true"
	#dialCopia
	header="{{ 'ELIJA_EL_PRODUCTO_ORIGEN' | translate }}"
	[styleClass]="'gestor-dialog'"
	(onShow)="onDialogShow($event, dialDetail)"
>
	<ng-container *ngIf="displayCopiarConfiguracion">
		<div class="grid">
			<div class="col-12">
				<descriptivo-material-selector
					[(ngModel)]="productoOrigen"
					[service]="service"
					[isLazy]="true"
					[limpiable]="false"
					[label]="'PRODUCTO' | translate"
					name="productoOrigen"
					required
				>
				</descriptivo-material-selector>
			</div>
			<div class="col-12 text-center p-fluid">
				<button pButton label="{{ 'COPIAR' | translate }}" (click)="copiarConfiguracion($event)" [disabled]="!productoOrigen"></button>
			</div>
		</div>
	</ng-container>
</p-dialog>

<ng-template #gestorRegion let-handler="handler">
	<gestor-region (onGuardado)="handler.onGuardado($event)" (onCancelado)="handler.onCancelado($event)" [isModal]="true" [goBack]="false"> </gestor-region>
</ng-template>
