import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
	name: "formatLog",
	pure: false // Establecer a false para recalcular cuando los datos cambian
})
export class FormatLogPipe implements PipeTransform {
	transform(logs: string[]): string {
		if (!logs) return ""
		return logs
			.map((line) => {
				if (line.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/)) {
					return `<span class="log-date">${line.substring(0, 19)}</span>${line.substring(19)}`
				}
				return `<span class="log-continuation">${line}</span>`
			})
			.join("<br>")
	}
}
