import { SerieAusencia } from './SerieAusencia';
import { Ausencia} from './Ausencia';


export class CalendarEvent  {
    constructor(
		public ausencia?: Ausencia,
		public serie?: SerieAusencia
    ) {

    }

}