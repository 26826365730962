import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import * as moment from "moment"
import { ConfirmationService } from "primeng/api"
import { FileUpload } from "primeng/fileupload"
import { Subscription } from "rxjs"
import { ID, MonedaEntorno } from "src/app/app.module"
import { FacturasAG } from "src/app/authguards/FacturasAG"
import { ProveedoresAG } from "src/app/authguards/ProveedoresAG"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Filtro } from "src/app/common/model/Filtro"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { round } from "src/app/common/utils/MathUtils"
import { Comision } from "src/app/model/Comision"
import { Factura } from "src/app/model/Factura"
import { GastoPuesto } from "src/app/model/GastoPuesto"
import { ItemFactura } from "src/app/model/ItemFactura"
import { Pago } from "src/app/model/Pago"
import { Proveedor } from "src/app/model/Proveedor"
import { TipoIVAGrabado } from "src/app/model/TipoIVAGrabado"
import { CajaService } from "src/app/services/caja.service"
import { CentroCostoService } from "src/app/services/centro-costo.service"
import { ClienteService } from "src/app/services/cliente.service"
import { FacturaService } from "src/app/services/factura.service"
import { FormaPagoService } from "src/app/services/forma-pago.service"
import { InsumoService } from "src/app/services/insumo.service"
import { MonedaService } from "src/app/services/moneda.service"
import { ProveedorService } from "src/app/services/proveedor.service"
import { SubcategoriaImponibleService } from "src/app/services/subcategoria-imponible.service"
import { TipoComprobanteService } from "src/app/services/tipo-comprobante.service"
import { TipoIVAGrabadoService } from "src/app/services/tipo-iva-grabado.service"
import { SessionComponent } from "./../../../common/components/session-component.component"
import { LoadingService } from "./../../../common/services/loading-data-service.service"
import { Insumo } from "./../../../model/Insumo"

@Component({
	selector: "gestor-factura",
	templateUrl: "gestor-factura.component.html",
	styleUrls: ["gestor-factura.component.less"],
	encapsulation: ViewEncapsulation.None
})
export class GestorFacturaComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	@Input()
	public item: Factura
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false
	public uploadUrl: string

	public insumoEditado: Insumo

	@Output()
	public onGuardado: EventEmitter<Factura> = new EventEmitter<Factura>()

	@Output()
	public onCancelado = new EventEmitter()

	public editable: boolean = false
	public proveedorOptions: Descriptivo[] = []
	public tipoComprobanteOptions: Descriptivo[] = []
	public centroCostoOptions: Descriptivo[] = []
	public usuarioOptions: Descriptivo[] = []
	public tiposIVAOptions: TipoIVAGrabado[] = []
	public defaultIVA: TipoIVAGrabado
	@Input()
	public readonly: boolean = false
	//nuevo insumo
	public itemEditado: ItemFactura
	public editando = false

	private _proveedor: Proveedor
	public get proveedor(): Proveedor {
		return this._proveedor
	}
	public set proveedor(v: Proveedor) {
		this._proveedor = v

		if (v?.id && v.cliente) {
			this.cliente = v.cliente
		} else {
			this.cliente = null
		}
	}

	cliente: Descriptivo
	public insumosGroups: any[] = [
		{ label: "Asociados", value: "a", items: [] },
		{ label: "No Asociados", value: "n", items: [] }
	]
	public insumosGroupOptions: { label: string; value: string; items: any[] }[]
	private desdeProveedor: any = {}
	//pagos
	public formaPagoOptions: Descriptivo[] = []
	public cajaOptions: Descriptivo[] = []
	public subcategoriaOptions: Descriptivo[] = []
	public edicionRestringidaSaldo: boolean = false
	@ViewChild("fileInput", { static: false })
	private fileUpload: FileUpload
	constructor(
		messagesService: MessagesService,
		public service: FacturaService,
		private route: ActivatedRoute,
		private router: Router,
		private confService: ConfirmationService,
		public proveedorService: ProveedorService,
		private centroCostoService: CentroCostoService,
		private insumoService: InsumoService,
		private tipoIVAService: TipoIVAGrabadoService,
		private tipoCompService: TipoComprobanteService,
		private formaPagoService: FormaPagoService,
		private cajaService: CajaService,
		private subcategoriaService: SubcategoriaImponibleService,
		public monedaService: MonedaService,
		public facturaAG: FacturasAG,
		private clienteService: ClienteService,
		private insumoAG: ProveedoresAG
	) {
		super(messagesService)
		this.desdeProveedor = this.router.getCurrentNavigation()?.extras?.state
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}
	public isValid() {
		if (this.item.pagos.length != 0 && round(this.item.totalPagos) != round(this.item.totalALocal)) {
			return this.error("Los pagos no coinciden con el total de la Factura")
		}
		return (
			(this.item.proveedor && this.item.tipoComprobante && this.item.tipoComprobante.codigo == "TI") ||
			this.item.tipoComprobante.codigo == "AR" ||
			(this.item.puntoVenta &&
				this.item.numero &&
				this.item.fecha &&
				this.item.fechaVencimiento &&
				this.item.items.length > 0 &&
				this.item.items.every((i) => i.isValid()))
		)
	}
	isEdicionRestringida = () => {
		return !this.editable || this.readonly || this.item?.saldo != this.item?.importe || this.item?.tipoComprobante?.codigo == "PA"
	}
	async ngOnInit() {
		this.uploadUrl = this.service.getApiURL() + "fileUpload/adjuntos"

		await this.tipoIVAService.getAll().then((ts) => {
			this.tiposIVAOptions = ts
			this.defaultIVA = ts.filter((t) => t.esDefault)[0]
		})
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
					this.editable = this.facturaAG.esVisible(this.usuario) && this.item?.tipoComprobante?.codigo != "PA"
					this.edicionRestringidaSaldo = true
				}
			})
		)
		if (this.route.snapshot.url.some((u) => u.path == "factura")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				let clonar: boolean = <boolean>params["clonar"]
				let idProveedor: number = <number>params["proveedorId"] || this.usuario?.proveedor?.id
				if (!this.service) return
				if (id) {
					this.service.getById(id).then(async (r) => {
						this.item = r
						this.centroCostoService.getDescriptivos().then((cs) => {
							this.centroCostoOptions = cs
							this.item.centroCosto = cs[0]
						})
						this.proveedorChange(this.item.proveedor)
						if (clonar) {
							this.item = Factura.clonar(r)
							this.editable = this.facturaAG.esVisible(this.usuario)
							this.edicionRestringidaSaldo = this.isEdicionRestringida()
						} else {
							this.editable = this.facturaAG.esVisible(this.usuario) && this.item?.tipoComprobante?.codigo != "PA"
						}
						this.proveedorChange(this.item.proveedor)
						this.edicionRestringidaSaldo = this.isEdicionRestringida()
					})
				} else {
					this.item = this.service ? this.service.newEnt() : null
					this.centroCostoService.getDescriptivos().then((cs) => {
						this.centroCostoOptions = cs
						this.item.centroCosto = cs[0]
					})
					this.item.moneda = MonedaEntorno()
					this.editable = this.facturaAG.esVisible(this.usuario)
					this.edicionRestringidaSaldo = this.isEdicionRestringida()
					if (this.desdeProveedor) {
						if (this.desdeProveedor.gastos && this.desdeProveedor.gastos.length > 0) {
							this.item.moneda = this.desdeProveedor.gastos[0].moneda || MonedaEntorno()
						} else if (this.desdeProveedor.comisiones && this.desdeProveedor.comisiones.length > 0) {
							this.item.moneda = this.desdeProveedor.comisiones[0].moneda || MonedaEntorno()
						} else {
							this.item.moneda = MonedaEntorno()
						}
						this.item.proveedor = this.desdeProveedor.proveedor
						this.proveedorChange(this.item.proveedor)
						if (this.desdeProveedor.gastos) {
							this.desdeProveedor.gastos.forEach((p: GastoPuesto) => {
								if (p.insumo.esSubcategorizado) {
									this.nuevoItemSubcategorizado(p)
								} else {
									this.nuevoItem(p)
								}
							})
						}

						if (this.desdeProveedor.comisiones) {
							this.desdeProveedor.comisiones.forEach((c: Comision) => {
								this.nuevoItemComision(c)
							})
							//this.item.puestos = this.desdeProveedor.gastos.map(p => p.id);
						}
					} else {
						if (idProveedor) {
							this.proveedorService.getById(idProveedor).then((p) => {
								this.item.proveedor = p
								this.proveedor = p

								this.proveedorChange(this.item.proveedor)
							})
						}
					}
				}
			})
		}
		let f = new Filtro("dummy", {})
		f.size = 200
		//this.proveedorService.getDescriptivos().then(ps => this.proveedorOptions = ps);

		this.tipoCompService.getAll(f).then((ts) => (this.tipoComprobanteOptions = ts.filter((t) => t.codigo != "PA")))
		this.formaPagoService.getAll().then((fs) => (this.formaPagoOptions = fs.filter((f) => f.codigo == "EF")))
		this.cajaService.getAll().then((cs) => (this.cajaOptions = cs))
		this.subcategoriaService.getAll().then((ss) => (this.subcategoriaOptions = ss))
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}
	public verOrdenPago = (idOrden) => {
		let newRelativeUrl = this.router.createUrlTree(["orden-pago/vista"], { queryParams: { id: idOrden } })
		let baseUrl = window.location.href.replace(this.router.url, "")
		window.open(baseUrl + newRelativeUrl, "_blank")
	}
	public nuevoItemSubcategorizado(puesto?: GastoPuesto) {
		let items: ItemFactura[] = []
		const key = ID()
		puesto.insumo.subcategorias.forEach((s) => {
			let desc = s.subcategoriaImponible?.descripcion
			let importe = s.getImporte(puesto.importe * puesto.cantidad)
			let i = new ItemFactura(
				null,
				this.item.centroCosto,
				puesto.insumo,
				puesto.insumo.unidad,
				puesto.cantidad,
				desc,
				s.tipoIVA,
				round(importe / puesto.cantidad, 2),
				importe,
				puesto.id
			)
			i["insumoKey"] = i.insumo.id
			i.proporcion = s.proporcion
			i.esSubcategoria = true
			i.referenciaPadre = key
			i.subcategoria = s.subcategoriaImponible
			items.push(i)
		})
		const dif = puesto.total - items.reduce((a, b) => (a += b.importe), 0)
		if (dif != 0) {
			items[items.length - 1].importe += dif / items[items.length - 1].cantidad
		}

		let itemPrincipal = this.nuevoItem(puesto)
		items.forEach((i) => {
			this.item.addItem(i)
		})
		itemPrincipal.referenciaPadre = key
		itemPrincipal["insumoKey"] = itemPrincipal.insumo.id
	}
	public nuevoItem(puesto?: GastoPuesto) {
		if (puesto) {
			let desc = puesto.descripcion + (puesto?.idReserva ? " " + moment(puesto.fechaActividad).format("DD/MM/YYYY") + " - R°:" + puesto.idReserva : "")
			let i = new ItemFactura(
				null,
				this.item.centroCosto,
				puesto.insumo,
				puesto.insumo.unidad,
				puesto.cantidad,
				desc,
				puesto.tipoIVA,
				puesto.importe,
				puesto.total,
				puesto.id
			)
			i["insumoKey"] = i.insumo.id
			this.item.addItem(i)
			return i
		} else {
			let i = new ItemFactura(null, this.item.centroCosto, null, null, 1, null, this.tiposIVAOptions[0], null, null, puesto ? puesto.id : null)
			i.referenciaPadre = ID()
			this.item.addItem(i)
			return i
		}
	}

	public nuevoItemComision(comision?: any) {
		if (comision) {
			let desc = comision.descProducto + " - " + moment(comision.fecha).format("DD/MM/YYYY") + " - " + comision.reserva
			this.item.addItem(
				new ItemFactura(
					null,
					this.item.centroCosto,
					new Insumo(null, Insumo.COMISION, "Comisión", comision.idInsumo),
					null,
					1,
					desc,
					this.tiposIVAOptions[0],
					comision.importe,
					comision.importe,
					null,
					comision.id
				)
			)
		} else {
			this.item.addItem(
				new ItemFactura(null, this.item.centroCosto, null, null, 1, null, this.tiposIVAOptions[0], null, null, null, comision ? comision.id : null)
			)
		}
	}

	puntoVentaChange(event) {
		this.item.puntoVenta = this.item.puntoVenta.toString().padStart(5, "0")
	}

	numeroChange(event) {
		this.item.numero = this.item.numero.toString().padStart(8, "0")
	}

	cantidadChange(event, item: ItemFactura) {
		this.getPrecio(item.insumo, item)
		item.importe = Math.round(item.importeUnitario * item.cantidad * 100) / 100
		item.importeModificado = this.verificarPrecio(item)
		item.esCategorizado() && this.actualizarSubcategorias(item)
	}
	actualizarSubcategorias(item: ItemFactura) {
		if (item.esCategorizado()) {
			this.item.items
				.filter((it) => it.esSubcategoria && it.referenciaPadre == item.referenciaPadre)
				.forEach((it) => {
					it.actualizarPorProporcion(item.importeUnitario, item.cantidad)
				})
			item.impuestosSubcategoria = this.item.items
				.filter((it) => it.esSubcategoria && it.referenciaPadre == item.referenciaPadre)
				.reduce((a, b) => (a += b.impuestos), 0)
		}
	}
	unitarioChange(event, item: ItemFactura) {
		item.importe = Math.round(item.importeUnitario * item.cantidad * 100) / 100
		item.importeModificado = this.verificarPrecio(item)
		item.esCategorizado() && this.actualizarSubcategorias(item)
	}

	totalChange(event, item: ItemFactura) {
		item.importeUnitario = Math.round((item.importe / item.cantidad) * 100) / 100

		item.importeModificado = this.verificarPrecio(item)
		item.esCategorizado() && this.actualizarSubcategorias(item)
	}

	onProveedorGuardado(proveedor: Proveedor) {
		this.proveedorOptions.push(Descriptivo.fromData(proveedor))
	}

	filterGroup(event: any) {
		let value = event.query
		this.insumosGroupOptions = this.insumosGroups
			.map((group) => ({ label: group.label, value: group.value, items: value ? this.filterDescriptivos(group.items, value) : group.items }))
			.filter((group) => group.items.length > 0)
	}

	filterDescriptivos(lista: Insumo[], value: string): Insumo[] {
		const filterValue = value.toLowerCase()
		return lista.filter((item) => item.value == "$new$" || item.descripcion.toLowerCase().indexOf(filterValue) != -1)
	}

	public getOptionText(option: Descriptivo) {
		return option ? option.descripcion : ""
	}

	public nuevoInsumo(item: ItemFactura) {
		if (!this.item.proveedor) {
			this.error("Seleccione un proveedor")
			return
		}
		this.itemEditado = item
		this.insumoEditado = new Insumo(null, null, item.descripcion)
		this.editando = true
	}

	public onDialogShow(event, dialog) {
		if (this.isMobile()) {
			dialog.maximized = true
		}
	}

	public onGuardadoInsumo(insumo: Insumo) {
		this.itemEditado.insumo = insumo
		this.editando = false
		this.insumoEditado = null
		this.itemEditado.insumoKey = insumo.id
		/*this.insumoService.asociarProveedor(insumo, this.item.proveedor).then((ok) => {
			this.agregarSubcategorias(this.itemEditado)
			this.actualizarInsumos(this.item.proveedor)
		})*/
	}

	public onCanceladoInsumo(event) {
		this.editando = false
	}

	public proveedorChange(proveedor: any) {
		let $this = this
		if (proveedor.prev) {
			this.item.items = []
			this.item.destinatario = null
		}
		if (proveedor && proveedor.id) {
			//if (proveedor.tipoProveedor?.codigo != "VE") {
			this.actualizarInsumos(proveedor)
			// }
			if (!this.item.destinatario) {
				this.proveedorService.getById(proveedor.id, new LoadingService()).then((p) => {
					this.item.destinatario = p.email
					this.proveedor = Proveedor.fromData(p)
				})
			}
		}
	}
	insumoElegido = (event, item) => {
		if (event.value == "$new$") {
			item.insumo = null
			this.nuevoInsumo(item)
		} else {
			this.actualizarInsumo(item)
		}
	}
	public actualizarInsumos(proveedor) {
		let ps = []
		ps.push(
			this.insumoService.getByProveedor(proveedor.id).then((is) => {
				this.insumosAsociados.items = is
				if (!this.readonly && this.insumoAG.esVisible(this.usuario) && !this.usuario?.esExterno) {
					this.insumosNoAsociados.items.push({ label: "Nuevo Insumo", value: "$new$", descripcion: "Nuevo Insumo" })
				}
			})
		)
		ps.push(
			this.insumoService.getAll().then((is) => {
				this.insumosNoAsociados.items = is
				if (!this.readonly && this.insumoAG.esVisible(this.usuario) && !this.usuario?.esExterno) {
					this.insumosNoAsociados.items.push({ label: "Nuevo Insumo", value: "$new$", descripcion: "Nuevo Insumo" })
				}
			})
		)
		Promise.all(ps).then((r) => {
			this.insumosNoAsociados.items = this.insumosNoAsociados.items.filter(
				(i) => i.value == "$new$" || !this.insumosAsociados.items.some((iasoc) => iasoc.id == i.id)
			)
		})
		this.insumosGroupOptions = [...this.insumosGroups]
	}
	get insumosAsociados() {
		return this.insumosGroups.find((g) => g.value == "a")
	}
	get insumosNoAsociados() {
		return this.insumosGroups.find((g) => g.value == "n")
	}
	get filasExtrasImpuestos() {
		return this.item.ivas.length < 1 ? [] : this.item.ivas.splice(1)
	}

	get finalTitle(): string {
		return (
			(this.item?.id &&
				!this.editable &&
				`${this.translateService.get("VISUALIZANDO")} ${this.translateService.get("FACTURA")} ${this.item.tipoNumero}`) ||
			(this.item?.id && `${this.translateService.get("EDITANDO")} ${this.translateService.get("FACTURA")} ${this.item.tipoNumero}`) ||
			this.translateService.get("NUEVA") + " " + this.translateService.get("FACTURA")
		)
	}

	nuevoPago() {
		this.item.pagos.push(new Pago(null, null, null, this.item.saldoRestante))
	}

	quitarPago(p: Pago) {
		let i = this.item.pagos.indexOf(p)
		if (i > -1) {
			this.item.pagos.splice(i, 1)
		}
	}

	actualizarInsumo(item: ItemFactura) {
		if (item.codigoProductoExterno) {
			this.item.items.filter((i) => i.codigoProductoExterno == item.codigoProductoExterno && i.esSubcategoria).forEach((i) => (i.insumo = item.insumo))
		} else {
			item.insumo && this.getPrecio(item.insumo, item)
			if (!item.insumo || item.insumo?.id != item?.insumoKey) {
				item.insumoKey = item.insumo?.id || null
				this.item.borrarPorReferencia(item.referenciaPadre)
				if (item.insumo?.esSubcategorizado) this.agregarSubcategorias(item)
			}
		}
	}

	agregarSubcategorias(item: ItemFactura) {
		let items: ItemFactura[] = []
		const key = ID()
		item.insumo.subcategorias.forEach((s) => {
			let desc = s.subcategoriaImponible?.descripcion
			let importe = s.getImporte(item.importeUnitario * item.cantidad)
			let i = new ItemFactura(
				null,
				this.item.centroCosto,
				item.insumo,
				item.insumo.unidad,
				item.cantidad,
				desc,
				s.tipoIVA,
				round(importe / item.cantidad, 2),
				importe
			)
			i.insumoKey = i.insumo.id
			i.proporcion = s.proporcion
			i.esSubcategoria = true
			i.subcategoria = s.subcategoriaImponible
			i.referenciaPadre = key
			items.push(i)
		})
		const dif = item.importe - items.reduce((a, b) => (a += b.importe), 0)
		if (dif != 0 && items?.length) {
			items[items.length - 1].importe += dif / items[items.length - 1].cantidad
		}
		this.item.agregarDespues(items, item)
		item.referenciaPadre = key
	}
	getPrecio(event, it: ItemFactura) {
		if (!event?.id) return
		const precio = this.proveedor.getPrecio(event.id, this.item.fecha, this.item?.moneda, it.cantidad)

		if (precio) {
			it.importeUnitario = precio.getCosto(it.cantidad)
			it.importe = it.importeUnitario * it.cantidad || 0
		}
	}
	validarItem(event: FocusEvent, item: ItemFactura) {
		if (!item?.insumo?.codigo) {
			const target = event.target as HTMLInputElement
			target.value = ""
			item.insumo = null
			this.actualizarInsumo(item)
		}
	}
	verificarPrecio(item: ItemFactura): boolean {
		if (!item?.insumo) return
		const precio = this.proveedor.getPrecio(item.insumo.id, this.item.fecha, this.item.moneda, item.cantidad)
		if (!precio || !precio?.id) {
			return true
		} else if (precio.precio == item.importeUnitario || precio.moneda?.codigo != this.item?.moneda?.codigo) {
			return false
		} else {
			return true
		}
	}
}
