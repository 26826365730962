

import { Descriptivo } from 'src/app/common/model/Descriptivo';

import { Parametrico } from 'src/app/common/model/Parametrico';

export const colors ={
    "NA":"white",
    "PC":"orange",
    "CO":"#6e8e32",
    "RE":"#ed6262",
    "PE":"blue",
    "NV":"red"
}
export class EstadoPuesto extends Parametrico {
	public static NO_ASIGNADO = EstadoPuesto.fromData({codigo:"NA", descripcion:"No Asignado"})
	public static PENDIENTE_CONFIRMACION  = EstadoPuesto.fromData({codigo:"PC", descripcion:"Pendiente de Confirmacion"});
	public static CONFIRMADO  = EstadoPuesto.fromData({codigo:"CO", descripcion:"Confirmado"});
	public static RECHAZADO = EstadoPuesto.fromData({codigo:"RE", descripcion:"Rechazado"});
    public static PENDIENTE_ENVIO = EstadoPuesto.fromData({codigo:"PE", descripcion:"Pendiente de Envio"});
    public static SIN_DEFINIR = EstadoPuesto.fromData({codigo:"SD",descripcion:"Sin definir"});
    public static NO_VIGENTE = EstadoPuesto.fromData({codigo:"NV",descripcion:"No Vigente"});




    public color: string;
    constructor(id?: number, codigo?: string, descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,
        esDefault: boolean = false


    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
    }
    public tipoParametro(): string {
        return "$MODELNAMELOW";
    }
    public static fromData(data: any): EstadoPuesto {
        if (!data) return null;
        
        const o: EstadoPuesto = new EstadoPuesto(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,
            data.esDefault,

        );
            o.color = colors[data?.codigo || 'NA']
        o.version = data.version; 
return o;

    }


}