import { Descriptivo } from "src/app/common/model/Descriptivo"
import { ProfilePic } from "src/app/common/model/ProfilePic"
import { IConAdjunto } from "../common-components/IConAdjunto"
import { FileData } from "./FileData"

export class PuntoDeVenta extends Descriptivo implements IConAdjunto {
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public firmanteCertificado?: string,
		public passwordCertificado?: string,
		public certificado?: FileData,
		public numeroPuntoVenta?: number,
		public profilePic: ProfilePic = new ProfilePic(null, "assets/images/default_profile.png"),
		public centro?: Descriptivo,
		public razonSocial?: string,
		public cuit?: string,
		public tipoIngresoBruto?: Descriptivo,
		public iibb?: string,
		public domicilioFiscal?: string,
		public codigoPostal?: string,
		public fechaInicioActividades?: Date,
		public tipoIva?: Descriptivo,
		public tipoDocumento?: Descriptivo,
		public maximoFacturacion?: number,
		public telefono?: string,
		public emailContacto?: string,
		public whatsapp?: string,
		public sitioWeb?: string
	) {
		super(codigo, descripcion, id)
	}

	public get adjunto(): FileData {
		return this.certificado
	}
	public set adjunto(v: FileData) {
		this.certificado = v
	}

	public static fromData(data: any): PuntoDeVenta {
		if (!data) return null
		const o: PuntoDeVenta = new PuntoDeVenta(
			data.id,
			data.codigo,
			data.descripcion,
			data.firmanteCertificado,
			data.passwordCertificado,
			FileData.fromData(data.certificado),
			data.numeroPuntoVenta,
			ProfilePic.fromData(data.profilePic),
			Descriptivo.fromData(data.centro),
			data.razonSocial,
			data.cuit,
			Descriptivo.fromData(data.tipoIngresoBruto),
			data.iibb,
			data.domicilioFiscal,
			data.codigoPostal,
			data.fechaInicioActividades ? new Date(data.fechaInicioActividades) : null,
			Descriptivo.fromData(data.tipoIva),
			Descriptivo.fromData(data.tipoDocumento),
			data.maximoFacturacion,
			data.telefono,
			data.emailContacto,
			data.whatsapp,
			data.sitioWeb
		)

		o.version = data.version
		return o
	}
	public get email() {
		return this.emailContacto
	}
}
