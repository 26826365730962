import { Injectable } from "@angular/core"
import { FinancieroAG } from "./FinancieroAG"

@Injectable({
	providedIn: "root"
})
export class FacturasAG extends FinancieroAG {
	public getRoles(): string[] {
		return super.getRoles().concat(["ROLE_FACTURAS", "ROLE_MIS_FACTURAS_PROVEEDOR"])
	}
}
