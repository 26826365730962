<div class="sin-cajas" *ngIf="!cajas?.length && !loading">
	<span>No se encontratron cajas abiertas.</span>
</div>
<p-dataView #dv [value]="item?.valores" *ngIf="cajas?.length" layout="list" emptyMessage=" ">
	<ng-template pTemplate="header">
		<div class="botones" *ngIf="!readonly">
			<div class="boton-pago card" *ngFor="let f of formasPago" (click)="agregarCobro(f)" [ngClass]="{ disabled: readonly }">
				<profile-badge [url]="f.imagenUrl" class="small-badge desktop-badge" [disabled]="true"> </profile-badge>
				<span>{{ f.descripcion }}</span>
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<div class="grid grid-nogutter" *ngIf="mostrarTotales">
			<div class="col-4 p-fluid">
				<span-dinero-editable [ngModel]="totalAPagarLocal" [editable]="false" [moneda]="monedaEntorno" [label]="'TOTAL_A_PAGAR' | translate">
				</span-dinero-editable>

				<small *ngIf="monedaEntorno?.codigo !== item?.moneda?.codigo" class="ml-2">
					{{ item.total | currencyCustom : item.moneda }}
				</small>
			</div>
			<div class="col-4 p-fluid">
				<span-dinero-editable [ngModel]="total" [editable]="false" [moneda]="monedaEntorno" [label]="'TOTAL_COBROS' | translate">
				</span-dinero-editable>
			</div>
			<div class="col-4 p-fluid">
				<span-dinero-editable [ngModel]="totalAPagarLocal - total" [editable]="false" [moneda]="monedaEntorno" [label]="'DIFERENCIA' | translate">
				</span-dinero-editable>
			</div>
		</div>
	</ng-template>
	<ng-template let-cobro pTemplate="listItem" let-i="rowIndex">
		<div class="col-12 dropzone">
			<div class="grid grid-nogutter align-items-center justify-content-center">
				<div class="col">
					<div class="grid grid-nogutter align-items-center">
						<div class="col-fixed">
							<div class="grid grid-nogutter align-items-center" style="flex-wrap: nowrap" *ngIf="cobro.tipoCobro != 'D'">
								<div class="col-fixed text-center" style="width: 3em">
									<profile-badge
										[url]="cobro?.formaPago?.imagenUrl"
										class="small-badge desktop-badge"
										tooltipPosition="bottom"
										pTooltip="{{ cobro?.formaPago?.descripcion }}"
										[disabled]="true"
									>
									</profile-badge>
								</div>
								<div class="col" style="max-width: 13em" *ngIf="cobro.tipoCobro != 'N'">
									<div class="p-inputgroup">
										<descriptivo-material-selector
											[options]="cajas"
											[label]="'CAJA' | translate"
											[limpiable]="false"
											[(ngModel)]="cobro.caja"
											[permiteNuevo]="false"
											name="{{ 'caja' + i }}"
											[required]="true"
											[readonly]="readonly || cobro.tipoCobro == 'S'"
											[itemTemplate]="itemTemplate"
											(onSelect)="onSelectCaja($event, cobro)"
										>
										</descriptivo-material-selector>
									</div>
								</div>

								<ng-container *ngIf="cobro.caja && cobro?.caja?.moneda?.codigo != monedaEntorno?.codigo">
									<div class="col-fixed w-12rem pl-2 p-fluid">
										<span-dinero-editable
											[(ngModel)]="cobro.cotizacion"
											[editable]="!readonly"
											label="{{
												!cobro?.caja?.moneda
													? ('COTIZACION_A_LOCAL' | translate)
													: cobro?.caja?.moneda?.codigo + ' ► ' + monedaEntorno?.codigo
											}}"
											name="{{ 'cotizacion' + i }}"
											[minValue]="0"
											[decimals]="8"
											[moneda]="monedaRef || monedaEntorno"
											(onChange)="onChangeCotizacion($event, cobro)"
										>
										</span-dinero-editable>
									</div>
								</ng-container>
							</div>
						</div>
						<div
							class="col"
							[ngSwitch]="cobro.tipoCobro"
							*ngIf="cobro?.caja || cobro?.tipoCobro == 'N' || cobro?.tipoCobro == 'S' || cobro?.tipoCobro == 'D'"
						>
							<div class="grid grid-nogutter align-items-center">
								<div class="p-2 col p-fluid">
									<ng-container *ngSwitchCase="'A'">
										<ng-container *ngTemplateOutlet="default; context: { $implicit: cobro, i: i }"> </ng-container>
									</ng-container>
									<ng-container *ngSwitchCase="'D'">
										<ng-container *ngTemplateOutlet="diferenciaCambio; context: { $implicit: cobro, i: i }"> </ng-container>
									</ng-container>
									<ng-container *ngSwitchCase="'E'">
										<ng-container *ngTemplateOutlet="efectivo; context: { $implicit: cobro, i: i }"> </ng-container>
									</ng-container>
									<ng-container *ngSwitchCase="'M'">
										<ng-container *ngTemplateOutlet="mercadoPago; context: { $implicit: cobro, i: i }"> </ng-container>
									</ng-container>
									<ng-container *ngSwitchCase="'T'">
										<ng-container *ngTemplateOutlet="tarjetaCredito; context: { $implicit: cobro, i: i }"> </ng-container>
									</ng-container>
									<ng-container *ngSwitchCase="'N'">
										<ng-container *ngTemplateOutlet="notaDeCredito; context: { $implicit: cobro, i: i }"> </ng-container>
									</ng-container>
									<ng-container *ngSwitchCase="'S'">
										<ng-container *ngTemplateOutlet="compensacionSaldo; context: { $implicit: cobro, i: i }"> </ng-container>
									</ng-container>
									<div class="grid" *ngSwitchDefault>
										<div class="col-12" *ngSwitchDefault>
											<span>SIN COMPONENTE</span>
										</div>
									</div>
								</div>
								<div class="col col-fixed" style="width: 3em" *ngIf="!cobro?.adjunto && !readonly">
									<button pButton icon="fa fa-paperclip" (click)="adjuntar(cobro, $event)"></button>
								</div>
								<div class="col-12 text-right" *ngIf="cobro?.adjunto">
									<div class="grid justify-content-center">
										<div class="col col-fixed file card">
											<span class="clickable" (click)="verAdjunto(cobro?.adjunto, $event)"
												>{{ cobro?.adjunto?.nombreOriginal
												}}<i
													class="fa fa-times delete"
													*ngIf="!readonly"
													(click)="quitarAdjunto(cobro, $event); actualizarValores()"
												></i
											></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-fixed text-center" style="width: 6em">
					<button pButton class="accion" (click)="quitarCobro(item, cobro)" icon="fa fa-trash" *ngIf="!readonly"></button>
					<p-toggleButton
						*ngIf="!cobro?.id && admiteGastos && !readonly"
						[(ngModel)]="cobro.incluyeGastos"
						[onIcon]="'pi pi-money-bill'"
						[offIcon]="'pi pi-money-bill'"
						[pTooltip]="'VER_GASTOS_ASOCIADOS' | translate"
						(onChange)="updateIncluyeGatos($event, cobro)"
					></p-toggleButton>
				</div>
				<div class="col-12" *ngIf="cobro.incluyeGastos">
					<div class="grid align-items-center">
						<div class="col-12 lg:col-3 text-right">
							<span style="height: 2rem; display: block; width: 100%">{{ "RECARGO" | translate }}</span>
						</div>
						<div class="col-6 lg:col-2">
							<p-selectButton
								[style]="{ 'justify-content': 'center', 'text-align': 'center' }"
								[options]="opcionesEdicion"
								name="modoEdicion"
								[(ngModel)]="cobro.tipoGasto"
								optionValue="value"
							>
								<ng-template let-item>
									<span style="width: 100%" class="block text-center">
										<i class=" {{ item.icon }}" pTooltip="{{ item.tooltip | translate }}"></i>
									</span>
								</ng-template>
							</p-selectButton>
						</div>
						<div class="col-6 lg:col" *ngIf="cobro?.tipoGasto">
							<div class="grid">
								<div class="col-fixed w-10rem p-fluid text-center p-inputgroup" *ngIf="cobro.tipoGasto == 'P'">
									<div class="p-inputgroup">
										<span class="p-float-label">
											<input
												pInputText
												placeholder="{{ 'PORCENTAJE' | translate }}"
												[ngModel]="cobro.porcentajeGasto"
												(ngModelChange)="updateImporte($event, cobro)"
												(focusin)="onFocus($event)"
												type="number"
												class="sin-botones"
												#porcentajeGasto
												name="{{ 'porcentajeGasto' + i }}"
												[disabled]="readonly"
												style="border-right: none"
											/>

											<label for="">{{ "PORCENTAJE" | translate }}</label>
										</span>
										<error-tag [model]="porcentajeGasto"></error-tag>
										<span class="p-inputgroup-addon" style="border-left: none"><i class="fa fa-percent"></i></span>
									</div>
								</div>
								<div class="col p-fluid text-right">
									<span-dinero-editable
										[(ngModel)]="cobro.importeGasto"
										[editable]="!readonly && cobro.tipoGasto == 'V'"
										[label]="'IMPORTE_RECARGO' | translate"
										name="{{ 'importeGasto' + i }}"
										[moneda]="cobro.moneda"
									>
									</span-dinero-editable>
								</div>
								<div class="col p-fluid text-right">
									<span-dinero-editable
										[ngModel]="cobro.monto"
										[editable]="false"
										[label]="(cobro.id ? 'Pagado' : 'A_PAGAR') | translate"
										name="{{ 'importeAPagar' + i }}"
										[moneda]="cobro.moneda"
									>
									</span-dinero-editable>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</p-dataView>
<input type="file" (change)="handleFileInput($event.target.files)" #inputFile name="inputfile" [hidden]="true" accept=".pdf,.doc,.docx,image/*" />
<ng-template #default let-cobro let-i>
	<div class="grid align-items-center m-0 p-fluid">
		<div class="col-12">
			<span-dinero-editable
				[(ngModel)]="cobro.montoBase"
				[editable]="!readonly"
				[label]="(cobro?.incluyeGastos ? 'MONTO_BASE' : cobro.id ? 'Pagado' : 'A_PAGAR') | translate"
				name="{{ 'monto_Def' + i }}"
				[minValue]="0"
				[moneda]="cobro.moneda"
			>
			</span-dinero-editable>
		</div>
	</div>
</ng-template>

<ng-template #diferenciaCambio let-cobro let-i>
	<div class="grid align-items-center m-0 p-fluid">
		<div class="col-12 lg:col-4">
			<span-dinero-editable
				[(ngModel)]="cobro.montoBase"
				[editable]="!readonly"
				[label]="'IMPORTE' | translate"
				name="{{ 'monto_Def' + i }}"
				[minValue]="0"
				[moneda]="cobro.moneda"
			>
			</span-dinero-editable>
		</div>
		<div class="col-12 lg:col-4">
			<span-dinero-editable
				[(ngModel)]="cobro.cotizacion"
				[editable]="!readonly"
				[label]="'DIFERENCIA_CAMBIO' | translate"
				name="{{ 'monto_Def' + i }}"
				[minValue]="0"
				[moneda]="cobro.moneda"
			>
			</span-dinero-editable>
		</div>
		<div class="col-12 lg:col-4">
			<span-dinero-editable
				[(ngModel)]="cobro.montoALocal"
				[editable]="!readonly"
				[label]="'TOTAL' | translate"
				name="{{ 'monto_Def' + i }}"
				[minValue]="0"
				[moneda]="monedaEntorno"
			>
			</span-dinero-editable>
		</div>
	</div>
</ng-template>

<ng-template #notaDeCredito let-cobro let-i>
	<div class="grid align-items-center m-0 p-fluid" (click)="readonly && verNota($event, cobro.notaGenerada)">
		<div class="col-4 lg:col-2" *ngIf="verMoneda">
			<parametrico-selector
				[service]="monedaService"
				#moneda="ngModel"
				[(ngModel)]="cobro.moneda"
				[readonly]="readonly"
				[limpiable]="false"
				name="moneda"
				[titulo]="'MONEDA' | translate"
				[required]="true"
			>
			</parametrico-selector>
		</div>
		<div class="col-6 lg:col-4">
			<p-checkbox
				*ngIf="!cobro?.notaGenerada"
				[binary]="true"
				[(ngModel)]="cobro.esInterna"
				[label]="'ES_INTERNA' | translate"
				name="esIntera"
				tooltipPosition="bottom"
				pTooltip="{{ 'ES_INTERNA_TOOLTIP' | translate }}"
				[disabled]="readonly"
			>
			</p-checkbox>
			<span class="p-float-label" *ngIf="cobro?.notaGenerada">
				<input
					pInputText
					placeholder="{{ 'Nota generada' | translate }}"
					[(ngModel)]="cobro.notaGenerada"
					[readonly]="true"
					tooltipPosition="bottom"
					type="text"
					name="{{ 'notaGenerada' + i }}"
				/>
				<label>{{ "Nota generada" | translate }}</label>
			</span>
		</div>
		<div class="{{ verMoneda ? 'lg:col-6 col-6' : 'lg:col-8 col-6' }}">
			<span-dinero-editable
				[(ngModel)]="cobro.montoBase"
				[editable]="!readonly"
				[label]="(cobro?.incluyeGastos ? 'MONTO_BASE' : cobro.id ? 'Pagado' : 'A_PAGAR') | translate"
				name="{{ 'monto_nota' + i }}"
				[minValue]="0"
				[moneda]="cobro.moneda"
			>
			</span-dinero-editable>
		</div>
	</div>
</ng-template>

<ng-template #mercadoPago let-cobro let-i>
	<div class="grid align-items-center m-0 p-fluid">
		<div class="col-4 lg:col-2" *ngIf="verMoneda">
			<parametrico-selector
				[service]="monedaService"
				#moneda="ngModel"
				[(ngModel)]="cobro.moneda"
				[readonly]="readonly"
				[limpiable]="false"
				name="moneda"
				[titulo]="'MONEDA' | translate"
				[required]="true"
			>
			</parametrico-selector>
		</div>
		<div class="{{ verMoneda ? 'lg:col-6 col-4' : 'lg:col-8 col-8' }}">
			<span-dinero-editable
				[(ngModel)]="cobro.montoBase"
				[editable]="!readonly"
				[label]="(cobro?.incluyeGastos ? 'MONTO_BASE' : cobro.id ? 'Pagado' : 'A_PAGAR') | translate"
				name="{{ 'monto_mp' + i }}"
				[minValue]="0"
				[moneda]="cobro.moneda"
			>
			</span-dinero-editable>
		</div>

		<div class="col-4 lg:col-4 p-fluid">
			<span class="p-float-label">
				<input
					pInputText
					placeholder="{{ 'NUMERO_DE_TRANSACCI_1' | translate }}"
					[(ngModel)]="cobro.idPago"
					[readonly]="readonly"
					tooltipPosition="bottom"
					pTooltip="{{ 'NUMERO_DE_TRANSACCI_1' | translate }}"
					type="number"
					name="{{ 'numeroTransaccion' + i }}"
				/>
				<label>{{ "NUMERO_DE_TRANSACCI_1" | translate }}</label>
			</span>
		</div>
	</div>
</ng-template>
<ng-template #efectivo let-cobro let-i>
	<div class="grid align-items-center m-0">
		<div class="lg:col-12 col-12 p-fluid">
			<div class="grid align-items-center m-0 p-fluid">
				<div class="col">
					<span-dinero-editable
						[(ngModel)]="cobro.montoBase"
						[editable]="!readonly"
						[label]="(cobro?.incluyeGastos ? 'MONTO_BASE' : cobro.id ? 'Pagado' : 'A_PAGAR') | translate"
						name="{{ 'monto_e' + i }}"
						[minValue]="0"
						[moneda]="cobro.moneda"
					>
					</span-dinero-editable>
				</div>
				<div class="col-4" *ngIf="!cobro?.id && !readonly">
					<span-dinero-editable
						[(ngModel)]="cobro.pagaCon"
						[editable]="!readonly && cobro.monto > 0"
						[label]="'Paga con ' | translate"
						name="{{ 'pagacon' + i }}"
						[minValue]="cobro.total"
						[moneda]="cobro.moneda"
					>
					</span-dinero-editable>
				</div>
				<div class="col-4" *ngIf="!cobro?.id && !readonly">
					<span-dinero-editable [importeMuestra]="cobro.pagaCon - cobro.monto" [editable]="false" [label]="'Vuelto' | translate">
					</span-dinero-editable>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #compensacionSaldo let-cobro let-i>
	<div class="grid align-items-center m-0">
		<div class="lg:col-12 col-12 p-fluid">
			<div class="grid align-items-center m-0 p-fluid">
				<div class="col">
					<span-dinero-editable
						[(ngModel)]="cobro.montoBase"
						[editable]="!readonly"
						[label]="(cobro?.incluyeGastos ? 'MONTO_BASE' : cobro.id ? 'Pagado' : 'A_PAGAR') | translate"
						name="{{ 'monto_e' + i }}"
						[minValue]="0"
						[moneda]="cobro.moneda"
					>
					</span-dinero-editable>
				</div>
				<div class="col-6">
					<div class="flex flex-column justify-content-center">
						<span
							class="p-float-label"
							[ngClass]="{ 'mb-3': cobro.refOrdenPago }"
							*ngIf="cobro['listadoComprobantes']?.documentosArr?.length && (clienteAsociado || readonly)"
						>
							<p-chips [ngModel]="cobro['listadoComprobantes'].documentosArr" [disabled]="true"> </p-chips>
							<label for="referencia">Documentos</label>
						</span>

						<span
							class="p-float-label"
							[ngClass]="{ 'mb-3': cobro.refCobranza }"
							*ngIf="cobro['listadoFacturas']?.documentosArr?.length && (proveedorAsociado || readonly)"
						>
							<p-chips [ngModel]="cobro['listadoFacturas'].documentosArr" [disabled]="true"> </p-chips>
							<label for="referencia">Documentos</label>
						</span>

						<div *ngIf="cobro.refOrdenPago" class="flex align-items-center">
							<span class="p-float-label mr-1">
								<input type="text" id="referencia" pInputText [(ngModel)]="cobro.refOrdenPago" disabled />
								<label for="referencia">{{ "ORDEN-PAGO" | translate }}</label>
							</span>
							<button
								pButton
								class="p-button-icon-only"
								icon="pi pi-external-link"
								pTooltip="{{ 'VER_OP' | translate }}"
								(click)="goToOp(cobro.refOrdenPago)"
							></button>
						</div>
						<div *ngIf="cobro.refCobranza" class="flex align-items-center">
							<span class="p-float-label mr-1">
								<input type="text" id="referencia" pInputText [(ngModel)]="cobro.refCobranza" disabled />
								<label for="referencia">{{ "COBRANZA" | translate }}</label>
							</span>
							<button
								pButton
								class="p-button-icon-only"
								icon="pi pi-external-link"
								pTooltip="{{ 'VER_COBRANZA' | translate }}"
								(click)="goToCobranza(cobro.refCobranza)"
							></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #tarjetaCredito let-cobro let-i>
	<div class="grid align-items-center m-0 p-fluid">
		<div class="col-6 lg:col-6">
			<parametrico-selector
				[service]="tipoTarjetaCreditoService"
				#tarjeta="ngModel"
				[readonly]="readonly"
				[(ngModel)]="cobro.tipoTarjeta"
				[limpiable]="false"
				name="tarjeta"
				[titulo]="'TARJETA' | translate"
				[required]="true"
			>
			</parametrico-selector>
		</div>

		<div class="col-3 lg:col-3">
			<parametrico-selector
				[service]="tipoCuotasTarjetaService"
				#cuotas="ngModel"
				[readonly]="readonly"
				[(ngModel)]="cobro.cantidadCuotas"
				[limpiable]="false"
				name="cuotas"
				[titulo]="'CUOTAS' | translate"
				[required]="true"
			>
			</parametrico-selector>
		</div>

		<div class="col-3 lg:col-3">
			<span class="p-float-label">
				<input
					pInputText
					placeholder="{{ 'NUMERO_TARJETA' | translate }}"
					[(ngModel)]="cobro.numeroTarjeta"
					[readonly]="readonly"
					tooltipPosition="bottom"
					pTooltip="{{ 'NUMERO_TARJETA' | translate }}"
					type="text"
					maxLength="4"
					minLength="4"
					(keypress)="numberOnly($event)"
				/>
				<label>{{ "NUMERO_TARJETA" | translate }}</label>
			</span>
		</div>

		<div class="col-6 lg:col-6">
			<span class="p-float-label">
				<input
					pInputText
					placeholder="{{ 'NUMERO_DE_TRANSACCI_1' | translate }}"
					[(ngModel)]="cobro.idPago"
					[readonly]="readonly"
					tooltipPosition="bottom"
					pTooltip="{{ 'NUMERO_DE_TRANSACCI_1' | translate }}"
					type="number"
					name="{{ 'numeroTransaccion' + i }}"
				/>
				<label>{{ "NUMERO_DE_TRANSACCI_1" | translate }}</label>
			</span>
		</div>

		<div class="lg:col-6 col-6">
			<span-dinero-editable
				[(ngModel)]="cobro.montoBase"
				[editable]="!readonly"
				[label]="(cobro?.incluyeGastos ? 'MONTO_BASE' : cobro.id ? 'Pagado' : 'A_PAGAR') | translate"
				name="{{ 'monto_t' + i }}"
				[minValue]="0"
				[moneda]="cobro.moneda"
			>
			</span-dinero-editable>
		</div>
	</div>
</ng-template>

<p-dialog
	[(visible)]="mostrarCompensacionSaldo"
	[modal]="true"
	header="{{ 'COMPENSACION' | translate }}"
	[styleClass]="'gestor-dialog'"
	(onHide)="mostrarCompensacionSaldo = false"
	appendTo="body"
	[style]="{ width: '80vw' }"
>
	<p-table
		[value]="clienteAsociado ? comprobantes : facturas"
		[(selection)]="itemsCompensacion"
		dataKey="id"
		[tableStyle]="{ 'min-width': '50rem' }"
		[scrollable]="true"
		scrollHeight="650px"
		selectionMode="multiple"
		[paginator]="true"
		[rows]="10"
		appendTo="body"
		(onRowSelect)="onRowCompensacionSelect($event)"
		(onRowUnselect)="onRowCompensacionUnselect($event)"
	>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td [attr.colspan]="7" class="emptymessage text-center">
					<h3 class="title m-5">
						<b>{{ "NO_SE_ENCONTRARON_COMPROBANTES" | translate }}</b>
					</h3>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="caption"> </ng-template>
		<ng-template pTemplate="header">
			<tr>
				<th colspan="4"><span class="text-right">Imputar pago</span></th>
				<th colspan="4">
					<div class="flex">
						<div class="imputacion grid align-content-center">
							<div class="col-fixed" style="width: 12em">
								<span-dinero-editable
									[label]="'TOTAL_A_PAGAR' | translate"
									[editable]="false"
									[readonly]="true"
									[ngModel]="totalAPagarLocal - totalSeleccionado"
								>
								</span-dinero-editable>
							</div>
							<div class="col-fixed" style="width: 12em">
								<span-dinero-editable [label]="'IMPORTE_A_IMPUTAR' | translate" [(ngModel)]="importeImputar" name="importeImputar">
								</span-dinero-editable>
							</div>

							<div class="col-fixed" style="width: 6em">
								<button
									pButton
									label="{{ 'IMPUTAR' | translate }}"
									(click)="imputar()"
									[disabled]="!importeImputar || totalSeleccionado > totalAPagarLocal || importeImputar > totalAPagarLocal"
								></button>
							</div>
							<div class="col-fixed" style="width: 6em">
								<p-button
									[disabled]="!itemsCompensacion?.length || totalSeleccionado > totalAPagarLocal || importeImputar > totalAPagarLocal"
									(click)="agregarCobroCompensacion()"
									>{{ "AGREGAR" | translate }}</p-button
								>
							</div>
						</div>
					</div>
					<small *ngIf="totalSeleccionado > totalAPagarLocal || importeImputar > totalAPagarLocal" class="p-error block">{{
						"ERROR_IMPORTE_IMPUTAR" | translate
					}}</small>
				</th>
			</tr>
			<tr>
				<th style="width: 4rem">
					<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
				</th>
				<th>{{ "DOCUMENTO" | translate }}</th>
				<th>{{ "FECHA" | translate }}</th>
				<th>{{ "DESCRIPCION" | translate }}</th>
				<th>{{ "IMPORTE" | translate }}</th>
				<th>{{ "SALDO" | translate }}</th>
				<th>{{ "IMPUTAR" | translate }}</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-item>
			<tr [pSelectableRow]="item">
				<td>
					<p-tableCheckbox [value]="item"></p-tableCheckbox>
				</td>
				<td>{{ item.tipoComprobante.codigo }} {{ item.numeroComprobante }}</td>
				<td>{{ item.fecha | date : "dd/MM/yyyy" }}</td>
				<td>{{ item.descripcion }}</td>
				<td>
					{{ item.importeTransformado | currencyCustom : monedaDefault }}
					<span *ngIf="item?.moneda?.codigo != monedaDefault?.codigo">
						<small class="block"
							>{{ item.importe | currencyCustom : item?.moneda }} x
							{{ item?.cotizacionSafe || item?.cotizacion | currencyCustom : monedaDefault : "1.1-6" }}</small
						>
					</span>
				</td>
				<td>
					{{ item.saldoTransformado | currencyCustom : monedaDefault }}
					<span *ngIf="item?.moneda?.codigo != monedaDefault?.codigo">
						<small class="block"
							>{{ item.saldo | currencyCustom : item?.moneda }} x {{ item?.cotizacionSafe | currencyCustom : monedaDefault : "1.1-6" }}</small
						>
					</span>
				</td>
				<td class="text-right w-10rem">
					<span-dinero-editable [required]="true" [(ngModel)]="item.importe" name="importe_{{ i }}" [moneda]="monedaDefault"> </span-dinero-editable>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-dialog>
