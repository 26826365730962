<div class="listado-tipo-notificacion content">
	<model-list
		[service]="service"
		modelName="tipo-notificacion"
		[columns]="columns"
		[title]="'TipoNotificacion_PLR' | translate"
		[(listado)]="listado"
		[isLazy]="true"
		[filtro]="filtro"
		[multilenguaje]="false"
		[modoSeleccion]="modoSeleccion"
		[hideHeader]="ocultarCabecera"
		[editable]="editable"
		(seleccionChange)="updateSeleccion($event)"
		[readonly]="readonly"
		[helpPath]="'configuracion/usuario'"
	>
	</model-list>
</div>
