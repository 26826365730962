<model-help [title]="'Gestión de usuarios'">
    <p>
        La pantalla de gestor de usuarios permite agregar un nuevo usuario o modificar los datos y permisos de un usuario existente, también permite accionar el proceso de recupero o cambio de
        contraseña.
    </p>
    <h3>Campos requeridos</h3>
    <p>Los campos requeridos están marcados por un * y son “nickname” (que es el nombre único por el cual el usuario será conocido en todo el sistema ) y “email”.</p>
    <p>El formulario se completa con datos no obligatorios sobre el usuario.</p>
    <h3>Generación de nuevo usuario o edición de usuario existente</h3>
    <p>
        Para generar un nuevo usuario debe volver al Listado de usuarios y hacer click en el ícono
        <i class="pi pi-plus"></i>
    </p>
    <p>
        Si está en modo de visualización, se puede editar el usuario desde el ícono de lápiz en la parte superior de la pantalla.
        <i class="fa fa-pencil"></i>
    </p>

    <h3>Imagen de Usuario</h3>
    <p>La foto o imagen deberá tener como tamaño ideal NN píxeles de ancho por NN de alto y una resolución no menor a NN dpi. Haga un click sobre la imagen para cambiarla.</p>
    <h3>Cambio o Recupero de Contraseña</h3>
    <p>Al clickear en el botón de recupero de contraseña, se enviara un correo electrónico al mail registrado para continuar con el proceso.</p>
    <img src="assets/images/help/recupero-contra.png" />
    <h3>Roles de usuarios</h3>
    <p>Los roles de usuario se corresponden en lineas generales con las entradas del menu lateral izquierdo. A continuación algunas excepciones</p>
    <ul>
        <li>Superusuario: Acceso máximo al sistema, todas las funciones disponibles.</li>
        <li>Jefe de administración: Accede a "Finanzas","Ventas" y ademas puede gestionar centros de costos y puntos de venta</li>
    </ul>
</model-help>