import { Injectable } from "@angular/core";
import { Usuario } from "../common/model/Usuario";
import { AdminAuthGuard } from "./AdminAuthGuard";

@Injectable()
export class VisualizadorAuthGuard extends AdminAuthGuard {
	public esVisible(user: Usuario): boolean {
		return user != undefined;
	}
}
