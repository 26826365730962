import * as moment from "moment"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { MonedaEntorno } from "../app.module"
import { Descriptivo } from "../common/model/Descriptivo"
import { Auditable } from "./Auditable"
import { Moneda } from "./Moneda"

export class FacturaResumen extends Auditable {
	public proveedorStr: string
	public searchStr
	public monedaStr: string
	public importeTransformado: number = 0
	constructor(
		public id?: number,
		public puntoVenta?: string,
		public numero?: string,
		public descripcion?: string,
		public fecha?: Date,
		public fechaVencimiento?: Date,
		public tipoComprobante?: Descriptivo,
		public proveedor?: Descriptivo,
		public saldo?: number,
		public importe?: number,
		public estado?: EstadoDocumento,
		public esACuenta?: boolean,
		public centroCosto?: Descriptivo,
		public cuitProveedor?: string,
		public impuestos: number = 0,
		public otrosImpuestos: number = 0,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
		public version?: number,
		public urlAdjunto?: string,
		public moneda?: Moneda,
		public cotizacion: number = 1,
		public esExterna: boolean = false,
		public tienePendientesHomologacion: boolean = false
	) {
		super(lastModifiedBy, lastModifiedDate)
		this.proveedorStr = proveedor ? proveedor.descripcion : ""
		this.searchStr = [this.id, this.descripcion, this.numero, this.tipoNumero, this.proveedorStr, this.tipoComprobante?.descripcion].join("_")
		this.monedaStr = this.moneda?.codigo || MonedaEntorno()?.codigo || "ARS"
	}
	public get fechaStr() {
		return moment(this.fecha).format("DD/MM/YYYY")
	}
	public get importeBruto() {
		return this.importe - this.impuestos
	}
	public static fromData(data: any): FacturaResumen {
		if (!data) return null
		const o: FacturaResumen = new FacturaResumen(
			data.id,

			data.puntoVenta,
			data.numero,
			data.descripcion,
			data.fecha ? new Date(data.fecha) : null,
			data.fechaVencimiento ? new Date(data.fechaVencimiento) : null,
			Descriptivo.fromData(data.tipoComprobante),
			Descriptivo.fromData(data.proveedor),
			data.saldo,
			data.importe,
			EstadoDocumento.fromData(data.estado),
			data.esACuenta,
			Descriptivo.fromData(data.centroCosto),
			data.cuitProveedor,
			data.impuestos,
			data.otrosImpuestos,
			data.lastModifiedBy,
			data.lastModifiedDate,
			data.version,
			data.urlAdjunto,
			Moneda.fromData(data.moneda),
			data.cotizacion || 1,
			data.esExterna,
			data.tienePendientesHomologacion
		)
		o.importeTransformado = o.cotizacion * o.importe
		return o
	}

	get tipoDocumentoProveedorStr() {
		return "CUIT" //this.proveedor?.tipoDocumento?.descripcion
	}
	get saldoTransformado() {
		return this.saldo * this.cotizacion
	}
	get tipoComprobanteCodigo() {
		return this.tipoComprobante ? this.tipoComprobante.codigo : null
	}
	get nombreProveedor() {
		return this.proveedor ? this.proveedor.descripcion : ""
	}
	get pagos() {
		return this.importe - this.saldo
	}
	get numeroComprobante() {
		return this.puntoVenta ? this.puntoVenta + "-" + this.numero : this.numero
	}
	get tipoNumero() {
		if (this.tipoComprobante?.codigo == "AR") {
			return "Por Rendir"
		}
		return this.tipoComprobante + " " + (this.puntoVenta ? this.puntoVenta + "-" : "") + (this.numero ? this.numero : "")
	}
	get importeLocal() {
		return this.importe * this.cotizacion
	}
	get impuestosLocal() {
		return this.impuestos * this.cotizacion
	}
	get otrosImpuestosLocal() {
		return this.otrosImpuestos * this.cotizacion
	}
	get importeBrutoLocal() {
		return this.importeBruto * this.cotizacion
	}
	get monedaLocalStr() {
		return MonedaEntorno()?.codigo || "ARS"
	}
}
