import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { BilletesCaja } from './BilletesCaja';
import { Caja } from './Caja';



export class ArqueoCaja {
	constructor(
		public id?: number,
		public fecha: Date = new Date(),
		public descripcion?: string,
		public responsable?: Descriptivo,
		public caja?: Caja,
		public monto?: number,
		public diferencia: number = 0,
		public justificacion?: string,
		public valores: BilletesCaja[] = [],

	) {
	}
	public static fromData(data: any): ArqueoCaja {
		if (!data) return null;
		const o: ArqueoCaja = new ArqueoCaja(
			data.id,
			data.fecha ? new Date(data.fecha) : null,
			data.descripcion,
			Descriptivo.fromData(data.responsable),
			Caja.fromData(data.caja),
			data.monto,
			data.diferencia,
			data.justificacion,
			data.valores ? data.valores.map(c => BilletesCaja.fromData(c)) : [],

		);
		return o;

	}

}

