import { round } from "../common/utils/MathUtils"
import { SubcategoriaImponible } from "./SubcategoriaImponible"
import { TipoIVAGrabado } from "./TipoIVAGrabado"
export class SubcategoriaImponibleItem {
	clonar(): any {
		const s = SubcategoriaImponibleItem.fromData(this)
		s.id = null
		return s
	}
	getImporte(importe: number) {
		return round(importe * (this.proporcion / 100), 2)
	}
	constructor(
		public id?: number,
		public version?: number,
		public subcategoriaImponible?: SubcategoriaImponible,
		public proporcion?: number,
		public tipoIVA?: TipoIVAGrabado
	) {}
	public static fromData(data: any): SubcategoriaImponibleItem {
		if (!data) return null
		const o: SubcategoriaImponibleItem = new SubcategoriaImponibleItem(
			data.id,
			data.version,
			SubcategoriaImponible.fromData(data.subcategoriaImponible),
			data.proporcion,
			TipoIVAGrabado.fromData(data.tipoIVA)
		)

		return o
	}

	get multiplicadorIVA() {
		return (this.tipoIVA?.valorIva || 0) / 100
	}
	get multiplicadorProporcion(): number {
		return (this.proporcion || 100) / 100
	}
}
