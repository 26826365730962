import { ProveedorService } from "./../../services/proveedor.service"
import { TipoProveedorService } from "./../../services/tipo-proveedor.service"

import { formatCurrency } from "@angular/common"
import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import ChartDataLabels from "chartjs-plugin-datalabels"
import * as moment from "moment"
import { debounceTime, filter } from "rxjs/operators"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { ExcelDownloader } from "src/app/common/utils/excelDownloader"
import { Moneda } from "src/app/model/Moneda"
import { CategoriaPuestoService } from "src/app/services/categoria-puesto.service"
import { InsumoService } from "src/app/services/insumo.service"
import { MonedaService } from "src/app/services/moneda.service"
import { FiltroRankingCompras } from "../producto/FiltroRankingCompras"
import { COLORS, MonedaEntorno } from "./../../app.module"
import { commonAnimations } from "./../../common-animations"
import { SessionComponent } from "./../../common/components/session-component.component"
import { MessagesService } from "./../../common/services/messages-data-service.service"
import { InsumoRanking } from "./../../model/Insumo"

@Component({
	selector: "ranking-compras",
	templateUrl: "./rankin-compras.component.html",
	styleUrls: ["./rankin-compras.component.less"],
	animations: [commonAnimations]
})
export class RankinComprasComponent extends SessionComponent implements OnInit {
	private _filtro: FiltroRankingCompras
	public plugins = ChartDataLabels
	cols: { field: string; header: string }[]
	agruparOptions: any[] = []

	private _moneda: Moneda = MonedaEntorno() || Moneda.PESOS
	public get moneda(): Moneda {
		return this._moneda
	}
	@Input()
	public set moneda(v: Moneda) {
		this._moneda = v
		this.actualizarRanking()
	}

	public get filtro(): FiltroRankingCompras {
		return this._filtro
	}
	@Input()
	public set filtro(v: FiltroRankingCompras) {
		this._filtro = v
	}
	rowGroupMetadata: any

	public insumos: InsumoRanking[]

	public dataPie: any

	public ranking = []
	public ranking2 = []

	@Input()
	public cliente: Descriptivo

	@Input()
	public showCharts: boolean = true

	@Input()
	public isFilterFixed: boolean = false

	public maxGrafico = 31

	private _mostrar: string = "importe"
	public get mostrar(): string {
		return this._mostrar
	}
	public set mostrar(v: string) {
		this._mostrar = v
		this.actualizarRanking()
	}

	private _agrupar2: Descriptivo
	public get agrupar2(): Descriptivo {
		return this._agrupar2
	}
	public set agrupar2(v: Descriptivo) {
		if (!v) {
			this._agrupar2 = v
			this.actualizarRanking()
		} else {
			if (v.codigo && v.codigo == this.agrupar.codigo) {
				this.error("Seleccionar una categoría distinta de la primera")
			} else {
				this._agrupar2 = v
				this.actualizarRanking()
			}
		}
	}
	formatterPie = (value, ctx) => {
		let percentage = value.toFixed(2) + "%"
		return percentage
	}
	private _agrupar = new Descriptivo("codigo", "Insumo")

	public get agrupar(): Descriptivo {
		return this._agrupar
	}
	public set agrupar(v: Descriptivo) {
		this._agrupar = v
		this.actualizarRanking()
	}

	private _cantidad: number = 10
	public get cantidad(): number {
		return this._cantidad
	}
	public set cantidad(v: number) {
		if (!v) v = 0
		this._cantidad = v
		this.actualizarRanking()
	}

	public dataBars: any
	public optionsPie = {
		responsive: true,

		animation: {
			animateScale: true,
			animateRotate: true
		},
		plugins: {
			legend: {
				display: false
			},
			datalabels: {
				display: "auto",
				formatter: this.formatterPie,
				color: "#fff"
			}
		}
	}
	public optionsBar = {
		scales: {
			x: {
				ticks: {
					display: false
				}
			}
		},
		responsive: true,

		animation: {
			animateScale: true,
			animateRotate: true
		},
		plugins: {
			legend: {
				display: false
			},
			datalabels: {
				display: "auto",
				align: "end",
				offset: "25",
				anchor: "end",
				formatter: (value, ctx) => {
					let percentage =
						this.mostrar == "importe"
							? formatCurrency(
									value,
									this.getUsersLocale("es-ar"),
									this.configuracionEntorno?.moneda?.simbolo || "$",
									this.configuracionEntorno?.moneda?.codigo || "USD"
							  )
							: value
					return percentage
				}
			}
		}
	}
	constructor(
		private insumoService: InsumoService,
		messagesService: MessagesService,
		public proveedorService: ProveedorService,
		private router: Router,
		public excelService: ExcelDownloader,
		public monedaService: MonedaService,
		public tipoProveedorService: TipoProveedorService,
		public categoriasInsumoService: CategoriaPuestoService,
		public route: ActivatedRoute
	) {
		super(messagesService)
	}
	public total: number = 0
	public get colors() {
		return COLORS
	}
	ngOnInit() {
		this.subs.push(
			this.route.queryParams.subscribe((params) => {
				const desde = params["desde"]
				const hasta = params["hasta"]
				this.filtro = this.filtro ? this.filtro : new FiltroRankingCompras("filtro_ranking_compras", 0, 99999999)
				if (desde) {
					this.filtro.fechaDesde = moment(desde, "DDMMYYYY").toDate()
				}
				if (hasta) {
					this.filtro.fechaHasta = moment(hasta, "DDMMYYYY").toDate()
				}
				this.agruparOptions = [
					new Descriptivo("codigo", "Insumo"),
					new Descriptivo("idProveedor", "Proveedor"),
					new Descriptivo("fecha", "Fecha"),
					new Descriptivo("tipoProveedor", "Tipo de Proveedor"),
					new Descriptivo("categoriaInsumo", "Categoria")
				]
				this.filtro.setMultiple({
					fechaDesde: this.filtro.fechaDesde || moment().startOf("month").subtract(1, "month").toDate()
				})

				this.filtro.dataChange
					.pipe(
						filter((f) => f != undefined),
						debounceTime(400)
					)
					.subscribe((f: any) => {
						this.insumoService.getRanking(f).then((r) => {
							this.insumos = r
							this.cols = [
								{ field: "id", header: "Id item factura" },
								{ field: "codigo", header: "Código insumo" },
								{ field: "descripcion", header: "Descripción insumo" },
								{ field: "cantidad", header: "Cantidad" },
								{ field: "precio", header: "Precio unitario" },
								{ field: "fecha", header: "Fecha" },
								{ field: "facturaId", header: "Factura ID" },
								{ field: "idProveedor", header: "Id Proveedor" },
								{ field: "nombreProveedor", header: "Nombre Proveder" },
								{ field: "razonSocial", header: "Razón social" },
								{ field: "tipoProveedor", header: "Tipo de Proveedor" }
							]
							this.insumos.forEach((p) => {
								p.fecha = moment(p.fecha).startOf("day").format("DD/MM/YYYY")
							})
							this.actualizarRanking()
						})
					})
			})
		)
	}

	public goToInsumo(event, id) {
		event.stopPropagation()
		this.router.navigate(["insumo/vista"], { queryParams: { id: id } })
	}

	public goToProveedor(event, id) {
		event.stopPropagation()
		this.router.navigate(["proveedor/vista"], { queryParams: { id: id } })
	}

	public actualizarRanking() {
		var labels = []
		var vals = []
		this.total = 0
		let prod
		if (this.mostrar == "importe") {
			prod = [...this.actualizarListadoPorMoneda(this.insumos, true)]
		} else prod = this.insumos

		if (this.agrupar && !this.agrupar2) {
			this.ranking = this.groupBy(prod, this.agrupar, this.mostrar)

			this.ranking.forEach((p) => {
				labels.push(p.label)
				vals.push(p.value)
				this.total += p.value
			})
			this.dataPie = {
				labels: labels,
				datasets: [
					{
						data: vals.map((v) => Math.round((v * 100) / this.total)),
						backgroundColor: COLORS
					}
				]
			}
			this.dataBars = {
				labels: labels,
				datasets: [
					{
						label: "Compras",
						data: vals,
						backgroundColor: COLORS
					}
				]
			}
		} else {
			this.ranking2 = this.groupBy2(prod, this.agrupar, this.agrupar2, this.mostrar)
			this.updateRowGroupMetaData()
		}
	}
	actualizarListadoPorMoneda(insumos: InsumoRanking[], pesos = false): InsumoRanking[] {
		if (!insumos || !insumos.length) return []

		if (!pesos) {
			insumos = insumos.filter((p) => p.moneda == this.moneda.codigo)
		} else {
		}
		return insumos
	}

	groupBy(list, key, valorAmostrar) {
		const ranking = {}
		list.forEach((item) => {
			if (!ranking[item[key.codigo]]) {
				const r = {
					value: item[valorAmostrar],
					label: this.getDescripcion(item, key.codigo)
				}
				ranking[item[key.codigo]] = r
			} else {
				ranking[item[key.codigo]].value += item[valorAmostrar]
			}
		})
		const keys = Object.keys(ranking)
		let result = []

		keys.forEach((k) => {
			result.push({ key: k, value: ranking[k].value, label: ranking[k].label })
		})

		result.sort(function (a, b) {
			return b.value - a.value
		})

		if (this.cantidad < result.length) {
			result = result.slice(0, this.cantidad)
		}
		return result
	}
	groupBy2(list, key, key2, valorAmostrar) {
		const ranking = {}
		const totales = {}

		list.forEach((item) => {
			let r = {}
			if (!ranking[item[key.codigo]]) {
				r[item[key2.codigo]] = {
					value: item[valorAmostrar],
					label: this.getDescripcion(item, key2.codigo),
					labelHeader: this.getDescripcion(item, key.codigo)
				}
				ranking[item[key.codigo]] = { ...r }
			} else {
				if (ranking[item[key.codigo]][item[key2.codigo]]) {
					ranking[item[key.codigo]][item[key2.codigo]].value += item[valorAmostrar]
				} else {
					r[item[key2.codigo]] = {
						value: item[valorAmostrar],
						label: this.getDescripcion(item, key2.codigo),
						labelHeader: this.getDescripcion(item, key.codigo)
					}
					ranking[item[key.codigo]] = { ...ranking[item[key.codigo]], ...r }
				}
			}
			if (!totales[item[key.codigo]]) {
				totales[item[key.codigo]] = {
					value: item[valorAmostrar]
				}
			} else {
				totales[item[key.codigo]].value += item[valorAmostrar]
			}
		})
		const keys = Object.keys(ranking)
		let result = []

		keys.forEach((k) => {
			const keys2 = Object.keys(ranking[k])
			keys2.forEach((k2) => {
				result.push({
					key: k,
					labelHeader: ranking[k][k2].labelHeader,
					totalHeader: totales[k].value,
					label: ranking[k][k2].label,
					value: ranking[k][k2].value
				})
			})
		})

		result.sort(function (a, b) {
			if (a.totalHeader > b.totalHeader) {
				return -1
			}
			if (b.totalHeader > a.totalHeader) {
				return 1
			}

			if (a.totalHeader == b.totalHeader) {
				if (a.value > b.value) {
					return -1
				}
				if (b.value >= a.value) {
					return 1
				}
			}
		})

		if (this.cantidad < result.length) {
			let countSlice = 0
			let previous
			let index = 0
			result.forEach((r) => {
				if (countSlice <= this.cantidad) {
					index++
					if (previous != r.key) {
						countSlice++
					}
					previous = r.key
				}
			})
			result = result.slice(0, index - 1)
		}

		return result
	}
	exportToExcel() {
		this.excelService.downloadData(this.insumos, "ranking_compras_" + moment().format("YYYYMMDD"), {}, this.cols)
	}
	getDescripcion(item, key) {
		let descripcion
		switch (key) {
			case "codigo":
				return item.descripcion
			case "idProveedor":
				return item?.nombreProveedor || item?.razonSocialProveedor || "NA"
			case "fecha":
				return item.fecha
			case "tipoProveedor":
				return item?.tipoProveedor || "NA"
			case "categoriaInsumo":
				return item?.categoriaInsumo || "NA"
			default:
				break
		}
	}
	goToItem(event, item) {
		let id
		switch (this.agrupar.codigo) {
			case "codigo":
				return this.goToInsumo(event, item.key)

			case "idProveedor":
				return this.goToProveedor(event, item.key)
			default:
				break
		}
	}

	updateRowGroupMetaData() {
		this.rowGroupMetadata = {}
		if (this.ranking2) {
			for (let i = 0; i < this.ranking2.length; i++) {
				let rowData = this.ranking2[i]
				let key = `${rowData.key}-${rowData.totalHeader}`
				if (i == 0) {
					this.rowGroupMetadata[key] = { index: 0, size: 1 }
				} else {
					let previousRowData = this.ranking2[i - 1]
					let previousRowGroup = `${previousRowData.key}-${previousRowData.totalHeader}`
					if (key === previousRowGroup) this.rowGroupMetadata[key].size++
					else this.rowGroupMetadata[key] = { index: i, size: 1 }
				}
			}
		}
	}

	getTotal(key, ranking): number {
		let v = 0

		this.ranking2.filter((r) => r.key == key).forEach((i) => (v += i.value))

		return v
	}
}
