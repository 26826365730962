<div class="ventas content">
	<div class="filter-content">
		<filtro [filterTemplateContent]="filterTemplate" [(filter)]="filtro"> </filtro>
		<ng-template #filterTemplate>
			<div class="grid" #filterContent>
				<div class="col-6">
					<div class="grid">
						<div class="col-12 lg:col-6 p-fluid">
							<span class="p-float-label field p-input-icon-left">
								<i class="pi pi-search"></i>
								<input
									pInputText
									placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}"
									#nombre="ngModel"
									name="nombre"
									[(ngModel)]="filtro.searchStr"
									tooltipPosition="bottom"
									pTooltip="{{ 'BUSQUEDA_GENERAL' | translate }}"
									type="text"
									required
								/>
								<label>
									{{ "BUSCAR" | translate }}
								</label>
							</span>
						</div>
						<div class="col-12 lg:col-3">
							<descriptivo-material-selector
								[label]="'CLIENTE' | translate"
								[limpiable]="true"
								[(ngModel)]="filtro.cliente"
								name="cliente"
								isLazy="true"
								[service]="clienteService"
							>
							</descriptivo-material-selector>
						</div>
						<div class="col-12 lg:col-3" *ngIf="opcionesPunto?.length > 1">
							<descriptivo-material-selector
								[label]="'PUNTO_VENTA' | translate"
								[limpiable]="true"
								[(ngModel)]="filtro.puntoDeVenta"
								isLazy="true"
								name="puntoDeVenta"
								[options]="opcionesPunto"
								[autohide]="true"
							>
							</descriptivo-material-selector>
						</div>
						<div class="col-12">
							<div class="grid">
								<div class="col-12 lg:col-6">
									<span class="p-float-label p-fluid">
										<p-calendar
											[(ngModel)]="filtro.fechaDesde"
											name="fechaDesde"
											dateFormat="dd/mm/yy"
											appendTo="body"
											[showIcon]="true"
										></p-calendar>
										<label for="">{{ "FECHADESDE" | translate }}</label>
									</span>
								</div>
								<div class="col-12 lg:col-6">
									<span class="p-float-label p-fluid">
										<p-calendar
											[(ngModel)]="filtro.fechaHasta"
											name="fechaHasta"
											dateFormat="dd/mm/yy"
											appendTo="body"
											[showIcon]="true"
										></p-calendar>
										<label for="">{{ "HASTA" | translate }}</label>
									</span>
								</div>
							</div>
						</div>
						<div class="col-12" *ngIf="tabActive == 1">
							<selector-multiple-chips
								[(ngModel)]="filtro.categoriasProducto"
								name="cats"
								[servicio]="categoriaProductoService"
								[permiteNuevo]="false"
								[limpiable]="true"
								[titulo]="'CATEGORIA_PRODUCTO' | translate"
								[maxItems]="3"
							>
							</selector-multiple-chips>
						</div>
					</div>
				</div>
				<div class="col-6">
					<selector-multiple-chips
						[(ngModel)]="filtro.tiposComprobante"
						name="tiposcomp"
						[servicio]="tipoComprobanteService"
						[permiteNuevo]="false"
						[limpiable]="true"
						[titulo]="'Tipos de comprobante' | translate"
						[maxItems]="3"
					>
					</selector-multiple-chips>
				</div>

				<div class="col-12 botonera">
					<span>
						<label class="fw-500">{{ "Estados" | translate }}</label>
						<input matInput hidden name="dummy" />
						<div class="grid">
							<div class="col text-center" *ngFor="let estado of estadosService.data | async">
								<button
									pButton
									[label]="estado?.descripcion"
									[ngClass]="{ 'no-seleccionado': !filtro?.tieneEstado(estado) }"
									[ngStyle]="{ 'border-bottom-color': estado?.color, 'background-color': estado?.color }"
									(click)="filtro.toggleEstado(estado)"
								></button>
							</div>
							<div class="col text-center">
								<button
									[ngClass]="{ 'no-seleccionado': !filtro.soloImpagas }"
									pButton
									[label]="'SOLO_IMPAGOS' | translate"
									class="solo-impagas"
									(click)="filtro.soloImpagas = !filtro.soloImpagas"
								></button>
							</div>
						</div>
					</span>
				</div>
			</div>
		</ng-template>
	</div>
	<p-tabView orientation="top" [styleClass]="'ranking-tabs'" (onChange)="onChange($event)">
		<p-tabPanel header="{{ 'COMPROBANTES' | translate }}">
			<div class="tabla">
				<p-table #table [value]="comprobantes" [columns]="columns" [paginator]="true" [rows]="20" [rowsPerPageOptions]="[5, 10, 20, 50, 100]">
					<ng-template pTemplate="caption">
						<div class="p-helper-clearfix" style="text-align: left">
							<button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="XLSX" (click)="exportExcel()"></button>
						</div>
					</ng-template>
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th>{{ "FECHA" | translate }}</th>
							<th colspan="2">{{ "Tipo Comprobante" | translate }}</th>
							<th>{{ "Número" | translate }}</th>
							<th>{{ "Cliente" | translate }}</th>
							<th>{{ "Cuit" | translate }}</th>
							<th>{{ "Imp. Bruto" | translate }}</th>
							<th>{{ "IVA" | translate }}</th>
							<th>{{ "Otros Impuestos" | translate }}</th>
							<th>{{ "Imp. Neto" | translate }}</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-rowData let-columns="columns">
						<tr>
							<td>{{ rowData.fecha | date : "dd/MM/yyyy" }}</td>
							<td class="text-center" style="width: 2em">{{ rowData.tipoComprobante?.codigo }}</td>
							<td style="width: 10em">{{ rowData.tipoComprobante?.descripcion }}</td>
							<td (click)="rowData.url && verPdf($event, rowData.id)">
								<span class="clickable">{{ rowData.numeroDocumento }}</span>
							</td>

							<td>{{ rowData.nombreCliente }}</td>
							<td>{{ rowData.cuitCliente }}</td>
							<td class="text-right">
								<ng-container *ngTemplateOutlet="valorTemplate; context: { $implicit: rowData.importeBruto, rowData: rowData }"> </ng-container>
							</td>
							<td class="text-right">
								<ng-container *ngTemplateOutlet="valorTemplate; context: { $implicit: rowData.impuestos, rowData: rowData }"> </ng-container>
							</td>
							<td class="text-right">
								<ng-container *ngTemplateOutlet="valorTemplate; context: { $implicit: rowData.otrosImpuestos, rowData: rowData }">
								</ng-container>
							</td>
							<td class="text-right">
								<ng-container *ngTemplateOutlet="valorTemplate; context: { $implicit: rowData.importe, rowData: rowData }"> </ng-container>
							</td>
						</tr>
					</ng-template>

					<ng-template pTemplate="footer">
						<tr>
							<td colspan="6" class="text-right">{{ "TOTALES" | translate }}</td>
							<td class="text-right">{{ totales?.bruto | currencyCustom }}</td>
							<td class="text-right">{{ totales?.impuestos | currencyCustom }}</td>
							<td class="text-right">{{ totales?.otros | currencyCustom }}</td>
							<td class="text-right">{{ totales?.total | currencyCustom }}</td>
						</tr>

						<tr *ngFor="let total of totalesMoneda">
							<td colspan="6" class="text-right">{{ total.moneda.descripcion }}</td>
							<td class="text-right">{{ total?.bruto | currency : total.moneda.simbolo + " " }}</td>
							<td class="text-right">{{ total?.impuestos | currency : total.moneda.simbolo + " " }}</td>
							<td class="text-right">{{ total?.otros | currency : total.moneda.simbolo + " " }}</td>
							<td class="text-right">{{ total?.total | currency : total.moneda.simbolo + " " }}</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</p-tabPanel>
		<p-tabPanel header="{{ 'ITEMS' | translate }}">
			<div class="tabla">
				<p-table #table [value]="items" [columns]="columnsItems" [paginator]="true" [rows]="20" [rowsPerPageOptions]="[5, 10, 20, 50, 100]">
					<ng-template pTemplate="caption">
						<div class="p-helper-clearfix" style="text-align: left">
							<button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="XLSX" (click)="exportExcelItems()"></button>
						</div>
					</ng-template>
					<ng-template pTemplate="header" let-columns>
						<th
							*ngFor="let col of columns"
							[ngStyle]="{ width: col.width }"
							class="{{ col.class }}"
							pResizableColumn
							tooltipPosition="bottom"
							pTooltip="{{ col.header }}"
						>
							{{ col.header | translate }}
						</th>
					</ng-template>
					<ng-template pTemplate="body" let-rowData let-columns="columns">
						<tr>
							<td class="text-center">
								<span class="clickable chip comprobante" [ngClass]="{ adelanto: data?.esAdelanto }" (click)="verComprobante(rowData)">
									{{ rowData.numeroComprobante }}
								</span>
							</td>
							<td class="text-center w-5rem">
								{{ rowData.tipoComprobante?.codigo }}
							</td>

							<td class="text-center w-12rem">
								{{ rowData.fecha | date : "dd/MM/yyyy" }}
							</td>
							<td class="text-center w-5rem">
								{{ rowData.moneda?.codigo }}
								<small class="block" *ngIf="rowData.cotizacionAfip > 1">{{ rowData.cotizacionAfip }}</small>
							</td>

							<td class="text-center w-5rem">
								{{ rowData.descuentoStr }}
							</td>
							<td class="text-center w-5rem">
								{{ rowData.ivaStr }}
							</td>
							<td class="text-left w-25rem overflow">
								{{ rowData.servicio?.descripcion }}
							</td>
							<td class="w-16rem text-center">
								<small
									pTooltip="{{ item.descripcion }}"
									class="chip mr-1 w-6rem overflow inline-block"
									*ngFor="let item of rowData.categoriasProducto"
									>{{ item.descripcion }}</small
								>
							</td>
							<td class="text-center w-5rem">
								{{ rowData.cantidad }}
							</td>
							<td class="w-14rem">
								<ng-container *ngTemplateOutlet="valorTemplate; context: { $implicit: rowData.precioUnitario, rowData: rowData }">
								</ng-container>
							</td>

							<td class="w-14rem">
								<ng-container *ngTemplateOutlet="valorTemplate; context: { $implicit: rowData.precioUnitarioBruto, rowData: rowData }">
								</ng-container>
							</td>
							<td class="w-14rem">
								<ng-container *ngTemplateOutlet="valorTemplate; context: { $implicit: rowData.impuestos, rowData: rowData }"> </ng-container>
							</td>

							<td class="w-14rem">
								<ng-container *ngTemplateOutlet="valorTemplate; context: { $implicit: rowData.total, rowData: rowData }"> </ng-container>
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="footer">
						<tr>
							<td colspan="10" class="text-right">{{ "TOTALES" | translate }}</td>
							<td class="text-right">{{ totalesItems?.total | currencyCustom }}</td>
							<td></td>
						</tr>

						<tr *ngFor="let total of totalesMonedaItems">
							<td colspan="10" class="text-right">{{ total.moneda.descripcion }}</td>
							<td class="text-right">{{ total?.total | currency : total.moneda.simbolo + " " }}</td>
							<td></td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</p-tabPanel>
	</p-tabView>
</div>

<ng-template let-value let-rowData="rowData" #valorTemplate>
	<div class="grid grid-nogutter">
		<div class="col-12 text-right">{{ value * (rowData?.cotizacionAfip || 1) | currencyCustom }}</div>
		<div class="col-12 text-right" *ngIf="rowData.moneda?.codigo != configuracionEntorno?.moneda?.codigo && rowData?.cotizacionAfip">
			<span [tooltipPosition]="'bottom'" [pTooltip]="'Importe en ' + rowData.moneda?.descripcion" class="oneline small">{{
				value | currency : rowData.moneda?.simbolo + " "
			}}</span>
		</div>
	</div>
</ng-template>
