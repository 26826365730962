import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { CanalVenta } from "src/app/model/CanalVenta"
import { MessagesService } from "../common/services/messages-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class CanalVentaService extends ParametricoService<CanalVenta> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "canal-venta"
	}
	public parseToEnt(data: any): CanalVenta {
		return CanalVenta.fromData(data)
	}
	public newEnt(): CanalVenta {
		return new CanalVenta()
	}
}
