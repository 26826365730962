import * as moment from 'moment'
import { Filtro } from 'src/app/common/model/Filtro'
import { Descriptivo } from '../common/model/Descriptivo'

export class FiltroConsentimiento extends Filtro {

    constructor(name: string, page: number = 0, size: number = 20, sortField: string = 'fechaEnviado', order: number = 2, guardable: boolean = true, defaults?:any) {
        super(name, {
            emailContacto: "Email",
            fechaEnviadoDesde: "Enviado Desde",
            fechaEnviadoHasta: "Enviado Hasta",
            fechaCompletadoDesde: "Completado Desde",
            fechaCompletadoHasta: "Completado Hasta",
            fechaActividadDesde: "Actividad Desde",
            fechaActividadHasta: "Actividad Hasta",
            noEnviados: "No Enviados",
            noCompletados: "No Completados",
            producto: "Producto"
        }, page, size, sortField, order, guardable);
        defaults && Object.assign(this,defaults)
    }

    
    private _emailContacto : string; 
    private _fechaEnviadoDesde: Date;
    private _fechaEnviadoHasta: Date;
    private _fechaCompletadoDesde: Date;
    private _fechaCompletadoHasta: Date;
    private _fechaActividadDesde: Date;
    private _fechaActividadHasta: Date;
    private _noEnviados: Boolean = false;
    private _noCompletados: Boolean = false;
    private _producto : Descriptivo;
    

    public get emailContacto() : string {
        return this._emailContacto;
    }
    public set emailContacto(v : string) {
        if (v != this._emailContacto) {
            this._emailContacto = v
            this.update()
        }
    }
    public get noEnviados(): Boolean {
        return this._noEnviados
    }
    public set noEnviados(v: Boolean) {
        if (v != this._noEnviados) {
            this._noEnviados = v
            this.update()
        }
    }

    public get noCompletados(): Boolean {
        return this._noCompletados
    }
    public set noCompletados(v: Boolean) {
        if (v != this._noCompletados) {
            this._noCompletados = v
            this.update()
        }
    }

    public get fechaEnviadoDesde(): Date {
        return this._fechaEnviadoDesde
    }
    public set fechaEnviadoDesde(v: Date) {
        this._fechaEnviadoDesde = moment(v).startOf('day').toDate()
        this.update()
    }

    public get fechaEnviadoHasta(): Date {
        return this._fechaEnviadoHasta
    }
    public set fechaEnviadoHasta(v: Date) {
        this._fechaEnviadoHasta = moment(v).endOf('day').toDate()
        this.update()
    }

    public get fechaCompletadoDesde(): Date {
        return this._fechaCompletadoDesde
    }
    public set fechaCompletadoDesde(v: Date) {
        this._fechaCompletadoDesde = moment(v).startOf('day').toDate()
        this.update()
    }

    public get fechaCompletadoHasta(): Date {
        return this._fechaCompletadoHasta
    }
    public set fechaCompletadoHasta(v: Date) {
        this._fechaCompletadoHasta = moment(v).endOf('day').toDate()
        this.update()
    }

    public get fechaActividadHasta(): Date {
        return this._fechaActividadHasta
    }
    public set fechaActividadHasta(v: Date) {
        this._fechaActividadHasta = moment(v).endOf('day').toDate()
        this.update()
    }

    public get fechaActividadDesde(): Date {
        return this._fechaActividadDesde
    }
    public set fechaActividadDesde(v: Date) {
        this._fechaActividadDesde = moment(v).startOf('day').toDate()
        this.update()
    }

    public get producto(): Descriptivo {
        return this._producto
    }
    public set producto(v: Descriptivo) {
        this._producto = v
        this.update()
    }

    public clean() {
        let cleanVal = {
            searchStr: null,
            emailContacto: null,
            fechaEnviadoDesde: null,
            fechaEnviadoHasta: null,
            fechaCompletadoDesde: null,
            fechaCompletadoHasta: null,
            noEnviados: false,
            noCompletados: false,
            producto: null,
        }

        this.setMultiple(cleanVal, false)
        super.clean(true)
    }

    public patchValue(v: any) {
        super.patchValue(v, false)
        this._emailContacto = v.emailContacto
        this._fechaEnviadoDesde = v.fechaEnviadoDesde ? new Date(v.fechaEnviadoDesde) : null
        this._fechaEnviadoHasta = v.fechaEnviadoHasta ? new Date(v.fechaEnviadoHasta) : null
        this._fechaCompletadoDesde = v.fechaCompletadoDesde ? new Date(v.fechaCompletadoDesde) : null
        this._fechaCompletadoHasta = v.fechaCompletadoHasta ? new Date(v.fechaCompletadoHasta) : null
        this._fechaActividadDesde = v.fechaActividadDesde ? new Date(v.fechaActividadDesde) : null
        this._fechaActividadHasta = v.fechaActividadHasta ? new Date(v.fechaActividadHasta) : null
        this._noEnviados = v.noEnviados || false
        this._noCompletados = v.noCompletados || false
        this._producto = v.producto || null
        this.update()
    }

    public valid(): boolean {
        return this.fechaEnviadoDesde != undefined
    }
}
