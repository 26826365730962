import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { HelpActividadesComponent } from "./help-actividades/help-actividades.component"
import { HelpCargaMasivaComponent } from "./help-carga-masiva/help-carga-masiva.component"
import { HelpClienteComponent } from "./help-cliente/help-cliente.component"
import { HelpConfiguracionComponent } from "./help-configuracion/help-configuracion.component"
import { HelpCuentaCorrienteComponent } from "./help-cuenta-corriente/help-cuenta-corriente.component"
import { HelpEnConstruccionComponent } from "./help-en-construccion/help-en-construccion.component"
import { HelpFacturaComponent } from "./help-factura/help-factura.component"
import { HelpFormaPagoComponent } from "./help-forma-pago/help-forma-pago.component"
import { HelpGestoresComponent } from "./help-gestores/help-gestores.component"
import { HelpIdiomaComponent } from "./help-idioma/help-idioma.component"
import { HelpItinerarioComponent } from "./help-itinerario/help-itinerario.component"
import { HelpListaPreciosComponent } from "./help-lista-precios/help-lista-precios.component"
import { HelpListadosComponent } from "./help-listados/help-listados.component"
import { HelpOrdenDePagoComponent } from "./help-orden-de-pago/help-orden-de-pago.component"
import { HelpPlantillasComponent } from "./help-plantillas/help-plantillas.component"
import { HelpProductoComponent } from "./help-producto/help-producto.component"
import { HelpProveedorComponent } from "./help-proveedor/help-proveedor.component"
import { HelpRecordatoriosComponent } from "./help-recordatorios/help-recordatorios.component"
import { HelpReportePrevisionReservasComponent } from "./help-reporte-prevision-reservas/help-reporte-prevision-reservas.component"
import { HelpReservaComponent } from "./help-reserva/help-reserva.component"
import { HelpTipoImpuestoComponent } from "./help-tipo-impuesto/help-tipo-impuesto.component"
import { HelpTipoNotificacionComponent } from "./help-tipo-notificacion/help-tipo-notificacion.component"
import { HelpTipoProveedorComponent } from "./help-tipo-proveedor/help-tipo-proveedor.component"
import { HelpTipoPuestoComponent } from "./help-tipo-puesto/help-tipo-puesto.component"
import { HelpUsuarioComponent } from "./help-usuario/help-usuario.component"
import { HelpVehiculoComponent } from "./help-vehiculo/help-vehiculo.component"

const routes: Routes = [
	{ path: "usuario", component: HelpUsuarioComponent },
	{ path: "proveedor", component: HelpProveedorComponent },
	{ path: "listados", component: HelpListadosComponent },
	{ path: "gestores", component: HelpGestoresComponent },
	{ path: "carga-masiva", component: HelpCargaMasivaComponent },
	{ path: "configuracion", component: HelpConfiguracionComponent },
	{ path: "cuenta-corriente", component: HelpCuentaCorrienteComponent },
	{ path: "forma-pago", component: HelpFormaPagoComponent },
	{ path: "idioma", component: HelpIdiomaComponent },
	{ path: "lista-precios", component: HelpListaPreciosComponent },
	{ path: "orden-de-pago", component: HelpOrdenDePagoComponent },
	{ path: "tipo-impuesto", component: HelpTipoImpuestoComponent },
	{ path: "tipo-proveedor", component: HelpTipoProveedorComponent },
	{ path: "tipo-puesto", component: HelpTipoPuestoComponent },
	{ path: "factura", component: HelpFacturaComponent },
	{ path: "reporte-prevision-servicios", component: HelpReportePrevisionReservasComponent },
	{ path: "en-construccion", component: HelpEnConstruccionComponent },
	{ path: "cliente", component: HelpClienteComponent },
	{ path: "vehiculo", component: HelpVehiculoComponent },
	{ path: "producto", component: HelpProductoComponent },
	{ path: "tipo-notificacion", component: HelpTipoNotificacionComponent },
	{ path: "emailtemplate", component: HelpPlantillasComponent },

	{ path: "itinerario", component: HelpItinerarioComponent },
	{ path: "reserva", component: HelpReservaComponent },
	{ path: "actividades", component: HelpActividadesComponent },
	{ path: "recordatorio", component: HelpRecordatoriosComponent },
	{ path: "**", component: HelpEnConstruccionComponent }
]
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class HelpRoutingModule {}
