import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { BloqueoFecha } from 'src/app/model/BloqueoFecha';
@Injectable({
    providedIn: 'root',
})
export class BloqueoFechaService extends ServicioAbstract<BloqueoFecha>{
    public baseName(): string {
        return "bloqueo-fecha";
    }
    public parseToEnt(data: any): BloqueoFecha {
        return BloqueoFecha.fromData(data);
    }
    public newEnt(): BloqueoFecha {
        return new BloqueoFecha();
    }


}