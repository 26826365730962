import { BaseService } from 'src/app/common/services/base.service'
import { Injectable } from '@angular/core'
import { share } from 'rxjs/operators'
import { LoadingService } from '../common/services/loading-data-service.service'
import { Comision } from '../model/Comision'
import { FiltroPuesto } from '../pages/proveedor/FiltroPuesto'

@Injectable({
    providedIn: 'root',
})
export class ComisionService extends BaseService {
    constructor() {
        super()
    }
    getByVendedor(filtro: FiltroPuesto, customLoading?: LoadingService): Promise<Comision[]> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL('byVendedor'), filtro.json)
            .pipe(share())
            .toPromise()
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return r ? r.map((p) => Comision.fromData(p)) : []
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    public baseName() {
        return 'comision'
    }
}
