import { Injectable } from "@angular/core"
import { FinancieroAG } from "./FinancieroAG"

@Injectable({
	providedIn: "root"
})
export class OrdenPagoAG extends FinancieroAG {
	public getRoles(): string[] {
		return super.getRoles().concat(["ROLE_ORDEN_PAGO", "ROLE_MIS_OPS"])
	}
}
