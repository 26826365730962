<div class="reporte-ingresos-egresos content">
	<div class="model-list">
		<div class="top-section grid">
			<div class="col-12 model-title custom">
				<div class="grid">
					<div class="col-8">
						<h2 autofocus>{{ "Ingresos y Egresos" | translate }}</h2>
					</div>
				</div>
			</div>

			<div class="col-12 model-filters" *ngIf="filtro">
				<filtro
					[verBuscador]="false"
					[(filter)]="filtro"
					[isFixed]="false"
					[isMinimized]="true"
					[useSidebar]="true"
					[filterTemplateContent]="filterTemplateContent"
				>
					<ng-template #filterTemplateContent>
						<div class="col-12 p-fluid field">
							<span class="p-float-label">
								<p-calendar
									[(ngModel)]="filtro.fechaDesde"
									appendTo="body"
									view="month"
									dateFormat="mm/yy"
									[readonlyInput]="true"
									inputId="monthpickerDesde"
								></p-calendar>
								<label for="calendar">{{ "FECHA_DESDE" | translate }}</label>
							</span>
						</div>
						<div class="col-12 p-fluid">
							<span class="p-float-label">
								<p-calendar
									[(ngModel)]="filtro.fechaHasta"
									view="month"
									appendTo="body"
									dateFormat="mm/yy"
									[readonlyInput]="true"
									inputId="monthpickerHasta"
								></p-calendar>
								<label for="calendar">{{ "FECHA_HASTA" | translate }}</label>
							</span>
						</div>
					</ng-template>
				</filtro>
			</div>
		</div>

		<div class="bottom-section">
			<div class="grid">
				<div class="col-12">
					<div class="card">
						<p-chart type="bar" [data]="dataBars" [options]="optionsBar" [plugins]="plugins" height="50vh"></p-chart>
					</div>

					<div class="col-12">
						<div class="grid totales">
							<div class="col-12">
								<h4 style="margin-bottom: 0">{{ "TOTALES" | translate }}</h4>
							</div>
							<div class="col-12 lg:col-4 md:col-6">
								<p-card [styleClass]="'total'" [header]="'INGRESOS' | translate">
									<strong>{{ totalIngresos | currencyCustom }}</strong>
									<span>{{ "PROMEDIO" | translate }}: {{ promedioIngresos | currencyCustom }}</span>
								</p-card>
							</div>
							<div class="col-12 lg:col-4 md:col-6">
								<p-card [styleClass]="'total'" [header]="'EGRESOS' | translate">
									<strong>{{ totalEgresos | currencyCustom }}</strong>
									<span>{{ "PROMEDIO" | translate }}: {{ promedioEgresos | currencyCustom }}</span>
								</p-card>
							</div>
							<div class="col-12 lg:col-4 md:col-6 value">
								<p-card [styleClass]="'total'" [header]="'MARGEN' | translate">
									<strong
										>{{ totalIngresos - totalEgresos | currencyCustom }}
										<span class="small">({{ (totalIngresos - totalEgresos) / (totalIngresos / 100) | number : "1.2-2" }}% )</span></strong
									>
									<span>{{ "PROMEDIO" | translate }}: {{ promedioMargen | currencyCustom }}</span>
								</p-card>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 lg:col-6" *ngIf="showDetails && cobranzas?.length">
					<div class="card">
						<p-chart type="pie" [data]="dataCobranzas" [options]="optionsPie" [plugins]="plugins" height="40vh"></p-chart>
						<!--a *ngIf="queryProductos" [routerLink]="['/reportes/ranking-productos']" [queryParams]="queryProductos">Ver ranking de ventas</a-->
					</div>
				</div>
				<div class="col-12 lg:col-6" *ngIf="showDetails && ops?.length">
					<div class="card">
						<p-chart type="pie" [data]="dataOps" [options]="optionsPie" [plugins]="plugins" height="40vh"></p-chart>
						<!--a *ngIf="queryProductos" [routerLink]="['/reportes/ranking-compras']" [queryParams]="queryProductos">Ver ranking de compras</a-->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
