import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { Nacionalidad } from 'src/app/model/Nacionalidad';
import { NacionalidadService } from 'src/app/services/nacionalidad.service';
import { ModelListHandler } from './../../../common/interfaces/ModelListHandler';
import { AccionesTabla } from './../../../common/model/AccionesTabla';



@Component({
	selector: 'listado-nacionalidad',
	templateUrl: 'listado-nacionalidad.component.html',
	styleUrls: ['listado-nacionalidad.component.less']
})

export class ListadoNacionalidadComponent extends SessionComponent implements OnInit, OnDestroy {


	private routeSub: Subscription;
	private paramId: string;
	public editandoParametro: boolean = false;
	public itemEditado: Nacionalidad = new Nacionalidad();
	public listado: Nacionalidad[] = [];
	public filtro: Filtro = new Filtro("param_filter", {}, 0, 10);
	public handler: ModelListHandler;
	constructor(public service: NacionalidadService,
		private confService: ConfirmationService,
		private route: ActivatedRoute,
		messagesService: MessagesService
	) {
		super(messagesService);

	}
	public columns = [{ "field": "codigo", "header": "Código", "role": "text-tabla", "sortable": true, "width": "6em" },
	{ "field": "bandera", "header": "Bandera", "class": "profile-pic", "role": "profile-tabla", "width": "5em" },
	{ "field": "descripcion", "header": "Descripción", "role": "text-tabla", "sortable": true },
	{ "field": "habilitado", "header": "Habilitado", "class": "is-boolean", "role": "boolean-tabla", "width": "10em" }
	]

	public acciones: AccionesTabla[] = [
		new AccionesTabla("Editar Nacionalidad", "fa fa-pencil", "accion-tabla",
			(item) => { this.editar(item.id) },
			(item) => { return this.esAdministrador }
		),
		new AccionesTabla("Borrar Nacionalidad", "fa fa-trash", "accion-tabla",
			(item) => { this.eliminar(item) },
			(item: Nacionalidad) => { return this.esAdministrador && item.habilitado }
		),
		new AccionesTabla("Habilitar Nacionalidad", "fa fa-check", "accion-tabla",
			(item) => { this.habilitar(item) },
			(item: Nacionalidad) => { return this.esAdministrador && !item.habilitado }
		),
	]

	ngOnInit() {
		let $this = this;
		this.handler = {
			nuevo: () => {
				$this.nuevo();
			},
			editar: (id) => {
				$this.editar(id);
			},
			eliminar: null
		};
	}

	public isValid(item: Nacionalidad) {
		return item.descripcion != undefined;
	}
	ngOnDestroy() {
		{
			if (this.routeSub) this.routeSub.unsubscribe();
		}
	}
	public refresh(item?: Nacionalidad) {
		this.service.getAll(this.filtro).then(r => {
			this.listado = r;
			this.editandoParametro = false;
			this.itemEditado = new Nacionalidad();
		})


	}
	public eliminar(item: Nacionalidad) {

		this.confService.confirm({
			key: 'genConf',
			header: "Eliminar",
			message: "Va a eliminar la nacionalidad " + item.descripcion + ". Desea continuar?",
			accept: () => {
				let $this = this;
				$this.service.eliminar(item.id).then((res) => {
					$this.refresh(item);
					$this.success("La nacionalidad fue eliminada")
				});

			}
		});


	}

	public habilitar(item: Nacionalidad) {
		this.confService.confirm({
			key: 'genConf',
			header: "Habilitar",
			message: "Va a habilitar el parametro " + item.descripcion + ". Desea continuar?",
			accept: () => {
				let $this = this;
				$this.service.habilitar(item.id).then((res) => {
					$this.refresh(item);
					$this.success("El parametro fue eliminado")
				});

			}
		});


	}

	public onCancelar() {
		this.editandoParametro = false;
		this.itemEditado = new Nacionalidad();
	}
	private nuevo() {
		this.itemEditado = new Nacionalidad();
		this.editandoParametro = true;
	}
	private editar(id: number) {
		this.service.getById(id).then(r => {
			this.itemEditado = r;
			this.editandoParametro = true;
		})
	}
}