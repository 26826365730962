import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { Nacionalidad } from "src/app/model/Nacionalidad"
import { Descriptivo } from "../common/model/Descriptivo"
import { Filtro } from "../common/model/Filtro"
import { LoadingService } from "../common/services/loading-data-service.service"
import { MessagesService } from "../common/services/messages-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class NacionalidadService extends ParametricoService<Nacionalidad> {
	constructor(protected novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "nacionalidad"
	}
	public parseToEnt(data: any): Nacionalidad {
		return Nacionalidad.fromData(data)
	}
	public newEnt(): Nacionalidad {
		return new Nacionalidad()
	}
	public getPublicNacionalidad(filtro: Filtro = new Filtro(null, {}, 0, 10000), customLoading?: LoadingService): Promise<Descriptivo[]> {
		const $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getApiURL() + "consentimiento/public/nacionalidades", filtro.json), { defaultValue: [] })
			.then((r: any[]) => r.map((d) => Descriptivo.fromData(d)))
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}

	public async getDefault(): Promise<Nacionalidad> {
		return this.getData().then((r) => {
			return r.filter((d) => d.esDefault)[0]
		})
	}
}
