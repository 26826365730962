<div class="listado-movimiento-caja content">
	<model-list
		[service]="service"
		[modelName]="'movimiento-caja'"
		[columns]="columns"
		[title]="'MOVIMIENTOS_CAJA' | translate"
		[(listado)]="listado"
		[isLazy]="true"
		[filtro]="filtro"
		[getData]="getData"
		[navegable]="false"
		[permiteNuevo]="false"
		[editable]="false"
		[borrable]="false"
		[readonly]="true"
		[conResumen]="false"
		[filterContent]="customFilter"
		[subheader]="customSubheader"
		[isPaginator]="false"
		[footer]="footer"
		[onClick]="navegar"
		[titleContent]="header"
		[acciones]="accionesModelList"
	>
		<ng-template #customFilter>
			<div class="grid">
				<div class="col-12">
					<div class="grid align-content-center vertical-aligned">
						<div class="col-12 small">
							<div class="p-inputgroup">
								<span class="p-float-label">
									<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
									<label>{{ "BUSCAR" | translate }}...</label>
								</span>
								<button type="button" pButton icon="pi pi-search"></button>
							</div>
						</div>
						<div class="col-6 mb-1">
							<span class="p-float-label">
								<p-calendar
									[inputStyleClass]="'no-space-mat-form'"
									[(ngModel)]="filtro.fechaDesde"
									name="fechaDesde"
									dateFormat="dd/mm/yy"
									appendTo="body"
								></p-calendar>
								<label>{{ "DESDE" | translate }}</label>
							</span>
						</div>
						<div class="col-6 mb-1">
							<span class="p-float-label">
								<p-calendar
									[inputStyleClass]="'no-space-mat-form'"
									[(ngModel)]="filtro.fechaHasta"
									name="fechaHasta"
									dateFormat="dd/mm/yy"
									appendTo="body"
								></p-calendar>
								<label>{{ "HASTA" | translate }}</label>
							</span>
						</div>
					</div>
					<div class="grid align-content-center vertical-aligned small">
						<div class="col-12">
							<descriptivo-material-selector
								[options]="formasPagoService.data | async"
								[label]="'FORMAPAGO' | translate"
								name="formaPago"
								[limpiable]="true"
								[(ngModel)]="filtro.formaPago"
								[itemTemplate]="conIcono"
							>
								<ng-template #conIcono let-option>
									<div class="grid">
										<div class="col-fixed" style="width: 3em">
											<profile-badge [url]="option.imagenUrl" class="small-badge desktop-badge" [disabled]="true"> </profile-badge>
										</div>
										<div class="col">
											<span>{{ option.descripcion }}</span>
										</div>
									</div>
								</ng-template>
							</descriptivo-material-selector>
						</div>
						<!--div class='col-12'>
                                <descriptivo-material-selector [options]="tiposMovimiento"
                                    [label]="'TIPOMOVIMIENTO' | translate" name="tipo" [limpiable]="true"
                                    [(ngModel)]="filtro.tipoMovimiento">
                                </descriptivo-material-selector>
                            </div-->
						<div class="col-12">
							<descriptivo-material-selector
								[options]="responsables"
								[label]="'RESPONSABLE' | translate"
								name="responsable"
								[limpiable]="true"
								[(ngModel)]="filtro.responsable"
							>
							</descriptivo-material-selector>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template #customSubheader>
			<tr>
				<td colspan="9">
					<div class="grid grid-nogutter">
						<div class="col-12 lg:col-3" *ngFor="let item of pendientes">
							<div class="card traspaso">
								<div class="grid">
									<div class="col-6" *ngIf="item.esPendiente() && caja?.id == item.cajaDestino?.id">
										<div class="bold">Origen</div>
										{{ item.cajaOrigen?.descripcion }}
									</div>
									<div class="col-6" *ngIf="item.esRechazado() || (item.esPendiente() && caja?.id == item.cajaOrigen?.id)">
										<div class="bold">Destino</div>
										{{ item.cajaDestino?.descripcion }}
									</div>
									<div class="col-6">
										{{ item.fecha | date : "dd/MM/yyyy" }}
									</div>
									<div class="col-6 bold">
										{{ item.formaPagoOrigen?.descripcion }}
										<i class="pi pi-arrow-right"></i>
										{{ item.formaPagoDestino?.descripcion }}
									</div>
									<div class="col-6 bold">
										{{ item.monto | currency : "USD" : "symbol-narrow" }}
									</div>
									<div class="col-6">
										<span>
											<span class="bold"> Responsable: </span>
											{{ item.responsable?.descripcion }}
										</span>
									</div>
									<div class="col-6">
										<div class="bold">
											{{ item.estado?.descripcion }}
										</div>
									</div>
									<div class="col-12" *ngIf="item.esPendiente() && caja?.id != item.cajaOrigen?.id">
										<div class="buttons">
											<button
												pButton
												icon="fa fa-check"
												(click)="aprobar(item)"
												tooltipPosition="bottom"
												pTooltip="{{ 'APROBAR' | translate }}"
											></button>
											<button
												pButton
												icon="fa fa-close"
												(click)="abrirRechazar(item)"
												tooltipPosition="bottom"
												pTooltip="{{ 'RECHAZAR' | translate }}"
											></button>
										</div>
									</div>
									<div class="col-12" *ngIf="item.esPendiente() && caja?.id == item.cajaOrigen?.id">
										<div class="buttons">
											<button
												pButton
												icon="fa fa-trash"
												(click)="eliminar(item)"
												tooltipPosition="bottom"
												pTooltip="{{ 'ELIMINAR' | translate }}"
											></button>
										</div>
									</div>
									<div class="col-12" *ngIf="item.esRechazado()">
										<div class="buttons">
											<button
												pButton
												icon="fa fa-pencil"
												(click)="editar(item)"
												tooltipPosition="bottom"
												pTooltip="{{ 'EDITAR' | translate }}"
											></button>
											<button
												pButton
												icon="fa fa-trash"
												(click)="eliminar(item)"
												tooltipPosition="bottom"
												pTooltip="{{ 'ELIMINAR' | translate }}"
											></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</td>
			</tr>

			<tr class="subheader">
				<td colspan="6" class="text-right">{{ "SALDO_AL" | translate }} {{ filtro.fechaDesde | date : "dd/MM/yyyy" }}</td>
				<td class="money-column">
					<span>{{ saldos?.salidas | currencyCustom : caja?.moneda }}</span>
				</td>
				<td class="money-column">
					<span>{{ saldos?.entradas | currencyCustom : caja?.moneda }}</span>
				</td>
				<td class="money-column">
					<span>{{ saldos?.saldo | currencyCustom : caja?.moneda }}</span>
				</td>
			</tr>
			<tr class="totales subheader">
				<td colspan="6" class="text-right total">
					<b>{{ "TOTAL" | translate }}</b>
				</td>
				<td class="text-right">{{ -totalSalida | currencyCustom : caja?.moneda }}</td>
				<td class="text-right">{{ totalEntrada | currencyCustom : caja?.moneda }}</td>
				<td class="text-right">{{ totalSaldo | currencyCustom : caja?.moneda }}</td>
			</tr>
		</ng-template>
		<ng-template #footer>
			<tr class="totales subheader">
				<td colspan="6" class="text-right total">
					<b>{{ "TOTAL" | translate }}</b>
				</td>
				<td class="text-right">{{ -totalSalida | currencyCustom : caja?.moneda }}</td>
				<td class="text-right">{{ totalEntrada | currencyCustom : caja?.moneda }}</td>
				<td class="text-right">{{ totalSaldo | currencyCustom : caja?.moneda }}</td>
			</tr>
		</ng-template>
	</model-list>
	<ng-template #header>
		<div class="grid filter-container">
			<div class="col nowrap">
				<h2 autofocus>
					{{ "Movimientos de caja: " | translate }}
					<span class="caja" (click)="verCajas($event)" [ngClass]="{ clickable: cajas.length > 1 }">
						{{ caja?.descripcion ? caja.descripcion : "Sin caja seleccionada" }} <i class="fa fa-chevron-down" *ngIf="cajas.length > 1"></i
					></span>
				</h2>
			</div>
			<div class="col-fixed flex">
				<div class="p-inputgroup" *ngIf="filtro">
					<input
						*ngIf="isLazy"
						[(ngModel)]="filtro.searchStr"
						pInputText
						placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}"
						[(ngModel)]="filtro.searchStr"
					/>
					<input
						*ngIf="!isLazy"
						(input)="table.filterGlobal($event.target.value.toUpperCase(), 'contains')"
						pInputText
						placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}"
						[(ngModel)]="filtro.searchStr"
					/>
					<button
						pButton
						icon="pi pi-refresh"
						class="p-button-sm p-button-outlined"
						(click)="filtro.forceUpdate(); $event.stopPropagation()"
					></button>
					<button
						pButton
						icon="pi pi-filter"
						class="p-button-sm"
						[ngClass]="{ 'p-button-outlined': !filtro?.hasFilters() }"
						(click)="showFilterbar($event)"
					></button>
				</div>
			</div>
			<div class="col-fixed estado-caja" *ngIf="caja">
				<span *ngIf="!caja?.estaCerrada">{{ "ABIERTA" | translate }}</span>
				<span *ngIf="caja?.estaCerrada">{{ "CERRADA" | translate }}</span>
			</div>
			<div class="col-fixed" *ngIf="acciones?.length">
				<button pButton label="{{ 'ACCIONES' | translate }}" (click)="menu.toggle($event)" icon="fa fa-bars"></button>
			</div>
		</div>
		<p-menu #menu [popup]="true" [model]="acciones" appendTo="body"></p-menu>
	</ng-template>
</div>
<p-overlayPanel #op [dismissable]="true" appendTo="body" [styleClass]="'overlay-option'">
	<ul class="options">
		<li *ngFor="let c of cajas" (click)="aplicarSeleccion(c)">
			{{ c.descripcion }}
		</li>
	</ul>
</p-overlayPanel>

<p-dialog
	header=""
	[(visible)]="editandoMovimiento"
	appendTo="body"
	[modal]="true"
	#dialDetail
	[styleClass]="'no-header gestor-dialog'"
	(onShow)="onDialogShow($event, dialDetail)"
	(onHide)="onDialogHide($event, dialDetail)"
>
	<gestor-cambio-estado-caja
		#gestorEstado
		[item]="itemEditado"
		(onGuardado)="refresh($event)"
		[cajaEditable]="false"
		(onCancelado)="onCancelar($event)"
		[goBack]="false"
		[isModal]="true"
		[caja]="itemEditado.caja"
		[esCierre]="itemEditado?.esCierre"
		*ngIf="arqueando && itemEditado && editandoMovimiento"
	>
	</gestor-cambio-estado-caja>
	<gestor-traspaso-de-caja
		#gestorTraspaso
		(onGuardado)="refresh($event)"
		[cajaEditable]="false"
		(onCancelado)="onCancelar($event)"
		[goBack]="false"
		[isModal]="true"
		[item]="traspaso"
		*ngIf="traspaso && editandoMovimiento"
	>
	</gestor-traspaso-de-caja>

	<gestor-cambio-saldo-caja
		#gestorCambioSaldo
		(onGuardado)="refresh($event)"
		[cajaEditable]="false"
		(onCancelado)="onCancelar($event)"
		[goBack]="false"
		[isModal]="true"
		[item]="cambioSaldo"
		*ngIf="cambioSaldo && editandoMovimiento"
	>
	</gestor-cambio-saldo-caja>
</p-dialog>
<p-sidebar [(visible)]="displayFilterBar" #filterBar position="right" [style]="{ width: isMobile() ? '100vw' : '30vw' }">
	<div class="header-filtros">
		<h2>Filtros</h2>
	</div>
	<ng-container *ngTemplateOutlet="customFilter; context: this"> </ng-container>
</p-sidebar>

<p-dialog
	header=""
	[(visible)]="rechazarMovimiento"
	appendTo="body"
	[modal]="true"
	#dialRechazo
	header="Ingrese un motivo de rechazo"
	[styleClass]="'gestor-dialog'"
	(onShow)="onDialogShow($event, dialDetail)"
	[style]="{ width: '40vw' }"
>
	<div *ngIf="itemSeleccionado">
		<span class="p-float-label">
			<textarea
				rows="4"
				pInputTextarea
				placeholder="{{ 'MOTIVO' | translate }}"
				[readonly]="readonly"
				[(ngModel)]="itemSeleccionado.motivoRechazo"
				tooltipPosition="bottom"
				pTooltip="{{ 'MOTIVO' | translate }}"
				type="text"
				name="observaciones"
			></textarea>
			<label>{{ "MOTIVO" | translate }}</label>
		</span>
	</div>

	<p-footer *ngIf="itemSeleccionado">
		<p-button
			(click)="rechazar()"
			label="Rechazar"
			styleClass="p-button-text"
			[disabled]="!itemSeleccionado.motivoRechazo || !itemSeleccionado.motivoRechazo.length"
		></p-button>
		<p-button (click)="cancelarRechazo()" label="Cancelar" styleClass="p-button-text"></p-button>
	</p-footer>
</p-dialog>
