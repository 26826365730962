<div class="gestor-item-comprobante content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'ITEM-COMPROBANTE' | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		(onCancelar)="handleCancelar($event)"
		[form]="ItemComprobanteForm"
		[multilenguaje]="false"
		[persist]="false"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #ItemComprobanteForm="ngForm" id="ItemComprobanteform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-3">
						<descriptivo-material-selector
							[allowDefault]="!item?.id"
							[service]="productoService"
							[isLazy]="true"
							[readonly]="readonly"
							[label]="'PRODUCTO' | translate"
							[limpiable]="limpiable"
							[(ngModel)]="item.producto"
							[permiteNuevo]="false"
							[getData]="productoService.getCabeceras"
							(onSelect)="seleccionarProducto($event)"
							name="producto"
						>
						</descriptivo-material-selector>
					</div>
					<div class="col-12 lg:col-3">
						<span class="p-float-label p-inputwrapper-filled">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[readonly]="readonly"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								type="text"
								#descripcion="ngModel"
								name="descripcion"
								autocomplete="off"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
					</div>
					<div class="col-12 lg:col-6">
						<div class="grid">
							<div class="col-fixed w-7rem p-fluid">
								<span class="p-float-label p-inputwrapper-filled">
									<input
										pInputText
										placeholder="{{ 'CANTIDAD_PH' | translate }}"
										[readonly]="readonly"
										[(ngModel)]="item.cantidad"
										tooltipPosition="bottom"
										type="number"
										#cantidad="ngModel"
										name="cantidad"
									/>
									<label>{{ "CANTIDAD" | translate }}</label>
								</span>
							</div>
							<div class="col-12 lg:col p-fluid" *ngIf="!incluyeImpuestos">
								<span-dinero-editable
									[(ngModel)]="item.precioUnitarioBruto"
									name="precioUnitario"
									[readonly]="readonly"
									[editable]="!readonly"
									[moneda]="moneda"
									[label]="'PRECIOUNITARIO' | translate"
								>
								</span-dinero-editable>
							</div>
							<div class="col-12 lg:col p-fluid" *ngIf="incluyeImpuestos">
								<span-dinero-editable
									[(ngModel)]="item.precioUnitario"
									name="precioUnitario"
									[readonly]="readonly"
									[editable]="!readonly"
									[moneda]="moneda"
									[label]="'PRECIOUNITARIO' | translate"
								>
								</span-dinero-editable>
							</div>
							<div class="col-12 lg:col-fixed lg:w-8rem p-fluid">
								<descriptivo-material-selector
									*ngIf="!item?.esSubcategorizado"
									[allowDefault]="!item?.id"
									[options]="tipoIVAGrabadoService.data | async"
									[label]="'IVA' | translate"
									[limpiable]="limpiable"
									[(ngModel)]="item.tipoIVA"
									[permiteNuevo]="false"
									name="tipo-iva"
									#tipoIVA="ngModel"
									[readonly]="readonly"
								>
								</descriptivo-material-selector>
								<span *ngIf="item?.esSubcategorizado">
									{{ "IMP_COMPUESTO" | translate }}
								</span>
							</div>
							<div class="col-fixed w-9rem p-fluid" *ngIf="!esNota">
								<p-inputNumber
									[(ngModel)]="item.descuento"
									placeholder="{{ 'Ajuste' | translate }}"
									tooltipPosition="bottom"
									[readonly]="readonly"
									name="descuento"
									step="5"
									[showButtons]="true"
									[suffix]="'%'"
									(onFocus)="onFocus($event)"
								>
								</p-inputNumber>
							</div>
						</div>
					</div>
					<div class="col-12">
						<span class="p-float-label p-inputwrapper-filled">
							<input
								pInputText
								placeholder="{{ 'OBSERVACIONES_PH' | translate }}"
								[(ngModel)]="item.observaciones"
								tooltipPosition="bottom"
								pTooltip="{{ 'OBSERVACIONES_TOOLTIP' | translate }}"
								type="text"
								#observaciones="ngModel"
								name="observaciones"
								[readonly]="readonly"
							/>
							<label>{{ "OBSERVACIONES" | translate }}</label>
						</span>
					</div>

					<div class="col-4 col-offset-8 text-right">
						<span class="p-float-label p-inputwrapper-filled">
							<input
								class="text-right"
								pInputText
								[ngModel]="(!incluyeImpuestos ? item?.totalBruto : item?.totalNeto) | currencyCustom : moneda"
								tooltipPosition="bottom"
								type="text"
								#total="ngModel"
								name="total"
								[readonly]="true"
							/>
							<label>{{ "TOTAL" | translate }}</label>
						</span>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
