import { ItemReserva } from './../model/ItemReserva';
import { ItemResumenReserva } from 'src/app/model/ItemReservaResumen';
import { Pipe, PipeTransform } from '@angular/core';
import { Vehiculo } from 'src/app/model/Vehiculo';
import { VehiculoRequerido } from './../model/VehiculoRequerido';

@Pipe({
  name: 'byRestriccion'
})
export class ByRestriccionPipe implements PipeTransform {

  transform(value: Vehiculo[], args?: VehiculoRequerido): any {
    return !args ? value : (value ? value.filter(v => v.cumple(args)) : []);
  }

}