import { Component, OnInit } from '@angular/core';
import { HelpService } from '../../../services/help.service';

@Component({
  selector: 'app-help-proveedor',
  templateUrl: './help-proveedor.component.html',
  styleUrls: ['./help-proveedor.component.less']
})
export class HelpProveedorComponent implements OnInit {

  constructor(public helpService: HelpService){}
  ngOnInit() {
    
  }
  alert(text){
    console.log("to be implemented")
  }

}
