import { PrecioInsumo } from "src/app/model/PrecioInsumo"
import { Descriptivo } from "../common/model/Descriptivo"
import { round } from "../common/utils/MathUtils"
import { Ausencia } from "./Ausencia"
import { Idioma } from "./Idioma"
import { Insumo } from "./Insumo"
import { Moneda } from "./Moneda"
import { OpcionPuestoOcupacion } from "./OpcionPuestoOcupacion"
import { Proveedor } from "./Proveedor"
import { TipoPuesto } from "./TipoPuesto"

export class OpcionPuesto {
	private _precioActual: PrecioInsumo
	public get precioActual(): PrecioInsumo {
		return this._precioActual
	}
	public set precioActual(v: PrecioInsumo) {
		this._precioActual = v
	}

	public get tipoPuesto(): TipoPuesto {
		return this.puestos && this.puestos.length > 0 ? this.puestos[0] : null
	}
	constructor(
		public proveedor?: Proveedor,
		public ocupacion: OpcionPuestoOcupacion[] = [],
		public puntaje: number = 0,
		public ausencia?: Ausencia,
		public noHabitual: boolean = false,
		public puestos?: TipoPuesto[],
		public insumos?: PrecioInsumo[],
		public idiomas?: Idioma[]
	) {}

	public static fromData(data: any): OpcionPuesto {
		if (!data) return null
		let o = new OpcionPuesto(
			Proveedor.fromData(data.proveedor),
			data.ocupacion.map((o) => OpcionPuestoOcupacion.fromData(o))
		)
		o.puestos = data.puestos ? data.puestos.map((p) => TipoPuesto.fromData(p)) : []
		o.insumos = data.insumos ? data.insumos.map((i) => PrecioInsumo.fromData(i)) : []
		o.idiomas = data.idiomas ? data.idiomas.map((i) => Idioma.fromData(i)) : []
		return o
	}
	public getPrecio(idInsumo: number, fechaVigencia: Date, moneda?: Moneda, cantidad?: number): PrecioInsumo {
		return this.insumos.find((i) => i.aplica({ id: idInsumo }, fechaVigencia, cantidad, moneda)) || new PrecioInsumo(null, null, null, 0, 0)
	}
	public get descripcion() {
		return this.proveedor ? this.proveedor.descripcion : ""
	}
	public precioInsumo(insumo: Insumo | Descriptivo, fecha: Date, moneda: Moneda, cantidad: number): PrecioInsumo {
		if (!insumo || !fecha) return new PrecioInsumo(null, null, null, 0, 0, moneda)
		return this.getPrecio(insumo.id, fecha, moneda, cantidad)
	}
	public getMargen(cantidad: number) {
		const venta = this.precioActual.precioVenta(cantidad)
		return round(((venta - this.precioActual.getCosto(cantidad)) / (venta || 1)) * 100, 2)
	}
	public get tipoMarkup() {
		switch (this.precioActual.tipoMarkup) {
			case PrecioInsumo.MARKUP:
				return "Markup"
			case PrecioInsumo.COMISION:
				return "Comisión"
			case PrecioInsumo.GANANCIA:
				return "Ganancia"
			default:
				return ""
		}
	}
}
