<model-help [title]="'Gestión de proveedores'">
    <h3>Carga Masiva</h3>
    <p>En la parte superior del listado de proveedores encontramos el icono <i class="pi pi-upload"></i> mediante el cual podremos cargar masivamente un listado de proveedores.<br>
    Para ver el instructivo y descargar de archivo modelo, siga este <help-link [linkFinal]="'carga-masiva'" [textoLink]="'Link'"></help-link></p>
    <h3>Acciones rápidas (Gestor de proveedores)</h3>
    <p>
        Las acciones rápidas disponibles en la parte superior de la pantalla son:<br /><br />
        <i class="fa fa-pencil"></i> Este ícono aparece en caso de que estemos en modo vista y nos permite editar el registro actual de proveedor.<br /><br />
        <i class="fa fa-shopping-cart"></i> Que permite abrir la pantalla de <help-link [linkFinal]="'factura'" [textoLink]="'Comprobante'"></help-link> para cargar un nuevo comprobante asociado a
        este proveedor, por productos o servicios prestados. <br /><br /><i class="fa fa-money"></i> Que permite abrir la pantalla de
        <help-link [linkFinal]="'orden-de-pago'" [textoLink]="'Orden de pago'"></help-link> para crear una nueva orden de pago asociada a un
        <help-link [linkFinal]="'factura'" [textoLink]="'Comprobante'"></help-link> previamente cargado y asociado a este proveedor.
    </p>
    <h3>Campos Particulares</h3>
    <p>
        <strong>Tipo Proveedor:</strong> Los valores que podemos seleccionar en este campo deben estár previamente especificados en
        <help-link [linkFinal]="'configuracion'" [textoLink]="'Configuracion'"></help-link> --> <help-link [linkFinal]="'tipo-proveedo'" [textoLink]="'Tipos Proveedor'"></help-link><br /><br />

        <strong>IVA (Condición frente al Iva):</strong> Los diferentes regímenes impositivos para proveedor deben estar previamente cargados en
        <help-link [linkFinal]="'configuracion'" [textoLink]="'Configuracion'"></help-link> --> <help-link [linkFinal]="'tipo-impuesto'" [textoLink]="'Tipos de impuestos'"></help-link><br /><br />

        <strong>Idiomas: </strong> Este campo permite selección múltiple de valores. Identifica los idiomas con los cuales el proveedor puede prestar el servicio. Los idiomas deben estar previamente
        cargados en <help-link [linkFinal]="'configuracion'" [textoLink]="'Configuracion'"></help-link> --> <help-link [linkFinal]="'idioma'" [textoLink]="'Idiomas'"></help-link><br /><br />

        <strong>Puestos a cubrir:</strong> Permite una selección múltiple de puestos (servicios que brinda) previamente cargados en
        <help-link [linkFinal]="'configuracion'" [textoLink]="'Configuracion'"></help-link> --> <help-link [linkFinal]="'tipo-puesto'" [textoLink]="'Tipo de Puesto'"></help-link>.<br /><br />
        <strong>Forma de pago default:</strong> Campo informativo de preferencia de pago del proveedor o forma de pago habitual. Se agregan nuevas formas de pago en
        <help-link [linkFinal]="'configuracion'" [textoLink]="'Configuracion'"></help-link> --><help-link [linkFinal]="'forma-pago'" [textoLink]="'Formas de Pago'"></help-link>
    </p>
    <h3>Sub Secciones</h3>
    
        <img src="assets/images/help/secciones-proveedor.jpg" /><br />

        <strong>Lista de precios: </strong>
        <p>
            En esta sección se puede configurar los precios vigentes de los <help-link [linkFinal]="'insumo'" [textoLink]="'Insumos'"></help-link> que ofrece el proveedor en cuestión. 
            Estos precios pueden ser mantenidos en forma manual o mediante la carga de  <help-link [linkFinal]="'factura'" [textoLink]="'Facturas'"></help-link>.
        </p>
        <p>
            La forma de carga de este listado es similar a la carga de <help-link [linkFinal]="'lista-precios'" [textoLink]="'Lista de precios'"></help-link> de los productos vigentes.
        </p>
        <br />
        <p>
        <strong>Cuenta Corriente</strong> Ayuda general sobre <help-link [linkFinal]="'cuenta-corriente'" [textoLink]="'Cuenta Corriente'"></help-link><br /><br />
        <strong>Servicios Provistos</strong><br /><br />
        <img src="assets/images/help/servicios-provistos.jpg" /><br />
        En esta Sub sección podemos encontrar los servicios que nos ha prestado este proveedor. <br />
        En la <mark>Parte Superior</mark> se encuentra una barra de Búsqueda por texto, un filtro por fechas y un botón de filtro por servicios aún "No facturados" por este proveedor.<br /><br />
        El botón "Facturar" permite abrir la pantalla de <help-link [linkFinal]="'comprobante'" [textoLink]="'Comprobante'"></help-link> con los datos de los servicios no facturados seleccionados con
        el tilde <i class="pi pi-check icon-ayuda"></i> en el listado.<br /><br />
        <strong>No disponibilidad / Disponibilidad</strong><br /><br />
        <img src="assets/images/help/no-disponibilidad.jpg" /><br /><br />
        La <mark>"No disponibilidad"</mark> se utiliza para conocer los días no disponible de los proveedores habituales y la <mark>"Disponibilidad"</mark> para los no habituales u ocasionales.<br /><br />
        Al clickear en un casillero del calendario se abre una pantalla para cargar la información correspondiente.
    </p>
</model-help>
