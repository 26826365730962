<div class="listado-caja content">
	<model-list
		[modelName]="'caja'"
		[columns]="columns"
		[service]="service"
		[title]="'CAJA_TITLE' | translate"
		[(listado)]="listado"
		[isLazy]="false"
		[filtro]="filtro"
	>
	</model-list>
</div>
