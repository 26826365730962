<div class="grid no-space-mat-form">
	<div class="col-12">
		<descriptivo-material-selector
			[options]="opciones"
			[label]="'PERIODO' | translate"
			[limpiable]="false"
			[(ngModel)]="periodoSeleccionado"
			[permiteNuevo]="false"
			(onSelect)="seleccionarPeriodo($event)"
			[name]="'periodo'"
			[required]="false"
			[buscarTraduccion]="true"
		>
		</descriptivo-material-selector>
	</div>
	<div class="col-12" *ngIf="periodoSeleccionado?.codigo == 'RE'">
		<div class="grid">
			<div class="col-12">
				<span class="p-float-label">
					<p-calendar [(ngModel)]="fechaDesde" appendTo="body" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
					<label>{{ "DESDE" | translate }}</label>
				</span>
			</div>
			<div class="col-12">
				<span class="p-float-label">
					<p-calendar [(ngModel)]="fechaHasta" appendTo="body" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
					<label>{{ "HASTA" | translate }}</label>
				</span>
			</div>
		</div>
	</div>
</div>
