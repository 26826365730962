import { HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { ServicioAbstract } from "src/app/common/services/service.service"
import { Descriptivo } from "../common/model/Descriptivo"
import { LoadingService } from "../common/services/loading-data-service.service"
import { ProcesoCargaMasiva } from "../model/ProcesoCargaMasiva"

@Injectable({
	providedIn: "root"
})
export class CargaMasivaService extends ServicioAbstract<ProcesoCargaMasiva> {
	public static TIPOS = [
		new Descriptivo("proveedor", "Proveedor"),
		new Descriptivo("cliente", "Cliente"),
		new Descriptivo("lista-precios", "Listado de Precios")
	]
	public newEnt(): ProcesoCargaMasiva {
		return new ProcesoCargaMasiva()
	}

	baseName() {
		return "carga-masiva"
	}

	constructor() {
		super()
	}

	public parseToEnt(data: any): ProcesoCargaMasiva {
		return ProcesoCargaMasiva.fromData(data)
	}
	verificarArchivo(tipo: string, file: File, customLoading?: LoadingService) {
		let $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()

		const frmData = new FormData()
		if (file) frmData.append("file", file)
		frmData.append("tipo", encodeURIComponent(tipo))
		const request = new HttpRequest("POST", this.getBaseURL() + "verificar", frmData)
		return lastValueFrom(this.http.request(request))
			.then((r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return $this.parseToEnt(r?.body)
			})
			.catch((e) => this.handleError(e, customLoading))
	}
	cargaMasiva(tipo: string, file: File, customLoading?: LoadingService) {
		let $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()

		const frmData = new FormData()
		if (file) frmData.append("file", file)
		frmData.append("tipo", encodeURIComponent(tipo))
		const request = new HttpRequest("POST", this.getBaseURL(), frmData)
		return lastValueFrom(this.http.request(request))
			.then((r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return $this.parseToEnt(r?.body)
			})
			.catch((e) => this.handleError(e, customLoading))
	}
}
