<div class="gestor-traspaso-de-caja content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'TraspasoDeCaja' | translate"
		[verAcciones]="false"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="TraspasoDeCajaForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #TraspasoDeCajaForm="ngForm" id="TraspasoDeCajaform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-4">
						<div class="p-field">
							<span class="p-float-label">
								<input pInputText [matDatepicker]="fechaPick" [(ngModel)]="item.fecha" #fecha="ngModel" name="fecha" [readonly]="readonly" />
								<label for="fecha">{{ "FECHA" | translate }}</label>
							</span>
						</div>
					</div>
					<div class="col-12 lg:col-4">
						<div class="p-field">
							<span class="p-float-label">
								<input
									pInputText
									placeholder="{{ 'Responsable' | translate }}"
									[ngModel]="getUserFullName()"
									tooltipPosition="bottom"
									pTooltip="{{ 'RESPONSABLE' | translate }}"
									type="text"
									name="responsable"
									[readonly]="true"
								/>
								<label for="responsable">{{ "RESPONSABLE" | translate }}</label>
							</span>
						</div>
					</div>
					<div class="col-12 lg:col-4" *ngIf="item.estado">
						<div class="p-field">
							<span class="p-float-label">
								<input
									pInputText
									placeholder="{{ 'ESTADO' | translate }}"
									[ngModel]="item.estado.descripcion"
									tooltipPosition="bottom"
									pTooltip="{{ 'ESTADO' | translate }}"
									type="text"
									name="estado"
									[readonly]="true"
								/>
								<label for="estado">{{ "ESTADO" | translate }}</label>
							</span>
						</div>
					</div>
					<div class="col-12">
						<div class="p-field">
							<span class="p-float-label">
								<input
									pInputText
									placeholder="{{ 'DESCRIPCION_PH' | translate }}"
									[(ngModel)]="item.descripcion"
									tooltipPosition="bottom"
									pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
									type="text"
									#descripcion="ngModel"
									name="descripcion"
									[readonly]="readonly"
									required
								/>
								<label for="descripcion">{{ "DESCRIPCION" | translate }}</label>
							</span>
						</div>
					</div>

					<div class="col-12 lg:col-6">
						<div class="grid grid-nogutter">
							<div class="col-12">
								<descriptivo-material-selector
									[allowDefault]="!item?.id"
									[options]="cajaOptions"
									[label]="'CAJAORIGEN' | translate"
									[limpiable]="limpiable"
									[(ngModel)]="item.cajaOrigen"
									[permiteNuevo]="false"
									(onNew)="nuevocajaOptions()"
									name="cajaOrigen"
									[readonly]="readonly || !cajaEditable"
								>
								</descriptivo-material-selector>
							</div>
							<div class="col-12" [ngClass]="{ mt: readonly || !cajaEditable }">
								<parametrico-selector
									[allowDefault]="!item?.id"
									[service]="formaPagoService"
									#formaPagoOrigen="ngModel"
									[(ngModel)]="item.formaPagoOrigen"
									[limpiable]="true"
									[permiteNuevo]="true"
									name="forma-pago"
									[titulo]="'FORMAPAGOORIGEN' | translate"
									[readonly]="readonly"
								>
								</parametrico-selector>
							</div>
						</div>
					</div>
					<div class="col-12 lg:col-6">
						<div class="grid grid-nogutter">
							<div class="col-12 p-field">
								<descriptivo-material-selector
									[allowDefault]="!item?.id"
									[options]="cajaOptions"
									[label]="'CAJADESTINO' | translate"
									[limpiable]="limpiable"
									[(ngModel)]="item.cajaDestino"
									[permiteNuevo]="false"
									(onNew)="nuevocajaOptions()"
									name="cajaDestino"
									[readonly]="readonly"
								>
								</descriptivo-material-selector>
							</div>
							<div class="col-12 mt">
								<parametrico-selector
									[allowDefault]="!item?.id"
									[service]="formaPagoService"
									#formaPagoDestino="ngModel"
									[(ngModel)]="item.formaPagoDestino"
									[limpiable]="true"
									[permiteNuevo]="true"
									name="forma-pago"
									[titulo]="'FORMAPAGODESTINO' | translate"
									[readonly]="readonly"
								>
								</parametrico-selector>
							</div>
						</div>
					</div>
					<div class="col-12 lg:col-4 p-fluid">
						<span-dinero-editable
							[(ngModel)]="item.monto"
							[label]="'Monto' | translate"
							[editable]="!readonly"
							[moneda]="item?.cajaOrigen?.moneda"
							name="{{ 'monto' + r }}"
							[minValue]="0"
						>
						</span-dinero-editable>
					</div>
					<div class="col-12 lg:col-8 p-fluid">
						<div class="grid">
							<div
								class="col-12 lg:col-3"
								*ngIf="item?.cajaDestino && item?.cajaOrigen && item?.cajaDestino?.moneda?.codigo != item?.cajaOrigen?.moneda?.codigo"
							>
								<span-dinero-editable
									[(ngModel)]="item.cotizacion"
									[label]="'Cotización a moneda destino' | translate"
									[moneda]="item?.cajaDestino?.moneda"
									[editable]="!readonly"
									name="{{ 'cotizacion' + r }}"
									[minValue]="0"
									[decimals]="6"
								>
								</span-dinero-editable>
							</div>

							<div class="col">
								<span-dinero-editable
									[(ngModel)]="item.costo"
									[label]="'Costo de la transferencia' | translate"
									[moneda]="item?.cajaDestino?.moneda"
									[editable]="!readonly"
									name="{{ 'costo' + r }}"
									[minValue]="0"
								>
								</span-dinero-editable>
							</div>
							<div
								class="col-12 lg:col-3"
								*ngIf="item?.cajaDestino && item?.cajaOrigen && item?.cajaDestino?.moneda?.codigo != item?.cajaOrigen?.moneda?.codigo"
							>
								<span-dinero-editable
									[ngModel]="item.montoEfectivo"
									[label]="'Monto a acreditar' | translate"
									[moneda]="item?.cajaDestino?.moneda"
									[editable]="!readonly"
									name="{{ 'efectivo' + r }}"
									[minValue]="0"
									[readonly]="true"
									[decimals]="6"
								>
								</span-dinero-editable>
							</div>
						</div>
					</div>

					<div class="p-fluid col-12 lg:col-4" *ngIf="item.esRechazado()">
						<span class="p-float-label field">
							<textarea
								pInputTextArea
								[readonly]="readonly"
								#motivoRechazo="ngModel"
								name="motivoRechazo"
								[class.p-filled]="item?.comentario?.length > 0"
								[(ngModel)]="item.motivoRechazo"
								tooltipPosition="bottom"
								rows="15"
								style="resize: none; width: 100%; padding: 0.75rem"
							></textarea>
							<label>{{ "MOTIVO" | translate }}</label>
						</span>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
