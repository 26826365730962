<div class="grid p-fluid login">
	<div class="login-content grid align-content-center justify-content-center">
		<div class="col-12 lg:col-6 left">
			<img src="{{ mainLogo }}" />
		</div>
		<div class="col-12 lg:col-6 right">
			<div class="grid">
				<div class="col-12" *ngIf="esperarMail">
					<span>{{ "SE_ENVIÓ_UN_MAIL_A__19" | translate }}</span>
				</div>

				<div class="col-12" *ngIf="error">
					<span>{{ error?.error || ("HUBO_UN_ERROR" | translate) }}</span>
				</div>
				<div class="col-12" *ngIf="!esperarMail">
					<form role="form" #form>
						<div class="grid align-content-center justify-content-center">
							<div class="col-12 lg:col-8">
								<span class="p-float-label field">
									<input
										pInputText
										placeholder="{{ 'NOMBRE' | translate }}"
										[(ngModel)]="registro.nombre"
										tooltipPosition="bottom"
										pTooltip="{{ 'NOMBRE' | translate }}"
										type="text"
										#nombre="ngModel"
										name="nombre"
										required
									/>
									<label>{{ "NOMBRE" | translate }}</label>
									<error-tag [model]="nombre"></error-tag>
								</span>
							</div>
							<div class="col-12 lg:col-8">
								<span class="p-float-label field">
									<input
										pInputText
										placeholder="{{ 'APELLIDO' | translate }}"
										[(ngModel)]="registro.apellido"
										tooltipPosition="bottom"
										pTooltip="{{ 'APELLIDO' | translate }}"
										type="text"
										#apellido="ngModel"
										name="apellido "
										required
									/>
									<label>{{ "APELLIDO" | translate }}</label>
									<error-tag [model]="apellido"></error-tag>
								</span>
							</div>
							<div class="col-12 lg:col-8">
								<span class="p-float-label field">
									<input
										pInputText
										placeholder="{{ 'EMAIL' | translate }}"
										[(ngModel)]="registro.email"
										tooltipPosition="bottom"
										pTooltip="{{ 'EMAIL' | translate }}"
										type="email"
										#email="ngModel"
										name="email"
										required
										email
									/>
									<label>{{ "EMAIL" | translate }}</label>
									<error-tag [model]="email"></error-tag>
								</span>
							</div>
							<div class="col-12 text-center" style="width: auto">
								<re-captcha (resolved)="resolved($event)" [formControl]="recaptcha" [siteKey]="key"> </re-captcha>
							</div>
							<div class="col-12 buttons">
								<button type="submit" pButton class="register-button" label="{{ 'REGISTRARSE' | translate }}" (click)="registrarse()"></button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
