<div class="gestor-encuesta-actividades content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'EncuestaActividades' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="EncuestaActividadesForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #EncuestaActividadesForm="ngForm" id="EncuestaActividadesform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-2">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'CODIGO_PH' | translate }}"
								[(ngModel)]="item.codigo"
								tooltipPosition="bottom"
								pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
								type="text"
								#codigo="ngModel"
								name="codigo"
								[readonly]="readonly"
							/>
							<label>{{ "CODIGO" | translate }}</label>
						</span>
						<error-tag [model]="codigo"></error-tag>
					</div>

					<div class="col-12 lg:col-6">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="$TYPE"
								#descripcion="ngModel"
								name="descripcion"
								[readonly]="readonly"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>
					<div class="subtitulo col-12">
						<span
							>{{ "PREGUNTAS" | translate }}
							<button pButton (click)="agregarPregunta()" icon="fa fa-plus"></button>
						</span>
					</div>

					<div class="seccion col-12">
						<div class="grid p-nogutter">
							<div
								class="insumo-wrapper col-12 lg:col-8"
								pDroppable="gens"
								(onDrop)="onDrop(i)"
								(onDragStart)="onDragStart(i)"
								pDraggable="gens"
								dragHandle=".drag-button"
								*ngFor="let pregunta of preguntasSeleccionadas; let i = index"
							>
								<div class="grid">
									<div class="col-9">
										<descriptivo-material-selector
											[allowDefault]="!item?.id"
											[allowDefault]="!item?.id"
											[service]="plantillaPreguntaService"
											[isLazy]="true"
											[placeHolder]="'PREGUNTA' | translate"
											[floatLabel]="i == 0 ? 'auto' : 'never'"
											name=" {{ 'pregunta_' + i }}"
											[limpiable]="false"
											[disabled]="readonly"
											[(ngModel)]="pregunta.pregunta"
											[label]="i == 0 ? titulo : null"
											[required]="true"
											(onSelect)="onSelectPregunta($event, i)"
											[gestor]="gestorPregunta"
											[permiteNuevo]="true"
											[readonly]="readonly"
										>
										</descriptivo-material-selector>
									</div>
									<div class="col-3">
										<button
											class="ordenar-button"
											pButton
											type="button"
											icon="pi pi-angle-up"
											(click)="reordenar($event, i, -1)"
											pTooltip="{{ 'SUBIR' | translate }}"
											[disabled]="readonly"
										></button>
										<button
											class="ordenar-button"
											pButton
											type="button"
											icon="pi pi-angle-down"
											(click)="reordenar($event, i, 1)"
											pTooltip="{{ 'BAJAR' | translate }}"
											[disabled]="readonly"
										></button>
										<button
											class="ordenar-button"
											pButton
											type="button"
											icon="pi pi-trash"
											(click)="borrarPregunta($event, i, 1)"
											pTooltip="{{ 'BORRAR' | translate }}"
											[disabled]="readonly"
										></button>

										<!-- <button class="ordenar-button drag-button" pButton type="button" icon="pi pi-sort"
											pTooltip="{{ 'ORDENAR' | translate }}"></button> -->
										<i class="pi pi-sort ordenar-button drag-button" pTooltip="{{ 'ORDENAR' | translate }}"> </i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>

<ng-template #gestorPregunta let-handler="handler">
	<gestor-plantilla-pregunta (onGuardado)="handler.onGuardado($event)" (onCancelado)="handler.onCancelado($event)" [isModal]="true" [goBack]="false">
	</gestor-plantilla-pregunta>
</ng-template>
