
import { Parametrico } from 'src/app/common/model/Parametrico';


export class Unidad extends Parametrico {
    constructor(id?: number, codigo?: string, descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,
        		public esDivisible ?: boolean,


    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso)
    }
    public tipoParametro(): string {
        return "UnidadLOW";
    }
    public static fromData(data: any): Unidad {
        if (!data) return null;
        const o: Unidad = new Unidad(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,

					 data.esDivisible, 
        );

        o.version = data.version; 
return o;

    }

}