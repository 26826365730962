import { Horario } from "./Horario"

export class DisponibilidadDiaria {
	constructor(public horarios: Horario[] = [new Horario()]) {}

	public static fromData(data: any) {
		if (!data) return new DisponibilidadDiaria()
		let o: DisponibilidadDiaria = new DisponibilidadDiaria(data.horarios ? data.horarios.map((h) => Horario.fromData(h)) : [])
		return o
	}

	public agregarHorario(item: Horario) {
		if (this.horarios.indexOf(item) < 0) {
			this.horarios = [...this.horarios, item]
		}
	}
	public quitarHorario(item: Horario) {
		this.horarios = this.horarios.filter((i) => i != item)
	}

	newHorario = () => {
		return new Horario()
	}
}
