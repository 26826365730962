<div class="listado-cobranza content">
	<model-list
		[service]="service"
		[modelName]="'cobranza'"
		[columns]="columns"
		[title]="'COBRANZAS' | translate"
		[(listado)]="listado"
		[isLazy]="true"
		[filtro]="filtro"
		[multilenguaje]="false"
		[getData]="getData"
		[getCount]="getCount"
		[filterContent]="customFilter"
		[acciones]="acciones"
		[hideHeader]="hideHeader"
		[footer]="footerTemplate"
	>
		<ng-template #customFilter>
			<div class="grid">
				<div class="col">
					<div class="p-inputgroup">
						<span class="p-float-label">
							<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
							<label>{{ "BUSCAR" | translate }}...</label>
						</span>
						<button type="button" pButton icon="pi pi-search"></button>
					</div>
				</div>
				<div class="col-12">
					<descriptivo-material-selector
						[label]="'CLIENTE' | translate"
						[limpiable]="true"
						[(ngModel)]="filtro.cliente"
						name="cliente"
						isLazy="true"
						[service]="clienteService"
					>
					</descriptivo-material-selector>
				</div>
				<div class="col-6">
					<span class="p-float-label">
						<p-calendar [(ngModel)]="filtro.fechaDesde" appendTo="body" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
						<label>{{ "DESDE" | translate }}</label>
					</span>
				</div>
				<div class="col-6">
					<span class="p-float-label">
						<p-calendar [(ngModel)]="filtro.fechaHasta" appendTo="body" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
						<label>{{ "HASTA" | translate }}</label>
					</span>
				</div>
				<div class="col-12 botonera">
					<div class="grid">
						<div class="col text-center" *ngFor="let estado of estadosService.data | async">
							<button
								pButton
								[label]="estado?.descripcion"
								[ngClass]="{ 'no-seleccionado': !filtro?.tieneEstado(estado) }"
								[ngStyle]="{ 'border-bottom-color': estado?.color, 'background-color': estado?.color }"
								(click)="filtro.toggleEstado(estado)"
							></button>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template #footerTemplate>
			<tr class="totales">
				<td colspan="3">
					<div class="grid align-content-center text-right">
						<div class="col-12">
							<b>{{ (totales?.totalesPorMoneda?.length > 1 ? "TOTALES" : "TOTAL") | translate }}</b>
						</div>
					</div>
				</td>
				<td class="text-right total">
					<div class="grid align-content-center grid-nogutter">
						<div class="col-12 text-right" *ngFor="let tot of totales.totalesPorMoneda">
							<b>{{ tot?.moneda?.descripcion }}</b>
						</div>
					</div>
				</td>

				<td class="text-right total">
					<div class="grid align-content-center grid-nogutter">
						<div class="col-12 text-right" *ngFor="let tot of totales.totalesPorMoneda">
							<b>{{ tot?.totalSaldo | currency : "USD" : tot?.moneda?.simbolo + " " || "$ " }}</b>
						</div>
					</div>
				</td>
				<td></td>
			</tr>
		</ng-template>
	</model-list>
</div>

<p-dialog
	[(visible)]="verAnulacion"
	appendTo="body"
	[modal]="true"
	header="Anulando cobranza"
	#diag
	[styleClass]="'cancelacion-dialog'"
	(onShow)="onDialogShow($event, diag)"
>
	<div class="grid" *ngIf="aAnular">
		<div class="col-12">
			<parametrico-selector
				[service]="motivoService"
				#motivoAnulacion="ngModel"
				[(ngModel)]="aAnular.motivoCancelacion"
				[limpiable]="true"
				[permiteNuevo]="true"
				name="motivo-cancelacion"
				[titulo]="'MOTIVO_CANCELACION' | translate"
			>
			</parametrico-selector>
		</div>

		<div class="col-12 text-right">
			<button class="col" pButton label="{{ 'CONFIRMAR' | translate }}" (click)="confirmarAnulacion()" [disabled]="!aAnular.motivoCancelacion"></button>
		</div>
	</div>
</p-dialog>
