<model-list
	[(listado)]="cuentaCorriente"
	[isLazy]="false"
	[service]="servicio"
	[getData]="getData"
	[modelName]="filtro?.name || 'cuenta'"
	[columns]="columns"
	[title]="'Cuenta Corriente' | translate"
	[filtro]="filtro"
	[multilenguaje]="false"
	[filterContent]="filterContent"
	[hideTitle]="true"
	[navegable]="false"
	[onClick]="onClick"
	[permiteNuevo]="false"
	[acciones]="acciones"
	[styleClass]="esEmbebida ? 'embebed' : ''"
	[download]="true"
	[getCount]="getCount"
	dataClass="data-cuenta-corriente"
	[loading]="customLoading.loading | async"
>
	<ng-template #filterContent>
		<div class="grid">
			<div class="col-12">
				<div class="p-inputgroup">
					<span class="p-float-label">
						<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
						<label>{{ "BUSCAR" | translate }}...</label>
					</span>
					<button type="button" pButton icon="pi pi-search"></button>
				</div>
			</div>
			<div class="col-12 p-fluid">
				<div class="grid">
					<div class="col-6">
						<span class="p-float-label">
							<p-calendar [(ngModel)]="filtro.fechaDesde" dateFormat="dd/mm/yy" appendTo="body" name="fechaDesde"></p-calendar>
							<label>{{ "FECHADESDE" | translate }}</label>
						</span>
					</div>
					<div class="col-6">
						<span class="p-float-label">
							<p-calendar [(ngModel)]="filtro.fechaHasta" dateFormat="dd/mm/yy" appendTo="body" name="fechaHasta"></p-calendar>
							<label>{{ "HASTA" | translate }}</label>
						</span>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</model-list>

<ng-template #documentoRef let-data let-col="col">
	<span
		class="clickable chip text-center print:no-background print:text-left"
		*ngIf="data?.numeroOperacion"
		(click)="verComprobante(data, $event)"
		[style.background-color]="data?.color || 'transparent'"
		tooltipPosition="bottom"
		pTooltip="{{ data?.tipoComprobante?.descripcion + ' ' + data?.numeroOperacion + ' - ' + data?.estadoDocumento?.descripcion }}"
		>{{ data?.tipoComprobante?.codigo }} {{ data?.numeroOperacion }}</span
	>
</ng-template>

<p-dialog
	[(visible)]="verAnulacionDocumento"
	appendTo="body"
	[modal]="true"
	header="Anulando"
	#diag
	[styleClass]="'cancelacion-dialog'"
	(onShow)="onDialogShow($event, diag)"
>
	<div class="grid" *ngIf="anularDocumento">
		<div class="col-12">
			<parametrico-selector
				[service]="motivoService"
				#motivoAnulacionCobranza="ngModel"
				[(ngModel)]="anularDocumento.motivoCancelacion"
				[limpiable]="true"
				[permiteNuevo]="true"
				name="motivo-cancelacion"
				[titulo]="'MOTIVO_CANCELACION' | translate"
			>
			</parametrico-selector>
		</div>

		<div class="col-12 text-right">
			<button
				class="col"
				pButton
				label="{{ 'CONFIRMAR' | translate }}"
				(click)="confirmarAnulacionCoranza()"
				[disabled]="!anularDocumento.motivoCancelacion"
			></button>
		</div>
	</div>
</p-dialog>

<ng-template #salidaRef let-data>
	<div class="grid grid-nogutter" *ngIf="data.salida">
		<div class="col-12 text-right">
			<span>{{ data.importeTransformado | currencyCustom }}</span>
		</div>
		<div class="col-12 text-right small" *ngIf="data.moneda?.codigo != configuracionEntorno?.moneda?.codigo">
			<span>{{ data.salida | currencyCustom : data.moneda }}</span>
		</div>
	</div>
</ng-template>

<ng-template #entradaRef let-data>
	<div class="grid grid-nogutter" *ngIf="data.entrada">
		<div class="col-12 text-right">
			<span>{{ data.importeTransformado | currencyCustom }}</span>
		</div>
		<div class="col-12 text-right SMALL" *ngIf="data.moneda?.codigo != configuracionEntorno?.moneda?.codigo">
			<span>{{ data.entrada | currencyCustom : data.moneda }}</span>
		</div>
	</div>
</ng-template>
