import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { ActividadesAG } from "../authguards/ActividadesAG"
import { AdminAuthGuard } from "../authguards/AdminAuthGuard"
import { AdministracionAuthGuard } from "../authguards/AdministracionAuthGuard"
import { ClientesAG } from "../authguards/ClientesAG"
import { CobranzasAG } from "../authguards/CobranzasAG"
import { ComprobantesAG } from "../authguards/ComprobantesAG"
import { EmpleadoAuthGuard } from "../authguards/EmpleadoAuthGuard"
import { EncuestasAG } from "../authguards/EncuestasAG"
import { FacturasAG } from "../authguards/FacturasAG"
import { GestorPlantillasAG } from "../authguards/GestorPlantillasAG"
import { HotelesAG } from "../authguards/HotelesAG"
import { ListaPreciosAG } from "../authguards/ListaPreciosAG"
import { MovimientoCajaAG } from "../authguards/MovimientoCajaAG"
import { NotificacionAG } from "../authguards/NotificacionesAG"
import { OrdenPagoAG } from "../authguards/OrdenPagoAG"
import { ProductosAG } from "../authguards/ProductosAG"
import { ProveedoresAG } from "../authguards/ProveedoresAG"
import { ReporteAG } from "../authguards/ReporteAG"
import { ReservasAG } from "../authguards/ReservasAG"
import { UsuarioAG } from "../authguards/UsuarioAG"
import { VehiculosAG } from "../authguards/VehiculosAG"
import { VendedoresAG } from "../authguards/VendedoresAG"
import { GestorBloqueoVehiculoComponent } from "../pages/bloqueo-vehiculo/gestor-bloqueo-vehiculo/gestor-bloqueo-vehiculo.component"
import { ListadoBloqueoVehiculoComponent } from "../pages/bloqueo-vehiculo/listado-bloqueo-vehiculo/listado-bloqueo-vehiculo.component"
import { GestorCajaComponent } from "../pages/caja/gestor-caja/gestor-caja.component"
import { ListadoCajaComponent } from "../pages/caja/listado-caja/listado-caja.component"
import { CalendarioComponent } from "../pages/calendario/calendario.component"
import { GestorArqueoCajaComponent } from "../pages/cambio-estado-caja/gestor-cambio-estado-caja/gestor-cambio-estado-caja.component"
import { ListadoArqueoCajaComponent } from "../pages/cambio-estado-caja/listado-cambio-estado-caja/listado-cambio-estado-caja.component"
import { GestorCambioSaldoCajaComponent } from "../pages/cambio-saldo-caja/gestor-cambio-saldo-caja/gestor-cambio-saldo-caja.component"
import { ListadoCambioSaldoCajaComponent } from "../pages/cambio-saldo-caja/listado-cambio-saldo-caja/listado-cambio-saldo-caja.component"
import { GestorCanalComunicacionComponent } from "../pages/canal-comunicacion/gestor-canal-comunicacion/gestor-canal-comunicacion.component"
import { ListadoCanalComunicacionComponent } from "../pages/canal-comunicacion/listado-canal-comunicacion/listado-canal-comunicacion.component"
import { GestorCategoriaProductoComponent } from "../pages/categoria-producto/gestor-categoria-producto/gestor-categoria-producto.component"
import { ListadoCategoriaProductoComponent } from "../pages/categoria-producto/listado-categoria-producto/listado-categoria-producto.component"
import { GestorCentroCostoComponent } from "../pages/centro-costo/gestor-centro-costo/gestor-centro-costo.component"
import { ListadoCentroCostoComponent } from "../pages/centro-costo/listado-centro-costo/listado-centro-costo.component"
import { GestorClienteComponent } from "../pages/cliente/gestor-cliente/gestor-cliente.component"
import { ListadoClienteComponent } from "../pages/cliente/listado-cliente/listado-cliente.component"
import { GestorCobranzaComponent } from "../pages/cobranza/gestor-cobranza/gestor-cobranza.component"
import { ListadoCobranzaComponent } from "../pages/cobranza/listado-cobranza/listado-cobranza.component"
import { GestorComprobanteComponent } from "../pages/comprobante/gestor-comprobante/gestor-comprobante.component"
import { ListadoComprobanteComponent } from "../pages/comprobante/listado-comprobante/listado-comprobante.component"
import { ListadoConsentimientoComponent } from "../pages/consentimiento-actividades/listado-consentimiento/listado-consentimiento.component"
import { GestorCuentaContableComponent } from "../pages/cuenta-contable/gestor-cuenta-contable/gestor-cuenta-contable.component"
import { ListadoCuentaContableComponent } from "../pages/cuenta-contable/listado-cuenta-contable/listado-cuenta-contable.component"
import { GestorEncuestaActividadesComponent } from "../pages/encuesta-actividades/gestor-encuesta-actividades/gestor-encuesta-actividades.component"
import { ListadoEncuestaActividadesComponent } from "../pages/encuesta-actividades/listado-encuesta-actividades/listado-encuesta-actividades.component"
import { GestorEstadoDocumentoComponent } from "../pages/estado-documento/gestor-estado-documento/gestor-estado-documento.component"
import { ListadoEstadoDocumentoComponent } from "../pages/estado-documento/listado-estado-documento/listado-estado-documento.component"
import { GestorEstadoReservaComponent } from "../pages/estado-reserva/gestor-estado-reserva/gestor-estado-reserva.component"
import { ListadoEstadoReservaComponent } from "../pages/estado-reserva/listado-estado-reserva/listado-estado-reserva.component"
import { GestorFacturaComponent } from "../pages/factura/gestor-factura/gestor-factura.component"
import { ListadoFacturaComponent } from "../pages/factura/listado-factura/listado-factura.component"
import { GestorFormaPagoComponent } from "../pages/forma-pago/gestor-forma-pago/gestor-forma-pago.component"
import { ListadoFormaPagoComponent } from "../pages/forma-pago/listado-forma-pago/listado-forma-pago.component"
import { GestorHotelComponent } from "../pages/hotel/gestor-hotel/gestor-hotel.component"
import { ListadoHotelComponent } from "../pages/hotel/listado-hotel/listado-hotel.component"
import { GestorIdiomaComponent } from "../pages/idioma/gestor-idioma/gestor-idioma.component"
import { ListadoIdiomaComponent } from "../pages/idioma/listado-idioma/listado-idioma.component"
import { GestorInsumoComponent } from "../pages/insumo/gestor-insumo/gestor-insumo.component"
import { ListadoInsumoComponent } from "../pages/insumo/listado-insumo/listado-insumo.component"
import { EdicionListaPreciosComponent } from "../pages/lista-precios/edicion-lista-precios/edicion-lista-precios.component"
import { ListadoListaPreciosComponent } from "../pages/lista-precios/listado-lista-precios/listado-lista-precios.component"
import { GestorMonedaComponent } from "../pages/moneda/gestor-moneda/gestor-moneda.component"
import { ListadoMonedaComponent } from "../pages/moneda/listado-moneda/listado-moneda.component"
import { ListadoMovimientoCajaComponent } from "../pages/movimiento-caja/listado-movimiento-caja/listado-movimiento-caja.component"
import { GestorNacionalidadComponent } from "../pages/nacionalidad/gestor-nacionalidad/gestor-nacionalidad.component"
import { ListadoNacionalidadComponent } from "../pages/nacionalidad/listado-nacionalidad/listado-nacionalidad.component"
import { GestorNotaComponent } from "../pages/nota/gestor-nota/gestor-nota.component"
import { ListadoNotaComponent } from "../pages/nota/listado-nota/listado-nota.component"
import { ListadoNotificacionComponent } from "../pages/notificacion/listado-notificacion/listado-notificacion.component"
import { GestorOrdenPagoComponent } from "../pages/orden-pago/gestor-orden-pago/gestor-orden-pago.component"
import { ListadoOrdenPagoComponent } from "../pages/orden-pago/listado-orden-pago/listado-orden-pago.component"
import { GestorEmailTemplateComponent } from "../pages/parametricos/email-template/gestor-email-template/gestor-email-template.component"
import { ListaEmailTemplatesComponent } from "../pages/parametricos/email-template/lista-email-template/lista-email-template.component"
import { GenericParametricoComponent } from "../pages/parametricos/generic-parametrico/generic-parametrico.component"
import { GestorParametricoComponent } from "../pages/parametricos/gestor-parametrico/gestor-parametrico.component"
import { ParametricosComponent } from "../pages/parametricos/parametricos/parametricos.component"
import { GestorPlantillaPreguntaComponent } from "../pages/plantilla-pregunta/gestor-plantilla-pregunta/gestor-plantilla-pregunta.component"
import { ListadoPlantillaPreguntaComponent } from "../pages/plantilla-pregunta/listado-plantilla-pregunta/listado-plantilla-pregunta.component"
import { GestorProductoComponent } from "../pages/producto/gestor-producto/gestor-producto.component"
import { ListadoProductoComponent } from "../pages/producto/listado-producto/listado-producto.component"
import { GestorProveedorComponent } from "../pages/proveedor/gestor-proveedor/gestor-proveedor.component"
import { ListadoProveedorComponent } from "../pages/proveedor/listado-proveedor/listado-proveedor.component"
import { GestorPuntoDeVentaComponent } from "../pages/punto-de-venta/gestor-punto-de-venta/gestor-punto-de-venta.component"
import { ListadoPuntoDeVentaComponent } from "../pages/punto-de-venta/listado-punto-de-venta/listado-punto-de-venta.component"
import { RankinProductoComponent } from "../pages/rankin-producto/rankin-producto.component"
import { ComprasComponent } from "../pages/reportes/compras/compras.component"
import { VentasComponent } from "../pages/reportes/ventas/ventas.component"
import { ActividadesComponent } from "../pages/reserva/actividades/actividades.component"
import { GestorReservaComponent } from "../pages/reserva/gestor-reserva/gestor-reserva.component"
import { ItinerarioComponent } from "../pages/reserva/itinerario/itinerario.component"
import { ListadoReservaComponent } from "../pages/reserva/listado-reserva/listado-reserva.component"
import { GestorRespuestaEncuestaActividadesComponent } from "../pages/respuesta-encuesta-actividades/gestor-respuesta-encuesta-actividades/gestor-respuesta-encuesta-actividades.component"
import { ListadoRespuestaEncuestaActividadesComponent } from "../pages/respuesta-encuesta-actividades/listado-respuesta-encuesta-actividades/listado-respuesta-encuesta-actividades.component"
import { GestorRestriccionComponent } from "../pages/restriccion/gestor-restriccion/gestor-restriccion.component"
import { ListadoRestriccionComponent } from "../pages/restriccion/listado-restriccion/listado-restriccion.component"
import { GestorTipoAusenciaComponent } from "../pages/tipo-ausencia/gestor-tipo-ausencia/gestor-tipo-ausencia.component"
import { ListadoTipoAusenciaComponent } from "../pages/tipo-ausencia/listado-tipo-ausencia/listado-tipo-ausencia.component"
import { GestorTipoCuotasTarjetaComponent } from "../pages/tipo-cuotas-tarjeta/gestor-tipo-cuotas-tarjeta/gestor-tipo-cuotas-tarjeta.component"
import { ListadoTipoCuotasTarjetaComponent } from "../pages/tipo-cuotas-tarjeta/listado-tipo-cuotas-tarjeta/listado-tipo-cuotas-tarjeta.component"
import { GestorTipoNotificacionComponent } from "../pages/tipo-notificacion/gestor-tipo-notificacion/gestor-tipo-notificacion.component"
import { ListadoTipoNotificacionComponent } from "../pages/tipo-notificacion/listado-tipo-notificacion/listado-tipo-notificacion.component"
import { GestorTipoPuestoComponent } from "../pages/tipo-puesto/gestor-tipo-puesto/gestor-tipo-puesto.component"
import { ListadoTipoPuestoComponent } from "../pages/tipo-puesto/listado-tipo-puesto/listado-tipo-puesto.component"
import { GestorTipoTarjetaCreditoComponent } from "../pages/tipo-tarjeta-credito/gestor-tipo-tarjeta-credito/gestor-tipo-tarjeta-credito.component"
import { ListadoTipoTarjetaCreditoComponent } from "../pages/tipo-tarjeta-credito/listado-tipo-tarjeta-credito/listado-tipo-tarjeta-credito.component"
import { GestorUnidadComponent } from "../pages/unidad/gestor-unidad/gestor-unidad.component"
import { ListadoUnidadComponent } from "../pages/unidad/listado-unidad/listado-unidad.component"
import { GestionUserComponent } from "../pages/user/gestion-user/gestion-user.component"
import { UserComponent } from "../pages/user/user.component"
import { GestorVehiculoComponent } from "../pages/vehiculos/gestor-vehiculos/gestor-vehiculos.component"
import { ListadoVehiculosComponent } from "../pages/vehiculos/listado-vehiculos/listado-vehiculos.component"
import { ItinerarioAG } from "./../authguards/ItinerarioAG"
import { ReportesComprasAG } from "./../authguards/ReporteComprasAG"
import { ReportesVentasAG } from "./../authguards/ReporteVentasAG"
import { ListadoCargaMasivaComponent } from "./../pages/carga-masiva/listado-carga-masiva/listado-carga-masiva.component"
import { GestorConsentimientoComponent } from "./../pages/consentimiento-actividades/gestor-consentimiento/gestor-consentimiento.component"
import { GestorFamiliaProductoComponent } from "./../pages/familia-producto/gestor-familia-producto/gestor-familia-producto.component"
import { ListadoFamiliaProductoComponent } from "./../pages/familia-producto/listado-familia-producto/listado-familia-producto.component"
import { GestorJobConfigurationComponent } from "./../pages/job-configuration/gestor-job-configuration/gestor-job-configuration.component"
import { ListadoJobConfigurationComponent } from "./../pages/job-configuration/listado-job-configuration/listado-job-configuration.component"
import { ListadoMargenReporteComponent } from "./../pages/margen-reporte/listado-margen-reporte/listado-margen-reporte.component"
import { VistaMargenReporteComponent } from "./../pages/margen-reporte/vista-margen-reporte/vista-margen-reporte.component"
import { RankinComprasComponent } from "./../pages/rankin-compra/rankin-compras.component"
import { EstadisticasActividadesComponent } from "./../pages/reportes/estadisticas-actividades/estadisticas-actividades.component"
import { ReporteConsentimientoComponent } from "./../pages/reportes/reporte-consentimiento/reporte-consentimiento.component"
import { ReporteEncuestasComponent } from "./../pages/reportes/reporte-encuestas/reporte-encuestas.component"
import { ReporteIngresosEgresosComponent } from "./../pages/reportes/reporte-ingresos-egresos/reporte-ingresos-egresos.component"
import { TotalesFormaPagoComponent } from "./../pages/reportes/totales-forma-pago/totales-forma-pago.component"

import { ClienteExternoAG } from "../authguards/ClienteExternoAG"
import { NacionalidadAG } from "../authguards/NacionalidadAG"
import { OrdenPagoEdicionAG } from "../authguards/OrdenPagoEdicionAG"
import { PuntoDeVentaAG } from "../authguards/PuntoDeVentaAG"
import { VisualizadorAuthGuard } from "../authguards/VisualizadorAuthGuard"
import { VisualizadorDocsProveedorAG } from "../authguards/VisualizadorDocsProveedorAG"
import { ListaProveedorAG, VisualizadorProveedorAG } from "../authguards/VisualizadorProveedorAG"
import { GestorCategoriaHospedajeComponent } from "../pages/categoria-hospedaje/gestor-categoria-hospedaje/gestor-categoria-hospedaje.component"
import { ListadoCategoriaHospedajeComponent } from "../pages/categoria-hospedaje/listado-categoria-hospedaje/listado-categoria-hospedaje.component"
import { GestorConfiguracionEntornoComponent } from "../pages/configuracion-entorno/gestor-configuracion-entorno/gestor-configuracion-entorno.component"
import { ListadoConfiguracionEntornoComponent } from "../pages/configuracion-entorno/listado-configuracion-entorno/listado-configuracion-entorno.component"
import { GestorInclusionComponent } from "../pages/inclusion/gestor-inclusion/gestor-inclusion.component"
import { ListadoInclusionComponent } from "../pages/inclusion/listado-inclusion/listado-inclusion.component"
import { GestorPaisComponent } from "../pages/pais/gestor-pais/gestor-pais.component"
import { ListadoPaisComponent } from "../pages/pais/listado-pais/listado-pais.component"
import { GestorProductoPublicadoComponent } from "../pages/producto-publicado/gestor-producto-publicado/gestor-producto-publicado.component"
import { ListadoProductoPublicadoComponent } from "../pages/producto-publicado/listado-producto-publicado/listado-producto-publicado.component"
import { GestorProviderConfigurationComponent } from "../pages/provider-configuration/gestor-provider-configuration/gestor-provider-configuration.component"
import { ListadoProviderConfigurationComponent } from "../pages/provider-configuration/listado-provider-configuration/listado-provider-configuration.component"
import { GestorRecordatorioComponent } from "../pages/recordatorio/gestor-recordatorio/gestor-recordatorio.component"
import { ListadoRecordatorioComponent } from "../pages/recordatorio/listado-recordatorio/listado-recordatorio.component"
import { GestorRegionComponent } from "../pages/region/gestor-region/gestor-region.component"
import { ListadoRegionComponent } from "../pages/region/listado-region/listado-region.component"
import { ReportePrevisionServiciosComponent } from "../pages/reportes/reporte-prevision-servicios/reporte-prevision-servicios.component"
import { ReporteServiciosComponent } from "../pages/reportes/reporte-servicios/reporte-servicios.component"
import { GestorSubcategoriaImponibleComponent } from "../pages/subcategoria-imponible/gestor-subcategoria-imponible/gestor-subcategoria-imponible.component"
import { ListadoSubcategoriaImponibleComponent } from "../pages/subcategoria-imponible/listado-subcategoria-imponible/listado-subcategoria-imponible.component"
import { GestorTagProductoComponent } from "../pages/tag-producto/gestor-tag-producto/gestor-tag-producto.component"
import { ListadoTagProductoComponent } from "../pages/tag-producto/listado-tag-producto/listado-tag-producto.component"
import { GestorTagUsuarioComponent } from "../pages/tag-usuario/gestor-tag-usuario/gestor-tag-usuario.component"
import { ListadoTagUsuarioComponent } from "../pages/tag-usuario/listado-tag-usuario/listado-tag-usuario.component"
import { GestorTipoComprobanteComponent } from "../pages/tipo-comprobante/gestor-tipo-comprobante/gestor-tipo-comprobante.component"
import { ListadoTipoComprobanteComponent } from "../pages/tipo-comprobante/listado-tipo-comprobante/listado-tipo-comprobante.component"
import { GestorTipoIVAGrabadoComponent } from "../pages/tipo-iva-grabado/gestor-tipo-iva-grabado/gestor-tipo-iva-grabado.component"
import { GestorTipoVarianteComponent } from "../pages/tipo-variante/gestor-tipo-variante/gestor-tipo-variante.component"
import { ListadoTipoVarianteComponent } from "../pages/tipo-variante/listado-tipo-variante/listado-tipo-variante.component"
import { UnsavedChangesGuard } from "../path-filters/UnsavedChangesGuard"

const routes: Routes = [
	{ path: "help", loadChildren: () => import("./../pages/help/help.module").then((m) => m.HelpModule) },
	{ path: "comprobante", component: ListadoComprobanteComponent, canActivate: [ComprobantesAG] },

	{ path: "comprobante/edit", canDeactivate: [UnsavedChangesGuard], component: GestorComprobanteComponent, canActivate: [ComprobantesAG] },
	{ path: "comprobante/vista", component: GestorComprobanteComponent, canActivate: [EmpleadoAuthGuard], data: { vista: true } },
	{ path: "comprobante/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorComprobanteComponent, canActivate: [ComprobantesAG] },
	{
		path: "comprobante/clonar",
		canDeactivate: [UnsavedChangesGuard],
		component: GestorComprobanteComponent,
		canActivate: [ComprobantesAG],
		data: { clonar: true }
	},

	{ path: "cobranza", component: ListadoCobranzaComponent, canActivate: [CobranzasAG] },

	{ path: "cobranza/edit", canDeactivate: [UnsavedChangesGuard], component: GestorCobranzaComponent, canActivate: [CobranzasAG] },
	{ path: "cobranza/vista", component: GestorCobranzaComponent, canActivate: [EmpleadoAuthGuard], data: { vista: true } },
	{ path: "cobranza/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorCobranzaComponent, canActivate: [CobranzasAG] },

	{ path: "notificacion", component: ListadoNotificacionComponent, canActivate: [NotificacionAG] },

	//Reservas
	{ path: "reserva", component: ListadoReservaComponent, canActivate: [ReservasAG] },
	{ path: "reserva/edit", component: GestorReservaComponent, canActivate: [ReservasAG], canDeactivate: [UnsavedChangesGuard] },
	{ path: "reserva/vista", component: GestorReservaComponent, canActivate: [ClienteExternoAG], data: { vista: true } },
	{ path: "reserva/nuevo", component: GestorReservaComponent, canActivate: [ReservasAG], canDeactivate: [UnsavedChangesGuard] },
	{ path: "reserva/clonar", component: GestorReservaComponent, canActivate: [ReservasAG], data: { clonar: true }, canDeactivate: [UnsavedChangesGuard] },

	{ path: "cotizacion", component: ListadoReservaComponent, canActivate: [ReservasAG], data: { esCotizacion: true } },
	{
		path: "cotizacion/edit",
		canDeactivate: [UnsavedChangesGuard],
		component: GestorReservaComponent,
		canActivate: [ReservasAG],
		data: { esCotizacion: true }
	},
	{ path: "cotizacion/vista", component: GestorReservaComponent, canActivate: [ClienteExternoAG], data: { vista: true, esCotizacion: true } },
	{
		path: "cotizacion/nuevo",
		canDeactivate: [UnsavedChangesGuard],
		component: GestorReservaComponent,
		canActivate: [ReservasAG],
		data: { esCotizacion: true }
	},

	{ path: "actividades", component: ActividadesComponent, canActivate: [ActividadesAG] },
	{ path: "itinerario", component: ItinerarioComponent, canActivate: [ItinerarioAG] },

	//Usuarios
	{ path: "usuario", component: UserComponent, canActivate: [UsuarioAG] },
	{ path: "usuario/edit", canDeactivate: [UnsavedChangesGuard], component: GestionUserComponent, canActivate: [EmpleadoAuthGuard] },
	{ path: "usuario/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestionUserComponent, canActivate: [UsuarioAG] },
	//Vehiculos
	{ path: "vehiculo", component: ListadoVehiculosComponent, canActivate: [VehiculosAG] },
	{ path: "vehiculo/edit", canDeactivate: [UnsavedChangesGuard], component: GestorVehiculoComponent, canActivate: [VehiculosAG] },
	{ path: "vehiculo/vista", component: GestorVehiculoComponent, canActivate: [EmpleadoAuthGuard], data: { vista: true } },
	{ path: "vehiculo/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorVehiculoComponent, canActivate: [VehiculosAG] },
	//Cliente
	{ path: "cliente", component: ListadoClienteComponent, canActivate: [ClientesAG] },
	{ path: "cliente/edit", canDeactivate: [UnsavedChangesGuard], component: GestorClienteComponent, canActivate: [ClientesAG] },
	{ path: "cliente/vista", component: GestorClienteComponent, canActivate: [EmpleadoAuthGuard], data: { vista: true } },
	{ path: "cliente/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorClienteComponent, canActivate: [ClientesAG] },

	//INSUMO
	{ path: "insumo", component: ListadoInsumoComponent, canActivate: [FacturasAG] },
	{ path: "insumo/edit", canDeactivate: [UnsavedChangesGuard], component: GestorInsumoComponent, canActivate: [FacturasAG] },
	{ path: "insumo/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorInsumoComponent, canActivate: [FacturasAG] },
	{ path: "insumo/vista", component: GestorInsumoComponent, canActivate: [EmpleadoAuthGuard], data: { vista: true } },

	//Producto
	{ path: "producto", component: ListadoProductoComponent, canActivate: [ProductosAG] },
	{ path: "producto/edit", canDeactivate: [UnsavedChangesGuard], component: GestorProductoComponent, canActivate: [ProductosAG] },
	{ path: "producto/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorProductoComponent, canActivate: [ProductosAG] },
	{ path: "producto/vista", component: GestorProductoComponent, canActivate: [EmpleadoAuthGuard], data: { vista: true } },
	{ path: "producto/clonar", canDeactivate: [UnsavedChangesGuard], component: GestorProductoComponent, canActivate: [ProductosAG], data: { clonar: true } },

	//Producto
	{ path: "unidad", component: ListadoUnidadComponent, canActivate: [AdminAuthGuard] },
	{ path: "unidad/edit", canDeactivate: [UnsavedChangesGuard], component: GestorUnidadComponent, canActivate: [AdminAuthGuard] },
	{ path: "unidad/vista", component: GestorUnidadComponent, canActivate: [EmpleadoAuthGuard], data: { vista: true } },
	{ path: "unidad/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorUnidadComponent, canActivate: [AdminAuthGuard] },

	//Cuenta Contable
	{ path: "cuenta-contable", component: ListadoCuentaContableComponent, canActivate: [AdminAuthGuard] },
	{ path: "cuenta-contable/edit", canDeactivate: [UnsavedChangesGuard], component: GestorCuentaContableComponent, canActivate: [AdminAuthGuard] },
	{ path: "cuenta-contable/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorCuentaContableComponent, canActivate: [AdminAuthGuard] },
	{ path: "cuenta-contable/vista", component: GestorCuentaContableComponent, canActivate: [EmpleadoAuthGuard], data: { vista: true } },

	//Hotel
	{ path: "hotel", component: ListadoHotelComponent, canActivate: [HotelesAG] },
	{ path: "hotel/edit", canDeactivate: [UnsavedChangesGuard], component: GestorHotelComponent, canActivate: [HotelesAG] },
	{ path: "hotel/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorHotelComponent, canActivate: [HotelesAG] },
	{ path: "hotel/vista", component: GestorHotelComponent, canActivate: [EmpleadoAuthGuard], data: { vista: true, animation: "Home" } },
	//Factura
	{ path: "factura", component: ListadoFacturaComponent, canActivate: [FacturasAG] },
	{ path: "factura/edit", canDeactivate: [UnsavedChangesGuard], component: GestorFacturaComponent, canActivate: [FacturasAG] },
	{ path: "factura/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorFacturaComponent, canActivate: [FacturasAG] },
	{ path: "factura/vista", component: GestorFacturaComponent, canActivate: [VisualizadorDocsProveedorAG], data: { vista: true, animation: "Home" } },
	//Orden pago
	{ path: "orden-pago", component: ListadoOrdenPagoComponent, canActivate: [OrdenPagoAG] },
	{ path: "orden-pago/edit", canDeactivate: [UnsavedChangesGuard], component: GestorOrdenPagoComponent, canActivate: [OrdenPagoEdicionAG] },
	{ path: "orden-pago/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorOrdenPagoComponent, canActivate: [OrdenPagoEdicionAG] },
	{ path: "orden-pago/vista", component: GestorOrdenPagoComponent, canActivate: [VisualizadorDocsProveedorAG], data: { vista: true, animation: "Home" } },
	//Lista de precios
	{ path: "lista-precios", component: ListadoListaPreciosComponent, canActivate: [ListaPreciosAG] },
	{ path: "lista-precios/edit", canDeactivate: [UnsavedChangesGuard], component: EdicionListaPreciosComponent, canActivate: [ListaPreciosAG] },
	{ path: "lista-precios/nuevo", canDeactivate: [UnsavedChangesGuard], component: EdicionListaPreciosComponent, canActivate: [ListaPreciosAG] },
	{ path: "lista-precios/vista", component: EdicionListaPreciosComponent, canActivate: [ListaPreciosAG], data: { vista: true, animation: "Home" } },
	//Lista de precios
	{ path: "movimiento-caja", component: ListadoMovimientoCajaComponent, canActivate: [MovimientoCajaAG] },

	{ path: "cambio-estado-caja", component: ListadoArqueoCajaComponent, canActivate: [AdminAuthGuard] },
	{ path: "cambio-estado-caja/vista", component: GestorArqueoCajaComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "cambio-saldo-caja", component: ListadoCambioSaldoCajaComponent, canActivate: [AdminAuthGuard] },
	{ path: "cambio-saldo-caja/vista", component: GestorCambioSaldoCajaComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	//Proveedores
	{ path: "proveedor", component: ListadoProveedorComponent, canActivate: [ListaProveedorAG] },
	{
		path: "proveedor/edit",
		canDeactivate: [UnsavedChangesGuard],
		component: GestorProveedorComponent,
		canActivate: [ProveedoresAG],
		data: { animation: "About" }
	},
	{ path: "proveedor/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorProveedorComponent, canActivate: [ProveedoresAG] },
	{ path: "proveedor/vista", component: GestorProveedorComponent, canActivate: [VisualizadorProveedorAG], data: { vista: true } },

	{ path: "calendario", component: CalendarioComponent, canActivate: [EmpleadoAuthGuard] },

	//Idioma
	{ path: "idioma", component: ListadoIdiomaComponent, canActivate: [AdminAuthGuard] },
	{ path: "idioma/edit", canDeactivate: [UnsavedChangesGuard], component: GestorIdiomaComponent, canActivate: [AdminAuthGuard] },
	{ path: "idioma/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorIdiomaComponent, canActivate: [AdminAuthGuard] },
	{ path: "idioma/vista", component: GestorIdiomaComponent, canActivate: [AdminAuthGuard], data: { vista: true } },

	//Vendedor
	// { path: 'vendedor', component: ListadoVendedorComponent, canActivate: [AdminAuthGuard] },
	// { path: 'vendedor/edit', component: GestorVendedorComponent, canActivate: [AdminAuthGuard] },
	// { path: 'vendedor/vista', component: GestorVendedorComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	// { path: 'vendedor/nuevo', component: GestorVendedorComponent, canActivate: [AdminAuthGuard] },

	{ path: "vendedor", component: ListadoProveedorComponent, canActivate: [VendedoresAG], data: { vendedor: true } },
	{
		path: "vendedor/edit",
		canDeactivate: [UnsavedChangesGuard],
		component: GestorProveedorComponent,
		canActivate: [VendedoresAG],
		data: { vendedor: true, animation: "About" }
	},
	{
		path: "vendedor/nuevo",
		canDeactivate: [UnsavedChangesGuard],
		component: GestorProveedorComponent,
		canActivate: [VendedoresAG],
		data: { vendedor: true }
	},
	{ path: "vendedor/vista", component: GestorProveedorComponent, canActivate: [EmpleadoAuthGuard], data: { vendedor: true, vista: true } },

	//Paramtros
	{ path: "parametros", component: GenericParametricoComponent, canActivate: [AdminAuthGuard] },
	{ path: "parametros/edit", canDeactivate: [UnsavedChangesGuard], component: GestorParametricoComponent, canActivate: [AdminAuthGuard] },
	{ path: "parametros/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorParametricoComponent, canActivate: [AdminAuthGuard] },

	{ path: "parametros/nacionalidad", component: ListadoNacionalidadComponent, canActivate: [NacionalidadAG] },
	{ path: "parametros/nacionalidad/edit", canDeactivate: [UnsavedChangesGuard], component: GestorNacionalidadComponent, canActivate: [NacionalidadAG] },
	{ path: "parametros/nacionalidad/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorNacionalidadComponent, canActivate: [NacionalidadAG] },

	{ path: "parametros/tipo-puesto", component: ListadoTipoPuestoComponent, canActivate: [AdministracionAuthGuard] },
	{ path: "parametros/tipo-puesto/edit", canDeactivate: [UnsavedChangesGuard], component: GestorTipoPuestoComponent, canActivate: [AdministracionAuthGuard] },
	{
		path: "parametros/tipo-puesto/nuevo",
		canDeactivate: [UnsavedChangesGuard],
		component: GestorTipoPuestoComponent,
		canActivate: [AdministracionAuthGuard]
	},

	{ path: "estado-reserva", component: ListadoEstadoReservaComponent, canActivate: [AdminAuthGuard] },
	{ path: "estado-reserva/edit", canDeactivate: [UnsavedChangesGuard], component: GestorEstadoReservaComponent, canActivate: [AdminAuthGuard] },
	{ path: "estado-reserva/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorEstadoReservaComponent, canActivate: [AdminAuthGuard] },
	{ path: "estado-reserva/vista", component: GestorEstadoReservaComponent, canActivate: [AdminAuthGuard], data: { vista: true } },

	{ path: "parametricos", component: ParametricosComponent, canActivate: [AdminAuthGuard] },
	{ path: "emailtemplate", component: ListaEmailTemplatesComponent, canActivate: [GestorPlantillasAG] },
	{ path: "emailtemplate/edit", canDeactivate: [UnsavedChangesGuard], component: GestorEmailTemplateComponent, canActivate: [GestorPlantillasAG] },
	{ path: "emailtemplate/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorEmailTemplateComponent, canActivate: [GestorPlantillasAG] },
	{ path: "emailtemplate/vista", component: GestorEmailTemplateComponent, canActivate: [EmpleadoAuthGuard], data: { vista: true } },

	{ path: "centro-costo", component: ListadoCentroCostoComponent, canActivate: [AdministracionAuthGuard] },
	{ path: "centro-costo/edit", canDeactivate: [UnsavedChangesGuard], component: GestorCentroCostoComponent, canActivate: [AdministracionAuthGuard] },
	{ path: "centro-costo/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorCentroCostoComponent, canActivate: [AdministracionAuthGuard] },
	{ path: "centro-costo/vista", component: GestorCentroCostoComponent, canActivate: [AdministracionAuthGuard], data: { vista: true } },

	{ path: "caja", component: ListadoCajaComponent, canActivate: [AdministracionAuthGuard] },
	{ path: "caja/edit", canDeactivate: [UnsavedChangesGuard], component: GestorCajaComponent, canActivate: [AdministracionAuthGuard] },
	{ path: "caja/vista", component: GestorCajaComponent, canActivate: [AdministracionAuthGuard], data: { vista: true } },
	{ path: "caja/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorCajaComponent, canActivate: [AdministracionAuthGuard] },
	{ path: "estado-documento", component: ListadoEstadoDocumentoComponent, canActivate: [AdminAuthGuard] },

	{ path: "estado-documento/edit", canDeactivate: [UnsavedChangesGuard], component: GestorEstadoDocumentoComponent, canActivate: [AdminAuthGuard] },
	{ path: "estado-documento/vista", component: GestorEstadoDocumentoComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "estado-documento/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorEstadoDocumentoComponent, canActivate: [AdminAuthGuard] },
	{ path: "moneda", component: ListadoMonedaComponent, canActivate: [AdminAuthGuard] },

	{ path: "moneda/edit", canDeactivate: [UnsavedChangesGuard], component: GestorMonedaComponent, canActivate: [AdminAuthGuard] },
	{ path: "moneda/vista", component: GestorMonedaComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "moneda/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorMonedaComponent, canActivate: [AdminAuthGuard] },
	{ path: "categoria-producto", component: ListadoCategoriaProductoComponent, canActivate: [AdminAuthGuard] },

	{
		path: "categoria-producto/edit",
		canDeactivate: [UnsavedChangesGuard],
		component: GestorCategoriaProductoComponent,
		canActivate: [AdministracionAuthGuard]
	},
	{ path: "categoria-producto/vista", component: GestorCategoriaProductoComponent, canActivate: [AdministracionAuthGuard], data: { vista: true } },
	{
		path: "categoria-producto/nuevo",
		canDeactivate: [UnsavedChangesGuard],
		component: GestorCategoriaProductoComponent,
		canActivate: [AdministracionAuthGuard]
	},
	{ path: "restriccion", component: ListadoRestriccionComponent, canActivate: [AdministracionAuthGuard] },

	{ path: "restriccion/edit", canDeactivate: [UnsavedChangesGuard], component: GestorRestriccionComponent, canActivate: [AdministracionAuthGuard] },
	{ path: "restriccion/vista", component: GestorRestriccionComponent, canActivate: [AdministracionAuthGuard], data: { vista: true } },
	{ path: "restriccion/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorRestriccionComponent, canActivate: [AdministracionAuthGuard] },

	{ path: "reportes/ranking-productos", component: RankinProductoComponent, canActivate: [ReporteAG] },
	{ path: "reportes/ranking-compras", component: RankinComprasComponent, canActivate: [ReporteAG] },
	{ path: "reportes/ventas", component: VentasComponent, canActivate: [ReportesVentasAG] },
	{ path: "reportes/compras", component: ComprasComponent, canActivate: [ReportesComprasAG] },
	{ path: "reportes/consentimiento", component: ReporteConsentimientoComponent, canActivate: [ReservasAG] },
	{ path: "reportes/servicios", component: ReporteServiciosComponent, canActivate: [ReservasAG] },
	{ path: "reportes/estadisticas-actividades", component: EstadisticasActividadesComponent, canActivate: [ReporteAG] },
	{ path: "reportes/margen-reporte", component: ListadoMargenReporteComponent, canActivate: [ReporteAG] },
	{ path: "reportes/encuestas", component: ReporteEncuestasComponent, canActivate: [ReporteAG] },
	{ path: "reportes/ingresos-egresos", component: ReporteIngresosEgresosComponent, canActivate: [ReporteAG] },
	{ path: "reportes/totales-forma-pago", component: TotalesFormaPagoComponent, canActivate: [ReporteAG] },
	{ path: "reportes/prevision-reservas", component: ReportePrevisionServiciosComponent, canActivate: [ReporteAG] },

	{ path: "margen-reporte/vista", component: VistaMargenReporteComponent, canActivate: [ReporteAG] },

	{ path: "forma-pago", component: ListadoFormaPagoComponent, canActivate: [AdminAuthGuard] },
	{ path: "forma-pago/edit", canDeactivate: [UnsavedChangesGuard], component: GestorFormaPagoComponent, canActivate: [AdminAuthGuard] },
	{ path: "forma-pago/vista", component: GestorFormaPagoComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "forma-pago/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorFormaPagoComponent, canActivate: [AdminAuthGuard] },

	{ path: "nota", component: ListadoNotaComponent, canActivate: [AdminAuthGuard] },

	{ path: "nota/edit", canDeactivate: [UnsavedChangesGuard], component: GestorNotaComponent, canActivate: [AdministracionAuthGuard] },
	{ path: "nota/vista", component: GestorNotaComponent, canActivate: [VisualizadorAuthGuard], data: { vista: true } },
	{ path: "nota/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorNotaComponent, canActivate: [AdministracionAuthGuard] },
	{ path: "bloqueo-vehiculo", component: ListadoBloqueoVehiculoComponent, canActivate: [VisualizadorAuthGuard] },

	{ path: "bloqueo-vehiculo/edit", canDeactivate: [UnsavedChangesGuard], component: GestorBloqueoVehiculoComponent, canActivate: [AdminAuthGuard] },
	{ path: "bloqueo-vehiculo/vista", component: GestorBloqueoVehiculoComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "bloqueo-vehiculo/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorBloqueoVehiculoComponent, canActivate: [AdminAuthGuard] },
	{ path: "tipo-tarjeta-credito", component: ListadoTipoTarjetaCreditoComponent, canActivate: [AdminAuthGuard] },

	{ path: "tipo-tarjeta-credito/edit", canDeactivate: [UnsavedChangesGuard], component: GestorTipoTarjetaCreditoComponent, canActivate: [AdminAuthGuard] },
	{ path: "tipo-tarjeta-credito/vista", component: GestorTipoTarjetaCreditoComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "tipo-tarjeta-credito/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorTipoTarjetaCreditoComponent, canActivate: [AdminAuthGuard] },
	{ path: "tipo-cuotas-tarjeta", component: ListadoTipoCuotasTarjetaComponent, canActivate: [AdminAuthGuard] },

	{ path: "tipo-cuotas-tarjeta/edit", canDeactivate: [UnsavedChangesGuard], component: GestorTipoCuotasTarjetaComponent, canActivate: [AdminAuthGuard] },
	{ path: "tipo-cuotas-tarjeta/vista", component: GestorTipoCuotasTarjetaComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "tipo-cuotas-tarjeta/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorTipoCuotasTarjetaComponent, canActivate: [AdminAuthGuard] },
	{ path: "canal-comunicacion", component: ListadoCanalComunicacionComponent, canActivate: [AdminAuthGuard] },

	{ path: "canal-comunicacion/edit", canDeactivate: [UnsavedChangesGuard], component: GestorCanalComunicacionComponent, canActivate: [AdminAuthGuard] },
	{ path: "canal-comunicacion/vista", component: GestorCanalComunicacionComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "canal-comunicacion/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorCanalComunicacionComponent, canActivate: [AdminAuthGuard] },
	{ path: "tipo-notificacion", component: ListadoTipoNotificacionComponent, canActivate: [AdminAuthGuard] },

	{ path: "tipo-notificacion/edit", canDeactivate: [UnsavedChangesGuard], component: GestorTipoNotificacionComponent, canActivate: [AdminAuthGuard] },
	{ path: "tipo-notificacion/vista", component: GestorTipoNotificacionComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "tipo-notificacion/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorTipoNotificacionComponent, canActivate: [AdminAuthGuard] },
	{ path: "tipo-ausencia", component: ListadoTipoAusenciaComponent, canActivate: [AdminAuthGuard] },

	{ path: "tipo-ausencia/edit", canDeactivate: [UnsavedChangesGuard], component: GestorTipoAusenciaComponent, canActivate: [AdminAuthGuard] },
	{ path: "tipo-ausencia/vista", component: GestorTipoAusenciaComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "tipo-ausencia/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorTipoAusenciaComponent, canActivate: [AdminAuthGuard] },
	{ path: "punto-de-venta", component: ListadoPuntoDeVentaComponent, canActivate: [PuntoDeVentaAG] },

	{ path: "punto-de-venta/edit", canDeactivate: [UnsavedChangesGuard], component: GestorPuntoDeVentaComponent, canActivate: [PuntoDeVentaAG] },
	{ path: "punto-de-venta/vista", component: GestorPuntoDeVentaComponent, canActivate: [PuntoDeVentaAG], data: { vista: true } },
	{ path: "punto-de-venta/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorPuntoDeVentaComponent, canActivate: [PuntoDeVentaAG] },

	{ path: "plantilla-pregunta", component: ListadoPlantillaPreguntaComponent, canActivate: [AdminAuthGuard] },

	{ path: "plantilla-pregunta/edit", canDeactivate: [UnsavedChangesGuard], component: GestorPlantillaPreguntaComponent, canActivate: [AdminAuthGuard] },
	{ path: "plantilla-pregunta/vista", component: GestorPlantillaPreguntaComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "plantilla-pregunta/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorPlantillaPreguntaComponent, canActivate: [AdminAuthGuard] },
	{ path: "encuesta-actividades", component: ListadoEncuestaActividadesComponent, canActivate: [AdminAuthGuard] },

	{ path: "encuesta-actividades/edit", canDeactivate: [UnsavedChangesGuard], component: GestorEncuestaActividadesComponent, canActivate: [AdminAuthGuard] },
	{ path: "encuesta-actividades/vista", component: GestorEncuestaActividadesComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "encuesta-actividades/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorEncuestaActividadesComponent, canActivate: [AdminAuthGuard] },
	{ path: "respuesta-encuesta-actividades", component: ListadoRespuestaEncuestaActividadesComponent, canActivate: [EncuestasAG] },
	{ path: "respuesta-encuesta-actividades/vista", component: GestorRespuestaEncuestaActividadesComponent, canActivate: [EncuestasAG], data: { vista: true } },

	{ path: "job-configuration", component: ListadoJobConfigurationComponent, canActivate: [AdminAuthGuard] },
	{ path: "job-configuration/edit", canDeactivate: [UnsavedChangesGuard], component: GestorJobConfigurationComponent, canActivate: [AdminAuthGuard] },
	{ path: "job-configuration/vista", component: GestorJobConfigurationComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "job-configuration/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorJobConfigurationComponent, canActivate: [AdminAuthGuard] },

	{ path: "familia-producto", component: ListadoFamiliaProductoComponent, canActivate: [ProductosAG] },
	{ path: "familia-producto/edit", canDeactivate: [UnsavedChangesGuard], component: GestorFamiliaProductoComponent, canActivate: [ProductosAG] },
	{ path: "familia-producto/vista", component: GestorFamiliaProductoComponent, canActivate: [ProductosAG], data: { vista: true } },
	{ path: "familia-producto/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorFamiliaProductoComponent, canActivate: [ProductosAG] },
	{ path: "tipo-variante", component: ListadoTipoVarianteComponent, canActivate: [AdminAuthGuard] },

	{ path: "tipo-variante/edit", canDeactivate: [UnsavedChangesGuard], component: GestorTipoVarianteComponent, canActivate: [AdminAuthGuard] },
	{ path: "tipo-variante/vista", component: GestorTipoVarianteComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "tipo-variante/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorTipoVarianteComponent, canActivate: [AdminAuthGuard] },
	{ path: "configuracion-entorno", component: ListadoConfiguracionEntornoComponent, canActivate: [AdminAuthGuard] },

	{ path: "configuracion-entorno/edit", canDeactivate: [UnsavedChangesGuard], component: GestorConfiguracionEntornoComponent, canActivate: [AdminAuthGuard] },
	{ path: "configuracion-entorno/vista", component: GestorConfiguracionEntornoComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{
		path: "configuracion-entorno/nuevo",
		canDeactivate: [UnsavedChangesGuard],
		component: GestorConfiguracionEntornoComponent,
		canActivate: [AdminAuthGuard]
	},
	{ path: "recordatorio", component: ListadoRecordatorioComponent, canActivate: [AdminAuthGuard] },

	{ path: "recordatorio/edit", canDeactivate: [UnsavedChangesGuard], component: GestorRecordatorioComponent, canActivate: [AdminAuthGuard] },
	{ path: "recordatorio/vista", component: GestorRecordatorioComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "recordatorio/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorRecordatorioComponent, canActivate: [AdminAuthGuard] },
	{ path: "tag-usuario", component: ListadoTagUsuarioComponent, canActivate: [AdminAuthGuard] },

	{ path: "tag-usuario/edit", canDeactivate: [UnsavedChangesGuard], component: GestorTagUsuarioComponent, canActivate: [AdminAuthGuard] },
	{ path: "tag-usuario/vista", component: GestorTagUsuarioComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "tag-usuario/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorTagUsuarioComponent, canActivate: [AdminAuthGuard] },
	{ path: "tipo-comprobante", component: ListadoTipoComprobanteComponent, canActivate: [AdminAuthGuard] },

	//IVA GRABADO
	{ path: "tipo-iva-grabado", component: GestorTipoIVAGrabadoComponent, canActivate: [AdminAuthGuard] },

	{ path: "tipo-comprobante/edit", canDeactivate: [UnsavedChangesGuard], component: GestorTipoComprobanteComponent, canActivate: [AdminAuthGuard] },
	{ path: "tipo-comprobante/vista", component: GestorTipoComprobanteComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "tipo-comprobante/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorTipoComprobanteComponent, canActivate: [AdminAuthGuard] },

	{ path: "provider-configuration", component: ListadoProviderConfigurationComponent, canActivate: [AdminAuthGuard] },
	{
		path: "provider-configuration/edit",
		canDeactivate: [UnsavedChangesGuard],
		component: GestorProviderConfigurationComponent,
		canActivate: [AdminAuthGuard]
	},
	{ path: "provider-configuration/vista", component: GestorProviderConfigurationComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{
		path: "provider-configuration/nuevo",
		canDeactivate: [UnsavedChangesGuard],
		component: GestorProviderConfigurationComponent,
		canActivate: [AdminAuthGuard]
	},

	{ path: "subcategoria-imponible", component: ListadoSubcategoriaImponibleComponent, canActivate: [AdminAuthGuard] },

	{
		path: "subcategoria-imponible/edit",
		canDeactivate: [UnsavedChangesGuard],
		component: GestorSubcategoriaImponibleComponent,
		canActivate: [AdminAuthGuard]
	},
	{ path: "subcategoria-imponible/vista", component: GestorSubcategoriaImponibleComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{
		path: "subcategoria-imponible/nuevo",
		canDeactivate: [UnsavedChangesGuard],
		component: GestorSubcategoriaImponibleComponent,
		canActivate: [AdminAuthGuard]
	},
	{ path: "producto-publicado", component: ListadoProductoPublicadoComponent, canActivate: [AdminAuthGuard] },

	{ path: "producto-publicado/edit", canDeactivate: [UnsavedChangesGuard], component: GestorProductoPublicadoComponent, canActivate: [AdminAuthGuard] },
	{ path: "producto-publicado/vista", component: GestorProductoPublicadoComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "producto-publicado/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorProductoPublicadoComponent, canActivate: [AdminAuthGuard] },
	{ path: "producto-publicado/autogenerar", canDeactivate: [UnsavedChangesGuard], component: GestorProductoPublicadoComponent, canActivate: [ProductosAG], data: { autogenerar: true } },


	{ path: "inclusion", component: ListadoInclusionComponent, canActivate: [AdminAuthGuard] },
	{ path: "inclusion/edit", canDeactivate: [UnsavedChangesGuard], component: GestorInclusionComponent, canActivate: [AdminAuthGuard] },
	{ path: "inclusion/vista", component: GestorInclusionComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "inclusion/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorInclusionComponent, canActivate: [AdminAuthGuard] },

	{ path: "tag-producto", component: ListadoTagProductoComponent, canActivate: [AdminAuthGuard] },
	{ path: "tag-producto/edit", canDeactivate: [UnsavedChangesGuard], component: GestorTagProductoComponent, canActivate: [AdminAuthGuard] },
	{ path: "tag-producto/vista", component: GestorTagProductoComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "tag-producto/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorTagProductoComponent, canActivate: [AdminAuthGuard] },

	{ path: "pais", component: ListadoPaisComponent, canActivate: [AdminAuthGuard] },
	{ path: "pais/edit", canDeactivate: [UnsavedChangesGuard], component: GestorPaisComponent, canActivate: [AdminAuthGuard] },
	{ path: "pais/vista", component: GestorPaisComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "pais/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorPaisComponent, canActivate: [AdminAuthGuard] },
	{ path: "categoria-hospedaje", component: ListadoCategoriaHospedajeComponent, canActivate: [AdminAuthGuard] },

	{ path: "categoria-hospedaje/edit", component: GestorCategoriaHospedajeComponent, canActivate: [AdminAuthGuard] },
	{ path: "categoria-hospedaje/vista", component: GestorCategoriaHospedajeComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "categoria-hospedaje/nuevo", component: GestorCategoriaHospedajeComponent, canActivate: [AdminAuthGuard] },
	//$NEWROUTES$
	{ path: "region", component: ListadoRegionComponent, canActivate: [AdminAuthGuard] },
	{ path: "region/edit", canDeactivate: [UnsavedChangesGuard], component: GestorRegionComponent, canActivate: [AdminAuthGuard] },
	{ path: "region/vista", component: GestorRegionComponent, canActivate: [AdminAuthGuard], data: { vista: true } },
	{ path: "region/nuevo", canDeactivate: [UnsavedChangesGuard], component: GestorRegionComponent, canActivate: [AdminAuthGuard] },

	{ path: "consentimiento", component: ListadoConsentimientoComponent, canActivate: [ReservasAG] },
	{ path: "consentimiento/vista", component: GestorConsentimientoComponent, canActivate: [ReservasAG], data: { vista: true } },

	{ path: "carga-masiva", component: ListadoCargaMasivaComponent, canActivate: [AdministracionAuthGuard] }
]
@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: "corrected" })],
	exports: [RouterModule]
})
export class ImplementacionAppRoutingModule { }
