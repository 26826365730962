import { Parametrico } from "src/app/common/model/Parametrico"

export class TipoIVAGrabado extends Parametrico {
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado: boolean = true,
		esSistema: boolean = false,
		peso: number = 99,
		public valorIva?: number,
		esDefault: boolean = false
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
	}
	public static readonly ZERO: TipoIVAGrabado = new TipoIVAGrabado(null, "ZERO", "0")
	public tipoParametro(): string {
		return "TipoIVAGrabadoLOW"
	}
	public static fromData(data: any): TipoIVAGrabado {
		if (!data) return null
		const o: TipoIVAGrabado = new TipoIVAGrabado(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			data.esSistema,
			data.peso,
			data.valorIva,
			data.esDefault
		)

		o.version = data.version
		return o
	}
}
