<div class="listado-comprobante content">
	<model-list
		[service]="service"
		[modelName]="'comprobante'"
		[columns]="columns"
		[title]="'COMPROBANTES' | translate"
		[(listado)]="listado"
		[isLazy]="true"
		[filtro]="filtro"
		[multilenguaje]="false"
		[acciones]="acciones"
		[filterContent]="filterContent"
		[hideHeader]="modoSeleccion == 'single'"
		[modoSeleccion]="modoSeleccion"
		[menuItemAcciones]=""
		[getData]="getData"
		[getCount]="getCount"
		[editable]="modoSeleccion != 'single'"
		(seleccionChange)="updateSeleccion($event)"
		[readonly]="readonly"
		[footer]="!skipTotales ? footerTemplate : null"
	>
		<ng-template #filterContent>
			<div class="grid">
				<div class="col-12">
					<div class="grid">
						<div class="col-12">
							<div class="p-inputgroup">
								<span class="p-float-label">
									<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
									<label>{{ "BUSCAR" | translate }}...</label>
								</span>
								<button type="button" pButton icon="pi pi-search"></button>
							</div>
						</div>
						<div class="col-12" *ngIf="opcionesPunto?.length > 1">
							<descriptivo-material-selector
								[label]="'PUNTO_VENTA' | translate"
								[limpiable]="true"
								[(ngModel)]="filtro.puntoDeVenta"
								isLazy="true"
								name="puntoDeVenta"
								[options]="opcionesPunto"
								[autohide]="true"
							>
							</descriptivo-material-selector>
						</div>

						<div class="col-12">
							<descriptivo-material-selector
								[label]="'CLIENTE' | translate"
								[limpiable]="true"
								[(ngModel)]="filtro.cliente"
								name="cliente"
								isLazy="true"
								[service]="clienteService"
							>
							</descriptivo-material-selector>
						</div>
						<div class="col-12 pb-0">
							<div class="grid">
								<div class="col-6">
									<span class="p-float-label">
										<p-calendar
											[(ngModel)]="filtro.fechaDesde"
											appendTo="body"
											dateFormat="dd/mm/yy"
											[showIcon]="true"
											[required]="true"
										></p-calendar>
										<label>{{ "FECHA_DESDE" | translate }}</label>
										<error-tag [model]="fechaDesde"></error-tag>
									</span>
								</div>
								<div class="col-6">
									<span class="p-float-label">
										<p-calendar [(ngModel)]="filtro.fechaHasta" appendTo="body" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
										<label>{{ "HASTA" | translate }}</label>
										<error-tag [model]="fechaHasta"></error-tag>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12">
					<selector-multiple-chips
						[(ngModel)]="filtro.tiposComprobante"
						name="tiposcomp"
						[servicio]="tipoComprobanteService"
						[permiteNuevo]="false"
						[limpiable]="true"
						[titulo]="'Tipos de comprobante' | translate"
						[maxItems]="3"
					>
					</selector-multiple-chips>
				</div>

				<div class="col-12 botonera">
					<span class="p-float-label p-field">
						<div class="grid p-inputwrapper-filled mt-3">
							<div class="col text-center" *ngFor="let estado of estadosService.data | async">
								<button
									pButton
									[label]="estado?.descripcion"
									[ngClass]="{ 'no-seleccionado': !filtro?.tieneEstado(estado) }"
									[ngStyle]="{ 'border-bottom-color': estado?.color, 'background-color': estado?.color }"
									(click)="filtro.toggleEstado(estado)"
								></button>
							</div>
							<div class="col text-center">
								<button
									[ngClass]="{ 'no-seleccionado': !filtro.soloImpagas }"
									pButton
									[label]="'SOLO_IMPAGOS' | translate"
									class="solo-impagas"
									(click)="filtro.soloImpagas = !filtro.soloImpagas"
								></button>
							</div>
						</div>
						<label class="mt-1">{{ "Estados" | translate }}</label>
					</span>
				</div>
			</div>
		</ng-template>
		<ng-template #footerTemplate>
			<tr class="totales">
				<td colspan="6">
					<div class="grid align-content-center text-right">
						<div class="col-12">
							<b>{{ (totales?.totalesPorMoneda?.length > 1 ? "TOTALES" : "TOTAL") | translate }}</b>
						</div>
					</div>
				</td>
				<td class="text-right total">
					<div class="grid align-content-center grid-nogutter">
						<div class="col-12 text-right" *ngFor="let tot of totales.totalesPorMoneda">
							<b>{{ tot?.moneda?.descripcion }}</b>
						</div>
					</div>
				</td>
				<td class="text-right total">
					<div class="grid align-content-center grid-nogutter">
						<div class="col-12 text-right" *ngFor="let tot of totales.totalesPorMoneda">
							<b>{{ tot?.totalConsumido | currency : "USD" : tot?.moneda?.simbolo + " " || "$ " }}</b>
						</div>
					</div>
				</td>
				<td class="text-right total">
					<div class="grid align-content-center grid-nogutter">
						<div class="col-12 text-right" *ngFor="let tot of totales.totalesPorMoneda">
							<b>{{ tot?.totalSaldo | currency : "USD" : tot?.moneda?.simbolo + " " || "$ " }}</b>
						</div>
					</div>
				</td>
				<td></td>
			</tr>
		</ng-template>
	</model-list>
</div>
<ng-template #estado let-data let-col="col">
	<span
		class="estado-id"
		[ngStyle]="{ 'border-left-color': data.estado?.color }"
		tooltipPosition="bottom"
		pTooltip="{{ 'N° ' + data.id + ' - ' + data?.estado?.descripcion }}"
		>{{ data.id }}
	</span>
</ng-template>

<ng-template #reserva let-data let-col="col">
	<span class="clickeable chip reserva" *ngIf="data?.reserva" (click)="verReserva(data?.reserva)">{{ data?.reserva?.id }}</span>
	<span class="overflow" *ngIf="!data?.reserva">{{ data?.descripcion }}</span>
</ng-template>

<p-dialog [(visible)]="verReenviarComprobante" [modal]="true" [style]="{ width: '60vw' }" header="{{ 'Enviar comprobante' | translate }}">
	<form #MailForm="ngForm" id="MailForm">
		<selector-email [(ngModel)]="destinatario" name="d" #d="ngModel"></selector-email>
		<p-toggleButton
			*ngIf="integrable"
			onLabel="{{ 'INTEGRATE' | translate }}"
			offLabel="{{ 'INTEGRATE' | translate }}"
			iconPos="left"
			name="integrar"
			onIcon="{{ 'fa fa-check-square-o' }} "
			offIcon="{{ 'fa fa-square-o' }} "
			[(ngModel)]="integrar"
			[disabled]="readonly"
			[pTooltip]="'INTEGRATE_TOOLTIP' | translate"
		></p-toggleButton>
	</form>
	<p-footer>
		<button pButton (click)="verReenviarComprobante = false" label="{{ 'CANCELAR' | translate }}"></button>
		<button pButton cclass="p-btn-success" (click)="enviarComprobante()" label="{{ 'ENVIAR' | translate }}" [disabled]="MailForm.invalid"></button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="verReenviarComprobanteMultiple" [modal]="true" [style]="{ width: '30vw' }" header="{{ 'Enviar comprobantes' | translate }}">
	<form #MailFormMultiple="ngForm" id="MailFormMultiple">
		<div class="grid">
			<div class="col-12 field p-fluid">
				<parametrico-selector
					[listado]="emailOpciones"
					#emailOpcion="ngModel"
					[(ngModel)]="destinatarioMultiple"
					[limpiable]="true"
					[permiteNuevo]="false"
					name="destinatarioMultiple"
					[titulo]="'TIPO_DE_EMAIL' | translate"
					class="p-inputwrapper-filled"
					[required]="true"
				>
				</parametrico-selector>
			</div>
			<div class="col-12 field p-fluid" *ngIf="destinatarioMultiple?.codigo == 'P'">
				<selector-email [(ngModel)]="destinatarioMultipleParticular" name="dMultiple" #dMultiple="ngModel"></selector-email>
			</div>
		</div>
	</form>
	<p-footer>
		<button pButton (click)="verReenviarComprobanteMultiple = false" label="{{ 'CANCELAR' | translate }}"></button>
		<button
			pButton
			cclass="p-btn-success"
			(click)="enviarComprobanteMutiple()"
			label="{{ 'ENVIAR' | translate }}"
			[disabled]="MailFormMultiple.invalid"
		></button>
	</p-footer>
</p-dialog>
