<div class="layout-container">
	<div class="layout-wrapper">
		<logo-topbar></logo-topbar>
		<div class="contrato content content-white" *ngIf="tipoVoucher === 'G' || tipoVoucher === 'F'">
			<actividades-card *ngIf="grupo" [actividad]="grupo" [esVoucher]="!voucherInterno"></actividades-card>
		</div>
		<div class="contrato content content-white" *ngIf="tipoVoucher === 'H'">
			<hospedaje-card *ngIf="itemConsentimiento" [itemReserva]="grupo" [esVoucher]="!voucherInterno"></hospedaje-card>
		</div>
		<div class="error" *ngIf="tipoVoucher === 'E'">
			<p>{{ "ERROR_VOUCHER" | translate }}</p>
		</div>
	</div>
</div>
<public-footer></public-footer>
