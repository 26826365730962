import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { SubcategoriaImponible } from "src/app/model/SubcategoriaImponible"
import { SubcategoriaImponibleItem } from "src/app/model/SubcategoriaImponibleItem"
import { SubcategoriaImponibleService } from "src/app/services/subcategoria-imponible.service"
import { TipoIVAGrabadoService } from "src/app/services/tipo-iva-grabado.service"
import { IConSubcategoriasImponibles } from "./IConSubcategoriasImponibles"

@Component({
	selector: "abm-subcategorias-imponibles",
	templateUrl: "abm-subcategorias-imponibles.component.html"
})
export class AbmSubcategoriasImponiblesComponent implements OnInit, OnDestroy {
	public subcategorias: SubcategoriaImponible[] = []
	@Input()
	public readonly: boolean = false
	@Input()
	public item: IConSubcategoriasImponibles
	private _sub: Subscription
	constructor(public tipoIvaGrabadoService: TipoIVAGrabadoService, public subcategoriasService: SubcategoriaImponibleService) {}
	ngOnInit() {
		this._sub = this.subcategoriasService.data.subscribe((r) => {
			this.subcategorias = r
		})
	}
	ngOnDestroy(): void {
		this._sub && this._sub.unsubscribe()
	}
	actualizarTipoIva(seleccionado: SubcategoriaImponible, item: SubcategoriaImponibleItem) {
		if (!item?.tipoIVA) {
			item.tipoIVA = seleccionado.tipoIVA
		}
	}
	newItem = (item) => {
		return () => item.newItem()
	}
}
