import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { Subscription } from "rxjs"
import { Idioma } from "src/app/common/model/Idioma"
import { IdiomaService } from "src/app/common/services/idioma.service"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { SessionComponent } from "./../../../common/components/session-component.component"
import { ProfilePic } from "./../../../common/model/ProfilePic"
@Component({
	selector: "gestor-idioma",
	templateUrl: "gestor-idioma.component.html",
	styleUrls: ["gestor-idioma.component.less"]
})
export class GestorIdiomaComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	@Input()
	public item: Idioma = new Idioma()
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false
	@Input()
	public readonly: boolean = false

	public url: string

	@Output()
	public onGuardado: EventEmitter<Idioma> = new EventEmitter<Idioma>()

	@Output()
	public onCancelado = new EventEmitter()

	public file: File
	public onPicSelected(file: File) {
		const reader = new FileReader()
		let $this = this
		if (file.size > 1000000) {
			return this.error(this.translateService.get("EL_ARCHIVO_NO_PUEDE_16"))
		}
		this.file = file
		reader.onload = (e: any) => {
			$this.item.bandera = new ProfilePic(null, "")
			this.url = e.target.result
		}
		reader.readAsDataURL(file)
	}

	constructor(messagesService: MessagesService, public service: IdiomaService, private route: ActivatedRoute, private confService: ConfirmationService) {
		super(messagesService)
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}

	public isValid() {
		return true
	}
	ngOnInit() {
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
				}
			})
		)
		if (this.route.snapshot.url.some((u) => u.path == "idioma")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				if (!this.service) return
				if (id) {
					this.service.getById(id).then((r) => {
						this.item = r
						this.url = r.bandera.picPathVersion
					})
				} else {
					this.item = this.service ? this.service.newEnt() : null
				}
			})
		}
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}
}
