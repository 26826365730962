import { Proveedor } from '../model/Proveedor';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noHabitual'
})
export class NoHabitualPipe implements PipeTransform {

  transform(proveedores: Proveedor[], noHabitual: boolean): any {
    return proveedores ? proveedores.filter(p => p.noHabitual == noHabitual) : [];
  }

}
