export class ListadoDocumentos {
	constructor(public documentos: any, public documentosArr?: string[]) {}

	public static fromData(data: any): ListadoDocumentos {
		if (!data) return null
		const o: ListadoDocumentos = new ListadoDocumentos(data.documentos || [])
		o.documentosArr = Object.keys(data.documentos)
		return o
	}
}
