<button
    pButton
    class="p-button-outlined"
    (click)="layoutElegido = 'L'"
    icon="pi pi-list"
    [disabled]="readonly"
    class="left"
    [ngClass]="{ 'p-button-primary': layoutElegido == 'L', 'p-button-outlined': layoutElegido != 'L' }"
></button>
<button
    pButton
    (click)="layoutElegido = 'G'"
    icon="pi pi-table"
    [disabled]="readonly"
    class="right"
    [ngClass]="{ 'p-button-primary': layoutElegido == 'G', 'p-button-outlined': layoutElegido != 'G' }"
></button>
