import { AccionesTabla } from './../../../common/model/AccionesTabla'
import { ConfirmationService } from 'primeng/api'
import { ModelListComponent } from './../../../common/components/model-list/model-list.component'
import { ModelListHandler } from './../../../common/interfaces/ModelListHandler'
import { ID } from 'src/app/app.module'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import * as moment from 'moment'
import { SessionComponent } from 'src/app/common/components/session-component.component'
import { Filtro } from 'src/app/common/model/Filtro'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { Notificacion } from 'src/app/model/Notificacion'
import { NotificacionService } from './../../../common/services/notificaciones.service'
import { Novedad } from './../../../model/Novedad'
import { IActualizable, NovedadService } from './../../../services/novedades.service'
@Component({
    selector: 'listado-notificacion',
    templateUrl: 'listado-notificacion.component.html',
    styleUrls: ['listado-notificacion.component.less'],
})
export class ListadoNotificacionComponent extends SessionComponent implements OnInit, OnDestroy, IActualizable {
    private key = ID()
    public getKey() {
        return this.key
    }
    public filtro: Filtro

    @Input()
    public guardarFiltro: boolean = true
    @Input()
    public ocultarCabecera: boolean = false
    @Input()
    public editable: boolean = true
    public listado: Notificacion[] = []
    @Input()
    public readonly: boolean = false
    @Input()
    public modoSeleccion: 'single' | 'multiple' | 'none' = 'none'

    @ViewChild(ModelListComponent, { static: false })
    public list: ModelListComponent
    public item : Notificacion;
    public acciones: AccionesTabla[] = []
    @Output()
    public onSelect: EventEmitter<Notificacion[]> = new EventEmitter()
    public jsonFormatted
    public verDetalle = false
    public columns = []
    public tomorrow = moment().add(1, 'day').startOf('day').format('DD/MM/YYYY')
    constructor(messagesService: MessagesService, public confirmationService: ConfirmationService, public novedadService: NovedadService, public service: NotificacionService) {
        super(messagesService)
    }
    next(n: Novedad) {
        if (!this.ultimaNovedad || this.ultimaNovedad.key != n.key) {
            this.filtro.forceUpdate()
            this.ultimaNovedad = n
        }
    }
    destroy() {}
    private ultimaNovedad: Novedad
    ngOnInit() {
        this.filtro = new Filtro('notificacion_lista', {}, 0, 20, 'id', 1, this.guardarFiltro)
        this.novedadService.registrarObservador('notificacion', this)
        this.acciones = [
            new AccionesTabla(this.translateService.get('Enviar pendientes'), null, null, (item) => {
                this.enviarPendientes()
            }),
            new AccionesTabla(this.translateService.get('Resumen Pax') + ' ' + this.tomorrow, null, null, (item) => {
                this.enviarPickupPax()
            }),
            new AccionesTabla(this.translateService.get('Resumen Hotel') + ' ' + this.tomorrow, null, null, (item) => {
                this.enviarResumenHotel()
            }),
            new AccionesTabla(this.translateService.get('Resumen Proveedor') + ' ' + this.tomorrow, null, null, (item) => {
                this.enviarResumenProveedor()
            }),
            new AccionesTabla(this.translateService.get('Resumen Guía') + ' ' + this.tomorrow, null, null, (item) => {
                this.enviarResumenGuia()
            }),
            new AccionesTabla(this.translateService.get('Resumen Diario') + ' ' + this.tomorrow, null, null, (item) => {
                this.enviarResumenDiario()
            }),
        ]
        this.columns = [
            {
                field: 'id',
                header: 'Estado Notificacion',
                role: 'estado',
                sortable: true,
                order: 40,
            },
            {
                field: 'encabezado',
                header: 'Encabezado',
                class: 'text-tabla',
                role: 'text-tabla',
                sortable: true,
                order: 40,
            },
            {
                field: 'codigoIdioma',
                header: 'Idioma',
                class: 'text-tabla',
                role: 'text-tabla',
                sortable: true,
                width: '5em',
                order: 40,
            },
            {
                field: 'tipoNotificacion',
                header: 'Codigo Tipo Notificacion',
                class: 'descriptivo-tabla',
                role: 'codigo-tabla',
                sortable: true,
                order: 40,
            },
            {
                field: 'intentos',
                header: 'Intentos',
                class: 'number-tabla',
                role: 'number-tabla',
                sortable: false,
                order: 40,
            },
            {
                field: 'logEstado',
                header: 'Log Estado',
                class: 'text-tabla overflow',
                role: 'text-tabla',
                sortable: true,
                width: '20em',
                order: 40,
            },
            {
                field: 'tipo',
                header: 'Tipo Canal',
                class: 'text-tabla',
                role: 'text-tabla',
                sortable: true,
                order: 40,
            },
            {
                field: 'destinatario',
                header: 'Destinatario',
                class: 'text-tabla',
                role: 'text-tabla',
                sortable: true,
                order: 40,
            },
            {
                field: 'fechaVencimiento',
                header: 'Fecha Vencimiento',
                class: 'date-tabla',
                role: 'date-tabla',
                sortable: false,
                order: 40,
            },
            {
                field: 'fechaProgramada',
                header: 'Fecha Programada',
                class: 'date-tabla',
                role: 'date-tabla',
                sortable: false,
                order: 40,
            },
            {
                field: 'key',
                header: 'Key',
                class: 'text-tabla',
                role: 'text-tabla',
                sortable: true,
                order: 40,
            },
        ]
        this.handler = {
            nuevo: () => {},
            editar: this.verNotificacion,
            eliminar: this.eliminar,
        }
    }

    public verNotificacion = (id, item) => {
      this.item = item;
        this.jsonFormatted = JSON.stringify(item, null, 4)
        this.verDetalle = true
    }
    public eliminar = (id, item) => {
        this.confirmationService.confirm({
            key: 'genConf',
            header: 'Eliminar',
            message: 'Va a eliminar una notificación. Continuar?',
            accept: () => {
                let $this = this
                $this.service.eliminar(id).then((res) => {
                    this.filtro.forceUpdate()
                })
            },
        })
    }
    public handler: ModelListHandler
    public updateSeleccion(seleccion: any) {
        if (seleccion?.length || seleccion['id']) {
            this.onSelect.emit(seleccion?.length ? seleccion : [seleccion])
        }
    }
    public enviarPickupPax() {
        if (this.esAdministrador) {
            this.service.enviarResumenPaxs()
            this.filtro.forceUpdate()
            this.success('Resumen enviados')
        }
    }
    public enviarResumenHotel() {
        if (this.esAdministrador) {
            this.service.enviarResumenHotel()
            this.filtro.forceUpdate()
            this.success('Resumen enviados')
        }
    }

    public enviarResumenProveedor() {
        if (this.esAdministrador) {
            this.service.enviarResumenProveedor()
            this.filtro.forceUpdate()
            this.success('Resumen enviados')
        }
    }
    public enviarResumenGuia() {
        if (this.esAdministrador) {
            this.service.enviarResumenGuia()
            this.filtro.forceUpdate()
            this.success('Resumen enviados')
        }
    }

    public enviarResumenDiario() {
        if (this.esAdministrador) {
            this.service.enviarResumenDiario()
            this.filtro.forceUpdate()
            this.success('Resumen enviados')
        }
    }
    public enviarPendientes() {
        if (this.esAdministrador) {
            this.service.enviar()
            this.filtro.forceUpdate()
            this.success('Pendientes enviados')
        }
    }
    ngOnDestroy() {}
}
