import { Component } from '@angular/core'
import { Location } from '@angular/common'

@Component({
    selector: 'app-help-itinerario',
    templateUrl: './help-itinerario.component.html',
    styleUrls: ['./help-itinerario.component.less'],
})
export class HelpItinerarioComponent {
    constructor(private _location: Location) {}

    public volver() {
        this._location.back()
    }
}
