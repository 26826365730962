import { ConsentimientoService } from './../../../services/consentimiento.service';
import { ProductoService } from 'src/app/services/producto.service';
import { FiltroConsentimiento } from './../../../model/FiltroConsentimiento';
import { SessionComponent } from './../../../common/components/session-component.component';
import { Component, OnInit } from '@angular/core';
import { debounceTime, filter, first } from 'rxjs/operators';
import { ConsentimientoReporte } from 'src/app/model/ConsentimientoReporte';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reporte-consentimiento',
  templateUrl: './reporte-consentimiento.component.html',
  styleUrls: ['./reporte-consentimiento.component.less']
})
export class ReporteConsentimientoComponent extends SessionComponent {
  public filtro: FiltroConsentimiento = new FiltroConsentimiento('consentimiento_filtros')
  public consentimientos: ConsentimientoReporte[] = []
  public resultado: ConsentimientoReporte[] = []
  public columns = [
    { field: 'idConsentimiento', header: 'id'},
    { field: 'fechaActividad', header: 'Fecha Actividad'},
    { field: 'estadoConsentimiento.descripcion', header: 'Estado'},
    { field: 'idReserva', header: 'Reserva'},
    { field: 'idProducto', header: 'Producto'},
    { field: 'descripcionProducto', header: 'Descripción'},
    { field: 'nombre', header: 'Nombre Pax / Cliente'},
    { field: 'documento', header: 'Documento'},
    { field: 'telefonoContacto', header: 'Telefono'},
    { field: 'fechaNacimientoPax', header: 'Fecha Nacimiento'},
    { field: 'observaciones', header: 'Observaciones'},

  ]

  
  private _soloAceptados : Boolean = true;
  public get soloAceptados() : Boolean {
    return this._soloAceptados;
  }
  public set soloAceptados(v : Boolean) {
    this._soloAceptados = v;

    if (v && this.resultado?.length) {
      this.consentimientos = [...this.resultado.filter(r => r.estadoConsentimiento?.codigo == 'OK')];
    } else {
      this.consentimientos = [...this.resultado]
    }
  }
  

  constructor(public productoService: ProductoService,
              public consentimientoService: ConsentimientoService,
              private router: Router) { 
    super()
  }

  ngOnInit() {
    this.filtro.isReady.pipe(filter(f => f == true), first()).subscribe(async f => {
      this.filtro.setMultiple({
        fechaActividadDesde: new Date(),
        fechaActividadHasta: new Date()
      }) 
    })


    this.filtro.dataChange.pipe(debounceTime(500)).subscribe(data => {
      this.getReporte();
    })


  }

  getReporte() {
    this.consentimientoService.getReporte(this.filtro).then( r => {
      this.resultado = r || []

      if (this.soloAceptados) {
        this.consentimientos = this.resultado.filter( r => r.estadoConsentimiento?.codigo == 'OK' )
      } else this.consentimientos = this.resultado;

    })
  }

  public goToProducto(event, data:ConsentimientoReporte) {
    event.stopPropagation()
    this.router.navigate(['producto/vista'], { queryParams: { id: data.idProducto } })
}

public goToReserva(event, data:ConsentimientoReporte) {
  event.stopPropagation()
  this.router.navigate(['reserva/vista'], { queryParams: { id: data.idReserva } })
}

public goToConsentimiento(event, data:ConsentimientoReporte) {
  event.stopPropagation()
  this.router.navigate(['consentimiento/vista'], { queryParams: { id: data.idConsentimiento } })
}


}
