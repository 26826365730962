<div class="gestor-email-template content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'EmailTemplate' | translate"
		[verAcciones]="false"
		[isModal]="isModal"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="EmailTemplateForm"
		[multilenguaje]="true"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #EmailTemplateForm="ngForm" id="EmailTemplateform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-3">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[listado]="tiposCanal"
							#tipoCanal="ngModel"
							[(ngModel)]="item.tipoCanal"
							[required]="true"
							[permiteNuevo]="false"
							name="tipoCanal"
							[titulo]="'TIPO_CANAL' | translate"
							[readonly]="readonly"
						>
						</parametrico-selector>
					</div>

					<div class="col-12 lg:col-3">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[service]="tipoNotificacionService"
							#tipoNotificacion="ngModel"
							[(ngModel)]="item.tipoNotificacion"
							[required]="true"
							[permiteNuevo]="false"
							name="tipo-notificacion"
							[titulo]="'TIPONOTIFICACION' | translate"
							[readonly]="readonly"
						>
						</parametrico-selector>
					</div>

					<div class="col-12 lg:col-3">
						<descriptivo-material-selector
							[isLazy]="true"
							[service]="puntoDeVentaService"
							[label]="'PuntoDeVenta' | translate"
							name="puntoDeVenta_plantilla"
							[limpiable]="true"
							[readonly]="readonly"
							[(ngModel)]="item.puntoDeVenta"
						>
						</descriptivo-material-selector>
					</div>
					<div class="col-12 lg:col-3">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="text"
								#descripcion="ngModel"
								name="descripcion"
								[readonly]="readonly"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>
					<div class="col-12 lg:col-3" *ngIf="item?.tipoCanal?.codigo == 'W'">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'TEMPLATE_WHATSAPP' | translate }}"
								[(ngModel)]="item.nombreTemplateWhatsapp"
								tooltipPosition="bottom"
								pTooltip="{{ 'TEMPLATE_WHATSAPP' | translate }}"
								type="text"
								#descripcion="ngModel"
								name="nombreTemplateWhatsapp"
								[readonly]="readonly"
								required
							/>
							<label>{{ "TEMPLATE_WHATSAPP" | translate }}</label>
						</span>
						<error-tag [model]="nombreTemplateWhatsapp"></error-tag>
					</div>
					<div class="col-12" *ngIf="item?.tipoCanal?.codigo == 'P'">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'TEMPLATE' | translate }}"
								[(ngModel)]="item.template"
								tooltipPosition="bottom"
								pTooltip="{{ 'TEMPLATE' | translate }}"
								type="$TYPE"
								#template="ngModel"
								name="template"
								[readonly]="readonly"
							/>
							<label>{{ "TEMPLATE" | translate }}</label>
						</span>
						<error-tag [model]="template"></error-tag>
					</div>
					<div class="col-12" *ngIf="item?.tipoCanal?.codigo != 'P' && item?.tipoCanal?.codigo != 'W'">
						<label>{{ "TEMPLATE" | translate }}</label>
						<p-editor
							[(ngModel)]="item.template"
							name="template"
							tabindex="10"
							[readonly]="readonly"
							[style]="{ height: '100%', 'max-height': '40vh', 'min-height': '20vh', overflow: 'auto', 'background-color': 'white' }"
						>
						</p-editor>
						<!--email-editor (loaded)="editorLoaded($event)" #editor [options]="options"></email-editor-->
						<p-header *ngIf="readonly">
							<span></span>
						</p-header>
						<small>
							{{ getErrorMessage(template) }}
						</small>
					</div>

					<div class="col-12 lg:col-6" *ngIf="item?.tipoCanal?.codigo == 'W'">
						<p-card header="Variables">
							<ng-container
								*ngTemplateOutlet="lista; context: { $implicit: item?.variablesWhatsapp?.variables, tipo: 'variables' }"
							></ng-container>
						</p-card>
					</div>

					<!-- <div class="col-12 lg:col-6" *ngIf="item?.tipoCanal?.codigo == 'W'">
                        <p-card header="Botones">

                            <ng-container *ngTemplateOutlet="lista; context: { $implicit: item?.variablesWhatsapp?.botones, tipo: 'botones'  }"></ng-container>

                        </p-card>
                    </div> -->

					<div class="col-12 lg:col-3" *ngIf="item?.tipoCanal?.codigo == 'P'">
						<div class="pic-wrapper">
							<div class="pic">
								<profile-badge
									[seleccionable]="true"
									[url]="item.icono?.picPathVersion"
									[disabled]="readonly"
									class="full-badge"
									(onFileSelected)="onPicSelected($event)"
								>
								</profile-badge>
								<i class="fa fa-pencil edit-icon" *ngIf="!readonly"></i>
							</div>
						</div>
					</div>
					<div class="col-12 lg:col-9" *ngIf="item?.tipoCanal?.codigo == 'P'">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'ACCION_PH' | translate }}"
								[(ngModel)]="item.accion"
								tooltipPosition="bottom"
								pTooltip="{{ 'ACCION_TOOLTIP' | translate }}"
								type="text"
								#accion="ngModel"
								name="accion"
								[readonly]="readonly"
							/>
							<label>{{ "ACCION" | translate }}</label>
						</span>
						<error-tag [model]="accion"></error-tag>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
<p-dialog
	*ngIf="mostrarAgregarVariable"
	header="AgregarVariable"
	[(visible)]="mostrarAgregarVariable"
	[modal]="true"
	closable="false"
	[style]="{ width: '50vw' }"
>
	<span class="p-float-label">
		<input
			pInputText
			placeholder="{{ 'VARIABLE' | translate }}"
			[(ngModel)]="variableSeleccionada"
			tooltipPosition="bottom"
			pTooltip="{{ 'variableSeleccionada' | translate }}"
			type="text"
		/>
		<label>{{ "VARIABLE" | translate }}</label>
	</span>
	<error-tag [model]="variableSeleccionada"></error-tag>

	<p-footer>
		<button pButton class="boton" (click)="agregarVariable()" label="Guardar"></button>
	</p-footer>
</p-dialog>

<ng-template #lista let-variables let-tipo="tipo">
	<p-orderList [value]="variables" [listStyle]="{ height: '20rem' }">
		<ng-template pTemplate="header">
			<div class="d-flex justify-content-end">
				<button pButton (click)="onAgregarVariable(tipo)">Agregar Variable</button>
			</div>
		</ng-template>

		<ng-template let-variable pTemplate="item">
			<div class="d-flex justify-content-between">
				<span class="d-flex align-items-center">
					{{ variable }}
				</span>
				<div>
					<button pButton class="accion" (click)="quitarVariable($event, variable, tipo)" icon="fa fa-trash" *ngIf="!readonly"></button>
				</div>
			</div>
		</ng-template>
	</p-orderList>
</ng-template>
