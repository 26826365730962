import { Moneda } from 'src/app/model/Moneda';



export class DetalleGastoReservaVO  {
    constructor(
		public idGasto ?: number, 
		public importeGasto ?: number, 
		public cantGasto ?: number, 
		public idTipoPuesto ?: number, 
		public codigoTipoPuesto ?: string, 
		public descTipoPuesto ?: string, 
		public picTipoPuesto ?: string, 
		public idProveedor ?: number, 
		public nombreProveedor ?: string, 
		public razonSocialProveedor ?: string, 
		public picProveedor ?: string,
		public idProductoGasto ?: number, 
		public codigoProductoGasto ?: string, 
		public descProductoGasto ?: string, 
		public idProductoReserva ?: number, 
		public codigoProductoReserva ?: string, 
		public descProductoReserva ?: string, 
		public idItemReserva ?: number, 
		public fechaActividad ?: Date, 
		public cantPax ?: number, 
		public ventaPerCapita ?: number, 
		public ajuste ?: number, 
		public idMonedaReserva ?: number, 
		public codigoMonedaReserva ?: string, 
		public descMonedaReserva ?: string, 
		public simboloMonedaReserva ?: string, 
		public cotizacionAfip ?: number, 
		public idInsumo?:number,
		public codigoInsumo?:string,
		public descInsumo?:string,
		public moneda?:Moneda

    ) {
    }
    public static fromData(data: any): DetalleGastoReservaVO {
        if (!data) return null;
        const o: DetalleGastoReservaVO = new DetalleGastoReservaVO(
			data.idGasto, 
			data.importeGasto, 
			data.cantGasto, 
			data.idTipoPuesto, 
			data.codigoTipoPuesto, 
			data.descTipoPuesto, 
			data.picTipoPuesto, 
			data.idProveedor, 
			data.nombreProveedor, 
			data.razonSocialProveedor, 
			data.picProveedor, 
			data.idProductoGasto, 
			data.codigoProductoGasto, 
			data.descProductoGasto, 
			data.idProductoReserva, 
			data.codigoProductoReserva, 
			data.descProductoReserva, 
			data.idItemReserva, 
			data.fechaActividad? new Date(data.fechaActividad) : null , 
			data.cantPax, 
			data.ventaPerCapita, 
			data.ajuste, 
			data.idMonedaReserva, 
			data.codigoMonedaReserva, 
			data.descMonedaReserva, 
			data.simboloMonedaReserva, 
			data.cotizacionAfip, 
			data.idInsumo,
			data.codigoInsumo,
			data.descInsumo

        );
return o;

    }

}