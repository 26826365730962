import { Directive, EventEmitter, Output } from "@angular/core"
import { AbstractControl, NG_VALIDATORS, ValidatorFn } from "@angular/forms"
import { StringUtils } from "./string-utils"

@Directive({
	selector: "[appMultiEmailValidator]",
	providers: [{ provide: NG_VALIDATORS, useExisting: MultiEmailValidatorDirective, multi: true }]
})
export class MultiEmailValidatorDirective {
	@Output() validMail: EventEmitter<boolean> = new EventEmitter()

	public static multiEmail(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			return !control?.value || StringUtils.isMailValid(control.value.trim()) ? null : { multiMailInvalido: { value: control.value } }
		}
	}

	validate(control: AbstractControl): { [key: string]: any } | null {
		this.validMail.emit(!control?.value || StringUtils.isMailValid(control?.value?.trim()))
		return MultiEmailValidatorDirective.multiEmail()(control)
	}
}
