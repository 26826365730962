import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { Descriptivo } from "src/app/common/model/Descriptivo";
import { ServicioAbstract } from "src/app/common/services/service.service";
import { Filtro } from "./../model/Filtro";
import { LoadingService } from "./loading-data-service.service";

@Injectable()
export abstract class DescriptivosService<E extends Descriptivo> extends ServicioAbstract<E> {
	public getDescriptivosByDesc(query: any, customLoading?: LoadingService): Promise<E[]> {
		if (customLoading) customLoading.addLoadingCount();
		else this.loadingService.addLoadingCount();
		return lastValueFrom(this.http.get(this.getBaseURL() + "getByDesc/?val=" + query))
			.then((r) => {
				if (customLoading) customLoading.susLoadingCount();
				else this.loadingService.susLoadingCount();
				return this.parse(r);
			})
			.catch((e) => this.handleError(e, customLoading));
	}
	getDescriptivos(filter: Filtro = new Filtro("", {}, 0, 3000, "descripcion"), customLoading?: LoadingService): Promise<Descriptivo[]> {
		let $this = this;
		if (customLoading) customLoading.addLoadingCount();
		else this.loadingService.addLoadingCount();
		return lastValueFrom(this.http.post(this.getBaseURL() + "descriptivos", filter.json)).then(
			(r) => {
				if (customLoading) customLoading.susLoadingCount();
				else this.loadingService.susLoadingCount();
				return this.parseDescriptivos(r);
			},
			(e) => this.handleError(e, customLoading)
		);
	}
	public getByCodigo(codigo: string, customLoading?: LoadingService): Promise<E> {
		if (customLoading) customLoading.addLoadingCount();
		else this.loadingService.addLoadingCount();
		return lastValueFrom(this.http.get(this.getBaseURL() + "byCodigo/" + codigo))
			.then((r) => {
				if (customLoading) customLoading.susLoadingCount();
				else this.loadingService.susLoadingCount();
				return r ? this.parseSingle(r) : null;
			})
			.catch((e) => this.handleError(e, customLoading));
	}
}
