import { Component, EventEmitter, Output } from "@angular/core"
import { debounceTime, filter, first } from "rxjs/operators"
import { FiltroServicios } from "src/app/model/FiltroServicios"
import { ReporteServicios } from "src/app/model/ReporteServicios"
import { GrupoPaxService } from "src/app/services/grupo-pax.service"
import { ProductoService } from "src/app/services/producto.service"
import { ProveedorService } from "src/app/services/proveedor.service"
import { VehiculoService } from "src/app/services/vehiculo.service"
import { SessionComponent } from "./../../../common/components/session-component.component"

@Component({
	selector: "app-reporte-servicios",
	templateUrl: "./reporte-servicios.component.html",
	styleUrls: ["./reporte-servicios.component.less"]
})
export class ReporteServiciosComponent extends SessionComponent {
	public filtro: FiltroServicios = new FiltroServicios("servicios_filtros")
	public servicios: ReporteServicios[] = []
	public hideTopbar = true
	public columns = [
		{ field: "nombrePax", header: this.translateService.get("NOMBRE") },
		{ field: "apellidoPax", header: this.translateService.get("APELLIDO") },
		{ field: "nacionalidadPax", header: this.translateService.get("NACIONALIDAD") },
		{ field: "documentoPax", header: this.translateService.get("DOCUMENTO") },
		{ field: "fechaNacPax", header: this.translateService.get("FECHA_NAC") },
		{ field: "observaciones", header: this.translateService.get("OBSERVACIONES") },
		{ field: "numeroReserva", header: this.translateService.get("NRO_RESERVA") }
	]

	constructor(
		public grupoPaxService: GrupoPaxService,
		public productoService: ProductoService,
		public proveedorService: ProveedorService,
		public vehiculoService: VehiculoService
	) {
		super()
	}

	ngOnInit() {
		this.hideTopbarChanged.emit(true)

		this.filtro.isReady
			.pipe(
				filter((f) => f == true),
				first()
			)
			.subscribe(async (f) => {
				this.filtro.fechaActividadDesde = new Date()
				this.filtro.fechaActividadHasta = new Date()
			})

		this.filtro.dataChange.pipe(debounceTime(500)).subscribe((data) => {
			this.getReporte()
		})
	}

	getReporte() {
		this.grupoPaxService.getReporteServicios(this.filtro).then((r) => {
			this.servicios = r || []
		})
	}

	getCantidadByGrupo(grupoPaxId: number) {
		return this.servicios.filter((s) => s.grupoPaxId == grupoPaxId).length
	}

	@Output()
	hideTopbarChanged: EventEmitter<any> = new EventEmitter()
}
