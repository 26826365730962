<div class="gestor-vehiculos content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[file]="file"
		[conArchivo]="true"
		[modelName]="'VEHICULO' | translate"
		[editable]="permiteEdicion"
		[(readonly)]="readonly"
	>
		<div class="gestor-form" model-type="dataContent">
			<div class="grid">
				<div class="col-12 lg:col-3">
					<div class="pic-wrapper">
						<div class="pic">
							<profile-badge
								[seleccionable]="true"
								[url]="item.imagenUrl"
								class="full-badge"
								(onFileSelected)="onPicSelected($event)"
								[disabled]="readonly"
							>
							</profile-badge>
							<i class="fa fa-pencil edit-icon"></i>
						</div>
					</div>
				</div>
				<div class="col-12 lg:col-9">
					<div class="grid">
						<div class="col-12 lg:col-4">
							<span class="p-float-label">
								<input pInputText placeholder="{{ 'NOMBRE' | translate }}" [(ngModel)]="item.nombre" [disabled]="readonly" name="patente" />
								<label>{{ "NOMBRE" | translate }}</label>
							</span>
						</div>
						<div class="col-12 lg:col-4">
							<span class="p-float-label">
								<input
									pInputText
									placeholder="{{ 'PH_PATENTE' | translate }}"
									[(ngModel)]="item.patente"
									[disabled]="readonly"
									name="patente"
								/>
								<label>{{ "PATENTE" | translate }}</label>
							</span>
						</div>
						<div class="col-12 lg:col-4">
							<span class="p-float-label">
								<input
									pInputText
									type="text"
									placeholder="{{ 'PH_MODELO' | translate }}"
									[(ngModel)]="item.modelo"
									required
									[disabled]="readonly"
									name="modelo"
								/>
								<label>{{ "MODELO" | translate }}</label>
							</span>
						</div>
						<div class="col-12 lg:col-2">
							<span class="p-float-label">
								<input
									pInputText
									type="number"
									placeholder="{{ 'PH_CAPACIDAD' | translate }}"
									[(ngModel)]="item.capacidad"
									min="2"
									required
									[disabled]="readonly"
									name="capacidad"
								/>
								<label>{{ "CAPACIDAD" | translate }}</label>
							</span>
						</div>
						<div class="col-12 lg:col-3">
							<span class="p-float-label">
								<p-calendar [(ngModel)]="item.fechaCompra" [disabled]="readonly" name="fechaCompra" dateFormat="dd/mm/yy"></p-calendar>
								<label>{{ "FECHA_COMPRA" | translate }}</label>
							</span>
						</div>
						<div class="col-12 lg:col-3">
							<span class="p-float-label">
								<p-calendar
									[(ngModel)]="item.fechaVencimientoHabilitacion"
									[disabled]="readonly"
									dateFormat="dd/mm/yy"
									name="fechaVencimientoHabilitacion"
								></p-calendar>
								<label>{{ "FECHA_VENCIMIENTO_HABILITACION" | translate }}</label>
							</span>
						</div>
						<div class="col-12 lg:col-2">
							<span class="p-float-label">
								<input
									pInputText
									type="number"
									placeholder="{{ 'PESO' | translate }}"
									[(ngModel)]="item.peso"
									min="0"
									required
									[disabled]="readonly"
									name="peso"
								/>
								<label>{{ "PESO" | translate }}</label>
							</span>
						</div>
						<div class="col-12 lg:col-2 align-content-center checkbox-container">
							<p-checkbox
								[disabled]="readonly"
								[(ngModel)]="item.es4x4"
								name="es4x4"
								tooltipPosition="bottom"
								pTooltip="{{ 'ES_4X4' | translate }}"
								binary="true"
								label="{{ 'ES_4X4' | translate }}"
							>
							</p-checkbox>
						</div>
						<div class="col-12 lg:col-2 align-content-center checkbox-container">
							<p-checkbox
								[disabled]="readonly"
								[(ngModel)]="item.propio"
								name="propio"
								tooltipPosition="bottom"
								pTooltip="{{ 'ES_PROPIO' | translate }}"
								binary="true"
								label="{{ 'ES_PROPIO' | translate }}"
							>
							</p-checkbox>
						</div>
						<div class="col-12 lg:col-6">
							<parametrico-selector
								[allowDefault]="!item?.id"
								[tipoParametro]="'tipovehiculo'"
								[(ngModel)]="item.tipoVehiculo"
								[permiteNuevo]="true"
								name="tipoVehiculo"
								[titulo]="'TIPOVEHICULO' | translate"
								[readonly]="readonly"
							>
							</parametrico-selector>
						</div>
						<div class="col-12 lg:col-6">
							<parametrico-selector
								[allowDefault]="!item?.id"
								[tipoParametro]="'estado-vehiculo'"
								[(ngModel)]="item.estadoVehiculo"
								[limpiable]="true"
								name="estadoVehiculo"
								[titulo]="'ESTADOVEHICULO' | translate"
								[readonly]="readonly"
							>
							</parametrico-selector>
						</div>
					</div>
				</div>
				<div class="col-12">
					<span class="fw-500">{{ "RECORDATORIOS" | translate }}</span>
					<abm-recordatorios [itemConRecordatorios]="item" [readonly]="readonly" [fechaBase]="item.fechaVencimientoHabilitacion"></abm-recordatorios>
				</div>
				<div class="col-12" *ngIf="item.id" style="max-height: '35vh'; overflow-y: auto">
					<abm-hijo
						[items]="item.bloqueos"
						(onAgregar)="item.agregarBloqueo($event)"
						(onQuitar)="item.quitarBloqueo($event)"
						[newItem]="item.newBloqueo"
						emptyMessage="{{ 'No hay bloqueos' | translate }}"
						[gestor]="gestorBloqueo"
						[itemTemplate]="itemTemplate"
						[editable]="!readonly"
						[disabled]="readonly"
					>
						<ng-template #itemTemplate let-item let-r="index">
							<div class="grid">
								<div class="col col-fixed" style="width: 30em">
									<span
										>Desde <strong>{{ item?.fechaDesde | date : "dd/MM/yyy" }}</strong> hasta
										<strong>{{ item?.fechaHasta | date : "dd/MM/yyy" }}</strong></span
									>
								</div>
								<div class="col motivo" [innerHtml]="item?.motivo | safeHtml"></div>
							</div>
						</ng-template>
					</abm-hijo>
				</div>
			</div>
		</div>
	</model-gestor>
</div>
<ng-template #gestorBloqueo let-handler="handler" let-itemEditado="itemEditado">
	<gestor-bloqueo-vehiculo
		[item]="itemEditado"
		[readonly]="readonly"
		[isModal]="true"
		[goBack]="false"
		[persist]="false"
		(onCancelado)="handler.onCancelado($event)"
		(onGuardado)="handler.onGuardado($event)"
	>
	</gestor-bloqueo-vehiculo>
</ng-template>
