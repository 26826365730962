import { Component } from "@angular/core"
import { Router } from "@angular/router"
import * as moment from "moment"
import { debounceTime, filter, first } from "rxjs/operators"
import { MonedaEntorno } from "src/app/app.module"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { ExcelDownloader } from "src/app/common/utils/excelDownloader"
import { ItemComprobanteReporte } from "src/app/model/ItemComprobanteReporte"
import { Moneda } from "src/app/model/Moneda"
import { CategoriaProductoService } from "src/app/services/categoria-producto.service"
import { ItemComprobanteService } from "src/app/services/item-comprobante.service"
import { PuntoDeVentaService } from "src/app/services/punto-de-venta.service"
import { SessionComponent } from "./../../../common/components/session-component.component"
import { MessagesService } from "./../../../common/services/messages-data-service.service"
import { ComprobanteResumen } from "./../../../model/Comprobante"
import { FiltroComprobante } from "./../../../model/FiltroComprobantes"
import { ClienteService } from "./../../../services/cliente.service"
import { ComprobanteService } from "./../../../services/comprobante.service"
import { EstadoDocumentoService } from "./../../../services/estado-documento.service"
import { TipoComprobanteService } from "./../../../services/tipo-comprobante.service"
@Component({
	selector: "app-ventas",
	templateUrl: "./ventas.component.html",
	styleUrls: ["./ventas.component.less"]
})
export class VentasComponent extends SessionComponent {
	public tabActive = 0
	public filtro: FiltroComprobante
	public comprobantes: ComprobanteResumen[] = []
	public items: ItemComprobanteReporte[] = []
	public opcionesPunto: Descriptivo[] = []
	public columns = []
	public columnsItems = []
	public totales: {
		bruto?: number
		impuestos?: number
		otros?: number
		total?: number
	} = {}

	public totalesItems: {
		total?: number
	} = {}

	public totalesMoneda: {
		moneda: Moneda
		bruto?: number
		impuestos?: number
		otros?: number
		total?: number
	}[] = []

	public totalesMonedaItems: {
		moneda: Moneda
		total?: number
	}[] = []
	constructor(
		messagesService: MessagesService,
		private comprobanteService: ComprobanteService,
		public tipoComprobanteService: TipoComprobanteService,
		public estadosService: EstadoDocumentoService,
		public puntoDeVentaService: PuntoDeVentaService,
		public clienteService: ClienteService,
		public exportExcelService: ExcelDownloader,
		public itemComprobanteService: ItemComprobanteService,
		public categoriaProductoService: CategoriaProductoService,
		public router: Router
	) {
		super(messagesService)
	}
	public updateRegistros() {
		if (this.filtro) {
			this.comprobanteService.getResumenAll(this.filtro).then((c) => {
				this.comprobantes = c
				this.totales = {
					bruto: 0,
					impuestos: 0,
					otros: 0,
					total: 0
				}
				this.totalesMoneda = []
				this.comprobantes.forEach((cc) => {
					this.totales.bruto += cc.importeBrutoPesos
					this.totales.impuestos += cc.impuestosPesos
					this.totales.otros += cc.otrosImpuestosPesos
					this.totales.total += cc.importePesos

					if (cc.moneda.codigo != MonedaEntorno().codigo) {
						let total = this.totalesMoneda.find((t) => t.moneda.id == cc.moneda.id)

						if (total) {
							total.bruto += cc.importeBruto
							total.impuestos += cc.impuestos
							total.otros += cc.otrosImpuestos
							total.total += cc.importe
						} else {
							this.totalesMoneda.push({
								moneda: cc.moneda,
								bruto: cc.importeBruto,
								impuestos: cc.impuestos,
								otros: cc.otrosImpuestos,
								total: cc.importe
							})
						}
					}
				})
			})
		}
	}
	private getItemsReporte() {
		this.itemComprobanteService.getReporte(this.filtro).then((c) => {
			this.items = c.sort((a, b) => (a.idComprobante == b.idComprobante ? a.id - b.id : a.idComprobante - b.idComprobante))

			this.totalesItems = {
				total: 0
			}
			this.totalesMonedaItems = []
			this.items.forEach((cc) => {
				this.totalesItems.total += cc.totalMonedaEntorno

				if (cc.moneda.codigo != MonedaEntorno().codigo) {
					let total = this.totalesMonedaItems.find((t) => t.moneda.id == cc.moneda.id)

					if (total) {
						total.total += cc.total
					} else {
						this.totalesMonedaItems.push({
							moneda: cc.moneda,
							total: cc.total
						})
					}
				}
			})
		})
	}

	public verPdf(event, id) {
		event && event.stopPropagation()
		this.comprobanteService.verPDF(id)
	}
	ngOnInit() {
		this.columns = [
			{
				field: "fechaStr",
				header: "Fecha",
				class: "date-tabla",
				role: "date-tabla",
				sortable: true,
				esMobile: true,
				order: 40,
				width: "10em"
			},
			{
				field: "tipoComprobanteCodigo",
				header: " ",
				class: "text-tabla d-flex justify-content-center",
				role: "text-tabla",
				sortable: true,
				esMobile: false,
				width: "4em",
				order: 40
			},
			{
				field: "numeroDocumento",
				header: "Número ",
				class: "text-tabla",
				role: "text-tabla",
				sortable: true,
				esMobile: true,
				width: "15em",
				order: 40
			},
			{
				field: "url",
				header: "Adjunto ",
				class: "download-tabla",
				role: "download-tabla",
				sortable: true,
				esMobile: true,
				width: "15em",
				order: 40
			},
			{
				field: "nombreCliente",
				header: "Cliente ",
				class: "text-tabla",
				role: "text-tabla",
				sortable: true,
				esMobile: true,
				width: "15em",
				order: 40
			},
			{
				field: "cuitCliente",
				header: "CUIT ",
				class: "text-tabla text-center",
				role: "text-tabla",
				sortable: true,
				esMobile: true,
				width: "15em",
				order: 40
			},
			{
				field: "importeBrutoPesos",
				header: "I. Bruto ",
				class: "money-tabla",
				role: "money-tabla",
				order: 40
			},

			{
				field: "impuestosPesos",
				header: "IVA ",
				class: "money-tabla",
				role: "money-tabla",
				order: 40
			},

			{
				field: "otrosImpuestosPesos",
				header: "Otros impuestos ",
				class: "money-tabla",
				role: "money-tabla",
				order: 40
			},

			{
				field: "importePesos",
				header: "I. Neto",
				class: "money-tabla",
				role: "money-tabla",

				order: 40
			},
			{
				field: "cotizacionSafe",
				header: "Cotización",
				class: "money-tabla",
				role: "money-tabla",
				downloadOnly: true,
				order: 40
			},
			{
				field: "monedaStr",
				header: "Moneda ",
				class: "text-tabla",
				role: "text-tabla",
				downloadOnly: true,
				order: 40
			},

			{
				field: "importeBruto",
				header: "I. Bruto - Moneda original",
				class: "money-tabla",
				role: "money-tabla",
				downloadOnly: true,
				order: 40
			},

			{
				field: "impuestos",
				header: "IVA - Moneda original",
				class: "money-tabla",
				role: "money-tabla",
				downloadOnly: true,
				order: 40
			},

			{
				field: "otrosImpuestos",
				header: "Otros impuestos - Moneda original",
				class: "money-tabla",
				role: "money-tabla",
				downloadOnly: true,
				order: 40
			},

			{
				field: "importe",
				header: "I. Neto - Moneda original",
				class: "money-tabla",
				role: "money-tabla",
				downloadOnly: true,
				order: 40
			},
			{
				field: "cae",
				header: "CAE",
				class: "text-tabla",
				role: "text-tabla",
				downloadOnly: true,
				order: 40
			}
		]

		this.columnsItems = [
			{
				field: "numeroComprobante",
				header: "Numero Comprobante ",
				class: "text-tabla",
				role: "text-tabla",
				sortable: true,
				esMobile: false,
				order: 40
			},
			{
				field: "tipoComprobanteCodigo",
				header: "T. Comp.",
				class: "descriptivo-tabla text-center",
				role: "descriptivo-tabla",
				sortable: true,
				esMobile: true,
				order: 40
			},
			{
				field: "fecha",
				header: "Fecha ",
				class: "date-tabla text-center",
				role: "date-tabla",
				sortable: true,
				esMobile: true,
				order: 40
			},
			{
				field: "monedaCodigo",
				header: "Moneda ",
				class: "descriptivo-tabla text-center",
				role: "descriptivo-tabla",
				sortable: true,
				esMobile: true,
				order: 40
			},

			{
				field: "descuentoStr",
				header: "Descuento ",
				class: "number-tabla text-center",
				role: "number-tabla",
				order: 40
			},
			{
				field: "tipoIVA",
				header: "IVA",
				class: "money-tabla text-center",
				role: "money-tabla",
				order: 40
			},
			{
				field: "servicio",
				header: "Servicio ",
				class: "text-tabla text-center",
				role: "text-tabla",
				order: 40
			},
			{
				field: "categoriasStr",
				header: "Categorias ",
				class: "text-tabla text-center",
				role: "text-tabla",
				sortable: true,
				esMobile: false,
				order: 40
			},
			{
				field: "cantidad",
				header: "Cantidad ",
				class: "number-tabla text-center",
				role: "number-tabla",
				order: 40
			},
			{
				field: "precioUnitario",
				header: "Precio Unitario ",
				class: "money-tabla text-right",
				role: "money-tabla",
				order: 40
			},

			{
				field: "precioUnitarioBruto",
				header: "Precio Unitario Bruto ",
				class: "money-tabla text-right",
				role: "money-tabla",
				order: 40
			},
			{
				field: "impuestosTotal",
				header: "Impuestos ",
				class: "money-tabla text-right",
				role: "money-tabla",
				order: 40
			},
			{
				field: "cotizacionSafe",
				header: "Cotización ",
				class: "money-tabla text-right",
				role: "money-tabla",
				order: 40
			},
			{
				field: "total",
				header: "Total ",
				class: "money-tabla text-right",
				role: "money-tabla",
				order: 40
			}
		]

		this.filtro = new FiltroComprobante(null, 0, 10000, "fecha", 2, false)

		this.filtro.onClean(async (f) => {
			f.setMultiple({
				fechaDesde: moment().startOf("month").startOf("day").toDate(),
				fechaHasta: moment().endOf("month").endOf("day").toDate(),
				tiposComprobante: await this.tipoComprobanteService.getOficiales(),
				estados: (await this.estadosService.getData()).filter((e) => e.codigo != "AN")
			})
		})
		const $this = this
		this.filtro.isReady
			.pipe(
				filter((f) => f == true),
				first()
			)
			.subscribe(async (f) => {
				this.filtro.setMultiple({
					fechaDesde: moment().startOf("month").startOf("day").toDate(),
					fechaHasta: moment().endOf("month").endOf("day").toDate(),
					tiposComprobante: await this.tipoComprobanteService.getOficiales(),
					estados: await this.estadosService.getData()
				})
				this.filtro.dataChange.pipe(debounceTime(500)).subscribe((data) => {
					if ($this.tabActive === 0) {
						this.updateRegistros()
					} else {
						this.getItemsReporte()
					}
				})
			})
		this.puntoDeVentaService.getAll().then((r) => {
			this.opcionesPunto = r
			if (this.opcionesPunto?.length == 1) {
				this.filtro.puntoDeVenta = this.opcionesPunto[0]
			}
		})
	}
	exportExcel() {
		this.exportExcelService.downloadData(this.comprobantes, "comprobantes_ventas", { proveedorStr: true, searchStr: true }, this.columns)
	}
	exportExcelItems() {
		this.exportExcelService.downloadData(this.items, "items_ventas", {}, this.columnsItems)
	}
	public verComprobante = (data: ItemComprobanteReporte) => {
		if (!data) return
		this.router.navigate(["comprobante/vista"], { queryParams: { id: data.idComprobante } })
	}

	onChange(event) {
		this.tabActive = event.index
		if (this.tabActive === 0) {
			this.updateRegistros()
		} else {
			this.getItemsReporte()
		}
	}
}
