import { lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoadingService } from 'src/app/common/services/loading-data-service.service';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { EmailTemplate } from '../model/EmailTemplate';
@Injectable({
    providedIn: 'root',
})
export class EmailTemplateService extends ServicioAbstract<EmailTemplate>{
    static CONSENTIMIENTO: string = 'CONS_TEMP';
    public getByCodigo(codigo: string, idioma:string= 'ES', customLoading?: LoadingService): Promise<EmailTemplate> {
        if (customLoading) customLoading.addLoadingCount(); else this.loadingService.addLoadingCount();
        return lastValueFrom(this.http.get(this.getBaseURL() + "public/byCodigo/" + codigo+"?idioma="+idioma)).then(r => {
          return r ? this.parseSingle(r) : null;
        }).catch((e) => this.handleError(e, customLoading)).finally(()=>{
            if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
        });
      }

    public newEnt(): EmailTemplate {
        return new EmailTemplate();
    }

    public baseName(): string { return "emailtemplate" }
    public parseToEnt(data: any): EmailTemplate {
        return EmailTemplate.fromData(data);
    }


}