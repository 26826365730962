<div class="grid align-content-center grid-nogutter">
  <div class="col-fixed badge-container">
      <div class="circular-badge" [ngStyle]="{ 'background-image': 'url(' + opcion?.proveedor?.imagen + '), url(' + puesto?.imagen + ')' }"></div>
  </div>
  <div class="col">
      <div class="grid " [class.grid-nogutter]="!seleccion">
          <div class="col">
              <span class="detalle">{{ opcion?.proveedor ? opcion.proveedor?.descripcion : puesto?.descripcion }}</span>
          </div>
          <div class="col-12 lg:col-3 idiomas">
            <img *ngFor="let item of opcion?.idiomas" src="{{ item?.imagenUrl }}" alt="" class="idioma" tooltipPosition="bottom" pTooltip="{{ item?.descripcion }}" />
          </div>
          <div class="col-12 mt-1" *ngIf="!seleccion">
            <p-badge styleClass="puesto-badge mr-1 mb-1" *ngFor="let puesto of opcion.puestos" [value]="puesto?.descripcion" size="small" ></p-badge>
          </div>
          <div class="col-12 ocupacion" *ngIf="!seleccion">
              <div *ngFor="let o of opcion.ocupacion">
                  <span>{{ o.descripcionProducto }} - {{ o.descripcionTipoPuesto }} - {{ o.fechaActividad | date: 'short' }}</span>
              </div>
          </div>
          <div class="col-12" *ngIf="opcion.proveedor?.noHabitual">
              <span>Proveedor No Habitual</span>
          </div>

          <div class="col-12" *ngIf="opcion.ausencia">
              <span>Ausente - {{ opcion.ausencia.tipo?.descripcion }}</span>
          </div>
          <div class="col-12" *ngIf="seleccion && opcion?.proveedor?.id && permiteGestionCostos">
            <button pButton  icon="fa fa-money" label="{{'GESTIONAR_COSTOS' | translate}}" (click)="verCostos()"></button>
          </div>
      </div>
  </div>
  <div class="col-1" (click)="deseleccionar()" tooltipPosition="bottom" pTooltip="Desasignar Personal" *ngIf="seleccion">
      <span  *ngIf="opcion?.proveedor?.id"><i class="fa fa-eraser" ></i></span>
  </div>
</div>