import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core"
import { NavigationExtras, Router } from "@angular/router"
import * as moment from "moment"
import { ConfirmationService } from "primeng/api"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { AccionesTabla } from "src/app/common/model/AccionesTabla"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Comprobante } from "src/app/model/Comprobante"
import { GenerarComprobante } from "src/app/model/GenerarComprobante"
import { ClienteService } from "src/app/services/cliente.service"
import { ComprobanteService } from "src/app/services/comprobante.service"
import { EstadoDocumentoService } from "src/app/services/estado-documento.service"
import { PuntoDeVentaService } from "src/app/services/punto-de-venta.service"
import { TipoComprobanteService } from "src/app/services/tipo-comprobante.service"
import { ComprobanteResumen } from "./../../../model/Comprobante"
import { FiltroComprobante } from "./../../../model/FiltroComprobantes"
import { Totales } from "./../../../model/Totales"

@Component({
	selector: "listado-comprobante",
	templateUrl: "listado-comprobante.component.html",
	styleUrls: ["listado-comprobante.component.less"]
})
export class ListadoComprobanteComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: FiltroComprobante
	public listado: Comprobante[] = []
	public columns = []
	public acciones = []
	public verReenviarComprobanteMultiple: boolean = false
	public itemsEnvioMultiple: ComprobanteResumen[] = []
	public destinatarioMultiple: Descriptivo = GenerarComprobante.ADMINISTRACION

	public emailOpciones: Descriptivo[] = [
		GenerarComprobante.RESERVA,
		GenerarComprobante.ADMINISTRACION,
		GenerarComprobante.CONTACTO,
		GenerarComprobante.PARTICULAR
	]
	public destinatarioMultipleParticular: string = ""
	public estados: Descriptivo[] = []
	@Input()
	public skipTotales: boolean = false

	@Input()
	public guardarFiltro: boolean = true

	@Input()
	public ocultarCabecera: boolean = false

	@Output()
	public onSelect: EventEmitter<ComprobanteResumen[]> = new EventEmitter()

	@Input()
	public modoSeleccion: "single" | "multiple" | "none" = "multiple"

	@ViewChild("estado", { static: true })
	public estadoRef: TemplateRef<any>
	@Input()
	public readonly: boolean = false
	@Input()
	public omitir: Comprobante
	@ViewChild("reserva", { static: true })
	public reservaRef: TemplateRef<any>

	public totales: Totales = new Totales()
	public opcionesPunto: Descriptivo[] = []
	constructor(
		messagesService: MessagesService,
		public router: Router,
		public estadosService: EstadoDocumentoService,
		public clienteService: ClienteService,
		private confirmationService: ConfirmationService,
		public tipoComprobanteService: TipoComprobanteService,
		public service: ComprobanteService,
		public puntoDeVentaService: PuntoDeVentaService
	) {
		super(messagesService)
	}

	public updateSeleccion(seleccion: any) {
		if (seleccion?.length || seleccion["id"]) {
			this.onSelect.emit(seleccion?.length ? seleccion : [seleccion])
		}
	}
	ngOnInit() {
		this.filtro = new FiltroComprobante("comprobante_filtros_list", 0, 10, "fecha", 2, !this.readonly)
		this.columns = []

		this.columns.push({
			field: "tipoComprobante",
			header: "Tipo",
			class: "codigo-tabla",
			role: "codigo-tabla",
			width: "4em",
			sortable: true,
			order: 40
		})
		this.columns.push({
			field: "numeroComprobanteCalc",
			header: "N° Comprobante",
			class: "text-tabla",
			role: "text-tabla",
			sortable: true,
			order: 40
		})
		this.columns.push({
			field: "monedaCodigo",
			header: "Moneda",
			downloadOnly: true,
			sortable: true,
			order: 40
		})
		this.columns.push({
			field: "cotizacionAfip",
			header: "Cotizacion",
			downloadOnly: true,
			sortable: true,
			order: 40
		})
		/* {
       "field": "centroCosto",
       "header": "Centro Costo",
       "class": "descriptivo-tabla",
       "role": "descriptivo-tabla",
       "sortable": true,
       "order": 40
     });*/
		this.columns.push({
			field: "fecha",
			header: "Fecha",
			class: "date-tabla",
			role: "date-tabla",
			sortable: true,
			esMobile: true,
			order: 40
		})
		this.columns.push({
			field: "clienteStr",
			header: "Cliente",
			class: "descriptivo-tabla",
			role: "link-tabla",
			sortable: true,
			navegar: (item) => {
				if (item?.cliente) this.router.navigate(["cliente/vista"], { queryParams: { id: item.cliente.id } })
			},
			order: 40
		})
		if (this.modoSeleccion != "single") {
			this.columns.push({
				field: "reserva",
				header: "Reserva / Desc",
				class: "descriptivo-tabla reserva",
				role: "template",
				template: this.reservaRef,
				esMobile: true,
				order: 40
			})
			this.acciones = [
				new AccionesTabla(
					this.translateService.get("Aprobar"),
					"fa fa-check",
					"accion-tabla",
					(item) => {
						this.service.aprobarComprobante(item).then((e) => {
							this.filtro.forceUpdate()
							this.success("Comprobante aprobado")
						})
					},
					(item) => {
						return item.estado.codigo == "P"
					}
				),
				new AccionesTabla(
					this.translateService.get("Editar"),
					"fa fa-pencil",
					"accion-tabla",
					(item) => {
						this.editar(item.id)
					},
					(item) => {
						return item?.id && this.esEditable(item)
					}
				),
				new AccionesTabla(
					this.translateService.get("Ver"),
					"fa fa-eye",
					"accion-tabla",
					(item) => {
						this.ver(item.id)
					},
					(item) => {
						return item?.id
					}
				),
				new AccionesTabla(
					this.translateService.get("Generar cobro"),
					"fa fa-money",
					"accion-tabla",
					(item) => {
						this.generarCobranza(item)
					},
					(item) => {
						return item.saldo != 0 && item.estado.codigo != "AN"
					}
				),
				new AccionesTabla(
					this.translateService.get("Generar Nota de Crédito"),
					"fa nc",
					"accion-tabla",
					(item) => {
						this.generarNotaCredito(item)
					},
					(item) => {
						return !item.tipoComprobante?.esNota && item.estado.codigo != "AN"
					}
				),
				new AccionesTabla(
					this.translateService.get("Generar Nota de Débito"),
					"fa nd",
					"accion-tabla",
					(item) => {
						this.generarNotaDebito(item)
					},
					(item) => {
						return !item.tipoComprobante?.esNota && item.estado.codigo != "AN"
					}
				),
				new AccionesTabla(
					this.translateService.get("Anular"),
					"fa fa-trash",
					"accion-tabla",
					(item) => {
						this.eliminar(item.id)
					},
					(item) => {
						return item?.id && this.esAnulable(item)
					}
				),
				new AccionesTabla(
					"Reenviar el comprobante",
					"fa fa-envelope",
					"accion-tabla",
					(item) => {
						this.reenviarComprobante(item)
					},
					(item: ComprobanteResumen) => {
						return item?.id != undefined
					}
				),
				new AccionesTabla(
					"Reenviar comprobantes",
					"fa fa-envelope",
					"accion-tabla",
					(item) => {
						this.reenviarComprobanteMultiple(item)
					},
					(item) => {
						return Array.isArray(item) && !item?.some((i) => i?.estado?.codigo == "AN" || i?.estado?.codigo == "C")
					}
				),

				new AccionesTabla(
					this.translateService.get("Descargar"),
					"fa fa-download",
					"accion-tabla",
					(item) => {
						if (!item.adjunto) {
							this.service.descargar(item.id, moment(item.fecha).format("YYYYMMDD") + "_" + item.numeroComprobanteCalc)
						} else {
							window.open(item.adjunto.url, "_blank")
						}
					},
					(item) => {
						return item?.id != undefined
					}
				),
				new AccionesTabla(
					this.translateService.get("Ver pdf"),
					"fa fa-file-pdf-o",
					"accion-tabla",
					(item) => {
						this.service.verPDF(item.id)
					},
					(item) => {
						return item?.id != undefined
					}
				),
				new AccionesTabla(
					this.translateService.get("Clonar"),
					"fa fa-copy",
					"accion-tabla",
					(item) => {
						this.clonar(item)
					},
					(item) => {
						return item?.id != undefined
					}
				)
			]
		} else {
			this.columns.push({
				field: "reserva",
				header: "Reserva",
				class: "descriptivo-tabla reserva",
				role: "descriptivo-tabla",
				esMobile: true,
				order: 40
			})
		}

		this.columns.push({
			field: "responsable",
			header: "Responsable",
			class: "descriptivo-tabla",
			role: "descriptivo-tabla",
			sortable: true,
			order: 40
		})

		this.columns.push({
			field: "importe",
			header: "Importe",
			class: "money-tabla",
			role: "money-tabla",
			sortable: false,
			order: 40
		})
		this.columns.push({
			field: "saldo",
			header: "Saldo",
			class: "money-tabla",
			role: "money-tabla",
			sortable: false,
			order: 40
		})
		this.puntoDeVentaService.getAll().then((r) => {
			this.opcionesPunto = r
			if (this.opcionesPunto?.length == 1) {
				this.filtro.puntoDeVenta = this.opcionesPunto[0]
			}
		})
	}
	public destinatario: string
	public integrar: boolean = false
	public integrable: boolean = false
	public itemEditado: ComprobanteResumen
	public verReenviarComprobante: boolean = false
	public reenviarComprobante(item: ComprobanteResumen) {
		this.destinatario = ""
		this.itemEditado = item
		this.integrable = item.cliente.integrado && !item.integrado
		this.clienteService.getById(item.cliente.id).then((r) => {
			this.destinatario = r.emailReserva || r.emailAdministrativo || r.email
			this.verReenviarComprobante = true
		})
	}
	public reenviarComprobanteMultiple(items: ComprobanteResumen[]) {
		this.destinatarioMultiple = GenerarComprobante.ADMINISTRACION
		this.verReenviarComprobanteMultiple = true
		this.itemsEnvioMultiple = items
	}
	public enviarComprobanteMutiple() {
		this.service
			.enviarComprobantes(
				this.itemsEnvioMultiple.map((i) => i.id),
				this.destinatarioMultiple,
				this.destinatarioMultipleParticular
			)
			.then((r) => {
				this.success(this.translateService.get("LOS_COMPROBANTES_FUERON_REGISTRADOS_PARA_ENVIAR"))
				this.verReenviarComprobanteMultiple = null
				this.destinatarioMultiple = null
				this.destinatarioMultipleParticular = null
				this.itemsEnvioMultiple = []
			})
	}
	public clonar(item: ComprobanteResumen) {
		let query: NavigationExtras = {
			queryParams: {
				id: item.id,
				clonar: true
			}
		}
		this.router.navigate(["comprobante/clonar"], query)
	}
	public enviarComprobante() {
		this.service.enviarComprobante(this.itemEditado, this.destinatario, this.integrar).then((r) => {
			this.success("Se envió el comprobante")
			this.verReenviarComprobante = null
			this.destinatario = null
			this.integrar = false
			this.itemEditado = null
		})
	}
	ngOnDestroy() {}
	public esEditable = (item: Comprobante) => {
		return item.estado && (this.esAdministrador || (item.estado.codigo != "F" && item.estado.codigo != "AN")) && !item.numeroComprobante
	}
	public getData = (f, l) => {
		const omitir = this.omitir
		return this.service.getAll(f, l).then((r) => {
			return r.filter((c) => !omitir?.id || c.id != omitir?.id)
		})
	}

	public getCount = (f, l) => {
		return this.service.getTotales(f, l).then((r) => {
			this.totales = r
			return Promise.resolve(this.totales.count)
		})
	}
	public esEliminable(item: Comprobante) {
		return item.estado && (this.esAdministrador || (item.estado.codigo != "F" && item.estado.codigo != "AN"))
	}

	public esAnulable(item: Comprobante) {
		return item.estado?.codigo != "AN"
	}
	public ver(id: number) {
		let query: NavigationExtras = {
			queryParams: {
				id: id
			}
		}
		this.router.navigate(["comprobante/vista"], query)
	}
	public editar(id: number) {
		let query: NavigationExtras = {
			queryParams: {
				id: id
			}
		}
		this.router.navigate(["comprobante/edit"], query)
	}

	public verReserva(reserva: Descriptivo) {
		this.router.navigate(["reserva/vista"], { queryParams: { id: reserva.id } })
	}
	generarCobranza(item) {
		if (item.saldo == 0) return this.error(this.translateService.get("No se puede cobrar la reserva. No tiene saldo"))
		this.router.navigate(["cobranza/nuevo"], { state: { comprobantes: [item.id] } })
	}

	async generarNotaCredito(item) {
		if (!item) return

		this.router.navigate(["comprobante/nuevo"], {
			state: {
				asociado: await this.service.getById(item.id),
				tipoComprobante: await this.tipoComprobanteService.getByCodigo("NC" + item?.tipoComprobante?.letra)
			}
		})
	}

	async generarNotaDebito(item) {
		if (!item) return

		this.router.navigate(["comprobante/nuevo"], {
			state: {
				asociado: await this.service.getById(item.id),
				tipoComprobante: await this.tipoComprobanteService.getByCodigo("ND" + item?.tipoComprobante?.letra)
			}
		})
	}
	public eliminar(id: number) {
		this.confirmationService.confirm({
			key: "genConf",
			header: "Anular",
			message: this.translateService.get("ELIMINAR_COMRPOBANTE").replace("{$1}", id),
			accept: () => {
				let $this = this
				$this.service
					.eliminar(id)
					.then((res) => {
						this.filtro.forceUpdate()
						this.success(this.translateService.get("COMPROBANTE_ANULADO"))
					})
					.catch((e) => {
						this.service.getNotaGenerada(id).then((res: any[]) => {
							if (res?.length > 0) {
								this.error("Se generó una nota de crédito por este comprobante. N°: " + res[0]["numeroComprobante"] + " cae: " + res[0]["cae"])
								this.filtro.forceUpdate()
							}
						})
					})
			}
		})
	}
}
