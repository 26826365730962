<div class="listado-provider-configuration content">
	<model-list
		[service]="service"
		[modelName]="'provider-configuration'"
		[columns]="columns"
		[title]="'PROVIDER_CONFIGURATION_PLR' | translate"
		[(listado)]="listado"
		[isLazy]="true"
		[filtro]="filtro"
		[multilenguaje]="false"
		[modoSeleccion]="modoSeleccion"
		[hideHeader]="ocultarCabecera"
		[editable]="editable"
		(seleccionChange)="updateSeleccion($event)"
		[readonly]="readonly"
		[acciones]="acciones"
	>
	</model-list>
</div>
