import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import moment from "moment"
import { StringUtils } from "src/app/common/utils/string-utils"
import { Recordatorio } from "src/app/model/Recordatorio"
import { RecordatorioService } from "src/app/services/recordatorio.service"
import { IConRecordatorio } from "../IConRecordatorio"

@Component({
	selector: "abm-recordatorios",
	templateUrl: "abm-recordatorio.component.html"
})
export class AbmRecordatorioComponent implements OnInit {
	@Input()
	public tipo: string
	@Input()
	public cuerpoRecordatorio: string
	@Input()
	public cabeceraRecordatorio: string
	public get editable() {
		return !this.readonly
	}
	@Output()
	public onItemsChange: EventEmitter<Recordatorio[]> = new EventEmitter<Recordatorio[]>()
	private _itemConRecordatorios: IConRecordatorio
	public get itemConRecordatorios(): IConRecordatorio {
		return this._itemConRecordatorios
	}
	@Input()
	public set itemConRecordatorios(v: IConRecordatorio) {
		this._itemConRecordatorios = v
		if (this._itemConRecordatorios.id && (this.tipo || this._itemConRecordatorios.tipoEntidad)) {
			this.recordatoriosService.getByEntidad(this.tipo || this._itemConRecordatorios.tipoEntidad, this._itemConRecordatorios.id).then((r) => {
				this._itemConRecordatorios.recordatorios = r
			})
		}
	}

	@Input()
	public readonly: boolean = false
	@Input()
	public fechaBase: Date = new Date()

	@Input()
	public fechaParticular: Date

	ngOnInit() {}
	constructor(private recordatoriosService: RecordatorioService) {}
	agregarRecordatorio = (rec: Recordatorio) => {
		if (this.itemConRecordatorios.recordatorios.find((r) => r == rec)) return
		this.itemConRecordatorios.recordatorios = [...this.itemConRecordatorios.recordatorios, rec]
		this.onItemsChange.emit(this.itemConRecordatorios.recordatorios)
	}

	quitarRecordatorio = (item: Recordatorio) => {
		if (!item) return
		this.itemConRecordatorios.recordatorios = [...this.itemConRecordatorios.recordatorios.filter((r) => r != item)]
		this.onItemsChange.emit(this.itemConRecordatorios.recordatorios)
	}

	public newRecordatorio = () => {
		const r = new Recordatorio()
		r.fechaBase = this.fechaBase
		r.fechaProgramada = this.fechaParticular ? moment(this.fechaParticular).minutes(0).toDate() : null
		r.esFechaFija = this.fechaParticular != null
		r.tipoEntidad = this.tipo || this._itemConRecordatorios.tipoEntidad
		if (this.cuerpoRecordatorio) r.body = this.cuerpoRecordatorio
		if (this.cabeceraRecordatorio) r.cabecera = StringUtils.htmlToPlainText(this.cabeceraRecordatorio)
		return r
	}
}
