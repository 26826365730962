import { Injectable } from "@angular/core"
import { AdministracionAuthGuard } from "./AdministracionAuthGuard"

@Injectable({
	providedIn: "root"
})
export class VisualizadorProveedorAG extends AdministracionAuthGuard {
	public getRoles(): string[] {
		return super.getRoles().concat(["ROLE_MD_PROVEEDORES", "ROLE_EMPLEADO", "ROLE_PROVEEDOR", "ROL_PROVEEDOR"])
	}
}

@Injectable({
	providedIn: "root"
})
export class ListaProveedorAG extends AdministracionAuthGuard {
	public getRoles(): string[] {
		return super.getRoles().concat(["ROLE_MD_PROVEEDORES", "ROLE_EMPLEADO"])
	}
}
