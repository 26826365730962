import { Injectable } from '@angular/core';
import { AdminAuthGuard } from './AdminAuthGuard';
import { EmpleadoAuthGuard } from './EmpleadoAuthGuard';

@Injectable({
    providedIn: 'root'
})
export class ClienteExternoAG extends EmpleadoAuthGuard {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_CLIENTE"]) };



}