import { Component, OnInit } from '@angular/core';
import { HelpService } from '../../../services/help.service';

@Component({
  selector: 'app-help-factura',
  templateUrl: './help-factura.component.html',
  styleUrls: ['./help-factura.component.less']
})
export class HelpFacturaComponent implements OnInit {

  constructor(public helpService: HelpService) { }

  ngOnInit() {
  }

}
