<div class="gestor-configuracion-entorno content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'ConfiguracionEntorno' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		[file]="file"
		[conArchivo]="true"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="ConfiguracionEntornoForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #ConfiguracionEntornoForm="ngForm" id="ConfiguracionEntornoform">
				<div class="grid" *ngIf="item">
					<div class="lg:col-2 pic-wrapper col-4">
						<div class="pic">
							<profile-badge
								[seleccionable]="!readonly"
								[url]="item?.profilePic?.picPathVersion"
								class=""
								name="profilePic"
								(onFileSelected)="onPicSelected($event)"
							>
							</profile-badge>
							<i class="fa fa-pencil edit-icon" *ngIf="!readonly"></i>
						</div>
					</div>
					<div class="col-12 lg:col-4 p-fluid field">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'NOMBREENTORNO_PH' | translate }}"
								[(ngModel)]="item.nombreEntorno"
								pTooltip="{{ 'NOMBREENTORNO_TOOLTIP' | translate }}"
								type="text"
								#nombreEntorno="ngModel"
								name="nombreEntorno"
								[readonly]="readonly"
							/>
							<label>{{ "NOMBREENTORNO" | translate }}</label>
							<small *ngIf="nombreEntorno?.invalid" class="p-error block">{{ getErrorMessage(nombreEntorno) }}</small>
						</span>
					</div>
					<div class="col-12 lg:col-4 p-fluid field">
						<parametrico-selector
							[allowDefault]="false"
							[readonly]="readonly"
							#pais="ngModel"
							[static]="true"
							[(ngModel)]="item.pais"
							[service]="nacionalidadService"
							[limpiable]="false"
							[permiteNuevo]="false"
							name="pais"
							[titulo]="'NACIONALIDAD' | translate"
							required
						>
						</parametrico-selector>
					</div>
					<div class="p-fluid col-12 lg:col-2">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[allowDefault]="!item?.id"
							[service]="idiomaService"
							#idiomaPax="ngModel"
							[readonly]="readonly"
							[(ngModel)]="item.idioma"
							[limpiable]="true"
							[permiteNuevo]="false"
							name="idioma"
							[titulo]="'IDIOMA' | translate"
						>
						</parametrico-selector>
					</div>
					<div class="col-12 lg:col-3">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[service]="monedaService"
							#moneda="ngModel"
							[(ngModel)]="item.moneda"
							[limpiable]="true"
							name="moneda"
							[titulo]="'MONEDA' | translate"
							required
							[readonly]="readonly"
						>
						</parametrico-selector>
					</div>
					<div class="col-12 lg:col-3">
						<p-checkbox
							[(ngModel)]="item.mostrarSiempreImpuestos"
							label="{{ 'MOSTRAR_SIEMPRE_IMPUESTOS' | translate }}"
							binary="true"
							name="mostrarSiempreImpuestos"
							pTooltip="{{ 'MOSTRAR_SIEMPRE_IMPUESTOS_TOOLTIP' | translate }}"
							[disabled]="readonly"
							[readonly]="readonly"
						></p-checkbox>
					</div>
					<div class="col-12 seccion">
						<h3>{{ "RESERVAS" | translate }}</h3>
					</div>
					<div class="col-12 lg:col-3">
						<p-checkbox
							[(ngModel)]="item.fechaVencimientoPorDefault"
							label="{{ 'FECHA_DEFAULT_SERVICIO' | translate }}"
							binary="true"
							name="fechaVencimientoPorDefault"
							pTooltip="{{ 'FECHA_DEFAULT_SERVICIO_TT' | translate }}"
							[disabled]="readonly"
							[readonly]="readonly"
						></p-checkbox>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
