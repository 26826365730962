<div class="gestor-tipo-tarjeta-credito content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'Tipos de Tarjetas' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[file]="file"
		[conArchivo]="true"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="TipoTarjetaCreditoForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #TipoTarjetaCreditoForm="ngForm" id="TipoTarjetaCreditoform">
				<div class="grid" *ngIf="item">
					<div class="lg:col-2 pic-wrapper col-4">
						<div class="pic">
							<profile-badge [seleccionable]="!readonly" [url]="item.imagenUrl" class="" name="icono" (onFileSelected)="onPicSelected($event)">
							</profile-badge>
							<i class="fa fa-pencil edit-icon" *ngIf="!readonly"></i>
						</div>
					</div>
					<div class="col-fixed col-codigo">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'CODIGO_PH' | translate }}"
								[(ngModel)]="item.codigo"
								tooltipPosition="bottom"
								pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
								type="$TYPE"
								#codigo="ngModel"
								name="codigo"
								[readonly]="readonly"
							/>
							<label>{{ "CODIGO" | translate }}</label>
						</span>
						<error-tag [model]="codigo"></error-tag>
					</div>

					<div class="col-12 lg:col-5">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="$TYPE"
								#descripcion="ngModel"
								name="descripcion"
								[readonly]="readonly"
								required
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>

					<div class="col-fixed col-codigo">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'PESO_PH' | translate }}"
								[(ngModel)]="item.peso"
								tooltipPosition="bottom"
								pTooltip="{{ 'PESO_TOOLTIP' | translate }}"
								type="number"
								#peso="ngModel"
								name="peso"
								[readonly]="readonly"
							/>
							<label>{{ "PESO" | translate }}</label>
						</span>
						<error-tag [model]="peso"></error-tag>
					</div>
					<div class="col-12 lg:col-3 checkbox-container">
						<p-checkbox
							[(ngModel)]="item.habilitado"
							[binary]="true"
							name="habilitado"
							[disabled]="readonly"
							label="{{ 'HABILITADO' | translate }}"
						></p-checkbox>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
