<div class="gestor-punto-de-venta content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'PuntoDeVenta' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		[file]="file"
		[conArchivo]="true"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="PuntoDeVentaForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #PuntoDeVentaForm="ngForm" id="PuntoDeVentaform">
				<div class="grid" *ngIf="item">
					<div class="lg:col-2 pic-wrapper col-4">
						<div class="pic">
							<profile-badge
								[seleccionable]="!readonly"
								[url]="item?.profilePic?.picPathVersion"
								class=""
								name="profilePic"
								(onFileSelected)="onPicSelected($event)"
							>
							</profile-badge>
							<i class="fa fa-pencil edit-icon" *ngIf="!readonly"></i>
						</div>
					</div>
					<div class="col-12 lg:col-4">
						<descriptivo-material-selector
							[allowDefault]="!item?.id"
							[allowDefault]="!item?.id"
							[options]="centroCostoOptions"
							[label]="'CENTRO' | translate"
							[limpiable]="limpiable"
							[(ngModel)]="item.centro"
							[permiteNuevo]="false"
							(onNew)="nuevocentroCostoOptions()"
							name="centro"
							[readonly]="readonly"
							[required]="true"
						>
						</descriptivo-material-selector>
					</div>
					<div class="col-12 lg:col-2">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'CODIGO_PH' | translate }}"
								[(ngModel)]="item.codigo"
								tooltipPosition="bottom"
								pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
								type="$TYPE"
								#codigo="ngModel"
								name="codigo"
								[readonly]="readonly"
								required
							/>
							<label>{{ "CODIGO" | translate }}</label>
						</span>
						<error-tag [model]="codigo"></error-tag>
					</div>

					<div class="col-12 lg:col-6">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="$TYPE"
								#descripcion="ngModel"
								name="descripcion"
								[readonly]="readonly"
								required
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>

					<div class="col-12 lg:col-6">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'RAZONSOCIAL_PH' | translate }}"
								[(ngModel)]="item.razonSocial"
								tooltipPosition="bottom"
								pTooltip="{{ 'RAZONSOCIAL_TOOLTIP' | translate }}"
								type="text"
								#razonSocial="ngModel"
								name="razonSocial"
								[readonly]="readonly"
								required
							/>
							<label>{{ "RAZONSOCIAL" | translate }}</label>
						</span>
						<error-tag [model]="razonSocial"></error-tag>
					</div>
					<div class="col-12">
						<div class="grid">
							<div class="col-4 lg:col-2 md:col-3">
								<parametrico-selector
									[allowDefault]="!item?.id"
									[tipoParametro]="'tipoDocumento'"
									#tipoCliente="ngModel"
									[(ngModel)]="item.tipoDocumento"
									[permiteNuevo]="false"
									name="tipoDocumento"
									[titulo]="'TIPODOCUMENTO' | translate"
									required
									[readonly]="readonly"
								>
								</parametrico-selector>
							</div>
							<div class="col-fixed w-16rem">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'CUIT_PH' | translate }}"
										[(ngModel)]="item.cuit"
										tooltipPosition="bottom"
										pTooltip="{{ 'CUIT_TOOLTIP' | translate }}"
										type="text"
										#cuit="ngModel"
										name="cuit"
										[readonly]="readonly"
										required
									/>
									<label>{{ "CUIT" | translate }}</label>
								</span>
								<error-tag [model]="cuit"></error-tag>
							</div>
							<div class="col-fixed w-12rem p-fluid">
								<span class="p-float-label">
									<p-calendar
										[(ngModel)]="item.fechaInicioActividades"
										name="fechaInicioActividades"
										[readonlyInput]="readonly"
										[showIcon]="true"
										dateFormat="dd/mm/yy"
										[showButtonBar]="true"
										#fechaInicioActividades="ngModel"
										required
									></p-calendar>
									<label>{{ "FECHAINICIOACTIVIDADES" | translate }}</label>
								</span>
								<error-tag [model]="fechaInicioActividades"></error-tag>
							</div>
							<div class="col-12 lg:col-2" [paises-habilitados]="['AR']">
								<parametrico-selector
									[allowDefault]="!item?.id"
									[service]="tipoIngresoBrutoService"
									#tipoIngresoBruto="ngModel"
									[(ngModel)]="item.tipoIngresoBruto"
									[limpiable]="true"
									[permiteNuevo]="true"
									name="tipo-ingreso-bruto"
									[titulo]="'TIPOINGRESOBRUTO' | translate"
									[readonly]="readonly"
								>
								</parametrico-selector>
							</div>
							<div class="col-fixed w-12rem p-fluid" [paises-habilitados]="['AR']">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'IIBB_PH' | translate }}"
										[(ngModel)]="item.iibb"
										tooltipPosition="bottom"
										pTooltip="{{ 'IIBB_TOOLTIP' | translate }}"
										type="text"
										#iibb="ngModel"
										name="iibb"
										[readonly]="readonly"
										required
									/>
									<label>{{ "IIBB" | translate }}</label>
								</span>
								<error-tag [model]="iibb"></error-tag>
							</div>
							<div class="col-12 lg:col">
								<parametrico-selector
									[allowDefault]="!item?.id"
									[service]="tipoIVAService"
									#tipoIva="ngModel"
									[(ngModel)]="item.tipoIva"
									[limpiable]="true"
									[permiteNuevo]="true"
									name="tipo-iva"
									[titulo]="'TIPOIVA' | translate"
									[readonly]="readonly"
									[required]="true"
								>
								</parametrico-selector>
							</div>
						</div>
					</div>

					<div class="col-8 lg:col-10 md:col-8" style="min-width: 20rem">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DOMICILIOFISCAL_PH' | translate }}"
								[(ngModel)]="item.domicilioFiscal"
								tooltipPosition="bottom"
								pTooltip="{{ 'DOMICILIOFISCAL_TOOLTIP' | translate }}"
								type="text"
								#domicilioFiscal="ngModel"
								name="domicilioFiscal"
								[readonly]="readonly"
								required
							/>
							<label>{{ "DOMICILIOFISCAL" | translate }}</label>
						</span>
						<error-tag [model]="domicilioFiscal"></error-tag>
					</div>
					<div class="col-2 md:col-4 lg:col-2">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'CODIGOPOSTAL_PH' | translate }}"
								[(ngModel)]="item.codigoPostal"
								tooltipPosition="bottom"
								pTooltip="{{ 'CODIGOPOSTAL_TOOLTIP' | translate }}"
								type="text"
								#codigoPostal="ngModel"
								name="codigoPostal"
								[readonly]="readonly"
							/>
							<label>{{ "CODIGOPOSTAL" | translate }}</label>
						</span>
						<error-tag [model]="codigoPostal"></error-tag>
					</div>
					<div class="col-12 lg:col-4 p-fluid">
						<span class="p-float-label field p-input-icon-left">
							<i class="fa fa-phone"></i>
							<input
								pInputText
								[readonly]="readonly"
								pTooltip="{{ 'TELEFONO' | translate }}"
								#telefono="ngModel"
								type="phone"
								name="telefono"
								[(ngModel)]="item.telefono"
								tooltipPosition="bottom"
							/>
							<label>{{ "TELEFONO" | translate }}</label>
						</span>
					</div>
					<div class="col-12 lg:col-4 p-fluid">
						<span class="p-float-label field p-input-icon-left">
							<i class="fa fa-whatsapp"></i>
							<input
								pInputText
								[readonly]="readonly"
								pTooltip="{{ 'WHATSAPP' | translate }}"
								#whatsapp="ngModel"
								type="phone"
								name="whatsapp"
								[(ngModel)]="item.whatsapp"
								tooltipPosition="bottom"
							/>
							<label>{{ "WHATSAPP" | translate }}</label>
						</span>
					</div>
					<div class="col-12 lg:col-4 p-fluid">
						<span class="p-float-label field p-input-icon-left">
							<i class="fa fa-envelope"></i>

							<input
								pInputText
								[readonly]="readonly"
								pTooltip="{{ 'EMAIL_CONTACTO_TP' | translate }}"
								#emailContacto="ngModel"
								appMultiEmailValidator
								name="emailContacto"
								[(ngModel)]="item.emailContacto"
								tooltipPosition="bottom"
								type="emailContacto"
							/><label>{{ "EMAIL_DE_CONTACTO" | translate }}</label>
							<error-tag [model]="emailContacto"></error-tag>
						</span>
					</div>
					<div class="col-12 lg:col-4">
						<div class="p-inputgroup">
							<span class="p-float-label field p-input-icon-left">
								<i class="fa fa-globe"></i>
								<input
									pInputText
									placeholder="{{ 'SITIOWEB_PH' | translate }}"
									[(ngModel)]="item.sitioWeb"
									[readonly]="readonly"
									#sitioWeb="ngModel"
									name="sitioWeb"
									inputType="url"
								/>
								<label>{{ "SITIOWEB" | translate }}</label>
							</span>
						</div>
						<error-tag [model]="sitioWeb"></error-tag>
					</div>

					<h2 class="col-12">Facturación Electrónica</h2>
					<div class="col-12">
						<div class="grid">
							<div class="col-fixed field p-fluid w-20rem">
								<span-dinero-editable
									[(ngModel)]="item.maximoFacturacion"
									name="maximoFacturacion"
									#maximoFacturacion="ngModel"
									[editable]="!readonly"
									[moneda]="monedaEntorno"
									[label]="'MAXIMO_FACTURACION' | translate"
								>
								</span-dinero-editable>
								<hint>{{ "ALERTA_SUPERA_MAXIMO" | translate }}</hint>
							</div>
						</div>
					</div>

					<div class="col-12 lg:col-3" [paises-habilitados]="['AR']">
						<adjunto
							[typesAllowed]="'application/x-pkcs12'"
							[label]="'CERTIFICADO'"
							[readonly]="readonly"
							[uploadUrl]="uploadUrl"
							[item]="item"
						></adjunto>
					</div>
					<div class="col-12 lg:col-3" [paises-habilitados]="['AR']">
						<span class="p-float-label">
							<input
								pInputText
								[(ngModel)]="item.numeroPuntoVenta"
								name="numeroPuntoVenta"
								type="number"
								#numeroPuntoVenta="ngModel"
								[readonly]="readonly"
								[required]="item?.certificado != undefined"
							/>
							<label>{{ "NUMEROPUNTOVENTA" | translate }}</label>
						</span>
						<error-tag [model]="numeroPuntoVenta"></error-tag>
					</div>

					<div class="col-12 lg:col-3" [paises-habilitados]="['AR']">
						<span class="p-float-label">
							<input
								pInputText
								[(ngModel)]="item.firmanteCertificado"
								name="firmanteCertificado"
								type="text"
								#firmanteCertificado="ngModel"
								[readonly]="readonly"
								[required]="item?.certificado != undefined"
							/>
							<label>{{ "FIRMANTE_CERTIFICADO" | translate }}</label>
						</span>
						<error-tag [model]="firmanteCertificado"></error-tag>
					</div>

					<div class="col-12 lg:col-3" [paises-habilitados]="['AR']">
						<span class="p-float-label">
							<p-password
								[(ngModel)]="item.passwordCertificado"
								[toggleMask]="true"
								[feedback]="false"
								name="passwordCertificado"
								[disable]="readonly"
								#passwordCertificado="ngModel"
								[autocomplete]="false"
							></p-password>

							<label>{{ "PASSWORD_CERTIFICADO" | translate }}</label>
						</span>
						<error-tag [model]="passwordCertificado"></error-tag>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
