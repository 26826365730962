import { NgModule } from "@angular/core"
import { AccordionModule } from "primeng/accordion"
import { ConfirmationService, MessageService } from "primeng/api"
import { AutoCompleteModule } from "primeng/autocomplete"
import { BadgeModule } from "primeng/badge"
import { CalendarModule } from "primeng/calendar"
import { CardModule } from "primeng/card"
import { CarouselModule } from "primeng/carousel"
import { ChartModule } from "primeng/chart"
import { CheckboxModule } from "primeng/checkbox"
import { ChipModule } from "primeng/chip"
import { ChipsModule } from "primeng/chips"
import { ConfirmDialogModule } from "primeng/confirmdialog"
import { ContextMenuModule } from "primeng/contextmenu"
import { DataViewModule } from "primeng/dataview"
import { DialogModule } from "primeng/dialog"
import { DragDropModule } from "primeng/dragdrop"
import { DropdownModule } from "primeng/dropdown"
import { EditorModule } from "primeng/editor"
import { FileUploadModule } from "primeng/fileupload"
import { InputMaskModule } from "primeng/inputmask"
import { InputNumberModule } from "primeng/inputnumber"
import { InputSwitchModule } from "primeng/inputswitch"
import { MenuModule } from "primeng/menu"
import { MenubarModule } from "primeng/menubar"
import { MessageModule } from "primeng/message"
import { MessagesModule } from "primeng/messages"
import { MultiSelectModule } from "primeng/multiselect"
import { OrderListModule } from "primeng/orderlist"
import { OverlayModule } from "primeng/overlay"
import { OverlayPanelModule } from "primeng/overlaypanel"
import { PanelModule } from "primeng/panel"
import { PasswordModule } from "primeng/password"
import { PickListModule } from "primeng/picklist"
import { RadioButtonModule } from "primeng/radiobutton"
import { RatingModule } from "primeng/rating"
import { RippleModule } from "primeng/ripple"
import { ScrollPanelModule } from "primeng/scrollpanel"
import { SelectButtonModule } from "primeng/selectbutton"
import { SidebarModule } from "primeng/sidebar"
import { SlideMenuModule } from "primeng/slidemenu"
import { SpinnerModule } from "primeng/spinner"
import { TableModule } from "primeng/table"
import { TabViewModule } from "primeng/tabview"
import { ToastModule } from "primeng/toast"
import { ToggleButtonModule } from "primeng/togglebutton"
import { TooltipModule } from "primeng/tooltip"
import { VirtualScrollerModule } from "primeng/virtualscroller"

@NgModule({
	exports: [
		CheckboxModule,
		ConfirmDialogModule,
		DialogModule,
		CalendarModule,
		AccordionModule,
		PanelModule,
		TableModule,
		RadioButtonModule,
		MultiSelectModule,
		AutoCompleteModule,
		TabViewModule,
		ToggleButtonModule,
		PickListModule,
		ChipsModule,
		ChipModule,
		TooltipModule,
		FileUploadModule,
		InputMaskModule,
		MenubarModule,
		ScrollPanelModule,
		MenuModule,
		ContextMenuModule,
		PasswordModule,
		EditorModule,
		MessagesModule,
		MessageModule,
		ToastModule,
		CarouselModule,
		InputSwitchModule,
		DragDropModule,
		OverlayPanelModule,
		DataViewModule,
		SpinnerModule,
		DropdownModule,
		ChartModule,
		CardModule,
		SlideMenuModule,
		SelectButtonModule,
		InputNumberModule,
		SidebarModule,
		RatingModule,
		VirtualScrollerModule,
		BadgeModule,
		OrderListModule,
		OverlayModule,
		RippleModule
	],
	providers: [ConfirmationService, MessageService]
})
export class PrimeNgModule {}
