import { Component, Input, OnInit } from "@angular/core"
import { DisponibilidadDiaria } from "src/app/model/DisponibilidadDiaria"

@Component({
	selector: "disponibilidad",
	templateUrl: "./disponibilidad.component.html",
	styleUrls: ["./disponibilidad.component.less"]
})
export class DisponibilidadComponent implements OnInit {
	@Input()
	public readonly: boolean = false
	@Input()
	public disponibilidad: DisponibilidadDiaria
	@Input()
	public small: boolean

	constructor() { }

	ngOnInit() {
		this.small
	}
}
