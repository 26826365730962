<div class="grid container">
    <div class="col-12" *ngIf="!data">
        <span> Elija un atajo</span>
    </div>

    <div class="col no-pad p-fluid"  *ngIf="data">
        <button pButton label="{{data?.titulo | translate}}" class="p-button-outlined" (click)="goTo(data?.ruta)" pTooltip="{{data?.tooltip}}"></button>
       
    </div>
</div>
<p-overlayPanel #op [dismissable]="true" appendTo="body">
    <ul class="options">
        <li *ngFor="let c of menuConfService.rutasBS | async" (click)="aplicarSeleccion(c)">
            {{c.label}}
        </li>
    </ul>
</p-overlayPanel>
