import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MessagesService } from './../../common/services/messages-data-service.service';
import { TranslateService } from './../../common/services/translate.service';
import { AuthService } from './../../services/auth.service';
import { ConfiguracionEntornoService } from 'src/app/services/configuracion-entorno.service';
import { DEF_LOGO } from 'src/app/app.module';


@Component({
  selector: 'recuperar-password',
  templateUrl: './recuperar-password.component.html',
  styleUrls: ['./../login/login.component.less']
})
export class RecuperarPasswordComponent implements OnInit, OnDestroy {

  public email: string;
  public finalizado: boolean = false;
  private sub: Subscription
  public verified: string;
  public mainLogo = DEF_LOGO;
  constructor(public authService: AuthService,
    public translateService: TranslateService,
    public mService: MessagesService,
    public routing: Router,
    private confEntorno:ConfiguracionEntornoService
		) {
			this.confEntorno.getConfiguracion().then(c=>{
				this.mainLogo = c.profilePic?.picPath || DEF_LOGO;
			})
    }
  ngOnInit() {
  }
  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  public recuperarPassword() {
    this.authService.recuperarPass(this.email).then(r => {
      this.mService.success(this.translateService.get('EL_EMAIL_A_SIDO_ENV_3'))
      this.routing.navigate(["login"]);
    });
    this.finalizado = true;
  }
  public get key() {
    return environment.recaptchaKey;
  }
  public resolved(event) {
    this.verified = event;
  }


}
