<div class="listado-consentimiento content">
	<model-list
		[service]="service"
		[modelName]="'consentimiento'"
		[columns]="columns"
		[title]="'CONSENTIMIENTO_TITLE' | translate"
		[(listado)]="listado"
		[isLazy]="true"
		[filtro]="filtro"
		[permiteNuevo]="false"
		[filterContent]="customFilter"
		[borrable]="false"
		[editable]="false"
	>
		<ng-template #customFilter>
			<div class="grid">
				<div class="col-12">
					<div class="p-inputgroup">
						<span class="p-float-label">
							<input [(ngModel)]="filtro.emailContacto" pInputText placeholder="{{ 'EMAIL' | translate }}" />
							<label>{{ "EMAIL" | translate }}</label>
						</span>
						<button type="button" pButton icon="pi pi-search"></button>
					</div>
				</div>
				<div class="col-6 p-fluid p-field">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="filtro.fechaEnviadoDesde"
							name="fechaEnviadoDesde"
							dateFormat="dd/mm/yy"
							appendTo="body"
							[showIcon]="true"
							#fechaEnviadoDesde="ngModel"
							required
						></p-calendar>
						<label for="">{{ "ENVIADO_DESDE" | translate }}</label>
						<small *ngIf="getErrorMessage(fechaEnviadoDesde)" class="p-error block">{{ getErrorMessage(fechaEnviadoDesde) }}</small>
					</span>
				</div>
				<div class="col-6 p-fluid p-field">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="filtro.fechaEnviadoHasta"
							name="fechaEnviadoHasta"
							dateFormat="dd/mm/yy"
							appendTo="body"
							[showIcon]="true"
							#fechaEnviadoHasta="ngModel"
							required
						></p-calendar>
						<label for="">{{ "ENVIADO_HASTA" | translate }}</label>
						<small *ngIf="getErrorMessage(fechaEnviadoHasta)" class="p-error block">{{ getErrorMessage(fechaEnviadoHasta) }}</small>
					</span>
				</div>
				<div class="col-6 p-fluid p-field">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="filtro.fechaCompletadoDesde"
							name="fechaCompletadoDesde"
							dateFormat="dd/mm/yy"
							appendTo="body"
							[showIcon]="true"
							#fechaCompletadoDesde="ngModel"
							required
						></p-calendar>
						<label for="">{{ "COMPLETADO_DESDE" | translate }}</label>
						<small *ngIf="getErrorMessage(fechaCompletadoDesde)" class="p-error block">{{ getErrorMessage(fechaCompletadoDesde) }}</small>
					</span>
				</div>
				<div class="col-6 p-fluid p-field">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="filtro.fechaCompletadoHasta"
							name="fechaCompletadoHasta"
							dateFormat="dd/mm/yy"
							appendTo="body"
							[showIcon]="true"
							#fechaCompletadoHasta="ngModel"
							required
						></p-calendar>
						<label for="">{{ "COMPLETADO_HASTA" | translate }}</label>
						<small *ngIf="getErrorMessage(fechaCompletadoHasta)" class="p-error block">{{ getErrorMessage(fechaCompletadoHasta) }}</small>
					</span>
				</div>
				<div class="col-12 lg:col-4 checkbox-container">
					<p-checkbox [(ngModel)]="filtro.noEnviados" name="noEnviados" [binary]="true" label="{{ 'NO_ENVIADOS' | translate }}"></p-checkbox>
				</div>
				<div class="col-12 lg:col-5 checkbox-container">
					<p-checkbox [(ngModel)]="filtro.noCompletados" name="noCompletados" [binary]="true" label="{{ 'NO_COMPLETADOS' | translate }}"></p-checkbox>
				</div>
			</div>
		</ng-template>
	</model-list>
</div>
<ng-template #cliente let-data>
	<div class="grid grid-nogutter align-content-center">
		<div class="col">
			<div class="grid grid-nogutter">
				<div class="col-12 overflow">
					<span class="oneline" tooltipPosition="bottom" pTooltip="{{ data.nombrePax }}">{{ data.nombrePax }} x {{ data.cantidadPax }} </span>
				</div>
				<div class="col-12 overflow">
					<span
						class="oneline small clickable"
						tooltipPosition="bottom"
						pTooltip="{{ data.nombreCliente }}"
						(click)="goToEnt($event, 'cliente', data.cliente?.id)"
						>{{ data?.nombreCliente }}</span
					>
				</div>
			</div>
		</div>
	</div>
</ng-template>
