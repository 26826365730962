<span [ngClass]="{ 'p-float-label': !hideLabel }" style="width: 100%">
	<p-inputNumber
		[formControl]="importeForm"
		[min]="minValue"
		[max]="maxValue"
		[styleClass]="'span-dinero-editable ' + styleClass"
		[readonly]="!editable || readonly || disabled"
		(onFocus)="onFocus($event)"
		(onInput)="onInputEvent($event)"
		(onKeyDown)="onKeyDownEvent($event)"
		(onBlur)="onBlur($event)"
		[maxFractionDigits]="decimals || 2"
		mode="{{ codigoSafe == 'PPP' ? 'decimal' : 'currency' }}"
		prefix="{{ codigoSafe == 'PPP' ? moneda?.simbolo + ' ' : '' }}"
		name="importe"
		[styleClass]="styleClass"
		currency="{{ codigoSafe || defaultMoneda }}"
		[locale]="localeEnvironment"
	>
	</p-inputNumber>
	<label *ngIf="!hideLabel">{{ label | translate }}</label>
</span>
