
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor() { }
  async loadConfig() {
    const response = await fetch('assets/environment.json');
    const config = await response.json();
    Object.assign(environment, config);
  }
}
export function initConfig(configService: ConfigService) {
    return () => configService.loadConfig();
  }
