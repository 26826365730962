import { Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { EventEmitter } from "events"
import { ConfirmationService, MenuItem } from "primeng/api"
import { Subscription } from "rxjs"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Consentimiento } from "src/app/model/Consentimiento"
import { Pax } from "src/app/model/Pax"
import { ConsentimientoService } from "src/app/services/consentimiento.service"

@Component({
	selector: "gestor-consentimiento",
	templateUrl: "gestor-consentimiento.component.html",
	styleUrls: ["gestor-consentimiento.component.less"]
})
export class GestorConsentimientoComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	@Input()
	public item: Consentimiento
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false

	@Input()
	public readonly: boolean = false

	@Output()
	public onCancelado = new EventEmitter()
	@ViewChild("gestor", { static: false }) screen: ElementRef
	public menuItemAcciones: MenuItem[] = []

	public dummy = "-"

	constructor(
		messagesService: MessagesService,
		public service: ConsentimientoService,
		private route: ActivatedRoute,
		private router: Router,
		private confService: ConfirmationService
	) {
		super(messagesService)
	}

	public handleCancelar(item) {
		this.onCancelado.emit(item)
	}

	public async isValid() {
		return true
	}
	navigateToReserva(idReserva: string) {
		this.router.navigate(["/reserva/vista", { id: idReserva }])
	}
	ngOnInit() {
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
				}
			})
		)
		if (this.route.snapshot.url.some((u) => u.path == "consentimiento")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				if (!this.service) return
				if (id) {
					this.service.getById(id, null, this.translateService.idioma ? this.translateService.idioma : "ES").then((r) => {
						this.item = r
						const newPaxs = this.item.cantidadPax - (this.item.paxs?.length | 0)
						for (let i = 0; i < newPaxs; i++) {
							this.item.paxs.push(new Pax())
						}
						this.generarMenu()
					})
				} else {
					this.item = this.service ? this.service.newEnt() : null
					this.item["codigoIdioma"] = this.translateService.idioma ? this.translateService.idioma : "ES"
					this.generarMenu()
				}
			})
		}
	}
	generarMenu() {
		this.menuItemAcciones = [
			{
				label: "Imprimir",
				visible: true,
				command: () => {
					this.imprimir()
				}
			},
			{
				label: "Permitir edición",
				visible: this.item?.id != null,
				command: () => {
					this.habilitarEdicionConsentimiento(this.item.id)
				}
			}
		]
	}
	public get esEditable() {
		return this.esAdministrador
	}

	habilitarEdicionConsentimiento = (id) => {
		let item = new Descriptivo("", "", id)
		this.service.habilitarEdicion(item).then((r) => {
			if (r) {
				this.messagesService.success(this.translateService.get("Edición habilitada"))
			} else this.messagesService.error(this.translateService.get("No se pudo habilitar"))
		})
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}

	imprimir() {
		window.print()
	}
}
