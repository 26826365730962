import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';


@Directive({
    selector: '[cuitValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CuitValidator, multi: true }]
})
export class CuitValidator implements Validator {

    public static cuit(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const res = control.value ? validateCuit(control.value) : true;
            return !res ? { 'cuitInvalido': { value: control.value } } : null;
        };
    }

    validate(control: AbstractControl): { [key: string]: any } | null {
        return CuitValidator.cuit()(control);
    }


}


export function validateCuit(cuit: string) {
    return new RegExp('^[0-9]{2}-?[0-9]{8}-?[0-9]$').test(cuit);

}