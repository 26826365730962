import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-vehiculo',
  templateUrl: './help-vehiculo.component.html',
  styleUrls: ['./help-vehiculo.component.less']
})
export class HelpVehiculoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
