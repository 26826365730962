import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { Usuario } from "../common/model/Usuario"
import { AuthService } from "../services/auth.service"
import { AdminAuthGuard } from "./AdminAuthGuard"

@Injectable({
	providedIn: "root"
})
export class ProveedorExternoAG extends AdminAuthGuard {
	protected visible: boolean = false
	constructor(protected auth: AuthService, protected router: Router) {
		super(auth, router)
	}
	public getRoles(): string[] {
		return []
	}
	public esVisible(user: Usuario): boolean {
		return user && user.tieneRol(["ROLE_PROVEEDOR"]) && user.esExterno && (!this.getRoles().length || user.tieneRol(this.getRoles()))
	}
}

@Injectable({
	providedIn: "root"
})
export class MisFacturasProveedorAG extends ProveedorExternoAG {
	public getRoles(): string[] {
		return super.getRoles().concat(["ROLE_MIS_FACTURAS_PROVEEDOR"])
	}
}

@Injectable({
	providedIn: "root"
})
export class MisOPSProveedorAG extends ProveedorExternoAG {
	public getRoles(): string[] {
		return super.getRoles().concat(["ROLE_MIS_OPS"])
	}
}

@Injectable({
	providedIn: "root"
})
export class MiCuentaProveedorAG extends ProveedorExternoAG {
	public getRoles(): string[] {
		return super.getRoles().concat(["ROLE_MI_CUENTA_PROVEEDOR"])
	}
}
