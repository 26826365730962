<div class="rankin-producto content">
	<div class="model-list">
		<div class="top-section grid">
			<div class="col-12 model-title custom">
				<div class="grid">
					<div class="col-8">
						<h2 autofocus>{{ "Estadísticas de ventas" | translate }}</h2>
					</div>
				</div>
			</div>

			<div class="col-12 model-filters">
				<filtro [(filter)]="filtro" [isFixed]="false" [isMinimized]="true" [useSidebar]="true" [filterTemplateContent]="filterTemplateContent">
					<ng-template #filterTemplateContent>
						<div class="grid">
							<div class="col-12 p-fluid mt-2">
								<div class="p-inputgroup">
									<span class="p-float-label">
										<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
										<label>{{ "BUSCAR" | translate }}...</label>
									</span>
									<button type="button" pButton icon="pi pi-search"></button>
								</div>
							</div>
							<div class="col-12 field" *ngIf="!cliente">
								<descriptivo-material-selector
									[label]="'CLIENTE' | translate"
									[limpiable]="true"
									[(ngModel)]="filtro.cliente"
									name="cliente"
									isLazy="true"
									[service]="clienteService"
								>
								</descriptivo-material-selector>
							</div>
							<div class="col-12 mb-2" *ngIf="!cliente">
								<div class="grid">
									<div class="col-12">
										<label class="fw-500">{{ "TIPO_CLIENTE" | translate }}</label>
									</div>
									<div class="col">
										<p-radioButton name="tipoCliente" [value]="true" [label]="'Empresa'" [(ngModel)]="filtro.empresa"></p-radioButton>
									</div>
									<div class="col">
										<p-radioButton name="tipoCliente" [value]="false" [label]="'Particular'" [(ngModel)]="filtro.empresa"></p-radioButton>
									</div>
									<div class="col">
										<p-radioButton name="tipoCliente" [value]="null" [label]="'Todos'" [(ngModel)]="filtro.empresa"></p-radioButton>
									</div>
								</div>
							</div>
							<div class="col-12 p-fluid">
								<span class="p-float-label field">
									<input
										pInputText
										placeholder="{{ 'Cantidad de productos a mostrar' | translate }}"
										[(ngModel)]="cantidad"
										tooltipPosition="bottom"
										pTooltip="{{ 'Cantidad de productos a mostrar' | translate }}"
										type="number"
									/>
									<label>{{ "CANTIDAD" | translate }}</label>
								</span>
							</div>
							<div class="col-12 mb-2">
								<div class="grid">
									<div class="col-12">
										<label class="fw-500">{{ "Tipo prestación" | translate }}</label>
									</div>
									<div class="col">
										<p-radioButton name="tipoProd" [value]="true" [label]="'Propios'" [(ngModel)]="filtro.propios"></p-radioButton>
									</div>
									<div class="col">
										<p-radioButton name="tipoProd" [value]="false" [label]="'Externos'" [(ngModel)]="filtro.propios"></p-radioButton>
									</div>
									<div class="col">
										<p-radioButton name="tipoProd" [value]="null" [label]="'Todos'" [(ngModel)]="filtro.propios"></p-radioButton>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="grid">
									<div class="col-6">
										<span class="p-float-label p-fluid field">
											<p-calendar
												[(ngModel)]="filtro.fechaFacturacionDesde"
												name="fechaEnviadoDesde"
												dateFormat="dd/mm/yy"
												pTooltip="{{ 'FECHA_FACTURACION_DESDE' | translate }}"
												appendTo="body"
												[showIcon]="true"
												#fechaFacturacionDesde="ngModel"
											></p-calendar>
											<label>{{ "FECHA_FACTURACION_DESDE" | translate }}</label>
											<error-tag [model]="fechaEnviadoDesde"></error-tag>
										</span>
									</div>
									<div class="col-6">
										<span class="p-float-label p-fluid field">
											<p-calendar
												[(ngModel)]="filtro.fechaFacturacionHasta"
												name="fechaFacturacionHasta"
												dateFormat="dd/mm/yy"
												appendTo="body"
												[showIcon]="true"
												#fechaFacturacionHasta="ngModel"
											></p-calendar>
											<label>{{ "HASTA" | translate }}</label>
											<error-tag [model]="fechaFacturacionHasta"></error-tag>
										</span>
									</div>
								</div>
							</div>

							<div class="col-12">
								<div class="grid">
									<div class="col-6">
										<span class="p-float-label p-fluid field">
											<p-calendar
												[(ngModel)]="filtro.fechaActividadDesde"
												pTooltip="{{ 'FECHA_ACTIVIDAD_DESDE' | translate }}"
												name="fechaActividadDesde"
												#fechaActividadDesde="ngModel"
												dateFormat="dd/mm/yy"
												appendTo="body"
												[showIcon]="true"
											></p-calendar>
											<label>{{ "FECHA_ACTIVIDAD_DESDE" | translate }}</label>
											<error-tag [model]="fechaActividadDesde"></error-tag>
										</span>
									</div>
									<div class="col-6">
										<span class="p-float-label p-fluid field">
											<p-calendar
												[(ngModel)]="filtro.fechaActividadHasta"
												name="fechaActividadHasta"
												#fechaActividadHasta="ngModel"
												dateFormat="dd/mm/yy"
												appendTo="body"
												[showIcon]="true"
											></p-calendar>
											<label>{{ "HASTA" | translate }}</label>
											<error-tag [model]="fechaActividadHasta"></error-tag>
										</span>
									</div>
								</div>
							</div>
							<div class="col-12 field">
								<descriptivo-material-selector
									[label]="'Moneda' | translate"
									[limpiable]="true"
									[(ngModel)]="moneda"
									name="moneda"
									isLazy="true"
									[service]="monedaService"
								>
								</descriptivo-material-selector>
							</div>
							<div class="p-fluid col-12 field">
								<selector-multiple-chips
									[(ngModel)]="filtro.categorias"
									name="categoriasProducto"
									[servicio]="catProductoService"
									[permiteNuevo]="false"
									[limpiable]="true"
									[readonly]="readonly"
									[titulo]="'CATEGORIA_PRODUCTO' | translate"
								>
								</selector-multiple-chips>
							</div>
							<div class="col-12">
								<div class="grid">
									<div class="col-12">
										<label class="fw-500">{{ "TIPO_PRODUCTO_BUSCADO" | translate }}</label>
									</div>
									<div class="col">
										<p-radioButton
											name="tipoProductoBuscado"
											[value]="'T'"
											[label]="'TODOS' | translate"
											[(ngModel)]="filtro.tipoProductoBuscado"
										></p-radioButton>
									</div>
									<div class="col">
										<p-radioButton
											name="tipoProductoBuscado"
											[value]="'P'"
											[label]="'SOLO_PRINCIPALES' | translate"
											[(ngModel)]="filtro.tipoProductoBuscado"
										></p-radioButton>
									</div>
									<div class="col">
										<p-radioButton
											name="tipoProductoBuscado"
											[value]="'A'"
											[label]="'SOLO_ADICIONALES' | translate"
											[(ngModel)]="filtro.tipoProductoBuscado"
										></p-radioButton>
									</div>
								</div>
							</div>
							<div class="col-12 field">
								<div class="grid">
									<div class="col-12">
										<label class="fw-500">
											{{ "Mostrar valores" | translate }}
										</label>
									</div>
									<div class="col">
										<p-radioButton name="groupname" value="cantidad" label="{{ 'CANTIDAD_PAX' | translate }}" [(ngModel)]="mostrar">
										</p-radioButton>
									</div>
									<div class="col">
										<p-radioButton name="groupname" value="importe" label="{{ 'IMPORTE' | translate }}" [(ngModel)]="mostrar">
										</p-radioButton>
									</div>
								</div>
							</div>
							<div class="col-12 field">
								<div class="grid">
									<div class="col-12">
										<label class="fw-500">
											{{ "Agrupar por" | translate }}
										</label>
									</div>
									<descriptivo-material-selector
										[class]="'col-6'"
										[limpiable]="false"
										[(ngModel)]="agrupar"
										name="agrupar"
										[options]="agruparOptions"
									>
									</descriptivo-material-selector>

									<descriptivo-material-selector
										[class]="'col-6'"
										[limpiable]="true"
										[(ngModel)]="agrupar2"
										name="agrupar2"
										[options]="agruparOptions"
									>
									</descriptivo-material-selector>
								</div>
							</div>
						</div>
					</ng-template>
				</filtro>
			</div>
		</div>
		<div class="bottom-section">
			<p-tabView orientation="top" [styleClass]="'ranking-tabs'">
				<p-tabPanel header="{{ 'REPORTES' | translate }}">
					<div class="grid align-content-around" *ngIf="agrupar && !agrupar2">
						<div class="col-12 lg:col-6" *ngIf="showCharts && productos?.length > 1 && cantidad < maxGrafico">
							<div class="grid p-dir-col align-content-around">
								<div class="col-12 charts card">
									<h3 class="p-text-center p-mt-4 p-mb-4">{{ "Distribucción de venta" | translate }}</h3>
									<p-chart type="pie" [data]="dataPie" [options]="optionsPie" [plugins]="plugins" height="40vh"> </p-chart>
								</div>
								<div class="col-12 charts card">
									<h3 class="p-text-center p-mt-4 p-mb-4">{{ "Comparación de venta" | translate }}</h3>
									<p-chart type="bar" [data]="dataBars" [options]="optionsBar" [plugins]="plugins" height="40vh"></p-chart>
								</div>
							</div>
						</div>
						<div class="col">
							<div class="card listado-ranking">
								<p-dataView [value]="ranking" dataKey="id">
									<ng-template let-data let-i="rowIndex" pTemplate="listItem">
										<div class="col-12 p-mb-2">
											<div class="card">
												<div class="grid">
													<div
														class="col-8 descripcion card-container"
														[ngStyle]="{ 'border-left-color': colors[i] ? colors[i] : 'white' }"
													>
														<span class="clickable" (click)="goToItem($event, data)">{{ data?.label }}</span>
													</div>
													<div class="col-4 valor text-right">
														<span class="cantidad" *ngIf="mostrar == 'cantidad'">{{ data?.value }}</span>
														<span class="cantidad" *ngIf="mostrar == 'cantidadPaxReserva'">{{ data?.value }}</span>
														<span class="cantidad" *ngIf="mostrar == 'importe'">{{ data?.value | currencyCustom : moneda }}</span>
													</div>
												</div>
											</div>
										</div>
									</ng-template>
									<p-header>
										<div class="total">
											<h3>{{ "VENTAS" | translate }}</h3>
											<span *ngIf="mostrar == 'cantidad'">{{ "TOTAL" | translate }}: {{ total }}</span>
											<span *ngIf="mostrar == 'importe'">{{ "TOTAL" | translate }}: {{ total | currencyCustom : moneda }}</span>
										</div>
									</p-header>
								</p-dataView>
							</div>
						</div>
					</div>
					<div class="bottom-section grid" *ngIf="agrupar && agrupar2">
						<p-table [value]="ranking2" dataKey="key">
							<ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded" let-columns="columns">
								<tr class="p-widget-header" *ngIf="rowGroupMetadata[rowData.key + '-' + rowData.totalHeader].index === rowIndex">
									<td>
										<a href="#" [pRowToggler]="rowData">
											<i
												[ngClass]="expanded ? 'pi pi-fw pi-chevron-circle-down' : 'pi pi-fw pi-chevron-circle-right'"
												style="vertical-align: middle; padding-bottom: 0.1em"
												class="total"
											></i>
											<span>{{ rowData.labelHeader }}</span>
										</a>
									</td>
									<th style="background-color: white">
										<span *ngIf="mostrar == 'cantidad'" class="total-black">{{ rowData.totalHeader }}</span>
										<span *ngIf="mostrar == 'importe'" class="total-black">{{ rowData.totalHeader | currencyCustom : moneda }}</span>
									</th>
									<th style="width: 15%; background-color: white"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="rowexpansion" let-rowData let-rowIndex="rowIndex">
								<tr>
									<td style="padding-left: 4em">{{ rowData.label }}</td>
									<td *ngIf="mostrar == 'cantidad'">
										{{ rowData.value }} ({{ (rowData.value / rowData.totalHeader) * 100 | number : "1.0-2" }}%)
									</td>
									<td *ngIf="mostrar == 'importe'">
										{{ rowData.value | currencyCustom : moneda }} ({{ (rowData.value / rowData.totalHeader) * 100 | number : "1.0-2" }}%)
									</td>

									<td style="width: 20%"></td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</p-tabPanel>

				<p-tabPanel header="{{ 'DATOS' | translate }}">
					<p-table
						[columns]="cols"
						[scrollable]="true"
						[value]="productos"
						[rows]="10"
						[showCurrentPageReport]="true"
						[rowsPerPageOptions]="[10, 25, 50]"
						[paginator]="true"
					>
						<ng-template pTemplate="caption">
							<div class="p-helper-clearfix" style="text-align: left">
								<button type="button" pButton icon="pi pi-xlsx-o" iconPos="left" label="XLSX" (click)="exportToExcel()"></button>
							</div>
						</ng-template>
						<!-- <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col *ngFor="let col of cols" style="width:100px">
                            </colgroup>
                        </ng-template> -->
						<ng-template pTemplate="header">
							<tr></tr>
							<tr>
								<th *ngFor="let col of cols" tooltipPosition="bottom" pTooltip="{{ col.header }}">
									{{ col.header }}
								</th>
							</tr>
						</ng-template>

						<ng-template pTemplate="body" let-data>
							<tr>
								<td *ngFor="let col of cols">
									{{ data[col.field] }}
								</td>
							</tr>
						</ng-template>
					</p-table>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>
</div>
