import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { AccionesTabla } from 'src/app/common/model/AccionesTabla';
import { LoadingService } from 'src/app/common/services/loading-data-service.service';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { Cobranza } from 'src/app/model/Cobranza';
import { ClienteService } from 'src/app/services/cliente.service';
import { CobranzaService } from 'src/app/services/cobranza.service';
import { EstadoDocumentoService } from 'src/app/services/estado-documento.service';
import { MotivoCancelacionService } from 'src/app/services/motivo-cancelacion.service';
import { FiltroComprobante } from './../../../model/FiltroComprobantes';
import { Totales } from './../../../model/Totales';




@Component({
  selector: 'listado-cobranza',
  templateUrl: 'listado-cobranza.component.html',
  styleUrls: ['listado-cobranza.component.less']
})

export class ListadoCobranzaComponent extends SessionComponent implements OnInit, OnDestroy {

  public filtro: FiltroComprobante;
  @Input()
  public listado: Cobranza[] = [];
  @Input()
  public static: boolean = false;

  @Input()
  public readonly: boolean = false;

  @Input()
  public hideHeader: boolean = false;
  public verAnulacion: boolean = false;
  public aAnular: Cobranza;
  public columns = [
    {
      "field": "id",
      "header": "#",
      "class": "id-column",
      "role": "estado",
      "sortable": true,
      "order": 1
    },

    {
      "field": "fecha",
      "header": "Fecha",
      "class": "date-tabla",
      "role": "date-tabla",
      "sortable": true,
      "order": 40
    },

    {
      "field": "descripcion",
      "header": "Descripcion",
      "class": "text-tabla",
      "role": "text-tabla",
      "sortable": true,
      "order": 40
    },
    {
      "field": "clienteStr",
      "header": "Cliente",
      "class": "descriptivo-tabla",
      "role": "link-tabla",
      "sortable": true,
      "navegar": (item) => {
        if (item?.cliente)
          this.router.navigate(['cliente/vista'], { queryParams: { id: item.cliente.id } })
      },
      "order": 40
    },
    {
      "field": "total",
      "header": "Total",
      "class": "money-tabla",
      "role": "money-tabla",
      "sortable": false,
      "order": 40
    },
  ];

  public acciones: AccionesTabla[] = [];

  constructor(messagesService: MessagesService,
    public service: CobranzaService,
    public router: Router,
    public estadosService: EstadoDocumentoService,
    public confirmationService: ConfirmationService,
    public clienteService: ClienteService,
    public motivoService: MotivoCancelacionService) {
    super(messagesService)
  }
  ngOnInit() {
    this.filtro = new FiltroComprobante("cobranzas_lista", 0, 20, 'fecha,id', 2);
    this.acciones = [];
    this.acciones.push(new AccionesTabla("Ver Cobranza", "fa fa-eye", "accion-tabla",
      (item) => { this.ver(item.id) },
      (item: Cobranza) => { return true }
    ));
    if (!this.readonly) {
      this.acciones.push(new AccionesTabla("Aprobar Cobranza", "fa fa-check", "accion-tabla",
        (item) => { this.service.aprobar(item).then(r => { this.success(this.translateService.get("Aprobado")); this.filtro.update(true) }) },
        (item: Cobranza) => { return this.esAdministrador && item.estado?.codigo == 'P' }
      ));
      this.acciones.push(new AccionesTabla("Rechazar Cobranza", "fa fa-close", "accion-tabla",
        (item) => { this.service.rechazar(item).then(r => { this.success(this.translateService.get("Rechazado")); this.filtro.update(true) }) },
        (item: Cobranza) => { return this.esAdministrador && item.estado?.codigo == 'P' }
      ));
      this.acciones.push(new AccionesTabla("Editar Cobranza", "fa fa-pencil", "accion-tabla",
        (item) => { this.editar(item.id) },
        (item: Cobranza) => { return this.esAdministrador && item.estado?.codigo == 'P' }
      ));
      this.acciones.push(new AccionesTabla("Eliminar Cobranza", "fa fa-trash", "accion-tabla",
        (item) => { this.eliminar(item) },
        (item: Cobranza) => { return this.esAdministrador && item.estado?.codigo == 'P' }
      ));
      this.acciones.push(new AccionesTabla("Anular Cobranza", "fa fa-ban", "accion-tabla",
        (item) => { this.anular(item) },
        (item: Cobranza) => { return this.esAdministrador && (item.estado?.codigo == 'A' || item.estado?.codigo == 'C' || item.estado?.codigo == 'F') }
      ));
    }




  }
  ngOnDestroy() { }


  public totales: Totales = new Totales();

  public editar(id: number) {
    let query: NavigationExtras = {
      queryParams: {
        "id": id
      }
    }
    this.router.navigate(["cobranza/edit"], query);
  }
  public ver(id: number) {
    let query: NavigationExtras = {
      queryParams: {
        "id": id
      }
    }
    this.router.navigate(["cobranza/vista"], query);
  }

  public eliminar(c: Cobranza) {
    this.confirmationService.confirm({
      key: 'genConf',
      header: "Anular",
      message: this.translateService.get("DESEA_ELIMINAR_EL__18"),
      accept: () => {
        let $this = this;
        $this.service.eliminar(c.id).then((res) => {
          $this.filtro.forceUpdate();
          $this.verAnulacion = false;
          $this.success(this.translateService.get("EL_ITEM_FUE_ELIMINA_15"))
        });

      }
    });
  }
  public confirmarAnulacion() {
    let $this = this;
    this.service.anular(this.aAnular).then((res) => {

      $this.success(this.translateService.get("EL_ITEM_FUE_ELIMINA_15"))
      $this.verAnulacion = false;
      $this.aAnular = null;
      $this.filtro.forceUpdate();

    });
  }
  public anular(c: Cobranza) {
    let $this = this;
    this.confirmationService.confirm({
      key: 'genConf',
      header: "Anular",
      message: this.translateService.get("DESEA_ELIMINAR_EL__18"),
      accept: () => {
        $this.aAnular = c;
        $this.verAnulacion = true;


      }
    });
  }


  getData = (f: FiltroComprobante, l?: LoadingService) => {

    return this.static ? Promise.resolve(this.listado) : this.service.getResumen(f, l);
  }

  public getCount = (f, l) => {

    return this.service.getTotales(f, l).then(r => {
      this.totales = r;
      return Promise.resolve(this.totales.count);
    })

  }


}
