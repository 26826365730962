import { EstadoDocumento } from "./EstadoDocumento"
import { FilaProcesoCargaMasiva } from "./FilaProcesoCargaMasiva"
export class ProcesoCargaMasiva {
	constructor(
		public id?: number,
		public inicioProceso?: Date,
		public fechaFinProceso?: Date,
		public estado?: EstadoDocumento,
		public progreso?: number,
		public tipo?: string,
		public filasProcesadasError: FilaProcesoCargaMasiva[] = [],
		public header: string = "",
		public version?: number,
		public responsable?: string
	) {}

	public static fromData(data: any): ProcesoCargaMasiva {
		if (!data) return
		let p = new ProcesoCargaMasiva()
		p.id = data.id
		p.tipo = data.tipo
		p.inicioProceso = data.inicioProceso ? new Date(data.inicioProceso) : null
		p.fechaFinProceso = data.fechaFinProceso ? new Date(data.fechaFinProceso) : null
		p.estado = EstadoDocumento.fromData(data.estado)
		p.progreso = data.progreso
		p.filasProcesadasError = data.filasProcesadasError?.length ? data.filasProcesadasError.map((f) => FilaProcesoCargaMasiva.fromData(f)) : []
		p.header = data.header
		p.version = data.version
		p.responsable = data.responsable
		return p
	}
}
