import { Component, Input, OnInit } from "@angular/core"
import * as FileSaver from "file-saver"
import moment from "moment"
import { BehaviorSubject } from "rxjs"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Filtro } from "src/app/common/model/Filtro"
import { FilterService } from "src/app/common/services/filter-service"
import { ExcelDownloader } from "src/app/common/utils/excelDownloader"
import { FiltroReporteEncuesta } from "src/app/model/FiltroReporteEncuesta"
import { ReportePrevisionReserva } from "src/app/model/ReportePrevisionReserva"
import { HelpService } from "src/app/services/help.service"
import { NovedadService } from "src/app/services/novedades.service"
import { ProductoService } from "src/app/services/producto.service"
import { ReservaService } from "src/app/services/reserva.service"
import { LoadingService } from "./../../../common/services/loading-data-service.service"

@Component({
	selector: "reporte-prevision-servicios",
	templateUrl: "./reporte-prevision-servicios.component.html",
	styleUrls: ["./reporte-prevision-servicios.component.less"]
})
export class ReportePrevisionServiciosComponent implements OnInit {
	@Input()
	filtro: FiltroReporteEncuesta = new FiltroReporteEncuesta("filtro-prevision")
	@Input()
	isHome: boolean = false
	listado: ReportePrevisionReserva[] = []
	dias: Date[] = []
	meses: {
		mes: string
		span: number
	}[] = []

	valores: {
		producto: Descriptivo
		fechas: {
			fecha: Date
			cantidad: number
		}[]
	}[] = []
	public productos = new BehaviorSubject<Descriptivo[]>([])

	hoveredItem: any
	public productosLista = []
	constructor(
		private reservaService: ReservaService,
		private novedadesService: NovedadService,
		public productoService: ProductoService,
		private helpService: HelpService,
		public excelDownloader: ExcelDownloader,
		public loadingService: LoadingService,
		public filtroService: FilterService
	) {
		this.filtro = FiltroReporteEncuesta.fromData("filtro-previsor", filtroService.load("filtro-previsor"))
	}

	ngOnInit(): void {
		this.novedadesService.registrarObservador("reserva", {
			getKey: () => "reserva",
			next: (n) => {
				this.reservaService.getById(n.idEntidad).then((r) => {
					r.itemsReserva.forEach((i) => {
						if (moment(i.fechaActividad).isBefore(moment(this.filtro.fechaHasta)) && moment(i.fechaActividad).isAfter(moment(this.filtro.fecha))) {
							let valor = this.valores.find((v) => v.producto.id === i.producto.id)

							if (valor) {
								valor.fechas.find((f) => moment(f.fecha).isSame(i.fechaActividad, "day")).cantidad += i.cantidadPax
							} else {
								this.valores.push({
									producto: i.producto,
									fechas: this.dias.map((d) => {
										return {
											fecha: d,
											cantidad: moment(d).isSame(i.fechaActividad, "day") ? i.cantidadPax : 0
										}
									})
								})
							}
						}
					})
				})
			},
			destroy: () => {
				this.novedadesService.unregister("reserva", null, null, null, "reserva")
			}
		})

		if (!this.isHome) {
			this.filtro.fechaHasta = moment().endOf("day").toDate()
			this.filtro.fecha = moment().subtract(14, "days").startOf("day").toDate()
		}
		this.filtro.filterChange.subscribe((f) => {
			this.dias = []
			this.listado = []
			this.valores = []
			this.meses = []
			for (let d = new Date(this.filtro.fecha); d <= this.filtro.fechaHasta; d.setDate(d.getDate() + 1)) {
				this.dias.push(new Date(d))

				let m = this.meses.find((m) => m.mes === moment(d).locale("es").format("MMMM"))
				if (m) {
					m.span++
				} else {
					this.meses.push({
						mes: moment(d).locale("es").format("MMMM"),
						span: 1
					})
				}
			}

			if (this.filtro.fecha && this.filtro.fechaHasta) {
				this.loadingService.addLoadingCount()
				this.reservaService.getReportePrevision(this.filtro).then((x) => {
					this.listado = x
					this.valores = []
					this.productoService.getData().then((productos) => {
						productos
							.filter(
								(p) =>
									(this.filtro.productos?.length == 0 || this.filtro.productos.some((p2) => p2.id === p.id)) &&
									(this.filtro.soloConAsignacion ? this.listado.some((l) => l.producto.id === p.id) : true)
							)
							.forEach((p) => {
								this.valores.push({
									producto: p,
									fechas: this.dias.map((d) => {
										return {
											fecha: d,
											cantidad: 0
										}
									})
								})
								this.dias.forEach((d) => {
									let item = this.listado.find((v) => v.producto.id === p.id && moment(v.fecha).isSame(d, "day"))
									if (item) {
										this.valores.find((v) => v.producto.id === p.id).fechas.find((f) => moment(f.fecha).isSame(d, "day")).cantidad +=
											item.cantidad
									}
								})
							})
						this.valores = [...this.valores]
						this.loadingService.susLoadingCount()
					})
				})
			}
		})

		this.productoService.getHabilitados().then((r) => {
			this.productos.next(r)
		})
	}

	onHover(product, item) {
		this.valores.forEach((v) => (v["hover"] = false))
		product["hover"] = true
		this.hoveredItem = item
	}

	onOffHover() {
		this.valores.forEach((v) => (v["hover"] = false))
		this.hoveredItem = null
	}

	public goToHelp() {
		this.helpService.goToHelp("reporte-prevision-servicios")
	}

	exportExcel() {
		const tablaDatos = this.valores.map((producto) => {
			const filaProducto: any = {
				producto: producto.producto.descripcion
			}

			this.dias.forEach((fechaUnica) => {
				const fechaEncontrada = producto.fechas.find((f) => f.fecha.getTime() === fechaUnica.getTime())
				filaProducto[moment(fechaUnica).format("DD/MM/YYYY")] = fechaEncontrada ? fechaEncontrada.cantidad : ""
			})

			return filaProducto
		})

		import("xlsx").then((xlsx) => {
			const worksheet = xlsx.utils.json_to_sheet(tablaDatos)
			const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] }
			const excelBuffer: any = xlsx.write(workbook, { bookType: "xlsx", type: "array" })
			this.saveAsExcelFile(excelBuffer, "datos")
		})
	}
	saveFiltro(filtro: Filtro) {
		filtro != undefined && this.filtroService.save(filtro)
	}
	saveAsExcelFile(buffer: any, fileName: string): void {
		let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
		let EXCEL_EXTENSION = ".xlsx"
		const data: Blob = new Blob([buffer], {
			type: EXCEL_TYPE
		})
		FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION)
	}
}
