<div class="profile-badge {{class}}" tooltipPosition="bottom" pTooltip="{{tooltip}}">
    <div class="selected-overlay" *ngIf="selected" [@appear]>
        <i class="fa fa-check selected"></i>
    </div>

    <div class="badge  {{class}} " [ngClass]="{'clickable': seleccionable && !disabled}"
        [ngStyle]="{'background-image': ' url('+(profilePic? profilePic: defaultImage) +')'}"
        (click)="seleccionable && !disabled ? inputFile.click(): ''"></div>
</div>
<input type="file" (change)="handleFileInput($event.target.files)" #inputFile name="inputfile" [hidden]="true"
    accept="image/*">
