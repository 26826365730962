<div class="grid container">
    <div class="col-12" *ngIf="!data">
        <span> Elija un listado</span>
    </div>

    <div class="col-12" *ngIf="data">
        <div class="p-inputgroup">

            <input [formControl]='buscarControl' name="search" autocomplete="off" pInputText
                tooltipPosition="bottom" pTooltip="{{'Buscar precio' | translate}}" type="text">
            <span class="p-inputgroup-addon"><i class="fa fa-search" style="line-height: 1.25;"></i></span>
        </div>

    </div>
    <div class="precios-lista">
        <p-scrollPanel [style]="{width: '100%', height:  'calc(100% - 3em)'}">
            <div class="card" *ngFor="let prod of productosFiltrados" (click)="irAProducto($event, prod.producto?.id)">
                <div class="grid">
                    <div class="col overflow">
                        <span class="desc clickable "
                            tooltipPosition="bottom" pTooltip="{{prod?.producto?.descripcion}}">{{prod?.producto?.descripcion}}</span>
                    </div>

                    <div class="col text-right" style="max-width: 10em;">
                        <span *ngIf="prod?.precioRango"
                            class="valor">{{prod?.precio | currency : 'USD' : "symbol-narrow"}}</span>
                        <span *ngIf="!prod?.precioRango" class="valor">{{'SIN_PRECIO_DEFINIDO' | translate}}</span>
                    </div>

                </div>
            </div>
        </p-scrollPanel>
    </div>
</div>
<p-overlayPanel #op [dismissable]="true" appendTo="body">
    <descriptivo-material-selector [options]=" lpService.data | async | habilitados"
        [label]="'LISTA_DE_PRECIOS' | translate" [(ngModel)]="listado" (onSelect)="seleccionarListado($event)"
        [permiteNuevo]="false" name="listaPrecio" [required]="true">
    </descriptivo-material-selector>
</p-overlayPanel>
