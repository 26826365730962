<div class="listado-producto-publicado content">
	<model-list [service]="service" [modelName]="'producto-publicado'" [columns]="columns"
		[title]="'PRODUCTO_PUBLICADO_PLR' | translate" [(listado)]="listado" [isLazy]="true" [filtro]="filtro"
		[multilenguaje]="false" [modoSeleccion]="modoSeleccion" [hideHeader]="ocultarCabecera" [editable]="editable"
		(seleccionChange)="updateSeleccion($event)" [readonly]="readonly" [acciones]="acciones">
	</model-list>
</div>

<p-dialog header="Integraciones" [(visible)]="isVerIntegraciones" appendTo="body" [modal]="true"
	[style]="{ width: '60vw' }" *ngIf="integraciones">
	<p-accordion [multiple]="true">
		<p-accordionTab *ngFor="let prov of providers">
			<ng-template pTemplate="header">
				{{ prov.descripcion }}
				<span *ngIf="!integracionProvider(prov.codigo)">
					- {{ "SIN_INTEGRAR" | translate }}
				</span>
				<span *ngIf="integracionProvider(prov.codigo)">
					- {{ (integracionProvider(prov.codigo)?.habilitado? "HABILITADO" : "NO_HABILITADO") |
					translate }}
					- {{ (integracionProvider(prov.codigo)?.habilitado ==
					integracionProvider(prov.codigo)?.habilitadoExterno? "PUBLICACION_ACTUALIZADA" :
					"PUBLICACION_NO_ACTUALIZADA") |
					translate }}
				</span>
				<button pButton (click)="integrar(prov)" style="position: absolute; right: 60px">{{ "INTEGRAR" |
					translate }}</button>
			</ng-template>
			<ng-template pTemplate="content">
				<p-table [value]="integracionProvider(prov.codigo).idiomas" *ngIf="integracionProvider(prov.codigo)">
					<ng-template pTemplate="header">
						<tr>
							<th style="width: 33%">{{ "IDIOMA" | translate }}</th>
							<th style="width: 33%">{{ "PUBLICACION_ACTUALIZADA" | translate }}</th>
							<th style="width: 33%">{{ "ESTADO" | translate }}</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-idi let-i="rowIndex">
						<tr>
							<td>
								{{ idi?.idioma?.codigo }}
							</td>
							<td>
								{{ idi?.actualizado }}
							</td>
							<td>
								{{ idi?.estado }}
							</td>
						</tr>
					</ng-template>
				</p-table>
				<span *ngIf="!integracionProvider(prov.codigo)">{{ "SIN_INTEGRAR" | translate }}</span>
			</ng-template>
		</p-accordionTab>
	</p-accordion>
</p-dialog>