import { FileData } from "src/app/model/FileData"
import { ItemReserva } from "src/app/model/ItemReserva"
import { Moneda } from "src/app/model/Moneda"
import { MotivoCancelacion } from "src/app/model/MotivoCancelacion"
import { Cobro } from "src/app/model/cobros/Cobro"
import { IConCobros } from "./../../../common/components/abm-cobros/IConCobros"
export class CancelacionReserva implements IConCobros {
	get tipoOperacion(): "COMPRA" | "VENTA" {
		return "VENTA"
	}
	public get valores(): Cobro[] {
		return this.devoluciones
	}
	public set valores(v: Cobro[]) {
		this.devoluciones = v
	}

	constructor(
		public idReserva?: number,
		public motivo?: MotivoCancelacion,
		public devoluciones: Cobro[] = [],
		public moneda?: Moneda,
		public saldo: number = 0,
		public items: ItemReserva[] = [],
		public adjunto?: FileData
	) {}
	get total(): number {
		return this.devoluciones.reduce((a, b) => a + b.monto, 0)
	}
	get totalALocal(): number {
		return this.devoluciones.reduce((a, b) => a + b.montoALocal, 0)
	}
	getSaldo(): number {
		return this.saldo - this.totalALocal
	}
	reemplazarCobro(item: Cobro, item2: Cobro) {
		if (!item && this.devoluciones.indexOf(item2) < 0) {
			this.devoluciones.push(item2)
		} else {
			var i = this.devoluciones.indexOf(item)
			if (i >= 0 && item2) {
				this.devoluciones[i] = item2
			}
		}
	}
	public agregarCobro(item: Cobro) {
		if (!this.devoluciones.some((ec) => ec == item)) {
			this.devoluciones.push(item)
		}
	}
	public quitarCobro(item: Cobro) {
		this.devoluciones = this.devoluciones.filter((i) => i != item)
	}
	newCobro = () => {
		return new Cobro()
	}

	public buscarCobro(ref) {
		return this.devoluciones.find((c) => c["refFactura"] == ref)
	}
}
