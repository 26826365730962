import { IServicioConEventos } from './IServicioConEventos';
import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { Nota } from 'src/app/model/Nota';
import { share } from 'rxjs/operators';
import { FiltroFecha } from '../common/model/FiltroFecha';
import { Evento } from '../model/Evento';
import { lastValueFrom } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class NotaService extends ServicioAbstract<Nota> implements IServicioConEventos{
    public baseName(): string {
        return "nota";
    }
    public parseToEnt(data: any): Nota {
        return Nota.fromData(data);
    }
    public newEnt(): Nota {
        let n = new Nota();
        n.fechaNota = new Date();
        return n;
    }

    public getEventos(filtro: FiltroFecha): Promise<Evento[]>  {
        return lastValueFrom(this.http.post(this.getBaseURL('getEventos'), filtro.json).pipe(share())
        ).then((r: any[]) => {
            return r.map( event => Evento.fromData(event))
        }, (e) => this.handleError(e));
    }


}