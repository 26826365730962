import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { first } from "rxjs/operators"
import { ItemReservaConsentimiento } from "src/app/model/ItemReservaConsentimiento"
import { IActividad } from "../../consentimiento-actividades/IActividad"
import { VoucherService } from "./voucher.service"

@Component({
	selector: "voucher-actividad",
	templateUrl: "./voucher-actividad.component.html",
	styleUrls: ["./voucher-actividad.component.less"]
})
export class VoucherActividadComponent implements OnInit {
	public grupo: IActividad
	public itemReservaConsentimiento: ItemReservaConsentimiento
	public tipoVoucher: string
	voucherInterno = false

	constructor(private route: ActivatedRoute, private voucherService: VoucherService) {}

	ngOnInit() {
		this.route.data.subscribe((u) => {
			if (u?.voucherInterno) {
				this.voucherInterno = true
			} else {
				this.voucherInterno = false
			}
		})

		this.route.params.pipe(first()).subscribe((params) => {
			if (params["token"] && params["id"]) {
				const id = params["id"]
				const token = params["token"]
				this.tipoVoucher = params["tipoVoucher"] || "E"
				switch (this.tipoVoucher) {
					case "F": {
						this.voucherService.getFamiliaByTokenAndId(token, id).then((g) => (this.grupo = g))
						break
					}
					case "H": {
						this.voucherService.getHotelByTokenAndId(token, id).then((g) => (this.itemReservaConsentimiento = g))
						break
					}
					case "G": {
						this.voucherService.getGrupoByTokenAndId(token, id).then((g) => (this.grupo = g))
						break
					}
					default: {
						break
					}
				}
			}
		})
	}
}
