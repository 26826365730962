
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { Component, OnDestroy, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { ColumnGenerator } from 'src/app/common/utils/ColumnGenerator';
import { TagUsuario } from 'src/app/model/TagUsuario';
import { TagUsuarioService } from 'src/app/services/tag-usuario.service';
import { LoadingService } from 'src/app/common/services/loading-data-service.service';

@Component({
	selector: 'listado-tag-usuario',
	templateUrl: 'listado-tag-usuario.component.html',
	styleUrls: ['listado-tag-usuario.component.less']
})

export class ListadoTagUsuarioComponent extends SessionComponent implements OnInit, OnDestroy {

	public filtro: Filtro;
	
	@Input()
	public guardarFiltro: boolean = true;	
	@Input()
	public ocultarCabecera: boolean = false;  	
	@Input()
	public editable : boolean = true;
	public listado: TagUsuario[] = [];
	@Input()
  	public readonly: boolean = false;
  	@Input()
  	public modoSeleccion: 'single' | 'multiple' | 'none' = 'none';
  	
  	@Output()
  	public onSelect: EventEmitter<TagUsuario[]> = new EventEmitter();
  	
	public columns = [];
	constructor(messagesService : MessagesService,
				public service: TagUsuarioService) {
		super(messagesService)
		console.log(JSON.stringify(this.columns));
	}
	ngOnInit() { 
	this.filtro = new Filtro("tag-usuario_lista", {}, 0, 20,'id',1,this.guardarFiltro)
		this.columns = [

  {
    "field": "id",
    "header": "#",
    "class": "id-column",
    "role": "text-tabla",
    "sortable": true,
    "order": 1
  },
  {
    "field": "descripcion",
    "header": "Descripcion",
    "class": "text-tabla",
    "role": "text-tabla",
    "sortable": true,
    "order": 3
  }
 
];
	}
	
	 public updateSeleccion(seleccion: any) {
	    if (seleccion?.length || seleccion['id']) {
	      this.onSelect.emit(seleccion?.length ? seleccion : [seleccion]);
	    }
	  }
	ngOnDestroy() { }

	
	
}