import { SubcategoriaImponibleItem } from "./SubcategoriaImponibleItem"
export interface IConImpuestos {
	get esSubcategorizado(): boolean
	get subcategorias(): SubcategoriaImponibleItem[]
	get importe(): number
	get cantidad(): number
	get ajuste(): number
	get multiplicadorIVA(): number
	get impuestosAdicionales(): number
	get incluyeImpuestos(): boolean
}
export class CalculadoraImpuestos {
	static calcularImpuestos(vo: IConImpuestos): number {
		let impuesto = 0
		if (vo.esSubcategorizado) {
			if (vo.subcategorias) {
				for (const s of vo.subcategorias) {
					const base = vo.importe * vo.cantidad * s.multiplicadorProporcion
					if (vo.incluyeImpuestos) {
						impuesto += base - base / (1 + vo.multiplicadorIVA)
					} else {
						impuesto += base * s.multiplicadorIVA
					}
				}
			}
		} else {
			const base = vo.importe * vo.cantidad
			if (vo.incluyeImpuestos) {
				impuesto += base - base / (1 + vo.multiplicadorIVA)
			} else {
				impuesto = base * vo.multiplicadorIVA
			}
		}
		return (1 + vo.ajuste / 100) * (impuesto + vo.impuestosAdicionales)
	}
}
