
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { ColumnGenerator } from 'src/app/common/utils/ColumnGenerator';
import { Moneda } from 'src/app/model/Moneda';
import { MonedaService } from 'src/app/services/moneda.service';
import { LoadingService } from 'src/app/common/services/loading-data-service.service';

@Component({
	selector: 'listado-moneda',
	templateUrl: 'listado-moneda.component.html',
	styleUrls: ['listado-moneda.component.less']
})

export class ListadoMonedaComponent extends SessionComponent implements OnInit, OnDestroy {

	public filtro: Filtro = new Filtro("moneda_filtros", {}, 0, 10);
	public listado: Moneda[] = [];
	public columns = [];
	constructor(messagesService : MessagesService,
				public service: MonedaService) {
		super(messagesService)
		console.log(JSON.stringify(this.columns));
	}
	ngOnInit() { 
		this.columns = [
  {
    "field": "codigo",
    "header": "Codigo",
    "class": "codigo-tabla",
    "role": "text-tabla",
    "sortable": true,
    "order": 1
  },
  {
    "field": "id",
    "header": "#",
    "class": "id-column",
    "role": "text-tabla",
    "sortable": true,
    "order": 1
  },
  {
    "field": "descripcion",
    "header": "Descripcion",
    "class": "text-tabla",
    "role": "text-tabla",
    "sortable": true,
    "order": 3
  },
  {
    "field": "simbolo",
    "header": "Simbolo",
    "class": "text-tabla",
    "role": "text-tabla",
    "sortable": true,
    "order": 40
  },
  {
    "field": "esDefault",
    "header": "Es Default",
    "class": "is-boolean",
    "role": "boolean-tabla",
    "sortable": false,
    "order": 40
  },
  {
    "field": "codigoAfip",
    "header": "Codigo Afip",
    "class": "text-tabla",
    "role": "text-tabla",
    "sortable": true,
    "order": 40
  },
  {
    "field": "habilitado",
    "header": "Habilitado",
    "class": "is-boolean",
    "role": "boolean-tabla",
    "sortable": false,
    "order": 99
  }
];
	}
	ngOnDestroy() { }

	
	
}