import { AsociacionInsumo } from './AsociacionInsumo';
import { Insumo } from './Insumo';



export class VehiculoInsumo implements AsociacionInsumo {
	constructor(
		public id?: number,
		public insumo?: Insumo,
		public proporcion?: number,
		public cantidad?: number,

	) {
	}
	public static fromData(data: any): VehiculoInsumo {
		if (!data) return null;
		const o: VehiculoInsumo = new VehiculoInsumo(

			data.id,
			Insumo.fromData(data.insumo),
			data.proporcion,
			data.cantidad,
		);

return o;

	}

}