import { Descriptivo } from "src/app/common/model/Descriptivo"
import { EstadoMovimientoCaja } from "src/app/model/EstadoMovimientoCaja"

export class TraspasoDeCaja {
	constructor(
		public id?: number,
		public fecha: Date = new Date(),
		public descripcion?: string,
		public responsable?: Descriptivo,
		public cajaOrigen?: Descriptivo,
		public cajaDestino?: Descriptivo,
		public formaPagoDestino?: Descriptivo,
		public formaPagoOrigen?: Descriptivo,
		public monto?: number,
		public costo?: number,
		public estado?: Descriptivo,
		public motivoRechazo?: string,
		public cotizacion: number = 1
	) {}
	public static fromData(data: any): TraspasoDeCaja {
		if (!data) return null
		const o: TraspasoDeCaja = new TraspasoDeCaja(
			data.id,
			data.fecha ? new Date(data.fecha) : new Date(),
			data.descripcion,
			Descriptivo.fromData(data.responsable),
			Descriptivo.fromData(data.cajaOrigen),
			Descriptivo.fromData(data.cajaDestino),
			Descriptivo.fromData(data.formaPagoDestino),
			Descriptivo.fromData(data.formaPagoOrigen),
			data.monto,
			data.costo,
			Descriptivo.fromData(data.estado),
			data.motivoRechazo,
			data.cotizacion || 1
		)
		return o
	}
	get montoEfectivo() {
		return this.monto ? this.monto * this.cotizacionSafe - (this.costo || 0) : 0
	}
	get cotizacionSafe() {
		return this.cotizacion || 1
	}
	esPendiente() {
		return this.estado?.codigo == EstadoMovimientoCaja.PENDIENTE
	}

	esAprobado() {
		return this.estado?.codigo == EstadoMovimientoCaja.APROBADO
	}

	esRechazado() {
		return this.estado?.codigo == EstadoMovimientoCaja.RECHAZADO
	}
}
