import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
	name: "visible"
})
export class VisiblePipe implements PipeTransform {
	transform(value: any[]): any {
		return value && value.filter((v) => !v.downloadOnly)
	}
}
