import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { Restriccion } from "src/app/model/Restriccion"
import { MessagesService } from "../common/services/messages-data-service.service"
import { LoadingService } from "./../common/services/loading-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class RestriccionService extends ParametricoService<Restriccion> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "restriccion"
	}
	public parseToEnt(data: any): Restriccion {
		return Restriccion.fromData(data)
	}
	public newEnt(): Restriccion {
		return new Restriccion()
	}
	public override esMultilenguaje() {
		return true
	}
	public actualizarDescripcion(data: Restriccion, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		var p = lastValueFrom(this.http.put(this.getBaseURL("actualizar-descripcion"), data))
		p.then((r) => {
			this.loadData(customLoading)
			if (customLoading) {
				customLoading.susLoadingCount()
			} else this.loadingService.susLoadingCount()
		})
		return p
	}
}
