import { Descriptivo } from "src/app/common/model/Descriptivo"
import { FormaPago } from "src/app/model/FormaPago"
import { Moneda } from "src/app/model/Moneda"
import { TipoCuotasTarjeta } from "src/app/model/TipoCuotasTarjeta"
import { FileData } from "../FileData"
import { TipoTarjetaCredito } from "../TipoTarjetaCredito"
import { CobroConCaja } from "./CobroConCaja"

export class CobroTarjetaCredito extends CobroConCaja {
	public static TIPO = "T"

	constructor(
		id?: number,
		formaPago?: FormaPago,
		comprobante?: string,
		monto?: number,
		caja?: Descriptivo,
		moneda?: Moneda,
		cotizacion: number = 1,
		public tipoTarjeta?: TipoTarjetaCredito,
		public cantidadCuotas?: TipoCuotasTarjeta,
		public numeroTarjeta?: string,
		public idPago?: string,
		adjunto?: FileData
	) {
		super(id, formaPago, comprobante, monto, caja, moneda, cotizacion, adjunto)
		this.tipoCobro = CobroTarjetaCredito.TIPO
	}

	public static fromData(data: any): CobroTarjetaCredito {
		if (!data) return null
		const o: CobroTarjetaCredito = new CobroTarjetaCredito(
			data.id,
			FormaPago.fromData(data.formaPago),
			data.comprobante,
			data.monto,
			Descriptivo.fromData(data.caja),
			Moneda.fromData(data.moneda),
			data.cotizacion,
			TipoTarjetaCredito.fromData(data.tipoTarjeta),
			TipoCuotasTarjeta.fromData(data.cantidadCuotas),
			data.numeroTarjeta,
			data.idPago,
			FileData.fromData(data.adjunto)
		)

		return o
	}
}
