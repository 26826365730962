import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { share } from 'rxjs/operators';
import { Disponibilidad } from 'src/app/model/Disponibilidad';
import { GastoPuesto } from 'src/app/model/GastoPuesto';
import { OpcionPuesto } from 'src/app/model/OpcionPuesto';
import { PuestoServicioReserva } from 'src/app/model/PuestoServicioReserva';
import { AusenciaService } from 'src/app/services/ausencia.service';
import { DisponibilidadService } from 'src/app/services/disponibilidad.service';
import { ProveedorService } from 'src/app/services/proveedor.service';
import { BaseService } from '../common/services/base.service';
import { AprobacionProveedor } from '../model/AprobacionProveedor';
import { Ausencia } from '../model/Ausencia';
import { Pax } from '../model/Pax';
import { TipoPuesto } from '../model/TipoPuesto';
import { environment } from './../../environments/environment';
import { LoadingService } from './../common/services/loading-data-service.service';
import { MessagesService } from './../common/services/messages-data-service.service';
import { ConfirmacionReservaPuesto } from './../model/ConfirmacionReservaPuesto';
import { OpcionPuestoOcupacion } from './../model/OpcionPuestoOcupacion';
import { FiltroPuesto } from './../pages/proveedor/FiltroPuesto';
@Injectable({
    providedIn: 'root',
})
export class PuestoServicioReservaService extends BaseService {
   

    asignacionBs: BehaviorSubject<boolean> = new BehaviorSubject(null)
    public baseName(): string {
        return 'puesto-servicio-reserva'
    }
    constructor(
        messages?: MessagesService,
        public loadingService?: LoadingService,
        private ausenciasService?: AusenciaService,
        private disponibilidadService?: DisponibilidadService,
        private proveedorService?: ProveedorService
    ) {
        super(messages)
    }

    getByPersonal(filtro: FiltroPuesto, customLoading?: LoadingService): Promise<GastoPuesto[]> {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http
            .post(this.getBaseURL('byPersonal'), filtro.json)
            .pipe(share()))
            .then(
                (r: any) => {
                    if (customLoading) {
                        customLoading.susLoadingCount()
                    } else this.loadingService.susLoadingCount()
                    return  filtro.apply(r ? r.map((p) => GastoPuesto.fromData(p)) : [])
                },
                (e) => this.handleError(e, customLoading)
            )
    }
    getApiURL() {
        return environment.apiUrl
    }
    public asignarConfirmacion(confirmacion:ConfirmacionReservaPuesto): Promise<ConfirmacionReservaPuesto> {
        return lastValueFrom(this.http
            .post(this.getBaseURL('asignarConfirmacion'), confirmacion))
            .then((r) => {
                this.asignacionBs.next(true)
                return r ? ConfirmacionReservaPuesto.fromData(r) : null
            })
    }
    public desasignarConfirmacion(confirmacion:ConfirmacionReservaPuesto): Promise<ConfirmacionReservaPuesto> {
        return lastValueFrom(this.http
            .post(this.getBaseURL('desasignarConfirmacion'), confirmacion))
            .then((r) => {
                this.asignacionBs.next(true)
                return r ? ConfirmacionReservaPuesto.fromData(r) : null
            })
    }

    public asignar(puesto:PuestoServicioReserva): Promise<PuestoServicioReserva> {
        return lastValueFrom(this.http
            .post(this.getBaseURL('asignar'), puesto))
            .then((r) => {
                this.asignacionBs.next(true)
                return r ? PuestoServicioReserva.fromData(r) : null
            })
    }
    public desasignar(puesto:PuestoServicioReserva): Promise<PuestoServicioReserva> {
        return lastValueFrom(this.http
            .post(this.getBaseURL('desasignar'), puesto))
            .then((r) => {
                this.asignacionBs.next(true)
                return r ? PuestoServicioReserva.fromData(r) : null
            })
    }

    getOcupacion(fecha: Date) {
        return lastValueFrom(this.http
            .get(this.getBaseURL() + 'getOcupacion/' + fecha))
            .then((r: any) => {
                return r?.length ? r.map((a) => OpcionPuestoOcupacion.fromData(a)) : []
            })
    }

    eliminar(id: number, customLoading?: LoadingService) {

        if (customLoading) { customLoading.addLoadingCount(); }
        else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.delete(this.getBaseURL() + id))            
            .then((r) => this.handleOk(r, customLoading)).catch((e) => this.handleError(e, customLoading)).finally(()=>{
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
            });
    }

    buscarPuestos(fecha: Date, puesto: TipoPuesto, _ausencias?: Ausencia[], _disponibilidad?: Disponibilidad[]): Promise<OpcionPuesto[]> {
        let p = []
        let ocupacion = []
        let opciones: OpcionPuesto[] = []
        let ausencias : Ausencia[] = _ausencias?.length ?_ausencias:[];
        let disponibilidad : Disponibilidad[] = _disponibilidad?.length? _disponibilidad:[];
        p.push(
            this.proveedorService.getOpcionesPuesto(puesto?.id).then((os) => {
                opciones = os
            })
        )
        if (fecha) {
            p.push(
                this.getOcupacion(fecha).then((o) => {
                    ocupacion = o
                })
            )
            let f = new FiltroPuesto()
            f.fechaDesde = fecha
            f.fechaHasta = fecha
            if (!ausencias?.length) {
                p.push(this.ausenciasService.getAll(f).then((a) => (ausencias = a)))
            } 
            if (!disponibilidad?.length) {
                p.push(this.disponibilidadService.getAll(f).then((d) => (disponibilidad = d)))
            } 
        }

        return Promise.all(p).then(() => {
            opciones.forEach((opcion) => {
                const a = ausencias?.find((a) => a.proveedor.id == opcion.proveedor.id)
                if (a) {
                    opcion.puntaje = opcion.puntaje - 10000
                    opcion.ausencia = a
                }

                if (opcion.proveedor.noHabitual) {
                    opcion.puntaje = opcion.puntaje - 100
                    opcion.noHabitual = opcion.proveedor.noHabitual
                }

                opcion.ocupacion = ocupacion?.filter((o) => o.idPersonal == opcion.proveedor.id)
                if (opcion.ocupacion?.length) {
                    opcion.puntaje = opcion.puntaje - 1000
                }
            })
            opciones = [
                ...opciones.filter((o) => !o.noHabitual || (o.noHabitual && disponibilidad?.some((d) => d.proveedor.id == o.proveedor.id && d.fechaDesde <= fecha && d.fechaHasta >= fecha))),
            ]

            opciones = [...this.sortOpciones(opciones)]
            return opciones
        })
    }

    sortOpciones(opciones: OpcionPuesto[]) {
        return opciones.sort((a, b) => {
            if (a.puntaje > b.puntaje) return -1
            else return 1
        })
    }


    getPendientesProveedor(filtro: FiltroPuesto,customLoading?: LoadingService) {
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http
            .post(this.getPublicBaseURL('get-pendientes'), filtro.json))
            .then(
                (r: any) => {
                    if (customLoading) customLoading.susLoadingCount()
                    else this.loadingService.susLoadingCount()
                    return filtro.apply(r).map(item => AprobacionProveedor.fromData(item))
                },
                (e) => this.handleError(e, customLoading)
            )


    }

    actualizarReferencia(g: AprobacionProveedor[] ,customLoading?: LoadingService) {
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http
            .post(this.getPublicBaseURL('actualizar-referencia'), g))
            .then(
                (r: any) => {
                    if (customLoading) customLoading.susLoadingCount()
                    else this.loadingService.susLoadingCount()
                    return r
                }).catch(
                (e) => this.handleError(e, customLoading)
            )

    }


    aprobarAsignacion(g: AprobacionProveedor[] ,customLoading?: LoadingService) {
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http
            .post(this.getPublicBaseURL('aprobarAsignacion'), g))
            .then(
                (r: any) => {
                    if (customLoading) customLoading.susLoadingCount()
                    else this.loadingService.susLoadingCount()
                    return r
                },
                (e) => this.handleError(e, customLoading)
            )


    }

      rechazarAsignacion(g: AprobacionProveedor[],customLoading?: LoadingService) {
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http
            .post(this.getPublicBaseURL("rechazarAsignacion") , g))
            .then(
                (r: any) => {
                    if (customLoading) customLoading.susLoadingCount()
                    else this.loadingService.susLoadingCount()
                    return r
                },
                (e) => this.handleError(e, customLoading)
            )


    }

    getPendientesByProveedor(filtro: FiltroPuesto,customLoading?: LoadingService) {
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http
            .post(this.getBaseURL(`get-pendientes-by-proveedor`),filtro.json))
            .then(
                (r: any) => {
                    if (customLoading) customLoading.susLoadingCount()
                    else this.loadingService.susLoadingCount()
                    return r.map(item => AprobacionProveedor.fromData(item))
                },
                (e) => this.handleError(e, customLoading)
            ).finally(()=>{
                if (customLoading) customLoading.susLoadingCount()
                else this.loadingService.susLoadingCount()
            })

    }

    count(filtro: FiltroPuesto,customLoading?: LoadingService) {
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http
            .post(this.getPublicBaseURL(`count-pendientes`),filtro.json))
            .catch(
                (e) => this.handleError(e, customLoading)
            ).finally(()=>{
                if (customLoading) customLoading.susLoadingCount()
                else this.loadingService.susLoadingCount()
            })

    }

    getDatosPaxs(idReserva: number, filtro: FiltroPuesto, customLoading?: LoadingService) {
        if (customLoading) customLoading.addLoadingCount();
        else this.loadingService.addLoadingCount();
        return lastValueFrom(this.http
            .get(filtro.token ? 
                this.getPublicBaseURL(`getDatosPaxs/${idReserva}/${filtro.personal.id}/${filtro.token}`) : 
                this.getBaseURL() + 'getDatosPaxs/' + idReserva))
            .then((r: any) => {
                return r.map((p) => Pax.fromData(p));
            })
            .catch(
                (e) => this.handleError(e, customLoading)
            ).finally(()=>{
                if (customLoading) customLoading.susLoadingCount()
                else this.loadingService.susLoadingCount()
            })
    }

}
