<div class="gestor-job-configuration content content-white">
    <model-gestor [service]="service" [(item)]="item" [validar]="isValid" [modelName]="'JobConfiguration' | translate"
        [verAcciones]="true" [isModal]="isModal" [goBack]="goBack" (onItemGuardado)="handleGuardado($event)"
        [editable]="esEditable" (onCancelar)="handleCancelar($event)" [form]="JobConfigurationForm"
        [multilenguaje]="false" (onEditar)="onEdit($event)">

        <div class="gestor-form  p-fluid" model-type="dataContent">
            <form #JobConfigurationForm="ngForm" id="JobConfigurationform">
                <div class="grid" *ngIf="item">
                    <div class="col-12 lg:col-4 field">
                        <span class="p-float-label">
                            <input pInputText placeholder="{{ 'JOBID_PH' | translate }}" [(ngModel)]="item.jobId"
                                pTooltip="{{ 'JOBID_TOOLTIP' | translate }}" type="text" #jobId="ngModel"
                                name="jobId" [readonly]="readonly">
                            <label>{{ 'JOBID' | translate }}</label>
                        </span>
                        <p-message severity="error" *ngIf="jobId.invalid" [text]="getErrorMessage(jobId)"></p-message>
                    </div>
                    <div class="col-12 lg:col-4 field">
                        <span class="p-float-label">
                            <input pInputText placeholder="{{ 'IDENTIFICACION_PH' | translate }}"
                                [(ngModel)]="item.identificacion" pTooltip="{{ 'IDENTIFICACION_TOOLTIP' | translate }}"
                                type="text" #identificacion="ngModel" name="identificacion" [readonly]="readonly">
                            <label>{{ 'IDENTIFICACION' | translate }}</label>
                        </span>
                        <p-message severity="error" *ngIf="identificacion.invalid" [text]="getErrorMessage(identificacion)"></p-message>
                    </div>

                    <div class="col-12 lg:col-4 field checkbox-container">
                        <p-toggleButton onLabel="{{ 'ACTIVO' | translate }}"
                            offLabel="{{ 'ACTIVO' | translate }}" iconPos="left" name="activo"
                            [onIcon]="'fa fa-check-square-o'" offIcon="fa fa-square-o" [disabled]="readonly"
                            [(ngModel)]="item.activo"></p-toggleButton>
                    </div>

					<div class="col-12 lg:col-4 field">
						<span class="p-float-label">
							<p-calendar
								[(ngModel)]="item.horaDeEjecucion"
								[timeOnly]="true"
								[hourFormat]="24"
								inputId="timeonly"
								name="horaDeEjecucion"
								#horaDeEjecucion="ngModel"
								[stepMinute]="15"
								[disabled]="readonly"
							></p-calendar>
							<label>{{ 'Hora de ejecución' | translate }}</label>
						</span>
                    </div>
                </div>
            </form>
        </div>
    </model-gestor>
</div>
