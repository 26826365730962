import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { Recordatorio } from 'src/app/model/Recordatorio';
import { LoadingService } from './../common/services/loading-data-service.service';
import { lastValueFrom } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class RecordatorioService extends ServicioAbstract<Recordatorio>{

    public baseName(): string {
        return "recordatorio";
    }
    public parseToEnt(data: any): Recordatorio {
        return Recordatorio.fromData(data);
    }
    public newEnt(): Recordatorio {
        return new Recordatorio();
    }
    getByEntidad(tipoEntidad: string, idEntidad: number, customLoading?: LoadingService): Promise<Recordatorio[]>{
        const $this = this;
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.get(this.getBaseURL(`byEntidad/${tipoEntidad}/${idEntidad}`)))
            .then(r =>  $this.parse(r)).catch((e) => this.handleError(e, customLoading)).finally(()=>{
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
            });
    }

}