<div class="layout-container consentimiento">
  <div class="layout-wrapper">
    <div class="grid p-justify-center">
      <div class="selector-idioma-container">
        <selector-idioma [(ngModel)]="idiomaSeleccionado" name="idioma" [idiomas]="idiomas"></selector-idioma>
      </div>
      <div class="col-12 logo">
        <img src="{{mainLogo}}" alt="" />
      </div>
      <div class="col-12 p-lg-6 encuesta" *ngIf="!validando">
        <div class="titulo">
          <span><strong>Encuesta: {{encuesta?.descripcionProducto}} - {{encuesta?.fechaActividad
              | date:'dd/MM/yyyy'}} </strong> </span>
        </div>
        <div class="d-block titulo" *ngFor="let puesto of encuesta.puestos">
          <span ><strong>
              {{puesto.puesto?.tipoPuesto?.descripcion}}: {{puesto?.personal?.descripcion}}
            </strong></span>
        </div>

        <div class="p-fluid" [formGroup]="formPreguntas" *ngIf="formPreguntas && !completada">
          <ng-container formArrayName="preguntas">
            <div class="grid">
              <div class="col-12" *ngFor="let pregunta of preguntas?.controls; let i = index">
                <div class="grid no-bottom">
                  <div class="col-12">
                    <span>{{pregunta.value.pregunta.pregunta.descripcion}}</span>
                  </div>

                  <div [formGroup]="pregunta" class="col-12"
                    [ngSwitch]="pregunta?.value?.pregunta?.pregunta?.tipoPregunta?.codigo">
                    <ng-container *ngSwitchCase="'L'">
                      <textarea type="text" pInputText formControlName="respuesta" style="width: 100%;"
                      rows="10" maxlength="1500"
                        placeholder="{{ 'RESPUESTA' | translate }}" ></textarea>
                    </ng-container>
                    <ng-container *ngSwitchCase="'R'">
                      <p-rating formControlName="respuesta" [cancel]="false"></p-rating>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="submit">
            <button *ngIf="!readonly" pButton type="button" label="{{ 'ENVIAR' | translate }}" (click)="onSubmit()"
              [disabled]="!formValid"></button>
          </div>


        </div>



      </div>
      <div class="p-lg-6 col-12 right" *ngIf="!valido && !validando">
        <div class="p-lg-12" style="margin-top: 4.5em">
          <h2>Link invalido. Comuniquese con Administración</h2>
        </div>
      </div>
      <div class="p-lg-6 col-12 right" *ngIf="validando">
        <div class="p-lg-12" style="margin-top: 4.5em">
          <h2>Verificando <i class="fa fa-spinner fa-spin"></i></h2>
        </div>
      </div>
      <div class="p-lg-6 col-12 right" *ngIf="valido && enviado == 2">
        <div class="p-lg-12" style="margin-top: 4.5em">
          <h2>{{ 'ERROR_EN_EL_FORMULA_33' | translate }}</h2>
        </div>
      </div>
      <div class="p-lg-6 col-12 right" *ngIf="completada">
        <div class="p-lg-12" style="margin-top: 4.5em">
          <h2>{{ 'ENCUESTA_COMPLETADA' | translate }}</h2>
        </div>
      </div>
    </div>
  </div>
</div>