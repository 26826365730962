import moment from "moment"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Usuario } from "../common/model/Usuario"
import { ProductoCabecera } from "../pages/producto/ProductoCabecera"
import { ListaProducto } from "./ListaProducto"
import { Moneda } from "./Moneda"

export class ListaPrecios extends Descriptivo {
	quitarPrecio(precio: ListaProducto) {
		this.productos = this.productos.filter((p) => p.key !== precio.key)
	}
	tieneProducto(p: ProductoCabecera | Descriptivo): boolean {
		return this.productos.some((prod) => prod.producto.id === p.id)
	}

	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public moneda?: Moneda,
		public productos: ListaProducto[] = [],
		public ultimaActualizacion?: Date,
		public responsable?: Descriptivo,
		public esDefault?: boolean,
		public habilitado?: boolean,
		public codigoEstado?: string,
		public impuestosIncluidos?: boolean,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
		public version?: number,
		public integraciones: Descriptivo[] = []
	) {
		super(codigo, descripcion, id)
	}

	public static fromData(data: any): ListaPrecios {
		if (!data) return null
		const o: ListaPrecios = new ListaPrecios(
			data.id,
			data.codigo,
			data.descripcion,
			Moneda.fromData(data.moneda),
			data.productos.map((c) => ListaProducto.fromData(c)),
			data.ultimaActualizacion ? new Date(data.ultimaActualizacion) : null,
			Usuario.fromData(data.responsable),
			data.esDefault,
			data.habilitado,
			data.codigoEstado,
			data.impuestosIncluidos,
			data.lastModifiedBy,
			data.lastModifiedDate,
			data.version,
			data.integraciones ? data.integraciones.map((r) => Descriptivo.fromData(r)) : []
		)

		return o
	}

	getProductosPorFecha(desde: Date, hasta: Date) {
		const data = this.productos
			.filter((p) => p.aplicaRango(desde, hasta))
			.sort((a, b) =>
				a.producto.id == b.producto.id ? (a.fechaDesde >= a.fechaHasta ? -1 : 1) : a.producto.descripcion > b.producto.descripcion ? 1 : -1
			)
		data.forEach((p) => (p.nameSort = p.producto.descripcion + moment(p.fechaDesde).format("YYYYMMDD") || ""))
		return data
	}

	getProductosFecha(f: Date) {
		let productosRango = this.productos.filter((p) => p.fechaDesde <= f && (!p.fechaHasta || p.fechaHasta >= f))
		productosRango.forEach((p) => (p.cumpleFecha = true))
		let productosUnicos = this.productos.filter((p, i, arr) => arr.findIndex((pp) => pp.producto.id === p.producto.id) === i)
		let productosNoRango = productosUnicos.filter((pu) => !productosRango.some((pp) => pp.producto.id == pu.producto.id))
		productosNoRango.forEach((p) => (p.cumpleFecha = false))
		return productosRango.concat(productosNoRango).sort((a, b) => (a.producto.descripcion < b.producto.descripcion ? -1 : 1))
	}
	getPrecioValor(producto: Descriptivo, fecha: Date, paxs: number): number {
		const precioBase = this.getPrecio(producto, fecha, paxs)
		if (!precioBase || !paxs) {
			return 0
		} else {
			return precioBase.precio * paxs >= precioBase.precioMinimo ? precioBase.precio : precioBase.precioMinimo / paxs
		}
	}

	getBasePrecio(producto: Descriptivo, fecha: Date, paxs: number, visitados: number[]): ListaProducto {
		if (visitados.includes(producto.id)) {
			throw new Error("Ciclo de precios detectado")
		}
		visitados.push(producto.id)
		let precio = this.productos.filter((l) => l.aplica(producto, fecha, paxs))[0]
		if (!precio) return null
		return precio
	}
	getPrecio(producto: Descriptivo, fecha: Date, paxs: number): ListaProducto {
		let precio = this.getBasePrecio(producto, fecha, paxs, [])
		if (precio?.esCalculado && precio?.tipoCalculo === "D") {
			const porcentaje = precio.porcentual / 100
			precio = this.getPrecio(precio.productoDependiente, fecha, paxs)
			if (precio?.esCalculado && precio?.tipoCalculo === "C") {
				precio.porcentual = porcentaje
			} else {
				precio.precio = precio.precio * porcentaje
			}
		}
		return precio ? precio : null
	}

	public static cabecera(lp: ListaPrecios) {
		return new ListaPrecios(
			lp.id,
			lp.codigo,
			lp.descripcion,
			lp.moneda,
			[],
			lp.ultimaActualizacion,
			lp.responsable,
			lp.esDefault,
			lp.habilitado,
			lp.codigo,
			lp.impuestosIncluidos,
			lp.lastModifiedBy,
			lp.lastModifiedDate,
			null,
			lp.integraciones
		)
	}
}
