<model-help [title]="'Gestión de Productos y Servicios'">
	<h3>Carga Masiva</h3>
	<p>
		En la parte superior del listado de productos encontramos el icono <i class="pi pi-upload"></i> mediante el cual podremos cargar masivamente un listado
		de productos preexistentes.<br />
		Para ver el instructivo y descargar de archivo modelo, siga este <help-link [linkFinal]="'carga-masiva'" [textoLink]="'Link'"></help-link>
	</p>
	<p>
		Esta pantalla permite ver, editar y modificar información de un producto, servicio, programa o insumo. <br />
		Permite también realizar la traducción del nombre, descripción y detalle de los mismos.<br />
		Además dependiendo del tipo, podremos agregar restricciones de espacio, roles a cubrir para brindar el servicio y productos adicionales incluidos en el
		mismo. <br /><br />
		Dependiendo del campo "Tipo" (que solo se puede editar al crear un nuevo producto, servicio programa o insumo) tendremos disponibles diferentes campos y
		secciónes.
	</p>
	<h3>Definiciones importantes</h3>
	<p>
		<span
			><strong>Regiones: </strong>Son las regiones en las que se puede ofrecer el servicio. Puede quedar vacío. Se puede crear desde el menú de
			configuración, Regiones o mismo desde el desplegable de opciones seleccionando "Nuevo". Podrá ser utilizado como filtro en la carga de reserva y en
			actividades</span
		><br /><br />
		<strong>Producto:</strong> Un producto es algo tangigle que se puede comercializar de manera directa o indirecta a traves de otros productos o
		servicios. <span class="texto-ejemplo">Ej: Entradas a un parque.</span><br /><br />
		<strong>Servicio: </strong>Es una actividad que tiene una fecha y una duración. Puede tener roles a cubrir por otros proveedores.
		<span class="texto-ejemplo">Ej: Una excursión.</span><br /><br />
		<strong>Familia de servicios: </strong> En caso de necesitar definir variantes de un servicio se podrá recurrir al uso de la
		<help-link [linkFinal]="'familia-servicios'" [textoLink]="'familia de servicios.'"></help-link>
		<span class="texto-ejemplo">
			( Por ejemplo: Se necesita determinar precio diferenciado de un producto de acuerdo a si el pax es mayor o menor de edad. En este caso definiremos
			una familia que contendra 2 servicios, uno para el pax mayor y otro para el pax menor de edad.)</span
		><br />
		Los servicios deberán crearse (o editarse si ya existen) por separado, serán 2 o más servicios distintos y asociados a la misma familia desde los campos
		destinados a ese fin en la misma pantalla del gestor de Productos / Servicios.<br />
		La familia de servicios podrá ser creada (si aún no existe) desde la pantalla de gestión de familias de productos o desde la misma pantalla del gestor
		de productos y servicios dando click a "Nuevo" en el desplegable correspondiente a la selección de familia.<br /><br />
		<strong>Insumo: </strong>Es un producto que no se puede vender directamente al cliente, sino que forma parte de los costos de la empresa.<span
			class="texto-ejemplo"
		>
			Ej: Horas de guía freelance.</span
		>
		<br />
		<strong>Programa: </strong> Define una cantidad de días con servicios preseleccionados para cada día. Al realizar una reserva el programa agrega esos
		servicios automáticamente a la misma.
	</p>
	<h3>Traducción</h3>
	<p>
		Seleccionando la bandera correspondiente, podremos cargar los campos <mark>Nombre Comercial, descripción y detalles</mark> en diferentes idiomas.<br />
		<img src="assets/images/help/traduccion.png" /><br />
		Ayuda sobre <help-link [linkFinal]="'idioma'" [textoLink]="'Idiomas'"></help-link><br /><br />
	</p>

	<h3>Seleccione el Tipo para desplegar la ayuda.</h3>
	<p-accordion>
		<p-accordionTab header="Producto">
			<p>
				<strong>Campos particulares</strong><br /><br />
				El check <i class="pi pi-check-square"></i> <strong>Es adicionable</strong> permite indicar que este producto podrá adicionarse a otros
				productos o servicios.<br /><br />
				El campo <strong>Cantidad</strong> y <strong>Base proporción</strong> están directamente vinculados. Base proporción es la cantidad de pax que
				rinde la cantidad indicada.<br /><br />

				<span class="text-ejemplo"
					>( Ejemplo: si hablamos de un producto "Botella de vino" y la cantidad es 1 (una botella) y la Base Proporcion es 4(que rinde para 4 pax) el
					sistema calcula que a cada pax le corresponde 1/4 de botella de vino. Y cada 4 pax se necesitará 1 botella para proveer este producto.) </span
				><br /><br />
			</p>

			<p>
				<strong>Configuración de proveedores</strong><br /><br />
				En esta sección podemos agregar, modificar o eliminar proveedores necesarios para este producto, asi tambien como los costos asociados a este
				tipo de proveedor. Es obligatorio definir al menos 1 configuración con un proveedor default.<br />
				<img src="assets/images/help/conf-prov.png" /><br /><br />
				Para <mark>Agregar un nuevo proveedor </mark>debemos clickear en <i class="pi pi-plus"></i> y nos aparecerá una pantalla auxiliar donde podremos
				elegir el <mark>Puesto</mark> (Ayuda sobre <help-link [linkFinal]="'puesto'" [textoLink]="'Puestos'"></help-link>)<br />
				Una vez seleccionado, se agregará el puesto pero aún no quedará determinado el proveedor definitivo que se pactará al adquirir este producto.<br />
				Podremos determinar un proveedor por defecto, haciendo click en el círculo en blanco o cambiarlo en el caso que estemos editando y ya aparezca
				uno por defecto.<br /><br />
				Para <mark>Agregar un nuevo costo asociado </mark> al proveedor seleccionado debemos clickear en <i class="pi pi-plus"></i>, entonces podremos
				configurar: <br /><br />
				* Un costo (precargado o generar uno nuevo desde <mark>Nuevo +</mark>). Es el servicio o producto de un proveedor que abonamos para poder
				brindar nuestro producto al cliente.<br />
				* Una cantidad que se multiplicará por la cantidad de PAX (excepto si es por servicio). <br />
				* Check de <i class="pi pi-check-square"></i> "Es por servicio", que determina que el costo es único por todo un servicio provisto.<br /><br />
			</p>

			<p>
				<strong>Productos Asociados</strong><br /><br />
				En esta sección podemos agregar productos asociados por defecto a este producto al momento de agregarlo a una reserva. <br />
				También podremos determinar una <mark>Cantidad</mark> por defecto. Para determinar que esta cantidad <mark>Es por servicio</mark> debemos usar
				el check <i class="pi pi-check-square"></i>, caso contrario se tomará el valor de cantidad y se multiplicará por la cantidad de PAX cuando se
				realice la <help-link [linkFinal]="'reserva'" [textoLink]="'Reserva'"></help-link>. <br /><br />
			</p>

			<p>
				<strong>Insumos</strong><br /><br />
				En esta sección podemos determinar los insumos que son necesario para conformar el producto actual. Podremos determinar una
				<mark>Cantidad</mark>. Para determinar que esta cantidad <mark>Es por servicio</mark> debemos usar el check <i class="pi pi-check-square"></i>,
				caso contrario se tomará el valor de cantidad y se multiplicará por la cantidad de PAX cuando se realice la reserva.<br />
			</p>
		</p-accordionTab>
		<p-accordionTab header="Servicio">
			<p>
				<strong>Campos particulares</strong><br /><br />
				Clickear sobre área de <strong>Imagen</strong> para cambiar o cargar una nueva imagen del servicio. Se recomienda un tamaño de NN X NN pixeles y
				NN dpi de resolución.<br />
				El check <i class="pi pi-check-square"></i> <strong>Producto Propio</strong> se usa a fines estadisticos para llevar un registro de aquellos
				servicios que no se tercerizan.<br />
				El check <i class="pi pi-check-square"></i> <strong>Requiere consentimiento</strong> se usa para indicar que este servicio al ser reservado
				enviará a los PAX que lo contraten un consentimiento para ser aceptado. (Ayuda sobre
				<help-link [linkFinal]="'consentimiento'" [textoLink]="'Consentimientos'"></help-link>)<br />
				Importante sobre los consentimientos: Si el servicio forma parte de una familia de servicios, se dará prioridad a la selección de la opción de
				requerir consentimiento determinado en dicha familia. <br />
				Es decir que tiene prioridad sobre el consentimiento del servicio, el consentimiento de la familia a la cual se ha asignado el servicio. El
				campo <strong>Base por Grupo</strong> indica que el servicio está definido en base a esa cantidad de PAX no pudiendo fraccionarse por menos.<br />
				El campo <strong>Duración hs </strong> es una duración aproximada del servicio completo. Se utilizará principalmente para definir visualmente el
				mismo en la pantalla de Actividades. (Ayuda sobre <help-link [linkFinal]="'actividad'" [textoLink]="'Actividades'"></help-link>)<br />
				El campo <strong>Hora default inicio </strong> es la hora que se utilizará para la creación de un grupo en la autoasignación en la carga de la
				reserva. El la hora en la que normalemente se inicia el servicio.<br />
				El campo <strong>Familia de Servicio </strong> permite seleccionar la familia a la cual agregararemos el servicio de ser necesario. También
				permite agregar una familia nueva desde la primera opción "+Nuevo".<br />
				El campo <strong>Variante </strong> permite seleccionar una variante dentro de la
				<help-link [linkFinal]="'familia-servicios'" [textoLink]="'familia de servicios'"></help-link> seleccionada en el campo anterior.
			</p>

			<p>
				<strong>Configuración de proveedores</strong><br /><br />
				En esta sección podemos agregar, modificar o eliminar proveedores necesarios para este servicio, asi tambien como los costos asociados a este
				tipo de proveedor.<br />
				<img src="assets/images/help/conf-prov.png" /><br /><br />
				Para <mark>Agregar un nuevo proveedor </mark>debemos clickear en <i class="pi pi-plus"></i> y nos aparecerá una pantalla auxiliar donde podremos
				elegir el <mark>Puesto</mark> (Ayuda sobre <help-link [linkFinal]="'puesto'" [textoLink]="'Puestos'"></help-link>)<br />
				Podremos determinar un proveedor por defecto, haciendo click en el círculo en blanco o cambiarlo en el caso que estemos editando y ya aparezca
				uno por defecto.<br /><br />
				Para <mark>Agregar un nuevo costo asociado </mark> al proveedor seleccionado debemos clickear en <i class="pi pi-plus"></i>, entonces podremos
				configurar: <br /><br />
				* Un costo (precargado o generar uno nuevo desde <mark>Nuevo +</mark>). Es el servicio o producto de un proveedor que abonamos para poder
				brindar nuestro producto al cliente.<br />
				* Una cantidad que se multiplicará por la cantidad de PAX (excepto si es por servicio). <br />
				* Check de <i class="pi pi-check-square"></i> "Es por servicio", que determina que el costo es único por todo un servicio provisto.<br /><br />
			</p>

			<p>
				<strong>Vehículos</strong><br /><br />
				En esta sección podemos agregar o modificar los <help-link [linkFinal]="'tipo-vehiculo'" [textoLink]="'Tipos de Vehículos'"></help-link> o
				espacios que serán necesarios para brindar el servicio.<br />
				También la capacidad mínima de PAX que debe tener y si debe ser <strong>4X4</strong> mediante el check <i class="pi pi-check-square"></i>.
				También podremos determinar <strong>Costos Asociados</strong> a ese vehículo. Podemos seleccionar que este costo
				<strong>Es por servicio</strong>usando el check correspondiente <i class="pi pi-check-square"></i>, caso contrario se tomará el valor de
				<strong>Cantidad</strong> y se multiplicará por la cantidad de PAX cuando se realice la reserva. (Ayuda sobre
				<help-link [linkFinal]="'reserva'" [textoLink]="'Reservas'"></help-link>)<br /><br />
			</p>

			<p>
				<strong>Productos Asociados</strong><br /><br />
				En esta sección podemos agregar productos asociados por defecto, que apareceran al realizar una nueva reserva. <br />
				También podremos determinar una <strong>Cantidad</strong> por defecto. Para determinar que esta cantidad
				<strong>Es por servicio</strong> debemos usar el check <i class="pi pi-check-square"></i>, caso contrario se tomará el valor de cantidad y se
				multiplicará por la cantidad de PAX cuando se realice la reserva. (Ayuda sobre
				<help-link [linkFinal]="'reserva'" [textoLink]="'Reservas'"></help-link>)<br /><br />
			</p>

			<p>
				<strong>Recordatorios</strong><br /><br />
				En esta sección se puede seleccionar que <help-link [linkFinal]="'notificacion'" [textoLink]="'Recordatorio'"></help-link> se le enviara al PAX
				antes del servicio. <br />
				Y también se podrá seleccionar una <help-link [linkFinal]="'encuesta'" [textoLink]="'Encuesta'"></help-link> para enviar al finalizar el
				servicio. <br />
				Importante: En relación a la encuesta, si el servicio forma parte de una familia de servicios, se dará prioridad al envío de la encuesta que se
				haya determinado previamente en dicha familia. <br />
				Es decir que tiene prioridad sobre la encuesta seleccionada en el servicio, la encuesta seleccionada en la familia a la cual fué asignada ese
				servicio.
			</p>
		</p-accordionTab>
		<p-accordionTab header="Insumo">
			<p>
				<strong>Configuración de proveedores</strong><br /><br />
				En esta sección podemos agregar, modificar o eliminar proveedores necesarios para este insumo, asi tambien como los costos asociados a este tipo
				de proveedor.<br />
				<img src="assets/images/help/conf-prov.png" /><br /><br />
				Para <mark>Agregar un nuevo proveedor </mark>debemos clickear en <i class="pi pi-plus"></i> y nos aparecerá una pantalla auxiliar donde podremos
				elegir el <mark>Puesto</mark> (Ayuda sobre <help-link [linkFinal]="'puesto'" [textoLink]="'Puestos'"></help-link>)<br />
				Una vez seleccionado, se agregará el puesto pero aún no quedará determinado el proveedor definitivo que se pactará al utilizar este insumo.<br />
				Podremos determinar un proveedor por defecto, haciendo click en el círculo en blanco o cambiarlo en el caso que estemos editando y ya aparezca
				uno por defecto.<br /><br />
				Para <mark>Agregar un nuevo costo asociado </mark> al proveedor seleccionado debemos clickear en <i class="pi pi-plus"></i>, entonces podremos
				configurar: <br /><br />
				* Un costo (precargado o generar uno nuevo desde <mark>Nuevo +</mark>)<br />
				* Una cantidad que se multiplicará por la cantidad de PAX (excepto si es por servicio). * Check de <i class="pi pi-check-square"></i> "Es por
				servicio", que determina que el costo es único por todo un servicio o producto provisto.<br /><br />
			</p>
			<p>
				<strong>Productos Asociados</strong><br /><br />
				En esta sección podemos agregar productos asociados por defecto, que apareceran al utilizar este insumo. <br />
				También podremos determinar una <strong>Cantidad</strong> por defecto. Para determinar que esta cantidad
				<strong>Es por servicio</strong> debemos usar el check <i class="pi pi-check-square"></i>, caso contrario se tomará el valor de cantidad y se
				multiplicará por la cantidad de PAX cuando se realice la reserva. (Ayuda sobre
				<help-link [linkFinal]="'reserva'" [textoLink]="'Reservas'"></help-link>)<br /><br />
			</p>
		</p-accordionTab>
		<p-accordionTab header="Programa">
			<p>
				<strong>Campos particulares</strong><br /><br />
				Clickear sobre área de <strong>Imagen</strong> para cambiar o cargar una nueva imagen del servicio. Se recomienda un tamaño de NN X NN pixeles y
				NN dpi de resolución.<br />
				El check <i class="pi pi-check-square"></i> <strong>Producto Propio</strong> se usa a fines estadisticos para llevar un registro de aquellos
				servicios que no se tercerizan.<br />
				El check <i class="pi pi-check-square"></i> <strong>Requiere consentimiento</strong> se usa para indicar que este servicio al ser reservado
				enviará a los PAX que lo contraten un consentimiento para ser aceptado. (Ayuda sobre
				<help-link [linkFinal]="'consentimiento'" [textoLink]="'Consentimientos'"></help-link>)<br />
				Importante respecto al consentimiento: En caso de que el Servicio se incluya en una familia de servicios, se dara prioridad del requerimiento de
				consentimiento en dicha familia. <br />
				Es decir, si en la familia del servicio se seleccionó que se requiere consentimiento y en la pantalla del servicio no se selecciona, se enviará
				el consentimiento de la familia de servicios.
			</p>

			<p>
				<strong>Configuración de servicios</strong><br /><br />
				En esta sección se agregarán los servicios que incluirá el programa.<br />
				Cada servicio debe tener un día asignado (Ejemplo: Servicio A día 1, Servicio B día 2, etc.)<br />
				Cada servicio puede tener un horario de comienzo por defecto.<br />
			</p>

			<p>
				<strong>Configuración de proveedores</strong><br /><br />
				En esta sección podemos agregar, modificar o eliminar proveedores necesarios para los servicios de este programa, asi tambien como los costos
				asociados a los tipos de proveedores.<br />
				<img src="assets/images/help/conf-prov.png" /><br /><br />
				Para <mark>Agregar un nuevo proveedor </mark>debemos clickear en <i class="pi pi-plus"></i> y nos aparecerá una pantalla auxiliar donde podremos
				elegir el <mark>Puesto</mark> (Ayuda sobre <help-link [linkFinal]="'puesto'" [textoLink]="'Puestos'"></help-link>)<br />
				Podremos determinar un proveedor por defecto, haciendo click en el círculo en blanco o cambiarlo en el caso que estemos editando y ya aparezca
				uno por defecto.<br /><br />
				Para <mark>Agregar un nuevo costo asociado </mark> al proveedor seleccionado debemos clickear en <i class="pi pi-plus"></i>, entonces podremos
				configurar: <br /><br />
				* Un costo (precargado o generar uno nuevo desde <mark>Nuevo +</mark>). Es el servicio o producto de un proveedor que abonamos para poder
				brindar nuestro producto al cliente.<br />
				* Una cantidad que se multiplicará por la cantidad de PAX (excepto si es por servicio). <br />
				* Check de <i class="pi pi-check-square"></i> "Es por servicio", que determina que el costo es único por todo un servicio provisto.<br /><br />
			</p>

			<p>
				<strong>Vehículos</strong><br /><br />
				En esta sección podemos agregar o modificar los <help-link [linkFinal]="'tipo-vehiculo'" [textoLink]="'Tipos de Vehículos'"></help-link> o
				espacios que serán necesarios para brindar el servicio.<br />
				También la capacidad mínima de PAX que debe tener y si debe ser <strong>4X4</strong> mediante el check <i class="pi pi-check-square"></i>.
				También podremos determinar <strong>Costos Asociados</strong> a ese vehículo. Podemos seleccionar que este costo
				<strong>Es por servicio</strong>usando el check correspondiente <i class="pi pi-check-square"></i>, caso contrario se tomará el valor de
				<strong>Cantidad</strong> y se multiplicará por la cantidad de PAX cuando se realice la reserva. (Ayuda sobre
				<help-link [linkFinal]="'reserva'" [textoLink]="'Reservas'"></help-link>)<br /><br />
			</p>

			<p>
				<strong>Productos Asociados</strong><br /><br />
				En esta sección podemos agregar productos asociados por defecto, que apareceran al realizar una nueva reserva. <br />
				También podremos determinar una <strong>Cantidad</strong> por defecto. Para determinar que esta cantidad
				<strong>Es por servicio</strong> debemos usar el check <i class="pi pi-check-square"></i>, caso contrario se tomará el valor de cantidad y se
				multiplicará por la cantidad de PAX cuando se realice la reserva. (Ayuda sobre
				<help-link [linkFinal]="'reserva'" [textoLink]="'Reservas'"></help-link>)<br /><br />
			</p>

			<p>
				<strong>Recordatorios</strong><br /><br />
				En esta sección se puede seleccionar que <help-link [linkFinal]="'notificacion'" [textoLink]="'Recordatorio'"></help-link> se le enviara al PAX
				antes del comienzo del programa. <br />
				Y también se podrá seleccionar una <help-link [linkFinal]="'encuesta'" [textoLink]="'Encuesta'"></help-link> para enviar al finalizar el
				programa. <br />
			</p>
		</p-accordionTab>
		<p-accordionTab header="Paquete">
			<p>
				<strong>Configuración de Servicios</strong><br /><br />
				En esta sección podemos agregar o modificar los servicios que se pueden agregar a la reserva como un conjunto.<br />
				Al realizar una reserva, el paquete seleccionado agregará automáticamente los servicios que lo componen.<br /><br />
			</p>
		</p-accordionTab>
	</p-accordion>
</model-help>
