import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core"
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from "@angular/forms"
import { BehaviorSubject, Subscription } from "rxjs"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { DescriptivoMaterialSelectorComponent, RequireMatch } from "../descriptivo-material-selector/descriptivo-material-selector.component"
import { AuthService } from "./../../../services/auth.service"
import { NovedadService } from "./../../../services/novedades.service"
import { Filtro } from "./../../model/Filtro"
import { Parametrico } from "./../../model/Parametrico"
import { GenericParemtricoService } from "./../../services/generic-parametrico.service"
import { StringUtils } from "./../../utils/string-utils"

@Component({
	selector: "parametrico-selector",
	templateUrl: "parametrico-selector.component.html",
	styleUrls: ["parametrico-selector.component.less"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ParametricoSelectorComponent),
			multi: true
		},
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => ParametricoSelectorComponent), multi: true }
	]
})
export class ParametricoSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
	public inputControl = new FormControl(null, [RequireMatch])
	private _tipoParametro: string
	private filterSub: Subscription
	public defaultTitle: string = "Parametrico"
	public ctx: any
	@Input()
	public disabled: boolean
	@Input()
	public styleClass: string
	constructor(private novedadService: NovedadService, private authService: AuthService) {}
	@ViewChild("descriptivo", { static: true })
	private descriptivo: DescriptivoMaterialSelectorComponent
	private _listado: Parametrico[]
	@Input()
	public buscarTraduccion: boolean = false
	public get listado(): Parametrico[] {
		return this._listado
	}
	@Input()
	public set listado(v: Parametrico[]) {
		this._listado = v

		if (this.required && this._listado && !this._seleccionado) {
			var def = this._listado.filter((p) => p.esDefault)[0]
			this.seleccionado = def ? def : this._listado[0]
		}
		this.data.next(v)
	}

	private _required: any
	public get required(): any {
		return this._required
	}
	@Input()
	public set required(v: any) {
		this._required = v
	}

	@Input()
	public gestor: TemplateRef<any>

	@Input()
	public itemTemplate: TemplateRef<any>
	@Input()
	public permiteNuevo: boolean = false

	@Input()
	public name: string
	@Input()
	public textoSeleccionar: string = "Seleccionar..."

	@Input()
	public static: boolean = false

	private _itemEditado: Parametrico = new Parametrico()
	public get itemEditado(): Parametrico {
		return this._itemEditado
	}
	public set itemEditado(v: Parametrico) {
		this._itemEditado = v
		this.ctx["itemEditado"] = v
	}

	public editando: boolean = false
	@Input()
	public titulo: string
	@Input()
	public appearence: string = "outline"
	@Input()
	public floatLabel: string = "'auto"

	@Input()
	public readonly: boolean = false

	@Input()
	public hideLabel: boolean = false
	@Input()
	public placeHolder: string = ""
	public filtro: Filtro = new Filtro("", {}, 0, 3000, "descripcion")
	@Input()
	public limpiable: boolean = false

	@Output() onSelect: EventEmitter<any> = new EventEmitter<any>()
	@Output()
	public seleccionadoChange: EventEmitter<Parametrico> = new EventEmitter<Parametrico>()
	@Input()
	public onNew: (r) => Parametrico = this.onNewDefault

	public data: BehaviorSubject<Parametrico[]> = new BehaviorSubject<Parametrico[]>([])

	private _seleccionado: Parametrico
	public get seleccionado(): Parametrico {
		return this._seleccionado
	}
	@Input()
	public set seleccionado(v: Parametrico) {
		if (this._seleccionado?.key != v?.key) {
			this._seleccionado = v
			this.onChangeCallback(v)
			this.seleccionadoChange.emit(v)
			this.onSelect.emit(v)
		}
	}

	public get tipoParametro(): string {
		return this._tipoParametro
	}
	private defaultService: GenericParemtricoService
	@Input()
	public set tipoParametro(v: string) {
		if (v) {
			this._tipoParametro = v
			this.defaultTitle = StringUtils.middlescoreToTitleCase(v)
			this.defaultService = new GenericParemtricoService(this.novedadService, this.authService)
			this.defaultService.paramId = v
			this.defaultService.getAll().then((r) => {
				this.data.next(r)
			})
		}
	}
	private onChangeCallback: (_: any) => void = () => {}

	@Input()
	public noSpace: boolean = false

	private _idioma: string

	public get idioma(): string {
		return this._idioma
	}
	@Input()
	public field: string = "descripcion"
	@Input()
	public set idioma(v: string) {
		this._idioma = v
	}
	@Input() public allowDefault: boolean = false
	private _service: ParametricoService<any>
	public get service(): ParametricoService<any> {
		return this._service
	}
	@Input()
	public set service(v: ParametricoService<any>) {
		this._service = v
		this._service &&
			this._service.getData().then((d) => {
				this.data.next(d.filter((p) => p.habilitado))
				if (this.required) {
					if (!this.seleccionado?.key) this.seleccionado = this._service.default
				}
			})
	}

	get finalTitle(): string {
		return this.hideLabel ? null : this.titulo ? this.titulo : this.defaultTitle
	}
	ngOnInit() {
		this.inputControl.valueChanges.subscribe((v) => {
			this.onChangeCallback(v)
		})
	}

	ngOnDestroy() {
		if (this.filterSub) this.filterSub.unsubscribe()
	}

	propagateChange = (_: any) => {}

	writeValue(obj: Parametrico): void {
		if (obj?.key !== this.seleccionado?.key) {
			this.seleccionado = obj
		}
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn
	}
	registerOnTouched(fn: any): void {
		this.descriptivo.registerOnTouched(fn)
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled
	}

	isTablet() {
		const width = window.innerWidth
		return width <= 1024 && width > 640
	}

	isDesktop() {
		return window.innerWidth > 1024
	}

	isMobile() {
		return window.innerWidth <= 640
	}

	validate(ctrl: AbstractControl) {
		return this.descriptivo.validate(ctrl)
	}

	public onNewDefault() {
		return new Parametrico()
	}

	public seleccionar(event) {
		this.onSelect.emit(event)
	}
}
