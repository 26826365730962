import { Injectable } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { TranslateService } from "../services/translate.service"

@Injectable({
	providedIn: "root"
})
export class ErrorHandler {
	constructor(private tService: TranslateService) {}
	public getErrorMessage(formControl: AbstractControl) {
		if (!formControl) return
		if (formControl.hasError("required")) {
			return this.tService.get("EL_CAMPO_ES_OBLIGATORIO")
		}
		if (formControl.hasError("multiMailInvalido")) {
			return this.tService.get("LOS_EMAILS_DEBEN_TENER_EL_FORMATO")
		}
		if (formControl.hasError("email")) {
			return this.tService.get("EL_EMAIL_TIENE_QUE_TENER_EL_FORMATO")
		}
		if (formControl.hasError("pattern")) {
			return this.tService.get("EL_CAMPO_NO_CUMPLE_CON_EL_FORMATO_REQUERIDO")
		}
		if (formControl.hasError("cuitInvalido")) {
			return this.tService.get("EL_CUIT_DEBE_TENER_FORMATO")
		}
        if (formControl.hasError("incorrect")) {
            return this.tService.get("DEBE_ELEGIR_UNA_OPCIÓN_DEL_LISTADO");
        }
        if (formControl.hasError("min")) {
            return this.tService.get("NO_PUEDE_INGRESAR_UN_VALOR_MENOR_A") + " " + formControl.errors["min"]["min"];
        }
        if (formControl.hasError("max")) {
            return this.tService.get("NO_PUEDE_INGRESAR_UN_VALOR_MAYOR_A") + " "  + formControl.errors["max"]["max"];
        }
    }

}
