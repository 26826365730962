import { Descriptivo } from "src/app/common/model/Descriptivo"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { ConfiguracionEntornoService } from "../services/configuracion-entorno.service"
import { IConCobros } from "./../common/components/abm-cobros/IConCobros"
import { round } from "./../common/utils/MathUtils"
import { ComprobanteResumen } from "./Comprobante"
import { ItemCobranza } from "./ItemCobranza"
import { Moneda } from "./Moneda"
import { Cobro } from "./cobros/Cobro"
import { CobroFactory } from "./cobros/CobroFactory"

export class Cobranza extends Descriptivo implements IConCobros {
	get tipoOperacion(): "COMPRA" | "VENTA" {
		return "VENTA"
	}
	tieneComprobante(c: ComprobanteResumen) {
		return this.items.some((i) => i.comprobante.id == c.id)
	}
	public clienteStr: string
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public responsable?: Descriptivo,
		public fecha: Date = new Date(),
		public estado?: EstadoDocumento,
		public centroCosto?: Descriptivo,
		public importeNeto?: number,
		public ipuestos?: number,
		public numeroComprobante?: string,
		public cobros: Cobro[] = [],
		public items: ItemCobranza[] = [],
		public cliente?: Descriptivo,
		public total: number = 0,
		public motivoCancelacion?: Descriptivo,
		public moneda?: Moneda,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
		public tipoCliente?: Descriptivo
	) {
		super(codigo, descripcion, id)
		this.clienteStr = cliente ? cliente.descripcion : ""
	}
	get totalALocal(): number {
		return this.items.reduce((a, b) => (a += b.importe), 0)
	}
	public get valores(): Cobro[] {
		return this.cobros
	}
	public set valores(v: Cobro[]) {
		this.cobros = v
	}
	public static fromData(data: any): Cobranza {
		if (!data) return null
		const o: Cobranza = new Cobranza(
			data.id,
			data.codigo,
			data.descripcion,
			Descriptivo.fromData(data.responsable),
			data.fecha ? new Date(data.fecha) : null,
			EstadoDocumento.fromData(data.estado),
			Descriptivo.fromData(data.centroCosto),
			data.importeNeto,
			data.ipuestos,
			data.numeroComprobante,
			data.cobros ? data.cobros.map((c) => CobroFactory.fromData(c)) : [],
			data.items ? data.items.map((c) => ItemCobranza.fromData(c)) : [],
			Descriptivo.fromData(data.cliente),
			data.total,
			Descriptivo.fromData(data.motivoCancelacion),
			Moneda.fromData(data.moneda),
			data.lastModifiedBy,
			data.lastModifiedDate,
			Descriptivo.fromData(data.tipoCliente)
		)
		if (o.items?.length) {
			o.items.forEach((i) => (i.moneda = Moneda.fromData(data.moneda)))
		}
		o.version = data.version
		return o
	}

	agregarItem(item: ItemCobranza) {
		if (this.items.indexOf(item) < 0) this.items = [...this.items, item]
	}
	public quitarItem(item) {
		this.items = this.items.filter((i) => i != item)
	}

	public get totalNotasACuenta() {
		return this.items.filter((i) => i.comprobante?.tipoComprobante?.esNota).reduce((a, b) => (a += b.importe * -1), 0)
	}
	public get totalCalculado() {
		return round(
			this.items.reduce((a, b) => (a += b.importe), 0),
			2
		)
	}

	public get totalesMonedaExtranjera() {
		let items = []
		const itemsMonedaExtranjera = this.items?.filter(
			(i) => i.comprobante?.moneda?.codigo != ConfiguracionEntornoService.CONFIGURACION_ENTORNO?.moneda?.codigo
		)
		itemsMonedaExtranjera.forEach((itemMoneda) => {
			let item = items.find((i) => i.moneda?.codigo == itemMoneda.comprobante?.moneda?.codigo)

			if (item) {
				item.total += itemMoneda.importe * (1 / itemMoneda.comprobante?.cotizacionSafe)
			} else {
				items.push({ moneda: itemMoneda.comprobante?.moneda, total: itemMoneda.importe * (1 / itemMoneda.comprobante?.cotizacionSafe) })
			}
		})
		return items
	}
	public get totalCobrado() {
		return round(
			this.cobros.reduce((a, b) => (a += b.monto * b.cotizacion), 0),
			2
		)
	}

	public agregarCobro(c: Cobro) {
		if (this.cobros.indexOf(c) < 0) this.cobros.push(c)
		this.cobros = [...this.cobros]
	}

	public quitarCobro(c: Cobro) {
		this.cobros = this.cobros.filter((cc) => cc != c)
	}

	public get saldo() {
		return this.totalCalculado - this.totalCobrado
	}

	public getSaldo() {
		return this.saldo
	}

	buscarCobro(ref) {
		return this.cobros.find((c) => c["refFactura"] == ref)
	}

	public reemplazarCobro(item: Cobro, item2: Cobro) {
		if (!item && this.cobros.indexOf(item2) < 0) {
			this.cobros.push(item2)
		} else {
			var i = this.cobros.indexOf(item)
			if (i >= 0 && item2) {
				this.cobros[i] = item2
			}
		}
	}
}
