import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { ModelListComponent } from "src/app/common/components/model-list/model-list.component"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { ModelListHandler } from "src/app/common/interfaces/ModelListHandler"
import { Filtro } from "src/app/common/model/Filtro"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Hotel } from "src/app/model/Hotel"
import { HotelService } from "src/app/services/hotel.service"

@Component({
	selector: "listado-hotel",
	templateUrl: "listado-hotel.component.html",
	styleUrls: ["listado-hotel.component.less"]
})
export class ListadoHotelComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: Filtro = new Filtro("hotel_filtros", {}, 0, 10)
	public listado: Hotel[] = []

	private _list: ModelListComponent
	public get list(): ModelListComponent {
		return this._list
	}
	@ViewChild("list", { static: false })
	public set list(v: ModelListComponent) {
		this._list = v
	}

	public handler: ModelListHandler

	public columns = [
		{
			field: "id",
			header: "#",
			class: "id-column",
			role: "text-tabla",
			sortable: true
		},

		{
			field: "profilePic",
			header: "",
			class: "profile-pic",
			role: "profile-tabla",

			sortable: false
		},
		{
			field: "nombre",
			header: "Nombre",
			role: "text-tabla",
			sortable: true
		},
		{
			field: "direccionCompleta",
			header: "Dirección",
			role: "text-tabla",
			esMobile: true,
			sortable: true
		},
		{
			field: "email",
			header: "Contacto",
			role: "contactos",
			sortable: true,
			esMobile: true
		},
		{
			field: "activo",
			header: "Activo",
			class: "is-boolean",
			role: "boolean-tabla",
			sortable: false
		}
	]
	constructor(public service: HotelService, messagesService: MessagesService, public confirmationService: ConfirmationService, public router: Router) {
		super(messagesService)
	}
	ngOnInit() {
		let $this = this

		this.handler = {
			nuevo: () => {
				this.confirmationService.confirm({
					key: "genConf",
					header: this.translateService.get("CREAR_HOTEL"),
					message: this.translateService.get("CREAR_PROVEEDOR"),
					accept: () => {
						$this.router.navigate(["/proveedor/nuevo"], { queryParams: { hotel: "true" } })
					},
					reject: () => {
						$this.router.navigate(["/hotel/nuevo"])
					}
				})
			},
			editar: (id, item: Hotel) => {
				if (item.proveedor) {
					$this.router.navigate(["/proveedor/edit"], { queryParams: { id: item.proveedor, hotel: "true", hotelId: item.id } })
				} else {
					$this.router.navigate(["/hotel/edit"], { queryParams: { id: id } })
				}
			},
			eliminar: null
		}
	}
	ngOnDestroy() {}
}
