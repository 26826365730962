import { ProfilePic } from "src/app/common/model/ProfilePic"
import { Idioma } from "./Idioma"
import { Moneda } from "./Moneda"
import { Nacionalidad } from "./Nacionalidad"

export class ConfiguracionEntorno {
	constructor(
		public id?: number,
		public version?: number,
		public profilePic: ProfilePic = new ProfilePic(null, "assets/images/default_profile.png"),
		public nombreEntorno?: string,
		public pais?: Nacionalidad,
		public idioma?: Idioma,
		public moneda?: Moneda,
		public mostrarSiempreImpuestos: boolean = false,
		public fechaVencimientoPorDefault: boolean = false
	) {
		if (!pais) this.pais = Nacionalidad.fromData({ codigo: "AR", descripcion: "Argentina" })
		if (!idioma) this.idioma = Idioma.fromData({ codigo: "es", descripcion: "Español" })
		if (!moneda) this.moneda = Moneda.fromData({ codigo: "ARS", descripcion: "Pesos Argentinos" })
	}
	public static fromData(data: any): ConfiguracionEntorno {
		if (!data) return null
		const o: ConfiguracionEntorno = new ConfiguracionEntorno(
			data.id,
			data.version,
			ProfilePic.fromData(data.profilePic),
			data.nombreEntorno,
			Nacionalidad.fromData(data.pais),
			Idioma.fromData(data.idioma),
			Moneda.fromData(data.moneda),
			data.mostrarSiempreImpuestos,
			data.fechaVencimientoPorDefault
		)

		return o
	}
}
