import { HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { share } from "rxjs/operators"
import { ServicioAbstract } from "src/app/common/services/service.service"
import { Reserva } from "src/app/model/Reserva"
import { ResumenReserva } from "src/app/model/ResumenReserva"
import { EstadoEntidadIntegracion } from "../model/EstadoEntidadIntegracion"
import { FiltroReporteEncuesta } from "../model/FiltroReporteEncuesta"
import { ItemReserva } from "../model/ItemReserva"
import { ReportePrevisionReserva } from "../model/ReportePrevisionReserva"
import { ReservaConNota } from "../model/ReservaConNota"
import { Totales } from "../model/Totales"
import { LoadingService } from "./../common/services/loading-data-service.service"
import { GenerarComprobante } from "./../model/GenerarComprobante"
import { Moneda } from "./../model/Moneda"
import { CancelacionReserva } from "./../pages/reserva/cancelacion-reserva/CancelacionReserva"
import { FiltroReserva } from "./../pages/reserva/listado-reserva/FiltroReserva"
@Injectable({
	providedIn: "root"
})
export class ReservaService extends ServicioAbstract<Reserva> {
	public baseName(): string {
		return "reserva"
	}
	public parseToEnt(data: any): Reserva {
		return Reserva.fromData(data)
	}
	public newEnt(): Reserva {
		return new Reserva()
	}

	toFile = (x, nombre, sub) => {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		var newBlob = new Blob([x], { type: "application/pdf" })

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob)

		var link = document.createElement("a")
		link.href = data
		link.download = nombre ? nombre + ".pdf" : "comprobante.pdf"
		// this is necessary as link.click() does not work on the latest firefox
		link.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true, view: window }))

		setTimeout(function () {
			// For Firefox it is necessary to delay revoking the ObjectURL
			window.URL.revokeObjectURL(data)
			link.remove()
			sub.unsubscribe()
		}, 100)
	}
	descargarLiquidacion(id: number, nombre?: string, customLoading?: LoadingService) {
		var sub = this.http.get(this.getBaseURL("getLiquidacion/" + id), { responseType: "blob" }).subscribe((r) => this.toFile(r, nombre, sub))
	}
	verLiquidacion(id: number, customLoading?: LoadingService) {
		var sub = this.http.get(this.getBaseURL("getLiquidacion/" + id), { responseType: "blob" }).subscribe((x) => {
			// It is necessary to create a new blob object with mime-type explicitly set
			// otherwise only Chrome works like it should
			var newBlob = new Blob([x], { type: "application/pdf" })
			var url = URL.createObjectURL(newBlob)
			window.open(url, "_blank")
			sub.unsubscribe()
		})
	}
	notificarProveedores(id: number, yaNotificados: boolean = false, customLoading?: LoadingService): Promise<ResumenReserva> {
		let $this = this
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		let params = new HttpParams()
		params = params.append("id", id + "")
		params = params.append("yaNotificados", yaNotificados + "")
		return lastValueFrom(this.http.post(this.getBaseURL() + "notificarProveedores", params)).then(
			(r) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return ResumenReserva.fromData(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	generarComprobante(generador: GenerarComprobante, customLoading?: LoadingService): Promise<Reserva> {
		let $this = this
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL() + "generarComprobante", generador.json)).then(
			(r) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return this.parseSingle(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	enviarItinerario(envioItinerario, customLoading?: LoadingService): Promise<Reserva> {
		let $this = this
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL() + "enviarItinerario", envioItinerario)).then(
			(r) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return this.parseSingle(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	enviarVouchers(id: number, customLoading?: LoadingService): Promise<Reserva> {
		let $this = this
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		let params = new HttpParams()
		params = params.append("id", id + "")
		return lastValueFrom(this.http.post(this.getBaseURL() + "enviarVouchers", params)).then(
			(r) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return this.parseSingle(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	public confirmarReserva(id: number): Promise<boolean> {
		let params = new HttpParams()
		params = params.append("id", id + "")
		return lastValueFrom(this.http.post(this.getBaseURL() + "confirmar", params))
			.then((r) => {
				return r
			})
			.catch(this.handleError)
	}
	public cerrarReserva(id: number): Promise<boolean> {
		let params = new HttpParams()
		params = params.append("id", id + "")
		return lastValueFrom(this.http.post(this.getBaseURL() + "cerrar", params))
			.then((r) => {
				return r
			})
			.catch(this.handleError)
	}

	public reabrirReserva(id: number): Promise<boolean> {
		let params = new HttpParams()
		params = params.append("id", id + "")
		return lastValueFrom(this.http.post(this.getBaseURL() + "reabrir", params))
			.then((r) => {
				return r
			})
			.catch(this.handleError)
	}

	public finalizarReserva(id: number): Promise<boolean> {
		let params = new HttpParams()
		params = params.append("id", id + "")
		return lastValueFrom(this.http.post(this.getBaseURL() + "finalizar", params))
			.then((r) => {
				return r
			})
			.catch(this.handleError)
	}
	public parse = (r: any): ResumenReserva[] => {
		let $this = this
		if (r) {
			let result = r.map((f) => ResumenReserva.fromData(f))

			return result
		}
		return []
	}

	public async cancelarServicio(servicio: ItemReserva, customLoading?: LoadingService): Promise<ReservaConNota> {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()

		return lastValueFrom(this.http.post(this.getBaseURL() + "cancelarServicio", servicio.json)).then(
			(r: ReservaConNota) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return r
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	public async cancelar(r: CancelacionReserva, customLoading?: LoadingService): Promise<Reserva> {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()

		return lastValueFrom(this.http.post(this.getBaseURL() + "cancelar", r)).then(
			(r: Reserva) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return Reserva.fromData(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	public getTotales(filtro: FiltroReserva, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("totales"), filtro.json).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				const tot = new Totales()
				r.forEach((t) =>
					tot.agregarTotal({
						moneda: Moneda.fromData(t.moneda),
						monto: t.totalConsumido,
						saldo: t.totalSaldo,
						count: t.count
					})
				)
				return tot
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	public getResumenById(id: number): Promise<ResumenReserva> {
		return lastValueFrom(this.http.get(this.getBaseURL("getResumen/" + id))).then((r) => ResumenReserva.fromData(r))
	}

	guardar(e: Reserva, customLoading?: LoadingService) {
		return super.guardar(e, customLoading).then((r) => {
			if (r?.errores?.length) {
				this.messages.warning(r.errores.join(", "))
			}
			return r
		})
	}

	getReportePrevision(f: FiltroReporteEncuesta, customLoading?: LoadingService): Promise<ReportePrevisionReserva[]> {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("get-reporte-prevision"), f.json)).then(
			(r: any) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return r.map((x) => ReportePrevisionReserva.fromData(x))
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	getExternasFallidas(customLoading?: LoadingService): Promise<EstadoEntidadIntegracion[]> {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL("getExternasFallidas"))).then((r: any) => {
			if (customLoading) customLoading.susLoadingCount()
			else this.loadingService.susLoadingCount()
			return r.map((eei) => EstadoEntidadIntegracion.fromData(eei))
		})
	}
}
