import * as moment from "moment"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { Descriptivo } from "./Descriptivo"
import { Filtro } from "./Filtro"

export class FiltroFactura extends Filtro {
	constructor(
		name: string,
		filterMap = {
			proveedor: "Proveedor",
			responsable: "Responsable",
			fechaDesde: "Desde",
			fechaHasta: "Hasta",
			impagas: "Impagas",
			externas: "Externas",
			tipoProveedor: "Tipo Proveedor",
			estados: "Estados",
			categorias: "Categorias"
		},
		page = 0,
		size = 20,
		sortfield = "id",
		order = 2,
		defaults?: any
	) {
		super(name, filterMap, page, size, sortfield, order)
		this.guardable = true
		if (defaults) this.setMultiple(defaults)
	}

	private _proveedor: Descriptivo
	private _responsable: Descriptivo
	private _fechaDesde: Date
	private _fechaHasta: Date
	private _estados: EstadoDocumento[] = []
	private _tiposComprobante: Descriptivo[] = []
	private _tipoProveedor: Descriptivo
	private _categorias: Descriptivo[] = []
	private _puestos: Descriptivo[] = []
	public get puestos(): Descriptivo[] {
		return this._puestos
	}
	public set puestos(v: Descriptivo[]) {
		this._puestos = v
		this.update()
	}
	public get categorias(): Descriptivo[] {
		return this._categorias
	}
	public set categorias(v: Descriptivo[]) {
		this._categorias = v
		this.update()
	}

	private _fechaDesdeVencimiento: Date
	public get fechaDesdeVencimiento(): Date {
		return this._fechaDesdeVencimiento
	}
	public set fechaDesdeVencimiento(v: Date) {
		this._fechaDesdeVencimiento = v
		this.update()
	}

	private _fechaHastaVencimiento: Date
	public get fechaHastaVencimiento(): Date {
		return this._fechaHastaVencimiento
	}
	public set fechaHastaVencimiento(v: Date) {
		this._fechaHastaVencimiento = moment(v).endOf("day").toDate()
		this.update()
	}

	public get tipoProveedor(): Descriptivo {
		return this._tipoProveedor
	}
	public set tipoProveedor(v: Descriptivo) {
		if (v != this._tipoProveedor) {
			this._tipoProveedor = v
			this.update()
		}
	}

	public get estados(): EstadoDocumento[] {
		return this._estados
	}
	public set estados(v: EstadoDocumento[]) {
		if (v != this._estados) {
			this._estados = v
			this.update()
		}
	}

	public tieneEstado(estado: EstadoDocumento) {
		return this.estados?.some((e) => e.id == estado.id)
	}
	public toggleEstado(estado: EstadoDocumento) {
		if (this.tieneEstado(estado)) {
			this.estados = this.estados.filter((e) => e.id != estado.id)
		} else {
			this.estados.push(estado)
		}
		this.update(true)
	}
	private _impagas: boolean
	private _externas: boolean
	private _porRendir: boolean
	public get tipoComprobante(): Descriptivo {
		return this.tiposComprobante ? this.tiposComprobante[0] : null
	}
	public set tipoComprobante(v: Descriptivo) {
		this.tiposComprobante = v ? [v] : []
		this.update()
	}

	public get tiposComprobante(): Descriptivo[] {
		return this._tiposComprobante
	}
	public set tiposComprobante(v: Descriptivo[]) {
		this._tiposComprobante = v
		this.update()
	}
	public get proveedor(): Descriptivo {
		return this._proveedor
	}
	public set proveedor(v: Descriptivo) {
		this._proveedor = v
		this.update()
	}

	public get responsable(): Descriptivo {
		return this._responsable
	}
	public set responsable(v: Descriptivo) {
		this._responsable = v
		this.update()
	}

	public get fechaDesde(): Date {
		return this._fechaDesde
	}
	public set fechaDesde(v: Date) {
		this._fechaDesde = v
		this.update()
	}

	public get fechaHasta(): Date {
		return this._fechaHasta
	}
	public set fechaHasta(v: Date) {
		this._fechaHasta = moment(v).endOf("day").toDate()
		this.update()
	}

	public get impagas(): boolean {
		return this._impagas
	}
	public set impagas(v: boolean) {
		this._impagas = v
		this.update()
	}

	public get externas(): boolean {
		return this._externas
	}
	public set externas(v: boolean) {
		this._externas = v
		this.update()
	}

	public get porRendir(): boolean {
		return this._porRendir
	}
	public set porRendir(v: boolean) {
		this._porRendir = v
		this.update()
	}

	public clean() {
		let cleanVal = {
			proveedor: null,
			responsable: null,
			fechaDesdeVencimiento: null,
			fechaHastaVencimiento: null,
			fechaDesde: moment().startOf("month").toDate(),
			fechaHasta: null,
			impagas: false,
			externas: false,
			estados: [],
			tipoProveedor: null,
			puestos: [],
			tiposComprobante: [],
			categorias: []
		}
		this.setMultiple(cleanVal, false)
		super.clean(true)
	}

	public patchValue(v: any) {
		super.patchValue(v, false)
		this._proveedor = v.proveedor ? Descriptivo.fromData(v.proveedor) : null
		this._responsable = v.responsable ? Descriptivo.fromData(v.responsable) : null
		this._fechaDesde = v.fechaDesde ? new Date(v.fechaDesde) : null
		this._fechaHasta = v.fechaHasta ? new Date(v.fechaHasta) : null
		this._fechaDesdeVencimiento = v.fechaDesdeVencimiento ? new Date(v.fechaDesdeVencimiento) : null
		this._fechaHastaVencimiento = v.fechaHastaVencimiento ? new Date(v.fechaHastaVencimiento) : null
		this._impagas = v.impagas
		this._externas = v.externas
		this._puestos = v.puestos ? v.puestos.map((t) => Descriptivo.fromData(t)) : []
		this._tipoProveedor = v.tipoProveedor
		this._estados = v.estados ? v.estados.map((e) => EstadoDocumento.fromData(e)) : []
		this._tiposComprobante = v.tiposComprobante ? v.tiposComprobante.map((t) => Descriptivo.fromData(t)) : []
		this._categorias = v.categorias ? v.categorias.map((t) => Descriptivo.fromData(t)) : []
		this.update()
	}

	public valid(): boolean {
		return this.fechaDesde != undefined
	}

	clonar() {
		const f = new FiltroFactura(null, null)
		f.patchValue(this)
		return f
	}

	public apply(d: any[]) {
		const searchLike = this.searchStr ? this.searchStr.toUpperCase().split(" ") : null
		return d.filter(
			(value) =>
				(this.categorias?.length == 0 || this.categorias.some((c) => value["categoria"]?.id == c.id)) &&
				(!searchLike || !value["searchStr"] || searchLike.every((v) => value["searchStr"].toUpperCase().includes(v.toUpperCase())))
		)
	}
}
