<div class="gestor-cambio-estado-caja content content-white">
	<model-gestor
		[readonly]="readonly"
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[verAcciones]="false"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="!readonly"
		(onCancelar)="handleCancelar($event)"
		[form]="ArqueoCajaForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
	>
		<div class="title" model-type="title">
			<div class="grid">
				<div class="col title-content">
					<h2>{{ "Arqueo de Caja" | translate }}</h2>
				</div>
			</div>
		</div>
		<div class="gestor-form" model-type="dataContent">
			<form #ArqueoCajaForm="ngForm" id="ArqueoCajaform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-4">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<p-calendar
								[showIcon]="true"
								[(ngModel)]="item.fecha"
								#fecha="ngModel"
								name="fecha"
								[readonly]="readonly"
								appendTo="body"
							></p-calendar>
							<label>{{ "FECHA" | translate }}</label>
							<error-tag [model]="fechaHasta"></error-tag>
						</span>
					</div>
					<div class="col">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<input pInputText value="{{ item.responsable?.descripcion }}" readonly />
							<label>{{ "Responsable" | translate }}</label>
						</span>
					</div>

					<div class="col-12 lg:col-4" *ngIf="cajaEditable">
						<descriptivo-material-selector
							[allowDefault]="!item?.id"
							[allowDefault]="!item?.id"
							[options]="cajaService?.data | async"
							[label]="'CAJA' | translate"
							[limpiable]="false"
							[(ngModel)]="cajaSeleccionada"
							[permiteNuevo]="false"
							(onNew)="nuevocajaOptions()"
							name="caja"
							[readonly]="readonly"
							required
						>
						</descriptivo-material-selector>
					</div>
					<div class="col-12 billetes">
						<span appearance="outline" floatLabel="always" [ngClass]="{ readonly: readonly }">
							<label>{{ "Conteo de Billetes" | translate }}</label>
							<input pInputText readonly />
							<div class="grid align-content-center">
								<div class="col-6 lg:col-3 billete" *ngFor="let billete of item.valores; let i = index">
									<div class="billete-wrapper">
										<div class="grid align-content-center">
											<div class="col-4 text-right">
												<span>{{ billete?.descripcion }} </span>
											</div>
											<div class="col-8 text-center">
												<p-inputNumber
													(focusin)="onFocus($event)"
													(onBlur)="updateMonto()"
													[style]="{ 'text-align': 'center' }"
													tooltipPosition="bottom"
													pTooltip="{{ 'Cantidad de billetes' | translate }}"
													[disabled]="readonly"
													name="{{ 'cantidad' + i }}"
													[(ngModel)]="billete.cantidad"
													[showButtons]="true"
													buttonLayout="horizontal"
													spinnerMode="horizontal"
												>
												</p-inputNumber>
											</div>
										</div>
									</div>
								</div>
							</div>
						</span>
					</div>
					<div class="col-12 lg:col-6">
						<span-dinero-editable [(ngModel)]="monto" [label]="'Monto en caja' | translate" [editable]="!readonly" name="{{ 'monto' + r }}">
						</span-dinero-editable>
					</div>
					<div class="col-12 lg:col-6">
						<span-dinero-editable [(ngModel)]="item.diferencia" [label]="'Diferencia' | translate" [editable]="false" name="{{ 'diferencia' + r }}">
						</span-dinero-editable>
					</div>
					<div class="col-12">
						<span class="p-float-label field">
							<input
								pInputText
								placeholder="{{ 'JUSTIFICACION_PH' | translate }}"
								required
								[(ngModel)]="item.justificacion"
								tooltipPosition="bottom"
								pTooltip="{{ 'JUSTIFICACION_TOOLTIP' | translate }}"
								type="text"
								#justificacion="ngModel"
								name="justificacion"
								[readonly]="readonly"
							/><label>{{ "JUSTIFICACION" | translate }}</label>
							<error-tag [model]="justificacion"></error-tag>
						</span>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
