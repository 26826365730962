import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { MessagesService } from "../common/services/messages-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class EstadoDocumentoService extends ParametricoService<EstadoDocumento> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "estado-documento"
	}
	public parseToEnt(data: any): EstadoDocumento {
		return EstadoDocumento.fromData(data)
	}
	public newEnt(): EstadoDocumento {
		return new EstadoDocumento()
	}
	public async getActivos() {
		return (await this.getData()).filter((e) => e.esTerminado || e.codigo == "A" || e.codigo == "PP")
	}
}
