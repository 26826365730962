import { debounceTime, filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ItemEncuestaActividades } from 'src/app/model/ItemEncuestaActividades';
import { ProductoService } from 'src/app/services/producto.service'
import { CategoriaProductoService } from 'src/app/services/categoria-producto.service'
import { TipoProductoService } from './../../../services/tipo-producto.service'
import { VehiculoService } from 'src/app/services/vehiculo.service'
import { ProveedorService } from 'src/app/services/proveedor.service'
import { RespuestaEncuestaActividadesService } from 'src/app/services/respuesta-encuesta-actividades.service'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { SessionComponent } from 'src/app/common/components/session-component.component'
import { FiltroReporteEncuesta } from './../../../model/FiltroReporteEncuesta'
import { Component, OnInit } from '@angular/core'
import { Puntaje, ReporteEncuesta, ReporteEncuestaSumarizado } from 'src/app/model/ReporteEncuesta'
import { Descriptivo } from 'src/app/common/model/Descriptivo'

@Component({
    selector: 'reporte-encuestas',
    templateUrl: './reporte-encuestas.component.html',
    styleUrls: ['./reporte-encuestas.component.less'],
})
export class ReporteEncuestasComponent extends SessionComponent implements OnInit {
    filtro = new FiltroReporteEncuesta('encuesta')
    listado: ReporteEncuesta[] = []
    productos: Descriptivo[] = []
    reporte: ReporteEncuestaSumarizado[] = []
    respuestas: any[] = []
    searchChg: BehaviorSubject<string> = new BehaviorSubject(null)
    
    private _searchStr : string;
    public get searchStr() : string {
        return this._searchStr;
    }
    public set searchStr(v : string) {
        this._searchStr = v;
        this.searchChg.next(v)
    }
    

    constructor(
        public messagesService: MessagesService,
        private service: RespuestaEncuestaActividadesService,
        public proveedorService: ProveedorService,
        public vehiculoService: VehiculoService,
        public tipoProductoService: TipoProductoService,
        public categoriaProductoService: CategoriaProductoService,
        public productoService: ProductoService
    ) {
        super(messagesService)
    }
    public puntajeSeleccionado:number = null;
    ngOnInit(): void {

        this.searchChg.pipe(debounceTime(500), filter(s => !s || !s.length || s.length > 3)).subscribe(data => {
            this.filtrarRespuestas(data)
        })

        this.filtro.valueChange.subscribe((f: FiltroReporteEncuesta) => {
            this.buscar(f)
        })

        this.productoService.getServicios().then((r) => {
            this.productos = r
        })
    }

    buscar(f: FiltroReporteEncuesta) {
        this.service.getReporte(f).then((r) => {
            this.listado = r
            this.generarReporte()
            this.crearListadoRespuestas(this.listado)
        })
    }

    generarReporte() {
        this.reporte = []
        this.listado.forEach((encuesta) => {
            encuesta.items.forEach((item) => {
                let i = this.reporte.find((it) => it.idPregunta == item.pregunta.id)

                if (item.pregunta.tipoPregunta.codigo == 'R') {
                    if (i) {
                        i.cantidad++
                        i.total += parseInt(item.respuesta)
                        let puntaje = i.puntajes.find((p) => p.valor == parseInt(item.respuesta))
                        if (puntaje) {
                            puntaje.cantidad++
                        }
                    } else {
                        let newItem = new ReporteEncuestaSumarizado(item.pregunta.id, item.pregunta.descripcion, 1, parseInt(item.respuesta), [
                            new Puntaje(5, 0),
                            new Puntaje(4, 0),
                            new Puntaje(3, 0),
                            new Puntaje(2, 0),
                            new Puntaje(1, 0),
                        ])
                        let puntaje = newItem.puntajes.find((p) => p.valor == parseInt(item.respuesta))
                        if (puntaje) {
                            puntaje.cantidad++
                        }
                        this.reporte = [...this.reporte, newItem]
                    }
                }
            })
        })
    }

    onClickPuntaje(puntaje: Puntaje, item: ReporteEncuestaSumarizado) {
        this.puntajeSeleccionado = puntaje.valor;
       let l = this.listado.filter((l) => l.items.some((i) => i.pregunta.id == item.idPregunta && i.respuesta == puntaje.valor.toString()))
        this.crearListadoRespuestas(l)

    }

    crearListadoRespuestas(l: ReporteEncuesta[] = []) {
        this.respuestas = []
        l.forEach(respuesta => {
            this.respuestas = [...this.respuestas, ...respuesta.items.filter( i => i.pregunta.tipoPregunta.codigo != "R" ).map(r => {
                return {
                     pregunta: r.pregunta,
                     respuesta: r.respuesta,
                     producto: respuesta.descripcionProducto,
                     fechaCompletado: respuesta.fechaCompletado,
                     nombrePax: respuesta.nombrePax
                }
            })]
        })
    }

    getValue(puntaje,item) {
        return Math.round((puntaje.cantidad / item.cantidad) * 100 * 100) / 100
    }

    onClickEstrella(i: number) {
        let v = i + 1
        if(this.puntajeSeleccionado == v){
            this.puntajeSeleccionado = null;
            this.crearListadoRespuestas(this.listado);
        }else{
            this.puntajeSeleccionado = v;
            let l = this.listado.filter((l) => l.items.some((item) => item.respuesta == v.toString()))
            this.crearListadoRespuestas(l)
        }
       
    }

    filtrarRespuestas(str: string) {
        let l = this.listado.filter((l) => l.items.some((i) => i.respuesta.toLowerCase().includes(str.toLowerCase())))
        this.respuestas = []
        
        this.crearListadoRespuestas(l)
    }
}
