import { Pipe, PipeTransform } from "@angular/core"
import { PuestoServicioReserva } from "../model/PuestoServicioReserva"

@Pipe({
	name: "prestador"
})
export class PrestadorPipe implements PipeTransform {
	transform(value: PuestoServicioReserva[]): PuestoServicioReserva[] {
		if (!value || !value.length) return []

		return value.filter((p) => p.tipoPuesto.esPrestador || (p.proveedor && p.proveedor.esPrestador))
	}
}
