import moment from "moment"
import { Filtro } from "src/app/common/model/Filtro"
import { Descriptivo } from "./../common/model/Descriptivo"

export class FiltroCliente extends Filtro {
	constructor(name: string, page?: number, size?: number, sortField: string = "fecha", order: number = 2, guardable: boolean = true, defaults?: any) {
		super(
			name,
			{
				cliente: "Cliente",
				tipoCliente: "Tipo de Cliente",
				soloImpagas: "Solo con saldo",
				verArchivados: "Ver archivados"
			},
			page,
			size,
			sortField,
			order,
			guardable
		)
		defaults && Object.assign(this, defaults)
	}

	private _cliente: Descriptivo
	private _soloImpagas: boolean
	private _fechaDesde: Date = moment().subtract(60, "days").startOf("day").toDate()
	private _fechaHasta: Date
	public get fechaDesde(): Date {
		return this._fechaDesde
	}
	public set fechaDesde(v: Date) {
		this._fechaDesde = v
		this.update()
	}

	public get fechaHasta(): Date {
		return this._fechaHasta
	}
	public set fechaHasta(v: Date) {
		this._fechaHasta = moment(v).endOf("day").toDate()
		this.update()
	}
	private _tiposCliente: Descriptivo[] = []
	public get tiposCliente(): Descriptivo[] {
		return this._tiposCliente
	}
	public set tiposCliente(v: Descriptivo[]) {
		if (v != this._tiposCliente) {
			this._tiposCliente = v
			this.update()
		}
	}

	public get tipoCliente(): Descriptivo {
		return this.tiposCliente ? this.tiposCliente[0] : null
	}
	public set tipoCliente(v: Descriptivo) {
		this.tiposCliente = v ? [v] : []
	}

	public get soloImpagas(): boolean {
		return this._soloImpagas
	}
	public set soloImpagas(v: boolean) {
		if (v != this._soloImpagas) {
			this._soloImpagas = v
			this.update()
		}
	}

	public get cliente(): Descriptivo {
		return this._cliente
	}
	public set cliente(v: Descriptivo) {
		this._cliente = v
		this.update()
	}

	public get verArchivados(): boolean {
		return this._verArchivados
	}
	public set verArchivados(v: boolean) {
		if (v != this._verArchivados) {
			this._verArchivados = v
			this.update()
		}
	}

	public clean() {
		let cleanVal = {
			searchStr: "",
			cliente: null,
			tiposComprobante: [],
			tiposCliente: [],
			soloImpagas: false,
			verArchivados: false,
			fechaDesde: moment().startOf("month").toDate(),
			fechaHasta: null
		}

		this.setMultiple(cleanVal, false)
		super.clean(true)
	}

	public patchValue(v: any) {
		super.patchValue(v, false)
		this._searchStr = v.searchStr
		this._cliente = v.cliente ? Descriptivo.fromData(v.cliente) : null
		this._soloImpagas = v.soloImpagas
		this._verArchivados = v.verArchivados
		this._tiposCliente = v._tiposCliente ? v._tiposCliente.map((t) => Descriptivo.fromData(t)) : []
		this._fechaDesde = v.fechaDesde ? new Date(v.fechaDesde) : moment().subtract(60, "days").startOf("day").toDate()
		this._fechaHasta = v.fechaHasta ? new Date(v.fechaHasta) : null
		this.update()
	}

	public valid(): boolean {
		return true
	}
	clonar() {
		const f = new FiltroCliente(null, null)
		f.patchValue(this)
		return f
	}

	public apply(data: any[]): any[] {
		const searchLike = this.searchStr ? this.searchStr.toUpperCase().split(" ") : null
		return data.filter(
			(value) =>
				(!searchLike || !value["searchStr"] || searchLike.every((v) => value["searchStr"].toUpperCase().includes(v))) &&
				(!this.cliente || (value["cliente"] && value["cliente"].id == this.cliente.id)) &&
				(!this.soloImpagas || value["saldo"] != 0) &&
				(this.verArchivados || value["activo"])
		)
	}
}
