<div class="bandera-contenedor">
	<ng-container *ngIf="mode === 'dropdown'">
		<button pButton (click)="handleClick($event)" class="p-button-text p-bandera" #button pRipple>
			<img
				[src]="idioma?.imagen"
				alt="Sin imagen"
				[ngClass]="{ display: readOnly }"
				tooltipPosition="bottom"
				pTooltip="{{ idioma?.descripcion }}"
				*ngIf="idioma"
			/>
			<span *ngIf="!idioma" (click)="handleClick($event)">Elija un idioma</span>
		</button>
	</ng-container>
	<ng-container *ngIf="mode === 'text'">
		<span class="idioma" *ngFor="let idioma of idiomas" (click)="seleccionar(idioma)">{{ idioma?.codigo }}</span>
	</ng-container>
</div>
<p-overlayPanel #op appendTo="body" [style]="{ 'max-width': isMobile() ? '100vw' : '15vw' }">
	<ng-template pTemplate>
		<div class="grid">
			<div class="col-12 opcion" *ngFor="let opcion of idiomas" (click)="seleccionar(opcion); op.hide($event)">
				<div class="grid">
					<div class="col-fixed" style="width: 4em; height: 4em">
						<img src="{{ opcion?.imagen }}" alt="" style="max-width: 100%" />
					</div>
					<div class="col">
						<span>{{ opcion?.descripcion }}</span>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</p-overlayPanel>
