import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { AuthService } from "src/app/services/auth.service"
import { NovedadService } from "src/app/services/novedades.service"
import { MessagesService } from "./messages-data-service.service"

@Injectable({
	providedIn: "root"
})
export class EstadoDocumentoService extends ParametricoService<EstadoDocumento> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "estado-documento"
	}
	public parseToEnt(data: any): EstadoDocumento {
		return EstadoDocumento.fromData(data)
	}
	public newEnt(): EstadoDocumento {
		return new EstadoDocumento()
	}
}
