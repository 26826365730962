import { lastValueFrom } from 'rxjs';
import { AccionAutomatica } from 'src/app/model/AccionAutomatica';
import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { SerieAccion } from 'src/app/model/SerieAccion';
import { LoadingService } from './../common/services/loading-data-service.service';
@Injectable({
  providedIn: 'root',
})
export class SerieAccionService extends ServicioAbstract<SerieAccion>{
    public baseName(): string {
        return "serie-accion";
    }
    public parseToEnt(data: any): SerieAccion {
        return SerieAccion.fromData(data);
    }
    public newEnt(): SerieAccion {
        return new SerieAccion();
    }


    cancelarRecurrencia(accion: AccionAutomatica, customLoading?: LoadingService) {
        let $this = this
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http
            .post(this.getBaseURL() + "cancelarRecurrencia", accion)) 
            .then(
                (r) => {
                    if (customLoading) customLoading.susLoadingCount()
                    else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )    
    
    
    
    }


    eliminar(id: number, customLoading?: LoadingService) {

        if (customLoading) { customLoading.addLoadingCount(); }
        else this.loadingService.addLoadingCount()
        return this.http.delete(this.getBaseURL() + id)
            .toPromise()
            .then((r) => {
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
                return r
            });
    }


}