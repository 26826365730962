
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { TraspasoDeCaja } from 'src/app/model/TraspasoDeCaja';
import { TraspasoDeCajaService } from 'src/app/services/traspaso-de-caja.service';

@Component({
  selector: 'listado-traspaso-de-caja',
  templateUrl: 'listado-traspaso-de-caja.component.html',
  styleUrls: ['listado-traspaso-de-caja.component.less']
})

export class ListadoTraspasoDeCajaComponent extends SessionComponent implements OnInit, OnDestroy {

  public filtro: Filtro;

  @Input()
  public guardarFiltro: boolean = true;
  @Input()
  public ocultarCabecera: boolean = false;
  @Input()
  public editable: boolean = true;
  public listado: TraspasoDeCaja[] = [];
  @Input()
  public readonly: boolean = false;
  @Input()
  public modoSeleccion: 'single' | 'multiple' | 'none' = 'none';

  @Output()
  public onSelect: EventEmitter<TraspasoDeCaja[]> = new EventEmitter();

  public columns = [];
  constructor(messagesService: MessagesService,
    public service: TraspasoDeCajaService) {
    super(messagesService)
    console.log(JSON.stringify(this.columns));
  }
  ngOnInit() {
    this.filtro = new Filtro("traspaso-de-caja_lista", {}, 0, 20, 'id', 1, this.guardarFiltro)
    this.columns = [
      {
        "field": "id",
        "header": "#",
        "class": "id-column",
        "role": "text-tabla",
        "sortable": true,
        "order": 1
      },

      {
        "field": "fecha",
        "header": "Fecha",
        "class": "date-tabla",
        "role": "date-tabla",
        "sortable": false,
        "order": 40
      },

      {
        "field": "descripcion",
        "header": "Descripcion",
        "class": "text-tabla",
        "role": "text-tabla",
        "sortable": true,
        "order": 40
      },

      {
        "field": "cajaOrigen",
        "header": "Caja Origen",
        "class": "descriptivo-tabla",
        "role": "descriptivo-tabla",
        "sortable": true,
        "order": 40
      },

      {
        "field": "formaPagoOrigen",
        "header": "Forma Pago Origen",
        "class": "descriptivo-tabla",
        "role": "descriptivo-tabla",
        "sortable": true,
        "order": 40
      },

      {
        "field": "cajaDestino",
        "header": "Caja Destino",
        "class": "descriptivo-tabla",
        "role": "descriptivo-tabla",
        "sortable": true,
        "order": 40
      },

      {
        "field": "formaPagoDestino",
        "header": "Forma Pago Destino",
        "class": "descriptivo-tabla",
        "role": "descriptivo-tabla",
        "sortable": true,
        "order": 40
      },

      {
        "field": "responsable",
        "header": "Responsable",
        "class": "descriptivo-tabla",
        "role": "descriptivo-tabla",
        "sortable": true,
        "order": 40
      },
      {
        "field": "monto",
        "header": "Monto",
        "class": "number-tabla",
        "role": "number-tabla",
        "sortable": false,
        "order": 40
      },
      {
        "field": "costo",
        "header": "Costo",
        "class": "number-tabla",
        "role": "number-tabla",
        "sortable": false,
        "order": 40
      },
    ];
  }

  public updateSeleccion(seleccion: any) {
    if (seleccion?.length || seleccion['id']) {
      this.onSelect.emit(seleccion?.length ? seleccion : [seleccion]);
    }
  }
  ngOnDestroy() { }



}