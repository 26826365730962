import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { FormaPago } from "src/app/model/FormaPago"
import { MessagesService } from "../common/services/messages-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class FormaPagoService extends ParametricoService<FormaPago> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "forma-pago"
	}
	public parseToEnt(data: any): FormaPago {
		return FormaPago.fromData(data)
	}
	public newEnt(): FormaPago {
		return new FormaPago()
	}
}
