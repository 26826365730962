import { Parametrico } from './../common/model/Parametrico';


export class TipoVehiculo extends Parametrico {
    constructor(id?: number, codigo?: string, descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,


    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso)
    }
    public tipoParametro(): string {
        return "tipovehiculo";
    }
    public static fromData(data: any): TipoVehiculo {
        if (!data) return null;
        let o: TipoVehiculo = new TipoVehiculo(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,

        );
        o.version = data.version
return o;

    }

}