<div class="listado-margen-reporte content" *ngIf="filtro">
	<model-list
		[modelName]="'margen-reporte'"
		[columns]="columns"
		[title]="'MargenReporte_PLR' | translate"
		[(listado)]="listado"
		[isLazy]="false"
		[filtro]="filtro"
		[multilenguaje]="false"
		[modoSeleccion]="modoSeleccion"
		[hideHeader]="ocultarCabecera"
		[filterContent]="filterContent"
		[footer]="footerTemplate"
		[editable]="false"
		[borrable]="false"
		(seleccionChange)="updateSeleccion($event)"
		[readonly]="readonly"
	>
		<ng-template #footerTemplate>
			<tr class="totales" [allowed]="'ROLE_JEFE_ADMIN'">
				<td [attr.colspan]="2">
					<div class="grid align-content-center text-right">
						<div class="col-12">
							<b>{{ "TOTALES" | translate }}</b>
						</div>
					</div>
				</td>
				<td class="text-right total">
					<div class="grid align-content-center grid-nogutter">
						<div class="col-12 text-right">
							<b>{{ totales?.totalCobro | currency : "USD" : monedaVisualizacion?.simbolo || "$ " }}</b>
						</div>
					</div>
				</td>
				<td class="text-right total">
					<div class="grid align-content-center grid-nogutter">
						<div class="col-12 text-right">
							<b>{{ totales?.totalFacturado | currency : "USD" : monedaVisualizacion?.simbolo || "$ " }}</b>
						</div>
					</div>
				</td>
				<td class="text-right total">
					<div class="grid align-content-center grid-nogutter">
						<div class="col-12 text-right">
							<b>{{ totales?.totalVenta | currency : "USD" : monedaVisualizacion?.simbolo || "$ " }}</b>
						</div>
					</div>
				</td>
				<td class="text-right total">
					<div class="grid align-content-center grid-nogutter">
						<div class="col-12 text-right">
							<b>{{ totales?.totalGastos | currency : "USD" : monedaVisualizacion?.simbolo || "$ " }}</b>
						</div>
					</div>
				</td>
				<td></td>
			</tr>
		</ng-template>
		<ng-template #filterContent>
			<div class="grid">
				<div class="col-12">
					<div class="p-inputgroup">
						<span class="p-float-label">
							<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
							<label>{{ "BUSCAR" | translate }}...</label>
						</span>
						<button type="button" pButton icon="pi pi-search"></button>
					</div>
				</div>

				<div class="col-12" *ngIf="opcionesPunto?.length > 1">
					<descriptivo-material-selector
						[label]="'PUNTO_VENTA' | translate"
						[limpiable]="true"
						[(ngModel)]="filtro.puntoDeVenta"
						isLazy="true"
						name="puntoDeVenta"
						[service]="puntoVentaService"
						[autohide]="true"
					>
					</descriptivo-material-selector>
				</div>
				<div class="col-12">
					<descriptivo-material-selector
						[label]="'CLIENTE' | translate"
						[limpiable]="true"
						[(ngModel)]="filtro.cliente"
						name="cliente_filtro"
						isLazy="true"
						[service]="clienteService"
					>
					</descriptivo-material-selector>
				</div>
				<div class="col-12">
					<descriptivo-material-selector
						[label]="'CANALVENTA' | translate"
						[limpiable]="true"
						[(ngModel)]="filtro.canalVenta"
						name="canal_filtro"
						isLazy="true"
						[service]="canalService"
					>
					</descriptivo-material-selector>
				</div>
				<div class="col-6 p-fluid field">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="filtro.fechaRegistroDesde"
							dateFormat="dd/mm/yy"
							appendTo="body"
							name="fechaRegistroDesde"
							[showIcon]="true"
						></p-calendar>
						<label>{{ "FECHA_DESDEREGISTRO" | translate }}</label>
					</span>
				</div>
				<div class="col-6 p-fluid field">
					<span class="p-float-label">
						<label>{{ "HASTA" | translate }}</label>

						<p-calendar [(ngModel)]="filtro.fechaRegistroHasta" name="rH" dateFormat="dd/mm/yy" appendTo="body" [showIcon]="true"></p-calendar>
					</span>
				</div>
				<div class="col-6 p-fluid field">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="filtro.fechaVencimientoDesde"
							dateFormat="dd/mm/yy"
							appendTo="body"
							name="fechaVencimientoDesde"
							[showIcon]="true"
						></p-calendar>
						<label>{{ "FECHAVENCIMIENTODESDE" | translate }}</label>
					</span>
				</div>
				<div class="col-6 p-fluid field">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="filtro.fechaVencimientoHasta"
							dateFormat="dd/mm/yy"
							appendTo="body"
							name="fechaVencimientoHasta"
							[showIcon]="true"
						></p-calendar>
						<label>{{ "HASTA" | translate }}</label>
					</span>
				</div>
				<div class="col-12 botonera">
					<div class="grid">
						<div class="col text-center" *ngFor="let estado of estadosService.habilitados | async">
							<button
								pButton
								[label]="estado?.descripcion"
								[ngClass]="{ 'no-seleccionado': !filtro?.tieneEstado(estado) }"
								[ngStyle]="{ 'border-bottom-color': estado?.color, 'background-color': estado?.color }"
								(click)="filtro.toggleEstado(estado)"
							></button>
						</div>
					</div>
				</div>
				<div class="col-12 subtitulo">Forma de visualización</div>
				<div class="col-12 lg:col-6">
					<parametrico-selector
						[service]="monedaService"
						#moneda="ngModel"
						[(ngModel)]="monedaVisualizacion"
						[limpiable]="false"
						name="moneda"
						[titulo]="'Moneda de visualización' | translate"
						[required]="true"
						(onSelect)="cambioMoneda($event)"
					>
					</parametrico-selector>
				</div>
				<div class="col-12 lg:col-6" *ngIf="cotizaciones">
					<div class="grid">
						<div class="col-12" *ngFor="let moneda of monedaService.habilitados | async">
							<span-dinero-editable
								[(ngModel)]="cotizaciones[moneda.codigo]"
								[label]="moneda.descripcion"
								name="'cotizacion' + moneda.id"
								[minValue]="0"
								[editable]="true"
								[decimals]="4"
								(onChange)="updateValores()"
							>
							</span-dinero-editable>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</model-list>
</div>
<ng-template #reserva let-data let-col="col">
	<span class="clickeable chip reserva" *ngIf="data?.idReserva" (click)="verReserva(data?.idReserva)">{{ data?.idReserva }}</span>
</ng-template>
