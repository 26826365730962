import * as moment from "moment"
import { Filtro } from "src/app/common/model/Filtro"
import { Descriptivo } from "../common/model/Descriptivo"

export class FiltroServicios extends Filtro {
	constructor(
		name: string,
		page: number = 0,
		size: number = 20,
		sortField: string = "fechaActividad",
		order: number = 2,
		guardable: boolean = true,
		defaults?: any
	) {
		super(
			name,
			{
				actividad: "Actividad",
				proveedor: "Proveedor",
				fechaActividadDesde: "Actividad Desde",
				fechaActividadHasta: "Actividad Hasta",
				vehiculo: "Vehiculo",
				soloConPaxs: "Solo con Paxs"
			},
			page,
			size,
			sortField,
			order,
			guardable
		)
		defaults && Object.assign(this, defaults)
	}

	private _actividad: Descriptivo
	private _proveedor: Descriptivo
	private _fechaActividadDesde: Date
	private _fechaActividadHasta: Date
	private _vehiculo: Descriptivo

	private _soloConPaxs: boolean = true
	public get soloConPaxs(): boolean {
		return this._soloConPaxs
	}
	public set soloConPaxs(v: boolean) {
		this._soloConPaxs = v
		this.update()
	}

	public get actividad(): Descriptivo {
		return this._actividad
	}
	public set actividad(v: Descriptivo) {
		this._actividad = v
		this.update()
	}
	public get proveedor(): Descriptivo {
		return this._proveedor
	}
	public set proveedor(v: Descriptivo) {
		this._proveedor = v
		this.update()
	}
	public get fechaActividadDesde(): Date {
		return this._fechaActividadDesde
	}
	public set fechaActividadDesde(v: Date) {
		this._fechaActividadDesde = moment(v).startOf("day").toDate()
		this.update()
	}
	public get fechaActividadHasta(): Date {
		return this._fechaActividadHasta
	}
	public set fechaActividadHasta(v: Date) {
		this._fechaActividadHasta = moment(v).endOf("day").toDate()
		this.update()
	}
	public get vehiculo(): Descriptivo {
		return this._vehiculo
	}
	public set vehiculo(v: Descriptivo) {
		this._vehiculo = v
		this.update()
	}

	public clean() {
		let cleanVal = {
			searchStr: null,
			actividad: null,
			proveedor: null,
			fechaActividadDesde: moment().startOf("day").toDate(),
			fechaActividadHasta: moment().endOf("day").toDate(),
			vehiculo: null,
			soloConPaxs: true
		}

		this.setMultiple(cleanVal, false)
		super.clean(true)
	}

	public patchValue(v: any) {
		super.patchValue(v, false)
		this._actividad = v.actividad || null
		this._proveedor = v.proveedor || null
		this._fechaActividadDesde = v.fechaActividadDesde ? new Date(v.fechaActividadDesde) : null
		this._fechaActividadHasta = v.fechaActividadHasta ? new Date(v.fechaActividadHasta) : null
		this._vehiculo = v.vehiculo || null
		this._soloConPaxs = v.soloConPaxs || true
		this.update()
	}

	public valid(): boolean {
		return this.fechaActividadDesde != undefined && this.fechaActividadHasta != undefined
	}
}
