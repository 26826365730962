<p-overlayPanel #op [dismissable]="false" appendTo="body" [styleClass]="'options'">
	<span class="close" (click)="$event.stopPropagation(); hideParams()" style="position: absolute; top: 3px; right: 3px; cursor: pointer; padding: 0.25em"
		><i class="pi pi-times"></i
	></span>
	<div class="grid" style="max-width: 30em">
		<div class="col-12 lg:col">
			<descriptivo-material-selector
				[(ngModel)]="data.tipoFecha"
				[options]="opciones"
				[limpiable]="false"
				[label]="'TIPO_FECHA' | translate"
				[permiteNuevo]="false"
				[isLazy]="true"
				(onSelect)="onSelectTipoFecha($event)"
			>
			</descriptivo-material-selector>
		</div>

		<ng-container *ngIf="data?.tipoFecha?.codigo == 'FE'">
			<div class="col-12">
				<span class="p-float-label p-fluid">
					<p-calendar [(ngModel)]="data.fechaDesde" name="fechaDesde" dateFormat="dd/mm/yy" appendTo="body" [showIcon]="true"></p-calendar>
					<label for="">{{ "FECHADESDE" | translate }}</label>
				</span>
			</div>
			<div class="col-12">
				<span class="p-float-label p-fluid">
					<p-calendar [(ngModel)]="data.fechaHasta" name="fechaHasta" dateFormat="dd/mm/yy" appendTo="body" [showIcon]="true"></p-calendar>
					<label for="">{{ "HASTA" | translate }}</label>
				</span>
			</div>
		</ng-container>
		<div class="col-12">
			<span class="p-float-label p-fluid">
				<p-multiSelect
					[options]="productos | async"
					[showToggleAll]="true"
					appendTo="body"
					[showClear]="true"
					[styleClass]="'prime-material'"
					[dropdownIcon]="'fa fa-chevron-down'"
					[(ngModel)]="data.prods"
					[dataKey]="'codigo'"
					[virtualScroll]="true"
					[virtualScrollItemSize]="34"
					[selectedItemsLabel]="'ITEMS_SELECCIONADOS' | translate"
					[filter]="true"
					[emptyFilterMessage]="'SIN_RESULTADOS' | translate"
				>
				</p-multiSelect>
				<label for="">{{ "PRODUCTOS" | translate }}</label>
			</span>
		</div>

		<div class="col-12 text-center">
			<button pButton (click)="buscar()" label="{{ 'ACTUALIZAR' | translate }}"></button>
		</div>
	</div>
</p-overlayPanel>

<reporte-prevision-servicios [isHome]="true" [filtro]="filtro"></reporte-prevision-servicios>
