import { DisponibilidadDiaria } from "./DisponibilidadDiaria"
import { ProductoIntegracionIdioma } from "./ProductoIntegracionIdioma"
import { Provider } from "./Provider"

export class ProductoPublicadoIntegracion {
	constructor(public id: number, public productoPublicadoId: number, public provider: Provider,
		public habilitado: boolean, public habilitadoExterno: boolean, public idiomas: ProductoIntegracionIdioma[],
		public disponibilidades: { [key: string]: DisponibilidadDiaria } = {}, public heredaDisponibilidad: { [key: string]: boolean } = {}) {
	}

	public static fromData(data: any): ProductoPublicadoIntegracion {
		if (!data) return null
		const o: ProductoPublicadoIntegracion = new ProductoPublicadoIntegracion(
			data.id,
			data.productoPublicadoId,
			Provider.fromData(data.provider),
			data.habilitado,
			data.habilitadoExterno,
			data.idiomas ? data.idiomas.map((c) => ProductoIntegracionIdioma.fromData(c)) : []
		)

		if (data.disponibilidades) {
			Object.keys(data.disponibilidades).forEach(key => {
				o.disponibilidades[key] = data.disponibilidades[key] != null ? DisponibilidadDiaria.fromData(data.disponibilidades[key]) : null
				o.heredaDisponibilidad[key] = data.disponibilidades[key] == null
			})
		}

		return o
	}

}
