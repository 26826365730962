
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { EstadoDocumento } from 'src/app/model/EstadoDocumento';
import { EstadoDocumentoService } from 'src/app/services/estado-documento.service';

@Component({
  selector: 'listado-estado-documento',
  templateUrl: 'listado-estado-documento.component.html',
  styleUrls: ['listado-estado-documento.component.less']
})

export class ListadoEstadoDocumentoComponent extends SessionComponent implements OnInit, OnDestroy {

  public filtro: Filtro = new Filtro("estado-documento_filtros_list", {}, 0, 10);
  public listado: EstadoDocumento[] = [];
  public columns = [];
  constructor(messagesService: MessagesService,
    public service: EstadoDocumentoService) {
    super(messagesService)
    console.log(JSON.stringify(this.columns));
  }
  ngOnInit() {
    this.columns = [
      {
        "field": "codigo",
        "header": "Codigo",
        "class": "codigo-tabla",
        "role": "text-tabla",
        "sortable": true,
        "order": 1
      },
      {
        "field": "id",
        "header": "#",
        "class": "id-column",
        "role": "text-tabla",
        "sortable": true,
        "order": 1
      },
      {
        "field": "descripcion",
        "header": "Descripcion",
        "class": "text-tabla",
        "role": "text-tabla",
        "sortable": true,
        "order": 3
      },
      {
        "field": "icono",
        "header": "Icono",
        "class": "icon-tabla",
        "role": "icon-tabla",
        "sortable": false,
        "order": 40
      },
      {
        "field": "color",
        "header": "Color",
        "class": "color-tabla",
        "role": "color-tabla",
        "sortable": false,
        "order": 40
      },
      {
        "field": "habilitado",
        "header": "Habilitado",
        "class": "is-boolean",
        "role": "boolean-tabla",
        "sortable": false,
        "order": 99
      }
    ];
  }
  ngOnDestroy() { }



}