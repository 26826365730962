<div class="contactos grid align-content-center grid-nogutter p-inputwrapper-filled" *ngIf="data?.whatsapp || data?.telefono || data?.email">
	<div class="{{ containerClass + ' overflow' }}" *ngIf="data?.whatsapp && data?.telefono != data?.whatsapp">
		<span class="data" tooltipPosition="bottom" pTooltip="{{ data.whatsapp }}" (click)="whatsapp($event)"
			><i class="fa fa-whatsapp info"></i>{{ data?.whatsapp }}</span
		>
	</div>
	<div class="{{ containerClass + ' overflow' }}" *ngIf="data?.telefono && data?.telefono != data?.whatsapp">
		<span class="data" tooltipPosition="bottom" pTooltip="{{ data.telefono }}" (click)="call($event)"
			><i class="fa fa-phone info"></i>{{ data?.telefono }}</span
		>
	</div>
	<div class="{{ containerClass + ' overflow' }}" *ngIf="data?.telefono == data?.whatsapp && data?.telefono">
		<span class="data" tooltipPosition="bottom" pTooltip="{{ data.telefono }}" (click)="call($event)"><i class="fa fa-phone info"></i></span>
		<span class="data" tooltipPosition="bottom" pTooltip="{{ data.whatsapp }}" (click)="whatsapp($event)"
			><i class="fa fa-whatsapp info"></i>{{ data?.whatsapp }}</span
		>
	</div>
	<div class="{{ containerClass + ' overflow' }}">
		<span *ngIf="data?.email" class="data" tooltipPosition="bottom" pTooltip="{{ data.email }}" (click)="sendEmail($event)"
			><i class="fa fa-at info"></i>{{ data?.email }}</span
		>
	</div>
</div>
