import { Component, Input, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import moment from "moment-timezone"
import { FilterService } from "src/app/common/services/filter-service"
import { ItemReserva } from "src/app/model/ItemReserva"
import { ResumenReserva } from "src/app/model/ResumenReserva"
import { ItemReservaService } from "src/app/services/item-reserva.service"
import { FiltroGrupo } from "../../gestor-reserva/FiltroGrupo"

@Component({
	selector: "itinerario-reserva",
	templateUrl: "itinerario-reserva.component.html",
	styleUrls: ["itinerario-reserva.component.less"]
})
export class ItinerarioReservaComponent implements OnInit {
	@Input()
	public filtro: FiltroGrupo

	public filtroReservas: FiltroGrupo
	public actividades: ItemReserva[] = []
	public reservas: ResumenReserva[] = []
	public fechas: Date[] = []
	public datos: any[] = []
	public verDetalles: boolean = true
	constructor(private filtrosService: FilterService, private itemReservaService: ItemReservaService, private router: Router) {}
	generarFechasEntre(fechaInicio: Date, fechaFin: Date): Date[] {
		let fechas: Date[] = []
		let fechaActual = new Date(fechaInicio)

		while (fechaActual <= fechaFin) {
			fechas.push(new Date(fechaActual))
			fechaActual.setDate(fechaActual.getDate() + 1)
		}

		return fechas
	}
	public esHoy(fecha: Date) {
		return moment().isSame(fecha, "day")
	}
	ngOnInit() {
		this.filtroReservas = this.filtro || this.filtrosService.getFilter("itinerario-reservas", new FiltroGrupo("itinerario-reservas"))
		this.filtroReservas.setMultiple({
			...this.filtro.json,
			fecha: this.filtro ? this.filtro.fecha : moment().startOf("day").toDate(),
			fechaHasta: this.filtro ? moment(this.filtro.fecha).add(6, "days").toDate() : moment().endOf("day").add(6, "days").toDate()
		})
		this.filtroReservas.dataChange.subscribe((filtro) => {
			this.fechas = this.generarFechasEntre(filtro.fecha, filtro.fechaHasta)
			this.filtrosService.save(this.filtroReservas)

			this.itemReservaService.getAll(filtro).then((actividades) => {
				this.actividades = [...actividades]
				this.reservas = actividades.reduce((acc, a) => {
					if (!acc.some((res) => res.id === a.idReserva)) {
						acc.push(
							ResumenReserva.fromData({
								id: a.idReserva,
								nombrePax: a.nombrePax,
								cantidadPax: a.cantidadPaxReserva,
								observaciones: a.observacionesReserva
							})
						)
					}
					return acc
				}, [])
				this.procesarDatos(this.reservas, this.actividades)
			})
		})

		this.filtroReservas.forceUpdate()
	}
	public procesarDatos(reservas: ResumenReserva[] = [], actividades: ItemReserva[] = []) {
		this.datos = []
		reservas.forEach((reserva) => {
			let dato = {
				reserva,
				actividades: actividades.filter((a) => a.idReserva === reserva.id && !a.esPrograma && !a.esCancelada)
			}
			this.fechas.forEach((fecha) => {
				dato[fecha.toString()] = dato.actividades
					.map((a) => {
						if (a.esHospedaje() && moment(a.checkOut).isSame(fecha, "day")) {
							a["tipo"] = "CO"
							return a
						} else if (a.esHospedaje() && moment(a.checkIn).isSame(fecha, "day")) {
							a["tipo"] = "CI"
							return a
						} else if (moment(a.fechaActividad).isSame(fecha, "day")) {
							a["tipo"] = "AC"
							a["puestos"] = a.getPuestos()
							return a
						}
					})
					.filter((a) => a)
			})
			this.datos.push(dato)
		})
	}
	public navegarFechas(dias: number) {
		this.filtroReservas.setMultiple({
			fecha: moment(this.filtroReservas.fecha).add(dias, "days").toDate(),
			fechaHasta: moment(this.filtroReservas.fechaHasta).add(dias, "days").toDate()
		})
	}
	public goToReserva(id: number) {
		if (!id) return
		this.router.navigate(["reserva/vista"], { queryParams: { id: id } })
	}
}
