<div class="grid" style="flex-wrap: nowrap">
	<div *ngIf="mostrarMoneda" class="col">
		<descriptivo-material-selector
			[service]="monedaService"
			#moneda="ngModel"
			[(ngModel)]="monedaElegida"
			[limpiable]="false"
			[allowDefault]="false"
			name="monedaCotizacion"
			[titulo]="'MONEDA' | translate"
			[required]="true"
			[readonly]="readonly || !monedaEditable"
			(onSelect)="monedaChange($event)"
		>
		</descriptivo-material-selector>
	</div>
	<div class="col" *ngIf="mostrarCotizacion">
		<span-dinero-editable
			[(ngModel)]="cotizacion"
			[editable]="!readonly"
			label="{{ !monedaElegida ? ('COTIZACION_A_LOCAL' | translate) : monedaRef?.codigo + ' ► ' + monedaElegida?.codigo }}"
			name="name"
			[moneda]="monedaElegida"
			[decimals]="8"
			(onChange)="cotizacionChange($event)"
			(onInput)="manualInput = $event != undefined"
		>
		</span-dinero-editable>
	</div>
</div>
