import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { ConfiguracionEntorno } from "src/app/model/ConfiguracionEntorno";
import { ConfiguracionEntornoService } from 'src/app/services/configuracion-entorno.service';
import { SessionComponent } from './../../../common/components/session-component.component';

import { ProfilePic } from 'src/app/common/model/ProfilePic';
import { NacionalidadService } from 'src/app/services/nacionalidad.service';
import { IdiomaService } from 'src/app/common/services/idioma.service';
import { MonedaService } from 'src/app/services/moneda.service';



@Component({
	selector: 'gestor-configuracion-entorno',
	templateUrl: 'gestor-configuracion-entorno.component.html',
	styleUrls: ['gestor-configuracion-entorno.component.less',]
})

export class GestorConfiguracionEntornoComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription;
	@Input()
	public item: ConfiguracionEntorno;
	@Input()
	public goBack: boolean = true;
	@Input()
	public isModal: boolean = false;
	
	@Input()
	public readonly:boolean = false;

	@Output()
	public onGuardado: EventEmitter<ConfiguracionEntorno> = new EventEmitter<ConfiguracionEntorno>();

	@Output()
	public onCancelado = new EventEmitter();

	

		public file: File;
	public onPicSelected(file: File) {
		const reader = new FileReader();
		let $this = this;
		if (file.size > 1000000) {
			return this.error(this.translateService.get('EL_ARCHIVO_NO_PUEDE_16'));
		}
		this.file = file;
		reader.onload = (e: any) => {
			$this.item.profilePic = new ProfilePic(null, e.target.result);
		};
		reader.readAsDataURL(file);
	}

	constructor(messagesService: MessagesService,
		public service: ConfiguracionEntornoService,
		private route: ActivatedRoute,
		private router: Router,
		private confService: ConfirmationService,
		public nacionalidadService:NacionalidadService,
		public idiomaService: IdiomaService,
		public monedaService: MonedaService,
		
	) {
		super(messagesService);
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item);
	}
	public handleCancelar(item) {
		this.onCancelado.emit();
	}

	public async isValid() {
		return true;
	}
	
	public onEdit(event) {
		if (this.item?.id)
			this.router.navigate(["'configuracion-entorno/edit"], { queryParams: { id: this.item.id } });
	}
	
	ngOnInit() {
		this.subs.push(this.route.data.subscribe(u => {
			if (u?.vista) {
				this.readonly = true;
			}
		}));
		if (this.route.snapshot.url.some(u => u.path == 'configuracion-entorno')) {
			this.routeSub = this.route.queryParams.subscribe(params => {
				let id: number = <number>params['id'];
				if (!this.service) return;
				if (id) {
					this.service.getById(id,null,this.translateService.idioma ? this.translateService.idioma : 'ES').then(r => {
						this.item = r;
					})
				} else {
					if (!this.item && this.service) {
						this.item = this.service.newEnt();
					}
					this.item["codigoIdioma"] = this.translateService.idioma ? this.translateService.idioma : 'ES';
				}

			});
		}

		
	}
	
	public get esEditable() {
		return this.esAdministrador;
	}


	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe();
	}
}