<div class="layout-breadcrumb">
    <ul>
        <li>
            <a routerLink="">
                <i class="material-icons">home</i>
            </a>
        </li>
        <li>/</li>
        <ng-template ngFor let-item let-last="last" [ngForOf]="items | async">
            <li>
                <a [routerLink]="item.routerLink" *ngIf="item.routerLink">{{item.label}}</a>
                <ng-container *ngIf="!item.routerLink">{{item.label}}</ng-container>
            </li>
            <li *ngIf="!last">/</li>
        </ng-template>
    </ul>


    <p-overlayPanel #op [dismissable]="true">
        <ul class="options">
            <li *ngFor="let lc of localesDisponibles" (click)="aplicarSeleccion(lc)">
                {{lc.descripcion}}
            </li>
        </ul>
    </p-overlayPanel>
