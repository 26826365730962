<div class="listado-lista-precios content">
	<model-list
		[service]="service"
		[modelName]="'lista-precios'"
		[columns]="columns"
		[acciones]="acciones"
		[getData]="getCabeceras"
		[title]="'LISTAS_PRECIOS' | translate"
		[(listado)]="listado"
		[isLazy]="false"
		[filtro]="filtro"
		[filterContent]="customFilter"
		[permiteCargaMasiva]="true"
		[helpPath]="lenguaje + '/lista-de-precios'"
	>
		<ng-template #customFilter>
			<div class="grid">
				<div class="col-12">
					<div class="p-inputgroup">
						<span class="p-float-label">
							<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
							<label>{{ "BUSCAR" | translate }}...</label>
						</span>
						<button type="button" pButton icon="pi pi-search"></button>
					</div>
				</div>
				<div class="col-12">
					<div class="grid">
						<div class="col button-wrapper">
							<p-toggleButton
								onLabel="{{ 'Ver archivados' | translate }}"
								tooltipPosition="bottom"
								pTooltip="{{ 'Ver productos que están deshabilitados' | translate }}"
								offLabel="{{ 'Ocultar archivados' | translate }}"
								iconPos="right"
								onIcon="{{ 'fa fa-check-square-o' }} "
								offIcon="{{ 'fa fa-square-o' }} "
								[(ngModel)]="filtro.verArchivados"
							></p-toggleButton>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</model-list>
</div>

<p-dialog
	[(visible)]="copiar"
	appendTo="body"
	[modal]="true"
	#copiarDialog
	[style]="{ width: '50%' }"
	[closeOnEscape]="true"
	[dismissableMask]="true"
	[closable]="true"
	[showHeader]="true"
	header="Copiar Lista de Precios"
>
	<ng-container>
		<div class="grid margin">
			<div class="col-12 lg:col-6">
				<span class="p-float-label">
					<input id="float-input" type="text" pInputText size="30" [(ngModel)]="descripcion" />
					<label for="float-input">{{ "DESCRIPCION" | translate }}</label>
				</span>
			</div>
		</div>
	</ng-container>
	<p-footer>
		<p-button icon="pi pi-check" (click)="copiarLista()" label="Guardar" styleClass="p-button-text"></p-button>
	</p-footer>
</p-dialog>

<p-dialog header="Integraciones" [(visible)]="isVerIntegraciones" appendTo="body" [modal]="true" [style]="{ width: '60vw' }" *ngIf="isVerIntegraciones">
	<p-table [value]="providers">
		<ng-template pTemplate="header">
			<tr>
				<th style="width: 30%">{{ "Provider" | translate }}</th>
				<th style="width: 20%">{{ "VERSION_PUBLICADA" | translate }}</th>
				<th style="width: 30%">{{ "ESTADO" | translate }}</th>
				<th style="width: 20%"></th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-prov let-i="rowIndex">
			<tr>
				<td>
					{{ prov?.descripcion }}
				</td>
				<td>
					{{ getIntegracion(prov?.codigo)?.versionIntegrada }}
				</td>
				<td>
					{{ getIntegracion(prov?.codigo)?.estado() || "SIN_INTEGRAR" | translate }}
				</td>
				<td>
					<div class="acciones-tabla">
						<button pButton (click)="integrar(prov?.codigo)" style="position: absolute; right: 60px" *ngIf="puedeIntegrar(prov?.codigo)">
							Integrar
						</button>
					</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-dialog>
