import { EncuestaItem } from './../model/EncuestaItem';
import { Injectable } from '@angular/core';
import { EncuestaActividades } from 'src/app/model/EncuestaActividades';
import { DescriptivosService } from 'src/app/common/services/descriptivos.service';
import { LoadingService } from '../common/services/loading-data-service.service';
import { lastValueFrom } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class EncuestaActividadesService extends DescriptivosService<EncuestaActividades>{


    public baseName(): string {
        return "encuesta-actividades";
    }
    public parseToEnt(data: any): EncuestaActividades {
        return EncuestaActividades.fromData(data);
    }
    public newEnt(): EncuestaActividades {
        return new EncuestaActividades();
    }
    getByGrupoReserva(idGrupoReserva: number, token: string, idioma: string, customLoading?: LoadingService): Promise<EncuestaItem> {
        if (customLoading) customLoading.addLoadingCount(); else this.loadingService.addLoadingCount();
        return lastValueFrom(this.http.get(this.getBaseURL() + `public/byGrupoReserva/${idGrupoReserva}/${token}` + (idioma ? "?idioma=" + idioma : ""))).then(r => {
          if (customLoading) customLoading.susLoadingCount(); else this.loadingService.susLoadingCount();
          return r ? EncuestaItem.fromData(r) : null;
        }).catch((e) => this.handleError(e, customLoading));
    }

    getByFamilia(reservaId: any, token: any, grupoId: any, tipo: any, familiaId: any, productoId: any, idioma: string,customLoading?: LoadingService): Promise<EncuestaItem> {
        if (customLoading) customLoading.addLoadingCount(); else this.loadingService.addLoadingCount();
        return lastValueFrom(this.http.get(this.getBaseURL() + `public/byFamilia/${reservaId}/${grupoId}/${tipo}/${familiaId? familiaId : productoId}/${token}` + (idioma ? "?idioma=" + idioma : ""))).then(r => {
          if (customLoading) customLoading.susLoadingCount(); else this.loadingService.susLoadingCount();
          return r ? EncuestaItem.fromData(r) : null;
        }).catch((e) => this.handleError(e, customLoading));
    
    }

}