import { ItemReservaConsentimiento } from './../model/ItemReservaConsentimiento';
import { ItemResumenReserva } from './../model/ItemReservaResumen';
import { ItemReserva } from './../model/ItemReserva';
import { Pipe, PipeTransform } from '@angular/core';
import { Vehiculo } from 'src/app/model/Vehiculo';
import { VehiculoRequerido } from './../model/VehiculoRequerido';


@Pipe({
  name: 'byTipoProducto'
})
export class ByTipoProducto implements PipeTransform {

  transform(value: ItemReservaConsentimiento[], args?: string): any {
    return !args ? value : (value ? value.filter(v => args === 'SER'? v.tipoProducto?.codigo!='PRD':v.tipoProducto?.codigo==args) : []);
  }

}
