<div class="listado-producto content">
	<model-list
		[service]="service"
		[modelName]="'producto'"
		[columns]="columns"
		[title]="'PRODUCTO_TITLE' | translate"
		[multilenguaje]="true"
		[(listado)]="listado"
		[isLazy]="false"
		[filtro]="filtro"
		[filterContent]="customFilter"
		[acciones]="acciones"
		[getData]="getData"
		[permiteCargaMasiva]="true"
		[helpPath]="lenguaje + '/producto'"
	>
		<ng-template #customFilter>
			<div class="grid">
				<div class="col-12">
					<div class="p-inputgroup">
						<span class="p-float-label">
							<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
							<label>{{ "BUSCAR" | translate }}...</label>
						</span>
						<button type="button" pButton icon="pi pi-search"></button>
					</div>
				</div>
				<div class="col-12">
					<selector-multiple-chips
						[(ngModel)]="filtro.categorias"
						name="categorias"
						[servicio]="categoriaService"
						[permiteNuevo]="false"
						[titulo]="'CATEGORÍA' | translate"
					>
					</selector-multiple-chips>
				</div>
				<div class="col-12">
					<selector-multiple-chips
						[(ngModel)]="filtro.tiposProducto"
						name="tiposProducto"
						[servicio]="tipoProductoService"
						[permiteNuevo]="false"
						[titulo]="'Tipos de producto' | translate"
					>
					</selector-multiple-chips>
				</div>
				<div class="col-12">
					<selector-multiple-chips
						[(ngModel)]="filtro.paises"
						name="paises"
						[servicio]="paisService"
						[permiteNuevo]="false"
						[titulo]="'PAIS' | translate"
					>
					</selector-multiple-chips>
				</div>
				<div class="col-12">
					<selector-multiple-chips
						[(ngModel)]="filtro.regiones"
						name="regiones"
						[servicio]="regionService"
						[permiteNuevo]="false"
						[titulo]="'REGION_PLR' | translate"
					>
					</selector-multiple-chips>
				</div>
				<div class="col-12">
					<div class="grid">
						<div class="col button-wrapper">
							<p-toggleButton
								onLabel="{{ 'Ver archivados' | translate }}"
								tooltipPosition="bottom"
								pTooltip="{{ 'Ver productos que están deshabilitados' | translate }}"
								offLabel="{{ 'Ocultar archivados' | translate }}"
								iconPos="right"
								onIcon="{{ 'fa fa-check-square-o' }} "
								offIcon="{{ 'fa fa-square-o' }} "
								[(ngModel)]="filtro.verArchivados"
							></p-toggleButton>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</model-list>
</div>
