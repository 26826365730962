import { Parametrico } from "src/app/common/model/Parametrico"
import { TipoIVA } from "./TipoIVA"

export class SubcategoriaImponible extends Parametrico {
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado: boolean = true,
		esSistema: boolean = false,
		peso: number = 99,
		esDefault: boolean = false,
		public tipoIVA?: TipoIVA
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
	}
	public tipoParametro(): string {
		return "subcategoriaImponible"
	}
	public static fromData(data: any): SubcategoriaImponible {
		if (!data) return null
		const o: SubcategoriaImponible = new SubcategoriaImponible(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			data.esSistema,
			data.peso,
			data.esDefault,
			TipoIVA.fromData(data.tipoIVA)
		)

		return o
	}
}
