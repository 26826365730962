<link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css" rel="stylesheet" />
<div class="grid">
	<div class="col-12">
		<full-calendar *ngIf="showCalendar" #calendar [events]="events" [options]="calendarOptions"></full-calendar>
	</div>
</div>

<ng-container [ngSwitch]="tipo?.codigo">
	<ng-container *ngSwitchCase="'AUS'">
		<ng-container *ngTemplateOutlet="ausencia; context: { $implicit: item }"> </ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'DIS'">
		<ng-container *ngTemplateOutlet="disponibilidad; context: { $implicit: item }"> </ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'NOT'">
		<ng-container *ngTemplateOutlet="nota; context: { $implicit: item }"> </ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'BVE'">
		<ng-container *ngTemplateOutlet="bloqueoVehiculo; context: { $implicit: item }"> </ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'FAC'">
		<ng-container *ngTemplateOutlet="factura; context: { $implicit: item }"> </ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'DFC'">
		<ng-container *ngTemplateOutlet="duplicarFactura; context: { $implicit: item }"> </ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'DCC'">
		<ng-container *ngTemplateOutlet="duplicarComprobante; context: { $implicit: item }"> </ng-container>
	</ng-container>
</ng-container>

<p-dialog [(visible)]="borrarDialog" appendTo="body" [modal]="true" [styleClass]="'gestor-dialog'" header="Borrar" closable="true">
	<ng-container>
		{{ "MENSAJE_SERIE" | translate }}
	</ng-container>

	<p-footer>
		<button (click)="borrarSerie(tipo.codigo)" pButton label="{{ 'BORRAR_SERIE' | translate }}"></button>
		<button (click)="borrar()" pButton label="{{ tipo?.codigo == 'AUS' ? 'BORRAR_AUSENCIA' : ('BORRAR_ACCION' | translate) }}"></button>
		<button (click)="cerrarBorrar()" pButton label="{{ 'VOLVER' | translate }}"></button>
	</p-footer>
</p-dialog>

<ng-template #ausencia let-item>
	<p-dialog [(visible)]="verDetalle" appendTo="body" [modal]="true" [styleClass]="'gestor-dialog'" header="Ausencia" closable="true">
		<ng-container *ngIf="item?.ausencia">
			<div class="grid">
				<div class="col-12 lg:col-6">
					<descriptivo-material-selector
						*ngIf="proveedores?.length"
						[options]="proveedores | noHabitual : false"
						[label]="'PROVEEDOR' | translate"
						name="proveedor"
						[limpiable]="true"
						[(ngModel)]="item.ausencia.proveedor"
						[readonly]="editando || item?.serie?.esCancelada"
					>
					</descriptivo-material-selector>
				</div>

				<div class="col-12 lg:col-6">
					<parametrico-selector
						[service]="tipoAusenciaService"
						[(ngModel)]="item.ausencia.tipo"
						[limpiable]="false"
						name="tipo"
						[titulo]="'TIPO_AUSENCIA' | translate"
						[required]="true"
						[disabled]="item?.serie?.esCancelada"
						[permiteNuevo]="true"
					>
					</parametrico-selector>
				</div>

				<div class="col-12 lg:col-6">
					<span class="p-float-label" [ngClass]="{ readonly: readonly }">
						<p-calendar
							dateFormat="dd/mm/yy"
							[showIcon]="true"
							[(ngModel)]="item.ausencia.fechaDesde"
							#fechaDesde="ngModel"
							name="fechaDesde"
							[readonly]="editando || item?.serie?.esCancelada"
							required
							appendTo="body"
						></p-calendar>
						<label>{{ "FECHADESDE" | translate }}</label>
						<error-tag [model]="fechaDesde"></error-tag>
					</span>
				</div>
				<div class="col-12 lg:col-6 d-flex align-items-end">
					<span class="p-float-label" [ngClass]="{ readonly: readonly }">
						<p-calendar
							dateFormat="dd/mm/yy"
							[showIcon]="true"
							[(ngModel)]="item.ausencia.fechaHasta"
							#fechaHasta="ngModel"
							name="fechaHasta"
							[readonly]="editando || item?.serie?.esCancelada"
							appendTo="body"
						></p-calendar>
						<label>{{ "HASTA" | translate }}</label>
						<error-tag [model]="fechaHasta"></error-tag>
					</span>
				</div>

				<div class="col-12">
					<p-checkbox [(ngModel)]="esRecurrente" label="Recurrente" binary="true" [disabled]="editando || item?.serie?.esCancelada"></p-checkbox>
				</div>

				<div class="col-12" *ngIf="esRecurrente">
					<div class="grid" *ngIf="item?.serie">
						<div class="col-6 lg:col-3 d-flex align-items-end">
							<!-- <p-inputNumber [(ngModel)]="item.serie.n" mode="decimal" [showButtons]="false" [min]="1"
                                [disabled]="editando"> </p-inputNumber> -->
							<span class="p-float-label field">
								<input pInputText type="number" required [(ngModel)]="item.serie.n" name="n" [disabled]="editando" /><label for="n">{{
									"CADA" | translate
								}}</label>
							</span>
						</div>

						<div class="col-12 lg:col-6">
							<p-radioButton
								class="radio"
								name="group1"
								value="d"
								label="Días"
								[(ngModel)]="item.serie.unidad"
								inputId="preopt1"
								[disabled]="editando || item?.serie?.esCancelada"
							></p-radioButton>
							<p-radioButton
								class="radio"
								name="group1"
								value="w"
								label="Semanas"
								[(ngModel)]="item.serie.unidad"
								inputId="preopt2"
								[disabled]="editando || item?.serie?.esCancelada"
							></p-radioButton>
							<p-radioButton
								class="radio"
								name="group1"
								value="m"
								label="Meses"
								[(ngModel)]="item.serie.unidad"
								inputId="preopt3"
								[disabled]="editando || item?.serie?.esCancelada"
							></p-radioButton>
						</div>
					</div>
				</div>
			</div>
		</ng-container>

		<p-footer>
			<button *ngIf="editando && esRecurrente" (click)="cancelarRecurrencia()" pButton label="{{ 'CANCELAR_RECURRENCIA' | translate }}"></button>
			<button *ngIf="editando" (click)="borrarEventoAusencia()" pButton label="{{ 'BORRAR' | translate }}"></button>
			<button (click)="guardarAusencia()" pButton label="{{ 'GUARDAR' | translate }}"></button>
		</p-footer>
	</p-dialog>
</ng-template>

<ng-template #nota let-item>
	<p-dialog [(visible)]="verDetalle" [modal]="true" #dialNotaFecha header="{{ 'NOTAS_DIA' | translate }}" [styleClass]="'gestor-dialog'">
		<gestor-nota
			*ngIf="item"
			[isModal]="true"
			[goBack]="false"
			[fecha]="item.fechaNota"
			[hideTitle]="false"
			[item]="item"
			(onGuardado)="onGuardado()"
			(onCancelado)="onCancelado()"
		>
		</gestor-nota>
	</p-dialog>
</ng-template>

<ng-template #bloqueoVehiculo let-item>
	<p-dialog [(visible)]="verDetalle" [modal]="true" #dialBloqueo [styleClass]="'gestor-dialog'">
		<p-header> {{ "BLOQUEO_VEHICULO" | translate }} - {{ item?.vehiculo?.descripcion }} </p-header>
		<gestor-bloqueo-vehiculo
			[item]="item"
			[readonly]="!editando"
			[isModal]="true"
			[goBack]="false"
			[persist]="true"
			(onGuardado)="onGuardado()"
			(onCancelado)="onCancelado()"
		>
		</gestor-bloqueo-vehiculo>
	</p-dialog>
</ng-template>

<ng-template #factura let-item>
	<p-dialog [(visible)]="verDetalle" [modal]="true" #dialFactura [styleClass]="'gestor-dialog'">
		<p-header>
			{{ "FACTURA" | translate }}
		</p-header>
		<gestor-factura [item]="item" [isModal]="true" [goBack]="false" (onGuardado)="onGuardado()" (onCancelado)="onCancelado()"> </gestor-factura>
	</p-dialog>
</ng-template>

<ng-template #duplicarFactura let-item>
	<p-dialog [(visible)]="verDetalle" [modal]="true" #duplicarFactura [styleClass]="'accion-dialog'">
		<p-header>
			{{ "REPETIR_FACTURA" | translate }}
		</p-header>
		<div class="grid" *ngIf="item">
			<div class="col-12 lg:col-6">
				<span class="p-float-label" [ngClass]="{ readonly: readonly }" style="padding-top: 2px">
					<p-calendar
						dateFormat="dd/mm/yy"
						[showIcon]="true"
						[(ngModel)]="item.fechaProgramada"
						#fechaProgramada="ngModel"
						name="fechaProgramada"
						required
						dateFormat="dd/mm/yy"
						[disabled]="editando"
						appendTo="body"
					></p-calendar>
					<label>{{ esRecurrente ? "FECHA_DESDE" : ("FECHAPROGRAMADA" | translate) }}</label>
					<error-tag [model]="fechaProgramada"></error-tag>
				</span>
			</div>

			<div class="col-12 lg:col-6">
				<descriptivo-material-selector
					*ngIf="!editando"
					[options]="proveedores"
					[label]="'PROVEEDOR' | translate"
					name="proveedor"
					[limpiable]="false"
					[(ngModel)]="proveedorSeleccionado"
					[disabled]="editando"
				>
				</descriptivo-material-selector>

				<span *ngIf="editando" class="p-float-label">
					<input pInputText [(ngModel)]="proveedorSeleccionado" readonly />
					<label>{{ "PROVEEDOR" | translate }}</label>
				</span>
			</div>
			<div class="col-12 lg:col-6" style="display: grid">
				<span class="p-float-label field" floatLabel="always" class="pointer">
					{{ item?.contexto?.idFactura }} {{ item?.contexto?.descripcion ? "- " + item?.contexto?.descripcion : "" }}
				</span>

				<p-inputText [readonly]="editando" required></p-inputText>
				<p-button label="{{ 'FACTURA' | translate }}" (click)="buscarComprobante()"></p-button>
			</div>
			<div class="col-12">
				<p-checkbox [(ngModel)]="esRecurrente" label="Recurrente" binary="true" [disabled]="editando"></p-checkbox>
			</div>

			<div class="col-12" *ngIf="esRecurrente">
				<div class="grid" *ngIf="item?.serie">
					<div class="col-6 lg:col-3 d-flex align-items-end">
						<span class="p-float-label field">
							<input pInputText type="number" required [(ngModel)]="item.serie.n" name="n" [disabled]="editando" /><label>{{
								"CADA" | translate
							}}</label>
						</span>
					</div>

					<div class="col-12 lg:col-6">
						<p-radioButton
							class="radio"
							name="group1"
							value="d"
							label="Días"
							[(ngModel)]="item.serie.unidad"
							inputId="preopt1"
							[disabled]="editando"
						></p-radioButton>
						<p-radioButton
							class="radio"
							name="group1"
							value="w"
							label="Semanas"
							[(ngModel)]="item.serie.unidad"
							inputId="preopt2"
							[disabled]="editando"
						></p-radioButton>
						<p-radioButton
							class="radio"
							name="group1"
							value="m"
							label="Meses"
							[(ngModel)]="item.serie.unidad"
							inputId="preopt3"
							[disabled]="editando"
						></p-radioButton>
					</div>

					<div class="col-12 lg:col-3 d-flex align-items-end" *ngIf="esRecurrente" style="padding-bottom: 20px">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<p-calendar
								dateFormat="dd/mm/yy"
								[showIcon]="true"
								[(ngModel)]="item.serie.fechaHasta"
								#fechaHasta="ngModel"
								name="fechaHasta"
								required
								[disabled]="editando"
								appendTo="body"
							></p-calendar>
							<label>{{ "HASTA" | translate }}</label>
							<error-tag [model]="fechaHasta"></error-tag>
						</span>
					</div>
				</div>
			</div>
		</div>
		<p-footer>
			<button *ngIf="editando && esRecurrente" (click)="cancelarRecurrencia('DFC')" pButton label="{{ 'CANCELAR_RECURRENCIA' | translate }}"></button>
			<button *ngIf="editando" (click)="borrarEventoAccion()" pButton label="{{ 'BORRAR' | translate }}"></button>
			<button (click)="guardarAccion()" pButton label="{{ 'GUARDAR' | translate }}"></button>
		</p-footer>
	</p-dialog>
</ng-template>

<ng-template #duplicarComprobante let-item>
	<p-dialog [(visible)]="verDetalle" [modal]="true" #duplicarComprobante [styleClass]="'accion-dialog'">
		<p-header>
			{{ "REPETIR_COMPROBANTE" | translate }}
		</p-header>
		<div class="grid" *ngIf="item">
			<div class="col-12 lg:col-6">
				<span class="p-float-label" [ngClass]="{ readonly: readonly }">
					<p-calendar
						dateFormat="dd/mm/yy"
						[showIcon]="true"
						[(ngModel)]="item.fechaProgramada"
						#fechaProgramada="ngModel"
						name="fechaProgramada"
						required
						[disabled]="editando"
						appendTo="body"
					></p-calendar>
					<label>{{ esRecurrente ? "FECHA_DESDE" : ("FECHAPROGRAMADA" | translate) }}</label>
					<error-tag [model]="fechaProgramada"></error-tag>
				</span>
			</div>

			<div class="col-12 lg:col-6">
				<descriptivo-material-selector
					*ngIf="!editando"
					[options]="clientes"
					[label]="'CLIENTE' | translate"
					name="proveedor"
					[limpiable]="false"
					[(ngModel)]="clienteSeleccionado"
					[disabled]="editando"
				>
				</descriptivo-material-selector>

				<span *ngIf="editando" class="p-float-label" [ngClass]="{ readonly: readonly }">
					<input pInputText value="{{ clienteSeleccionado?.descripcion }}" readonly />
					<label>{{ "CLIENTE" | translate }}</label>
				</span>
			</div>
			<div class="col-12 lg:col-6">
				<span class="p-float-label field" floatLabel="always" class="pointer">
					{{ item?.contexto?.idComprobante }} {{ item?.contexto?.descripcion ? "- " + item?.contexto?.descripcion : "" }}
				</span>

				<p-inputText
					[(ngModel)]="item.contexto.idComprobante"
					#idComprobante="ngModel"
					name="idComprobante"
					[readonly]="editando"
					required
				></p-inputText>
				<p-button label="{{ 'COMPROBANTE' | translate }}" (click)="buscarComprobanteCliente()"></p-button>
			</div>
			<div class="col-12">
				<p-checkbox [(ngModel)]="esRecurrente" label="Recurrente" binary="true" [disabled]="editando"></p-checkbox>
			</div>

			<div class="col-12" *ngIf="esRecurrente">
				<div class="grid" *ngIf="item?.serie">
					<div class="col-6 lg:col-3 d-flex align-items-end">
						<span class="p-float-label field">
							<input pInputText type="number" required [(ngModel)]="item.serie.n" name="n" [disabled]="editando" /><label>{{
								"CADA" | translate
							}}</label>
						</span>
					</div>

					<div class="col-12 lg:col-6">
						<p-radioButton
							class="radio"
							name="group1"
							value="d"
							label="Días"
							[(ngModel)]="item.serie.unidad"
							inputId="preopt1"
							[disabled]="editando"
						></p-radioButton>
						<p-radioButton
							class="radio"
							name="group1"
							value="w"
							label="Semanas"
							[(ngModel)]="item.serie.unidad"
							inputId="preopt2"
							[disabled]="editando"
						></p-radioButton>
						<p-radioButton
							class="radio"
							name="group1"
							value="m"
							label="Meses"
							[(ngModel)]="item.serie.unidad"
							inputId="preopt3"
							[disabled]="editando"
						></p-radioButton>
					</div>
					<div class="col-12 lg:col-3 d-flex align-items-end" *ngIf="esRecurrente" style="padding-bottom: 20px">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<p-calendar
								dateFormat="dd/mm/yy"
								[showIcon]="true"
								[(ngModel)]="item.serie.fechaHasta"
								#fechaHasta="ngModel"
								name="fechaHasta"
								required
								[disabled]="editando"
								appendTo="body"
							></p-calendar>
							<label>{{ "HASTA" | translate }}</label>
							<error-tag [model]="fechaHasta"></error-tag>
						</span>
					</div>
				</div>
			</div>
		</div>
		<p-footer>
			<button *ngIf="editando && esRecurrente" (click)="cancelarRecurrencia('DFC')" pButton label="{{ 'CANCELAR_RECURRENCIA' | translate }}"></button>
			<button *ngIf="editando" (click)="borrarEventoAccion()" pButton label="{{ 'BORRAR' | translate }}"></button>
			<button (click)="guardarAccion()" pButton label="{{ 'GUARDAR' | translate }}"></button>
		</p-footer>
	</p-dialog>
</ng-template>

<ng-template #disponibilidad let-item>
	<p-dialog [(visible)]="verDetalle" appendTo="body" [modal]="true" [styleClass]="'gestor-dialog'" header="Disponibilidad" closable="true">
		<ng-container *ngIf="item">
			<div class="grid">
				<div class="col-12 lg:col-6">
					<descriptivo-material-selector
						*ngIf="proveedores?.length"
						[options]="proveedores | noHabitual : true"
						[label]="'PROVEEDOR' | translate"
						name="proveedor"
						[limpiable]="true"
						[(ngModel)]="item.proveedor"
						[readonly]="editando"
					>
					</descriptivo-material-selector>
				</div>

				<div class="col-12 lg:col-8">
					<span class="p-float-label" [ngClass]="{ readonly: readonly }">
						<p-calendar
							dateFormat="dd/mm/yy"
							[showIcon]="true"
							[(ngModel)]="item.fechaDesde"
							#fechaDesde="ngModel"
							name="fechaDesde"
							required
							appendTo="body"
						></p-calendar>
						<label>{{ "FECHADESDE" | translate }}</label>
						<error-tag [model]="fechaDesde"></error-tag>
					</span>
				</div>

				<div class="col-12 lg:col-6">
					<span class="p-float-label" [ngClass]="{ readonly: readonly }">
						<p-calendar
							dateFormat="dd/mm/yy"
							[showIcon]="true"
							[(ngModel)]="item.fechaHasta"
							#fechaHasta="ngModel"
							name="fechaHasta"
							appendTo="body"
						></p-calendar>
						<label>{{ "FECHAHASTA" | translate }}</label>
						<error-tag [model]="fechaHasta"></error-tag>
					</span>
				</div>
			</div>
		</ng-container>

		<p-footer>
			<button *ngIf="editando" (click)="borrarEventoDisponibilidad()" pButton label="{{ 'BORRAR' | translate }}"></button>
			<button (click)="guardarDisponibilidad()" pButton label="{{ 'GUARDAR' | translate }}"></button>
		</p-footer>
	</p-dialog>
</ng-template>

<p-dialog [(visible)]="verCrear" [modal]="true" #dialCrear [styleClass]="'gestor-dialog'">
	<p-header>
		{{ "CREAR_EVENTO" | translate }}
	</p-header>

	<descriptivo-material-selector
		[options]="opcionesCreacion"
		[label]="'TIPO_EVENTO' | translate"
		name="evento"
		*ngIf="verCrear"
		(onSelect)="procesarCreacionEvento($event)"
	>
	</descriptivo-material-selector>
</p-dialog>

<p-dialog [(visible)]="verFiltro" [modal]="true" #dialFiltro [styleClass]="filtroDialogClass">
	<p-header>
		{{ "FILTRAR" | translate }}
	</p-header>
	<div class="grid">
		<div class="col-12" *ngFor="let opcion of opcionesFiltro">
			<p-checkbox name="grupo" [label]="opcion.descripcion" [value]="opcion.codigo" [(ngModel)]="opcionesFiltroSeleccionadas"></p-checkbox>
			<span class="color-sample {{ opcion.codigo }}"></span>
		</div>
	</div>

	<p-footer>
		<button (click)="refreshCalendar()" pButton label="{{ 'APLICAR' | translate }}"></button>
	</p-footer>
</p-dialog>

<p-dialog
	[(visible)]="verBusquedaComprobante"
	appendTo="body"
	[modal]="true"
	[closable]="true"
	header="{{ 'BUSCAR_COMPROBANTE' | translate }}"
	#diag
	(onShow)="onDialogShow($event, diag)"
	[styleClass]="'gestor-dialog'"
>
	<div class="grid" *ngIf="verBusquedaComprobante">
		<div class="col-12">
			<h3 class="subtitle">{{ "FACTURAS" | translate }}</h3>
			<p-table
				[value]="facturas"
				[(selection)]="facturaSeleccionada"
				dataKey="factura.id"
				[selectionMode]="'single'"
				[paginator]="true"
				[rows]="10"
				(onRowSelect)="onSelectFactura($event)"
			>
				<ng-template pTemplate="header">
					<tr>
						<th style="width: 15%">{{ "FECHA" | translate }}</th>
						<th style="width: 20%">{{ "NUMERO" | translate }}</th>
						<th style="width: 20%" class="overflow">{{ "DESCRIPCION" | translate }}</th>
						<th style="width: 15%" class="text-right">{{ "IMPORTE" | translate }}</th>
						<th style="width: 15%" class="text-right">{{ "SALDO" | translate }}</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="5">
							<span class="empty-message">{{ "NO_SE_ENCONTRARON_REGISTROS" | translate }}</span>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-it let-i="rowIndex">
					<tr [pSelectableRow]="it">
						<td>{{ it.fecha | date : "dd/MM/yyyy" }}</td>
						<td>{{ it.tipoNumero }}</td>
						<td class="overflow" tooltipPosition="bottom" pTooltip="{{ it.descripcion }}">{{ it.descripcion }}</td>
						<td class="text-right">{{ it.importe | currency : "USD" : "$" }}</td>
						<td class="text-right">{{ it.saldo | currency : "USD" : "$" }}</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
</p-dialog>

<p-dialog
	[(visible)]="verBusquedaComprobanteCliente"
	appendTo="body"
	[modal]="true"
	[closable]="true"
	header="{{ 'BUSCAR_COMPROBANTE' | translate }}"
	#diag
	(onShow)="onDialogShow($event, diag)"
	[styleClass]="'gestor-dialog'"
>
	<div class="grid" *ngIf="verBusquedaComprobanteCliente">
		<div class="col-12">
			<h3 class="subtitle">{{ "COMPROBANTES" | translate }}</h3>
			<p-table
				[value]="comprobantes"
				[(selection)]="comprobanteSeleccionado"
				dataKey="comprobante.id"
				[selectionMode]="'single'"
				[paginator]="true"
				[rows]="10"
				(onRowSelect)="onSelectComprobante($event)"
			>
				<ng-template pTemplate="header">
					<tr>
						<th style="width: 15%">{{ "FECHA" | translate }}</th>
						<th style="width: 20%">{{ "NUMERO" | translate }}</th>
						<th style="width: 20%" class="overflow">{{ "DESCRIPCION" | translate }}</th>
						<th style="width: 15%" class="text-right">{{ "IMPORTE" | translate }}</th>
						<th style="width: 15%" class="text-right">{{ "SALDO" | translate }}</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="5">
							<span class="empty-message">{{ "NO_SE_ENCONTRARON_REGISTROS" | translate }}</span>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-it let-i="rowIndex">
					<tr [pSelectableRow]="it">
						<td>{{ it.fecha | date : "dd/MM/yyyy" }}</td>
						<td>{{ it.tipoNumero }}</td>
						<td class="overflow" tooltipPosition="bottom" pTooltip="{{ it.descripcion }}">{{ it.descripcion }}</td>
						<td class="text-right">{{ it.importe | currency : "USD" : "$" }}</td>
						<td class="text-right">{{ it.saldo | currency : "USD" : "$" }}</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
</p-dialog>
