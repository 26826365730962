import { Injectable } from '@angular/core'
import { share } from 'rxjs/operators'
import { ServicioAbstract } from 'src/app/common/services/service.service'
import { Factura } from 'src/app/model/Factura'
import { FiltroFecha } from '../common/model/FiltroFecha'
import { LoadingService } from '../common/services/loading-data-service.service'
import { Evento } from '../model/Evento'
@Injectable({
    providedIn: 'root',
})
export class FacturaService extends ServicioAbstract<Factura> {
    public baseName(): string {
        return 'factura'
    }
    public parseToEnt(data: any): Factura {
        return Factura.fromData(data)
    }
    public newEnt(): Factura {
        return new Factura()
    }
    public accionAnular(documento: Factura) {
        return this.eliminar(documento.id)
    }
    public esAccionAnular(documento: Factura) {
        return true
    }

    public getEventos(filtro: FiltroFecha): Promise<Evento[]> {
        return this.http
            .post(this.getBaseURL('getEventos'), filtro.json)
            .pipe(share())
            .toPromise()
            .then(
                (r: any[]) => {
                    return r.map((event) => Evento.fromData(event))
                },
                (e) => this.handleError(e)
            )
    }

    verAttach(attachUrl: string, customLoading?: LoadingService) {
        var sub = this.http.get(attachUrl, { responseType: 'blob' }).subscribe((x) => {
            var newBlob = new Blob([x], { type: 'application/pdf' })

            var url = URL.createObjectURL(newBlob)
            window.open(url, '_blank')
            sub.unsubscribe()
        })
    }
}
