import { Descriptivo } from 'src/app/common/model/Descriptivo';
export class CambioEstadoCajaResumen {
    constructor(
        public id?: number,
        public fecha: Date = new Date(),
        public descripcion?: string,
        public responsable?: Descriptivo,
        public monto?: number,
        public diferencia?: number,
        public justificacion?: string,
        public esCierre?: boolean,

    ) {
    }
    public static fromData(data: any): CambioEstadoCajaResumen {
        if (!data) return null;
        const o: CambioEstadoCajaResumen = new CambioEstadoCajaResumen(
            data.id,
            data.fecha ? new Date(data.fecha) : null,
            data.descripcion,
            Descriptivo.fromData(data.responsable),
            data.monto,
            data.diferencia,
            data.justificacion,
            data.esCierre,

        );

       return o;

    }

    public get tipoIcono() {
        return this.esCierre ? 'fa fa-lock' : 'fa fa-unlock';
    }
}