<div class="gestion-user content content-white">
	<model-gestor
		[service]="userService"
		[(item)]="item"
		[validar]="isValid"
		[file]="file"
		(onItemGuardado)="onGuardado($event)"
		[conArchivo]="true"
		[editable]="permiteEdicion"
		(onEditar)="onEditar($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<div class="grid" *ngIf="item">
				<div class="col-12 lg:col-3">
					<div class="grid">
						<div class="col-12 pic-wrapper">
							<div class="pic">
								<profile-badge
									[seleccionable]="true"
									[url]="item.profilePic?.picPathVersion"
									[disabled]="readonly"
									class="full-badge"
									(onFileSelected)="onPicSelected($event)"
								>
								</profile-badge>
								<i class="fa fa-pencil edit-icon" *ngIf="!readonly"></i>
							</div>
						</div>
						<div class="col-12">
							<div class="recuperar">
								<button
									pButton
									label="{{ 'RECUPERAR_CONTRASEÑA' | translate }}"
									class="p-button-warning"
									icon="fa fa-key"
									(click)="recuperarContrasena()"
								></button>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 lg:col-9">
					<div class="grid">
						<div class="col-12 lg:col-6">
							<span class="p-float-label field">
								<input
									pInputText
									placeholder="{{ 'PH_NICKNAME' | translate }}"
									[(ngModel)]="item.username"
									type="text"
									[readonly]="item.id || readonly"
									required
								/>
								<label>{{ "NICKNAME" | translate }}</label>
							</span>
						</div>
						<div class="col-12 lg:col-6">
							<span class="p-float-label field">
								<input
									pInputText
									placeholder="{{ 'PH_EMAIL' | translate }}"
									[(ngModel)]="item.email"
									type="email"
									[readonly]="readonly"
									required
								/>
								<label>{{ "EMAIL" | translate }}</label>
							</span>
						</div>
						<div class="col-12 lg:col-4">
							<span class="p-float-label field">
								<input pInputText placeholder="{{ 'PH_NOMBRE' | translate }}" [(ngModel)]="item.nombre" [readonly]="readonly" />
								<label>{{ "NOMBRE" | translate }}</label>
							</span>
						</div>
						<div class="col-12 lg:col-4">
							<span class="p-float-label field">
								<input pInputText placeholder="{{ 'PH_APELLIDO' | translate }}" [(ngModel)]="item.apellido" [readonly]="readonly" />
								<label>{{ "APELLIDO" | translate }}</label>
							</span>
						</div>

						<div class="col-12 lg:col-4">
							<span class="p-float-label field">
								<input pInputText placeholder="{{ 'PH_TELEFONO' | translate }}" [(ngModel)]="item.telefono" type="tel" [readonly]="readonly" />
								<label>{{ "TELEFONO" | translate }}</label>
							</span>
						</div>

						<div class="col-12" *ngIf="esAdministrador">
							<selector-multiple-chips
								[(ngModel)]="item.rolesNoDependientes"
								name="rolesF"
								#rolesF="ngModel"
								[opciones]="roles"
								[permiteNuevo]="false"
								[titulo]="'ROLES' | translate"
								[readonly]="readonly"
								(onChange)="rolChange($event)"
							>
							</selector-multiple-chips>
						</div>
						<div class="col-12" *ngIf="esAdministrador && rolesDependientes?.length">
							<selector-multiple-chips
								[(ngModel)]="item.rolesDependientes"
								name="rolesFDependientes"
								#rolesFDependientes="ngModel"
								[opciones]="rolesDependientes"
								[permiteNuevo]="false"
								[titulo]="'ROLES_DEPENDIENTES' | translate"
								[readonly]="readonly"
								(onChange)="rolChange($event)"
							>
							</selector-multiple-chips>
						</div>
						<div class="col-12">
							<selector-multiple-chips
								[(ngModel)]="item.tagsUsuario"
								name="tagsUsuario"
								#tagsUsuario="ngModel"
								[servicio]="tagUsuarioService"
								[permiteNuevo]="true"
								[titulo]="'Tags' | translate"
								[readonly]="readonly"
								[gestor]="gestorTag"
							>
								<ng-template #gestorTag let-handler="handler" let-itemEditado="itemEditado">
									<gestor-tag-usuario
										[item]="itemEditado"
										(onGuardado)="handler.onGuardado($event)"
										(onCancelado)="handler.onCancelado($event)"
										[isModal]="true"
										[goBack]="false"
									>
									</gestor-tag-usuario>
								</ng-template>
							</selector-multiple-chips>
						</div>
						<div class="col-12" [allowed]="'ROLE_JEFE_ADMIN'" *ngIf="!item.esAdmin() && !item.esJefeAdmin()">
							<selector-multiple-chips
								[(ngModel)]="item.cajas"
								name="cajasUsuario"
								#cajasUsuario="ngModel"
								[servicio]="cajaService"
								[permiteNuevo]="false"
								[titulo]="'Cajas' | translate"
								[readonly]="readonly"
							>
							</selector-multiple-chips>
						</div>
						<div class="col-12 lg:col-6" *ngIf="mostrarProveedor">
							<descriptivo-material-selector
								[label]="'PROVEEDOR' | translate"
								[limpiable]="true"
								[(ngModel)]="item.proveedor"
								name="proveedorUser"
								isLazy="true"
								[service]="proveedorService"
								[required]="true"
								[readonly]="readonly || !usuarioAG.esVisible(usuario)"
							>
							</descriptivo-material-selector>
						</div>
						<div class="col-12 lg:col-6" *ngIf="mostrarCliente">
							<descriptivo-material-selector
								[label]="'CLIENTE' | translate"
								[limpiable]="true"
								[(ngModel)]="item.cliente"
								name="clienteUser"
								isLazy="true"
								[service]="clienteService"
								[required]="true"
								[readonly]="readonly || !usuarioAG.esVisible(usuario)"
							>
							</descriptivo-material-selector>
						</div>
					</div>
				</div>
			</div>
		</div>
	</model-gestor>
</div>
