<div class="model-list grid actividades">
	<div class="bottom-section grid" style="margin-top: 0.25em; width: 100%">
		<div class="col-12 lg:col-12">
			<div class="grid">
				<div class="model-filters col-12 lg:col-7">
					<filtro
						*ngIf="!offline"
						[filter]="filtro"
						[isFixed]="false"
						[isMinimized]="true"
						[filterTemplateContent]="filterTemplate"
						[useSidebar]="true"
						[permiteGuardado]="true"
					>
					</filtro>
				</div>
				<div class="col-12 lg:col-5 p-fluid">
					<div class="grid">
						<div class="col">
							<button pButton label="Hoy" class="p-button-sm" (click)="updateDia(0); $event.stopPropagation()"></button>
						</div>
						<div class="col">
							<button [disabled]="offline" pButton label="Mañana" class="p-button-sm" (click)="updateDia(1); $event.stopPropagation()"></button>
						</div>
						<div class="col-fixed text-right" *ngIf="!esExterno">
							<p-selectButton
								[options]="layoutOptions"
								[(ngModel)]="filtro.layout"
								optionLabel="lebel"
								optionValue="value"
								optionIcon="icon"
							></p-selectButton>
						</div>
						<button
							class="help-button-itinerario"
							pButton
							tooltipPosition="bottom"
							pTooltip="{{ 'AYUDA' | translate }}"
							icon="fa fa-question"
							(click)="goToHelp('itinerario')"
						></button>
					</div>
				</div>
				<div class="col-12 list" *ngIf="filtro.layout === 'D'">
					<div class="grid">
						<div class="col-12" *ngFor="let fecha of fechas">
							<div class="grid">
								<div class="col-12 subtitulo clickable" (click)="irAFecha(fecha.fecha)">
									<span>{{ fecha.fecha | date : "dd/MM/yyyy" }}</span>
								</div>
								<div class="col-12 totales-actividades" *ngIf="verTotalesAdicionales">
									<div class="grid">
										<div class="col-12">
											<div class="card">
												<div class="grid">
													<div class="col-12 subtitulo">
														<span>{{ "TOTALES_ADICIONALES" | translate }}</span>
													</div>
													<div class="item-puesto col-12 lg:col" style="min-width: '33%'" *ngFor="let total of fecha?.totales">
														<div class="card card-border">
															<div class="grid grid-nogutter">
																<div class="col">
																	<span>{{ total?.producto?.descripcion }}</span>
																</div>
																<div class="col-fixed text-right">
																	<div class="bold">{{ total?.total }}</div>
																</div>
															</div>
														</div>
													</div>
													<div class="col-12" *ngFor="let actividad of fecha?.actividades">
														<strong>{{ actividad?.actividad?.descripcion }}: </strong>
														<span *ngFor="let proveedor of actividad?.proveedores" class="mr-2 proveedor">{{
															proveedor?.descripcion
														}}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<ng-container *ngIf="filtro.verDetalle">
									<div class="col-12 lg:col-4 grupo print:col-12" *ngFor="let grupo of fecha.grupos">
										<grupo-itinerario [grupo]="grupo" [full]="true" [bloqueable]="true"> </grupo-itinerario>
									</div>
								</ng-container>
							</div>
						</div>
						<div class="w-100 p-5" *ngIf="!fechas?.length">
							<strong class="text-center block">{{ "SIN_ACTIVIDADES" | translate }}</strong>
						</div>
					</div>
				</div>
				<div class="col-12 list" *ngIf="filtro.layout === 'R' && !esExterno">
					<itinerario-reserva [filtro]="filtro"> </itinerario-reserva>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="loading" *ngIf="customLoading.loading | async" [@inOutAnimation]>
	<span>{{ customLoading.status }}</span>
</div>

<ng-template #filterTemplate>
	<div class="grid">
		<div class="col-12">
			<div class="grid mt-2 p-fluid">
				<div class="col-12 lg:col-6">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="fechaDesdePivote"
							dateFormat="dd/mm/yy"
							appendTo="body"
							name="fecha"
							[showIcon]="true"
							tooltipPosition="bottom"
							pTooltip="{{ 'FECHA_DESDE' | translate }}"
							#fecha="ngModel"
							required
						></p-calendar>
						<label>{{ "FECHA_DESDE" | translate }}</label>
						<error-tag [model]="fecha"></error-tag>
					</span>
				</div>
				<div class="col-12 lg:col-6">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="fechaHastaPivote"
							dateFormat="dd/mm/yy"
							appendTo="body"
							name="fechaHasta"
							[showIcon]="true"
							tooltipPosition="bottom"
							pTooltip="{{ 'FECHA_HASTA' | translate }}"
							#fechaHasta="ngModel"
							required
						></p-calendar>
						<label>{{ "HASTA" | translate }}</label>
						<error-tag [model]="fechaHasta"></error-tag>
					</span>
				</div>
			</div>
		</div>
		<div class="col-12">
			<div class="p-inputgroup">
				<span class="p-float-label">
					<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
					<label>{{ "BUSCAR" | translate }}...</label>
				</span>
				<button type="button" pButton icon="pi pi-search"></button>
			</div>
		</div>
		<div class="col-12">
			<descriptivo-material-selector
				[label]="'CLIENTE' | translate"
				[limpiable]="true"
				[(ngModel)]="filtro.cliente"
				name="cliente_filt"
				isLazy="true"
				[service]="clienteService"
			>
			</descriptivo-material-selector>
		</div>
		<div class="col-12" *ngIf="layout != 'R'">
			<parametrico-selector
				[service]="tipoVehiculoService"
				[(ngModel)]="filtro.tipoVehiculo"
				[titulo]="'TIPO_DE_VEHÍCULO' | translate"
				[limpiable]="true"
			>
			</parametrico-selector>
		</div>
		<div class="col-12 field p-fluid">
			<span class="p-float-label">
				<p-multiSelect
					[options]="productos | async"
					[showToggleAll]="false"
					appendTo="body"
					[styleClass]="'prime-material'"
					[dropdownIcon]="'fa fa-chevron-down'"
					[(ngModel)]="filtro.productos"
					[virtualScroll]="true"
					[virtualScrollItemSize]="34"
					[selectedItemsLabel]="'ITEMS_SELECCIONADOS' | translate"
					[filter]="true"
					[emptyFilterMessage]="'SIN_RESULTADOS' | translate"
				>
				</p-multiSelect>
				<label>{{ "PRODUCTOS" | translate }}</label>
			</span>
		</div>
		<div class="col-12" *ngIf="!usuario?.proveedor && layout != 'R'">
			<descriptivo-material-selector
				[label]="'PROVEEDOR' | translate"
				[limpiable]="true"
				[(ngModel)]="filtro.proveedor"
				name="proveedor_filt"
				isLazy="true"
				[service]="proveedorService"
			>
			</descriptivo-material-selector>
		</div>
		<div class="col-12">
			<p-checkbox
				[(ngModel)]="filtro.verDetalle"
				name="verDetalle"
				tooltipPosition="bottom"
				pTooltip="{{ 'VER_DETALLE' | translate }}"
				binary="true"
				label="{{ 'VER_DETALLE' | translate }}"
			>
			</p-checkbox>
		</div>
	</div>
</ng-template>
