import { Component, OnInit } from "@angular/core"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { AccionesTabla } from "src/app/common/model/AccionesTabla"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { EmailTemplate } from "src/app/model/EmailTemplate"
import { FiltroEmailTemplate } from "src/app/model/FiltroEmailTemplate"
import { PuntoDeVenta } from "src/app/model/PuntoDeVenta"
import { PuntoDeVentaService } from "src/app/services/punto-de-venta.service"
import { EmailTemplateService } from "./../../../../services/emailtemplate.service"
@Component({
	selector: "lista-email-template",
	templateUrl: "lista-email-template.component.html",
	styleUrls: ["../../parametricos.less"]
})
export class ListaEmailTemplatesComponent extends SessionComponent implements OnInit {
	public f = new FiltroEmailTemplate("filtro-email", 0, 10, "id")
	selectedItem: EmailTemplate
	copiar: boolean = false
	descripcion: string
	puntoDeVenta: PuntoDeVenta
	acciones: AccionesTabla[] = [
		new AccionesTabla("Clonar", "fa fa-copy", "accion-tabla", (item) => {
			this.selectedItem = item
			this.copiar = true
			this.descripcion = item.descripcion + " - Copia"
		})
	]

	constructor(public emailTemplateService: EmailTemplateService, messagesService: MessagesService, public puntoDeVentaService: PuntoDeVentaService) {
		super(messagesService)
	}
	public columns
	ngOnInit(): void {
		this.columns = [
			{
				field: "tipoCanal",
				header: "Tipo Canal",
				role: "descriptivo-tabla",
				width: "15%"
			},
			{
				field: "tipoNotificacion",
				header: "Tipo Notificación",
				role: "descriptivo-tabla",
				width: "15%"
			},
			{
				field: "puntoDeVenta",
				header: "Punto de Venta",
				role: "descriptivo-tabla",
				width: "15%"
			},
			{
				field: "descripcion",
				header: "Descripción",
				role: "text-tabla"
			}
		]
	}

	copiarPlantilla() {
		let e = EmailTemplate.fromData(this.selectedItem)
		e.descripcion = this.descripcion
		e.puntoDeVenta = this.puntoDeVenta
		e.id = null
		e.codigo = null
		this.emailTemplateService.guardar(e).then((data) => {
			this.messagesService.success("Se ha copiado la plantilla correctamente")
			this.copiar = false
			this.selectedItem = null
			this.descripcion = null
			this.puntoDeVenta = null
			this.f.forceUpdate()
		})
	}
}
