import { Directive, ElementRef } from "@angular/core"
import { AuthService } from "./../../services/auth.service"

@Directive({
	selector: "[hideExterno]"
})
export class HideExterno {
	constructor(private el: ElementRef, private authService: AuthService) {
		let u = this.authService.getCurrentUser
		if (u?.esExterno) {
			this.el.nativeElement.style.display = "none"
		}
	}
}
