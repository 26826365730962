import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { TipoComprobante } from "src/app/model/TipoComprobante"
import { MessagesService } from "../common/services/messages-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class TipoComprobanteService extends ParametricoService<TipoComprobante> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "tipo-comprobante"
	}
	public parseToEnt(data: any): TipoComprobante {
		return TipoComprobante.fromData(data)
	}
	public newEnt(): TipoComprobante {
		return new TipoComprobante()
	}

	public getOficiales(): Promise<TipoComprobante[]> {
		return this.getData().then((c) => {
			return c.filter((t) => t.esOficial)
		})
	}
}
