import { Component, OnInit } from '@angular/core';
import { HelpService } from '../../../services/help.service';

@Component({
  selector: 'app-help-configuracion',
  templateUrl: './help-configuracion.component.html',
  styleUrls: ['./help-configuracion.component.less']
})
export class HelpConfiguracionComponent {

  constructor(public helpService: HelpService) { }


}
