import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { FormControl } from "@angular/forms"
import { ActivatedRoute } from "@angular/router"
import { ShepherdService } from "angular-shepherd"
import * as moment from "moment"
import { ConfirmationService } from "primeng/api"
import { Table } from "primeng/table"
import { Subscription } from "rxjs"
import { map } from "rxjs/operators"
import { ListaPreciosAG } from "src/app/authguards/ListaPreciosAG"
import { commonAnimations } from "src/app/common-animations"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { ListaPrecios } from "src/app/model/ListaPrecios"
import { ListaProducto } from "src/app/model/ListaProducto"
import { ListaPreciosService } from "src/app/services/lista-precios.service"
import { MonedaService } from "src/app/services/moneda.service"
import * as XLSX from "xlsx"
import { SessionComponent } from "../../../common/components/session-component.component"
@Component({
	selector: "edicion-lista-precios",
	templateUrl: "edicion-lista-precios.component.html",
	styleUrls: ["edicion-lista-precios.component.less"],
	animations: [commonAnimations]
})
export class EdicionListaPreciosComponent extends SessionComponent implements OnDestroy, OnInit {
	defaultShepherdOptions = {
		buttons: [
			{
				classes: "p-button p-button-primary fw-700 p-button-icon-only mr-2",
				text: "<<",
				action: () => {
					this.shepherdService.back()
				}
			},
			{
				classes: "p-button p-button-primary fw-700 p-button-icon-only",
				text: ">>",
				action: () => {
					this.shepherdService.next()
				}
			}
		],
		cancelIcon: {
			enabled: true
		},
		highlightClass: "highlight",
		useModalOverlay: true
	}
	private routeSub: Subscription
	@ViewChild("tabla") tabla: Table
	@Input()
	public item: ListaPrecios = new ListaPrecios()
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false
	public showRedondeo: boolean = false
	@Output()
	public onGuardado: EventEmitter<ListaPrecios> = new EventEmitter<ListaPrecios>()
	@Output()
	public onCancelado = new EventEmitter()
	public opcionesEdicion = [
		{ tooltip: "PORCENTAJE", value: "P", icon: "pi pi-percentage" },
		{ value: "M", tooltip: "MONTO", icon: "pi pi-pencil" },
		{ value: "S", tooltip: "SUMA", icon: "pi pi-plus" }
	]

	public opcionesCalculo = [
		{ tooltip: "PRECIO", value: "P" },
		{ tooltip: "DEPENDIENTE", value: "D" },
		{ tooltip: "COSTOS", value: "C" }
	]
	public opcionesEdicionNuevo = [...this.opcionesEdicion, { value: "E", tooltip: "Mismo valor", icon: "pi pi-pause rotate90" }]
	public modoEdicion: "M" | "P" | "S" = "P"
	public modoEdicionNuevo: "E" | "M" | "P" | "S" = "P"
	public montoEdicion: number = 0
	public montoEdicionNuevo: number = 0
	public showNuevosPrecios: boolean = false
	public readonly: boolean = false
	public editable: boolean = true
	public productoOptions: ListaProducto[] = []
	public digitosSignificativos: number = 0

	private _fechaPreciosHasta: Date
	public get fechaPreciosHasta(): Date {
		return this._fechaPreciosHasta
	}
	public set fechaPreciosHasta(v: Date) {
		this._fechaPreciosHasta = v
		this.actualizarPrecios()
	}

	private _fechaPrecios: Date = new Date()
	public get fechaPrecios(): Date {
		return this._fechaPrecios
	}
	public set fechaPrecios(v: Date) {
		this._fechaPrecios = v ? v : moment().add(-2, "year").toDate()
		this.actualizarPrecios()
	}
	public verGestionProductos: boolean = false
	public verGestionPrecios: boolean = false
	public preciosGestionando: ListaProducto[] = []
	public productoGestionando: Descriptivo
	public errorGestionPrecios: string
	public selectedProducts: ListaProducto[] = []
	public permitirMasivo: boolean = true
	public verEdicionMasiva: boolean = false
	public desdeMasivo: Date
	public hastaMasivo: Date
	public montoMasivo: number
	public suma: boolean = true
	public edicion: boolean = false
	public porcentaje: boolean = false
	public productosDisponiblesMasivo: Descriptivo[] = []
	public productosEditarMasivo: Descriptivo[] = []
	public productosPorFecha: ListaProducto[] = []
	public productosFiltrados: ListaProducto[] = []

	private _soloConflictos: boolean = false
	public get soloConflictos(): boolean {
		return this._soloConflictos
	}
	public set soloConflictos(v: boolean) {
		this._soloConflictos = v
		this._filter(this.buscarControl.value)
	}

	constructor(
		messagesService: MessagesService,
		public service: ListaPreciosService,
		private listaPreciosAG: ListaPreciosAG,
		private route: ActivatedRoute,
		public monedaService: MonedaService,
		private shepherdService: ShepherdService,
		private confirmService: ConfirmationService
	) {
		super(messagesService)
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item)
		this.actualizarPrecios()
	}

	public handleCancelar(item) {
		this.onCancelado.emit()
	}
	public descargarMuestra(event) {
		event.stopPropagation()
		const listaPrecios = this.item
		const workbook = XLSX.utils.book_new()
		const worksheet = XLSX.utils.aoa_to_sheet([
			["Código", "Descripción", "Código Moneda", "Incluye Impuestos"],
			[listaPrecios.codigo || "", listaPrecios.descripcion || "", listaPrecios.moneda?.codigo || "", listaPrecios.impuestosIncluidos ? "X" : ""],
			[
				"Código de producto",
				"Descripcion producto",
				"Fecha Desde",
				"Fecha Hasta",
				"Minimo Paxs",
				"Máximo Paxs",
				"Precio",
				"Precio Mínimo",
				"Es calculado",
				"Tipo Cálculo",
				"Porcentaje",
				"Código de producto dependiente"
			]
		])

		this.productosFiltrados.forEach((producto) => {
			const filaProducto = [
				producto.producto?.codigo || "",
				producto.producto?.descripcion || "",
				producto.fechaDesde ? producto.fechaDesde.toISOString().split("T")[0] : "",
				producto.fechaHasta ? producto.fechaHasta.toISOString().split("T")[0] : "",
				producto.minPax || "",
				producto.maxPax || "",
				producto.precio || 0,
				producto.precioMinimo || "",
				producto.esCalculado ? "X" : "",
				producto.tipoCalculo || "",
				producto.porcentual || null,
				producto.productoDependiente?.codigo || ""
			]
			XLSX.utils.sheet_add_aoa(worksheet, [filaProducto], { origin: -1 })
		})

		XLSX.utils.book_append_sheet(workbook, worksheet, "Precios")
		XLSX.writeFile(workbook, "ListaPrecios" + this.item.descripcion + ".xlsx")
	}
	public isValid = () => {
		var valid = true
		if (!this.item.descripcion) {
			valid = valid && this.error("Indique una descripción")
		}
		if (!this.item.moneda) {
			valid = valid && this.error("Indique una moneda")
		}
		if (!this.item.productos.length) {
			valid = valid && this.error("Indique al menos un producto")
		}
		this.errorGestionPrecios = null

		this.productosPorFecha.forEach((p) => {
			delete p["superpone"]
			delete p["productoDependienteInexistente"]
		})
		this.productosPorFecha.forEach((elementoActual) => {
			this.productosPorFecha
				.filter(
					(elementoSiguiente) =>
						elementoActual.producto?.id === elementoSiguiente.producto?.id &&
						elementoActual !== elementoSiguiente &&
						elementoActual.aplicaRango(elementoSiguiente.fechaDesde, elementoSiguiente.fechaHasta) &&
						elementoActual.superponeMaxMin(elementoSiguiente.minPax, elementoSiguiente.maxPax)
				)
				.forEach((elementoSiguiente) => {
					elementoActual["superpone"] = true
					elementoSiguiente["superpone"] = true
				})

			if (elementoActual.productoDependiente && !this.item.tieneProducto(elementoActual.productoDependiente)) {
				elementoActual["productoDependienteInexistente"] = true
			}
		})
		this.productosPorFecha
			.filter((p) => p["superpone"] || !p.precio)
			.forEach((p) => {
				if (p["superpone"]) {
					valid = valid && this.error(this.translateService.get("EXISTEN_FECHAS_SUPERPUESTAS").replace("${1}", p.producto.descripcion))
				}
				if (p["productoDependienteInexistente"]) {
					valid =
						valid &&
						this.error(this.translateService.get("EL_PRODUCTO_NO_SE_ENCUENTRA_EN_EL_LISTADO").replace("${1}", p.productoDependiente.descripcion))
				}
			})

		return valid
	}

	private _filter(d: string) {
		this.productosFiltrados = [
			...this.productosPorFecha
				.filter(
					(p) =>
						(this.soloConflictos ? p["superpone"] || !p.precio : true) &&
						(!d || (p.producto && p.producto.descripcion.toUpperCase().includes(d.toUpperCase())))
				)
				.sort((a, b) => {
					return a.descripcion > b.descripcion ? 1 : -1
				})
		]
	}
	public buscarControl: FormControl = new FormControl()
	ngOnInit() {
		this.subs.push(
			this.buscarControl.valueChanges
				.pipe(
					map((v) => {
						if (typeof v === "string") {
							return v.toUpperCase()
						} else {
							return ""
						}
					})
				)
				.subscribe((d: string) => {
					this._filter(d)
				})
		)
		this.subs.push(
			this.route.data.subscribe((u) => {
				this.editable = this.listaPreciosAG.esVisible(this.usuario)
				this.readonly = u?.vista || !this.editable
			})
		)
		if (this.route.snapshot.url.some((u) => u.path == "lista-precios")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				if (!this.service) return
				if (id) {
					this.service.getById(id).then((r) => {
						this.item = ListaPrecios.fromData(r)
						this.updateProductos()
						this.fechaPrecios = moment().subtract(1, "month").toDate()
					})
				} else {
					// this.error('Error al editar lista')
					this.item = this.service.newEnt()
					this.updateProductos()
					this.fechaPrecios = moment().subtract(1, "month").toDate()
				}
			})
		}
	}
	public get productosEnListado() {
		return (
			this.item?.productos
				.filter((p) => p.tipoCalculo != "D" || !p.tipoCalculo)
				.map((p) => p.producto)
				.filter((producto, index, self) => self.findIndex((p) => p.id === producto.id) === index) || []
		)
	}
	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}
	actualizarPrecios() {
		this.productosPorFecha = this.item ? this.item.getProductosPorFecha(this.fechaPrecios, this.fechaPreciosHasta) : []
		this._filter(this.buscarControl.value)
	}
	gestionarPrecios(p: ListaProducto) {
		this.preciosGestionando = this.item
			? this.item.productos.filter((pi) => pi.producto.id == p.producto.id).sort((a, b) => (a.fechaDesde < b.fechaDesde ? 1 : -1))
			: []
		this.productoGestionando = p.producto
		this.verGestionPrecios = true
	}

	cancelarGestionPrecios() {
		this.preciosGestionando = []
		this.verGestionPrecios = false
	}
	aplicarNuevo() {
		this.selectedProducts
			.reduce((resultado, objeto) => {
				const existe = resultado.some((item) => item.producto.id === objeto.producto.id)
				if (!existe) {
					resultado.push(objeto)
				}
				return resultado
			}, [])
			.forEach((p) => {
				this.nuevoPrecio(p, this.modoEdicionNuevo, this.montoEdicionNuevo)
			})
		this.selectedProducts = []
		this.actualizarPrecios()
		this.showNuevosPrecios = false
	}
	nuevoPrecioParaSeleccionados(producto?: ListaProducto) {
		this.montoEdicionNuevo = 0
		this.modoEdicionNuevo = "E"
		if (producto) this.selectedProducts = [producto]
		this.showNuevosPrecios = true
	}
	copiarSeleccionados() {
		this.selectedProducts.forEach((p) => {
			this.copiarPrecio(p)
		})
		this.selectedProducts = []
		this.actualizarPrecios()
	}
	preGuardado = () => {
		this.item &&
			this.item.productos.forEach((p) => {
				p.fechaDesde = moment(p.fechaDesde).startOf("day").toDate()
				if (p.fechaHasta) p.fechaHasta = moment(p.fechaHasta).endOf("day").subtract(10, "seconds").toDate()
			})
	}
	nuevoPrecio = (precio: ListaProducto, modoEdicion?: string, montoEdicion?: number) => {
		const precioMayor = this.item
			? this.item.productos.filter((p) => p.producto.id == precio.producto.id).sort((a, b) => (a.fechaDesde > b.fechaDesde ? -1 : 1))[0]
			: null
		let nuevoPrecio: ListaProducto
		if (!precioMayor) {
			nuevoPrecio = new ListaProducto(null, precio.producto, null, null, null, new Date(), null, null, precioMayor.minPax, precioMayor.maxPax)
		} else {
			if (!precioMayor.fechaHasta) {
				let now = moment().endOf("date").toDate()
				precioMayor.fechaHasta = precioMayor.fechaDesde > now ? moment(precioMayor.fechaDesde).endOf("date").subtract(10, "seconds").toDate() : now
			}
			nuevoPrecio = new ListaProducto(
				null,
				precio.producto,
				null,
				null,
				null,
				moment(precioMayor.fechaHasta).add(1, "days").startOf("day").toDate(),
				null,
				null,
				precioMayor.minPax,
				precioMayor.maxPax
			)
		}
		if (modoEdicion && (montoEdicion || modoEdicion == "E")) {
			this.actualizarValor(
				nuevoPrecio,
				this.digitosSignificativos,
				this.montoEdicionNuevo,
				this.modoEdicionNuevo,
				precioMayor.precio,
				precioMayor.precioMinimo
			)
		}

		this.item && this.item.productos.push(nuevoPrecio)
		this.animarModificado(nuevoPrecio)
		this.actualizarPrecios()
	}
	copiarPrecio = (precio: ListaProducto) => {
		let nuevoPrecio = ListaProducto.fromData({ ...precio, id: null })
		this.item && this.item.productos.push(nuevoPrecio)
		this.animarModificado(nuevoPrecio)
		this.actualizarPrecios()
	}
	animarModificado(p: any) {
		p["modificado"] = true
		setTimeout(() => {
			delete p["modificado"]
		}, 1000)
	}

	eliminarPrecio(precio: ListaProducto) {
		this.item && this.item.quitarPrecio(precio)
		this.actualizarPrecios()
	}

	get productosUnicos() {
		return this.item ? this.item.productos.filter((p, i, arr) => arr.findIndex((pp) => pp.producto.id === p.producto.id) === i) : []
	}

	get productoOptionsUnicos() {
		return this.productoOptions
			.filter((p, i, arr) => arr.findIndex((pp) => pp.producto.id === p.producto.id) === i)
			.sort((a, b) => (a.descripcion > b.descripcion ? 1 : -1))
	}

	addProducto(event) {
		event.items.forEach((p) => {
			this.item.productos = this.item.productos.concat(this.productoOptions.filter((pp) => pp.producto.id == p.producto.id))
			this.productoOptions = this.productoOptions.filter((pp) => pp.producto.id != p.producto.id)
		})
		this.actualizarPrecios()
	}

	removeProducto(event) {
		event.items.forEach((p) => {
			this.productoOptions = this.productoOptions.concat(this.item.productos.filter((pp) => pp.producto.id == p.producto.id))
			this.item.productos = this.item.productos.filter((pp) => pp.producto.id != p.producto.id)
		})
		this.actualizarPrecios()
	}

	guardarMasivo() {
		if (!this.desdeMasivo) {
			this.error("Debe indicar la fecha Desde")
			return
		} else if (!this.montoMasivo) {
			this.error("Debe indicar el monto de la operación")
			return
		} else if (this.productosEditarMasivo.length == 0) {
			this.error("Debe seleccionar al menos un producto a editar")
			return
		}
		var error = false
		this.item.productos
			.filter((p) => p.precio <= 0)
			.forEach((p) => {
				this.error("El producto " + p.producto.descripcion + " no tiene precio")
				error = true
			})
		if (error) return

		this.service
			.guardarEdicionMasiva(
				this.item.id,
				this.productosEditarMasivo,
				this.desdeMasivo,
				this.hastaMasivo,
				this.montoMasivo,
				this.edicion,
				this.suma,
				this.porcentaje
			)
			.then((saved) => {
				this.item = saved
				this.success("Se guardó con éxito la lista")
				this.verEdicionMasiva = false
			})
	}
	private actualizarValor(p: ListaProducto, digitos: number, valorUpdate: number, modo: string, base?: number, baseMinimo?: number) {
		const precioBase = base || p.precio || 0
		const minimoBase = baseMinimo || p.precioMinimo || 0
		const factor = Math.pow(10, digitos - 2)
		switch (modo) {
			case "E":
				p.precio = p.precio
			case "S":
				p.precio = precioBase + valorUpdate
				//p.precioMinimo = minimoBase + valorUpdate
				break
			case "P":
				p.precio = precioBase * (1 + valorUpdate / 100)
				p.precioMinimo = minimoBase * (1 + valorUpdate / 100)
				break
			case "M":
				p.precio = valorUpdate
				break
		}
		if (digitos !== 0) {
			p.precio = Math.round(p.precio / factor) * factor
			p.precioMinimo = Math.round(p.precioMinimo / factor) * factor
		}
		p["modificado"] = true
	}
	public confirmarRedondeo(base?: number) {
		const factor = Math.pow(10, this.digitosSignificativos - 2)
		this.selectedProducts.forEach((p) => {
			this.actualizarValor(p, this.digitosSignificativos, this.montoEdicion, this.modoEdicion)
		})
		setTimeout(() => {
			this.productosFiltrados.forEach((p) => {
				delete p["modificado"]
			})
			this.productosFiltrados = [...this.productosFiltrados]
		}, 1000)
		this.montoEdicion = 0
		this.modoEdicion = "P"
		this.selectedProducts = []
		this.showRedondeo = false
	}
	public aplicarAjuste() {
		this.digitosSignificativos = 2
		this.showRedondeo = true
	}
	private updateProductos() {
		this.service.getProductos().then((ps) => {
			//this.item.productos = [];
			this.productoOptions = []
			ps.forEach((p) => {
				this.productoOptions = ps.filter((p) => !this.item.productos.some((pi) => pi.producto.id == p.producto.id))
			})
			if (!this.readonly) {
				const tourShown = localStorage.getItem("tourShown")
				if (!tourShown) {
					this.shepherdService.modal = true
					this.shepherdService.defaultStepOptions = this.defaultShepherdOptions

					this.shepherdService.addSteps([
						{
							id: "intro",
							attachTo: {
								element: "#step1edicion",
								on: "bottom"
							},
							scrollTo: false,
							text: [this.translateService.get("STEP1_EDICION_PRECIOS")]
						},
						{
							id: "intro",
							attachTo: {
								element: "#step2edicion",
								on: "bottom"
							},
							scrollTo: false,
							text: [this.translateService.get("STEP2_EDICION_PRECIOS")]
						},
						{
							id: "intro",
							attachTo: {
								element: "#step3edicion",
								on: "bottom"
							},
							scrollTo: false,
							text: [this.translateService.get("STEP3_EDICION_PRECIOS")]
						}
					])

					localStorage.setItem("tourShown", "true")
					this.shepherdService.start()
				}
			}
		})
	}

	get scrollHeight() {
		if (this.isMobile()) return "30vh"

		return window.innerWidth < 1500 ? "50vh" : "60vh"
	}

	// onChangePrecio(p: ListaProducto, precio: number) {
	// 	this.productosFiltrados.forEach((producto) => {
	// 		let pr = this.item.productos.find((prod) => prod.key == producto.key)
	// 		pr.precio = producto.precio
	// 	})
	// 	this.item = ListaPrecios.fromData(this.item)
	// }
}
