import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-producto',
  templateUrl: './help-producto.component.html',
  styleUrls: ['./help-producto.component.less']
})
export class HelpProductoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
