import * as moment from "moment"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Hotel } from "src/app/model/Hotel"
import { ItemResumenReserva } from "src/app/model/ItemReservaResumen"
import { Restriccion } from "src/app/model/Restriccion"
import { TipoProducto } from "src/app/model/TipoProducto"
import { ID } from "../app.module"
import { IConSubcategoriasImponibles } from "../common-components/abm-subcategorias-imponibles/IConSubcategoriasImponibles"
import { round } from "./../common/utils/MathUtils"
import { Auditable } from "./Auditable"
import { CalculadoraImpuestos, IConImpuestos } from "./CalculadoraImpuestos"
import { CategoriaHospedaje } from "./CategoriaHospedaje"
import { ConfiguracionPrograma } from "./ConfiguracionPrograma"
import { ConfirmacionReservaPuesto } from "./ConfirmacionReservaPuesto"
import { EstadoReserva } from "./EstadoReserva"
import { GrupoPax } from "./GrupoPax"
import { GrupoPaxResumen } from "./GrupoPaxResumen"
import { GrupoReserva } from "./GrupoReserva"
import { Idioma } from "./Idioma"
import { ProductoAsociadoReserva } from "./ProductoAsociadoReserva"
import { PuestoServicioReserva } from "./PuestoServicioReserva"
import { SubcategoriaImponibleItem } from "./SubcategoriaImponibleItem"
import { TipoIVAGrabado } from "./TipoIVAGrabado"
import { TipoVariante } from "./TipoVariante"

export class ItemReserva extends Auditable implements IConSubcategoriasImponibles, IConImpuestos {
	public asociadosModificados = false
	public prevKey: string
	public configuracionPaquete: ConfiguracionPaqueteItem[]

	constructor(
		public id?: number,
		public idReserva?: number,
		public nombrePax?: string,
		public observaciones?: string,
		public cantidadPax?: number,
		public fechaActividad?: Date,
		public idiomaServicio?: Idioma,
		public duracion?: number,
		public precioPerCapita?: number,
		public ajuste: number = 0,
		public comision?: number,
		public productosAsociados: ProductoAsociadoReserva[] = [],
		public producto?: Descriptivo,
		public pickUp?: Date,
		public reservas: GrupoReserva[] = [],
		public restricciones: Restriccion[] = [],
		public hotel?: Hotel,
		public UUID?: string,
		public esCancelada?: boolean,
		public motivoCancelacion?: string,
		public precioEditado = false,
		public aceptadoConsentimiento = false,
		public estadoConsentimiento?: EstadoReserva,
		public impuestosIncluidos: boolean = false,
		public tipoIVA?: TipoIVAGrabado,
		public notaCliente?: string,
		public tipoProducto?: Descriptivo,
		public token?: string,
		public tieneEncuesta?: boolean,
		public confirmaciones?: ConfirmacionReservaPuesto[],
		public configuracionPrograma?: ConfiguracionPrograma,
		public programaId?: number,
		public direccionAlternativa?: string,
		public familiaProducto?: Descriptivo,
		lastModifiedBy?: string,
		lastModifiedDate?: Date,
		public version?: number,
		public precioPerCapitaOriginal?: number,
		public nombreCliente?: string,
		public subcategorias: SubcategoriaImponibleItem[] = [],
		public esSubcategorizado: boolean = false,
		public checkIn?: Date,
		public checkOut?: Date,
		public confirmarHospedaje?: boolean,
		public proveedorTentativo?: number,
		public cantidadPaxReserva?: number,
		public agrupado: boolean = true,
		public tokenAgrupado?: string,
		public variante?: TipoVariante,
		public observacionesReserva?: string,
		public baseCalculo: number = 1,
		public referencia?: string,
		public categoriaHospedaje?: CategoriaHospedaje
	) {
		super(lastModifiedBy, lastModifiedDate)
		/*if (pickUp == null) {
			this.pickUp = moment(this.fechaActividad).set("hour", 8).set("minute", 0).toDate()
		}*/
		if (!UUID) this.UUID = this.id ? this.id + "" : ID()
	}
	get importe(): number {
		return this.precioPerCapita
	}
	get cantidad(): number {
		return !this.esHospedaje() ? this.cantidadPax : 1
	}
	get multiplicadorIVA(): number {
		return (this.tipoIVA?.valorIva || 0) / 100
	}
	get colorVariante() {
		return this.variante?.color || "#000000"
	}
	get descVariante() {
		return this.variante?.descripcion || ""
	}
	public static fromData(data: any): ItemReserva {
		if (!data) return null
		const o: ItemReserva = new ItemReserva(
			data.id,
			data.idReserva,
			data.nombrePax,
			data.observaciones,
			data.cantidadPax,
			data.fechaActividad ? new Date(data.fechaActividad) : null,
			Idioma.fromData(data.idiomaServicio),
			data.duracion,
			data.precioPerCapita,
			data.ajuste,
			data.comision,
			data.productosAsociados ? data.productosAsociados.map((c) => ProductoAsociadoReserva.fromData(c)) : [],
			Descriptivo.fromData(data.producto),
			data.pickUp ? new Date(data.pickUp) : null,
			data.reservas ? data.reservas.map((i) => GrupoReserva.fromData(i)) : [],
			data.restricciones ? data.restricciones.map((r) => Restriccion.fromData(r)) : [],
			Hotel.fromData(data.hotel),
			data.UUID,
			data.esCancelada,
			data.motivoCancelacion,
			data.precioEditado,
			data.aceptadoConsentimiento,
			EstadoReserva.fromData(data.estadoConsentimiento),
			data.impuestosIncluidos,
			TipoIVAGrabado.fromData(data.tipoIVA),
			data.notaCliente,
			Descriptivo.fromData(data.tipoProducto),
			data.token,
			data.tieneEncuesta,
			data.confirmaciones ? data.confirmaciones.map((c) => ConfirmacionReservaPuesto.fromData(c)) : [],
			ConfiguracionPrograma.fromData(data.configuracionPrograma),
			data.programaId,
			data.direccionAlternativa,
			Descriptivo.fromData(data.familiaProducto),
			data.lastModifiedBy,
			data.lastModifiedDate
		)
		o.version = data.version
		o.productosAsociados.forEach((p) => {
			p.prevKey = p.producto?.id + moment(o.fechaActividad).format("DDMMYYYY")
			p.impuestosIncluidos = o.impuestosIncluidos
		})
		o.subcategorias = data.subcategorias?.map((s) => SubcategoriaImponibleItem.fromData(s)) || []
		o.esSubcategorizado = data.esSubcategorizado
		o.prevKey = o.producto?.id + moment(o.fechaActividad).format("DDMMYYYY")
		o.precioPerCapitaOriginal = o.precioPerCapita
		o.checkIn = data.checkIn ? new Date(data.checkIn) : null
		o.checkOut = data.checkOut ? new Date(data.checkOut) : null
		o.proveedorTentativo = data.proveedorTentativo
		o.cantidadPaxReserva = data.cantidadPaxReserva
		o.agrupado = data.agrupado
		o.tokenAgrupado = data.tokenAgrupado
		o.variante = TipoVariante.fromData(data.variante)
		o.observacionesReserva = data.observacionesReserva
		o.baseCalculo = data.baseCalculo
		o.referencia = data.referencia
		o.categoriaHospedaje = CategoriaHospedaje.fromData(data.categoriaHospedaje)
		return o
	}

	public get totalAdicionales() {
		return this.productosAsociados.filter((p) => p.esAdicional || p.conPrecio).reduce((a, p) => a + p.total, 0)
	}
	public get totalNetoAdicionales() {
		return this.productosAsociados.filter((p) => p.esAdicional || p.conPrecio).reduce((a, p) => a + p.totalNeto, 0)
	}
	public get netoAdicionales() {
		return this.productosAsociados.filter((p) => p.esAdicional || p.conPrecio).reduce((a, p) => a + p.subtotal, 0)
	}

	public get subtotalAdicionales() {
		return this.productosAsociados.filter((p) => p.esAdicional || p.conPrecio).reduce((a, p) => a + p.subtotal, 0) * (1 + this.ajuste / 100)
	}

	public get impuestosAdicionales() {
		return this.productosAsociados.filter((p) => p.esAdicional || p.conPrecio).reduce((a, p) => a + p.getImpuestos(this.ajuste / 100), 0)
	}
	get incluyeImpuestos(): boolean {
		return this.impuestosIncluidos
	}
	public get neto() {
		return this.esHospedaje() ? this.precioPerCapita : this.precioPerCapita * this.cantidadPax
	}
	public get netoConAdicionales() {
		return this.neto + this.netoAdicionales - (this.incluyeImpuestos ? this.impuestos : 0)
	}
	public get valorAjuste() {
		return round((this.netoConAdicionales * (this.ajuste || 0)) / 100)
	}

	public get valorComision() {
		return this.comision ? Math.round(this.neto * (1 + this.ajuste / 100) * (this.comision / 100) * 100) / 100 : 0
	}
	public get total() {
		if (this.impuestosIncluidos) return round(this.neto * (1 + this.ajuste / 100) + this.totalNetoAdicionales * (1 + this.ajuste / 100))
		return round(this.neto * (1 + this.ajuste / 100) + this.impuestos + this.totalNetoAdicionales * (1 + this.ajuste / 100))
	}
	public quitarProducto(p: ProductoAsociadoReserva) {
		this.productosAsociados = this.productosAsociados.filter((pp) => pp != p)
	}

	public get porUbicar() {
		return (
			((!this.tipoProducto ||
				this.tipoProducto?.codigo == TipoProducto.PAQUETE ||
				this.tipoProducto?.codigo == TipoProducto.FAMILIA ||
				this.tipoProducto?.codigo == TipoProducto.SERVICIO ||
				this.tipoProducto?.codigo == TipoProducto.TRANSFER ||
				this.tipoProducto?.codigo == TipoProducto.TERCERIZADO) &&
				this.cantidadPax -
					this.reservas
						.filter((g) => g?.tieneItem(this))
						.reduce((a, b) => {
							return (a += b.cantidadPax)
						}, 0)) ||
			0
		)
	}
	public agregarGrupoReserva(gr: GrupoReserva) {
		const exist = this.reservas.filter((r) => r.UUID == gr.UUID)[0]
		if (!exist) {
			this.reservas.push(gr)
			gr.itemReserva = ItemResumenReserva.fromData(this)
		} else {
			exist.cantidadPax = gr.cantidadPax
		}
		this.fechaActividad = gr.grupoResumen?.fechaActividad || this.fechaActividad
	}

	public quitarGrupoReserva(gr: GrupoReserva) {
		this.reservas = this.reservas.filter((r) => r.UUID != gr.UUID)
	}
	public get descripcion() {
		return (this.producto ? this.producto?.descripcion : "") + (this.fechaActividad ? " - " + moment(this.fechaActividad).format("DD/MM/YYYY") : "")
	}

	public quitarGrupo(g) {
		let aQuitar = this.reservas.filter((t) => t.grupoResumen?.UUID == g?.UUID)
		this.reservas = this.reservas.filter((t) => t.grupoResumen?.UUID != g?.UUID)
		return aQuitar
	}

	public get subtotal() {
		const neto = round(this.neto * (1 + this.ajuste / 100))
		return this.incluyeImpuestos ? neto - this.impuestos : neto
	}

	public get impuestos() {
		return round(CalculadoraImpuestos.calcularImpuestos(this))
	}
	public esTipoServicio(tipo: string) {
		return this.tipoProducto?.codigo == tipo
	}
	public esTercerizado() {
		return this.esTipoServicio(TipoProducto.TERCERIZADO)
	}
	public esTransfer() {
		return this.esTipoServicio(TipoProducto.TRANSFER)
	}
	public esServicio() {
		return !this.tipoProducto || this.tipoProducto?.codigo == "SER"
	}

	public get esPrograma() {
		return this.esTipoServicio(TipoProducto.PROGRAMA)
	}
	public esProducto() {
		return this.esTipoServicio(TipoProducto.PRODUCTO)
	}
	public esHospedaje() {
		return this.esTipoServicio(TipoProducto.HOSPEDAJE)
	}
	public get json() {
		return JSON.parse(
			JSON.stringify(this, (k, v) => {
				return k != "_reservas" ? v : null
			})
		)
	}
	getPuestos() {
		return this.reservas.map((r) => r.grupoResumen.puestos).flat()
	}
	getPuestoPrincipal(): PuestoServicioReserva {
		if (!this.reservas.length) return null
		return this.reservas[0].grupoResumen.puestos[0] || null
	}
	public get actividadCompletada() {
		return this.reservas.every((reserva) => reserva.grupoResumen.actividadCompletada)
	}
	public get direccion() {
		return this.direccionAlternativa || this.hotel?.direccion
	}
	public get referenciaDireccion() {
		return this.direccionAlternativa || this.hotel ? this.hotel?.descripcion + " - " + this.hotel?.direccion : "S/D"
	}
	public quitarItem(item: any) {
		this.subcategorias = this.subcategorias.filter((i) => i != item)
	}
	public agregarItem(item: any) {
		if (this.subcategorias.indexOf(item) < 0) {
			this.subcategorias = [...this.subcategorias, item]
		}
	}
	public newItem() {
		const s = new SubcategoriaImponibleItem()
		s.proporcion = 100 - this.subcategorias.reduce((a, b) => (a += b.proporcion), 0)
		return s
	}

	public get resumenImpuestos() {
		if (!this.esSubcategorizado) return this.tipoIVA?.descripcion || "IVA Sin definir"
		return this.subcategorias.map((s) => s.subcategoriaImponible?.descripcion + " (" + s.tipoIVA?.descripcion + ") " + s.proporcion + "%").join(", ")
	}
	public get keyFamilia() {
		return this.familiaProducto ? `f${this.familiaProducto.id}` : `p${this.producto.id}`
	}

	public get cantidadNoches() {
		let d = moment(this.checkOut).diff(moment(this.checkIn), "hours")

		return Math.ceil(d / 24)
	}
	updateGrupo(encontrado: GrupoPax) {
		this.reservas
			.filter((gr) => gr.grupoResumen.UUID == encontrado.UUID)
			.forEach((gr) => {
				gr.grupoResumen = GrupoPaxResumen.fromData(encontrado)
			})
	}
	public get cantidadPaxsTotal() {
		return this.configuracionPaquete?.length > 0 ? this.configuracionPaquete.reduce((a, b) => a + b.cantidad, 0) : this.cantidadPax
	}

	public get fullProductoDescripcion() {
		return this.producto?.descripcion + (this.variante ? " - " + this.variante.codigo : "")
	}
}

export type ConfiguracionPaqueteItem = {
	producto: Descriptivo
	fechaActividad: Date
	cantidad: number
	precioPerCapita: number
	autoasignar: boolean
	fullDescripcion: string
}
