import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { CategoriaPuesto } from "src/app/model/CategoriaPuesto"
import { MessagesService } from "../common/services/messages-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class CategoriaPuestoService extends ParametricoService<CategoriaPuesto> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "categoria-puesto"
	}
	public parseToEnt(data: any): CategoriaPuesto {
		return CategoriaPuesto.fromData(data)
	}
	public newEnt(): CategoriaPuesto {
		return new CategoriaPuesto()
	}
}
