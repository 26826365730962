<div class="grid disponibilidad-component">
	<div class="col-12">
		<abm-hijo [items]="disponibilidad.horarios" (onAgregar)="disponibilidad.agregarHorario($event)"
			(onQuitar)="disponibilidad.quitarHorario($event)" [newItem]="disponibilidad.newHorario"
			emptyMessage="{{ 'No hay horarios' | translate }}" [itemTemplate]="itemTemplate" [editable]="!readonly"
			[insertOnly]="true" [labelNuevo]="null">
			<ng-template #itemTemplate let-item let-r="index">
				<div class="grid">
					<div [ngClass]="small ? 'col-12' : 'xl:col-6 col-12'">
						<selector-dia [(ngModel)]="item.dias" name="{{ 'dias' + r }}"
							[readonly]="readonly"></selector-dia>
					</div>
					<div [ngClass]="small ? 'col-12' : 'xl:col-6 col-12'">
						<div class="grid">
							<div class="lg:col-3 col-12">
								<div class="p-inputgroup" [ngClass]="{ readonly: readonly }">
									<span class="p-float-label">
										<p-calendar [(ngModel)]="item.horario" [timeOnly]="true" [disabled]="readonly"
											dateFormat="dd/MM/yyyy"> </p-calendar>
										<input pInputText type="text" hidden name="dummy" />

										<label>{{ "HORARIO" | translate }}</label>
									</span>
								</div>
							</div>
							<div class="lg:col-3 col-12">
								<div class="p-inputgroup" [ngClass]="{ readonly: readonly }">
									<span class="p-float-label">
										<p-calendar [(ngModel)]="item.desde" [dateOnly]="true" [disabled]="readonly">
										</p-calendar>
										<input pInputText type="text" hidden name="dummy" />

										<label>{{ "DESDE" | translate }}</label>
									</span>
								</div>
							</div>
							<div class="lg:col-3 col-12">
								<div class="p-inputgroup" [ngClass]="{ readonly: readonly }">
									<span class="p-float-label">
										<p-calendar [(ngModel)]="item.hasta" [dateOnly]="true" [disabled]="readonly">
										</p-calendar>
										<input pInputText type="text" hidden name="dummy" />

										<label>{{ "HASTA" | translate }}</label>
									</span>
								</div>
							</div>
							<div class="lg:col-3 col-12">
								<span class="p-float-label">
									<input pInputText placeholder="{{ 'CUPO' | translate }}"
										[(ngModel)]="item.capacidadMaxima" type="number" #capacidadMaxima="ngModel"
										name="duracion" [readonly]="readonly" />

									<label>{{ "CUPO" | translate }}</label>
								</span>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</abm-hijo>
	</div>
</div>