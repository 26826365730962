<div class="listado-proveedor content">
	<model-list
		[service]="service"
		[modelName]="modelName"
		[columns]="columns"
		[title]="titulo"
		[(listado)]="listado"
		[isLazy]="true"
		[filtro]="filtro"
		[getData]="getData"
		[getCount]="getCount"
		[footer]="footerTemplate"
		[acciones]="acciones"
		[filterContent]="filterTemplate"
		[permiteCargaMasiva]="true"
		[helpPath]="modelName"
	>
		<ng-template #filterTemplate>
			<div class="grid small" *ngIf="filtro">
				<div class="col-12">
					<div class="p-inputgroup">
						<span class="p-float-label" *ngIf="filtro">
							<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
							<label>{{ "BUSCAR" | translate }}...</label>
						</span>
						<button type="button" pButton icon="pi pi-search"></button>
					</div>
				</div>

				<div class="col-12">
					<parametrico-selector
						[service]="tpService"
						[(ngModel)]="filtro.tipoProveedor"
						[limpiable]="true"
						[permiteNuevo]="false"
						name="tipoProveedor"
						[titulo]="'TIPOPROVEEDOR' | translate"
					>
					</parametrico-selector>
				</div>
				<div class="col-12">
					<selector-multiple-chips
						[(ngModel)]="filtro.puestos"
						name="puestos"
						[opciones]="puestos"
						[permiteNuevo]="false"
						[limpiable]="true"
						[titulo]="'PUESTOS' | translate"
					>
					</selector-multiple-chips>
				</div>
				<div class="col-12 checkbox-container">
					<p-toggleButton
						onLabel="{{ 'SOLO_DEUDA' | translate }}"
						tooltipPosition="bottom"
						pTooltip="{{ 'PROVEEDORES_TODOS' | translate }}"
						offLabel="{{ 'VISUALIZANDO_TODOS' | translate }}"
						iconPos="right"
						onIcon="{{ 'fa fa-check-square-o' }} "
						offIcon="{{ 'fa fa-square-o' }} "
						[(ngModel)]="filtro.impagas"
					></p-toggleButton>

					<p-toggleButton
						onLabel="{{ 'VISUALIZANDO_HABILITADOS_DESHABILITADOS' | translate }}"
						class="ml-2"
						tooltipPosition="bottom"
						pTooltip="{{ 'VER_SOLO_HABILITADOS_TODOS' | translate }}"
						offLabel="{{ 'VISUALIZANDO_SOLO_HABILITADOS' | translate }}"
						iconPos="right"
						onIcon="{{ 'fa fa-check-square-o' }} "
						offIcon="{{ 'fa fa-square-o' }} "
						[(ngModel)]="filtro.deshabilitados"
					></p-toggleButton>
				</div>
			</div>
		</ng-template>
		<ng-template #footerTemplate>
			<tr class="totales" [allowed]="'ROLE_PROVEEDOR'">
				<td colspan="6" class="text-right total">
					<b>{{ "TOTAL" | translate }}</b>
				</td>
				<td class="text-right">{{ totales?.totalSaldo | currencyCustom }}</td>
				<td></td>
			</tr>
		</ng-template>
	</model-list>
</div>

<ng-template #datosProveedorTemplate let-data let-col="col">
	<div class="grid p-aling-center grid-nogutter">
		<div class="col-12">
			<span>{{ data?.nombre }}</span>
		</div>
		<div class="col-12" *ngIf="data?.razonSocial">
			<span class="small">
				{{ data?.razonSocial }}
			</span>
		</div>
	</div>
</ng-template>
<ng-template #contactos let-data let-col="col">
	<span class="block w-15rem overflow">
		<contactos [data]="data"></contactos>
	</span>
</ng-template>
