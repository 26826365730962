import { Descriptivo } from "src/app/common/model/Descriptivo"
import { PuntoDeVenta } from "src/app/model/PuntoDeVenta"
import { Comprobante } from "./Comprobante"

export class ConvertirComprobantes {
	constructor(
		public comprobantes: Comprobante[] = [],
		public cotizacionAfip?: number,
		public tipoComprobante?: Descriptivo,
		public moneda?: Descriptivo,
		public cliente?: Descriptivo,
		public fecha?: Date,
		public fechaVencimiento?: Date,
		public idReserva?: number,
		public puntoDeVenta?: PuntoDeVenta,
		public enviarComprobante?: boolean,
		public tipoEmail?: Descriptivo,
		public destinatarios?: string
	) {}
	public static fromData(data: any): ConvertirComprobantes {
		if (!data) return null
		const o: ConvertirComprobantes = new ConvertirComprobantes(
			data.comprobantes ? data.comprobantes.map((c) => Comprobante.fromData(c)) : [],
			data.cotizacionAfip,
			Descriptivo.fromData(data.tipoComprobante),
			Descriptivo.fromData(data.moneda),
			Descriptivo.fromData(data.cliente),
			data.fecha ? new Date(data.fecha) : null,
			data.fechaVencimiento ? new Date(data.fechaVencimiento) : null,
			data.idReserva,
			PuntoDeVenta.fromData(data.puntoDeVenta),
			data.enviarComprobante,
			Descriptivo.fromData(data.tipoEmail),
			data.destinatarios
		)

		return o
	}
}
