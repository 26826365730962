import { Injectable } from "@angular/core"
import { BehaviorSubject, Subscription } from "rxjs"
import { filter } from "rxjs/operators"
import { BaseService } from "src/app/common/services/base.service"
import { ConfiguracionUsuario } from "src/app/model/ConfiguracionUsuario"
import { LoadingService } from "./../common/services/loading-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class ConfiguracionUsuarioService extends BaseService {
	deleteFiltro = async (route: string, nombre: any) => {
		this.filtros[route] = this.filtros[route].filter((f) => f.nombre != nombre)
		return Promise.resolve(true)
	}
	getFiltros = async (route: string) => {
		return this.filtros ? Promise.resolve(this.filtros[route]) : Promise.resolve([])
	}
	public save(customLoading?: LoadingService) {
		if (this.config) {
			if (customLoading) {
				customLoading.addLoadingCount()
			} else this.loadingService.addLoadingCount()
			this.http
				.put(this.getBaseURL(), this.config.json)
				.toPromise()
				.then((c) => {
					this.config = ConfiguracionUsuario.fromData(c)
					this.configObs.next(this.config)
					return this.config
				})
				.finally(() => {
					if (customLoading) {
						customLoading.susLoadingCount()
					} else this.loadingService.susLoadingCount()
				})
		}
	}
	public baseName(): string {
		return "configuracion-usuario"
	}
	public parseToEnt(data: any): ConfiguracionUsuario {
		return ConfiguracionUsuario.fromData(data)
	}
	public newEnt(): ConfiguracionUsuario {
		return new ConfiguracionUsuario()
	}
	public configObs: BehaviorSubject<ConfiguracionUsuario> = new BehaviorSubject(null)
	private sus: Subscription[] = []
	constructor(private authService: AuthService, private nService: NovedadService) {
		super()
	}

	public setThemeConfig(themeConfig: any) {
		this.config.themeConfig = themeConfig
		this.save()
	}
	public getThemeConfig(): Promise<any> {
		return this.get().then((r) => {
			return r.themeConfig || { componentTheme: "iterpax", colorScheme: "-light" }
		})
	}
	public updateThemeConfig(key, value) {
		this.config.themeConfig[key] = value
		this.save()
	}

	public get filtros(): any {
		return this.config.filtros
	}
	public set filtros(v: any) {
		this.config.filtros = v
		this.save()
	}

	public get cols(): any {
		return this.config.cols
	}
	public set cols(v: any) {
		this.config.cols = v
		this.save()
	}

	public init() {
		this.sus.push(
			this.authService
				.getUser()
				.pipe(filter((u) => u != undefined))
				.subscribe((u) => {
					if (this.config != u.configuracion) {
						this.configObs.next(u.configuracion)
						this.config = ConfiguracionUsuario.fromData(u.configuracion)
					}
				})
		)
	}
	private config: ConfiguracionUsuario
	public get(): Promise<ConfiguracionUsuario> {
		return Promise.resolve(this.config)
	}
}
