<p-dialog [(visible)]="verGenerarComprobante" #generarComprobanteDialog [modal]="true" [style]="{ width: '60vw' }"
	header="{{ generador?.reenvio ? 'Reenviar comprobante' : 'Generar comprobante' }}" (visibleChange)="limpiar($event)"
	*ngIf="verGenerarComprobante && generador">
	<form #ComprobanteForm="ngForm" id="ComprobanteForm">
		<div class="grid" *ngIf="generador">
			<div class="col field p-fluid" *ngIf="generador && !generador.reenvio">
				<descriptivo-material-selector [label]="'PUNTO_VENTA' | translate" [limpiable]="false"
					*ngIf="!generador.reenvio" [(ngModel)]="generador.puntoDeVenta" name="punto_de_venta" isLazy="true"
					[service]="puntoDeVentaService" [autohide]="true" [required]="true" [allowDefault]="true"
					class="p-inputwrapper-filled">
				</descriptivo-material-selector>
			</div>
			<div class="col field p-fluid" *ngIf="reserva?.id && !generador.reenvio">
				<span-dinero-editable [ngModel]="generador.totalBase" [editable]="false"
					[label]="'TOTAL_RESERVA' | translate" [ngModelOptions]="{ standalone: true }" [minValue]="0"
					[decimals]="2" *ngIf="generador" [moneda]="reserva.moneda"
					class="p-inputwrapper-filled"></span-dinero-editable>
			</div>
			<div class="col field p-fluid" *ngIf="!generador.reenvio">
				<parametrico-selector [listado]="tiposComprobante" #tipoComprobante="ngModel"
					[(ngModel)]="generador.tipoComprobante" [limpiable]="true" [permiteNuevo]="false"
					name="tipo-comprobante" [required]="true" [titulo]="'TIPOCOMPROBANTE' | translate"
					class="p-inputwrapper-filled">
				</parametrico-selector>
			</div>
			<div class="col field p-fluid"
				*ngIf="generador?.tipoComprobante && !generador?.tipoComprobante?.codigoAfip && generador?.reservas?.length == 1">
				<span class="p-float-label">
					<input [(ngModel)]="generador.numeroComprobante" pInputText name="numeroComprobante"
						placeholder="{{ 'Número de comprobante' | translate }}" class="p-inputwrapper-filled" />
					<label>{{ "Número de comprobante" | translate }}</label>
				</span>
			</div>
			<div class="col-12">
				<div class="grid">
					<div class="col p-fluid" *ngIf="!generador.reenvio && generador">
						<cotizacion [(ngModel)]="generador.cotizacion" [decimals]="6" [monedaRef]="generador?.monedaRef"
							name="cotizacion" (onMonedaChange)="onMonedaChange($event)" class="p-inputwrapper-filled">
						</cotizacion>
					</div>

					<div class="col p-fluid"
						*ngIf="esArgentina && !generador.reenvio && generador.moneda?.codigo != configuracionEntorno?.moneda?.codigo">
						<span-dinero-editable [(ngModel)]="generador.cotizacionAfip" [editable]="!readonly" label="{{
								!generador?.moneda ? ('COTIZACION_AFIP' | translate) : generador?.moneda?.codigo + ' ► ' + configuracionEntorno?.moneda?.codigo
							}}" name="cotizacionAfip" [minValue]="0" [decimals]="6" class="p-inputwrapper-filled">
						</span-dinero-editable>
					</div>
				</div>
			</div>
			<div class="col-12" *ngIf="generador?.esAdelanto && verGenerarComprobante">
				<!-- <div class="grid">
					<div class="col-12 lg:col-5">
						<span-dinero-editable
							[(ngModel)]="generador.importe"
							[editable]="!readonly"
							[moneda]="generador.moneda"
							[label]="'Importe del adelanto' | translate"
							name="importe"
							[minValue]="0"
							[decimals]="2"
							class="p-inputwrapper-filled"
						>
						</span-dinero-editable>
					</div>
					<div class="col-6 lg:col-3">
						<parametrico-selector
							[service]="tipoIvaGrabadoService"
							#marca="ngModel"
							[readonly]="readonly"
							[(ngModel)]="generador.tipoIva"
							name="tipoIva"
							[titulo]="'TIPO_DE_IVA' | translate"
							required
							class="p-inputwrapper-filled"
						>
						</parametrico-selector>
					</div>
				</div> -->

				<p-table [value]="generador.itemsAdelanto" [(selection)]="itemsAdelantoSeleccionados" dataKey="id"
					[selectionMode]="'multiple'">
					<ng-template pTemplate="header">
						<tr>
							<th colspan="5"><span class="text-right">Imputar cobro</span></th>
							<th colspan="2">
								<div class="imputacion grid align-content-center">
									<div class="col no-space-mat-form">
										<span-dinero-editable [label]="'IMPORTE_A_IMPUTAR' | translate"
											[readonly]="readonly" [(ngModel)]="importeImputar" [editable]="!readonly"
											[moneda]="item.moneda" name="importeImputar">
										</span-dinero-editable>
									</div>
									<div class="col-fixed" style="width: 6em">
										<button pButton label="{{ 'IMPUTAR' | translate }}" (click)="imputar()"
											[disabled]="!importeImputar || !itemCobranzaOptions?.length"></button>
									</div>
								</div>
							</th>
						</tr>
						<tr>
							<th style="width: 25px; text-overflow: unset">
								<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
							</th>
							<th style="width: 8%">{{ "PRODUCTO" | translate }}</th>
							<th style="width: 13%">{{ "IMPORTE" | translate }}</th>
							<th style="width: 13%">{{ "SALDO" | translate }}</th>
							<th style="width: 15%">{{ "A_PAGAR" | translate }}</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-it let-i="rowIndex">
						<tr [pSelectableRow]="it">
							<td *ngIf="editable">
								<p-tableCheckbox [value]="it"></p-tableCheckbox>
							</td>
							<td>
								<span>{{ it.producto }}</span>
							</td>

							<td class="text-right">
								<span>{{ it?.importe | currencyCustom : it?.moneda }}</span>
							</td>
							<td class="text-right">
								<div class="col-12">
									<span>{{ it?.saldo | currencyCustom : generador?.moneda }}</span>
								</div>
							</td>

							<td class="text-right">
								<span-dinero-editable [label]="'IMPORTE' | translate" [readonly]="readonly"
									[required]="true" [editable]="editable" [(ngModel)]="it.importe"
									name="importe_{{ i }}" [moneda]="generador?.moneda">
								</span-dinero-editable>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>

			<div class="d-flex align-items-center col-12 field p-fluid"
				*ngIf="!generador?.reenvio && generador?.reservas?.length <= 1">
				<p-checkbox [binary]="true" [(ngModel)]="generador.datosFacturacionDistintos"
					name="datosFacturacionDistintos" [label]="'DATOS_FACTURACION_DISTINTOS' | translate"></p-checkbox>
			</div>

			<ng-container *ngIf="generador.datosFacturacionDistintos">
				<div class="col-12 lg:col-3 field p-fluid">
					<parametrico-selector [readonly]="readonly" [tipoParametro]="'tipocliente'"
						#tipoClienteFacturacion="ngModel" [(ngModel)]="generador.tipoClienteFacturacion"
						name="tipoClienteFacturacion" [titulo]="'TIPOCLIENTE' | translate" (onSelect)="updateCuit()"
						required class="p-inputwrapper-filled">
					</parametrico-selector>
				</div>
				<div class="col-12 lg:col-2 field p-fluid">
					<parametrico-selector [readonly]="readonly" #nacionalidadFacturacion="ngModel" [static]="true"
						[(ngModel)]="generador.nacionalidadFacturacion" [service]="nacionalidadService"
						[limpiable]="false" [permiteNuevo]="true" name="nacionalidadFacturacion"
						[titulo]="'NACIONALIDAD' | translate" (onSelect)="updateCuit()" required
						class="p-inputwrapper-filled">
					</parametrico-selector>
				</div>
				<div class="col-6 lg:col-3 field p-fluid">
					<span class="p-float-label">
						<input pInputText [readonly]="readonly" placeholder="{{ 'NOMBRE' | translate }}"
							#nombreFacturacion="ngModel" name="nombreFacturacion"
							[(ngModel)]="generador.nombreFacturacion" tooltipPosition="bottom"
							pTooltip="{{ 'NOMBRE_TOOLTIP' | translate }}" type="text" [readonly]="readonly" required
							class="p-inputwrapper-filled" />
						<label>{{ "NOMBRE" | translate }}</label>
					</span>
				</div>
				<div class="col-6 lg:col-4 field p-fluid">
					<parametrico-selector [readonly]="readonly" [service]="tipoIvaService" [limpiable]="true"
						#ivaFacturacion="ngModel" [(ngModel)]="generador.ivaFacturacion" name="ivaFacturacion"
						[titulo]="'IVA' | translate" [disabled]="esExtranjero" class="p-inputwrapper-filled">
					</parametrico-selector>
				</div>
				<div class="col-6 lg:col-3 field p-fluid">
					<parametrico-selector [tipoParametro]="'tipoDocumento'" #tipoDocumentoFacturacion="ngModel"
						[(ngModel)]="generador.tipoDocumentoFacturacion" [permiteNuevo]="false"
						name="tipoDocumentoFacturacion" [titulo]="'TIPODOCUMENTO' | translate" required
						[readonly]="readonly || esExtranjero" class="p-inputwrapper-filled">
					</parametrico-selector>
				</div>

				<div class="col-6 lg:col-3 field p-fluid" *ngIf="generador?.tipoDocumentoFacturacion?.codigo == '80'">
					<span class="p-float-label">
						<input pInputText [readonly]="readonly" #numeroDocumentoFacturacion="ngModel" cuitValidator
							[(ngModel)]="generador.numeroDocumentoFacturacion" tooltipPosition="bottom"
							pTooltip="{{ 'NUMERODOCUMENTO_TOOLTIP' | translate }}" type="text" [disabled]="esExtranjero"
							name="numeroDocumentoFacturacion" class="p-inputwrapper-filled" />
						<label>{{ "CUIT" | translate }}</label>
					</span>

					<div class="link cursor-pointer"
						*ngIf="esExtranjero && generador?.nacionalidad && !generador?.numeroDocumento"
						(click)="editarNacionalidad(generador.nacionalidad); gestor = gestorNacionalidad">
						<p-message class="link" severity="error"
							text="Falta cuit para la nacionalidad elegida. Revíselo aquí"></p-message>
					</div>
				</div>
				<div class="col-6 lg:col-3 field p-fluid" *ngIf="generador?.tipoDocumentoFacturacion?.codigo != '80'">
					<span class="p-float-label">
						<input pInputText [readonly]="readonly" #numeroDocumentoFacturacion="ngModel"
							[(ngModel)]="generador.numeroDocumentoFacturacion" tooltipPosition="bottom"
							pTooltip="{{ 'NUMERODOCUMENTO_TOOLTIP' | translate }}" type="text" [disabled]="esExtranjero"
							name="numeroDocumentoFacturacion" class="p-inputwrapper-filled" />
						<label>{{ "Número de Documento" | translate }}</label>
					</span>

					<div class="link cursor-pointer"
						(click)="editarNacionalidad(generador.nacionalidad); gestor = gestorNacionalidad"
						*ngIf="esExtranjero && generador?.nacionalidad && !generador?.numeroDocumento">
						<p-message class="link" severity="error"
							text="Falta cuit para la nacionalidad elegida. Revíselo aquí"></p-message>
					</div>
				</div>

				<div class="col-12 lg:col-6 field p-fluid">
					<span class="p-float-label">
						<input pInputText [readonly]="readonly" placeholder="{{ 'DOMICILIO_PH' | translate }}"
							#domicilio="ngModel" name="domicilioFacturacion"
							[(ngModel)]="generador.domicilioFacturacion" tooltipPosition="bottom"
							pTooltip="{{ 'DOMICILIO_TOOLTIP' | translate }}" type="text"
							class="p-inputwrapper-filled" />
						<label>{{ "DOMICILIO" | translate }}</label>
					</span>
				</div>
			</ng-container>

			<div class="col-12" *ngIf="itemsCobranzas.length">
				<div class="col-12 subheader">
					<div class="titulo-cobros">Cobros realizados</div>
				</div>
				<div class="col-12 text-center" *ngIf="!itemsCobranzas?.length">
					<span>{{ "No se encontraron cobranzas" | translate }}</span>
				</div>
				<div class="col-12">
					<p-table [value]="itemsCobranzas">
						<ng-template pTemplate="header">
							<tr>
								<th>{{ "FECHA" | translate }}</th>
								<th>{{ "COMPROBANTE" | translate }}</th>
								<th>{{ "IMPORTE" | translate }}</th>
								<th>{{ "IMPORTE_LOCAL" | translate }}</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-cobranza>
							<tr>
								<td>{{ cobranza.fecha | date : "dd/MM/yyyy" }}</td>
								<td>{{ cobranza.descripcionLarga }}</td>
								<td>{{ cobranza.comprobante?.importeSinRecargos | currencyCustom :
									cobranza.comprobante?.moneda }}</td>
								<td>
									{{
									cobranza.montoTransformado(generador.cotizacionOperacion,
									generador.getMonedaOperacion())
									| currencyCustom : generador.getMonedaOperacion()
									}}
								</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
				<!--div class="col-12 field p-fluid" *ngIf="reserva?.id && !generador.reenvio && saldoActualizado != generador.total">
					<span-dinero-editable
						[ngModel]="saldoActualizado"
						[editable]="false"
						[label]="'SALDO_ACTUALIZADO' | translate"
						name="saldoActualizado"
						[minValue]="0"
						[decimals]="2"
						[moneda]="reserva.moneda"
						class="p-inputwrapper-filled"
					></span-dinero-editable>
				</div-->
			</div>

			<ng-container *ngIf="generador?.tipoComprobante?.codigo == 'DE'">
				<ng-container *ngTemplateOutlet="adjunto"></ng-container>
			</ng-container>
			<div class="col-12 field p-fluid" *ngIf="generador?.reservas?.length == 1 && !generador.reenvio">
				<span class="p-float-label">
					<abm-cobros [mostrarTotales]="true" [totalACobrar]="saldoActualizadoALocal" [item]="generador"
						[admiteGastos]="true" [readonly]="readonly" [unico]="!generador?.unicoCliente"
						[monedaRef]="monedaCobranza" class="p-inputwrapper-filled"
						[proveedorAsociado]="proveedor?.id"></abm-cobros>
					<label>{{ "COBROS" | translate }}</label>
				</span>
			</div>

			<div class="col-12 lg:col-3 align-content-center field p-fluid" *ngIf="!generador?.reenvio">
				<p-checkbox [(ngModel)]="generador.enviarComprobante" binary="true" name="enviarComprobante"
					tooltipPosition="bottom" pTooltip="{{ 'ENVIAR_COMPROBANTE__8' | translate }}"
					class="p-inputwrapper-filled" label="{{ 'ENVIAR_COMPROBANTE__8' | translate }}">
				</p-checkbox>
			</div>
			<ng-container *ngIf="generador.enviarComprobante || generador.reenvio">
				<div class="col-12">
					<div class="grid">
						<div class="col field p-fluid" *ngIf="generador?.reservas?.length > 1">
							<parametrico-selector [listado]="emailOpciones" #emailOpcion="ngModel"
								[(ngModel)]="generador.tipoEmail" [limpiable]="true" [permiteNuevo]="false"
								name="tipoEmail" [titulo]="'TIPO_DE_EMAIL' | translate"
								(onSelect)="tipoEmalChange($event)" class="p-inputwrapper-filled">
							</parametrico-selector>
						</div>
						<div class="col field p-fluid"
							*ngIf="generador?.reservas?.length == 1 || generador?.tipoEmail?.codigo == 'P'">
							<selector-email [(ngModel)]="generador.destinatarios" name="d"
								#d="ngModel"></selector-email>
						</div>
					</div>
				</div>
			</ng-container>
			<div class="col-12 lg:col-3 align-content-center field p-fluid" *ngIf="generador?.integrable">
				<p-checkbox [(ngModel)]="generador.integrar" binary="true" name="generador?.integrar"
					tooltipPosition="bottom" pTooltip="{{ 'INTEGRATE_TOOLTIP' | translate }}"
					class="p-inputwrapper-filled" label="{{ 'INTEGRATE' | translate }}">
				</p-checkbox>
			</div>
		</div>
	</form>
	<p-footer>
		<button pButton (click)="cancelarComprobante()" label="{{ 'CANCELAR' | translate }}"></button>
		<button pButton cclass="p-btn-success"
			(click)="generador?.reenvio ? reenviarComprobante() : generarComprobante()"
			[disabled]="ComprobanteForm.invalid" label="{{ 'CONFIRMAR' | translate }}"></button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="verEnvioItinerario" [modal]="true" [style]="{ width: '60vw' }"
	header="{{ 'Enviar Itinerario' | translate }}">
	<form #MailForm="ngForm" id="MailForm">
		<div class="grid" *ngIf="envioItinerario">
			<div class="col-12" *ngIf="tn?.tieneMail">
				<selector-email [(ngModel)]="envioItinerario.destinatario" name="d" #d="ngModel"></selector-email>
			</div>
			<div class="col-12" *ngIf="tn?.tieneWhatsapp">
				<span class="p-float-label">
					<input [(ngModel)]="envioItinerario.telefono" pInputText name="telefono" #telefono="ngModel"
						placeholder="{{ 'TELEFONO' | translate }}" class="p-inputwrapper-filled" />
					<label>{{ "TELEFONO" | translate }}</label>
				</span>
			</div>
		</div>
	</form>

	<p-footer>
		<button pButton (click)="verEnvioItinerario = false" label="{{ 'CANCELAR' | translate }}"></button>
		<button pButton cclass="p-btn-success" (click)="confirmarEnvioItinerario()" [disabled]="MailForm.invalid"
			label="{{ 'ENVIAR' | translate }}"></button>
	</p-footer>
</p-dialog>
<cancelacion-reserva (onCancelar)="reserva = null" [pregunta]="'¿Desea cancelar la reserva ' + reserva?.id + '?'"
	(onRerservaCancelada)="onCancelacionReserva($event)"></cancelacion-reserva>

<p-dialog [(visible)]="verConsentimiento" [modal]="true" [style]="{ width: '60vw' }"
	header="{{ 'ENVIAR_CONSENTIMIEN_1' | translate }}">
	<form #MailForm="ngForm" id="MailForm">
		<div class="p-fluid grid" *ngIf="verConsentimiento">
			<div class="field p-fluid col-12 lg:col-6" *ngFor="let canal of tn?.canales">
				<span class="p-float-label" *ngIf="canal.tipo == 'E'">
					<selector-email [(ngModel)]="itemConsentimiento.emailConsentimiento" name="d"
						#d="ngModel"></selector-email>
				</span>

				<span class="p-float-label" *ngIf="canal.tipo == 'W'">
					<p-inputNumber *ngIf="canal.tipo == 'W'" [(ngModel)]="itemConsentimiento.telefonoConsentimiento"
						mode="decimal" name="destinatario_wpp" placeholder="{{ 'Destinatario' | translate }}"
						[useGrouping]="false" id="wpp" class="p-inputwrapper-filled">
					</p-inputNumber>
					<label for="wpp">{{ "WHATSAPP_DESTINATARIO" | translate }}</label>
				</span>
			</div>
		</div>
	</form>
	<p-footer>
		<button pButton (click)="verConsentimiento = false" label="{{ 'CANCELAR' | translate }}"></button>
		<button pButton cclass="p-btn-success" (click)="enviarConsentimiento(itemConsentimiento)"
			[disabled]="MailForm.invalid" label="{{ 'ENVIAR' | translate }}"></button>
	</p-footer>
</p-dialog>

<ng-template #adjunto>
	<div class="grid">
		<div class="col-12">
			<p-fileUpload name="files" [url]="uploadUrl" mode="basic" multiple="false" #fileInput [disabled]="readonly"
				(onError)="onUploadError($event)" (onUpload)="uploadEnd($event, item)" accept="application/pdf"
				chooseLabel="{{ 'SUBIR_ARCHIVO' | translate }}" maxFileSize="1000000" [auto]="true"
				invalidFileSizeMessageDetail="{{ 'EL_TAMAÑO_MÁXIMO_ES' | translate }}"
				invalidFileSizeMessageSummary="{{ 'TAMAÑO_EXCEDIDO' | translate }} "
				invalidFileTypeMessageSummary="{{ 'TIPO_DE_ARCHIVO_INV_4' | translate }}"
				invalidFileTypeMessageDetail="{{ 'LOS_TIPOS_ACEPTADOS_3' | translate }}"
				invalidFileLimitMessageDetail="{{ 'EL_LIMITE_DE_ARCHIV_11' | translate }} "
				invalidFileLimitMessageSummary="{{ 'LIMITE_DE_ARVHIVOS__7' | translate }}">
			</p-fileUpload>
		</div>
		<div class="col-12 file-wrapper">
			<div class="card col-12" (click)="open(generador?.adjunto)" *ngIf="generador?.adjunto">
				<div class="grid">
					<div class="col-12 lg:col-10 flex align-items-center">
						<span>{{ generador.adjunto.descripcion }}</span>
					</div>
					<div class="col-12 lg:col-2 acciones" *ngIf="!readonly">
						<button pButton (click)="generador.adjunto = null" icon="fa fa-times"></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<p-dialog [(visible)]="mostrarConvertirAOficial" [modal]="true" [style]="{ width: '60vw' }"
	header="{{ 'Generar comprobante' }}">
	<form #ComprobanteForm="ngForm" id="ComprobanteForm">
		<div class="grid" *ngIf="convertirComprobante">
			<div class="col field p-fluid">
				<descriptivo-material-selector [label]="'PUNTO_VENTA' | translate" [limpiable]="false"
					[(ngModel)]="convertirComprobante.puntoDeVenta" name="punto_de_venta" isLazy="true"
					[service]="puntoDeVentaService" [autohide]="true" [required]="true" [allowDefault]="true"
					class="p-inputwrapper-filled">
				</descriptivo-material-selector>
			</div>

			<div class="col field p-fluid">
				<parametrico-selector [service]="tipoComprobanteService" #tipoComprobante="ngModel"
					[(ngModel)]="convertirComprobante.tipoComprobante" [limpiable]="true" [permiteNuevo]="false"
					name="tipo-comprobante" [required]="true" [titulo]="'TIPOCOMPROBANTE' | translate"
					class="p-inputwrapper-filled">
				</parametrico-selector>
			</div>

			<div class="col-12">
				<div class="grid">
					<div class="col p-fluid" *ngIf="esArgentina">
						<span-dinero-editable [(ngModel)]="convertirComprobante.cotizacionAfip" [editable]="!readonly"
							[label]="'Cotización Afip' | translate" name="cotizacionAfip" [minValue]="0" [decimals]="6"
							class="p-inputwrapper-filled">
						</span-dinero-editable>
					</div>
				</div>
			</div>
			<div class="col-12 align-content-center field p-fluid">
				<p-checkbox [(ngModel)]="convertirComprobante.enviarComprobante" binary="true" name="enviarComprobante"
					tooltipPosition="bottom" pTooltip="{{ 'ENVIAR_COMPROBANTE__8' | translate }}"
					class="p-inputwrapper-filled" label="{{ 'ENVIAR_COMPROBANTE__8' | translate }}">
				</p-checkbox>
			</div>
			<ng-container *ngIf="convertirComprobante.enviarComprobante">
				<div class="col-12">
					<div class="grid">
						<div class="col field p-fluid">
							<parametrico-selector [listado]="emailOpciones" #emailOpcion="ngModel"
								[(ngModel)]="convertirComprobante.tipoEmail" [limpiable]="true" [permiteNuevo]="false"
								name="tipoEmail" [titulo]="'TIPO_DE_EMAIL' | translate"
								(onSelect)="tipoEmalChange($event, convertirComprobante)" class="p-inputwrapper-filled">
							</parametrico-selector>
						</div>
						<div class="col field p-fluid" *ngIf="convertirComprobante?.tipoEmail?.codigo == 'P'">
							<selector-email [(ngModel)]="convertirComprobante.destinatarios" name="d"
								#d="ngModel"></selector-email>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</form>
	<p-footer>
		<!-- <button pButton (click)="cancelarComprobante()" label="{{ 'CANCELAR' | translate }}"></button> -->
		<button pButton cclass="p-btn-success" (click)="convertir()" [disabled]="ComprobanteForm.invalid"
			label="{{ 'CONFIRMAR' | translate }}"></button>
	</p-footer>
</p-dialog>