import { ProveedorCabecera } from './ProveedorCabecera';

import { GrupoReserva} from './GrupoReserva';
import { PuestoServicioReserva} from './PuestoServicioReserva';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { ContenedorPuesto } from '../common-components/puesto-badge/ContenedorPuesto';
import { GastoPuesto } from './GastoPuesto';
import { Proveedor } from './Proveedor';
import { PuestoInsumo } from './PuestoInsumo';
import { TipoPuesto } from './TipoPuesto';


export class ConfirmacionReservaPuesto  implements ContenedorPuesto {
    constructor(
		public id ?: number, 
		public estado ?: Descriptivo, 
		public motivoRechazo ?: string, 
		public notificado ?: boolean, 
		public fecha?: Date,
		public cantidadPax?: number,
		public modificado?: boolean,
		public modificacion: any = {},
		public observaciones?: string,
		public tipoPuesto?:TipoPuesto,
		public proveedor?:ProveedorCabecera,
		public keyPuesto?:string,
		public refPuestoServicioReserva?:number,
		public version?: number,
    ) {
    }
	
    public static fromData(data: any): ConfirmacionReservaPuesto {
        if (!data) return null;
        const o: ConfirmacionReservaPuesto = new ConfirmacionReservaPuesto(
			data.id, 
			Descriptivo.fromData(data.estado), 
			data.motivoRechazo, 
			data.notificado, 
			data.fecha? new Date(data.fecha) : null,
			data.cantidadPax,
			data.modificado,
			data.modificacion || {},
			data.observaciones,
			TipoPuesto.fromData(data.tipoPuesto),
			ProveedorCabecera.fromData(data.proveedor),
			data.keyPuesto,
			data.refPuestoServicioReserva

        );

        o.version = data.version; 
		return o;

    }
	clonar() {
		let c = ConfirmacionReservaPuesto.fromData(this)
		c.id = null;
		return c;
	}
	

}