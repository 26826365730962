import { Vehiculo } from 'src/app/model/Vehiculo';
import { AsociacionInsumo } from './AsociacionInsumo';
import { InsumoContainer } from './InsumoContainer';
import { RestriccionVehiculo } from './RestriccionVehiculo';
import { TipoVehiculo } from './TipoVehiculo';
import { VehiculoInsumo } from './VehiculoInsumo';



export class VehiculoRequerido implements InsumoContainer {
	getRestriccion(): RestriccionVehiculo {

		var r: RestriccionVehiculo = new RestriccionVehiculo(this.es4x4, this.esPrincipal, this.capacidadMinima, null, this.tipoVehiculo);
		return r;

	}
	constructor(
		public id?: number,
		public es4x4?: boolean,
		public capacidadMinima: number = 0,
		public tipoVehiculo?: TipoVehiculo,
		public insumos: VehiculoInsumo[] = [],
		public esPrincipal: boolean = false

	) {
	}
	quitarInsumo(insumo: AsociacionInsumo) {
		this.insumos = this.insumos.filter(i => i != insumo);
	}
	public static fromData(data: any): VehiculoRequerido {
		if (!data) return null;
		const o: VehiculoRequerido = new VehiculoRequerido(

			data.id,
			data.es4x4,
			data.capacidadMinima || 0, 
			TipoVehiculo.fromData(data.tipoVehiculo),
			data.insumos.map(c => VehiculoInsumo.fromData(c)),
			data.esPrincipal
		);

return o;

	}

	public cumple(vehiculo: Vehiculo) {
		return vehiculo ? vehiculo.cumple(this) : false;
	}




}