<div class="user content">
	<model-list
		[service]="usuarioService"
		[modelName]="'usuario'"
		[columns]="columns"
		[title]="'USUARIOS' | translate"
		[(listado)]="listado"
		[isLazy]="true"
		[filtro]="filtro"
		[conResumen]="false"
		[filterContent]="filterContent"
		[helpPath]="lenguaje + '/usuario'"
	>
		<ng-template #filterContent>
			<div class="grid">
				<div class="col-12">
					<div class="p-inputgroup">
						<span class="p-float-label">
							<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
							<label>{{ "BUSCAR" | translate }}...</label>
						</span>
						<button type="button" pButton icon="pi pi-search"></button>
					</div>
				</div>
				<div class="col-12 checkbox-container">
					<p-toggleButton
						onLabel="{{ 'Ver solo activos' | translate }}"
						tooltipPosition="bottom"
						pTooltip="{{ 'Ver todos' | translate }}"
						offLabel="{{ 'Visualizando Todos' | translate }}"
						iconPos="right"
						onIcon="{{ 'fa fa-check-square-o' }} "
						offIcon="{{ 'fa fa-square-o' }} "
						[(ngModel)]="filtro.soloActivos"
					></p-toggleButton>
				</div>
			</div>
		</ng-template>
	</model-list>
</div>
