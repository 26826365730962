import { Descriptivo } from "src/app/common/model/Descriptivo"
import { FormaPago } from "src/app/model/FormaPago"
import { Moneda } from "src/app/model/Moneda"
import { FileData } from "../FileData"
import { CobroConCaja } from "./CobroConCaja"

export class CobroMercadoPago extends CobroConCaja {
	public static TIPO = "M"

	constructor(
		id?: number,
		formaPago?: FormaPago,
		comprobante?: string,
		monto?: number,
		caja?: Descriptivo,
		moneda?: Moneda,
		cotizacion: number = 1,
		public idPago?: string,
		adjunto?: FileData
	) {
		super(id, formaPago, comprobante, monto, caja, moneda, cotizacion, adjunto)
		this.tipoCobro = CobroMercadoPago.TIPO
	}

	public static fromData(data: any): CobroMercadoPago {
		if (!data) return null
		const o: CobroMercadoPago = new CobroMercadoPago(
			data.id,
			FormaPago.fromData(data.formaPago),
			data.comprobante,
			data.monto,
			Descriptivo.fromData(data.caja),
			Moneda.fromData(data.moneda),
			data.cotizacion,
			data.idPago,
			FileData.fromData(data.adjunto)
		)

		return o
	}
}
