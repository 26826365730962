import { Component, Input } from '@angular/core'
import { Location } from '@angular/common'
import { HelpService } from '../../../services/help.service';

@Component({
    selector: 'model-help',
    templateUrl: './model-help.component.html',
    styleUrls: ['./model-help.component.less'],
})
export class ModelHelpComponent {
    @Input()
    public title: string = ''

    constructor(private _location: Location, public helpService: HelpService) {}

    public volver() {
        this._location.back()
    }

}
