import * as moment from 'moment';
import { Descriptivo } from './../common/model/Descriptivo';


export class BloqueoVehiculo {
	aplica(fechaDesde: Date, fechaHasta?: Date, vehiculo?: Descriptivo): boolean {
		return this.aplicaFecha(fechaDesde, fechaHasta) && (!vehiculo || vehiculo.id == this.vehiculo.id);
	}
	aplicaFecha(fechaDesde, fechaHasta) {
		if (!fechaHasta) {
			return moment(fechaDesde).isBetween(this.fechaDesde, this.fechaHasta, "date", "[]")
		} else {
			return moment(fechaDesde).isAfter(this.fechaHasta) && moment(fechaHasta).isAfter(this.fechaDesde);
		}
	}
	public vehiculo: Descriptivo;
	constructor(
		public id?: number,
		public fechaDesde?: Date,
		public fechaHasta?: Date,
		public motivo?: string,
		public responsable?: Descriptivo,
		public version?: number,


	) {
	}
	public static fromData(data: any): BloqueoVehiculo {
		if (!data) return null;
		const o: BloqueoVehiculo = new BloqueoVehiculo(
			data.id,
			data.fechaDesde ? new Date(data.fechaDesde) : null,
			data.fechaHasta ? new Date(data.fechaHasta) : null,
			data.motivo,
			Descriptivo.fromData(data.responsable),

		);
		o.vehiculo = Descriptivo.fromData(data.vehiculo)
		o.version = data.version; 
		return o;

	}

}