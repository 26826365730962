import { Pipe, PipeTransform } from '@angular/core';
import { IConCategoria } from './../model/IConCategoria';

@Pipe({
  name: 'puestoByTipo'
})
export class PuestoByTipoPipe implements PipeTransform {

  transform(puestos: IConCategoria[], tipo: string): any {
    return puestos ? puestos.filter(p => !p.getCategoria() || p.getCategoria() == tipo) : [];
  }

}
