<div class="estadisticas content">
	<div class="model-list">
		<div class="top-section grid">
			<div class="col-12 model-title custom">
				<div class="grid">
					<div class="col-8">
						<h2 autofocus>{{ "Reporte de actividades" | translate }}</h2>
					</div>
				</div>
			</div>

			<div class="col-12 model-filters">
				<filtro
					[(filter)]="filtro"
					[isFixed]="false"
					[isMinimized]="true"
					[useSidebar]="true"
					[filterTemplateContent]="filterTemplateContent"
					[table]="table"
				>
					<ng-template #filterTemplateContent>
						<div class="grid p-fluid">
							<div class="col-12">
								<div class="p-inputgroup">
									<span class="p-float-label">
										<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
										<label>{{ "BUSCAR" | translate }}...</label>
									</span>
									<button type="button" pButton icon="pi pi-search"></button>
								</div>
							</div>
							<div class="col-12">
								<selector-multiple-chips
									[(ngModel)]="filtro.productos"
									name="productos"
									[opciones]="productos"
									[permiteNuevo]="false"
									[limpiable]="true"
									[titulo]="'PRODUCTOS' | translate"
								>
								</selector-multiple-chips>
							</div>
							<div class="col-6">
								<span class="p-float-label">
									<p-calendar
										[(ngModel)]="filtro.fechaDesde"
										dateFormat="dd/mm/yy"
										appendTo="body"
										name="fechaDesde"
										[showIcon]="true"
										tooltipPosition="bottom"
										pTooltip="{{ 'FECHA_DESDE' | translate }}"
									></p-calendar>
									<label>{{ "FECHA_DESDE" | translate }}</label>
								</span>
							</div>
							<div class="col-6">
								<span class="p-float-label">
									<p-calendar
										[(ngModel)]="filtro.fechaHasta"
										dateFormat="dd/mm/yy"
										appendTo="body"
										name="fechaHasta"
										[showIcon]="true"
										tooltipPosition="bottom"
										pTooltip="{{ 'HASTA' | translate }}"
									></p-calendar>
									<label>{{ "HASTA" | translate }}</label>
								</span>
							</div>
						</div>
					</ng-template>
				</filtro>
			</div>
		</div>
		<div class="bottom-section">
			<p-tabView orientation="top" [styleClass]="'ranking-tabs'">
				<p-tabPanel header="{{ 'TARJETAS' | translate }}">
					<div class="grid" *ngIf="totalesActividad?.length">
						<div class="col-3" *ngFor="let item of totalesActividad | byProductoDescripcion : filtro.searchStr">
							<reporte-actividades-card [item]="item" (onEliminar)="eliminar($event)"></reporte-actividades-card>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="{{ 'TABLA' | translate }}">
					<p-table #table [value]="totalesActividad" [columns]="columns" [paginator]="true" [rows]="20" [rowsPerPageOptions]="[5, 10, 20, 50, 100]">
						<ng-template pTemplate="caption">
							<div class="grid">
								<div class="col-6">
									<div class="p-helper-clearfix" style="text-align: left">
										<button
											type="button"
											pButton
											icon="pi pi-file-o"
											iconPos="left"
											label="CSV"
											(click)="table.exportCSV()"
											style="margin-right: 0.5em"
										></button>
									</div>
								</div>
								<div class="col-6" hidden>
									<div class="buscador">
										<input
											pInputText
											type="text"
											(input)="table.filterGlobal($event.target.value, 'contains')"
											placeholder="Búsqueda Global"
										/>
									</div>
								</div>
							</div>
						</ng-template>
						<ng-template pTemplate="header" let-columns>
							<tr>
								<th [tooltipPosition]="'bottom'" [pTooltip]="'PRODUCTO' | translate" style="width: 20%">{{ "PRODUCTO" | translate }}</th>
								<th class="text-center" [tooltipPosition]="'bottom'" [pTooltip]="'TOTAL_PAX' | translate" style="width: 20%">
									{{ "TOTAL_PAX" | translate }}
								</th>
								<th class="text-center" [tooltipPosition]="'bottom'" [pTooltip]="'PROMEDIO_PAX' | translate" style="width: 20%">
									{{ "PROMEDIO_PAX" | translate }}
								</th>
								<th class="text-center" [tooltipPosition]="'bottom'" [pTooltip]="'TOTAL_VEHICULOS' | translate" style="width: 20%">
									{{ "TOTAL_VEHICULOS" | translate }}
								</th>
								<th class="text-center" [tooltipPosition]="'bottom'" [pTooltip]="'PROMEDIO_VEHICULOS' | translate" style="width: 20%">
									{{ "PROMEDIO_VEHICULOS" | translate }}
								</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-rowData let-columns="columns">
							<tr>
								<td [tooltipPosition]="'bottom'" [pTooltip]="rowData?.producto?.descripcion">
									<span class="clickable" (click)="goToProducto($event, rowData?.producto?.id)">{{ rowData?.producto?.descripcion }}</span>
								</td>
								<td class="text-center" [tooltipPosition]="'bottom'" [pTooltip]="rowData?.cantidadPax">{{ rowData.cantidadPax }}</td>
								<td class="text-center" [tooltipPosition]="'bottom'" [pTooltip]="rowData?.promedioPax">
									{{ rowData.promedioPax | number : ".0-2" }}
								</td>
								<td class="text-center" [tooltipPosition]="'bottom'" [pTooltip]="rowData?.cantidadVehiculos">
									{{ rowData.cantidadVehiculos }}
								</td>
								<td class="text-center" [tooltipPosition]="'bottom'" [pTooltip]="rowData?.promedioVehiculos">
									{{ rowData.promedioVehiculos | number : ".0-2" }}
								</td>
							</tr>
						</ng-template>
					</p-table>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>
</div>
