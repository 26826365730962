import { lastValueFrom } from 'rxjs'
import { ServicioAbstract } from 'src/app/common/services/service.service'
import { Descriptivo } from './../common/model/Descriptivo'
import { FiltroConsentimiento } from 'src/app/model/FiltroConsentimiento'
import { Injectable } from '@angular/core'
import { Reserva } from 'src/app/model/Reserva'
import { ResumenReserva } from 'src/app/model/ResumenReserva'
import { LoadingService } from '../common/services/loading-data-service.service'
import { Consentimiento } from '../model/Consentimiento'
import { ReservaConsentimiento } from '../model/ReservaConsentimiento'
import { BufferedService } from './BufferedService'
import { ConsentimientoReporte } from '../model/ConsentimientoReporte'

@Injectable({
    providedIn: 'root',
})
export class ConsentimientoService extends ServicioAbstract<Consentimiento> {
    public baseName(): string {
        return 'consentimiento'
    }
    public parseToEnt(data: any): Consentimiento {
        return Consentimiento.fromData(data)
    }
    public newEnt(): Consentimiento {
        return new Consentimiento()
    }

    validarToken(token: string, reservaId: string, codigoIdioma: string = 'EN', customLoading?: LoadingService): Promise<ReservaConsentimiento> {
        let $this = this
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.post(this.getBaseURL() + 'public/validar-token', { reservaId, token, codigoIdioma }))
            .then((r) => {
                return ReservaConsentimiento.fromData(r)
            })
            .catch((e) => this.handleError(e, customLoading))
            .finally(() => {
                if (customLoading) {
                    customLoading.susLoadingCount()
                } else this.loadingService.susLoadingCount()
            })
    }

    validarTokenEncuesta(token: string, reservaId: string, codigoIdioma: string = 'EN', customLoading?: LoadingService): Promise<ReservaConsentimiento> {
        let $this = this
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.post(this.getBaseURL() + 'public/validar-token-encuesta', { reservaId, token, codigoIdioma }))
            .then((r) => {
                return ReservaConsentimiento.fromData(r)
            })
            .catch((e) => this.handleError(e, customLoading))
            .finally(() => {
                if (customLoading) {
                    customLoading.susLoadingCount()
                } else this.loadingService.susLoadingCount()
            })
    }

    enviarConsentimiento(r: ResumenReserva, customLoading?: LoadingService): Promise<Reserva> {
        let $this = this
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.post(this.getBaseURL() + 'enviarConsentimiento', r))
            .then((r) => {
                return this.parseSingle(r)
            })
            .catch((e) => this.handleError(e, customLoading))
            .finally(() => {
                if (customLoading) {
                    customLoading.susLoadingCount()
                } else this.loadingService.susLoadingCount()
            })
    }

    guardarAceptacion(r: Consentimiento, customLoading?: LoadingService): Promise<Consentimiento> {
        let $this = this
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.post(this.getBaseURL() + 'public/guardar-aceptacion', r))
            .then((r) => {
                return r
            })
            .catch((e) => this.handleError(e, customLoading))
            .finally(() => {
                if (customLoading) {
                    customLoading.susLoadingCount()
                } else this.loadingService.susLoadingCount()
            })
    }

    habilitarEdicion(r: Descriptivo, customLoading?: LoadingService): Promise<Consentimiento> {
        let $this = this
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.post(this.getBaseURL() + 'habilitar-edicion', r))
            .then((r) => {
                return r
            })
            .catch((e) => this.handleError(e, customLoading))
            .finally(() => {
                if (customLoading) {
                    customLoading.susLoadingCount()
                } else this.loadingService.susLoadingCount()
            })
    }

    public getReporte(filter?: FiltroConsentimiento, customLoading?: LoadingService): Promise<ConsentimientoReporte[]> {
        return lastValueFrom(this.http.post(this.getBaseURL() + 'getReporte', filter.json))
            .then((r: any[]) => {
                return r.map((c) => ConsentimientoReporte.fromData(c))
            })
            .catch((e) => this.handleError(e, customLoading))
            .finally(() => {
                if (customLoading) {
                    customLoading.susLoadingCount()
                } else this.loadingService.susLoadingCount()
            })
    }
}
