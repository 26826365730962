<div class="grupo-container {{ grupo?.hide ? 'hide' : '' }} " *ngIf="grupo">
	<div class="grupo {{ error }} grid">
		<ng-container *ngTemplateOutlet="fullSize"></ng-container>
	</div>
</div>

<ng-template #fullSize let-contextDialog="dialog">
	<div class="col-12">
		<div class="grid nested-grid" *ngIf="!contextDialog">
			<div class="col-9 titulo-grupo">
				<span class="titulo" tooltipPosition="bottom" pTooltip="{{ grupo?.producto?.descripcion }} - {{ grupo?.id }}">{{
					grupo?.producto?.descripcion
				}}</span>
				<span *ngIf="grupo?.actividadCompletada">{{ "Actividad finalizada" | translate }}</span>
				<span class="patente-vehiculo mt-1"><i class="fa fa-car mr-2"></i>{{ grupo.vehiculoAsignado?.descripcion }}</span>
				<small style="margin-left: 2rem" *ngIf="grupo.vehiculoAsignado?.descripcion != grupo.vehiculoAsignado?.patente">{{
					grupo.vehiculoAsignado?.patente
				}}</small>
			</div>
			<div class="col-3 text-right lugares-disponibles-grupo" tooltipPosition="bottom" pTooltip="{{ 'LUGARES_OCUPADOS' | translate }}">
				<button
					pButton
					icon="fa fa-flag-checkered "
					*ngIf="!grupo.actividadCompletada"
					(click)="completar()"
					pTooltip="{{ 'COMPLETAR' | translate }}"
				></button>
				<span *ngIf="!grupo.bloqueado"> <i class="fa fa-user-o"></i>{{ grupo?.lugaresOcupados }}</span>
			</div>
		</div>
	</div>

	<div class="col-12 no-bottom" *ngIf="grupo?.puestos?.length">
		<div class="grid">
			<div class="item-puesto col card-wrapper" *ngFor="let puesto of grupo?.puestos">
				<div class="card">
					<span class="nombre block">{{ puesto.personal?.descripcion || "Sin asignación" }}</span>
					<span class="descripcion-puesto block">{{ puesto.tipoPuesto?.descripcion }}</span>
				</div>
			</div>
		</div>
	</div>
	<div class="col-12 data-content">
		<div class="grid justify-content-start">
			<div class="col-12 paxs">
				<div class="item-reserva" *ngFor="let itemReserva of grupo?.gruposReserva">
					<div class="card" (click)="clickItem($event, itemReserva)">
						<div class="grid align-content-center">
							<div class="col-7 small-gap">
								<span class="large">{{ itemReserva?.descripcion }}</span>
							</div>
							<div class="col-5 text-right idiomas small-gap">
								<span class="grey"> <i class="fa fa-user-o"></i> {{ itemReserva.cantidadPax }}</span>
								<img src="{{ itemReserva?.idiomaServicio?.imagenUrl }}" alt="" class="idioma" />
								<span class="separado grey"> {{ itemReserva?.idiomaServicio?.descripcion }}</span>
							</div>
							<div class="col-12 small-gap" *ngIf="itemReserva?.itemReserva?.pickUp && itemReserva?.itemReserva?.hotel">
								<i class="fa fa-map-marker"></i
								><span class="separado grey"
									>{{ itemReserva?.itemReserva?.pickUp | date : "HH:mm" }} -
									<link-to-map [address]="itemReserva.itemReserva?.referenciaDireccion"></link-to-map
								></span>
							</div>
							<div class="col-12 small-gap" *ngIf="itemReserva?.itemReserva?.restricciones?.length">
								<i class="fa fa-exclamation-triangle"></i>
								<span class="chips text-center grey" *ngFor="let r of itemReserva.itemReserva.restricciones">{{ r.descripcion }}</span>
							</div>
							<div class="col-12 small-gap" *ngIf="itemReserva?.itemReserva?.saldo != 0">
								<div class="grid grid-nogutter danger">
									<div class="col-fixed">
										<i class="fa fa-warning"></i>
									</div>
									<div class="col">
										<span class="saldo">
											{{
												itemReserva?.itemReserva?.saldo
													| currency : "USD" : (itemReserva?.itemReserva?.monedaReserva?.simbolo || "$") + " "
											}}
										</span>
									</div>
								</div>
							</div>
							<div class="col-12 small-gap" *ngIf="itemReserva?.itemReserva?.observaciones">
								<div class="grid grid-nogutter">
									<div class="col-fixed">
										<i class="fa fa-comment-o iblock"></i>
									</div>
									<div class="col">
										<span class="grey">
											{{ itemReserva.itemReserva.observaciones }}
										</span>
									</div>
								</div>
							</div>

							<div class="col-8 small-gap" *ngIf="itemReserva.itemReserva?.telefonoContacto">
								<div class="grid grid-nogutter">
									<div class="col-fixed link-button" (click)="openLink($event, 'whatsapp', itemReserva.itemReserva?.telefonoContacto)">
										<i class="fa fa-whatsapp"></i>
									</div>
									<div class="col-fixed link-button" (click)="openLink($event, 'phone', itemReserva.itemReserva?.telefonoContacto)">
										<i class="fa fa-phone"></i>
									</div>
									<div class="col">
										<span class="grey">
											{{ itemReserva.itemReserva?.telefonoContacto }}
										</span>
									</div>
								</div>
							</div>
							<div
								class="d-flex justify-content-end link-button"
								*ngIf="true"
								(click)="verVoucher($event, itemReserva)"
								[ngClass]="itemReserva.itemReserva?.telefonoContacto ? 'col-3' : 'col-11'"
							>
								<i class="fa fa-qrcode"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-12" *ngIf="grupo?.productosAsociados?.length">
		<div class="grid">
			<div class="item-puesto col card-wrapper" *ngFor="let producto of grupo?.productosAsociados">
				<div class="card">
					<div class="grid grid-nogutter">
						<div class="col">
							<span>{{ producto?.producto?.descripcion }}</span>
						</div>
						<div class="col-fixed text-right">
							<div class="badge">{{ producto?.cantidad }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<p-dialog
	[(visible)]="mostrarVoucher"
	[modal]="true"
	[style]="{ width: '40vw' }"
	header="{{ 'VOUCHER' | translate }}"
	[focusOnShow]="false"
	(onHide)="mostrarVoucher = false; itemEditado = null"
>
	<actividades-card *ngIf="itemEditado" [actividad]="itemEditado" [token]="itemEditado?.itemReserva?.token" [readonly]="true"></actividades-card>
</p-dialog>
