import { Descriptivo } from "src/app/common/model/Descriptivo"
import { ProfilePic } from "src/app/common/model/ProfilePic"
import { ResumenModel } from "./../common/model/ResumenModel"
import { StringUtils } from "./../common/utils/string-utils"
import { PuntoDeVenta } from "./PuntoDeVenta"
import { VariablesWhatsapp } from "./VariablesWhatsapp"

export class EmailTemplate extends Descriptivo implements ResumenModel {
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public template?: string,
		public tipoCanal?: Descriptivo,
		public tipoNotificacion?: Descriptivo,
		public codigoIdioma: string = "ES",
		public icono?: ProfilePic,
		public accion?: string,
		public variablesWhatsapp?: VariablesWhatsapp,
		public nombreTemplateWhatsapp?: string,
		public puntoDeVenta?: PuntoDeVenta
	) {
		super(codigo, descripcion, id)
		this.searchStr = StringUtils.getSinTildes([this.id, this.codigo, this.descripcion].join("_"))
		this.variablesWhatsapp = new VariablesWhatsapp()
	}
	entityName(): string {
		return "emailtemplate"
	}

	public static fromData(data: any): EmailTemplate {
		if (!data) return null
		let o: EmailTemplate = new EmailTemplate(
			data.id,
			data.codigo,
			data.descripcion,
			data.template,
			Descriptivo.fromData(data.tipoCanal),
			Descriptivo.fromData(data.tipoNotificacion)
		)
		o.icono = ProfilePic.fromData(data.icono)
		o.accion = data.accion
		o.variablesWhatsapp = data.variablesWhatsapp ? VariablesWhatsapp.fromData(data.variablesWhatsapp) : new VariablesWhatsapp()
		o.nombreTemplateWhatsapp = data.nombreTemplateWhatsapp
		o.version = data.version
		o.puntoDeVenta = PuntoDeVenta.fromData(data.puntoDeVenta)
		return o
	}
}
