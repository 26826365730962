<div class="listado-nacionalidad content">
	<div class="grid">
		<div class="col-12">
			<model-list
				[service]="service"
				[modelName]="'NACIONALIDAD' | translate"
				[columns]="columns"
				[title]="'NACIONALIDAD_TITULO' | translate"
				[(listado)]="listado"
				[isLazy]="false"
				[filtro]="filtro"
				[handler]="handler"
				(onEliminar)="refresh($event)"
				[acciones]="acciones"
			>
			</model-list>
		</div>
	</div>
</div>
<p-dialog
	header=""
	[(visible)]="editandoParametro"
	appendTo="body"
	[modal]="true"
	#dialDetail
	[styleClass]="'no-header gestor-dialog'"
	(onShow)="onDialogShow($event, dialDetail)"
>
	<gestor-nacionalidad
		[item]="itemEditado"
		(onGuardado)="refresh($event)"
		(onCancelado)="onCancelar()"
		[goBack]="false"
		[isModal]="true"
	></gestor-nacionalidad>
</p-dialog>
