import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessagesService } from "src/app/common/services/messages-data-service.service";
import { ConfiguracionEntornoService } from "src/app/services/configuracion-entorno.service";
import { SessionComponent } from "./../../common/components/session-component.component";

import { DEF_LOGO } from "src/app/app.module";

@Component({
	selector: "login",
	templateUrl: "./login.component.html",
	styleUrls: ["login.component.less"],
})
export class LoginComponent extends SessionComponent implements OnInit {
	username: string;
	password: string;
	returnUrl: string;
	ngOnInit(): void {
		this.configEntorno.getConfiguracion().then((c) => {
			this.mainLogo = c.profilePic?.picPath || DEF_LOGO;
		});
		if (this.authService.getCurrentUser) this.router.navigate(["home"]);
		this.activeRoute.queryParams.subscribe((params) => {
			this.returnUrl = params["returnUrl"];
		});
	}

	constructor(
		private router: Router,
		private activeRoute: ActivatedRoute,
		messagesService: MessagesService,
		private configEntorno: ConfiguracionEntornoService
	) {
		super(messagesService);
		
	}
	public mainLogo: string = "assets/images/iterpax.png";
	login(): void {
		const $this = this;
		this.addLoadingCount();
		this.authService
			.login(this.username, this.password)
			.then((r) => {
				if (this.authService.usuario?.proveedor?.id && this.authService.usuario.tieneRol(["ROLE_PROVEEDOR"])) {
					this.router.navigate(["itinerario"]);
				} else if (this.authService.usuario?.cliente?.id && this.authService.usuario.tieneRol(["ROLE_CLIENTE"])) {
					this.router.navigate(["reserva"]);
				} else {
					this.router.navigate([this.returnUrl || "home"]);
				}

				$this.susLoadingCount();
			})
			.catch(this.errorHandler);
	}
	registrarse() {
		this.router.navigate(["public/registrarse"]);
	}
	olvidePass() {
		this.router.navigate(["public/envio-recupero"]);
	}
}
