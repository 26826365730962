import { Injectable } from '@angular/core';
import { AdminAuthGuard } from './AdminAuthGuard';
import { VentasAG } from './VentasAG';

@Injectable({
    providedIn: 'root'
})
export class ComprobantesAG extends VentasAG {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_COMPROBANTES"]) };



}