import * as moment from "moment"
import { ItemReserva } from "src/app/model/ItemReserva"
import { Vehiculo } from "src/app/model/Vehiculo"
import { ID } from "../app.module"
import { Descriptivo } from "../common/model/Descriptivo"
import { ProductoCabecera } from "./../pages/producto/ProductoCabecera"
import { IConRecordatorio } from "./../pages/recordatorio/IConRecordatorio"
import { Auditable } from "./Auditable"
import { EstadoPuesto } from "./EstadoPuesto"
import { GastoActividad } from "./GastoActividad"
import { GrupoPaxResumen } from "./GrupoPaxResumen"
import { GrupoReserva } from "./GrupoReserva"
import { Hotel } from "./Hotel"
import { Idioma } from "./Idioma"
import { ItemResumenReserva } from "./ItemReservaResumen"
import { Producto } from "./Producto"
import { ProductoAsociadoReserva } from "./ProductoAsociadoReserva"
import { PuestoServicioReserva } from "./PuestoServicioReserva"
import { Recordatorio } from "./Recordatorio"
import { RestriccionVehiculo } from "./RestriccionVehiculo"
import { TipoProducto } from "./TipoProducto"

export class GrupoPax extends Auditable implements IConRecordatorio {
	public recordatorios: Recordatorio[] = []
	private _idiomas: {}
	private _hoteles: {}
	private _productos: {}
	private _datosIncompletos: boolean

	public get tipoEntidad(): string {
		return "GrupoPaxVO"
	}

	public row: number

	private _base: string
	public get base(): string {
		return this._base
	}
	public set base(v: string) {
		this._base = v
	}

	private _area: string
	public get area(): string {
		return this._area
	}
	public set area(v: string) {
		this._area = v
	}
	public keyActividad: string
	public actividad: Descriptivo
	public get horaDesde(): number {
		var a = moment(this.fechaActividad).format("HH:mm")
		var b = moment.duration(a).asHours()
		return Math.round(Number(b) * 2)
	}
	public get colHasta(): number {
		return this.duracion * 2 + this.horaDesde
	}

	public itemsReserva: ItemResumenReserva[] = []
	public get lugaresLibres() {
		return this.capacidad - this.lugaresOcupados
	}

	public get lugaresOcupados() {
		return this.gruposReserva.reduce((r, b) => {
			return (r += b.cantidadPax)
		}, 0)
	}
	public getHora() {
		return this.fechaActividad ? Number(moment(this.fechaActividad).format("HH")) : 0
	}
	public adicionalesDestacados: Descriptivo[] = []
	public key: string
	public get esVacio() {
		return !this.gruposReserva || !this.gruposReserva.length
	}
	constructor(
		public capacidad: number,
		public id?: number,
		public fechaActividad?: Date,
		public gruposReserva: GrupoReserva[] = [],
		public producto?: ProductoCabecera,
		public puestos: PuestoServicioReserva[] = [],
		public restriccionVehiculo: RestriccionVehiculo[] = [],
		public duracion: number = 1,
		public esAlquiler: boolean = false,
		public gastos: GastoActividad[] = [],
		public bloqueado?: boolean,
		public UUID?: string,
		public productosAsociados?: ProductoAsociadoReserva[],
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
		public vehiculoAsignado?: Vehiculo,
		public esDelegado: boolean = false,
		public actividadCompletada: boolean = false,
		public version?: number,
		public generadoManual?: boolean
	) {
		super(lastModifiedBy, lastModifiedDate)
		if (!UUID) {
			this.UUID = this.id ? this.id + "" : ID()
		}

		this.key = this.UUID

		this.updateMaps()
	}
	public static fromData(data: any): GrupoPax {
		if (!data) return null
		const o: GrupoPax = new GrupoPax(
			data.capacidad,
			data.id,
			data.fechaActividad ? new Date(data.fechaActividad) : null,
			data.gruposReserva ? data.gruposReserva.map((c) => GrupoReserva.fromData(c)) : [],
			ProductoCabecera.fromData(data.producto),
			data.puestos ? data.puestos.map((c) => PuestoServicioReserva.fromData(c)) : [],
			data.restriccionVehiculo ? data.restriccionVehiculo.map((c) => RestriccionVehiculo.fromData(c)) : [],
			data.duracion,
			data.esAlquiler,
			data.gastos ? data.gastos.map((g) => GastoActividad.fromData(g)) : [],
			data.bloqueado,
			data.UUID,
			[],
			data.lastModifiedBy,
			data.lastModifiedDate,
			data.vehiculoAsignado ? Vehiculo.fromData(data.vehiculoAsignado) : null,
			data.esDelegado,
			data.actividadCompletada
		)

		if (o.gruposReserva?.length) {
			o.gruposReserva.forEach((grupoReserva) => {
				if (grupoReserva.itemReserva?.productosAsociados?.length) {
					grupoReserva.itemReserva.productosAsociados.forEach((productoAsociado) => {
						let p = ProductoAsociadoReserva.fromData(productoAsociado)
						let a = o.productosAsociados.find((pr) => pr.producto.id == productoAsociado.producto.id)
						if (a) {
							a.cantidad += productoAsociado.cantidad
						} else {
							o.productosAsociados.push(p)
						}
					})
				}
			})
			o.gruposReserva = o.gruposReserva.sort((a, b) => {
				if (a.itemReserva?.pickUp == b.itemReserva?.pickUp) {
					return a.id > b.id ? -1 : 1
				} else return moment(a.itemReserva?.pickUp).isBefore(b.itemReserva?.pickUp) ? -1 : 1
			})
		}
		o.recordatorios = data.recordatorios ? data.recordatorios.map((r) => Recordatorio.fromData(r)) : []
		o.version = data.version
		o.generadoManual = data.generadoManual
		let adicionales = []
		o.gruposReserva.forEach((gr) => {
			if (gr.itemReserva?.productosAsociados) {
				gr.itemReserva.productosAsociados.forEach((pa) => {
					if (pa.destacarComoAdicional && !adicionales.some((p) => p.id === pa.producto?.id)) {
						adicionales.push(pa.producto)
					}
				})
			}
		})
		o.adicionalesDestacados = adicionales
		return o
	}
	public get tieneDestacados() {
		return this.adicionalesDestacados?.length > 0
	}
	private updateMaps() {
		this._idiomas = {}
		this._hoteles = {}
		this._productos = {}
		this._datosIncompletos = false
		this.gruposReserva.forEach((gr) => {
			const ir = gr.itemReserva
			if (!ir) return
			if (ir.idiomaServicio) this._idiomas[ir.idiomaServicio.id] = ir.idiomaServicio
			if (ir.hotel) this._hoteles[ir.hotel.id] = ir.hotel.descripcion
			if (ir.producto) this._productos[ir.producto.id] = ir.producto.descripcion
			ir.productosAsociados.forEach((pa) => {
				if (pa.producto) this._productos[pa.producto.id] = pa.producto.descripcion
			})
			this._datosIncompletos = this._datosIncompletos || !ir.idiomaServicio || !ir.hotel || (ir.producto.esTransfer() && !ir.referencia)
		})
		this.keyActividad = this.producto?.familiaProducto ? this.producto?.familiaProducto.id + "f" : this.producto?.id + ""
		this.actividad = this.producto?.familiaProducto ? Descriptivo.fromData(this.producto?.familiaProducto) : Descriptivo.fromData(this.producto)
	}

	public get puestoStyle() {
		return { "flex-basis": this.base }
	}
	public setVehiculo(vehiculo: Vehiculo) {
		this.vehiculoAsignado = vehiculo
		this.esAlquiler = !this.vehiculoAsignado
	}

	public tieneItem(item: ItemReserva) {
		return this.enReserva(item) > 0
	}
	public tieneReserva(item: ItemReserva) {
		return this.gruposReserva.some((p) => p.itemReserva?.idReserva == item.idReserva)
	}
	public enReserva(item: ItemResumenReserva | ItemReserva) {
		return this.gruposReserva
			.filter((p) => p.itemReserva?.UUID == item.UUID)
			.reduce((a, b) => {
				return (a += b.cantidadPax)
			}, 0)
	}

	public quitarGruposReserva(grupos: GrupoReserva[]) {
		grupos.forEach((g) => this.quitarGrupoReserva(g))
	}

	public quitarGrupoReserva(grupoReserva: GrupoReserva) {
		this.gruposReserva = this.gruposReserva.filter((g) => g.UUID != grupoReserva.UUID)
		this.updateMaps()
	}
	public esTercerizado() {
		return this.esDelegado || this.producto?.tipoProducto.codigo == TipoProducto.TERCERIZADO
	}

	agregarPaxs(cantidad: number, itemReserva: ItemReserva | ItemResumenReserva) {
		let gr = this.gruposReserva.filter((p) => p?.itemReserva?.UUID == itemReserva?.UUID)[0]
		if (gr) {
			gr.cantidadPax += cantidad
		} else {
			gr = new GrupoReserva(null, cantidad, ItemResumenReserva.fromData(itemReserva), GrupoPaxResumen.fromData(this))
			this.gruposReserva.push(gr)
		}
		return gr
	}
	agregarGrupoReserva(grupoReserva: GrupoReserva) {
		let gr = this.gruposReserva.filter((p) => p.UUID == grupoReserva.itemReserva?.UUID)[0]
		if (gr) {
			gr.cantidadPax += grupoReserva.cantidadPax
		} else {
			this.gruposReserva.push(grupoReserva)
			gr = grupoReserva
			grupoReserva.grupoResumen = GrupoPaxResumen.fromData(this)
		}

		this.updateMaps()
		return gr
	}

	public get sinAsignar() {
		return this.puestos.some((p) => (!p.personal && !p.puesto?.esOpcional) || p.estado?.codigo == EstadoPuesto.RECHAZADO.codigo)
	}

	public aplicaVehiculo(vehiculo: Vehiculo): boolean {
		return !this.restriccionVehiculo?.length || this.restriccionVehiculo.some((v) => v.cumple(vehiculo))
	}
	getIdiomas(): Idioma[] {
		return this._idiomas ? Object.keys(this._idiomas).map((k) => this._idiomas[k]) : []
	}

	reemplazarPuesto(original: PuestoServicioReserva, nuevo: PuestoServicioReserva) {
		const i = this.puestos.indexOf(original)
		if (i > 0) this.puestos[i] = nuevo
	}

	aplicaProducto(producto: Producto): boolean {
		if (!producto) return false
		if (this.producto.id == producto.id) return true
		return producto.familiaProducto?.id && producto.familiaProducto.id == this.producto?.familiaProducto?.id
	}
	tieneHotel(h: Descriptivo | Hotel): boolean {
		return h != undefined && this._hoteles[h.id] != undefined
	}
	tieneIdioma(h: Descriptivo | Idioma): boolean {
		return h != undefined && this._idiomas[h.id] != undefined
	}

	tieneProducto(p: Producto | ProductoCabecera | Descriptivo): boolean {
		return p != undefined && this._productos[p.id] != undefined
	}
	datosIncompletos() {
		return this._datosIncompletos
	}
	tieneRegion(r: Descriptivo) {
		return (r?.codigo === Descriptivo._SIN_DEFINIR.codigo && this.producto?.regiones?.length == 0) || this.producto?.regiones?.some((pr) => pr.id == r.id)
	}
	tienePais(r: Descriptivo) {
		return (
			(r?.codigo === Descriptivo._SIN_DEFINIR.codigo && this.producto?.regiones?.length == 0) ||
			this.producto?.regiones?.some((pr) => pr?.pais?.id == r.id)
		)
	}
	get proveedorPrincipal() {
		return this.producto?.esTercerizado() ? this.puestos[0]?.proveedor : null
	}
}
