import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { SessionComponent } from "src/app/common/components/session-component.component";
import { AccionesTabla } from "src/app/common/model/AccionesTabla";
import { MessagesService } from "src/app/common/services/messages-data-service.service";
import { RespuestaEncuestaActividades } from "src/app/model/RespuestaEncuestaActividades";
import { RespuestaEncuestaActividadesService } from "src/app/services/respuesta-encuesta-actividades.service";
import { ProductoService } from "./../../../services/producto.service";
import { FiltroRespuestaEncuesta } from "./../FiltroRespuestaEncuesta";

@Component({
	selector: "listado-respuesta-encuesta-actividades",
	templateUrl: "listado-respuesta-encuesta-actividades.component.html",
	styleUrls: ["listado-respuesta-encuesta-actividades.component.less"],
})
export class ListadoRespuestaEncuestaActividadesComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: FiltroRespuestaEncuesta;

	@Input()
	public guardarFiltro: boolean = true;
	@Input()
	public ocultarCabecera: boolean = false;
	@Input()
	public editable: boolean = true;
	public listado: RespuestaEncuestaActividades[] = [];
	@Input()
	public readonly: boolean = false;

	@Output()
	public onSelect: EventEmitter<RespuestaEncuestaActividades[]> = new EventEmitter();

	acciones = [
		new AccionesTabla(
			"VER",
			"fa fa-eye",
			"",
			(da, event) => {
				this.onClick(da);
				event.stopPropagation();
			},
			(data) => {
				return data.fechaCompletado;
			}
		),
	];

	public columns = [];
	constructor(
		messagesService: MessagesService,
		public service: RespuestaEncuestaActividadesService,
		private router: Router,
		public productoService: ProductoService
	) {
		super(messagesService);
	}
	ngOnInit() {
		this.filtro = new FiltroRespuestaEncuesta("respuesta-encuesta-actividades_lista", 0, 20);
		this.columns = [
			{
				field: "estado",
				header: "",
				class: "estado",
				role: "estado",
				sortable: true,
				order: 40,
			},
			{
				field: "idReserva",
				header: "Reserva",
				class: "id-column",
				role: "text-tabla",
				sortable: false,
				order: 1,
				width: "10em",
			},

			{
				field: "descripcionProducto",
				header: "Producto",
				class: "text-column",
				role: "text-tabla",
				sortable: true,
				order: 1,
				width: "25%",
			},
			{
				field: "nombrePax",
				header: "Nombre Pasajero",
				class: "text-column",
				role: "text-tabla",
				sortable: false,
				order: 1,
				width: "25%",
			},
			{
				field: "fechaActividad",
				header: "Fecha Actividad",
				class: "date-tabla",
				role: "date-tabla",
				sortable: true,
				order: 40,
				width: "7em",
			},
			{
				field: "fechaCompletado",
				header: "Fecha Completado",
				class: "date-tabla",
				role: "date-tabla",
				sortable: true,
				order: 40,
				width: "7em",
			},
		];
	}

	public updateSeleccion(seleccion: any) {
		if (seleccion?.length || seleccion["id"]) {
			this.onSelect.emit(seleccion?.length ? seleccion : [seleccion]);
		}
	}
	ngOnDestroy() {}

	public ver: (item) => void = (item) => {
		let query: NavigationExtras = {
			queryParams: {
				id: item.id,
			},
		};
		this.router.navigate(["respuesta-encuesta-actividades" + "/vista"], query);
	};

	public get estados() {
		return RespuestaEncuestaActividades.ESTADOS;
	}

	public onClick: (item) => void = (item) => {
		if (item.fechaCompletado) this.ver(item);
	};
}
