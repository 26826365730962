import { Injectable } from "@angular/core"
import { IMessage } from "@stomp/stompjs"
import { Subscription, lastValueFrom } from "rxjs"
import { filter } from "rxjs/operators"
import { LogoEntorno } from "src/app/app.module"
import { ServicioAbstract } from "src/app/common/services/service.service"
import { Notificacion } from "src/app/model/Notificacion"
import { Novedad } from "src/app/model/Novedad"
import { AuthService } from "src/app/services/auth.service"
import { WebsocketService } from "src/app/services/websockets.service"
import { environment } from "src/environments/environment"
import { StringUtils } from "../utils/string-utils"
import { LoadingService } from "./loading-data-service.service"
import { MessagesService } from "./messages-data-service.service"
@Injectable({
	providedIn: "root"
})
export class NotificacionService extends ServicioAbstract<Notificacion> {
	private static subWs: Subscription
	private observers = []
	constructor(messages?: MessagesService, loadingService?: LoadingService, private wsService?: WebsocketService, private authService?: AuthService) {
		super(messages, loadingService)
		if (!NotificacionService.subWs)
			NotificacionService.subWs = this.wsService.subscribe("/topic/alerta").subscribe((a: IMessage) => {
				const n: Novedad = Novedad.fromData(JSON.parse(a.body))
				this.observers.forEach((f) => f(n))
				if (n.aplica(this.authService?.usuario)) {
					const m = StringUtils.htmlToPlainText(n.body)
					this.messages.info(m.slice(0, m.length > 300 ? 300 : m.length), StringUtils.htmlToPlainText(n.cabecera))
					if (document.visibilityState === "visible") {
						return
					}
					const saved = localStorage.getItem("lastPush" + environment.tokenKey)
					if (saved !== n.key) {
						localStorage.setItem("lastPush" + environment.tokenKey, n.key)
						const body = StringUtils.htmlToPlainText(n.body)
						if (!("Notification" in window)) {
							console.log("Este navegador no soporta notificaciones de escritorio")
						} else if (Notification.permission === "granted") {
							new Notification(n.tipo, { body: body, icon: LogoEntorno() })
						} else if (Notification.permission !== "denied") {
							Notification.requestPermission(function (permission) {
								if (permission === "granted") {
									new Notification(n.tipo, { body: body, icon: LogoEntorno() })
								}
							})
						}
					}
				}
			})
		this.authService.onLogout.pipe(filter((u) => u === true)).subscribe(() => {
			NotificacionService.subWs && NotificacionService.subWs.unsubscribe()
			NotificacionService.subWs = null
		})
	}
	//funcion que recibe otra funcion y la ejecuta cuando llega una alerta
	public suscribirAlerta(f: (n: Novedad) => void) {
		this.observers.push(f)
	}
	public baseName(): string {
		return "notificacion"
	}
	public parseToEnt(data: any): Notificacion {
		return Notificacion.fromData(data)
	}
	public newEnt(): Notificacion {
		return new Notificacion()
	}

	public enviar() {
		return lastValueFrom(this.http.post(this.getBaseURL("enviarPendientes"), {}))
	}
	public ocultarNotificacion(id: number, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL() + "ocultarNotificacion", id)).then((r) => {
			if (customLoading) customLoading.susLoadingCount()
			else this.loadingService.susLoadingCount()
			return r
		})
	}

	public enviarResumenPaxs() {
		return lastValueFrom(this.http.post(this.getBaseURL("resumenPaxs"), {}))
	}
	public enviarResumenHotel() {
		return lastValueFrom(this.http.post(this.getBaseURL("resumenHotel"), {}))
	}
	public enviarResumenProveedor() {
		return lastValueFrom(this.http.post(this.getBaseURL("resumenProveedor"), {}))
	}
	public enviarResumenGuia() {
		return lastValueFrom(this.http.post(this.getBaseURL("resumenGuia"), {}))
	}
	public enviarResumenDiario() {
		return lastValueFrom(this.http.post(this.getBaseURL("resumenDiario"), {}))
	}

	public getMisNotificaciones() {
		if (!this.authService.token) return Promise.resolve([])
		return lastValueFrom(this.http.get(this.getBaseURL("mis-notificaciones"))).then((n) => {
			return this.parse(n)
		})
	}
}
