import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { TipoPreguntaEncuesta } from "src/app/model/TipoPreguntaEncuesta"
import { MessagesService } from "../common/services/messages-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class TipoPreguntaEncuestaService extends ParametricoService<TipoPreguntaEncuesta> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "tipo-pregunta-encuesta"
	}
	public parseToEnt(data: any): TipoPreguntaEncuesta {
		return TipoPreguntaEncuesta.fromData(data)
	}
	public newEnt(): TipoPreguntaEncuesta {
		return new TipoPreguntaEncuesta()
	}
}
