export class Intercambio {

    constructor(
        public grupoRerservaDesde?: number,
        public grupoRerservaHasta?: number,
        public grupoOrigen?: number,
        public grupoDestino?: number,
        public cantidad?: number

    ) {

    }
}