import * as moment from "moment"
import { CentroCosto } from "./CentroCosto"
import { Nacionalidad } from "./Nacionalidad"
import { ProveedorCabecera } from "./ProveedorCabecera"

export class ReporteServicios {
	constructor(
		public grupoPaxId?: number,
		public modeloVehiculo?: string,
		public patenteVehiculo?: string,
		public proveedores?: ProveedorCabecera[],
		public fechaActividad?: Date,
		public tipoActividad?: string,
		public nombrePax?: string,
		public apellidoPax?: string,
		public documentoPax?: string,
		public fechaNacPax?: Date,
		public edadPax?: number,
		public observaciones?: Date,
		public nroReserva?: number,
		public centroCosto?: CentroCosto,
		public telefonoPax?: string,
		public alojamiento?: string,
		public nacionalidad?: Nacionalidad
	) {}

	public static fromData(data): ReporteServicios {
		const r = new ReporteServicios(
			data.grupoPaxId,
			data.modeloVehiculo,
			data.patenteVehiculo,
			data.proveedores.map((p) => ProveedorCabecera.fromData(p)),
			moment(data.fechaActividad).toDate(),
			data.tipoActividad,
			data.nombrePax,
			data.apellidoPax,
			data.documentoPax,
			moment(data.fechaNacPax).toDate(),
			moment().diff(data.fechaNacPax, "years"),
			data.observaciones,
			data.nroReserva,
			CentroCosto.fromData(data.centroCosto),
			data.telefonoPax,
			data.alojamiento,
			Nacionalidad.fromData(data.nacionalidad)
		)

		return r
	}
}
