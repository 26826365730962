<div class="">
	<div class="consentimiento">
		<div *ngIf="item && valido && enviado == 0" class="contrato content content-white">
			<div class="selector-idioma-container">
				<selector-idioma [(ngModel)]="idiomaSeleccionado" name="idioma" [idiomas]="idiomas" [mode]="'text'"></selector-idioma>
			</div>
			<div class="grid">
				<div class="col-12 logo">
					<img src="{{ mainLogo }}" alt="" />
				</div>

				<div class="col-12" *ngIf="encuestas?.length && !usuario">
					<p-panel>
						<p-header>
							<span class="large bold">
								{{ "ENCUESTAS_SIN_COMPLETAR" | translate }}
							</span>
						</p-header>
						<div class="grid">
							<div class="col-12" *ngFor="let item of encuestas">
								<div class="card" (click)="mostrarEncuesta(item)">
									<div class="grid grid-nogutter">
										<div class="col">
											<span style="display: block" class="pt-2">
												{{ item.familia ? item.familia?.descripcion : item.producto.descripcion }} - {{ item.grupo }} -
												{{ item.fecha | date : "dd/MM/yyyy" }}
											</span>
										</div>
										<div class="col-fixed text-center">
											<button
												*ngIf="isMobile()"
												pButton
												pTooltip="{{ 'COMPLETAR_ENCUESTA' | translate }}"
												icon="fa fa-pencil-square-o"
												(click)="mostrarEncuesta(item)"
											></button>
											<button
												*ngIf="!isMobile()"
												pButton
												pTooltip="{{ 'COMPLETAR_ENCUESTA' | translate }}"
												icon="fa fa-pencil-square-o"
												(click)="mostrarEncuesta(item)"
												label="{{ 'COMPLETAR' | translate }}"
											></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</p-panel>
				</div>
				<div class="col-12">
					<p-panel>
						<p-header>
							<span class="large bold">
								{{ "ACTIVIDADES" | translate }}
							</span>
						</p-header>

						<div class="grid" *ngIf="!isMobile()">
							<div
								class="col-12 lg:col-4 print:col-12"
								*ngFor="let familia of familias; let i = index"
								[ngClass]="{ pagebreak: i != familias?.length }"
							>
								<actividades-card
									[actividad]="familia"
									[token]="getToken(familia)"
									[consentimientoPendiente]="familia.getRequiereConsentimiento() && !familia?.confirmado"
									[readonly]="!editando"
									(verConsentimiento)="verConsentimiento()"
								></actividades-card>
								<!--div class="col-12" *ngIf="familia.getRequiereConsentimiento() && !readonly && !familia?.confirmado">
									<p-checkbox
										[disabled]="readonly"
										[(ngModel)]="familia.confirmado"
										binary="true"
										label="{{ 'HE_LEIDO' | translate }}"
										name="{{ familia?.id + 'check' }}"
									></p-checkbox>
								</div-->
							</div>
						</div>
						<div *ngIf="isMobile()">
							<div
								class="card card-item-reserva"
								[ngClass]="{ 'falta-consentimiento': grupo.getRequiereConsentimiento() && !grupo.confirmado }"
								*ngFor="let grupo of familias; let i = index"
								pRipple
							>
								<div class="grid p-0 m-0 align-items-center" (click)="goToDetalleItem(grupo)">
									<div class="col-4 imagen" *ngIf="grupo?.getItemBase()?.producto?.icono">
										<img src="{{ grupo?.getItemBase()?.producto?.icono?.picPath }}" style="width: 100%" />
									</div>
									<div [ngClass]="grupo?.getItemBase()?.producto?.icono ? 'col-7' : 'col-11'">
										<div class="grid">
											<div class="col-12">
												<span class="titulo">
													{{ grupo?.familia?.descripcion || grupo?.producto?.descripcion }}
												</span>
											</div>
											<div class="col-12">
												<a
													[href]="'https://maps.google.com/maps?q=' + grupo?.getItemBase()?.referenciaDireccion"
													target="_blank"
													class="block"
													><i class="fa fa-map-marker mr-2"></i>{{ grupo?.getItemBase()?.referenciaDireccion }}</a
												>
											</div>
											<div class="col-12">
												<small *ngFor="let v of grupo.getVariantes()" class="mr-2">{{ v.variante + " x " + v.cantidad }}</small>
											</div>
											<div class="col-12">
												<small class="mr-3"><i class="fa fa-calendar-o"></i>{{ grupo?.fechaActividad | date : "dd/MM/yyyy" }}</small>
												<small><i class="fa fa-clock-o"></i>{{ grupo?.getItemBase()?.pickUp | date : "HH:mm" : "UTC-3" }} hs</small>
											</div>
											<div class="col-12">
												<div class="grid align-items-center grid-nogutter" *ngIf="grupo?.puestoTercerizado?.esPrestador()">
													<div class="col-12">
														<strong
															><small>{{ "PROVISTO_POR" | translate }}</small></strong
														>
													</div>
													<div class="col-12 image-wrapper">
														<img [src]="grupo?.puestoTercerizado?.personal?.imagenUrl" alt="" />
													</div>
													<div class="col-12 text-center small">
														<small>{{ grupo?.puestoTercerizado?.personal?.descripcion }}</small>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-1">
										<i class="fa fa-chevron-right"></i>
									</div>
									<div class="col-12 text-center" *ngIf="!editando && grupo.getRequiereConsentimiento() && !grupo.confirmado">
										<strong>{{ "FALTA_CONSENTIMIENTO" | translate }}</strong>
									</div>
								</div>
								<div class="grid p-0">
									<div class="col-12" *ngIf="editando && grupo.getRequiereConsentimiento()">
										<p-checkbox
											[disabled]="readonly"
											[(ngModel)]="grupo.confirmado"
											binary="true"
											label="{{ 'HE_LEIDO' | translate }}"
											name="{{ grupo?.id + 'check' }}"
										></p-checkbox>
									</div>
								</div>
							</div>
						</div>
					</p-panel>
				</div>
				<div class="col-12 prevent-page-break">
					<p-panel *ngIf="productos?.length">
						<p-header>
							<span class="large bold">
								{{ "PRODUCTOS" | translate }}
							</span>
						</p-header>
						<div class="grid">
							<div class="col-12 lg:col-4 print:col-12" *ngFor="let i of productos">
								<div class="card">
									<div class="grid">
										<div class="col-12 bold">
											{{ getHeader(i) }}
										</div>
										<div class="detail-container" style="max-height: 8em; overflow-y: auto">
											<div class="col-12" *ngIf="i.producto?.detalle" [innerHtml]="i.producto?.detalle | safeHtml"></div>
										</div>

										<div class="col-12">
											<h3 style="color: black" *ngIf="i.productosAsociados?.length">{{ "Incluye" | translate }}</h3>
											<div class="grid">
												<div
													[ngClass]="{ 'col-12': isMobile(), 'col-fixed': !isMobile() }"
													*ngFor="let producto of i.productosAsociados"
												>
													<span class="chips text-center"> {{ producto?.producto?.descripcion }} x {{ producto?.cantidad }} </span>
												</div>
											</div>
										</div>

										<div class="col-12" *ngIf="i.producto.requiereConsentimiento">
											<p-checkbox
												[disabled]="readonly"
												name="{{ i.id + 'acepto' }}"
												[(ngModel)]="i.aceptadoConsentimiento"
												binary="true"
												label="{{ 'HE_LEIDO' | translate }}"
											></p-checkbox>
										</div>
									</div>
								</div>
							</div>
						</div>
					</p-panel>
				</div>
				<div class="col-12 prevent-page-break" *ngIf="hospedajes?.length">
					<p-panel>
						<ng-template pTemplate="header">
							<span class="large bold">
								{{ "HOSPEDAJES" | translate }}
							</span>
						</ng-template>

						<div class="grid">
							<div class="col-12 lg:col-4 print:col-12" *ngFor="let hospedaje of hospedajes; let i = index">
								<hospedaje-card [itemReserva]="hospedaje"></hospedaje-card>
							</div>
						</div>
					</p-panel>
				</div>
				<div class="col-12 print:mt-4 prevent-page-break" *ngIf="item?.observaciones">
					<p-panel #panelContrato [collapsed]="false" [toggleable]="true" [styleClass]="'force-on-print'">
						<ng-template pTemplate="header">
							<span class="large bold block w-100" (click)="panelContrato.toggle($event)">{{ "OBSERVACIONES" | translate }}</span>
						</ng-template>
						<div class="contendor-contrato p-2">
							<div class="card">
								<div class="col-12" [innerHtml]="item.observaciones | safeHtml"></div>
							</div>
						</div>
					</p-panel>
				</div>

				<div class="col-12 print:mt-4">
					<p-panel
						#panelContrato
						[collapsed]="readonly"
						[toggleable]="true"
						*ngIf="item.requiereConsentimiento || textoConsentimiento"
						[styleClass]="'force-on-print'"
					>
						<ng-template pTemplate="header">
							<span class="large bold block w-100" (click)="panelContrato.toggle($event)">{{ "CONDICIONES_DEL_SERVICIO" | translate }}</span>
						</ng-template>
						<div class="contendor-contrato p-2">
							<div class="card">
								<div class="textoConsentimiento w-100" [innerHtml]="textoConsentimiento | safeHtml"></div>
							</div>
						</div>
					</p-panel>
				</div>
				<div class="col-12 print:mt-4" *ngIf="item?.verEstadoCuenta">
					<p-panel #panelContrato [collapsed]="false" [toggleable]="true" [styleClass]="'force-on-print'">
						<ng-template pTemplate="header">
							<span class="large bold block w-100" (click)="panelContrato.toggle($event)">{{ "ESTADO_DE_CUENTA" | translate }}</span>
						</ng-template>

						<div class="card">
							<span-dinero-editable
								[ngModel]="item.importeTotal"
								[label]="'TOTAL' | translate"
								[moneda]="item.moneda"
								[disabled]="true"
								[editable]="false"
							>
							</span-dinero-editable>
							<span-dinero-editable
								[label]="'SALDO_A_PAGAR' | translate"
								[ngModel]="item.saldo"
								[moneda]="item.moneda"
								[disabled]="true"
								[editable]="false"
							>
							</span-dinero-editable>
						</div>
					</p-panel>
				</div>
				<div class="col-12 prevent-page-break print:mt-4">
					<p-panel [collapsed]="readonly" [toggleable]="true" [styleClass]="'force-on-print '" #panelPaxs>
						<ng-template pTemplate="header">
							<span class="large bold block w-100" (click)="panelPaxs.toggle($event)"
								>{{ "DATOS_PASAJEROS" | translate }} <i class="fa fa-warning warning ml-2" *ngIf="!usuario && datosInclompletos()"> </i
							></span>
						</ng-template>

						<div class="paxs p-fluid" [formGroup]="formPaxs">
							<ng-container formArrayName="paxs">
								<div class="grid">
									<div class="col-12 lg:col-4 print:col-6" *ngFor="let pax of paxs.controls; let i = index">
										<div class="card" style="position: relative">
											<button
												*ngIf="!editandoPaxs && !editando && !usuario"
												pButton
												pRipple
												type="button"
												class="editar p-button-rounded p-button-text"
												[rounded]="true"
												icon="fa fa-pencil"
												(click)="editarDatos()"
											></button>
											<div class="grid">
												<div class="col-12 bold">{{ "PASAJERO" | translate }} {{ i + 1 }}</div>
												<div class="col-12" [formGroup]="pax">
													<input
														type="text"
														pInputText
														formControlName="nombre"
														#nombre
														placeholder="{{ 'NOMBRE' | translate }}"
														required
													/>
												</div>
												<div class="col-12" [formGroup]="pax">
													<input
														type="text"
														pInputText
														#apellido
														formControlName="apellido"
														placeholder="{{ 'APELLIDO' | translate }}"
														required
													/>
												</div>
												<div class="col-12" [formGroup]="pax">
													<p-dropdown
														appendTo="body"
														[options]="nacionalidades"
														formControlName="nacionalidad"
														placeholder="{{ 'NACIONALIDAD' | translate }}"
														optionLabel="descripcion"
														dataKey="codigo"
														[required]="true"
														styleClass="small-width"
														[disabled]="readonly"
													></p-dropdown>
												</div>
												<div class="col-12 lg:col-6" [formGroup]="pax">
													<span class="p-float-label">
														<p-calendar
															formControlName="fechaNacimiento"
															name="fechaNacimiento"
															required
															[disabled]="readonly"
															dateFormat="dd/mm/yy"
															[maxDate]="now"
														></p-calendar>
														<label for="fecha">{{ "FECHA_NACIMIENTO" | translate }}</label>
													</span>
												</div>

												<div class="col-12">
													<div class="grid">
														<div class="col-fixed" style="width: 10em" [formGroup]="pax">
															<p-dropdown
																appendTo="body"
																[options]="documentos"
																formControlName="tipoDocumento"
																placeholder="{{ 'SELECCIONAR_UN_TIPO_12' | translate }}"
																optionLabel="descripcion"
																dataKey="codigo"
																styleClass="small-width"
															></p-dropdown>
														</div>
														<div class="col" [formGroup]="pax">
															<input
																type="text"
																pInputText
																formControlName="numeroDocumento"
																placeholder="{{ 'NUMERO_DE_DOCUMENTO' | translate }}"
															/>
														</div>
													</div>
												</div>

												<div class="col-12 p-fluid" [formGroup]="pax">
													<span class="p-float-label">
														<textarea
															pInputTextarea
															id="observaciones"
															name="observaciones"
															[class.p-filled]="true"
															formControlName="observaciones"
															style="resize: none; width: 100%; padding: 0.75rem"
														></textarea>
														<label>{{ "OBSERVACIONES" | translate }}</label>
													</span>
													<!-- <error-tag [model]="pax.controls['observaciones']"></error-tag> -->
												</div>
											</div>
										</div>
									</div>
									<div class="col-12" *ngIf="item.consentimiento">
										<div class="p-inputgroup">
											<span class="p-inputgroup-addon" [ngClass]="{ readonly: readonly || (!editando && !editandoPaxs) }"
												><i class="fa fa-phone"></i
											></span>
											<span class="p-float-label">
												<input
													pInputText
													type="text"
													required
													[(ngModel)]="item.consentimiento.telefonoContacto"
													[ngModelOptions]="{ standalone: true }"
													[disabled]="readonly || (!editando && !editandoPaxs)"
													placeholder="{{ 'TELEFONOCONTACTO_TOOLTIP' | translate }}"
												/>
												<label for="" *ngIf="editando || editandoPaxs || !item.consentimiento?.telefonoContacto">{{
													"TELEFONOCONTACTO" | translate
												}}</label>
											</span>
										</div>
									</div>
									<div class="col-12" *ngIf="editandoPaxs">
										<div class="grid">
											<div class="col-6 p-fluid">
												<button
													pButton
													class="p-button-gray"
													type="button"
													label="{{ 'CANCELAR' | translate }}"
													(click)="cancelarEdit($event)"
												></button>
											</div>
											<div class="col-6 p-fluid">
												<button
													pButton
													class="p-button-success"
													type="button"
													label="{{ 'GUARDAR' | translate }}"
													(click)="guardarPasajeros($event)"
												></button>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</p-panel>
				</div>

				<div
					class="col-12 prevent-page-break"
					*ngIf="item?.puntoDeVenta?.telefono || item?.puntoDeVenta?.whatsapp || item?.puntoDeVenta?.emailContacto"
				>
					<p-panel [collapsed]="readonly" [toggleable]="true" [styleClass]="'force-on-print'" #panelContactos>
						<ng-template pTemplate="header">
							<span class="large bold block w-100" (click)="panelContactos.toggle($event)">{{ "CONTACTANOS" | translate }}</span>
						</ng-template>

						<div class="card">
							<contactos [data]="item?.puntoDeVenta" [containerClass]="'col-12 contacto-itinerario'"></contactos>
						</div>
					</p-panel>
				</div>
				<div class="lg:col-offset-4 col-12 lg:col-4 p-fluid" *ngIf="editando">
					<button
						pButton
						type="button"
						label="{{ 'ENVIAR' | translate }}"
						(click)="onSubmit()"
						[disabled]="!formValid || !item.consentimiento?.telefonoContacto"
					></button>
				</div>
				<div class="col-12" *ngIf="!readonly && (!formValid || !item.consentimiento?.telefonoContacto)">
					<span class="text-center w-100 block">{{ "VERIFIQUE_QUE_TODOS_LOS_CAMPOS_OBLIGATORIOS_ESTÉN_COMPLETOS" | translate }}</span>
				</div>
			</div>
		</div>

		<div class="grid mensajes" style="margin: 0">
			<div class="col-12 logo" *ngIf="!item">
				<img src="{{ mainLogo }}" alt="" />
			</div>
			<div class="col-12 text-center" *ngIf="!valido && !validando">
				<h2>Link invalido. Comuniquese con Administración</h2>
			</div>

			<div class="col-12 text-center" *ngIf="validando">
				<h2>Verificando <i class="fa fa-spinner fa-spin"></i></h2>
			</div>

			<div class="lg:col-6 col-12 text-center" *ngIf="valido && enviado == 2">
				<h2>{{ "ERROR_EN_EL_FORMULA_33" | translate }}</h2>
			</div>
		</div>
	</div>
</div>

<p-sidebar [(visible)]="mostrarSide" position="right" [style]="{ width: '100%' }">
	<div *ngIf="grupoDetalle">
		<actividades-card
			[actividad]="grupoDetalle"
			[token]="getToken(grupoDetalle)"
			[readonly]="!editando"
			[consentimientoPendiente]="grupoDetalle.getRequiereConsentimiento() && !grupoDetalle?.confirmado"
			(verConsentimiento)="verConsentimiento()"
		></actividades-card>
	</div>
</p-sidebar>
