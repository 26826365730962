
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { Component, OnDestroy, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { ColumnGenerator } from 'src/app/common/utils/ColumnGenerator';
import { Restriccion } from 'src/app/model/Restriccion';
import { RestriccionService } from 'src/app/services/restriccion.service';
import { LoadingService } from 'src/app/common/services/loading-data-service.service';

@Component({
	selector: 'listado-restriccion',
	templateUrl: 'listado-restriccion.component.html',
	styleUrls: ['listado-restriccion.component.less']
})

export class ListadoRestriccionComponent extends SessionComponent implements OnInit, OnDestroy {

	public filtro: Filtro;
	
	@Input()
	public guardarFiltro: boolean = true;	
	@Input()
	public ocultarCabecera: boolean = false;  	
	@Input()
	public editable : boolean = true;
	public listado: Restriccion[] = [];
	@Input()
  	public readonly: boolean = false;
  	@Input()
  	public modoSeleccion: 'single' | 'multiple' | 'none' = 'none';
  	
  	@Output()
  	public onSelect: EventEmitter<Restriccion[]> = new EventEmitter();
  	
	public columns = [];
	constructor(messagesService : MessagesService,
				public service: RestriccionService) {
		super(messagesService)
		console.log(JSON.stringify(this.columns));
	}
	ngOnInit() { 
	this.filtro = new Filtro("restriccion_lista", {}, 0, 20,'id',1,this.guardarFiltro)
		this.columns = [
  {
    "field": "codigo",
    "header": "Codigo",
    "class": "codigo-tabla",
    "role": "text-tabla",
    "sortable": true,
    "order": 1
  },
  {
    "field": "id",
    "header": "#",
    "class": "id-column",
    "role": "text-tabla",
    "sortable": true,
    "order": 1
  },
  {
    "field": "descripcion",
    "header": "Descripciones",
    "class": "text-editable-tabla",
    "role": "text-editable-tabla",
    "sortable": true,
    "order": 40
  },
  {
    "field": "icono",
    "header": "Icono",
    "class": "profile-pic",
    "role": "profile-tabla",
    "sortable": false,
    "order": 40
  },
  {
    "field": "habilitado",
    "header": "Habilitado",
    "class": "is-boolean",
    "role": "boolean-tabla",
    "sortable": false,
    "order": 99
  }
];
	}
	
	 public updateSeleccion(seleccion: any) {
	    if (seleccion?.length || seleccion['id']) {
	      this.onSelect.emit(seleccion?.length ? seleccion : [seleccion]);
	    }
	  }
	ngOnDestroy() { }

	  public actualizarDescricpion(data: Restriccion, customLoading?: LoadingService) {
    this.service.actualizarDescripcion(data, customLoading);
  }
	
}