import { HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { ServicioAbstract } from "src/app/common/services/service.service"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { OrdenPagoResumen } from "src/app/model/OrdenPagoResumen"
import { Filtro } from "../common/model/Filtro"
import { OrdenPago } from "../model/OrdenPago"
import { Totales } from "../model/Totales"
import { LoadingService } from "./../common/services/loading-data-service.service"
@Injectable({
	providedIn: "root"
})
export class OrdenPagoResumenService extends ServicioAbstract<OrdenPagoResumen> {
	getTotales(f: any): Promise<Totales> {
		return lastValueFrom(this.http.post(this.getBaseURL() + "getTotales", f.json)).then((r) => Totales.fromData(r))
	}
	public accionAnular(documento: any) {
		return this.eliminarOrden(documento.id, documento.motivoCancelacion.id)
	}
	public esAccionAnular(documento: OrdenPagoResumen): boolean {
		return true
	}
	notificarProveedor(itemEditado: OrdenPagoResumen | OrdenPago, destinatario: string, customLoading?: LoadingService): Promise<boolean> {
		let $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("notificarProveedor"), { idEntidad: itemEditado.id, destinatario: destinatario })).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	public baseName(): string {
		return "orden-pago"
	}
	public parseToEnt(data: any): OrdenPagoResumen {
		return OrdenPagoResumen.fromData(data)
	}
	public newEnt(): OrdenPagoResumen {
		return new OrdenPagoResumen()
	}

	getAll(filtro: Filtro): Promise<OrdenPagoResumen[]> {
		return lastValueFrom(this.http.post(this.getBaseURL() + "getAllResumen", filtro.json)).then((r) => this.parse(r))
	}

	public aprobarOrdenPago(op: OrdenPagoResumen): Promise<any> {
		let copy = { ...op }
		copy.estado = new EstadoDocumento(null, "A", "Aprobar")
		return this.cambioEstadoOrdenPago(copy).then((saved) => (op.estado = saved.estado))
	}
	public rechazarOrdenPago(op: OrdenPagoResumen): Promise<any> {
		let copy = { ...op }
		copy.estado = new EstadoDocumento(null, "R", "Rechazar")
		return this.cambioEstadoOrdenPago(copy).then((saved) => (op.estado = saved.estado))
	}

	private cambioEstadoOrdenPago(op: OrdenPagoResumen): Promise<any> {
		return lastValueFrom(this.http.post(this.getBaseURL() + "cambioEstadoOrdenPago", op)).then((op) => {
			return op
		}, this.handleError)
	}

	public eliminarOrden(id: number, idMotivo: number) {
		let params = new HttpParams()
		params = params.append("id", id + "")
		params = params.append("idMotivo", idMotivo + "")
		return lastValueFrom(this.http.delete(this.getBaseURL(), { params })).then(this.handleOk).catch(this.handleError)
	}
}
