<div id="grupo{{ actividad.getId() }}">
	<div class="grupo-container" style="height: auto">
		<div class="grupo {{ error }} grid" *ngIf="actividad && itemReserva" [ngClass]="{ large: !esVoucher }">
			<div class="col-12 logo print-only">
				<img src="{{ mainLogo }}" alt="" />
			</div>
			<button
				pButton
				tooltipPosition="bottom"
				pTooltip="{{ 'IMPRIMIR' | translate }}"
				class="print-button"
				icon="fa fa-print"
				printSectionId="grupo{{ actividad.getId() }}"
				ngxPrint
				[useExistingCss]="true"
				*ngIf="!esVoucher"
			></button>
			<ng-container *ngTemplateOutlet="fullSize; context: { $implicit: actividad }"></ng-container>
		</div>
	</div>
</div>

<ng-template #fullSize let="grupo">
	<div class="col-12 titulo-grupo">
		<span class="titulo" tooltipPosition="bottom" pTooltip="{{ itemReserva?.descripcionServicio }}"
			>{{ actividad?.getDescripcion() }} - {{ actividad?.fechaActividad | date : "dd/MM/yyyy" }}</span
		>

		<span *ngIf="!esVoucher" class="bold mt-1">{{
			itemReserva?.producto?.duracion ? ("DURACION" | translate) + ": " + itemReserva?.producto?.duracion + " hs" : ""
		}}</span>
	</div>
	<div class="col-12 clickable" *ngIf="verHotel && itemReserva?.referenciaDireccion">
		<a [href]="'https://maps.google.com/maps?q=' + actividad?.getReferenciaDireccion()" target="_blank" class="block"
			><i class="fa fa-map-marker mr-2"></i>{{ actividad?.getReferenciaDireccion() }}</a
		>
	</div>

	<div class="col-12" *ngIf="hayPrestador">
		<div class="grid grid-nogutter">
			<!-- <div class="col-12" *ngIf="itemReserva.producto?.detalle">
                    <div class="card">
                        <div [innerHTML]="itemReserva.producto?.detalle">

                        </div>
                    </div>
                </div> -->
			<div class="col-12" *ngFor="let puesto of actividad.getPuestos() | prestador">
				<div class="item-puesto card-wrapper">
					<div class="card">
						<div class="grid">
							<div class="col-auto badge-wrapper">
								<img [src]="puesto?.personal?.imagen" class="badge" />
							</div>
							<div class="col">
								<span class="nombre block">{{ puesto?.personal?.descripcion || "Sin asignación" }}</span>
								<span class="descripcion-puesto block">{{ puesto?.tipoPuesto?.descripcion }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-12 data-content">
		<div class="grid justify-content-start grid-nogutter">
			<div class="col-12 item-reserva">
				<div class="card">
					<div class="grid align-content-center">
						<div class="col-12">
							<strong class="block"><i class="fa fa-users mr-2"></i>{{ actividad?.getNombreReferencia() }}</strong>
						</div>
						<div class="col-12 idiomas">
							<span *ngFor="let v of actividad.getVariantes()" class="mr-2">{{ v.variante + " x " + v.cantidad }}</span>
							<img src="{{ itemReserva?.idiomaServicio?.imagenUrl }}" alt="" class="idioma" />
							<span class="separado grey"> {{ itemReserva?.idiomaServicio?.descripcion }}</span>
						</div>
						<div class="col-12 small-gap">
							<i class="fa fa-clock-o"></i><span class=""> {{ itemReserva?.pickUp | date : "HH:mm" : "UTC-3" }} hs</span>
						</div>
						<div class="col-12 small-gap" *ngIf="itemReserva?.pickUp && itemReserva?.hotel && !esVoucher">
							<i class="fa fa-map-marker"></i><span class="separado grey">{{ itemReserva?.hotel }} - {{ itemReserva?.direccionHotel }}</span>
						</div>
						<div class="col-12 small-gap detalle-wrapper" *ngIf="!esVoucher">
							<div
								class="detail-container"
								#detalleGrupo
								[ngClass]="actividad.verMas ? 'expandido' : ''"
								[innerHtml]="actividad.getDetalle() || '' | safeHtml"
							></div>
							<div class="ver-mas" pRipple *ngIf="isMobile() && mostrarVerMas" (click)="actividad.verMas = !actividad.verMas">
								<span>{{ (actividad.verMas ? "VER_MENOS" : "VER_MAS") | translate }}</span>
							</div>
						</div>
						<div class="col-12 small-gap" *ngIf="actividad?.getRestricciones()?.length && !esVoucher">
							<i class="fa fa-exclamation-triangle"></i>
							<span class="chips text-center grey" *ngFor="let r of actividad.getRestricciones()">{{ r.descripcion }}</span>
						</div>
						<div class="col-12 small-gap" *ngIf="itemReserva?.notaCliente">
							<div class="grid grid-nogutter">
								<div class="col-fixed">
									<i class="fa fa-comment-o iblock"></i>
								</div>
								<div class="col">
									<span class="grey">
										{{ itemReserva?.notaCliente }}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 small-gap" *ngIf="actividad?.productosAsociados?.length && !esVoucher">
				<span class="titulo">{{ "INCLUYE" | translate }}</span>
			</div>
			<div class="col-12 small-gap" *ngIf="actividad?.productosAsociados?.length && !esVoucher">
				<div class="grid">
					<div class="item-puesto col card-wrapper" *ngFor="let producto of actividad.productosAsociados">
						<div class="card">
							<div class="grid grid-nogutter">
								<div class="col">
									<span>{{ producto?.producto?.descripcion }}</span>
								</div>
								<div class="col-fixed text-right">
									<div class="badge">{{ producto?.cantidad }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 flex-center">
				<div *ngIf="!esVoucher && !consentimientoPendiente && readonly" class="flex-center">
					<span>
						{{ "PRESENTA_QR" | translate }}
					</span>
					<qrcode imageHeight="12em" imageWidth="12em" [qrdata]="url" [errorCorrectionLevel]="'L'" elementType="url"></qrcode>
				</div>
				<div *ngIf="!esVoucher && consentimientoPendiente && readonly" class="flex-center">
					<span>
						{{ "DEBE_FIRMAR_CONSENTIMIENTO" | translate }}
					</span>
					<button
						*ngIf="!usuario"
						pButton
						pTooltip="{{ 'FIRMAR_CONSENTIMIENTO' | translate }}"
						icon="fa fa-pencil-square-o"
						(click)="firmarConsentimiento()"
						label="{{ 'FIRMAR_CONSENTIMIENTO' | translate }}"
						style="margin: 15px 0"
					></button>
				</div>
				<h2 class="titulo succes" *ngIf="esVoucher && !actividad.actividadCompletada">Voucher valido</h2>
				<h2 class="titulo danger" *ngIf="esVoucher && actividad.actividadCompletada">Voucher ya utilizado</h2>
				<span class="titulo" *ngIf="!consentimientoPendiente && readonly"> #{{ itemReserva.id }} </span>
			</div>
			<div class="col-12" *ngIf="!readonly && !esVoucher">
				<p-checkbox [disabled]="readonly" [(ngModel)]="actividad.confirmado" binary="true" label="{{ 'HE_LEIDO' | translate }}"></p-checkbox>
			</div>

			<!-- <div class="col-12 flex-center mt-1" *ngIf="grupo.actividadIniciada  && grupo.tieneEncuesta && !encuestaRespondida && !esVoucher && !readonly">
                <button pButton pTooltip="{{'COMPLETAR_ENCUESTA' | translate }}" icon="fa fa-pencil-square-o" (click)="mostrarEncuesta()" label="{{'COMPLETAR_ENCUESTA' | translate}}"></button>
            </div> -->
		</div>
	</div>
</ng-template>
