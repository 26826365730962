import { TagUsuario } from "src/app/model/TagUsuario"
import { ConfiguracionUsuario } from "./../../model/ConfiguracionUsuario"
import { Descriptivo } from "./Descriptivo"
import { ProfilePic } from "./ProfilePic"
import { Rol } from "./Rol"

export class Usuario extends Descriptivo {
	esSoporte() {
		return this.username === "soporte"
	}
	public static MODEL_NAME = "usuario"

	private _rolesDependientes: Rol[] = []
	public get rolesDependientes(): Rol[] {
		return this._rolesDependientes
	}
	public set rolesDependientes(v: Rol[]) {
		this._rolesDependientes = v
		this.setRoles(this.rolesNoDependientes, v)
	}

	private _rolesNoDependientes: Rol[] = []
	public get rolesNoDependientes(): Rol[] {
		return this._rolesNoDependientes
	}
	public set rolesNoDependientes(v: Rol[]) {
		this._rolesNoDependientes = v
		this.setRoles(this.rolesDependientes, v)
	}

	constructor(
		id?: number,
		public username?: string,
		public nombre?: string,
		public apellido?: string,
		public activo: boolean = true,
		public bloqueado: boolean = false,
		public email?: string,
		public profilePic = new ProfilePic(null, "assets/images/default_profile.png"),
		public telefono?: string,
		public roles: Rol[] = [],
		public esVerificado: boolean = false,
		public configuracion: ConfiguracionUsuario = new ConfiguracionUsuario(),
		public proveedor?: Descriptivo,
		public cliente?: Descriptivo,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
		public tagsUsuario: TagUsuario[] = [],
		public cajas: Descriptivo[] = [],
		public esExterno: boolean = false
	) {
		super(id + "", username, id)
		this.telefono = telefono
		this.roles = roles
		this.esVerificado = esVerificado
		this.updateRoles()
	}
	entityName(): string {
		return Usuario.MODEL_NAME
	}
	public static fromData(data: any): Usuario {
		if (!data) return null
		let u: Usuario = new Usuario(
			data.id,
			data.username,
			data.nombre,
			data.apellido,
			data.activo,
			data.bloqueado,
			data.email,
			data.profilePic ? ProfilePic.fromData(data.profilePic) : new ProfilePic(),
			data.telefono,
			data.roles ? data.roles.map(Rol.fromData) : [],
			data.esVerificado,
			ConfiguracionUsuario.fromData(data.configuracion),
			Descriptivo.fromData(data.proveedor),
			Descriptivo.fromData(data.cliente),
			data.lastModifiedBy,
			data.lastModifiedDate ? new Date(data.lastModifiedDate) : null,
			data.tagsUsuario ? data.tagsUsuario.map(TagUsuario.fromData) : [],
			data.cajas ? data.cajas.map(Descriptivo.fromData) : [],
			data.esExterno
		)

		return u
	}

	esAdmin() {
		return this.tieneRol([Rol.ADMIN_CODE])
	}
	esJefeAdmin() {
		return this.tieneRol([Rol.JEFE_ADMIN_CODE])
	}
	tieneRol(roles: string[]) {
		for (let rol = 0; rol < roles.length; rol++) if (this.roles.filter((r) => r.codigo === roles[rol]).length > 0) return true

		return false
	}
	tieneRolOAdmin(roles: string[]) {
		return this.esAdmin() || this.tieneRol(roles)
	}

	esSoloVisualizador() {
		return this.roles.length === 1 && this.roles.some((r) => r.codigo == Rol.VISUALIZADOR_CODE)
	}

	get nombreCompleto(): string {
		return this.descripcion
	}

	public get imagen() {
		return this.profilePic
	}
	public setRoles(nuevos: Rol[], base: Rol[] = []) {
		this.roles = [...nuevos, ...base]
		this.roles = this.roles.filter((r) => !r.dependeDe || this.roles.some((r2) => r2.codigo === r.dependeDe))
		this.updateRoles()
	}
	private updateRoles() {
		this._rolesDependientes = this.roles.filter((r) => r.dependeDe)
		this._rolesNoDependientes = this.roles.filter((r) => !r.dependeDe)
	}

	public get json() {
		var ob: any = Object.assign({}, this)
		ob.configuracion = this.configuracion.json
		return ob
	}
	public tieneTag(tag: string) {
		return this.tagsUsuario.some((t) => t.descripcion === tag)
	}
}
