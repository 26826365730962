
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { IdiomaService } from 'src/app/common/services/idioma.service';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { Idioma } from 'src/app/model/Idioma';

@Component({
  selector: 'listado-idioma',
  templateUrl: 'listado-idioma.component.html',
  styleUrls: ['listado-idioma.component.less']
})

export class ListadoIdiomaComponent extends SessionComponent implements OnInit, OnDestroy {

  public filtro: Filtro = new Filtro("idioma_filtros", {}, 0, 10);
  public listado: Idioma[] = [];
  public columns = [
    {
      "field": "id",
      "header": "#",
      "class": "id-column",
      "role": "text-tabla",
      "sortable": true,
      "order": 0
    },
    {
      "field": "codigo",
      "header": "Código",
      "class": "codigo-tabla",
      "role": "text-tabla",
      "sortable": true,
      "order": 1

    },
    {
      "field": "bandera",
      "header": "Bandera",
      "class": "profile-pic",
      "role": "profile-tabla",
      "sortable": false,
      "order": 40
    },
    {
      "field": "descripcion",
      "header": "Descripción",
      "class": "text-tabla",
      "role": "text-tabla",
      "sortable": true,
      "order": 3
    },
    {
      "field": "esDefault",
      "header": "Default",
      "class": "is-boolean",
      "role": "boolean-tabla",
      "sortable": false,
      "order": 40
    },

    {
      "field": "habilitado",
      "header": "Habilitado",
      "class": "is-boolean",
      "role": "boolean-tabla",
      "sortable": false,
      "order": 99
    }
  ];
  constructor(messagesService: MessagesService,
    public service: IdiomaService) {
    super(messagesService)

  }
  ngOnInit() { }
  ngOnDestroy() { }


}