import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
	name: "columnasVisibles"
})
export class ColumnasVisiblesPipe implements PipeTransform {
	transform(value: any[]): any {
		return value.filter((v) => !v["downloadOnly"] && !v["hidden"])
	}
}
