import { Directive, ElementRef, Input } from "@angular/core";
import { NacionalidadEntorno } from "src/app/app.module";
import { Nacionalidad } from "src/app/model/Nacionalidad";
import { AuthService } from "./../../services/auth.service";

@Directive({
	selector: "[paises-habilitados]",
})
export class NacionalidadDependiente {
	private _paisesHabilitados: string[] = [];
	private _paisesOmitidos: string[] = [];
	public get paisesHabilitados(): string[] {
		return this._paisesHabilitados;
	}
	public get paisesOmitidos(): string[] {
		return this._paisesOmitidos;
	}
	@Input("paises-habilitados")
	public set paisesHabilitados(v: string[]) {
		this._paisesHabilitados = v;
		this.checkAllowed();
	}
	@Input("paises-omitidos")
	public set paisesOmitidos(v: string[]) {
		this._paisesOmitidos = v;
		this.checkAllowed();
	}

	constructor(private el: ElementRef, private authService: AuthService) {}

	private checkAllowed() {
		let nacionalidadEntorno = NacionalidadEntorno();
		if (nacionalidadEntorno) {
			if (!this.esHabilitado(nacionalidadEntorno) || this.esOmitido(nacionalidadEntorno)) {
				this.el.nativeElement.style.display = "none";
			} else {
				this.el.nativeElement.style.display = "visible";
			}
		}
	}
	esOmitido(nacionalidadEntorno: Nacionalidad): boolean {
		return this.paisesOmitidos.includes(nacionalidadEntorno.codigo);
	}
	esHabilitado(nacionalidadEntorno: Nacionalidad): boolean {
		return this.paisesHabilitados?.length == 0 || this.paisesHabilitados.includes(nacionalidadEntorno.codigo);
	}
}
