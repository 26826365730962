import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core"
import moment from "moment"
import { OverlayPanel } from "primeng/overlaypanel"
import { filter } from "rxjs"
import { ClientesAG } from "src/app/authguards/ClientesAG"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { TranslateService } from "src/app/common/services/translate.service"
import { ConfiguracionEntorno } from "src/app/model/ConfiguracionEntorno"
import { FiltroCliente } from "src/app/model/FiltroCliente"
import { TotalCliente } from "src/app/model/TotalCliente"
import { Totales } from "src/app/model/Totales"
import { AuthService } from "src/app/services/auth.service"
import { ClienteService } from "src/app/services/cliente.service"
import { ConfiguracionEntornoService } from "src/app/services/configuracion-entorno.service"
import { DashboardConfiguration } from "../../dashboard-element/DashboardConfiguration"
import { IDashboardElement } from "../../dashboard-element/IDashboardElement"
import { SelectorPeriodoDinamicoComponent } from "../selector-periodo-dinamico/selector-periodo-dinamico.component"

@Component({
	selector: "estado-clientes",
	templateUrl: "estado-clientes.component.html",
	styleUrls: ["estado-clientes.component.less"]
})
export class EstadoClientesComponent implements OnInit, IDashboardElement, AfterViewInit {
	public static TIPO = "estado-clientes"
	public data: TotalCliente
	@ViewChild("selectorPeriodo", { static: false })
	public selectorPeriodo: SelectorPeriodoDinamicoComponent
	public customLoading: LoadingService = new LoadingService()
	title: string = this.translateService.get("TOTALES")
	filtro: FiltroCliente = new FiltroCliente("filtro_estado_clientes_home")
	public static defaultConfig: DashboardConfiguration = {
		fixedHeader: false,
		x: null,
		y: null,
		resizeEnabled: false,
		rows: 1,
		cols: 2,
		tipo: "estado-clientes",
		params: { periodo: "1" }
	}

	@ViewChild("op", { static: true }) op: OverlayPanel
	public configuracionEntorno: ConfiguracionEntorno
	periodoSeleccionado: Descriptivo
	constructor(
		private reporteService: ClienteService,
		private authService: AuthService,
		private estadoClienteAG: ClientesAG,
		private configuracionEntornoService: ConfiguracionEntornoService,
		private translateService: TranslateService
	) {
		this.configuracionEntornoService.getConfiguracionObs.subscribe((c) => (this.configuracionEntorno = c))
		//this.fechaDesde = moment().subtract(3, "months").startOf("date").toDate()
	}
	ngAfterViewInit(): void {
		/*if (this._config.params["periodo"] && this.selectorPeriodo) {
			this.selectorPeriodo.seleccionarPeriodoCodigo(this._config.params["periodo"])
		}*/
	}
	textoPeriodo: string
	public _config: DashboardConfiguration
	public get config(): DashboardConfiguration {
		return EstadoClientesComponent.defaultConfig
	}
	@Input()
	public set config(v: DashboardConfiguration) {
		this._config = v
		if (this._config.params["cliente"]) {
			this.filtro.cliente = this._config.params["cliente"]
		}
		if (this._config.params["periodo"] && this.selectorPeriodo) {
			this.selectorPeriodo.seleccionarPeriodoCodigo(this._config.params["periodo"])
		}

		if (this._config.params["fechaDesde"]) {
			this.filtro.fechaDesde = this._config.params["fechaDesde"]
		}
		if (this._config.params["fechaHasta"]) {
			this.filtro.fechaHasta = this._config.params["fechaHasta"]
		}
	}
	public onPeriodoSeleccionado = (periodo: Descriptivo) => {
		if (periodo && this.periodoSeleccionado?.codigo != periodo?.codigo) {
			this.periodoSeleccionado = periodo
			this.config.params["periodo"] = periodo.codigo
			this.textoPeriodo = this.periodoSeleccionado ? this.periodoSeleccionado.descripcion : this.translateService.get("SELECCIONE_UN_PERIO_1")
			this.title = this.translateService.get("TOTALES")
			this.onChange(this.config)
		}
	}
	ngOnInit() {
		if (!this.estadoClienteAG.esVisible(this.authService.getCurrentUser)) return
		this.filtro.dataChange.pipe(filter((d) => d != undefined)).subscribe((d) => {
			this.customLoading.addLoadingCount()
			this.reporteService.getTotales(d).then((r: Totales) => {
				this.data = r
				this.customLoading.susLoadingCount()
			})
		})
		this.filtro.forceUpdate()
	}
	showParams(event) {
		//if (this.op) this.op.toggle(event)
	}
	hideParams() {
		//if (this.op) this.op.hide()
	}
	onChange: (conf: DashboardConfiguration) => void

	public getTitle = () => {
		return this.translateService.get("SALDO_A_LA_FECHA")
	}

	public getTextoPeriodo() {
		return this.periodoSeleccionado?.codigo != "RE"
			? this.textoPeriodo
			: `${moment(this.filtro.fechaDesde).format("DD/MM/YYYY")} a ${moment(this.filtro.fechaHasta).format("DD/MM/YYYY")}`
	}
}
