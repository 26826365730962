<div class="grid" *ngIf="!ocultarBuscador">
	<div class="col-12 lg:col-6">
		<descriptivo-material-selector
			[(ngModel)]="puesto"
			[service]="tipoPuestoService"
			[limpiable]="false"
			[label]="'TIPO_PUESTO' | translate"
			[permiteNuevo]="false"
			[isLazy]="true"
		>
		</descriptivo-material-selector>
	</div>

	<div class="col-12 lg:col-6">
		<span class="p-float-label">
			<p-calendar [(ngModel)]="fecha" appendTo="body" dateFormat="dd/mm/yy"></p-calendar>
			<label>{{ "FECHA" | translate }}</label>
		</span>
	</div>
</div>

<div class="grid grid-nogutter">
	<div class="col-12" *ngIf="opciones?.length">
		<div class="p-inputgroup">
			<span class="p-float-label">
				<input [(ngModel)]="buscador" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
				<label>{{ "BUSCAR" | translate }}...</label>
			</span>
			<button type="button" pButton icon="pi pi-search"></button>
		</div>
	</div>
	<div class="col-12">
		<div class="grid grid-nogutter opciones">
			<div
				class="col-12 puesto-opcion card"
				*ngFor="let opcion of opciones | descriptivoPipe : buscador"
				[ngClass]="{ ocupado: opcion.ocupacion?.length, ausente: opcion.ausencia?.id, noHabitual: opcion.proveedor.noHabitual }"
			>
				<opcion-puesto [opcion]="opcion" [seleccion]="false" [puesto]="puesto"></opcion-puesto>
			</div>
			<div class="col-12 error-buscador" *ngIf="mostrarError && (!opciones || !opciones.length)">
				<h3>{{ "ERROR_BUSCADOR_DISPONIBILIDAD" | translate }}</h3>
			</div>
		</div>
	</div>
</div>
