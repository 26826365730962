import { Injectable } from "@angular/core"
import * as moment from "moment"
import { lastValueFrom } from "rxjs"
import { FiltroMovimiento } from "src/app/common/model/FiltroMovimiento"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Caja } from "src/app/model/Caja"
import { Descriptivo } from "../common/model/Descriptivo"
import { Usuario } from "../common/model/Usuario"
import { LoadingService } from "../common/services/loading-data-service.service"
import { BufferedDescriptivoService } from "./BufferedDescriptivoService"
import { AuthService } from "./auth.service"
import { SaldosTotales } from "./movimiento-caja.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class CajaService extends BufferedDescriptivoService<Caja> {
	private usuario
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
		authService.getUser().subscribe((u) => {
			this.usuario = u
		})
	}
	public baseName(): string {
		return "caja"
	}
	public parseToEnt(data: any): Caja {
		return Caja.fromData(data)
	}
	public newEnt(): Caja {
		return new Caja()
	}
	public getAutorizados() {
		return this.getData().then((r) => r.filter((c) => this.usuario.esAdmin() || c.tieneUsuario(this.usuario)))
	}
	public getSaldo(caja: Caja): Promise<number> {
		var filtro = new FiltroMovimiento("dummy")
		filtro.guardable = false
		filtro.fechaDesde = moment(new Date()).add(1, "day").toDate()
		filtro.formaPago = new Descriptivo("EF")
		filtro.caja = caja

		return this.http
			.post(this.getApiURL() + "movimiento-caja/getSaldoHasta", filtro.json)
			.toPromise()
			.then((s: SaldosTotales) => {
				return s.saldo
			})
	}

	public getHabilitados(f, loadingService: LoadingService = new LoadingService()) {
		return this.getAll(f, loadingService)
	}
	public getAutorizadosUsuario(usuario: Usuario, customLoading: LoadingService = new LoadingService()) {
		if (!usuario.id) {
			Promise.resolve([])
		}
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL("getAllByUsuario/" + usuario.id))).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r.map((f) => Descriptivo.fromData(f))
			},
			(e) => this.handleError(e, customLoading)
		)
	}
}
