import { Parametrico } from "../common/model/Parametrico"
import { PuntoDeVenta } from "./PuntoDeVenta"

export class CanalComunicacion extends Parametrico {
	public static TIPO = "C"
	static EMAIL: CanalComunicacion = new CanalComunicacion(null, "E", "Email", true, true, 0, false, "E")

	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado?: boolean,
		esSistema?: boolean,
		peso?: number,
		esDefault?: boolean,
		public tipo: string = CanalComunicacion.TIPO,
		public puntoDeVenta?: PuntoDeVenta,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
	}
	public static fromData(data: any): CanalComunicacion {
		if (!data) return null
		const o: CanalComunicacion = new CanalComunicacion(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			data.esSistema,
			data.peso,
			data.esDefault,
			data.tipo,
			PuntoDeVenta.fromData(data.puntoDeVenta),
			data.lastModifiedBy,
			data.lastModifiedDate
		)

		o.version = data.version
		return o
	}
}
