import { Pipe, PipeTransform } from "@angular/core"
import { LocalizacionEntorno, MonedaEntorno } from "../app.module"

@Pipe({
	name: "currencyCustom"
})
export class CurrencyCustomPipe implements PipeTransform {
	transform(value: any, moneda?: { codigo?: string; simbolo?: string }, digitInfo: string = "1.1-2"): string {
		return CurrencyCustomPipe.format(value, moneda, digitInfo)
	}

	static format(value: number, moneda?: { codigo?: string; simbolo?: string }, digitInfo: string = "1.1-2") {
		if (value == undefined || value == null) return ""
		let codigo = moneda?.codigo || MonedaEntorno()?.codigo || "ARS"
		let simbolo = moneda?.simbolo || MonedaEntorno()?.simbolo || "$ "

		let [minIntegers, fraction] = (digitInfo || "").split(".")
		let [minFraction, maxFraction] = fraction.split("-").map((d) => +d)

		const local = LocalizacionEntorno() || "en-US"
		return new Intl.NumberFormat(local, {
			style: "currency",
			currency: codigo,
			minimumIntegerDigits: minIntegers !== undefined ? +minIntegers : 1,
			minimumFractionDigits: minFraction !== undefined ? minFraction : 0,
			maximumFractionDigits: maxFraction !== undefined ? maxFraction : 2
		})
			.format(value)
			.replace(new RegExp(codigo, "g"), simbolo)
	}
}
