<div class="gestor-proveedor content content-white" *ngIf="modelName">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="modelName"
		[isModal]="isModal"
		[goBack]="goBack"
		[file]="file"
		[conArchivo]="true"
		[editable]="puedeEditar"
		[readonly]="readonly"
		(onItemGuardado)="handleGuardado($event)"
		(onCancelar)="handleCancelar($event)"
		[form]="ProveedorForm"
	>
		<div class="title small" model-type="title">
			<div class="grid title-tabs">
				<div class="col-12">
					<div class="grid align-items-center">
						<div class="col-12 lg:col">
							<h2 style="display: inline-block">{{ finalTitle }}</h2>
						</div>
						<div class="col-fixed" *ngIf="readonly && puedeEditar">
							<button pButton icon="fa fa-pencil" (click)="editar($event)"></button>
						</div>
						<div class="col-fixed" *ngIf="item.id && esAdministracion">
							<button
								pButton
								icon="fa fa-shopping-cart"
								(click)="cargarFactura()"
								tooltipPosition="bottom"
								pTooltip="{{ 'CARGAR_COMPROBANTE' | translate }}"
								style="margin-left: 1em"
							></button>
						</div>
						<div class="col-fixed" *ngIf="item.id && esAdministracion" [hideExterno]>
							<button
								pButton
								icon="fa fa-money"
								(click)="cargarOP()"
								*ngIf="item.id && esAdministracion"
								tooltipPosition="bottom"
								pTooltip="{{ 'CARGAR_ORDEN_DE_PAGO' | translate }}"
								style="margin-left: 1em"
							></button>
						</div>
						<div class="col-fixed">
							<button
								pButton
								[allowed]="'ROLE_JEFE_ADMIN'"
								icon="fa fa-envelope"
								(click)="enviarAsignaciones($event)"
								tooltipPosition="bottom"
								pTooltip="{{ 'Notificar asignaciones pendientes de confirmación' | translate }}"
								style="margin-left: 1em"
							></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div model-type="dataContent" #dataContent class="grid">
			<div class="col-12 gestor-form">
				<p-tabView orientation="top" (onChange)="changeTab($event)" [(activeIndex)]="activeIndex" *ngIf="!buscandoHotel">
					<p-tabPanel header="{{ 'HOTEL' | translate }}" name="hotel" *ngIf="item.hotel || item.hotelRelacionado">
						<gestor-hotel [(item)]="item.hotelRelacionado" [esProveedor]="true" (nombreChange)="nombreHotelChange($event)"></gestor-hotel>
					</p-tabPanel>
					<p-tabPanel header="{{ 'INFORMACIÓN' | translate }}" name="info">
						<div class="gestor-form">
							<form #ProveedorForm="ngForm" id="ProveedorForm">
								<div class="grid" [hidden]="pantallaActiva != 'data'">
									<div class="col-12 lg:col-3">
										<div class="pic-wrapper">
											<div class="pic">
												<profile-badge
													[seleccionable]="true"
													[url]="item.profilePic?.picPathVersion"
													class="full-badge"
													(onFileSelected)="onPicSelected($event)"
													[disabled]="readonly"
												>
												</profile-badge>
												<i class="fa fa-pencil edit-icon" *ngIf="!readonly"></i>
											</div>
										</div>
									</div>
									<div class="col-12 lg:col-9">
										<div class="grid">
											<div class="col-12 lg:col-3" *ngIf="!vendedor">
												<parametrico-selector
													[allowDefault]="!item?.id"
													[tipoParametro]="'tipo-proveedor'"
													[readonly]="readonly || esTipoHotel()"
													#tipoProveedorDefault="ngModel"
													[(ngModel)]="item.tipoProveedor"
													[limpiable]="true"
													[permiteNuevo]="true"
													name="tipoProveedor"
													[titulo]="'TIPOPROVEEDOR' | translate"
													required
												>
												</parametrico-selector>
											</div>
											<div class="col p-fluid field">
												<span class="p-float-label">
													<input
														pInputText
														[(ngModel)]="item.nombre"
														tooltipPosition="bottom"
														type="text"
														#nombre="ngModel"
														name="nombre"
														[readonly]="readonly"
														required
													/>
													<label>{{ "NOMBRE" | translate }}</label>
												</span>
												<error-tag [model]="nombre"></error-tag>
											</div>
											<div class="col" *ngIf="item.tipoProveedor?.codigo != 'PE' && item.tipoProveedor?.codigo != 'PI'">
												<span class="p-float-label" [ngClass]="{ readonly: readonly }">
													<input
														pInputText
														placeholder="{{ 'RAZÓN_SOCIAL' | translate }}"
														[(ngModel)]="item.razonSocial"
														[readonly]="readonly"
														tooltipPosition="bottom"
														pTooltip="{{ 'RAZÓN_SOCIAL' | translate }}"
														type="text"
														#razonSocial="ngModel"
														name="razonSocial"
													/>
													<label>{{ "RAZÓN_SOCIAL" | translate }}</label>
													<error-tag [model]="razonSocial"></error-tag>
												</span>
											</div>
											<div class="col-12">
												<div class="grid">
													<div class="col-12 lg:col-4">
														<parametrico-selector
															[allowDefault]="!item?.id"
															[tipoParametro]="'tipo-iva'"
															#ivaDefault="ngModel"
															[(ngModel)]="item.iva"
															[limpiable]="true"
															[permiteNuevo]="true"
															[readonly]="readonly"
															name="tipoIva"
															[titulo]="'IVA' | translate"
															required
														>
														</parametrico-selector>
													</div>
													<div class="p-fluid col-12 lg:col-3">
														<parametrico-selector
															[allowDefault]="!item?.id"
															[tipoParametro]="'tipoDocumento'"
															#tipoDocumento="ngModel"
															[(ngModel)]="item.tipoDocumento"
															[permiteNuevo]="false"
															name="tipoDocumento"
															[titulo]="'TIPODOCUMENTO' | translate"
															[required]="true"
															[readonly]="readonly || esExtranjero"
														>
														</parametrico-selector>
													</div>
													<div class="p-fluid col-12 lg:col-3" *ngIf="item?.tipoDocumento?.codigo == '80'">
														<span class="p-float-label field">
															<input
																pInputText
																[readonly]="readonly"
																#numeroDocumento="ngModel"
																cuitValidator
																[(ngModel)]="item.numeroDocumento"
																type="text"
																[disabled]="esExtranjero"
																name="numeroDocumento"
															/>
															<label>{{ "Número de Documento" | translate }} </label>
															<error-tag [model]="numeroDocumento"></error-tag>
														</span>
													</div>
													<div class="p-fluid col-12 lg:col-3" *ngIf="item?.tipoDocumento?.codigo != '80'">
														<span class="p-float-label field">
															<input
																pInputText
																[readonly]="readonly"
																#numeroDocumento="ngModel"
																[(ngModel)]="item.numeroDocumento"
																tooltipPosition="bottom"
																pTooltip="{{ 'NUMERODOCUMENTO_TOOLTIP' | translate }}"
																type="text"
																[disabled]="esExtranjero"
																name="numeroDocumento"
																[required]="item?.tipoDocumento?.codigo != '99'"
															/>
															<label>{{ "Número de Documento" | translate }} </label>
															<error-tag [model]="numeroDocumento"></error-tag>
														</span>
													</div>

													<div class="col-12 lg:col-4">
														<span class="p-float-label" [ngClass]="{ readonly: readonly }">
															<input
																pInputText
																placeholder="{{ 'EMAIL_PH' | translate }}"
																[(ngModel)]="item.email"
																[readonly]="readonly"
																tooltipPosition="bottom"
																pTooltip="{{ 'EMAIL_TOOLTIP' | translate }}"
																type="email"
																#email1="ngModel"
																name="email1"
																appMultiEmailValidator
															/>
															<label>{{ "EMAIL" | translate }}</label>
															<error-tag [model]="email1"></error-tag>
														</span>
													</div>

													<div class="col-12 lg:col-4">
														<span class="p-float-label" [ngClass]="{ readonly: readonly }">
															<input
																pInputText
																placeholder="{{ 'EMAIL_PH' | translate }}"
																[(ngModel)]="item.emailAdministrativo"
																[readonly]="readonly"
																tooltipPosition="bottom"
																pTooltip="{{ 'EMAIL_TOOLTIP' | translate }}"
																type="email"
																#emailAdministrativo="ngModel"
																name="emailAdministrativo"
																appMultiEmailValidator
															/>
															<label>{{ "EMAIL_ADMINISTRATIVO" | translate }}</label>
															<error-tag [model]="emailAdministrativo"></error-tag>
														</span>
													</div>
													<div class="col-12 lg:col-6" *ngIf="idiomas?.length">
														<selector-multiple-chips
															[(ngModel)]="item.idiomas"
															name="idiomas"
															[opciones]="idiomas"
															[permiteNuevo]="false"
															[limpiable]="true"
															[titulo]="'IDIOMAS' | translate"
															[readonly]="readonly"
														>
														</selector-multiple-chips>
													</div>
													<div class="col-12 lg:col-2 align-content-center checkbox-container" [hideExterno]>
														<p-checkbox
															[(ngModel)]="item.noHabitual"
															name="noHabitualCheckBox"
															tooltipPosition="bottom"
															pTooltip="Este prooveedor no es de uso habitual"
															[binary]="true"
															[disabled]="readonly"
															label="No Habitual"
														>
														</p-checkbox>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-12">
										<div class="grid">
											<div class="col-12" *ngIf="!vendedor" [hideExterno]>
												<selector-multiple-chips
													[(ngModel)]="item.tiposPuesto"
													name="tiposPuesto"
													#tiposPuesto="ngModel"
													[servicio]="tiposPuestoService"
													[permiteNuevo]="true"
													[limpiable]="true"
													[titulo]="'Tipos de puesto' | translate"
													[readonly]="readonly"
													[gestor]="gestorPuesto"
												>
												</selector-multiple-chips>
											</div>

											<div class="col-12 lg:col-4">
												<span class="p-float-label" [ngClass]="{ readonly: readonly }">
													<input
														pInputText
														placeholder="{{ 'TELEFONO_PH' | translate }}"
														[(ngModel)]="item.telefono"
														[readonly]="readonly"
														tooltipPosition="bottom"
														pTooltip="{{ 'TELEFONO_TOOLTIP' | translate }}"
														type="text"
														#telefono="ngModel"
														name="telefono"
													/>
													<label>{{ "TELEFONO" | translate }}</label>
													<error-tag [model]="telefono"></error-tag>
												</span>
												<!-- <telefono-selector [(ngModel)]="item.telefono" [ngClass]="{ readonly: readonly }"                                                         #telefono="ngModel"
                                                name="telefono" [disabled]="readonly" [required]="true"></telefono-selector >  -->
											</div>
											<div class="col-12 lg:col-8">
												<span class="p-float-label" [ngClass]="{ readonly: readonly }">
													<input
														pInputText
														placeholder="{{ 'DOMICILIO_PH' | translate }}"
														[(ngModel)]="item.domicilio"
														[readonly]="readonly"
														tooltipPosition="bottom"
														pTooltip="{{ 'DOMICILIO_TOOLTIP' | translate }}"
														type="text"
														#domicilio="ngModel"
														name="domicilio"
													/>
													<label>{{ "DOMICILIO" | translate }}</label>
													<error-tag [model]="domicilio"></error-tag>
												</span>
											</div>

											<div class="col-12 lg:col-6">
												<span class="p-float-label" [ngClass]="{ readonly: readonly }">
													<input
														pInputText
														placeholder="{{ 'FACEBOOK_PH' | translate }}"
														[(ngModel)]="item.facebook"
														[readonly]="readonly"
														tooltipPosition="bottom"
														pTooltip="{{ 'FACEBOOK_TOOLTIP' | translate }}"
														type="text"
														#facebook="ngModel"
														name="facebook"
													/>
													<label>{{ "FACEBOOK" | translate }}</label>
													<error-tag [model]="facebook"></error-tag>
												</span>
											</div>
											<div class="col-12 lg:col-6">
												<span class="p-float-label" [ngClass]="{ readonly: readonly }">
													<input
														pInputText
														placeholder="{{ 'INSTAGRAM_PH' | translate }}"
														[(ngModel)]="item.instagram"
														[readonly]="readonly"
														tooltipPosition="bottom"
														pTooltip="{{ 'INSTAGRAM_TOOLTIP' | translate }}"
														type="text"
														#instagram="ngModel"
														name="instagram"
													/>
													<label>{{ "INSTAGRAM" | translate }}</label>
													<error-tag [model]="instagram"></error-tag>
												</span>
											</div>

											<div class="p-fluid col-12 lg:col-4">
												<span class="p-float-label field">
													<textarea
														pInputTextArea
														[readonly]="readonly"
														#comentario="ngModel"
														name="comentario"
														[class.p-filled]="item?.comentario?.length > 0"
														[(ngModel)]="item.comentario"
														tooltipPosition="bottom"
														pTooltip="{{ 'COMENTARIO_TOOLTIP' | translate }}"
														rows="15"
														style="resize: none; width: 100%; padding: 0.75rem"
													></textarea>
													<label>{{ "COMENTARIO" | translate }}</label>
												</span>
											</div>
											<div class="col-12 lg:col-2" [hideExterno]>
												<span class="p-float-label" [ngClass]="{ readonly: readonly }">
													<input
														pInputText
														placeholder="{{ 'Plazo en días de la cuenta corriente' | translate }}"
														[(ngModel)]="item.plazoCtaCte"
														[readonly]="readonly"
														tooltipPosition="bottom"
														pTooltip="{{ 'Plazo en días de la cuenta corriente' | translate }}"
														type="number"
														#plazoCtaCte="ngModel"
														name="plazoCtaCte"
													/>
													<label>{{ "Plazo Cta Cte" | translate }}</label>
													<error-tag [model]="plazoCtaCte"></error-tag>
												</span>
											</div>
											<div class="col-12 lg:col-3" [hideExterno]>
												<parametrico-selector
													[allowDefault]="!item?.id"
													[tipoParametro]="'forma-pago'"
													#formaPagoDefaultDefault="ngModel"
													[readonly]="readonly"
													[(ngModel)]="item.formaPagoDefault"
													[limpiable]="true"
													[permiteNuevo]="true"
													[service]="formaPagoService"
													name="formaPago"
													[titulo]="'FORMAPAGODEFAULT' | translate"
												>
												</parametrico-selector>
											</div>
											<div class="col-12 lg:col-3 p-fluid field" [hideExterno]>
												<descriptivo-material-selector
													[service]="clienteService"
													[(ngModel)]="item.cliente"
													[ngModelOptions]="{ standalone: true }"
													[isLazy]="true"
													[label]="'CLIENTE' | translate"
													[limpiable]="true"
													[permiteNuevo]="false"
													[readonly]="readonly"
												>
												</descriptivo-material-selector>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</p-tabPanel>
					<p-tabPanel *ngIf="!vendedor && item" header="{{ 'LISTA_DE_PRECIOS' | translate }}">
						<precios-proveedor
							[(items)]="item.insumos"
							[readonly]="readonly || esExterno"
							[proveedor]="item"
							*ngIf="pantallaActiva === 'precios'"
							#preciosProveedor
						></precios-proveedor>
					</p-tabPanel>

					<p-tabPanel header="{{ 'CUENTA_CORRIENTE' | translate }}" *ngIf="item?.id" name="ctacte">
						<div class="grid">
							<div class="col-12 lg:col-8 nopad print:col-12" *ngIf="pantallaActiva === 'ctacte'">
								<cuenta-corriente [filtro]="filtroCuenta" [servicio]="service"> </cuenta-corriente>
							</div>
							<div class="col-12 lg:col-4 print:col-8 print:col-offset-4" *ngIf="totales">
								<totales
									[totalesCliente]="totalesCliente"
									[totalesProveedor]="totales"
									[proveedor]="item"
									[cliente]="item?.cliente"
									[tipoCuenta]="'proveedor'"
									[loadingService]="loadingTotales"
								></totales>
							</div>
						</div>
					</p-tabPanel>
					<p-tabPanel
						header="{{ 'SERVICIOS_PROVISTOS' | translate }}"
						*ngIf="item?.tipoProveedor?.codigo != 'PI' && !vendedor && item?.id && !esExterno"
					>
						<div class="grid">
							<div class="col-12">
								<filtro [(filter)]="filtro" [columns]="columns" [isFixed]="false" [isMinimized]="true" [useSidebar]="true">
									<div class="grid">
										<div class="col-12">
											<div class="p-inputgroup">
												<span class="p-float-label">
													<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
													<label>{{ "BUSCAR" | translate }}...</label>
												</span>
												<button type="button" pButton icon="pi pi-search"></button>
											</div>
										</div>
										<div class="col-12">
											<div class="grid">
												<div class="col-12 lg:col-6 p-fluid">
													<span class="p-float-label">
														<p-calendar [(ngModel)]="filtro.fechaDesde" [required]="true" dateFormat="dd/mm/yy" appendTo="body">
														</p-calendar>
														<label>{{ "FECHA_DESDE" | translate }}</label>
													</span>
												</div>
												<div class="col-12 lg:col-6 p-fluid">
													<span class="p-float-label">
														<p-calendar
															[(ngModel)]="filtro.fechaHasta"
															[required]="true"
															dateFormat="dd/mm/yy"
															appendTo="body"
														></p-calendar>
														<label>{{ "FECHA_HASTA" | translate }}</label>
													</span>
												</div>
											</div>
										</div>
										<div class="col-12 lg:col-4 lg:col-offset-4">
											<p-toggleButton
												onLabel="{{ 'Solo no facturado' | translate }}"
												tooltipPosition="bottom"
												pTooltip="{{ 'Ver solo servicios no facturados' | translate }}"
												offLabel="{{ 'Todos' | translate }}"
												iconPos="right"
												onIcon="{{ 'fa fa-check-square-o' }} "
												offIcon="{{ 'fa fa-square-o' }} "
												[(ngModel)]="filtro.soloNoFacturados"
											></p-toggleButton>
										</div>
									</div>
								</filtro>
							</div>
							<div class="col-12">
								<p-table
									[value]="puestosServicio"
									[rowHover]="true"
									[(selection)]="puestosElegidos"
									dataKey="id"
									sortField="fechaActividad"
									sortMode="single"
									(onHeaderCheckboxToggle)="onHeaderToggle($event)"
								>
									<ng-template pTemplate="caption">
										<div class="acciones">
											<button
												pButton
												class="accion"
												[disabled]="!puedeFacturar"
												(click)="facturar()"
												label="{{ 'FACTURAR' | translate }}"
											></button>
											<button
												type="button"
												pButton
												icon="pi pi-file-excel"
												tooltipPosition="bottom"
												pTooltip="Descargar a excel"
												iconPos="left"
												(click)="exportExcel()"
											></button>
										</div>
									</ng-template>
									<ng-template pTemplate="header">
										<tr>
											<th style="width: 4em">
												<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
											</th>
											<th style="width: 10em" pSortableColumn="fechaActividad">
												{{ "Fecha " | translate }}<p-sortIcon field="fechaActividad"> </p-sortIcon>
											</th>
											<th class="text-center" style="width: 20rem">{{ "Reserva / Grupo" | translate }}</th>
											<th style="width: 15%">{{ "Puesto" | translate }}</th>
											<th style="width: 15rem">{{ "G. Asociado" | translate }}</th>

											<th class="text-center" style="width: 4rem">{{ "Cant." | translate }}</th>
											<th class="text-right" style="width: 12rem">{{ "P. Unit." | translate }}</th>
											<th class="text-right" style="width: 12rem">{{ "TOTAL" | translate }}</th>
											<th class="text-center" style="width: 12em">
												{{ "Comprobante" | translate }}
											</th>
											<th *ngIf="esAdministrador" style="width: 4em"></th>
										</tr>
									</ng-template>
									<ng-template pTemplate="footer">
										<tr>
											<td colspan="5" class="text-right"><span> Cant </span></td>
											<td class="text-center">
												<strong>{{ totalServicios?.cantidad || 0 }}</strong>
											</td>
											<td class="text-right"><span>Total: </span></td>
											<td class="text-right">
												<strong>{{ totalServicios?.monto || 0 | currencyCustom }}</strong>
											</td>

											<td colspan="2" class="text-right" *ngIf="esAdministrador"><span></span></td>
											<td class="text-right" *ngIf="!esAdministrador"><span></span></td>
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
										<tr>
											<td>
												<p-tableCheckbox [value]="rowData" [disabled]="rowData?.comprobante" [index]="rowIndex"></p-tableCheckbox>
											</td>
											<td>
												<span>{{ rowData?.fechaActividad | date : "dd/MM/yyyy" }}</span>
											</td>
											<td class="pr-2 pl-2 p-fluid" style="max-width: 25rem">
												<div class="grid align-content-center" *ngIf="rowData?.idReserva">
													<div class="col-12 nopad">
														<span
															class="clickable chip comprobante text-center"
															*ngIf="rowData?.idReserva"
															(click)="verReserva(rowData?.idReserva)"
															>{{ rowData?.idReserva }}</span
														>
													</div>
													<div class="col-12 overflow nopad text-center">
														<span class="overflow small" tooltipPosition="bottom" pTooltip="{{ rowData?.descripcion }}">{{
															rowData?.descripcion
														}}</span>
													</div>
												</div>
												<div class="grid align-content-center" *ngIf="rowData?.idGrupo && !rowData?.idReserva">
													<div class="col-fixed mr-4 overflow nopad">
														<span class="overflow">{{ "GRUPO" | translate }}</span>
													</div>
													<div class="col-fixed nopad text-center">
														<span
															class="clickable chip comprobante text-center"
															style="width: 12rem"
															*ngIf="rowData?.idGrupo"
															(click)="verGrupo(rowData?.idGrupo)"
															>{{ rowData?.idGrupo }}</span
														>
													</div>
												</div>
												<div class="grid align-content-center" *ngIf="rowData?.usuarioDesasignador">
													<div class="col overflow nopad">
														<span class="overflow">{{ rowData?.usuarioDesasignador }}</span>
														<span class="overflow">{{ rowData?.motivoDesasignacion }}</span>
													</div>
												</div>
											</td>
											<td>
												<div class="grid align-content-center">
													<div class="col-fixed" style="width: 3em">
														<profile-badge [url]="rowData?.tipoPuesto?.imagen" class="small-badge circular"> </profile-badge>
													</div>
													<div class="col">
														<span *ngIf="!rowData.hotel">
															{{ rowData?.tipoPuesto?.descripcion }}
														</span>
														<span *ngIf="rowData.hotel">
															{{ rowData?.hotel?.descripcion }}
														</span>
													</div>
													<div class="col-12 nopad overflow">
														<span class="small" tooltipPosition="bottom" pTooltip="{{ rowData?.producto?.descripcion }}">
															{{ rowData?.producto?.descripcion }}</span
														>
													</div>
												</div>
											</td>
											<td>
												<span class="overflow" tooltipPosition="bottom" pTooltip="{{ rowData?.producto?.descripcion }}">{{
													rowData?.insumo?.descripcion
												}}</span>
											</td>

											<td class="text-center">
												<span
													>{{ rowData?.cantidad }}
													<span class="text-right" tooltipPosition="bottom" pTooltip="{{ rowData?.unidad?.descripcion }}">{{
														rowData?.unidad?.codigo
													}}</span></span
												>
											</td>
											<td class="text-right">
												<span class="text-right">{{ rowData?.importe | currencyCustom : rowData.moneda }}</span>
											</td>
											<td class="text-right">
												<span class="text-right">{{ rowData?.total | currencyCustom : rowData.moneda }}</span>
											</td>
											<td class="text-center">
												<span (click)="verComprobante($event, rowData?.comprobante)" class="link" *ngIf="rowData?.comprobante">{{
													rowData?.comprobante
												}}</span>
											</td>
											<td class="text-center" *ngIf="puedeBorrarGasto">
												<div class="grid">
													<div class="col-12">
														<button
															pButton
															tooltipPosition="bottom"
															pTooltip="{{ 'Eliminar Gasto' | translate }}"
															icon="fa fa-trash"
															(click)="$event.stopPropagation(); borrarGasto(rowData)"
															*ngIf="!rowData?.comprobante"
														></button>
														<button
															pButton
															tooltipPosition="bottom"
															pTooltip="{{ 'Desvincular Gasto' | translate }}"
															icon="fa fa-link"
															(click)="$event.stopPropagation(); desasignarGasto(rowData)"
															*ngIf="rowData?.comprobante && !rowData?.usuarioDesasignador"
														></button>
													</div>
												</div>
											</td>
										</tr>
									</ng-template>
								</p-table>
							</div>
						</div>
					</p-tabPanel>
					<p-tabPanel header="{{ 'COMISIONES' | translate }}" *ngIf="vendedor && item?.id && !esExterno" name="comisiones">
						<div class="grid">
							<div class="col-12">
								<filtro [(filter)]="filtro" [columns]="columns">
									<div class="grid">
										<div class="col-12 lg:col-6">
											<div class="p-inputgroup">
												<span class="p-float-label">
													<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
													<label>{{ "BUSCAR" | translate }}...</label>
												</span>
												<button type="button" pButton icon="pi pi-search"></button>
											</div>
										</div>
										<div class="col-12 lg:col-4">
											<div class="grid">
												<div class="col-12 lg:col-6">
													<span class="p-float-label">
														<p-calendar
															[(ngModel)]="filtro.fechaDesde"
															[required]="true"
															appendTo="body"
															dateFormat="dd/mm/yy"
														></p-calendar>
														<label>{{ "FECHA_DESDE" | translate }}</label>
													</span>
												</div>
												<div class="col-12 lg:col-6">
													<span class="p-float-label">
														<p-calendar
															[(ngModel)]="filtro.fechaHasta"
															[required]="true"
															appendTo="body"
															dateFormat="dd/mm/yy"
														></p-calendar>
														<label>{{ "FECHA_HASTA" | translate }}</label>
													</span>
												</div>
											</div>
										</div>
										<div class="col-12 lg:col-2">
											<p-toggleButton
												onLabel="{{ 'Solo no facturado' | translate }}"
												tooltipPosition="bottom"
												pTooltip="{{ 'Ver solo comisiones no facturadas' | translate }}"
												offLabel="{{ 'Todos' | translate }}"
												iconPos="right"
												onIcon="{{ 'fa fa-check-square-o' }} "
												offIcon="{{ 'fa fa-square-o' }} "
												[(ngModel)]="filtro.soloNoFacturados"
											></p-toggleButton>
										</div>
									</div>
								</filtro>
							</div>
							<div class="col-12">
								<p-table
									[value]="comisiones"
									[rowHover]="true"
									[(selection)]="comisionesSeleccionadas"
									dataKey="id"
									sortField="fecha"
									sortMode="single"
								>
									<ng-template pTemplate="caption">
										<div class="acciones">
											<button
												pButton
												class="accion"
												[disabled]="!puedeFacturarComision"
												(click)="facturarComision()"
												label="{{ 'FACTURAR' | translate }}"
											></button>
										</div>
									</ng-template>
									<ng-template pTemplate="header">
										<tr>
											<th style="width: 4em">
												<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
											</th>
											<th style="width: 14%">Reserva</th>
											<th style="width: 15%">Pax</th>
											<th style="width: 15%">Producto</th>
											<th>Fecha</th>
											<th style="width: 20%">Total</th>
											<th style="width: 20%">Comprobante</th>
											<!-- <th *ngIf="esAdministrador" style="width: 4em;"></th> -->
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
										<tr>
											<td>
												<p-tableCheckbox [value]="rowData" [disabled]="rowData?.comprobante" [index]="rowIndex"></p-tableCheckbox>
											</td>
											<td>
												<div class="grid align-content-center" *ngIf="rowData?.reserva">
													<div class="col-12 nopad">
														<span
															class="clickable chip comprobante text-center"
															*ngIf="rowData?.reserva"
															(click)="verReserva(rowData?.reserva.id)"
															>{{ rowData?.reserva.id }}</span
														>
													</div>
													<div class="col-12 overflow nopad text-center">
														<span class="overflow small" tooltipPosition="bottom" pTooltip="{{ rowData?.reserva.descripcion }}">{{
															rowData?.reserva.descripcion
														}}</span>
													</div>
												</div>
												<!-- <div class="grid  align-content-center" *ngIf="!rowData?.reserva">
                                                <div class="col overflow nopad">
                                                    <span class="overflow">{{'GRUPO' | translate}}</span>
                                                </div>
                                                <div class="col-fixed nopad text-center" style="width: 5em;">
                                                    <span class="clickable chip comprobante text-center"
                                                        *ngIf="rowData?.idGrupo"
                                                        (click)="verGrupo(rowData?.idGrupo)">{{rowData?.idGrupo}}</span>
                                                </div>
                                            </div> -->
											</td>
											<td>
												<span class="overflow" tooltipPosition="bottom" pTooltip="{{ rowData?.producto?.nombrePax }}">{{
													rowData?.itemReserva?.nombrePax
												}}</span>
											</td>
											<td>
												<span class="overflow" tooltipPosition="bottom" pTooltip="{{ rowData?.producto?.descripcion }}">{{
													rowData?.producto?.descripcion
												}}</span>
											</td>
											<td>
												<span>{{ rowData?.fecha | date : "dd/MM/yyyy" }}</span>
											</td>
											<td class="text-right">
												<span class="text-right">{{ rowData?.importe | currencyCustom }}</span>
											</td>
											<td class="text-center">
												<span (click)="verComprobante($event, rowData?.comprobante?.id)" class="link" *ngIf="rowData?.comprobante">{{
													rowData?.comprobante.id
												}}</span>
											</td>
											<!-- <td class="text-center" *ngIf="esAdministrador">
                                            <div class="grid">
                                                <div class="col-12">
                                                    <button pButton  icon="fa fa-trash"
                                                        (click)="$event.stopPropagation(); (rowData)"
                                                        *ngIf="!rowData?.comprobante"></button>
                                                </div>
                                            </div>

                                        </td> -->
										</tr>
									</ng-template>
								</p-table>
							</div>
						</div>
					</p-tabPanel>
					<p-tabPanel header="{{ calendarioHeader }}" *ngIf="item?.id" name="calendario">
						<calendario *ngIf="showCalendar" [proveedor]="item" [readonly]="readonly || esExterno"></calendario>
					</p-tabPanel>
					<p-tabPanel header="{{ 'CONFIRMACIONES' | translate }}" *ngIf="item?.id">
						<servicios-proveedor
							[hideTitle]="true"
							[proveedor]="item"
							[estados]="allEstados"
							*ngIf="!buscandoHotel"
							[interno]="true"
						></servicios-proveedor>
					</p-tabPanel>
				</p-tabView>
			</div>
		</div>
	</model-gestor>
</div>
<p-dialog
	[(visible)]="mostrarGrupo"
	[modal]="true"
	#dialGrupo
	header="{{ grupo?.producto?.descripcion }}"
	[styleClass]="'gestor-dialog'"
	(onShow)="onDialogShow($event, dialGrupo)"
	(onHide)="cancelar()"
>
	<grupo [grupo]="grupo" [full]="true" [bloqueable]="false" (onItemClick)="verActividad($event)" [readonly]="true" *ngIf="grupo"> </grupo>
</p-dialog>

<p-dialog
	[(visible)]="mostrarDetalleActividad"
	[modal]="true"
	#dialActividad
	header="{{ grupo?.producto?.descripcion }}"
	[styleClass]="'gestor-dialog'"
	(onShow)="onDialogShow($event, dialActividad)"
	(onHide)="cancelar()"
>
	<detalle-actividad [(ngModel)]="itemReserva" [editable]="false" *ngIf="itemReserva"> </detalle-actividad>
</p-dialog>

<p-dialog
	[(visible)]="verDesasignacion"
	[modal]="true"
	#dialDesasignacion
	header="Desasignación de gasto"
	[styleClass]="'gestor-dialog'"
	(onShow)="onDialogShow($event, verDesasignacion)"
	(onHide)="verDesasignacion = false"
	[style]="{ width: '20vw' }"
>
	<div class="grid">
		<div class="col-12 advertencia">
			<div class="grid align-items-center">
				<div class="col-fixed" style="width: 2em">
					<i class="fa fa-warning"></i>
				</div>
				<div class="col">
					<p class="text-center">Está desasignando un gasto ya facturado. Esto quitará relaciones con reservas, grupos o servicios.</p>
					<p class="text-center" style="font-size: 110%">
						<strong>Esta acción es irreversible</strong>
					</p>
				</div>

				<div class="col-fixed" style="width: 2em">
					<i class="fa fa-warning"></i>
				</div>
			</div>
		</div>
		<div class="col-12" *ngIf="gastoEditado">
			<div class="grid align-items-center">
				<div class="col-12 lg:col-fixed">
					<label for="">Motivo</label>
				</div>
				<div class="col text-center">
					<textarea [(ngModel)]="gastoEditado.motivoDesasignacion" id="" rows="4" style="width: 100%"></textarea>
				</div>
			</div>
		</div>
		<div class="col-12"><span style="margin-top: 1em">¿Desea desasignando el gasto?</span></div>
	</div>

	<ng-template pTemplate="footer">
		<button pButton label="Cancelar" class="p-button-secondary p-button-outlined" (click)="verDesasignacion = false"></button>
		<button pButton label="Desasignar" class="p-button-danger" (click)="confirmarDesasignacion()"></button>
	</ng-template>
</p-dialog>
<ng-template #gestorPuesto let-handler="handler" let-itemEditado="itemEditado">
	<ng-container *ngIf="itemEditado">
		<gestor-tipo-puesto
			[item]="itemEditado"
			(onGuardado)="handler.onGuardado($event)"
			(onCancelado)="handler.onCancelado($event)"
			[isModal]="true"
			[goBack]="false"
		>
		</gestor-tipo-puesto>
	</ng-container>
</ng-template>
