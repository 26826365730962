import { AdministracionAuthGuard } from './AdministracionAuthGuard';
import { Injectable } from '@angular/core';
import { AdminAuthGuard } from './AdminAuthGuard';






@Injectable()
export class EmpleadoAuthGuard extends AdministracionAuthGuard {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_EMPLEADO", "ROLE_VISUALIZADOR"]) };



}