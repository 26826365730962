<div
	class="p-inputgroup descriptivo-material-selector"
	[ngClass]="{ readonly: readonly, 'con-dropdown': !readonly && !disabled, 'con-addon': limpiable && !disabled && !readonly && seleccionado }"
>
	<span class="p-inputgroup-addon" *ngIf="limpiable && !disabled && !readonly && seleccionado">
		<i class="fa fa-eraser" (click)="clean($event)"></i>
	</span>
	<span class="p-fluid" #inputDS [ngClass]="{ 'no-rounded-right': !readonly && !disabled, ' p-float-label': label && !placeHolder && !hideLabel }">
		<p-autoComplete
			[dataKey]="'codigo'"
			[formControl]="inputControl"
			[suggestions]="dataFiltrada"
			(completeMethod)="filtrar($event.query)"
			[field]="field"
			(onBlur)="autoselect($event); onTouchedCallback($event)"
			[required]="required"
			[readonly]="readonly || disabled"
			forceSelection="true"
			[appendTo]="'body'"
			delay="500"
			[showEmptyMessage]="true"
			[emptyMessage]="emptyMessage || ('NO_SE_ENCONTRARON_RESULTADOS' | translate)"
			[style]="{ width: '100%' }"
			[styleClass]="'descriptivo-selector-autocomplete'"
			[dropdown]="!readonly && !disabled"
			[autoHighlight]="autoHighlight"
			[placeholder]="placeHolder"
			[forceSelection]="true"
			[virtualScroll]="true"
			[virtualScrollOptions]="virtualScrollOptions"
			[virtualScrollItemSize]="36"
			(onShow)="onShowOverlay($event)"
			#autoComplete
			[panelStyleClass]="'descriptivo-selector-autocomplete-panel'"
		>
			<ng-template let-option pTemplate="item">
				<ng-container *ngTemplateOutlet="itemTemplate ? itemTemplate : itemTemplateDefault; context: { $implicit: option }"> </ng-container>
			</ng-template>
			<ng-template let-option pTemplate="selectedItem">
				<ng-container *ngTemplateOutlet="itemTemplate ? itemTemplate : itemTemplateDefault; context: { $implicit: option }"> </ng-container>
			</ng-template>
		</p-autoComplete>
		<label *ngIf="label && !placeHolder && !hideLabel" for="inputgroup">{{ label }} <span class="danger" *ngIf="required && !readonly">*</span></label>
	</span>

	<span class="p-inputgroup-addon" *ngIf="loadingService.loading | async">
		<i class="pi pi-circle-o-notch fa-spin fa-fw fa-2x"></i>
	</span>
</div>
<small *ngIf="inputControl?.invalid && inputControl?.touched" class="p-error block">{{ getErrorMessage(inputControl) }}</small>

<p-dialog
	[(visible)]="editando"
	appendTo="body"
	[modal]="true"
	#dialDetail
	[styleClass]="'no-header gestor-dialog'"
	(onShow)="onDialogShow($event, dialDetail)"
	(onHide)="onHide($event)"
>
	<ng-container *ngIf="editando">
		<ng-container *ngTemplateOutlet="gestor ? gestor : defaultGestor; context: ctx"></ng-container>
	</ng-container>
</p-dialog>
<ng-template #defaultGestor>
	<span>no se definió formulario</span>
</ng-template>
<ng-template #itemTemplateDefault let-option>
	<i [class]="(option?.icon ? option?.icon : 'dummy') + ' mr-2'" *ngIf="aplicaIcono"></i
	><span class="w100 inline-block" *ngIf="option" showDelay="800" tooltipPosition="bottom" pTooltip="{{ option[field] }}"
		>{{ option[field] }}
	</span></ng-template
>

<p-contextMenu [target]="inputDS" [model]="menuItems" appendTo="body" [baseZIndex]="2000" *ngIf="!readonly"> </p-contextMenu>
