<model-help [title]="'Actividades'">
    <p>
        En esta pantalla se puede ver un resumen de las actividades del día. Por defecto la fecha seleccionada es la del día actual, se puede cambiar para ver las actividades de otro día mediante el
        selector en la parte superior de la pantalla (Búsqueda y Filtros) y también desde
        <img src="assets/images/help/hoy-button.jpg" style="margin: 0 5px 5px 5px; width: 80px; vertical-align: middle" /> ubicado en el vértice izquierdo en la tabla de
        <help-link [linkFinal]="'vehiculo'" [textoLink]="'vehículos'"></help-link>.<br />
    </p>

    <h3>Búsqueda y Filtros</h3>
    <p>
        En la parte superior se encuentra la barra de búsqueda y filtro. La Búsqueda se realiza por nombre del Pasajero (PAX) de referencia de cada
        <help-link [linkFinal]="'reserva'" [textoLink]="'reserva'"></help-link>.<br />
        Si se necesita desplegar más opciones de filtro o búsqueda se puede realizar desde el ícono <i class="pi pi-filter"></i> <br />
        Al desplegar las opciones del filtro encontramos:
    </p>
    <ul>
        <li><help-link [linkFinal]="'cliente'" [textoLink]="'Cliente'"></help-link>.</li>
        <li><help-link [linkFinal]="'tipo-vehiculo'" [textoLink]="'Tipo de vehículo'"></help-link>.</li>
        <li><help-link [linkFinal]="'producto'" [textoLink]="'Productos o servicios'"></help-link> (se pueden seleccionar varias opciones).</li>
        <li><help-link [linkFinal]="'vehiculo'" [textoLink]="'Vehículos'"></help-link> sin ocupar: Filtra los vehículos que tienen al menos un lugar disponible en el día de la fecha seleccionado.</li>
        <li>Ver solo sin asignar: Filtra las actividades que tienen algún puesto sin asignar. (Ejemplo: Una excursión a la que aún no se le asignó un guía).</li>
        <li>Ver clienter: muestra bajo el nombre de la reserva el nombre del cliente (solo si es distinto al del pasajero)</li>
        
    </ul>
    <h3>Otras opciones en cabecera</h3>
    <p>
        <strong>Agregar Nota:</strong> Este botón permite agregar notas con fecha de inicio y finalización. Lás mismas aparecen sobre el resumen de actividades, en la sección de calendario y su
        widget, así tambíen como en el widget de notas que puede incluirse en la pantalla de Home del sistema.
    </p>

    <strong>Bloquear:</strong> Bloquea el día completo para que no se puedan realizar nuevas reservas; las reservas que ya fueron realizadas para dicha fecha con anterioridad, pueden cancelarse desde
    la reserva misma.<br />
    <strong>Disponibilidad:</strong> Este botón permite visualizar los diferentes proveedores disponibles para determinado puesto y fecha. Por defecto se guarda el último puesto seleccionado.<br />

    <h3>Panel principal</h3>
    <p>En la primera columna del panel se encuentran listados los vehículos afectados a alguna actividad en el día seleccionado. Vemos la siguiente información descriptiva del mismo:</p>
    <img src="assets/images/help/autito.jpg" /><br />
    <ul>
        <li>Foto</li>
        <li>
            Bloqueo: <i class="fa fa-ban"></i> Haciendo click en este ícono nos abrirá una pantalla emergente que permite bloquear el vehículo entre determinadas fechas y agregar un motivo para dicho
            bloqueo. Como condición no tiene que tener ninguna actividad designada entre las fecha seleccionadas. <br />
        </li>
        <li>Capacidad Máxima: Indicada por el número junto al ícono <i class="fa fa-users"></i></li>
        <li>4x4: Si es un <help-link [linkFinal]="'vehiculo'" [textoLink]="'vehículo'"></help-link> marcado como 4x4 aparecera la inscripción.</li>
        <li>Identificación del vehículo: Patente u otro según corresponda.</li>
        <br />
    </ul>
    <p>Debajo de los vehículos asignados (propios o administrados) se encuentran los vehículos alquilados asignados a una actividad y luego las actividades delegadas a otro prestador.</p>
    <br />

    <p>En la Fila superior del panel encontramos las divisiones por hora (0 a 24) seccionadas cada media hora.</p>
    <img src="assets/images/help/horas.jpg" /><br />
    <p>En las intersecciones de vehículo y horas, se encuentran las actividades con la siguiente información:</p>

    <img src="assets/images/help/card-actividades1.jpg" /><br />
    <p>
        El color de fondo del recuadro general determina si dicha actividad necesita atención en especial, es decir que falta asignar un puesto.<br />
        El color de fondo gris indica que todos los puestos están cubiertos o también que existen puestos a designar pero que son opcionales.
    </p>
    <p>
        Si no se visualiza el detalle completo como en la imagen de ejemplo, es posible que el espacio en pantalla no lo permita, en ese caso al hacer click en el recuadro de la actividad se abrirá
        una pantalla emergente que contendrá la información completa.
    </p>
    <p>De arriba hacia abajo y de izquierda a derecha se pueden encontrar los siguientes iconos e informacion:</p>
    <ul>
        <li>Nombre del servicio.</li>
        <li>Hora del servicio <i class="fa fa-clock"></i></li>
        <li>Actividad finalizada <i class="fa fa-flag"></i> (si este ícono no se encuentra visible es que aún la actividad no está marcada como finalizada).</li>
        <li>Cantidad de PAX en el vehículo asignado. <i class="fa fa-users"></i></li>
        <li>
            Puestos: Los circulos puede estar en blanco ( puestos sin asignar) o contener la foto o ícono del proveedor o personal asignado a ese puesto. Haciendo click se puede asignar o cambiar la
            asignación a otro proveedor.<br />
            El contorno de color del círculo nos indica 3 estados posibles
            <ul>
                <li><span style="color: red">Rojo:</span> no asignado o rechazado por el <help-link [linkFinal]="'proveedor'" [textoLink]="'proveedor'"></help-link>.</li>
                <li><span style="color: yellow; background-color: rgb(185, 185, 185)">Amarillo:</span> Pendiente de confirmación del proveedor</li>
                <li><span style="color: green">Verde:</span> Confirmado o no necesita confirmación</li>
            </ul>
        </li>
        <li>
            Detalle de cada reserva asignada al vehículo: dentro de cada recuadro blanco se encuentran datos de cada reserva con respecto a esa actividad. Para ver el detalle completo se debe hacer
            click en el mismo. (Ver Pantallas emergentes en esta misma ayuda).
            <ul>
                <li>Nombre PAX referente del grupo.</li>
                <li>Cantidad de PAX representados por el ícono <i class="fa fa-user"></i></li>
                <li>Hora pickup: <i class="fa fa-clock"></i> Hora de retiro de los pasajeros si lo hubiera en hotel o punto de encuentro.</li>
                <li><help-link [linkFinal]="'idioma'" [textoLink]="'Idioma'"></help-link> de la reserva: Indicado con imagen de Bandera.</li>
                <li>
                    Estado de <help-link [linkFinal]="'consentimiento'" [textoLink]="'consentimiento'"></help-link>: puede tener 3 iconos de estado.
                    <ul>
                        <li><i class="fa fa-exclamation" style="color: red"></i> Pendiente de envío.</li>
                        <li><i class="fa fa-envelope" style="color: lightblue"></i> Pendiente de respuesta.</li>
                        <li><i class="fa fa-check" style="color: green"></i> Completado.</li>
                    </ul>
                </li>
                <li>Nombre Hotel: <i class="fa fa-building"></i> Hotel por el cual se debe hacer el pickup del PAX (TIP: si se pasa el mouse por encima aparece la dirección del hotel).</li>
                <li>Monto pendiente de cancelación: En caso de existir un monto a cobrar o pendiente de cancelación aparece el icono <i class="fa fa-warning"></i>, seguido del importe.</li>
            </ul>
        </li>
    </ul>

    <h3>Pantallas emergentes importantes</h3>
    <p><strong>Detalle Actividad:</strong></p>
    <img src="assets/images/help/detalle-actividad.jpg" /><br />
    En adición a los íconos e información mencionados anteriormente que aparecen en el recuadro de cada actividad, encontramos las siguientes acciones
    <ul>
        <li>Delegar: <i class="fa fa-send"></i> Asignar el grupo a otro prestador.</li>
        <li>Completar actividad: <i class="fa fa-flag"></i> Indicar que dicha actividad ya ha finalizado.</li>
        <li>Bloquear grupo: <i class="fa fa-lock"></i> No permitir sumar más pasajeros a dicha actividad en ese vehiculo.</li>
    </ul>
    <br />
    <p>
        <strong>Detalle <help-link [linkFinal]="'reserva'" [textoLink]="'reserva'"></help-link> de un grupo en dicha actividad:</strong> <br />
        <img src="assets/images/help/detalle-reserva.jpg" /><br />
        Contiene información relevante, permite actualizar los items de la reserva, navegar hacia la misma, visualizar el voucher correspondiente y los productos asociados .
    </p>
</model-help>
