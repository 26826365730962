import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import * as moment from "moment"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { TotalesActividad } from "src/app/model/TotalesActividad"
import { GrupoPaxService } from "src/app/services/grupo-pax.service"
import { ProductoService } from "src/app/services/producto.service"
import { FiltroProducto } from "./../../producto/FiltroProducto"

@Component({
	selector: "estadisticas-actividades",
	templateUrl: "./estadisticas-actividades.component.html",
	styleUrls: ["./estadisticas-actividades.component.less"]
})
export class EstadisticasActividadesComponent implements OnInit {
	productos: Descriptivo[] = []
	filtro: FiltroProducto = new FiltroProducto("filtro-estadisticas-actividades", null, null, null, null, null, true)
	totalesActividad: TotalesActividad[] = []
	columns: { field: string; header: string }[]

	constructor(public productoService: ProductoService, private grupoService: GrupoPaxService, private router: Router) {}

	ngOnInit() {
		this.columns = [
			{ field: "producto", header: "Producto" },
			{ field: "cantidadPax", header: "Cantidad de Pasajeros" },
			{ field: "promedioPax", header: "Promedio de Pasajeros" },
			{ field: "cantidadVehiculos", header: "Cantidad de vehículos" },
			{ field: "promedioVehiculos", header: "Promedio de vehículos" }
		]
		this.productoService.getServicios().then((r) => {
			this.productos = r
		})
		let f = JSON.parse(localStorage.getItem("filtro-estadisticas-actividades"))
		if (f) {
			this.filtro.patchValue(f)
		}

		if (this.filtro.productos?.length) {
			this.buscar(this.filtro)
		}

		this.filtro.fechaDesde = moment().subtract(30, "days").toDate()
		this.filtro.fechaHasta = new Date()

		this.filtro.valueChange.subscribe((f: FiltroProducto) => {
			if (f.productos?.length) this.buscar(f)
			let s = JSON.stringify(f.json)
			localStorage.setItem("filtro-estadisticas-actividades", s)
		})
	}

	buscar(f) {
		this.grupoService.getTotalesReporteActividades(f).then((t) => {
			this.totalesActividad = t
		})
	}

	eliminar(item: TotalesActividad) {
		this.totalesActividad = [...this.totalesActividad.filter((t) => t.productoId != item.productoId)]
		this.filtro.productos = this.filtro.productos.filter((p) => p.id != item.productoId)
	}

	public goToProducto(event, id) {
		event.stopPropagation()
		this.router.navigate(["producto/vista"], { queryParams: { id: id } })
	}
}
