import * as moment from 'moment';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { Filtro } from './../../common/model/Filtro';
export class FiltroRankingCompras extends Filtro {

    constructor(name: string, page?: number, size?: number, sortField: string = 'peso', order: number = 1, guardable: boolean = true) {
        super(name, {
            categorias: "Categorías",
            proveedor: "Proveedor",
            tipoProveedor: "Tipo Proveedor",
            fechaDesde: "Fecha desde",
            fechaHasta: "Fecha hasta",
            ordenadoPor: "Ordenado por",
        }, page, size, sortField, order, guardable);
    }

    private _categorias: Descriptivo[] = [];
    private _fechaDesde: Date;
    private _fechaHasta: Date;
    private _proveedor: Descriptivo;
    private _moneda: Descriptivo = new Descriptivo('1',null,1); 
    private _tipoProveedor : Descriptivo;

    

    public get moneda(): Descriptivo {
        return this._moneda
    }

    public set moneda(moneda: Descriptivo) {
        this._moneda = moneda;
    }    
   

    private _ordenadoPor: string = "cantidad";
    public get ordenadoPor(): string {

        return this._ordenadoPor;

    }

    public set ordenadoPor(v: string) {
        this._ordenadoPor = v;
        this.sortField = v;
        this.update();

    }
    public get cantidad(): number {
        return this.size;
    }
    public set cantidad(v: number) {
        this.size = v;
        this.update();
    }

    public get categorias(): Descriptivo[] {
        return this._categorias;
    }
    public set categorias(v: Descriptivo[]) {

        this._categorias = v;
        this.update();

    }

    public get proveedor(): Descriptivo {
        return this._proveedor;
    }
    public set proveedor(v: Descriptivo) {
        this._proveedor = v;
        this.update();
    }

    public get fechaDesde(): Date {
        return this._fechaDesde;
    }
    public set fechaDesde(v: Date) {
        this._fechaDesde = v ? moment(v).startOf('day').toDate() : null;
        this.update();
    }

    public get fechaHasta(): Date {
        return this._fechaHasta;
    }
    public set fechaHasta(v: Date) {
        this._fechaHasta = v ? moment(v).endOf('day').toDate() : null;
        this.update();
    }
   
    public get tipoProveedor() : Descriptivo {
        return this._tipoProveedor;
    }
    public set tipoProveedor(v : Descriptivo) {
        this._tipoProveedor = v;
        this.update()
    }

    public clean() {
        super.clean(false);
        let cleanVal = {
            categoria: [],
            verArchivados: false,
            tiposProducto: [],
            proveedor: null,
            fechaDesde: moment().subtract(30, "days").toDate(),
            fechaHasta: null,
            ordenadoPro: "cantidad",
            solorapidos: false,
            centros: [],
            locales: [],
            soloVenta: true,
            propios: null,
            tipoProveedor: null,
        }
        this.patchValue(cleanVal);
    }

    public patchValue(v: any) {
        super.patchValue(v, false);
        this._searchStr = v.searchStr;
       this._categorias = v.categorias ? v.categorias.map(c => Descriptivo.fromData(c)) : [];
        this._proveedor = v.proveedor ? Descriptivo.fromData(v.proveedor) : null;
        this._fechaDesde = v.fechaDesde ? new Date(v.fechaDesde) : moment().subtract(30, "days").toDate();
        this._fechaHasta = v.fechaHasta ? new Date(v.fechaHasta) : null;
        this._ordenadoPor = v.ordenadoPro ? v.ordenadoPor : "cantidad";
        this._tipoProveedor = v.tipoProveedor
        this.update();
    }

    public apply(data: any[]): any[] {
        const searchLike = this.searchStr ? this.searchStr.toUpperCase().split(" ") : null;
        return data.filter(value => (!searchLike || !value['descripcion']
            || searchLike.every(v => value['descripcion'].toUpperCase().includes(v)))
          
            && (!this.categorias?.length || !value['categorias'] || (value['categorias'].length && value['categorias'].filter(cat => this.categorias.findIndex(f => cat.codigo === f.codigo) >= 0))));

    }

}