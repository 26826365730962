export class ReporteActividades {

    constructor( 
        public fechaActividad?: Date,
        public grupoPaxId?: number,
        public vehiculoId?: number,
        public descripcionVehiculo?: string, 
        public grupoReservaId?: number,
        public cantidadPax?: number, 
        public productoId?: number,
        public descripcionProducto?: string
    ) {

    }

    public static fromData(data): ReporteActividades {

        let r = new ReporteActividades(data.fechaActividad,
                                       data.grupoPaxId,
                                       data.vehiculoId,
                                       data.descripcionVehiculo,
                                       data.grupoReservaId,
                                       data.cantidadPax,
                                       data.productoId,
                                       data.descripcionProducto)

        return r

    }

}