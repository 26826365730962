import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { TipoRecordatorio } from "src/app/model/TipoRecordatorio"
import { MessagesService } from "../common/services/messages-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class TipoRecordatorioService extends ParametricoService<TipoRecordatorio> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "tipo-recordatorio"
	}
	public parseToEnt(data: any): TipoRecordatorio {
		return TipoRecordatorio.fromData(data)
	}
	public newEnt(): TipoRecordatorio {
		return new TipoRecordatorio()
	}
}
