import { TipoComprobante } from "src/app/model/TipoComprobante"
import { MonedaEntorno } from "../app.module"
import { ConfiguracionEntornoService } from "../services/configuracion-entorno.service"
import { IConCobros } from "./../common/components/abm-cobros/IConCobros"
import { Descriptivo } from "./../common/model/Descriptivo"
import { FileData } from "./FileData"
import { Idioma } from "./Idioma"
import { Moneda } from "./Moneda"
import { Nacionalidad } from "./Nacionalidad"
import { ResumenReserva } from "./ResumenReserva"
import { TipoCliente } from "./TipoCliente"
import { TipoIVA } from "./TipoIVA"
import { Cobro } from "./cobros/Cobro"
export class GenerarComprobante implements IConCobros {
	get tipoOperacion(): "COMPRA" | "VENTA" {
		return "VENTA"
	}
	public static PARTICULAR = new Descriptivo("P", "Particular")
	public static CONTACTO = new Descriptivo("C", "Contacto")
	public static ADMINISTRACION = new Descriptivo("A", "Administración")
	public static RESERVA = new Descriptivo("R", "Reserva")

	private _reservas: ResumenReserva[] = []
	public get reservas(): ResumenReserva[] {
		return this._reservas
	}
	public set reservas(v: ResumenReserva[]) {
		this._reservas = v
		this.idReservas = this._reservas?.map((r) => r.id)
	}

	constructor(
		public idReservas: number[] = [],
		public enviarComrpoante?: boolean,
		public destinatarios?: string,
		public tipoComprobante?: TipoComprobante,
		public codigoIdioma: string = Idioma.DEFAULT_CODIGO,
		public reenvio: boolean = false,
		public tipoEmail: Descriptivo = GenerarComprobante.PARTICULAR,
		public cobros: Cobro[] = [],
		public moneda?: Moneda,
		public cotizacion: number = 1,
		public cotizacionAfip: number = 1,
		public numeroComprobante?: string,
		public puntoDeVenta?: Descriptivo,
		public datosFacturacionDistintos: boolean = false,
		public tipoDocumentoFacturacion?: Descriptivo,
		public numeroDocumentoFacturacion?: string,
		public nombreFacturacion?: string,
		public emailFacturacion?: string,
		public ivaFacturacion?: TipoIVA,
		public domicilioFacturacion?: String,
		public nacionalidadFacturacion?: Nacionalidad,
		public tipoClienteFacturacion?: TipoCliente,
		public noActualizarCotizacion: Boolean = false,
		public esAdelanto: boolean = false,
		public importe: number = 0,
		public tipoIva: Descriptivo = null,
		public adjunto?: FileData,
		public integrable?: boolean,
		public integrar?: boolean,
		public agrupar?: boolean
	) {}
	public get valores(): Cobro[] {
		return this.cobros
	}
	public set valores(v: Cobro[]) {
		this.cobros = v
	}

	public get json() {
		return JSON.parse(
			JSON.stringify(this, (k, v) => {
				return k != "_reservas" ? v : null
			})
		)
	}
	public get cotizacionAfipSafe() {
		return this.cotizacionAfip || 1
	}
	public set idReserva(id: number) {
		this.idReservas = [id]
	}
	public get idReserva(): number {
		return this.idReservas[0] || null
	}
	public get unicoCliente() {
		return this.reservas.every((r) => r.cliente?.id == this.reservas[0]?.cliente?.id)
	}
	public get total() {
		if (this.importe) return this.importe
		return this._reservas.length > 1 ? 0 : this._reservas.reduce((a, b) => (a += b.saldo * (this.cotizacion || 1)), 0)
	}
	public get totalBase() {
		return this._reservas.length > 1 ? 0 : this._reservas.reduce((a, b) => (a += b.saldo), 0)
	}
	public get totalALocal() {
		const monedaOrigen = this.esAdelanto ? this.moneda : this._reservas[0]?.moneda || null
		const cotizacion = this.getCotizacion(monedaOrigen)
		if (this.esAdelanto) return this.importe * (ConfiguracionEntornoService.CONFIGURACION_ENTORNO?.moneda?.codigo != monedaOrigen?.codigo ? cotizacion : 1)
		return this._reservas.length > 1 ? 0 : this._reservas.reduce((a, b) => (a += b.saldo * cotizacion), 0)
	}
	getCotizacion(monedaOrigen) {
		const cotizacion =
			!monedaOrigen || monedaOrigen?.codigo == ConfiguracionEntornoService.CONFIGURACION_ENTORNO?.moneda?.codigo
				? 1
				: monedaOrigen?.codigo != ConfiguracionEntornoService.CONFIGURACION_ENTORNO?.moneda?.codigo &&
				  this.moneda.codigo == ConfiguracionEntornoService.CONFIGURACION_ENTORNO?.moneda?.codigo
				? this.cotizacion
				: this.cotizacionAfip
		return cotizacion
	}
	getSaldo(): number {
		const monedaOrigen = this.esAdelanto ? this.moneda : this._reservas[0]?.moneda || null
		const cotizacion = this.getCotizacion(monedaOrigen)
		if (this.esAdelanto) return this.importe * cotizacion - this.cobros.reduce((a, b) => (a += b.montoALocal), 0)
		return this._reservas.reduce((a, b) => (a += b.saldo), 0) * cotizacion - this.cobros.reduce((a, b) => (a += b.montoALocal), 0)
	}
	agregarCobro(c: Cobro) {
		this.cobros.push(c)
	}

	buscarCobro(ref) {
		return this.cobros.find((c) => c["refFactura"] == ref)
	}

	quitarCobro(c: Cobro) {
		this.cobros = this.cobros.filter((a) => a != c)
	}
	get cotizacionOperacion() {
		return (this.getMonedaOperacion().codigo == MonedaEntorno().codigo ? this.cotizacion : this.cotizacionAfip) || 1
	}
	reemplazarCobro(c1: Cobro, c2: Cobro) {
		if (!c1 && this.cobros.indexOf(c2) < 0) {
			this.cobros.push(c2)
		} else {
			var i = this.cobros.indexOf(c1)
			if (i >= 0 && c2) {
				this.cobros[i] = c2
			}
		}
	}
	getMonedaOperacion() {
		return (this.esAdelanto ? this.moneda : this._reservas[0]?.moneda) || MonedaEntorno()
	}
}
