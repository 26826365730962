<div class="gestor-provider-configuration content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'PROVIDER_CONFIGURATION' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="ProviderConfigurationForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #ProviderConfigurationForm="ngForm" id="ProviderConfigurationform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-4">
						<descriptivo-material-selector
							[options]="providerOptions"
							[label]="'Provider'"
							[limpiable]="limpiable"
							[(ngModel)]="item.provider"
							[permiteNuevo]="false"
							(onNew)="nuevoproviderOptions()"
							name="provider"
							[readonly]="readonly"
						>
						</descriptivo-material-selector>
					</div>
					<div class="col-12 lg:col-4 p-fluid field">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="API Path"
								[(ngModel)]="item.apiPath"
								type="text"
								#apiPath="ngModel"
								name="apiPath"
								[readonly]="readonly"
							/>
							<label>API Path</label>
							<error-tag [model]="apiPath"></error-tag>
						</span>
					</div>
					<div class="col-12 lg:col-4 p-fluid field">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="API User"
								[(ngModel)]="item.apiUser"
								type="text"
								#apiUser="ngModel"
								name="apiUser"
								[readonly]="readonly"
							/>
							<label>API User</label>
							<error-tag [model]="apiUser"></error-tag>
						</span>
					</div>
					<div class="col-12 lg:col-4 p-fluid field">
						<span class="p-float-label">
							<p-password [(ngModel)]="item.apiPass" [toggleMask]="true" [feedback]="false" name="apiPass"></p-password>
							<label>API Pass</label>
							<error-tag [model]="apiPass"></error-tag>
						</span>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
