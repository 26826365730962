import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router"
import { of } from "rxjs"
import { catchError, filter, first, map } from "rxjs/operators"
import { Usuario } from "../common/model/Usuario"
import { AuthService } from "../services/auth.service"

@Injectable({
	providedIn: "root"
})
export class AdminAuthGuard implements CanActivate {
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (!this.auth.isLoggedIn && !this.auth.token) {
			this.auth.logout(state.url)
			return false
		}
		return this.auth
			.getUser()
			.pipe(
				filter((u) => u !== undefined),
				first()
			)
			.pipe(
				map((u) => {
					return this.esVisible(u)
				}),
				catchError((error) => {
					this.auth.logout(state.url)
					return of(false)
				})
			)
	}

	protected visible: boolean = false
	constructor(protected auth: AuthService, protected router: Router) {}
	public getRoles(): string[] {
		return []
	}

	public esVisible(user: Usuario): boolean {
		return user && user.tieneRol(["ROLE_ADMIN"].concat(this.getRoles()))
	}
}
