import { Descriptivo } from "src/app/common/model/Descriptivo"
import { MonedaEntorno } from "../app.module"
import { round } from "../common/utils/MathUtils"
import { CategoriaProducto } from "./CategoriaProducto"
import { Moneda } from "./Moneda"
import { TipoIVAGrabado } from "./TipoIVAGrabado"

export class ItemComprobanteReporte {
	public monedaCodigo: string
	private monedaLocal: string
	public tipoComprobanteCodigo: string
	constructor(
		public id?: number,
		public idComprobante?: number,
		public numeroComprobante?: string,
		public tipoComprobante?: Descriptivo,
		public fecha?: Date,
		public moneda?: Moneda,
		public cotizacion?: number,
		public cotizacionAfip?: number,
		public precioUnitario?: number,
		public cantidad?: number,
		public totalComprobante?: number,
		public categoriasProducto: CategoriaProducto[] = [],
		public precioUnitarioBruto?: number,
		public descuento?: number,
		public tipoIVA?: TipoIVAGrabado,
		public servicio?: Descriptivo,
		public impuestos?: number
	) {
		this.tipoComprobanteCodigo = tipoComprobante?.codigo || ""
		this.monedaCodigo = moneda?.codigo || ""
		this.monedaLocal = MonedaEntorno()?.codigo || Moneda.PESOS.codigo
	}
	public static fromData(data: any): ItemComprobanteReporte {
		if (!data) return null
		const o: ItemComprobanteReporte = new ItemComprobanteReporte(
			data.id,
			data.idComprobante,
			data.numeroComprobante,
			Descriptivo.fromData(data.tipoComprobante),
			data.fecha ? new Date(data.fecha) : null,
			Moneda.fromData(data.moneda),
			data.cotizacion,
			data.cotizacionAfip,
			data.precioUnitario,
			data.cantidad,
			data.totalComprobante,
			data.categoriasProducto ? data.categoriasProducto.map((c) => CategoriaProducto.fromData(c)) : [],
			data.precioUnitarioBruto,
			data.descuento,
			TipoIVAGrabado.fromData(data.tipoIVA),
			Descriptivo.fromData(data.servicio),
			data.impuestos
		)
		o.precioUnitarioBruto = round(o.precioUnitarioBruto * o.porcentajeDescuento)

		return o
	}

	public getTotal(discrimina) {
		if (discrimina) {
			return this.total
		} else return this.cantidad * this.precioUnitario
	}

	public get totalDiscriminado() {
		const valorIva = this.tipoIVA?.valorIva || 0
		return round(this.precioUnitarioBruto * (1 + valorIva / 100) * this.cantidad)
	}
	public get total() {
		return round(this.subtotal, 2) * this.porcentajeDescuento
	}
	public get cotizacionSafe() {
		if (this.moneda?.codigo === this.monedaLocal) return 1
		return (this.cotizacionAfip != 1 ? this.cotizacionAfip : this.cotizacion) || 1
	}
	public get subtotal() {
		return this.cantidad * this.precioUnitario
	}

	public get porcentajeDescuento() {
		return 1 + this.descuento / 100
	}

	public get categoriasStr() {
		return this.categoriasProducto.map((c) => c.descripcion).join(", ")
	}

	public get descuentoStr() {
		return Math.abs(this.descuento) + "%"
	}

	public get ivaStr() {
		return this.tipoIVA?.valorIva + "%"
	}
	public get impuestosTotal() {
		return this.impuestos * this.cantidad
	}
	public get totalMonedaEntorno() {
		return this.total * this.cotizacionAfip
	}
}
