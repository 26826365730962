import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BreadcrumbService } from './breadcrumb.service';
import { Descriptivo } from './common/model/Descriptivo';
import { DefaultService } from './common/services/default.service';
import { AuthService } from './services/auth.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app.breadcrumb.component.html',
    styleUrls: ["app.breadcrumb.component.less"]
})
export class AppBreadcrumbComponent implements OnDestroy, OnInit {

    subscription: Subscription;
    @ViewChild("op", { static: true }) op;
    _items: MenuItem[] = [];

    public items: BehaviorSubject<MenuItem[]> = new BehaviorSubject([]);
    public localesDisponibles: Descriptivo[] = [];
    public localSeleccionado: Descriptivo;
    constructor(public breadcrumbService: BreadcrumbService,
        public dfService: DefaultService,
        public authService: AuthService,
        public router: Router,) {

    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    ngOnInit() {
        this.subscription = this.breadcrumbService.itemsHandler.subscribe(response => {
            this.items.next(response);
        });

    }

    public nuevoPedido(idTipoEntrega) {
        if (this.localSeleccionado)
            this.router.navigate(["pedido/nuevo"], { queryParams: { localId: this.localSeleccionado.id, tipoEntregaId: idTipoEntrega } });
    }
}
