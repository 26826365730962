import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import * as moment from "moment"
import { Subscription } from "rxjs"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { FiltroMovimiento } from "src/app/common/model/FiltroMovimiento"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { UsuarioService } from "src/app/common/services/usuario.service"
import { Caja } from "src/app/model/Caja"
import { Moneda } from "src/app/model/Moneda"
import { MovimientoCaja } from "src/app/model/MovimientoCaja"
import { CajaService } from "src/app/services/caja.service"
import { FormaPagoService } from "src/app/services/forma-pago.service"
import { MovimientoCajaService } from "src/app/services/movimiento-caja.service"
import { TipoMovimientoService } from "src/app/services/tipo-movimiento.service"

@Component({
	selector: "totales-forma-pago",
	templateUrl: "./totales-forma-pago.component.html",
	styleUrls: ["./totales-forma-pago.component.less"]
})
export class TotalesFormaPagoComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: FiltroMovimiento
	listado = []
	public acciones = []
	public movimientos: MovimientoCaja[] = []
	public totales: {
		formaPago: number
		descripcion: string
		total: number
		imagen: string
		moneda: Moneda
	}[] = []
	public tiposMovimiento: Descriptivo[] = []
	public responsables: Descriptivo[] = []
	public cajas: Caja[] = []
	public caja: Caja
	private routeSub: Subscription
	public columns = [
		{
			field: "descripcion",
			header: "Descripción",
			class: "text-tabla",
			role: "text-tabla",
			sortable: false,
			esMobile: true,
			width: "50%",
			order: 40
		},

		{
			field: "total",
			header: "Total",
			class: "money-tabla",
			role: "money-tabla",
			sortable: false,
			width: "50%",
			order: 40
		}
	]
	public displayFilterBar: boolean = false

	constructor(
		messagesService: MessagesService,
		public service: MovimientoCajaService,
		private router: Router,
		private aRoute: ActivatedRoute,
		private cajaService: CajaService,
		private tipoService: TipoMovimientoService,
		private respService: UsuarioService,
		public formasPagoService: FormaPagoService
	) {
		super(messagesService)
	}

	ngOnInit(): void {
		this.filtro = new FiltroMovimiento("totales-caja")
		this.filtro.layout = "G"
		this.filtro.fechaDesde = moment().subtract(3, "months").toDate()

		this.cajaService.getAutorizados().then((r) => {
			this.cajas.push(new Caja(null, "SC", "Sin Caja Seleccionada"))
			this.cajas = [...this.cajas, ...r]
			if (this.cajas.length == 1 && !this.cajaSeleccionada) {
				this.aplicarSeleccion(this.cajas[0])
			}
		})

		this.tipoService.getDescriptivos().then((ts) => (this.tiposMovimiento = ts))
		this.respService.getDescriptivos().then((us) => (this.responsables = us))
		this.routeSub = this.aRoute.queryParams.subscribe((params) => {
			let id: number = <number>params["id"]
			if (id) {
				setTimeout(() => {
					this.cajaService.getById(id).then((r) => {
						this.aplicarSeleccion(r)
					})
				}, 100)
			}
		})
	}

	getData = (filtro, loading) => {
		// if (!this.cajaSeleccionada) {
		//     return []
		// }
		this.totales = []

		return this.service.getAll(filtro, loading).then((r) => {
			this.movimientos = r

			if (this.movimientos?.length) {
				this.totales = []
				this.movimientos.forEach((movimiento) => {
					let total = this.totales.find((total) => total.formaPago == movimiento.formaPago.id)

					if (total) {
						total.total += movimiento.monto
					} else {
						this.totales.push({
							formaPago: movimiento.formaPago.id,
							descripcion: movimiento.formaPago.descripcion,
							total: movimiento.monto,
							imagen: movimiento.formaPago.imagenUrl,
							moneda: movimiento.moneda
						})
					}
				})
			}
			return this.totales
		})
	}

	public verCajas(event) {
		if (this.cajas.length > 1) {
			this.op.toggle(event)
		}
	}

	private _cajaSeleccionada: Descriptivo
	public get cajaSeleccionada(): Descriptivo {
		if (!this._cajaSeleccionada) {
			this._cajaSeleccionada = this.filtro.caja
		}
		return this._cajaSeleccionada
	}
	public set cajaSeleccionada(v: Descriptivo) {
		this._cajaSeleccionada = v
		this.filtro.caja = v
		this.cajaService.getById(v.id).then((r) => {
			this.aplicarSeleccion(r)
		})
	}
	@ViewChild("op", { static: true }) op

	public aplicarSeleccion(caja: Caja) {
		if (caja?.codigo == "SC") {
			this.caja = null
			this._cajaSeleccionada = null
			this.filtro.caja = null
			this.op.hide()
			return
		}
		this.caja = caja
		this._cajaSeleccionada = Descriptivo.fromData(caja)
		this.filtro.caja = this._cajaSeleccionada
		this.op.hide()
	}
	public showFilterbar(event) {
		event?.stopPropagation && event.stopPropagation()
		this.displayFilterBar = true
	}

	irAListado() {
		const queryParams = this.cajaSeleccionada ? { id: this.cajaSeleccionada.id } : {}

		this.router.navigate(["movimiento-caja"], { queryParams })
	}
}
