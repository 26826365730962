<div class="layout-container">
	<div class="login content">
		<div class="grid login-content">
			<div class="lg:col-6 col-12 left">
				<img src="{{ mainLogo }}" />
			</div>
			<div class="lg:col-6 col-12 right">
				<div class="grid align-content-center justify-content-center" style="margin-top: 1.5em">
					<div class="col-12">
						<span class="p-float-label">
							<input pInputText placeholder="{{ 'PH_EMAIL' | translate }}" [(ngModel)]="email" type="text" required />
							<label>{{ "EMAIL" | translate }}</label>
						</span>
					</div>
					<div class="col-12">
						<button
							pButton
							label="{{ 'RECUPERAR_CONTRASEÑA' | translate }}"
							(click)="recuperarPassword()"
							[disabled]="!verified || !email || finalizado"
						></button>
					</div>
					<re-captcha (resolved)="resolved($event)" [siteKey]="key"></re-captcha>
				</div>
			</div>
		</div>
	</div>
</div>
