
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { Unidad } from 'src/app/model/Unidad';
import { UnidadService } from 'src/app/services/unidad.service';

@Component({
	selector: 'listado-unidad',
	templateUrl: 'listado-unidad.component.html',
	styleUrls: ['listado-unidad.component.less']
})

export class ListadoUnidadComponent extends SessionComponent implements OnInit, OnDestroy {

	public filtro: Filtro = new Filtro("unidad_filtros", {}, 0, 10);
	public listado: Unidad[] = [];
	public columns = [{ "field": "codigo", "header": "Código", "role": "text-tabla", "sortable": true, "width": "10em" },

	{ "field": "descripcion", "header": "Descripción", "role": "text-tabla", "sortable": true },
	{ "field": "esDivisible", "header": "Es Divisible", "class": "is-boolean", "role": "boolean-tabla", "width": "10em" },
	{ "field": "habilitado", "header": "Habilitado", "class": "is-boolean", "role": "boolean-tabla", "width": "10em" },
	{ "field": "esSistema", "header": "De Sistema", "class": "is-boolean", "role": "boolean-tabla", "width": "10em" }
	]
	constructor(messagesService: MessagesService,
		public service: UnidadService) {
		super(messagesService)
	}
	ngOnInit() { }
	ngOnDestroy() { }


}