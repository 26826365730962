import { AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { MenuItem } from "primeng/api"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Comprobante } from "src/app/model/Comprobante"
import { EstadoEntidadIntegracion } from "src/app/model/EstadoEntidadIntegracion"
import { ItemReserva } from "src/app/model/ItemReserva"
import { Reserva } from "src/app/model/Reserva"
import { ResumenReserva } from "src/app/model/ResumenReserva"
import { Totales } from "src/app/model/Totales"
import { CanalVentaService } from "src/app/services/canal-venta.service"
import { ComprobanteService } from "src/app/services/comprobante.service"
import { ConsentimientoService } from "src/app/services/consentimiento.service"
import { CotizacionService } from "src/app/services/cotizacion.service"
import { ListaPreciosService } from "src/app/services/lista-precios.service"
import { MonedaService } from "src/app/services/moneda.service"
import { PuntoDeVentaService } from "src/app/services/punto-de-venta.service"
import { ReservaService } from "src/app/services/reserva.service"
import { TipoComprobanteService } from "src/app/services/tipo-comprobante.service"
import { VendedorService } from "src/app/services/vendedor.service"
import { AccionesTabla } from "./../../../common/model/AccionesTabla"
import { Descriptivo } from "./../../../common/model/Descriptivo"
import { EstadoReserva } from "./../../../model/EstadoReserva"
import { ClienteService } from "./../../../services/cliente.service"
import { EstadoReservaService } from "./../../../services/estado-reserva.service"
import { AccionesReservaComponent } from "./../acciones-reserva/acciones-reserva.component"
import { FiltroReserva } from "./FiltroReserva"

@Component({
	selector: "listado-reserva",
	templateUrl: "listado-reserva.component.html",
	styleUrls: ["listado-reserva.component.less"]
})
export class ListadoReservaComponent extends SessionComponent implements OnInit, OnDestroy, AfterViewInit {
	public displayDevolucion = false
	public hacerDevolucion = true
	public displayMotivo = false
	public totales: Totales = new Totales()
	public item: ResumenReserva = new ResumenReserva()
	public itemsReserva: ItemReserva[] = []
	public newItem: Reserva
	public filtro: FiltroReserva
	public estados: EstadoReserva[] = []
	public totalRecords: number = 0
	public envioItinerario: { idReserva: number; destinatario: string }
	@ViewChild(AccionesReservaComponent)
	public accionesReservaComponente: AccionesReservaComponent
	public verEnvioItinerario: boolean = false
	public columns = []
	@ViewChild("cliente", { static: true }) clienteRef: TemplateRef<any>
	@ViewChild("fecha", { static: true }) fechaRef: TemplateRef<any>

	@ViewChild("idColumn", { static: true }) idColumnRef: TemplateRef<any>
	@ViewChild("comprobante", { static: true }) comprobanteRef: TemplateRef<any>
	@ViewChild("ultimaModificacion", { static: true }) ultimaModificacionRef: TemplateRef<any>
	private _listado: ResumenReserva[] = []
	public get listado(): ResumenReserva[] {
		return this._listado
	}
	public set listado(v: ResumenReserva[]) {
		this._listado = v
	}
	@Input()
	public modoSeleccion: string
	public itemEditado: ResumenReserva
	public itemsMenu: MenuItem[] = []
	public nota: Comprobante
	public acciones: AccionesTabla[] = []
	public opcionesPunto: Descriptivo[] = []
	public vendedores: Descriptivo[] = []
	public esCotizacion: boolean = false
	public externasFallidas: EstadoEntidadIntegracion[] = []
	public estructuraFallida: string

	// private _verModificacion: boolean
	// public get verModificacion(): boolean {
	// 	return this._verModificacion
	// }
	// public set verModificacion(v: boolean) {
	// 	this._verModificacion = v
	// 	this.updateColumnas()
	// }

	// private _verVendedor: boolean
	// public get verVendedor(): boolean {
	// 	return this._verVendedor
	// }
	// public set verVendedor(v: boolean) {
	// 	this._verVendedor = v
	// 	this.updateColumnas()
	// }

	// private _verListaPrecios: boolean
	// public get verListaPrecios(): boolean {
	// 	return this._verListaPrecios
	// }
	// public set verListaPrecios(v: boolean) {
	// 	this._verListaPrecios = v
	// 	this.updateColumnas()
	// }

	constructor(
		messagesService: MessagesService,
		public service: ReservaService,
		public comprobanteService: ComprobanteService,
		public clienteService: ClienteService,
		public estadosService: EstadoReservaService,
		public tipoComprobanteService: TipoComprobanteService,
		public canalService: CanalVentaService,
		public router: Router,
		public cotizacionService: CotizacionService,
		public monedaService: MonedaService,
		public consentimientoService: ConsentimientoService,
		public puntoDeVentaService: PuntoDeVentaService,
		public vendedorService: VendedorService,
		public activeRoute: ActivatedRoute,
		public listaPrecioService: ListaPreciosService
	) {
		super(messagesService)
	}

	ngOnInit() {
		setTimeout(() => {
			this.updateColumnas()
		}, 500)

		const $this = this
		this.subs.push(
			this.activeRoute.data.subscribe((u) => {
				$this.esCotizacion = u?.esCotizacion
				const tag = $this.esCotizacion ? "cotizaciones" : "reservas"
				if ($this.fitlerServices.hasFilter(tag)) {
					$this.filtro = $this.fitlerServices.getFilter(tag)
				} else {
					$this.filtro = new FiltroReserva(tag, 0, 20, "id", 2)
				}

				if (this.esCotizacion) {
					$this.filtro.estados = [EstadoReserva.descriptivo(EstadoReserva.COTIZACION)]
				}
			})
		)
		this.vendedorService.getVendedores().then((v) => {
			this.vendedores = v
		})

		this.estadosService.getHabilitados().then((r) => (this.estados = r))
		if (!this.modoSeleccion) {
			this.modoSeleccion = this.esJefeAdministracion || this.esAdministrador ? "multiple" : "none"
		}

		this.puntoDeVentaService.getAll().then((r) => {
			this.opcionesPunto = r
			if (this.opcionesPunto?.length == 1) {
				this.filtro.puntoDeVenta = this.opcionesPunto[0]
			}
		})
		this.getExternasFallidas()
	}
	ngAfterViewInit() {
		this.accionesReservaComponente &&
			this.accionesReservaComponente.getAcciones().then((v) => {
				this.acciones = v
			})
	}

	private _colSpan: number = 5
	public get colSpan(): number {
		return this._colSpan
	}
	public set colSpan(v: number) {
		this._colSpan = v
	}

	updateColumnas() {
		this.columns = []
		this.columns.push({
			field: "id",
			header: "File",
			class: "id-column estado",
			role: "estado",
			//"template": this.idColumnRef,
			sortable: true,
			order: 1
		})

		this.columns.push({
			field: "cliente",
			header: "Cliente",
			class: "text-tabla",
			role: "template",
			sortable: true,
			template: this.clienteRef,
			order: 2,
			width: "24%",
			esMobile: true
		})

		this.columns.push({
			field: "nombrePax",
			header: "NombrePax",
			downloadOnly: true,
			esMobile: false
		})

		this.columns.push({
			field: "canalVenta",
			header: "Canal",
			class: "text-tabla",
			role: "text-tabla",
			sortable: true,
			order: 3,
			width: "15em",
			esMobile: true
		})

		this.columns.push({
			field: "fechaVencimiento",
			header: "Fecha Vencimiento",
			class: "date-tabla overflow",
			role: "template",
			template: this.fechaRef,
			sortable: true,
			order: 40,
			width: "11em",
			esMobile: true
		})

		this.columns = [
			...this.columns,
			{
				field: "numeroComprobante",
				header: "Comprobante",
				class: "text-center",
				role: "template",
				template: this.comprobanteRef,
				sortable: false,
				order: 40,
				esMobile: true
			},
			{
				field: "monedaCodigo",
				header: "Moneda",
				downloadOnly: true,
				sortable: true,
				order: 40
			},
			{
				field: "montoReserva",
				header: "Monto Reserva",
				class: "money-tabla",
				role: "money-tabla",
				sortable: true,
				order: 40
			},

			{
				field: "saldo",
				header: "Saldo",
				class: "money-tabla",
				role: "money-tabla",
				sortable: true,
				order: 40,
				esMobile: true
			}
		]
		this.columns.push({
			field: "lastModifiedDate",
			header: "Ultima Modificacion",
			class: "text-center",
			role: "template",
			template: this.ultimaModificacionRef,
			width: "12rem",
			sortable: true,
			order: 40,
			esMobile: true,
			optional: true
		})

		this.columns.push({
			field: "nombreVendedor",
			header: "Vendedor",
			role: "text-tabla",
			width: "16rem",
			sortable: true,
			order: 40,
			esMobile: true,
			optional: true
		})

		this.columns.push({
			field: "listadoPrecio",
			header: "Lista de Precios",
			role: "descriptivo-tabla",
			class: "text-center",
			width: "16rem",
			sortable: true,
			order: 40,
			esMobile: true,
			optional: true
		})
		this.columns.push({
			field: "puntoDeVenta",
			header: "PuntoDeVenta",
			role: "descriptivo-tabla",
			class: "text-center",
			width: "12rem",
			sortable: true,
			order: 40,
			esMobile: true,
			optional: true
		})
	}
	ngOnDestroy() {
		if (this.subs) this.subs.forEach((s) => s.unsubscribe())
	}

	cambiarDisplay(v: boolean) {
		this.displayDevolucion = v
	}
	public verComprobante = (comp: number) => {
		if (!comp) return
		this.router.navigate(["comprobante/vista"], { queryParams: { id: comp } })
	}
	public updateFiltros(event) {
		this.filtro.forceUpdate()
	}
	public updateMenuAcciones(s: ResumenReserva[]) {
		this.itemsMenu = []
		this.acciones.forEach((a) => {
			if (a.esVisible(s)) {
				this.itemsMenu.push({
					label: a.label,
					icon: a.icon,
					command: (e) => {
						a.command(s, e)
					},
					visible: true
				})
			}
		})
	}
	public getCount = (f, l) => {
		return this.service.getTotales(f, l).then((r) => {
			this.totales = r
			return Promise.resolve(this.totales.count)
		})
	}

	public verConcentimiento(event, resumenReserva: ResumenReserva) {
		event && event.stopPropagation()
		if (resumenReserva.consentimientoAceptado) {
			this.router.navigate(["consentimiento/vista"], { queryParams: { id: resumenReserva.idConsentimiento } })
		}
	}

	private getExternasFallidas() {
		this.service.getExternasFallidas().then((exs) => {
			this.externasFallidas = exs
		})
	}

	public verMensaje(item: EstadoEntidadIntegracion) {
		this.estructuraFallida = JSON.parse(item.estructuraObtenida)
	}
}
