import { Component, Input, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { Totales } from "src/app/model/Totales"

@Component({
	selector: "totales",
	templateUrl: "./totales.component.html",
	styleUrls: ["./totales.component.less"]
})
export class TotalesComponent implements OnInit {
	private _totalesProveedor: Totales
	public get totalesProveedor(): Totales {
		return this._totalesProveedor
	}
	@Input()
	public set totalesProveedor(v: Totales) {
		this._totalesProveedor = v
	}

	private _totalesCliente: Totales
	public get totalesCliente(): Totales {
		return this._totalesCliente
	}
	@Input()
	public set totalesCliente(v: Totales) {
		this._totalesCliente = v
	}

	@Input()
	proveedor: Descriptivo

	@Input()
	cliente: Descriptivo

	@Input() loadingService: LoadingService = new LoadingService()
	@Input() tipoCuenta: string

	constructor(private router: Router) {}

	ngOnInit(): void {}
	get totalCuentas(): number {
		return Math.abs(this.totalesCliente?.totalSaldo - this.totalesProveedor?.totalSaldo)
	}
	goToProveedor = (p: Descriptivo) => {
		this.router.navigate(["proveedor/vista"], { queryParams: { id: p.id } })
	}
	goToCliente = (c: Descriptivo) => {
		this.router.navigate(["cliente/vista"], { queryParams: { id: c.id } })
	}
}
