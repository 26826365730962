<div class="gestor-insumo content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'INSUMO' | translate"
		[title]="titulo"
		[isModal]="isModal"
		[goBack]="goBack"
		[editable]="true"
		(onItemGuardado)="handleGuardado($event)"
		(onCancelar)="handleCancelar($event)"
		[form]="InsumoForm"
		[readonly]="readonly"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #InsumoForm="ngForm" id="Insumoform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-1">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<input
								pInputText
								[(ngModel)]="item.codigo"
								tooltipPosition="bottom"
								pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
								type="text"
								#codigo="ngModel"
								name="codigo"
								[disabled]="readonly"
								class="p-inputwrapper-filled"
							/>
							<label>{{ "CODIGO" | translate }}</label>
						</span>
					</div>
					<div class="col-12 lg:col-7">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="text"
								#descripcion="ngModel"
								name="descripcion"
								[required]="true"
								[disabled]="readonly"
								class="p-inputwrapper-filled"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
							<error-tag [model]="descripcion"></error-tag>
						</span>
					</div>

					<div class="col-8 lg:col-4">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[tipoParametro]="'categoria-puesto'"
							[(ngModel)]="item.categoria"
							[disabled]="item?.esSistema || readonly"
							[limpiable]="true"
							[permiteNuevo]="true"
							name="categoria"
							[titulo]="'CATEGORIA' | translate"
						>
						</parametrico-selector>
					</div>
					<div class="col-8 lg:col-3">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[tipoParametro]="'unidad'"
							[(ngModel)]="item.unidad"
							[disabled]="item?.esSistema || readonly"
							[limpiable]="true"
							[permiteNuevo]="true"
							name="unidad"
							[titulo]="'UNIDAD' | translate"
							[gestor]="gestorUnidad"
							[required]="true"
						>
							<ng-template #gestorUnidad let-handler="handler" let-itemEditado="itemEditado">
								<gestor-unidad
									[item]="itemEditado"
									(onGuardado)="handler.onGuardado($event)"
									(onCancelado)="handler.onCancelado($event)"
									[isModal]="true"
									[goBack]="false"
								>
								</gestor-unidad>
							</ng-template>
						</parametrico-selector>
					</div>
					<div class="col-6 lg:col-2 align-content-center checkbox-container">
						<p-checkbox
							[(ngModel)]="item.esCostoFijo"
							name="esCostoFijo"
							[binary]="true"
							[disabled]="readonly"
							label="{{ 'ES_COSTO_FIJO' | translate }}"
						>
						</p-checkbox>
					</div>
					<div class="col-6 lg:col-2 align-content-center checkbox-container">
						<p-checkbox
							[(ngModel)]="item.habilitado"
							name="habilitado"
							[binary]="true"
							[disabled]="readonly"
							label="{{ 'HABILITADO' | translate }}"
						>
						</p-checkbox>
					</div>
					<div class="col-6 lg:col-2 align-content-center checkbox-container">
						<p-checkbox
							[(ngModel)]="item.esSubcategorizado"
							name="esSubcategorizado"
							[binary]="true"
							[disabled]="readonly"
							label="{{ 'ES_SUBCATEGORIZADO' | translate }}"
							pTooltip="{{ 'ES_SUBCATEGORIZADO_TOOLTIP' | translate }}"
						>
						</p-checkbox>
					</div>
					<div class="col-12 lg:col-2 md:col-4" *ngIf="!item?.esSubcategorizado">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[service]="tipoIvaGrabadoService"
							name="tipoIVA"
							[readonly]="readonly"
							[(ngModel)]="item.tipoIVA"
							#tipoIVA="ngModel"
							[limpiable]="true"
							[titulo]="'TIPO_DE_IVA' | translate"
						>
						</parametrico-selector>
					</div>
					<div class="col-12 pt-5" *ngIf="item?.esSubcategorizado">
						<span class="p-float-label subtitle">
							<abm-subcategorias-imponibles [item]="item" [readonly]="readonly"></abm-subcategorias-imponibles>
							<label>{{ "SUBCATEGORIA_IMPONIBLE_PLR" | translate }}</label>
						</span>
					</div>
					<div class="seccion col-12">
						<div class="grid">
							<div class="col-12 lg:col-4 md:col-6" *ngFor="let precioProveedor of preciosProveedores">
								<div class="card">{{ "Proveedor" | translate }}: {{ precioProveedor?.descripcion }}</div>
							</div>
						</div>
					</div>
					<div class="seccion col-12" *ngIf="item?.id">
						<button
							pButton
							class="w-15rem"
							label="{{ 'CREAR_SERVICIO_TERCERIZADO' | translate }}"
							(click)="crearServicioTercerizado($event)"
							[disabled]="readonly"
						></button>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>

<p-dialog
	[(visible)]="mostrarSeleccionPuesto"
	[modal]="true"
	#dialDetail
	header="{{ 'ELIJA_UN_TIPO_DE_PU_3' | translate }}"
	[styleClass]="'gestor-dialog'"
	(onShow)="onDialogShow($event, dialDetail)"
>
	<ng-container *ngIf="mostrarSeleccionPuesto">
		<descriptivo-material-selector
			[allowDefault]="!item?.id"
			[(ngModel)]="tipoPuestoElegido"
			[service]="tiposPuestos"
			[isLazy]="true"
			[limpiable]="false"
			[label]="'TIPO_DE_PUESTO' | translate"
			[permiteNuevo]="true"
			name="puesto"
			[gestor]="gestorPuesto"
			required
			(onSelect)="confirmarPuesto($event)"
		>
			<ng-template #gestorPuesto let-handler="handler" let-itemEditado="itemEditado">
				<gestor-tipo-puesto
					[item]="itemEditado"
					(onGuardado)="handler.onGuardado($event)"
					(onCancelado)="handler.onCancelado($event); mostrarSeleccionPuesto = false"
					[isModal]="true"
					[goBack]="false"
					[categoria]="'PE'"
				>
				</gestor-tipo-puesto>
			</ng-template>
		</descriptivo-material-selector>
	</ng-container>
</p-dialog>
