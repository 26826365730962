<div class="gestor-cobranza content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'COBRANZA' | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[verAcciones]="verAcciones"
		[menuItemAcciones]="menuItemAcciones"
		(onCancelar)="handleCancelar($event)"
		[form]="CobranzaForm"
		[multilenguaje]="false"
		[subheader]="item?.id ? 'Estado: ' + item?.estado?.descripcion : null"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #CobranzaForm="ngForm" id="Cobranzaform">
				<div class="grid" *ngIf="item">
					<!--div class='col-12 lg:col-2'>
                        <descriptivo-material-selector [allowDefault]="!item?.id" [allowDefault]="!item?.id"[options]="centroCostoOptions"
                            [label]="'CENTROCOSTO' | translate" [limpiable]="limpiable" [(ngModel)]="item.centroCosto"
                            [permiteNuevo]="permiteNuevo" (onNew)="nuevocentroCostoOptions()" name="centroCosto"
                            [required]="true" [readonly]="readonly">
                        </descriptivo-material-selector>
                    </div-->
					<div class="col-12 lg:col-3" *ngIf="!loading">
						<descriptivo-material-selector
							[allowDefault]="!item?.id"
							[service]="clienteService"
							[isLazy]="true"
							[label]="'CLIENTE' | translate"
							[limpiable]="limpiable"
							[(ngModel)]="item.cliente"
							[permiteNuevo]="false"
							name="cliente"
							[required]="true"
							(onSelect)="updateCliente($event)"
							[readonly]="readonly || item?.items?.length > 0"
						>
						</descriptivo-material-selector>
					</div>
					<div class="col-12 lg:col-3">
						<span class="p-float-label p-fluid">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="text"
								#descripcion="ngModel"
								name="descripcion"
								autocomplete="off"
								[readonly]="readonly"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>
					<div class="col-12 lg:col-2 p-fluid">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'NUMEROCOMPROBANTE_PH' | translate }}"
								[(ngModel)]="item.numeroComprobante"
								tooltipPosition="bottom"
								pTooltip="{{ 'NUMEROCOMPROBANTE_TOOLTIP' | translate }}"
								type="text"
								#numeroComprobante="ngModel"
								name="numeroComprobante"
								[readonly]="readonly"
							/><label>{{ "NUMEROCOMPROBANTE" | translate }}</label>
						</span>
						<error-tag [model]="numeroComprobante"></error-tag>
					</div>
					<div class="col-12 lg:col-2 p-fluid">
						<span class="p-float-label">
							<p-calendar
								[(ngModel)]="item.fecha"
								#fecha="ngModel"
								name="fecha"
								required
								[disabled]="readonly"
								dateFormat="dd/mm/yy"
							></p-calendar>
							<label for="fecha">{{ "FECHA" | translate }}</label>
						</span>

						<error-tag [model]="fecha"></error-tag>
					</div>
					<div class="col-12 lg:col-2">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[service]="monedaService"
							name="moneda_item"
							[ngModel]="item.moneda"
							#moneda="ngModel"
							[titulo]="'MONEDA' | translate"
							[required]="true"
							[readonly]="true"
						>
						</parametrico-selector>
					</div>
					<div class="col-12">
						<div class="grid">
							<div class="col-12">
								<h3 class="subtitle">{{ readonly ? "Comprobantes cobrados" : "Comprobantes a cobrar" }}</h3>
								<p-table
									[value]="itemCobranzaOptions"
									[(selection)]="item.items"
									dataKey="comprobante.id"
									[selectionMode]="editable ? 'multiple' : none"
									[paginator]="true"
									[rows]="5"
									[rowsPerPageOptions]="[5, 10, 20, 50, 100]"
									[first]="0"
								>
									<ng-template pTemplate="header">
										<tr *ngIf="!readonly && !item?.id && itemCobranzaOptions?.length > 0">
											<th colspan="5"><span class="text-right">Imputar cobro</span></th>
											<th colspan="2">
												<div class="imputacion grid align-content-center">
													<div class="col no-space-mat-form">
														<span-dinero-editable
															[label]="'IMPORTE_A_IMPUTAR' | translate"
															[readonly]="readonly"
															[(ngModel)]="importeImputar"
															[editable]="!readonly"
															[moneda]="item.moneda"
															name="importeImputar"
														>
														</span-dinero-editable>
													</div>
													<div class="col-fixed" style="width: 6em">
														<button
															pButton
															label="{{ 'IMPUTAR' | translate }}"
															(click)="imputar()"
															[disabled]="!importeImputar || !itemCobranzaOptions?.length"
														></button>
													</div>
												</div>
											</th>
										</tr>
										<tr>
											<th style="width: 25px; text-overflow: unset" *ngIf="editable">
												<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
											</th>
											<th style="width: 8%">{{ "FECHA" | translate }}</th>
											<th style="width: 11%">{{ "NUMERO" | translate }}</th>
											<th style="width: 30%">{{ "DESCRIPCION" | translate }}</th>
											<th style="width: 13%">{{ "IMPORTE" | translate }}</th>
											<th style="width: 13%">{{ "SALDO" | translate }}</th>

											<th style="width: 15%">{{ "A_PAGAR" | translate }}</th>
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-it let-i="rowIndex">
										<tr [pSelectableRow]="it">
											<td *ngIf="editable">
												<p-tableCheckbox [value]="it"></p-tableCheckbox>
											</td>
											<td>
												<span>{{ it.comprobante.fecha | date : "dd/MM/yyyy" }}</span>
											</td>
											<td>
												<span class="clickable" (click)="goToComprobante($event, it)">{{ getDescripcionComprobante(it) }}</span>
											</td>
											<td>
												<span class="overflow" tooltipPosition="bottom" pTooltip="{{ it.comprobante?.descripcion }}"></span
												>{{ it.comprobante?.descripcion }}
											</td>
											<td class="text-right">
												<span>{{ it.comprobante?.importe | currencyCustom : it.comprobante?.moneda }}</span>
											</td>
											<td class="text-right">
												<div class="col-12">
													<span>{{ it.comprobante?.saldo | currencyCustom : it.comprobante?.moneda }}</span>
													<small class="block" *ngIf="it.moneda?.codigo != it.comprobante?.moneda?.codigo && it.comprobante?.saldo"
														>{{ it.comprobante?.saldo | currencyCustom : it.comprobante?.moneda }}
														x
														{{ it.comprobante?.cotizacionAfip | currencyCustom : monedaEntorno : "1.1-6" }}
													</small>
												</div>
											</td>

											<td class="text-right">
												<span-dinero-editable
													*ngIf="editable"
													[label]="'IMPORTE' | translate"
													[readonly]="readonly"
													(onChange)="importeItemChange($event, it)"
													[required]="true"
													[editable]="editable"
													[(ngModel)]="it.importe"
													name="importe_{{ i }}"
													[moneda]="item.moneda"
												>
												</span-dinero-editable>
												<div class="col-12" *ngIf="!editable">
													<span>{{ it.importe | currencyCustom : it?.moneda }}</span>
													<small class="oneline block" *ngIf="it.moneda?.codigo != it.comprobante?.moneda?.codigo"
														>{{ it.totalEnMonedaOrigen | currencyCustom : it.comprobante?.moneda }}
														x
														{{ it.comprobante?.cotizacionSafe | currencyCustom : monedaEntorno : "1.1-6" }}
													</small>
												</div>
											</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="footer">
										<tr>
											<td *ngIf="editable"></td>
											<td colspan="5" class="text-right">
												<b>{{ (editable ? "TOTAL_A_COBRAR" : "TOTAL_COBRADO") | translate }}:</b>
											</td>
											<td class="text-right">
												{{ item.totalCalculado | currencyCustom : item?.moneda : "1.1-2" }}
											</td>
										</tr>
										<tr *ngFor="let i of item.totalesMonedaExtranjera">
											<td *ngIf="editable"></td>
											<td colspan="5" class="text-right">
												<b>{{ i.moneda?.descripcion }}:</b>
											</td>
											<td class="text-right">
												{{ i.total | currencyCustom : i?.moneda : "1.1-2" }}
											</td>
										</tr>
									</ng-template>
								</p-table>
							</div>
						</div>
					</div>

					<div class="col-12">
						<span class="p-float-label subtitle">
							<abm-cobros
								[item]="item"
								[readonly]="readonly"
								[monedaRef]="item?.moneda"
								class="p-inputwrapper-filled"
								[proveedorAsociado]="proveedor?.id"
							></abm-cobros>
							<label>{{ "COBROS" | translate }}</label>
						</span>
					</div>

					<div class="col-12 totales text-right">
						<div class="grid">
							<div class="col-12 total">
								<span>{{ "TOTAL" | translate }}:</span>
								<span>{{ item?.totalCobrado | currency : "USD" : (item?.moneda?.simbolo || "$") + " " }}</span>
							</div>
							<div class="col-12 error text-right" *ngIf="item.totalCalculado != item.totalCobrado && !readonly">
								<span>{{ "EL_TOTAL_DE_PAGOS_N_38" | translate }}</span>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>

<ng-template #gestorItemCobranza let-handler="handler" let-itemEditado="itemEditado"> </ng-template>

<ng-template #gestorCobro let-handler="handler" let-itemEditado="itemEditado"> </ng-template>

<p-dialog
	[(visible)]="visualizarComprobante"
	appendTo="body"
	[modal]="true"
	[closable]="true"
	header="Ver comprobante"
	#diagComp
	(onShow)="onDialogShow($event, diagComp)"
	[style]="{ width: '65vw' }"
>
	<gestor-comprobante [item]="comprobante" [isModal]="true" [goBack]="false" [readonly]="true" [verAcciones]="false" [hideTitle]="true"> </gestor-comprobante>
</p-dialog>

<p-dialog
	[(visible)]="verAnulacion"
	appendTo="body"
	[modal]="true"
	header="Anulando cobranza"
	#diag
	[styleClass]="'cancelacion-dialog'"
	(onShow)="onDialogShow($event, diag)"
>
	<div class="grid" *ngIf="item?.id">
		<div class="col-12">
			<parametrico-selector
				[allowDefault]="!item?.id"
				[service]="motivoService"
				#motivoAnulacion="ngModel"
				[(ngModel)]="item.motivoCancelacion"
				[limpiable]="true"
				[permiteNuevo]="true"
				name="motivo-cancelacion"
				[titulo]="'MOTIVO_CANCELACION' | translate"
			>
			</parametrico-selector>
		</div>

		<div class="col-12 text-right">
			<button class="col" pButton label="{{ 'CONFIRMAR' | translate }}" (click)="confirmarAnulacion()" [disabled]="!item?.motivoCancelacion"></button>
		</div>
	</div>
</p-dialog>
