import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { ShepherdService } from "angular-shepherd"
import * as moment from "moment"
import { ConfirmationService, MenuItem } from "primeng/api"
import { first } from "rxjs/operators"
import { DEF_LOGO } from "src/app/app.module"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { AccionesTabla } from "src/app/common/model/AccionesTabla"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { AprobacionProveedor } from "src/app/model/AprobacionProveedor"
import { EstadoPuesto } from "src/app/model/EstadoPuesto"
import { Pax } from "src/app/model/Pax"
import { Proveedor } from "src/app/model/Proveedor"
import { ConfiguracionEntornoService } from "src/app/services/configuracion-entorno.service"
import { PuestoServicioReservaService } from "src/app/services/puesto-servicio-reserva.service"
import { Descriptivo } from "../../common/model/Descriptivo"
import { FiltroPuesto } from "../proveedor/FiltroPuesto"

@Component({
	selector: "servicios-proveedor",
	templateUrl: "./servicios-proveedor.component.html",
	styleUrls: ["./servicios-proveedor.component.less"]
})
export class ServiciosProveedorComponent extends SessionComponent implements OnInit {
	private token: string
	private id: number
	validando: boolean
	public listado: AprobacionProveedor[] = []
	public acciones: AccionesTabla[] = []
	public columns = []
	filtro: FiltroPuesto
	mostrarMotivo = false
	rechazos: AprobacionProveedor[] = []
	motivoRechazo: string
	@Input() interno = false

	@ViewChild("producto", { static: true }) productoRef: TemplateRef<any>

	@ViewChild("paxs", { static: true })
	public paxsRef: TemplateRef<any>

	private _proveedor: Proveedor
	public get proveedor(): Proveedor {
		return this._proveedor
	}
	@Input()
	public set proveedor(v: Proveedor) {
		if (v?.id != this._proveedor?.id) {
			this._proveedor = v
			if (!this.filtro) this.crearFiltro()
			this.filtro.personal = v
		} else if (v == null) {
			this.filtro.clean()
		}
	}

	@Input() hideTitle: boolean = false
	@Input() fechaDesde: Date = moment().subtract(30, "days").toDate()
	@Input() estados: EstadoPuesto[] = []
	public allEstados: EstadoPuesto[] = []
	mostrarDatosPaxs
	paxs

	constructor(
		public messagesService: MessagesService,
		public service: PuestoServicioReservaService,
		private route: ActivatedRoute,
		private confirmationService: ConfirmationService,
		private confEntorno: ConfiguracionEntornoService,
		private shepherdService: ShepherdService
	) {
		super(messagesService)
		this.confEntorno.getConfiguracion().then((c) => {
			this.mainLogo = c.profilePic?.picPath || DEF_LOGO
		})
	}
	public menuAcciones: MenuItem[] = []
	ngOnInit() {
		this.route.params.pipe(first()).subscribe((params) => {
			if (params["token"]) {
				this.validando = true
				this.token = params["token"]
				this.id = Number.parseInt(params["id"])
				this.crearFiltro()

				this.filtro.personal = new Descriptivo(null, null, this.id)
				this.filtro.token = this.token
				this.filtro.clean = () => {
					this.filtro.patchValue({ personal: new Descriptivo(null, null, this.id), token: this.token })
				}
			}
		})

		if (this.proveedor) {
			this.crearFiltro()
			this.filtro.personal = Descriptivo.fromData(this.proveedor)
			this.filtro.clean = () => {
				this.filtro.patchValue({ personal: Descriptivo.fromData(this.proveedor) })
			}
		}
		this.allEstados = [EstadoPuesto.CONFIRMADO, EstadoPuesto.PENDIENTE_CONFIRMACION, EstadoPuesto.RECHAZADO, EstadoPuesto.NO_VIGENTE]
		if (this.interno) {
			this.allEstados.push(EstadoPuesto.PENDIENTE_ENVIO)
		}
		this.acciones.push(
			new AccionesTabla(
				this.translateService.get("ACEPTAR"),
				"fa fa-check",
				"",
				(da, event) => {
					let aprobaciones = []
					if (da["puesto"]) {
						aprobaciones.push(da)
					} else {
						aprobaciones = da
					}
					this.aprobar(aprobaciones)
					if (event) event.stopPropagation()
				},
				(data) => {
					return data.estado?.codigo != "CO" && data.estado?.codigo != "RE" && data.estado?.codigo != "NV"
				}
			),
			new AccionesTabla(
				this.translateService.get("RECHAZAR"),
				"fa fa-times",
				"",
				(da, event) => {
					let rechazos = []
					if (da["puesto"]) {
						rechazos.push(da)
					} else {
						rechazos = da
					}
					this.mostrarDialogoMotivo(rechazos)
					if (event) event.stopPropagation()
				},
				(data) => {
					return data.estado?.codigo != "CO" && data.estado?.codigo != "RE" && data.estado?.codigo != "NV"
				}
			)
		)

		this.columns = [
			{
				field: "producto",
				header: "Producto",
				class: "text-tabla",
				role: "template",
				sortable: true,
				template: this.productoRef,
				width: "17em"
			},
			{
				field: "nombrePax",
				header: "Nombre pax",
				role: "text-tabla",
				width: "12em"
			},
			{
				field: "cantidadPax",
				header: "Cant. Pax",
				role: "template",
				sortable: true,
				width: "3em",
				classFn: (data) => {
					if (data.estado?.codigo == EstadoPuesto.NO_VIGENTE.codigo) return "no-valido"
					return "valido"
				},
				template: this.paxsRef
			},
			{
				field: "idReserva",
				header: "Nro. Reserva",
				role: "text-tabla",
				class: "text-center",
				width: "4em"
			},
			{
				field: "idVoucher",
				header: "Voucher",
				role: "text-tabla",
				class: "text-center",
				width: "4em"
			},
			{
				field: this.proveedor?.esHospedaje ? "checkinCheckoutStr" : "fechaActividadStr",
				header: "Fecha",
				role: "text-tabla",
				sortable: true,
				esMobile: true,
				width: "12em",
				classFn: (data) => {
					if (data.estado?.codigo == EstadoPuesto.NO_VIGENTE.codigo) return "no-valido"
					return "valido"
				}
			},
			{
				field: "observaciones",
				header: "Referencia",
				role: "text-editable-tabla",
				editable: (data) =>
					data["estado"].codigo == EstadoPuesto.CONFIRMADO.codigo || data["estado"].codigo == EstadoPuesto.PENDIENTE_CONFIRMACION.codigo,
				sortable: false,
				width: "3em"
			}
		]
		if (this.interno) {
			this.columns.push({
				field: "confirmadoPor",
				header: "Confirmado Por",
				role: "text-tabla",
				width: "7em"
			})
		}
	}

	crearFiltro() {
		this.filtro = new FiltroPuesto("servicio_proveedor_" + (this.token || this.proveedor?.id), 0, 20)

		this.filtro.estados = this.estados?.length
			? this.estados
			: [Descriptivo.fromData(EstadoPuesto.PENDIENTE_CONFIRMACION), Descriptivo.fromData(EstadoPuesto.NO_VIGENTE)]
		this.filtro.soloNoFacturados = false
		this.filtro.fechaDesde = this.fechaDesde || moment().subtract(30, "days").startOf("day").toDate()
	}

	mostrarDialogoMotivo(rechazos: AprobacionProveedor[]) {
		this.mostrarMotivo = true
		this.rechazos = rechazos
	}

	aprobar(g: AprobacionProveedor[]) {
		let promesas = []
		g.forEach((a) => {
			promesas.push(
				this.service.aprobarAsignacion([a], new LoadingService()).catch((e) => {
					this.messagesService.error("Hubo un error actualizando")
					return Promise.reject()
				})
			)
		})
		Promise.all(promesas).then((r) => {
			this.messagesService.success("Aprobado correctamente")
			this.filtro.forceUpdate()
		})
	}

	rechazar() {
		if (!this.motivoRechazo || !this.motivoRechazo.length) {
			return this.messagesService.error(this.translateService.get("INGRESE_MOTIVO"))
		}

		let promesas = []
		this.rechazos.forEach((a) => {
			promesas.push(
				this.service.rechazarAsignacion([a], new LoadingService()).catch((e) => {
					this.messagesService.error("Hubo un error actualizando")
					this.mostrarMotivo = false
					this.rechazos = []
					return Promise.reject()
				})
			)
		})
		Promise.all(promesas).then((r) => {
			this.messagesService.success("Rechazado correctamente")
			this.filtro.forceUpdate()
			this.rechazos = []
			this.mostrarMotivo = false
		})
	}
	groupByVoucher = (r: AprobacionProveedor[]) => {
		return r.sort((a, b) => {
			if (moment(a.fechaActividad).isSame(b.fechaActividad)) {
				if (a.idReserva == b.idReserva) {
					if (a.idVoucher == b.idVoucher) {
						return a.estado?.codigo == EstadoPuesto.NO_VIGENTE.codigo
							? -1
							: b.estado?.codigo == EstadoPuesto.NO_VIGENTE.codigo
							? 1
							: a.estado?.codigo > b.estado?.codigo
							? 1
							: -1
					} else return a.idVoucher > b.idVoucher ? 1 : -1
				} else return a.idReserva > b.idReserva ? 1 : -1
			} else return moment(a.fechaActividad).isAfter(b.fechaActividad) ? 1 : -1
		})
	}

	getData = () => {
		return this.service.getPendientesProveedor(this.filtro).then(this.groupByVoucher)
	}

	getDataByProveedor = () => {
		return this.service.getPendientesByProveedor(this.filtro).then(this.groupByVoucher)
	}

	updateReferencia = (s: AprobacionProveedor) => {
		if (s.estado.codigo == EstadoPuesto.RECHAZADO.codigo) {
			s.observaciones = null
			return this.error("No puede cambiar la referencia de un servicio rechazado")
		} else if (s.estado.codigo != EstadoPuesto.CONFIRMADO.codigo) {
			this.confirmationService.confirm({
				key: "genConf",
				header: "Confirmación de servicio",
				message: "Está cambiando referencia de un servicio no confirmado. Para guardar los cambios debe confirmarlo. Desea confirmar el servicio?",
				accept: () => {
					this.aprobar([s])
				},
				reject: () => {
					s.observaciones = null
				}
			})
		} else if (s.estado.codigo == EstadoPuesto.CONFIRMADO.codigo) {
			let promesas = []
			s["referencias"].forEach((ref) => (ref.observaciones = s.observaciones))
			promesas.push(
				this.service.actualizarReferencia(s["referencias"], new LoadingService()).catch((e) => {
					this.messagesService.error("Hubo un error actualizando")
					return Promise.reject()
				})
			)
			Promise.all(promesas).then((e) => {
				this.success("Referencia actualizada")
			})
		} else {
			s.observaciones = null
			return this.error("Estado invalido de la confirmación. No se puede actualizar la referencia.")
		}
	}

	updateMenuAcciones(s: AprobacionProveedor[]) {
		this.menuAcciones = []
		this.acciones.forEach((a) => {
			this.menuAcciones.push({
				label: a.label,
				icon: a.icon,
				command: (e) => {
					a.command(s, e)
				},
				visible: true
			})
		})
	}

	public mainLogo = DEF_LOGO

	cancelarRechazar() {
		this.mostrarMotivo = false
		this.rechazos = []
	}

	get titulo() {
		return this.proveedor ? `Confirmaciones ${this.proveedor.descripcion}` : `Servicios con ${this.translateService.get("TITULO")}`
	}

	getTooltip(item: AprobacionProveedor) {
		const field = item.modificacion["fecha"] ? "Fecha" : "Cantidad Pax"
		const value = item.modificacion["fecha"] ? moment(new Date(item.modificacion["fecha"])).format("DD/MM/YYYY HH:mm") : item.modificacion["cantidad pax"]
		const valorActual = item.modificacion["fecha"] ? item.fechaActividadStr : item.cantidadPax
		return `Modificado ${field}: ${value} -> ${valorActual} `
	}

	verDatosPaxs(row: any) {
		const self = this
		this.service.getDatosPaxs(row.idReserva, this.filtro).then((paxs: any) => {
			if (paxs?.length > 0) {
				self.mostrarDatosPaxs = true
				self.paxs = paxs
			} else {
				self.messagesService.success("NO_DATOS_PAXS")
			}
		})
		console.log(this.paxs)
	}

	public get _paxs(): Pax[] {
		return this.paxs
	}

	cerrarDatosPaxs() {
		this.mostrarDatosPaxs = false
		this.paxs = undefined
	}

	imprimirDatosPaxs() {
		window.print()
	}

	setTour() {
		const tourShown = localStorage.getItem("tourShownConfirmacionesPaxs")
		if (!tourShown) {
			const defaultShepherdOptions = {
				buttons: [],
				cancelIcon: {
					enabled: true
				},
				highlightClass: "highlight",
				useModalOverlay: true,
				classes: "shepherd-pax"
			}

			this.shepherdService.modal = true
			this.shepherdService.defaultStepOptions = defaultShepherdOptions

			this.shepherdService.addSteps([
				{
					id: "intro",
					attachTo: {
						element: "#step1Pax",
						on: "bottom"
					},
					scrollTo: false,
					title: this.translateService.get("STEP1_VER_DATOS_PAX"),
					modalOverlayOpeningRadius: 5
				}
			])

			localStorage.setItem("tourShownConfirmacionesPaxs", "true")
			this.shepherdService.start()
		}
	}

	handleListadoChange(listado: any) {
		setTimeout(() => {
			if (listado.length) {
				this.setTour()
			}
		}, 1000)
	}
}
