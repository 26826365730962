import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Router } from "@angular/router"
import { ShepherdService } from "angular-shepherd"
import moment from "moment"
import { Table } from "primeng/table"
import { Subscription, map } from "rxjs"
import { MonedaEntorno } from "src/app/app.module"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Insumo } from "src/app/model/Insumo"
import { ListaPrecios } from "src/app/model/ListaPrecios"
import { PrecioInsumo } from "src/app/model/PrecioInsumo"
import { Proveedor } from "src/app/model/Proveedor"
import { TipoIVAGrabado } from "src/app/model/TipoIVAGrabado"
import { InsumoService } from "src/app/services/insumo.service"
import { MonedaService } from "src/app/services/moneda.service"
import { TipoIVAGrabadoService } from "src/app/services/tipo-iva-grabado.service"

@Component({
	selector: "precios-proveedor",
	templateUrl: "./precios-proveedor.component.html",
	styleUrls: ["./precios-proveedor.component.less"]
})
export class PreciosProveedorComponent extends SessionComponent implements OnDestroy, OnInit {
	defaultShepherdOptions = {
		buttons: [
			{
				classes: "p-button p-button-primary fw-700 p-button-icon-only mr-2",
				text: "<<",
				action: () => {
					this.shepherdService.back()
				}
			},
			{
				classes: "p-button p-button-primary fw-700 p-button-icon-only",
				text: ">>",
				action: () => {
					this.shepherdService.next()
				}
			}
		],
		cancelIcon: {
			enabled: true
		},
		highlightClass: "highlight",
		useModalOverlay: true
	}
	public opcionesMarkups: { value: string; tooltip: string }[] = [
		{ value: "M", tooltip: "Markup" },
		{ value: "C", tooltip: "Comisión" },
		{ value: "G", tooltip: "Ganancia" }
	]

	private _tipoMarkupGlobal: string = PrecioInsumo.MARKUP
	public get tipoMarkupGlobal(): string {
		return this._tipoMarkupGlobal
	}
	public set tipoMarkupGlobal(v: string) {
		this._tipoMarkupGlobal = v
		this.selectedProducts.forEach((p) => {
			p.tipoMarkup = v
		})
	}

	public: string = "M"
	public markupGlobal: number = 0
	private routeSub: Subscription
	@ViewChild("tabla") tabla: Table

	@Input() items: PrecioInsumo[] = []
	@Output() itemsChange = new EventEmitter<PrecioInsumo[]>()
	@Input() proveedor: Proveedor
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false
	@Output()
	public onGuardado: EventEmitter<ListaPrecios> = new EventEmitter<ListaPrecios>()
	@Output()
	public onCancelado = new EventEmitter()
	public opcionesEdicion = [
		{ tooltip: "PORCENTAJE", value: "P", icon: "pi pi-percentage" },
		{ value: "M", tooltip: "MONTO", icon: "pi pi-pencil" },
		{ value: "S", tooltip: "SUMA", icon: "pi pi-plus" }
	]
	public opcionesEdicionNuevo = [...this.opcionesEdicion, { value: "E", tooltip: "Mismo valor", icon: "pi pi-pause rotate90" }]

	public showRedondeo: boolean = false
	public showNuevosPrecios: boolean = false
	public digitosSignificativos: number = 0
	public modoEdicion: "M" | "P" | "S" = "P"

	public modoEdicionNuevo: "E" | "M" | "P" | "S" = "P"
	public montoEdicion: number = 0
	public montoEdicionNuevo: number = 0
	@Input()
	public readonly: boolean = false
	public editable: boolean = true
	public insumoOptions: Insumo[] = []
	public mostrarGestorInsumo: boolean = false

	private _fechaPreciosHasta: Date
	public get fechaPreciosHasta(): Date {
		return this._fechaPreciosHasta
	}
	public set fechaPreciosHasta(v: Date) {
		this._fechaPreciosHasta = v
		this.actualizarPrecios()
	}

	private _fechaPrecios: Date
	public get fechaPrecios(): Date {
		return this._fechaPrecios
	}
	public set fechaPrecios(v: Date) {
		this._fechaPrecios = v
		this.actualizarPrecios()
	}
	public verGestioninsumos: boolean = false
	public verGestionPrecios: boolean = false
	public preciosGestionando: PrecioInsumo[] = []
	public insumoGestionando: Descriptivo
	public errorGestionPrecios: string
	public selectedProducts: PrecioInsumo[] = []
	public permitirMasivo: boolean = true
	public verEdicionMasiva: boolean = false
	public desdeMasivo: Date
	public hastaMasivo: Date
	public montoMasivo: number
	public insumo: Insumo
	public suma: boolean = true
	public edicion: boolean = false
	public porcentaje: boolean = false
	public insumosDisponiblesMasivo: Descriptivo[] = []
	public insumosEditarMasivo: Descriptivo[] = []
	public insumosPorFecha: PrecioInsumo[] = []
	public insumosFiltrados: PrecioInsumo[] = []

	private _soloConflictos: boolean = false
	public get soloConflictos(): boolean {
		return this._soloConflictos
	}
	public set soloConflictos(v: boolean) {
		this._soloConflictos = v
		this._filter(this.buscarControl.value)
	}

	constructor(
		messagesService: MessagesService,
		public monedaService: MonedaService,
		private shepherdService: ShepherdService,
		public insumoService: InsumoService,
		public tipoIvaGrabadoService: TipoIVAGrabadoService,
		public router: Router
	) {
		super(messagesService)
	}

	public isValid = () => {
		var valid = true

		this.errorGestionPrecios = null

		this.insumosPorFecha.forEach((p) => {
			delete p["superpone"]
		})
		this.insumosPorFecha.forEach((elementoActual) => {
			this.insumosPorFecha
				.filter(
					(elementoSiguiente) =>
						elementoActual.insumo?.id === elementoSiguiente.insumo?.id &&
						elementoActual !== elementoSiguiente &&
						elementoActual.moneda?.id === elementoSiguiente.moneda?.id &&
						elementoActual.aplicaRango(elementoSiguiente.fechaDesde, elementoSiguiente.fechaHasta) &&
						elementoActual.superponeMaxMin(elementoSiguiente.minPax, elementoSiguiente.maxPax)
				)
				.forEach((elementoSiguiente) => {
					elementoActual["superpone"] = true
					elementoSiguiente["superpone"] = true
				})
		})
		this.insumosPorFecha
			.filter((p) => p["superpone"] || (!p.precio && !p.id))
			.forEach((p) => {
				if (p["superpone"]) {
					valid = valid && this.error(this.translateService.get("EXISTEN_FECHAS_SUPERPUESTAS").replace("${1}", p.insumo.descripcion))
				}
				if (!p.precio && !p.id) {
					valid = valid && this.error(this.translateService.get("EXISTEN_INSUMOS_SIN_PRECIO").replace("${1}", p.insumo.descripcion))
				}
			})

		return valid
	}

	private _filter(d: string) {
		this.insumosFiltrados = [
			...this.insumosPorFecha
				.filter(
					(p) =>
						(this.soloConflictos ? p["superpone"] || !p.precio : true) &&
						(!d || (p.insumo && p.insumo.descripcion.toUpperCase().includes(d.toUpperCase())))
				)
				.sort((a, b) => {
					return a.insumo.descripcion > b.insumo.descripcion ? 1 : -1
				})
		]
	}
	public buscarControl: FormControl = new FormControl()
	public ivaDefault: TipoIVAGrabado
	ngOnInit() {
		this.tipoIvaGrabadoService.getDefault().then((i) => (this.ivaDefault = i))
		this.subs.push(
			this.buscarControl.valueChanges
				.pipe(
					map((v) => {
						if (typeof v === "string") {
							return v.toUpperCase()
						} else {
							return ""
						}
					})
				)
				.subscribe((d: string) => {
					this._filter(d)
				})
		)

		setTimeout(() => {
			this.fechaPrecios = new Date()
		}, 300)

		this.insumoService.getAll().then((data) => {
			this.insumoOptions = data.filter((i) => !this.items.some((p) => p.insumo.id == i.id))
		})
	}
	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}
	actualizarPrecios() {
		this.insumosPorFecha = this.items ? this.getInsumosPorFecha(this.fechaPrecios, this.fechaPreciosHasta) : []
		this._filter(this.buscarControl.value)
		this.itemsChange.emit(this.items)
	}
	gestionarPrecios(p: PrecioInsumo) {
		this.preciosGestionando = this.items
			? this.items.filter((pi) => pi.insumo.id == p.insumo.id).sort((a, b) => (a.fechaDesde < b.fechaDesde ? 1 : -1))
			: []
		this.insumoGestionando = p.insumo
		this.verGestionPrecios = true
	}

	cancelarGestionPrecios() {
		this.preciosGestionando = []
		this.verGestionPrecios = false
	}
	nuevoPrecioParaSeleccionados(item?: PrecioInsumo) {
		this.montoEdicionNuevo = 0
		this.modoEdicionNuevo = "E"
		if (item) this.selectedProducts = [item]
		this.showNuevosPrecios = true
	}
	aplicarMarkup() {
		this.selectedProducts.forEach((p) => {
			p.markup = this.markupGlobal
		})
	}
	aplicarNuevo() {
		this.selectedProducts
			.reduce((resultado, objeto) => {
				const existe = resultado.some((item) => item.insumo.id === objeto.insumo.id)
				if (!existe) {
					resultado.push(objeto)
				}
				return resultado
			}, [])
			.forEach((p) => {
				this.nuevoPrecio(p, this.modoEdicionNuevo, this.montoEdicionNuevo)
			})
		this.selectedProducts = []
		this.actualizarPrecios()
		this.showNuevosPrecios = false
	}

	public nuevoInsumo() {
		this.insumo = new Insumo()
		this.mostrarGestorInsumo = true
	}
	public guardarNuevoInsumo(insumo: Insumo) {
		this.insumoOptions = [...this.insumoOptions, insumo]
		this.mostrarGestorInsumo = false
	}
	public cancelarNuevoInsumo() {
		this.mostrarGestorInsumo = false
	}
	copiarSeleccionados() {
		this.selectedProducts.forEach((p) => {
			this.copiarPrecio(p)
		})
		this.selectedProducts = []
		this.actualizarPrecios()
	}
	// preGuardado = () => {
	// 	this.item &&
	// 		this.item.insumos.forEach((p) => {
	// 			p.fechaDesde = moment(p.fechaDesde).startOf("day").toDate()
	// 			if (p.fechaHasta) p.fechaHasta = moment(p.fechaHasta).endOf("day").subtract(10, "seconds").toDate()
	// 		})
	// }
	nuevoPrecio = (precio: PrecioInsumo, modoEdicion?: string, montoEdicion?: number) => {
		const precioMayor = this.items
			? this.items
					.filter((p) => p.insumo.id == precio.insumo.id && p.minPax == precio.minPax && p.maxPax == precio.maxPax)
					.sort((a, b) => (a.fechaDesde > b.fechaDesde ? -1 : 1))[0]
			: null
		let nuevoPrecio: PrecioInsumo
		if (!precioMayor) {
			nuevoPrecio = new PrecioInsumo()
			nuevoPrecio.insumo = precio.insumo
			nuevoPrecio.fechaDesde = new Date()
		} else {
			if (!precioMayor.fechaHasta) {
				let now = moment().endOf("date").toDate()
				precioMayor.fechaHasta = precioMayor.fechaDesde > now ? moment(precioMayor.fechaDesde).endOf("date").subtract(10, "seconds").toDate() : now
			}
			nuevoPrecio = new PrecioInsumo()
			nuevoPrecio.insumo = precio.insumo
			nuevoPrecio.fechaDesde = moment(precioMayor.fechaHasta).add(1, "days").startOf("day").toDate()
		}
		nuevoPrecio.minPax = precioMayor?.minPax || null
		nuevoPrecio.maxPax = precioMayor?.maxPax || null
		if (modoEdicion && (montoEdicion || modoEdicion == "E")) {
			this.actualizarValor(
				nuevoPrecio,
				this.digitosSignificativos,
				this.montoEdicionNuevo,
				this.modoEdicionNuevo,
				precioMayor.precio,
				precioMayor.precioMinimo
			)
		}
		nuevoPrecio.moneda = precioMayor?.moneda || MonedaEntorno()
		nuevoPrecio.tipoIVA = precioMayor?.tipoIVA || this.tipoIvaGrabadoService.default
		nuevoPrecio.markup = precioMayor?.markup || 0
		this.items && this.items.push(nuevoPrecio)
		this.animarModificado(nuevoPrecio)
		this.actualizarPrecios()
	}
	copiarPrecio = (precio: PrecioInsumo) => {
		let nuevoPrecio = PrecioInsumo.fromData({ ...precio, id: null })
		this.items && this.items.push(nuevoPrecio)
		this.animarModificado(nuevoPrecio)
		this.actualizarPrecios()
	}
	animarModificado(p: any) {
		p["modificado"] = true
		setTimeout(() => {
			delete p["modificado"]
		}, 1000)
	}

	eliminarPrecio(precio: PrecioInsumo) {
		this.items = this.items.filter((p) => p.key != precio.key)
		this.actualizarPrecios()
	}

	get insumosUnicos() {
		return this.items ? this.items.filter((p, i, arr) => arr.findIndex((pp) => pp.insumo.id === p.insumo.id) === i)?.map((r) => r.insumo) : []
	}
	addinsumo(event) {
		event.items.forEach((p) => {
			this.items = [
				...this.items,
				new PrecioInsumo(null, new Date(), null, null, null, MonedaEntorno(), null, null, Descriptivo.fromData(this.proveedor), p, this.ivaDefault)
			]
			this.insumoOptions = this.insumoOptions.filter((pp) => pp.id != p.id)
		})
		this.actualizarPrecios()
	}
	removeinsumo(event) {
		event.items.forEach((p) => {
			this.items = this.items.filter((pp) => pp.insumo.id != p.id)
		})
		this.actualizarPrecios()
	}
	public aplicarAjuste() {
		this.digitosSignificativos = 2
		this.showRedondeo = true
	}
	private actualizarValor(p: PrecioInsumo, digitos: number, valorUpdate: number, modo: string, base?: number, baseMinimo?: number) {
		const precioBase = base || p.precio || 0
		const minimoBase = baseMinimo || p.precioMinimo || 0
		const factor = Math.pow(10, digitos - 2)
		switch (modo) {
			case "E":
				p.precio = p.precio
			case "S":
				p.precio = precioBase + valorUpdate
				p.precioMinimo = minimoBase + valorUpdate
				break
			case "P":
				p.precio = precioBase * (1 + valorUpdate / 100)
				p.precioMinimo = minimoBase * (1 + valorUpdate / 100)
				break
			case "M":
				p.precio = valorUpdate
				p.precioMinimo = p.precioMinimo || minimoBase
				break
		}
		if (digitos !== 0) {
			p.precio = Math.round(p.precio / factor) * factor
			p.precioMinimo = Math.round(p.precioMinimo / factor) * factor
		}
		p["modificado"] = true
	}
	public confirmarRedondeo(base?: number) {
		const factor = Math.pow(10, this.digitosSignificativos - 2)
		this.selectedProducts.forEach((p) => {
			this.actualizarValor(p, this.digitosSignificativos, this.montoEdicion, this.modoEdicion)
		})
		setTimeout(() => {
			this.insumosFiltrados.forEach((p) => {
				delete p["modificado"]
			})
			this.insumosFiltrados = [...this.insumosFiltrados]
		}, 1000)
		this.montoEdicion = 0
		this.modoEdicion = "P"
		this.selectedProducts = []
		this.showRedondeo = false
	}
	public goToInsumo(event, idInsumo) {
		event && event.stopPropagation()
		if (this.esExterno) return
		let newRelativeUrl = this.router.createUrlTree(["insumo/vista"], {
			queryParams: { id: idInsumo }
		})
		let baseUrl = window.location.href.replace(this.router.url, "")
		window.open(baseUrl + newRelativeUrl, "_blank")
		event.stopPropagation()
	}
	get scrollHeight() {
		if (this.isMobile()) return "30vh"

		return window.innerWidth < 1500 ? "50vh" : "75vh"
	}

	getInsumosPorFecha(desde: Date, hasta: Date) {
		const data = this.items
			.filter((p) => p.aplicaRango(desde, hasta))
			.sort((a, b) => (a.insumo.id == b.insumo.id ? (a.fechaDesde >= a.fechaHasta ? -1 : 1) : a.insumo.descripcion > b.insumo.descripcion ? 1 : -1))

		return data
	}
}
