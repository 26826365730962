import * as moment from 'moment'

export class JobConfiguration {
    public descripcion:string
    public estado:string
    public version?: number;
    constructor(public id?: number, public jobId?: string, public identificacion?: string, public activo?: boolean, public horaDeEjecucion?: Date) {
      this.descripcion = jobId;
      this.estado = activo ? 'On' : 'Off';
    }
    public static fromData(data: any): JobConfiguration {
        if (!data) return null
        const o: JobConfiguration = new JobConfiguration(data.id, data.jobId, data.identificacion, data.activo, data.horaDeEjecucion ? 
          new Date(data.horaDeEjecucion) : null)
          o.version = data.version;
        return o
    }
}
