<div class="layout-sidebar-filter p-fluid">
	<p-autoComplete
		[(ngModel)]="searchStr"
		[suggestions]="results"
		(completeMethod)="search($event)"
		field="label"
		placeholder="{{ 'BUSCAR_OPCIÓN' | translate }}..."
		(onSelect)="menuPick($event)"
	></p-autoComplete>
</div>
<ul class="layout-menu">
	<li app-menuitem *ngFor="let item of menuConfService?.menuBS | async; let i = index" [item]="item" [index]="i" [root]="item.items?.length"></li>
</ul>
