<div class="gestor-tipo-iva-grabado content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'TIPO-IVA-GRABADO' | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		(onCancelar)="handleCancelar($event)"
		[form]="TipoIVAGrabadoForm"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #TipoIVAGrabadoForm="ngForm" id="TipoIVAGrabadoform">
				<div class="grid" *ngIf="item">
					<div class="col-fixed w-10rem">
						<span class="p-float-label field">
							<input
								pInputText
								placeholder="{{ 'PESO_PH' | translate }}"
								[(ngModel)]="item.peso"
								tooltipPosition="bottom"
								pTooltip="{{ 'PESO_TOOLTIP' | translate }}"
								type="number"
								#peso="ngModel"
								name="peso"
							/><label>{{ "PESO" | translate }}</label>
							<error-tag [model]="peso"></error-tag>
						</span>
					</div>
					<div class="col-fixed w-10rem">
						<span class="p-float-label field">
							<input
								pInputText
								placeholder="{{ 'VALORIVA_PH' | translate }}"
								[(ngModel)]="item.valorIva"
								tooltipPosition="bottom"
								pTooltip="{{ 'VALORIVA_TOOLTIP' | translate }}"
								type="number"
								#valorIva="ngModel"
								name="valorIva"
								[readonly]="readonly"
							/><label>{{ "VALORIVA" | translate }}</label>
							<error-tag [model]="valorIva"></error-tag>
						</span>
					</div>
					<div class="col-fixed w-10rem">
						<span class="p-float-label field">
							<input
								pInputText
								placeholder="{{ 'CODIGO_PH' | translate }}"
								[(ngModel)]="item.codigo"
								tooltipPosition="bottom"
								pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
								type="text"
								#codigo="ngModel"
								name="codigo"
							/><label>{{ "CODIGO" | translate }}</label>
							<error-tag [model]="codigo"></error-tag>
						</span>
					</div>
					<div class="col-12 lg:col-4">
						<span class="p-float-label field">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="text"
								#descripcion="ngModel"
								name="descripcion"
							/><label>{{ "DESCRIPCION" | translate }}</label>
							<error-tag [model]="descripcion"></error-tag>
						</span>
					</div>
				</div>
				<div class="col-12 lg:col-3">
					<p-toggleButton
						onLabel="{{ 'HABILITADO' | translate }}"
						offLabel="{{ 'HABILITADO' | translate }}"
						iconPos="left"
						name="habilitado"
						onIcon="{{ 'fa fa-check-square-o' }} "
						offIcon="{{ 'fa fa-square-o' }} "
						[(ngModel)]="item.habilitado"
					></p-toggleButton>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
