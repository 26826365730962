import { Component, Input, OnInit, ViewChild } from "@angular/core"
import moment from "moment"
import { OverlayPanel } from "primeng/overlaypanel"
import { BehaviorSubject } from "rxjs"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { TranslateService } from "src/app/common/services/translate.service"
import { FiltroReporteEncuesta } from "src/app/model/FiltroReporteEncuesta"
import { ProductoService } from "src/app/services/producto.service"
import { DashboardConfiguration } from "../../dashboard-element/DashboardConfiguration"
import { IDashboardElement } from "../../dashboard-element/IDashboardElement"

@Component({
	selector: "reporte-prevision-servicios-home",
	templateUrl: "./reporte-prevision-servicios-home.component.html",
	styleUrls: ["./../dashboard-element-common.component.less", "./reporte-prevision-servicios-home.component.less"]
})
export class ReportePrevisionServiciosHomeComponent implements OnInit, IDashboardElement {
	filtro: FiltroReporteEncuesta = new FiltroReporteEncuesta("filtro-prevision")
	data: { fechaDesde: Date; prods: Descriptivo[]; tipoFecha: Descriptivo; fechaHasta: Date } = {
		fechaDesde: null,
		fechaHasta: null,
		prods: [],
		tipoFecha: null
	}

	public opciones: Descriptivo[] = []
	public static defaultConfig: DashboardConfiguration = {
		fixedHeader: true,
		x: null,
		y: null,
		rows: 9,
		cols: 7,
		tipo: "reporte-prevision-servicios",
		params: {
			class: "overflow-auto"
		}
	}
	public productos = new BehaviorSubject<Descriptivo[]>([])

	constructor(public productoService: ProductoService, public translateService: TranslateService) {
		this.opciones = [
			new Descriptivo("1", this.translateService.get("ESTA_SEMANA")),
			new Descriptivo("2", this.translateService.get("PROXIMA_SEMANA")),
			new Descriptivo("3", this.translateService.get("PROXIMOS_7_DIAS")),
			new Descriptivo("4", this.translateService.get("PROXIMOS_15_DIAS")),
			new Descriptivo("FE", this.translateService.get("Fechas específicas"))
		]
	}
	onChange = (conf: DashboardConfiguration) => {}
	showParams(event) {
		if (this.op) this.op.toggle(event)
	}
	hideParams() {
		if (this.op) this.op.hide()
	}

	@ViewChild("op", { static: true }) op: OverlayPanel
	public static TIPO = "reporte-prevision-servicios"

	private _config: DashboardConfiguration
	public get config(): DashboardConfiguration {
		return this._config
	}
	@Input()
	public set config(v: DashboardConfiguration) {
		this._config = v
		if (this._config.params["productos"]) {
			this.filtro.productos = this._config.params["productos"]
			this.data.prods = this._config.params["productos"] ? this._config.params["productos"].map((p) => Descriptivo.fromData(p)) : []
			this.productos.next(this.data.prods)
		} else {
			this.filtro.productos = []
			this.data.prods = []
		}

		this.filtro.fecha = this._config.params["fechaDesde"] ? new Date(this._config.params["fechaDesde"]) : new Date()
		this.data.fechaDesde = this.filtro.fecha

		this.filtro.fechaHasta = this._config.params["fechaHasta"] ? new Date(this._config.params["fechaHasta"]) : moment().add(7, "days").toDate()
		this.data.fechaHasta = this.filtro.fechaHasta

		this.data.tipoFecha = this._config.params["tipoFecha"] ? Descriptivo.fromData(this._config.params["tipoFecha"]) : this.opciones[2]
		this.seleccionarFecha(this.data.tipoFecha)
	}
	seleccionarFecha(tipo: Descriptivo) {
		switch (tipo.codigo) {
			case "1":
				this.data.fechaDesde = moment().startOf("week").toDate()
				this.data.fechaHasta = moment().endOf("week").toDate()
				break
			case "2":
				this.data.fechaDesde = moment().endOf("week").add(1, "day").startOf("date").toDate()
				this.data.fechaHasta = moment().endOf("week").add(1, "day").endOf("week").toDate()
				break
			case "3":
				this.data.fechaDesde = moment().startOf("day").toDate()
				this.data.fechaHasta = moment().startOf("day").add(7, "days").toDate()
				break
			case "4":
				this.data.fechaDesde = moment().startOf("day").toDate()
				this.data.fechaHasta = moment().startOf("day").add(15, "days").toDate()
				break
			case "FE":
				this.data.fechaDesde = this.data.fechaDesde ? new Date(this.data.fechaDesde) : new Date()
				this.data.fechaHasta = this.data.fechaHasta ? new Date(this.data.fechaHasta) : new Date()
			default:
				break
		}
	}

	onSelectTipoFecha(event: Descriptivo) {
		this.seleccionarFecha(event)
	}
	getTitle() {
		return "Reporte Previsión Servicios"
	}

	ngOnInit() {
		this.productoService.getHabilitados().then((r) => {
			this.productos.next(r)
		})
	}

	buscar() {
		this.filtro.productos = this.data.prods
		this.filtro.fecha = this.data.fechaDesde
		this.filtro.fechaHasta = this.data.fechaHasta
		this.hideParams()

		this._config.params["productos"] = this.filtro.productos
		this._config.params["fecha"] = this.filtro.fecha
		this._config.params["fechaHasta"] = this.filtro.fechaHasta

		this.onChange(this.config)
	}
}
