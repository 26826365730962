import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { ConfiguracionEntorno } from 'src/app/model/ConfiguracionEntorno';
import { LoadingService } from './../common/services/loading-data-service.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { MessagesService } from '../common/services/messages-data-service.service';
@Injectable({
  providedIn: 'root',
})
export class ConfiguracionEntornoService extends ServicioAbstract<ConfiguracionEntorno>{
   
    public static get CONFIGURACION_ENTORNO(): ConfiguracionEntorno{
        return ConfiguracionEntornoService._configuracionEntorno
    }
    public getConfiguracionObs: BehaviorSubject<ConfiguracionEntorno> = new BehaviorSubject<ConfiguracionEntorno>(null);

private static _configuracionEntorno : ConfiguracionEntorno;
public get configuracionEntorno() : ConfiguracionEntorno {
    return ConfiguracionEntornoService._configuracionEntorno;
}
public set configuracionEntorno(v : ConfiguracionEntorno) {
    ConfiguracionEntornoService._configuracionEntorno = v;
    this.getConfiguracionObs.next(v);
}


    constructor(messages?: MessagesService, loadingService?: LoadingService) {
        super(messages, loadingService)
        this.getConfiguracion();
    }
    public baseName(): string {
        return "configuracion-entorno";
    }
    public parseToEnt(data: any): ConfiguracionEntorno {
        return ConfiguracionEntorno.fromData(data);
    }
    public newEnt(): ConfiguracionEntorno {
        return new ConfiguracionEntorno();
    }
    override postGuardar(e: ConfiguracionEntorno): ConfiguracionEntorno {
        this.configuracionEntorno = e;
        return e;
    }
    public getConfiguracion():Promise<ConfiguracionEntorno>  {
        if(ConfiguracionEntornoService.CONFIGURACION_ENTORNO){
            return new Promise((resolve,reject)=>{
                resolve(ConfiguracionEntornoService.CONFIGURACION_ENTORNO);
            })
        }
        return lastValueFrom(this.http.get(this.getApiURL()+"public/configuracion")).then((c)=>{
            if(c){
                localStorage.setItem("configuracion"+environment.tokenKey,JSON.stringify(c));
                this.configuracionEntorno = this.parseSingle(c);
            }
            return this.configuracionEntorno}
            ).catch((e)=>{
                const c = localStorage.getItem("configuracion"+environment.tokenKey);
                if(c){
                    const c:ConfiguracionEntorno =this.parseSingle(JSON.parse(localStorage.getItem("configuracion"+environment.tokenKey)));
                    this.configuracionEntorno = this.parseSingle(c);
                    return this.configuracionEntorno ;
                    
                }
                return this.handleError(e)
            });
    }


}