import { share } from 'rxjs/operators';
import { DetalleGastoReservaVO } from './../model/DetalleGastoReservaVO';
import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { MargenReporte } from 'src/app/model/MargenReporte';
import { LoadingService } from './../common/services/loading-data-service.service';
@Injectable({
  providedIn: 'root',
})
export class MargenReporteService extends ServicioAbstract<MargenReporte>{
    public baseName(): string {
        return "margen-reporte";
    }
    public parseToEnt(data: any): MargenReporte {
        return MargenReporte.fromData(data);
    }
    public newEnt(): MargenReporte {
        return new MargenReporte();
    }

    getDetalleByReserva(idReserva : number, customLoading?: LoadingService): Promise<DetalleGastoReservaVO[]> {
        let $this = this;
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return this.http.get(this.getBaseURL('detalle-reserva') +"/"+ idReserva).pipe(share())
            .toPromise()
            .then((r:any) => {
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
                return r.map(data => DetalleGastoReservaVO.fromData(data));
            }, (e) => this.handleError(e, customLoading));
    }

}