import { Idioma } from './../common/model/Idioma';
import { PuestoServicioReserva } from './PuestoServicioReserva';
import { PlantillaPreguntaEncuesta } from './PlantillaPreguntaEncuesta';
import { Descriptivo } from 'src/app/common/model/Descriptivo';


export class EncuestaItem extends Descriptivo {
    constructor(id?: number, codigo?: string, descripcion?: string,
		public preguntas : PlantillaPreguntaEncuesta[] = [], 
        public descripcionProducto?: string,
        public fechaActividad?: Date,
        public puestos: PuestoServicioReserva[] = [],
        public idiomaPax?: Idioma, 
    ) {
        super(codigo, descripcion,id)
    }
   
    public static fromData(data: any): EncuestaItem {
        if (!data) return null;
        const o: EncuestaItem = new EncuestaItem(
            data.id,
            data.codigo,
            data.descripcion,
			data.preguntas? data.preguntas.map(c => PlantillaPreguntaEncuesta.fromData(c)) : [], 
            data.descripcionProducto,
            data.fechaActividad ? new Date(data.fechaActividad) : null,
            data.puestos? data.puestos.map( p => PuestoServicioReserva.fromData(p)) : [],
            Idioma.fromData(data.idiomaPax)
            );

        o.version = data.version; 
return o;

    }

}