
import { Descriptivo } from 'src/app/common/model/Descriptivo';


export class ProductoAsociado {
    constructor(
        public id?: number,
        public asociado?: Descriptivo,
        public basePersonas?: number,
        public cantidad?: number,
        public esPorServicio: boolean = false,
        public conPrecio: boolean = false,
        public version?: number,
    ) {
    }
    public static fromData(data: any): ProductoAsociado {
        if (!data) return null;
        const o: ProductoAsociado = new ProductoAsociado(

            data.id,
            Descriptivo.fromData(data.asociado),
            data.basePersonas,
            data.cantidad,
            data.esPorServicio,
            data.conPrecio
        );

        o.version = data.version; 
return o;

    }



}