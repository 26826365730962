import { AjustePorDiferenciaDeCambio } from "./AjustePorDiferenciaDeCambio"
import { Cobro } from "./Cobro"
import { CobroCompensacionSaldo } from "./CobroCompensacionSaldo"
import { CobroConCaja } from "./CobroConCaja"
import { CobroEnEfectivo } from "./CobroEnEfectivo"
import { CobroMercadoPago } from "./CobroMercadoPago"
import { CobroNotaCredito } from "./CobroNotaCredito"
import { CobroTarjetaCredito } from "./CobroTarjetaCredito"
export class CobroFactory {
	public static types = [
		Cobro,
		CobroConCaja,
		CobroEnEfectivo,
		CobroMercadoPago,
		CobroTarjetaCredito,
		CobroNotaCredito,
		CobroCompensacionSaldo,
		AjustePorDiferenciaDeCambio
	]
	public static factory

	static newCobro(data?: any) {
		let o = null
		if (!CobroFactory.factory) {
			CobroFactory.factory = {}
			CobroFactory.types.forEach((tt) => (CobroFactory.factory[tt.TIPO] = tt.fromData))
		}
		return CobroFactory.factory[data.tipo](data)
	}

	public static fromData(data) {
		return this.newCobro(data)
	}
}
