<div class='gestor-tag-usuario content  content-white'>


    <model-gestor [service]='service' [(item)]='item' [validar]='isValid' [modelName]="'TagUsuario' | translate" [verAcciones]="true"
        [isModal]="isModal" [goBack]="goBack"  (onItemGuardado)="handleGuardado($event)" [editable]="esEditable"
        (onCancelar)="handleCancelar($event)" [form]="TagUsuarioForm" [multilenguaje]="false" (onEditar)="onEdit($event)">

        <div class='gestor-form' model-type='dataContent'>
            <form #TagUsuarioForm="ngForm" id="TagUsuarioform">
                <div class="grid" *ngIf="item">			
			
                    <div class='col-12 p-fluid'>
                        <span class="p-float-label">
                            <input pInputText  [(ngModel)]='item.descripcion'	
                            type="text" #descripcion="ngModel" name="descripcion" [readonly]="readonly" />
                            <label>{{ 'DESCRIPCION' | translate }}</label>
                            <small *ngIf="descripcion?.invalid" class="p-error block">{{getErrorMessage(descripcion)}}</small>
                        </span>
                    </div>		

                </div>
            </form>
        </div>
    </model-gestor>
</div>