<div class="insumo grid" *ngIf="contenedor && insumo">
	<div class="col">
		<div class="grid">
			<div class="col-12 lg:col-5 md:col-12 field">
				<descriptivo-material-selector
					[permiteNuevo]="true"
					[options]="insumos"
					[placeHolder]="titulo ? titulo : ('INSUMO' | translate)"
					[floatLabel]="index == 0 ? 'auto' : 'never'"
					name=" {{ 'insumo_' + tipo + +index }}"
					[limpiable]="false"
					[disabled]="readonly"
					[(ngModel)]="insumo.insumo"
					[label]="index == 0 ? titulo : null"
					[gestor]="insumoGestor"
					[service]="insumoService"
				>
					<ng-template #insumoGestor let-handler="handler" let-itemEditado="itemEditado">
						<gestor-insumo
							[item]="itemEditado"
							(onGuardado)="handler.onGuardado($event)"
							[titulo]="'COSTO_ASOCIADO' | translate"
							(onCancelado)="handler.onCancelado($event)"
							[isModal]="true"
							[goBack]="false"
							[readonly]="readonly"
						>
						</gestor-insumo>
					</ng-template>
				</descriptivo-material-selector>
			</div>
			<div class="col-6 md:col-6 lg:col-3 field" style="min-width: 10rem" *ngIf="cantidadEditable">
				<div class="p-inputgroup">
					<span class="p-float-label">
						<input
							type="text"
							pInputText
							type="number"
							[min]="1"
							[(ngModel)]="insumo.cantidad"
							#cantidad="ngModel"
							(focusin)="onFocus($event)"
							placeholder="{{ 'CANTIDAD' | translate }}"
							tooltipPosition="bottom"
							pTooltip="{{ (!insumo.esPorServicio ? 'MENSAJE_CANTIDAD_BASE_INSUMO' : 'CANTIDAD_BASE') | translate }}"
							[disabled]="!insumo?.insumo?.codigo || readonly || !insumo.esPorServicio"
						/>
						<label for="float-input">{{ "CANTIDAD_BASE" | translate }}</label>
					</span>
					<span class="p-inputgroup-addon" style="width: 3em">{{ insumo?.insumo?.unidad?.codigo }}</span>
					<small *ngIf="getErrorMessage(cantidad)" class="p-error block">{{ getErrorMessage(cantidad) }}</small>
				</div>
			</div>
			<div class="col field" style="align-self: center">
				<p-checkbox
					[(ngModel)]="insumo.esPorServicio"
					label="{{ 'ES_POR_SERVICIO' | translate }}"
					name="esPorServicio"
					[binary]="true"
					tooltipPosition="bottom"
					pTooltip="{{ 'EL_COSTO_SE_APLICA__19' | translate }} {{
						(!esMasterData && insumo.id ? 'NO_SE_PUEDE_MODIFICAR_TIPO_INSUMO' : '') | translate
					}}"
					[disabled]="readonly || (!esMasterData && insumo.id)"
				>
					{{ "ES_POR_SERVICIO" | translate }} ></p-checkbox
				>
			</div>
		</div>
	</div>
	<div class="col-fixed w-3rem" *ngIf="!readonly">
		<button pButton icon="fa fa-trash" (click)="contenedor.quitarInsumo(insumo)" [disabled]="readonly"></button>
	</div>
</div>
