import { FiltroUsuario } from './FiltroUsuario';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { Filtro } from './../../common/model/Filtro';
import { UsuarioResumen } from './../../common/model/resumen/UsuarioResumen';
import { UsuarioResumenService } from './../../common/services/usuarioresumen.service';


@Component({
	selector: 'user',
	templateUrl: 'user.component.html',
	styleUrls: ['./user.component.less']
})
export class UserComponent extends SessionComponent implements OnInit {
	public listado: UsuarioResumen[] = [];
	public filtro: FiltroUsuario;
	public acciones = []
	public columns = [
		{
			"field": "id",
			"header": "#",
			"class": "id-column",
			"role": "text-tabla",
			"sortable": true
		},
		{
			"field": "profile",
			"header": "",
			"class": "profile-pic",
			"role": "profile-tabla",
			"sortable": false
		},
		{
			"field": "username",
			"header": "Username",
			"role": "text-tabla",
			"sortable": true
		},
		{
			"field": "nombre",
			"header": "Nombre",
			"role": "text-tabla",
			"sortable": true,
			"esMobile": true
		},
		{
			"field": "email",
			"header": "Email",
			"role": "email-tabla",
			"sortable": true,
			"esMobile": true
		},
		{
			"field": "tagsUsuario",
			"header": "Tags",
			"role": "tags",
			"esMobile": true
		},
		{
			"field": "activo",
			"header": "Activo",
			"class": "is-boolean",
			"role": "boolean-tabla",
			"sortable": false,
			"esMobile": true
		}
	]
	constructor(public usuarioService: UsuarioResumenService, public router: Router, messagesService: MessagesService) {
		super(messagesService)
	}

	ngOnInit() {

		this.filtro = this.fitlerServices.hasFilter("lista_usuarios") ? this.fitlerServices.getFilter("lista_usuarios") :new FiltroUsuario("lista_usuarios");

	}





}