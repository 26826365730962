import * as moment from "moment"
import { Filtro } from "src/app/common/model/Filtro"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { Descriptivo } from "./../common/model/Descriptivo"
import { CategoriaProducto } from "./CategoriaProducto"

export class FiltroComprobante extends Filtro {
	constructor(name: string, page?: number, size?: number, sortField: string = "fecha", order: number = 2, guardable: boolean = true, defaults?: any) {
		super(
			name,
			{
				cliente: "Cliente",
				fechaDesde: "Desde",
				fechaHasta: "Hasta",
				estados: "Estados",
				tipoCliente: "Tipo de Cliente",
				soloImpagas: "Solo con saldo",
				tiposComprobante: "T. Comprobantes"
			},
			page,
			size,
			sortField,
			order,
			guardable
		)
		defaults && Object.assign(this, defaults)
	}

	private _cliente: Descriptivo
	private _fechaDesde: Date = moment().subtract(60, "days").startOf("day").toDate()
	private _fechaHasta: Date
	private _estados: EstadoDocumento[] = []
	private _soloImpagas: boolean
	private _tiposComprobante: Descriptivo[] = []
	private _puntoDeVenta: Descriptivo

	private _idReserva: number
	public get idReserva(): number {
		return this._idReserva
	}
	public set idReserva(v: number) {
		this._idReserva = v
	}

	public get tipoComprobante(): Descriptivo {
		return this.tiposComprobante ? this.tiposComprobante[0] : null
	}
	public set tipoComprobante(v: Descriptivo) {
		this.tiposComprobante = v ? [v] : []
		this.update()
	}
	private _tiposCliente: Descriptivo[] = []
	public get tiposCliente(): Descriptivo[] {
		return this._tiposCliente
	}
	public set tiposCliente(v: Descriptivo[]) {
		if (v != this._tiposCliente) {
			this._tiposCliente = v
			this.update()
		}
	}

	public get tipoCliente(): Descriptivo {
		return this.tiposCliente ? this.tiposCliente[0] : null
	}
	public set tipoCliente(v: Descriptivo) {
		this.tiposCliente = v ? [v] : []
	}

	public get tiposComprobante(): Descriptivo[] {
		return this._tiposComprobante
	}
	public set tiposComprobante(v: Descriptivo[]) {
		this._tiposComprobante = v
		this.update()
	}
	public get soloImpagas(): boolean {
		return this._soloImpagas
	}
	public set soloImpagas(v: boolean) {
		if (v != this._soloImpagas) {
			this._soloImpagas = v
			this.update()
		}
	}
	public get estados(): EstadoDocumento[] {
		return this._estados
	}
	public set estados(v: EstadoDocumento[]) {
		if (v != this._estados) {
			this._estados = v
			this.update()
		}
	}

	public tieneEstado(estado: EstadoDocumento) {
		return this.estados.some((e) => e.id == estado.id)
	}
	public toggleEstado(estado: EstadoDocumento) {
		if (this.tieneEstado(estado)) {
			this.estados = this.estados.filter((e) => e.id != estado.id)
		} else {
			this.estados.push(estado)
		}
		this.update(true)
	}
	public get cliente(): Descriptivo {
		return this._cliente
	}
	public set cliente(v: Descriptivo) {
		this._cliente = v
		this.update()
	}

	public get fechaDesde(): Date {
		return this._fechaDesde
	}
	public set fechaDesde(v: Date) {
		this._fechaDesde = v
		this.update()
	}

	public get fechaHasta(): Date {
		return this._fechaHasta
	}
	public set fechaHasta(v: Date) {
		this._fechaHasta = moment(v).endOf("day").toDate()
		this.update()
	}

	public get puntoDeVenta(): Descriptivo {
		return this._puntoDeVenta
	}
	public set puntoDeVenta(v: Descriptivo) {
		this._puntoDeVenta = v
		this.update()
	}

	private _categoriasProducto: CategoriaProducto[]
	public get categoriasProducto(): CategoriaProducto[] {
		return this._categoriasProducto
	}
	public set categoriasProducto(v: CategoriaProducto[]) {
		this._categoriasProducto = v
		this.update()
	}

	public clean() {
		let cleanVal = {
			searchStr: "",
			cliente: null,
			fechaDesde: moment().startOf("month").toDate(),
			fechaHasta: null,
			estados: [],
			tiposComprobante: [],
			tiposCliente: [],
			soloImpagas: false,
			puntoDeVenta: null
		}

		this.setMultiple(cleanVal, false)
		super.clean(true)
	}

	public patchValue(v: any) {
		super.patchValue(v, false)
		this._searchStr = v.searchStr
		this._cliente = v.cliente ? Descriptivo.fromData(v.cliente) : null
		this._fechaDesde = v.fechaDesde ? new Date(v.fechaDesde) : null
		this._fechaHasta = v.fechaHasta ? new Date(v.fechaHasta) : null
		this._estados = v.estados ? v.estados.map((v) => EstadoDocumento.fromData(v)) : []
		this._soloImpagas = v.soloImpagas
		this._tiposComprobante = v.tiposComprobante ? v.tiposComprobante.map((t) => Descriptivo.fromData(t)) : []
		this._tiposCliente = v._tiposCliente ? v._tiposCliente.map((t) => Descriptivo.fromData(t)) : []
		this._puntoDeVenta = v._puntoDeVenta ? Descriptivo.fromData(v._puntoDeVenta) : null
		this.update()
	}

	public valid(): boolean {
		return this.fechaDesde != undefined
	}
	clonar() {
		const f = new FiltroComprobante(null, null)
		f.patchValue(this)
		return f
	}

	public apply(data: any[]): any[] {
		const searchLike = this.searchStr ? this.searchStr.toUpperCase().split(" ") : null
		return data.filter(
			(value) =>
				(!searchLike || !value["searchStr"] || searchLike.every((v) => value["searchStr"].toUpperCase().includes(v))) &&
				(!this.cliente || (value["cliente"] && value["cliente"].id == this.cliente.id)) &&
				(!this.soloImpagas || value["saldo"] != 0)
		)
	}
}
