import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { MonedaEntorno } from "src/app/app.module"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Cobranza } from "src/app/model/Cobranza"
import { FileData } from "src/app/model/FileData"
import { ItemReserva } from "src/app/model/ItemReserva"
import { ResumenReserva } from "src/app/model/ResumenReserva"
import { ClienteService } from "src/app/services/cliente.service"
import { CobranzaService } from "src/app/services/cobranza.service"
import { MotivoCancelacionService } from "src/app/services/motivo-cancelacion.service"
import { ProveedorService } from "src/app/services/proveedor.service"
import { ReservaService } from "src/app/services/reserva.service"
import { Reserva } from "./../../../model/Reserva"
import { CancelacionReserva } from "./CancelacionReserva"

@Component({
	selector: "cancelacion-reserva",
	templateUrl: "cancelacion-reserva.component.html",
	styleUrls: ["cancelacion-reserva.component.less"]
})
export class CancelacionReservaComponent implements OnInit {
	@Input()
	public pregunta: string = "¿Desea continuar?"

	@Input()
	public itemReserva: ItemReserva

	public cobranzas: Cobranza[] = []
	uploadUrl: string = ""

	public display(reserva: Reserva | ResumenReserva, item?: ItemReserva) {
		this.reserva = reserva
		this.itemReserva = item
		this.visualizar = true
	}

	private _hacerDevolucion: boolean
	public get hacerDevolucion(): boolean {
		return this._hacerDevolucion
	}
	public set hacerDevolucion(v: boolean) {
		this._hacerDevolucion = v
		if (v && this.reserva.id) {
			this.buscarCobranzas()
		}
	}

	private _visualizar: boolean = false
	public get visualizar(): boolean {
		return this._visualizar
	}
	public set visualizar(v: boolean) {
		this._visualizar = v
	}

	private _reserva: Reserva
	public get reserva(): Reserva | ResumenReserva {
		return this._reserva
	}
	@Input()
	public set reserva(v: Reserva | ResumenReserva) {
		if (v instanceof ResumenReserva) {
			if (v?.id) {
				this.cancelacion.idReserva = v.id
				this.reservaService.getById(v.id).then((r) => (this.reserva = r))
			}
		} else {
			this._reserva = v
			if (!this.cancelacion) this.cancelacion = new CancelacionReserva()
			if (v?.id) {
				this.cancelacion.idReserva = v.id

				if (this.hacerDevolucion) {
					this.buscarCobranzas()
				}
			}
		}

		if (v?.id) {
			this.clienteService.getByIdForced(v.cliente.id).then(async (c) => {
				this.proveedor = c.proveedor
			})
		}
	}

	@Output() public onRerservaCancelada: EventEmitter<ResumenReserva | Reserva> = new EventEmitter()
	@Output() public onCancelar: EventEmitter<Boolean> = new EventEmitter()
	@Output() public onVisualizarChange: EventEmitter<Boolean> = new EventEmitter()
	public cancelacion: CancelacionReserva = new CancelacionReserva()
	proveedor: Descriptivo
	constructor(
		public motivoService: MotivoCancelacionService,
		private cobranzaService: CobranzaService,
		private reservaService: ReservaService,
		private messagesService: MessagesService,
		private proveedorService: ProveedorService,
		private clienteService: ClienteService
	) {}
	buscarCobranzas = () => {
		this.cobranzaService.getByReserva(this.reserva.id).then((cob: Cobranza[]) => {
			this.cobranzas = cob.filter((c) => c.total != 0)
			this.cancelacion.moneda = cob[0].moneda
			const totalCobros = this.cobranzas.reduce((a, c) => {
				return (a += c.items
					.filter((i) => !this.reserva || i.comprobante.id == this._reserva.comprobante)
					.reduce((tot, comp) => {
						return (tot += comp.importe)
					}, 0))
			}, 0)
			this.cancelacion.saldo = this.itemReserva ? this.getSaldoItemReserva() : totalCobros
			if (this.cancelacion.saldo > totalCobros) {
				this.cancelacion.saldo = totalCobros
			}
		})
	}

	getSaldoItemReserva = () => {
		let comprobante = this.cobranzas[0].items.find((i) => i.comprobante.id == this._reserva.comprobante)?.comprobante

		if (comprobante?.moneda?.codigo == MonedaEntorno().codigo && this.reserva?.moneda?.codigo != MonedaEntorno().codigo)
			return this.itemReserva?.total * comprobante?.cotizacion
		else if (comprobante?.moneda?.codigo != MonedaEntorno().codigo && this.reserva?.moneda?.codigo != MonedaEntorno().codigo)
			return this.itemReserva?.total * comprobante?.cotizacionAfip
		else return this.itemReserva?.total
	}
	public cancelarDialogo() {
		this.visualizar = false
		this.onCancelar.emit(true)
		this.clear()
	}
	public cancelarReserva() {
		if (this.itemReserva) this.cancelacion.items.push(this.itemReserva)
		this.reservaService
			.cancelar(this.cancelacion)
			.then((r) => {
				this.onRerservaCancelada.emit(r)
				this.visualizar = false
				this.messagesService.success("Reserva cancelada")
				this.clear()
			})
			.catch((e) => {
				this.messagesService.error("No se pudo cancelara la reserva")
			})
	}
	public clear() {
		this.cancelacion = new CancelacionReserva()
		this.reserva = null
		this.cobranzas = []
		this.hacerDevolucion = false
	}
	ngOnInit() {
		this.uploadUrl = this.reservaService.getApiURL() + "fileUpload/adjuntos"
	}

	onUploadError(error) {
		this.messagesService.error(error)
	}

	uploadEnd(data, destino) {
		this.cancelacion.adjunto = data.originalEvent.body[0]
	}

	open(file: FileData) {
		window.open(file.url, "_blank")
	}
}
