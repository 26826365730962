import { PuestoServicioReserva } from "src/app/model/PuestoServicioReserva"
import { Vehiculo } from "src/app/model/Vehiculo"
import { ID } from "../app.module"
import { ProductoCabecera } from "./../pages/producto/ProductoCabecera"
import { Auditable } from "./Auditable"
import { GastoActividad } from "./GastoActividad"
import { Restriccion } from "./Restriccion"
import { VehiculoServicioReserva } from "./VehiculoServicioReserva"

export class GrupoPaxResumen extends Auditable {
	public key: string
	constructor(
		public capacidad: number,
		public id?: number,
		public fechaActividad?: Date,
		public producto?: ProductoCabecera,
		public puestos: PuestoServicioReserva[] = [],
		public restricciones: Restriccion[] = [],
		public duracion: number = 1,
		public esAlquiler: boolean = false,
		public gastos: GastoActividad[] = [],
		public bloqueado: boolean = false,
		public UUID?: string,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
		public vehiculoAsignado?: Vehiculo,
		public actividadCompletada = false,
		public version?: number,
		public esDelegado?: boolean
	) {
		super(lastModifiedBy, lastModifiedDate)
		if (!UUID) {
			this.UUID = this.id ? this.id + "" : ID()
		}
		this.key = this.UUID
	}
	public static fromData(data: any): GrupoPaxResumen {
		if (!data) return null
		const o: GrupoPaxResumen = new GrupoPaxResumen(
			data.capacidad,
			data.id,
			data.fechaActividad ? new Date(data.fechaActividad) : null,
			ProductoCabecera.fromData(data.producto),
			data.puestos ? data.puestos.map((c) => PuestoServicioReserva.fromData(c)) : [],
			data.vehiculos ? data.vehiculos.map((c) => VehiculoServicioReserva.fromData(c)) : [],
			data.duracion,
			data.esAlquiler,
			data.gastos ? data.gastos.map((g) => GastoActividad.fromData(g)) : [],
			data.bloqueado,
			data.UUID,
			data.lastModifiedBy,
			data.lastModifiedDate,
			data.vehiculoAsignado ? Vehiculo.fromData(data.vehiculoAsignado) : null,
			data.actividadCompletada,
			data.version,
			data.esDelegado
		)

		return o
	}

	public get puestoTercerizado() {
		return this.producto?.esTercerizado() ? this.puestos.find((p) => p.esPrestador()) : null
	}
}
