import { Injectable } from "@angular/core"
import { Rol } from "../common/model/Rol"
import { FinancieroAG } from "./FinancieroAG"

@Injectable({
	providedIn: "root"
})
export class MovimientoCajaAG extends FinancieroAG {
	public getRoles(): string[] {
		return super.getRoles().concat(["ROLE_MOV_CAJA"])
	}
}

@Injectable({
	providedIn: "root"
})
export class IngresosEgresosAG extends FinancieroAG {
	public getRoles(): string[] {
		return super.getRoles().concat([Rol.ROLE_INGRESO_EGRESO_CAJA])
	}
}
