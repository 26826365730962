import { lastValueFrom } from "rxjs"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Filtro } from "src/app/common/model/Filtro"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { MessagesService } from "../common/services/messages-data-service.service"
import { Idioma } from "../model/Idioma"
import { BufferedService } from "./BufferedService"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"

export abstract class BufferedDescriptivoService<E extends Descriptivo> extends BufferedService<E> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public getDescriptivosByDesc(query: any, customLoading?: LoadingService): Promise<E[]> {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL() + "getByDesc/?val=" + query))
			.then((r) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return this.parse(r)
			})
			.catch((e) => this.handleError(e, customLoading))
	}
	getDescriptivos(
		filter: Filtro = new Filtro("", {}, 0, 10000),
		customLoading?: LoadingService,
		idioma: string = Idioma.DEFAULT_CODIGO
	): Promise<Descriptivo[]> {
		let p: Promise<Descriptivo[]>
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		p = this.getData(filter, customLoading, idioma).then((r) => {
			return filter.apply(r).map((p) => Descriptivo.fromData(p))
		})
		p.finally(() => {
			if (customLoading) customLoading.susLoadingCount()
			else this.loadingService.susLoadingCount()
		})
		return p
	}
	public getByCodigo(
		codigo: string,
		customLoading?: LoadingService,
		idioma: string = Idioma.DEFAULT_CODIGO,
		filter: Filtro = new Filtro("", {}, 0, 10000)
	): Promise<E> {
		let $this = this
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		let p
		p = this.getData(filter, customLoading, idioma).then((r) => {
			return r.find((d) => d.codigo == codigo)
		})
		p.finally(() => {
			if (customLoading) customLoading.susLoadingCount()
			else this.loadingService.susLoadingCount()
		})
		return p
	}
}
