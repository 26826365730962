import { Usuario } from '../common/model/Usuario';
import { IConRecordatorio } from '../pages/recordatorio/IConRecordatorio';
import { Descriptivo } from './../common/model/Descriptivo';
import { Recordatorio } from './Recordatorio';


export class Nota implements IConRecordatorio {
	public static NOMBRE_ENTIDAD = 'NotaVO';

	constructor(
		public id?: number,
		public fechaNota?: Date,
		public fechaHasta?: Date,
		public esPermanente: boolean = false,
		public cabecera?: string,
		public contenido?: string,
		public responsable?: Descriptivo,
		public ultimaModificacion?: Date,
		public color: string = "#ffffff",
		public recordatorios: Recordatorio[] = [],
		public version?: number,

	) {
	}
	
	public static fromData(data: any): Nota {
		if (!data) return null;
		const o: Nota = new Nota(
			data.id,
			data.fechaNota ? new Date(data.fechaNota) : null,
			data.fechaHasta ? new Date(data.fechaHasta) : null,
			data.esPermanente,
			data.cabecera,
			data.contenido,
			Descriptivo.fromData(data.responsable),
			data.ultimaModificacion ? new Date(data.ultimaModificacion) : null,
			data.color,
			data.recordatorios? data.recordatorios.map(r => Recordatorio.fromData(r)) : [],
			data.version

		);
return o;

	}
	public get tipoEntidad(){
		return Nota.NOMBRE_ENTIDAD
	}
}