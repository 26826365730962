<div class="gestor-canal-comunicacion content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'CanalComunicacion' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="CanalComunicacionForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #CanalComunicacionForm="ngForm" id="CanalComunicacionform">
				<div class="grid">
					<div class="col-12 p-fluid">
						<span class="p-float-label w-15rem">
							<p-dropdown
								[autoDisplayFirst]="false"
								[ngModelOptions]="{ standalone: true }"
								[options]="canales"
								[(ngModel)]="tipoCanal"
								optionValue="value"
								(onChange)="onSelectCanal($event)"
								[disabled]="tipoCanal"
							>
							</p-dropdown>
							<label for="">{{ "TIPO_DE_CANAL" | translate }}</label>
						</span>
					</div>

					<div class="col" *ngIf="tipoCanal && item">
						<div class="grid">
							<div class="col-12 lg:col-4" *ngIf="!esPuestoUnico">
								<descriptivo-material-selector
									[service]="puntoDeVentaService"
									[label]="'PuntoDeVenta' | translate"
									name="puntoDeVenta"
									[limpiable]="true"
									[(ngModel)]="item.puntoDeVenta"
									[readonly]="readonly"
								>
								</descriptivo-material-selector>
							</div>
							<div class="col" [ngSwitch]="tipoCanal">
								<ng-container *ngSwitchCase="'E'">
									<ng-container *ngTemplateOutlet="email; context: { $implicit: item }"> </ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'W'">
									<ng-container *ngTemplateOutlet="whatsapp; context: { $implicit: item }"> </ng-container>
								</ng-container>

								<div class="grid" *ngSwitchDefault>
									<div class="col-12" *ngSwitchDefault>
										<span></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>

<ng-template #whatsapp>
	<div class="col-12 lg:col-4">
		<span class="p-float-label">
			<input
				pInputText
				[(ngModel)]="item.descripcion"
				[readonly]="readonly"
				id="input_descripcion"
				tooltipPosition="bottom"
				pTooltip="{{ 'DESCRIPCION' | translate }}"
				type="text"
			/>
			<label htmlFor="input_descripcion">{{ "DESCRIPCION" | translate }}</label>
		</span>
	</div>

	<div class="col-12 lg:col-4">
		<span class="p-float-label">
			<input
				pInputText
				[(ngModel)]="item.numero"
				[readonly]="readonly"
				id="input_numero"
				tooltipPosition="bottom"
				pTooltip="{{ 'NUMERO' | translate }}"
				type="text"
			/>
			<label htmlFor="input_numero">{{ "NUMERO" | translate }}</label>
		</span>
	</div>

	<div class="col-12 lg:col-4">
		<span class="p-float-label">
			<input
				pInputText
				[(ngModel)]="item.token"
				[readonly]="readonly"
				id="input_token"
				tooltipPosition="bottom"
				pTooltip="{{ 'TOKEN' | translate }}"
				type="text"
			/>
			<label htmlFor="input_token">{{ "TOKEN" | translate }}</label>
		</span>
	</div>
</ng-template>

<ng-template #email let-canal>
	<div class="grid">
		<div class="col-12 lg:col-4">
			<span class="p-float-label">
				<input
					pInputText
					[(ngModel)]="item.descripcion"
					[readonly]="readonly"
					id="input_descripcion"
					tooltipPosition="bottom"
					pTooltip="{{ 'DESCRIPCION' | translate }}"
					type="text"
				/>
				<label htmlFor="input_descripcion">{{ "DESCRIPCION" | translate }}</label>
			</span>
		</div>

		<div class="col-12 lg:col-4">
			<span class="p-float-label">
				<input
					pInputText
					[(ngModel)]="item.username"
					[readonly]="readonly"
					id="input_email"
					tooltipPosition="bottom"
					pTooltip="{{ 'DIRECCION_MAIL' | translate }}"
					type="email"
					appMultiEmailValidator
				/>
				<label htmlFor="input_email">{{ "DIRECCION_MAIL" | translate }}</label>
			</span>
		</div>
		<div class="col-12 lg:col-4">
			<span class="p-float-label">
				<input
					pInputText
					[(ngModel)]="item.password"
					[readonly]="readonly"
					id="input_password"
					tooltipPosition="bottom"
					pTooltip="{{ 'PASSWORD' | translate }}"
					type="password"
				/>
				<label htmlFor="input_password">{{ "PASSWORD" | translate }}</label>
			</span>
		</div>
		<div class="col-12 lg:col-4">
			<span class="p-float-label">
				<input
					pInputText
					[(ngModel)]="item.host"
					[readonly]="readonly"
					id="input_host"
					tooltipPosition="bottom"
					pTooltip="{{ 'HOST' | translate }}"
					type="text"
				/>
				<label htmlFor="input_host">{{ "HOST" | translate }}</label>
			</span>
		</div>
		<div class="col-12 lg:col-4">
			<span class="p-float-label">
				<input
					pInputText
					[(ngModel)]="item.port"
					[readonly]="readonly"
					id="input_port"
					tooltipPosition="bottom"
					pTooltip="{{ 'PUERTO' | translate }}"
					type="number"
				/>
				<label htmlFor="input_port">{{ "PUERTO" | translate }}</label>
			</span>
		</div>
		<div class="col-12 lg:col-4">
			<span class="p-float-label">
				<input
					pInputText
					[(ngModel)]="item.protocol"
					[readonly]="readonly"
					id="input_protocol"
					tooltipPosition="bottom"
					pTooltip="{{ 'Protocolo' | translate }}"
					type="text"
				/>
				<label htmlFor="input_protocol">{{ "Protocolo" | translate }}</label>
			</span>
		</div>
		<div class="col-6 lg:col-2">
			<p-checkbox [(ngModel)]="item.smtpAuth" [binary]="true" name="smtpAuth" label="{{ 'SMTP_AUTH' | translate }}"></p-checkbox>
		</div>
		<div class="col-6 lg:col-2">
			<p-checkbox [(ngModel)]="item.startTLS" [binary]="true" name="startTLS" label="{{ 'START_TLS' | translate }}"></p-checkbox>
		</div>
		<div class="col-6 lg:col-2">
			<p-checkbox [(ngModel)]="item.useSocketFactory" [binary]="true" name="usaSocketFactory" label="{{ 'Usa Socket Factory' | translate }}"></p-checkbox>
		</div>
		<div class="col-6 lg:col-2">
			<p-checkbox [(ngModel)]="item.habilitado" [binary]="true" name="habilitado" label="{{ 'HABILITADO' | translate }}"></p-checkbox>
		</div>
	</div>
</ng-template>
