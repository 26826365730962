import { Injectable } from "@angular/core"
import { Filtro } from "src/app/common/model/Filtro"

@Injectable({
	providedIn: "root"
})
export class FilterService {
	save(filtro: Filtro) {
		filtro && localStorage.setItem(filtro.name, JSON.stringify(filtro.json))
	}
	load(name: string): any {
		return JSON.parse(localStorage.getItem(name) || "{}")
	}
	private currentFilter: Map<string, any> = new Map()
	public hasFilter(entityName: string) {
		return this.currentFilter.has(entityName)
	}
	public getFilter(entityName: string, def?: Filtro) {
		return this.currentFilter.has(entityName) ? this.currentFilter.get(entityName) : (def || new Filtro(entityName, {})).clonar()
	}

	public setFilter(entityName: string, filter: Filtro) {
		this.currentFilter.set(entityName, filter)
	}
}
