import { Moneda } from './Moneda';

export class MargenReporte  {
    constructor(
		public idReserva ?: number, 
		public totalGastos ?: number, 
		public totalVenta ?: number, 
		public totalFacturado ?: number, 
		public totalCobrado ?: number, 
		public fechaReserva ?: Date, 
		public monedaReserva ?: Moneda,
		public monedaComprobante?: Moneda,
		public monedaCobranza?:Moneda,

    ) {
    }
    public static fromData(data: any): MargenReporte {
        if (!data) return null;
        const o: MargenReporte = new MargenReporte(
			data.idReserva, 
			data.totalGastos, 
			data.totalVenta, 
			data.totalFacturado, 
			data.totalCobrado, 
			data.fechaReserva? new Date(data.fechaReserva) : null , 
			data.monedaReserva? Moneda.fromData(data.monedaReserva) : Moneda.PESOS,
			data.monedaComprobante?Moneda.fromData(data.monedaComprobante) : Moneda.PESOS,
			data.monedaCobranza?Moneda.fromData(data.monedaCobranza) : Moneda.PESOS,

        );
		return o;

    }

	public get id(){
		return this.idReserva;
	}

}