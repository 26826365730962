import * as validator from 'validator';

export class StringUtils {

    public static capitalize = (cadena: string) => {
        return cadena+"".toLowerCase().replace(/\b\w/g, (l) =>{ return l.toUpperCase() });
    }
    public static uppercaseWithUnderscore = (cadena: string) => {
        return (cadena+"").toUpperCase().replace(/ /g, "_");
    }
    public static uppercaseWithUnderscoreStripped = (cadena: string) => {
        return (this.getSinTildes(cadena)+"").toUpperCase().replace(/ /g, "_");
    }
    public static middlescoreToTitleCase(val: string) {
        var words = val.split("-");
        words.forEach((w, i) => {
            words[i] = w.charAt(0).toUpperCase() + w.slice(1)
        });
        return words.join(" ");
    }
    public static toPathFormat(val: string) {
        return val? val.replace("VO","").replace(/([A-Z])/g, '-$1').toLowerCase().replace(/^-/, '') : "";
    }
    //multiple mail validator. Separated by ;
    public static isMailValid(mail: string): boolean {
        const emailArray = mail.split(';')

        for (const email of emailArray) {
            const trimmedEmail = email.trim()

            if (!validator.isEmail(trimmedEmail)) {
                return false
            }
        }

        return true

  }


    public static toTitleCase(val: string) {
        var words = val.replace(/([A-Z])/g, " $1");
        return words.charAt(0).toUpperCase() + words.slice(1);
    }

    public static getSinTildes(original: string): string {
        let str: string = original ? (original+"").toUpperCase() : "";
        str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        return str;
    }

    public static contiene(a: string = "", b: string = "") {
        return StringUtils.getSinTildes(a).includes(StringUtils.getSinTildes(b));
    }
    public static htmlToPlainText(html: string): string {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        console.log(tmp.textContent || tmp.innerText || "");
        return tmp.textContent || tmp.innerText || "";
      }


}