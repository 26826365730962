<p-dialog
	header="{{ 'DESEA_CANCELAR' | translate }} {{ itemReserva ? ('EL_SERVICIO' | translate) : ('LA_RESERVA' | translate) }} {{
		itemReserva ? itemReserva.producto?.descripcion : reserva?.id
	}}?"
	[(visible)]="visualizar"
	[modal]="true"
	closable="false"
	appendTo="body"
	[style]="{ width: '60vw' }"
	#dialogCancelar
>
	<div class="grid">
		<div class="col-12" *ngIf="visualizar">
			<parametrico-selector
				[service]="motivoService"
				[(ngModel)]="cancelacion.motivo"
				[limpiable]="true"
				[permiteNuevo]="true"
				name="motivo-cancelacion"
				[titulo]="'MOTIVO_CANCELACION' | translate"
			>
			</parametrico-selector>
		</div>
		<ng-container>
			<div class="col-12 checkbox" *ngIf="reserva?.saldo != reserva?.total">
				<p-checkbox binary="true" [(ngModel)]="hacerDevolucion" label="Generar devolución"> </p-checkbox>
			</div>
			<div class="col-12" *ngIf="hacerDevolucion">
				<div class="grid">
					<div class="col-12 subheader">
						<h3>Cobros realizados</h3>
					</div>
					<div class="col-12 text-center" *ngIf="!cobranzas?.length">
						<span>{{ "No se encontraron cobranzas" | translate }}</span>
					</div>
					<div class="col-6" *ngFor="let cobranza of cobranzas">
						<p-card styleClass="cobranza">
							<div class="grid">
								<div class="col-12">
									<span>Cobranza #{{ cobranza.id }}</span>
								</div>
								<div class="col-12">
									<div class="grid grid-nogutter">
										<div class="col-12 cobro" *ngFor="let itemCobranza of cobranza.items">
											<div class="grid">
												<div class="col-8">
													<span class="descripcion">{{ itemCobranza.descripcion }}</span>
												</div>
												<div class="col-4 text-right">
													<span-dinero-editable [(ngModel)]="itemCobranza.importe" [editable]="false" [moneda]="cobranza.moneda">
													</span-dinero-editable>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12">
									<span>Formas de Pago</span>
								</div>
								<div class="col-12">
									<div class="grid grid-nogutter">
										<div class="col-12 cobro" *ngFor="let cobro of cobranza.cobros">
											<div class="grid">
												<div class="col">
													<span class="descripcion">{{ cobro.formaPago?.descripcion }} - {{ cobro.caja?.descripcion }}</span>
												</div>
												<div class="col-4 text-right">
													<span-dinero-editable [(ngModel)]="cobro.monto" [editable]="false" [moneda]="cobro.moneda">
													</span-dinero-editable>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</p-card>
					</div>

					<div class="col-12">
						<div class="grid">
							<div class="col-12">
								<p-fileUpload
									name="files"
									[url]="uploadUrl"
									mode="basic"
									multiple="false"
									#fileInput
									[disabled]="readonly"
									(onError)="onUploadError($event)"
									(onUpload)="uploadEnd($event, cancelacion)"
									chooseLabel="{{ 'SUBIR_ARCHIVO' | translate }}"
									maxFileSize="1000000"
									[auto]="true"
									invalidFileSizeMessageDetail="{{ 'EL_TAMAÑO_MÁXIMO_ES' | translate }}"
									invalidFileSizeMessageSummary="{{ 'TAMAÑO_EXCEDIDO' | translate }} "
									invalidFileTypeMessageSummary="{{ 'TIPO_DE_ARCHIVO_INV_4' | translate }}"
									invalidFileTypeMessageDetail="{{ 'LOS_TIPOS_ACEPTADOS_3' | translate }}"
									invalidFileLimitMessageDetail="{{ 'EL_LIMITE_DE_ARCHIV_11' | translate }} "
									invalidFileLimitMessageSummary="{{ 'LIMITE_DE_ARVHIVOS__7' | translate }}"
								>
								</p-fileUpload>
							</div>
							<div class="col-12 file-wrapper">
								<div class="card col-12" (click)="open(cancelacion?.adjunto)" *ngIf="cancelacion?.adjunto">
									<div class="grid">
										<div class="col-12 lg:col-10 flex align-items-center">
											<span>{{ cancelacion.adjunto.descripcion }}</span>
										</div>
										<div class="col-12 lg:col-2 acciones" *ngIf="!readonly">
											<button pButton (click)="cancelacion.adjunto = null" icon="fa fa-times"></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 subheader">
						<h3>Devoluciones</h3>
					</div>
					<div class="col-12">
						<abm-cobros
							[permitirNota]="true"
							[item]="cancelacion"
							[readonly]="false"
							[monedaOrigen]="reserva?.comprobante?.moneda"
							[monedaRef]="cancelacion?.moneda"
							[proveedorAsociado]="proveedor?.id"
						></abm-cobros>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<p-footer>
		<button pButton class="boton" (click)="cancelarDialogo()" label="No"></button>
		<button pButton class="boton" (click)="cancelarReserva()" label="Si"></button>
	</p-footer>
</p-dialog>
