import { Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms"
import { OverlayPanel } from "primeng/overlaypanel"
import { Subscription } from "rxjs"
import { filter } from "rxjs/operators"
import { Idioma } from "src/app/common/model/Idioma"
import { ConfiguracionEntornoService } from "src/app/services/configuracion-entorno.service"
import { IdiomaService } from "./../../common/services/idioma.service"

@Component({
	selector: "selector-idioma",
	templateUrl: "./selector-idioma.component.html",
	styleUrls: ["./selector-idioma.component.less"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectorIdiomaComponent),
			multi: true
		},
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => SelectorIdiomaComponent), multi: true }
	]
})
export class SelectorIdiomaComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
	private sub: Subscription
	@ViewChild("op", { static: false })
	private op: OverlayPanel
	@Input()
	public mode: "dropdown" | "text" = "dropdown"
	private _idioma: Idioma
	public get idioma(): Idioma {
		return this._idioma
	}
	@Input()
	public set idioma(v: Idioma) {
		if (v) {
			this._idioma = v
			this.onChangeCallback(this._idioma)
		}
	}

	@Input()
	public autoselect: boolean = true
	public get idiomaCodigo(): string {
		return this.idioma ? this.idioma.codigo : this.configEntorno.configuracionEntorno?.idioma?.codigo || "ES"
	}
	@Input()
	public set idiomaCodigo(v: string) {
		this.idioma = this.idiomas.find((i) => i.codigo == v) || new Idioma(null, "ES", "Español")
	}
	@Input()
	public readOnly: boolean = false
	private onChangeCallback: (_: any) => void = () => {}
	private onTouchedCallback: (_: any) => void = () => {}
	public disabled: boolean = false
	@Input()
	public idiomas: Idioma[] = []
	constructor(public service: IdiomaService, private configEntorno: ConfiguracionEntornoService) {}
	public handleClick(event) {
		if (this.disabled) {
			return
		}
		if (this.readOnly) {
			event.preventDefault()
		} else {
			this.op.toggle(event)
		}
	}
	registerOnValidatorChange?(fn: () => void): void {}
	public seleccionar(opcion) {
		this.idioma = opcion
	}
	writeValue(obj: any): void {
		this.idioma = obj
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled
	}

	get urlImagen(): string {
		return this.idioma?.bandera?.picPathVersion || "assets/images/default_image.png"
	}
	ngOnDestroy() {
		if (this.sub) this.sub.unsubscribe()
	}
	ngOnInit() {
		if (this.idiomas?.length) return
		this.sub = this.service.data.pipe(filter((t) => t != undefined)).subscribe((r) => {
			this.idiomas = r
			if (this.autoselect && !this.idioma?.codigo) {
				this.idioma = r[0]
			}
		})
	}

	validate(control: AbstractControl): ValidationErrors {
		return control ? control.errors : null
	}
	isMobile() {
		return window.innerWidth <= 640
	}
}
