import { Descriptivo } from "src/app/common/model/Descriptivo"
export class Rol extends Descriptivo {
	public static ADMIN_CODE = "ROLE_ADMIN"
	public static JEFE_ADMIN_CODE = "ROLE_JEFE_ADMINISTRACION"
	public static EMPLEADO_CODE = "ROLE_EMPLEADO"
	public static VISUALIZADOR_CODE = "ROLE_VISUALIZADOR"
	public static ROLE_INGRESO_EGRESO_CAJA = "ROLE_INGRESO_EGRESO_CAJA"

	constructor(id?: number, codigo?: string, descripcion?: string, public dependeDe?: string) {
		super(codigo, descripcion, id)
	}

	public static fromData(data: any): Rol {
		if (!data) return null
		let o: Rol = new Rol(data.id, data.codigo, data.descripcion, data.dependeDe)

		o.version = data.version
		return o
	}
}
