import { Provider } from "./Provider"

export class ListaPreciosIntegracion {
	constructor(
		public provider: Provider,
		public versionIntegrada: number,
		public habilitada: boolean,
		public pendiente: boolean) { }

	public static fromData(data: any): ListaPreciosIntegracion {
		if (!data) return null
		const o: ListaPreciosIntegracion = new ListaPreciosIntegracion(
			Provider.fromData(data.provider),
			data.versionIntegrada,
			data.habilitada,
			data.pendiente)

		return o
	}

	public estado() {
		if (this.pendiente) {
			return "PENDIENTE"
		} else if (this.habilitada) {
			return "INTEGRADO"
		} else {
			return "SIN_INTEGRAR"
		}
	}
}
