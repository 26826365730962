
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { Component, OnDestroy, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { ColumnGenerator } from 'src/app/common/utils/ColumnGenerator';
import { BloqueoVehiculo } from 'src/app/model/BloqueoVehiculo';
import { BloqueoVehiculoService } from 'src/app/services/bloqueo-vehiculo.service';
import { LoadingService } from 'src/app/common/services/loading-data-service.service';

@Component({
	selector: 'listado-bloqueo-vehiculo',
	templateUrl: 'listado-bloqueo-vehiculo.component.html',
	styleUrls: ['listado-bloqueo-vehiculo.component.less']
})

export class ListadoBloqueoVehiculoComponent extends SessionComponent implements OnInit, OnDestroy {

	public filtro: Filtro;
	
	@Input()
	public guardarFiltro: boolean = true;	
	@Input()
	public ocultarCabecera: boolean = false;  	
	@Input()
	public editable : boolean = true;
	public listado: BloqueoVehiculo[] = [];
	@Input()
  	public readonly: boolean = false;
  	@Input()
  	public modoSeleccion: 'single' | 'multiple' | 'none' = 'none';
  	
  	@Output()
  	public onSelect: EventEmitter<BloqueoVehiculo[]> = new EventEmitter();
  	
	public columns = [];
	constructor(messagesService : MessagesService,
				public service: BloqueoVehiculoService) {
		super(messagesService)
		console.log(JSON.stringify(this.columns));
	}
	ngOnInit() { 
	this.filtro = new Filtro("bloqueo-vehiculo_lista", {}, 0, 20,'id',1,this.guardarFiltro)
		this.columns = [
  {
    "field": "id",
    "header": "#",
    "class": "id-column",
    "role": "text-tabla",
    "sortable": true,
    "order": 1
  },
  {
    "field": "responsable",
    "header": "Responsable",
    "class": "descriptivo-tabla",
    "role": "descriptivo-tabla",
    "sortable": true,
    "order": 40
  },
  {
    "field": "motivo",
    "header": "Motivo",
    "class": "text-tabla",
    "role": "text-tabla",
    "sortable": true,
    "order": 40
  },
  {
    "field": "fechaHasta",
    "header": "Fecha Hasta",
    "class": "date-tabla",
    "role": "date-tabla",
    "sortable": false,
    "order": 40
  },
  {
    "field": "fechaDesde",
    "header": "Fecha Desde",
    "class": "date-tabla",
    "role": "date-tabla",
    "sortable": false,
    "order": 40
  }
];
	}
	
	 public updateSeleccion(seleccion: any) {
	    if (seleccion?.length || seleccion['id']) {
	      this.onSelect.emit(seleccion?.length ? seleccion : [seleccion]);
	    }
	  }
	ngOnDestroy() { }

	
	
}