import { Pipe, PipeTransform } from '@angular/core';
import { GrupoPax } from 'src/app/model/GrupoPax';
import { Vehiculo } from 'src/app/model/Vehiculo';

@Pipe({
  name: 'byTipoGrupo'
})
export class ByTipoGrupoPipe implements PipeTransform {

  transform(value: GrupoPax[], tipo: 'delegado'|'alquilado'|'normal' = 'normal', vehiculos: Vehiculo[] = []): any {
    return value.filter(g => {
      if(tipo == 'normal'){
        return !g.esDelegado && !g.esAlquiler && (vehiculos?.length == 0 || vehiculos.some(v => v?.id == g.vehiculoAsignado?.id));
      } else if(tipo=='alquilado'){
        return g.esAlquiler && !g.esDelegado;
      }else if(tipo=='delegado'){
        return !g.esAlquiler && g.esDelegado;
      } else return false;
    })
  }

}
