<ng-container *ngIf="itemConRecordatorios?.recordatorios">
	<abm-hijo
		[items]="itemConRecordatorios.recordatorios"
		(onAgregar)="agregarRecordatorio($event)"
		(onQuitar)="quitarRecordatorio($event)"
		[itemTemplate]="itemTemplate"
		[newItem]="newRecordatorio"
		[editable]="editable"
		[gestor]="gestorRecordatorio"
	>
		<ng-template #itemTemplate let-item let-r="index">
			<div class="grid">
				<div class="col-12">
					<div class="grid justify-content-between">
						<div class="col-fixed"><strong>Tipo:</strong> {{ item?.tipo?.descripcion }}</div>
						<div class="col-fixed"><strong>Fecha:</strong>{{ item?.fecha | date : "dd/MM/yyy HH:mm" }}</div>
						<div class="col-fixed overflow" style="max-width: 30rem; text-wrap: nowrap">{{ item?.cabecera }}</div>
						<div class="p-fluid col">
							<strong>Destinatario: </strong>
							<span class="chip mr-1" *ngFor="let tag of item?.destinatarios"
								><i
									class="mr-1 fa"
									[ngClass]="{
										'fa-tag': tag.tipo == 'Tag',
										'fa-user': tag?.tipo == 'Usuario',
										'fa-at': tag?.tipo == 'Email',
										'fa-users': tag?.tipo == 'Todos'
									}"
								></i
								>{{ tag.descripcion }}</span
							>
						</div>
					</div>
				</div>
			</div>

			<div></div>
		</ng-template>
	</abm-hijo>

	<ng-template #gestorRecordatorio let-handler="handler" let-itemEditado="itemEditado">
		<gestor-recordatorio
			[item]="itemEditado"
			(onGuardado)="handler.onGuardado($event)"
			(onCancelado)="handler.onCancelado($event)"
			[isModal]="true"
			[goBack]="false"
			[embedded]="true"
			[readonly]="readonly"
		>
		</gestor-recordatorio>
	</ng-template>
</ng-container>
