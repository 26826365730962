<div class="vista-margen-reporte">
	<div class="content" *ngIf="reserva">
		<div class="grid">
			<div class="title col-12" *ngIf="reserva">
				<div class="grid">
					<div class="col title-content">
						<h2>{{ reserva.descripcion }}</h2>
					</div>
					<div class="col-6 lg:col-3" *ngIf="reserva?.moneda?.codigo != configuracionEntorno?.moneda?.codigo">
						<parametrico-selector
							[service]="monedaService"
							#moneda="ngModel"
							[(ngModel)]="monedaVisualizacion"
							[limpiable]="false"
							name="moneda"
							[titulo]="'Moneda de visualización' | translate"
							[required]="true"
							(onSelect)="cambioMoneda($event)"
						>
						</parametrico-selector>
					</div>
					<div class="col-6 lg:col-3" *ngIf="reserva?.moneda?.codigo != configuracionEntorno?.moneda?.codigo">
						<span-dinero-editable
							[(ngModel)]="cotizacion"
							[label]="'Cotización' | translate"
							name="cotizacion"
							[minValue]="0"
							[editable]="true"
							[decimals]="4"
							(onChange)="updateImportes()"
						>
						</span-dinero-editable>
					</div>
				</div>
			</div>
			<div class="col-12 subtitulo">
				<span>
					{{ "DATOSRESERVA" | translate }}
				</span>
				<span *ngIf="item?.estado?.esCancelada()" style="display: block; margin-left: 4rem">
					Motivo de cancelación: {{ reserva.motivoCancelacion || "Sin Motivo" }}
				</span>
			</div>
			<div class="lg:col-3 col-12">
				<span class="p-float-label field">
					<input pInputText [value]="reserva?.puntoDeVenta?.descripcion" type="text" [readonly]="true" /><label>{{
						"PUNTO_VENTA" | translate
					}}</label>
				</span>
			</div>
			<div class="lg:col-4 col-12">
				<span class="p-float-label field">
					<input pInputText [value]="reserva?.cliente?.descripcion" type="text" [readonly]="true" /><label>{{ "CLIENTE" | translate }}</label>
				</span>
			</div>
			<div class="lg:col-5 col-12">
				<div class="grid">
					<div class="col-6">
						<span class="p-float-label field">
							<input pInputText value="{{ reserva?.fechaRegistro | date : 'dd/MM/yyyy' }}" type="text" [readonly]="true" /><label>{{
								"FECHA_DESDEREGISTRO" | translate
							}}</label>
						</span>
					</div>
					<div class="col-6">
						<span class="p-float-label field">
							<input pInputText value="{{ reserva?.fechaVencimiento | date : 'dd/MM/yyyy' }}" type="text" [readonly]="true" /><label>{{
								"FECHAVENCIMIENTO" | translate
							}}</label>
						</span>
					</div>
				</div>
			</div>
			<div class="lg:col-4 col-12">
				<span class="p-float-label field">
					<input pInputText [value]="reserva?.canalVenta?.descripcion" type="text" [readonly]="true" /><label>{{ "VENDEDOR" | translate }}</label>
				</span>
			</div>
			<div class="lg:col-4 col-12">
				<span class="p-float-label field">
					<input pInputText [value]="reserva?.canalVenta?.descripcion" type="text" [readonly]="true" /><label>{{ "CANALVENTA" | translate }}</label>
				</span>
			</div>
			<div class="lg:col-4 col-12">
				<span class="p-float-label field">
					<input pInputText [value]="reserva?.listadoPrecio?.descripcion" type="text" [readonly]="true" /><label>{{
						"LISTA_PRECIOS" | translate
					}}</label>
				</span>
			</div>
			<div class="lg:col-3 col-8">
				<span class="p-float-label field">
					<input pInputText [value]="reserva?.nombrePax" type="text" [readonly]="true" /><label>{{ "NOMBREPAX" | translate }}</label>
				</span>
			</div>
			<div class="lg:col-1 col-4">
				<span class="p-float-label field">
					<input pInputText [value]="reserva?.cantidadPax" type="number" [readonly]="true" /><label>{{ "CANTIDADPAX" | translate }}</label>
				</span>
			</div>
			<div class="lg:col-2 col-6">
				<span class="p-float-label field">
					<input pInputText [value]="reserva?.emailContacto" type="text" [readonly]="true" /><label>{{ "EMAILCONTACTO" | translate }}</label>
				</span>
			</div>
			<div class="lg:col-2 col-6">
				<span class="p-float-label field">
					<input pInputText [value]="reserva?.telefonoContacto" type="text" [readonly]="true" /><label>{{ "TELEFONOCONTACTO" | translate }}</label>
				</span>
			</div>
			<div class="lg:col-2 col-6">
				<span class="p-float-label field">
					<input pInputText [value]="reserva?.nacionalidadPax?.descripcion" type="text" [readonly]="true" /><label>{{
						"NACIONALIDADPAX" | translate
					}}</label>
				</span>
			</div>
			<div class="lg:col-2 col-6">
				<span class="p-float-label field">
					<input pInputText [value]="reserva?.idiomaPax?.descripcion" type="text" [readonly]="true" /><label>{{ "IDIOMAPAX" | translate }}</label>
				</span>
			</div>

			<div class="lg:col-3 col-6">
				<span class="p-float-label field">
					<input pInputText [value]="reserva?.hotel?.descripcion" type="text" [readonly]="true" /><label>{{ "HOTEL" | translate }}</label>
				</span>
			</div>

			<div class="lg:col-5 col-6">
				<span class="p-float-label field">
					<textarea pInputText [value]="reserva?.observaciones" type="text" [readonly]="true" rows="5"></textarea
					><label>{{ "OBSERVACIONES" | translate }}</label>
				</span>
			</div>

			<div class="lg:col-4 col-6">
				<span class="p-float-label field">
					<textarea pInputText [value]="reserva?.notaCliente" type="text" [readonly]="true" rows="5"></textarea
					><label>{{ "Notas Cliente" | translate }}</label>
				</span>
			</div>
			<div class="col-12 subtitulo">
				<span>
					{{ "Detalle Contratación" | translate }}
				</span>
				<button
					pButton
					type="button"
					class="p-button-transparent"
					(click)="toggleAll($event)"
					[icon]="expandidoAll ? 'pi pi-minus' : 'pi pi-plus'"
				></button>
			</div>
			<div class="col-12 item-reserva" *ngFor="let item of reserva.itemsReserva" [ngClass]="{ expandido: item.expandido }">
				<div class="card">
					<div class="header" (click)="toggle($event, item)">
						{{ item.descripcion }}
						<i [class]="item.expandido ? 'pi pi-minus' : 'pi pi-plus'"></i>
					</div>
					<div class="content">
						<div class="grid">
							<div class="col-12">
								<div class="grid">
									<div class="col-12">ACA VAN LOS DATOS</div>
								</div>
							</div>
							<div class="col-12 lg:col-5 section">
								<div class="grid">
									<div class="col-12">
										<strong>Valores de Venta</strong>
									</div>
									<div class="col-5">
										<span>Precio por persona: {{ item.precioPerCapita | currency : "USD" : monedaSimbolo }}</span>
									</div>
									<div class="col-2">
										<span>Paxs: {{ item.cantidadPax }}</span>
									</div>
									<div class="col-5">
										<span>Subtotal: {{ item.cantidadPax * item.precioPerCapita | currency : "USD" : monedaSimbolo }}</span>
									</div>
									<div class="col-6" *ngIf="item.ajuste">
										<span
											>Ajustes: ({{ item.ajuste | number : "1.0-2" }}%)
											{{ item.valorComisionTransformado | currency : "USD" : monedaSimbolo }}</span
										>
									</div>
									<div class="col-6" *ngIf="item.impuestosTransformado">
										<span>Impuestos: {{ item.impuestosTransformado | currency : "USD" : monedaSimbolo }}</span>
									</div>
									<div class="text-right total">
										<span><strong>Total Bruto: </strong>{{ item.total | currency : "USD" : monedaSimbolo }}</span>
									</div>
								</div>
							</div>
							<div class="col-12 lg:col-7 section">
								<div class="grid">
									<div class="col-12">
										<strong>Gastos</strong>
									</div>
									<div class="col-12" *ngIf="item.comision">
										<span>Comisiones: {{ item.valorComision | currency : "USD" : monedaSimbolo }}</span>
									</div>
									<div class="col-12">
										<div class="grid">
											<div class="col small">
												<span>Tipo Puesto</span>
											</div>
											<div class="col small">
												<span>Producto</span>
											</div>

											<div class="col small">
												<span>Proveedor</span>
											</div>
											<div class="col small">
												<span>Insumo</span>
											</div>

											<div class="col col-fixed small text-center" style="width: 3em">
												<span>Cnt.</span>
											</div>
											<div class="col col-fixed small text-right" style="width: 8em">
												<span>Importe</span>
											</div>
										</div>
									</div>
									<div class="col-12" *ngFor="let gasto of item.gastos">
										<div class="grid">
											<div class="col small">
												<span>{{ gasto?.descTipoPuesto }}</span>
											</div>
											<div class="col small">
												<span>{{ gasto?.descProductoGasto }}</span>
											</div>
											<div class="col small">
												<span>{{ gasto?.nombreProveedor }}</span>
											</div>
											<div class="col small">
												<span>{{ gasto?.descInsumo }}</span>
											</div>
											<div class="col col-fixed small text-center" style="width: 3em">
												<span>{{ gasto?.cantGasto }}</span>
											</div>
											<div class="col col-fixed small text-right" style="width: 8em">
												<span>{{ getValor(gasto?.importeGasto * gasto?.cantGasto) | currency : "USD" : monedaSimbolo }}</span>
											</div>
										</div>
									</div>
									<div class="text-right total">
										<span><strong>Total: </strong>{{ item.totalGastos | currency : "USD" : monedaSimbolo }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="text-right margen-bruto footer">
						<strong>Margen Bruto:</strong>
						<span [ngClass]="{ negativo: item.total - item.totalGastos <= 0 }">
							{{ item.total - item.totalGastos | currency : "USD" : monedaSimbolo }}</span
						>
						<span> ({{ ((item.total - item.totalGastos - item.impuestosTransformado) * 100) / item.total | number : "1.0-2" }}%)</span>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="totales">
					<div class="grid">
						<div class="col-12 lg:col-offset-7 lg:col-5 total">
							<div class="col-12">
								<div class="grid grid-nogutter">
									<div class="col-5"><span>Ventas Bruto:</span></div>
									<div class="col-7">
										<strong>{{ totalVentasBruto | currency : "USD" : monedaSimbolo }}</strong>
									</div>
								</div>
							</div>

							<div class="col-12">
								<div class="grid grid-nogutter">
									<div class="col-5"><span>Impuestos:</span></div>
									<div class="col-7">
										<strong>{{ impuestos | currency : "USD" : monedaSimbolo }}</strong>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="grid grid-nogutter big">
									<div class="col-5"><span>Total Comisiones:</span></div>
									<div class="col-7">
										<strong>{{ totalComisiones | currency : "USD" : monedaSimbolo }}</strong>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="grid grid-nogutter big">
									<div class="col-5"><span>Total Gastos:</span></div>
									<div class="col-7">
										<strong>{{ totalGastos | currency : "USD" : monedaSimbolo }}</strong>
									</div>
								</div>
							</div>
							<div class="col-12 margen-bruto" *ngIf="reserva.id && totalVentasBruto">
								<div class="grid grid-nogutter big">
									<div class="col-5"><span>Margen Bruto:</span></div>
									<div class="col-7">
										<strong>
											<span [ngClass]="{ negativo: totalVentasBruto - totalGastos - impuestos - comisiones <= 0 }">
												{{ totalVentasBruto - totalGastos - impuestos - totalComisiones | currency : "USD" : monedaSimbolo }}</span
											>
											({{
												((totalVentasBruto - totalGastos - impuestos - totalComisiones) * 100) / totalVentasBruto | number : "1.0-2"
											}}%)</strong
										>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
