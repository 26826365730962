import * as moment from "moment"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Parametrico } from "src/app/common/model/Parametrico"
import { Idioma } from "src/app/model/Idioma"
import { ID } from "../app.module"
import { round } from "../common/utils/MathUtils"
import { IConRecordatorio } from "../pages/recordatorio/IConRecordatorio"
import { Auditable } from "./Auditable"
import { Cliente } from "./Cliente"
import { EstadoReserva } from "./EstadoReserva"
import { GrupoPaxResumen } from "./GrupoPaxResumen"
import { Hotel } from "./Hotel"
import { ItemReserva } from "./ItemReserva"
import { Moneda } from "./Moneda"
import { Pax } from "./Pax"
import { Recordatorio } from "./Recordatorio"

export class Reserva extends Auditable implements IConRecordatorio {
	public get tipoEntidad(): string {
		return "ReservaVO"
	}
	public recordatorios: Recordatorio[] = []
	totalOriginal: number
	constructor(
		public id?: number,
		public cliente?: Descriptivo,
		public cantidadPax?: number,
		public nombrePax?: string,
		public observaciones?: string,
		public fechaVencimiento?: Date,
		public fechaRegistro: Date = new Date(),
		public hotel?: Descriptivo,
		public nacionalidadPax?: Descriptivo,
		public idiomaPax?: Idioma,
		public itemsReserva: ItemReserva[] = [],
		public telefonoContacto?: string,
		public emailContacto?: string,
		public vendedor?: Descriptivo,
		public canalVenta?: Descriptivo,
		public esCotizacion: boolean = false,
		public listadoPrecio?: Descriptivo,
		public estado?: EstadoReserva,
		public comprobante?: number,
		public saldo?: number,
		public moneda?: Moneda,
		public cotizacion: number = 1,
		public numeroComprobante?: string,
		public errores: string[] = [],
		public esCancelada?: boolean,
		public motivoCancelacion?: string,
		public tipoCliente?: Parametrico,
		public ajusteTotal: number = 0,
		public tipoAjuste?: string,
		public notaCliente?: string,
		public puntoDeVenta?: Descriptivo,
		public consentimientoAceptado: boolean = false,
		public tokenConsentimiento?: string,
		public direccionAlternativa?: string,
		public tokenEncuesta?: string,
		public adjuntoComprobante?: string,
		lastModifiedBy?: string,
		lastModifiedDate?: Date,
		public version?: number,
		public paxs?: Pax[],
		public condiciones?: string,
		public verEnFactura?: boolean,
		public notificarClienteExterno: boolean = false,
		public idConsentimiento?: number,
		public mostrarCotizacion: boolean = false,
		public esFacturado: boolean = false,
		public verEstadoCuenta: boolean = false
	) {
		super(lastModifiedBy, lastModifiedDate)
		this.ordenarItems()
	}
	public static clonar(data: Reserva): Reserva {
		let r: Reserva = Reserva.fromData(data)
		r.id = null
		r.nombrePax = null
		r.observaciones = null
		r.notaCliente = null
		const nuevoVencimiento = moment(r.fechaRegistro).add(moment(r.fechaVencimiento).diff(r.fechaRegistro, "days"), "days").toDate()

		r.fechaRegistro = new Date()
		r.estado = null
		r.consentimientoAceptado = false
		r.recordatorios.forEach((re) => {
			re.id = null
		})
		r.comprobante = null
		r.direccionAlternativa = null
		r.hotel = null
		r.paxs = []
		r.itemsReserva.forEach((i) => {
			i.id = null
			i.UUID = ID()
			i.reservas = []
			i.confirmaciones = []
			i.hotel = i.hotel ? null : null
			i.observaciones = null
			i.notaCliente = null
			i.nombrePax = null
			i.reservas || []
			i.fechaActividad = moment(nuevoVencimiento).add(moment(i.fechaActividad).diff(r.fechaVencimiento, "days"), "days").toDate()
			const pickUpDate = new Date(i.pickUp)
			pickUpDate.setFullYear(i.fechaActividad.getFullYear())
			pickUpDate.setMonth(i.fechaActividad.getMonth())
			pickUpDate.setDate(i.fechaActividad.getDate())
			i.pickUp = pickUpDate
			i.esCancelada = false
			i.productosAsociados.forEach((pa) => {
				pa.id = null
			})
		})
		r.fechaVencimiento = nuevoVencimiento
		r.idConsentimiento = null
		r.mostrarCotizacion = false
		return r
	}
	public static fromData(data: any): Reserva {
		if (!data) return null
		const o: Reserva = new Reserva(
			data.id,
			Cliente.fromData(data.cliente),
			data.cantidadPax,
			data.nombrePax,
			data.observaciones,
			data.fechaVencimiento ? new Date(data.fechaVencimiento) : null,
			data.fechaRegistro ? new Date(data.fechaRegistro) : null,
			Hotel.fromData(data.hotel),
			Descriptivo.fromData(data.nacionalidadPax),
			Idioma.fromData(data.idiomaPax),
			data.itemsReserva?.map((c) => ItemReserva.fromData(c)) || [],
			data.telefonoContacto,
			data.emailContacto,
			Descriptivo.fromData(data.vendedor),
			Descriptivo.fromData(data.canalVenta),
			data.esCotizacion,
			Descriptivo.fromData(data.listadoPrecio),
			EstadoReserva.fromData(data.estado),
			data.comprobante,
			data.saldo,
			Moneda.fromData(data.moneda),
			data.cotizacion,
			data.numeroComprobante,
			data.errores,
			data.esCancelada,
			data.motivoCancelacion,
			Parametrico.fromData(data.tipoCliente),
			data.ajusteTotal,
			data.tipoAjuste,
			data.notaCliente,
			Descriptivo.fromData(data.puntoDeVenta),
			data.consentimientoAceptado,
			data.tokenConsentimiento,
			data.direccionAlternativa,
			data.tokenEncuesta
		)

		if (!o.comprobante) {
			o.comprobante = data.comprobanteVO?.id
		}
		if (data.comprobanteVO) {
			o.adjuntoComprobante = data.comprobanteVO.adjunto?.url
		}
		o.recordatorios = data.recordatorios?.map((r) => Recordatorio.fromData(r)) || []
		o.version = data.version
		o.paxs = data.paxs?.map((p) => Pax.fromData(p)) || []
		o.totalOriginal = o.total
		o.condiciones = data.condiciones
		o.verEnFactura = data.verEnFactura
		o.idConsentimiento = data.idConsentimiento
		o.mostrarCotizacion = data.mostrarCotizacion
		o.esFacturado = data.esFacturado
		o.verEstadoCuenta = data.verEstadoCuenta
		return o
	}

	public get impuestos(): number {
		return this.itemsReserva.filter((i) => !i.esCancelada).reduce((a, b) => (a += b.impuestos), 0)
	}

	public get total(): number {
		return round(this.itemsReserva.filter((i) => !i.esCancelada).reduce((a, b) => (a += b.total), 0) * (1 + this.ajusteTotal / 100))
	}

	public get totalSinHospedajes(): number {
		return round(this.itemsReserva.filter((i) => !i.esCancelada && !i.esHospedaje()).reduce((a, b) => (a += b.total), 0) * (1 + this.ajusteTotal / 100))
	}

	public get totalHospedajes(): number {
		return round(this.itemsReserva.filter((i) => !i.esCancelada && i.esHospedaje()).reduce((a, b) => (a += b.total), 0) * (1 + this.ajusteTotal / 100))
	}

	public get neto(): number {
		return this.itemsReserva.filter((i) => !i.esCancelada).reduce((a, b) => (a += b.netoConAdicionales), 0)
	}
	public get ajuste(): number {
		return round(this.itemsReserva.filter((i) => !i.esCancelada).reduce((a, b) => (a += b.valorAjuste), 0) + (this.subtotalItems * this.ajusteTotal) / 100)
	}
	public get subtotalItems() {
		return this.itemsReserva.filter((i) => !i.esCancelada).reduce((a, b) => (a += b.subtotal + b.subtotalAdicionales), 0)
	}

	public get ajustePorcentaje(): number {
		switch (this.tipoAjuste) {
			case "suma":
				return Math.round((this.ajusteTotal / this.total) * 100 * 100) / 100
			case "porcentaje":
				return this.ajusteTotal
			default:
				break
		}
	}
	public ordenarItems() {
		this.itemsReserva = this.itemsReserva.sort((a, b) => {
			if (a.esPrograma) return -1
			else if (b.esPrograma) return 1
			if (moment(a.fechaActividad).isSame(b.fechaActividad, "date")) {
				return moment(a.pickUp).isSameOrAfter(b.pickUp) ? 1 : -1
			}
			return moment(a.fechaActividad).isAfter(moment(b.fechaActividad)) ? 1 : -1
		})
	}
	public agregarItem(item: ItemReserva) {
		this.itemsReserva.push(item)
		this.ordenarItems()
	}

	public quitarItem(item: ItemReserva, index?: number) {
		this.itemsReserva = this.itemsReserva.filter((o, i) => (o.id ? item.id != o.id : index != i))
	}

	public get descripcion() {
		if (!this.id) return ""
		return "N° " + this.id + " - " + this.nombrePax + " x " + this.cantidadPax + " - " + moment(this.fechaVencimiento).format("DD/MM/YYYY")
	}

	public get montoReserva() {
		return this.saldo
	}
	getItemByProducto(producto: Descriptivo): ItemReserva {
		return this.itemsReserva.find((i) => i.producto.id == producto.id)
	}

	public get tienePrograma(): boolean {
		return this.itemsReserva.some((i) => i.tipoProducto?.codigo == "PGM")
	}
	public get tieneProgramaConVehiculo(): boolean {
		return this.itemsReserva.some((i) => i.tipoProducto?.codigo == "PGM")
	}

	public get itemsPrograma(): ItemReserva[] {
		return this.itemsReserva.filter((i) => i.tipoProducto?.codigo == "PGM")
	}

	public get grupos(): GrupoPaxResumen[] {
		let grupos: GrupoPaxResumen[] = []

		this.itemsReserva.forEach((i) => {
			i.reservas.forEach((r) => {
				grupos.push(r.grupoResumen)
			})
		})

		return grupos
	}
}
