import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { DEF_LOGO } from "src/app/app.module"
import { ItemReservaConsentimiento } from "src/app/model/ItemReservaConsentimiento"
import { ConfiguracionEntornoService } from "src/app/services/configuracion-entorno.service"

@Component({
	selector: "hospedaje-card",
	templateUrl: "./hospedaje-card.component.html",
	styleUrls: ["./hospedaje-card.component.less"]
})
export class HospedajeCardComponent implements OnInit {
	@Input() itemReserva: ItemReservaConsentimiento

	// @Input() encuestaRespondida = false
	@Input() token: string
	@Input() readonly = false
	@Input() consentimientoPendiente: boolean
	@Output()
	public onMostrarEncuesta: EventEmitter<number> = new EventEmitter(null)
	verMas = false

	constructor(private confEntorno: ConfiguracionEntornoService) {
		this.confEntorno.getConfiguracion().then((c) => {
			this.mainLogo = c.profilePic?.picPath || DEF_LOGO
		})
	}
	public mainLogo = DEF_LOGO

	ngOnInit() {}

	// onPrint() {
	// 	var mywindow = window.open("", "PRINT", "width=284")
	// 	setTimeout(() => {
	// 		mywindow.document.write(this.grupoHTML.nativeElement.innerHTML)
	// 		mywindow.document.close() // necessary for IE >= 10
	// 		mywindow.focus() // necessary for IE >= 10*/
	// 		/* setTimeout(()=>{
	//     mywindow.print();

	//   },1000)*/
	// 	})
	// }

	// mostrarEncuesta() {
	//   this.onMostrarEncuesta.emit(this.grupo.id)
	// }

	public isMobile() {
		return window.innerWidth <= 640
	}
}
