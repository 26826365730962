import { Component, Input, OnInit, ViewChild } from '@angular/core'
import * as moment from 'moment'
import { OverlayPanel } from 'primeng/overlaypanel'
import { filter, first } from 'rxjs/operators'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { LoadingService } from 'src/app/common/services/loading-data-service.service'
import { TotalesActividad } from 'src/app/model/TotalesActividad'
import { GrupoPaxService } from 'src/app/services/grupo-pax.service'
import { ProductoService } from 'src/app/services/producto.service'
import { TranslateService } from '../../../../common/services/translate.service'
import { ReporteActividades } from '../../../../model/ReporteActividades'
import { FiltroProducto } from '../../../producto/FiltroProducto'
import { DashboardConfiguration } from '../../dashboard-element/DashboardConfiguration'
import { IDashboardElement } from '../../dashboard-element/IDashboardElement'

@Component({
    selector: 'reporte-actividades',
    templateUrl: './reporte-actividades.component.html',
    styleUrls: ['./../dashboard-element-common.component.less', './reporte-actividades.component.less'],
})
export class ReporteActividadesComponent implements OnInit, IDashboardElement {
    public opcionesTexts: string[] = ['ULTIMOS_7_DÍAS', 'ESTE_MES', 'ULTIMOS_3_MESES', 'ESTE_ANIO', 'RANGO_ESPECÍFICO']
    public opciones: Descriptivo[] = [
        new Descriptivo('1', 'ULTIMOS_7_DÍAS'),
        new Descriptivo('2', 'ESTE_MES'),
        new Descriptivo('3', 'ULTIMOS_3_MESES'),
        new Descriptivo('4', 'ESTE_ANIO'),
        new Descriptivo('RE', 'RANGO_ESPECÍFICO'),
    ]
    public customLoading: LoadingService = new LoadingService()
    productoSeleccionado: Descriptivo
    totales: TotalesActividad[] = []
    grupos: ReporteActividades[] = []
    filtro: FiltroProducto = new FiltroProducto('reporte_actividades')
    textoPeriodo: string
    periodoSeleccionado: Descriptivo
    title: any
    mostrarNuevoProducto: boolean = false
    constructor(public loadingService: LoadingService, private grupoPaxService: GrupoPaxService, private translateService: TranslateService, public productoService: ProductoService) {}
    onChange = (conf: DashboardConfiguration) => {}
    showParams(event) {
        if (this.op) this.op.toggle(event)
    }
    hideParams() {
        if (this.op) this.op.hide()
    }

    @ViewChild('op', { static: true }) op: OverlayPanel
    public static TIPO = 'reporte-actividades'
    public static defaultConfig: DashboardConfiguration = {
        fixedHeader: true,
        x: null,
        y: null,
        rows: 6,
        cols: 4,
        tipo: ReporteActividadesComponent.TIPO,
        refreshable: true,
        params: {},
    }

    private _config: DashboardConfiguration
    public get config(): DashboardConfiguration {
        return this._config
    }
    @Input()
    public set config(v: DashboardConfiguration) {
        this._config = v
        let startup: Promise<any>[] = []
        if (this._config.params['productos']) {
            this.filtro.productos = this._config.params['productos']
        }

        if (this._config.params['periodo']) {
            this.periodoSeleccionado = this.opciones.filter((op) => op.codigo == this._config.params['periodo'])[0]
            this.seleccionarPeriodo(this.periodoSeleccionado)
        }
        if (this._config.params['fechaDesde']) {
            this.filtro.fechaDesde = this._config.params['fechaDesde']
        }
        if (this._config.params['fechaHasta']) {
            this.filtro.fechaHasta = this._config.params['fechaHasta']
        }
        Promise.all(startup).then((r) => {
            this.updateTitle()
            this.buscar()
        })
    }
    public updateTitle() {
        this.title = this.translateService.get('TOTALES')
    }

    seleccionarProducto(d: Descriptivo) {
        this.filtro.productos = [...this.filtro.productos, d]
        this.mostrarNuevoProducto = false
        this.config.params['productos'] = this.filtro.productos
        this.buscar().then(() => {
            this.onChange(this.config)
        })
    }

    seleccionarPeriodo(d: Descriptivo) {
        if (!d) return
        switch (d.codigo) {
            case '1':
                this.filtro.setMultiple({
                    fechaDesde: moment().subtract(7, 'days').startOf('day').toDate(),
                    fechaHasta: moment().endOf('day').toDate(),
                })

                break
            case '2':
                this.filtro.setMultiple({
                    fechaDesde: moment().subtract(1, 'months').startOf('day').toDate(),
                    fechaHasta: moment().endOf('day').toDate(),
                })
                break
            case '3':
                this.filtro.setMultiple({
                    fechaDesde: moment().subtract(3, 'months').startOf('day').toDate(),
                    fechaHasta: moment().endOf('day').toDate(),
                })

                break
            case '4':
                this.filtro.setMultiple({
                    fechaDesde: moment().startOf('year').toDate(),
                    fechaHasta: moment().endOf('day').toDate(),
                })
                break
            default:
                break
        }
        if (d.codigo != 'RE') {
            this.config.params['fechaDesde'] = null
            this.config.params['fechaHasta'] = null
        }
        this.config.params['periodo'] = d.codigo
        this.textoPeriodo = this.opciones.filter((op) => op.codigo == d.codigo)[0].descripcion

        this.onChange(this.config)
    }

    ngOnInit() {
        this.translateService.upateData
            .pipe(
                filter((r) => r != undefined),
                first()
            )
            .subscribe((r) => {
                this.opcionesTexts.forEach((op, i) => {
                    let t = this.translateService.get(op)
                    this.opciones[i].descripcion = t
                })
                if (this.periodoSeleccionado) {
                    this.periodoSeleccionado = this.opciones.filter((op) => op.codigo == this.periodoSeleccionado.codigo)[0]
                }
                this.textoPeriodo = this.periodoSeleccionado ? this.periodoSeleccionado.descripcion : this.translateService.get('SELECCIONE_UN_PERIO_1')
                this.title = this.translateService.get('TOTALES')
            })
    }

    buscar() {
        this.totales = []
        this.hideParams()
        if (this.filtro.productos?.length) {
            return this.grupoPaxService.getTotalesReporteActividades(this.filtro).then((r) => {
                this.totales = r
            })
        }
    }
    public getTitle = () => {
        return `${this.title} - ${this.getTextoPeriodo()}`
    }

    public getTextoPeriodo() {
        return this.periodoSeleccionado?.codigo != 'RE' ? this.textoPeriodo : `${moment(this.filtro.fechaDesde).format('DD/MM/YYYY')} a ${moment(this.filtro.fechaHasta).format('DD/MM/YYYY')}`
    }

    agregarCard() {
        if (!this.periodoSeleccionado.codigo) return
        this.mostrarNuevoProducto = true
    }

    eliminar(item: TotalesActividad) {
        this.totales = [...this.totales.filter((t) => t.productoId != item.productoId)]
        this.filtro.productos = this.filtro.productos.filter((p) => p.id != item.productoId)
        this.config.params['productos'] = this.filtro.productos
    }
}
