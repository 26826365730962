import { Insumo } from "src/app/model/Insumo"
import { MonedaEntorno } from "../app.module"
import { Descriptivo } from "./../common/model/Descriptivo"
import { Hotel } from "./Hotel"
import { Moneda } from "./Moneda"
import { TipoIVAGrabado } from "./TipoIVAGrabado"

export class GastoPuesto {
	public groupKey: string
	public searchStr: string
	constructor(
		public id?: number,
		public descripcion?: string,
		public importe?: number,
		public insumo?: Insumo,
		public tipoPuesto?: Descriptivo,
		public esParticular: boolean = false,
		public comprobante?: number,
		public fechaActividad?: Date,
		public nombrePax?: string,
		public idReserva?: number,
		public idGrupo?: number,
		public idItemReserva?: number,
		public producto?: Descriptivo,
		public cantidad?: number,
		public usuarioDesasignador?: string,
		public motivoDesasignacion?: string,
		public proveedor?: Descriptivo,
		public version?: number,
		public moneda: Moneda = MonedaEntorno(),
		public tipoIVA?: TipoIVAGrabado,
		public hotel?: Hotel
	) {
		this.groupKey = idGrupo + "" + producto?.id + (tipoPuesto?.codigo || "")
		this.searchStr = [idGrupo, producto?.descripcion, idReserva, comprobante, this.groupKey, descripcion].filter((d) => d != null).join("_")
	}
	public static fromData(data: any): GastoPuesto {
		if (!data) return null
		const o: GastoPuesto = new GastoPuesto(
			data.id,
			data.descripcion,
			data.importe,
			Insumo.fromData(data.insumo),
			Descriptivo.fromData(data.tipoPuesto),
			data.esParticular,
			data.comprobante,
			data.fechaActividad ? new Date(data.fechaActividad) : null,
			data.nombrePax,
			data.idReserva,
			data.idGrupo,
			data.idItemReserva,
			Descriptivo.fromData(data.producto),
			data.cantidad,
			data.usuarioDesasignador,
			data.motivoDesasignacion,
			Descriptivo.fromData(data.proveedor)
		)
		o.moneda = Moneda.fromData(data.moneda) || MonedaEntorno()
		o.tipoIVA = TipoIVAGrabado.fromData(data.tipoIVA)
		o.version = data.version
		o.hotel = Hotel.fromData(data.hotel)
		return o
	}

	public get total() {
		return this.importe * this.cantidad
	}
}
