import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byProductoDescripcion'
})
export class ByProductoDescripcion implements PipeTransform {

  transform(table: any[] = [], searchStr: string): any {
    return searchStr?.length ? table.filter( item => item.producto?.descripcion?.toLowerCase().includes(searchStr.toLowerCase())) : table
  }

}
