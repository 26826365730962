import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable } from "rxjs"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { AuthService } from "src/app/services/auth.service"
import { BaseService } from "../common/services/base.service"
import { MessagesService } from "../common/services/messages-data-service.service"
import { WebsocketService } from "./websockets.service"
@Injectable({
	providedIn: "root"
})
export class LogService extends BaseService {
	public baseName(): string {
		return "api/logs"
	}
	public logObserver: BehaviorSubject<string> = new BehaviorSubject<string>("")
	constructor(messages?: MessagesService, loadingService?: LoadingService, private authService?: AuthService, private wsService?: WebsocketService) {
		super(messages, loadingService)
	}

	getLogTail(lines: number = 1000): Observable<string[]> {
		return this.http.get<string[]>(this.getBaseURL("tail/" + lines))
	}

	searchLogs(query: string): Observable<string[]> {
		return this.http.get<string[]>(this.getBaseURL(`search?query=${query}`))
	}
}
