<div class="gestor-plantilla-pregunta content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'PlantillaPregunta' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="PlantillaPreguntaForm"
		[multilenguaje]="true"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #PlantillaPreguntaForm="ngForm" id="PlantillaPreguntaform">
				<div class="grid" *ngIf="item">
					<div class="col-6 lg:col-2">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<input
								pInputText
								[(ngModel)]="item.codigo"
								tooltipPosition="bottom"
								pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
								type="$TYPE"
								#codigo="ngModel"
								name="codigo"
								[readonly]="readonly"
							/>
							<label>{{ "CODIGO" | translate }}</label>
						</span>
						<error-tag [model]="codigo"></error-tag>
					</div>

					<div class="col-12 lg:col-6">
						<span class="p-float-label" [ngClass]="{ readonly: readonly }">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="$TYPE"
								#descripcion="ngModel"
								name="descripcion"
								[readonly]="readonly"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>
					<div class="col-12 lg:col-4">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[service]="tipoPreguntaEncuestaService"
							#tipoPregunta="ngModel"
							[(ngModel)]="item.tipoPregunta"
							[limpiable]="true"
							[permiteNuevo]="true"
							name="tipo-pregunta-encuesta"
							[titulo]="'TIPOPREGUNTA' | translate"
							[readonly]="readonly"
						>
						</parametrico-selector>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
