import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { SessionComponent } from "src/app/common/components/session-component.component";
import { Filtro } from "src/app/common/model/Filtro";
import { LoadingService } from "src/app/common/services/loading-data-service.service";
import { MessagesService } from "src/app/common/services/messages-data-service.service";
import { CategoriaProducto } from "src/app/model/CategoriaProducto";
import { CategoriaProductoService } from "src/app/services/categoria-producto.service";

@Component({
	selector: "listado-categoria-producto",
	templateUrl: "listado-categoria-producto.component.html",
	styleUrls: ["listado-categoria-producto.component.less"],
})
export class ListadoCategoriaProductoComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: Filtro;

	@Input()
	public guardarFiltro: boolean = true;
	@Input()
	public ocultarCabecera: boolean = false;
	@Input()
	public editable: boolean = true;
	public listado: CategoriaProducto[] = [];
	@Input()
	public readonly: boolean = false;
	@Input()
	public modoSeleccion: "single" | "multiple" | "none" = "none";

	@Output()
	public onSelect: EventEmitter<CategoriaProducto[]> = new EventEmitter();

	public columns = [];
	constructor(messagesService: MessagesService, public service: CategoriaProductoService) {
		super(messagesService);
	}
	ngOnInit() {
		this.filtro = new Filtro("categoria-producto_lista", {}, 0, 20, "id", 1, this.guardarFiltro);
		this.columns = [
			{
				field: "codigo",
				header: "Codigo",
				class: "codigo-tabla",
				role: "text-tabla",
				sortable: true,
				order: 1,
			},
			{
				field: "id",
				header: "#",
				class: "id-column",
				role: "text-tabla",
				sortable: true,
				order: 1,
			},
			{
				field: "descripcion",
				header: "Descripciones",
				class: "text-editable-tabla",
				role: "text-editable-tabla",
				sortable: true,
				order: 40,
			},
			{
				field: "icono",
				header: "Icono",
				class: "profile-pic",
				role: "profile-tabla",
				sortable: false,
				order: 40,
			},
			{
				field: "habilitado",
				header: "Habilitado",
				class: "is-boolean",
				role: "boolean-tabla",
				sortable: false,
				order: 99,
			},
		];
	}

	public updateSeleccion(seleccion: any) {
		if (seleccion?.length || seleccion["id"]) {
			this.onSelect.emit(seleccion?.length ? seleccion : [seleccion]);
		}
	}
	ngOnDestroy() {}

	public actualizarDescricpion(data: CategoriaProducto, customLoading?: LoadingService) {
		this.service.actualizarDescripcion(data, customLoading);
	}
}
