import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { Billete } from "src/app/model/Billete"
import { BilletesCaja } from "src/app/model/BilletesCaja"
import { MessagesService } from "../common/services/messages-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class BilleteService extends ParametricoService<Billete> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "billete"
	}
	public parseToEnt(data: any): Billete {
		return Billete.fromData(data)
	}
	public newEnt(): Billete {
		return new Billete()
	}

	public iniciarBilletes(): Promise<BilletesCaja[]> {
		if (!this.getCache()) {
			return this.loadData().then((r) => {
				return r.map((b) => new BilletesCaja(null, b, 0))
			})
		} else {
			return Promise.resolve(this.getCache().map((b) => new BilletesCaja(null, b, 0)))
		}
	}
}
