import { Clipboard } from "@angular/cdk/clipboard"
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { NavigationExtras, Router } from "@angular/router"
import * as moment from "moment"
import { ConfirmationService } from "primeng/api"
import * as QRCode from "qrcode"
import { BehaviorSubject } from "rxjs"
import { MonedaEntorno } from "src/app/app.module"
import { CancelacionReservaAG } from "src/app/authguards/ReservasAG"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { Parametrico } from "src/app/common/model/Parametrico"
import { validateCuit } from "src/app/common/utils/CuitValidator"
import { round } from "src/app/common/utils/MathUtils"
import { CanalComunicacion } from "src/app/model/CanalComunicacion"
import { Cliente } from "src/app/model/Cliente"
import { Cobranza } from "src/app/model/Cobranza"
import { ConvertirComprobantes } from "src/app/model/ConvertirComprobantes"
import { FileData } from "src/app/model/FileData"
import { ItemCobranza } from "src/app/model/ItemCobranza"
import { Moneda } from "src/app/model/Moneda"
import { Nacionalidad } from "src/app/model/Nacionalidad"
import { TipoComprobante } from "src/app/model/TipoComprobante"
import { TipoIVA } from "src/app/model/TipoIVA"
import { TipoNotificacion } from "src/app/model/TipoNotificacion"
import { CurrencyCustomPipe } from "src/app/pipes/currencyCustom.pipe"
import { CobranzaService } from "src/app/services/cobranza.service"
import { ConsentimientoService } from "src/app/services/consentimiento.service"
import { ProveedorService } from "src/app/services/proveedor.service"
import { PuntoDeVentaService } from "src/app/services/punto-de-venta.service"
import { ReservaService } from "src/app/services/reserva.service"
import { TipoIVAGrabadoService } from "src/app/services/tipo-iva-grabado.service"
import { TipoNotificacionService } from "src/app/services/tipo-notificacion.service"
import { AccionesTabla } from "./../../../common/model/AccionesTabla"
import { Descriptivo } from "./../../../common/model/Descriptivo"
import { EstadoReserva } from "./../../../model/EstadoReserva"
import { GenerarComprobante } from "./../../../model/GenerarComprobante"
import { Reserva } from "./../../../model/Reserva"
import { ResumenReserva } from "./../../../model/ResumenReserva"
import { ClienteService } from "./../../../services/cliente.service"
import { ComprobanteService } from "./../../../services/comprobante.service"
import { CotizacionService } from "./../../../services/cotizacion.service"
import { MonedaService } from "./../../../services/moneda.service"
import { NacionalidadService } from "./../../../services/nacionalidad.service"
import { TipoComprobanteService } from "./../../../services/tipo-comprobante.service"
import { TipoIVAService } from "./../../../services/tipo-iva.service"
import { CancelacionReservaComponent } from "./../cancelacion-reserva/cancelacion-reserva.component"

@Component({
	selector: "acciones-reserva",
	templateUrl: "acciones-reserva.component.html",
	styleUrls: ["acciones-reserva.component.less"]
})
export class AccionesReservaComponent extends SessionComponent implements OnInit {
	verConsentimiento: boolean
	itemConsentimiento: any
	tn: TipoNotificacion

	@ViewChild("inputFile")
	public inputFile: ElementRef
	mostrarConvertirAOficial: boolean = false
	convertirComprobante: ConvertirComprobantes
	itemsCobranzas: ItemCobranza[] = []
	proveedor: Descriptivo
	public tiposComprobante: TipoComprobante[] = []
	constructor(
		private confirmationService: ConfirmationService,
		private comprobanteService: ComprobanteService,
		public tipoComprobanteService: TipoComprobanteService,
		private clienteService: ClienteService,
		private monedaService: MonedaService,
		private cotizacionService: CotizacionService,
		private service: ReservaService,
		private consentimientoService: ConsentimientoService,
		public puntoDeVentaService: PuntoDeVentaService,
		private router: Router,
		public tipoIvaService: TipoIVAService,
		public tipoIvaGrabadoService: TipoIVAGrabadoService,
		public nacionalidadService: NacionalidadService,
		public tipoNotificacionService: TipoNotificacionService,
		public clipboard: Clipboard,
		public cobranzaService: CobranzaService,
		public proveedorService: ProveedorService,
		public cancelacionReservaAG: CancelacionReservaAG
	) {
		super()
	}
	@ViewChild(CancelacionReservaComponent, { static: true })
	private cancelacionReservaComp: CancelacionReservaComponent
	private _reserva: Reserva | ResumenReserva
	private acciones: AccionesTabla[] = []
	public verGenerarComprobante: boolean = false
	public verEnvioItinerario: boolean = false
	public generador: GenerarComprobante
	public ready: BehaviorSubject<Boolean> = new BehaviorSubject(false)
	public envioItinerario: any
	public procesando: boolean = false
	public monedaCobranza: Moneda = MonedaEntorno()
	public emailOpciones: Descriptivo[] = [
		GenerarComprobante.RESERVA,
		GenerarComprobante.ADMINISTRACION,
		GenerarComprobante.CONTACTO,
		GenerarComprobante.PARTICULAR
	]

	@Output()
	public onAccionesChange: EventEmitter<AccionesTabla[]> = new EventEmitter()
	@Output()
	public onDataUpdate: EventEmitter<Reserva | ResumenReserva> = new EventEmitter()
	public get reserva(): Reserva | ResumenReserva {
		return this._reserva
	}
	@Input()
	public editandoReserva = false
	@Input()
	public set reserva(v: Reserva | ResumenReserva) {
		this._reserva = v
		this.actualizarAcciones()
	}

	uploadUrl: string = ""
	ngOnInit() {
		this.actualizarAcciones()
		this.tipoComprobanteService.getAll().then((r) => {
			this.tiposComprobante = r.filter((t) => !t.esNota && t.codigo != TipoComprobante.PAGO_ADELANTADO_CODIGO)
		})
		this.tipoNotificacionService.getByCodigo("ITP").then((n) => {
			this.tn = n
			if (this.tn && !this.tn?.canales?.length) {
				this.tn.canales = [CanalComunicacion.EMAIL]
			}
		})
		this.uploadUrl = this.service.getApiURL() + "fileUpload/adjuntos"
	}
	public getVisibles = (): Promise<AccionesTabla[]> => {
		return Promise.resolve(this.acciones.filter((a) => a.esVisible(this.reserva)))
	}
	public getAcciones = (): Promise<AccionesTabla[]> => {
		return Promise.resolve(this.acciones)
	}

	private actualizarAcciones = () => {
		this.acciones = [
			new AccionesTabla(
				this.translateService.get("Editar"),
				"fa fa-pencil",
				"accion-tabla p-button-text",
				this.editar,
				(item) => this.esEditable(item) && !this.editandoReserva
			),
			new AccionesTabla(
				this.translateService.get("Ver"),
				"fa fa-eye",
				"accion-tabla p-button-text",
				this.ver,
				(item) => item && item["id"] && !this.editandoReserva
			),
			new AccionesTabla(this.translateService.get("Clonar"), "fa fa-copy", "accion-tabla p-button-text", this.clonar, (item) => item && item["id"]),
			new AccionesTabla(this.translateService.get("Cerrar reserva"), "fa fa-lock", "accion-tabla p-button-text", this.cerrarReserva, this.esCerrable),
			new AccionesTabla(
				this.translateService.get("Reabrir reserva"),
				"fa fa-unlock",
				"accion-tabla p-button-text",
				this.reabrirReserva,
				this.puedeReabrir
			),
			new AccionesTabla(
				this.translateService.get("Finalizar reserva"),
				"fa fa-flag-checkered",
				"accion-tabla p-button-text",
				this.finalizarReserva,
				this.esFinalizable
			),
			new AccionesTabla(
				this.translateService.get("Enviar itinerario"),
				"fa fa-list-ol",
				"accion-tabla p-button-text",
				this.enviarItinerario,
				this.puedeEnviarItinerario
			),
			new AccionesTabla(
				this.translateService.get("ABRIR_ITINERARIO"),
				"fa fa-list-ol",
				"accion-tabla p-button-text",
				this.abrirItinerario,
				this.puedeEnviarItinerario
			),
			new AccionesTabla(
				this.translateService.get("DESCARGAR_QR_ITINERARIO"),
				"fa fa-qrcode",
				"accion-tabla p-button-text",
				this.descargarQR,
				(item) => !item["length"] && this.puedeEnviarItinerario(item)
			),
			new AccionesTabla(
				this.translateService.get("LINK_ITINERARIO"),
				"fa fa-copy",
				"accion-tabla p-button-text",
				this.linkItinerario,
				(item) => !item["length"] && this.puedeEnviarItinerario(item)
			),
			new AccionesTabla(
				this.translateService.get("Enviar Vouchers"),
				"fa fa-send",
				"accion-tabla p-button-text",
				this.enviarVouchers,
				this.puedeEnviarVouchers
			),
			new AccionesTabla(
				this.translateService.get("Cancelar"),
				"fa fa-trash",
				"accion-tabla danger",
				(item) => {
					this.cancelacionReservaComp.display(this.reserva ? this.reserva : item)
				},
				this.esEliminable
			),
			new AccionesTabla(
				this.translateService.get("Generar adelanto"),
				"fa fa-money",
				"accion-tabla p-button-text",
				(item) => {
					this.abrirGenerarComprobante(item, true)
				},
				(item) => !(item instanceof Array) && this.puedeGenerarComprobante(item)
			),
			new AccionesTabla(
				this.translateService.get("Generar comprobante"),
				"fa fa-file",
				"accion-tabla p-button-text",
				(item) => {
					this.abrirGenerarComprobante(item)
				},
				this.puedeGenerarComprobante
			),
			new AccionesTabla(
				this.translateService.get("Reenviar comprobante"),
				"fa fa-share",
				"accion-tabla p-button-text",
				(item) => {
					this.abrirReenviarComprobante(item)
				},
				this.esDescargable
			),
			new AccionesTabla(
				this.translateService.get("Generar cobro"),
				"fa fa-money",
				"accion-tabla p-button-text",
				(item) => {
					this.generarCobranza(this.toArray(this.reserva ?? item))
				},
				this.esCobrable
			),
			new AccionesTabla(
				this.translateService.get("Descargar"),
				"fa fa-download",
				"accion-tabla p-button-text",
				(item: ResumenReserva | Reserva) => {
					const { comprobante, numeroComprobante, adjuntoComprobante } = this.reserva ?? item
					if (!adjuntoComprobante) {
						this.comprobanteService.descargar(comprobante, numeroComprobante ?? comprobante + "")
					} else {
						window.open(adjuntoComprobante, "_blank")
					}
				},
				(item) => {
					return this.esDescargable(item)
				}
			),
			new AccionesTabla(
				this.translateService.get("Ver pdf"),
				"fa fa-file-pdf-o",
				"accion-tabla p-button-text",
				(item) => {
					const { comprobante, numeroComprobante, adjuntoComprobante } = this.reserva ?? item
					if (!adjuntoComprobante) {
						this.comprobanteService.verPDF(comprobante)
					} else {
						this.comprobanteService.verAttach(adjuntoComprobante)
					}
				},
				this.esDescargable
			),
			new AccionesTabla(
				this.translateService.get("Ver cotización"),
				"fa fa-file-pdf-o",
				"accion-tabla p-button-text",
				(item: ResumenReserva) => {
					const data = this.reserva ?? item
					this.service.verLiquidacion(data.id)
				},
				this.esCotizacion
			),
			new AccionesTabla(
				this.translateService.get("Ver liquidación"),
				"fa fa-file-pdf-o",
				"accion-tabla p-button-text",
				(item: ResumenReserva) => {
					const data = this.reserva ?? item
					this.service.verLiquidacion(data.id)
				},
				this.esLiquidable
			),
			new AccionesTabla(
				this.translateService.get("Descargar Liquidación"),
				"fa fa-download",
				"accion-tabla p-button-text",
				(item) => {
					const data = this.reserva ? this.reserva : item
					this.service.descargarLiquidacion(data.id, data.id + " - " + data.nombrePax + "x" + data.cantidadPax)
				},
				this.esLiquidable
			),
			new AccionesTabla(
				this.translateService.get("Descargar cotización"),
				"fa fa-download",
				"accion-tabla p-button-text",
				(item) => {
					const data = this.reserva ? this.reserva : item
					this.service.descargarLiquidacion(data.id, data.id + " - " + data.nombrePax + "x" + data.cantidadPax)
				},
				this.esCotizacion
			),
			new AccionesTabla(
				this.translateService.get("Notificar Proveedores"),
				"fa fa-envelope",
				"accion-tabla p-button-text",
				async (item) => {
					const data = this.reserva ? this.reserva : item
					const r = await this.service.getById(data.id)
					const notify = (yaNotificados: boolean) => {
						this.service.notificarProveedores(data.id, yaNotificados).then((r: ResumenReserva) => {
							if (r.errores?.length) {
								this.messagesService.warning("No todos los mails fueron enviados. Errores: " + r.errores.join(", "))
							} else {
								this.messagesService.success("Proveedores notificados")
							}
							this.finalizarAccion()
						})
					}

					notify(true)
				},
				(item) => {
					const data = this.reserva ? this.reserva : item
					return !this.esClienteExterno && data?.id != undefined && !data?.esCotizacion && !data?.estado?.esCancelada()
				}
			),
			// new AccionesTabla(
			//     this.translateService.get('ENVIAR_CONSENTIMIEN_1'),
			//     'fa fa-envelope-o',
			//     'accion-tabla p-button-text',
			//     (item) => {
			//         this.abrirEnviarConsentimiento(item)
			//     },
			//     (item: ResumenReserva) => {
			//         return !item.consentimientoAceptado && !item?.estado?.esCancelada() && !item.esCotizacion
			//     }
			// ),
			new AccionesTabla(
				this.translateService.get("EDITAR_CONSENTIMIENTO"),
				"fa fa-pie",
				"accion-tabla p-button-text",
				(item) => {
					this.habilitarEdicionConsentimiento(item.idConsentimiento)
				},
				(item: ResumenReserva) => {
					return item.consentimientoAceptado && !item?.estado?.esCancelada() && !item.esCotizacion
				}
			),
			new AccionesTabla(
				this.translateService.get("CONVERTIR_A_OFICIAL"),
				"fa fa-share",
				"accion-tabla p-button-text",
				(item) => {
					this.abrirConvertirAOficial(item)
				},
				this.esConvertibleAOficial
			),
			new AccionesTabla(
				this.translateService.get("Ver Margenes"),
				"fa fa-pencil-square-o",
				"accion-tabla p-button-text",
				(item) => {
					let query: NavigationExtras = {
						queryParams: {
							id: item?.id || this.reserva?.id
						}
					}
					this.router.navigate(["margen-reporte/vista"], query)
				},
				(item) => {
					return !(item instanceof Array) && !item.esCotizacion && this.authService.usuario?.tieneRol(["ROLE_ADMIN", "ROLE_REPORTES"])
				}
			)
		]
		this.ready.next(true)
		this.onAccionesChange.emit(this.acciones)
	}

	public esConvertibleAOficial = (item: Reserva | ResumenReserva) => {
		return item?.comprobante != undefined
	}

	public abrirConvertirAOficial(item: Reserva | ResumenReserva) {
		this.mostrarConvertirAOficial = true
		this.convertirComprobante = new ConvertirComprobantes()
		this.convertirComprobante.cliente = item.cliente
		this.convertirComprobante.idReserva = item.id
		this.convertirComprobante.fecha = new Date()
		this.convertirComprobante.fechaVencimiento = new Date()
		this.convertirComprobante.moneda = item.moneda
	}

	public abrirItinerario = (item: Reserva | ResumenReserva) => {
		const url = this.generarUrlItinerario(item)

		window.open(url)
	}
	public descargarQR = async (item: Reserva | ResumenReserva) => {
		const url = this.generarUrlItinerario(item)
		const link = document.createElement("a")
		link.href = await this.generateQRCode(url)
		link.download = item.descripcion + "_QR.png"
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}
	async generateQRCode(data: string): Promise<string> {
		try {
			return await QRCode.toDataURL(data)
		} catch (err) {
			console.error("Error generating QR code", err)
			return ""
		}
	}
	public linkItinerario = (item: Reserva | ResumenReserva) => {
		const url = this.generarUrlItinerario(item)

		const r = this.clipboard.copy(url)

		if (r) {
			this.success(this.translateService.get("LINK_COPIADO"))
		} else {
			this.error(this.translateService.get("ERROR_LINK"))
		}
	}
	public clonar = (item: Reserva | ResumenReserva) => {
		let query: NavigationExtras = {
			queryParams: {
				id: item.id
			}
		}
		this.router.navigate(["reserva/clonar"], query)
	}
	generarUrlItinerario(item) {
		const data = this.reserva ?? item
		const url = `${window.location.origin}/#/public/mi-reserva/${data.id}/${data.tokenEncuesta}`
		return url
	}

	public ver = (item: Reserva | ResumenReserva) => {
		let query: NavigationExtras = {
			queryParams: {
				id: item.id
			}
		}
		this.router.navigate(["reserva/vista"], query)
	}
	public editar = (item: Reserva | ResumenReserva) => {
		let query: NavigationExtras = {
			queryParams: {
				id: item.id
			}
		}
		this.router.navigate(["reserva/edit"], query)
	}

	public abrirReenviarComprobante = async (items: ResumenReserva | ResumenReserva[] = []) => {
		const data = this.reserva ?? items
		this.generador = new GenerarComprobante()
		this.generador.reservas = this.toArray(data)
		if (this.generador.reservas.some((i) => !i.comprobante)) {
			this.messagesService.error("No tiene comprobante")
		} else {
			this.reserva = this.generador.reservas.length == 1 ? this.generador.reservas[0] : null
			this.generador.destinatarios = this.reserva?.emailContacto
			this.generador.reenvio = true
			this.generador.integrable = (await this.clienteService.getById(this.reserva?.cliente.id))?.integrado && !this.reserva.comprobanteIntegrado
			this.verGenerarComprobante = true
		}
	}
	public esDescargable = (item: ResumenReserva[] | ResumenReserva) => {
		const vals = this.toArray(this.reserva ?? item)
		return vals[0]?.comprobante && vals.length == 1
	}
	public esLiquidable = (item: ResumenReserva[] | ResumenReserva) => {
		const vals = this.toArray(this.reserva ?? item)
		return vals.length == 1 && !vals.some((v) => v?.estado?.esCancelada() || v.esCotizacion)
	}
	public esCotizacion = (item: ResumenReserva[] | ResumenReserva) => {
		const vals = this.toArray(this.reserva ?? item)
		return vals.length == 1 && vals.every((v) => v.esCotizacion)
	}
	public puedeGenerarComprobante = (item: ResumenReserva[] | ResumenReserva) => {
		const vals = this.toArray(this.reserva ?? item)
		const monedaMuestra = vals[0]?.moneda
		return (
			!this.esClienteExterno &&
			vals.every((i) => i.moneda?.codigo == monedaMuestra?.codigo && (!i.comprobante || i.esAdelanto) && !i.esCotizacion && !i.estado?.esCancelada())
		)
	}
	public esCobrable = (item: ResumenReserva | ResumenReserva[]) => {
		var vals = this.toArray(this.reserva ?? item)
		let c = vals[0]?.cliente || null
		return !this.esClienteExterno && c && vals.every((i) => c && i.cliente?.id == c.id && i.comprobante && i.saldo > 0 && i.estado?.codigo != "CAN")
	}
	public esCerrable = (item: ResumenReserva[] | ResumenReserva) => {
		var vals = this.toArray(this.reserva ?? item)
		return (
			!this.esClienteExterno &&
			vals.every((i) => {
				return !i?.esCotizacion && i?.estado?.codigo == EstadoReserva.APROBADA
			})
		)
	}
	public puedeEnviarItinerario = (item: ResumenReserva[] | ResumenReserva) => {
		var vals = this.toArray(this.reserva ?? item)
		return (
			!this.esClienteExterno &&
			vals.every((i) => {
				return i?.estado?.codigo == EstadoReserva.APROBADA || i?.estado?.codigo == EstadoReserva.CERRADA
			})
		)
	}

	public puedeEnviarVouchers = (item: ResumenReserva[] | ResumenReserva) => {
		var vals = this.toArray(this.reserva ?? item)
		return (
			!this.esClienteExterno &&
			vals.every((i) => {
				return i?.estado?.codigo == EstadoReserva.CERRADA
			})
		)
	}
	private toArray = (item) => {
		var vals: ResumenReserva[]
		if (!Array.isArray(item)) {
			vals = [item]
		} else {
			vals = item
		}
		return vals ? vals : []
	}
	public esConfirmable = (item: ResumenReserva[] | ResumenReserva) => {
		const vals = this.toArray(this.reserva ?? item)
		return (
			!this.esClienteExterno &&
			vals.every((i) => {
				return i.esCotizacion && i.estado && i.estado?.esConfirmable() && moment(i.fechaVencimiento).isBefore(new Date(), "day")
			})
		)
	}

	public esFinalizable = (item: ResumenReserva[] | ResumenReserva) => {
		const vals = this.toArray(this.reserva ?? item)
		return (
			!this.esClienteExterno &&
			vals.every((i) => {
				return !i.esCotizacion && i.estado && i.estado?.esFinalizable()
			})
		)
	}

	public puedeReabrir = (item: ResumenReserva[] | ResumenReserva) => {
		const vals = this.toArray(this.reserva ?? item)
		return (
			!this.esClienteExterno &&
			this.authService.esAdministrador &&
			vals.every((i) => {
				return i.estado && i.estado?.codigo == EstadoReserva.CERRADA
			})
		)
	}

	public generarCobranza = (items: ResumenReserva[]) => {
		const data = this.reserva ? [this.reserva] : this.toArray(this.reserva ?? items)
		if (!data.every((r) => r.comprobante && r.cliente?.id == data[0]?.cliente?.id && r.saldo > 0))
			return this.messagesService.error(this.translateService.get("No se puede cobrar la reserva. No tiene saldo"))
		this.router.navigate(["cobranza/nuevo"], {
			state: { reservaId: data.map((r) => r.id), idCliente: data[0].cliente?.id }
		})
	}
	public esEditable = (item: ResumenReserva) => {
		return item?.estado?.codigo != "F" && item?.estado?.codigo != "CAN" && !this.esClienteExterno
	}

	public esEliminable = (item: ResumenReserva | ResumenReserva[]) => {
		const data = this.reserva ? [this.reserva] : item
		var vals = this.toArray(this.reserva ?? item)
		return (
			this.cancelacionReservaAG.esVisible(this.usuario) &&
			vals.length == 1 &&
			vals[0].estado?.codigo != "F" &&
			vals[0].estado?.codigo != "CAN" &&
			!this.esClienteExterno
		)
	}
	public cancelarComprobante = () => {
		this.verGenerarComprobante = false
		this.generador = null

		this._reserva = null
	}
	public mostrarEnvioItinerario = (items: ResumenReserva) => {
		const data = this.reserva ? this.reserva : items
		this.verEnvioItinerario = true
		this.reserva = data
		this.envioItinerario = {
			idReserva: data.id,
			destinatario: data?.emailContacto,
			telefono: data?.telefonoContacto
		}
	}
	public confirmarEnvioItinerario = () => {
		if (!this.envioItinerario.destinatario) {
			return this.messagesService.error("Indique un destinatario")
		}
		this.service.enviarItinerario(this.envioItinerario).then((r) => {
			this.messagesService.success(this.translateService.get("ITINERARIO_ENVIADO").replace("{$1}", r.id))
			this.envioItinerario = null
			this.verEnvioItinerario = false
			this.finalizarAccion()
		})
	}

	public finalizarReserva = (items?: ResumenReserva | ResumenReserva[]) => {
		const data = this.reserva ? [this.reserva] : items
		const vals = this.toArray(data)
		this.confirmationService.confirm({
			key: "genConf",
			header: "Finalizar",
			message:
				vals.length == 1
					? this.translateService.get("FINALIZAR_RESERVA_PREGUNTA").replace("{$1}", vals[0].id)
					: this.translateService.get("Va a finalizar las reservas seleccionadas. Continuar?"),
			accept: () => {
				let $this = this
				let p: Promise<any>[] = []
				vals.forEach((data) => {
					p.push(
						$this.service.finalizarReserva(data.id).then((res) => {
							this.messagesService.success(this.translateService.get("RESERVA_FINALIZADA").replace("{$1}", data.id))
						})
					)
				})
				Promise.all(p).finally(() => {
					this.onDataUpdate.emit(this.reserva)
					this.finalizarAccion()
				})
			}
		})
	}
	public enviarItinerario = (items?: ResumenReserva | ResumenReserva[]) => {
		const data = this.reserva ? [this.reserva] : items
		const vals = this.toArray(data)
		if (vals.length == 1) {
			return this.mostrarEnvioItinerario(vals[0])
		}
		this.confirmationService.confirm({
			key: "genConf",
			header: "Enviar Itinerario",
			message: this.translateService.get("Va a enviar los itinerarios de las reservas seleccionadas. Continuar?"),
			accept: () => {
				let $this = this
				let p: Promise<any>[] = []
				vals.forEach((data) => {
					p.push(
						$this.service
							.enviarItinerario({
								idReserva: data.id,
								destinatario: data.emailContacto
							})
							.then((res) => {
								this.messagesService.success(this.translateService.get("ITINERARIO_ENVIADO").replace("{$1}", data.id))
							})
					)
				})
				Promise.all(p).finally(() => {
					this.onDataUpdate.emit(this.reserva)
					this.finalizarAccion()
				})
			}
		})
	}

	public enviarVouchers = (items?: ResumenReserva | ResumenReserva[]) => {
		const data = this.reserva ? [this.reserva] : items
		const vals = this.toArray(data)
		this.confirmationService.confirm({
			key: "genConf",
			header: "Enviar Vouchers",
			message: this.translateService.get("VA_A_ENVIAR_LOS_VOU_30"),
			accept: () => {
				let $this = this
				let p: Promise<any>[] = []
				vals.forEach((data) => {
					p.push(
						$this.service.enviarVouchers(data.id).then((res) => {
							this.messagesService.success(this.translateService.get("VOUCHERS_ENVIADOS"))
						})
					)
				})
				Promise.all(p).finally(() => {
					this.onDataUpdate.emit(this.reserva)
				})
			}
		})
	}
	public get totalAPagar() {
		return this.generador.reservas.map((r) => r.total).reduce((a, b) => a + b, 0)
	}
	private get totalAdelantos() {
		return round(
			this.itemsCobranzas
				.map((ic) => ic.montoTransformado(this.generador.cotizacionAfipSafe, this.generador.getMonedaOperacion()))
				.reduce((a, b) => a + b, 0),
			2
		)
	}
	public get saldoActualizado() {
		return this.generador.importe ? this.generador.importe : this.totalAPagar - this.totalAdelantos
	}
	public get saldoActualizadoALocal() {
		return this.generador?.getMonedaOperacion()?.codigo === MonedaEntorno()?.codigo
			? this.saldoActualizado
			: this.saldoActualizado *
					(this.generador.moneda?.codigo != MonedaEntorno().codigo ? this.generador.cotizacionAfipSafe : this.generador.cotizacion || 1)
	}
	public cerrarReserva = (items?: ResumenReserva | ResumenReserva[]) => {
		const data = this.reserva ? [this.reserva] : items
		const vals = this.toArray(data)
		this.confirmationService.confirm({
			key: "genConf",
			header: "Cerrar",
			message:
				vals.length == 1
					? this.translateService.get("CERRAR_RESERVA_P").replace("{$1}", vals[0].id)
					: this.translateService.get("Va a cerrar las reservas seleccionadas. Continuar?"),
			accept: () => {
				let $this = this
				vals.forEach((data) => {
					$this.service.cerrarReserva(data.id).then((res) => {
						this.onDataUpdate.emit(this.reserva)
						this.messagesService.success(this.translateService.get("RESERVA_CERRADA").replace("{$1}", data.id))
						this.finalizarAccion()
					})
				})
			}
		})
	}
	public reabrirReserva = (items?: ResumenReserva | ResumenReserva[]) => {
		const data = this.reserva ? [this.reserva] : items
		const vals = this.toArray(data)
		this.confirmationService.confirm({
			key: "genConf",
			header: "Reabrir",
			message:
				vals.length == 1
					? this.translateService.get("REABRIR_RESERVA_PREGUNTA").replace("{1}", vals[0].id)
					: this.translateService.get("Va a reabrir las reservas seleccionadas. Continuar?"),
			accept: () => {
				let $this = this
				vals.forEach((data) => {
					$this.service.reabrirReserva(data.id).then((res) => {
						this.onDataUpdate.emit(this.reserva)
						this.messagesService.success(this.translateService.get("RESERVA_ABIERTA").replace("{1}", data.id))
						this.finalizarAccion()
					})
				})
			}
		})
	}

	public abrirGenerarCobranza = (items: ResumenReserva | ResumenReserva[] = []) => {
		const data = this.reserva ? [this.reserva] : items

		var vals = this.toArray(data)
		let c = vals[0]?.cliente
		if (vals.every((r) => r.cliente?.id == c?.id)) {
			this.generarCobranza(vals)
		}
	}
	public abrirGenerarComprobante = async (items: ResumenReserva | ResumenReserva[], esAdelanto: boolean = false) => {
		this.generador = null
		const generar = async ($this, agrupar: boolean = false) => {
			$this.generador = new GenerarComprobante()
			$this.generador.agrupar = agrupar
			$this.generador.cotizacion = 1
			$this.generador.cotizacionAfip = 1
			const data = $this.reserva ? $this.reserva : items
			const reservaSafe = (Array.isArray(items) ? items[0] : null) || (items && <ResumenReserva>items) || $this.reserva

			const cliente = await $this.clienteService.getByIdForced(reservaSafe.cliente.id)

			if (cliente.proveedor) {
				$this.proveedor = cliente.proveedor
			} else {
				$this.proveedor = null
			}
			$this.reserva = reservaSafe
			$this.generador.esAdelanto = reservaSafe ? esAdelanto : false
			$this.generador.nombreFacturacion = $this.reserva?.nombrePax
			$this.generador.moneda = reservaSafe.moneda
			if ($this.reserva?.cotizacion) {
				$this.generador.cotizacionAfip = $this.reserva.cotizacion
				$this.generador.noActualizarCotizacion = true
			} else if (items && items["cotizacion"]) {
				$this.generador.cotizacionAfip = items["cotizacion"]
				$this.generador.noActualizarCotizacion = true
			} else if (items && items[0] && items[0].cotizacion) {
				$this.generador.cotizacionAfip = items[0].cotizacion
				$this.generador.noActualizarCotizacion = true
			}
			$this.generador["monedaRef"] = reservaSafe.moneda
			await this.cotizacionService.getCotizacion(this.generador.moneda, MonedaEntorno()).then((c) => {
				if (this.generador.cotizacionAfip == 1) this.generador.cotizacionAfip = c.valor || 1
			})
			if ($this.generador.esAdelanto) {
				$this.generador.tipoIva = $this.tipoIvaGrabadoService.default
			}
			if ($this.reserva) {
				$this.generador.puntoDeVenta = $this.reserva.puntoDeVenta
			}

			$this.generador.reservas = $this.toArray(data)
			if ($this.generador.reservas.some((i) => i.comprobante && !i.esAdelanto)) {
				$this.messagesService.error("Ya tiene comprobante")
			} else {
				$this.reserva = $this.generador.reservas.length == 1 ? $this.generador.reservas[0] : null

				$this.generador.reenvio = false
				$this.generador.integrable = cliente?.integrado

				if ($this.generador.reservas.length > 1) {
					$this.generador.tipoEmail = GenerarComprobante.ADMINISTRACION
				} else {
					$this.generador.destinatarios = cliente?.emailAdministrativo || $this.reserva?.emailContacto
				}

				$this.verGenerarComprobante = true
				$this.procesando = false
			}
		}
		const $this = this
		if (!Array.isArray(items)) {
			const comprobantes = await this.comprobanteService.getByReserva(items.id)
			this.cobranzaService.getByReserva(items.id).then((cobranzas: Cobranza[]) => {
				this.itemsCobranzas = cobranzas.flatMap((cobranza) =>
					cobranza.items
						.filter((item) => item.comprobante.reserva.id === items.id)
						.map((item) => {
							let i = item
							item["fecha"] = cobranza.fecha
							return i
						})
				)
				if (!esAdelanto && comprobantes && comprobantes.some((c) => !c.tipoComprobante.esOficial && c.esVigente)) {
					this.confirmationService.confirm({
						key: "genConf",
						header: this.translateService.get("ADELANTOS_NO_OFICIALES"),
						message: this.translateService.get("MENSAJE_ADELANTOS_NO_OFICIALES"),
						accept: () => {
							generar($this, true)
						},
						reject: () => {
							generar($this)
						}
					})
				} else generar($this)
			})
		} else generar($this)
	}

	public reenviarComprobante = () => {
		if (this.procesando) return
		this.procesando = true
		this.service
			.generarComprobante(this.generador)
			.then((r) => {
				if (this.generador.reservas.length > 1) {
					this.messagesService.success(this.translateService.get("COMPROBANTES_ENVIAD_1"))
				} else {
					if (r.errores?.length) {
						this.messagesService.success(this.translateService.get("Comprobante generado con errores. ") + r.errores.join(", "))
					} else {
						this.messagesService.success(this.translateService.get("COMPROBANTE_REEN").replace("{1}", r.numeroComprobante))
					}
				}

				this.onDataUpdate.emit(this.reserva)
				this.cancelarComprobante()
				this.finalizarAccion()
			})
			.finally(() => (this.procesando = false))
	}

	public generarComprobante = () => {
		if (this.procesando) return
		this.procesando = true
		if (this.generador.cobros.some((c) => c.monto == 0)) {
			return this.messagesService.error("No puede haber cobros con valor 0")
		}
		if (!this.generador?.tipoComprobante) {
			return this.messagesService.error(this.translateService.get("DEBE_SELECCIONAR_UN_TIPO_DE_COMPROBANTE"))
		}
		if (this.generador.datosFacturacionDistintos) {
			if (!this.generador.tipoDocumentoFacturacion) {
				this.procesando = false
				return this.messagesService.error(this.translateService.get("INGRESE_TIPO_DOCUMENTO"))
			}

			if (!this.generador.numeroDocumentoFacturacion) {
				this.procesando = false
				return this.messagesService.error(this.translateService.get("INGRESE_DOCUMENTO"))
			}

			if (!this.generador.nombreFacturacion) {
				this.procesando = false
				return this.messagesService.error(this.translateService.get("INGRESE_NOMBRE"))
			}

			if (!this.generador.ivaFacturacion) {
				this.procesando = false
				return this.messagesService.error(this.translateService.get("INGRESE_IVA"))
			}

			if (
				(this.generador.tipoDocumentoFacturacion.codigo == "80" || this.generador.tipoDocumentoFacturacion.codigo == "86") &&
				!validateCuit(this.generador.numeroDocumentoFacturacion)
			) {
				this.procesando = false
				return this.messagesService.error(this.translateService.get("CUIT_INVALIDO"))
			}
		}
		this.puntoDeVentaService.getById(this.generador.puntoDeVenta.id).then((p) => {
			const $this = this
			const aceptar = () => {
				$this.service
					.generarComprobante($this.generador)
					.then((r) => {
						if (r.errores?.length) {
							r.errores.forEach((e) => {
								$this.error($this.translateService.get(e))
							})
						} else if ($this.generador.reservas.length > 1) {
							$this.messagesService.success($this.translateService.get("COMPROBANTES_GENERA_2"))
							$this.cancelarComprobante()
						} else {
							$this.messagesService.success(this.translateService.get("COMPROBANTE_N").replace("{1}", r.comprobante))
							$this.cancelarComprobante()
						}
						this.onDataUpdate.emit(this.reserva)
						this.finalizarAccion()
					})
					.finally(() => (this.procesando = false))
			}
			if (p.maximoFacturacion > 0 && p.maximoFacturacion <= this.generador.totalALocal) {
				this.confirmationService.confirm({
					key: "genConf",
					header: this.translateService.get("Facturación máxima superada"),
					message: this.translateService.get("ESTA_POR_SUPERAR_MAXIMO_FACTURACION").replace("${1}", CurrencyCustomPipe.format(p.maximoFacturacion)),

					accept: aceptar
				})
			} else {
				aceptar()
			}
		})
	}

	public nuevo = () => {
		this.router.navigate(["reserva/nuevo"])
	}

	public tipoEmailChange = (tipo: Descriptivo, ent) => {
		if (tipo?.codigo != "P") {
			ent.destinatarios = ""
		}
	}
	public onCancelacionReserva = (reserva: ResumenReserva) => {
		this.onDataUpdate.emit(reserva)
	}
	public actualizarCotizacion = (valor) => {}
	public onMonedaChange = (event) => {
		if (!event) return
		if (this.generador.moneda?.codigo == event.codigo) return
		this.generador.moneda = event
		if (this.generador.moneda?.codigo == MonedaEntorno().codigo) {
			this.generador.cotizacionAfip = 1
		} else {
			this.cotizacionService.getCotizacion(this.generador.moneda, MonedaEntorno()).then((c) => {
				if (this.generador.cotizacionAfip == 1) this.generador.cotizacionAfip = c.valor || 1
			})
		}
		if (this.generador.reservas.length == 1) {
			const r = this.generador.reservas[0]
			if (r.moneda?.codigo != this.generador.moneda?.codigo) {
				if (this.generador.moneda?.codigo != r.moneda?.codigo) {
					this.cotizacionService.getCotizacion(r.moneda, this.generador.moneda).then((c) => {
						if (this.generador.cotizacion == 1) this.generador.cotizacion = c.valor || 1
					})
				} else this.generador.cotizacion = 1
			} else this.generador.cotizacion = 1
		}
	}

	enviarConsentimiento = (item) => {
		this.consentimientoService.enviarConsentimiento(item).then((r) => {
			if (r) {
				this.messagesService.success(this.translateService.get("Mail enviado correctamente"))
				this.verConsentimiento = false
			} else this.messagesService.error(this.translateService.get("Error en el envío"))
		})
	}

	habilitarEdicionConsentimiento = (id) => {
		let item = new Descriptivo("", "", id)
		this.consentimientoService.habilitarEdicion(item).then((r) => {
			if (r) {
				this.messagesService.success(this.translateService.get("Edición habilitada"))
			} else this.messagesService.error(this.translateService.get("No se pudo habilitar"))
			this.finalizarAccion()
		})
	}

	abrirEnviarConsentimiento(item: any) {
		const data = this.reserva ? this.reserva : item
		this.itemConsentimiento = data
		this.itemConsentimiento.emailConsentimiento = data.emailContacto
		this.itemConsentimiento.telefonoConsentimiento = data.telefonoContacto
		this.verConsentimiento = true
	}

	public get esExtranjero(): boolean {
		return this.generador && (!this.generador.nacionalidadFacturacion || this.generador.nacionalidadFacturacion.codigo != "AR")
	}

	public updateCuit() {
		if (!this.generador.tipoClienteFacturacion || !this.generador.nacionalidadFacturacion) {
			return
		}
		if (this.esExtranjero) {
			this.tipoIvaService.getByCodigo(TipoIVA.CONSUMIDOR_FINAL).then((r) => {
				this.generador.ivaFacturacion = r
			})
			this.generador.tipoDocumentoFacturacion = new Parametrico(null, Cliente.DOCUMENTO_CUIT, "CUIT")
			this.nacionalidadService.getById(this.generador.nacionalidadFacturacion.id).then((n: Nacionalidad) => {
				switch (this.generador.tipoClienteFacturacion.codigo) {
					case "EMP":
						this.generador.numeroDocumentoFacturacion = n.cuitJuridica

						break
					case "PART":
						this.generador.numeroDocumentoFacturacion = n.cuitFisica
						break
					default:
						this.generador.numeroDocumentoFacturacion = n.cuitOtros
						break
				}
			})
		}
	}
	limpiar = (event) => {
		if (!event) {
			this._reserva = null
			this.generador = null
		}
	}

	onUploadError(error) {
		this.error(error)
	}

	uploadEnd(data, destino) {
		this.generador.adjunto = data.originalEvent.body[0]
	}

	open(file: FileData) {
		window.open(file.url, "_blank")
	}

	finalizarAccion() {
		this.reserva = null
	}
	public convertir() {
		this.comprobanteService.convertirAOficial(this.convertirComprobante).then((r) => {
			this.messagesService.success("Comprobante convertido")
			this.mostrarConvertirAOficial = false
		})
	}

	public editarNacionalidad(n: Descriptivo) {
		let $this = this

		this.nacionalidadService.getById(n.id).then((r) => {
			/* this.ctx["itemEditado"] = r
			this.ctx["handler"] = {
				onGuardado: (r) => {
					$this.item.nacionalidad = r
					$this.updateCuit()
					$this.editandoOpcion = false
					$this.ctx = {}
				},
				onCancelado: (r) => {
					$this.editandoOpcion = false
				}
			}
			this.editandoOpcion = true */
		})
	}
}
