import * as moment from "moment"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { TipoProducto } from "./TipoProducto"

export class ListaProducto {
	cumpleFecha = true
	nameSort = ""
	key
	searchStr: string

	constructor(
		public id?: number,
		public producto?: Descriptivo,
		public tipoProducto?: TipoProducto,
		public adicionable?: boolean,
		public precio?: number,
		public fechaDesde?: Date,
		public fechaHasta?: Date,
		public costo?: number,
		public minPax?: number,
		public maxPax?: number,
		public precioMinimo: number = 0,
		public porcentual: number = 0,
		public tipoCalculo: string = "P",
		public productoDependiente: Descriptivo = null
	) {
		this.nameSort = (this.producto.descripcion ? this.producto.descripcion.replace(" ", "-") : "") + moment(this.fechaDesde).format("YYYYMMDD") || ""
		this.key = this.id || this.nameSort
		this.searchStr = [this.nameSort, this.producto?.id || "", this.producto?.codigo || "", this.producto?.descripcion || ""].join("_")
		this.tipoCalculo = this.tipoCalculo || "P"
	}
	public get esCalculado() {
		return this.tipoCalculo == "C" || this.tipoCalculo == "D"
	}
	public static fromData(data: any): ListaProducto {
		if (!data) return null
		const o: ListaProducto = new ListaProducto(
			data.id,
			Descriptivo.fromData(data.producto),
			TipoProducto.fromData(data.tipoProducto),
			data.adicionable,
			data.precio,
			data.fechaDesde ? new Date(data.fechaDesde) : null,
			data.fechaHasta ? new Date(data.fechaHasta) : null,
			data.costo,
			data.minPax,
			data.maxPax,
			data.precioMinimo,
			data.porcentual,
			data.tipoCalculo || "P",
			Descriptivo.fromData(data.productoDependiente)
		)

		return o
	}

	public get rango() {
		if (!this.fechaDesde || !this.cumpleFecha) return ""
		let fechaDesdeStr: string = moment(this.fechaDesde).format("DD/MM/YYYY") + " "
		let fechaHastaStr: string = this.fechaHasta ? " hasta " + moment(this.fechaHasta).format("DD/MM/YYYY") + " " : " en adelante"
		return fechaDesdeStr + fechaHastaStr
	}

	public get precioRango() {
		return this.cumpleFecha ? this.precio : null
	}

	public get error() {
		return !this.fechaDesde || this.precio == undefined
	}
	public aplicaRango(desde: Date, hasta: Date) {
		const _hasta = this.fechaHasta || moment().add(10, "year").toDate()
		return moment(desde).isSameOrBefore(moment(_hasta)) && moment(this.fechaDesde).isSameOrBefore(moment(hasta || _hasta))
	}
	public aplica(producto: Descriptivo, fecha: Date = new Date(), paxs: number) {
		return (
			producto != undefined &&
			this.producto.id == producto.id &&
			moment(this.fechaDesde).isSameOrBefore(fecha) &&
			(this.fechaHasta == null || moment(this.fechaHasta).isSameOrAfter(fecha)) &&
			(this.minPax == null || this.minPax <= paxs) &&
			(this.maxPax == null || this.maxPax >= paxs)
		)
	}
	public get descripcion() {
		return this.producto?.descripcion || null
	}

	public superponeMaxMin(min: number, max: number) {
		let min1 = this.minPax
		let min2 = min
		let max1 = this.maxPax
		let max2 = max

		if (min1 === null) min1 = 0
		if (max1 === null) max1 = Infinity
		if (min2 === null) min2 = 0
		if (max2 === null) max2 = Infinity

		return !(max1 < min2 || min1 > max2)
	}
}
