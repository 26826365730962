<div class="gestor-respuesta-encuesta-actividades content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'RespuestaEncuestaActividades' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="RespuestaEncuestaActividadesForm"
		[multilenguaje]="false"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #RespuestaEncuestaActividadesForm="ngForm" id="RespuestaEncuestaActividadesform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-6">
						<span class="p-float-label field">
							<input
								pInputText
								[(ngModel)]="item.reserva.nombrePax"
								type="text"
								#nombrePax="ngModel"
								name="nombrePax"
								[readonly]="readonly"
							/><label>{{ "NOMBRE_PAX" | translate }}</label>
						</span>
					</div>
					<div class="col-4 lg:col-2">
						<span class="p-float-label field">
							<input
								pInputText
								[(ngModel)]="item.cantidadPax"
								type="text"
								#cantidadPax="ngModel"
								name="cantidadPax"
								[readonly]="readonly"
							/><label>{{ "CANTIDAD_PAX" | translate }}</label>
						</span>
					</div>

					<div class="col-4 lg:col-2">
						<span class="p-float-label field">
							<input pInputText [(ngModel)]="item.reserva.id" #idReserva="ngModel" type="text" name="idReserva" [readonly]="readonly" /><label>{{
								"RESERVA" | translate
							}}</label>
						</span>
					</div>

					<div class="col-6 lg:col-2">
						<span class="p-float-label">
							<p-calendar
								[(ngModel)]="item.grupo.fechaActividad"
								dateFormat="dd/mm/yy"
								appendTo="body"
								name="fechaActividad"
								[showIcon]="true"
								#fechaCompletado="ngModel"
							></p-calendar>
							<label>{{ "FECHAACTIVIDAD" | translate }}</label>
						</span>
					</div>
					<div class="col-6 lg:col-2">
						<span class="p-float-label">
							<p-calendar
								[(ngModel)]="item.fechaCompletado"
								dateFormat="dd/mm/yy"
								appendTo="body"
								name="fechaCompletado"
								[showIcon]="true"
								#fechaCompletado="ngModel"
								[readonly]="readonly"
							></p-calendar>
							<label>{{ "FECHACOMPLETADO" | translate }}</label>
							<error-tag [model]="fechaCompletado"></error-tag>
						</span>
					</div>

					<div class="col-12 lg:col-4">
						<span class="p-float-label field" *ngIf="!item.familia">
							<input pInputText [(ngModel)]="item.producto.descripcion" #producto="ngModel" name="producto" [readonly]="readonly" /><label>{{
								"PRODUCTO" | translate
							}}</label>
						</span>

						<span class="p-float-label field" *ngIf="item.familia">
							<input pInputText [(ngModel)]="item.familia.descripcion" #producto="ngModel" name="producto" [readonly]="readonly" /><label>{{
								"FAMILIA" | translate
							}}</label>
						</span>
					</div>

					<div class="col-12 lg:col-4" *ngFor="let guia of item.guias; let i = index">
						<span class="p-float-label field" *ngIf="guia">
							<input pInputText [value]="guia" name="guia{{ i }}" [readonly]="readonly" /><label>{{ "GUIA" | translate }}</label>
						</span>
					</div>
					<div class="col-12" *ngIf="item?.items?.length">
						<h4>Respuestas</h4>
					</div>
					<div class="col-12" *ngFor="let item of item.items; let i = index">
						<div class="grid no-bottom">
							<div class="col-12">
								<span>{{ item.pregunta.descripcion }}</span>
							</div>

							<div class="col-12" [ngSwitch]="item.pregunta.tipoPregunta.codigo">
								<ng-container *ngSwitchCase="'L'">
									<input
										type="text"
										pInputText
										[(ngModel)]="item.respuesta"
										[ngModelOptions]="{ standalone: true }"
										placeholder="{{ 'RESPUESTA' | translate }}"
										disabled
									/>
								</ng-container>
								<ng-container *ngSwitchCase="'R'">
									<p-rating [(ngModel)]="item.respuesta" [ngModelOptions]="{ standalone: true }" [cancel]="false" disabled></p-rating>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
