<div class="listado-vehiculos content">
	<model-list
		[service]="service"
		[modelName]="'vehiculo'"
		[columns]="columns"
		[title]="'VEHICULOS' | translate"
		[(listado)]="listado"
		[isLazy]="false"
		[filtro]="filtro"
	>
	</model-list>
</div>
