import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Rol } from 'src/app/common/model/Rol';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { LoadingService } from './loading-data-service.service';
import { MessagesService } from './messages-data-service.service';

@Injectable()
export class RolService extends ServicioAbstract<Rol> {
    public baseName(): string {
        return "rol";
    }
    public newEnt() {
        return new Rol();
    }
    public parseToEnt(data: any): Rol {
        return Rol.fromData(data);
    }

    getActivos(customLoading?: LoadingService): Promise<Rol[]> {
        let $this = this;
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.get(this.getBaseURL('activos')),{defaultValue:[]})
            .then(r => {
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
                return $this.parse(r)
            }).catch((e) => this.handleError(e, customLoading)).finally(()=>{
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
            });
    }

}