import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { Moneda } from "src/app/model/Moneda"
import { round } from "../common/utils/MathUtils"
import { AuthService } from "./auth.service"
import { CotizacionService } from "./cotizacion.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class MonedaService extends ParametricoService<Moneda> {
	// static cotizaciones: Map<String, Cotizacion[]> = new Map();

	constructor(public novedadesService: NovedadService, public authService: AuthService, public cotizacionService: CotizacionService) {
		super(novedadesService, authService)
	}

	async getCotizacion(monedaDesde: Moneda, monedaHasta: Moneda) {
		if (!monedaDesde || !monedaHasta || monedaDesde.codigo == monedaHasta.codigo) return 1

		const c = await this.cotizacionService.getCotizacion(monedaDesde, monedaHasta)
		if (!c?.valor || c?.valor == 1) {
			const inv = await this.cotizacionService.getCotizacion(monedaHasta, monedaDesde)
			return inv?.valor ? round(1 / inv?.valor, 8) : null
		}
		return c?.valor || null
	}
	public baseName(): string {
		return "moneda"
	}
	public parseToEnt(data: any): Moneda {
		return Moneda.fromData(data)
	}
	public newEnt(): Moneda {
		return new Moneda()
	}
	// protected loadData(customLoading?: LoadingService): Promise<Moneda[]> {
	//     var p = super.loadData(customLoading);
	//     MonedaService.cotizaciones = new Map();
	//     MonedaService.cotizaciones.set("P", [new Cotizacion('D', 0.014),
	//     new Cotizacion('E', 0.011)
	//     ]);
	//     MonedaService.cotizaciones.set("E", [new Cotizacion('D', 1.18),
	//     new Cotizacion('P', 86.96)
	//     ]);
	//     MonedaService.cotizaciones.set("D", [new Cotizacion('E', 0.85),
	//     new Cotizacion('P', 73.72)
	//     ]);
	//     return p;

	// }
}
