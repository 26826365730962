import { IdiomaEntorno } from "../app.module"
import { Usuario } from "../common/model/Usuario"
import { UsuarioResumen } from "../common/model/resumen/UsuarioResumen"
import { Destinatario } from "./Destinatario"

export class Novedad {
	constructor(
		public alta: Date = new Date(),
		public tipo: string,
		public key: string,
		public idEntidad: number,
		public body?: string,
		public cabecera?: string,
		public usuarioRegistrador?: string,
		public destinatario?: string,
		public params?: any,
		public idioma: string = IdiomaEntorno(),
		public version?: number,
		public tipoOperacion?: string
	) {}

	public static fromData(data) {
		if (!data) return
		return new Novedad(
			data.alta ? new Date(data.alta) : null,
			data.tipo,
			data.key,
			data.idEntidad,
			data.body,
			data.cabecera,
			data.usuarioRegistrador,
			data.destinatario,
			data.params || {},
			data.idioma || IdiomaEntorno(),
			data.version,
			data.tipoOperacion
		)
	}

	public aplica(u: Usuario | UsuarioResumen) {
		return this.destinatario.split(";").some((d) => d === u.email || d === Destinatario.TODOS || u.tieneTag(d))
	}
}
