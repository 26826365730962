import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
// import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { Telefono } from 'src/app/model/Telefono';

@Component({
  selector: 'telefono-selector',
  templateUrl: './telefono-selector.component.html',
  styleUrls: ['./telefono-selector.component.less'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TelefonoSelectorComponent),
        multi: true,
    }
],
})
export class TelefonoSelectorComponent implements OnInit, ControlValueAccessor {

  // preferredCountries: CountryISO[] = [CountryISO.Argentina, CountryISO.Brazil, CountryISO.UnitedStates];
	// separateDialCode = false ;
	// SearchCountryField = SearchCountryField;
	// CountryISO = CountryISO;
  // PhoneNumberFormat = PhoneNumberFormat;

  private _telefono: Telefono
  public get telefono(): Telefono {
    return this._telefono;
  }
  @Input()
  public set telefono(v: Telefono) {
    if (v) {
      this._telefono = v;
      this.onChangeCallback(this._telefono);
    }

  }


  private onChangeCallback: (_: any) => void = () => { };
  private onTouchedCallback: (_: any) => void = () => { };

  @Input() disabled: boolean = false

  constructor() { }
  writeValue(obj: any): void {
    this.telefono = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  ngOnInit(): void {
  }

}
