import { animate, state, style, transition, trigger } from "@angular/animations"
import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import * as moment from "moment"
import { ConfirmationService } from "primeng/api"
import { BehaviorSubject, Observable } from "rxjs"
import { scan } from "rxjs/operators"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { ProfilePic } from "src/app/common/model/ProfilePic"
import { IdiomaService } from "src/app/common/services/idioma.service"
import { Ausencia } from "src/app/model/Ausencia"
import { BloqueoVehiculo } from "src/app/model/BloqueoVehiculo"
import { Idioma } from "src/app/model/Idioma"
import { Intercambio } from "src/app/model/Intercambio"
import { ItemReserva } from "src/app/model/ItemReserva"
import { Nota } from "src/app/model/Nota"
import { Pais } from "src/app/model/Pais"
import { Producto } from "src/app/model/Producto"
import { Proveedor } from "src/app/model/Proveedor"
import { ProveedorCabecera } from "src/app/model/ProveedorCabecera"
import { Region } from "src/app/model/Region"
import { AusenciaService } from "src/app/services/ausencia.service"
import { HotelService } from "src/app/services/hotel.service"
import { NotaService } from "src/app/services/nota.service"
import { PaisService } from "src/app/services/pais.service"
import { RegionService } from "src/app/services/region.service"
import { TipoProductoService } from "src/app/services/tipo-producto.service"
import { DisponibilidadService } from "../../../services/disponibilidad.service"
import { HelpService } from "../../../services/help.service"
import { ProductoCabecera } from "../../producto/ProductoCabecera"
import { FiltroPuesto } from "../../proveedor/FiltroPuesto"
import { Descriptivo } from "./../../../common/model/Descriptivo"
import { FiltroFecha } from "./../../../common/model/FiltroFecha"
import { DragDropContext } from "./../../../common/services/drag-drop-context.service"
import { LoadingService } from "./../../../common/services/loading-data-service.service"
import { StringUtils } from "./../../../common/utils/string-utils"
import { BloqueoFecha } from "./../../../model/BloqueoFecha"
import { Disponibilidad } from "./../../../model/Disponibilidad"
import { GrupoPax } from "./../../../model/GrupoPax"
import { GrupoReserva } from "./../../../model/GrupoReserva"
import { PuestoServicioReserva } from "./../../../model/PuestoServicioReserva"
import { TipoPuesto } from "./../../../model/TipoPuesto"
import { Vehiculo } from "./../../../model/Vehiculo"
import { BloqueoFechaService } from "./../../../services/bloqueo-fecha.service"
import { BloqueoVehiculoService } from "./../../../services/bloqueo-vehiculo.service"
import { ClienteService } from "./../../../services/cliente.service"
import { GrupoPaxService } from "./../../../services/grupo-pax.service"
import { ItemReservaService } from "./../../../services/item-reserva.service"
import { ProductoService } from "./../../../services/producto.service"
import { PuestoServicioReservaService } from "./../../../services/puesto-servicio-reserva.service"
import { TipoVehiculoService } from "./../../../services/tipovehiculo.service"
import { VehiculoService } from "./../../../services/vehiculo.service"
import { FiltroProducto } from "./../../producto/FiltroProducto"
import { FiltroGrupo } from "./../gestor-reserva/FiltroGrupo"
import { MovimientoDragDrop } from "./../model/MovimientoDragDrop"
@Component({
	selector: "actividades",
	templateUrl: "actividades.component.html",
	styleUrls: ["actividades.component.less"],
	animations: [
		trigger("inOutAnimation", [
			transition(":enter", [style({ bottom: "-1em", opacity: 0 }), animate("0.2s ease-out", style({ bottom: "0", opacity: 1 }))]),
			transition(":leave", [style({ bottom: "0", opacity: 1 }), animate("0.2s ease-in", style({ bottom: "-1em", opacity: 0 }))])
		]),
		trigger("liftEffect", [
			state(
				"normal",
				style({
					transform: "translateY(0)"
				})
			),
			state(
				"lifted",
				style({
					transform: "translateY(-10px)"
				})
			),
			transition("normal => lifted", animate("200ms ease-out")),
			transition("lifted => normal", animate("200ms ease-in"))
		])
	]
})
export class ActividadesComponent extends SessionComponent implements OnInit {
	public filtro: FiltroGrupo
	public layoutOptions = [
		{ label: "Grilla", value: "G", icon: "pi pi-table" },
		{ label: "Lista", value: "L", icon: "pi pi-list" },
		{ label: "Por proveedor", value: "P", icon: "pi pi-user" },
		{ label: "Por vehiculo", value: "V", icon: "pi pi-car" },
		{ label: "Por Actividad", value: "A", icon: "pi pi-shopping-bag" }
	]
	public itemEditado: ItemReserva
	public mostrarDetalleActividad: boolean = false
	public nuevasAsignaciones = false
	public vehiculos: Vehiculo[] = []
	public vehiculosFiltrados: Vehiculo[] = []
	public grupos: GrupoPax[] = []
	public alquilados: GrupoPax[] = []
	public delegados: GrupoPax[] = []
	public conVehiculo: GrupoPax[] = []
	public tercerizados: {
		grupos: GrupoPax[]
		vehiculo: Vehiculo
	}[] = []
	public transfers: GrupoPax[] = []
	public mostrarCliente
	public gruposFiltrados: GrupoPax[] = []
	public fechasBloqueadas: BloqueoFecha[] = []
	public opcionActualizacion: string = "i"
	public diferencia: number = 0
	public actualizarFechasPickupVisible: boolean = false
	public horas: number[] = Array(48)
		.fill(0)
		.map((x, i) => i / 2)
	public mostrarBloquearFecha: boolean = false
	public mostrarTraspasoGrupo: boolean = false
	public customLoading: LoadingService = new LoadingService()
	public grupoOrigen: GrupoPax
	public grupoPaxReferencia: GrupoPax
	public grupoDestino: GrupoPax
	public productos = new BehaviorSubject<Descriptivo[]>([])
	public productos$: Observable<Descriptivo[]>
	public hoteles: Descriptivo[] = []
	public regiones: Descriptivo[] = []
	public paises: Descriptivo[] = []
	public idiomas: Idioma[] = []
	public notas: Nota[] = []
	public mostrarNota = false
	public notaEditada: Nota
	public editandoNota: boolean = false
	public mostrarDetalleBloqueo: boolean = false
	public bloqueoEditado: BloqueoVehiculo
	public ausencias: Ausencia[] = []
	public disponibilidad: Disponibilidad[] = []
	public disponibilidadLength: number
	public puesto: TipoPuesto
	rowOver: number

	/* private _grupo: GrupoPax
	public get grupo(): GrupoPax {
		return this._grupo
	}
	@Input()
	public set grupo(v: GrupoPax) {
		this._grupo = v
	} */

	public ordenDelDia: {
		fecha: Date
		id: number
		orden: number
	}
	tercerizadoDragged: any

	public get fechaDesdeSafe(): Date {
		return this.filtro?.fecha || null
	}
	public set fechaDesdeSafe(v: Date) {
		if (this.filtro) {
			if (this.filtro.layout == "G") {
				this.filtro.setMultiple({
					fechaHasta: v ? moment(v).toDate() : null,
					fecha: v ? moment(v).startOf("date").toDate() : null
				})
			} else {
				this.filtro.fecha = v ? moment(v).toDate() : null
			}
			this.filtro.forceUpdate()
		}
	}
	public get fechaHastaSafe(): Date {
		return this.filtro?.fechaHasta || null
	}
	public set fechaHastaSafe(v: Date) {
		if (this.filtro) {
			if (this.filtro.layout == "G") {
				this.filtro.setMultiple({
					fechaHasta: v ? moment(v).toDate() : null,
					fecha: v ? moment(v).startOf("date").toDate() : null
				})
			} else {
				this.filtro.fechaHasta = v ? moment(v).toDate() : null
			}
			this.filtro.forceUpdate()
		}
	}

	public get layout(): string {
		return this.filtro.layout
	}
	public set layout(v: string) {
		this.gruposFiltrados = []
		this.filtro.setMultiple({ layout: v, fechaHasta: v == "G" ? null : moment(this.filtro.fecha).toDate() })
		this.filtro.forceUpdate()
	}

	public productoFiltro: FiltroProducto = new FiltroProducto("productos", -1, 100, "descripcion", 1)
	public fechas: { fecha: Date; actividades: GrupoPax[]; transfers: GrupoPax[]; tercerizados: GrupoPax[] }[] = []
	public vehiculosAgrupados: { vehiculo: Vehiculo; grupos: GrupoPax[]; actividades: Descriptivo[] }[] = []
	public proveedoresAgrupados: { proveedor: ProveedorCabecera; grupos: GrupoPax[]; actividades: Descriptivo[] }[] = []
	public actividadesAgrupadas: { producto: ProductoCabecera; grupos: GrupoPax[]; actividades: Descriptivo[] }[] = []

	public getButtonClass() {
		return "full"
	}

	vehiculosOrdenados: {
		fecha: Date
		vehiculos: {
			id: number
			orden: number
		}[]
	}[] = []

	tercerizadosOrdenados: {
		fecha: Date
		vehiculos: {
			id: number
			orden: number
		}[]
	}[] = []

	public bloques: BloqueoVehiculo[] = []
	public cargandoFechas: boolean = false
	public nuevoGrupoDialog: boolean = false
	public nuevoGrupo: {
		actividad: Producto
		hora: number
		vehiculo: Vehiculo
	}
	constructor(
		public router: Router,
		public clienteService: ClienteService,
		public itemReservaService: ItemReservaService,
		public grupoPaxService: GrupoPaxService,
		public vehiculosService: VehiculoService,
		public bloqueoFechaService: BloqueoFechaService,
		public dragDropService: DragDropContext,
		public confirmationService: ConfirmationService,
		public puestoServicioReservaService: PuestoServicioReservaService,
		public productoService: ProductoService,
		private notaService: NotaService,
		public bloquoService: BloqueoVehiculoService,
		public tipoVehiculoService: TipoVehiculoService,
		public ausenciasService: AusenciaService,
		public disponibilidadService: DisponibilidadService,
		public helpService: HelpService,
		public hotelService: HotelService,
		public idiomaService: IdiomaService,
		public confService: ConfirmationService,
		public regionService: RegionService,
		public paisService: PaisService,
		public tipoProductoService: TipoProductoService
	) {
		super()
	}
	public async getGrupos(r: FiltroGrupo): Promise<GrupoPax[]> {
		this.grupos = await this.grupoPaxService.getAll(r).then((grupos) => {
			grupos.forEach((g) => {
				g["permiteGestionCostos"] =
					this.esAdministrador ||
					this.esJefeAdministracion ||
					moment(g.fechaActividad).startOf("date").isSameOrAfter(moment().subtract(14, "days").startOf("date"), "date")
			})
			return grupos
		})
		if (this.vehiculos.length == 0) {
			this.vehiculos = await this.vehiculosService.getAll().then((r) => {
				return r
			})
		}

		this.refreshGrupos()
		return this.grupos
	}
	public agregarNota() {
		this.notaEditada = this.notaService.newEnt()
		this.notaEditada.fechaNota = this.filtro.fecha
		this.mostrarNota = true
		this.editandoNota = true
	}
	public actualizarBloqueos() {
		this.bloqueoFechaService.getAll().then((r) => {
			this.fechasBloqueadas = r
		})
	}
	public accionHora(event, hora, vehiculo: Vehiculo) {
		this.nuevoGrupo = { hora: hora, vehiculo: vehiculo, actividad: null }
		this.nuevoGrupoDialog = true
	}
	public crearGrupo = async (event) => {
		if (!this.nuevoGrupo?.actividad) return
		const vehiculo = this.nuevoGrupo.vehiculo
		const producto = await this.productoService.getById(this.nuevoGrupo.actividad.id)
		const hora = this.nuevoGrupo.hora
		const g = new GrupoPax(
			this.nuevoGrupo.vehiculo?.capacidad < producto.cantidadMaxGrupo ? vehiculo.capacidad : producto.cantidadMaxGrupo,
			null,
			moment(this.filtro.fecha).startOf("day").add(hora, "hour").toDate(),
			[],
			ProductoCabecera.fromData(producto),
			[],
			[],
			producto.duracion
		)
		g.generadoManual = true
		g.vehiculoAsignado = vehiculo
		g.puestos = producto ? producto.puestosACubrir.map((p) => new PuestoServicioReserva(null, p.personalDefault, p.clonar(true))) : []
		if (!this.verificarVehiculo(vehiculo, hora, g, g.duracion)) return
		this.grupoPaxService
			.guardar(g)
			.then((r) => {
				this.filtro.forceUpdate()
			})
			.finally(() => (this.nuevoGrupoDialog = false))
	}
	public async actualizarBloqueosVehiculos() {
		this.vehiculos = [...(await this.vehiculosService.getAll())]
		this.actualizarBloqueos()
		this.aplicarFiltros(this.filtro)
		this.mostrarDetalleBloqueo = false
		this.bloqueoEditado = null
	}
	public aplicarFiltros(filtro: FiltroGrupo) {
		this.cargandoFechas = true
		this.tercerizados = []
		this.delegados = []
		this.transfers = []
		this.vehiculosFiltrados = this.vehiculos
			.filter((v) => {
				if (this.filtro.vehiculos?.length && !this.filtro.vehiculos.some((d) => d.id === v.id)) return false
				let esTipo = !filtro.tipoVehiculo || !v.tipoVehiculo || v.tipoVehiculo?.id == filtro.tipoVehiculo?.id
				if (this.filtro.mostrarSinOcupacion) {
					return esTipo
				} else {
					return esTipo && this.grupos.some((g) => g.vehiculoAsignado?.id == v.id)
				}
			})
			.map((v) => {
				let ordenado = this.vehiculosOrdenados.find((v) => moment(this.filtro.fecha).isSame(v.fecha))
				if (ordenado) {
					v["orden"] = ordenado.vehiculos?.find((vv) => vv.id == v.id)?.orden ?? 99999
				} else {
					v["orden"] = this.buscarCategoriaMasBaja(v)
				}
				return v
			})
			.sort(this.sortVehiculos)

		this.gruposFiltrados = this.grupos.filter((g) => {
			return (
				(!g.vehiculoAsignado || this.vehiculosFiltrados.some((v) => !g.vehiculoAsignado || g.esAlquiler || v?.id == g.vehiculoAsignado?.id)) &&
				(!filtro.productos?.length || filtro.productos.some((pp) => g.tieneProducto(pp))) &&
				(!filtro.cliente || g.gruposReserva.some((gr) => gr.itemReserva?.cliente?.id == filtro.cliente?.id)) &&
				((filtro.soloSinAsignar && g.sinAsignar) || !filtro.soloSinAsignar) &&
				(!filtro.hoteles?.length || filtro.hoteles.some((h) => g.tieneHotel(h))) &&
				(!filtro.idiomas?.length || filtro.idiomas.some((i) => g.tieneIdioma(i))) &&
				(!filtro.paises?.length || filtro.paises.some((p) => g.tienePais(p))) &&
				(!filtro.regiones?.length || filtro.regiones.some((p) => g.tieneRegion(p))) &&
				(!filtro.datosIncompletos || g.datosIncompletos()) &&
				(!filtro.tipoProducto || g.producto.tipoProducto?.id == filtro.tipoProducto.id) &&
				(!g.gruposReserva?.length ||
					g.gruposReserva.some((p) => !filtro.searchStr || StringUtils.contiene(p.itemReserva?.descripcion, filtro.searchStr)))
			)
		})
		this.fechas = []
		this.proveedoresAgrupados = []
		this.vehiculosAgrupados = []
		this.vehiculosFiltrados = this.filtro.mostrarSinOcupacion
			? this.vehiculosFiltrados
			: this.vehiculosFiltrados.filter((v) => this.gruposFiltrados.some((g) => g.vehiculoAsignado?.id == v.id))
		this.vehiculosFiltrados.forEach((v, index) => {
			v["orden"] = index
		})
		if (this.filtro.layout == "P") {
			const sinAsignar = { proveedor: new ProveedorCabecera(null, "Por asignar"), grupos: [], actividades: [] }
			this.proveedoresAgrupados = [sinAsignar]
			this.gruposFiltrados.forEach((g) => {
				g.puestos.forEach((p) => {
					if (!this.proveedoresAgrupados.some((p2) => p2.proveedor?.id == p.proveedor?.id)) {
						this.proveedoresAgrupados.push({ proveedor: p.proveedor, grupos: [], actividades: [] })
					}
					const v = this.proveedoresAgrupados.find((p2) => (p2.proveedor == null ? sinAsignar : p2.proveedor?.id == p.proveedor?.id))
					if (v) {
						v.grupos.push(g)
						if (!v.actividades.some((a) => a.id == g.producto?.id)) {
							v.actividades.push(g.producto)
						}
					}
				})
			})
		}
		if (this.filtro.layout == "A") {
			this.actividadesAgrupadas = []
			this.gruposFiltrados.forEach((g) => {
				if (!this.actividadesAgrupadas.some((v) => v.producto?.id == g.producto?.id)) {
					this.actividadesAgrupadas.push({ producto: g.producto, grupos: [], actividades: [] })
				}
				const a = this.actividadesAgrupadas.find((v) => v.producto?.id == g.producto?.id)
				if (a) {
					a.grupos.push(g)
					if (!a.actividades.some((ac) => ac.id == g.producto?.id)) {
						a.actividades.push(g.producto)
					}
				}
			})
			this.actividadesAgrupadas?.sort((a, b) => {
				if (!a.producto.peso) return -1
				if (a.producto.peso > b.producto.peso) return 1
				else return -1
			})
			this.actividadesAgrupadas?.forEach((a) => {
				a.grupos.sort((a, b) => {
					if (moment(a.fechaActividad).isBefore(moment(b.fechaActividad))) return -1
					else return 1
				})
			})
		}

		if (this.filtro.layout == "V") {
			this.vehiculosAgrupados = []
			this.gruposFiltrados.forEach((g) => {
				if (!this.vehiculosAgrupados.some((v) => v.vehiculo?.id == g.vehiculoAsignado?.id)) {
					this.vehiculosAgrupados.push({ vehiculo: g.vehiculoAsignado, grupos: [], actividades: [] })
				}
				const v = this.vehiculosAgrupados.find((v) => v.vehiculo?.id == g.vehiculoAsignado?.id)
				if (v) {
					v.grupos.push(g)
					if (!v.actividades.some((a) => a.id == g.producto?.id)) {
						v.actividades.push(g.producto)
					}
				}
			})
		}
		this.gruposFiltrados.forEach((g) => {
			if (!this.fechas.some((f) => moment(f.fecha).isSame(g.fechaActividad, "date"))) {
				this.fechas.push({ fecha: g.fechaActividad, actividades: [], transfers: [], tercerizados: [] })
			}

			const l = this.fechas.filter((f) => moment(f.fecha).isSame(g.fechaActividad, "date"))[0]
			if (l) {
				if (g.producto?.esTransfer()) {
					l.transfers.push(g)
				} else if (g?.producto?.esTercerizado()) {
					l.tercerizados.push(g)
				} else {
					l.actividades.push(g)
				}
			}
		})
		this.conVehiculo = this.gruposFiltrados.filter((g) => g.vehiculoAsignado)
		this.alquilados = this.gruposFiltrados.filter((f) => f.esAlquiler)
		this.delegados = this.gruposFiltrados.filter((f) => f.esDelegado && !f.esAlquiler)
		this.transfers = this.gruposFiltrados.filter((f) => f.producto?.esTransfer() && !f.esDelegado)
		this.generarTercerizados(this.delegados)
		this.generarTercerizados(this.gruposFiltrados.filter((g) => g.producto["tipoProducto"].codigo == "TER"))
		this.tercerizados.sort(this.sortTercerizados)

		this.tercerizados.forEach((t, index) => {
			t.grupos.sort((a, b) => {
				if (moment(a.fechaActividad).isBefore(moment(b.fechaActividad))) return 1
				else return -1
			})
			t.vehiculo["orden"] = index
		})

		this.cargandoFechas = false
	}

	buscarCategoriaMasBaja(v: Vehiculo): number {
		let c: number = 99

		this.grupos
			.filter((g) => g.vehiculoAsignado?.id == v?.id)
			.forEach((g) => {
				g.producto?.categorias?.forEach((categoria) => {
					if (categoria.peso < c) {
						c = categoria.peso
					}
				})
			})
		c = c * (1000 + (this.grupos.find((g) => g.vehiculoAsignado?.id == v?.id)?.producto.peso ?? 99))
		return c
	}

	generarTercerizados(grupos: GrupoPax[]) {
		grupos.forEach((d) => {
			let g

			g = this.tercerizados.find((t) =>
				d.puestos[0].proveedor ? t.vehiculo?.proveedor?.id == d.puestos[0].proveedor?.id : t.vehiculo.proveedor?.codigo == "SIN_DELEGAR"
			)

			if (g) {
				g.grupos.push(d)
			} else {
				let v = new Vehiculo()
				let p = new ProfilePic()
				p.picPath = d.puestos[0].proveedor?.imagen
				p.picPathVersion = d.puestos[0].proveedor?.imagen

				v.capacidad = 9999
				v.profilePic = p
				v.descripcion = d.puestos[0].proveedor?.descripcion || this.translateService.get("SIN_DEFINIR")
				v.patente = d.puestos[0].proveedor?.descripcion || this.translateService.get("SIN_DEFINIR")
				v.proveedor = d.puestos[0].proveedor
					? Proveedor.fromData(d.puestos[0].proveedor)
					: Proveedor.fromData({ codigo: "SIN_DELEGAR", descripcion: this.translateService.get("SIN_DEFINIR") })
				v.peso = d.puestos[0].proveedor ? v.proveedor.id : -1

				const t = this.tercerizadosOrdenados.find((t) => moment(this.filtro.fecha).isSame(t.fecha))

				if (t) {
					v["orden"] = t?.vehiculos?.find((v) => v.id == d.puestos[0].proveedor.id)?.orden ?? 99999
				} else {
					v["orden"] = this.buscarCategoriaMasBaja(v)
				}
				v.id = v.proveedor?.id || 0

				g = {
					grupos: [d],
					vehiculo: v
				}

				this.tercerizados.push(g)
			}
		})
	}

	trackByFn = (index, item) => {
		return item.id
	}
	public getFila(grupo: GrupoPax) {
		if (grupo.esDelegado) return this.delegados.indexOf(grupo) + this.vehiculosFiltrados.length + this.alquilados.length
		if (grupo.esAlquiler) return this.alquilados.indexOf(grupo) + this.vehiculosFiltrados.length
		return this.vehiculosFiltrados.map((v) => v.id).indexOf(grupo.vehiculoAsignado?.id)
	}
	public onDragOver(event) {
		event.target.classList.add("over")
	}
	private lastMove = new Date()
	public onTopOver(event, container) {
		if (moment(new Date()).diff(this.lastMove, "ms") > 50) {
			this.lastMove = new Date()
			container.scrollTop += -70
		}
	}
	public onBottomOver(event, container) {
		if (moment(new Date()).diff(this.lastMove, "ms") > 50) {
			this.lastMove = new Date()
			container.scrollTop += +70
		}
	}

	public onDragLeave(event) {
		event.target.classList.remove("over")
		event.target.classList.remove("over-disabled")
	}

	public cambioHorarioAlquiler(hora, tipo, evento) {
		evento.target.classList.remove("over")
		evento.target.classList.remove("over-disabled")
		let data: GrupoPax = this.dragDropService.fromContext
		evento.stopPropagation()
		if (tipo == "delegado" && !data.esDelegado) {
			return this.error(this.translateService.get("DELEGAR"))
		}
		if (data.duracion + hora > 24 || hora < 0) {
			data["hide"] = false
			return this.error(this.translateService.get("FUERA_DE_RANGO"))
		}
		data.fechaActividad = moment(data.fechaActividad).startOf("day").add(hora, "hour").toDate()
		data["hide"] = false
		return this.guardarGrupo(data)
	}
	public verificarVehiculo = (vehiculo: Vehiculo, hora: number, grupo: GrupoPax, duracion?: number) => {
		var inter = this.getInterseccion(vehiculo, hora, (duracion || grupo.duracion) + hora)
		if (inter?.filter((g) => g != grupo).length > 0) {
			inter.forEach((g) => {
				g["error"] = "solapado"
				setTimeout(() => {
					g["error"] = ""
				}, 1000)
			})
			grupo["hide"] = false

			return this.error(this.translateService.get("HAY_GRUPO_SOLAPADO"))
		}

		if (vehiculo?.id != grupo.vehiculoAsignado?.id) {
			if (!grupo.aplicaVehiculo(vehiculo)) {
				grupo["hide"] = false
				return this.error(this.translateService.get("EL_VEHICULO_NO_CUMPLE"))
			}
			if (vehiculo.esBloqueado(this.filtro.fecha)) {
				grupo["hide"] = false
				return this.error(this.translateService.get("El vehiculo destino se encuentra bloqueado"))
			}
			grupo.setVehiculo(vehiculo)
		}
		return true
	}
	public actualizarLayout(event) {
		if (event.value == "G") {
			this.filtro.setMultiple({ fechaHasta: moment(this.filtro.fecha).endOf("date").toDate() }, true)
			this.filtro.forceUpdate()
		}
	}
	public eliminarGrupo(grupo: GrupoPax) {
		if (!grupo?.esVacio || !grupo?.id) return
		this.grupoPaxService.eliminar(grupo?.id).then((r) => {
			this.filtro.forceUpdate()
		})
	}
	public cambioHorario(hora, vehiculo: Vehiculo, evento, grupo?: GrupoPax, fechaOriginal?: Date, duracion?: number) {
		evento?.target?.classList?.remove("over")
		evento?.target?.classList?.remove("over-disabled")
		let data: GrupoPax = grupo || this.dragDropService.fromContext
		const fOrigen: Date = fechaOriginal || data.fechaActividad
		const _duracion: number = duracion || data.duracion
		evento?.stopPropagation()
		if (_duracion + hora > 24 || hora < 0) {
			data["hide"] = false
			return this.error(this.translateService.get("FUERA_DE_RANGO"))
		}
		data["hide"] = false
		if (this.verificarVehiculo(vehiculo, hora, data, _duracion)) {
			data.fechaActividad = grupo?.fechaActividad || moment(data.fechaActividad).startOf("day").add(hora, "hour").toDate()

			data.duracion = _duracion
			return this.guardarGrupo(data)
				.then((g) => this.filtro.forceUpdate())
				.then((g) => {
					if (fOrigen && !moment(fOrigen).isSame(data.fechaActividad) && data.gruposReserva.length) {
						this.diferencia = moment(data.fechaActividad).diff(moment(fOrigen), "minutes")
						this.opcionActualizacion = "i"
						this.actualizarFechasPickupVisible = true
						this.grupoPaxReferencia = data
					}
				})
		} else {
			return false
		}
	}
	public actualizarPickup = () => {
		this.actualizarFechasPickupVisible = false
		this.grupoPaxService.actualizarPickups(this.grupoPaxReferencia, this.diferencia, this.opcionActualizacion, this.customLoading).then((r) => {
			this.filtro.forceUpdate()
		})
	}
	public cambioHorarioTransfer(grupo: GrupoPax, event) {
		const fechaOriginal = event?.original || null
		const duracion = event?.duracion || grupo.duracion
		if (!this.cambioHorario(grupo.getHora(), grupo.vehiculoAsignado, null, grupo, fechaOriginal, duracion)) {
			if (fechaOriginal) {
				grupo.fechaActividad = fechaOriginal
			}
		}
	}
	public refreshGrupos() {
		this.aplicarFiltros(this.filtro)
	}
	public guardarGrupo(grupo: GrupoPax) {
		this.customLoading.status = "Actualizando..."
		return this.grupoPaxService
			.guardar(grupo, this.customLoading)
			.then((r) => {
				this.customLoading.status = "Ok"
				this.refreshGrupos()
			})
			.catch((e) => this.getGrupos(this.filtro))
	}
	public getInterseccion(vehiculo: Vehiculo, col: number, colHasta: number): GrupoPax[] {
		return this.grupos.filter(
			(g) => ((!vehiculo && !g.vehiculoAsignado) || g.vehiculoAsignado?.id == vehiculo?.id) && g.horaDesde <= colHasta * 2 && col * 2 < g.colHasta
		)
	}

	public asignar(puesto: PuestoServicioReserva) {
		this.puestoServicioReservaService
			.asignar(puesto)
			.then((r) => {
				this.success(this.translateService.get("ASIGNACION_CORRECTA"))
			})
			.catch((r) => {
				puesto.personal = null
			})
	}
	public onDragEnd(event, data) {
		if (data) data["hide"] = false
		event.target.classList.remove("over")
		event.target.classList.remove("over-disabled")
	}
	public drop(event, grupo: GrupoPax) {
		this.grupoOrigen = GrupoPax.fromData(event.item.data.grupo)
		this.grupoDestino = GrupoPax.fromData(grupo)
		event.target.classList.remove("over")
		event.target.classList.remove("over-disabled")
		this.mostrarTraspasoGrupo = true
	}

	public getNextBatch() {
		this.productoFiltro.page++
		this.productoService.getServicios(this.productoFiltro, this.customLoading, false).then((r) => {
			this.productos.next(r)
		})
	}
	ngOnInit() {
		this.vehiculosOrdenados = JSON.parse(localStorage.getItem(this.keyVehiculosOrdenados)) || []
		this.tercerizadosOrdenados = JSON.parse(localStorage.getItem(this.keyTercerizadosOrdenados)) || []

		this.actualizarBloqueos()
		this.filtro = this.fitlerServices.getFilter("grupos", new FiltroGrupo("grupos"))
		this.filtro.busquedaForzadaUnicamente = true
		this.productoFiltro.verArchivados = true
		this.vehiculosService.getAll().then((r) => (this.vehiculos = r))
		this.productos$ = this.productos.pipe(
			scan((acc, curr) => {
				return [...acc, ...curr]
			}, [])
		)
		this.regionService.getHabilitados().then((r) => (this.regiones = [...r, Region.fromData(Descriptivo._SIN_DEFINIR)]))
		this.paisService.getHabilitados().then((r) => (this.paises = [...r, Pais.fromData(Descriptivo._SIN_DEFINIR)]))
		this.getNextBatch()
		this.hotelService.getAll().then((r) => (this.hoteles = r))
		this.idiomaService.getAll().then((r) => (this.idiomas = r))

		this.subs.push(
			this.filtro.dataChange.subscribe(async (r) => {
				const fFecha = FiltroFecha.getByFechas(r.fecha, this.layout == "G" ? r.fecha : r.fechaHasta)

				let f = new FiltroPuesto()

				f.fechaDesde = fFecha.fechaDesde || this.now
				f.fechaHasta = fFecha.fechaHasta ? moment(fFecha.fechaHasta).toDate() : moment(fFecha.fechaDesde).endOf("date").toDate()
				if (Math.abs(moment(f.fechaDesde).diff(f.fechaHasta, "days")) > 60) {
					return this.error("No puede realizar busquedas de mas de 60 días a la vez")
				}
				this.ausenciasService.getAll(f).then((a) => (this.ausencias = a))
				this.disponibilidadService.getAll(f).then((d) => (this.disponibilidad = d))

				this.notaService.getAll(fFecha).then((n) => {
					this.notas = n
				})
				this.getGrupos(r)
				this.fitlerServices.setFilter("grupos", r)
				this.aplicarFiltros(r)
			})
		)
		this.subs.push(
			this.filtro.filterChange.subscribe((r) => {
				this.aplicarFiltros(r)
			})
		)
	}

	public verNota(nota: Nota) {
		this.notaEditada = Nota.fromData(nota)
		this.mostrarNota = true
		this.editandoNota = false
	}
	public getNotaByFecha(fecha: Date) {
		return this.notas.filter((n) => moment(n.fechaNota).isSame(fecha, "date"))
	}

	public actualizarVehiculos(event) {
		if (!event) return
		const grupo: GrupoPax = event?.grupo
		const vehiculoOrgien = event?.from
		const vehiculoDestino = event?.to
		if (this.verificarVehiculo(vehiculoDestino, grupo.getHora(), grupo)) {
			this.guardarGrupo(grupo).then((r) => {
				this.filtro.forceUpdate()
				this.success(this.translateService.get("VEHICULO_ACTUALIZADO"))
			})
		}
	}
	public irAFecha(date: Date) {
		if (this.isMobile()) return
		if (!date) date = new Date()
		this.filtro.patchValue({
			...this.filtro.json,
			fecha: moment(date).startOf("date").toDate(),
			fechaHasta: moment(date).toDate(),
			layout: "G"
		})
		this.filtro.forceUpdate()
	}
	public actualizarGrupos(mov: MovimientoDragDrop) {
		var inter: Intercambio = new Intercambio(
			mov.grupoReservaOrigen?.id,
			mov.grupoReservaDestino?.id,
			mov.grupoOrigen?.id,
			mov.grupoDestino?.id,
			mov.cantidad
		)
		this.grupoPaxService
			.actualizarIntercambio(inter)
			.then((r) => {
				this.success(this.translateService.get("ACTIVIDADES_ACTUALIZADAS"))
				this.filtro.forceUpdate()
			})
			.catch((r) => {
				this.getGrupos(this.filtro)
			})
	}

	public bloquearFecha(date: Date) {
		this.mostrarBloquearFecha = true
	}

	public desbloquear(date: Date) {
		this.confirmationService.confirm({
			key: "genConf",
			header: this.translateService.get("DESBLOQUEAR_FECHA"),
			message: this.translateService.get("MENSAJE_HABILITAR_FECHA").replace("{$1}", moment(date).format("DD/MM/YYYY")),
			accept: () => {
				this.bloqueoFechaService.eliminar(this.getBloqueo(date)?.id).then((r) => {
					this.success(this.translateService.get("FECHA_HABILITADA"))
					this.actualizarBloqueos()
				})
			}
		})
	}

	public esBloqueada(date: Date): boolean {
		return this.getBloqueo(date) != undefined
	}

	public getBloqueo(date: Date) {
		let fechasBloqueadas = this.fechasBloqueadas.filter((d) => moment(d.fecha).isSame(moment(date), "date"))[0]
		return fechasBloqueadas
	}

	public actualizarItemReserva(item: ItemReserva) {
		this.itemReservaService.actualizarReserva(item).then((r) => {
			this.success(this.translateService.get("RESERVA_ACTUALIZADA"))
			this.mostrarDetalleActividad = false
			this.itemEditado = r
			this.grupoEditado && this.grupoEditado.updateItemReserva(r)

			//this.filtro.forceUpdate()
		})
	}
	public grupoBorrable(grupo: GrupoPax) {
		return false
	}
	public nuevo() {
		this.router.navigate(["reserva/nuevo"])
	}

	public editar(item: ItemReserva) {
		this.router.navigate(["reserva/edit"], { queryParams: { id: item.idReserva } })
	}
	public cancelar() {
		this.mostrarDetalleActividad = false
		this.grupoEditado = null
	}
	bloquearVehiculo(vehiculo: Vehiculo) {
		if (!vehiculo) return
		let b = this.bloquoService.newEnt()
		b.vehiculo = vehiculo
		b.fechaDesde = this.filtro.fecha
		this.bloqueoEditado = b
		this.mostrarDetalleBloqueo = true
	}
	verBloqueo(vehiculo: Vehiculo) {
		if (!vehiculo) return
		this.bloqueoEditado = vehiculo.getBloqueo(this.filtro.fecha)
		this.mostrarDetalleBloqueo = true
	}
	async ver(item: GrupoReserva) {
		this.grupoEditado = item
		this.itemEditado = await this.itemReservaService.getById(item?.itemReserva.id)
		this.mostrarDetalleActividad = true
	}
	public grupoEditado: GrupoReserva
	async mostrarItem(item: GrupoReserva) {
		this.grupoEditado = item
		this.itemEditado = await this.itemReservaService.getById(item?.itemReserva.id)
	}

	mostrarDisponibilidad = false

	public irAHoy() {
		this.irAFecha(new Date())
	}
	public navegarDias(cantidad) {
		if (!this.filtro.fecha) return
		let fecha = moment(this.filtro.fecha).startOf("date").add(cantidad, "days").toDate()
		this.irAFecha(fecha)
	}

	vehiculoDragged: Vehiculo

	dropFilaVehiculo(v) {
		let end = this.vehiculosFiltrados.find((vehiculo) => vehiculo.id == v.id)
		let start = this.vehiculosFiltrados.find((vehiculo) => vehiculo.id == this.vehiculoDragged.id)

		const pesoStart = start["orden"]

		const pesoEnd = end["orden"]

		end["orden"] = pesoStart
		start["orden"] = pesoEnd

		this.vehiculosFiltrados.sort(this.sortVehiculos)

		let f = this.vehiculosOrdenados.find((v) => moment(this.filtro.fecha).isSame(v.fecha))

		if (f) {
			f.vehiculos = []
		} else {
			f = {
				fecha: this.filtro.fecha,
				vehiculos: []
			}
			this.vehiculosOrdenados.push(f)
		}
		this.vehiculosFiltrados.forEach((v) => {
			f.vehiculos = [...(f.vehiculos ?? []), { id: v.id, orden: v["orden"] }]
		})

		this.vehiculosOrdenados = this.vehiculosOrdenados.filter((v) => moment(v.fecha).isSameOrAfter(moment().startOf("day")))

		localStorage.setItem(this.keyVehiculosOrdenados, JSON.stringify(this.vehiculosOrdenados))
	}

	sortVehiculos = (v1, v2) => {
		return v1.orden === v2.orden ? (v1.peso === v2.peso ? (v1.patente < v2.patente ? -1 : 1) : v1.peso < v2.peso ? -1 : 1) : v1.orden < v2.orden ? -1 : 1
	}

	sortTercerizados = (t1, t2) => {
		return t1.vehiculo?.orden === t2.vehiculo?.orden ? (t1.vehiculo.peso < t2.vehiculo.peso ? -1 : 1) : t1.vehiculo.orden > t2.vehiculo.orden ? 1 : -1
	}

	dragStartTercerizado(v) {
		this.tercerizadoDragged = v
	}

	get keyTercerizadosOrdenados() {
		return this.usuario.id + "_" + (this.environment.production ? "prod" : "dev") + "_tercerizadosOrdenados"
	}

	isRowLifted(item: any): boolean {
		return this.vehiculoDragged != null && this.rowOver === item
	}

	dragStartVehiculo(v) {
		this.vehiculoDragged = v
	}

	get keyVehiculosOrdenados() {
		return this.usuario.id + "_" + (this.environment.production ? "prod" : "dev") + "_vehiculosOrdenados"
	}

	dragEnter(index) {
		this.rowOver = index
	}

	dragEnd() {
		this.rowOver = null
		this.vehiculoDragged = null
		this.tercerizadoDragged = null
	}

	isRowLiftedTercerizado(item: any): boolean {
		return this.tercerizadoDragged != null && this.rowOver === item
	}
	cambioVehiculoTransfer = (grupo, evento) => {
		alert(evento)
	}
	dropFilaTercerizado(item) {
		let end = this.tercerizados.find((t) => t.vehiculo?.id == item.vehiculo?.id)
		let start = this.tercerizados.find((t) => t.vehiculo.id == this.tercerizadoDragged.vehiculo.id)

		const pesoStart = start?.vehiculo["orden"]

		const pesoEnd = end?.vehiculo["orden"]

		end.vehiculo["orden"] = pesoStart
		start.vehiculo["orden"] = pesoEnd

		this.tercerizados.sort(this.sortTercerizados)

		let f = this.tercerizadosOrdenados.find((v) => moment(this.filtro.fecha).isSame(v.fecha))

		if (f) {
			f.vehiculos = []
		} else {
			f = {
				fecha: this.filtro.fecha,
				vehiculos: []
			}
			this.tercerizadosOrdenados.push(f)
		}
		this.tercerizados.forEach((v) => {
			f.vehiculos = [...(f.vehiculos ?? []), { id: v.vehiculo.id, orden: v.vehiculo["orden"] }]
		})
		this.tercerizadosOrdenados = this.tercerizadosOrdenados.filter((v) => moment(v.fecha).isSameOrAfter(new Date()))

		localStorage.setItem(this.keyTercerizadosOrdenados, JSON.stringify(this.tercerizadosOrdenados))
	}
}
