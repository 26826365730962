<div class="compras content">
	<div class="filter-content p-0">
		<filtro [(filter)]="filtro" [columns]="columns" [filterTemplateContent]="filterTemplate"> </filtro>
		<ng-template #filterTemplate>
			<div class="grid filter-content-template">
				<div class="col-12 lg:col-2 p-fluid">
					<div class="p-inputgroup">
						<span class="p-float-label">
							<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
							<label>{{ "BUSCAR" | translate }}...</label>
						</span>
						<button type="button" pButton icon="pi pi-search"></button>
					</div>
				</div>
				<div class="col-12 lg:col-2">
					<descriptivo-material-selector
						[label]="'PROVEEDOR' | translate"
						[limpiable]="true"
						[(ngModel)]="filtro.proveedor"
						name="cliente"
						isLazy="true"
						[service]="proveedorService"
					>
					</descriptivo-material-selector>
				</div>

				<div class="col-12 lg:col-2">
					<span class="p-float-label p-fluid">
						<p-calendar [(ngModel)]="filtro.fechaDesde" name="fechaDesde" dateFormat="dd/mm/yy" appendTo="body" [showIcon]="true"></p-calendar>
						<label for="">{{ "FECHADESDE" | translate }}</label>
					</span>
				</div>
				<div class="col-12 lg:col-2">
					<span class="p-float-label p-fluid">
						<p-calendar [(ngModel)]="filtro.fechaHasta" name="fechaHasta" dateFormat="dd/mm/yy" appendTo="body" [showIcon]="true"></p-calendar>
						<label for="">{{ "HASTA" | translate }}</label>
					</span>
				</div>
				<div class="col" *ngIf="tabActive == 1">
					<selector-multiple-chips
						[(ngModel)]="filtro.categorias"
						name="cats"
						[servicio]="categoriasInsumoService"
						[permiteNuevo]="false"
						[limpiable]="true"
						[titulo]="'CATEGORIA' | translate"
						[maxItems]="3"
					>
					</selector-multiple-chips>
				</div>
				<div class="col-12 lg:col-12">
					<selector-multiple-chips
						[(ngModel)]="filtro.tiposComprobante"
						name="tiposcomp"
						[servicio]="tipoComprobanteService"
						[permiteNuevo]="false"
						[titulo]="'Tipos de factura' | translate"
						[maxItems]="3"
					>
					</selector-multiple-chips>
				</div>
			</div>
		</ng-template>
	</div>
	<p-tabView orientation="top" [styleClass]="'ranking-tabs'" (onChange)="onChange($event)">
		<p-tabPanel header="{{ 'FACTURA_PLR' | translate }}">
			<div class="tabla">
				<p-table
					#table
					[value]="facturas"
					[columns]="columns"
					[paginator]="true"
					sortField="fecha"
					[rows]="filtro?.size || 20"
					[rowsPerPageOptions]="[5, 10, 20, 50, 100]"
					[loading]="loading.loading | async"
				>
					<ng-template pTemplate="caption">
						<div class="p-helper-clearfix" style="text-align: left">
							<button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="XLSX" (click)="exportExcel()"></button>
						</div>
					</ng-template>
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th pSortableColumn="fecha">{{ "FECHA" | translate }} <p-sortIcon field="fecha"></p-sortIcon></th>
							<th colspan="2">{{ "Tipo Comprobante" | translate }}</th>
							<th pSortableColumn="numeroComprobante">{{ "Número" | translate }} <p-sortIcon field="numeroComprobante"></p-sortIcon></th>
							<th>{{ "Adjunto" | translate }}</th>
							<th pSortableColumn="nombreProveedor">{{ "Proveedor" | translate }} <p-sortIcon field="nombreProveedor"></p-sortIcon></th>
							<th>{{ "Cuit" | translate }}</th>
							<th class="text-right">{{ "Imp. Bruto" | translate }}</th>
							<th class="text-right">{{ "IVA" | translate }}</th>
							<th class="text-right">{{ "Otros Impuestos" | translate }}</th>
							<th class="text-right">{{ "Imp. Neto" | translate }}</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-rowData let-columns="columns">
						<tr (click)="goToFactura(rowData.id)">
							<td>{{ rowData.fecha | date : "dd/MM/yyyy" }}</td>
							<td class="text-center" style="width: 2em">{{ rowData.tipoComprobante?.codigo }}</td>
							<td style="width: 10em">{{ rowData.tipoComprobante?.descripcion }}</td>
							<td>{{ rowData.numeroComprobante }}</td>
							<td class="text-center" style="width: 5em" (click)="rowData.urlAdjunto && openLink($event, rowData.urlAdjunto)">
								<i class="pi pi-download" *ngIf="rowData.urlAdjunto"></i>
							</td>

							<td>{{ rowData.nombreProveedor }}</td>
							<td>{{ rowData.cuitProveedor }}</td>
							<td class="text-right">{{ rowData.importeBruto | currencyCustom : rowData.moneda }}</td>
							<td class="text-right">{{ rowData.impuestos | currencyCustom : rowData.moneda }}</td>
							<td class="text-right">{{ rowData.otrosImpuestos | currencyCustom : rowData.moneda }}</td>
							<td class="text-right">{{ rowData.importe | currencyCustom : rowData.moneda }}</td>
						</tr>
					</ng-template>

					<ng-template pTemplate="footer">
						<tr *ngFor="let totalMoneda of totalesMonedas; let i = index">
							<td colspan="6" class="text-right" *ngIf="i == 0">
								<strong>{{ "TOTALES" | translate }}: </strong>
							</td>
							<td colspan="1" class="text-right" *ngIf="i == 0">
								<strong>{{ " " + totalMoneda?.moneda?.codigo || "$ " }}</strong>
							</td>
							<td colspan="7" class="text-right" *ngIf="i > 0">
								<strong>{{ " " + totalMoneda?.moneda?.codigo || "$ " }}</strong>
							</td>
							<td class="text-right">
								<strong>{{ totalMoneda?.totalBruto | currencyCustom : totalMoneda?.moneda }}</strong>
							</td>
							<td class="text-right">
								<strong>{{ totalMoneda?.totalImpuestos | currencyCustom : totalMoneda?.moneda }}</strong>
							</td>
							<td class="text-right">
								<strong>{{ totalMoneda?.totalOtrosImpuestos | currencyCustom : totalMoneda?.moneda }}</strong>
							</td>
							<td class="text-right">
								<strong>{{ totalMoneda?.totalConsumido | currencyCustom : totalMoneda?.moneda }}</strong>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</p-tabPanel>
		<p-tabPanel header="{{ 'ITEMS' | translate }}">
			<div class="tabla">
				<p-table
					#table
					[value]="items"
					[columns]="columns"
					[paginator]="true"
					sortField="fecha"
					[rows]="20"
					[rowsPerPageOptions]="[5, 10, 20, 50, 100]"
					[loading]="loading.loading | async"
				>
					<ng-template pTemplate="caption">
						<div class="p-helper-clearfix" style="text-align: left">
							<button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="XLSX" (click)="exportExcelItems()"></button>
						</div>
					</ng-template>
					<ng-template pTemplate="header" let-columns>
						<th
							*ngFor="let col of columns | columnasVisibles"
							[ngStyle]="{ width: col.width }"
							class="{{ col.class }}"
							pResizableColumn
							tooltipPosition="bottom"
							pTooltip="{{ col.header }}"
							pSortableColumn="{{ col.field }}"
						>
							{{ col.header | translate }} <p-sortIcon *ngIf="col.sortable" field="{{ col.field }}"></p-sortIcon>
						</th>
					</ng-template>
					<ng-template pTemplate="body" let-rowData let-columns="columns">
						<tr>
							<td class="small">
								{{ rowData.proveedor?.descripcion }}
							</td>
							<td class="text-center">
								<span class="clickable chip comprobante" [ngClass]="{ adelanto: data?.esAdelanto }" (click)="verFactura(rowData)">
									{{ rowData.numeroFactura }}
								</span>
							</td>
							<td class="text-center w-5rem">
								{{ rowData.tipoComprobante?.codigo }}
							</td>

							<td class="text-center w-12rem">
								{{ rowData.fecha | date : "dd/MM/yyyy" }}
							</td>
							<td class="text-center w-5rem">
								{{ rowData.moneda?.codigo }}
								<small class="block" *ngIf="rowData.cotizacionAfip > 1">{{ rowData.cotizacionAfip }}</small>
							</td>
							<td class="text-center w-5rem">
								{{ rowData.ivaStr }}
							</td>
							<td class="text-left w-25rem overflow">
								{{ rowData?.insumo?.descripcion + (rowData?.esSubcategoria ? " - " + rowData.subcategoriaStr : "") }}
							</td>
							<td class="text-left w-10rem overflow">
								{{ rowData?.categoria?.descripcion }}
							</td>
							<td class="text-center w-5rem">
								{{ rowData.cantidad }}
							</td>
							<td class="w-14rem">
								<ng-container *ngTemplateOutlet="valorTemplate; context: { $implicit: rowData.precioUnitario, rowData: rowData }">
								</ng-container>
							</td>

							<td class="w-14rem">
								<ng-container *ngTemplateOutlet="valorTemplate; context: { $implicit: rowData.precioUnitarioBruto, rowData: rowData }">
								</ng-container>
							</td>
							<td class="w-14rem">
								<ng-container *ngTemplateOutlet="valorTemplate; context: { $implicit: rowData.impuestos, rowData: rowData }"> </ng-container>
							</td>

							<td class="w-14rem">
								<ng-container *ngTemplateOutlet="valorTemplate; context: { $implicit: rowData.total, rowData: rowData }"> </ng-container>
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="footer">
						<tr *ngFor="let totalMoneda of totalesMonedas; let i = index">
							<td colspan="9" class="text-right" *ngIf="i == 0">
								<strong>{{ "TOTALES" | translate }}: </strong>
							</td>
							<td colspan="1" class="text-right" *ngIf="i == 0">
								<strong>{{ " " + totalMoneda?.moneda?.codigo || "$ " }}</strong>
							</td>
							<td colspan="10" class="text-right" *ngIf="i > 0">
								<strong>{{ " " + totalMoneda?.moneda?.codigo || "$ " }}</strong>
							</td>

							<td class="text-right">
								<strong>{{ totalMoneda?.totalImpuestos + totalMoneda?.totalOtrosImpuestos | currencyCustom : totalMoneda?.moneda }}</strong>
							</td>

							<td class="text-right">
								<strong>{{ totalMoneda?.totalConsumido | currencyCustom : totalMoneda?.moneda }}</strong>
							</td>
						</tr>
						<tr *ngFor="let totalImpuesto of totalesImpuestos; let i = index">
							<td colspan="9" class="text-right" *ngIf="i == 0">
								<strong>{{ "IMPUESTOS" | translate }}: </strong>
							</td>
							<td colspan="1" class="text-right" *ngIf="i == 0">
								<strong>{{ " " + totalImpuesto?.impuesto?.descripcion }}</strong>
							</td>
							<td colspan="10" class="text-right" *ngIf="i > 0">
								<strong>{{ " " + totalImpuesto?.impuesto?.descripcion }}</strong>
							</td>

							<td class="text-right">
								<strong>{{ totalImpuesto?.total | currencyCustom }}</strong>
							</td>
							<td></td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</p-tabPanel>
	</p-tabView>
</div>

<ng-template let-value let-rowData="rowData" #valorTemplate>
	<div class="grid grid-nogutter">
		<div class="col-12 text-right">{{ value * (rowData?.cotizacion || 1) | currencyCustom }}</div>
		<div class="col-12 text-right" *ngIf="rowData.moneda?.codigo != monedaEntorno?.codigo && rowData?.cotizacion">
			<span [tooltipPosition]="'bottom'" [pTooltip]="'Importe en ' + rowData.moneda?.descripcion" class="oneline small">{{
				value | currency : rowData.moneda?.simbolo + " "
			}}</span>
		</div>
	</div>
</ng-template>
