import { Descriptivo } from "src/app/common/model/Descriptivo"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { IConAdjunto } from "../common-components/IConAdjunto"
import { Usuario } from "../common/model/Usuario"
import { round } from "../common/utils/MathUtils"
import { IConRecordatorio } from "../pages/recordatorio/IConRecordatorio"
import { IConCobros } from "./../common/components/abm-cobros/IConCobros"
import { Auditable } from "./Auditable"
import { FileData } from "./FileData"
import { ItemFactura } from "./ItemFactura"
import { ItemOrdenPago } from "./ItemOrdenPago"
import { Moneda } from "./Moneda"
import { Pago } from "./Pago"
import { Recordatorio } from "./Recordatorio"
import { TipoIVAGrabado } from "./TipoIVAGrabado"
import { CobroCompensacionSaldo } from "./cobros/CobroCompensacionSaldo"

export class Factura extends Auditable implements IConCobros, IConRecordatorio, IConAdjunto {
	get tipoOperacion(): "COMPRA" | "VENTA" {
		return "COMPRA"
	}
	agregarDespues(items: ItemFactura[], item: ItemFactura) {
		this.items.splice(this.items.indexOf(item) + 1, 0, ...items)
	}
	borrarPorReferencia(referenciaPadre: string) {
		this.items = this.items.filter((i) => !i.esSubcategoria || (i.esSubcategoria && i.referenciaPadre != referenciaPadre))
	}
	public get tipoEntidad(): string {
		return "FacturaVO"
	}
	public recordatorios: Recordatorio[] = []
	public clienteStr: string
	public adjunto: FileData
	public searchStr: string
	static clonar(r: Factura): Factura {
		let f = Factura.fromData(r)
		f.id = null
		f.fecha = new Date()
		f.fechaVencimiento = new Date()
		f.items.forEach((i) => (i.id = null))
		f.estado = null
		f.numero = null
		f.itemsPagos = []
		f.saldo = f.importe
		return f
	}
	constructor(
		public id?: number,
		public puntoVenta?: string,
		public numero?: string,
		public descripcion?: string,
		public fecha: Date = new Date(),
		public fechaVencimiento: Date = new Date(),
		public tipoComprobante?: Descriptivo,
		public proveedor?: Descriptivo,
		public centroCosto?: Descriptivo,
		public items: ItemFactura[] = [],
		public iibbOtros: number = 0,
		public saldo?: number,
		public importe?: number,
		public estado?: EstadoDocumento,
		public responsable?: Descriptivo,
		public numeroFacturaRelacionado?: string,
		public pagos: Pago[] = [],
		public enviarNotificacion: boolean = true,
		public destinatario?: string,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,

		public itemsPagos: ItemOrdenPago[] = [],
		public version?: number,
		public moneda?: Moneda,
		public cotizacion: number = 1
	) {
		super(lastModifiedBy, lastModifiedDate)
		this.searchStr = [(this.id, this.proveedor?.descripcion, this.numero, this.descripcion)].join("-")
	}
	get totalALocal(): number {
		return this.total * this.cotizacion
	}
	public static fromData(data: any): Factura {
		if (!data) return null
		const o: Factura = new Factura(
			data.id,
			data.puntoVenta,
			data.numero,
			data.descripcion,
			data.fecha ? new Date(data.fecha) : null,
			data.fechaVencimiento ? new Date(data.fechaVencimiento) : null,
			Descriptivo.fromData(data.tipoComprobante),
			Descriptivo.fromData(data.proveedor),
			Descriptivo.fromData(data.centroCosto),
			data.items.map((c) => ItemFactura.fromData(c)),
			data.iibbOtros,
			data.saldo,
			data.importe,
			EstadoDocumento.fromData(data.estado),
			Usuario.fromData(data.responsable),
			data.numeroFacturaRelacionado,
			data.pagos.map((p) => Pago.fromData(p)),
			null,
			null,
			data.lastModifiedBy,
			data.lastModifiedDate,
			data.pagos.map((p) => Pago.fromData(p))
		)
		o.itemsPagos = data.itemsPagos.map((ip) => ItemOrdenPago.fromData(ip)) ?? []
		o.version = data.version
		o.recordatorios = data.recordatorios.map((r) => Recordatorio.fromData(r)) ?? []
		o.adjunto = FileData.fromData(data.adjunto)
		o.moneda = Moneda.fromData(data.moneda)
		o.cotizacion = data.cotizacion || 1
		o.items = o.items.sort((a, b) => a.id - b.id)
		o.items
			.filter((i) => i.esCategorizado())
			.forEach((it) => {
				it.impuestosSubcategoria = o.getImpuestosSubategoria(it)
			})
		return o
	}
	get valores() {
		return this.pagos
	}
	public addItem(item: ItemFactura) {
		this.items.push(item)
	}

	public quitarItem(item: ItemFactura) {
		let i = this.items.indexOf(item)
		if (i > -1) {
			this.items.splice(i, 1)
		}
		if (item.esCategorizado()) {
			this.items = this.items.filter((it) => it.referenciaPadre != item.referenciaPadre)
		}
	}
	public getImpuestosSubategoria(item: ItemFactura) {
		return this.items.filter((it) => it.esSubcategoria && it.referenciaPadre == item.referenciaPadre).reduce((a, b) => a + b.impuestos, 0)
	}
	get total(): number {
		let totalItems = this.items.filter((it) => !it.esSubcategoria).reduce((a, b) => (a += b.importe), 0)
		return round(this.iibbOtros + totalItems, 2)
	}
	get totalImpuestos(): number {
		return round(this.iibbOtros + this.totalIvas, 2)
	}
	get ivas(): TipoIVAGrabado[] {
		let ivas = this.items
			.filter((i) => (!i.esCategorizado() || i.esSubcategoria) && i.tipoIVA && i.tipoIVA.valorIva > 0)
			.map((a) => a.tipoIVA)
			.filter((tip, i, arr) => arr.findIndex((t) => t.id == tip.id) === i)
		return ivas
	}

	totalIva(iva: TipoIVAGrabado): number {
		let totalIva = this.items
			.filter((i) => (!i.esCategorizado() || i.esSubcategoria) && i.tipoIVA?.codigo == iva?.codigo)
			.reduce((a, b) => (a += b.impuestos), 0)
		return round(totalIva, 2)
	}

	get totalIvas(): number {
		let totalIvas = this.ivas.map((i) => this.totalIva(i)).reduce((a, b) => a + b, 0)
		return round(totalIvas, 2)
	}

	get tipoNumero() {
		return this.tipoComprobante.codigo + " " + this.puntoVenta + "-" + this.numero
	}

	get saldoRestante() {
		return this.totalALocal > 0 ? this.totalALocal - this.totalPagos : 0
	}

	get totalPagos(): number {
		return this.pagos.reduce((a, b) => a + b.monto * b.cotizacionSafe, 0)
	}

	get subtotal() {
		return this.total - this.totalIvas - this.iibbOtros || 0
	}
	getSaldo(): number {
		return this.saldoRestante
	}
	agregarCobro(c: Pago) {
		if (c.tipoCobro == CobroCompensacionSaldo.TIPO) {
			c["listadoComprobantes"] = c["listadoFacturas"]
		}
		this.pagos.push(c)
	}

	buscarCobro(ref) {
		return this.pagos.find((c) => c["refComprobante"] == ref)
	}

	quitarCobro(c: Pago) {
		this.pagos = this.pagos.filter((cc) => cc != c)
	}
	reemplazarCobro(item: Pago, item2: Pago) {
		if (!item && this.pagos.indexOf(item2) < 0) {
			this.pagos.push(item2)
		} else {
			var i = this.pagos.indexOf(item)
			if (i >= 0 && item2) {
				this.pagos[i] = item2
			}
		}
	}
}
