import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from "@angular/core"
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms"
import { debounceTime } from "rxjs"

@Component({
	selector: "selector-email",
	templateUrl: "./selector-email.component.html",
	styleUrls: ["./selector-email.component.less"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectorEmailComponent),
			multi: true
		},
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => SelectorEmailComponent), multi: true }
	]
})
export class SelectorEmailComponent implements OnInit, ControlValueAccessor, Validator {
	public mailForm: FormControl
	public disabled: boolean = false
	@Input()
	public label: string = "DESTINATARIO"
	@Input()
	public placeHolder: string = "myemail@domain.com"
	@Input()
	public tooltip: string = "EMAIL_TOOLTIP"
	constructor() {}

	validate(control: AbstractControl<any, any>): ValidationErrors {
		control.setErrors(this.mailForm.errors)
		return control ? control.errors : null
	}

	private onChangeCallback: (_: any) => void = () => {}
	private onTouchedCallback: (_: any) => void = () => {}
	@Output()
	public onChange: EventEmitter<number> = new EventEmitter()

	writeValue(obj: any): void {
		this.mailForm.setValue(obj)
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled
	}

	ngOnInit(): void {
		this.mailForm = new FormControl(null)

		this.mailForm.valueChanges.pipe(debounceTime(200)).subscribe((r) => {
			this.onChange.emit(r)
			this.onChangeCallback(r)
		})
	}
}
