<model-help [title]="'Gestión de comprobantes (facturas)'">
    <p>En esta pantalla se cargarán las facturas por las prestaciones recibidas de nuestros proveedores de servicios.</p>

    <h3>Encabezado</h3>
    Corresponde a todos los datos de factura, incluyendo fecha de vencimiento de pago de la misma.
    <h3>Items</h3>
    <p>Con el ícono <i class="pi pi-plus"></i> agregaremos los items de cada factura pudiendo diferenciar el iva aplicado a cada uno.</p>
    <p>
        Al seleccionar un tipo de insumo "Nuevo" (ver <help-link [linkFinal]="'insumo'" [textoLink]="'Insumo'"></help-link> ), tendremos la posibilidad de cargar uno nuevo en la pantalla emergente; o
        seleccionar uno previamente cargado.
    </p>
    <p>
        Al seleccionar uno ya previamente cargado nos traerá el precio unitario por defecto según lista de precios vigente del proveedor (Ver
        <help-link [linkFinal]="'proveedor'" [textoLink]="'Proveedor'"></help-link> ), en caso de modificar el precio unitario, nos aparecerá un check que nos permitirá actualizar el precio vigente
        para ese proveedor.
    </p>

    <br />
    <img src="assets/images/help/insumo-actualizar-precio.jpg" /><br />

    <p>Si completamos la sección de pagos, se generará una <help-link [linkFinal]="'orden-pago'" [textoLink]="'Orden de Pago'"></help-link> cancelando la factura que se está creando.</p>
    <img src="assets/images/help/pagos.png" /><br />
    <p>
        Si marcamos el tilde "Notifica al proveedor" nos permitirá indicar un email de destino (por default el mail administrativo o de contacto del proveedor) a donde se enviará la notificación
        <help-link [linkFinal]="'tipo-notificacion'" [textoLink]="'Tipo de Notificación'"></help-link> Pago emitido.
    </p>
    <br />
    <h4>Recordatorios</h4>
    <p>ver <a href="#/help/recordatorio">Recordatorios</a></p>
</model-help>
