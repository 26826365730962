<div class="grid">
	<div class="col p-fluid">
		<span class="p-float-label field">
			<input
				pInputText
				placeholder="{{ placeHolder || 'DESTINATARIO_PH' | translate }}"
				name="destinatario"
				[formControl]="mailForm"
				tooltipPosition="bottom"
				pTooltip="{{ tooltip || 'DESTINATARIO_TP' | translate }}"
				type="email"
				required
				appMultiEmailValidator
				[disabled]="disabled"
			/>
			<label>
				{{ label || "DESTINATARIOS" | translate }}
			</label>
			<error-tag [model]="mailForm"></error-tag>
		</span>
	</div>
</div>
