import { Idioma } from "./Idioma"

export class ProductoIntegracionIdioma {
	constructor(public idioma: Idioma, public actualizado: boolean, public estado: string, public integrado: boolean) { }

	public static fromData(data: any): ProductoIntegracionIdioma {
		if (!data) return null
		const o: ProductoIntegracionIdioma = new ProductoIntegracionIdioma(
			Idioma.fromData(data.idioma),
			data.actualizado,
			data.estado,
			data.integrado
		)

		return o
	}
}
