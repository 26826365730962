import { Insumo } from './Insumo';




export class GastoActividad {
    constructor(
        public id?: number,
        public descripcion?: string,
        public importe?: number,
        public insumo?: Insumo,
        public version?: number,
    ) {
    }
    public static fromData(data: any): GastoActividad {
        if (!data) return null;
        const o: GastoActividad = new GastoActividad(

            data.id,
            data.descripcion,
            data.importe,
            Insumo.fromData(data.insumo)
        );

        o.version = data.version; 
return o;

    }

}