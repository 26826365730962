import { Component} from '@angular/core'

@Component({
    selector: 'app-help-usuario',
    templateUrl: './help-usuario.component.html',
    styleUrls: ['./help-usuario.component.less'],
})
export class HelpUsuarioComponent {
    
}
