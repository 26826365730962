<div class="listado-notificacion content">
	<model-list
		[service]="service"
		[modelName]="'notificacion'"
		[columns]="columns"
		[title]="'Notificacion_PLR' | translate"
		[(listado)]="listado"
		[isLazy]="true"
		[filtro]="filtro"
		[visualizable]="true"
		[borrable]="esAdministrador"
		[multilenguaje]="false"
		[modoSeleccion]="modoSeleccion"
		[filterContent]="filterContent"
		[permiteNuevo]="false"
		[editable]="false"
		(seleccionChange)="updateSeleccion($event)"
		[readonly]="readonly"
		[headerActionsContent]="accionesCabecera"
		[handler]="handler"
	>
		<ng-template #filterContent>
			<div class="grid">
				<div class="col-12">
					<div class="p-inputgroup">
						<span class="p-float-label">
							<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
							<label>{{ "BUSCAR" | translate }}...</label>
						</span>
						<button type="button" pButton icon="pi pi-search"></button>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template #accionesCabecera>
			<button pButton [label]="accion?.label" *ngFor="let accion of acciones" (click)="accion.command(null, $event)"></button>
		</ng-template>
	</model-list>
</div>

<p-dialog header="Detalle" #detalleNotificacion appendTo="body" [modal]="true" [(visible)]="verDetalle" [closable]="true" [style]="{ width: '50vw' }">
	<div class="grid" *ngIf="item">
		<div class="col-12">
			<pre class="jsonContent" [innerHtml]="jsonFormatted" *ngIf="jsonFormatted"></pre>
		</div>
		<div class="col-12">
			<textarea readonly class="jsonContent" [ngModel]="item.logEstado" *ngIf="item?.logEstado" style="width: 100%"> </textarea>
		</div>
		<div class="col-12">
			<span><strong>Originado:</strong> {{ item?.originador }}</span>
		</div>
		<div class="col-12">
			<span><strong>Moficado por:</strong> {{ item?.lastModifiedBy }}</span>
		</div>
		<div class="col-12">
			<span><strong>Fecha Modificación:</strong> {{ item?.lastModifiedDate | date : "dd/MM/yyyy HH:mm" }}</span>
		</div>
	</div>
</p-dialog>
