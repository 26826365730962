import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { AccionesTabla } from "src/app/common/model/AccionesTabla"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Cliente } from "src/app/model/Cliente"
import { FiltroCliente } from "src/app/model/FiltroCliente"
import { ClienteService } from "src/app/services/cliente.service"
import { ComprobanteService } from "src/app/services/comprobante.service"
import { DEFAULT_SKIP, ModelListComponent } from "./../../../common/components/model-list/model-list.component"
import { LoadingService } from "./../../../common/services/loading-data-service.service"
import { Totales } from "./../../../model/Totales"

@Component({
	selector: "listado-cliente",
	templateUrl: "listado-cliente.component.html",
	styleUrls: ["listado-cliente.component.less"]
})
export class ListadoClienteComponent extends SessionComponent implements OnInit, OnDestroy {
	@ViewChild("contactos", { static: true })
	public contactosRef: TemplateRef<any>

	@ViewChild("datosClienteTemplate", { static: true })
	public datosClienteRef: TemplateRef<any>

	@ViewChild(ModelListComponent, { static: true })
	public listadoComponent: ModelListComponent
	emailValido: boolean = false

	public filtro: FiltroCliente
	public listado: Cliente[] = []
	public columns = []
	public totales: Totales
	public skipFields = { ...DEFAULT_SKIP, clienteStr: true, nacionalidad: true, descripcion: true }
	constructor(
		public service: ClienteService,
		private router: Router,
		messagesService: MessagesService,
		public comprobanteService: ComprobanteService,
		private confirmationService: ConfirmationService
	) {
		super(messagesService)
	}
	public getData = (f: FiltroCliente, l: LoadingService) => {
		return this.service.getCabeceras(f, l).then((r) => f.apply(r))
	}

	public getCount = (f: FiltroCliente, l: LoadingService) => {
		return this.service.getTotales(f, l).then((t: Totales) => {
			this.totales = t
			return Promise.resolve(t.count)
		})
	}
	public acciones: AccionesTabla[] = []
	ngOnInit() {
		this.filtro = new FiltroCliente("cliente_filtros_list", 0, 10)
		setTimeout(() => {
			this.columns = [
				{
					field: "id",
					header: "#",
					class: "id-column",
					role: "text-tabla",
					sortable: true
				},
				{
					field: "nombre",
					header: "Nombre",
					role: "template",
					template: this.datosClienteRef,
					sortable: true
				},
				{
					field: "numeroDocumento",
					header: "Numero Documento",
					role: "text-tabla",
					sortable: false
				},
				{
					field: "email",
					header: "Contactos",
					role: "template",
					esMobile: true,
					template: this.contactosRef
				},
				{
					field: "comentario",
					header: "Comentario",
					role: "text-tabla",
					sortable: false,
					width: "15em"
				},
				{
					field: "importe",
					header: "Facturado",
					role: "money-tabla",
					class: "money-tabla",
					sortable: true
				},
				{
					field: "saldo",
					header: "Saldo",
					role: "money-tabla",
					class: "money-tabla",
					esMobile: true,
					sortable: true
				}
			]
		}, 200)
		this.acciones = this.listadoComponent.defaultAcciones
		this.acciones.push(
			new AccionesTabla(
				this.translateService.get("HACER_PEDIDO"),
				"fa fa-shopping-cart",
				"",
				(da, event) => {
					this.hacerPedido(da.id)
					if (event) event.stopPropagation()
				},
				(data) => {
					return true
				}
			),
			new AccionesTabla(
				this.translateService.get("GENERAR_COBRO"),
				"fa fa-money",
				"",
				(da, event) => {
					this.generarCobro(da.id)
					if (event) event.stopPropagation()
				},
				(data) => {
					return true
				}
			),
			new AccionesTabla(
				this.translateService.get("NOTIFICAR_DEUDA"),
				"fa fa-envelope",
				"",
				(da, event) => {
					this.notificarDeuda(da)
					if (event) event.stopPropagation()
				},
				(data) => {
					return true
				}
			),
			new AccionesTabla(
				this.translateService.get("HABILITAR"),
				"fa fa-check",
				"",
				(da, event) => {
					this.service.habilitar(da).then(async (r) => {
						this.success(await this.translateService.get("OPERACION_OK"))
						this.filtro.forceUpdate()
					})

					if (event) event.stopPropagation()
				},
				(data) => {
					return data && ((data?.every && data.every((item) => !item.activo === false)) || data.activo === false)
				}
			)
		)
	}
	ngOnDestroy() {}
	public hacerPedido(id) {
		this.router.navigate(["reserva/nuevo"], { queryParams: { clienteId: id } })
	}
	public generarCobro(id) {
		this.router.navigate(["cobranza/nuevo"], { queryParams: { clienteId: id } })
	}

	public destinatario: string
	public verEnvioDeuda: boolean = false
	public notificarDeudaCliente: Cliente

	public notificarDeuda(item: Cliente) {
		this.comprobanteService.hayNotasSinCompensar(item.id).then((hayNotas) => {
			if (hayNotas) {
				this.confirmationService.confirm({
					key: "genConf",
					header: this.translateService.get("NOTAS_SIN_COMPENSAR"),
					message: this.translateService.get("MENSAJE_NOTAS_SIN_COMPENSAR"),
					accept: () => {
						this.destinatario = item.emailAdministrativo || item.email
						this.notificarDeudaCliente = item
						this.verEnvioDeuda = true
					}
				})
			} else {
				this.destinatario = item.emailAdministrativo || item.email
				this.notificarDeudaCliente = item
				this.verEnvioDeuda = true
			}
		})
	}

	public confirmarEnvioDeuda() {
		this.service
			.notificarDeuda({
				destinatario: this.destinatario,
				idEntidad: this.notificarDeudaCliente.id
			})
			.then((s) => {
				this.success("Se envió la notificación al cliente")
				this.verEnvioDeuda = false
				this.notificarDeudaCliente = null
			})
	}
}
