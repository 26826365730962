import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { FormControl } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import * as moment from "moment"
import { ConfirmationService } from "primeng/api"
import { Subscription } from "rxjs"
import { debounceTime, filter } from "rxjs/operators"
import { MonedaEntorno } from "src/app/app.module"
import { FacturasAG } from "src/app/authguards/FacturasAG"
import { ProveedorExternoAG } from "src/app/authguards/PermisosProveedorExterno"
import { ProveedoresAG } from "src/app/authguards/ProveedoresAG"
import { FiltroFactura } from "src/app/common/model/FiltroFactura"
import { ProfilePic } from "src/app/common/model/ProfilePic"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { ExcelDownloader } from "src/app/common/utils/excelDownloader"
import { Comision } from "src/app/model/Comision"
import { EstadoPuesto } from "src/app/model/EstadoPuesto"
import { FiltroCliente } from "src/app/model/FiltroCliente"
import { GastoPuesto } from "src/app/model/GastoPuesto"
import { Hotel } from "src/app/model/Hotel"
import { Insumo } from "src/app/model/Insumo"
import { Moneda } from "src/app/model/Moneda"
import { Proveedor } from "src/app/model/Proveedor"
import { TipoProveedor } from "src/app/model/TipoProveedor"
import { TipoPuesto } from "src/app/model/TipoPuesto"
import { ClienteService } from "src/app/services/cliente.service"
import { FormaPagoService } from "src/app/services/forma-pago.service"
import { GastoPuestoService } from "src/app/services/gasto-puesto.service"
import { HotelService } from "src/app/services/hotel.service"
import { InsumoService } from "src/app/services/insumo.service"
import { MonedaService } from "src/app/services/moneda.service"
import { ProveedorService } from "src/app/services/proveedor.service"
import { TipoIVAGrabadoService } from "src/app/services/tipo-iva-grabado.service"
import { PreciosProveedorComponent } from "../precios-proveedor/precios-proveedor.component"
import { AdministracionAuthGuard } from "./../../../authguards/AdministracionAuthGuard"
import { MisFacturasProveedorAG } from "./../../../authguards/PermisosProveedorExterno"
import { SessionComponent } from "./../../../common/components/session-component.component"
import { Descriptivo } from "./../../../common/model/Descriptivo"
import { IdiomaService } from "./../../../common/services/idioma.service"
import { CuentaCorriente } from "./../../../model/CuentaCorriente"
import { GrupoPax } from "./../../../model/GrupoPax"
import { GrupoReserva } from "./../../../model/GrupoReserva"
import { Idioma } from "./../../../model/Idioma"
import { ItemReserva } from "./../../../model/ItemReserva"
import { PrecioInsumo } from "./../../../model/PrecioInsumo"
import { Totales } from "./../../../model/Totales"
import { ComisionService } from "./../../../services/comision.service"
import { GrupoPaxService } from "./../../../services/grupo-pax.service"
import { ItemReservaService } from "./../../../services/item-reserva.service"
import { PuestoServicioReservaService } from "./../../../services/puesto-servicio-reserva.service"
import { TipoPuestoService } from "./../../../services/tipo-puesto.service"
import { FiltroPuesto } from "./../FiltroPuesto"

@Component({
	selector: "gestor-proveedor",
	templateUrl: "gestor-proveedor.component.html",
	styleUrls: ["gestor-proveedor.component.less"]
})
export class GestorProveedorComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	@Input()
	public item: Proveedor = new Proveedor()
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false
	public motivoDesasignacion: string
	public verDesasignacion: boolean = false
	public gastoEditado: GastoPuesto
	public errors: string[] = []
	public totalServicios = {
		monto: 0,
		cantidad: 0
	}
	@Output()
	public onGuardado: EventEmitter<Proveedor> = new EventEmitter<Proveedor>()

	@Output()
	public onCancelado = new EventEmitter()

	public puestosServicio: GastoPuesto[] = []
	public rowMetadata: any = {}
	public puestosElegidos: GastoPuesto[] = []
	private _puestosElegidos: GastoPuesto[] = []
	public activeIndex: number = 0
	public tiposPuesto: TipoPuesto[] = []
	public pantallaActiva: string = "data"
	public nuevoInsumoVisible: boolean = false
	public mostrarGestorInsumo: boolean = false
	public gestorPreciosFechas: boolean = false
	public disponibles: PrecioInsumo[] = []
	public ofrecidos: PrecioInsumo[] = []
	public insumoEditado: PrecioInsumo
	public insumo: Insumo
	public insumoOriginal: PrecioInsumo
	public file: File
	public preciosPorFecha: PrecioInsumo[] = []
	public preciosFiltrados: PrecioInsumo[] = []
	buscandoHotel: boolean = true
	titulo: string = "Proveedor"
	modelName: string
	@Input() vendedor: boolean = false
	comisionesSeleccionadas: Comision[] = []
	comisiones: Comision[] = []
	idiomas: Idioma[] = []
	showCalendar = false
	filtroCuentaCliente: FiltroCliente
	totalesCliente: Totales
	esHotel: boolean = false
	public verComprobante(event, comprobante) {
		event.preventDefault()
		event.stopPropagation()
		this.router.navigate(["factura/vista"], { queryParams: { id: comprobante } })
	}
	public cancelar() {
		this.gestorPreciosFechas = false
		this.insumoEditado = null
	}
	public facturar() {
		const moneda = this.puestosElegidos[0].moneda
		if (this.puestosElegidos.some((p) => p.moneda?.codigo != moneda?.codigo)) return this.error("No se puede facturar gastos con distintas monedas")
		this.router.navigate(["factura/nuevo"], { state: { proveedor: this.item, gastos: this.puestosElegidos.filter((p) => !p.comprobante) } })
	}

	public facturarComision() {
		let c = this.comisionesSeleccionadas.filter((p) => !p.comprobante)
		this.insumosService.getByCodigo(Insumo.COMISION).then((i) => {
			let comisiones = c.map((com) => {
				return {
					id: com.id,
					idInsumo: i.id,
					descProducto: com.producto.descripcion,
					fecha: com.fecha,
					producto: Descriptivo.fromData(com.producto),
					importe: com.importe,
					reserva: com.reserva?.id
				}
			})
			this.router.navigate(["factura/nuevo"], { state: { proveedor: this.item, comisiones: comisiones } })
		})
	}
	public verReserva(reserva: string) {
		this.router.navigate(["reserva/vista"], { queryParams: { id: reserva } })
	}
	public onPicSelected(file: File) {
		const reader = new FileReader()
		let $this = this
		if (file.size > 1000000) {
			return this.error(this.translateService.get("EL_ARCHIVO_NO_PUEDE_16"))
		}
		this.file = file
		reader.onload = (e: any) => {
			$this.item.profilePic = new ProfilePic(null, e.target.result)
		}
		reader.readAsDataURL(file)
	}

	private _preciosProveedor: PreciosProveedorComponent
	public get preciosProveedor(): PreciosProveedorComponent {
		return this._preciosProveedor
	}
	@ViewChild(PreciosProveedorComponent)
	public set preciosProveedor(v: PreciosProveedorComponent) {
		this._preciosProveedor = v
	}

	// public superpone(nuevo: PrecioInsumo, index: number) {
	// 	return this.insumoEditado.some((p, i) => {
	// 		return (
	// 			index != i &&
	// 			(!nuevo.fechaHasta || p.fechaDesde <= nuevo.fechaHasta) &&
	// 			(!p.fechaHasta || p.fechaHasta >= nuevo.fechaDesde) &&
	// 			superponeMaxMin(nuevo, p)
	// 		)
	// 	})
	// }
	public moneda: Moneda = MonedaEntorno()
	public buscarControl: FormControl = new FormControl()
	// private _fechaPrecios: Date = new Date()
	// public get fechaPrecios(): Date {
	// 	return this._fechaPrecios
	// }
	// public set fechaPrecios(v: Date) {
	// 	this._fechaPrecios = v
	// 	this.preciosPorFecha = this.item.getPreciosPorFecha(v)
	// 	this.buscarControl.setValue(this.buscarControl.value)
	// }
	public filtroCuenta: FiltroFactura
	public cuentaCorriente: CuentaCorriente[] = []
	public loadingTotales: LoadingService = new LoadingService()
	public totales: Totales
	public filtro: FiltroPuesto
	public mostrarDetalleActividad: boolean = false
	public mostrarGrupo: boolean = false
	public itemReserva: ItemReserva
	public grupo: GrupoPax
	public allEstados: EstadoPuesto[] = []
	@Input()
	public readonly: boolean = false
	public hotelId: number
	constructor(
		messagesService: MessagesService,
		public service: ProveedorService,
		private route: ActivatedRoute,
		public administracionAG: AdministracionAuthGuard,
		private router: Router,
		private confService: ConfirmationService,
		public tiposPuestoService: TipoPuestoService,
		private puestosService: PuestoServicioReservaService,
		private insumosService: InsumoService,
		private grupoService: GrupoPaxService,
		private comisionService: ComisionService,
		private itemReservaService: ItemReservaService,
		private gastoService: GastoPuestoService,
		public idiomaService: IdiomaService,
		public proveedorAG: ProveedoresAG,
		public monedaService: MonedaService,
		public tipoIVAService: TipoIVAGrabadoService,
		public clienteService: ClienteService,
		public excelDownloader: ExcelDownloader,
		public hotelService: HotelService,
		public formaPagoService: FormaPagoService,
		public proveedorExternoAG: ProveedorExternoAG,
		public facturaAG: FacturasAG,
		public MisFacturasProveedorAG: MisFacturasProveedorAG
	) {
		super(messagesService)
		/*this.monedaService.getDefault().then((m) => {
			this.moneda = m;
		});*/
	}
	public verGrupo(idGrupo) {
		this.grupoService.getById(idGrupo).then((g) => {
			this.grupo = g
			this.mostrarGrupo = true
		})
	}
	public borrarGasto(item: GastoPuesto) {
		if (item.comprobante) {
			return this.error("No se puede borrar un gasto ya facturado")
		}
		this.confService.confirm({
			key: "genConf",
			header: "Eliminar Gasto",
			message: "Va a eliminar un gasto. Desea continuar?",
			accept: () => {
				this.service.eliminarGasto(item.id).then((r) => {
					this.success("Gasto eliminado")
					this.filtro.forceUpdate()
				})
			}
		})
	}
	async verActividad(item: GrupoReserva) {
		this.itemReserva = await this.itemReservaService.getById(item?.itemReserva.id)
		this.mostrarDetalleActividad = true
	}
	public get esAdministracion() {
		return this.usuario && (this.facturaAG.esVisible(this.usuario) || this.MisFacturasProveedorAG.esVisible(this.usuario))
	}

	public get puedeBorrarGasto() {
		return this.esAdministrador || this.usuario.tieneRol(["ROLE_BORRAR_GASTO"])
	}
	public changeTab = (event) => {
		let index = this.esHotel || this.esTipoHotel() ? event.index - 1 : event.index

		if (index == 1 && !this.vendedor && !this.esTipoHotel()) {
			this.pantallaActiva = "precios"
		} else if ((!this.vendedor && index == 2) || (this.vendedor && index == 1)) {
			this.verCuentaCorriente()
		} else if (this.esExterno ? index == 3 : index == 4) {
			this.showCalendar = true
		} else {
			this.goToDatos()
		}
	}
	// public nuevoPrecio() {
	// 	this.insumoEditado.precios = this.insumoEditado.precios.sort((a, b) => {
	// 		return a.fechaDesde > b.fechaDesde ? -1 : 1
	// 	})
	// 	let precioMayor = this.insumoEditado.precios[0]
	// 	if (!precioMayor) {
	// 		this.insumoEditado.precios.push(new PrecioInsumo(null, new Date()))
	// 	} else {
	// 		if (!precioMayor.fechaHasta) {
	// 			let now = moment().endOf("day").toDate()
	// 			precioMayor.fechaHasta = precioMayor.fechaDesde > now ? precioMayor.fechaDesde : now
	// 		}

	// 		this.insumoEditado.precios.push(new PrecioInsumo(null, moment(precioMayor.fechaHasta).add(1, "days").toDate(), null, precioMayor.precio))
	// 		this.insumoEditado.precios = this.insumoEditado.precios.sort((a, b) => {
	// 			return a.fechaDesde > b.fechaDesde ? -1 : 1
	// 		})
	// 	}
	// }
	public get puedeEditar() {
		return this.proveedorAG.esVisible(this.usuario) || this.proveedorExternoAG.esVisible(this.usuario)
	}
	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}
	public cargarFactura() {
		this.router.navigate(["factura/nuevo"], { queryParams: { proveedorId: this.item.id } })
	}
	public cargarOP() {
		this.router.navigate(["orden-pago/nuevo"], { queryParams: { proveedorId: this.item.id } })
	}
	public verCuentaCorriente = () => {
		this.pantallaActiva = "ctacte"
	}
	public isValid = () => {
		return !this.preciosProveedor || this.preciosProveedor?.isValid()
	}
	public onTipoRemove(tp: TipoPuesto) {
		this.item.quitarPuesto(tp)
	}

	// public nuevoInsumo() {
	// 	this.insumo = new Insumo()
	// 	this.mostrarGestorInsumo = true
	// }
	// public guardarNuevoInsumo(insumo: Insumo) {
	// 	this.disponibles = [...this.disponibles, new PrecioInsumo(null, insumo)]
	// 	this.mostrarGestorInsumo = false
	// }
	// public cancelarNuevoInsumo() {
	// 	this.mostrarGestorInsumo = false
	// }
	public goToDatos = () => {
		this.pantallaActiva = "data"
	}
	// public goToInsumos = () => {
	// 	this.insumosService.getAll(new Filtro(null, {}, 0, 1000)).then((r) => {
	// 		this.disponibles = r.filter((i) => !this.item.tieneInsumo(i)).map((i) => new PrecioInsumo(null, i))
	// 		this.pantallaActiva = "insumos"
	// 	})
	// }
	public getKey(i: number, item: PrecioInsumo) {
		return item.insumo.id
	}
	public get finalTitle() {
		if (!this.vendedor) {
			return this.item.id ? "Editando Proveedor " + this.item.descripcion : "Nuevo Proveedor"
		} else {
			return this.item.id ? "Editando Vendedor " + this.item.descripcion : "Nuevo Vendedor"
		}
	}
	public get puedeFacturar() {
		return this.puestosElegidos.filter((p) => !p.comprobante).length > 0
	}

	public get puedeFacturarComision() {
		return this.comisionesSeleccionadas.filter((p) => !p.comprobante).length > 0
	}
	// private _filter(d: string) {
	// 	this.preciosFiltrados = this.preciosPorFecha.filter((p) => p.descripcion && p.descripcion.toUpperCase().includes(d))
	// }
	public editar(event) {
		this.readonly = false
		event.stopPropagation()
	}
	ngOnInit() {
		this.filtro = new FiltroPuesto("filtroPuesto", 0, 1000, moment().subtract(1, "months").startOf("month").toDate())

		// this.subs.push(
		// 	this.buscarControl.valueChanges
		// 		.pipe(
		// 			map((v) => {
		// 				if (typeof v === "string") {
		// 					return v.toUpperCase()
		// 				} else {
		// 					return ""
		// 				}
		// 			})
		// 		)
		// 		.subscribe((d: string) => {
		// 			this._filter(d)
		// 		})
		// )

		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
				}
				if (u.vendedor) {
					this.vendedor = true
					this.titulo = "Vendedor"
					this.modelName = "vendedor"
					if (!this.item.tipoProveedor) this.item.tipoProveedor = new Descriptivo("VE", null, null)
				} else {
					this.modelName = "Proveedor"
				}
			})
		)

		this.routeSub = this.route.queryParams.subscribe((params) => {
			let id: number = <number>params["id"]
			this.esHotel = <boolean>params["hotel"]
			this.hotelId = <number>params["hotelId"]
			this.buscandoHotel = true
			if (!this.service) return
			if (this.esExterno) {
				this.service.name = "proveedorExterno"
			}
			if (id && (this.route.routeConfig.path.includes("proveedor") || this.route.routeConfig.path.includes("vendedor"))) {
				this.service.getById(id).then((r) => {
					this.item = r
					if (this.item?.cliente) {
						this.filtroCuentaCliente = new FiltroCliente(this.item.cliente.codigo + "_cuenta_cliente", 0, 20, "fecha", 2, false, {
							cliente: this.item.cliente,
							fechaDesde: null
						})
						this.clienteService.getTotales(this.filtroCuentaCliente, this.loadingTotales).then((t) => (this.totalesCliente = t))
						this.filtroCuentaCliente.onClean((f) => {
							f.cliente = this.item.cliente
							f.fechaDesde = null
						})
					}

					// this.fechaPrecios = new Date()
					this.filtro.personal = Descriptivo.fromData(r)
					this.filtroCuenta = new FiltroFactura("cuenta_prov_" + this.item.id, null, 0, 20, "fecha", 1, {
						proveedor: Descriptivo.fromData(this.item)
					})
					this.filtroCuenta.sortOrder = 2

					this.filtroCuenta.clean = () => {
						this.filtroCuenta.patchValue({
							searchStr: "",
							fechaDesde: null,
							fechaHasta: null,
							proveedor: Descriptivo.fromData(this.item)
						})
					}

					if (r.tipoProveedor?.codigo == "VE") {
						this.filtroCuenta.tipoProveedor = new Descriptivo(r.tipoProveedor.codigo, null, r.tipoProveedor.id)
					}

					this.service.getTotales(this.filtroCuenta).then((t) => {
						this.totales = t
					})
					if (this.hotelId) {
						this.hotelService
							.getById(this.hotelId)
							.then((h) => {
								this.item.hotelRelacionado = h === undefined ? null : h
								this.buscandoHotel = false
								if (!h) {
									this.pantallaActiva = "data"
									this.activeIndex = 0
								} else {
									this.pantallaActiva = this.esHotel ? "hotel" : "data"
									this.activeIndex = this.esHotel ? 0 : 1
								}
							})
							.catch((e) => {
								this.buscandoHotel = false
							})
					} else {
						this.hotelService
							.getByProveedor(this.item.id)
							.then((h) => {
								this.item.hotelRelacionado = h === undefined ? null : h
								this.buscandoHotel = false
								if (!h) {
									this.pantallaActiva = "data"
									this.activeIndex = 0
								} else {
									this.pantallaActiva = this.esHotel ? "hotel" : "data"
									this.activeIndex = this.esHotel ? 0 : 1
								}
							})
							.catch((e) => {
								this.buscandoHotel = false
							})
					}
					this.procesarHotel()
				})
			} else {
				this.item = this.service ? this.service.newEnt() : null
				this.buscandoHotel = false
				if (this.vendedor) {
					this.item.tipoProveedor = new Descriptivo("VE", null, null)
				}
				this.procesarHotel()
			}
			// this.fechaPrecios = new Date()
		})
		this.subs.push(
			this.filtro.dataChange
				.pipe(
					debounceTime(400),
					filter((f) => f != undefined)
				)
				.subscribe((f: FiltroPuesto) => {
					if (f.personal == null) {
						this.filtro.personal = Descriptivo.fromData(this.item)
					} else if (!this.vendedor && f.personal?.id) {
						this.puestosService.getByPersonal(f).then((r) => {
							this.puestosServicio = r
							this.totalServicios = { monto: 0, cantidad: 0 }
							r.forEach((g, i) => {
								if (this.rowMetadata[g.groupKey]) {
									this.rowMetadata[g.groupKey].size++
								} else {
									this.rowMetadata[g.groupKey] = { index: i, size: 1 }
								}
								this.totalServicios.cantidad += g.cantidad
								this.totalServicios.monto += g.total
							})
							// this.puestosElegidos = []
						})
					} else if (this.vendedor) {
						this.comisionService.getByVendedor(f).then((c) => {
							this.comisiones = c
						})
					}
				})
		)
		this.filtro.onClean((f: FiltroPuesto) => {
			this.puestosElegidos = []
		})
		this.tiposPuestoService.getHabilitados().then((r) => {
			this.tiposPuesto = r
		})

		this.idiomaService.getAll().then((i) => {
			this.idiomas = i.filter((idioma) => idioma.codigo != "EN-ES")
		})
		this.allEstados = [
			EstadoPuesto.CONFIRMADO,
			EstadoPuesto.PENDIENTE_CONFIRMACION,
			EstadoPuesto.RECHAZADO,
			EstadoPuesto.PENDIENTE_ENVIO,
			EstadoPuesto.NO_VIGENTE
		]
	}
	esTipoHotel = () => {
		return this.item?.id && this.item?.tipoProveedor?.codigo == TipoProveedor.HOTEL && this.item?.hotelRelacionado?.id
	}
	procesarHotel() {
		if (this.esHotel && !this.hotelId) {
			this.item.hotelRelacionado = new Hotel()
			this.item.hotelRelacionado.checkIn = moment().set("hour", 14).set("minute", 0).toDate()
			this.item.hotelRelacionado.checkOut = moment().set("hour", 10).set("minute", 0).toDate()
		}
		if (!this.item.tipoProveedor) {
			this.item.tipoProveedor = new Descriptivo(TipoProveedor.HOTEL, "Hotel")
		}
	}
	public agregarInsumos() {
		this.nuevoInsumoVisible = true
	}

	async exportExcel() {
		this.excelDownloader.downloadData(this.puestosServicio, this.modelName || "datosExportados")
	}
	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}
	enviarAsignaciones(event) {
		if (!this.administracionAG.esVisible(this.usuario)) return
		this.service.enviarResumen(this.item.id).then((r) => this.messagesService.success("RESUMEN_ENVIADO"))
		if (event) event.stopPropagation()
	}
	get calendarioHeader() {
		return this.item.noHabitual ? this.translateService.get("DISPONIBILIDAD") : this.translateService.get("NO_DISPONIBILIDAD")
	}

	public desasignarGasto(gasto: GastoPuesto) {
		this.gastoEditado = null
		if (!gasto) return
		this.gastoEditado = gasto
		this.verDesasignacion = true
	}

	public confirmarDesasignacion() {
		if (this.gastoEditado == null) {
			this.error("No se encontró gasto")
			this.verDesasignacion = false
			return
		}
		if (this.gastoEditado.motivoDesasignacion?.length < 6) {
			return this.error("Debe indicar un motivo")
		}
		this.gastoService.desasignarGasto(this.gastoEditado).then((e) => {
			this.filtro.forceUpdate()
			this.success("Gasto desasignado")
			this.verDesasignacion = false
		})
	}

	nombreHotelChange(nombre) {
		if (!this.item?.id) this.item.nombre = nombre
	}
}

function superponeMaxMin(p1, p2) {
	let min1 = p1.minPax
	let min2 = p2
	let max1 = p1.maxPax
	let max2 = p2

	if (min1 === null) min1 = 0
	if (max1 === null) max1 = Infinity
	if (min2 === null) min2 = 0
	if (max2 === null) max2 = Infinity

	return !(max1 < min2 || min1 > max2)
}
