import { Auditable } from './Auditable';
import { EstadoDocumento } from './EstadoDocumento';

import { FacturaResumen } from './FacturaResumen';

export class ItemOrdenPago extends Auditable {
    constructor(
        public id ?: number,
		public factura ?: FacturaResumen,
		public importe ?: number,
        public idOrdenPago?:number,
        public descripcionOrdenPago?: string,
        public fechaOrdenPago?:Date,
        public estadoOrdenPago?: EstadoDocumento,
        public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
        public version?: number,
	) {
		super(lastModifiedBy, lastModifiedDate)
    }
    public static fromData(data: any): ItemOrdenPago {
        if (!data) return null;
        const o: ItemOrdenPago = new ItemOrdenPago(
            data.id, 
            FacturaResumen.fromData(data.factura), 
            data.importe, 
            data.idOrdenPago,
            data.descripcionOrdenPago,
            data.fechaOrdenPago,
            EstadoDocumento.fromData(data.estadoOrdenPago),
            data.lastModifiedBy,
            data.lastModifiedDate,
        );

        o.version = data.version; 
return o;

    }

}