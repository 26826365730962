<label *ngIf="nombre" class="control-label {{labelClass}}">{{nombre}}</label>
<ng-content select="descriptivo-label"></ng-content>
<div class="ds_default {{inputClass}}" *ngIf=!sinDatos>

    <p-autoComplete #autocomplete [disabled]="!editable" [size]="50" [suggestions]="recomendaciones" field="descripcion"
        (completeMethod)="filtrar($event)" [(ngModel)]="seleccionado" [dropdown]="true" [dataKey]="'codigo'"
        [ngClass]="{'ds_dropdown-limpiable': seleccionado && limpiable}" placeholder="{{textoSeleccionar}}"
        (focusout)="autoselectOpcion()" (onFocus)="onFocus($event)" [appendTo]="appendTo" [tooltipPosition]="'bottom'" [pTooltip]="tooltip">
        <ng-template let-item pTemplate="item">
            <div class="p-helper-clearfix" style="border-bottom:1px solid #D5D5D5">
                <div><i [class]="item.icon" style="margin-right: 5px;" *ngIf="item?.icon"></i>{{item.descripcion}}</div>
            </div>
        </ng-template>
    </p-autoComplete>
    <button *ngIf="seleccionado && limpiable" type="button" class="btn btn-xs ds_limpiar-dropdown" (click)="clear()">
        <strong><i title="Borrar Filtro" class="fa fa-close"></i></strong></button>

</div>
<div class="{{inputClass}}" *ngIf=sinDatos>
    <label class="control-label">No se encontraron {{nombre}}</label>
</div>