<model-help [title]="'Gestión de proveedores'">
    <h3>Listado de Ordenes de Pago</h3>
    <img src="assets/images/help/orden-pago-listado.jpg" style="max-height: 195px" /><br />

    <strong>En la cabecera de la página de listado</strong>, se encuentra el botón para agregar una nueva orden de pago<i class="pi pi-plus"></i> y a continuación la barra de búsqueda (permite buscar
    por nombre del proveedor, fecha de creación, número de orden de pago, descripcion y nombre del responsable) y filtro <i class="pi pi-filter"></i>. <br />
    En la línea siguiente se encuentra un resumen de los filtros aplicados y el ícono para borrar estos filtros <i class="pi pi-trash"></i> y obtener el listado completo.<br /><br />

    <h4>Columnas del listado</h4>
    <ul>
        <li>
            <strong>Estado:</strong> se define por colores. <br />
            Los estados por defecto en sistema son:<br /><br />

            <ul>
                <li>
                    <i class="pi pi-circle-fill" style="color: #5f53ff"></i><strong>Aprobado:</strong> Estado por defecto al crear una nueva Orden de pago y tomando el pago correspondiente al total de
                    la misma.
                </li>
                <li>
                    <i class="pi pi-circle-fill" style="color: #fcae46"></i><strong>Pendiente de pago:</strong> Estado por defecto al crear una nueva Orden de pago cuando queda saldo pendiente de pago
                    del total de la misma.
                </li>
                <li><i class="pi pi-circle-fill" style="color: #c04f4f"></i><strong>Cancelada</strong></li>
            </ul>
            <br />
        </li>

        <li><strong>#</strong> Número único identificador de la orden de pago.</li>
        <li><strong>Fecha:</strong> de la creación de la orden.</li>
        <li><strong>Descripción.</strong></li>
        <li><strong>Responsable:</strong> Quien la creo.</li>
        <li>
            <strong>Importe: </strong> Es importante destacar que el importe mostrado aquí es el total de la orden de pago, sin importar si abarca total o parcialmente facturas, si incluye o no pagos
            o si son pagos adelantados.
        </li>
        <li>
            <strong>Acciones:</strong> En el caso de existir 4 o más acciones disponibles aparecerá el ícono <i class="fa fa-cog"></i> ubicado en la última columna en cada línea que desplegará las
            acciones correspondientes para ser seleccionadas, y en el caso de haber menos acciones disponibles aparecerán los iconos ubicados uno junto al otro.
        </li>
        <ul>
            <li><i class="fa fa-trash"></i> Cancelar Orden de pago (se requerirá seleccionar un motivo.</li>
            <li><i class="fa fa-eye"></i> Visualizar el detalle de la Orden. (no permite edición si ya se han realizado pagos.)</li>
            <li><i class="fa fa-envelope"></i> Enviar la Orden de pago al proveedor.</li>
            <li><i class="fa fa-search"></i> Auditar</li>
            <li>
                <i class="fa fa-pencil" style="color: black; padding: 0; background-color: transparent"></i> Editar: Esta opción estará disponible únicamente si la orden de pago está pendiente de
                pago.
            </li>
        </ul>
        <li>
            <strong>Icono de descarga </strong><i class="pi pi-file-excel"></i>: Descarga el listado de órdenes de pago en formato csv compatible con excel, o diferentes programas de hojas de cálculo)
        </li>
        <br />
    </ul>
    <h4>Filtros en listado</h4>

    <div class="grid">
        <div class="col-12 lg:col-6">
            <p>
                Esta ventana emergente de filtros se puede encontrar en diferentes listados a lo largo de todo el sistema y varía según el entorno.<br />
                En este caso en particular se dispone de las siguientes opciones para encontrar la orden de pago requerida.
            </p>
            <ul>
                <li><help-link [linkFinal]="'proveedor'" [textoLink]="'Proveedor'"></help-link></li>
                <li><help-link [linkFinal]="'usuario'" [textoLink]="'Usuario'"> responsable.</help-link></li>

                <li>Rango de fechas de creación.</li>

                <li>
                    Estados:
                    <ul>
                        <li>
                            <i class="pi pi-circle-fill" style="color: #5f53ff"></i><strong>Aprobado:</strong> Estado por defecto al crear una nueva Orden de pago y tomando el pago correspondiente al
                            total de la misma.
                        </li>
                        <li>
                            <i class="pi pi-circle-fill" style="color: #fcae46"></i><strong>Pendiente de pago:</strong> Estado por defecto al crear una nueva Orden de pago cuando queda saldo pendiente
                            de pago del total de la misma.
                        </li>
                        <li><i class="pi pi-circle-fill" style="color: #c04f4f"></i><strong>Cancelada</strong></li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="col-12 lg:col-6"><img src="assets/images/help/filtro-orden.jpg" style="margin-left: 10%" /><br /></div>
    </div>

    <h3>Gestor de Ordenes de Pago</h3>

    <h4>Campos Particulares</h4>
    <p>
        <strong><help-link [linkFinal]="'proveedor'" [textoLink]="'Proveedor'"></help-link>:</strong> Al seleccionar un proveedor se nos desplegará en la sección de "Facturas a pagar", el listado de
        facturas pendientes de pago tanto total como parcialmente y también los pagos a cuenta o adelantos realizados si los hubiese.
       <br><br>
        <strong>Pago a cuenta: </strong>Este campo se utilizará únicamente si queremos realizar un adelanto al proveedor.<br /><br>
        <strong>Importe a imputar: </strong> Este campo se utilizará para imputar un importe en las facturas ordenadas por fecha (de más antigua a más reciente) (ejemplo: queremos realizar un pago de
        un monto X pero no necesariamente corresponde a una factura en particular.)<br /><br>
        <strong>Importe (en línea con las facturas pendientes): </strong>Estos campos son modificables para el caso que se desee hacer pagos parciales de una o más facturas.<br /><br>
        <strong>Pagos: </strong>En este campo se podra elegir el medio y la caja por el cual se recibirán uno o más pagos.<br /><br>
        <strong>Notificación: </strong>si se desea notificar al proveedor se debe seleccionar el check para enviar por mail la orden de pago a la casilla por defecto de dicho proveedor; en caso de
        querer enviarla a otro mail se podrá modificar.<br /><br>
    </p>

    <h4>Consideraciones importantes</h4>

    <p>
        En caso de existir pagos adelantados para el proveedor seleccionado, aparecerán con importes con signo negativo (-) en el mismo listado que las facturas pendientes, de esta forma se podrá pagar una factura
        con monto X pendiente con un adelanto de monto Y y una diferencia si hiciese falta o quedará monto a favor según sea el caso.
    </p>
    <p>Se podrán realizar órdenes de pago pendientes de pago u órdenes de pago con pago total o parcial de una o más facturas, realizar pagos adelantados y a cuenta; todo desde esta pantalla.</p>
</model-help>
