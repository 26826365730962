import { Component, Input, OnInit } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { Insumo } from "src/app/model/Insumo"
import { InsumoService } from "src/app/services/insumo.service"
import { ProveedorService } from "src/app/services/proveedor.service"
import { ErrorHandler } from "./../../../common/utils/ErrorsHandler"
import { AsociacionInsumo } from "./../../../model/AsociacionInsumo"
import { InsumoContainer } from "./../../../model/InsumoContainer"

@Component({
	selector: "insumo",
	templateUrl: "./insumo.component.html",
	styleUrls: ["./insumo.component.less"]
})
export class InsumoComponent implements OnInit {
	@Input()
	insumos: Insumo[] = []

	@Input()
	proporcion: boolean = true
	@Input()
	index: number = 0
	@Input()
	tipo: string = ""
	@Input()
	insumo: AsociacionInsumo
	@Input()
	cantidadEditable: boolean = true
	@Input()
	contenedor: InsumoContainer
	@Input()
	titulo: string
	@Input()
	esMasterData: boolean = true
	@Input()
	esPrimero: boolean = true

	@Input()
	readonly: boolean = false

	constructor(private insumoService: InsumoService, private proveedorService: ProveedorService, private errorHandler: ErrorHandler) {}

	ngOnInit() {
		if (this.insumos.length == 0) {
			this.insumoService.getHabilitados().then((i) => {
				this.insumos = i
			})
		}
	}

	getErrorMessage(control: AbstractControl) {
		return this.errorHandler.getErrorMessage(control)
	}
	public onFocus(event: any) {
		event.srcElement.select()
	}
}
