<div class="pantalla-ayuda grid">
    <div class="col-12 lg:col-10">
        <h2>{{'AYUDA' | translate}} - {{ title | translate}}</h2>
        <ng-content></ng-content>
        <div class="flex justify-content-end">
            <p-button label="Volver" (click)="volver()"></p-button>
        </div>
    </div>
    <div class="col-12 lg:col-2 barra-derecha-ayuda">
        <h4>Enlaces Relacionados</h4>
        <ul>
            <li>
                <help-link [linkFinal]="'listados'" [textoLink]="'Listados (General)'"></help-link>
            </li>
            <li>
                <help-link [linkFinal]="'gestores'" [textoLink]="'Gestores (General)'"></help-link>
            </li>
        </ul>
    </div>
</div>
