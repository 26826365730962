<!-- Sección de Detalle del Comprobante -->
<div class="grid">
	<!-- Cliente -->
	<div class="col-12 lg:col-4">
		<span class="p-float-label">
			<input id="cliente" type="text" pInputText disabled [value]="item?.cliente?.descripcion" />
			<label for="cliente">{{ "CLIENTE" | translate }}</label>
		</span>
	</div>

	<!-- Punto de Venta -->
	<div class="col-12 lg:col-4">
		<span class="p-float-label">
			<input id="puntoVenta" type="text" pInputText disabled [value]="item?.puntoDeVenta?.descripcion" />
			<label for="puntoVenta">{{ "PuntoDeVenta" | translate }}</label>
		</span>
	</div>
	<!-- Tipo de Comprobante -->
	<div class="col-12 lg:col-fixed w-18rem">
		<span class="p-float-label">
			<input id="tipoComprobante" type="text" pInputText disabled [value]="item?.comprobanteCompleto" />
			<label for="tipoComprobante">{{ "COMPROBANTE" | translate }}</label>
		</span>
	</div>

	<!-- Fecha -->
	<div class="col-12 lg:col-4">
		<div class="grid">
			<div class="col-6">
				<span class="p-float-label">
					<input id="fecha" type="text" pInputText disabled [value]="item?.fecha | date : 'dd/MM/yyyy'" />
					<label for="fecha">{{ "FECHA" | translate }}</label>
				</span>
			</div>
			<div class="col-6">
				<span class="p-float-label">
					<input id="fechaVencimiento" type="text" pInputText disabled [value]="item?.fechaVencimiento | date : 'dd/MM/yyyy'" />
					<label for="fechaVencimiento">{{ "FECHA_VENCIMIENTO" | translate }}</label>
				</span>
			</div>
		</div>
	</div>

	<!-- Descripción -->
	<div class="col-12 lg:col-6">
		<span class="p-float-label">
			<input id="descripcion" type="text" pInputText disabled [value]="item?.descripcion" />
			<label for="descripcion">{{ "DESCRIPCION" | translate }}</label>
		</span>
	</div>

	<!-- Moneda -->
	<div class="col-12 lg:col-3">
		<span class="p-float-label">
			<input id="moneda" type="text" pInputText disabled [value]="item?.moneda?.descripcion" />
			<label for="moneda">{{ "MONEDA" | translate }}</label>
		</span>
	</div>
</div>

<!-- Tabla de Items -->
<p-table [value]="item?.items" responsiveLayout="scroll" class="mt-4">
	<!-- Encabezados de columna -->
	<ng-template pTemplate="header">
		<tr>
			<th>{{ "DESCRIPCION" | translate }}</th>
			<th>{{ "CANTIDAD" | translate }}</th>
			<th>{{ "PRECIO_BRUTO" | translate }}</th>
			<th>{{ "IMPUESTOS" | translate }}</th>
			<th>{{ "PRECIO_UNITARIO" | translate }}</th>
			<th>{{ "IVA" | translate }}</th>
			<th>{{ "AJUSTE" | translate }}</th>
			<th>{{ "TOTAL" | translate }}</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-i>
		<tr>
			<td>{{ i?.producto?.descripcion }}</td>
			<td>{{ i?.cantidad }}</td>
			<td>{{ i?.precioUnitarioBruto | currency }}</td>
			<td>{{ i?.impuestos | currency }}</td>
			<td>{{ i?.precioUnitario | currency }}</td>
			<td>{{ i?.tipoIVA?.descripcion }}</td>
			<td>{{ i?.valorAjuste }}</td>
			<td>{{ i?.total | currency }}</td>
		</tr>
	</ng-template>
</p-table>

<!-- Sección de Ajustes y Redondeo -->
<div class="grid mt-4">
	<div class="col-12 lg:col-3" *ngIf="item?.bonificacion">
		<span class="p-float-label">
			<input id="ajuste" type="text" pInputText disabled [value]="item?.bonificacion + '%'" />
			<label for="ajuste">{{ "AJUSTE" | translate }}</label>
		</span>
	</div>
	<div class="col-12 lg:col-3" *ngIf="item?.redondeo">
		<span class="p-float-label">
			<input id="redondeo" type="text" pInputText disabled [value]="item?.redondeo | currency" />
			<label for="redondeo">{{ "REDONDEO" | translate }}</label>
		</span>
	</div>
</div>

<!-- Tabla de Cobros -->
<p-table [value]="cobranzas" responsiveLayout="scroll" class="mt-6">
	<!-- Encabezados de columna -->
	<ng-template pTemplate="emptyMessage">
		<span>{{ "NO_HAY_COBROS" | translate }}</span>
	</ng-template>
	<ng-template pTemplate="header">
		<tr>
			<th colspan="4">
				<strong>
					{{ "COBRANZAS" | translate }}
				</strong>
			</th>
		</tr>
		<tr>
			<th>{{ "NUMERODOCUMENTO" | translate }}</th>
			<th>{{ "DESCRIPCION" | translate }}</th>
			<th>{{ "FECHA" | translate }}</th>
			<th>{{ "IMPORTE" | translate }}</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-cobro>
		<tr>
			<td>{{ cobro?.id }}</td>
			<td>{{ cobro?.descripcion }}</td>
			<td>{{ cobro?.fecha | date : "shortDate" }}</td>
			<td>{{ cobro?.total + cobro?.totalNotasACuenta | currency }}</td>
		</tr>
	</ng-template>
</p-table>

<!-- Resumen Financiero -->
<div class="grid mt-4">
	<div class="col-8">
		<span></span>
	</div>
	<div class="col-12 lg:col-4">
		<div class="card" style="box-shadow: none; background-color: rgba(0, 0, 0, 0.06)">
			<div class="grid">
				<div class="col-6">{{ "SUBTOTAL" | translate }}:</div>
				<div class="col-6">{{ item?.subtotal | currency }}</div>
				<div class="col-6">{{ "TOTAL" | translate }}:</div>
				<div class="col-6">{{ item?.total | currency }}</div>
				<div class="col-6">{{ "SALDO" | translate }}:</div>
				<div class="col-6">{{ item?.saldo | currency }}</div>
			</div>
		</div>
	</div>
</div>
