<p-overlayPanel #op [dismissable]="false" appendTo="body" [styleClass]="'options'">
	<div class="grid buscador-puesto">
		<div class="col-12 lg:col">
			<descriptivo-material-selector
				[(ngModel)]="data.puesto"
				[service]="tipoPuestoService"
				[limpiable]="false"
				[label]="'TIPO_PUESTO' | translate"
				[permiteNuevo]="false"
				[isLazy]="true"
			>
			</descriptivo-material-selector>
		</div>

		<div class="col-12 lg:col">
			<descriptivo-material-selector
				[(ngModel)]="data.tipoFecha"
				[options]="opciones"
				[limpiable]="false"
				[label]="'TIPO_FECHA' | translate"
				[permiteNuevo]="false"
				[isLazy]="true"
				(onSelect)="onSelectTipoFecha($event)"
			>
			</descriptivo-material-selector>
		</div>

		<div class="col-12 lg:col-4" *ngIf="data?.tipoFecha?.codigo == 'FE'">
			<span class="p-float-label">
				<p-calendar [showIcon]="true" [(ngModel)]="fecha" appendTo="body" dateFormat="dd/mm/yy"></p-calendar>
				<label>{{ "FECHA" | translate }}</label>
			</span>
		</div>
	</div>
</p-overlayPanel>
<div class="grid container disponibilidad-container" *ngIf="mostrarDisponibilidad">
	<div class="col-12">
		<div class="grid content">
			<div class="col-12 disponibilidad-home">
				<disponibilidad-puesto [fecha]="data.fecha" [puesto]="data.puesto" [ocultarBuscador]="true"></disponibilidad-puesto>
			</div>
		</div>
	</div>
</div>
