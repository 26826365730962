import { Producto } from "./Producto"

export class HotelHabitacion {
	constructor(public id?: number, public version?: number, public habitacion?: Producto) {}
	public static fromData(data: any): HotelHabitacion {
		if (!data) return null
		const o: HotelHabitacion = new HotelHabitacion(data.id, data.version, Producto.fromData(data.habitacion))

		return o
	}
}
