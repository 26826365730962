<div class="gestor-tipo-puesto content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'TIPO-PUESTO' | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		[file]="file"
		[conArchivo]="true"
		(onItemGuardado)="handleGuardado($event)"
		(onCancelar)="handleCancelar($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<div class="grid" *ngIf="item">
				<div class="col-4 pic-wrapper">
					<div class="pic">
						<profile-badge [seleccionable]="true" [url]="item?.icono?.picPathVersion" class="" (onFileSelected)="onPicSelected($event)">
						</profile-badge>
						<i class="fa fa-pencil edit-icon"></i>
					</div>
				</div>
				<div class="col-8">
					<div class="grid">
						<div class="col-2">
							<div class="p-field">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'PESO_PH' | translate }}"
										[(ngModel)]="item.peso"
										tooltipPosition="bottom"
										pTooltip="{{ 'PESO_TOOLTIP' | translate }}"
										type="number"
									/>
									<label for="peso">{{ "PESO" | translate }}</label>
								</span>
							</div>
						</div>
						<div class="col-12 lg:col-2">
							<div class="p-field">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'CODIGO_PH' | translate }}"
										[(ngModel)]="item.codigo"
										tooltipPosition="bottom"
										pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
										type="text"
										[disabled]="item?.esSistema"
									/>
									<label for="codigo">{{ "CODIGO" | translate }}</label>
								</span>
							</div>
						</div>
						<div class="col-12 lg:col-8">
							<div class="p-field">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'DESCRIPCION_PH' | translate }}"
										[(ngModel)]="item.descripcion"
										tooltipPosition="bottom"
										pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
										type="text"
									/>
									<label for="descripcion">{{ "DESCRIPCION" | translate }}</label>
								</span>
							</div>
						</div>
						<div class="col-12" *ngIf="!categoriaSeleccionada">
							<div class="p-field">
								<parametrico-selector
									[allowDefault]="!item?.id"
									[service]="categoriaService"
									[(ngModel)]="item.categoria"
									[disabled]="item?.esSistema"
									[limpiable]="true"
									[permiteNuevo]="true"
									name="categoria"
									[titulo]="'CATEGORIA' | translate"
								>
								</parametrico-selector>
							</div>
						</div>
						<div class="col align-content-center">
							<div class="p-field">
								<p-checkbox
									[(ngModel)]="item.esPrestador"
									[binary]="true"
									name="esPrestador"
									label="{{ 'ES_PRESTADOR' | translate }}"
									pTooltip="{{ 'ES_PRESTADOR_TP' | translate }}"
								>
								</p-checkbox>
							</div>
						</div>
						<div class="col align-content-center">
							<div class="p-field">
								<p-checkbox
									[(ngModel)]="item.recibeResumen"
									[binary]="true"
									name="recibeResumen"
									label="{{ 'RECIBE_OT' | translate }}"
									pTooltip="{{ 'RECIBE_OT_TP' | translate }}"
								>
								</p-checkbox>
							</div>
						</div>
						<div class="col align-content-center">
							<div class="p-field">
								<p-checkbox
									[(ngModel)]="item.recibeReserva"
									[binary]="true"
									name="recibeReserva"
									label="{{ 'RECIBE_CONFIRMACION' | translate }}"
									pTooltip="{{ 'RECIBE_CONFIRMACION_TP' | translate }}"
									(click)="item.recibeDatosPaxs = false"
								>
								</p-checkbox>
								<p-checkbox
									[(ngModel)]="item.recibeDatosPaxs"
									[binary]="true"
									name="recibeDatosPaxs"
									label="{{ 'Recibe datos de los paxs' | translate }}"
									*ngIf="item.recibeReserva"
									style="margin-top: 15px"
								>
								</p-checkbox>
							</div>
						</div>
						<div class="col p-align-center">
							<div class="p-field">
								<p-checkbox
									[(ngModel)]="item.mostrarEncuesta"
									[binary]="true"
									name="mostrarEncuesta"
									label="{{ 'MOSTRAR_ENCUESTA' | translate }}"
									pTooltip="{{ 'MOSTRAR_ENCUESTA_TP' | translate }}"
								>
								</p-checkbox>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</model-gestor>
</div>
