import { ItemReserva } from './../../../model/ItemReserva'
import { CotizacionService } from './../../../services/cotizacion.service'
import { Moneda } from 'src/app/model/Moneda'
import { MonedaService } from 'src/app/services/moneda.service'
import { ComprobanteService } from './../../../services/comprobante.service'
import { Comprobante } from './../../../model/Comprobante'
import { HotelService } from './../../../services/hotel.service'
import { ClienteService } from './../../../services/cliente.service'
import { PuntoDeVentaService } from './../../../services/punto-de-venta.service'
import { DetalleGastoReservaVO } from './../../../model/DetalleGastoReservaVO'
import { Reserva } from './../../../model/Reserva'
import { ReservaService } from 'src/app/services/reserva.service'
import { Router, ActivatedRoute } from '@angular/router'
import { MessagesService } from './../../../common/services/messages-data-service.service'
import { MargenReporteService } from 'src/app/services/margen-reporte.service'
import { SessionComponent } from './../../../common/components/session-component.component'
import { Component, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment'
import { ConfiguracionEntorno } from 'src/app/model/ConfiguracionEntorno'
import { MonedaEntorno } from 'src/app/app.module'

@Component({
    selector: 'vista-margen-reporte',
    templateUrl: 'vista-margen-reporte.component.html',
    styleUrls: ['vista-margen-reporte.component.less'],
})
export class VistaMargenReporteComponent extends SessionComponent {
    private routeSub
    public reserva: Reserva
    private reservaOriginal: Reserva
    public detalleGastos: DetalleGastoReservaVO[] = []
    public readonly = true
    public expandidoAll: boolean = false
    public totalGastos: number = 0
    public totalVentasBruto: number = 1
    public totalComisiones: number = 0
    public impuestos: number = 0
    public cotizacion: number = 1
    public comprobante: Comprobante
    public monedaVisualizacion: Moneda
    constructor(
        messagesService: MessagesService,
        public service: MargenReporteService,
        public comprobanteService: ComprobanteService,
        public router: Router,
        public activeRoute: ActivatedRoute,
        public monedaService: MonedaService,
        public cotizacionService: CotizacionService,
        public reservaService: ReservaService
    ) {
        super(messagesService)
    }
    ngOnInit() {
        if (this.activeRoute.snapshot.url.some((u) => u.path == 'margen-reporte')) {
            this.readonly = true
            this.routeSub = this.activeRoute.queryParams.subscribe((params) => {
                let id: number = <number>params['id']
                if (!this.service) return
                if (id) {
                    this.reservaService.getById(id).then(async (r) => {
                        this.reservaOriginal = r
                        this.monedaVisualizacion = this.reservaOriginal.moneda
                        if (this.reservaOriginal.moneda?.codigo != MonedaEntorno().codigo) {
                            if (this.reservaOriginal.comprobante) {
                                this.comprobante = await this.comprobanteService.getById(this.reservaOriginal.comprobante)
                                this.cotizacion = this.comprobante.cotizacionAfip
                            } else {
                                this.cotizacion = (await this.cotizacionService.getCotizacion(this.reservaOriginal.moneda, Moneda.PESOS)).valor || 1
                            }
                        }
                        this.service.getDetalleByReserva(r.id).then((d) => {
                            this.detalleGastos = d
                            this.updateImportes()
                        })
                    })
                }
            })
        }
    }
    public async updateImportes() {
        this.reserva =Reserva.fromData(this.reservaOriginal);

        this.totalGastos = 0
        this.totalComisiones = 0
        this.totalVentasBruto = this.getValor(this.reserva.total,this.reserva.moneda);
        this.impuestos = 0 //this.getValor(this.reserva.impuestos,this.reserva.moneda)      
        this.reserva.itemsReserva.forEach((ir) => {
            ir['expandido'] = false
            ir['gastos'] = this.detalleGastos.filter((g) => g.idItemReserva == ir.id)
            ir['totalGastos'] = ir['gastos'].reduce((a, b) => (a += this.getValor(b.importeGasto * b.cantGasto, Moneda.PESOS)), 0)
            this.totalGastos += ir['totalGastos']
            ir['totalGastos'] += this.getValor(ir.valorComision, this.reserva.moneda)
            this.totalComisiones += this.getValor(ir.valorComision, this.reserva.moneda)
            ir.precioPerCapita = this.getValor(ir.precioPerCapita, this.reserva.moneda)
            ir['valorComisionTransformado'] = this.getValor(ir.valorAjuste, this.reserva.moneda)
            ir['impuestosTransformado'] = this.getValor(ir['impuestosTransformado'] || 0, this.reserva.moneda)
            this.impuestos += ir['impuestos']
        })
    }
    public async cambioMoneda(moneda: Moneda) {
        if (moneda?.codigo != MonedaEntorno().codigo && !this.cotizacion) {
            this.cotizacion = (await this.cotizacionService.getCotizacion(this.reserva.moneda, Moneda.PESOS)).valor || 1
        }
        this.updateImportes()
    }
    public get monedaSimbolo() {
        return this.monedaVisualizacion ? this.monedaVisualizacion?.simbolo + ' ' : '$ '
    }

    public toggle(event, item) {
        event.stopPropagation && event.stopPropagation()
        item['expandido'] = !item['expandido']
        if (item['expandido'] != this.expandidoAll) {
            this.expandidoAll = true
        }
    }
    public toggleAll(event) {
        if (this.reserva) {
            this.reserva.itemsReserva.forEach((i) => (i['expandido'] = !this.expandidoAll))
            this.expandidoAll = !this.expandidoAll
        }
    }
    public getValor(valor: number, moneda: Moneda = Moneda.PESOS) {
        return moneda?.codigo != this.monedaVisualizacion?.codigo ? valor * this.cotizacion : valor
    }
}
