import { Injectable } from "@angular/core"
import { AdministracionAuthGuard } from "./AdministracionAuthGuard"

@Injectable({
	providedIn: "root"
})
export class ProveedoresAG extends AdministracionAuthGuard {
	public getRoles(): string[] {
		return super.getRoles().concat(["ROLE_MD_PROVEEDORES", "ROL_PROVEEDOR", "ROLE_PROVEEDOR"])
	}
}
