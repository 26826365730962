<p-dataView
	#dv
	[value]="items"
	[paginator]="conPaginado"
	[rows]="20"
	paginatorPosition="bottom"
	[emptyMessage]="emptyMessage ? emptyMessage : ('SIN_REGISTROS' | translate)"
	styleClass="abm-hijo-container"
>
	<p-header>
		<ng-content *ngTemplateOutlet="headerTemplate ? headerTemplate : defaultHeader"></ng-content>
	</p-header>
	<ng-template let-item pTemplate="listItem" let-i="rowIndex">
		<ng-content *ngTemplateOutlet="gridTemplate ? gridTemplate : defaultGrid; context: { $implicit: item, index: i }"> </ng-content>
	</ng-template>
</p-dataView>

<p-dialog
	[(visible)]="editando"
	appendTo="body"
	[modal]="true"
	#dialDetail
	[styleClass]="'no-header gestor-dialog'"
	(onShow)="onDialogShow($event, dialDetail)"
>
	<ng-container *ngIf="editando">
		<ng-container *ngTemplateOutlet="gestor; context: ctx"></ng-container>
	</ng-container>
</p-dialog>
<ng-template #defaultGrid let-item let-index="index">
	<div class="col-12">
		<div class="grid align-items-center m-0 justify-content-center">
			<div class="col" (click)="editar($event, item)">
				<ng-content *ngTemplateOutlet="itemTemplate ? itemTemplate : defaultItem; context: { $implicit: item, index: index }"> </ng-content>
			</div>
			<div class="col-fixed text-center" style="width: 4em" *ngIf="editable || visualizable">
				<button pButton class="accion" (click)="quitarItem(item)" icon="fa fa-trash" *ngIf="editable && !item.esSoloDisplay"></button>
				<button pButton class="accion" (click)="onVer.emit(item)" icon="fa fa-eye" *ngIf="visualizable && !item.esSoloDisplay"></button>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #defaultItem let-item let-index="index">
	<div class="grid align-items-center grid-nogutter justify-content-center">
		<div class="col-2">
			<span class="p-float-label">
				<input
					pInputText
					placeholder="{{ 'CODIGO_PH' | translate }}"
					[(ngModel)]="item.codigo"
					readonly
					tooltipPosition="bottom"
					pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
					type="text"
					#codigo="ngModel"
					[name]="'codigo' + index"
					class="p-inputwrapper-filled"
				/>
				<label *ngIf="index == 0">{{ "CODIGO" | translate }}</label>
			</span>
		</div>
		<div class="col-10">
			<span class="p-float-label">
				<input
					pInputText
					placeholder="{{ 'DESCRIPCION_PH' | translate }}"
					[(ngModel)]="item.descripcion"
					readonly
					tooltipPosition="bottom"
					pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
					type="text"
					#descripcion="ngModel"
					[name]="'descripcion' + index"
					class="p-inputwrapper-filled"
				/>
				<label *ngIf="index == 0">{{ "DESCRIPCION" | translate }}</label>
			</span>
		</div>
	</div>
</ng-template>

<ng-template #defaultHeader>
	<button
		pButton
		[label]="labelNuevo | translate"
		icon="pi pi-plus"
		(click)="agregarOpcion()"
		*ngIf="(gestor || insertOnly) && editable"
		id="{{ buttonName }}"
	></button>
</ng-template>
