import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import * as moment from "moment"
import { BehaviorSubject, Observable } from "rxjs"
import { filter, first, scan } from "rxjs/operators"
import { ItinerarioAG } from "src/app/authguards/ItinerarioAG"
import { commonAnimations } from "src/app/common-animations"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { StringUtils } from "src/app/common/utils/string-utils"
import { GrupoPax } from "src/app/model/GrupoPax"
import { ItemReserva } from "src/app/model/ItemReserva"
import { PuestoServicioReserva } from "src/app/model/PuestoServicioReserva"
import { Vehiculo } from "src/app/model/Vehiculo"
import { ClienteService } from "src/app/services/cliente.service"
import { GrupoPaxService } from "src/app/services/grupo-pax.service"
import { ItemReservaService } from "src/app/services/item-reserva.service"
import { ProductoService } from "src/app/services/producto.service"
import { PuestoServicioReservaService } from "src/app/services/puesto-servicio-reserva.service"
import { TipoVehiculoService } from "src/app/services/tipovehiculo.service"
import { HelpService } from "../../../services/help.service"
import { FiltroProducto } from "../../producto/FiltroProducto"
import { FiltroGrupo } from "../gestor-reserva/FiltroGrupo"
import { ProveedorService } from "./../../../services/proveedor.service"

@Component({
	selector: "itinerario",
	templateUrl: "itinerario.component.html",
	styleUrls: ["itinerario.component.less"],
	animations: [commonAnimations]
})
export class ItinerarioComponent extends SessionComponent implements OnInit {
	public filtro: FiltroGrupo
	public itemEditado: ItemReserva
	public nuevasAsignaciones = false
	public vehiculos: Vehiculo[] = []
	public vehiculosFiltrados: Vehiculo[] = []
	public grupos: GrupoPax[] = []
	public alquilados: GrupoPax[] = []
	public gruposFiltrados: GrupoPax[] = []
	public verTotalesAdicionales: boolean = false
	public horas: number[] = Array(48)
		.fill(0)
		.map((x, i) => i / 2)

	public customLoading: LoadingService = new LoadingService()
	public grupoOrigen: GrupoPax
	public grupoDestino: GrupoPax
	public productos = new BehaviorSubject<Descriptivo[]>([])
	public productos$: Observable<Descriptivo[]>
	public offline = false

	public get layout(): string {
		return this.filtro.layout
	}
	public set layout(v: string) {
		this.gruposFiltrados = []
	}

	public get fechaDesdePivote(): Date {
		return this.filtro?.fecha || new Date()
	}
	public set fechaDesdePivote(v: Date) {
		if (this.filtro?.layout == "R") {
			this.filtro.setMultiple({
				fecha: v ? moment(v).startOf("day").toDate() : moment().startOf("day").toDate(),
				fechaHasta: v ? moment(v).add(6, "days").endOf("day").toDate() : moment().endOf("day").toDate()
			})
		} else this.filtro.fecha = v || moment().startOf("day").toDate()
	}
	public get fechaHastaPivote(): Date {
		return this.filtro?.fechaHasta || moment().endOf("day").toDate()
	}
	public set fechaHastaPivote(v: Date) {
		if (this.filtro?.layout == "R") {
			this.filtro.setMultiple({
				fecha: v ? moment(v).startOf("day").subtract(6, "days").toDate() : moment().startOf("day").toDate(),
				fechaHasta: v ? moment(v).endOf("day").toDate() : moment().endOf("day").add(6, "days").toDate()
			})
		} else this.filtro.fechaHasta = v || moment().endOf("day").toDate()
	}

	public productoFiltro: FiltroProducto = new FiltroProducto("productos", -1, 100, "descripcion", 1)
	public fechas: {
		fecha: Date
		grupos: GrupoPax[]
		actividades: {
			key: string
			actividad: Descriptivo
			proveedores: Descriptivo[]
		}[]
		totales: {
			producto: Descriptivo
			total: number
		}[]
	}[] = []
	public getButtonClass() {
		return "full"
	}
	public layoutOptions = [
		{
			value: "R",
			label: "Por reserva",
			icon: "fa fa-calendar"
		},
		{
			value: "D",
			label: "Por día",
			icon: "fa fa-calendar-check-o"
		}
	]
	private _sinAsignar: boolean
	public get sinAsignar(): boolean {
		return this._sinAsignar
	}
	public set sinAsignar(v: boolean) {
		this._sinAsignar = v
		this.aplicarFiltros(this.filtro)
	}
	public totales: { producto: Descriptivo; total: number }[] = []
	constructor(
		public router: Router,
		public clienteService: ClienteService,
		public itemReservaService: ItemReservaService,
		public grupoPaxService: GrupoPaxService,
		public puestoServicioReservaService: PuestoServicioReservaService,
		public productoService: ProductoService,
		public tipoVehiculoService: TipoVehiculoService,
		public proveedorService: ProveedorService,
		public helpService: HelpService,
		public itinerarioAG: ItinerarioAG
	) {
		super()
	}
	public async getGrupos(r: FiltroGrupo): Promise<GrupoPax[]> {
		this.grupos = await this.grupoPaxService.getAll(r)

		if (this.grupos?.length) {
			this.grupos.forEach((grupo) => {
				if (grupo.gruposReserva?.length) {
					grupo.gruposReserva = grupo.gruposReserva.sort((a, b) => {
						return moment(a.itemReserva?.pickUp).isBefore(b.itemReserva?.pickUp, "hour") ? -1 : 1
					})
				}
			})
		}

		this.refreshGrupos()
		return this.grupos
	}
	public aplicarFiltros(filtro: FiltroGrupo) {
		this.gruposFiltrados = []
		let gruposFiltrados = this.grupos.filter((g) => {
			return (
				(filtro.productos?.length == 0 || filtro.productos.some((pp) => g.tieneProducto(pp))) &&
				(!filtro.cliente || g.gruposReserva.some((gr) => gr.itemReserva?.cliente?.id == filtro.cliente?.id)) &&
				(!this.sinAsignar || g.sinAsignar) &&
				g.gruposReserva.some((p) => !filtro.searchStr || StringUtils.contiene(p.itemReserva?.descripcion, filtro.searchStr))
			)
		})
		if (gruposFiltrados?.length) {
			if (filtro.proveedor) {
				gruposFiltrados.forEach((grupo: GrupoPax) => {
					let salir = false
					grupo.puestos.forEach((puesto: PuestoServicioReserva) => {
						if (!salir && puesto.personal?.id == filtro.proveedor.id) {
							this.gruposFiltrados = [...this.gruposFiltrados, grupo]
							salir = true
						}
					})
				})
			} else this.gruposFiltrados = gruposFiltrados
		}
		this.fechas = []
		this.gruposFiltrados.forEach((g) => {
			if (!this.fechas.some((f) => moment(f.fecha).isSame(g.fechaActividad, "date"))) {
				this.fechas.push({ fecha: g.fechaActividad, grupos: [], totales: [], actividades: [] })
			}

			const l = this.fechas.filter((f) => moment(f.fecha).isSame(g.fechaActividad, "date"))[0]
			l.grupos.push(g)
			g.productosAsociados.forEach((pa) => {
				let t = l.totales.find((tot) => tot.producto.id == pa.producto.id)
				if (!t) {
					t = { producto: pa.producto, total: 0 }
					l.totales.push(t)
				}
				t.total += pa.cantidad
			})
		})
		this.fechas.forEach((f) => {
			f.actividades = []
			f.grupos.forEach((g) => {
				let a = f.actividades.find((act) => act.key === g.keyActividad)
				if (!a) {
					a = { key: g.keyActividad, actividad: g.actividad, proveedores: [] }
					f.actividades.push(a)
				}
				g.puestos.forEach((p) => {
					if (p.proveedor?.id && !a.proveedores?.some((prov) => prov.id == p.proveedor?.id)) {
						a.proveedores.push(Descriptivo.fromData(p.proveedor))
					}
				})
			})
		})
		this.alquilados = this.gruposFiltrados.filter((f) => f.esAlquiler)
	}

	public refreshGrupos() {
		this.aplicarFiltros(this.filtro)
	}

	public getNextBatch() {
		this.productoFiltro.page++
		this.productoService.getServicios(this.productoFiltro, this.customLoading).then((r) => {
			this.productos.next(r)
		})
	}

	ngOnInit() {
		this.offline = !navigator.onLine && this.environment.production == true
		this.filtro = this.fitlerServices.getFilter("itinerario", new FiltroGrupo("itinerario", 0, 10000, "R"))
		this.filtro.proveedor = this.usuario?.proveedor || null
		this.filtro.layout = this.filtro?.layout === "G" ? "D" : this.filtro.layout || "R"
		this.verTotalesAdicionales = this.itinerarioAG.esVisible(this.usuario)
		if (this.esExterno) {
			this.filtro.layout = "D"
		}
		this.productos$ = this.productos.pipe(
			scan((acc, curr) => {
				return [...acc, ...curr]
			}, [])
		)
		this.getNextBatch()
		this.filtro.isReady
			.pipe(
				filter((v) => v == true),
				first()
			)
			.subscribe((v) => {
				this.layout = this.filtro.layout

				this.subs.push(
					this.filtro.dataChange.subscribe((r: FiltroGrupo) => {
						if (this.layout === "D") {
							if (this.esExterno) {
								r.setMultiple({
									proveedor: this.usuario.proveedor,
									fechaHasta:
										!r.fechaHasta || moment(r.fechaHasta).isAfter(moment().add(15, "days"))
											? (r.fechaHasta = moment().add(15, "days").toDate())
											: r.fechaHasta
								})
								this.getGrupos(r)
							} else {
								this.getGrupos(r)
								this.fitlerServices.setFilter("itinerario", r)
							}
						} else if (this.layout === "R" && this.esExterno) {
							return []
						}
					})
				)
			})
	}
	public irAFecha(date: Date) {
		if (this.isMobile()) return
		this.filtro.setMultiple({
			fecha: moment(date).startOf("day").toDate(),
			fechaHasta: this.filtro.layout === "R" ? moment(date).add(6, "days").endOf("day").toDate() : moment(date).endOf("day").toDate()
		})
	}

	updateDia(dias: number) {
		this.fechaDesdePivote = moment().add(dias, "days").startOf("day").toDate()
		if (this.filtro.layout != "R") {
			this.fechaHastaPivote = moment().add(dias, "days").endOf("day").toDate()
		}
	}

	public nuevo() {
		this.router.navigate(["reserva/nuevo"])
	}

	public editar(item: ItemReserva) {
		this.router.navigate(["reserva/edit"], { queryParams: { id: item.idReserva } })
	}
}
