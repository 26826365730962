import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { ServicioAbstract } from "src/app/common/services/service.service"
import { ItemConsentimiento } from "src/app/model/Consentimiento"
import { GrupoReserva } from "src/app/model/GrupoReserva"
import { ItemReservaConsentimiento } from "src/app/model/ItemReservaConsentimiento"
import { FamiliaConsentimiento } from "../../consentimiento-actividades/FamiliaConsentimiento"
import { IActividad } from "../../consentimiento-actividades/IActividad"

@Injectable({
	providedIn: "root"
})
export class VoucherService extends ServicioAbstract<IActividad> {
	getGrupoByTokenAndId(token: any, id: any, customLoading?: LoadingService): Promise<IActividad> {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getApiURL() + "public/" + this.baseName() + "/grupoByTokenAndId/" + token + "/" + id))
			.then((r) => {
				return this.parseSingleGrupo(r)
			})
			.catch((e) => {
				return this.handleError(e)
			})
			.finally(() => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
			})
	}
	getFamiliaByTokenAndId(token: any, id: any, customLoading?: LoadingService): Promise<IActividad> {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getApiURL() + "public/" + this.baseName() + "/familiaByTokenAndId/" + token + "/" + id))
			.then((r) => {
				return this.parseSingleFamilia(r)
			})
			.catch((e) => {
				return this.handleError(e)
			})
			.finally(() => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
			})
	}
	getHotelByTokenAndId(token: any, id: any, customLoading?: LoadingService): Promise<ItemReservaConsentimiento> {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getApiURL() + "public/" + this.baseName() + "/hotelByTokenAndId/" + token + "/" + id))
			.then((r) => {
				return this.parseSingleHotel(r)
			})
			.catch((e) => {
				return this.handleError(e)
			})
			.finally(() => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
			})
	}
	public baseName(): string {
		return "voucher"
	}
	public parseToEnt(data: any): IActividad {
		return null
	}
	parseSingleGrupo(data: any): IActividad {
		return GrupoReserva.fromData(data)
	}
	parseSingleHotel(data: any): ItemConsentimiento {
		return ItemConsentimiento.fromData(data)
	}
	parseSingleFamilia(data: any): IActividad {
		return FamiliaConsentimiento.fromData(data)
	}
	public newEnt(): IActividad {
		return null
	}
}
