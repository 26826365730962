import { Injectable } from "@angular/core";
import { IMessage, RxStomp } from "@stomp/rx-stomp";
import { StompConfig } from "@stomp/stompjs";
import { BehaviorSubject, Observable, from } from "rxjs";
import { filter, first, switchMap } from "rxjs/operators";
import SockJS from "sockjs-client";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";

@Injectable({
	providedIn: "root",
})
export class WebsocketService {
	private rxStomp: RxStomp = new RxStomp();
	private reconnectAttempts = 0;
	private intentionallyDisconnected: boolean = false;
	private readonly maxReconnectAttempts = 5;
	public connectionState: BehaviorSubject<number> = new BehaviorSubject(WebSocket.CLOSED);
	public connecting: Promise<void>;
	constructor(private authService: AuthService) {
		this.authService
			.getAutenticateState()
			.pipe(filter((u) => u === AuthService.LOGUEADO),first())
			.subscribe((l) => {
				this.openConnection();
			});
	}

	isConnected(): boolean {
		return this.rxStomp?.active;
	}

	openConnection(): Promise<void> {
		if(this.connectionState.value === WebSocket.CONNECTING)	return this.connecting;
		this.connecting = new Promise((resolve, reject) => {
			const stompConfig: StompConfig = {
				brokerURL: "",
				webSocketFactory: () =>{
					this.connectionState.next(WebSocket.CONNECTING);
					return new SockJS(environment.apiUrl + `websocket?access_token=${this.authService.token}`, null, { transports: ["websocket"] })
				}
					,

				debug: (msg: string) => {
					console.log(new Date(), msg);
				},
				onStompError: (frame) => {
					console.error("Error en la conexión STOMP:", frame);
				},
				onConnect: (frame) => {
					this.intentionallyDisconnected = false;
					this.reconnectAttempts = 0;
					resolve();
				},
				onUnhandledMessage: (message) => {
					console.log("Mensaje no manejado:", message);
				},
				onDisconnect: (frame) => {
					if (this.intentionallyDisconnected) {
						return;
					}
					console.error("Desconectado. Intentando reconectar...");
					if (this.reconnectAttempts < this.maxReconnectAttempts) {
						setTimeout(() => {
							this.reconnectAttempts++;
							this.openConnection().then(resolve).catch(reject);
						}, 5000); // Espera 5 segundos antes de intentar reconectar
					} else {
						console.error("Se alcanzó el máximo número de intentos de reconexión.");
						reject(new Error("Se alcanzó el máximo número de intentos de reconexión."));
					}
				},
			};

			this.rxStomp = new RxStomp();
			this.rxStomp.configure(stompConfig);
			this.rxStomp.activate();
		});
		return this.connecting;
	}

	connect(): void {
		
		if (!this.rxStomp.active) {
			this.openConnection().then(() => {
				this.connectionState.next(WebSocket.OPEN);
			});
		} else {
			this.connectionState.next(WebSocket.OPEN);
		}
	}

	disconnect(): void {
		this.intentionallyDisconnected = true;
		this.rxStomp.deactivate();
		this.connectionState.next(WebSocket.CLOSED);
	}

	subscribe(topic: string): Observable<IMessage> {
		if (this.rxStomp.active) {
			return this.rxStomp.watch(topic);
		}

		return from(this.openConnection()).pipe(switchMap(() => this.rxStomp.watch(topic)));
	}

	send(destination: string, message: string): void {
		if (!this.rxStomp.active) {
			this.openConnection().then(() => {
				this.rxStomp.publish({ destination, body: message });
			});
		} else {
			this.rxStomp.publish({ destination, body: message });
		}
	}
}
