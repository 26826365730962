
import { AccionAutomatica} from './AccionAutomatica';


export class SerieAccion  {
    constructor(
		public id ?: number, 
		public fechaDesde ?: Date, 
		public fechaHasta ?: Date, 
		public acciones : AccionAutomatica[] = [], 
		public n ?: number, 
		public unidad ?: string, 
		public esCancelada ?: boolean, 
		public contexto : any = {}, 
		public tipoAccion ?: string, 
    ) {
    }
    public static fromData(data: any): SerieAccion {
        if (!data) return null;
        const o: SerieAccion = new SerieAccion(
			data.id, 
			data.fechaDesde? new Date(data.fechaDesde) : null , 
			data.fechaHasta? new Date(data.fechaHasta) : null , 
			data.acciones? data.acciones.map(c => AccionAutomatica.fromData(c)) : [], 
			data.n, 
			data.unidad, 
			data.esCancelada, 

        );

		return o;

    }

}