import { Descriptivo } from "src/app/common/model/Descriptivo"
import { PuestoACubrir } from "src/app/model/PuestoACubrir"
import { TipoPuesto } from "src/app/model/TipoPuesto"
import { ContenedorPuesto } from "./../common-components/puesto-badge/ContenedorPuesto"
import { GastoPuesto } from "./GastoPuesto"
import { IConCategoria } from "./IConCategoria"
import { Proveedor } from "./Proveedor"
import { ProveedorCabecera } from "./ProveedorCabecera"
import { PuestoInsumo } from "./PuestoInsumo"

export class PuestoServicioReserva implements IConCategoria, ContenedorPuesto {
	public get permiteConfirmar() {
		return this.puesto?.tipoPuesto?.recibeReserva && (this.puesto?.proveedor || this.personal)
	}
	constructor(
		public id?: number,
		public personal?: ProveedorCabecera,
		public puesto?: PuestoACubrir,
		public codigoEstado?: string,
		public gastos: GastoPuesto[] = [],
		public notificado?: boolean,
		public estado?: Descriptivo,
		public esAdicional?: boolean,
		public confirmar?: boolean
	) {}

	getCategoria(): string {
		return this.puesto?.tipoPuesto?.categoria.codigo
	}
	public static fromData(data: any): PuestoServicioReserva {
		if (!data) return null
		const o: PuestoServicioReserva = new PuestoServicioReserva(
			data.id,
			ProveedorCabecera.fromData(data.personal),
			PuestoACubrir.fromData(data.puesto),
			data.codigoEstado,
			data.gastos?.map((g) => GastoPuesto.fromData(g)) || [],
			data.notificado,
			Descriptivo.fromData(data.estado),
			data.esAdicional,
			data.confirmar
		)

		return o
	}
	public get proveedor(): ProveedorCabecera {
		return this.personal
	}
	public set proveedor(v: ProveedorCabecera) {
		this.personal = v
		if (this.puesto) this.puesto.proveedor = Proveedor.fromData(v)
	}

	public get insumos(): PuestoInsumo[] {
		return this.puesto.insumos
	}
	public set insumos(v: PuestoInsumo[]) {
		this.puesto.insumos = v
	}

	public get tipoPuesto(): TipoPuesto {
		return this.puesto.tipoPuesto
	}
	public set tipoPuesto(v: TipoPuesto) {
		this.puesto.tipoPuesto = v
	}

	public clonar() {
		return PuestoServicioReserva.fromData(this)
	}

	public esPrestador() {
		return this.tipoPuesto?.esPrestador || this.personal?.esPrestador
	}
}
