import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { Subscription } from "rxjs"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Unidad } from "src/app/model/Unidad"
import { UnidadService } from "src/app/services/unidad.service"
import { SessionComponent } from "./../../../common/components/session-component.component"

@Component({
	selector: "gestor-unidad",
	templateUrl: "gestor-unidad.component.html",
	styleUrls: ["gestor-unidad.component.less"]
})
export class GestorUnidadComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	@Input()
	public item: Unidad = new Unidad()
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false

	@Input()
	public readonly: boolean = false

	@Output()
	public onGuardado: EventEmitter<Unidad> = new EventEmitter<Unidad>()

	@Output()
	public onCancelado = new EventEmitter()

	constructor(messagesService: MessagesService, public service: UnidadService, private route: ActivatedRoute, private confService: ConfirmationService) {
		super(messagesService)
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}

	public isValid() {
		return true
	}
	ngOnInit() {
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
				}
			})
		)
		if (this.route.snapshot.url.some((u) => u.path == "unidad")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				if (!this.service) return
				if (id) {
					this.service.getById(id).then((r) => {
						this.item = r
					})
				} else {
					this.item = this.service ? this.service.newEnt() : null
				}
			})
		}
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}
}
