import { Injectable } from "@angular/core"
import * as moment from "moment"
import { BehaviorSubject, lastValueFrom } from "rxjs"
import { filter, first } from "rxjs/operators"
import { ServicioAbstract } from "src/app/common/services/service.service"
import { GrupoPax } from "src/app/model/GrupoPax"
import { TotalesActividad } from "src/app/model/TotalesActividad"
import { ProductoService } from "src/app/services/producto.service"
import { environment } from "src/environments/environment"
import { LoadingService } from "../common/services/loading-data-service.service"
import { FiltroServicios } from "../model/FiltroServicios"
import { Intercambio } from "../model/Intercambio"
import { ReporteServicios } from "../model/ReporteServicios"
import { FiltroVehiculo } from "./../common/model/FiltroVehiculo"
import { Delegacion } from "./../model/Delegacion"
import { ReporteActividades } from "./../model/ReporteActividades"
import { FiltroProducto } from "./../pages/producto/FiltroProducto"
import { FiltroGrupo } from "./../pages/reserva/gestor-reserva/FiltroGrupo"
import { AuthService } from "./auth.service"
@Injectable({
	providedIn: "root"
})
export class GrupoPaxService extends ServicioAbstract<GrupoPax> {
	public isReady: BehaviorSubject<boolean> = new BehaviorSubject(false)

	constructor(protected authService: AuthService, protected productoService: ProductoService) {
		super()
		this.init()
	}
	public baseName(): string {
		return "grupo-pax"
	}
	public parseToEnt(data: any): GrupoPax {
		return GrupoPax.fromData(data)
	}
	public newEnt(): GrupoPax {
		return new GrupoPax(6)
	}

	init() {
		let f = new FiltroGrupo("itinerario_today")
		f.fecha = new Date()
		f.fechaHasta = moment(f.fecha).endOf("day").toDate()
		if (this.authService.getCurrentUser?.proveedor) {
			f.proveedor = this.authService.getCurrentUser.proveedor
		}
		super.getAll(f).then((grupos) => {
			localStorage.setItem(environment.tokenKey + "_itinerario", JSON.stringify(grupos))
			this.isReady.next(true)
		})
	}

	public getAll(filtro?: FiltroGrupo, customLoading?: LoadingService): Promise<GrupoPax[]> {
		if (!filtro) return Promise.resolve([])
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		const _filtro = filtro

		return lastValueFrom(
			this.isReady.pipe(
				filter((f) => f == true),
				first()
			)
		)
			.then((f) => {
				if (navigator.onLine) return super.getAll(_filtro ?? new FiltroGrupo("grupo_pax_itinerario", 0, 10000), customLoading)
				else
					return this.getCache().then((gr) => {
						let grupos = JSON.parse(gr)
						return grupos.map((g) => GrupoPax.fromData(g))
					})
			})
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}
	actualizarPickups(data: GrupoPax, diferencia: number, opcion: string = "i", customLoading: LoadingService): Promise<GrupoPax> {
		let $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("actualizarPickup"), { grupoId: data.id, diferencia: diferencia, opcion: opcion }))
			.then((r) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return $this.parseSingle(r)
			})
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}
	public getByVehiculo(filtro?: FiltroVehiculo, customLoading?: LoadingService): Promise<GrupoPax[]> {
		let $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("getByVehiculo"), filtro.json), { defaultValue: [] })
			.then((r) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return $this.parse(r)
			})
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}

	getCache() {
		return new Promise<any>(async (resolve) => {
			return resolve(localStorage.getItem(environment.tokenKey + "_itinerario"))
		})
	}

	public actualizarIntercambio(intercambio: Intercambio): Promise<Boolean> {
		return lastValueFrom(this.http.put(this.getBaseURL("actualizar-intercambio"), intercambio)).then((r) => {
			return true
		})
	}
	public actualizarVehiculos(intercambio: Intercambio): Promise<Boolean> {
		return lastValueFrom(this.http.put(this.getBaseURL("actualizar-vehiculo"), intercambio)).then((r) => {
			return true
		})
	}

	public bloquearGrupo(grupoABloquear: GrupoPax): Promise<GrupoPax> {
		return lastValueFrom(this.http.put(this.getBaseURL("bloquearGrupo"), grupoABloquear)).then((r) => {
			return this.parseSingle(r)
		})
	}

	public desbloquearGrupo(grupoABloquear: GrupoPax): Promise<GrupoPax> {
		return lastValueFrom(this.http.put(this.getBaseURL("desbloquearGrupo"), grupoABloquear)).then((r) => {
			return this.parseSingle(r)
		})
	}

	public getReporteActividades(filtro: FiltroProducto, customLoading?: LoadingService): Promise<ReporteActividades[]> {
		let $this = this
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()

		return lastValueFrom(this.http.post(this.getBaseURL() + "reporte-actividades", filtro.json)).then(
			(r: any[]) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return r.map((rr) => ReporteActividades.fromData(rr))
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	getTotalesReporteActividades(filtro: FiltroProducto): Promise<TotalesActividad[]> {
		let grupos = []
		let vehiculos = []
		let totales: TotalesActividad[] = []
		return this.getReporteActividades(filtro).then((r) => {
			grupos = r

			if (grupos?.length) {
				grupos.forEach(async (grupo) => {
					let nuevo = false
					let t = totales.find((t) => t.productoId == grupo.productoId)
					if (!t) {
						t = new TotalesActividad()
						t.productoId = grupo.productoId
						nuevo = true
					}
					t.cantidadPax += grupo.cantidadPax

					let v = vehiculos.find((v) => v.grupoPax == grupo.grupoPaxId && v.producto == grupo.productoId)
					if (!v) {
						vehiculos.push({ producto: grupo.productoId, grupoPax: grupo.grupoPaxId })
						t.cantidadVehiculos++
					}

					if (nuevo) {
						totales.push(t)
					}
				})
			}
			if (filtro.productos?.length) {
				let productosSinDatos = filtro.productos.filter((p) => !totales.find((t) => t.productoId == p.id))

				if (productosSinDatos?.length) {
					productosSinDatos.forEach((p) => {
						totales.push(new TotalesActividad(null, p.id, 0, 0, 0, 0))
					})
				}
			}
			if (totales?.length) {
				totales.forEach((t) => {
					this.productoService.getById(t.productoId).then((p) => {
						t.producto = p
					})
					if (t.cantidadVehiculos) {
						t.promedioPax = Number((t.cantidadPax / t.cantidadVehiculos).toFixed(2))
						const d = moment(filtro.fechaHasta).diff(filtro.fechaDesde, "days")

						if (d) {
							t.promedioVehiculos = Number((t.cantidadVehiculos / d).toFixed(2))
						}
					}
				})
			}
			return totales
		})
	}
	public delegar(delegacion: Delegacion, customLoading?: LoadingService): Promise<GrupoPax> {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()

		return lastValueFrom(this.http.post(this.getBaseURL() + "delegar", delegacion)).then(
			(r: any) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return GrupoPax.fromData(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	completar(grupo: GrupoPax, customLoading?: LoadingService): Promise<GrupoPax> {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL() + "completar-actividad", grupo)).then(
			(r: any) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return GrupoPax.fromData(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	public getReporteServicios(filtro: FiltroServicios, customLoading?: LoadingService): Promise<ReporteServicios[]> {
		let $this = this
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()

		return lastValueFrom(this.http.post(this.getBaseURL() + "reporte-servicios", filtro.json))
			.then(
				(r: any[]) => {
					return r.map((rr) => ReporteServicios.fromData(rr))
				},
				(e) => this.handleError(e, customLoading)
			)
			.finally(() => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
			})
	}
}
