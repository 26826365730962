import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { Region } from "src/app/model/Region"
import { LoadingService } from "./../common/services/loading-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class RegionService extends ParametricoService<Region> {
	public baseName(): string {
		return "region"
	}
	public parseToEnt(data: any): Region {
		return Region.fromData(data)
	}
	public newEnt(): Region {
		return new Region()
	}

	constructor(public novedadesService: NovedadService, public authService: AuthService) {
		super(novedadesService, authService)
	}

	public actualizarDescripcion(data: Region, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		var p = this.http.put(this.getBaseURL("actualizar-descripcion"), data).toPromise()
		p.then((r) => {
			this.loadData(customLoading)
			if (customLoading) {
				customLoading.susLoadingCount()
			} else this.loadingService.susLoadingCount()
		})
		return p
	}
}
