import { Component } from "@angular/core"
import { Router } from "@angular/router"
import * as moment from "moment"
import { debounceTime, filter, first } from "rxjs/operators"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { ExcelDownloader } from "src/app/common/utils/excelDownloader"
import { ItemFacturaReporte } from "src/app/model/ItemFacturaReporte"
import { Totales } from "src/app/model/Totales"
import { TotalesImpuestos } from "src/app/model/TotalesImpuestos"
import { CategoriaPuestoService } from "src/app/services/categoria-puesto.service"
import { SessionComponent } from "./../../../common/components/session-component.component"
import { FiltroFactura } from "./../../../common/model/FiltroFactura"
import { MessagesService } from "./../../../common/services/messages-data-service.service"
import { FacturaResumen } from "./../../../model/FacturaResumen"
import { EstadoDocumentoService } from "./../../../services/estado-documento.service"
import { FacturaResumenService } from "./../../../services/factura-resumen.service"
import { ProveedorService } from "./../../../services/proveedor.service"
import { TipoComprobanteService } from "./../../../services/tipo-comprobante.service"

@Component({
	selector: "app-compras",
	templateUrl: "./compras.component.html",
	styleUrls: ["./compras.component.less"]
})
export class ComprasComponent extends SessionComponent {
	public filtro: FiltroFactura
	public tabActive = 0
	public facturas: FacturaResumen[] = []
	public columns = []
	public totalesMonedas: Totales[] = []
	public totalesImpuestos: TotalesImpuestos[] = []
	public items: ItemFacturaReporte[] = []
	constructor(
		messagesService: MessagesService,
		private facturaService: FacturaResumenService,
		public tipoComprobanteService: TipoComprobanteService,
		public estadosService: EstadoDocumentoService,
		public router: Router,
		public proveedorService: ProveedorService,
		public exportExcelService: ExcelDownloader,
		public categoriasInsumoService: CategoriaPuestoService
	) {
		super(messagesService)
	}
	public goToFactura(id) {
		this.router.navigate(["factura/vista"], { queryParams: { id: id } })
	}
	private updateRegistros: () => void

	public getFacturas() {
		this.columns = [
			{
				field: "fechaStr",
				header: "Fecha",
				class: "date-tabla",
				role: "date-tabla",
				sortable: true,
				esMobile: true,
				order: 40,
				width: "10em"
			},
			{
				field: "tipoComprobanteCodigo",
				header: "Tipo Comp.",
				class: "text-tabla",
				role: "text-tabla",
				sortable: true,
				esMobile: false,
				width: "4em",
				order: 40
			},
			{
				field: "numeroComprobante",
				header: "Número ",
				class: "text-tabla",
				role: "text-tabla",
				sortable: true,
				esMobile: true,
				width: "15em",
				order: 40
			},
			{
				field: "urlAdjunto",
				header: "Adjunto ",
				class: "download-tabla",
				role: "download-tabla",
				sortable: true,
				esMobile: true,
				width: "15em",
				order: 40
			},
			{
				field: "nombreProveedor",
				header: "Proveedor ",
				class: "text-tabla",
				role: "text-tabla",
				sortable: true,
				esMobile: true,
				width: "15em",
				order: 40
			},
			{
				field: "tipoDocumentoProveedorStr",
				header: "Tipo. Doc. ",
				class: "text-tabla text-ledt",
				role: "text-tabla",
				sortable: true,
				esMobile: true,
				width: "15em",
				downloadOnly: true,
				order: 40
			},
			{
				field: "cuitProveedor",
				header: "ID ",
				class: "text-tabla text-center",
				role: "text-tabla",
				sortable: true,
				esMobile: true,
				width: "15em",
				order: 40
			},
			{
				field: "monedaStr",
				header: "Moneda",
				downloadOnly: true
			},
			{
				field: "importeBruto",
				header: "I. Bruto ",
				class: "money-tabla",
				role: "money-tabla",
				order: 40
			},

			{
				field: "impuestos",
				header: "IVA ",
				class: "money-tabla",
				role: "money-tabla",
				order: 40
			},

			{
				field: "otrosImpuestos",
				header: "Otros impuestos ",
				class: "money-tabla",
				role: "money-tabla",
				order: 40
			},

			{
				field: "importe",
				header: "I. Neto",
				class: "money-tabla",
				role: "money-tabla",
				order: 40
			},
			{
				field: "cotizacion",
				header: "Cotización ",
				class: "money-tabla text-right",
				role: "money-tabla",
				downloadOnly: true,
				order: 40
			},
			{
				field: "monedaLocalStr",
				header: "Moneda Local",
				downloadOnly: true
			},
			{
				field: "importeBrutoLocal",
				header: "I. Bruto - Local",
				class: "money-tabla",
				role: "money-tabla",
				downloadOnly: true,
				order: 40
			},

			{
				field: "impuestosLocal",
				header: "IVA Local",
				class: "money-tabla",
				role: "money-tabla",
				downloadOnly: true,
				order: 40
			},

			{
				field: "otrosImpuestosLocal",
				header: "Otros impuestos Local",
				class: "money-tabla",
				role: "money-tabla",
				downloadOnly: true,
				order: 40
			},

			{
				field: "importeLocal",
				header: "I. Neto Local",
				class: "money-tabla",
				role: "money-tabla",
				downloadOnly: true,
				order: 40
			}
		]
		if (this.filtro) {
			this.facturaService.getAll(this.filtro, this.loading).then((c) => {
				this.facturas = c
			})
			this.facturaService.getTotales(this.filtro).then((c) => {
				this.totalesMonedas = c
			})
		}
	}
	public loading: LoadingService = new LoadingService()
	public getItems() {
		this.columns = [
			{
				field: "proveedor",
				header: "Proveedor",
				class: "descriptivo-tabla w-15rem",
				role: "descriptivo-tabla",
				sortable: true,
				esMobile: true,
				order: 40
			},
			{
				field: "numeroFactura",
				header: "Numero Factura ",
				class: "text-tabla",
				role: "text-tabla",
				sortable: true,
				esMobile: false,
				order: 40
			},

			{
				field: "tipoComprobanteCodigo",
				header: "T. Comp.",
				class: "descriptivo-tabla text-center",
				role: "descriptivo-tabla",
				sortable: true,
				esMobile: true,
				order: 40
			},
			{
				field: "fecha",
				header: "Fecha ",
				class: "date-tabla text-center",
				role: "date-tabla",
				sortable: true,
				esMobile: true,
				order: 40
			},
			{
				field: "monedaCodigo",
				header: "Moneda ",
				class: "descriptivo-tabla text-center",
				role: "descriptivo-tabla",
				sortable: true,
				esMobile: true,
				order: 40
			},
			{
				field: "tipoIVA",
				header: "IVA",
				class: "money-tabla text-center",
				role: "money-tabla",
				order: 40
			},
			{
				field: "insumo",
				header: "Insumo ",
				class: "descriptivo-tabla text-center",
				role: "descriptivo-tabla",
				order: 40
			},
			{
				field: "categoria",
				header: "Categoría ",
				class: "descriptivo-tabla text-center",
				role: "descriptivo-tabla",
				sortable: true,
				esMobile: false,
				order: 40
			},
			{
				field: "subcategoriaStr",
				header: "Subc. Imponible ",
				class: "descriptivo-tabla text-center",
				role: "descriptivo-tabla",
				sortable: true,
				esMobile: false,
				downloadOnly: true,
				order: 40
			},
			{
				field: "cantidad",
				header: "Cantidad ",
				class: "number-tabla text-center",
				role: "number-tabla",
				order: 40
			},
			{
				field: "precioUnitario",
				header: "Precio Unitario ",
				class: "money-tabla text-right",
				role: "money-tabla",
				order: 40
			},

			{
				field: "precioUnitarioBruto",
				header: "Precio Unitario Bruto ",
				class: "money-tabla text-right",
				role: "money-tabla",
				order: 40
			},

			{
				field: "impuestos",
				header: "Impuestos ",
				class: "money-tabla text-right",
				role: "money-tabla",
				order: 40
			},
			{
				field: "cotizacion",
				header: "Cotización ",
				class: "money-tabla text-right",
				role: "money-tabla",
				downloadOnly: true,
				order: 40
			},
			{
				field: "total",
				header: "Total ",
				class: "money-tabla text-right",
				role: "money-tabla",
				order: 40
			}
		]
		if (this.filtro) {
			this.facturaService.getItems(this.filtro, this.loading).then((c) => {
				this.items = c
				const impuestos = {}

				this.items.forEach((i) => {
					if (i.tipoIVA.codigo != "ZERO") {
						if (!impuestos[i.tipoIVA.codigo]) {
							impuestos[i.tipoIVA.codigo] = new TotalesImpuestos(i.tipoIVA, i.impuestos * i.cotizacion)
						} else impuestos[i.tipoIVA.codigo].total += i.impuestos * i.cotizacion
					}
				})
				this.totalesImpuestos = Object.values(impuestos) || []
			})
			this.facturaService.getTotales(this.filtro).then((c) => {
				this.totalesMonedas = c
			})
		}
	}
	ngOnInit() {
		if (!this.updateRegistros) {
			this.updateRegistros = this.getFacturas
		}
		this.filtro = new FiltroFactura("compras", null, 0, 0, "fecha", 1)
		this.filtro.onClean(async (f) => {
			f.setMultiple({
				size: 0,
				fechaDesde: moment().startOf("month").startOf("day").toDate(),
				fechaHasta: moment().endOf("month").endOf("day").toDate(),
				tiposComprobante: await this.tipoComprobanteService.getOficiales(),
				estados: await this.estadosService.getActivos()
			})
		})
		this.filtro.isReady
			.pipe(
				filter((f) => f == true),
				first()
			)
			.subscribe((f) => {
				this.filtro.clean()
				this.filtro.dataChange.pipe(debounceTime(500)).subscribe((data) => {
					this.updateRegistros()
				})
			})
	}
	exportExcel() {
		this.exportExcelService.downloadData(this.facturas, "facturas_compras", { clienteStr: true }, this.columns)
	}
	exportExcelItems() {
		this.exportExcelService.downloadData(this.items, "items_compras", { clienteStr: true }, this.columns)
	}
	onChange(event) {
		this.tabActive = event.index
		if (this.tabActive == 0) {
			this.updateRegistros = this.getFacturas
		} else {
			this.updateRegistros = this.getItems
		}
		this.updateRegistros()
	}
}
