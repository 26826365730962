import { Component, forwardRef, Input, OnInit } from "@angular/core"
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms"
import { Router } from "@angular/router"
import { ItemReserva } from "src/app/model/ItemReserva"
import { ResumenReserva } from "src/app/model/ResumenReserva"
import { ReservaService } from "./../../../services/reserva.service"

@Component({
	selector: "detalle-actividad",
	templateUrl: "./detalle-actividad.component.html",
	styleUrls: ["./detalle-actividad.component.less"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DetalleActividadComponent),
			multi: true
		},
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => DetalleActividadComponent), multi: true }
	]
})
export class DetalleActividadComponent implements OnInit, ControlValueAccessor, Validator {
	contacto

	private _itemEditado: ItemReserva
	mostrarVoucher: boolean = false
	public get itemEditado(): ItemReserva {
		return this._itemEditado
	}
	public descripcionServicio: string
	@Input()
	public set itemEditado(v: ItemReserva) {
		this._itemEditado = v
		this.descripcionServicio = v?.producto?.descripcion
		this.onChangeCallback(v)

		if (this._itemEditado?.idReserva) {
			this.reservaService.getResumenById(this._itemEditado.idReserva).then((r: ResumenReserva) => {
				this.contacto = {
					email: r.emailContacto,
					telefono: r.telefonoContacto,
					whatsapp: r.telefonoContacto
				}
			})
		}
	}
	@Input()
	public verDetalle: boolean = false
	public disabled: boolean = false

	@Input()
	public editable: boolean = false

	ngOnInit() {}
	constructor(private router: Router, private reservaService: ReservaService) {}
	private onChangeCallback: (_: any) => void = () => {}
	private onTouchedCallback: (_: any) => void = () => {}
	registerOnValidatorChange?(fn: () => void): void {}
	writeValue(obj: any): void {
		this.itemEditado = obj
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled
	}
	validate(control: AbstractControl): ValidationErrors {
		return control ? control.errors : null
	}

	public irAReserva(item: ItemReserva) {
		this.router.navigate(["reserva/edit"], { queryParams: { id: item.idReserva } })
	}

	verVoucher(event: Event) {
		// event.stopPropagation()
		this.mostrarVoucher = true
	}
}
