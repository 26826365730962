import { AdministracionAuthGuard } from './AdministracionAuthGuard';
import { Injectable } from '@angular/core';
import { AdminAuthGuard } from './AdminAuthGuard';

@Injectable({
    providedIn: 'root'
})
export class PuntoDeVentaAG extends AdministracionAuthGuard {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_PUNTO_VENTA"]) };



}