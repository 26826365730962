import { Usuario } from "../common/model/Usuario";
import { UsuarioResumen } from "../common/model/resumen/UsuarioResumen";
import { TagUsuario } from "./TagUsuario"

export class Destinatario {
    public static TAG:string="Tag";
    public static EMAIL:string="Email";
    public static USUARIO:string="Usuario";
    public static TODOS:string="Todos";
    public static ALL=new Destinatario(Destinatario.TAG,Destinatario.TODOS,Destinatario.TODOS);
    
    public static tag = (u:TagUsuario)=>{
        return new Destinatario(Destinatario.TAG, u.descripcion, u.descripcion);
    }
    public static email = (e:string)=>{
        return new Destinatario(Destinatario.EMAIL, e, e);
    }
    public static usuario = (u:Usuario|UsuarioResumen)=>{
        return new Destinatario(Destinatario.USUARIO, u.email,u.username);
    }
    constructor(public tipo:string, public destinatario:string, public descripcion:string){

    }
}