
import { Parametrico } from 'src/app/common/model/Parametrico';


export class TipoMovimiento extends Parametrico {
    constructor(id?: number, codigo?: string, descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,
        

    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso)
    }
    public tipoParametro(): string {
        return "TipoMovimientoLOW";
    }
    public static fromData(data: any): TipoMovimiento {
        if (!data) return null;
        const o: TipoMovimiento = new TipoMovimiento(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,

        );

        o.version = data.version; 
return o;

    }

}