import { Descriptivo } from "src/app/common/model/Descriptivo"
import { CategoriaProducto } from "src/app/model/CategoriaProducto"
import { EncuestaActividades } from "src/app/model/EncuestaActividades"
import { TipoVariante } from "src/app/model/TipoVariante"
import { IConSubcategoriasImponibles } from "../common-components/abm-subcategorias-imponibles/IConSubcategoriasImponibles"
import { ProfilePic } from "./../common/model/ProfilePic"
import { FamiliaProducto } from "./FamiliaProducto"
import { Idioma } from "./Idioma"
import { Producto } from "./Producto"
import { ProductoAsociado } from "./ProductoAsociado"
import { ProductoInsumo } from "./ProductoInsumo"
import { PuestoACubrir } from "./PuestoACubrir"
import { Region } from "./Region"
import { SubcategoriaImponibleItem } from "./SubcategoriaImponibleItem"
import { TipoIVAGrabado } from "./TipoIVAGrabado"
import { TipoProducto } from "./TipoProducto"
import { VehiculoRequerido } from "./VehiculoRequerido"

export class Transfer extends Producto implements IConSubcategoriasImponibles {
	public static NOTA: string = "$NOTA$"

	public static TIPO: string = TipoProducto.TRANSFER_SHORT

	public static esMismaFamilia(producto1, producto2): boolean {
		return producto1?.familiaProducto ? producto2.familiaProducto?.id === producto1?.familiaProducto?.id : producto2.id === producto1.id
	}

	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado?: boolean,
		tipoProducto?: Descriptivo,
		categorias: CategoriaProducto[] = [],
		peso?: number,
		fechaVigencia?: Date,
		duracion?: number,
		puestosACubrir: PuestoACubrir[] = [],
		vehiculosACubrir: VehiculoRequerido[] = [],
		basePersonas: number = 1,
		cantidad: number = 1,
		insumos: ProductoInsumo[] = [],
		productosAsociados: ProductoAsociado[] = [],
		adicionable: boolean = true,
		esDivisible?: boolean,
		esPropio?: boolean,
		cantidadMaxGrupo: number = 6,
		icono?: ProfilePic,
		detalle?: string,
		codigoIdioma: string = Idioma.DEFAULT_CODIGO,
		tipoIVA?: TipoIVAGrabado,
		tipoRecordatorio?: Descriptivo,
		requiereConsentimiento: boolean = false,
		nombreComercial?: string,
		encuesta?: EncuestaActividades,
		tipoProductoDesc: string = Transfer.TIPO,
		horaInicio?: Date,
		familiaProducto?: FamiliaProducto,
		tipoVariante?: TipoVariante,
		lastModifiedBy?: string,
		lastModifiedDate?: Date,
		clonado?: number,
		notificaHotel: boolean = true,
		esSubcategorizado: boolean = false,
		subcategorias: SubcategoriaImponibleItem[] = [],
		regiones: Region[] = []
	) {
		super(
			id,
			codigo,
			descripcion,
			habilitado,
			tipoProducto,
			categorias,
			peso,
			fechaVigencia,
			duracion,
			puestosACubrir,
			vehiculosACubrir,
			basePersonas,
			cantidad,
			insumos,
			productosAsociados,
			adicionable,
			esDivisible,
			esPropio,
			cantidadMaxGrupo,
			icono,
			detalle,
			codigoIdioma,
			tipoIVA,
			tipoRecordatorio,
			requiereConsentimiento,
			nombreComercial,
			encuesta,
			tipoProductoDesc,
			horaInicio,
			familiaProducto,
			tipoVariante,
			lastModifiedBy,
			lastModifiedDate,
			clonado,
			notificaHotel,
			esSubcategorizado,
			subcategorias,
			regiones
		)
	}
	getAgrupado(): boolean {
		return this.familiaProducto?.agrupado || false
	}
	public static fromData(data: any): Transfer {
		if (!data) return null
		const o: Transfer = new Transfer(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			Descriptivo.fromData(data.tipoProducto),
			data.categorias ? data.categorias.map((c) => CategoriaProducto.fromData(c)) : [],
			data.peso,
			data.fechaVigencia ? new Date(data.fechaVigencia) : null,
			data.duracion,
			data.puestosACubrir?.map((c) => PuestoACubrir.fromData(c)) || [],
			data.vehiculosACubrir?.map((c) => VehiculoRequerido.fromData(c)) || [],
			data.basePersonas,
			data.cantidad,
			data.insumos?.map((c) => ProductoInsumo.fromData(c)) || [],
			data.productosAsociados?.map((c) => ProductoAsociado.fromData(c)) || [],
			data.adicionable,
			data.esDivisible,
			data.esPropio,
			data.cantidadMaxGrupo,
			ProfilePic.fromData(data.icono),
			data.detalle,
			data.codigoIdioma,
			TipoIVAGrabado.fromData(data.tipoIVA),
			Descriptivo.fromData(data.tipoRecordatorio),
			data.requiereConsentimiento,
			data.nombreComercial,
			EncuestaActividades.fromData(data.encuesta),
			Transfer.TIPO,
			data.horaInicio ? new Date(data.horaInicio) : null,
			data.familiaProducto ? FamiliaProducto.fromData(data.familiaProducto) : null,
			data.tipoVariante ? TipoVariante.fromData(data.tipoVariante) : null,
			data.lastModifiedBy,
			data.lastModifiedDate ? new Date(data.lastModifiedDate) : null,
			data.clonado,
			data.notificaHotel,
			data.esSubcategorizado,
			data.subcategorias?.map((s) => SubcategoriaImponibleItem.fromData(s)) || [],
			data.regiones?.map((s) => Region.fromData(s)) || []
		)
		o.version = data.version
		return o
	}
	public quitarItem(item: any) {
		this.subcategorias = this.subcategorias.filter((i) => i != item)
	}
	public agregarItem(item: any) {
		if (this.subcategorias.indexOf(item) < 0) {
			this.subcategorias = [...this.subcategorias, item]
		}
	}
	public newItem() {
		const s = new SubcategoriaImponibleItem()
		s.proporcion = 100 - this.subcategorias.reduce((a, b) => (a += b.proporcion), 0)
		return s
	}

	public get resumenImpuestos() {
		if (!this.esSubcategorizado) return this.tipoIVA?.descripcion || "IVA Sin definir"
		return this.subcategorias.map((s) => s.subcategoriaImponible?.descripcion + " (" + s.tipoIVA?.descripcion + ") " + s.proporcion + "%").join(", ")
	}

	public clonar(): Transfer {
		var p = Transfer.fromData(this)
		p.id = null
		p.puestosACubrir.forEach((c) => {
			c.id = null
			;(c.producto = null), c.insumos.forEach((i) => (i.id = null))
		})
		p.vehiculosACubrir.forEach((v) => {
			v.id = null
			v.insumos.forEach((i) => (i.id = null))
		})
		p.insumos.forEach((i) => (i.id = null))
		p.productosAsociados.forEach((pa) => (pa.id = null))
		p.codigo = null
		p.esPropio = this.esPropio
		p.familiaProducto = this.familiaProducto
		p.tipoVariante = this.tipoVariante
		p.tipoProductoDesc = this.tipoProductoDesc
		p.requiereConsentimiento = this.requiereConsentimiento
		p.tipoRecordatorio = this.tipoRecordatorio
		p.encuesta = this.encuesta
		p.regiones = this.regiones
		p.esSubcategorizado = this.esSubcategorizado
		p.subcategorias = this.subcategorias.map((s) => {
			const a = SubcategoriaImponibleItem.fromData(s)
			a.id = null
			return a
		})
		p.notificaHotel = this.notificaHotel
		return p
	}
}
