import { ServicioAbstract } from 'src/app/common/services/service.service'
import { SerieAusencia } from './../model/SerieAusencia'
import { BaseService } from 'src/app/common/services/base.service'
import { Injectable } from '@angular/core'
import { LoadingService } from '../common/services/loading-data-service.service'
import { Ausencia } from '../model/Ausencia'

@Injectable({
    providedIn: 'root',
})
export class SerieAusenciaService extends ServicioAbstract<SerieAusencia> {

    constructor() {
        super()
    }
    cancelarRecurrencia(ausencia: Ausencia, customLoading?: LoadingService) {
        let $this = this
        if (customLoading) customLoading.addLoadingCount()
        else this.loadingService.addLoadingCount()
        return this.http
            .post(this.getBaseURL() + "cancelarRecurrencia", ausencia)
            .toPromise()
            .then(
                (r) => {
                    if (customLoading) customLoading.susLoadingCount()
                    else this.loadingService.susLoadingCount()
                    return this.parseSingle(r)
                },
                (e) => this.handleError(e, customLoading)
            )    
    
    
    
    }
    // guardar(s: SerieAusencia, customLoading?: LoadingService): Promise<SerieAusencia> {
    //     let $this = this
    //     if (customLoading) customLoading.addLoadingCount()
    //     else this.loadingService.addLoadingCount()
    //     return this.http
    //         .post(this.getBaseURL(), s)
    //         .toPromise()
    //         .then(
    //             (r) => {
    //                 if (customLoading) customLoading.susLoadingCount()
    //                 else this.loadingService.susLoadingCount()
    //                 return this.parseSingle(r)
    //             },
    //             (e) => this.handleError(e, customLoading)
    //         )
    // }
    
    public parseToEnt(data: any): SerieAusencia {
        return SerieAusencia.fromData(data)
    }
    public newEnt(): SerieAusencia {
        return new SerieAusencia()
    }
    public baseName(): string {
        return 'serie-ausencia'
    }

    eliminar(id: number, customLoading?: LoadingService) {

        if (customLoading) { customLoading.addLoadingCount(); }
        else this.loadingService.addLoadingCount()
        return this.http.delete(this.getBaseURL() + id)
            .toPromise()
            .then((r) => {
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
                return r
            });
    }
}
