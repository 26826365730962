<div class="gestor-subcategoria-imponible content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'SubcategoriaImponible' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="SubcategoriaImponibleForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #SubcategoriaImponibleForm="ngForm" id="SubcategoriaImponibleform">
				<div class="grid" *ngIf="item">
					<div class="col-fixed col-codigo field">
						<span class="p-float-label">
							<input pInputText [(ngModel)]="item.codigo" type="text" #codigo="ngModel" name="codigo" [readonly]="readonly" />
							<label>{{ "CODIGO" | translate }}</label>
							<error-tag [model]="$CODIGO"></error-tag>
						</span>
					</div>
					<div class="col-fixed col-codigo p-fluid field">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'PESO_PH' | translate }}"
								[(ngModel)]="item.peso"
								pTooltip="{{ 'PESO_TOOLTIP' | translate }}"
								type="number"
								#peso="ngModel"
								name="peso"
								[readonly]="readonly"
							/>
							<label>{{ "PESO" | translate }}</label>
							<error-tag [model]="peso"></error-tag>
						</span>
					</div>
					<div class="col-12 lg:col-6">
						<span class="p-float-label">
							<input pInputText [(ngModel)]="item.descripcion" type="text" #descripcion="ngModel" name="descripcion" [readonly]="readonly" />
							<label>{{ "DESCRIPCION" | translate }}</label>
							<error-tag [model]="$DESCRIPCION"></error-tag>
						</span>
					</div>
					<div class="col-12 lg:col-4">
						<parametrico-selector
							[service]="tipoIVAGrabadoService"
							#tipoIVA="ngModel"
							[(ngModel)]="item.tipoIVA"
							[limpiable]="true"
							[permiteNuevo]="true"
							name="tipo-iva-grabado"
							[titulo]="'TIPOIVA' | translate"
							[readonly]="readonly"
						>
						</parametrico-selector>
					</div>

					<div class="col-12 lg:col-4 checkbox-container">
						<p-toggleButton
							onLabel="{{ 'HABILITADO' | translate }}"
							offLabel="{{ 'HABILITADO' | translate }}"
							iconPos="left"
							name="habilitado"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[disabled]="readonly"
							[(ngModel)]="item.habilitado"
						></p-toggleButton>
					</div>

					<div class="col-12 lg:col-4 checkbox-container">
						<p-toggleButton
							onLabel="{{ 'ESDEFAULT' | translate }}"
							offLabel="{{ 'ESDEFAULT' | translate }}"
							iconPos="left"
							name="esDefault"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[disabled]="readonly"
							[(ngModel)]="item.esDefault"
						></p-toggleButton>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
