import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Filtro } from "../common/model/Filtro"
export class FiltroEmailTemplate extends Filtro {
	constructor(
		name: string,
		page?: number,
		size?: number,
		sortField: string = "descripcion",
		order: number = 1,
		guardable: boolean = true,
		localSearch: boolean = false
	) {
		super(
			name,
			{
				punto: "Punto de Venta"
			},
			page,
			size,
			sortField,
			order,
			guardable,
			localSearch
		)
	}
	private _puntoDeVenta: Descriptivo

	public get puntoDeVenta(): Descriptivo {
		return this._puntoDeVenta
	}
	public set puntoDeVenta(v: Descriptivo) {
		if (v != this._puntoDeVenta) {
			this._puntoDeVenta = v
			this.update(true)
		}
	}

	public clean() {
		super.clean(false)
		let cleanVal = {
			puntoDeVenta: null
		}
		this.patchValue(cleanVal)
	}

	public patchValue(v: any) {
		super.patchValue(v, false)
		this._searchStr = v.searchStr
		this._puntoDeVenta = v.puntoDeVenta ? v.puntoDeVenta : null
		this.update()
	}

	public apply(data: any[]): any[] {
		const searchLike = this.searchStr ? this.searchStr.toUpperCase().split(" ") : null
		return data.filter(
			(value) =>
				(!searchLike || !value["descripcion"] || searchLike.every((v) => value["descripcion"].toUpperCase().includes(v))) &&
				(!this.puntoDeVenta || (value["puntoDeVenta"] && value["puntoDeVenta"].id == this.puntoDeVenta?.id))
		)
	}
}
