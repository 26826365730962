import * as moment from "moment"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { EstadoPuesto } from "src/app/model/EstadoPuesto"
import { Proveedor } from "src/app/model/Proveedor"
import { PuestoACubrir } from "src/app/model/PuestoACubrir"
export class AprobacionProveedor {
	public fechaActividadStr: string
	public searchStr: string = ""
	constructor(
		public id?: number,
		public idReserva?: number,
		public nombrePax?: string,
		public idPuesto?: number,
		public personal?: Proveedor,
		public puesto?: PuestoACubrir,
		public fechaActividad?: Date,
		public producto?: Descriptivo,
		public cantidadPax?: number,
		public estado?: EstadoPuesto,
		public motivoRechazo?: string,
		public idVoucher?: number,
		public confirmadoPor?: string,
		public modificado?: boolean,
		public modificacion: any = {},
		public observaciones?: string,
		public checkIn?: Date,
		public checkOut?: Date
	) {
		this.searchStr = this.idReserva + "/" + this.idVoucher + "/" + this.nombrePax + "/" + this.observaciones
	}
	public get descripcion() {
		return this.producto?.descripcion + " x " + this.cantidadPax
	}
	public static fromData(data): AprobacionProveedor {
		let a = new AprobacionProveedor()
		a.id = data.idPuesto
		a.idReserva = data.idReserva
		a.nombrePax = data.nombrePax
		a.idPuesto = data.idPuesto
		a.personal = Proveedor.fromData(data.personal)
		a.puesto = PuestoACubrir.fromData(data.puesto)
		a.fechaActividad = data.fechaActividad ? new Date(data.fechaActividad) : null
		a.producto = Descriptivo.fromData(data.producto)
		a.cantidadPax = data.cantidadPax
		a.estado = EstadoPuesto.fromData(data.estado)
		a.motivoRechazo = data.motivoRechazo
		a.idVoucher = data.idVoucher
		a.confirmadoPor = data.confirmadoPor
		a.fechaActividadStr = moment(a.fechaActividad).format("DD/MM/YYYY HH:mm")
		a.modificado = data.modificado
		a.modificacion = data.modificacion || {}
		a.observaciones = data.observaciones
		a.searchStr = a.idReserva + "/" + a.idVoucher + "/" + a.nombrePax + "/" + a.observaciones

		return a
	}

	get checkinCheckoutStr() {
		return moment(this.checkIn).format("DD/MM/YYYY") + " - " + moment(this.checkOut).format("DD/MM/YYYY")
	}
}
