import { CanalComunicacion } from "src/app/model/CanalComunicacion"
import { PuntoDeVenta } from "./PuntoDeVenta"

export class CanalComunicacionEmail extends CanalComunicacion {
	public static TIPO = "E"

	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado?: boolean,
		esSistema?: boolean,
		peso?: number,
		esDefault?: boolean,
		public username?: string,
		public port?: number,
		public password?: string,
		public host?: string,
		public smtpAuth?: boolean,
		public startTLS?: boolean,
		puntoDeVenta?: PuntoDeVenta,
		lastModifiedBy?: string,
		lastModifiedDate?: Date,
		public protocol?: string,
		public useSocketFactory?: boolean
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso, esDefault, CanalComunicacionEmail.TIPO, puntoDeVenta, lastModifiedBy, lastModifiedDate)
	}
	public static fromData(data: any): CanalComunicacionEmail {
		if (!data) return null
		const o: CanalComunicacionEmail = CanalComunicacion.fromData(data)

		o.username = data.username
		o.port = data.port
		o.password = data.password
		o.host = data.host
		o.smtpAuth = data.smtpAuth
		o.startTLS = data.startTLS
		o.protocol = data.protocol
		o.useSocketFactory = data.useSocketFactory

		return o
	}
}
