import moment from "moment"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Destinatario } from "./Destinatario"

export class Recordatorio {
	clonar(): any {
		let o = new Recordatorio()
		Object.assign(o, this)
		o.id = null
		o.idEntidad = null
		o.referencia = null
	}
	public static NOMBRE_ENTIDAD = "Recordatorio"
	constructor(
		public id?: number,
		public version?: number,
		public tipo?: Descriptivo,
		public cantidad?: number,
		public unidad?: string,
		public referencia?: string,
		public fechaBase?: Date,
		public cabecera?: string,
		public body?: string,
		public tipoEntidad?: string,
		public idEntidad?: number,
		public destinatarios: Destinatario[] = [],
		public fechaProgramada?: Date,
		public esFechaFija: boolean = false
	) {
		if (this.fechaProgramada || !this.fechaBase) this.esFechaFija = true
	}
	public static fromData(data: any): Recordatorio {
		if (!data) return null
		const o: Recordatorio = new Recordatorio(
			data.id,
			data.version,
			Descriptivo.fromData(data.tipo),
			data.cantidad,
			data.unidad,
			data.referencia,
			data.fechaBase ? new Date(data.fechaBase) : null,
			data.cabecera,
			data.body,
			data.tipoEntidad,
			data.idEntidad,
			data.destinatarios,
			data.fechaProgramada ? new Date(data.fechaProgramada) : null,
			data.esFechaFija
		)

		return o
	}

	get fecha() {
		if (this.fechaProgramada) return this.fechaProgramada
		let unidad

		switch (this.unidad) {
			case "mm":
				unidad = "minutes"
				break
			case "d":
				unidad = "days"
				break
			case "h":
				unidad = "hours"
				break
			default:
				break
		}

		return moment(this.fechaBase).startOf("day").subtract(this.cantidad, unidad).toDate()
	}
}
