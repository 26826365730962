import { FormaPago } from "src/app/model/FormaPago"
import { Moneda } from "src/app/model/Moneda"
import { Cobro } from "src/app/model/cobros/Cobro"
import { TipoComprobante } from "../TipoComprobante"

export class CobroNotaCredito extends Cobro {
	public static TIPO = "N"

	constructor(
		id?: number,
		formaPago?: FormaPago,
		comprobante?: string,
		monto?: number,
		moneda?: Moneda,
		cotizacion: number = 1,
		public tipoComprobante?: TipoComprobante,
		public notaGenerada?: number,
		public esInterna?: boolean
	) {
		super(id, formaPago, comprobante, monto, moneda, cotizacion)
		this.tipoCobro = CobroNotaCredito.TIPO
	}

	public static fromData(data: any): CobroNotaCredito {
		if (!data) return null
		const o: CobroNotaCredito = new CobroNotaCredito(
			data.id,
			FormaPago.fromData(data.formaPago),
			data.comprobante,
			data.monto,
			Moneda.fromData(data.moneda),
			data.cotizacion,
			TipoComprobante.fromData(data.tipoComprobante),
			data.notaGenerada,
			data.esInterna
		)

		return o
	}
}
