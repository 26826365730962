import * as moment from 'moment'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { Filtro } from 'src/app/common/model/Filtro'
export class FiltroRespuestaEncuesta extends Filtro {
    private _productos: Descriptivo[] = []
    public get productos(): Descriptivo[] {
        return this._productos
    }
    public set productos(v: Descriptivo[]) {
        this._productos = v
        this.update(true)
    }

    private _fecha: Date = moment().subtract(1,'month').startOf('day').toDate()
    public get fecha(): Date {
        return this._fecha
    }
    public set fecha(v: Date) {
        if (v == this._fecha) return
        this._fecha = v ? moment(v).startOf('day').toDate() : null
        this.update(true)
    }

    private _fechaHasta: Date = moment().endOf('day').toDate()
    public get fechaHasta(): Date {
        return this._fechaHasta
    }
    public set fechaHasta(v: Date) {
        if (v == this._fechaHasta) return
        this._fechaHasta = v ? moment(v).endOf('day').toDate() : null
        this.update(true)
    }

    private _estados: Descriptivo[] = []
    public get estados(): Descriptivo[] {
        return this._estados
    }
    public set estados(v: Descriptivo[]) {
        if (v == this._estados) return
        this._estados = v
        this.update(true)
    }
    public tieneEstado(estado: Descriptivo) {
        return this.estados?.some(e => e.codigo == estado.codigo);
    }
    public toggleEstado(estado: Descriptivo) {
        if (this.tieneEstado(estado)) {
            this.estados = this.estados.filter(e => e.codigo != estado.codigo);
        } else {
            this.estados.push(estado);
        }
        this.update(true);
    }
    
    private _idItemReserva : number;
    public get idItemReserva() : number {
        return this._idItemReserva;
    }
    public set idItemReserva(v : number) {
        if (v == this._idItemReserva) return
        this._idItemReserva = v;
        this.update(true)
    }
    

    public clean() {
        super.clean(false)
        this.patchValue({
            searchStr: '',
            fecha: null,
            fechaHasta: null,
            productos: [],
            estado: null,
        })
    }
    public patchValue(v: any) { 
        super.patchValue(v, false)
        this._fecha = v.fecha ? new Date(v.fecha) : moment().startOf('day').toDate()
        this._fechaHasta = v.fechaHasta ? new Date(v.fechaHasta) : moment().startOf('day').toDate()
        this._productos = v.productos ? v.productos.map((p) => Descriptivo.fromData(p)) : []
        this.guardable = v.guardable
        this.estados = v.estados
        this.update(true)
    }
    public valid(): boolean {
        return true
    }
    constructor(name, page: number = 0, size: number = 100) {
        super(
            name,
            {
                fecha: 'Fecha desde',
                fechaHasta: 'Hasta',
                proveedor: 'Proveedor',
                productos: 'Productos',
            },
            page,
            size,
            "id",
            2,
            true
        )
        this.layout = 'L'
    }
}
