import { Parametrico } from './../common/model/Parametrico';


export class TipoCliente extends Parametrico {
    constructor(id?: number, codigo?: string, descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,


    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso)
    }
    public tipoParametro(): string {
        return "tipo-cliente";
    }
    public static fromData(data: any): TipoCliente {
        if (!data) return null;
        let o: TipoCliente = new TipoCliente(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,

        );

        o.version = data.version; 
return o;

    }

}