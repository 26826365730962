<model-help [title]="'Carga masiva de proveedores y/o clientes'">
	<p>
		La carga masiva de datos está disponible para proveedores y/o clientes mediante un archivo de tipo csv, que se puede generar fácilmente desde un archivo
		excel o una planilla de cálculo de google docs. Se brindan dos modelos de planilla (para clientes y para proveedores) que el usuario podrá manipular y
		completar para luego realizar el proceso de subida al sistema.
	</p>

	<h3>1 - Acceso al archivo modelo</h3>
	<p><strong>Links: </strong></p>
	<p>
		<a target="_blank" href="https://docs.google.com/spreadsheets/d/1kysv-Sw5UgCVqHml41_XYf7onCoR2YY1A7gIYYklX7w/edit#gid=0"
			>Archivo modelo para carga de proveedores</a
		>
	</p>
	<p>
		<a target="_blank" href="https://docs.google.com/spreadsheets/d/1LrxCs-BbzU-t55n9-4YrDBga-MGqf5TSlbFNVEf5LHs/edit#gid=0"
			>Archivo modelo para carga de clientes</a
		>
	</p>
	<p>
		<a target="_blank" href="https://docs.google.com/spreadsheets/d/12lIyVamba_E8b-el6onFMRaFEeUgUidkFSpRBmHNrhM/edit?usp=sharing"
			>Archivo modelo para carga de Lista de precios</a
		>
	</p>
	<p>
		<a target="_blank" href="https://docs.google.com/spreadsheets/d/1PhL5u4aU5CLl-296jVbrVr4TvJhzBm7KsbBhGp7G2I4/edit#gid=215726578"
			>Archivo modelo para carga de Productos y Servicios</a
		>
	</p>

	<p>
		Al hacer click en alguno de los links anteriores tendrá acceso a una hoja de cálculo de google docs en modo <strong>Solo Lectura</strong>, para poder
		editarla y completarla con datos deberá seguir los siguientes pasos: Ir a <strong>Archivo -> Crear una copia</strong><br /><br />
		<img src="assets/images/help/carga-1.jpg" /><br /><br />
	</p>

	<p>
		Se nos abrirá una pantalla de diálogo donde podremos cambiar el nombre del archivo y elegir la ubicación de la copia que realizaremos. (Generalmente lo
		hará en alguna ubicación de "Mi unidad" y desde allí podrá trabajar sobre el archivo o descargarlo a su pc en formato de excel o el que prefiera.)
	</p>
	<p>Luego de seleccionar el nombre y destino <strong>Guardar copia</strong></p>
	<h3>2 - Instrucciones para completar datos</h3>
	<p>
		Una vez abierto el archivo nos encontraremos con la planilla con los encabezados ya definidos en cada columna, en la parte inferior tenemos acceso a una
		segunda página del documento
		<strong>Estructura</strong>; en esta página encontraremos las indicaciones que deberemos seguir en cuanto al formato de datos a insertar en cada columna
		de la página de <strong>Datos</strong>.<br />
		También en la página de estructura nos indicará que columnas son requeridas (que deben estar completadas obligatoriamente) para poder procesar la carga.
	</p>
	<img src="assets/images/help/carga-3.jpg" /><br />

	<h3>3 - Exportacion de archivo en formato csv</h3>
	Una vez completado el documento con todos los datos necesarios y cumpliendo con los datos requeridos y los formatos especificados, se puede proceder a la
	carga del mismo.<br />
	Como primera instancia exportaremos o descargaremos el archivo en formato csv separados por coma. Ir a
	<strong>Archivo -> Descargar -> Valores separados por coma (csv)</strong><br /><br />
	<img src="assets/images/help/carga-5.jpg" /><br /><br />
	Tener en cuenta en que carpeta de nuestra PC se descarga (generalmente en "descargas" ó "downloads")

	<h3>4 - Carga del archivo en sistema</h3>
	<p>
		Existen 2 maneras de cargar el archivo a sistema: Una es directamente por la pantalla de
		<help-link [linkFinal]="'cliente'" [textoLink]="'Clientes'"></help-link> ó de
		<help-link [linkFinal]="'proveedor'" [textoLink]="'Proveedores'"></help-link>, según corresponda. <br />En la parte superior del listado correspondiente
		haciendo click en el ícono <i class="pi pi-upload"></i> y seleccionando el archivo alojado en nuestra PC.<br />
		Otra manera es ingresando por configuración mediante el ícono en la parte superior derecha de la pantalla de sistema <i class="pi pi-cog"></i>
		<strong>-> Configuración -> Carga Masiva.</strong>
	</p>
	<p>
		En dicha pantalla podemos dar click en <i class="pi pi-plus"></i> para agregar una nueva carga masiva, en la pantalla emergente elegimos el tipo de
		archivo y cargamos el mismo haciendo click en <i class="pi pi-upload"></i> y seleccionando el archivo alojado en nuestra PC.
	</p>

	<h3>5 - Checkear proceso y errores</h3>
	<p>
		Una vez realizado el paso anterior, comienza a ejecutarse el proceso en segundo plano. Es decir que podremos seguir utilizando el sistema normalmente.
		<br />
		Dependiendo de la cantidad de clientes / proveedores que estemos cargando el proceso puede demorar varios minutos.<br />
		Para checkear el estado del proceso accedemos a la pantalla de Carga Masiva y nos encontraremos con el siguiente listado.
	</p>
	<img src="assets/images/help/carga-6.jpg" /><br />
	En el podemos observar los procesos realizados y en proceso.<br />
	Los estados posibles son:<br />
	<ul>
		<li><strong>Pendiente de procesar:</strong> Aún no se inició el proceso, pero iniciará automáticamente pronto.</li>
		<li><strong>En proceso:</strong> Que esta siendo procesado actualmente.</li>
		<li><strong>Error al intentar procesar:</strong> Se intentó procesar el archivo pero falló. No se insertó en sistema ningún dato.</li>
		<li>
			<strong>Finalizado con errores:</strong> Se procesó el archivo pero uno o varios de los registros generó error. El sistema insertó los registros
			válidos pero obvió los erroneos.
		</li>
		<li><strong>Finalizado sin errores: </strong> La carga se realizó con total éxito.</li>
	</ul>

	<p>
		Para ver los estados actualizados por favor utilice el botón con el ícono de <i class="pi pi-refresh"></i> ubicado en la parte superior del listado para
		refrescar la pantalla.<br />
		También puede hacerlo mediante la tecla F5.
	</p>

	<p>
		<strong>Importante: </strong> En todos los casos podemos hacer click en el ícono de <i class="fa fa-eye"></i> ubicado a la derecha del listado de "Carga
		Masiva" para obtener mayor información de proceso y el estado. <br />
		en el caso de "Finalizado con errores" podremos ver que registro y que campos generó el error y de esa manera corregir y volver a subir
		<strong>Solo ese registro</strong> nuevamente.
	</p>
</model-help>
