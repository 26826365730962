<div class="detalle-actividad">
	<form #nuevoServicioForm="ngForm" id="nuevoServicioForm">
		<div class="grid" *ngIf="itemEditado">
			<div class="lg:col-6 col-12">
				<span class="p-float-label">
					<input pInputText [(ngModel)]="itemEditado.nombrePax" name="nombrePax" readonly />
					<label>{{ "NOMBREPAX" | translate }}</label>
				</span>
			</div>
			<div class="lg:col-4 col-6">
				<span class="p-float-label pt-3">
					<span class="p-inputwrapper-filled text-center">{{ itemEditado.hotel?.descripcion }} - {{ itemEditado.direccion }}</span>
					<label>{{ "HOTEL" | translate }}</label>
				</span>
			</div>
			<div class="lg:col-2 col-6">
				<button pButton label="{{ 'IR_A_RESERVA' | translate }}" (click)="irAReserva(itemEditado)" class="big-button"></button>
				<button
					class="big-button voucher-button"
					pButton
					icon="fa fa-qrcode"
					(click)="verVoucher($event)"
					tooltipPosition="bottom"
					pTooltip="{{ 'VER_VOUCHER' | translate }}"
				></button>
			</div>
			<div class="col-6 lg:col-3">
				<span class="p-float-label">
					<p-calendar
						[(ngModel)]="itemEditado.fechaActividad"
						#fechaActividad="ngModel"
						name="fechaActividad"
						required
						dateFormat="dd/mm/yy"
						[readonlyInput]="true"
					></p-calendar>
					<label>{{ "FECHAACTIVIDAD" | translate }}</label>
				</span>
			</div>
			<div class="col-12 lg:col-3">
				<span class="p-float-label">
					<p-calendar
						[(ngModel)]="itemEditado.pickUp"
						showTime="true"
						[hourFormat]="24"
						#horaActividad="ngModel"
						name="hora"
						required
						timeOnly="true"
					></p-calendar>
					<label for="horapickup">{{ "HORA_PICKUP" | translate }}</label>
				</span>
			</div>
			<div class="col-12 lg:col-2">
				<span class="p-float-label">
					<input
						pInputNumber
						[(ngModel)]="itemEditado.cantidadPax"
						tooltipPosition="bottom"
						pTooltip="{{ 'CANTIDADPAX_TOOLTIP' | translate }}"
						class="p-inputwrapper-filled pt-4 text-center"
						name="cantidadPax"
						required
						readonly
					/>
					<label>{{ "CANTIDADPAX" | translate }}</label>
				</span>
			</div>
			<div class="col-6 lg:col-2">
				<span class="p-float-label">
					<input
						pInputNumber
						[(ngModel)]="itemEditado.duracion"
						tooltipPosition="bottom"
						pTooltip="{{ 'DURACION_ACTIVIDAD' | translate }}"
						class="p-inputwrapper-filled pt-4 text-center"
						name="duracion"
						required
						readonly
					/>
					<label>{{ "DURACION" | translate }}</label>
				</span>
			</div>
			<div class="col-12 lg:col-2">
				<span class="p-float-label pt-4">
					<selector-idioma [idioma]="itemEditado.idiomaServicio" [readOnly]="true" class="p-inputwrapper-filled pt-4 text-center"></selector-idioma>
					<label>{{ "IDIOMASERVICIO" | translate }}</label>
				</span>
			</div>
			<div class="col-12 lg:col-6" *ngIf="contacto">
				<span class="p-float-label pt-3">
					<contactos [data]="contacto" containerClass="col-4" class="p-inputwrapper-filled"></contactos>
					<label>{{ "CONTACTO" | translate }}</label>
				</span>
			</div>
			<div class="col-12 lg:col-6" *ngIf="itemEditado?.estadoConsentimiento">
				<span class="p-float-label pt-3">
					<i
						style="margin-right: 1em"
						class="p-inputwrapper-filled fa {{ itemEditado?.estadoConsentimiento?.icono }}"
						[ngStyle]="{ color: itemEditado?.estadoConsentimiento?.color }"
					></i>
					{{ itemEditado?.estadoConsentimiento?.descripcion }}
					<label>{{ "Estado consentimiento" | translate }}</label>
				</span>
			</div>
			<div class="col-12 lg:col-12">
				<span class="p-float-label">
					<input
						pInputText
						[(ngModel)]="itemEditado.observaciones"
						tooltipPosition="bottom"
						pTooltip="{{ 'OBSERVACIONES_TOOLTIP' | translate }}"
						name="observaciones"
					/>
					<label>{{ "OBSERVACIONES" | translate }}</label>
				</span>
			</div>
			<div class="col-12 lg:col-12">
				<selector-multiple-chips
					[(ngModel)]="itemEditado.restricciones"
					name="restricciones"
					tooltipPosition="bottom"
					pTooltip="{{ 'Restricciones de los pasajeros' | translate }}"
					[opciones]="itemEditado.restricciones"
					[permiteNuevo]="false"
					[readOnly]="true"
					[titulo]="'Restricciones' | translate"
				></selector-multiple-chips>
			</div>

			<div class="col-12 subtitulo">
				<span>{{ "PRODUCTOS_ASOCIADOS" | translate }}</span>
			</div>
			<div class="col-12">
				<div class="grid">
					<div class="puesto-wrapper col-12" *ngFor="let producto of itemEditado?.productosAsociados; let i = index">
						<div class="grid">
							<div class="col-4">
								<descriptivo-material-selector
									[options]="adicionables"
									[placeHolder]="'NOMBRE_DEL_PRODUCTO' | translate"
									[floatLabel]="i == 0 ? 'auto' : 'never'"
									name=" {{ 'asociado_' + i }}"
									[limpiable]="false"
									[(ngModel)]="producto.producto"
									[label]="index == 0 ? ('NOMBRE_DEL_PRODUCTO' | translate) : null"
									required
									[readonly]="!producto.esAdicional"
									readonly
								>
								</descriptivo-material-selector>
							</div>
							<!-- Resto de los campos migrados similarmente -->
						</div>
					</div>
				</div>
			</div>
		</div>

		<p-dialog
			[(visible)]="mostrarVoucher"
			[modal]="true"
			[style]="{ width: '40vw' }"
			header="{{ 'VOUCHER' | translate }}"
			[focusOnShow]="false"
			(onHide)="mostrarVoucher = false"
		>
			<div class="grid" *ngFor="let item of itemEditado?.reservas">
				<div class="col-12">
					<actividades-card *ngIf="mostrarVoucher" [actividad]="item" [token]="itemEditado?.token" [readonly]="true"></actividades-card>
				</div>
			</div>
		</p-dialog>
	</form>
</div>
