import { TipoProveedorService } from './tipo-proveedor.service';
import { FiltroProveedor } from './../common/model/FiltroProveedor';
import { LoadingService } from './../common/services/loading-data-service.service';
import { ProveedorService } from 'src/app/services/proveedor.service';
import { Injectable } from '@angular/core';
import { Vendedor } from 'src/app/model/Vendedor';
import { BufferedDescriptivoService } from 'src/app/services/BufferedDescriptivoService';
@Injectable({
    providedIn: 'root',
})
export class VendedorService {

    constructor(private proveedorService:ProveedorService, private tipoProveedorService:TipoProveedorService){

    }

    async getVendedores(filtro?:FiltroProveedor,customLoading?:LoadingService){
        let f = filtro || new FiltroProveedor("filtro_vendedor",null,0,9000);
        f.tipoProveedor = await this.tipoProveedorService.getByCodigo("VE");
        return this.proveedorService.getAll(f,customLoading)
    }


}