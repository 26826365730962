import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { Evento } from '../model/Evento';
import { FiltroPuesto } from '../pages/proveedor/FiltroPuesto';
import { LoadingService } from '../common/services/loading-data-service.service';
import { Disponibilidad } from '../model/Disponibilidad';
@Injectable({
  providedIn: 'root',
})
export class DisponibilidadService extends ServicioAbstract<Disponibilidad>{
    public baseName(): string {
        return "disponibilidad";
    }
    public parseToEnt(data: any): Disponibilidad {
        return Disponibilidad.fromData(data);
    }
    public newEnt(): Disponibilidad {
        return new Disponibilidad();
    }
    eliminar(id: number, customLoading?: LoadingService) {

        if (customLoading) { customLoading.addLoadingCount(); }
        else this.loadingService.addLoadingCount()
        return this.http.delete(this.getBaseURL() + id)
            .toPromise()
            .then((r) => {
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
                return r
            });
    }


    public getEventos(filtro: FiltroPuesto): Promise<Evento[]>  {
        return this.http.post(this.getBaseURL('getEventos'), filtro.json).pipe(share())
        .toPromise()
        .then((r: any[]) => {
            return r.map( event => Evento.fromData(event))
        }, (e) => this.handleError(e));
    }
    
}