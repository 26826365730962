<div class="gestor-caja content content-white">
    <model-gestor [service]="service" [(item)]="item" [validar]="isValid" [modelName]="'CAJA' | translate"
        [isModal]="isModal" [goBack]="goBack" (onItemGuardado)="handleGuardado($event)" [editable]="permiteEdicion"
        (onCancelar)="handleCancelar($event)" [form]="CajaForm" (onEditar)="onEditar($event)">
        <div class="gestor-form" model-type="dataContent">
            <form #CajaForm="ngForm" id="Cajaform">
                <div class="grid" *ngIf="item">
                    <div class="col-12 lg:col-4 p-fluid">
                        <span class="p-float-label">
                            <input pInputText placeholder="{{ 'DESCRIPCION_PH' | translate }}" [readonly]="readonly"
                                [(ngModel)]="item.descripcion" tooltipPosition="bottom"
                                pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}" type="text" #descripcion="ngModel"
                                name="descripcion" autocomplete="off" class="p-inputwrapper-filled" />
                            <label>{{ 'DESCRIPCION' | translate }}</label>
                        </span>
                    </div>
                    <div class="col-12 lg:col-2 p-fluid">
                        <descriptivo-material-selector [allowDefault]="!item?.id" [service]="monedaService"
                            [label]="'MONEDA' | translate" [limpiable]="false" [(ngModel)]="item.moneda"
                            [permiteNuevo]="false" name="moneda" [required]="true" [readonly]="readonly">
                        </descriptivo-material-selector>
                    </div>

                    <div class="col-12 lg:col-4">
                        <span class="p-float-label">
                            <input pInputText placeholder="{{ 'CODIGO_PH' | translate }}" [(ngModel)]="item.codigo"
                                tooltipPosition="bottom" pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}" type="text"
                                #codigo="ngModel" name="codigo" [readonly]="readonly" class="p-inputwrapper-filled" />
                            <label>{{ "CODIGO" | translate }}</label>
                        </span>
                    </div>
                    <div class="col-12 lg:col-6">
                        <span class="p-float-label">
                            <selector-multiple-chips [(ngModel)]="item.usuariosAutorizados" name="usuariosAutorizados"
                                #listasPrecios="ngModel" [opciones]="usuarioOptions" [permiteNuevo]="false"
                                [readonly]="readonly" [titulo]="'USUARIOSAUTORIZADOS' | translate">
                            </selector-multiple-chips>
                        </span>
                    </div>

                    <div class="col-12">
                        <p-table [value]="item.cuentas">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 50%">{{ 'CENTRO_COSTO' | translate }}</th>
                                    <th style="width: 50%">{{ 'CUENTA-CONTABLE' | translate }}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-it let-i="rowIndex">
                                <tr>
                                    <td>
                                        {{ it.centro.descripcion }}
                                    </td>
                                    <td>
                                        <descriptivo-material-selector [allowDefault]="!item?.id"
                                            [options]="cuentaContableOptions" [label]="'CUENTA-CONTABLE' | translate"
                                            [limpiable]="limpiable" [(ngModel)]="it.cuenta" name="cuenta_{{ i }}"
                                            [permiteNuevo]="true" [gestor]="gestorCuenta" [readonly]="readonly"
                                            class="p-inputwrapper-filled">
                                        </descriptivo-material-selector>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </form>
        </div>
    </model-gestor>
</div>

<ng-template #gestorCuenta let-handler="handler">
    <gestor-cuenta-contable (onGuardado)="handler.onGuardado($event); onCuentaGuardada($event)"
        (onCancelado)="handler.onCancelado($event)" [isModal]="true" [goBack]="false"> </gestor-cuenta-contable>
</ng-template>