import { CanalComunicacion } from "src/app/model/CanalComunicacion"
import { PuntoDeVenta } from "./PuntoDeVenta"

export class CanalComunicacionInterno extends CanalComunicacion {
	public static TIPO = "I"

	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado?: boolean,
		esSistema?: boolean,
		peso?: number,
		esDefault?: boolean,
		puntoDeVenta?: PuntoDeVenta,
		lastModifiedBy?: string,
		lastModifiedDate?: Date
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso, esDefault, CanalComunicacionInterno.TIPO, puntoDeVenta, lastModifiedBy, lastModifiedDate)
	}
	public static fromData(data: any): CanalComunicacionInterno {
		if (!data) return null
		const o: CanalComunicacionInterno = CanalComunicacion.fromData(data)

		return o
	}
}
