
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { Vendedor } from 'src/app/model/Vendedor';
import { VendedorService } from 'src/app/services/vendedor.service';

@Component({
  selector: 'listado-vendedor',
  templateUrl: 'listado-vendedor.component.html',
  styleUrls: ['listado-vendedor.component.less']
})

export class ListadoVendedorComponent extends SessionComponent implements OnInit, OnDestroy {

  public filtro: Filtro = new Filtro("vendedor_filtros", {}, 0, 10);
  public listado: Vendedor[] = [];
  public columns = [];
  constructor(messagesService: MessagesService,
    public service: VendedorService) {
    super(messagesService)

  }
  ngOnInit() {
    this.columns = [
      {
        "field": "id",
        "header": "#",
        "class": "id-column",
        "role": "text-tabla",
        "sortable": true,
        "order": 1
      },
      {
        "field": "nombre",
        "header": "Nombre",
        "class": "text-tabla",
        "role": "text-tabla",
        "sortable": true,
        "order": 40
      },
      {

        "header": "Contacto",
        "class": "text-tabla",
        "role": "contactos",
        "sortable": false,
        "esMobile": true,
        "order": 40
      }

    ];
  }
  ngOnDestroy() { }


}