import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms"
import { Moneda } from "./../../model/Moneda"
import { MonedaService } from "./../../services/moneda.service"

@Component({
	selector: "cotizacion",
	templateUrl: "./cotizacion.component.html",
	styleUrls: ["./cotizacion.component.less"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CotizacionComponent),
			multi: true
		}
	]
})
export class CotizacionComponent implements OnInit, ControlValueAccessor {
	private _monedaRef: Moneda
	public get monedaRef(): Moneda {
		return this._monedaRef
	}

	@Input()
	public set monedaRef(v: Moneda) {
		this._monedaRef = v
		if (!this._monedaElegida && !this.readonly) {
			this._monedaElegida = v
			this.monedaService.getCotizacion(this.monedaRef, this._monedaElegida).then((cc) => {
				this.cotizacion = cc
			})
		}
	}

	@Input()
	public readonly: boolean = false

	@Input()
	public mostrarMoneda: boolean = true

	@Input()
	public monedaEditable: boolean = true

	private _mostrarCotizacion: boolean = true
	public get mostrarCotizacion(): boolean {
		return this.monedaRef?.codigo != this.monedaElegida?.codigo
	}

	@Output()
	public onMonedaChange: EventEmitter<Moneda> = new EventEmitter()

	@Output()
	public onCotizacionChange: EventEmitter<number> = new EventEmitter()

	private _disabled: boolean
	public get disabled(): boolean {
		return this._disabled
	}
	public set disabled(v: boolean) {
		this._disabled = v
	}

	@Input()
	public name: string = "cotizacion"
	private _cotizacion: number = 1
	public get cotizacion(): number {
		return this._cotizacion
	}
	public set cotizacion(v: number) {
		if (this._cotizacion && this._cotizacion != v) {
			this.onCotizacionChange.emit(v)
		}
		this._cotizacion = v
		this.onChangeCallback(v)
	}

	private _monedaElegida: Moneda
	public get monedaElegida(): Moneda {
		return this._monedaElegida
	}
	public manualInput = false
	@Input()
	public set monedaElegida(v: Moneda) {
		if (!v || v?.codigo == this._monedaElegida?.codigo) return
		this._monedaElegida = v
		if (!this.readonly && !this.manualInput) {
			if (v?.codigo != this.monedaRef?.codigo && !this.manualInput) {
				this.monedaService.getCotizacion(this.monedaRef, this._monedaElegida).then((cc) => {
					this.cotizacion = cc
				})
			} else {
				this.cotizacion = 1
			}

			this.onMonedaChange.emit(v)
		}
	}
	public cotizacionChange(v: number) {
		this.onCotizacionChange.emit(v)
	}

	public monedaChange(v: Moneda) {
		this.onMonedaChange.emit(v)
	}

	constructor(public monedaService: MonedaService) {}

	private onChangeCallback: (_: any) => void = () => {}
	private onTouchedCallback: (_: any) => void = () => {}

	writeValue(obj: any): void {
		this._cotizacion = obj ? obj : []
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled
	}

	ngOnInit() {}
}
