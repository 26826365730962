import { Injectable } from "@angular/core";

@Injectable()
export class ColorUtils {

	public resolverEstilosContraste(colorHex, estiloPrincipal, estiloSecundario) {
		ColorUtils.contraste(colorHex)


	}
	public static contraste(colorHex) {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorHex);
		const rgb = result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;

		const colorSecundario = rgb && (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 186 ? "#000000" : "#ffffff";
		return colorSecundario;
	}

}