import { Injectable } from "@angular/core"
import { VisualizadorAuthGuard } from "./VisualizadorAuthGuard"

@Injectable({
	providedIn: "root"
})
export class VisualizadorDocsProveedorAG extends VisualizadorAuthGuard {
	public getRoles(): string[] {
		return super.getRoles().concat(["ROLE_MD_PROVEEDORES", "ROL_PROVEEDOR", "ROLE_PROVEEDOR"])
	}
}
