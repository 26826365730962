<div class="listado-cambio-saldo-caja content">
	<model-list
		[service]="service"
		[modelName]="'cambio-saldo-caja'"
		[columns]="columns"
		[(listado)]="listado"
		[isLazy]="true"
		[filtro]="filtro"
		[multilenguaje]="false"
		[modoSeleccion]="modoSeleccion"
		[hideHeader]="ocultarCabecera"
		[editable]="false"
		(seleccionChange)="updateSeleccion($event)"
		[acciones]="acciones"
		[readonly]="readonly"
		[titleContent]="header"
		[handler]="handler"
		[filterContent]="filter"
		[onClick]="ver"
	>
		<ng-template #filter>
			<div class="grid">
				<div class="col-12">
					<div class="grid align-content-center vertical-aligned">
						<div class="col-12 small">
							<div class="p-inputgroup">
								<span class="p-float-label">
									<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
									<label>{{ "BUSCAR" | translate }}...</label>
								</span>
								<button type="button" pButton icon="pi pi-search"></button>
							</div>
						</div>
						<div class="col-12">
							<span class="p-float-label">
								<p-calendar
									[(ngModel)]="filtro.fechaDesde"
									dateFormat="dd/mm/yy"
									appendTo="body"
									name="fechaDesde"
									[showIcon]="true"
								></p-calendar>
								<label>{{ "DESDE" | translate }}</label>
							</span>
						</div>
						<div class="col-12">
							<span class="p-float-label">
								<p-calendar
									[(ngModel)]="filtro.fechaHasta"
									dateFormat="dd/mm/yy"
									appendTo="body"
									name="fechaDesde"
									[showIcon]="true"
								></p-calendar>
								<label>{{ "HASTA" | translate }}</label>
							</span>
						</div>
					</div>
					<div class="grid align-content-center vertical-aligned small">
						<div class="col-12">
							<descriptivo-material-selector
								[options]="cajas"
								[label]="'CAJA' | translate"
								name="caja"
								[limpiable]="true"
								[(ngModel)]="filtro.caja"
							>
							</descriptivo-material-selector>
						</div>

						<!--div class='col-12'>
                                    <descriptivo-material-selector [options]="tiposMovimiento"
                                        [label]="'TIPOMOVIMIENTO' | translate" name="tipo" [limpiable]="true"
                                        [(ngModel)]="filtro.tipoMovimiento">
                                    </descriptivo-material-selector>
                                </div-->
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template #header>
			<div class="grid">
				<div class="col-12 nowrap">
					<h2 autofocus>{{ "Cambios de saldo de caja" }}</h2>
					<button pButton tooltipPosition="bottom" pTooltip="{{ 'Retiro' | translate }}" icon="fa fa-sign-out" (click)="operar(true)"></button>

					<button pButton tooltipPosition="bottom" pTooltip="{{ 'Ingreso' | translate }}" icon="fa fa-sign-in" (click)="operar(false)"></button>
				</div>
			</div>
		</ng-template>
	</model-list>
</div>

<p-dialog
	header=""
	[(visible)]="editandoMovimiento"
	appendTo="body"
	[modal]="true"
	#dialDetail
	[styleClass]="'no-header gestor-dialog'"
	(onShow)="onDialogShow($event, dialDetail)"
>
	<gestor-cambio-saldo-caja
		#gestorEstado
		[item]="itemEditado"
		(onGuardado)="refresh($event)"
		[cajaEditable]="true"
		(onCancelado)="onCancelar($event)"
		[goBack]="false"
		[isModal]="true"
		*ngIf="itemEditado && editandoMovimiento"
	>
	</gestor-cambio-saldo-caja>
</p-dialog>
