<div class="estado-reservas flex align-items-center" style="height: 100%" pTooltip="{{ 'SALDO_A_LA_FECHA' | translate }}">
	<div class="col-fixed icon" style="height: 100%">
		<i class="fa fa-dollar fa-2x bold w-100"></i>
	</div>
	<div class="col text-center">
		<strong class="font-large" *ngIf="data">{{ data.totalSaldo | number : "1.2-2" }}</strong>
	</div>
</div>

<p-overlayPanel #op [dismissable]="true" appendTo="body" [styleClass]="'options'">
	<span class="close" (click)="$event.stopPropagation(); hideParams()" style="position: absolute; top: 3px; right: 3px; cursor: pointer; padding: 0.25em"
		><i class="pi pi-times"></i
	></span>
	<div class="grid filtros">
		<div class="col-12">
			<selector-periodo-dinamico
				#selectorPeriodo
				(onPeriodoChange)="onPeriodoSeleccionado($event)"
				[filtro]="filtro"
				[desde]="'fechaVencimientoDesde'"
				[hasta]="'fechaVencimientoHasta'"
			>
			</selector-periodo-dinamico>
		</div>
		<div class="col-12" *ngIf="filtro">
			<descriptivo-material-selector
				[service]="puntoDeVentaService"
				[label]="'PuntoDeVenta' | translate"
				name="puntoDeVenta"
				[limpiable]="true"
				[(ngModel)]="filtro.puntoDeVenta"
			>
			</descriptivo-material-selector>
		</div>
		<div class="col-12 botonera">
			<button
				[ngClass]="{ 'no-seleccionado': !filtro.soloFacturadas }"
				pButton
				tooltipPosition="bottom"
				pTooltip="{{ 'SOLO_FACTURADAS_TT' | translate }}"
				[label]="'SOLO_FACTURADAS' | translate"
				class="solo-con-saldo"
				name="soloFacturadas"
				(click)="filtro.soloFacturadas = !filtro.soloFacturadas"
			></button>
		</div>
	</div>
</p-overlayPanel>
