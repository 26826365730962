import { StringUtils } from 'src/app/common/utils/string-utils';
import { ProfilePic } from './../ProfilePic';
import { ResumenModel } from './../ResumenModel';
import { Usuario } from './../Usuario';
import { TagUsuario } from 'src/app/model/TagUsuario';


export class UsuarioResumen implements ResumenModel {

    public searchStr: string = "";


    constructor(
        public id?: number,
        public profile = new ProfilePic(null, 'assets/images/logo.png'),
        public username?: string,
        public nombre?: string,
        public email?: string,
        public activo: boolean = true,
        public bloqueado: boolean = false,
        public lastModifiedBy?: string,
        public lastModifiedDate?: Date,
        public tagsUsuario:TagUsuario[] =[]

    ) {
        this.id = id;
        this.searchStr = StringUtils.getSinTildes([this.username, this.nombre, this.email].join("_").toUpperCase());
    }
    entityName(): string {
        return Usuario.MODEL_NAME;
    }
    public static fromData(data: any): UsuarioResumen {
        if (!data) return null;
        let u: UsuarioResumen = new UsuarioResumen(data.id,
            data.profilePic ? ProfilePic.fromData(data.profilePic) : new ProfilePic(),
            data.username,
            [data.nombre, data.apellido].join(" "),
            data.email,
            data.activo,
            data.bloqueado,
            data.lastModifiedBy,
            data.lastModifiedDate ? new Date(data.lastModifiedDate) : null,
            data.tagsUsuario? data.tagsUsuario.map(TagUsuario.fromData):[]
        );

        return u;
    }

    get profilePic(): string {
        return this.profile ? this.profile.picPathVersion : "";
    }
    get descripcion(): string {
        return this.username;
    }
    get imagen() {
        return this.profile;
    }
    get imagenUrl() {
        return this.profile?.picPathVersion || 'assets/images/default_profile.png';
    }
    public tieneTag(tag:string){
        return this.tagsUsuario.some(t=> t.descripcion===tag)
    }
}
