import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { catchError, finalize, share } from "rxjs/operators"
import { ServicioAbstract } from "src/app/common/services/service.service"
import { Cobranza } from "src/app/model/Cobranza"
import { Comprobante } from "src/app/model/Comprobante"
import { MonedaEntorno } from "../app.module"
import { Descriptivo } from "../common/model/Descriptivo"
import { ConvertirComprobantes } from "../model/ConvertirComprobantes"
import { LoadingService } from "./../common/services/loading-data-service.service"
import { ComprobanteResumen } from "./../model/Comprobante"
import { FiltroComprobante } from "./../model/FiltroComprobantes"
import { Totales } from "./../model/Totales"
@Injectable({
	providedIn: "root"
})
export class ComprobanteService extends ServicioAbstract<Comprobante> {
	enviarComprobantes(idsComprobantes: number[], destinatarioMultiple: Descriptivo, destinatarioMultipleParticular: string): Promise<boolean> {
		const body = {
			items: idsComprobantes,
			tipoDestinatario: destinatarioMultiple,
			destinatario: destinatarioMultipleParticular
		}
		return lastValueFrom(this.http.post(this.getBaseURL("enviarComprobantes"), body).pipe(share())).then(
			(r: any) => {
				return r
			},
			(e) => this.handleError(e)
		)
	}
	public accionAnular(documento: Comprobante) {
		return this.eliminar(documento.id)
	}
	public esAccionAnular(documento: Comprobante): boolean {
		return documento.estado && documento.estado.codigo != "F" && documento.estado.codigo != "AN" && !documento.id
	}

	enviarComprobante(itemEditado: ComprobanteResumen, destinatario: string, integrar: boolean, customLoading?: LoadingService): Promise<boolean> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(
			this.http.post(this.getBaseURL("enviarComprobante"), { idEntidad: itemEditado.id, destinatario: destinatario, integrar: integrar }).pipe(share())
		).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	getImpagos(filtro: any, customLoading?: LoadingService): Promise<ComprobanteResumen[]> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("impagos"), filtro.json).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r.map((f) => ComprobanteResumen.fromData(f))
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	getByIds(ids: number[], customLoading?: LoadingService): Promise<ComprobanteResumen[]> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("byIds"), ids).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r.map((f) => ComprobanteResumen.fromData(f))
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	public getByReserva(idReserva: number, customLoading?: LoadingService): Promise<ComprobanteResumen[]> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL("byReservaId/" + idReserva)).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r.map((f) => ComprobanteResumen.fromData(f))
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	public getByReservas(idReservas: number[], customLoading?: LoadingService): Promise<ComprobanteResumen[]> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL("byReservaIdMultiple/" + idReservas.toString)).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r.map((f) => ComprobanteResumen.fromData(f))
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	public getCobros(idComprobante: number, customLoading?: LoadingService): Promise<Cobranza[]> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL("cobros/" + idComprobante)).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r.map((f) => Cobranza.fromData(f))
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	public baseName(): string {
		return "comprobante"
	}
	public parseToEnt(data: any): Comprobante {
		return Comprobante.fromData(data)
	}
	public newEnt(): Comprobante {
		const c = new Comprobante()
		c.moneda = MonedaEntorno()
		return c
	}
	public getTotales(filtro: FiltroComprobante, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("totales"), filtro.json).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				const tot = new Totales()
				r.forEach((t) => tot.agregarTotal(t))
				return tot
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	getResumenAll(filtro: FiltroComprobante = new FiltroComprobante(null), customLoading?: LoadingService): Promise<ComprobanteResumen[]> {
		let $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("all"), filtro.json).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r.map((c) => ComprobanteResumen.fromData(c))
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	toFile = (x, nombre, sub) => {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		var newBlob = new Blob([x], { type: "application/pdf" })

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob)

		var link = document.createElement("a")
		link.href = data
		link.download = nombre ? nombre + ".pdf" : "comprobante.pdf"
		// this is necessary as link.click() does not work on the latest firefox
		link.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true, view: window }))

		setTimeout(function () {
			// For Firefox it is necessary to delay revoking the ObjectURL
			window.URL.revokeObjectURL(data)
			link.remove()
			sub.unsubscribe()
		}, 100)
	}
	descargar(id: number, nombre?: string, customLoading?: LoadingService) {
		var sub = this.http.get(this.getBaseURL("descargar/" + id), { responseType: "blob" }).subscribe((r) => this.toFile(r, nombre, sub))
	}
	descargarToken(token: string, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		const obst = this.http.get(this.getApiURL() + "public/comprobante/descargar/" + token, { responseType: "blob", observe: "response" })

		var sub = obst.subscribe((r) => {
			const contentDispositionHeader = r.headers.get("Content-disposition")
			let nombre = ""
			if (contentDispositionHeader) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
				const matches = filenameRegex.exec(contentDispositionHeader)
				if (matches != null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "")
					nombre = filename ? filename : token
				}
			}

			if (customLoading) {
				customLoading.susLoadingCount()
			} else this.loadingService.susLoadingCount()
			this.toFile(r?.body, nombre, sub)
		})
		return lastValueFrom(obst).finally(() => {
			if (customLoading) {
				customLoading.susLoadingCount()
			} else this.loadingService.susLoadingCount()
		})
	}
	verPDF(id: number, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		var sub = this.http
			.get(this.getBaseURL("descargar/" + id), { responseType: "blob" })
			.pipe(
				catchError((err) => this.handleError(err, customLoading)),
				finalize(() => {
					if (customLoading) customLoading.susLoadingCount()
					else this.loadingService.susLoadingCount()
				})
			)

			.subscribe((x) => {
				var newBlob = new Blob([x], { type: "application/pdf" })

				var url = URL.createObjectURL(newBlob)
				window.open(url, "_blank")
				sub.unsubscribe()
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}
	verAttach(attachUrl: string, customLoading?: LoadingService) {
		var sub = this.http.get(attachUrl, { responseType: "blob" }).subscribe((x) => {
			var newBlob = new Blob([x], { type: "application/pdf" })

			var url = URL.createObjectURL(newBlob)
			window.open(url, "_blank")
			sub.unsubscribe()
		})
	}

	public convertir(comprobante: Comprobante, customLoading?: LoadingService): Promise<Comprobante> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("convertir"), comprobante))
			.then((r) => {
				return this.parseSingle(r)
			})
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}

	convertirAOficial(convertirComprobante: ConvertirComprobantes, customLoading?: LoadingService): Promise<Comprobante> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("convertir-multiple"), convertirComprobante))
			.then((r) => {
				return this.parseSingle(r)
			})
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}

	public aprobarComprobante(comprobante: Comprobante, customLoading?: LoadingService): Promise<Comprobante> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("aprobar"), comprobante))
			.then((r) => {
				return this.parseSingle(r)
			})
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}

	hayNotasSinCompensar(idCliente, customLoading?: LoadingService): Promise<boolean> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL("hayNotasSinCompensar/" + idCliente)).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	getNotaGenerada(id: number, customLoading?: LoadingService): Promise<any[]> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL("getNotaGenerada/" + id)).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r
			},
			(e) => this.handleError(e, customLoading)
		)
	}
}
