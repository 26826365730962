import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import listPlugin from "@fullcalendar/list"
import timeGridPlugin from "@fullcalendar/timegrid"

import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { FullCalendarComponent } from "@fullcalendar/angular"
import { CalendarOptions, EventApi, EventInput } from "@fullcalendar/core"
import * as moment from "moment"
import { ConfirmationService } from "primeng/api"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { AccionAutomatica } from "src/app/model/AccionAutomatica"
import { Ausencia } from "src/app/model/Ausencia"
import { CalendarEvent } from "src/app/model/CalendarEvent"
import { Cliente } from "src/app/model/Cliente"
import { Comprobante } from "src/app/model/Comprobante"
import { Disponibilidad } from "src/app/model/Disponibilidad"
import { Evento } from "src/app/model/Evento"
import { Nota } from "src/app/model/Nota"
import { Proveedor } from "src/app/model/Proveedor"
import { SerieAccion } from "src/app/model/SerieAccion"
import { AccionAutomaticaService } from "src/app/services/accion-automatica.service"
import { AusenciaService } from "src/app/services/ausencia.service"
import { ClienteService } from "src/app/services/cliente.service"
import { ComprobanteService } from "src/app/services/comprobante.service"
import { DisponibilidadService } from "src/app/services/disponibilidad.service"
import { ProveedorService } from "src/app/services/proveedor.service"
import { FiltroFecha } from "../../common/model/FiltroFecha"
import { BloqueoVehiculo } from "../../model/BloqueoVehiculo"
import { Factura } from "../../model/Factura"
import { SerieAusencia } from "../../model/SerieAusencia"
import { BloqueoVehiculoService } from "../../services/bloqueo-vehiculo.service"
import { FacturaService } from "../../services/factura.service"
import { NotaService } from "../../services/nota.service"
import { SerieAusenciaService } from "../../services/serie-ausencia.service"
import { TipoAusenciaService } from "../../services/tipo-ausencia.service"
import { FiltroPuesto } from "../proveedor/FiltroPuesto"
import { FiltroAcciones } from "./../../common/model/FiltroAcciones"
import { FiltroFactura } from "./../../common/model/FiltroFactura"
import { FacturaResumen } from "./../../model/FacturaResumen"
import { FiltroComprobante } from "./../../model/FiltroComprobantes"
import { FacturaResumenService } from "./../../services/factura-resumen.service"
import { SerieAccionService } from "./../../services/serie-accion.service"

@Component({
	selector: "calendario",
	templateUrl: "./calendario.component.html",
	styleUrls: ["./calendario.component.less"]
})
export class CalendarioComponent extends SessionComponent implements OnInit {
	@ViewChild("calendar") private calendar: FullCalendarComponent
	currentDate: Date
	item: any
	ausencias: Ausencia[] = []
	verDetalle: boolean = false
	editando: boolean = false
	esRecurrente: boolean = false
	@Input()
	initialView: "dayGridMonth" | "listWeek" = "dayGridMonth"

	@Input()
	view: string
	@Input()
	isHome: boolean = false

	private refreshing: boolean = false
	showCalendar: boolean = false

	private _events: Evento[] = []
	verBusquedaComprobante: boolean = false
	verBusquedaComprobanteCliente: boolean = false

	public get events(): Evento[] {
		return this._events
	}
	public set events(v: Evento[]) {
		this._events = v
		this.calendar?.getApi()?.removeAllEvents()
		this.calendar?.getApi()?.removeAllEventSources()
		let e: EventInput[] = v.map((ev) => {
			let event: EventInput = Evento.toEventInput(ev)
			return event
		})
		this.calendar?.getApi()?.addEventSource(e)
	}

	selectedItem: EventApi
	opcionesCreacion: Descriptivo[] = []
	opcionesFiltro: Descriptivo[] = []
	facturas: FacturaResumen[] = []
	facturaSeleccionada: FacturaResumen
	comprobanteSeleccionado: Comprobante

	private _proveedorSeleccionado: Descriptivo
	public get proveedorSeleccionado(): Descriptivo {
		return this._proveedorSeleccionado
	}
	public set proveedorSeleccionado(v: Descriptivo) {
		if (v && v != this._proveedorSeleccionado) {
			this._proveedorSeleccionado = v
			let f = new FiltroFactura("factura-calendario")
			f.proveedor = v
			f.sortField = "fecha"
			f.sortOrder = 0
			this.facturas = []
			this.facturaResumenService.getAll(f).then((facturas) => (this.facturas = [...facturas]))
		}
	}

	private _clienteSeleccionado: Descriptivo
	public get clienteSeleccionado(): Descriptivo {
		return this._clienteSeleccionado
	}
	public set clienteSeleccionado(v: Descriptivo) {
		if (v && v != this._clienteSeleccionado) {
			this._clienteSeleccionado = v
			let f = new FiltroComprobante("comprobante-calendario")
			f.cliente = v
			f.sortField = "fecha"
			f.sortOrder = 0
			this.comprobantes = []
			this.comprobanteService.getAll(f).then((c) => (this.comprobantes = [...c]))
		}
	}

	comprobantes: Comprobante[] = []

	public opcionesFiltroSeleccionadas: String[] = []

	@Input() proveedor: Proveedor

	public readonly: boolean = false
	public proveedores: Proveedor[] = []
	public clientes: Cliente[] = []
	calendarOptions: CalendarOptions
	borrarDialog: boolean = false
	verCrear: boolean = false
	dateSelected: any

	private _tipo: Descriptivo
	public get tipo(): Descriptivo {
		return this._tipo
	}
	public set tipo(v: Descriptivo) {
		this._tipo = v
	}

	verFiltro: boolean = false
	fechaDesde: Date
	fechaHasta: Date
	constructor(
		public messagesService: MessagesService,
		public ausenciaService: AusenciaService,
		public proveedorService: ProveedorService,
		private serieAusenciaService: SerieAusenciaService,
		public tipoAusenciaService: TipoAusenciaService,
		public confirmationService: ConfirmationService,
		public bloqueoVehiculoService: BloqueoVehiculoService,
		public notaService: NotaService,
		public facturaService: FacturaService,
		public disponibilidadService: DisponibilidadService,
		public accionesService: AccionAutomaticaService,
		public facturaResumenService: FacturaResumenService,
		public serieAccionService: SerieAccionService,
		public comprobanteService: ComprobanteService,
		public clienteService: ClienteService
	) {
		super(messagesService)
		this.opcionesCreacion = [Evento.AUSENCIA, Evento.DISPONIBILIDAD, Evento.NOTA, Evento.DUPLICAR_FACTRA, Evento.DUPLICAR_COMPROBANTE]
		this.opcionesFiltro = [
			Evento.AUSENCIA,
			Evento.NOTA,
			Evento.BLOQUEO_VEHICULO,
			Evento.VENCIMIENTO_FACTURA,
			Evento.DISPONIBILIDAD,
			Evento.DUPLICAR_FACTRA,
			Evento.DUPLICAR_COMPROBANTE
		]
		this.opcionesFiltroSeleccionadas = this.opcionesFiltro.map((o) => o.codigo)
	}

	ngOnInit() {
		let timeout = 0
		if (this.isHome) timeout = 1000
		setTimeout(() => {
			this.showCalendar = true
			this.init()
		}, timeout)
	}

	init() {
		if (this.proveedor) {
			this.opcionesFiltroSeleccionadas = [Evento.AUSENCIA.codigo, Evento.DISPONIBILIDAD.codigo]
		} else {
			const vals = localStorage.getItem("opciones-calendario") || ""
			this.opcionesFiltroSeleccionadas = vals ? vals.split(",") : this.opcionesFiltro.map((o) => o.codigo)
		}

		const customButtons = this.proveedor
			? {}
			: {
					filter: {
						text: "Filtro",
						icon: "bi bi bi-funnel",
						click: () => {
							this.verFiltro = true
						}
					}
			  }

		this.reinitDate()

		this.refreshCalendar()
		if (!this.proveedor) {
			this.proveedorService.getAll().then((p) => {
				this.proveedores = p
			})
		}
		if (!this.clienteSeleccionado && !this.proveedor) {
			this.clienteService.getAll().then((p) => {
				this.clientes = p
			})
		}

		this.calendarOptions = {
			initialDate: new Date(),
			locale: "ES",
			plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
			customButtons,
			buttonText: {
				month: "Mes",
				week: "Semana",
				today: "Ir a Hoy"
			},
			headerToolbar: {
				left: "prev,next",
				center: "title",
				right: this.isHome ? "filter" : "today,dayGridMonth,listWeek,filter"
			},
			initialView: this.initialView,
			editable: true,
			dateClick: (e) => {
				if (this.esExterno || this.readonly) return
				this.tipo = null
				this.dateSelected = e.date
				this.procesarDateClick()
			},
			/*eventContent: (arg) => {
				/*const iconHtml = `<i class="fa fa-check"></i> ${arg.event.title}`
				return { html: iconHtml }
			},*/
			eventClick: (e) => {
				if (this.isHome || this.esExterno) return
				this.selectedItem = e.event
				this.tipo = new Descriptivo(e.event.extendedProps["tipo"])
				this.verDetalle = true
				this.editando = true
				this.esRecurrente = false
				this.procesarClick()
			},
			events: this.events.map((e) => Evento.toEventInput(e)),
			// aspectRatio: this.isHome? 1.35 : 2
			views: {
				timeGrid: {
					dayMaxEventRows: 6 // adjust to 6 only for timeGridWeek/timeGridDay
				}
			},
			dayMaxEventRows: true,
			aspectRatio: this.isHome ? 1.35 : 1.5
		}
	}

	reinitDate() {
		this.currentDate = new Date()
		this.fechaDesde = moment(this.currentDate).startOf("month").subtract(5, "days").toDate()
		this.fechaHasta = moment(this.fechaDesde).add(45, "days").toDate()
	}

	procesarDateClick() {
		if (this.isHome) return
		this.esRecurrente = false
		if (this.proveedor) {
			if (!this.proveedor.noHabitual) {
				this.tipo = Evento.AUSENCIA
			} else {
				this.tipo = Evento.DISPONIBILIDAD
			}
			this.procesarCreacionEvento(this.tipo)
		} else {
			this.verCrear = true
		}
	}

	procesarCreacionEvento(tipo: Descriptivo) {
		this._proveedorSeleccionado = null
		this._clienteSeleccionado = null
		this.verCrear = false
		switch (tipo.codigo) {
			case Evento.AUSENCIA.codigo:
				this.esRecurrente = false
				this.item = new CalendarEvent()
				this.item.ausencia = new Ausencia()
				this.item.serie = new SerieAusencia()
				if (this.proveedor) {
					this.item.ausencia.proveedor = this.proveedor
				}
				this.item.ausencia.fechaDesde = this.dateSelected
				this.item.ausencia.fechaHasta = moment(this.dateSelected).endOf("day").toDate()
				this.tipo = new Descriptivo("AUS", "Ausencia")
				this.verDetalle = true
				this.editando = false
				break
			case Evento.NOTA.codigo:
				this.item = new Nota()
				this.item.fechaNota = this.dateSelected
				this.item.fechaHasta = moment(this.dateSelected).add(1, "days").toDate()
				this.tipo = new Descriptivo("NOT", "Nota")
				this.verDetalle = true
				this.editando = false
				break
			case Evento.DISPONIBILIDAD.codigo:
				this.item = new Disponibilidad()
				if (this.proveedor) {
					this.item.proveedor = this.proveedor
				}
				this.item.fechaDesde = this.dateSelected
				this.item.fechaHasta = moment(this.dateSelected).endOf("day").toDate()
				this.tipo = new Descriptivo("DIS", "Disponibilidad")
				this.verDetalle = true
				this.editando = false
				break
			case Evento.DUPLICAR_FACTRA.codigo:
				this.item = new AccionAutomatica()
				this.item.serie = new SerieAccion()
				this.item.fechaProgramada = this.dateSelected
				this.tipo = Evento.DUPLICAR_FACTRA
				this.item.tipoAccion = AccionAutomatica.CLONAR_FACTURA
				this.verDetalle = true
				this.editando = false
				break
			case Evento.DUPLICAR_COMPROBANTE.codigo:
				this.item = new AccionAutomatica()
				this.item.serie = new SerieAccion()
				this.item.fechaProgramada = this.dateSelected
				this.tipo = Evento.DUPLICAR_COMPROBANTE
				this.item.tipoAccion = AccionAutomatica.CLONAR_COMPROBANTE
				this.verDetalle = true
				this.editando = false
				break
			default:
				break
		}
	}

	procesarClick() {
		this.item = null
		this._proveedorSeleccionado = null
		this._clienteSeleccionado = null
		switch (this.tipo.codigo) {
			case Evento.AUSENCIA.codigo:
				this.ausenciaService.getById(parseInt(this.selectedItem.id)).then((a: Ausencia) => {
					this.item = new CalendarEvent()
					this.item.ausencia = a
					if (this.item.ausencia.idSerie) {
						this.serieAusenciaService.getById(this.item.ausencia.idSerie).then((s) => {
							this.item.serie = s
							this.esRecurrente = true
						})
					}
				})
				break
			case Evento.DISPONIBILIDAD.codigo:
				this.disponibilidadService.getById(parseInt(this.selectedItem.id)).then((d: Disponibilidad) => {
					this.item = d
				})
				break
			case Evento.NOTA.codigo:
				this.notaService.getById(parseInt(this.selectedItem.id)).then((n: Nota) => {
					this.item = n
				})
				break
			case Evento.BLOQUEO_VEHICULO.codigo:
				this.bloqueoVehiculoService.getById(parseInt(this.selectedItem.id)).then((b: BloqueoVehiculo) => {
					this.item = b
				})
				break
			case Evento.DUPLICAR_FACTRA.codigo:
				this.accionesService.getById(parseInt(this.selectedItem.id)).then((b: AccionAutomatica) => {
					this.item = b
					if (this.item.contexto["proveedor"]) {
						const d = Descriptivo.fromData(JSON.parse(this.item.contexto["proveedor"]))
						this.proveedorService
							.getById(d.id)
							.then((r) => {
								if (r) {
									this.proveedorSeleccionado = r
								} else {
									this.proveedorSeleccionado = d
									this.proveedorSeleccionado.descripcion += " - Eliminado"
								}
							})
							.catch(() => {
								this.proveedorSeleccionado = d
								this.proveedorSeleccionado.descripcion += " - Eliminado"
							})
					}
					if (this.item.idSerie) {
						this.esRecurrente = true
						this.serieAccionService.getById(this.item.idSerie).then((r) => (this.item.serie = r))
					}
				})
				break
			case Evento.DUPLICAR_COMPROBANTE.codigo:
				this.accionesService.getById(parseInt(this.selectedItem.id)).then((b: AccionAutomatica) => {
					this.item = b
					if (this.item.contexto["cliente"]) {
						const d = Descriptivo.fromData(JSON.parse(this.item.contexto["cliente"]))
						this.clienteService
							.getById(d.id)
							.then((r) => {
								if (r) {
									this.clienteSeleccionado = r
								} else {
									this.clienteSeleccionado = d
									this.clienteSeleccionado.descripcion += " - Eliminado"
								}
							})
							.catch(() => {
								this.clienteSeleccionado = d
								this.clienteSeleccionado.descripcion += " - Eliminado"
							})
					}
					if (this.item.idSerie) {
						this.esRecurrente = true
						this.serieAccionService.getById(this.item.idSerie).then((r) => (this.item.serie = r))
					}
				})
				break
			case Evento.VENCIMIENTO_FACTURA.codigo:
				this.facturaService.getById(parseInt(this.selectedItem.id)).then((f: Factura) => {
					this.item = f
				})
				break
			default:
				break
		}
	}
	guardarDisponibilidad() {
		if (!this.item.fechaDesde) {
			return this.error("Ingrese una fecha de inicio")
		}

		if (!this.item.fechaHasta) {
			return this.error("Ingrese una fecha de finalización")
		}

		if (!this.item.proveedor) {
			return this.error(this.translateService.get("INGRESE_PROVEEDOR"))
		}

		this.disponibilidadService.guardar(this.item).then((a) => {
			if (a) {
				this.verDetalle = false
				this.success(this.translateService.get("OK_GUARDADO"))
			}
			this.refreshCalendar()
		})
	}
	guardarAusencia() {
		if (!this.item.ausencia.fechaDesde) {
			return this.error(this.translateService.get("INGRESE_FECHA_INICIO"))
		}

		if (!this.item.ausencia.fechaHasta) {
			return this.error(this.translateService.get("INGRESE_FECHA_FINALIZACION"))
		}

		if (!this.item.ausencia.proveedor) {
			return this.error(this.translateService.get("INGRESE_PROVEEDOR"))
		}

		if (!this.item.ausencia.tipo?.codigo) {
			return this.error(this.translateService.get("INGRESE_TIPO_AUSENCIA"))
		}

		if (this.esRecurrente) {
			if (!this.item.serie.n) {
				return this.error(this.translateService.get("INGRESE_NUMERO_REPETICIONES"))
			}

			if (!this.item.serie.unidad) {
				return this.error(this.translateService.get("INGRESE_DIAS_SEMANAS_MESES"))
			}
			if (!this.editando) {
				this.item.serie.fechaDesde = this.item.ausencia.fechaDesde
				this.item.serie.fechaHasta = this.item.ausencia.fechaHasta
				this.item.serie.proveedor = this.item.ausencia.proveedor
			}
			this.item.serie.tipoAusencia = this.item.ausencia.tipo
			this.serieAusenciaService.guardar(this.item.serie).then((s) => {
				if (s) {
					this.verDetalle = false
					this.success(this.translateService.get("OK_GUARDADO"))
				}
				this.refreshCalendar()
			})
		} else
			this.ausenciaService.guardar(this.item.ausencia).then((a) => {
				if (a) {
					this.verDetalle = false
					this.success(this.translateService.get("OK_GUARDADO"))
				}
				this.refreshCalendar()
			})
	}

	guardarAccion() {
		if (!this.item?.fechaProgramada) {
			return this.error(this.translateService.get("INDIQUE_FECHA"))
		} else if (this.item.tipoAccion == AccionAutomatica.CLONAR_FACTURA && !this.item?.contexto?.idFactura) {
			return this.error(this.translateService.get("INDIQUE_FACTURA_REPETIR"))
		} else if (this.item.tipoAccion == AccionAutomatica.CLONAR_COMPROBANTE && !this.item?.contexto?.idComprobante) {
			return this.error(this.translateService.get("INDIQUE_COMPROBANTE_REPETIR"))
		} else if (this.esRecurrente) {
			if (!this.item?.serie?.fechaHasta) return this.error(this.translateService.get("INDIQUE_FECHA_HASTA"))
			if (!this.item?.serie?.n || !this.item?.serie?.unidad) return this.error(this.translateService.get("INGRESE_NUMERO_REPETICIONES"))

			this.item.serie.contexto = this.item.contexto
			this.item.serie.tipoAccion = this.item.tipoAccion
			this.item.serie.fechaDesde = this.item.fechaProgramada
			this.serieAccionService.guardar(this.item.serie).then((r) => {
				this.success(this.translateService.get("ACCION_CREADA"))
				this.refreshCalendar()
				this.verDetalle = false
				this.item = null
				this.esRecurrente = false
				this._proveedorSeleccionado = null
				this._clienteSeleccionado = null
			})
		} else {
			this.accionesService.guardar(this.item).then((r) => {
				this.success(this.translateService.get("ACCION_CREADA"))
				this.refreshCalendar()
				this.verDetalle = false
				this.item = null
				this._proveedorSeleccionado = null
				this._clienteSeleccionado = null
			})
		}
	}
	updateSingleCalendar() {
		let filtroFecha = new FiltroFecha("filtro")
		filtroFecha.fechaDesde = this.fechaDesde
		filtroFecha.fechaHasta = this.fechaHasta
		let f = new FiltroPuesto("ausencias", null)
		f.fechaDesde = this.fechaDesde
		f.fechaHasta = this.fechaHasta
		if (this.proveedor) {
			f.personal = this.proveedor
		}
		let filtroAcciones = new FiltroAcciones("filtro")

		switch (this.tipo.codigo) {
			case Evento.AUSENCIA.codigo:
				this.ausenciaService.getEventos(f).then((e) => {
					this.events = [...this.events.filter((event) => event.extendedProps.tipo != Evento.AUSENCIA.codigo), ...e]
				})
				break

			case Evento.NOTA.codigo:
				this.notaService.getEventos(filtroFecha).then((e) => {
					this.events = [...this.events.filter((event) => event.extendedProps.tipo != Evento.NOTA.codigo), ...e]
				})
				break
			case Evento.BLOQUEO_VEHICULO.codigo:
				this.bloqueoVehiculoService.getEventos(filtroFecha).then((e) => {
					this.events = [...this.events.filter((event) => event.extendedProps.tipo != Evento.BLOQUEO_VEHICULO.codigo), ...e]
				})
				break
			case Evento.VENCIMIENTO_FACTURA.codigo:
				this.facturaService.getEventos(filtroFecha).then((e) => {
					this.events = [...this.events.filter((event) => event.extendedProps.tipo != Evento.VENCIMIENTO_FACTURA.codigo), ...e]
				})
				break
			case Evento.DISPONIBILIDAD.codigo:
				this.disponibilidadService.getEventos(f).then((e) => {
					this.events = [...this.events.filter((event) => event.extendedProps.tipo != Evento.DISPONIBILIDAD.codigo), ...e]
				})
				break
			case Evento.DUPLICAR_FACTRA.codigo:
				filtroAcciones.fechaDesde = this.fechaDesde
				filtroAcciones.fechaHasta = this.fechaHasta
				filtroAcciones.tipoAccion = AccionAutomatica.CLONAR_FACTURA
				this.accionesService.getEventos(filtroAcciones).then((e) => {
					this.events = [...this.events.filter((event) => event.extendedProps.tipo != Evento.DUPLICAR_FACTRA.codigo), ...e]
				})
				break
			case Evento.DUPLICAR_COMPROBANTE.codigo:
				filtroAcciones.fechaDesde = this.fechaDesde
				filtroAcciones.fechaHasta = this.fechaHasta
				filtroAcciones.tipoAccion = AccionAutomatica.CLONAR_COMPROBANTE
				this.accionesService.getEventos(filtroAcciones).then((e) => {
					this.events = [...this.events.filter((event) => event.extendedProps.tipo != Evento.DUPLICAR_COMPROBANTE.codigo), ...e]
				})
				break
			default:
				break
		}
		this.selectedItem = null
		this.tipo = null
	}

	refreshCalendar() {
		this.refreshing = true
		let p = []
		this.events = []
		this.ausencias = []
		this.verFiltro = false
		if (this.aplicaOpcion(Evento.AUSENCIA.codigo)) {
			let f = new FiltroPuesto("ausencias", null)
			f.fechaDesde = this.fechaDesde
			f.fechaHasta = this.fechaHasta
			if (this.proveedor) {
				f.personal = this.proveedor
			}
			p.push(
				this.ausenciaService.getEventos(f).then((e) => {
					this.events = [...this.events, ...e]
				})
			)
		}
		if (this.aplicaOpcion(Evento.DISPONIBILIDAD.codigo)) {
			let f = new FiltroPuesto("disponibilidad", null)
			f.fechaDesde = this.fechaDesde
			f.fechaHasta = this.fechaHasta
			if (this.proveedor) {
				f.personal = this.proveedor
			}
			p.push(
				this.disponibilidadService.getEventos(f).then((e) => {
					this.events = [...this.events, ...e]
				})
			)
		}
		let filtroFecha = new FiltroFecha("filtro")
		filtroFecha.fechaDesde = this.fechaDesde
		filtroFecha.fechaHasta = this.fechaHasta
		if (this.aplicaOpcion(Evento.DUPLICAR_FACTRA.codigo) && this.tieneRol("ROLE_FACTURAS")) {
			let filtroAcciones = new FiltroAcciones("filtro_accion")
			filtroAcciones.fechaDesde = this.fechaDesde
			filtroAcciones.fechaHasta = this.fechaHasta
			filtroAcciones.tipoAccion = AccionAutomatica.CLONAR_FACTURA
			p.push(
				this.accionesService.getEventos(filtroAcciones).then((e) => {
					this.events = [...this.events, ...e]
				})
			)
		}
		if (this.aplicaOpcion(Evento.DUPLICAR_COMPROBANTE.codigo) && this.tieneRol("ROLE_COMPROBANTES")) {
			let filtroAcciones = new FiltroAcciones("filtro_accion")
			filtroAcciones.fechaDesde = this.fechaDesde
			filtroAcciones.fechaHasta = this.fechaHasta
			filtroAcciones.tipoAccion = AccionAutomatica.CLONAR_COMPROBANTE
			p.push(
				this.accionesService.getEventos(filtroAcciones).then((e) => {
					this.events = [...this.events, ...e]
				})
			)
		}
		if (!this.proveedor) {
			if (this.aplicaOpcion(Evento.BLOQUEO_VEHICULO.codigo) && this.tieneRol("ROLE_ADMIN"))
				p.push(
					this.bloqueoVehiculoService.getEventos(filtroFecha).then((e) => {
						this.events = [...this.events, ...e]
					})
				)
			if (this.aplicaOpcion(Evento.NOTA.codigo))
				p.push(
					this.notaService.getEventos(filtroFecha).then((e) => {
						this.events = [...this.events, ...e]
					})
				)
			if (this.aplicaOpcion(Evento.VENCIMIENTO_FACTURA.codigo) && this.tieneRol("ROLE_FACTURAS"))
				p.push(
					this.facturaService.getEventos(filtroFecha).then((e) => {
						this.events = [...this.events, ...e]
					})
				)
		}

		Promise.all(p).then(() => (this.refreshing = false))
		localStorage.setItem("opciones-calendario", this.opcionesFiltroSeleccionadas.join(","))
	}

	borrarEventoAusencia() {
		if (this.item.ausencia.idSerie) {
			this.borrarDialog = true
		} else {
			this.confirmationService.confirm({
				key: "genConf",
				header: this.translateService.get("GUARDAR_CAMBIOS"),
				message: "¿Desea borrar la ausencia?",
				accept: () => {
					this.borrarAusencia()
				}
			})
		}
	}

	borrarEventoAccion() {
		if (this.item.idSerie) {
			this.borrarDialog = true
		} else {
			this.confirmationService.confirm({
				key: "genConf",
				header: this.translateService.get("GUARDAR_CAMBIOS"),
				message: "¿Desea borrar la acción?",
				accept: () => {
					this.borrarAccion()
				}
			})
		}
	}

	borrarEventoDisponibilidad() {
		this.confirmationService.confirm({
			key: "genConf",
			header: this.translateService.get("GUARDAR_CAMBIOS"),
			message: "¿Desea borrar la disponibilidad?",
			accept: () => {
				this.borrarDisponibilidad()
			}
		})
	}

	cancelarRecurrencia(event = Evento.AUSENCIA.codigo) {
		this.confirmationService.confirm({
			key: "genConf",
			header: this.translateService.get("GUARDAR_CAMBIOS"),
			message: "Esta opción cancelará todos los eventos futuros de esta serie. ¿Desea continuar?",
			accept: () => {
				if (event == Evento.AUSENCIA.codigo)
					this.serieAusenciaService.cancelarRecurrencia(this.item.ausencia).then((r) => {
						if (r) {
							this.success("La serie fue actualizada correctamente")
							this.borrarDialog = false
							this.editando = false
							this.verDetalle = false
							this.refreshCalendar()
						} else {
							this.error("Hubo un error actualizando la serie")
						}
					})
				else if (event == Evento.DUPLICAR_FACTRA.codigo) {
					this.serieAccionService.cancelarRecurrencia(this.item).then((r) => {
						if (r) {
							this.success("La serie fue actualizada correctamente")
							this.borrarDialog = false
							this.editando = false
							this.verDetalle = false
							this.refreshCalendar()
						} else {
							this.error("Hubo un error actualizando la serie")
						}
					})
				}
			}
		})
	}

	cerrarBorrar() {
		this.borrarDialog = false
	}

	borrarSerie(event = Evento.AUSENCIA.codigo) {
		if (event == Evento.AUSENCIA.codigo) {
			this.serieAusenciaService.eliminar(this.item?.serie?.id).then((r) => {
				if (r) {
					this.success("La serie fue borrada correctamente")
					this.borrarDialog = false
					this.editando = false
					this.verDetalle = false
					this.refreshCalendar()
				} else {
					this.error("Hubo un error borrando la serie")
				}
			})
		} else if (event == Evento.DUPLICAR_FACTRA.codigo || event == Evento.DUPLICAR_COMPROBANTE.codigo) {
			this.serieAccionService.eliminar(this.item?.serie?.id).then((r) => {
				if (r) {
					this.success("La serie fue borrada correctamente")
					this.borrarDialog = false
					this.editando = false
					this.verDetalle = false
					this.refreshCalendar()
				} else {
					this.error("Hubo un error borrando la serie")
				}
			})
		}
	}

	borrar() {
		switch (this.tipo.codigo) {
			case Evento.AUSENCIA.codigo:
				this.borrarAusencia()
				break
			case Evento.DUPLICAR_FACTRA.codigo:
				this.borrarAccion()
				break
			case Evento.DUPLICAR_COMPROBANTE.codigo:
				this.borrarAccion()
				break
		}
	}
	borrarAusencia() {
		this.ausenciaService.eliminar(this.item?.ausencia?.id).then((r) => {
			if (r) {
				this.success("La ausencia fue borrada correctamente")
				this.borrarDialog = false
				this.editando = false
				this.verDetalle = false
				this.refreshCalendar()
			} else {
				this.error("Hubo un error borrando la ausencia")
			}
		})
	}

	borrarAccion() {
		this.accionesService.eliminar(this.item?.id).then((r) => {
			if (r) {
				this.success("La acción fue borrada correctamente")
				this.borrarDialog = false
				this.editando = false
				this.verDetalle = false
				this.refreshCalendar()
			} else {
				this.error("Hubo un error borrando la acción")
			}
		})
	}

	borrarDisponibilidad() {
		this.disponibilidadService.eliminar(this.item?.id).then((r) => {
			if (r) {
				this.success("La disponibilidad fue borrada correctamente")
				this.borrarDialog = false
				this.editando = false
				this.verDetalle = false
				this.refreshCalendar()
			} else {
				this.error("Hubo un error borrando la disponibilidad")
			}
		})
	}
	ngAfterViewInit() {
		if (this.isHome) {
			setTimeout(() => {
				this.afterView()
			}, 2000)
		} else {
			this.afterView()
		}
	}

	afterView() {
		if (this.view) {
			setTimeout(() => {
				this.calendar?.getApi()?.changeView(this.view)
			}, 0)
		}

		setTimeout(() => {
			this && this.bindEvents()
		}, 10)
	}

	bindEvents() {
		if (!this.calendar) return
		let prevButton = this.calendar["element"]?.nativeElement?.getElementsByClassName("fc-prev-button")
		let nextButton = this.calendar["element"]?.nativeElement?.getElementsByClassName("fc-next-button")
		let today = this.calendar["element"]?.nativeElement?.getElementsByClassName("fc-today-button")

		nextButton?.length &&
			nextButton[0].addEventListener("click", () => {
				if (!this.refreshing) {
					const calendar = this.calendar.getApi()
					this.calcularFecha(calendar, "add")
					this.refreshCalendar()
				}

				// this.bindEvents()
			})
		prevButton?.length &&
			prevButton[0].addEventListener("click", () => {
				if (!this.refreshing) {
					const calendar = this.calendar.getApi()
					this.calcularFecha(calendar, "sub")
					this.refreshCalendar()
				}

				// this.bindEvents()
			})

		today?.length &&
			today[0].addEventListener("click", () => {
				if (!this.refreshing) {
					const calendar = this.calendar.getApi()
					this.reinitDate()
					this.refreshCalendar()
				}

				// this.bindEvents()
			})
	}

	calcularFecha(calendar, op) {
		const unit = calendar?.currentData?.dateProfile?.currentRangeUnit || "month"
		this.currentDate = moment(calendar?.currentData?.currentDate).toDate()

		switch (op) {
			case "add":
				this.fechaHasta = moment(this.fechaHasta).add(1, unit).toDate()
				this.fechaDesde = moment(this.fechaDesde).add(1, unit).toDate()
				break
			case "sub":
				this.fechaHasta = moment(this.fechaHasta).subtract(1, unit).toDate()
				this.fechaDesde = moment(this.fechaDesde).subtract(1, unit).toDate()
				break
			default:
				break
		}
	}

	unBindEvents() {}

	ngOnDestroy() {
		this.unBindEvents()
	}

	onGuardado() {
		this.item = null
		this.verDetalle = false
		this.editando = false
		this.updateSingleCalendar()
	}

	onCancelado() {
		this.item = null
		this.verDetalle = false
		this.editando = false
	}

	aplicaOpcion(opcion: string) {
		return !this.opcionesFiltroSeleccionadas.length || this.opcionesFiltroSeleccionadas.find((o) => o == opcion)
	}

	get filtroDialogClass() {
		return this.isHome ? "gestor-dialog height-auto small" : "gestor-dialog height-auto medium"
	}

	public buscarComprobante() {
		if (!this.readonly && this.proveedorSeleccionado) this.verBusquedaComprobante = true
	}

	public buscarComprobanteCliente() {
		if (!this.readonly && this.clienteSeleccionado) this.verBusquedaComprobanteCliente = true
	}

	onSelectFactura(event) {
		event.stopPropagation
		this.verBusquedaComprobante = false
		this.item.contexto["idFactura"] = event.data.id
		this.item.contexto["descripcion"] = event.data.descripcion ?? event.data.tipoNumero
		this.item.contexto["proveedor"] = JSON.stringify(this.proveedorSeleccionado)
	}

	onSelectComprobante(event) {
		event.stopPropagation
		this.verBusquedaComprobanteCliente = false
		this.item.contexto["idComprobante"] = event.data.id
		this.item.contexto["descripcion"] = event.data.descripcion
		this.item.contexto["cliente"] = JSON.stringify(Descriptivo.fromData(this.clienteSeleccionado))
	}
}
