import { Descriptivo } from "../common/model/Descriptivo"
import { ProfilePic } from "../common/model/ProfilePic"
import { Ciudad } from "./Ciudad"
import { HotelHabitacion } from "./HotelHabitacion"
import { Proveedor } from "./Proveedor"
import { Provincia } from "./Provincia"

export class Hotel extends Descriptivo {
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public nombre?: string,
		public latitud?: number,
		public longitud?: number,
		public direccion?: string,
		public codigoPostal?: string,
		public provincia?: Descriptivo,
		public ciudad?: Descriptivo,
		public nombreContacto?: string,
		public mailContacto?: string,
		public telefonoContacto?: string,
		public facebook?: string,
		public website?: string,
		public observaciones?: string,
		public activo?: boolean,
		public profilePic: ProfilePic = new ProfilePic(null, "assets/images/default_profile.png"),
		public email?: string,
		public habitaciones: HotelHabitacion[] = [],
		public proveedor?: number,
		public crearProveedor?: boolean,
		public provRelacionado?: Proveedor,
		public checkIn?: Date,
		public checkOut?: Date
	) {
		super(codigo, descripcion, id)
	}

	public static fromData(data: any): Hotel {
		if (!data) return null
		let o: Hotel = new Hotel(
			data.id,
			data.codigo,
			data.descripcion,

			data.nombre,
			data.latitud,
			data.longitud,
			data.direccion,
			data.codigoPostal,
			Provincia.fromData(data.provincia),
			Ciudad.fromData(data.ciudad),
			data.nombreContacto,
			data.mailContacto,
			data.telefonoContacto,
			data.facebook,
			data.website,
			data.observaciones,
			data.activo,
			ProfilePic.fromData(data.profilePic),
			data.email,
			data.habitaciones ? data.habitaciones.map((h) => HotelHabitacion.fromData(h)) : [],
			data.proveedor
		)
		o.checkIn = data.checkIn ? new Date(data.checkIn) : null
		o.checkOut = data.checkOut ? new Date(data.checkOut) : null
		o.version = data.version
		return o
	}
	public get imagen() {
		return this.profilePic
	}

	public get direccionCompleta() {
		let data = [this.direccion, this.provincia ? this.provincia.descripcion : null, this.ciudad ? this.ciudad.descripcion : null]
		return data.filter(Boolean).join(", ")
	}

	public get referencia() {
		return this.descripcion + (this.direccion ? ` - ${this.direccion}` : "")
	}
}
