import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DescriptivosService } from 'src/app/common/services/descriptivos.service';
import { LoadingService } from 'src/app/common/services/loading-data-service.service';
import { Usuario } from './../../common/model/Usuario';
@Injectable()
export class UsuarioService extends DescriptivosService<Usuario>{
  /*constructor(ns: NovedadService, ms?: MessagesService) {
    super(ns, new BehaviorSubject<Usuario[]>([]), ms)
  }*/
  public newEnt(): Usuario {
    return new Usuario();
  }
  public baseName(): string { return "usuario" }
  public parseToEnt(data: any): Usuario {
    return Usuario.fromData(data);
  }
  public updateTokenPush(userId: number, token: string): Promise<any> {
    return this.http.post(this.getBaseURL() + "updateTokenPush", { idUsuario: userId, token: token }).toPromise();
  }
  public validarToken(token: string): Promise<Boolean> {
    return this.http.post(this.getApiURL() + "login/validar-token", token).toPromise().then((r: any) => {
      if (r) {
        return Promise.resolve(r);
      } else {
        return false;
      }

    });
  }

  public actualizarPassword(pass: string, token: string): Promise<Boolean> {
    return this.http.post(this.getApiURL() + "login/actualizarpassword", { token: token, pass: pass }).toPromise().then((r: any) => {
      if (r) {
        return Promise.resolve(r);
      } else {
        return false;
      }

    });
  }

  guardarConArchivo(e: Usuario, file: File, customLoading?: LoadingService) {
    if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
    if (e["id"]) {

      const frmData = new FormData();
      if (file) frmData.append("file", file);
      let vo = this.parseToEnt(e);
      if (!vo.profilePic?.id) {
        vo.profilePic.picPath = null;
        vo.profilePic.picPathVersion = null;
      }
      frmData.append("vo", encodeURIComponent(JSON.stringify(vo.json)));
      const request = new HttpRequest(
        "PUT", this.getBaseURL(),
        frmData,
      );
      return this.http.request(request).toPromise()
        .then((r: HttpResponse<Usuario>) => {
          if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
          return this.parseSingle(r.body)
        }, (e) => this.handleError(e, customLoading));
    } else {
      const frmData = new FormData();
      if (file) frmData.append("file", file);
      let vo = this.parseToEnt(e);
      if (!vo.profilePic?.id) {
        vo.profilePic.picPath = null;
        vo.profilePic.picPathVersion = null;
      }
      frmData.append("vo", encodeURIComponent(JSON.stringify(vo.json)));
      const request = new HttpRequest(
        "POST", this.getBaseURL(),
        frmData,
      );
      return this.http.request(request).toPromise()
        .then((r: HttpResponse<Usuario>) => {
          if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
          return this.parseSingle(r.body)
        }, (e) => this.handleError(e, customLoading));

    }

  }


}