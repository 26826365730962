<div class="parametrico-selector {{ styleClass || '' }}">
	<descriptivo-material-selector
		[permiteNuevo]="permiteNuevo"
		[options]="data | async"
		[label]="finalTitle"
		[limpiable]="limpiable"
		[(ngModel)]="seleccionado"
		[gestor]="gestor ? gestor : defaultGestor"
		[isLazy]="false"
		[placeHolder]="placeHolder"
		[disabled]="disabled"
		[required]="required"
		[name]="name"
		[newEnt]="onNew"
		[appearence]="appearence"
		[floatLabel]="floatLabel"
		[inputControl]="inputControl"
		[readonly]="readonly"
		(onSelect)="seleccionar($event)"
		[itemTemplate]="itemTemplate"
		[noSpace]="noSpace"
		[field]="field"
		[idioma]="idioma"
		[allowDefault]="allowDefault"
		[buscarTraduccion]="buscarTraduccion"
		#descriptivo
	>
	</descriptivo-material-selector>
</div>

<ng-template #defaultGestor let-handler="handler" let-itemEditado="itemEditado">
	<gestor-parametrico
		[service]="service ? service : defaultService"
		(onGuardar)="handler.onGuardado($event)"
		(onCancelar)="handler.onCancelado()"
		[goBack]="false"
		[isModal]="true"
		[title]="('NUEVO' | translate) + ' ' + finalTitle"
		[itemEditado]="itemEditado"
		[editable]="true"
		[readonly]="false"
	>
	</gestor-parametrico>
</ng-template>
