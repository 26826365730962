
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { CentroCosto } from 'src/app/model/CentroCosto';
import { CentroCostoService } from 'src/app/services/centro-costo.service';

@Component({
  selector: 'listado-centro-costo',
  templateUrl: 'listado-centro-costo.component.html',
  styleUrls: ['listado-centro-costo.component.less']
})

export class ListadoCentroCostoComponent extends SessionComponent implements OnInit, OnDestroy {

  public filtro: Filtro = new Filtro("centro-costo_filtros", {}, 0, 10);
  public listado: CentroCosto[] = [];
  public columns = [];
  constructor(messagesService: MessagesService,
    public service: CentroCostoService) {
    super(messagesService)
    console.log(JSON.stringify(this.columns));
  }
  ngOnInit() {
    this.columns = [
      {
        "field": "id",
        "header": "#",
        "class": "id-column",
        "role": "text-tabla",
        "sortable": true,
        "order": 1
      },

      {
        "field": "profilePic",
        "header": "",
        "class": "profile-pic",
        "role": "profile-tabla",
        "sortable": false,
        "order": 40
      },
      {
        "field": "descripcion",
        "header": "Descripción",
        "class": "text-tabla",
        "role": "text-tabla",
        "sortable": true,
        "order": 40
      },

      {
        "field": "esDefault",
        "header": "Es Default",
        "class": "is-boolean",
        "role": "boolean-tabla",
        "sortable": false,
        "order": 40
      },
    ];
  }
  ngOnDestroy() { }



}