import { CanalComunicacion } from "src/app/model/CanalComunicacion"
import { PuntoDeVenta } from "./PuntoDeVenta"

export class CanalComunicacionWhatsapp extends CanalComunicacion {
	public static TIPO = "W"

	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado?: boolean,
		esSistema?: boolean,
		peso?: number,
		esDefault?: boolean,
		puntoDeVenta?: PuntoDeVenta,
		public numero?: string,
		public token?: string,
		lastModifiedBy?: string,
		lastModifiedDate?: Date
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso, esDefault, CanalComunicacionWhatsapp.TIPO, puntoDeVenta, lastModifiedBy, lastModifiedDate)
	}
	public static fromData(data: any): CanalComunicacionWhatsapp {
		if (!data) return null
		const o: CanalComunicacionWhatsapp = CanalComunicacion.fromData(data)
		o.numero = data.numero
		o.token = data.token

		o.version = data.version
		return o
	}
}
