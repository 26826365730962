<span class="fw-500">{{ label | translate }}</span>
<div class="grid pt-3 adjunto">
    <div
        class="col-12 drop-area"
        [ngClass]="{ 'drag-over': isDragging }"
        *ngIf="!readonly && !item?.adjunto"
        id="drag-drop-container"
        (drop)="handleFileDrop($event)"
        (dragover)="allowDrop($event)"
        (dragenter)="handleDragEnter($event)"
        (dragleave)="handleDragLeave($event)"
    >
        <p-fileUpload
            name="files"
            [url]="uploadUrl"
            mode="basic"
            multiple="false"
            #fileInput
            [disabled]="readonly"
            (onError)="onUploadError($event)"
            [accept]="typesAllowed"
            (onUpload)="uploadEnd($event, item)"
            chooseLabel="{{ 'SELECCIONE_O_ARRASTRE_UN_ARCHIVO' | translate }}"
            [maxFileSize]="maxFileSize"
            [auto]="true"
            invalidFileSizeMessageDetail="{{ 'EL_TAMAÑO_MÁXIMO_ES' | translate }}"
            invalidFileSizeMessageSummary="{{ 'TAMAÑO_EXCEDIDO' | translate }} "
            invalidFileTypeMessageSummary="{{ 'TIPO_DE_ARCHIVO_INV_4' | translate }}"
            invalidFileTypeMessageDetail="{{ 'LOS_TIPOS_ACEPTADOS_3' | translate }}"
            invalidFileLimitMessageDetail="{{ 'EL_LIMITE_DE_ARCHIV_11' | translate }} "
            invalidFileLimitMessageSummary="{{ 'LIMITE_DE_ARVHIVOS__7' | translate }}"
        >
        </p-fileUpload>
    </div>
    <div class="col-12 file-wrapper">
        <div class="card" (click)="open(item?.adjunto)" *ngIf="item?.adjunto">
            <div class="grid">
                <div class="col-12 lg:col-10">
                    <span>{{ item.adjunto.descripcion }}</span>
                </div>
                <div class="col-12 lg:col-2 acciones" *ngIf="!readonly">
                    <button pButton (click)="item.adjunto = null" icon="fa fa-times"></button>
                </div>
            </div>
        </div>
    </div>
</div>
