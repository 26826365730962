<div class="listado-orden-pago content">
	<model-list
		[service]="service"
		[modelName]="'orden-pago'"
		[columns]="columns"
		[title]="'ORDEN-PAGO_TITLE' | translate"
		[(listado)]="listado"
		[isLazy]="true"
		[filtro]="filtro"
		[modoSeleccion]="'multiple'"
		[filterContent]="customFilter"
		[footer]="customFooter"
		[acciones]="acciones"
		[menuItemAcciones]="menuAcciones"
		[permiteNuevo]="permiteNuevo"
	>
		<ng-template #customFilter>
			<div class="grid align-content-center">
				<div class="col-12">
					<div class="p-inputgroup">
						<span class="p-float-label">
							<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
							<label>{{ "BUSCAR" | translate }}...</label>
						</span>
						<button type="button" pButton icon="pi pi-search"></button>
					</div>
				</div>
				<div class="col-12" *ngIf="!usuario?.esExterno">
					<descriptivo-material-selector
						[isLazy]="true"
						[service]="provService"
						[label]="'PROVEEDOR' | translate"
						name="proveedor"
						[limpiable]="true"
						[(ngModel)]="filtro.proveedor"
					>
					</descriptivo-material-selector>
				</div>
				<div class="col-12" *ngIf="!usuario?.esExterno">
					<descriptivo-material-selector
						[options]="responsables"
						[label]="'RESPONSABLE' | translate"
						name="responsable"
						[limpiable]="true"
						[(ngModel)]="filtro.responsable"
					>
					</descriptivo-material-selector>
				</div>
				<div class="col-12 p-fluid">
					<div class="grid align-content-center">
						<div class="col-6">
							<span class="p-float-label">
								<p-calendar
									[inputStyleClass]="'no-space-mat-form'"
									[(ngModel)]="filtro.fechaDesde"
									name="fechaDesde"
									dateFormat="dd/mm/yy"
									appendTo="body"
								></p-calendar>
								<label>{{ "DESDE" | translate }}</label>
							</span>
						</div>
						<div class="col-6">
							<span class="p-float-label">
								<p-calendar
									[inputStyleClass]="'no-space-mat-form'"
									[(ngModel)]="filtro.fechaHasta"
									name="fechaHasta"
									dateFormat="dd/mm/yy"
									appendTo="body"
								></p-calendar>
								<label>{{ "HASTA" | translate }}</label>
							</span>
						</div>
					</div>
				</div>
				<div class="col-12 botonera">
					<div class="col text-center" *ngFor="let estado of estadosOrdenes">
						<button
							pButton
							[label]="estado?.descripcion"
							[ngClass]="{ 'no-seleccionado': !filtro?.tieneEstado(estado) }"
							[ngStyle]="{ 'border-bottom-color': estado?.color, 'background-color': estado?.color }"
							(click)="filtro.toggleEstado(estado)"
						></button>
					</div>
					<button
						[ngClass]="{ 'no-seleccionado': !filtro.impagas }"
						pButton
						[label]="'SOLO_IMPAGAS' | translate"
						class="solo-impagas"
						(click)="filtro.impagas = !filtro.impagas"
					></button>
				</div>
			</div>
		</ng-template>
		<ng-template #customFooter>
			<tr>
				<td class="text-right" [attr.colspan]="colspan"><strong>Total:</strong></td>
				<td class="text-right">
					<strong>{{ totalImporte() | currencyCustom }}</strong>
				</td>
				<td></td>
			</tr>
		</ng-template>
	</model-list>
</div>
<p-dialog [(visible)]="verNotificarProveedor" [modal]="true" [style]="{ width: '60vw' }" header="{{ 'Enviar notificación' | translate }}">
	<form *ngIf="formNotificarProveedor" [formGroup]="formNotificarProveedor">
		<selector-email formControlName="destinatarioControl" name="destinatarioControl"></selector-email>
	</form>
	<p-footer>
		<button pButton (click)="verNotificarProveedor = false" label="{{ 'CANCELAR' | translate }}"></button>
		<button pButton cclass="p-btn-success " (click)="notificarProveedor()" label="{{ 'ENVIAR' | translate }}"></button>
	</p-footer>
</p-dialog>
<p-dialog
	[(visible)]="ordenEliminando"
	appendTo="body"
	[modal]="true"
	header="Eliminando Orden de Pago"
	[styleClass]="'cancelacion-dialog'"
	(onShow)="cancelacionShow()"
>
	<div class="grid">
		<div class="col-12">
			<parametrico-selector
				[tipoParametro]="'motivo-cancelacion'"
				#motivoCancelacion="ngModel"
				[(ngModel)]="motivoSeleccionado"
				[limpiable]="true"
				[permiteNuevo]="true"
				name="motivo-cancelacion"
				[titulo]="'MOTIVO_CANCELACION' | translate"
			>
			</parametrico-selector>
		</div>

		<div class="col-12 text-right">
			<button class="col" pButton label="{{ 'CONFIRMAR' | translate }}" (click)="eliminar()" [disabled]="!motivoSeleccionado"></button>
		</div>
	</div>
</p-dialog>
