import { Descriptivo } from "src/app/common/model/Descriptivo"

export class ProductoVariante {
	constructor(
		public habilitado: boolean = true,
		public id?: number,
		public variante?: Descriptivo,
		public producto?: Descriptivo,
		public aclaracion?: string,
		public ocupaLugar?: boolean,
		public version?: number
	) {}
	public static fromData(data: any): ProductoVariante {
		if (!data) return null
		const o: ProductoVariante = new ProductoVariante(
			data.habilitado,
			data.id,
			Descriptivo.fromData(data.variante),
			Descriptivo.fromData(data.producto),
			data.aclaracion,
			data.ocupaLugar,
			data.version
		)

		return o
	}
}
