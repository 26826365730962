import { Component, OnDestroy, OnInit } from "@angular/core"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { SessionComponent } from "./../../../common/components/session-component.component"
import { Filtro } from "./../../../common/model/Filtro"
import { Vehiculo } from "./../../../model/Vehiculo"
import { VehiculoService } from "./../../../services/vehiculo.service"

@Component({
	selector: "listado-vehiculos",
	templateUrl: "listado-vehiculos.component.html",
	styleUrls: ["listado-vehiculos.component.less"]
})
export class ListadoVehiculosComponent extends SessionComponent implements OnInit, OnDestroy {
	public filtro: Filtro = new Filtro("vehiculo_filtros", {}, 0, 10, "peso", 1)
	public listado: Vehiculo[] = []
	public columns = [
		{
			field: "id",
			header: "#",
			class: "id-column",
			role: "text-tabla",
			sortable: true
		},
		{
			field: "profilePic",
			header: "",
			class: "profile-pic",
			role: "profile-tabla",
			sortable: false
		},
		{
			field: "tipoVehiculo",
			header: "Tipo Vehiculo",
			role: "descriptivo-tabla",
			sortable: true
		},
		{
			field: "nombre",
			header: "Nombre",
			role: "text-tabla",
			sortable: true
		},
		{
			field: "patente",
			header: "Patente",
			role: "text-tabla",
			sortable: true
		},
		{
			field: "modelo",
			header: "Modelo",
			role: "text-tabla",
			sortable: true
		},
		{
			field: "capacidad",
			header: "Capacidad",
			role: "text-tabla",
			sortable: true
		},
		{
			field: "fechaVencimientoHabilitacion",
			header: "Fecha Vencimiento Habilitación",
			role: "date-tabla",
			sortable: true
		},
		{
			field: "estadoHoy",
			header: "Estado Hoy",
			role: "text-tabla",
			sortable: false
		}
	]
	constructor(public service: VehiculoService, messagesService: MessagesService) {
		super(messagesService)
	}
	ngOnInit() {
		this.filtro.sortField = "peso"
	}
	ngOnDestroy() {}
}
