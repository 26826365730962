import { Insumo } from "src/app/model/Insumo"
import { MonedaEntorno } from "../app.module"
import { AsociacionInsumo } from "./AsociacionInsumo"
import { Moneda } from "./Moneda"
import { TipoPuesto } from "./TipoPuesto"

export class PuestoInsumo implements AsociacionInsumo {
	constructor(
		public id?: number,
		public insumo?: Insumo,
		public proporcion?: number,
		public cantidad?: number,
		public esPorServicio?: boolean,
		public tipoPuesto?: TipoPuesto,
		public precio: number = 0,
		public precioParticular: boolean = false,
		public moneda: Moneda = MonedaEntorno()
	) {}
	public static fromData(data: any): PuestoInsumo {
		if (!data) return null
		const o: PuestoInsumo = new PuestoInsumo(
			data.id,
			Insumo.fromData(data.insumo),
			data.proporcion,
			data.cantidad,
			data.esPorServicio,
			TipoPuesto.fromData(data.tipoPuesto),
			data.precio,
			data.precioParticular
		)
		o.moneda = Moneda.fromData(data.moneda) || MonedaEntorno()
		return o
	}
	public get descripcion() {
		return this.insumo ? this.insumo.descripcion : ""
	}
}
