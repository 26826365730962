import { Injectable } from "@angular/core"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { Parametrico } from "../model/Parametrico"
import { AuthService } from "./../../services/auth.service"
import { NovedadService } from "./../../services/novedades.service"
import { Filtro } from "./../model/Filtro"
import { LoadingService } from "./loading-data-service.service"
@Injectable({
	providedIn: "root"
})
export class GenericParemtricoService extends ParametricoService<Parametrico> {
	private _paramId: string
	public get paramId(): string {
		return this._paramId
	}
	public set paramId(v: string) {
		this._paramId = v
		this.tipo = v
	}
	public baseName(): string {
		return this.tipo
	}
	public parseToEnt(data: any): Parametrico {
		return Parametrico.fromData(data)
	}
	public newEnt(): Parametrico {
		return new Parametrico()
	}
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService, protected tipo?: string) {
		super(novedadesService, authService, messServ)
		this.paramId = tipo
		if (this.paramId) this.novedadesService.registrarObservador(this.paramId, this)
	}

	getDescriptivos(filter?: Filtro, customLoading?: LoadingService) {
		if (!this.paramId && !this.tipo) return Promise.resolve([])
		return super.getDescriptivos(filter, customLoading)
	}
	getAll(filtro?: Filtro, customLoading?: LoadingService): Promise<Parametrico[]> {
		if (!this.paramId) return Promise.resolve([])
		return super.getData(filtro, customLoading)
	}

	getHabilitados(filtro?: Filtro, customLoading?: LoadingService): Promise<Parametrico[]> {
		if (!this.paramId) return Promise.resolve([])
		return super.getHabilitados(filtro, customLoading)
	}

	protected loadData(customLoading?: LoadingService) {
		if (!this.paramId) return Promise.resolve([])
		return super.loadData(customLoading)
	}
}
