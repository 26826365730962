<div id="grupo{{ itemReserva.id }}">
	<div class="grupo-container" style="height: auto">
		<div class="grupo {{ error }} grid" *ngIf="itemReserva">
			<div class="col-12 logo print-only">
				<img src="{{ mainLogo }}" alt="" />
			</div>
			<!-- <button pButton tooltipPosition="bottom" pTooltip="{{ 'IMPRIMIR' | translate }}" class="print-button"
				icon="fa fa-print" printSectionId="grupo{{ itemReserva.id }}" ngxPrint [useExistingCss]="true"
				></button> -->
			<ng-container *ngTemplateOutlet="fullSize; context: { $implicit: itemReserva }"></ng-container>
		</div>
	</div>
</div>

<ng-template #fullSize let="itemReserva">
	<div class="col-12 titulo-grupo">
		<span class="titulo" tooltipPosition="bottom"
			pTooltip="{{ itemReserva?.hotel?.descripcion }} {{itemReserva?.producto?.descripcion}}">{{
			itemReserva?.hotel?.descripcion }} - {{ itemReserva?.producto?.descripcion }}</span>
	</div>

	<div class="col-12 data-content">
		<div class="grid justify-content-start grid-nogutter">
			<div class="col-12 item-reserva">
				<div class="card">
					<div class="grid align-content-center">
						<div class="col-12 idiomas small-gap">
							<i class="fa fa-user mr-2"></i>
							<span class="grey">{{ itemReserva.cantidadPax }} pax</span>
							<img src="{{ itemReserva?.idiomaServicio?.imagenUrl }}" alt="" class="idioma" />
							<span class="separado grey"> {{ itemReserva?.idiomaServicio?.descripcion }}</span>
						</div>
						<div class="col-12 small-gap">
							<i class="fa fa-clock-o"></i><span class="">Check In: {{ itemReserva?.checkIn | date :
								"dd/MM/yyyy HH:mm" :
								"UTC-3" }} hs</span>
						</div>
						<div class="col-12 small-gap">
							<i class="fa fa-clock-o"></i><span class="">Check Out: {{ itemReserva?.checkOut | date :
								"dd/MM/yyyy HH:mm" :
								"UTC-3" }} hs</span>
						</div>
						<div class="col-12 small-gap" *ngIf="itemReserva?.hotel">
							<i class="fa fa-map-marker"></i><span class="separado grey">{{
								itemReserva?.hotel?.direccion }}</span>
						</div>

						<div class="col-12 small-gap" *ngIf="itemReserva?.restricciones?.length">
							<i class="fa fa-exclamation-triangle"></i>
							<span class="chips text-center grey" *ngFor="let r of itemReserva.restricciones">{{
								r.descripcion }}</span>
						</div>
						<div class="col-12 small-gap" *ngIf="itemReserva?.notaCliente">
							<div class="grid grid-nogutter">
								<div class="col-fixed">
									<i class="fa fa-comment-o iblock"></i>
								</div>
								<div class="col">
									<span class="grey">
										{{ itemReserva?.notaCliente }}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 small-gap" *ngIf="itemReserva?.productosAsociados?.length">
				<span class="titulo">{{ "INCLUYE" | translate }}</span>
			</div>
			<div class="col-12 small-gap" *ngIf="itemReserva?.productosAsociados?.length">
				<div class="grid">
					<div class="item-puesto col card-wrapper" *ngFor="let producto of itemReserva.productosAsociados">
						<div class="card">
							<div class="grid grid-nogutter">
								<div class="col">
									<span>{{ producto?.producto?.descripcion }}</span>
								</div>
								<div class="col-fixed text-right">
									<div class="badge">{{ producto?.cantidad }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>