import { environment } from './../environments/environment';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppComponent } from './app.component';
import { DefaultService } from './common/services/default.service';
import { ConfiguracionUsuarioService } from './services/configuracion-usuario.service';
import { ConfiguracionEntornoService } from './services/configuracion-entorno.service';
import { DEF_LOGO } from './app.module';

@Component({
    selector: 'app-inline-profile',
    templateUrl: "app.profile.component.html",
    styleUrls: ['app.profile.component.less'],
    animations: [
        trigger('menu', [
            state('hidden', style({
                height: '0px'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppInlineProfileComponent implements OnInit {
    @ViewChild("op", { static: true }) op;
    _items: MenuItem[] = [];
    active: boolean = true;
    private sus: Subscription;
    public mainLogo: string = DEF_LOGO
    constructor(public app: AppComponent, private router: Router, private dfService: DefaultService,
        private ceService:ConfiguracionEntornoService,
        private confService: ConfiguracionUsuarioService) {

    }
    ngOnInit() {
        this.ceService.getConfiguracion().then(r=>{
            this.mainLogo = r.profilePic?.picPath || "assets/image/iterpax.png"
        })

    }

    public editarPerfil() {
        if (!this.app.usuario) return;
        let query: NavigationExtras = {
            queryParams: {
                "id": this.app.usuario.id
            }
        }
        this.router.navigate(["usuario/edit"], query)
    }
    onClick(event) {
        this.active = !this.active;
        setTimeout(() => {
            this.app.layoutMenuScrollerViewChild.moveBar();
        }, 450);
        event.preventDefault();
    }
    public isMobile() {
        return window.innerWidth <= 640;
    }

    public get usuario() {
        return this.app.usuario;
    }

}
