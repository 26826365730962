import { PuntoDeVenta } from 'src/app/model/PuntoDeVenta';


import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { ProfilePic } from 'src/app/common/model/ProfilePic';
import { FileData } from './FileData';


export class CentroCosto extends Descriptivo {
	constructor(id?: number, codigo?: string, descripcion?: string,

		public domicilioFiscal?: string,
		public profilePic: ProfilePic = new ProfilePic(null, 'assets/images/default_profile.png'),
		public telefono?: string,
		public sitioWeb?: string,
		public esDefault: boolean = false,
		public puntoDeVenta?: PuntoDeVenta


	) {
		super(codigo, descripcion, id)
	}

	public static fromData(data: any): CentroCosto {
		if (!data) return null;
		const o: CentroCosto = new CentroCosto(
			data.id,
			data.codigo,
			data.descripcion,
			data.domicilioFiscal,
			ProfilePic.fromData(data.profilePic),
			data.telefono,
			data.sitioWeb,
			data.esDefault,
			PuntoDeVenta.fromData(data.puntoDeVenta)

		);
		o.version = data.version; 
		return o;

	}

}