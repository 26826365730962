import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { ConfirmationService } from "primeng/api"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { Parametrico } from "./../../../common/model/Parametrico"
import { GenericParemtricoService } from "./../../../common/services/generic-parametrico.service"
import { NovedadService } from "./../../../services/novedades.service"

@Component({
	selector: "gestor-parametrico",
	templateUrl: "gestor-parametrico.component.html",
	styleUrls: ["gestor-parametrico.component.less"]
})
export class GestorParametricoComponent extends SessionComponent implements OnDestroy, OnInit {
	@Input()
	public editable: boolean = true

	@Input()
	public goBack: Boolean = true
	@Input()
	public readonly: boolean = false
	@Input()
	public isModal: Boolean = false
	@Output()
	public onGuardar: EventEmitter<Parametrico> = new EventEmitter<Parametrico>()

	public defTitulo: string = ""

	@Input()
	public title: string

	@Output()
	public onCancelar: EventEmitter<boolean> = new EventEmitter<boolean>()

	private _tipo: string
	public get tipo(): string {
		return this._tipo
	}
	@Input()
	public set tipo(v: string) {
		this._tipo = v
		if (v && !this._service) {
			this.defaultService = new GenericParemtricoService(this.noverdadService, this.authService)
			this.defaultService.paramId = v
		}
	}

	private defaultService: GenericParemtricoService

	private _service: ParametricoService<any>
	public get service(): ParametricoService<any> {
		return this._service ? this._service : this.defaultService
	}
	@Input()
	public set service(v: ParametricoService<any>) {
		this._service = v
	}

	constructor(private confService: ConfirmationService, messagesService: MessagesService, private noverdadService: NovedadService) {
		super(messagesService)
	}

	private _itemEditado: Parametrico = new Parametrico()
	public get itemEditado(): Parametrico {
		return this._itemEditado
	}
	@Input()
	public set itemEditado(v: Parametrico) {
		this._itemEditado = v
		if (!v) {
			this._itemEditado = new Parametrico()
		}
	}

	public isValid(item) {
		let valido = true
		if (item.descripcion == undefined || !item.descripcion) {
			valido = this.error("Indique una descripción")
		}
		return valido
	}
	ngOnInit() {}
	ngOnDestroy() {}

	public onGuardado(item: Parametrico) {
		this.onGuardar.emit(item)
	}
	public onCancelado() {
		this.onCancelar.emit()
	}
}
