<div class="content-badge" [ngClass]="{ condetalle: verDetalle, rechazado: estado == 'puesto-danger' }">
	<div
		class="circular-badge {{ estadoDisplay?.codigo || 'sin-estado' }} {{ size }} "
		tooltipPosition="bottom"
		pTooltip="{{ tooltip }}"
		[ngClass]="{ 'sin-asignar': !proveedor }"
		[ngStyle]="{ 'background-image': 'url(' + urlImagen + ')' }"
		(click)="click($event, puesto)"
	></div>
	<div class="detalle grid grid-nogutter {{ size }}" *ngIf="verDetalle">
		<label class="col-12" for="" *ngIf="puesto?.tipoPuesto">{{ puesto?.tipoPuesto?.descripcion }}</label>
		<span class="col-12" *ngIf="proveedor">{{ proveedor?.descripcion }}</span>
	</div>
</div>

<p-dialog [(visible)]="opciones" [resizable]="false" [modal]="true" [closable]="true" appendTo="body" #dialogo [styleClass]="'puesto-selector-dialog'">
	<p-header>
		<div class="grid">
			<div class="col-12 puesto-opcion seleccion">
				<opcion-puesto
					[opcion]="opcionSeleccionada"
					[seleccion]="true"
					[puesto]="puesto"
					[permiteGestionCostos]="permiteGestionCostos"
					[deseleccionar]="deseleccionar"
					(onVerCostos)="verCostos($event)"
				></opcion-puesto>
			</div>
		</div>
	</p-header>

	<div class="grid grid-nogutter">
		<div class="col-12">
			<div class="p-inputgroup">
				<span class="p-float-label">
					<input [(ngModel)]="buscador" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
					<label>{{ "BUSCAR" | translate }}...</label>
				</span>
				<button type="button" pButton icon="pi pi-search"></button>
			</div>
		</div>
		<div class="col-12">
			<div class="grid grid-nogutter opciones">
				<div class="col-12 text-center" *ngIf="!opciones?.length">
					<span class="block p-4 text-center">{{ "NO_HAY_PROVEEDORES_CON_EL_PUESTO_ELEGIDO" | translate }}</span>
				</div>
				<div
					class="col-12 puesto-opcion"
					*ngFor="let opcion of opciones | descriptivoPipe : buscador"
					(click)="seleccionarOpcion(opcion)"
					[ngClass]="{ ocupado: opcion.ocupacion?.length, ausente: opcion.ausencia?.id, noHabitual: opcion.proveedor.noHabitual }"
				>
					<!-- <ng-container *ngTemplateOutlet="opcionTemplate; context: { opcion: opcion, seleccion: false, puesto: puesto }"> </ng-container> -->
					<opcion-puesto [opcion]="opcion" [seleccion]="false" [puesto]="puesto"></opcion-puesto>
				</div>
				<div class="col-12 p-2" *ngIf="permiteNoCompatibles">
					<descriptivo-material-selector
						[service]="proveedorService"
						[(ngModel)]="proveedor"
						[ngModelOptions]="{ standalone: true }"
						(onSelect)="seleccionarOtroProveedor($event)"
						[isLazy]="true"
						[label]="'TODOS' | translate"
						[limpiable]="true"
						[permiteNuevo]="false"
						[readonly]="readonly"
					>
					</descriptivo-material-selector>
				</div>
			</div>
		</div>

		<!--div class="grid" *ngFor="let opcion of opciones | descriptivoPipe : buscador "
            [ngClass]="{'ocupado': opcion?.ocupacion.length > 0}">
            <div class="col-3" (click)="seleccionarOpcion(opcion)">
                <div class="circular-badge"
                    [ngStyle]="{'background-image': 'url('+opcion?.proveedor.imagen+'), url('+puesto?.imagen+')'}">
                </div>
            </div>
            <div class="col-9">
                <div class="col-12" >
                    <span class="detalle overflow">{{opcion?.proveedor.nombre}}</span>
                </div>
                <div class="col-12 text-right">
                    <span-list [value]="opcion.ocupacion"></span-list>
                </div>
            </div>
        </div-->
	</div>
	<p-footer *ngIf="solicitarInsumos">
		<button (click)="cancelar($event)" pButton label="{{ 'CANCELAR' | translate }}" class="p-button-danger"></button>
		<button (click)="guardarSeleccion()" pButton label="{{ 'APLICAR' | translate }}" [disabled]="!proveedor" class="p-button-success"></button>
	</p-footer>
</p-dialog>

<!-- <ng-template #opcionTemplate let-opcion="opcion" let-seleccion="seleccion" let-puesto="puesto">

</ng-template> -->
