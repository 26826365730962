import { Location } from "@angular/common"
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router"
import { ConfirmationService, MenuItem } from "primeng/api"
import { FileUpload } from "primeng/fileupload"
import { Subscription } from "rxjs"
import { MonedaEntorno } from "src/app/app.module"
import { ModelGestorComponent } from "src/app/common/components/model-gestor/model-gestor.component"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Identificable } from "src/app/common/model/Identficable"
import { Parametrico } from "src/app/common/model/Parametrico"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Cobranza } from "src/app/model/Cobranza"
import { Comprobante } from "src/app/model/Comprobante"
import { EstadoDocumento } from "src/app/model/EstadoDocumento"
import { ItemComprobante } from "src/app/model/ItemComprobante"
import { Moneda } from "src/app/model/Moneda"
import { Nacionalidad } from "src/app/model/Nacionalidad"
import { Producto } from "src/app/model/Producto"
import { TipoComprobante } from "src/app/model/TipoComprobante"
import { TipoIVA } from "src/app/model/TipoIVA"
import { Cobro } from "src/app/model/cobros/Cobro"
import { CurrencyCustomPipe } from "src/app/pipes/currencyCustom.pipe"
import { CentroCostoService } from "src/app/services/centro-costo.service"
import { ClienteService } from "src/app/services/cliente.service"
import { CobranzaService } from "src/app/services/cobranza.service"
import { ComprobanteService } from "src/app/services/comprobante.service"
import { CotizacionService } from "src/app/services/cotizacion.service"
import { MonedaService } from "src/app/services/moneda.service"
import { ProductoService } from "src/app/services/producto.service"
import { PuntoDeVentaService } from "src/app/services/punto-de-venta.service"
import { TipoComprobanteService } from "src/app/services/tipo-comprobante.service"
import { TipoIVAGrabadoService } from "src/app/services/tipo-iva-grabado.service"
import { Cliente, ClienteResumen } from "./../../../model/Cliente"
import { ComprobanteResumen } from "./../../../model/Comprobante"
import { NacionalidadService } from "./../../../services/nacionalidad.service"
import { TipoIVAService } from "./../../../services/tipo-iva.service"

@Component({
	selector: "gestor-comprobante",
	templateUrl: "gestor-comprobante.component.html",
	styleUrls: ["gestor-comprobante.component.less"]
})
export class GestorComprobanteComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	public menuItemAcciones: MenuItem[] = []
	@ViewChild("fileInput", { static: false })
	private fileUpload: FileUpload

	@Input()
	public item: Comprobante
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false
	@ViewChild("modelGestor", { static: false })
	modelGestor: ModelGestorComponent
	@Output()
	public onGuardado: EventEmitter<Comprobante> = new EventEmitter<Comprobante>()

	@Output()
	public onCancelado = new EventEmitter()
	private productoNota: Producto
	public clienteOptions: Descriptivo[] = []
	public centroCostoOptions: Descriptivo[] = []
	public itemComprobanteOptions: Descriptivo[] = []
	public usuarioOptions: Descriptivo[] = []
	public verDialogConvertir: boolean = false
	public editando: Comprobante
	private _readonly: boolean
	public comprobante: Comprobante
	public get readonly(): boolean {
		return this._readonly
	}
	@Input()
	public set readonly(v: boolean) {
		this._readonly = v
	}

	public get simboloMoneda() {
		return this.item?.moneda ? this.item.moneda?.simbolo + " " : "$ "
	}
	public cobros: Cobro[] = []
	public cobranzas: Cobranza[] = []
	public verBusquedaComprobante: boolean = false

	@Input()
	public verAcciones: boolean = true
	public asociado: Comprobante
	public tipoComprobante: TipoComprobante
	public comprobantesOficiales: TipoComprobante[] = []
	public monedaEntonro: Moneda = MonedaEntorno()
	verDialogoNota: boolean = false
	esIntegrado: boolean = false

	@Input()
	public hideTitle: boolean = false
	constructor(
		messagesService: MessagesService,
		public service: ComprobanteService,
		private route: ActivatedRoute,
		public monedaService: MonedaService,
		private router: Router,
		private confService: ConfirmationService,
		public tipoComprobanteService: TipoComprobanteService,
		public tipoIVAGrabadoService: TipoIVAGrabadoService,
		public clienteService: ClienteService,
		protected _location: Location,
		public cobranzaService: CobranzaService,
		public productoService: ProductoService,
		private centroCostoService: CentroCostoService,
		public cotizacionService: CotizacionService,
		public puntoDeVentaService: PuntoDeVentaService,
		public tipoIvaService: TipoIVAService,
		public nacionalidadService: NacionalidadService
	) {
		super(messagesService)
		this.tipoComprobante = this.router.getCurrentNavigation()?.extras?.state?.tipoComprobante
		this.asociado = this.router.getCurrentNavigation()?.extras?.state?.asociado
	}
	public uploadUrl: string
	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}
	public get esNota() {
		return this.item?.tipoComprobante?.esNota
	}
	public async isValid() {
		if (this.item.cliente?.activo === false && !this.item?.id) {
			return this.error("El cliente se encuentra inhabilitado")
		}
		return true
	}
	public onEdit() {
		if (this.item?.id)
			this.router.navigate(["comprobante/edit"], {
				queryParams: { id: this.item.id }
			})
	}
	private prevTipo: TipoComprobante
	public actualizarTipoComprobante(tipo: TipoComprobante) {
		if (tipo?.id != this.prevTipo?.id) {
			if (tipo?.esNota) {
				this.agregarItemNota()
			} else if (this.prevTipo?.esNota) {
				this.quitarItemNota()
			}
		}
		this.prevTipo = tipo
	}
	public get soloCabecera() {
		return !this.readonly && (!this.item.estado || !this.item.id || EstadoDocumento.esEditable(this.item.estado) || this.esAdministrador)
	}
	public esEditable() {
		return this.item && !this.item.cae && (!this.item.id || !this.item.estado || EstadoDocumento.esEditable(this.item.estado))
	}
	public async agregarItemNota() {
		if (!this.productoNota) {
			this.productoNota = await this.productoService.getByCodigo(Producto.NOTA)
		}
		if (this.asociado) {
			this.item.items = this.asociado.items.map((i) => {
				const nItem = ItemComprobante.fromData(i)
				nItem.cantidad = nItem.cantidad || 1
				nItem.id = null
				nItem.descuento = 0
				nItem.valorDescuento = 0

				return nItem
			})
		} else {
			this.item.items = [new ItemComprobante(null, this.productoNota, this.productoNota.descripcion, this.tipoIVAGrabadoService.default, 0, 0, 1, 0)]
		}
	}

	public get discriminaIva() {
		return this.item?.tipoComprobante?.discriminaIva
	}
	public async quitarItemNota() {
		this.item.items = []
	}
	public buscarComprobante() {
		if (!this.readonly) this.verBusquedaComprobante = true
	}

	public onComprobanteSelecciondo(comp: ComprobanteResumen[]) {
		this.item.comprobanteAsociado = comp ? Descriptivo.fromData(comp[0]) : null
		this.verBusquedaComprobante = false
	}
	public updateAcciones() {
		this.menuItemAcciones = [
			{
				label: "Cobrar",
				icon: "fa fa-money",
				visible: this.item.saldo > 0 && this.item.id != undefined,
				command: () => {
					this.generarCobranza()
				}
			},
			/*{
				label: "Enviar comprobante",

				command: () => { this.enviarComprobante() }
			},*/
			/*{
				label: "Ver comprobante",
				command: () => { this.verFactura() }
			},*/
			{
				label: "Descargar comprobante",
				icon: "fa fa-download",
				visible: this.item?.id != undefined,
				command: () => {
					if (!this.item?.esDocumentoExterno) {
						this.service.descargar(this.item.id, this.item.numeroComprobanteCalc + "")
					} else {
						window.open(this.item?.adjunto?.url, "_blank")
					}
				}
			},
			{
				label: "Convertir comprobante",
				icon: "fa fa-exchange",
				visible: !this.item?.tipoComprobante?.esOficial && !this.item.cae,
				command: () => {
					this.verConvertir(this.item)
				}
			},

			{
				label: "Ver comprobante PDF",
				icon: "fa fa-file-pdf-o",
				visible: this.item?.id != undefined,
				command: () => {
					if (!this.item?.esDocumentoExterno) {
						this.service.verPDF(this.item.id)
					} else {
						window.open(this.item?.adjunto?.url, "_blank")
					}
				}
			},
			{
				label: "Generar Nota de Crédito",
				icon: "fa nc",
				visible: this.item?.id != undefined && !this.item?.tipoComprobante?.esNota,
				command: () => {
					this.generarNotaCredito()
				}
			},
			{
				label: "Generar Nota de Débito",
				icon: "fa nd",
				visible: this.item?.id != undefined && !this.item?.tipoComprobante?.esNota,
				command: () => {
					this.generarNotaDebito()
				}
			},

			{
				label: "Anular",
				icon: "fa fa-ban",
				visible: this.item?.id != undefined,
				command: () => {
					this.eliminar()
				}
			},

			{
				label: "Volver",
				icon: "fa fa-step-backward",
				command: () => {
					this.volver()
				}
			}
		]
		if (this.item?.id) {
			this.menuItemAcciones.push({
				label: this.translateService.get("Clonar"),
				icon: "fa fa-copy",
				visible: this.item?.id != undefined && !this.item?.tipoComprobante?.esNota,
				command: () => {
					this.clonar(this.item)
				}
			})
		}
		this.editable = this.esEditable()
	}
	public clonar(item: Identificable) {
		let query: NavigationExtras = {
			queryParams: {
				id: item.id,
				clonar: true
			}
		}
		this.router.navigate(["comprobante/clonar"], query)
	}
	private tiposComprobante: TipoComprobante[] = []
	public opcionesTipoComprobante: TipoComprobante[] = []
	ngOnInit() {
		this.uploadUrl = this.service.getApiURL() + "fileUpload/adjuntos"
		this.clienteService.getDescriptivos().then((r) => {
			this.clienteOptions = r
		})
		this.tipoComprobanteService.getHabilitados().then((r) => {
			this.tiposComprobante = r
			this.comprobantesOficiales = r.filter((tc) => tc.esOficial && tc.codigo.startsWith("F"))
			this.opcionesTipoComprobante = r.filter((tc) => !this.item?.id || !tc.esOficial || tc.codigo != TipoComprobante.PAGO_ADELANTADO_CODIGO)
		})
		this.centroCostoService.getDescriptivos().then((r) => {
			this.centroCostoOptions = r
		})
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
				}
			})
		)
		if (this.route.snapshot.url.some((u) => u.path == "comprobante")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				let clone: boolean = <boolean>params["clonar"]
				if (!this.service) return
				if (id) {
					this.service.getById(id).then((r) => {
						this.item = clone ? r.clonar() : r
						if (this.item?.id) {
							this.prevTipo = clone ? null : this.item.tipoComprobante
							this.opcionesTipoComprobante = this.tiposComprobante.filter(
								(tc) => !tc.esOficial && tc.codigo != TipoComprobante.PAGO_ADELANTADO_CODIGO
							)
							this.cobranzaService.getByComprobante(this.item.id).then((r) => {
								this.cobranzas = r
							})
							this.updateAcciones()
						}
					})
				} else {
					this.item = this.service ? this.service.newEnt() : null
					if (this.tipoComprobante) {
						this.item.tipoComprobante = this.tipoComprobante
						this.actualizarTipoComprobante(this.tipoComprobante)
					}
					if (this.asociado) {
						this.item.comprobanteAsociado = Descriptivo.fromData(this.asociado)
						this.item.cliente = ClienteResumen.fromData(this.asociado.cliente)
						this.item.moneda = this.asociado.moneda
						this.item.centroCosto = this.asociado.centroCosto
						this.item.reserva = this.asociado.reserva
						this.item.puntoDeVenta = this.asociado.puntoDeVenta
						this.item.datosFacturacionDistintos = this.asociado.datosFacturacionDistintos
						if (this.item.datosFacturacionDistintos) {
							this.item.tipoClienteFacturacion = this.asociado.tipoClienteFacturacion
							this.item.emailFacturacion = this.asociado.emailFacturacion
							this.item.nombreFacturacion = this.asociado.nombreFacturacion
							this.item.numeroDocumentoFacturacion = this.asociado.numeroDocumentoFacturacion
							this.item.tipoDocumentoFacturacion = this.asociado.tipoDocumentoFacturacion
							this.item.ivaFacturacion = this.asociado.ivaFacturacion
							this.item.domicilioFacturacion = this.asociado.domicilioFacturacion
							this.item.nacionalidadFacturacion = this.asociado.nacionalidadFacturacion
						}
					}
					this.updateAcciones()
				}
			})
		}
	}
	public irACobranza(item: Cobranza) {
		if (!item?.id) return this.error("No hay comprobante de cobranza")
		this.router.navigate(["cobranza/vista"], { queryParams: { id: item?.id } })
	}
	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}
	async generarNotaCredito() {
		if (!this.item?.id) return

		this.router.navigate(["comprobante/nuevo"], {
			state: {
				asociado: this.item,
				tipoComprobante: await this.tipoComprobanteService.getByCodigo("NC" + this.item?.tipoComprobante?.letra)
			}
		})
	}

	async generarNotaDebito() {
		if (!this.item?.id) return

		this.router.navigate(["comprobante/nuevo"], {
			state: {
				asociado: this.item,
				tipoComprobante: await this.tipoComprobanteService.getByCodigo("ND" + this.item?.tipoComprobante?.letra)
			}
		})
	}
	public volver() {
		this._location.back()
	}
	public eliminar() {
		this.confService.confirm({
			key: "genConf",
			header: "Eliminar",
			message: this.translateService.get("ELIMINAR_COMRPOBANTE").replace("{$1}", this.item.id),
			accept: () => {
				let $this = this
				$this.service
					.eliminar(this.item.id)
					.then((res) => {
						//this.filtro.forceUpdate();
						this.success(this.translateService.get("COMPROBANTE_ANULADO"))
						this.volver()
					})
					.catch((e) => {
						this.service.getNotaGenerada(this.item.id).then(async (res: any[]) => {
							if (res?.length > 0) {
								this.error("Se generó una nota de crédito por este comprobante. N°: " + res[0]["numeroComprobante"] + " cae: " + res[0]["cae"])
								this.item = await this.service.getById(this.item.id)
							}
						})
					})
			}
		})
	}
	generarCobranza() {
		if (this.item.saldo <= 0) return this.error(this.translateService.get("No se puede cobrar la reserva. No tiene saldo"))
		this.router.navigate(["cobranza/nuevo"], {
			state: { comprobantes: [this.item.id] }
		})
	}
	public enviarComprobante() {}
	public verFactura() {}

	public ejecutarConversion(comp: Comprobante) {
		if (!comp.tipoComprobante?.esOficial) return this.error("Indique un comprobante del tipo Factura")
		this.service.convertir(comp).then((r) => {
			this.item = r
			this.success("Comprobante convertido")
			this.cancelarConversion()
		})
	}
	public cancelarConversion() {
		this.verDialogConvertir = false
		this.editando = null
		this.updateAcciones()
	}
	public verConvertir(comp: Comprobante) {
		this.editando = Comprobante.fromData(comp)
		this.editando.tipoComprobante = null
		this.verDialogConvertir = true
	}
	public guardarItem(item: ItemComprobante) {
		this.item.agregarItem(item)
		item.subcategorias.forEach((sc) => {
			this.item.agregarItem(sc)
		})
		item.subcategorias = []
		this.item.eliminarSinReferencia()
	}

	public getItemComprobante = (item: ItemComprobante) => {
		const items = this.item ? this.item.items.filter((i) => i.referenciaPadre === item.referenciaPadre && i != item) : []
		item.subcategorias = items
		return item
	}
	public onMonedaChange(event) {
		if (this.item?.moneda?.codigo == "D") {
			this.cotizacionService.getCotizacion(this.item.moneda, new Moneda(null, "P")).then((c) => {
				this.item.cotizacionAfip = c.valor
			})
		}
	}

	public get esExtranjero(): boolean {
		return this.item && (!this.item.nacionalidadFacturacion || this.item.nacionalidadFacturacion.codigo != "AR")
	}

	public updateCuit() {
		if (!this.item.tipoClienteFacturacion || !this.item.nacionalidadFacturacion) {
			return
		}
		if (this.esExtranjero) {
			this.tipoIvaService.getByCodigo(TipoIVA.CONSUMIDOR_FINAL).then((r) => {
				this.item.ivaFacturacion = r
			})
			if (!this.item.id) this.item.tipoDocumentoFacturacion = new Parametrico(null, Cliente.DOCUMENTO_CUIT, "CUIT")
			this.nacionalidadService.getById(this.item.nacionalidadFacturacion.id).then((n: Nacionalidad) => {
				switch (this.item.tipoClienteFacturacion.codigo) {
					case "EMP":
						this.item.numeroDocumentoFacturacion = n.cuitJuridica

						break
					case "PART":
						this.item.numeroDocumentoFacturacion = n.cuitFisica
						break
					default:
						this.item.numeroDocumentoFacturacion = n.cuitOtros
						break
				}
			})
		}
	}

	guardar = async (goBack) => {
		const $this = this
		const p = await this.puntoDeVentaService.getById(this.item.puntoDeVenta.id)
		this.item.items.forEach((i) => (i.subcategorias = []))
		const aceptar = () => $this.modelGestor.defaultGuardar(goBack)
		if (this.esNota && this.item?.total == this.asociado?.total) {
			this.confService.confirm({
				header: "Alerta Nota",
				message: this.translateService.get("MENSAJE_NOTA_ASOCIADO"),
				key: "genConf2",
				accept: () => {
					return this.modelGestor.defaultGuardar(goBack)
				}
			})
		} else if (!this.item.id && p.maximoFacturacion > 0 && p.maximoFacturacion <= this.item.totalALocal) {
			this.confService.confirm({
				key: "genConf",
				header: this.translateService.get("Facturación máxima superada"),
				message: this.translateService.get("ESTA_POR_SUPERAR_MAXIMO_FACTURACION").replace("${1}", CurrencyCustomPipe.format(p.maximoFacturacion)),
				accept: aceptar
			})
		} else {
			aceptar()
		}
	}

	checkEsIntegrado(cli) {
		this.clienteService.checkEsIntegrado(cli.id).then((esIntegrado) => {
			this.esIntegrado = esIntegrado
		})
	}
}
