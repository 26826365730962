import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { Filtro } from "src/app/common/model/Filtro"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { Hotel } from "src/app/model/Hotel"
import { MessagesService } from "../common/services/messages-data-service.service"
import { BufferedService } from "./BufferedService"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class HotelService extends BufferedService<Hotel> {
	constructor(protected novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "hotel"
	}
	public parseToEnt(data: any): Hotel {
		return Hotel.fromData(data)
	}
	public newEnt(): Hotel {
		return new Hotel()
	}

	public getDescriptivos(filter?: Filtro, customLoading?: LoadingService) {
		return this.getAll(filter, customLoading)
	}

	public getHabilitados = (filter?: Filtro, customLoading?: LoadingService) => {
		return this.getAll(filter, customLoading).then((r) => r.filter((h) => h.activo))
	}
	public getConProveedor = (filter?: Filtro, customLoading?: LoadingService) => {
		return this.getHabilitados(filter, customLoading).then((r) => r.filter((h) => h.proveedor != null))
	}
	getByProveedor(id: number): Promise<Hotel> {
		return lastValueFrom(this.http.get(this.getBaseURL() + "byProveedor/" + id)).then((r) => this.parseSingle(r))
	}
}
