import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { CategoriaHospedaje } from 'src/app/model/CategoriaHospedaje';
import { ParametricoService } from 'src/app/common/services/parametricos.service';
import { LoadingService } from './../common/services/loading-data-service.service';
import { NovedadService } from 'src/app/services/novedades.service';
import { AuthService } from 'src/app/services/auth.service';
@Injectable({
  providedIn: 'root',
})
export class CategoriaHospedajeService extends ParametricoService<CategoriaHospedaje>{
	constructor(public novedadesService: NovedadService, public authService: AuthService) {
		super(novedadesService, authService)
	}
    public baseName(): string {
        return "categoria-hospedaje";
    }
    public parseToEnt(data: any): CategoriaHospedaje {
        return CategoriaHospedaje.fromData(data);
    }
    public newEnt(): CategoriaHospedaje {
        return new CategoriaHospedaje();
    }
 public actualizarDescripcion(data: CategoriaHospedaje, customLoading?: LoadingService) {
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        var p = this.http.put(this.getBaseURL("actualizar-descripcion"), data).toPromise();
        p.then(r => {
            this.loadData(customLoading);
            if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
        });
        return p
    };


}