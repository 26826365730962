import { Component, OnInit } from "@angular/core"
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms"
import { ActivatedRoute, Params, Router } from "@angular/router"
import * as moment from "moment"
import { first } from "rxjs/operators"
import { DEF_LOGO } from "src/app/app.module"
import { ProfilePic } from "src/app/common/model/ProfilePic"
import { Usuario } from "src/app/common/model/Usuario"
import { Consentimiento, ItemConsentimiento } from "src/app/model/Consentimiento"
import { EmailTemplate } from "src/app/model/EmailTemplate"
import { FamiliaProducto } from "src/app/model/FamiliaProducto"
import { GrupoReserva } from "src/app/model/GrupoReserva"
import { Idioma } from "src/app/model/Idioma"
import { Pax } from "src/app/model/Pax"
import { ReservaConsentimiento } from "src/app/model/ReservaConsentimiento"
import { TipoProducto } from "src/app/model/TipoProducto"
import { AuthService } from "src/app/services/auth.service"
import { ConfiguracionEntornoService } from "src/app/services/configuracion-entorno.service"
import { ConsentimientoService } from "src/app/services/consentimiento.service"
import { NacionalidadService } from "src/app/services/nacionalidad.service"
import { environment } from "src/environments/environment"
import { Descriptivo } from "./../../common/model/Descriptivo"
import { MessagesService } from "./../../common/services/messages-data-service.service"
import { TranslateService } from "./../../common/services/translate.service"
import { ItemReservaConsentimiento } from "./../../model/ItemReservaConsentimiento"
import { EmailTemplateService } from "./../../services/emailtemplate.service"
import { FamiliaConsentimiento } from "./FamiliaConsentimiento"

@Component({
	selector: "app-consentimiento-actividades",
	templateUrl: "./consentimiento-actividades.component.html",
	styleUrls: ["./consentimiento-actividades.component.less"]
})
export class ConsentimientoActividadesComponent implements OnInit {
	familias: FamiliaConsentimiento[] = []

	token: any
	reservaId
	valido: boolean
	fecha: Date
	item: ReservaConsentimiento
	grupoReservas: GrupoReserva[] = []
	grupoReservasAgrupadas: { familia: FamiliaProducto; grupos: GrupoReserva[] }[] = []
	productos: ItemReservaConsentimiento[] = []
	hospedajes: ItemReservaConsentimiento[] = []
	initialState: any
	requiereConsentimiento: ItemReservaConsentimiento[] = []
	enviado = 0
	public textoConsentimiento: string
	public validando: boolean = false
	formPaxs
	documentos = [new Descriptivo("96", "DNI"), new Descriptivo("94", "Pasaporte")]
	nacionalidades: Descriptivo[] = []
	idiomas: Idioma[] = [
		new Idioma(
			null,
			"ES",
			"Español",
			true,
			true,
			1,
			new ProfilePic(null, "https://sistema-back.tierraturismo.com/tierra-turismo-backend/profilepics/banderas/4a037ffe-ba2c-480c-9c0a-1cdbdf6f88de.jpg")
		),
		new Idioma(
			null,
			"EN",
			"English",
			true,
			true,
			1,
			new ProfilePic(null, "https://sistema-back.tierraturismo.com/tierra-turismo-backend/profilepics/banderas/8a2f2e51-f67d-4030-a249-d6dc0e059a3e.jpg")
		),
		new Idioma(
			null,
			"PT",
			"Português",
			true,
			true,
			1,
			new ProfilePic(null, "https://sistema-back.tierraturismo.com/tierra-turismo-backend/profilepics/banderas/fba0b72d-72a3-457e-96d9-530612c84c98.jpg")
		)
	]
	public now = new Date()
	private _idiomaSeleccionado: Idioma
	itemEncuesta: ItemReservaConsentimiento
	tokenEncuesta: any
	encuestas: any[] = []
	mostrarSide = false
	grupoDetalle: FamiliaConsentimiento
	editando: boolean = false
	editandoPaxs: boolean = false

	public get idiomaSeleccionado(): Idioma {
		return this._idiomaSeleccionado
	}
	public set idiomaSeleccionado(v: Idioma) {
		if (v?.codigo != this._idiomaSeleccionado?.codigo) {
			if (!this.idiomas.some((i) => i.codigo === v.codigo)) {
				this._idiomaSeleccionado = this.idiomas[1]
			} else {
				this._idiomaSeleccionado = v
			}
			this.updateTemplate(this._idiomaSeleccionado?.codigo || "ES")
			this.translateService.use(this._idiomaSeleccionado.codigo.toLowerCase())
			if (this.item?.idiomaPax?.codigo != v.codigo) {
				this.getConsent()
			}
		}
	}
	public mainLogo = DEF_LOGO
	public usuario: Usuario
	constructor(
		private service: ConsentimientoService,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private messagesService: MessagesService,
		private translateService: TranslateService,
		private emTemplateService: EmailTemplateService,
		private confEntorno: ConfiguracionEntornoService,
		private router: Router,
		private authService: AuthService,
		private nacionalidadService: NacionalidadService
	) {
		this.confEntorno.getConfiguracion().then((c) => {
			this.mainLogo = c.profilePic?.picPath || DEF_LOGO
		})
		this.usuario = this.authService.getCurrentUser
	}
	ngOnInit() {
		this.fecha = new Date()
		this.route.params.pipe(first()).subscribe((params) => {
			if (params["token"] || params["tokenEncuesta"]) {
				this.validando = true
				this.token = params["token"]
				this.reservaId = params["reserva"]
				this.tokenEncuesta = params["tokenEncuesta"]
				this.getConsent()
			}
		})
		this.nacionalidadService.getPublicNacionalidad().then((n) => {
			this.nacionalidades = n || []
		})

		this.route.queryParams.subscribe((params: Params) => {
			if (!params["grupo"] || params["grupo"] != this.grupoDetalle?.getId()) {
				this.mostrarSide = false
				this.grupoDetalle = undefined
			}
		})
		this.updateTemplate(this._idiomaSeleccionado?.codigo || "EN")
	}
	addPax(p?: Pax) {
		const pax: FormGroup = this.fb.group({
			id: p ? p.id : null,
			nombre: [p ? p.nombre : "", Validators.required],
			apellido: [p ? p.apellido : "", Validators.required],
			tipoDocumento: [null, Validators.required],
			numeroDocumento: [p ? p.numeroDocumento : "", Validators.required],
			fechaNacimiento: [p ? p.fechaNacimiento : null, Validators.required],
			observaciones: [p ? p.observaciones : ""],
			nacionalidad: [p ? p.nacionalidad : null, Validators.required]
		})
		pax.get("tipoDocumento").patchValue(p ? Descriptivo.fromData(p.tipoDocumento) : new Descriptivo("96", "DNI"))
		this.paxs.push(pax)
		if (this.readonly) {
			this.paxs.disable()
		}
	}
	private getConsent() {
		if (this.token) {
			this.service
				.validarToken(this.token, this.reservaId, this.idiomaSeleccionado?.codigo || "EN")
				.then(async (r) => {
					this.procesar(r)
				})
				.catch((e) => {
					this.validando = false
					this.valido = false
				})
		} else if (this.tokenEncuesta) {
			this.service
				.validarTokenEncuesta(this.tokenEncuesta, this.reservaId, this.idiomaSeleccionado?.codigo || "EN")
				.then(async (r) => {
					this.procesar(r)
				})
				.catch((e) => {
					this.validando = false
					this.valido = false
				})
		}
	}

	procesar(r: ReservaConsentimiento) {
		this.familias = []
		this.encuestas = []
		this.productos = []
		this.requiereConsentimiento = []
		this.hospedajes = []
		this.validando = false
		if (r) {
			this.mainLogo = r.puntoDeVenta?.profilePic?.picPath || this.mainLogo
			this.formPaxs = this.fb.group({ paxs: this.fb.array([]) })
			if (!this.idiomaSeleccionado) {
				if (r.idiomaPax) {
					this.idiomaSeleccionado = r.idiomaPax
				} else if (!this._idiomaSeleccionado) {
					this._idiomaSeleccionado = this.idiomas[0]
				}
			}

			this.valido = true
			this.item = r
			this.item.itemsReserva = this.item.itemsReserva.filter((i) => !i.esCancelada).sort((a, b) => a.comparar(b))
			this.item.itemsReserva.forEach((item) => {
				if (item.producto.esProducto()) {
					this.productos.push(item)
				} else if (item.producto.esHospedaje()) {
					this.hospedajes.push(item)
				} else if (item.producto.requiereConsentimiento) {
					this.requiereConsentimiento.push(item)
				}

				if (
					item.producto.tipoProducto.codigo == TipoProducto.SERVICIO ||
					item.producto.tipoProducto.codigo == TipoProducto.TERCERIZADO ||
					item.producto.tipoProducto.codigo == TipoProducto.TRANSFER
				) {
					let familia = this.familias.find(
						(f) => item?.agrupado && f.keyFamilia == item.producto.keyFamilia && moment(item.fechaActividad).isSame(moment(f.fecha), "date")
					)

					if (familia) {
						familia.addItem(item)
					} else {
						familia = new FamiliaConsentimiento(
							item.producto.keyFamilia,
							item.producto,
							item.producto.familiaProducto,
							item.fechaActividad,
							[item],
							null,
							this.item.nombrePax,
							this.item.cantidadPax
						)
						this.familias.push(familia)
					}

					this.familias.forEach((familia) => {
						if (familia.producto?.encuesta || familia.familia?.encuesta) {
							familia.items.forEach((item) => {
								item.reservas.forEach((reserva) => {
									if (reserva.grupoResumen.actividadCompletada) {
										let e = this.item.encuestas.find(
											(encuesta) =>
												encuesta.reserva.id == reserva.itemReserva.idReserva &&
												encuesta.grupo.id == reserva.grupoResumen.id &&
												(encuesta.producto?.id == familia?.producto.id || encuesta.familia?.id == familia.familia?.id)
										)
										if (!e) {
											if (
												!this.encuestas.find(
													(e) =>
														e.producto.keyFamilia == familia.producto.keyFamilia &&
														e.grupo == reserva.grupoResumen.id &&
														e.reserva == reserva.itemReserva.idReserva &&
														moment(e.fecha).isSame(moment(familia.fecha))
												)
											)
												this.encuestas.push({
													producto: familia.producto,
													familia: familia.familia,
													grupo: reserva.grupoResumen.id,
													reserva: reserva.itemReserva.idReserva,
													fecha: familia.fecha
												})
										}
									}
								})
							})
						}
						familia.confirmado = !familia.items.some((i) => i.requiereConsentimiento && !i.aceptadoConsentimiento)
					})
				}
			})

			if (!this.requiereConsentimiento?.length && !this.item.consentimiento?.fechaCompletado) {
				this.service.guardarAceptacion(this.item.consentimiento).then((re: any) => {
					if (re) {
						this.item.consentimiento = re
					} else this.enviado = 2
				})
			}

			this.item.consentimiento?.paxs?.forEach((p) => {
				this.addPax(p)
			})
			const newPaxs = this.item.cantidadPax - (this.item.consentimiento?.paxs?.length | 0)
			for (let index = 0; index < newPaxs; index++) {
				this.addPax()
			}

			this.grupoReservas = []

			this.item.itemsReserva.forEach((i) => {
				i.reservas.forEach((reserva) => {
					reserva.grupoResumen.producto["detalle"] = i.producto.detalle || i.producto.familiaProducto?.detalle || ""
					reserva.grupoResumen.producto["requiereConsentimiento"] = i.producto.requiereConsentimiento
					this.grupoReservas.push(reserva)
				})
			})
		} else this.valido = false

		this.initialState = this.paxs.value
	}
	get paxs() {
		return this.formPaxs ? (this.formPaxs.get("paxs") as FormArray) : null
	}
	public updateTemplate(idioma: string = "EN") {
		this.emTemplateService.getByCodigo(EmailTemplateService.CONSENTIMIENTO, idioma).then((t: EmailTemplate) => {
			this.textoConsentimiento = t.template
		})
	}
	getHeader(i: ItemReservaConsentimiento) {
		if (i.producto?.esProducto()) return `${i.producto.nombreComercial || i.producto.descripcion}`
		return `${i.producto.nombreComercial || i.producto.descripcion} - ${moment(i.fechaActividad).utcOffset(environment.timezone).format("DD/MM/YYYY")}`
	}

	getHeaderFamilia(f: FamiliaConsentimiento) {
		return `${
			f.familia?.nombreComercial ||
			f.items.find((i) => i.producto?.esAdulto)?.producto?.nombreComercial ||
			f.items[0].producto.nombreComercial ||
			f.items[0].producto.descripcion
		} - ${moment(f.fecha).utcOffset(environment.timezone).format("DD/MM/YYYY")}`
	}
	public faltaConsentimiento = () => {
		return this.item.itemsReserva.some((i) => i.producto.requiereConsentimiento && !i.aceptadoConsentimiento)
	}
	onSubmit(paxOnly: boolean = false) {
		if (!paxOnly && this.faltaConsentimiento()) {
			return this.messagesService.error(this.translateService.get("DEBE_ACEPTAR_TODAS_LAS_ACTIVIDADES_PARA_CONTINUAR"), "")
		}
		let paxs = []
		this.paxs.controls.forEach((control) => {
			paxs.push(
				new Pax(
					control.value["nombre"],
					control.value["apellido"],
					control.value["tipoDocumento"],
					control.value["numeroDocumento"],
					control.value["observaciones"],
					control.value["fechaNacimiento"],
					control.value["id"],
					control.value["nacionalidad"]
				)
			)
		})
		let r = this.item.consentimiento
		r.items = this.item.itemsReserva.map((i) => {
			return new ItemConsentimiento(i.id, i.aceptadoConsentimiento)
		})
		r.paxs = paxs

		this.service.guardarAceptacion(r).then((re: any) => {
			if (re) {
				this.item.consentimiento = Consentimiento.fromData(re)
				this.messagesService.success(this.translateService.get("FORMULARIO_ENVIADO__12"))
			} else this.enviado = 2
		})
		this.editando = false
		this.paxs.disable()
	}

	public isMobile() {
		return window.innerWidth <= 640
	}

	public get readonly() {
		if (!this.editando) return true
		if (this.item.consentimiento.editable) return false
		return this.item.consentimiento.tokenUtilizado
	}

	public get formValid() {
		return (
			(!this.requiereConsentimiento?.length || this.formPaxs?.valid) &&
			!this.item.itemsReserva.some((i) => i.requiereConsentimiento && !i.aceptadoConsentimiento)
		)
	}

	// onMostrarEncuesta(event, grupo: GrupoReserva) {
	//     // event?.stopPropagation()
	//     this.itemEncuesta = this.item.itemsReserva.find((item) => item.idItemReserva == grupo.idItemReserva)
	//     this.router.navigate([`/public/encuesta/v2/`],{
	//         queryParams: {
	//             grupo: this.item.
	//         }
	//     })
	// }

	getToken(actividad: FamiliaConsentimiento) {
		return actividad.tipoVoucher === "F" ? actividad.getItemBase().tokenAgrupado : actividad.getItemBase().tokenItem
	}

	getTextoItems(items: ItemReservaConsentimiento[]) {
		let r

		items.forEach((item) => {
			if (r) {
				r += " - " + item.producto.tipoVariante?.descripcion + " x " + item.cantidadPax
			} else {
				r = item.producto.tipoVariante?.descripcion + " x " + item.cantidadPax
			}
		})

		return r
	}

	getCheckboxValue(item) {
		return item.items.every((i) => i.producto.requiereConsentimiento && i.aceptadoConsentimiento)
	}

	onChangeConfirmado(item) {
		item.items?.forEach((i) => (i.aceptadoConsentimiento = item.confirmado))
	}

	mostrarEncuesta(item) {
		this.router.navigate([`/public/encuesta/v2/`], {
			queryParams: {
				grupo: item.grupo,
				reserva: item.reserva,
				familia: item.familia?.id,
				producto: item.producto?.id,
				tipo: item.producto.keyFamilia[0],
				token: this.token || this.tokenEncuesta
			}
		})
	}

	goToDetalleItem(grupoDetalle: FamiliaConsentimiento) {
		this.grupoDetalle = grupoDetalle
		const currentUrl = this.router.url.split("?")[0]
		const newUrlWithQueryParam = currentUrl + "?grupo=" + grupoDetalle.getId()
		this.mostrarSide = true
		this.router.navigateByUrl(newUrlWithQueryParam)
	}
	guardarPasajeros(event) {
		event && event.stopPropagation()
		event.preventDefault()
		if (this.paxs.invalid) {
			return this.messagesService.error(this.translateService.get("DEBE_COMPLETAR_TODOS_LOS_CAMPOS_DE_LOS_PASAJEROS"), "")
		}
		this.onSubmit(true)
		this.editandoPaxs = false
	}
	cancelarEdit(event) {
		event && event.stopPropagation()
		event.preventDefault()
		this.editando = false
		this.editandoPaxs = false
		this.paxs.reset(this.initialState)
		this.paxs.disable()
	}
	editarDatos() {
		if (!this.usuario) {
			this.paxs.enable()
			this.editandoPaxs = true
			if (this.item.consentimiento?.editable) {
				this.editando = true
			}
		}
	}
	datosInclompletos() {
		return this.item.consentimiento?.paxs.some((p) => p.esIncompleto())
	}
	verConsentimiento() {
		this.editando = true
		this.paxs.enable()
		this.mostrarSide = false
	}
}
