import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'public-footer',
	templateUrl: 'public-footer.component.html',
	styleUrls:['public-footer.component.less']
})

export class PublicFooterComponent implements OnInit {

	ngOnInit() { }

	goToIterpax() {
		window.open('http://info.iterpax.com/','_blank')
	  }
  
}