import { TipoAusencia } from './TipoAusencia';

import { Ausencia} from './Ausencia';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { Proveedor} from './Proveedor';


export class SerieAusencia  {
    constructor(
		public id ?: number, 
		public fechaDesde ?: Date, 
		public fechaHasta ?: Date, 
		public esRecurrente: boolean = false,
		public unidad?: string,
		public n?: number,
		public proveedor ?: Descriptivo, 
		public tipoAusencia ?: Descriptivo, 
		public esCancelada: Boolean = false

    ) {
    }
    public static fromData(data: any): SerieAusencia {
        if (!data) return null;
        const o: SerieAusencia = new SerieAusencia(
			data.id, 
			data.fechaDesde? new Date(data.fechaDesde) : null , 
			data.fechaHasta? new Date(data.fechaHasta) : null , 
			data.esRecurrente,
			data.unidad,
			data.n,
			Proveedor.fromData(data.proveedor), 
			Descriptivo.fromData(data.tipoAusencia), 
			data.esCancelada

        );

        return o;

    }

}