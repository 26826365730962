import { Descriptivo } from "src/app/common/model/Descriptivo"
import { DisponibilidadDiaria } from "./DisponibilidadDiaria"
import { ProductoVariante } from "./ProductoVariante"

export class OpcionPublicacion extends Descriptivo {
	public selected: boolean

	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public habilitado: boolean = true,
		public codigoIdioma: string = "ES",
		public variantes: ProductoVariante[] = [],
		public descripcionCorta?: string,
		public incluidos: Descriptivo[] = [],
		public noIncluidos: Descriptivo[] = [],
		public disponibilidad: DisponibilidadDiaria = new DisponibilidadDiaria([]),
		public version?: number
	) {
		super(codigo, descripcion, id)
		this.heredaDisponibilidad = disponibilidad ? false : true
		this.heredaInclusiones = (incluidos.length || noIncluidos.length) ? false : true
	}

	public heredaDisponibilidad;
	public heredaInclusiones;

	public static fromData(data: any): OpcionPublicacion {
		if (!data) return null
		const o: OpcionPublicacion = new OpcionPublicacion(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			data.codigoIdioma,
			data.variantes ? data.variantes.map((c) => ProductoVariante.fromData(c)) : [],
			data.descripcionCorta,
			data.incluidos ? data.incluidos.map((c) => Descriptivo.fromData(c)) : [],
			data.noIncluidos ? data.noIncluidos.map((c) => Descriptivo.fromData(c)) : [],
			data.disponibilidad ? DisponibilidadDiaria.fromData(data.disponibilidad) : null,
			data.version
		)

		return o
	}
}
