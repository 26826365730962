import { Descriptivo } from './../common/model/Descriptivo';



export class Provincia extends Descriptivo {
    constructor(id?: number, codigo?: string, descripcion?: string,
        
		public nombre ?: string,

    ) {
        super(codigo, descripcion,id)
    }
   
    public static fromData(data: any): Provincia {
        if (!data) return null;
        let o: Provincia = new Provincia(
            data.id,
            data.codigo,
            data.descripcion,
            
					 data.nombre, 
        );

        o.version = data.version; 
return o;

    }

}