import * as moment from "moment"
import { Filtro } from "src/app/common/model/Filtro"
import { Descriptivo } from "./../../../common/model/Descriptivo"
export class FiltroGrupo extends Filtro {
	private _mostrarSinOcupacion: boolean = false
	private _paises: Descriptivo[] = []
	private _regiones: Descriptivo[] = []

	private _verDetalle: boolean
	public get verDetalle(): boolean {
		return this._verDetalle
	}
	public set verDetalle(v: boolean) {
		this._verDetalle = v
	}

	public get mostrarSinOcupacion(): boolean {
		return this._mostrarSinOcupacion
	}
	public set mostrarSinOcupacion(v: boolean) {
		if (v == this._mostrarSinOcupacion) return
		this._mostrarSinOcupacion = v
		this.update(true)
	}
	private _vehiculos: Descriptivo[] = []

	public get vehiculo(): Descriptivo {
		return this._vehiculos ? this._vehiculos[0] : null
	}
	public set vehiculo(v: Descriptivo) {
		if (!this._vehiculos) this._vehiculos = []
		if (this.vehiculo != v) {
			this._vehiculos = [v]
			this.update(true)
		}
	}
	public get vehiculos(): Descriptivo[] {
		return this._vehiculos
	}
	public set vehiculos(v: Descriptivo[]) {
		if (v == this._vehiculos) return
		this._vehiculos = v
		this.update(true)
	}

	private _tiposVehiculo: Descriptivo[] = []

	public get tipoVehiculo(): Descriptivo {
		return this._tiposVehiculo ? this._tiposVehiculo[0] : null
	}
	public set tipoVehiculo(v: Descriptivo) {
		if (!this._tiposVehiculo) this._tiposVehiculo = []
		if (this.tipoVehiculo != v) {
			this._tiposVehiculo = [v]
			this.update(true)
		}
	}
	public get tiposVehiculo(): Descriptivo[] {
		return this._tiposVehiculo
	}
	public set tiposVehiculo(v: Descriptivo[]) {
		if (v == this._tiposVehiculo) return
		this._tiposVehiculo = v
		this.update(true)
	}

	private _productos: Descriptivo[] = []
	public get productos(): Descriptivo[] {
		return this._productos
	}
	public set productos(v: Descriptivo[]) {
		if (v == this._productos) return
		this._productos = v
		this.update(true)
	}

	private _idiomas: Descriptivo[] = []
	public get idiomas(): Descriptivo[] {
		return this._idiomas
	}
	public set idiomas(v: Descriptivo[]) {
		if (v == this._idiomas) return
		this._idiomas = v
		this.update(true)
	}

	private _hoteles: Descriptivo[] = []
	public get hoteles(): Descriptivo[] {
		return this._hoteles
	}
	public set hoteles(v: Descriptivo[]) {
		if (v == this._hoteles) return
		this._hoteles = v
		this.update(true)
	}

	private _datosIncompletos: boolean = false
	public get datosIncompletos(): boolean {
		return this._datosIncompletos
	}
	public set datosIncompletos(v: boolean) {
		if (v != undefined && v != this._datosIncompletos) {
			this._datosIncompletos = v
			this.update(true)
		}
	}

	private _fecha: Date = moment(new Date()).startOf("day").toDate()
	public get fecha(): Date {
		return this._fecha
	}
	public set fecha(v: Date) {
		if (v == this._fecha) return
		this._fecha = v ? moment(v).startOf("day").toDate() : null
		this.update(true)
	}

	private _fechaHasta: Date = moment(new Date()).toDate()
	public get fechaHasta(): Date {
		return this._fechaHasta
	}
	public set fechaHasta(v: Date) {
		if (v == this._fechaHasta) return
		this._fechaHasta = v ? moment(v).toDate() : null
		this.update(true)
	}

	private _cliente: Descriptivo
	public get cliente(): Descriptivo {
		return this._cliente
	}
	public set cliente(v: Descriptivo) {
		if (v == this._cliente) return
		this._cliente = v
		this.update(true)
	}

	private _proveedor: Descriptivo
	public get proveedor(): Descriptivo {
		return this._proveedor
	}
	public set proveedor(v: Descriptivo) {
		if (v == this._proveedor) return
		this._proveedor = v
		this.update(true)
	}
	private _mostrarCliente: boolean = false
	public get mostrarCliente(): boolean {
		return this._mostrarCliente
	}
	public set mostrarCliente(v: boolean) {
		if (v == this._mostrarCliente) return
		this._mostrarCliente = v
	}

	private _mostrarObservaciones: boolean = false
	public get mostrarObservaciones(): boolean {
		return this._mostrarObservaciones
	}
	public set mostrarObservaciones(v: boolean) {
		this._mostrarObservaciones = v
	}

	private _verInfoContacto: boolean = false
	public get verInfoContacto(): boolean {
		return this._verInfoContacto
	}
	public set verInfoContacto(v: boolean) {
		this._verInfoContacto = v
	}

	private _soloSinAsignar: boolean = false
	public get soloSinAsignar(): boolean {
		return this._soloSinAsignar
	}
	public set soloSinAsignar(v: boolean) {
		if (v == this._soloSinAsignar) return
		this._soloSinAsignar = v
		this.update(true)
	}
	public get paises(): Descriptivo[] {
		return this._paises
	}
	public set paises(v: Descriptivo[]) {
		this._paises = v
		this.update()
	}

	public set pais(v: Descriptivo) {
		this._paises = v ? [v] : []
		this.update()
	}
	public get pais() {
		return this._paises ? this._paises[0] : null
	}

	public get regiones(): Descriptivo[] {
		return this._regiones
	}
	public set regiones(v: Descriptivo[]) {
		this._regiones = v
		this.update()
	}

	public set region(v: Descriptivo) {
		this._regiones = v ? [v] : []
		this.update()
	}
	public get region() {
		return this._regiones ? this._regiones[0] : null
	}

	private _tipoProducto: Descriptivo
	public get tipoProducto(): Descriptivo {
		return this._tipoProducto
	}
	public set tipoProducto(v: Descriptivo) {
		this._tipoProducto = v
		this.update()
	}

	public clean() {
		super.clean(false)
		this.patchValue({
			searchStr: "",
			fecha: moment().startOf("day").toDate(),
			fechaHasta: this.layout === "R" ? moment().add(6, "days").toDate() : moment().toDate(),
			cliente: null,
			productos: [],
			mostrarSinOcupacion: false,
			guardable: true,
			proveedor: null,
			tipoVehiculo: null,
			vehiculo: null,
			mostrarCliente: false,
			mostrarObservaciones: false,
			soloSinAsignar: false,
			layout: this.layout || "G",
			idiomas: [],
			hoteles: [],
			datosIncompletos: false,
			regiones: [],
			paises: [],
			tipoProducto: null,
			verInfoContacto: false,
			verDetalle: true
		})
	}
	public patchValue(v: any) {
		super.patchValue(v, false)
		this._fecha = v.fecha ? new Date(v.fecha) : this._fecha || moment().startOf("day").toDate()
		this._fechaHasta = v.fechaHasta ? new Date(v.fechaHasta) : this._fechaHasta || moment().endOf("day").toDate()
		this._cliente = v.cliente ? Descriptivo.fromData(v.cliente) : null
		this._proveedor = v.proveedor ? Descriptivo.fromData(v.proveedor) : null
		this._tiposVehiculo = v.tiposVehiculo ? v.tiposVehiculo.map((d) => Descriptivo.fromData(d)) : []
		this._productos = v.productos ? v.productos.map((p) => Descriptivo.fromData(p)) : []
		this._mostrarSinOcupacion = v.mostrarSinOcupacion != undefined ? v.mostrarSinOcupacion : false
		this.guardable = v.guardable
		this.vehiculos = v.vehiculos ? v.vehiculos.map((d) => Descriptivo.fromData(d)) : []
		this._mostrarCliente = v.mostrarCliente != undefined ? v.mostrarCliente : this.mostrarCliente
		this._mostrarObservaciones = v.mostrarObservaciones != undefined ? v.mostrarObservaciones : this.mostrarObservaciones
		this._soloSinAsignar = v.soloSinAsignar != undefined ? v.soloSinAsignar : this.soloSinAsignar
		this._idiomas = v.idiomas ? v.idiomas.map((d) => Descriptivo.fromData(d)) : []
		this._hoteles = v.hoteles ? v.hoteles.map((d) => Descriptivo.fromData(d)) : []
		this._datosIncompletos = v.datosIncompletos != undefined ? v.datosIncompletos : false
		this._regiones = v.regiones ? v.regiones.map((c) => Descriptivo.fromData(c)) : []
		this._paises = v.paises ? v.paises.map((c) => Descriptivo.fromData(c)) : []
		this._tipoProducto = v.tipoProducto ? Descriptivo.fromData(v.tipoProducto) : null
		this._verInfoContacto = v.verInfoContacto != undefined ? v.verInfoContacto : false
		this._verDetalle = v.verDetalle != undefined ? v.verDetalle : true
		this.update(true)
	}
	public idItemReserva: number
	public valid(): boolean {
		return this.fecha != undefined
	}
	constructor(name, page: number = 0, size: number = 100, layout: string = "G") {
		super(
			name,
			{
				fecha: "Fecha desde",
				fechaHasta: "Hasta",
				cliente: "Cliente",
				proveedor: "Proveedor",
				productos: "Productos",
				tiposVehiculo: "Tipos de Vehiculo",
				vehiculos: "Vehiculos",
				tipoVehiculo: "Tipo de Vehiculo",
				vehiculo: "Vehiculo",
				mostrarSinOcupacion: "Vehiculo sin ocupación",
				mostrarCliente: "Ver cliente",
				mostrarObservaciones: "Ver observaciones",
				soloSinAsignar: "Solo sin asignar",
				idiomas: "Idiomas",
				hoteles: "Hoteles",
				datosIncompletos: "Con datos incompletos",
				regiones: "Regiones",
				paises: "Paises",
				tipoProducto: "Tipo de Producto",
				verInfoContacto: "Ver Info Contacto"
			},
			page,
			size,
			"id",
			1,
			true
		)
		this.layout = layout || "G"
	}
}
