<div class="gestor-familia-producto content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'FAMILIA_PRODUCTO' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="FamiliaProductoForm"
		[multilenguaje]="true"
		(onEditar)="onEdit($event)"
		[loadEntity]="loadEntity"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #FamiliaProductoForm="ngForm" id="FamiliaProductoform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-2">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'CODIGO_PH' | translate }}"
								[(ngModel)]="item.codigo"
								tooltipPosition="bottom"
								pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
								type="text"
								#codigo="ngModel"
								name="codigo"
								[readonly]="readonly"
							/>
							<label>{{ "CODIGO" | translate }}</label>
						</span>
						<error-tag [model]="codigo"></error-tag>
					</div>

					<div class="col-12 lg:col-5">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="$TYPE"
								#descripcion="ngModel"
								name="descripcion"
								[readonly]="readonly"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>

					<div class="col-12 lg:col-5">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'NOMBRE_COMERCIAL' | translate }}"
								[(ngModel)]="item.nombreComercial"
								tooltipPosition="bottom"
								pTooltip="{{ 'NOMBRE_COMERCIAL' | translate }}"
								type="text"
								#descripcion="ngModel"
								name="descripcion"
								[readonly]="readonly"
							/>
							<label>{{ "NOMBRE_COMERCIAL" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>
					<div class="col checkbox-container">
						<p-checkbox
							[(ngModel)]="item.requiereConsentimiento"
							[binary]="true"
							name="requiereConsentimiento"
							tooltipPosition="bottom"
							pTooltip="{{ 'Requiere consentimiento de los paxs' | translate }}"
							[disabled]="readonly"
							label="{{ 'Requiere consentimiento' | translate }}"
						></p-checkbox>
					</div>
					<div class="col checkbox-container">
						<p-checkbox
							[(ngModel)]="item.agrupado"
							[binary]="true"
							name="agrupado"
							tooltipPosition="bottom"
							pTooltip="{{ 'AGRUPAR_VOUCHER_TOOLTIP' | translate }}"
							[disabled]="readonly"
							label="{{ 'AGRUPAR_VOUCHER' | translate }}"
						></p-checkbox>
					</div>
					<div class="col-12 lg:col-12 grid-nogutter">
						<label>{{ "DETALLE_PRODUCTO" | translate }}</label>
						<p-editor
							[(ngModel)]="item.detalle"
							name="detalle"
							tabindex="10"
							[readonly]="readonly"
							[style]="{ height: '100%', 'max-height': '40vh', 'min-height': '20vh', overflow: 'auto', 'background-color': 'white' }"
						>
						</p-editor>

						<p-header *ngIf="readonly">
							<span></span>
						</p-header>
						<small>
							{{ getErrorMessage(detalle) }}
						</small>
					</div>

					<div class="col-12 lg:col-6">
						<div class="grid">
							<div class="subtitulo col-12">
								<span>{{ "ENCUESTA" | translate }} </span>
							</div>
							<div class="seccion col-12 lg:col-6">
								<descriptivo-material-selector
									[allowDefault]="!item?.id"
									[service]="encuestaService"
									[placeHolder]="'ENCUESTA' | translate"
									[(ngModel)]="item.encuesta"
									[readonly]="readonly"
									#encuesta="ngModel"
									name="encuesta"
									[isLazy]="true"
									[limpiable]="true"
								>
								</descriptivo-material-selector>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
