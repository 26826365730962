<div class="consentimiento content">
	<div class="filter-content">
		<filtro [(filter)]="filtro" [columns]="columns" [useSidebar]="true" [isFixed]="true" [filterTemplateContent]="filterTemplate"> </filtro>
		<ng-template #filterTemplate>
			<div class="grid">
				<div class="col-12">
					<div class="grid">
						<div class="col-12 lg:col-6">
							<div class="p-inputgroup">
								<span class="p-float-label">
									<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
									<label>{{ "BUSCAR" | translate }}...</label>
								</span>
								<button type="button" pButton icon="pi pi-search"></button>
							</div>
						</div>

						<div class="col-12 lg:col-6">
							<descriptivo-material-selector
								[(ngModel)]="filtro.producto"
								[service]="productoService"
								[limpiable]="true"
								[label]="'Actividad' | translate"
								[permiteNuevo]="false"
								[isLazy]="true"
								[getData]="productoService.getServicios"
							>
							</descriptivo-material-selector>
						</div>

						<div class="col-12">
							<div class="grid">
								<div class="col-6 lg:col-3 p-fluid p-field">
									<span class="p-float-label">
										<p-calendar
											[readonlyInput]="true"
											[(ngModel)]="filtro.fechaActividadDesde"
											name="fechaActividadDesde"
											dateFormat="dd/mm/yy"
											appendTo="body"
											[showIcon]="true"
											#fechaActividadDesde="ngModel"
											required
										></p-calendar>
										<label for="">{{ "FECHA_DESDE" | translate }}</label>
										<small *ngIf="getErrorMessage(fechaActividadDesde)" class="p-error block">{{
											getErrorMessage(fechaActividadDesde)
										}}</small>
									</span>
								</div>
								<div class="col-6 lg:col-3 p-fluid p-field">
									<span class="p-float-label">
										<p-calendar
											[readonlyInput]="true"
											[(ngModel)]="filtro.fechaActividadHasta"
											name="fechaActividadHasta"
											dateFormat="dd/mm/yy"
											appendTo="body"
											[showIcon]="true"
											#fechaActividadHasta="ngModel"
											required
										></p-calendar>
										<label for="">{{ "HASTA" | translate }}</label>
										<small *ngIf="getErrorMessage(fechaActividadHasta)" class="p-error block">{{
											getErrorMessage(fechaActividadHasta)
										}}</small>
									</span>
								</div>

								<div class="col-3">
									<p-checkbox [label]="'SOLO_ACEPTADOS' | translate" [(ngModel)]="soloAceptados" binary="true"></p-checkbox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
	<div class="tabla">
		<p-table #table [value]="consentimientos" [columns]="columns" [paginator]="true" [rows]="20" [rowsPerPageOptions]="[5, 10, 20, 50, 100]">
			<ng-template pTemplate="caption">
				<div class="p-helper-clearfix" style="text-align: left">
					<button
						type="button"
						pButton
						icon="pi pi-file-o"
						iconPos="left"
						label="CSV"
						(click)="table.exportCSV()"
						style="margin-right: 0.5em"
					></button>
				</div>
			</ng-template>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th [tooltipPosition]="'bottom'" [pTooltip]="'ID' | translate" style="width: 5%">{{ "ID" | translate }}</th>
					<th [tooltipPosition]="'bottom'" [pTooltip]="'FECHA_ACTIVIDAD' | translate" style="width: 10%">{{ "FECHA_ACTIVIDAD" | translate }}</th>
					<th [tooltipPosition]="'bottom'" [pTooltip]="'RESERVA' | translate" style="width: 10%">{{ "RESERVA" | translate }}</th>
					<th [tooltipPosition]="'bottom'" [pTooltip]="'PRODUCTO' | translate" style="width: 15%">{{ "PRODUCTO" | translate }}</th>
					<th [tooltipPosition]="'bottom'" [pTooltip]="'NOMBRE_PAX_CLIENTE' | translate" style="width: 20%">
						{{ "NOMBRE_PAX_CLIENTE" | translate }}
					</th>
					<th [tooltipPosition]="'bottom'" [pTooltip]="'DOCUMENTO' | translate" style="width: 10%">{{ "DOCUMENTO" | translate }}</th>
					<th [tooltipPosition]="'bottom'" [pTooltip]="'FECHA_NACIMIENTO' | translate" style="width: 10%">{{ "FECHA_NACIMIENTO" | translate }}</th>
					<th [tooltipPosition]="'bottom'" [pTooltip]="'TELEFONO' | translate" style="width: 10%">{{ "TELEFONO" | translate }}</th>
					<th [tooltipPosition]="'bottom'" [pTooltip]="'OBSERVACIONES' | translate" style="width: 10%">{{ "OBSERVACIONES" | translate }}</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData let-columns="columns">
				<tr>
					<td [tooltipPosition]="'bottom'" [pTooltip]="rowData?.idConsentimiento">
						<span class="clickable" (click)="goToConsentimiento($event, rowData)">{{ rowData?.idConsentimiento }}</span>
					</td>
					<td [tooltipPosition]="'bottom'" [pTooltip]="rowData?.fechaActividad">{{ rowData.fechaActividad }}</td>
					<td [tooltipPosition]="'bottom'" [pTooltip]="rowData?.idReserva">
						<ng-container *ngTemplateOutlet="reserva; context: { $implicit: rowData }"> </ng-container>
					</td>
					<td [tooltipPosition]="'bottom'" [pTooltip]="rowData?.descripcionProducto">
						<span class="clickable" (click)="goToProducto($event, rowData)">{{ rowData.descripcionProducto }}</span>
					</td>
					<td [tooltipPosition]="'bottom'" [pTooltip]="rowData.nombre">{{ rowData.nombre }}</td>
					<td [tooltipPosition]="'bottom'" [pTooltip]="rowData?.documento">{{ rowData.documento }}</td>
					<td [tooltipPosition]="'bottom'" [pTooltip]="rowData?.fechaNacimientoPax">{{ rowData.fechaNacimientoPax }}</td>
					<td [tooltipPosition]="'bottom'" [pTooltip]="rowData?.telefonoContacto">{{ rowData.telefonoContacto }}</td>
					<td [tooltipPosition]="'bottom'" [pTooltip]="rowData?.observaciones">{{ rowData.observaciones }}</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<ng-template #reserva let-data>
	<div class="grid grid-nogutter align-content-center" style="flex-wrap: nowrap">
		<div class="col">
			<span class="clickable" (click)="goToReserva($event, rowData)">{{ data?.idReserva }}</span>
		</div>
		<div
			class="col-fixed text-center link"
			style="width: 2em"
			[ngClass]="{ link: data.consentimientoAceptado }"
			(click)="data?.consentimientoAceptado && verConcentimiento($event, data)"
		>
			<i
				tooltipPosition="bottom"
				pTooltip="{{ data.estadoConsentimiento?.descripcion }}"
				class="fa icon {{ data?.estadoConsentimiento?.icono }}"
				[ngStyle]="{ color: data?.estadoConsentimiento?.color }"
			></i>
		</div>
	</div>
</ng-template>
