<div class="listado-factura content">
	<model-list
		[service]="service"
		[modelName]="'factura'"
		[columns]="columns"
		[title]="'FACTURA_TITLE' | translate"
		[(listado)]="listado"
		[isLazy]="true"
		[filtro]="filtro"
		[filterContent]="customFilter"
		[footer]="customFooter"
		[acciones]="acciones"
		[menuItemAcciones]="menuAcciones"
		[modoSeleccion]="'multiple'"
		(seleccionChange)="updateSeleccion($event)"
		[previousContent]="previousContent"
		[notificarNovedades]="true"
	>
		<ng-template #previousContent>
			<p-panel
				[styleClass]="'externas-panel mb-1 ' + (nuevaFactura ? 'nueva-factura' : '')"
				*ngIf="externasPendientes?.length"
				[toggleable]="true"
				#panelExternos
			>
				<ng-template pTemplate="header">
					<div class="w-100" (click)="panelExternos.toggle($event)">
						<h5 class="pl-2 mt-3 mr-2" style="position: relative">
							{{ "FACTURAS_EXTERNAS" | translate }}
							<p-badge [value]="externasPendientes.length" severity="warning"></p-badge>
						</h5>
					</div>
				</ng-template>
				<p-scrollPanel [style]="{ width: '100%', height: '20vh' }">
					<div class="grid externas">
						<div class="col-12 lg:col-4" *ngFor="let item of externasPendientes">
							<div class="card" pRipple (click)="ver(item.id); $event.stopPropagation()">
								<div class="grid">
									<div class="col-12">
										<strong><i class="fa fa-user mr-2"></i>{{ item.proveedor?.descripcion }}</strong>
									</div>
									<div class="col-6">
										<span><strong>#</strong> {{ item.tipoComprobante?.codigo + " " + item.puntoVenta + item.numero }}</span>
									</div>
									<div class="col-6">
										<span><i class="fa fa-calendar-o mr-2"></i> {{ item.fechaVencimiento | date : "dd/MM/yyyy" }}</span>
									</div>
									<div class="col-12">
										<strong>{{ "DESCRIPCION" | translate }}</strong>
										<span class="block">
											{{ item.descripcion }}
										</span>
									</div>
									<div class="col-12 text-right">
										<strong style="font-size: 1.2rem">{{ item.importe | currencyCustom : item?.moneda }}</strong>
									</div>
									<div class="col-6 lg:col-4 lg:col-offset-4 text-center p-fluid">
										<button
											pButton
											pRipple
											(click)="rechazarExterna(item); $event.stopPropagation()"
											[label]="'RECHAZAR' | translate"
											class="p-button-danger p-button-outlined"
										></button>
									</div>
									<div class="col-6 lg:col-4 text-center p-fluid">
										<button
											pButton
											pRipple
											(click)="aprobarExterna(item); $event.stopPropagation()"
											[label]="'APROBAR' | translate"
											class="p-button-success"
										></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</p-scrollPanel>
			</p-panel>
		</ng-template>
		<ng-template #customFilter>
			<div class="grid align-content-center">
				<div class="col-12 small">
					<div class="p-inputgroup">
						<span class="p-float-label">
							<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
							<label>{{ "BUSCAR" | translate }}...</label>
						</span>
						<button type="button" pButton icon="pi pi-search"></button>
					</div>
				</div>
				<div class="col-12">
					<descriptivo-material-selector
						[service]="provService"
						[isLazy]="true"
						[filter]="filtroProveedor"
						[label]="'PROVEEDOR' | translate"
						name="proveedor"
						[limpiable]="true"
						[(ngModel)]="filtro.proveedor"
					>
					</descriptivo-material-selector>
				</div>
				<div class="col-12">
					<descriptivo-material-selector
						[options]="responsables"
						[label]="'RESPONSABLE' | translate"
						name="responsable"
						[limpiable]="true"
						[(ngModel)]="filtro.responsable"
					>
					</descriptivo-material-selector>
				</div>
				<div class="col-12">
					<selector-multiple-chips
						[(ngModel)]="filtro.tiposComprobante"
						name="tiposcomp"
						[servicio]="tipoComprobanteService"
						[permiteNuevo]="false"
						[limpiable]="true"
						[titulo]="'Tipos de comprobante' | translate"
						[maxItems]="3"
					>
					</selector-multiple-chips>
				</div>
				<div class="col-12">
					<div class="grid align-content-center">
						<div class="col-12 lg:col-6">
							<span class="p-float-label">
								<p-calendar
									[(ngModel)]="filtro.fechaDesde"
									dateFormat="dd/mm/yy"
									appendTo="body"
									name="fechaDesde"
									[showIcon]="true"
								></p-calendar>
								<label>{{ "FECHA_DESDE" | translate }}</label>
							</span>
						</div>
						<div class="col-12 lg:col-6">
							<span class="p-float-label">
								<p-calendar
									[(ngModel)]="filtro.fechaHasta"
									dateFormat="dd/mm/yy"
									appendTo="body"
									name="fechaHasta"
									[showIcon]="true"
								></p-calendar>
								<label>{{ "FECHA_HASTA" | translate }}</label>
							</span>
						</div>
						<div class="col-12 lg:col-6">
							<span class="p-float-label">
								<p-calendar
									[(ngModel)]="filtro.fechaDesdeVencimiento"
									dateFormat="dd/mm/yy"
									appendTo="body"
									name="fechaDesdeVencimiento"
									[showIcon]="true"
								></p-calendar>
								<label>{{ "DESDE_VENCIMIENTO" | translate }}</label>
							</span>
						</div>
						<div class="col-12 lg:col-6">
							<span class="p-float-label">
								<p-calendar
									[(ngModel)]="filtro.fechaHastaVencimiento"
									dateFormat="dd/mm/yy"
									appendTo="body"
									name="fechaHastaVencimiento"
									[showIcon]="true"
								></p-calendar>
								<label>{{ "HASTA_VENCIMIENTO" | translate }}</label>
							</span>
						</div>
					</div>
				</div>
				<div class="col-12 botonera">
					<span class="p-float-label p-field">
						<div class="grid p-inputwrapper-filled mt-3">
							<div class="col text-center" *ngFor="let estado of estadosService.data | async">
								<button
									pButton
									[label]="estado?.descripcion"
									[ngClass]="{ 'no-seleccionado': !filtro?.tieneEstado(estado) }"
									[ngStyle]="{ 'border-bottom-color': estado?.color, 'background-color': estado?.color }"
									(click)="filtro.toggleEstado(estado)"
								></button>
							</div>
							<div class="col-6 text-center">
								<button
									[ngClass]="{ 'no-seleccionado': !filtro.impagas }"
									pButton
									[label]="'SOLO_IMPAGOS' | translate"
									class="solo-impagas"
									(click)="filtro.impagas = !filtro.impagas"
								></button>
							</div>
							<div class="col-6 text-center">
								<button
									[ngClass]="{ 'no-seleccionado': !filtro.externas }"
									pButton
									[label]="'SOLO_EXTERNAS' | translate"
									class="solo-externas"
									(click)="filtro.externas = !filtro.externas"
								></button>
							</div>
						</div>
						<label class="mt-1">{{ "Estados" | translate }}</label>
					</span>
				</div>
			</div>
		</ng-template>
		<ng-template #customFooter>
			<tr *ngFor="let totalMoneda of totalesMonedas; let i = index">
				<td colspan="6" class="text-right" *ngIf="i == 0">
					<strong>{{ "TOTALES" | translate }}: </strong>
				</td>
				<td colspan="1" class="text-right" *ngIf="i == 0">
					<strong>{{ " " + totalMoneda?.moneda?.codigo || "$ " }}</strong>
				</td>
				<td colspan="7" class="text-right" *ngIf="i > 0">
					<strong>{{ " " + totalMoneda?.moneda?.codigo || "$ " }}</strong>
				</td>
				<td class="text-right">
					<strong>{{ totalMoneda?.totalConsumido | currencyCustom : totalMoneda?.moneda }}</strong>
				</td>
				<td class="text-right">
					<strong>{{ totalMoneda?.totalPagado | currencyCustom : totalMoneda?.moneda }}</strong>
				</td>
				<td class="text-right">
					<strong>{{ totalMoneda?.totalSaldo | currencyCustom : totalMoneda?.moneda }}</strong>
				</td>
				<td></td>
			</tr>
		</ng-template>
	</model-list>
</div>

<p-dialog [(visible)]="facturaRindiendo" appendTo="body" [modal]="true" header="Rendir Factura">
	<div class="col-12">
		<div class="col-4">
			<descriptivo-material-selector
				[options]="tiposComprobante"
				[label]="'TIPOCOMPROBANTE' | translate"
				name="tipo"
				[(ngModel)]="tipoComprobanteSeleccionado"
			>
			</descriptivo-material-selector>
		</div>
		<div class="col-4">
			<span class="p-float-label">
				<input pInputText [(ngModel)]="puntoVenta" type="text" name="puntoVenta" maxLength="5" (change)="puntoVentaChange($event)" />
				<label>{{ "PUNTO_VENTA" | translate }}</label>
			</span>
		</div>
		<div class="col-4">
			<span class="p-float-label">
				<input pInputText [(ngModel)]="numeroComprobante" type="text" name="numeroComprobante" maxLength="8" (change)="numeroChange($event)" />
				<label>{{ "NUMERO" | translate }}</label>
			</span>
		</div>
	</div>
	<div class="col-12 text-right">
		<button
			class="col"
			pButton
			label="{{ 'CONFIRMAR' | translate }}"
			(click)="rendir()"
			[disabled]="!tipoComprobanteSeleccionado || (tipoComprobanteSeleccionado.codigo != 'TI' && (!puntoVenta || !numeroComprobante))"
		></button>
	</div>
</p-dialog>
<ng-template #comprobante let-data>
	<span class="clickable chip comprobante" *ngIf="data?.tipoNumero">
		<i class="fa fa-warning warning" *ngIf="data?.tienePendientesHomologacion" pTooltip="{{ 'PENDIENTES_HOMOLOGACION' | translate }}"></i>
		{{ data?.tipoNumero }}
	</span>
</ng-template>
