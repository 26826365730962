import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'
import { ConfirmationService } from 'primeng/api'
import { Subscription } from 'rxjs'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { Nota } from 'src/app/model/Nota'
import { NotaService } from 'src/app/services/nota.service'
import { SessionComponent } from './../../../common/components/session-component.component'
import { UsuarioService } from 'src/app/common/services/usuario.service'
import { Recordatorio } from 'src/app/model/Recordatorio'
import { RecordatorioService } from 'src/app/services/recordatorio.service'

@Component({
    selector: 'gestor-nota',
    templateUrl: 'gestor-nota.component.html',
    styleUrls: ['gestor-nota.component.less'],
})
export class GestorNotaComponent extends SessionComponent implements OnDestroy, OnInit {
    private routeSub: Subscription
    @Input()
    public item: Nota
    @Input()
    public goBack: boolean = true
    @Input()
    public isModal: boolean = false
    @Input()
    public hideTitle: boolean = false

    private _esPermanente: boolean
    public get esPermanente(): boolean {
        return this.item.esPermanente
    }
    public set esPermanente(v: boolean) {
        this.item.esPermanente = v
        if (v) {
            this.item.fechaHasta = null
        }
    }

    private _fecha: Date
    public get fecha(): Date {
        return this.item?.fechaNota
    }
    @Input()
    public set fecha(v: Date) {
        if (!this.item) {
            this.item = this.service.newEnt()
        }
        if (!this.item.fechaNota) this.item.fechaNota = moment(v).startOf('date').toDate()
    }

    @Input()
    public readonly: boolean = false

    @Output()
    public onGuardado: EventEmitter<Nota> = new EventEmitter<Nota>()

    @Output()
    public onCancelado = new EventEmitter()

    public usuarioOptions: Descriptivo[] = []

    private _asignarAMi: boolean
    public get asignarAMi(): boolean {
        return this._asignarAMi
    }
    public set asignarAMi(v: boolean) {
        this._asignarAMi = v
        // if (v) {
        // 	this.item.destinatario = this.authService.usuario
        // }
    }
    recordatorio: Recordatorio
    constructor(
        messagesService: MessagesService,
        public service: NotaService,
        private route: ActivatedRoute,
        private router: Router,
        private confService: ConfirmationService,
        public usuariosService: UsuarioService
    ) {
        super(messagesService)
    }

    public handleGuardado(item) {
        this.onGuardado.emit(item)
        this.item.version = item.version;
    }
    public handleCancelar(item) {
        this.onCancelado.emit()
    }

    public async isValid() {
        if (!this.item.cabecera) {
            return Promise.resolve(this.error('La cabecera de la nota no puede estar vacía'))
        } else if (!this.item.esPermanente && moment(this.item.fechaHasta).isBefore(this.item.fechaNota, 'date')) {
            return Promise.resolve(this.error('Fecha hasta debe ser mayor a fecha de inicio'))
        }
        return Promise.resolve(true)
    }

    public onEdit(event) {
        if (this.item?.id) {
            if (this.isModal) {
                this.readonly = false
            } else {
                this.router.navigate(["'nota/edit"], { queryParams: { id: this.item.id } })
            }
        }
    }

    ngOnInit() {
        this.subs.push(
            this.route.data.subscribe((u) => {
                if (u?.vista) {
                    this.readonly = true
                }
            })
        )
        if (this.route.snapshot.url.some((u) => u.path == 'nota')) {
            this.routeSub = this.route.queryParams.subscribe((params) => {
                let id: number = <number>params['id']
                if (!this.service) return
                if (id) {
                    this.service.getById(id, null, this.translateService.idioma ? this.translateService.idioma : 'ES').then((r) => {
                        this.item = r
                        this.esPermanente = r.esPermanente
                        
                    })
                } else {
                    if (!this.item && this.service) {
                        this.item = this.service.newEnt()
                    }
                    this.item['codigoIdioma'] = this.translateService.idioma ? this.translateService.idioma : 'ES'
                }
            })
        } 


    }

    public get esEditable() {
        return this.esAdministrador
    }

    public delete(event?) {
        if (!this.item.id) return this.error('No se puede eliminar')
        if (event) event.stopPropagation()
        this.confService.confirm({
            key: 'genConf',
            header: 'Eliminar',
            message: 'Está borrando una nota. Esto no puede deshacerse. Desea continuar?',
            accept: () => {
                let $this = this
                $this.service.eliminar(this.item.id).then((res) => {
                    $this.success('Nota eliminada')
                    this.onGuardado.emit(null)
                })
            },
        })
    }
    ngOnDestroy() {
        if (this.routeSub) this.routeSub.unsubscribe()
    }

   
}
