import { FiltroRespuestaEncuesta } from './../FiltroRespuestaEncuesta';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { RespuestaEncuestaActividades } from "src/app/model/RespuestaEncuestaActividades";
import { RespuestaEncuestaActividadesService } from 'src/app/services/respuesta-encuesta-actividades.service';
import { SessionComponent } from './../../../common/components/session-component.component';

import {Descriptivo} from 'src/app/common/model/Descriptivo';
import {ItemReservaService} from 'src/app/services/item-reserva.service';;
import {EncuestaActividadesService} from 'src/app/services/encuesta-actividades.service';;



@Component({ 
	selector: 'gestor-respuesta-encuesta-actividades',
	templateUrl: 'gestor-respuesta-encuesta-actividades.component.html',
	styleUrls: ['gestor-respuesta-encuesta-actividades.component.less',]
})

export class GestorRespuestaEncuestaActividadesComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription;
	@Input()
	public item: RespuestaEncuestaActividades;
	@Input()
	public goBack: boolean = true;
	@Input()
	public isModal: boolean = false;
	
	@Input()
	public readonly:boolean = false;

	@Output()
	public onGuardado: EventEmitter<RespuestaEncuestaActividades> = new EventEmitter<RespuestaEncuestaActividades>();

	@Output()
	public onCancelado = new EventEmitter();

		public  itemReservaOptions : Descriptivo[] = [];
	public  itemEncuestaActividadesOptions : Descriptivo[] = [];
	public  encuestaActividadesOptions : Descriptivo[] = [];


	

	constructor(messagesService: MessagesService,
		public service: RespuestaEncuestaActividadesService,
		private route: ActivatedRoute,
		private router: Router,
		private confService: ConfirmationService,
		private itemReservaService : ItemReservaService,
		private encuestaActividadesService : EncuestaActividadesService,

	) {
		super(messagesService);
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item);
	}
	public handleCancelar(item) {
		this.onCancelado.emit();
	}

	public async isValid() {
		return true;
	}

	
	ngOnInit() {
		this.readonly = true
		if (this.route.snapshot.url.some(u => u.path == 'respuesta-encuesta-actividades')) {
			this.routeSub = this.route.queryParams.subscribe(params => {
				const id: number = <number>params['id']
				const idReserva: number = <number>params['idReserva'];
				const idGrupo: number = <number>params['idGrupo']
				const idFamilia: number = <number>params['idFamilia']
				const idProducto: number = <number>params['idProducto']
				let idItemReserva: number = <number>params['idItemReserva'];
				if (!this.service) return;
				if (id) {
					this.service.getById(id).then(r => {
						this.item = r;
					})
				} else if( idItemReserva){
					this.service.byIdItemReserva(idItemReserva).then(r => {
						this.item = r;
					})
				}
			});
		}
	}
	
	public get esEditable() {
		return false
	}


	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe();
	}
}