<div class="grid container">
	<div class="col-12">
		<div class="grid content">
			<div class="col-12" (click)="agregarCard()">
				<div class="card plus" [ngClass]="{ disabled: !periodoSeleccionado?.codigo }">
					<i class="fa fa-plus fa-2" tooltipPosition="bottom" pTooltip="{{ 'AGREGAR_PROODUCTO' | translate }}"></i>
				</div>
			</div>

			<div class="col-12" *ngFor="let item of totales">
				<reporte-actividades-card [item]="item" (onEliminar)="eliminar($event)"></reporte-actividades-card>
			</div>
		</div>
	</div>

	<loading-panel [loadingService]="customLoading"></loading-panel>
</div>

<p-overlayPanel #op [dismissable]="false" appendTo="body" [styleClass]="'options'">
	<span class="close" (click)="$event.stopPropagation(); hideParams()" style="position: absolute; top: 3px; right: 3px; cursor: pointer; padding: 0.25em"
		><i class="pi pi-times"></i
	></span>
	<div class="grid no-space-mat-form" style="max-width: 18em">
		<div class="col-11">
			<descriptivo-material-selector
				[options]="opciones"
				[label]="'PERIODO' | translate"
				[limpiable]="false"
				[(ngModel)]="periodoSeleccionado"
				[permiteNuevo]="false"
				(onSelect)="seleccionarPeriodo($event)"
				[name]="'periodo'"
				[required]="false"
			>
			</descriptivo-material-selector>
		</div>
		<div class="col-11" *ngIf="periodoSeleccionado?.codigo == 'RE'">
			<div class="grid">
				<div class="col-12">
					<span class="p-float-label">
						<p-calendar [(ngModel)]="fechaDesde" appendTo="body" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
						<label>{{ "DESDE" | translate }}</label>
					</span>
				</div>
				<div class="col-12">
					<span class="p-float-label">
						<p-calendar [(ngModel)]="fechaHasta" appendTo="body" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
						<label>{{ "HASTA" | translate }}</label>
					</span>
				</div>
			</div>
		</div>
		<div class="col-12 text-center">
			<button pButton (click)="buscar()" label="{{ 'ACTUALIZAR' | translate }}"></button>
		</div>
	</div>
</p-overlayPanel>
<loading-panel [loadingService]="loadingService"></loading-panel>
<p-dialog
	[(visible)]="mostrarNuevoProducto"
	[modal]="true"
	#nuevoProductoDialog
	header="{{ 'AGREGAR_PRODUCTO' | translate }}"
	[styleClass]="'gestor-dialog'"
	appendTo="body"
	[style]="{ width: '60vw' }"
>
	<div class="col-11">
		<descriptivo-material-selector
			[service]="productoService"
			[label]="'PRODUCTO' | translate"
			[limpiable]="true"
			[isLazy]="true"
			[(ngModel)]="productoSeleccionado"
			(onSelect)="seleccionarProducto($event)"
			[getData]="productoService.getServicios"
			[permiteNuevo]="false"
			[name]="'producto'"
			[required]="false"
		>
		</descriptivo-material-selector>
	</div>
</p-dialog>
