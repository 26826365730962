import { Descriptivo } from "../common/model/Descriptivo"

export class ReportePrevisionReserva {
	constructor(public producto?: Descriptivo, public cantidad?: number, public fecha?: Date) {}

	public static fromData(data: any): ReportePrevisionReserva {
		if (!data) return null
		const o: ReportePrevisionReserva = new ReportePrevisionReserva(
			data.producto ? Descriptivo.fromData(data.producto) : null,
			data.cantidad,
			data.fecha ? new Date(data.fecha) : null
		)

		return o
	}
}
