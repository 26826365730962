import { GrupoReserva } from './../../model/GrupoReserva';
import { TranslateService } from './../../common/services/translate.service'
import { MessagesService } from './../../common/services/messages-data-service.service'
import { ItemReserva } from 'src/app/model/ItemReserva'
import { RespuestaEncuestaActividadesService } from './../../services/respuesta-encuesta-actividades.service'
import { EncuestaActividades } from 'src/app/model/EncuestaActividades'
import { RespuestaEncuestaActividades } from './../../model/RespuestaEncuestaActividades'
import { EncuestaActividadesService } from 'src/app/services/encuesta-actividades.service'
import { ItemReservaConsentimiento } from './../../model/ItemReservaConsentimiento'
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core'
import { FormArray, FormBuilder, Validators } from '@angular/forms'
import { ItemEncuestaActividades } from 'src/app/model/ItemEncuestaActividades'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { PlantillaPreguntaEncuesta } from 'src/app/model/PlantillaPreguntaEncuesta'
import { ActivatedRoute } from '@angular/router'
import { first } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { Location } from '@angular/common';
import { EncuestaItem } from 'src/app/model/EncuestaItem'
import { Idioma } from 'src/app/model/Idioma'
import { ProfilePic } from 'src/app/common/model/ProfilePic'
import { PlantillaPregunta } from 'src/app/model/PlantillaPregunta'
import { DEF_LOGO } from 'src/app/app.module';
import { ConfiguracionEntornoService } from 'src/app/services/configuracion-entorno.service';

@Component({
    selector: 'encuesta-servicio',
    templateUrl: './encuesta-servicio.component.html',
    styleUrls: ['./encuesta-servicio.component.less'],
})
export class EncuestaServicioComponent implements OnInit {
    @Input() item: number
    @Input() readonly = false
    formPreguntas
    encuesta: EncuestaItem = new EncuestaItem()
    @Output()
    public onMostrarEncuesta: EventEmitter<boolean> = new EventEmitter(true)
    reservaId: any
    token: any
    valido: boolean
    validando:boolean = false
    enviado = 0
    completada = false
    constructor(
        public encuestaActividadesService: EncuestaActividadesService,
        private fb: FormBuilder,
        private respuestaService: RespuestaEncuestaActividadesService,
        private messagesService: MessagesService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private location: Location,
        private confEntorno:ConfiguracionEntornoService
    ) {
        this.confEntorno.getConfiguracion().then(c=>{
            this.mainLogo = c.profilePic?.picPath || DEF_LOGO;
        })
    }
    idiomas: Idioma[] = [
        new Idioma(null,"ES", "Español",true,true,1,new ProfilePic(null,"https://sistema-back.tierraturismo.com/tierra-turismo-backend/profilepics/banderas/4a037ffe-ba2c-480c-9c0a-1cdbdf6f88de.jpg")),
        new Idioma(null,"EN", "English",true,true,1,new ProfilePic(null,"https://sistema-back.tierraturismo.com/tierra-turismo-backend/profilepics/banderas/8a2f2e51-f67d-4030-a249-d6dc0e059a3e.jpg")),
        new Idioma(null,"PG", "Português",true,true,1,new ProfilePic(null,"https://sistema-back.tierraturismo.com/tierra-turismo-backend/profilepics/banderas/fba0b72d-72a3-457e-96d9-530612c84c98.jpg")),
    ]
    public mainLogo = DEF_LOGO;
    private _idiomaSeleccionado : Idioma;
    itemEncuesta: ItemReservaConsentimiento;
    public get idiomaSeleccionado() : Idioma {
        return this._idiomaSeleccionado;
    }
    public set idiomaSeleccionado(v : Idioma) {
        
        if(v?.codigo != this._idiomaSeleccionado?.codigo){
            if(!this.idiomas.some(i => i.codigo === v.codigo) ){
                this._idiomaSeleccionado = this.idiomas[1];
            }else{
                this._idiomaSeleccionado = v;
            }
            this.translateService.use(this._idiomaSeleccionado.codigo.toLowerCase());
            if(this.encuesta?.idiomaPax?.codigo != v.codigo){
                this.getEncuesta();
            }
        }
    }
    ngOnInit() {
            this.route.params.pipe(first()).subscribe((params) => {
                if (params['token']) {
                    this.validando = true;
                    this.token = params['token']
                    this.reservaId = params['reserva']
                    this.getEncuesta().then( (r:EncuestaItem) => {
                        this.idiomaSeleccionado = r.idiomaPax
                    })
                }
            })
    }
    getEncuesta() {
        return this.encuestaActividadesService.getByGrupoReserva(this.reservaId, this.token, this.idiomaSeleccionado?.codigo ).then((r) => { this.encuesta = r
            this.formPreguntas = this.fb.group({ preguntas: this.fb.array([]) })
            r.preguntas
                .sort((a, b) => {
                    if (a.peso > b.peso) return 1
                    else return -1
                })
                .forEach((pregunta) => this.addPregunta(pregunta))
                this.valido = true
                this.validando = false
                return r
            })
    
    }

    get preguntas() {
        return this.formPreguntas ? (this.formPreguntas.get('preguntas') as FormArray) : null
    }

    addPregunta(p?: PlantillaPreguntaEncuesta) {
        const pregunta = this.fb.group({
            pregunta: [p.pregunta ? p : ''],
            respuesta: ['', Validators.required],
        })
        this.preguntas.push(pregunta)
        if (this.readonly) {
            this.preguntas.disable()
        }
    }

    public get formValid() {
        return this.formPreguntas?.valid
    }

    onSubmit() {
        let respuesta = new RespuestaEncuestaActividades()
        respuesta.fechaCompletado = new Date()
        respuesta.encuesta = this.encuesta
        respuesta.idGrupoReserva = this.reservaId

        this.preguntas.controls.forEach((pregunta) => {
            respuesta.items.push(new ItemEncuestaActividades(null, null, PlantillaPregunta.fromData(pregunta.value['pregunta']?.pregunta), pregunta.value['respuesta'].toString()))
        })

        this.respuestaService.guardarRespuesta(respuesta).then((r) => {
            if (r) {
                this.messagesService.success(this.translateService.get('FORMULARIO_ENVIADO__12'))
                setTimeout(() => {
                    this.location.back()
                }, 500);
            }
            else this.enviado = 2
        })
    }
}
