import { Descriptivo } from "src/app/common/model/Descriptivo"
import { FormaPago } from "src/app/model/FormaPago"
import { Moneda } from "src/app/model/Moneda"
import { IValor } from "../../common/components/abm-cobros/IConCobros"
import { Auditable } from "../Auditable"
import { FileData } from "../FileData"

export class Cobro extends Auditable implements IValor {
	public static TIPO = "C"
	public saldoBase: number

	private _montoBase: number
	public get montoBase(): number {
		return this._montoBase
	}
	public set montoBase(v: number) {
		this._montoBase = v
		this.monto = this._montoBase + (this.importeGasto || 0)
	}

	constructor(
		public id?: number,
		public formaPago?: FormaPago,
		public comprobante?: string,
		public monto?: number,
		public moneda?: Moneda,
		public cotizacion: number = 1,
		public tipoCobro: string = Cobro.TIPO,
		public caja?: Descriptivo,
		public adjunto?: FileData,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
		public version?: number,
		public tipoGasto: "V" | "P" = "P",
		public porcentajeGasto?: number,
		public importeGasto?: number,
		public incluyeGasto?: boolean
	) {
		super(lastModifiedBy, lastModifiedDate)
		this.saldoBase = monto
		this._montoBase = monto - (importeGasto || 0)
	}
	public static fromData(data: any): Cobro {
		if (!data) return null
		const o: Cobro = new Cobro(
			data.id,
			FormaPago.fromData(data.formaPago),
			data.comprobante,
			data.monto,
			Moneda.fromData(data.moneda),
			data.cotizacion,
			Cobro.TIPO,
			Descriptivo.fromData(data.caja),
			FileData.fromData(data.adjunto),
			data.version,
			data.tipoGasto,
			data.porcentajeGasto,
			data.importeGasto,
			data.incluyeGasto
		)
		return o
	}
	public get cotizacionSafe() {
		return this.cotizacion || 1
	}
	public get montoALocal(): number {
		return this.monto * (this.cotizacion > 0 ? this.cotizacion : 1)
	}
}
