import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "link-to-map",
	templateUrl: "link-to-map.component.html",
	styleUrls: ["link-to-map.component.less"],
})
export class LinkToMapComponent implements OnInit {
	private MAPS_URL = "https://www.google.com/maps/search/?q=";

	private _address: string;
	public get address(): string {
		return this._address;
	}
	@Input()
	public set address(v: string) {
		this._address = v;
		this.urlSafe = this.address? this.MAPS_URL + this.address.replace(/ /g, "+") : "";
	}

	protected urlSafe: string;
	ngOnInit() {}
}
