import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { share } from "rxjs/operators"
import { Cliente } from "src/app/model/Cliente"
import { BufferedDescriptivoService } from "src/app/services/BufferedDescriptivoService"
import { Descriptivo } from "../common/model/Descriptivo"
import { Filtro } from "../common/model/Filtro"
import { MessagesService } from "../common/services/messages-data-service.service"
import { FiltroCliente } from "../model/FiltroCliente"
import { Idioma } from "../model/Idioma"
import { LoadingService } from "./../common/services/loading-data-service.service"
import { ClienteResumen } from "./../model/Cliente"
import { CuentaCorriente } from "./../model/CuentaCorriente"
import { Totales } from "./../model/Totales"
import { IServicioCuentaCorriente } from "./IServicioCuentaCorriente"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class ClienteService extends BufferedDescriptivoService<Cliente> implements IServicioCuentaCorriente {
	public baseName(): string {
		return "cliente"
	}
	public parseToEnt(data: any): Cliente {
		return Cliente.fromData(data)
	}
	public newEnt(): Cliente {
		return new Cliente()
	}
	constructor(protected novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	protected fillData = this.getCabeceras
	getCuentaCorriente(filtro: FiltroCliente, customLoading?: LoadingService): Promise<CuentaCorriente[]> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.put(this.getBaseURL("cuenta-corriente"), filtro.json).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r.map((d) => CuentaCorriente.fromData(d))
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	getDescriptivos(
		filter: Filtro = new Filtro("", {}, 0, 10000),
		customLoading?: LoadingService,
		idioma: string = Idioma.DEFAULT_CODIGO
	): Promise<Descriptivo[]> {
		return this.getData(filter, customLoading, idioma).then((r) => {
			return filter.apply(r).map((p) => Descriptivo.fromData(p))
		})
	}
	getActivos = (
		filter: Filtro = new Filtro("", {}, 0, 10000),
		customLoading?: LoadingService,
		idioma: string = Idioma.DEFAULT_CODIGO
	): Promise<Descriptivo[]> => {
		return this.getData(filter, customLoading, idioma).then((r) => {
			return filter
				.apply(r)
				.filter((a) => a.activo)
				.map((p) => Descriptivo.fromData(p))
		})
	}
	notificarDeuda(notificarA, customLoading?: LoadingService): Promise<boolean> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.put(this.getBaseURL("notificar-deuda"), notificarA).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	getTotales(filtro: FiltroCliente = new FiltroCliente(null), customLoading?: LoadingService): Promise<Totales> {
		let $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("totales"), filtro.json).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return new Totales(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}

	getCabeceras(filtro: FiltroCliente = new FiltroCliente(null, 9000), customLoading?: LoadingService): Promise<ClienteResumen[]> {
		let $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("all"), filtro.json).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r.map((p) => ClienteResumen.fromData(p))
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	getByTipoNumero(codigo: string, numeroDocumento: string) {
		return this.getData()
			.then((clientes) => {
				return clientes.filter((c) => c.activo && c.tipoDocumento?.codigo == codigo && c.numeroDocumento == numeroDocumento)
			})
			.catch((e) => {
				return []
			})
	}
	getByIdSinBuffer(id: number, customLoading?: LoadingService, idioma?: string): Promise<Cliente> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL() + id + (idioma ? "?idioma=" + idioma : ""))).then(
			(r) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return this.parseSingle(r)
			},
			(e) => this.handleError(e, customLoading)
		)
	}
	habilitar(da: ClienteResumen | ClienteResumen[], customLoading?: LoadingService): Promise<boolean> {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else {
			this.loadingService.addLoadingCount()
		}

		let clientes: ClienteResumen[] = Array.isArray(da) ? da : [da]
		let ids: number[] = clientes.map((cliente) => cliente.id)
		let payload = { ids: ids }

		return lastValueFrom(this.http.put(this.getBaseURL("habilitarMultiple"), payload))
			.then(() => {
				return true
			})
			.catch((e) => {
				this.handleError(e, customLoading)
				return false
			})
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}

	checkEsIntegrado(id: number, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()

		return lastValueFrom(this.http.get(`${this.getBaseURL()}esIntegrado/${id}`))
			.then((r) => {
				return r
			})
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}
}
