import { Descriptivo } from "../common/model/Descriptivo"
import { Identificable } from "./../common/model/Identficable"

export class EstadoEntidadIntegracion implements Identificable {
	constructor(
		public id?: number,
		public provider?: Descriptivo,
		public estructuraObtenida?: string,
		public log?: string,
		public tipoEntidad?: string,
		public referenciaInterna?: string,
		public referenciaExterna?: string,
		public fechaRegistro?: Date
	) {}

	public static fromData(data) {
		let o = new EstadoEntidadIntegracion(
			data.id,
			Descriptivo.fromData(data.provider),
			data.estructuraObtenida,
			data.log,
			data.tipoEntidad,
			data.referenciaInterna,
			data.referenciaExterna,
			data.fechaRegistro
		)
		return o
	}
}
