import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { Vehiculo } from 'src/app/model/Vehiculo';
import { TranslateService } from './../../common/services/translate.service';
import { TipoVehiculo } from './../../model/TipoVehiculo';

@Component({
  selector: 'selector-vehiculo',
  templateUrl: './selector-vehiculo.component.html',
  styleUrls: ['./selector-vehiculo.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectorVehiculoComponent),
      multi: true
    },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => SelectorVehiculoComponent), multi: true }
  ]
})
export class SelectorVehiculoComponent implements OnInit, ControlValueAccessor, Validator {

  constructor(private traductorService: TranslateService) { }


  @Input()
  public small: boolean = false;
  private _vehiculo: Vehiculo;
  public get vehiculo(): Vehiculo {
    return this._vehiculo;
  }

  @Input()
  public estado: string;
  @Input()
  public set vehiculo(v: Vehiculo) {
    this._vehiculo = v;
    this.onChangeCallback(v);
  }
  @Input()
  public verDetalle: boolean = false;
  public disabled: boolean = false;
  @Input()
  public readonly: boolean = false;


  private _requerimiento: IRequerimiento = {
    capacidadMinima: 0,
    es4x4: false
  };
  public get requerimiento(): IRequerimiento {
    return this._requerimiento;
  }
  @Input()
  public set requerimiento(v: IRequerimiento) {
    this._requerimiento = v;
  }
  ngOnInit() {
  }

  private onChangeCallback: (_: any) => void = () => { };
  private onTouchedCallback: (_: any) => void = () => { };
  registerOnValidatorChange?(fn: () => void): void {

  }
  writeValue(obj: any): void {
    this.vehiculo = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  validate(control: AbstractControl): ValidationErrors {
    return control ? control.errors : null;
  }
  public get tooltip() {
    return this.vehiculo?.descripcion || this.traductorService.get("SIN_VEHICULO");
  }
  public get urlImagen() {
    return this.vehiculo?.imagenUrl ? this.vehiculo?.imagenUrl : "assets/implementacion/images/transporte.png";
  }

}

interface IRequerimiento {
  capacidadMinima?: number,
  tipoVehiculo?: TipoVehiculo,
  es4x4?: boolean
}