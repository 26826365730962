import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { JobConfiguration } from 'src/app/model/JobConfiguration';
import { LoadingService } from './../common/services/loading-data-service.service';
@Injectable({
  providedIn: 'root',
})
export class JobConfigurationService extends ServicioAbstract<JobConfiguration>{
    public baseName(): string {
        return "job-configuration";
    }
    public parseToEnt(data: any): JobConfiguration {
        return JobConfiguration.fromData(data);
    }
    public newEnt(): JobConfiguration {
        return new JobConfiguration();
    }


}