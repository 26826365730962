import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core"
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	NgModel,
	ValidationErrors,
	Validator,
	Validators
} from "@angular/forms"
import { MenuItem } from "primeng/api"
import { AutoComplete } from "primeng/autocomplete"
import { ScrollerOptions } from "primeng/scroller"
import { Observable } from "rxjs"
import { debounceTime } from "rxjs/operators"
import { DescriptivosService } from "src/app/common/services/descriptivos.service"
import { TranslateService } from "../../services/translate.service"
import { Descriptivo } from "./../../model/Descriptivo"
import { Filtro } from "./../../model/Filtro"
import { Parametrico } from "./../../model/Parametrico"
import { DefaultService } from "./../../services/default.service"
import { LoadingService } from "./../../services/loading-data-service.service"
import { ErrorHandler } from "./../../utils/ErrorsHandler"

export function RequireMatch(control: AbstractControl) {
	const selection: any = control.value
	if (typeof selection === "string" && selection) {
		return { incorrect: true }
	}
	return null
}
@Component({
	selector: "descriptivo-material-selector",
	templateUrl: "descriptivo-material-selector.component.html",
	styleUrls: ["descriptivo-material-selector.component.less"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DescriptivoMaterialSelectorComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => DescriptivoMaterialSelectorComponent),
			multi: true
		}
	]
})
export class DescriptivoMaterialSelectorComponent implements OnInit, AfterViewInit, ControlValueAccessor, Validator {
	public menuItems: MenuItem[] = []
	@Input()
	public buscarTraduccion: boolean = false
	@Input()
	public appearence: string = "outline"
	@Input()
	public emptyMessage: string
	@Input()
	public newEnt: (desc?) => any = (desc?) => {
		if (this.service) {
			let d = this.service.newEnt()
			if (desc) d["descripcion"] = desc
			return d
		} else {
			return new Descriptivo(null, desc)
		}
	}
	@Input()
	public noSpace: boolean = false

	@ViewChild("seleccionadoF", { static: true, read: NgModel }) seleccionadoF: NgModel
	@ViewChild("autoComplete", { static: true }) autoComplete: AutoComplete

	@Input()
	public floatLabel: string = "auto"
	@Input()
	public field: string = "descripcion"
	private _disabled: boolean = false
	public get disabled(): boolean {
		return this._disabled
	}
	public aplicaIcono: boolean = false
	@Input()
	public inputControl: FormControl = new FormControl()

	@Input()
	public set disabled(v: boolean) {
		this._disabled = v
		this._disabled ? this.inputControl?.disable() : this.inputControl.enable()
	}

	public get idioma(): string {
		return this.filter.idioma
	}
	@Input()
	public set idioma(v: string) {
		this.filter.idioma = v
	}

	private _required: boolean = false
	public get required(): boolean {
		return this._required
	}
	@Input()
	public set required(v: boolean) {
		this._required = v
		if (!this.inputControl) return
		if (this._required) {
			this.inputControl.setValidators([Validators.required, RequireMatch])
		} else {
			this.inputControl.setValidators([RequireMatch])
		}
	}

	private onChangeCallback: (_: any) => void = () => {}
	public onTouchedCallback: (event: any) => void = (event) => {
		this.inputControl.markAsTouched(event)
	}

	private _options: Descriptivo[] = []

	public get seleccionado(): Descriptivo {
		return typeof this.inputControl.value === "string" ? null : this.inputControl.value
	}
	public set seleccionado(val: any) {
		if (this.buscarTraduccion && val?.descripcion) val.descripcion = this.translateService.get(val.descripcion)
		this.inputControl && this.inputControl.setValue(val)
		if (val) this.inputControl.markAsTouched()
	}

	public filteredOptions: Observable<Descriptivo[]>

	@Input()
	public label: string
	@Input()
	public hideLabel: boolean = false

	@Input()
	public placeHolder: string

	private _filterf: Filtro = new Filtro(null, {}, 0, 100, "id", 1, false)
	public get filter(): Filtro {
		return this._filterf
	}
	@Input()
	public set filter(v: Filtro) {
		if (v) {
			this._filterf = v
		}
	}

	private _service: DescriptivosService<any>
	public get service(): DescriptivosService<any> {
		return this._service
	}

	@Input()
	public set service(v: DescriptivosService<any>) {
		this._service = v
	}

	private _default: Descriptivo
	public get default(): Descriptivo {
		return this._default
	}
	@Input()
	public set default(v: Descriptivo) {
		this._default = v
		setTimeout(() => {
			if (!this.seleccionado && !this.readonly && v) {
				this.seleccionado = v
			}
		}, 100)
	}

	@Input()
	public readonly: boolean = false
	public virtualScrollOptions: ScrollerOptions = {
		autoSize: true,
		orientation: "vertical"
	}
	@Input()
	public autoHighlight: boolean = true
	@Input()
	public set options(v: Descriptivo[]) {
		this._options = v ? v : []
		this.aplicaIcono = this._options.some((d) => d["icon"])
	}

	@Input()
	public permiteNuevo: boolean = false

	@Input()
	public limpiable: boolean = false

	@Input()
	public showError: boolean = true
	@Input()
	public gestor: TemplateRef<any>

	@Input()
	public itemTemplate: TemplateRef<any>
	@Output() onNew: EventEmitter<any> = new EventEmitter()

	@Output() onRefresh: EventEmitter<any> = new EventEmitter()
	@Output() onSelect: EventEmitter<any> = new EventEmitter()
	@Input()
	public allowDefault: boolean = true
	public itemEditado = new Descriptivo()
	public editando = false
	@Input()
	public isLazy: boolean = false
	@Input()
	public getData: (filter: Filtro, l?: LoadingService) => Promise<Descriptivo[]> = (f, l) => {
		return this.service ? this.service.getDescriptivos(f, l) : Promise.resolve(f.apply(this.options))
	}
	@Input()
	public name: string
	public contextVisible: boolean = false
	public dataFiltrada: Descriptivo[] = []
	public ctx: any
	public loadingService: LoadingService = new LoadingService()
	constructor(private defaultService: DefaultService, private errorHandler: ErrorHandler, private translateService: TranslateService) {
		this.ctx = {
			itemEditado: this.itemEditado,
			handler: {
				onGuardado: (r) => this.onGuardado(r),
				onCancelado: (r) => this.onCancelar()
			}
		}
	}
	ngAfterViewInit(): void {}
	writeValue(obj: any): void {
		//if ((!this.seleccionado?.key && !obj) || obj?.key || obj?.id || obj?.codigo) {
		this.itemEditado = obj === undefined ? null : obj
		this.seleccionado = obj === undefined ? null : obj
		//}
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = (_: any) => {
			fn && fn(_)
			this.inputControl.markAsTouched()
		}
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled
	}
	public get options(): Descriptivo[] {
		return this._options
	}

	public getOptionText(option: Descriptivo) {
		return option ? option.descripcion : ""
	}

	public calcWith = (val) => {
		return "auto"
	}
	getErrorMessage(control: AbstractControl) {
		return this.errorHandler.getErrorMessage(control)
	}
	private seleccionarUnico(r) {
		setTimeout(() => {
			if (
				(r.length == 1 || r.filter((a) => a.codigo != Parametrico.NUEVO.codigo).length == 1) &&
				(this.inputControl?.value?.length >= 1 || this.required)
			) {
				this.seleccionado = r.find((a) => a.codigo != Parametrico.NUEVO.codigo)
			}
		}, 200)
	}
	public autoselect(event?) {
		if (event) event.stopPropagation()
		if (this.inputControl.value && typeof this.inputControl.value === "string") {
			this.filtrar(this.inputControl.value, true).then((r) => {
				if (r) this.seleccionarUnico(r)
			})
		}
	}

	ngOnInit() {
		this.menuItems = [
			{
				label: this.translateService.get("RECORDAR"),
				command: () => {
					if (this.seleccionado?.key) {
						this.defaultService.setDefault(this.name, this.seleccionado)
					}
				}
			},
			{
				label: this.translateService.get("LIMPIAR"),
				command: () => {
					this.clean()
				}
			}
		]
		if (this.required && !this.inputControl?.value) {
			this.refreshData(null).then((r) => {
				if (r) this.seleccionarUnico(r)
			})
		}
		const $this = this
		this.inputControl.valueChanges.pipe(debounceTime(500)).subscribe((val) => {
			if (val && (val?.id || val?.key)) {
				const valD = val instanceof Descriptivo ? val : Descriptivo.fromData(val)
				if (valD && valD?.key == Parametrico.NUEVO.key) {
					this.onNew.emit()
					this.nuevo(null)
				} else {
					if (valD && valD?.key != this.itemEditado?.key) {
						this.onTouchedCallback(valD)
						this.onChangeCallback(valD)
						this.onSelect.emit(valD)
						this.itemEditado = valD
					}
				}
			}
		})
		if (this.allowDefault && !this.readonly && !this.seleccionado) {
			setTimeout(() => {
				if (!this.seleccionado) {
					if (this.permiteNuevo ? this.options?.length == 2 : this.options?.length == 1) {
						this.seleccionado = this.options[this.permiteNuevo ? 1 : 0]
						this.onChangeCallback(this.seleccionado)
					} else {
						this.defaultService.getDefault(this.name).then((def) => {
							if (def && !this.readonly && !this.seleccionado?.codigo) {
								this.seleccionado = Descriptivo.fromData(def)
								this.onChangeCallback(def)
							}
						})
					}
				}
			}, 1000)
		}
	}
	public clean(event?) {
		this.seleccionado = null
		this.defaultService.cleanDefault(this.name)
		if (event) event.stopPropagation()
		this.onChangeCallback(null)
		this.onSelect.emit(null)
	}
	applyResults = (results, soloValores: boolean = false): Descriptivo[] => {
		/*if ((results.length == 1 || results.filter((a) => a.codigo != Parametrico.NUEVO.codigo).length == 1) && !this.readonly) {
			this.seleccionado = results.find((a) => a.codigo != Parametrico.NUEVO.codigo)
		}*/
		return soloValores ? results : this.generarBase(results)
	}
	public filtrar(value: string, soloValores: boolean = false): Promise<Descriptivo[]> {
		const f = this._filterf || new Filtro(null, {})
		f.searchStr = value ? value.toLowerCase() : ""
		return this.getData(f)
			.then((r) => this.applyResults(r, soloValores))
			.then((r) => {
				const codigoIgual = r.find((d) => f.searchStr && d.codigo?.toUpperCase() == f.searchStr.toLocaleUpperCase())
				this.dataFiltrada = codigoIgual ? [codigoIgual, ...r.filter((d) => d.codigo != codigoIgual.codigo)] : r
				this.aplicaIcono = r.some((d) => d["icon"])
				return r
			})
	}

	private generarBase(val: Descriptivo[]) {
		let data = val?.length ? [...val] : []
		if (this.permiteNuevo) {
			data.push(Parametrico.NUEVO)
		}
		return this.buscarTraduccion ? data.map((d) => Descriptivo.fromData({ ...d, descripcion: this.translateService.get(d.descripcion) })) : data
	}

	public onFocus(event: any) {
		event.stopPropagation()

		event.srcElement.select()
	}
	isTablet() {
		const width = window.innerWidth
		return width <= 1024 && width > 640
	}
	isDesktop() {
		return window.innerWidth > 1024
	}

	isMobile() {
		return window.innerWidth <= 640
	}

	public onDialogShow(event, dialog) {
		if (!this.isDesktop()) {
			dialog.maximized = true
		}
	}

	public refreshData(str?: string): Promise<any> {
		if (this.service) {
			if (str) {
				this.filter.searchStr = str
			}
			return this.getData(this.filter, this.loadingService).then((r) => {
				this.options = this.generarBase(r)
				return this.options
			})
		} else {
			this.onRefresh.emit()
			return Promise.resolve(this.options)
		}
	}
	public nuevo(desc?) {
		this.itemEditado = this.newEnt(desc)
		this.ctx["itemEditado"] = this.itemEditado

		this.editando = true
	}
	public onGuardado(item: Descriptivo) {
		this.refreshData().then((r) => {
			this.seleccionado = item
			this.editando = false
			this.itemEditado = this.newEnt()
		})
	}
	public onCancelar() {
		this.itemEditado = new Parametrico()
		this.editando = false
	}
	validate(control: AbstractControl): ValidationErrors | null {
		if (control && this.inputControl && control.touched) {
			this.inputControl.markAsTouched()
		}
		control.setErrors(this.inputControl.errors)
		return !control?.valid ? control.errors : null
	}

	public onHide(event) {
		if (this.seleccionado?.key == Parametrico.NUEVO.key) this.clean()
	}
	onShowOverlay(event) {
		if (this.autoComplete.overlay) {
			this.autoComplete.overlay.style.width = this.calculateOverlayWidth()
		}
	}

	private calculateOverlayWidth(): string {
		const inputWidth = this.autoComplete.el.nativeElement.offsetWidth
		const minWidth = inputWidth < 350 ? inputWidth * 2 : inputWidth

		return `${minWidth}px `
	}
}
