import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from "@angular/core"
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms"
import { ConfirmationService } from "primeng/api"
import { Dialog } from "primeng/dialog"
import { Ausencia } from "src/app/model/Ausencia"
import { GastoPuesto } from "src/app/model/GastoPuesto"
import { OpcionPuesto } from "src/app/model/OpcionPuesto"
import { Proveedor } from "src/app/model/Proveedor"
import { Reserva } from "src/app/model/Reserva"
import { ProveedorService } from "src/app/services/proveedor.service"
import { Descriptivo } from "./../../common/model/Descriptivo"
import { MessagesService } from "./../../common/services/messages-data-service.service"
import { TranslateService } from "./../../common/services/translate.service"
import { Disponibilidad } from "./../../model/Disponibilidad"
import { GrupoPax } from "./../../model/GrupoPax"
import { Idioma } from "./../../model/Idioma"
import { OpcionPuestoOcupacion } from "./../../model/OpcionPuestoOcupacion"
import { PuestoInsumo } from "./../../model/PuestoInsumo"
import { AuthService } from "./../../services/auth.service"
import { PuestoServicioReservaService } from "./../../services/puesto-servicio-reserva.service"
import { ContenedorPuesto } from "./ContenedorPuesto"

export type BadgeSize = "small" | "big" | "xsmall"

@Component({
	selector: "puesto-badge",
	templateUrl: "./puesto-badge.component.html",
	styleUrls: ["./puesto-badge.component.less"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PuestoBadgeComponent),
			multi: true
		},
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => PuestoBadgeComponent), multi: true }
	]
})
export class PuestoBadgeComponent implements OnInit, ControlValueAccessor, Validator {
	@Input()
	mostrarEstado: boolean = false
	@Input()
	public permiteGestionCostos: boolean = false
	@Input()
	size: BadgeSize = "big"
	@Input()
	permiteNoCompatibles: boolean = false

	@Input() estadoBadge: Descriptivo

	public puestoEditado: ContenedorPuesto
	private _puesto: ContenedorPuesto
	public get puesto(): ContenedorPuesto {
		return this._puesto
	}
	@Input()
	public set puesto(v: ContenedorPuesto) {
		this._puesto = v
		this.puestoEditado = v.clonar()
	}

	@Input()
	public ausencias: Ausencia[] = []

	private _disponibilidad: Disponibilidad[] = []
	public get disponibilidad(): Disponibilidad[] {
		return this._disponibilidad
	}
	@Input()
	public set disponibilidad(v: Disponibilidad[]) {
		this._disponibilidad = v
	}

	@ViewChild("dialogo")
	public dialog: Dialog

	@Input()
	public solicitarInsumos: boolean = false

	errores: string[] = []

	public disabled: boolean = false
	public get proveedor(): Proveedor {
		return this.opcionSeleccionada.proveedor
	}
	public set proveedor(v: Proveedor) {
		this.opcionSeleccionada.proveedor = v
		if (!this.solicitarInsumos) {
			this.onChangeCallback(v)
		}
		if (v && this.solicitarInsumos) {
			this.insumos = v.insumos ? v.insumos.map((i) => i.insumo) : []
		}
	}

	@Input()
	public estado: string

	@Input()
	public reserva: Reserva

	@Input()
	public verDetalle: boolean = false

	idiomasGrupo: Idioma[] = []

	private _grupo: GrupoPax
	public get grupo(): GrupoPax {
		return this._grupo
	}
	@Input()
	public set grupo(v: GrupoPax) {
		this._grupo = v

		if (v) {
			this.idiomasGrupo = this._grupo.getIdiomas()
		}
	}
	buscarPersonal = (event, puesto) => {
		event.stopPropagation()

		if (this.disabled) return

		if (puesto.gastos?.length && puesto.gastos.some((gasto: GastoPuesto) => gasto.comprobante)) {
			this.confirmationService.confirm({
				key: "genConf",
				header: "Confirmar",
				message: this.translateService.get(`La actividad ya se encuentra facturada al proveedor. ¿Deseás continuar?`),
				accept: () => {
					this.buscar(event)
				},
				reject: () => {}
			})
		} else this.buscar(event)
	}

	@Output()
	public onSeleccion: EventEmitter<ContenedorPuesto> = new EventEmitter()

	@Output()
	public onDeseleccion: EventEmitter<ContenedorPuesto> = new EventEmitter()

	@Output()
	public onVerCostos: EventEmitter<ContenedorPuesto> = new EventEmitter()

	private _click: (event?, puesto?: ContenedorPuesto) => void = this.buscarPersonal
	public get click() {
		return this._click
	}
	@Input()
	public set click(v) {
		this._click = v || this.buscarPersonal
	}
	public opcionSeleccionada: OpcionPuesto = new OpcionPuesto()
	public buscador: string = ""
	public opcionesResult: OpcionPuesto[]

	private _opciones: OpcionPuesto[]
	public get opciones(): OpcionPuesto[] {
		return this._opciones
	}
	public set opciones(v: OpcionPuesto[]) {
		this._opciones = v
		if (this._opciones?.length) {
			this._opciones.forEach((opcion) => {
				this.idiomasGrupo.forEach((idioma) => {
					if (opcion.proveedor.idiomas.find((i) => i.id == idioma.id)) opcion.puntaje++
				})
			})

			this.sortOpciones()
		}
	}

	public insumos: Descriptivo[] = []
	public ocupacion: OpcionPuestoOcupacion[] = []

	constructor(
		private confirmationService: ConfirmationService,
		private messagesService: MessagesService,
		private authService: AuthService,
		private translateService: TranslateService,
		private puestoServicioReservaService: PuestoServicioReservaService,
		public proveedorService: ProveedorService,
		public confService: ConfirmationService
	) {}

	ngOnInit() {}

	private onChangeCallback: (_: any) => void = () => {}
	private onTouchedCallback: (_: any) => void = () => {}
	registerOnValidatorChange?(fn: () => void): void {}
	writeValue(obj: any): void {
		this.proveedor = obj
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled
	}
	validate(control: AbstractControl): ValidationErrors {
		return control ? control.errors : null
	}
	public get urlImagen(): string {
		return this.proveedor ? this.proveedor.imagen : this.puesto?.tipoPuesto?.imagen || "assets/img/default.png"
	}

	public get tooltip(): string {
		let tooltip = [this.puesto?.tipoPuesto?.descripcion || "", this.proveedor?.descripcion].join(" - ")
		tooltip +=
			this.puesto?.estado && this.puesto?.tipoPuesto?.recibeReserva
				? " - " + (this.estadoBadge ? this.estadoBadge.descripcion : this.puesto?.estado?.descripcion)
				: ""
		return tooltip
	}
	public guardarSeleccion() {
		if (this.proveedor) {
			this.onChangeCallback(this.proveedor)
			this.onSeleccion.emit(this.puesto)
			this.opciones = null
		}
	}
	public cancelar(event) {
		this.dialog.close(event)
	}

	public buscar(event) {
		let p = []
		this.puestoServicioReservaService
			.buscarPuestos(this.grupo ? this.grupo.fechaActividad : null, this.puesto.tipoPuesto, this.ausencias, this.disponibilidad)
			.then((o) => (this.opciones = o))
	}
	public agregarGasto() {
		this.puesto.gastos.push(new GastoPuesto(null, "", 0, null, null, true))
	}
	public success(mensaje?: string, titulo?: string) {
		this.messagesService.success(mensaje, titulo)
		return true
	}
	public error(mensaje?: string, titulo?: string) {
		this.messagesService.error(mensaje, titulo)
		return false
	}
	public info(mensaje?: string, titulo?: string) {
		this.messagesService.info(mensaje, titulo)
	}
	public warning(mensaje?: string, titulo?: string) {
		this.messagesService.warning(mensaje, titulo)
	}

	get esAdministrador() {
		return this.authService.esAdministrador
	}

	actualizarPrecio(insumo, gasto: GastoPuesto) {
		// if (!gasto.id) {
		//     if (!this.proveedor) this.error(this.translateService.get('Elija un proveedor'))
		//     gasto.importe = this.proveedor.getPrecio(insumo.id, this.fecha)?.precio
		// }
	}
	public seleccionarOtroProveedor = async (prov: Descriptivo) => {
		if (prov == null) return
		this.confService.confirm({
			key: "genConf",
			header: this.translateService.get("ASIGNAR_PROVEEDOR_SIN_PUESTO"),
			message: this.translateService.get("WARNING_ASIGNACION_PROVEEDOR"),
			accept: async () => {
				const op = new OpcionPuesto(await this.proveedorService.getById(prov.id), [])
				this.seleccionarOpcion(op)
			}
		})
	}
	public seleccionarOpcion(data: OpcionPuesto) {
		if (data.ausencia) {
			return
		}
		const asignar = () => {
			this.opcionesResult = null
			this.proveedor = data.proveedor
			this.puesto.proveedor = data.proveedor
			this.insumos = data.insumos?.map((i) => Descriptivo.fromData(i))
			if (!this.solicitarInsumos) {
				this.opciones = null
				this._puesto.insumos = this.puestoEditado.insumos
				this.onSeleccion.emit(this.puesto)
			}
		}

		const mostrarDialogoOcupacion = () => {
			if (data.ocupacion.length) {
				this.confirmationService.confirm({
					header: "Asignación de Proveedor",
					message: `Va a asignar el proveedor ${data.proveedor.descripcion} que ya se encuentra asignado. ¿Desea continuar?`,
					key: "genConf",
					accept: () => {
						if (data.proveedor.noHabitual) {
							mostrarProveedorNoHabitual()
						} else {
							asignar()
						}
					}
				})
			} else if (data.proveedor.noHabitual) {
				mostrarProveedorNoHabitual()
			} else {
				asignar()
			}
		}

		const mostrarProveedorNoHabitual = () => {
			if (data.proveedor.noHabitual) {
				this.confirmationService.confirm({
					header: "Asignación de Proveedor",
					message: `${data.proveedor.descripcion} no es un proveedor habitual y debe verificar disponibilidad.`,
					key: "genConf3",
					accept: () => {
						asignar()
					},
					acceptLabel: "Ya verifiqué",
					rejectLabel: "Cancelar"
				})
			} else {
				asignar()
			}
		}

		const mostrarDialogoIdioma = () => {
			this.confirmationService.confirm({
				header: "Asignación de Proveedor",
				message: `Va a asignar el proveedor ${data.proveedor.descripcion} que no habla ${faltaIdioma.descripcion}. ¿Desea continuar?`,
				key: "genConf2",
				accept: () => {
					if (data.ocupacion.length) {
						mostrarDialogoOcupacion()
					} else if (data.proveedor.noHabitual) {
						mostrarProveedorNoHabitual()
					} else {
						asignar()
					}
				}
			})
		}

		let faltaIdioma: Idioma = null
		if (this.grupo) {
			this.grupo.gruposReserva
				.filter((g) => g.idiomaServicio?.codigo.toUpperCase() != "ES")
				.forEach((grupo) => {
					if (!data.idiomas.find((i) => i.id == grupo.itemReserva?.idiomaServicio?.id)) {
						faltaIdioma = grupo.itemReserva.idiomaServicio
					}
				})
		}

		if (faltaIdioma) {
			mostrarDialogoIdioma()
		} else if (data.proveedor.noHabitual) {
			mostrarProveedorNoHabitual()
		} else if (data.ocupacion.length) {
			mostrarDialogoOcupacion()
		} else asignar()
	}

	public deseleccionar = () => {
		this.confirmationService.confirm({
			header: "Desasignación de Proveedor",
			message: "Va a desasignar al proveedor " + this.proveedor.descripcion + ". Desea continuar? ",
			key: "genConf",
			accept: () => {
				this.proveedor = null
				// this.opciones = this.opcionesResult.filter((o) => !this.proveedor || o.proveedor.id != this.proveedor.id)
				this.onDeseleccion.emit(this.puesto)
			}
		})
	}
	public quitarInsumo(insumo: PuestoInsumo) {
		this.puesto.insumos = this.puesto.insumos.filter((i) => i != insumo)
	}
	public getImporte(insumo: PuestoInsumo) {
		// if (this.proveedor && insumo.insumo?.id) {
		//     return this.proveedor.getPrecio(insumo.insumo.id, this.fecha)?.precio | 0
		// }
		return 0
	}

	sortOpciones() {
		this._opciones.sort((a, b) => {
			if (a.puntaje > b.puntaje) return -1
			else return 1
		})
	}

	public verCostos = (event: OpcionPuesto) => {
		return this.onVerCostos.emit(this.puesto)
	}

	get estadoDisplay() {
		if (!this.mostrarEstado) return null

		return this.estadoBadge ? this.estadoBadge : this.puesto?.estado
	}
}
