import { Pax } from "./Pax"

export class Consentimiento {
	constructor(
		public id?: number,
		public idReserva?: number,
		public items?: ItemConsentimiento[],
		public paxs?: Pax[],
		public fechaEnviado?: Date,
		public fechaCompletado?: Date,
		public emailContacto?: string,
		public nombrePax?: string,
		public nombreCliente?: string,
		public cantidadPax?: number,
		public tokenUtilizado?: boolean,
		public editable: boolean = false,
		public telefonoContacto?: string,
		public version?: number
	) {}

	public static fromData(data): Consentimiento {
		if (!data) return null
		return new Consentimiento(
			data.id,
			data.idReserva,
			data.items?.map((i) => ItemConsentimiento.fromData(i)) || [],
			data.paxs?.map((p) => Pax.fromData(p)) || [],
			data.fechaEnviado,
			data.fechaCompletado,
			data.emailContacto,
			data.nombrePax,
			data.nombreCliente,
			data.cantidadPax,
			data.tokenUtilizado,
			data.editable,
			data.telefonoContacto,
			data.version
		)
	}
}

export class ItemConsentimiento {
	constructor(public idItem: number, public aceptado: boolean = false) {}

	public static fromData(data): ItemConsentimiento {
		return new ItemConsentimiento(data.id, data.aceptado)
	}
}
