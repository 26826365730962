import { Component, Input, OnInit } from "@angular/core"
import { Cobranza } from "src/app/model/Cobranza"
import { Comprobante } from "src/app/model/Comprobante"

@Component({
	selector: "comprobante-imprimir",
	templateUrl: "comprobante-imprimir.component.html",
	styleUrls: ["comprobante-imprimir.component.less"]
})
export class ComprobanteImprimirComponent implements OnInit {
	@Input()
	public item: Comprobante
	@Input()
	public cobranzas: Cobranza[] = []
	ngOnInit() {}
}
