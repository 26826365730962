

import { Descriptivo } from 'src/app/common/model/Descriptivo';

export class CajaCuentaContable {
    constructor(
        public cuenta?: Descriptivo,
        public centro?: Descriptivo,
        public version?: number,
    ) {
    }

    public static fromData(data: any): CajaCuentaContable {
        if (!data) return null;
        const o: CajaCuentaContable = new CajaCuentaContable(
            Descriptivo.fromData(data.cuenta),
            Descriptivo.fromData(data.centro)
        );

        o.version = data.version; 
        return o;
    }

}