import { Parametrico } from "src/app/common/model/Parametrico"

export class TipoVariante extends Parametrico {
	public static ADULTO: string = "ADT"
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado: boolean = true,
		esSistema: boolean = false,
		peso: number = 99,
		esDefault: boolean = false,
		public color: string = "#000000"
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
	}
	public tipoParametro(): string {
		return "$MODELNAMELOW"
	}
	public static fromData(data: any): TipoVariante {
		if (!data) return null
		const o: TipoVariante = new TipoVariante(data.id, data.codigo, data.descripcion, data.habilitado, data.esSistema, data.peso, data.esDefault, data.color)
		o.version = data.version
		return o
	}
}
