import { Vehiculo } from './../../model/Vehiculo';
import { Producto } from './../../model/Producto';
import * as moment from 'moment';
import { Filtro } from './Filtro';
import { Descriptivo } from './Descriptivo';
export class FiltroVehiculo extends Filtro {


    constructor(name: string,
        filterMap = {
            fechaDesde: "Desde",
            programa: "Programa",
            cantidadPax: "Cantidad Pax"
        }, page = 0, size = 100, sortField = "id", order = 1, guardable = true) {
        super(name, filterMap, page, size, sortField, order, guardable);

    }


    private _fechaDesde: Date;
    public get fechaDesde(): Date {
        return this._fechaDesde;
    }
    public set fechaDesde(v: Date) {

        this._fechaDesde = moment(v).startOf('day').toDate();
        this.update();
    }
    private _fechaHasta: Date;
    public get fechaHasta(): Date {
        return this._fechaHasta;
    }
    public set fechaHasta(v: Date) {

        this._fechaHasta = moment(v).startOf('day').toDate();
        this.update();
    }

    
    private _programa : Descriptivo;
    public get programa() : Descriptivo {
        return this._programa;
    }
    public set programa(v : Descriptivo) {
        this._programa = v;
        this.update()
    }

    
    private _cantidadPax : number;
    public get cantidadPax() : number {
        return this._cantidadPax;
    }
    public set cantidadPax(v : number) {
        this._cantidadPax = v;
        this.update()
    }

    
    private _vehiculo : Vehiculo;
    public get vehiculo() : Vehiculo {
        return this._vehiculo;
    }
    public set vehiculo(v : Vehiculo) {
        this._vehiculo = v;
    }
    
    
    

    public clean() {
        let cleanVal = {
            searchStr: "",
            fechaDesde: moment().startOf('day').toDate(),
            programa: null,
            cantidad: null,
            vehiculo: null,
            sortOrder: 1
        }
        this.patchValue(cleanVal);
    }

    public patchValue(v: any) {
        super.patchValue(v, false);
        this._searchStr = v.searchStr;

        this._fechaDesde = v.fechaDesde ? new Date(v.fechaDesde) : null;
        this._cantidadPax = v.cantidadPax;
        this._programa = Descriptivo.fromData(v.programa)
        this._vehiculo = v.vehiculo
        this.update();
    }


}