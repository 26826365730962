<div class="grid" style="width: 100%; overflow: hidden;">
    <div class="col-12 text-center" style="margin-top: 3em;">
        <img src="{{mainLogo}}" />
    </div>
    <div class="col-12">
        <p class="text-center">
            <span *ngIf="estado=='b'">Buscando el archivo...</span>
            <span *ngIf="estado=='d'">Su archivo se descargará a la brevedad.</span>
            <span *ngIf="estado=='d'" class="small clickable top-3" (click)="retryLink()">Si no comienza la descarga,
                haga
                click <strong>aquí</strong></span>
            <span *ngIf="estado=='e'">No se encuentra el archivo!</span>
        </p>
    </div>
</div>
