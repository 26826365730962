<div class="grupo-container {{ grupo?.hide ? 'hide' : '' }} {{ styleClass }} {{ 'id_' + grupo.id }}" *ngIf="grupo">
	<div
		class="grupo {{ error }} grid {{ grupo?.class }} {{ grupo?.sinAsignar ? 'incompleto' : '' }}"
		(drop)="ocuparLugar($event)"
		pDraggable="grupo"
		dragHandle=".titulo-grupo"
		pDroppable="paxs"
		[pDraggableDisabled]="!draggable"
		[ngClass]="{ small: !full && grupo?.duracion < 3 && !grupo?.producto?.esTercerizado() }"
		(onDragStart)="startDragGrupo($event)"
		(click)="verDetalle()"
		(dragenter)="onDragOver($event, grupo)"
		(dragend)="onDragEnd($event, grupo)"
		(dragleave)="onDragLeave($event)"
	>
		<ng-container *ngTemplateOutlet="full || grupo?.duracion >= 3 || grupo?.producto?.esTercerizado() ? fullSize : small"></ng-container>
	</div>
</div>
<div></div>
<p-dialog
	[(visible)]="displayModal"
	[modal]="true"
	[styleClass]="'grupo-dialog'"
	appendTo="body"
	[header]="'CANTIDAD_A_MOVER' | translate"
	*ngIf="displayModal"
>
	<span class="p-float-label" style="display: flex; justify-content: center">
		<input
			type="number"
			autofocus
			(focusin)="onFocus($event)"
			[(ngModel)]="aMover"
			pInputText
			#aMoverM="ngModel"
			name="aMover"
			required
			[min]="1"
			[max]="maximoValor"
			style="width: 50%"
		/>
	</span>
	<error-tag [model]="aMoverM"></error-tag>
	<p-footer>
		<button [disabled]="aMoverM.errors" type="button" pButton icon="pi pi-check" (click)="onConfirm($event)" label="Aceptar"></button>
		<button type="button" pButton icon="pi pi-times" (click)="onCancel($event)" label="Cancelar"></button>
	</p-footer>
</p-dialog>
<ng-template #small>
	<i
		class="fa delete"
		[ngClass]="{ 'fa-trash': grupo?.gruposReserva?.length == 1, 'fa-external-link': grupo?.gruposReserva?.length > 1 }"
		*ngIf="borrable"
		(click)="borrar($event)"
		tooltipPosition="bottom"
		pTooltip="{{ grupo?.gruposReserva?.length > 1 ? 'Quitar pax del grupo' : 'Borrar grupo' }}"
	></i>
	<profile-badge
		[url]="grupo?.producto?.imagenUrl"
		tooltipPosition="bottom"
		pTooltip="{{ grupo?.producto?.descripcion }}"
		class="small-badge circular titulo-grupo"
	></profile-badge>
	<span class="paxs-small" pDraggable="pax" (onDragStart)="startPaxDrag($event, null, 1, true)" (dragend)="onDragEnd($event, grupo)">
		<i class="fa fa-users"></i> x {{ grupo?.lugaresOcupados }}</span
	>
	<selector-idioma [idioma]="idioma" [readOnly]="true" *ngFor="let idioma of grupo?.idiomas"> </selector-idioma>
</ng-template>
<ng-template #detailGrupo let-contextDialog="dialog" let-grupo>
	<div class="col-12 data-content">
		<div class="grid align-content-center justify-content-start">
			<div class="col-8" *ngIf="mostrarPuestos">
				<div class="grid grid-nogutter align-items-center">
					<div class="col-fix badge-button-plus-wrapper" *ngFor="let puesto of grupo?.puestos">
						<puesto-badge
							[mostrarEstado]="true"
							[(ngModel)]="puesto.personal"
							[puesto]="puesto"
							[verDetalle]="false"
							[disabled]="readonly || !permiteAsignacion"
							[solicitarInsumos]="puesto?.puesto?.esExterno"
							class="text-center"
							[grupo]="grupo"
							[size]="badgeSize"
							[permiteGestionCostos]="permiteGestionCostos"
							(onSeleccion)="asignar($event)"
							(onVerCostos)="mostrarPuestoAdicional($event)"
							(onDeseleccion)="desasignar($event)"
							[ausencias]="ausenciasPuesto"
							[disponibilidad]="disponibilidadPuesto"
						>
						</puesto-badge>
						<i class="fa fa-trash button-borrar" *ngIf="puesto?.esAdicional && permiteGestionCostos" (click)="borrarPuesto(puesto)"></i>
					</div>

					<div class="col-fix badge-button-plus-wrapper" *ngIf="permiteAgregarPuestos">
						<p-button
							class="badge-button-plus"
							styleClass="badge-button-plus p-button-rounded p-button-outlined p-button-success p-button-rounded p-button-outlined"
							icon="fa fa-plus"
							pTooltip="{{ 'AGREGAR_PUESTO' | translate }}"
							(click)="mostrarPuestoAdicional()"
						>
						</p-button>
					</div>
				</div>
			</div>
			<div class="col-4 text-right" *ngIf="!grupo?.esDelegado && !grupo?.actividadCompletada && !grupo.esTercerizado()">
				<button pButton (click)="mostrarDelegar(grupo)" [label]="'DELEGAR' | translate" [icon]="'fa fa-send'"></button>
			</div>

			<div class="item-reserva col-12 card" *ngFor="let itemReserva of grupo?.gruposReserva" (click)="clickItem($event, itemReserva)">
				<ng-container *ngTemplateOutlet="itemReservaTemplate; context: { $implicit: grupo, itemReserva: itemReserva }"></ng-container>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #fullSize>
	<div class="grupo-header col-12">
		<span class="titulo-grupo" tooltipPosition="bottom" pTooltip="{{ grupo?.producto?.descripcion }} - {{ grupo?.id }}">
			{{ grupo?.producto?.descripcionGeneral }}

			<small class="destacado block ml-2" *ngFor="let destacado of grupo?.adicionalesDestacados">
				{{ destacado?.descripcion }}
			</small>
			<small
				class="block pt-2 pb-2"
				*ngIf="mostrarVehiculo && grupo?.vehiculoAsignado?.id"
				[ngClass]="{ clickable: vehiculoEditable }"
				(click)="editarVehiculo($event, opVehiculos)"
				>{{ grupo?.vehiculoAsignado?.descripcion }} {{ verFecha ? " - " + (grupo?.fechaActividad | date: "dd/MM/yyyy") : "" }}</small
			>
			<small
				class="block pt-2 pb-2"
				*ngIf="verFecha && (!mostrarVehiculo || !grupo?.vehiculoAsignado?.id)"
				[ngClass]="{ clickable: vehiculoEditable }"
				(click)="editarVehiculo($event, opVehiculos)"
				>{{ verFecha ? (grupo?.fechaActividad | date : "dd/MM/yyyy") : "" }}</small
			>
		</span>

		<div class="adicionales text-right">
			<span
				(click)="habilitarEdicionFecha($event)"
				class="hora-actividad"
				*ngIf="grupo?.fechaActividad"
				tooltipPosition="bottom"
				pTooltip="Hora de actividad: {{ grupo?.fechaActividad | date: 'HH:mm' }}"
				><i class="fa fa-clock-o mr-1"></i>{{ grupo?.fechaActividad | date: "HH:mm" }}</span
			>

			<span class="bloqueo-grupo" *ngIf="grupo.actividadCompletada"
				><i class="fa fa-flag-checkered" tooltipPosition="bottom" pTooltip="{{ 'Actividad finalizada' | translate }}"></i
			></span>
			<span class="lugares-disponibles-grupo clickable" *ngIf="!grupo.bloqueado" (click)="modificarDisponibles($event)"
				>{{ grupo?.lugaresLibres }} <i class="fa fa-users" tooltipPosition="bottom" pTooltip="{{ 'LUGARES_DISPONIBLES' | translate }}"></i
			></span>
			<span class="bloqueo-grupo" *ngIf="grupo.bloqueado"
				><i class="fa fa-lock" tooltipPosition="bottom" pTooltip="{{ 'BLOQUEADO_TOOLTIP' | translate }}"></i
			></span>

			<span class="borrable" *ngIf="borrable">
				<i
					class="fa"
					[ngClass]="{ 'fa-trash': grupo?.gruposReserva?.length <= 1, 'fa-external-link': grupo?.gruposReserva?.length > 1 }"
					*ngIf="borrable"
					(click)="borrar($event)"
					tooltipPosition="bottom"
					pTooltip="{{ grupo?.gruposReserva?.length > 1 ? 'Quitar pax del grupo' : 'Borrar grupo' }}"
				></i
			></span>
		</div>
	</div>
	<div class="col-12 data-content" [ngClass]="{ 'con-vehiculo': mostrarVehiculo }">
		<div class="grid align-content-center justify-content-start">
			<div class="col-fixed puestos" *ngIf="mostrarPuestos && grupo?.puestos?.length > 0">
				<puesto-badge
					[mostrarEstado]="true"
					[(ngModel)]="puesto.personal"
					[puesto]="puesto"
					[verDetalle]="false"
					[disabled]="readonly || !permiteAsignacion"
					[solicitarInsumos]="puesto?.puesto?.esExterno"
					*ngFor="let puesto of grupo?.puestos"
					class="text-center"
					[grupo]="grupo"
					[size]="badgeSize"
					[permiteGestionCostos]="permiteGestionCostos"
					(onSeleccion)="asignar($event)"
					(onDeseleccion)="desasignar($event)"
					(onVerCostos)="mostrarPuestoAdicional($event)"
					[ausencias]="ausenciasPuesto"
					[disponibilidad]="disponibilidadPuesto"
				>
				</puesto-badge>
			</div>
			<div class="col paxs">
				<div
					class="item-reserva col-12 card"
					*ngFor="let itemReserva of grupo?.gruposReserva"
					pDraggable="pax"
					[pDraggableDisabled]="!esSeleccionable(itemReserva)"
					(onDragStart)="startPaxDrag($event, itemReserva, itemReserva.cantidadPax, false)"
					(dragend)="onDragEnd($event, grupo)"
					(dragover)="$event.preventDefault()"
					(drop)="intercambiar($event, itemReserva)"
					(click)="clickItem($event, itemReserva)"
				>
					<ng-container *ngTemplateOutlet="itemReservaTemplate; context: { $implicit: grupo, itemReserva: itemReserva }"></ng-container>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #itemReservaTemplate let-grupo let-itemReserva="itemReserva">
	<div class="grid align-content-center" tooltipPosition="bottom" pTooltip="{{ itemReserva?.itemReserva?.observaciones }}">
		<div class="col-7" style="display: flex">
			<span
				class="nombre"
				tooltipPosition="bottom"
				pTooltip="{{ itemReserva?.descripcion }} - {{ itemReserva?.itemReserva?.producto?.descripcion }}"
				tooltipPosition="top"
				(onDragStart)="startPaxDrag($event, itemReserva, itemReserva.cantidadPax, false)"
				>{{ itemReserva?.descripcion }}

				<small
					*ngIf="
						verCliente &&
						itemReserva?.itemReserva?.nombreCliente != itemReserva?.descripcion &&
						itemReserva?.itemReserva?.nombreCliente != 'Consumidor Final'
					"
					class="block"
					>{{ itemReserva?.itemReserva?.nombreCliente }}
				</small>
			</span>

			<span
				class="pax"
				pDraggable="pax"
				(dragend)="onDragEnd($event, grupo)"
				[pDraggableDisabled]="!esSeleccionable(itemReserva)"
				(onDragStart)="startPaxDrag($event, itemReserva, itemReserva.cantidadPax, true)"
			>
				<i class="fa fa-user" [style.color]="itemReserva?.colorVariante" pTooltip="{{ itemReserva?.descVariante }}"></i>
			</span>
			<span class="pax-total" *ngIf="itemReserva.cantidadPax > 1">x {{ itemReserva.cantidadPax }}</span>
		</div>
		<div class="col restricciones">
			<img
				class="restriccion"
				src="{{ r?.imagenUrl }}"
				alt=""
				tooltipPosition="bottom"
				pTooltip="{{ r.descripcion }}"
				style="height: 2em"
				*ngFor="let r of itemReserva?.itemReserva?.restricciones"
			/>
			<span class="pickup"
				><i class="fa fa-clock-o" *ngIf="itemReserva?.itemReserva?.pickUp"></i>{{ itemReserva?.itemReserva?.pickUp | date: "HH:mm" }}</span
			>
			<img
				src="{{ itemReserva?.idiomaServicio?.imagenUrl }}"
				alt=""
				class="idioma"
				tooltipPosition="bottom"
				pTooltip="{{ itemReserva?.idiomaServicio?.descripcion }}"
			/>
		</div>

		<div class="col-12" style="padding: 0 10px">
			<div class="grid grid-nogutter">
				<div class="col-6 lg:col-8">
					<span class="overflow oneline direccion" pTooltip="{{ itemReserva?.direccion }}" tooltipPosition="bottom"
						><i
							class="fa {{ itemReserva?.itemReserva?.estadoConsentimiento?.icono }}"
							pTooltip="{{ 'Estado de consentimiento: ' + itemReserva?.itemReserva?.estadoConsentimiento?.descripcion }}"
							style="margin-right: 5px"
							[ngStyle]="{ color: itemReserva?.itemReserva?.estadoConsentimiento?.color }"
						></i>
						<i class="fa fa-building" style="margin-right: 5px"></i>{{ itemReserva?.referenciaDireccion || "Sin definir" }}</span
					>
				</div>
				<div class="col-6 lg:col-4 text-right">
					<span *ngIf="itemReserva?.itemReserva?.saldo != 0" class="saldo"
						><i class="fa fa-warning" style="margin-right: 5px"></i
						>{{ itemReserva?.itemReserva?.saldo | currency: "USD" : (itemReserva?.itemReserva?.monedaReserva?.simbolo || "$") + " " }}</span
					>
				</div>
				<div class="col-12 mt-1 small" *ngIf="itemReserva?.itemReserva?.referencia">
					<small><strong>REF:</strong> {{ itemReserva?.itemReserva?.referencia }}</small>
				</div>
				<div class="col-12" *ngIf="verObservaciones && itemReserva?.itemReserva?.observaciones">
					<span class="small">{{ itemReserva?.itemReserva?.observaciones }}</span>
				</div>
				<div class="col-12 mt-1 small" *ngIf="verInfoContacto">
					<contactos [data]="itemReserva?.itemReserva?.contactos"></contactos>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #detallePuestoEditado let-puesto>
	<div class="grid" *ngIf="puesto">
		<div class="col">
			<descriptivo-material-selector
				[(ngModel)]="puesto.tipoPuesto"
				[service]="tipoPuestoService"
				[readonly]="readonly || puesto?.id"
				[limpiable]="true"
				[isLazy]="true"
				[label]="'TIPO_DE_PUESTO' | translate"
				name="tipoPuesto"
			>
			</descriptivo-material-selector>
		</div>
		<div class="col-6" *ngIf="puesto?.tipoPuesto">
			<puesto-badge
				[mostrarEstado]="true"
				[puesto]="puesto"
				[(ngModel)]="puesto.personalDefault"
				[disabled]="readonly || puesto?.id"
				[verDetalle]="true"
				[grupo]="grupo"
				name="{'personalDefaul'}"
			>
			</puesto-badge>
		</div>
		<div class="col-12" *ngIf="puesto?.tipoPuesto">
			<div class="grid">
				<div class="col-12 subtitulo">
					<span
						>{{ "COSTOS_ASOCIADOS" | translate }}
						<button pButton (click)="agregarInsumo(puesto)" icon="fa fa-plus" *ngIf="!readonly"></button>
					</span>
				</div>
				<div class="col-12 insumo-wrapper" *ngFor="let insumoPuesto of puesto.insumos; let i = index">
					<p-card>
						<div class="grid">
							<div class="col-12">
								<insumo
									[cantidadEditable]="insumoPuesto.esPorServicio"
									[contenedor]="puesto"
									[insumo]="insumoPuesto"
									[index]="i"
									[tipo]="puesto.tipoPuesto"
									[proporcion]="false"
									[esMasterData]="false"
								>
								</insumo>
							</div>
							<div class="col-12 field" *ngIf="puesto?.proveedor">
								<div class="grid">
									<div class="col-fixed align-self-center">
										<p-checkbox
											[(ngModel)]="insumoPuesto.precioParticular"
											label="{{ 'ES_PRECIO_PARTICULAR' | translate }}"
											name="esPorServicio"
											[binary]="true"
											tooltipPosition="bottom"
											pTooltip="{{ 'UN_PRECIO_PARTICULAR_FUERA_DE_LISTADO' | translate }}"
											[disabled]="readonly"
										>
										</p-checkbox>
									</div>
									<div class="col" *ngIf="insumoPuesto?.precioParticular">
										<div class="grid">
											<div class="col-3">
												<parametrico-selector
													[service]="monedaService"
													#moneda="ngModel"
													[(ngModel)]="insumoPuesto.moneda"
													[readonly]="readonly"
													[limpiable]="false"
													name="moneda"
													[titulo]="'MONEDA' | translate"
													[required]="true"
												>
												</parametrico-selector>
											</div>
											<div class="col-6 p-fluid">
												<span-dinero-editable
													[(ngModel)]="insumoPuesto.precio"
													[editable]="!readonly"
													[label]="'Importe unitario' | translate"
													name="importe"
													[moneda]="insumoPuesto.moneda"
													[minValue]="0"
												>
												</span-dinero-editable>
											</div>
											<div class="col-3">
												<parametrico-selector
													[service]="tipoIVAService"
													#moneda="ngModel"
													[(ngModel)]="insumoPuesto.tipoIVA"
													[readonly]="readonly"
													[limpiable]="false"
													name="TIPO_IVA_GRABADO"
													[titulo]="'TIPO_IVA_GRABADO' | translate"
													[required]="false"
												>
												</parametrico-selector>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</p-card>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<p-dialog [(visible)]="visualizarDetalle" [modal]="true" [styleClass]="'grupo-dialog'" appendTo="body" *ngIf="visualizarDetalle">
	<ng-template pTemplate="header">
		<span class="descripcion" tooltipPosition="bottom" pTooltip="{{ grupo?.producto?.descripcion }}">{{ grupo?.producto?.descripcion }}</span>
		<button
			pButton
			icon="fa fa-flag-checkered "
			class="p-button-secondary"
			*ngIf="!grupo?.actividadCompletada"
			(click)="completar()"
			pTooltip="{{ 'COMPLETAR' | translate }}"
		></button>
		<button
			pButton
			*ngIf="bloqueable && !grupo?.actividadCompletada"
			(click)="bloquear()"
			class="bloqueo-grupo p-button-secondary"
			[icon]="grupo?.bloqueado ? 'fa fa-unlock' : 'fa fa-lock'"
			pTooltip="{{ (bloqueable && !grupo.bloqueado ? 'BLOQUEAR_TOOLTIP' : 'DESBLOQUEAR_TOOLTIP') | translate }}"
		></button>
		<button
			pButton
			*ngIf="borrable"
			(click)="borrar($event)"
			class="bloqueo-grupo p-button-secondary"
			[icon]="grupo?.gruposReserva?.length > 1 ? 'fa fa-external-link' : 'fa fa-trash'"
			tooltipPosition="bottom"
			pTooltip="{{ grupo?.gruposReserva?.length > 1 ? 'Quitar pax del grupo' : 'Borrar grupo' }}"
		></button>
		<button
			pButton
			*ngIf="!grupo?.bloqueado && !grupo?.actividadCompletada"
			(click)="modificarDisponibles($event)"
			class="bloqueo-grupo p-button-secondary"
			[label]="grupo?.lugaresLibres"
			[icon]="'fa fa-users'"
			tooltipPosition="bottom"
			pTooltip="{{ 'LUGARES_DISPONIBLES' | translate }}"
		></button>
	</ng-template>
	<div class="grupo-detalle" *ngIf="grupo">
		<h4 *ngIf="grupo?.actividadCompletada" style="margin: 0">Actividad Finalizada</h4>
		<ng-container *ngTemplateOutlet="detailGrupo; context: { dialog: true, $implicit: grupo }"></ng-container>
	</div>
</p-dialog>

<p-dialog
	appendTo="body"
	*ngIf="verDelegar"
	[(visible)]="verDelegar"
	[modal]="true"
	[style]="{ 'max-width': '40vw' }"
	[styleClass]="'grupo-dialog'"
	header="{{ 'Delegación de servicio' | translate }}"
>
	<div class="col-12" *ngIf="delegacion">
		<ng-container *ngTemplateOutlet="detallePuestoEditado; context: { $implicit: delegacion.puesto }"></ng-container>
	</div>
	<p-footer>
		<button pButton label="{{ 'CANCELAR' | translate }}" (click)="cancelarDelegacion()"></button>
		<button pButton label="{{ 'CONFIRMAR_DELEGAR' | translate }}" (click)="confirmarDelegacion()"></button>
	</p-footer>
</p-dialog>

<p-dialog
	appendTo="body"
	*ngIf="verPuestoAdicional"
	#dAdicional
	[(visible)]="verPuestoAdicional"
	[modal]="true"
	[style]="{ 'max-width': '40vw' }"
	[styleClass]="'grupo-dialog'"
	header="{{ 'Puesto adicional' | translate }}"
>
	<div class="col-12" *ngIf="puestoEditado">
		<ng-container *ngTemplateOutlet="detallePuestoEditado; context: { $implicit: puestoEditado }"></ng-container>
	</div>
	<p-footer>
		<button pButton label="{{ 'CANCELAR' | translate }}" (click)="dAdicional.close($event)"></button>
		<!--button pButton label="{{ 'AGREGAR_PUESTO' | translate }}" (click)="agregarAdicional(dAdicional)" *ngIf="!puestoEditado?.id"></button-->
		<button pButton label="{{ 'GUARDAR_PUESTO' | translate }}" (click)="guardarPuesto(dAdicional)"></button>
	</p-footer>
</p-dialog>

<p-dialog
	appendTo="body"
	*ngIf="editarFecha"
	[(visible)]="editarFecha"
	[modal]="true"
	[style]="{ 'max-width': '40vw' }"
	[styleClass]="'grupo-dialog'"
	header="{{ 'Editar Hora Actividad' | translate }}"
>
	<div class="grid w-15rem">
		<div class="col-12 p-fluid">
			<span class="p-float-label text-center">
				<p-calendar
					[(ngModel)]="fechaEditada"
					showTime="true"
					[hourFormat]="24"
					[stepMinute]="5"
					#fechaActividad="ngModel"
					name="hora"
					required
					[disabled]="readonly"
					timeOnly="true"
					appendTo="body"
				></p-calendar>
				<label for="">{{ "HORARIO" | translate }}</label>
			</span>
		</div>
		<div class="col-12 p-fluid">
			<div class="p-inputgroup">
				<span class="p-float-label text-center">
					<input pInputText type="number" name="duracion" [(ngModel)]="duracionEditada" />
					<label for="">{{ "DURACION" | translate }}</label>
				</span>
				<span class="p-inputgroup-addon">
					<span>hs</span>
				</span>
			</div>
		</div>
	</div>

	<p-footer>
		<button pButton label="{{ 'CANCELAR' | translate }}" (click)="cerrarFecha()"></button>
		<button pButton label="{{ 'GUARDAR' | translate }}" (click)="guardarFecha()"></button>
	</p-footer>
</p-dialog>

<p-dialog
	appendTo="body"
	*ngIf="verModificarDisponibles"
	[(visible)]="verModificarDisponibles"
	#dModificarDisponibles
	[modal]="true"
	[style]="{ 'max-width': '40vw' }"
	[styleClass]="'grupo-dialog'"
	header="{{ 'Nueva capacidad' | translate }}"
>
	<p-inputNumber [(ngModel)]="nuevaCapacidad" [ngModelOptions]="{ standalone: true }"> </p-inputNumber>

	<p-footer>
		<button pButton label="{{ 'GUARDAR' | translate }}" (click)="aplicarCambioCapacidad()"></button>
		<button pButton label="{{ 'CANCELAR' | translate }}" (click)="verModificarDisponibles = false"></button>
	</p-footer>
</p-dialog>

<p-overlayPanel #opVehiculos [style]="{ width: '60rem' }">
	<ng-template pTemplate>
		<p-table
			#dt1
			[value]="vehiculos"
			selectionMode="single"
			(onRowSelect)="seleccionarVehiculo($event, opVehiculos)"
			[paginator]="true"
			[rows]="10"
			responsiveLayout="scroll"
			[globalFilterFields]="['nombre', 'patente', 'representative.name', 'status']"
		>
			<ng-template pTemplate="caption">
				<div class="flex w-100">
					<span class="p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" />
					</span>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th pSortableColumn="nombre">Nombre<p-sortIcon field="nombre"></p-sortIcon></th>
					<th pSortableColumn="patente">Patente <p-sortIcon field="patente"></p-sortIcon></th>
					<th pSortableColumn="capacidad" class="text-center">Capacidad <p-sortIcon field="capacidad"></p-sortIcon></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData let-vehiculo>
				<tr [pSelectableRow]="rowData">
					<td>{{ vehiculo.nombre }}</td>
					<td>{{ vehiculo.patente }}</td>
					<td class="text-center">{{ vehiculo.capacidad }}</td>
				</tr>
			</ng-template>
		</p-table>
	</ng-template>
</p-overlayPanel>
