import { Injectable } from "@angular/core"
import { AdminAuthGuard } from "./AdminAuthGuard"

@Injectable({
	providedIn: "root"
})
export class ReporteAG extends AdminAuthGuard {
	public getRoles(): string[] {
		return super.getRoles().concat(["ROLE_REPORTES"])
	}
}
@Injectable({
	providedIn: "root"
})
export class PaxPorServiciosAG extends AdminAuthGuard {
	public getRoles(): string[] {
		return super.getRoles().concat(["ROLE_PAX_POR_SERVICIO"])
	}
}
