import { PlantillaPregunta } from 'src/app/model/PlantillaPregunta';

import { Descriptivo } from 'src/app/common/model/Descriptivo';


export class PlantillaPreguntaEncuesta  {
    constructor(
		public id ?: number, 
		public pregunta ?: PlantillaPregunta, 
		public peso ?: number, 
    public version?: number,
    ) {
    }
    public static fromData(data: any): PlantillaPreguntaEncuesta {
        if (!data) return null;
        const o: PlantillaPreguntaEncuesta = new PlantillaPreguntaEncuesta(
			data.id, 
			PlantillaPregunta.fromData(data.pregunta), 
			data.peso, 

        );

        o.version = data.version; 
return o;

    }

}