import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { ProviderConfiguration } from 'src/app/model/ProviderConfiguration';
import { LoadingService } from './../common/services/loading-data-service.service';
@Injectable({
  providedIn: 'root',
})
export class ProviderConfigurationService extends ServicioAbstract<ProviderConfiguration>{
    public baseName(): string {
        return "provider-configuration";
    }
    public parseToEnt(data: any): ProviderConfiguration {
        return ProviderConfiguration.fromData(data);
    }
    public newEnt(): ProviderConfiguration {
        return new ProviderConfiguration();
    }


}