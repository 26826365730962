import { ProfilePic } from "../common/model/ProfilePic"

export class ImagenProducto {
	constructor(public profilePic: ProfilePic, public fileId: number, public esPortada: boolean) {}

	public static fromData(data: any): ImagenProducto {
		if (!data) return null
		const o: ImagenProducto = new ImagenProducto(data.profilePic ? ProfilePic.fromData(data.profilePic) : null, null, data.esPortada)

		return o
	}

	public get imagenUrl() {
		return this.profilePic ? this.profilePic.picPath : "assets/images/default_image.png"
	}
}
