<div class="grid-nopad has-header home wrapper">
	<gridster [options]="options" #grid>
		<gridster-item [item]="config" *ngFor="let config of configs" [ngClass]="{ editando: dbEditable }">
			<div class="header-db" *ngIf="dbEditable || config.fixedHeader || isMobile()">
				<div class="grid">
					<div class="col drag-handle overflow">
						<span>
							{{ db?.title }}
						</span>
					</div>
					<div class="col-fixed" style="width: 2em" (click)="db.verParams($event)">
						<i class="fa fa-cog" *ngIf="dbEditable"></i>
					</div>
					<div class="col-fixed" style="width: 2em" (click)="delete(config)">
						<i class="fa fa-trash" *ngIf="dbEditable"></i>
					</div>
				</div>
			</div>
			<div class="gridster-item-content" [ngClass]="{ 'has-header-db': dbEditable || config.fixedHeader || isMobile() }">
				<dashboard-element [config]="config" #db (onChange)="dbService.guardar()"></dashboard-element>
			</div>
		</gridster-item>
	</gridster>
	<button
		class="floating editar p-button"
		[ngClass]="{ editando: dbEditable }"
		(click)="toggleEditable($event)"
		tooltipPosition="bottom"
		pTooltip="{{ 'AGREGAR_COMPONENTES' | translate }}"
		pButton
		icon="fa {{ dbEditable ? 'fa-save' : 'fa-pencil' }}"
	></button>

	<button
		class="floating ayuda p-button"
		pButton
		tooltipPosition="bottom"
		pTooltip="{{ 'AYUDA' | translate }}"
		icon="fa fa-question"
		(click)="goToHelp('home')"
	></button>

	<button
		class="floating menu"
		(click)="menu.toggle($event)"
		icon="fa fa-plus"
		pButton
		tooltipPosition="bottom"
		pTooltip="{{ 'AGREGAR_COMPONENTES' | translate }}"
		*ngIf="dbEditable"
	></button>

	<button
		class="floating cancelar"
		*ngIf="dbEditable"
		(click)="cancelarEdicion($event)"
		icon="fa fa-times"
		pButton
		tooltipPosition="bottom"
		pTooltip="{{ 'Cancelar cambios' | translate }}"
	></button>
</div>

<p-menu #menu [model]="opcionesDashboard" [popup]="true" appendTo="body"></p-menu>
