<div class="gestor-comprobante content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'COMPROBANTE' | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[readonly]="readonly"
		[hideTitle]="hideTitle"
		[hideButtons]="hideTitle"
		[menuItemAcciones]="menuItemAcciones"
		[subheader]="item?.id ? 'Estado: ' + item?.estado?.descripcion : null"
		[editable]="esEditable() || esAdministrador"
		[verAcciones]="verAcciones"
		(onCancelar)="handleCancelar($event)"
		(onEditar)="onEdit()"
		[form]="ComprobanteForm"
		[multilenguaje]="false"
		[customGuardar]="guardar"
		#modelGestor
	>
		<div class="gestor-form" model-type="dataContent">
			<form #ComprobanteForm="ngForm" id="Comprobanteform" [class]="'print-hidden'">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-3 p-fluid field">
						<descriptivo-material-selector
							[allowDefault]="!item?.id"
							[service]="clienteService"
							[isLazy]="true"
							[label]="'CLIENTE' | translate"
							[limpiable]="limpiable"
							[(ngModel)]="item.cliente"
							[permiteNuevo]="false"
							name="cliente"
							[getData]="clienteService.getActivos"
							[required]="true"
							[readonly]="readonly || !esEditable()"
							(onSelect)="checkEsIntegrado($event)"
						>
						</descriptivo-material-selector>
					</div>
					<div class="col-12 lg:col-3 p-fluid field">
						<descriptivo-material-selector
							[allowDefault]="!item?.id"
							[service]="puntoDeVentaService"
							[isLazy]="true"
							[label]="'PUNTO_VENTA' | translate"
							[limpiable]="limpiable"
							[(ngModel)]="item.puntoDeVenta"
							[permiteNuevo]="false"
							name="puntoDeVenta"
							[required]="true"
							[readonly]="readonly || !esEditable()"
						>
						</descriptivo-material-selector>
					</div>
					<div class="col-12 lg:col-3 p-fluid field">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'NUMERODOCUMENTO_PH' | translate }}"
								[(ngModel)]="item.numeroComprobante"
								tooltipPosition="bottom"
								pTooltip="{{ 'NUMERODOCUMENTO_TOOLTIP' | translate }}"
								type="text"
								#numeroComprobante="ngModel"
								name="numeroComprobante"
								[readonly]="readonly || (!esEditable() && !esAdministrador)"
							/>
							<label>{{ "NUMERODOCUMENTO" | translate }}</label>
						</span>
						<error-tag [model]="numeroComprobante"></error-tag>
					</div>
					<div class="col-12 lg:col-3 p-fluid field">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[listado]="opcionesTipoComprobante"
							#tipoComprobante="ngModel"
							[(ngModel)]="item.tipoComprobante"
							[limpiable]="true"
							[permiteNuevo]="true"
							name="tipo-comprobante"
							[titulo]="'TIPOCOMPROBANTE' | translate"
							[readonly]="readonly || prevTipo?.esOficial"
							(onSelect)="actualizarTipoComprobante($event)"
						>
						</parametrico-selector>
					</div>
					<div class="col-12 lg:col-fixed w-12rem p-fluid field">
						<span class="p-float-label">
							<p-calendar
								[(ngModel)]="item.fecha"
								#fecha="ngModel"
								name="fecha"
								required
								[disabled]="readonly || !esEditable()"
								dateFormat="dd/mm/yy"
							></p-calendar>
							<label for="fecha">{{ "FECHA" | translate }}</label>
						</span>
						<error-tag [model]="fecha"></error-tag>
					</div>
					<div class="col-12 lg:col-fixed w-12rem p-fluid field">
						<span class="p-float-label">
							<p-calendar
								[(ngModel)]="item.fechaVencimiento"
								#fecha="ngModel"
								name="fechaVencimiento"
								required
								[disabled]="readonly || !esEditable()"
								dateFormat="dd/mm/yy"
							></p-calendar>
							<label for="fechaVencimiento">{{ "FECHAVENCIMIENTO" | translate }}</label>
						</span>
						<error-tag [model]="fechaVencimiento"></error-tag>
					</div>

					<div class="col-12 lg:col-4 p-fluid field" *ngIf="item?.tipoComprobante?.esNota">
						<span class="p-float-label" (click)="buscarComprobante()">
							<input pInputText type="text" [value]="item?.comprobanteAsociado?.descripcion" readonly />
							<label>{{ "COMPROBANTE_ASOCIADO" | translate }}</label>
						</span>
					</div>
					<div class="col p-fluid field">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="text"
								#descripcion="ngModel"
								name="descripcion"
								autocomplete="off"
								[readonly]="readonly"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"> </error-tag>
					</div>
					<div class="col-6 lg:col-2 p-fluid field" *ngIf="item.monedaOrigen">
						<cotizacion
							[monedaRef]="item.monedaOrigen"
							[monedaElegida]="item.moneda"
							[monedaEditable]="!item?.id"
							name="cotizacion"
							[(ngModel)]="item.cotizacion"
							[readonly]="true"
						></cotizacion>
					</div>
					<div class="col-fixed w-15rem p-fluid field checkbox-container">
						<p-checkbox
							[(ngModel)]="item.incluyeImpuestos"
							[binary]="true"
							[readonly]="readonly || !esEditable() || item?.items?.length > 0"
							name="incluyeImpuestos"
							[label]="'INCLUYE_IMPUESTOS' | translate"
							[pTooltip]="'INCLUYE_IMPUESTOS_TOOLTIP' | translate"
						></p-checkbox>
					</div>
					<div class="col-12 lg:col p-fluid field" *ngIf="!item.monedaOrigen">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[service]="monedaService"
							#moneda="ngModel"
							[(ngModel)]="item.moneda"
							[limpiable]="true"
							name="moneda"
							[titulo]="'MONEDA' | translate"
							required
							[readonly]="readonly || !soloCabecera"
							(seleccionadoChange)="onMonedaChange($event)"
						>
						</parametrico-selector>
					</div>
					<div class="col-6 lg:col-2 p-fluid field" *ngIf="item.moneda?.codigo != monedaEntorno?.codigo">
						<span-dinero-editable
							[(ngModel)]="item.cotizacionAfip"
							[editable]="!readonly"
							label="{{ !item?.moneda ? ('COTIZACION_A_LOCAL' | translate) : item?.moneda?.codigo + ' ► ' + monedaEntorno.codigo }}"
							name="name"
							[minValue]="0"
							[decimals]="6"
						>
						</span-dinero-editable>
					</div>
					<div class="col-12 p-fluid field" *ngIf="!readonly && esEditable()">
						<p-checkbox
							[(ngModel)]="item.datosFacturacionDistintos"
							[binary]="true"
							name="datosFacturacionDistintos"
							[label]="'DATOS_FACTURACION_DISTINTOS' | translate"
						></p-checkbox>
					</div>

					<ng-container *ngIf="item.datosFacturacionDistintos">
						<div class="col-12 lg:col-2 p-fluid field">
							<parametrico-selector
								[allowDefault]="!item?.id"
								[readonly]="readonly"
								[tipoParametro]="'tipocliente'"
								#tipoClienteFacturacion="ngModel"
								[(ngModel)]="item.tipoClienteFacturacion"
								name="tipoClienteFacturacion"
								[titulo]="'TIPOCLIENTE' | translate"
								(onSelect)="updateCuit()"
								required
								[disabled]="readonly || !esEditable()"
							>
							</parametrico-selector>
						</div>
						<div class="col-12 lg:col-3 p-fluid field">
							<parametrico-selector
								[allowDefault]="!item?.id"
								[readonly]="readonly"
								#nacionalidadFacturacion="ngModel"
								[static]="true"
								[(ngModel)]="item.nacionalidadFacturacion"
								[service]="nacionalidadService"
								[limpiable]="false"
								[permiteNuevo]="true"
								name="nacionalidadFacturacion"
								[titulo]="'NACIONALIDAD' | translate"
								(onSelect)="updateCuit()"
								required
								[disabled]="readonly || !esEditable()"
							>
							</parametrico-selector>
						</div>
						<div class="col-6 lg:col-2 p-fluid field">
							<parametrico-selector
								[allowDefault]="!item?.id"
								[readonly]="readonly"
								[service]="tipoIvaService"
								[limpiable]="true"
								#ivaFacturacion="ngModel"
								[(ngModel)]="item.ivaFacturacion"
								name="ivaFacturacion"
								[titulo]="'IVA' | translate"
								[disabled]="esExtranjero || item?.id"
							>
							</parametrico-selector>
						</div>
						<div class="col-6 lg:col-2 p-fluid field">
							<parametrico-selector
								[allowDefault]="!item?.id"
								[tipoParametro]="'tipoDocumento'"
								#tipoDocumentoFacturacion="ngModel"
								[(ngModel)]="item.tipoDocumentoFacturacion"
								[permiteNuevo]="false"
								name="tipoDocumentoFacturacion"
								[titulo]="'TIPODOCUMENTO' | translate"
								required
								[readonly]="readonly || esExtranjero || item?.id"
							>
							</parametrico-selector>
						</div>

						<div class="col-6 lg:col-3 p-fluid field" *ngIf="item?.tipoDocumentoFacturacion?.codigo == '80'">
							<span class="p-float-label">
								<input
									pInputText
									[disabled]="readonly || !esEditable()"
									placeholder="{{ 'NUMERODOCUMENTO_PH' | translate }}"
									#numeroDocumentoFacturacion="ngModel"
									cuitValidator
									[(ngModel)]="item.numeroDocumentoFacturacion"
									tooltipPosition="bottom"
									pTooltip="{{ 'NUMERODOCUMENTO_TOOLTIP' | translate }}"
									type="text"
									[disabled]="esExtranjero"
									name="numeroDocumento"
									[readonly]="readonly || !esEditable() || item?.id"
								/>
								<small class="error" *ngIf="esExtranjero && item?.nacionalidad && !item?.numeroDocumento">
									Falta cuit para la nacionalidad elegida. Revíselo
									<span class="link" (click)="editarNacionalidad(item.nacionalidad); gestor = gestorNacionalidad">aquí</span>
								</small>

								<label>{{ "CUIT" | translate }} </label>
							</span>
							<error-tag [model]="numeroDocumentoFacturacion"> </error-tag>
						</div>
						<div class="col-6 lg:col-3 p-fluid field" *ngIf="item?.tipoDocumentoFacturacion?.codigo != '80'">
							<span class="p-float-label">
								<input
									pInputText
									[disabled]="readonly || !esEditable() || item?.id"
									placeholder="{{ 'NUMERODOCUMENTO_PH' | translate }}"
									#numeroDocumento="ngModel"
									[(ngModel)]="item.numeroDocumentoFacturacion"
									tooltipPosition="bottom"
									pTooltip="{{ 'NUMERODOCUMENTO_TOOLTIP' | translate }}"
									type="text"
									name="numeroDocumentoFacturacion"
								/>
								<label>{{ "Número de Documento" | translate }} </label>
							</span>
							<error-tag [model]="numeroDocumentoFacturacion"> </error-tag>
						</div>
						<div class="col-6 lg:col-3 p-fluid field">
							<span class="p-float-label">
								<input
									pInputText
									placeholder="{{ 'NOMBRE' | translate }}"
									#nombreFacturacion="ngModel"
									name="nombreFacturacion"
									[(ngModel)]="item.nombreFacturacion"
									[disabled]="readonly || item?.id"
									tooltipPosition="bottom"
									pTooltip="{{ 'NOMBRE_TOOLTIP' | translate }}"
									type="text"
									[readonly]="readonly"
									required
								/>
								<label>
									{{ "NOMBRE" | translate }}
								</label>
							</span>
							<error-tag [model]="nombreFacturacion"> </error-tag>
						</div>

						<div class="col-12 lg:col-6 p-fluid field">
							<span class="p-float-label">
								<input
									pInputText
									[readonly]="readonly"
									placeholder="{{ 'DOMICILIO_PH' | translate }}"
									#domicilio="ngModel"
									name="domicilioFacturacion"
									[(ngModel)]="item.domicilioFacturacion"
									tooltipPosition="bottom"
									pTooltip="{{ 'DOMICILIO_TOOLTIP' | translate }}"
									type="text"
								/>
								<label>{{ "DOMICILIO" | translate }}</label>
							</span>
						</div>
					</ng-container>
					<div class="col-12">
						<span class="p-float-label subtitle">
							<abm-hijo
								class="p-inputwrapper-filled"
								*ngIf="item?.tipoComprobante"
								[items]="item.items"
								[getItem]="getItemComprobante"
								(onAgregar)="guardarItem($event)"
								(onQuitar)="item.quitarItemComprobante($event)"
								[newItem]="item.newItemComprobante"
								[gestor]="gestorItemCombrobante"
								[itemTemplate]="itemTemplate"
								[editable]="!readonly && item?.cliente?.id && esEditable()"
							>
								<ng-template #itemTemplate let-itemRow let-r="index">
									<ng-container *ngTemplateOutlet="itemComprobante; context: { $implicit: itemRow, index: r }"> </ng-container>
								</ng-template>
							</abm-hijo>
							<label>{{ "ITEMS" | translate }}</label>
						</span>
						<small *ngIf="!item?.tipoComprobante">Elija un tipo de comprobante</small>
					</div>
					<div class="col-6 lg:col-2 mb-5">
						<span-dinero-editable
							[(ngModel)]="item.bonificacion"
							[label]="'Ajuste' | translate"
							[editable]="!readonly && esEditable()"
							name="bonificacion"
							[moneda]="item.moneda"
						>
						</span-dinero-editable>
					</div>
					<div class="col-6 lg:col-2 mb-5">
						<span-dinero-editable
							[(ngModel)]="item.redondeo"
							[label]="'REDONDEO' | translate"
							[editable]="!readonly && esEditable()"
							name="REDONDEO"
							[moneda]="item.moneda"
						>
						</span-dinero-editable>
					</div>

					<div class="col-12 mb-2" *ngIf="item?.esNoOficial">
						<div class="grid">
							<div class="col-12 lg:col-6">
								<adjunto [item]="item" [readonly]="readonly" [uploadUrl]="uploadUrl"></adjunto>
							</div>
						</div>
					</div>

					<div class="col-12" *ngIf="cobranzas?.length" sc>
						<span class="p-float-label subtitle">
							<p-dataView #dv [value]="cobranzas" [paginator]="false" class="p-inputwrapper-filled">
								<ng-template let-cobro pTemplate="listItem" let-i="rowIndex">
									<div class="col-12 clickeable" (click)="irACobranza(cobro)">
										<div class="grid align-content-center justify-content-center">
											<div class="lg:col-2 col-12 p-fluid">
												<span class="p-float-label">
													<input type="text" pInputText [value]="cobro?.id" readonly />
													<label *ngIf="i == 0">{{ "NUMERODOCUMENTO" | translate }} </label>
												</span>
											</div>
											<div class="lg:col-3 col-12 p-fluid">
												<span class="p-float-label">
													<input type="text" pInputText readonly [value]="cobro?.descripcion" />
													<label *ngIf="i == 0">{{ "Descripción" | translate }}</label>
												</span>
											</div>
											<div class="lg:col-3 col-12 p-fluid">
												<span class="p-float-label">
													<input type="text" pInputText readonly [value]="cobro?.responsable?.descripcion" />
													<label *ngIf="i == 0">{{ "Responsable" | translate }}</label>
												</span>
											</div>
											<div class="col col-fixed p-fluid" style="width: 10rem">
												<span class="p-float-label">
													<input type="text" pInputText [value]="cobro?.fecha | date : 'dd/MM/yyyy'" readonly />
													<label *ngIf="i == 0">{{ "FECHA" | translate }}</label>
												</span>
											</div>
											<div class="col p-fluid">
												<div class="grid">
													<div class="col">
														<span class="p-float-label">
															<input
																type="text"
																pInputText
																readonly
																class="text-right"
																[value]="
																	cobro?.total + cobro?.totalNotasACuenta
																		| currency : cobro?.moneda?.codigo || 'USD' : cobro?.moneda?.simbolo || '$'
																"
															/>
															<label *ngIf="i == 0">{{ "IMPORTE" | translate }}</label>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</ng-template>
							</p-dataView>
							<label>{{ "COBROS" | translate }}</label>
						</span>
					</div>
					<div class="col-12 totales text-right">
						<div class="grid">
							<div class="col-12 subtotal">
								<span>{{ "SUBTOTAL" | translate }}:</span>
								<span>{{ (discriminaIva ? item?.subtotalBruto : item?.subtotalNeto) | currencyCustom : item?.moneda }}</span>
							</div>
							<div class="col-12 subtotal" *ngIf="item?.descuentos != 0 && !esNota">
								<span>{{ "Ajustes" | translate }}:</span>
								<span>{{ item?.descuentos | currencyCustom : item?.moneda }}</span>
							</div>
							<div class="col-12 subtotal" *ngIf="item?.impuestos != 0 && discriminaIva">
								<span>{{ "IMPUESTOS" | translate }}:</span>
								<span>{{ item?.impuestos | currencyCustom : item?.moneda }}</span>
							</div>

							<div class="col-12 total">
								<span>{{ "TOTAL" | translate }}:</span>
								<span>{{ item?.total | currencyCustom : item?.moneda }}</span>
							</div>
							<div class="col-12 total" *ngIf="item?.id">
								<span>{{ "SALDO" | translate }}:</span>
								<span>{{ item?.saldo | currencyCustom : item?.moneda }}</span>
							</div>
						</div>
					</div>

					<div class="col-12" *ngIf="!readonly || (readonly && item.observaciones)">
						<span class="p-float-label">
							<p-editor
								[(ngModel)]="item.observaciones"
								name="observaciones"
								tabindex="10"
								class="p-inputwrapper-filled"
								*ngIf="!readonly"
								[style]="{ height: '100%', 'min-height': '20vh', overflow: 'auto', 'background-color': 'white' }"
							>
							</p-editor>
							<div *ngIf="readonly" style="width: 100%" [innerHtml]="item.observaciones" class="p-inputwrapper-filled p-4"></div>
							<label>{{ "OBSERVACIONES" | translate }}</label>
						</span>
					</div>

					<div class="col-12 lg:col-2 checkbox-container" *ngIf="esIntegrado">
						<p-toggleButton
							onLabel="{{ 'INTEGRATE' | translate }}"
							offLabel="{{ 'INTEGRATE' | translate }}"
							iconPos="left"
							name="integrar"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[(ngModel)]="integrar"
							[disabled]="readonly"
							[pTooltip]="'INTEGRATE_TOOLTIP' | translate"
						></p-toggleButton>
					</div>
				</div>
			</form>
			<div class="print-only">
				<comprobante-imprimir [item]="item" [cobranzas]="cobranzas"></comprobante-imprimir>
			</div>
		</div>
	</model-gestor>
</div>
<ng-template #itemComprobante let-itemRow let-r="index" let-editable="editable">
	<ng-container *ngTemplateOutlet="itemRow?.esSubcategoria ? templateSubcategoria : templateItem; context: { $implicit: itemRow, index: r }"></ng-container>
</ng-template>
<ng-template #templateSubcategoria let-itemRow let-r="index" let-editable="editable">
	<div class="grid grid-nogutter item-component small" *ngIf="item">
		<div class="col-12 lg:col-4 p-fluid">
			<small class="overflow pl-5" pTooltip="{{ itemRow.descripcion }}"> {{ itemRow.descripcion }}</small>
		</div>
		<div class="col-12 lg:col-fixed lg:w-5rem p-fluid">
			<small class="block text-center"> {{ itemRow.cantidad }}</small>
		</div>
		<div class="text-right co-fixed w-9rem sm:col-12 p-fluid">
			<small class="overflow text-right block"> {{ itemRow.precioUnitarioBruto | currencyCustom : itemRow?.moneda }}</small>
		</div>
		<div class="text-right co-fixed w-9rem sm:col-12 p-fluid">
			<small class="overflow text-right block"> {{ itemRow.impuestos | currencyCustom : itemRow?.moneda }}</small>
		</div>
		<div class="text-right co-fixed w-9rem sm:col-12 p-fluid">
			<small class="overflow text-right block"> {{ itemRow.precioUnitario | currencyCustom : itemRow?.moneda }}</small>
		</div>

		<div class="col">
			<div class="grid">
				<div class="col-fixed w-6rem p-inputgroup p-fluid">
					<small class="overflow text-center block"> {{ itemRow.tipoIVA?.descripcion }}</small>
				</div>
				<div class="col p-fluid text-right">
					<small class="overflow text-right block"> {{ itemRow.total | currencyCustom : itemRow?.moneda }}</small>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #templateItem let-itemRow let-r="index" let-editable="editable">
	<div class="grid grid-nogutter item-component" *ngIf="item">
		<div class="col-4 sm:col-4 p-fluid">
			<span [ngClass]="{ 'p-float-label': r == 0 }">
				<input type="text" readonly pInputText name="{{ 'producto' + r }}" [(ngModel)]="itemRow.descripcion" />
				<label *ngIf="r == 0">{{ "DESCRIPCION" | translate }}</label>
			</span>
		</div>
		<div class="col-fixed w-5rem sm:col-12 p-fluid">
			<span [ngClass]="{ 'p-float-label': r == 0 }" class="text-center">
				<input type="text" class="text-center" [readonly]="!editable" [(ngModel)]="itemRow.cantidad" pInputText name="{{ 'cantidad' + r }}" />
				<label *ngIf="r == 0">{{ "CANTIDAD" | translate }}</label>
			</span>
		</div>
		<div class="text-right col-fixed w-9rem sm:col-12 p-fluid">
			<span-dinero-editable
				[hideLabel]="r > 0"
				[editable]="editable && !readonly"
				[(ngModel)]="itemRow.precioUnitarioBruto"
				[readonly]="readonly"
				[moneda]="item.moneda"
				[label]="'PRECIO_BRUTO' | translate"
			></span-dinero-editable>
		</div>
		<div class="text-right co-fixed w-9rem sm:col-12 p-fluid">
			<span-dinero-editable
				[hideLabel]="r > 0"
				[editable]="editable && !readonly"
				[ngModel]="itemRow.impuestos"
				[readonly]="true"
				[moneda]="item.moneda"
				[label]="'IMPUESTOS' | translate"
			></span-dinero-editable>
		</div>
		<div class="text-right co-fixed w-9rem sm:col-12 p-fluid">
			<span-dinero-editable
				[hideLabel]="r > 0"
				[editable]="editable && !readonly"
				[ngModel]="itemRow.precioUnitario"
				[readonly]="true"
				[moneda]="item.moneda"
				[label]="'PRECIO_UNITARIO' | translate"
			></span-dinero-editable>
		</div>

		<div class="col">
			<div class="grid">
				<div class="col-fixed w-6rem text-center p-fluid">
					<span [ngClass]="{ 'p-float-label': r == 0 }" class="text-center d-flex align-items-center">
						<input
							*ngIf="!item.esSubcategorizado"
							type="text"
							class="text-center"
							[readonly]="true"
							[ngModel]="itemRow.tipoIVA?.descripcion"
							pInputText
							name="{{ 'iva' + r }}"
						/>
						<input
							*ngIf="item.esSubcategorizado"
							type="text"
							class="text-center"
							[readonly]="true"
							[ngModel]="'IMP_COMPUESTO' | translate"
							pInputText
							name="{{ 'iva' + r }}"
						/>

						<label *ngIf="r == 0">{{ "IVA" | translate }}</label>
					</span>
				</div>
				<div class="col-fixed w-6rem p-fluid" *ngIf="!esNota">
					<span [ngClass]="{ 'p-float-label': r == 0 }">
						<input type="text" class="text-center" readonly [value]="itemRow?.descuento + '%'" pInputText name="{{ 'descuento' + r }}" />
						<label *ngIf="r == 0">{{ "Ajuste" | translate }}</label>
					</span>
				</div>
				<div class="col p-fluid text-right">
					<span-dinero-editable
						[editable]="false"
						[ngModel]="itemRow.total"
						[readonly]="readonly"
						[moneda]="item.moneda"
						[hideLabel]="r > 0"
						[label]="'TOTAL' | translate"
					></span-dinero-editable>
				</div>
			</div>
		</div>

		<div class="col-12 lg:col-12 p-fluid" *ngIf="itemRow?.observaciones">
			<span class="p-float-label">
				<input type="text" [readonly]="!editable" pInputText name="{{ 'observaciones' + r }}" [(ngModel)]="itemRow.observaciones" />
				<label>{{ "OBSERVACIONES" | translate }}</label>
			</span>
		</div>
	</div>
</ng-template>

<ng-template #gestorItemCombrobante let-handler="handler" let-itemEditado="itemEditado">
	<gestor-item-comprobante
		[item]="itemEditado"
		(onGuardado)="handler.onGuardado($event)"
		(onCancelado)="handler.onCancelado($event)"
		[isModal]="true"
		[goBack]="false"
		[esNota]="esNota"
		[readonly]="readonly || !esEditable()"
		[moneda]="item.moneda"
		[incluyeImpuestos]="item.incluyeImpuestos"
	>
	</gestor-item-comprobante>
</ng-template>

<p-dialog
	[(visible)]="verBusquedaComprobante"
	appendTo="body"
	[modal]="true"
	[closable]="true"
	header="Buscar comprobante"
	#diag
	(onShow)="onDialogShow($event, diag)"
>
	<div class="grid" *ngIf="verBusquedaComprobante">
		<div class="col-12">
			<listado-comprobante
				[modoSeleccion]="'single'"
				(onSelect)="onComprobanteSelecciondo($event)"
				[omitir]="item"
				[readonly]="true"
				[skipTotales]="true"
			>
			</listado-comprobante>
		</div>
	</div>
</p-dialog>

<p-dialog
	[(visible)]="verDialogConvertir"
	appendTo="body"
	[modal]="true"
	[closable]="true"
	header="Convertir comprobante"
	#diag
	(onShow)="onDialogShow($event, diag)"
	(onClose)="cancelarConversion()"
>
	<div class="grid" *ngIf="editando">
		<div class="col">
			<parametrico-selector
				[allowDefault]="!item?.id"
				[listado]="comprobantesOficiales"
				#tipoComprobante="ngModel"
				[(ngModel)]="editando.tipoComprobante"
				[limpiable]="true"
				[permiteNuevo]="false"
				name="tipo-comprobante"
				[titulo]="'TIPOCOMPROBANTE' | translate"
			>
			</parametrico-selector>
		</div>
		<div class="col-6" *ngIf="esArgentina && editando.moneda?.codigo != configuracionEntorno?.moneda?.codigo">
			<span-dinero-editable
				[(ngModel)]="editando.cotizacionAfip"
				[editable]="true"
				[label]="'Cotización en Moneda local' | translate"
				name="name"
				[minValue]="0"
				[decimals]="3"
			>
			</span-dinero-editable>
		</div>
	</div>
	<p-footer>
		<button pButton (click)="cancelarConversion()" label="{{ 'CANCELAR' | translate }}"></button>
		<button pButton cclass="p-btn-success" (click)="ejecutarConversion(editando)" label="{{ 'Convertir' | translate }}"></button>
	</p-footer>
</p-dialog>
