import { Descriptivo } from 'src/app/common/model/Descriptivo';
export class TotalesActividad {

    constructor( 
        public producto?: Descriptivo,
        public productoId?: number,
        public cantidadPax: number = 0,
        public cantidadVehiculos: number = 0,
        public promedioPax: number = 0,
        public promedioVehiculos: number = 0,
    ) {

    }



}