import { GrupoPaxResumen } from './GrupoPaxResumen';
import { GrupoPax } from 'src/app/model/GrupoPax';
import { PuestoACubrir } from 'src/app/model/PuestoACubrir';
import { Proveedor } from './Proveedor';
import { TipoPuesto } from './TipoPuesto';
import { Descriptivo } from './../common/model/Descriptivo';
export class Delegacion {

    constructor(
        public grupo?: GrupoPax | GrupoPaxResumen,
        public puesto? : PuestoACubrir
    ){

    }

    public esCompleta(){
        return this.grupo && this.puesto?.tipoPuesto && this.puesto?.proveedor;
    }
}