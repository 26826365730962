import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { Subscription } from "rxjs"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Recordatorio } from "src/app/model/Recordatorio"
import { RecordatorioService } from "src/app/services/recordatorio.service"
import { SessionComponent } from "./../../../common/components/session-component.component"

import moment from "moment"
import { ModelGestorComponent } from "src/app/common/components/model-gestor/model-gestor.component"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { UsuarioService } from "src/app/common/services/usuario.service"
import { Destinatario } from "src/app/model/Destinatario"
import { TagUsuarioService } from "src/app/services/tag-usuario.service"
import { TipoRecordatorioService } from "src/app/services/tipo-recordatorio.service"
@Component({
	selector: "gestor-recordatorio",
	templateUrl: "gestor-recordatorio.component.html",
	styleUrls: ["gestor-recordatorio.component.less"]
})
export class GestorRecordatorioComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	@Input()
	public item: Recordatorio
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false

	@Input()
	public readonly: boolean = false

	@Output()
	public onGuardado: EventEmitter<Recordatorio> = new EventEmitter<Recordatorio>()

	@Output()
	public onCancelado = new EventEmitter()
	public usuarioOptions: Descriptivo[] = []
	public destinatarios: Destinatario[] = []
	public posiblesDestinatarios: Destinatario[] = []

	private _fechaParticular: Date
	public get fechaParticular(): Date {
		return this._fechaParticular
	}
	@Input()
	public set fechaParticular(v: Date) {
		this._fechaParticular = v
		this.item.fechaProgramada = v
	}

	@Input() embedded = false

	@ViewChild("gestor") gestor: ModelGestorComponent

	constructor(
		messagesService: MessagesService,
		public service: RecordatorioService,
		private route: ActivatedRoute,
		private router: Router,
		private confService: ConfirmationService,
		public tipoRecordatorioService: TipoRecordatorioService,
		public tagUsuarioService: TagUsuarioService,
		public usuarioService: UsuarioService
	) {
		super(messagesService)
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item)
		return item
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}

	public async isValid() {
		return this.item.destinatarios?.length > 0
	}

	public onEdit(event) {
		if (this.item?.id) this.router.navigate(["'recordatorio/edit"], { queryParams: { id: this.item.id } })
	}
	public get fechaProgramada() {
		return this.item?.fecha ? moment(this.item.fecha).format("DD/MM/YYYY HH:mm") : null
	}

	public get esFechaFija(): boolean {
		return this.item.esFechaFija
	}
	public set esFechaFija(v: boolean) {
		this.item.esFechaFija = v
		if (this.item.esFechaFija && !this.item.fechaProgramada) {
			this.item.fechaProgramada = moment().minutes(0).toDate()
		}
	}

	ngOnInit() {
		this.destinatarios = []
		const prom = [
			this.tagUsuarioService.getAll().then((r) => (this.destinatarios = [...this.destinatarios, ...r.map((u) => Destinatario.tag(u))])),
			this.usuarioService.getAll().then((r) => (this.destinatarios = [...this.destinatarios, ...r.map((u) => Destinatario.usuario(u))]))
		]
		Promise.all(prom).then((r) => (this.destinatarios = [...this.destinatarios, Destinatario.ALL]))

		if (this.route.snapshot.url.some((u) => u.path == "recordatorio")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				if (!this.service) return
				if (id) {
					this.service.getById(id, null, this.translateService.idioma ? this.translateService.idioma : "ES").then((r) => {
						this.item = r
					})
				} else {
					if (!this.item && this.service) {
						this.item = this.service.newEnt()
					}
					this.item["codigoIdioma"] = this.translateService.idioma ? this.translateService.idioma : "ES"
				}
			})
		}

		this.usuarioService.getDescriptivos().then((r) => {
			this.usuarioOptions = r
		})
	}

	public get esEditable() {
		return this.esAdministrador
	}

	getDestinatarios = ({ query }) => {
		this.posiblesDestinatarios = [
			...this.destinatarios.filter(
				(d) =>
					d.descripcion === Destinatario.TODOS ||
					d.descripcion.toLowerCase().includes(query.toLowerCase()) ||
					d.destinatario?.toLowerCase().includes(query.toLowerCase())
			)
		]
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}

	guardar = () => {
		if (this.embedded) {
			return Promise.resolve(this.handleGuardado(this.item))
		} else {
			return this.gestor.defaultGuardar(this.goBack)
		}
	}
}
