import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Filtro } from "../common/model/Filtro"

export class FiltroReporteEncuesta extends Filtro {
	constructor(
		name: string,
		page?: number,
		size?: number,
		sortField: string = "fecha",
		order: number = 1,
		guardable: boolean = true,
		localSearch: boolean = false
	) {
		super(
			name,
			{
				centro: "Reporte Encuesta"
			},
			page,
			size,
			sortField,
			order,
			guardable,
			localSearch
		)
	}

	private _fecha: Date
	public get fecha(): Date {
		return this._fecha
	}
	public set fecha(v: Date) {
		if (v != this._fecha) {
			this._fecha = v
			this.update()
		}
	}

	private _soloConAsignacion: boolean = true
	public get soloConAsignacion(): boolean {
		return this._soloConAsignacion
	}
	public set soloConAsignacion(v: boolean) {
		this._soloConAsignacion = v
		this.update(false, true)
	}

	private _fechaHasta: Date
	public get fechaHasta(): Date {
		return this._fechaHasta
	}
	public set fechaHasta(v: Date) {
		if (v != this._fechaHasta) {
			this._fechaHasta = v
			this.update()
		}
	}

	private _productos: Descriptivo[] = []
	public get productos(): Descriptivo[] {
		return this._productos
	}
	public set productos(v: Descriptivo[]) {
		this._productos = v
		this.update()
	}

	private _personal: Descriptivo
	public get personal(): Descriptivo {
		return this._personal
	}
	public set personal(v: Descriptivo) {
		if (v != this._personal) {
			this._personal = v
			this.update()
		}
	}

	private _vehiculo: Descriptivo
	public get vehiculo(): Descriptivo {
		return this._vehiculo
	}
	public set vehiculo(v: Descriptivo) {
		if (v != this._vehiculo) {
			this._vehiculo = v
			this.update()
		}
	}

	private _categoriaProducto: Descriptivo
	public get categoriaProducto(): Descriptivo {
		return this._categoriaProducto
	}
	public set categoriaProducto(v: Descriptivo) {
		if (v != this._categoriaProducto) {
			this._categoriaProducto = v
			this.update()
		}
	}

	private _tipoProducto: Descriptivo
	public get tipoProducto(): Descriptivo {
		return this._tipoProducto
	}
	public set tipoProducto(v: Descriptivo) {
		if (v != this._tipoProducto) {
			this._tipoProducto = v
			this.update()
		}
	}

	public clean() {
		super.clean(false)
		let cleanVal = {
			fecha: null,
			fechaHasta: null,
			personal: null,
			productos: [],
			tipoProducto: null,
			categoriaProducto: null,
			vehiculo: null
		}
		this.patchValue(cleanVal)
	}

	public patchValue(v: any) {
		super.patchValue(v, false)
		this._fecha = v.fecha
		this._fechaHasta = v.fechaHasta
		this._personal = v.personal
		this._productos = v.productos ? v.productos : []
		this._tipoProducto = v.tipoProducto
		this._categoriaProducto = v.categoriaProducto
		this._vehiculo = v.vehiculo
		this.update()
	}
	public static fromData(name: string, data: any): FiltroReporteEncuesta {
		if (!data) return new FiltroReporteEncuesta(name)
		let f = new FiltroReporteEncuesta(name)
		f.patchValue(data)
		return f
	}
}
