import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { CambioEstadoCaja } from 'src/app/model/CambioEstadoCaja';
@Injectable({
    providedIn: 'root',
})
export class CambioEstadoCajaService extends ServicioAbstract<CambioEstadoCaja>{
    public baseName(): string {
        return "cambio-estado-caja";
    }
    public parseToEnt(data: any): CambioEstadoCaja {
        return CambioEstadoCaja.fromData(data);
    }
    public newEnt(): CambioEstadoCaja {
        return new CambioEstadoCaja();
    }


}