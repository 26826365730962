import { AdministracionAuthGuard } from './AdministracionAuthGuard';
import { Injectable } from '@angular/core';
import { AdminAuthGuard } from './AdminAuthGuard';






@Injectable()
export class ReportesVentasAG extends AdministracionAuthGuard {


    public getRoles(): string[] { return super.getRoles().concat(["ROLE_REPORTES", "ROLE_REPORTE_VENTAS"]) };




}