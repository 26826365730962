import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';
export const slideInAnimation =
    trigger('triggerName', [
        transition('List => Item', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
                style({
                    opacity: 0,
                    position: "absolute",
                    top: "16px",
                    left: "16px",
                    right: "16px",
                    bottom: "16px"
                })
            ]),
            query(':enter', [
                style({ opacity: 0 })
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('300ms ease-in-out', style({ opacity: 0 }))
                ]),
                query(':enter', [
                    animate('300ms 0.1s ease-in-out', style({ opacity: 1 })),
                ])
            ]),
            query(':enter', animateChild()),
        ]),
        transition('* => List', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
                style({
                    opacity: 0,
                    position: "absolute",
                    top: "16px",
                    left: "16px",
                    right: "16px",
                    bottom: "16px"
                })
            ]),
            query(':enter', [
                style({ opacity: 0 })
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('300ms ease-in-out', style({ opacity: 0 }))
                ]),
                query(':enter', [
                    animate('300ms 0.1s ease-in-out', style({ opacity: 1 })),
                ])
            ]),
            query(':enter', animateChild()),
        ]),

    ]);