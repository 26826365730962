<div class="listado-hotel content">
	<model-list
		#list
		[service]="service"
		[modelName]="'hotel'"
		[columns]="columns"
		[title]="'HOTEL_TITLE' | translate"
		[(listado)]="listado"
		[isLazy]="false"
		[filtro]="filtro"
		[handler]="handler"
	>
	</model-list>
</div>
