<div class="gestor-cliente content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[modelName]="'CLIENTE' | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		(onCancelar)="handleCancelar($event)"
		[validar]="validar"
		[form]="gestorForm"
		[readonly]="readonly"
	>
		<div class="title" model-type="title">
			<div class="grid title-tabs">
				<div class="lg:col-8 col-12">
					<div class="grid">
						<div class="col-6 lg:col print:col-12">
							<h2 style="display: inline-block">{{ finalTitle | translate }}</h2>
						</div>
						<div class="col-6 lg:col-8 tex-center print-hidden">
							<button
								pButton
								icon="fa fa-pencil"
								(click)="editar()"
								*ngIf="readonly && permiteEdicion && item?.activo"
								style="margin-left: 0.5em"
							></button>
							<button
								pButton
								icon="fa fa-shopping-cart"
								(click)="hacerPedido()"
								*ngIf="item?.id && esVentas && item?.activo"
								tooltipPosition="bottom"
								pTooltip="{{ 'HACER_PEDIDO' | translate }}"
								style="margin-left: 0.5em"
							></button>
							<button
								pButton
								icon="fa fa-check"
								(click)="habilitar()"
								*ngIf="item?.id && esVentas && !item?.activo"
								tooltipPosition="bottom"
								label="{{ 'HABILITAR' | translate }}"
								pTooltip="{{ 'HABILITAR' | translate }}"
								style="margin-left: 0.5em"
							></button>
							<button
								pButton
								icon="fa fa-money"
								(click)="generarCobro()"
								*ngIf="item?.id && esVentas && item?.activo"
								tooltipPosition="bottom"
								pTooltip="{{ 'GENERAR_COBRO' | translate }}"
								style="margin-left: 0.5em"
							></button>
							<button
								pButton
								icon="fa fa-envelope"
								(click)="notificarDeuda()"
								label="{{ 'Notificar Deuda' | translate }}"
								tooltipPosition="bottom"
								pTooltip="{{ 'Enviar un mail con la deuda actual al cliente' | translate }}"
								style="margin-left: 0.5em"
								*ngIf="totales?.totalSaldo"
							></button>
							<button
								pButton
								icon="fa fa-chevron-left"
								(click)="volver()"
								label="{{ 'VOLVER' | translate }}"
								tooltipPosition="bottom"
								pTooltip="{{ 'VOLVER' | translate }}"
								style="margin-left: 0.5em"
							></button>
						</div>
					</div>
				</div>
				<div class="p-fluid col-6 lg:col-2 tab first print-hidden" (click)="goTo('data')" #datatab [ngClass]="{ active: pantallaActiva == 'data' }">
					<span>Datos</span>
				</div>
				<div
					class="p-fluid col-6 lg:col-2 tab print-hidden"
					[ngClass]="{ active: pantallaActiva == 'ctacte', 'd-none': !item?.id }"
					#ctactetab
					(click)="verCuentaCorriente()"
				>
					<div *ngIf="item?.id">
						<span>Cuenta Corriente</span>
					</div>
				</div>
			</div>
		</div>
		<div class="gestor-form" model-type="dataContent">
			<form #gestorForm="ngForm" id="gestorForm" autocomplete="off">
				<div class="grid" [hidden]="pantallaActiva != 'data'">
					<div class="p-fluid col-12 lg:col-2">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[readonly]="readonly"
							[tipoParametro]="'tipocliente'"
							#tipoCliente="ngModel"
							[(ngModel)]="item.tipoCliente"
							[permiteNuevo]="true"
							name="tipoCliente"
							[titulo]="'TIPOCLIENTE' | translate"
							(onSelect)="updateCuit()"
							required
						>
						</parametrico-selector>
					</div>
					<div class="p-fluid col-12 lg:col-2">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[readonly]="readonly"
							#nacionalidad="ngModel"
							[static]="true"
							[(ngModel)]="item.nacionalidad"
							[service]="nacionalidadService"
							[limpiable]="false"
							name="nacionalidad"
							[titulo]="'NACIONALIDAD' | translate"
							[permiteNuevo]="false"
							(onSelect)="updateNacionalidad()"
							[gestor]="gestorNacionalidad"
							required
						>
						</parametrico-selector>
					</div>
					<div class="p-fluid col-12 lg:col-2">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[allowDefault]="!item?.id"
							[service]="idiomaService"
							#idiomaPax="ngModel"
							[readonly]="readonly"
							[(ngModel)]="item.idioma"
							[limpiable]="true"
							[permiteNuevo]="false"
							name="idioma"
							[titulo]="'IDIOMA' | translate"
						>
						</parametrico-selector>
					</div>
					<div class="col-12 lg:col-6">
						<div class="grid">
							<div class="col p-fluid">
								<span class="p-float-label field">
									<input
										pInputText
										[(ngModel)]="item.nombre"
										#nombre="ngModel"
										name="nombre"
										tooltipPosition="bottom"
										type="text"
										[readonly]="readonly"
										required
									/>
									<label>
										{{ (item.tipoCliente?.codigo == "EMP" ? "Nombre de fantasía" : "NOMBRE_APELLIDO") | translate }}
									</label>
									<error-tag [model]="nombre"></error-tag>
								</span>
							</div>

							<div class="col p-fluid" *ngIf="item.tipoCliente?.codigo == 'EMP'">
								<span class="p-float-label field">
									<input
										pInputText
										placeholder="{{ 'RAZON_SOCIAL' | translate }}"
										#razonSocial="ngModel"
										name="razonSocial"
										[(ngModel)]="item.razonSocial"
										tooltipPosition="bottom"
										pTooltip="{{ 'RAZON_SOCIAL' | translate }}"
										type="text"
										[readonly]="readonly"
									/><label>{{ "RAZON_SOCIAL" | translate }} </label>

									<error-tag [model]="razonSocial"></error-tag>
								</span>
							</div>
						</div>
					</div>

					<div class="p-fluid col-12 lg:col-3">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[tipoParametro]="'tipoDocumento'"
							#tipoDocumento="ngModel"
							[(ngModel)]="item.tipoDocumento"
							[permiteNuevo]="false"
							name="tipoDocumento"
							[titulo]="'TIPODOCUMENTO' | translate"
							[required]="true"
							[readonly]="readonly || esExtranjero"
							[buscarTraduccion]="true"
						>
						</parametrico-selector>
					</div>
					<div class="p-fluid col-12 lg:col-3" *ngIf="item?.tipoDocumento?.codigo == '80'">
						<span class="p-float-label field">
							<input
								pInputText
								[readonly]="readonly"
								#numeroDocumento="ngModel"
								cuitValidator
								[(ngModel)]="item.numeroDocumento"
								type="text"
								[disabled]="esExtranjero"
								name="numeroDocumento"
							/>
							<label>{{ "Número de Documento" | translate }} </label>
							<small class="error" *ngIf="esExtranjero && item?.nacionalidad && !item?.numeroDocumento">
								Falta cuit para la nacionalidad elegida. Revíselo
								<span class="link" (click)="editarNacionalidad(item.nacionalidad); gestor = gestorNacionalidad">aquí</span>
							</small>
							<error-tag [model]="numeroDocumento"></error-tag>
						</span>
					</div>
					<div class="p-fluid col-12 lg:col-3" *ngIf="item?.tipoDocumento?.codigo != '80'">
						<span class="p-float-label field">
							<input
								pInputText
								[readonly]="readonly"
								#numeroDocumento="ngModel"
								[(ngModel)]="item.numeroDocumento"
								tooltipPosition="bottom"
								pTooltip="{{ 'NUMERODOCUMENTO_TOOLTIP' | translate }}"
								type="text"
								[disabled]="esExtranjero"
								name="numeroDocumento"
								[required]="item?.tipoDocumento?.codigo != '99'"
							/>
							<label>{{ "Número de Documento" | translate }} </label>
							<error-tag [model]="numeroDocumento"></error-tag>
						</span>
					</div>
					<div class="p-fluid col-12 lg:col-3" [paises-habilitados]="['AR']">
						<parametrico-selector
							[allowDefault]="!item?.id"
							[readonly]="readonly"
							[permiteNuevo]="false"
							[service]="tipoIVAService"
							[limpiable]="true"
							#iva="ngModel"
							[(ngModel)]="item.iva"
							name="iva"
							[titulo]="'IVA' | translate"
							[disabled]="esExtranjero"
							[required]="false"
						>
						</parametrico-selector>
					</div>
					<div class="col-12 lg:col-3 p-fluid field">
						<descriptivo-material-selector
							[service]="proveedorService"
							[(ngModel)]="item.proveedor"
							[ngModelOptions]="{ standalone: true }"
							[isLazy]="true"
							[label]="'PROVEEDOR_ASOCIADO' | translate"
							[limpiable]="true"
							[permiteNuevo]="false"
							[readonly]="readonly"
						>
						</descriptivo-material-selector>
					</div>
					<div class="p-fluid col-12 lg:col-3">
						<span class="p-float-label field p-input-icon-left">
							<i class="fa fa-envelope"></i>

							<input
								pInputText
								[readonly]="readonly"
								pTooltip="{{ 'EMAIL_CONTACTO_TP' | translate }}"
								#email="ngModel"
								appMultiEmailValidator
								name="email"
								[(ngModel)]="item.email"
								tooltipPosition="bottom"
								type="email"
							/><label>{{ "EMAIL_DE_CONTACTO" | translate }}</label>
							<error-tag [model]="email"></error-tag>
						</span>
					</div>
					<div class="p-fluid col-12 lg:col-3">
						<span class="p-float-label field p-input-icon-left">
							<i class="fa fa-envelope"></i>
							<input
								pInputText
								[readonly]="readonly"
								placeholder="{{ 'EMAIL_PH' | translate }}"
								#emailAdministrativo="ngModel"
								appMultiEmailValidator
								name="emailAdministrativo"
								[(ngModel)]="item.emailAdministrativo"
								tooltipPosition="bottom"
								pTooltip="{{ 'EMAIL_ADMINISTRATIVO_TOOLTIP' | translate }}"
								type="email"
							/>
							<label>{{ "EMAIL_ADMINISTRATIVO" | translate }}</label>
							<error-tag [model]="emailAdministrativo"></error-tag>
						</span>
					</div>
					<div class="p-fluid col-12 lg:col-3">
						<span class="p-float-label field p-input-icon-left">
							<i class="fa fa-envelope"></i>
							<input
								pInputText
								[readonly]="readonly"
								placeholder="{{ 'EMAIL_PH' | translate }}"
								#emailReserva="ngModel"
								appMultiEmailValidator
								name="emailReserva"
								[(ngModel)]="item.emailReserva"
								tooltipPosition="bottom"
								pTooltip="{{ 'EMAIL_RESERVA_TOOLTIP' | translate }}"
								type="email"
							/>
							<label>{{ "EMAIL_DE_RESERVA" | translate }}</label>
							<error-tag [model]="emailReserva"></error-tag>
						</span>
					</div>

					<div class="p-fluid col-12 lg:col-3">
						<span class="p-float-label field p-input-icon-left">
							<i class="fa fa-whatsapp"></i>

							<input
								pInputText
								[readonly]="readonly"
								#telefono="ngModel"
								name="telefono"
								[(ngModel)]="item.telefono"
								tooltipPosition="bottom"
								type="text"
							/><label>{{ "TELEFONO" | translate }}</label>
							<small>
								{{ getErrorMessage(telefono) }}
							</small>
						</span>
					</div>

					<div class="p-fluid col-12 lg:col-6">
						<span class="p-float-label field">
							<input
								pInputText
								[readonly]="readonly"
								placeholder="{{ 'FACEBOOK_PH' | translate }}"
								#facebook="ngModel"
								name="facebook"
								[(ngModel)]="item.facebook"
								tooltipPosition="bottom"
								type="text"
							/><label>{{ "FACEBOOK" | translate }}</label>
						</span>
					</div>
					<div class="p-fluid col-12 lg:col-6">
						<span class="p-float-label field">
							<input
								pInputText
								[readonly]="readonly"
								placeholder="{{ 'INSTAGRAM_PH' | translate }}"
								#instagram="ngModel"
								name="instagram"
								[(ngModel)]="item.instagram"
								tooltipPosition="bottom"
								type="text"
							/>
							<label>{{ "INSTAGRAM" | translate }}</label>
						</span>
					</div>

					<div class="p-fluid col-12 lg:col-12">
						<span class="p-float-label field">
							<input
								pInputText
								[readonly]="readonly"
								#domicilio="ngModel"
								name="domicilio"
								[(ngModel)]="item.domicilio"
								tooltipPosition="bottom"
								type="text"
							/>
							<label>{{ "DOMICILIO" | translate }}</label>
						</span>
					</div>
					<div class="p-fluid col-12 lg:col-4">
						<span class="p-float-label field">
							<textarea
								pInputTextArea
								[readonly]="readonly"
								#comentario="ngModel"
								name="comentario"
								[class.p-filled]="item?.comentario?.length > 0"
								[(ngModel)]="item.comentario"
								tooltipPosition="bottom"
								pTooltip="{{ 'COMENTARIO_TOOLTIP' | translate }}"
								rows="15"
								style="resize: none; width: 100%; padding: 0.75rem"
							></textarea>
							<label>{{ "COMENTARIO" | translate }}</label>
						</span>
					</div>
					<div class="col-12 lg:col-8">
						<div class="grid">
							<div class="col-12 lg:col-4 p-fluid">
								<span class="p-float-label field p-input-icon-right">
									<input
										pInputText
										[readonly]="readonly"
										#descuento="ngModel"
										name="descuento"
										[(ngModel)]="item.descuento"
										tooltipPosition="bottom"
										pTooltip="{{ 'DESCUENTO_TOOLTIP' | translate }}"
										type="number"
										[max]="100"
										[min]="0"
										[disabled]="!esAdministrador"
									/>
									<i class="fa fa-percent"></i>
									<label>{{ "DESCUENTO" | translate }}</label>
								</span>
							</div>
							<div class="p-fluid col-12 lg:col-8">
								<parametrico-selector
									[allowDefault]="!item?.id"
									[readonly]="readonly"
									[service]="formaPagoService"
									#formaPagoDefault="ngModel"
									[(ngModel)]="item.formaPagoDefault"
									[limpiable]="true"
									[permiteNuevo]="true"
									name="formaPago"
									[titulo]="'FORMAPAGO_PREFERIDA' | translate"
									[gestor]="gestorFormaPago"
								>
								</parametrico-selector>
							</div>
							<div class="p-fluid col-12 lg:col-12">
								<selector-multiple-chips
									[(ngModel)]="item.listasPrecios"
									name="listasPrecios"
									#listasPrecios="ngModel"
									[servicio]="listaPreciosService"
									[permiteNuevo]="false"
									[limpiable]="true"
									[readonly]="readonly"
									[titulo]="'LISTAS_PRECIOS' | translate"
								>
								</selector-multiple-chips>
							</div>
							<div class="col-12">
								<span class="fw-500">{{ "RECORDATORIOS" | translate }}</span>

								<abm-recordatorios [itemConRecordatorios]="item" [readonly]="readonly"></abm-recordatorios>
							</div>
						</div>
					</div>
				</div>
				<div class="grid" *ngIf="pantallaActiva == 'ctacte' && item?.id">
					<div class="p-fluid col-12 lg:col-8 print:col-12 nopad">
						<cuenta-corriente [filtro]="filtroCuenta" [servicio]="service"> </cuenta-corriente>
					</div>
					<div class="p-fluid col-12 lg:col-4 print:col-8 print:col-offset-4">
						<totales
							[cliente]="item"
							[proveedor]="item?.proveedor"
							[totalesCliente]="totales"
							[totalesProveedor]="totalesProveedor"
							tipoCuenta="cliente"
							[loadingService]="loadingTotales"
						></totales>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>

<ng-template #gestorNacionalidad let-handler="handler" let-itemEditado="itemEditado">
	<gestor-nacionalidad
		[item]="itemEditado"
		(onGuardado)="handler.onGuardado($event)"
		(onCancelado)="handler.onCancelado($event)"
		[isModal]="true"
		[goBack]="false"
	>
	</gestor-nacionalidad>
</ng-template>

<ng-template #gestorFormaPago let-handler="handler" let-itemEditado="itemEditado">
	<gestor-forma-pago
		[item]="itemEditado"
		(onGuardado)="handler.onGuardado($event)"
		(onCancelado)="handler.onCancelado($event)"
		[isModal]="true"
		[goBack]="false"
	>
	</gestor-forma-pago>
</ng-template>

<p-dialog
	header=""
	[(visible)]="editandoOpcion"
	appendTo="body"
	[modal]="true"
	#dialDetail
	[styleClass]="'no-header gestor-dialog'"
	(onShow)="onDialogShow($event, dialDetail)"
>
	<ng-container *ngTemplateOutlet="gestor; context: ctx"></ng-container>
</p-dialog>

<p-dialog [(visible)]="verEnvioDeuda" [modal]="true" [style]="{ width: '60vw' }" header="{{ 'Enviar Deuda' | translate }}">
	<form #MailForm="ngForm" id="MailForm">
		<selector-email [(ngModel)]="destinatario" name="d" #d="ngModel"></selector-email>
	</form>
	<p-footer>
		<button pButton (click)="verEnvioDeuda = false" label="{{ 'CANCELAR' | translate }}"></button>
		<button pButton cclass="p-btn-success" (click)="confirmarEnvioDeuda()" label="{{ 'ENVIAR' | translate }}" [disabled]="MailForm.invalid"></button>
	</p-footer>
</p-dialog>
<p-contextMenu [target]="datatab" [model]="menuItems" appendTo="body" [baseZIndex]="2000"> </p-contextMenu>
<p-contextMenu [target]="ctactetab" [model]="menuItems" appendTo="body" [baseZIndex]="2000"> </p-contextMenu>
