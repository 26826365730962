<model-list
	[service]="service"
	[modelName]="'ProcesoCargaMasiva'"
	[columns]="columns"
	[title]="'CARGA_MASIVA' | translate"
	[isLazy]="true"
	[handler]="handler"
	[filtro]="filtro"
	[editable]="false"
	[filterContent]="filterTemplate"
	[helpPath]="'configuracion/carga-masiva'"
>
	<ng-template #filterTemplate>
		<div class="grid small">
			<div class="col-6">
				<span class="p-float-label">
					<p-calendar [(ngModel)]="filtro.fechaDesde" dateFormat="dd/mm/yy" appendTo="body" name="fechaDesde" [showIcon]="true"></p-calendar>
					<label>{{ "FECHA_DESDE" | translate }}</label>
				</span>
			</div>
			<div class="col-6">
				<span class="p-float-label">
					<p-calendar [(ngModel)]="filtro.fechaHasta" dateFormat="dd/mm/yy" appendTo="body" name="fechaHasta" [showIcon]="true"></p-calendar>
					<label>{{ "FECHA_HASTA" | translate }}</label>
				</span>
			</div>
		</div>
	</ng-template>
</model-list>

<p-dialog
	*ngIf="mostrarNuevo"
	header="{{ 'NUEVA_CARGA_MASIVA' | translate }}"
	[(visible)]="mostrarNuevo"
	[modal]="true"
	closable="false"
	[style]="{ width: '30vw' }"
	(onClose)="mostrarNuevo = false"
>
	<div>
		<div class="grid">
			<div class="col-12">
				<descriptivo-material-selector
					[options]="tipos"
					[label]="'TIPO' | translate"
					[limpiable]="false"
					[(ngModel)]="tipoSeleccionado"
					[permiteNuevo]="false"
					[required]="true"
					[readonly]="readonly"
				>
				</descriptivo-material-selector>
			</div>
			<div class="col-12 checkbox-container">
				<p-checkbox [(ngModel)]="soloVerificacion" binary="true" label="{{ 'SOLO_VERIFICACION' | translate }}"></p-checkbox>
			</div>
			<div class="col-12" fileUpld (fileDropped)="handleFileInput($event, true)">
				<button
					pButton
					class="p-button-outlined p-button-icon-only ml-3 p-button-lg upload-button"
					tooltipPosition="bottom"
					icon="pi pi-upload"
					pTooltip="{{ 'CARGA_MASIVA' | translate }}"
					(click)="inputFile.click()"
				></button>
			</div>
		</div>
	</div>

	<input type="file" (change)="handleFileInput($event)" #inputFile name="inputfile" [hidden]="true" accept=".csv" />
</p-dialog>

<p-dialog header="Detalle" #detalleItem appendTo="body" [modal]="true" [(visible)]="verDetalle" [closable]="true" [style]="{ width: '90vw' }">
	<p-table [value]="filas" [paginator]="true" [rows]="10" styleClass="p-datatable-striped">
		<ng-template pTemplate="header">
			<tr>
				<th class="overflow oneline" *ngFor="let h of header">{{ h | translate }}</th>
			</tr>

			<!-- <tr>
            <th colspan="16"> {{'Error'}}</th>

        </tr> -->
		</ng-template>

		<ng-template pTemplate="body" let-it let-i="rowIndex">
			<tr>
				<td class="overflow oneline" *ngFor="let item of it.contenido" pTooltip="{{ item }}">{{ item }}</td>
			</tr>
			<tr>
				<td colspan="16" pTooltip="{{ item }}"><strong>Error - </strong> {{ it.error }}</td>
			</tr>
		</ng-template>
	</p-table>
</p-dialog>
