import { Descriptivo } from "src/app/common/model/Descriptivo"
import { round } from "../common/utils/MathUtils"
import { Auditable } from "./Auditable"
import { Insumo } from "./Insumo"
import { SubcategoriaImponible } from "./SubcategoriaImponible"
import { TipoIVAGrabado } from "./TipoIVAGrabado"

export class ItemFactura extends Auditable {
	public esSubcategoria: boolean = false
	public insumoKey: number
	public impuestosSubcategoria: number = 0
	constructor(
		public id?: number,
		public centroCosto?: Descriptivo,
		public insumo?: Insumo,
		public unidad?: Descriptivo,
		public cantidad?: number,
		public descripcion?: string,
		public tipoIVA?: TipoIVAGrabado,
		public importeUnitario?: number,
		public importe?: number,
		public idGastoAsociado?: number,
		public idComisionAsociada?: number,
		public importeModificado: boolean = false,
		public actualizarPrecio: boolean = false,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
		public version?: number,
		public proporcion?: number,
		public referenciaPadre?: string,
		public subcategoria?: SubcategoriaImponible,
		public codigoProductoExterno?: string,
		public codigoSubcategoriaExterna?: string
	) {
		super(lastModifiedBy, lastModifiedDate)
	}
	public static fromData(data: any): ItemFactura {
		if (!data) return null
		const o: ItemFactura = new ItemFactura(
			data.id,
			Descriptivo.fromData(data.centroCosto),
			Insumo.fromData(data.insumo),
			Descriptivo.fromData(data.unidad),
			data.cantidad,
			data.descripcion,
			TipoIVAGrabado.fromData(data.tipoIVA),
			Math.round((data.importe / data.cantidad) * 100) / 100,
			data.importe,
			data.idGastoAsociado,
			data.idComisionAsociada
		)
		o.proporcion = data.proporcion
		o.esSubcategoria = data.esSubcategoria
		o.referenciaPadre = data.refreciaPadre
		o.subcategoria = SubcategoriaImponible.fromData(data.subcategoria)
		o.codigoProductoExterno = data.codigoProductoExterno
		o.codigoSubcategoriaExterna = data.codigoSubcategoriaExterna
		return o
	}
	public get impuestos() {
		if (this.esCategorizado()) return this.impuestosSubcategoria
		return this.tipoIVA ? this.importe - this.importe / (1 + this.tipoIVA.valorIva / 100) : 0
	}
	public esCategorizado() {
		return this.insumo?.esSubcategorizado && !this.esSubcategoria
	}
	public isValid() {
		return this.insumo && this.cantidad && (this.tipoIVA || this.esCategorizado())
	}
	public actualizarPorProporcion(valorUnitario: number, cantidad: number = 1) {
		this.importeUnitario = round((valorUnitario * this.proporcion) / 100, 2)
		this.cantidad = cantidad
		this.importe = this.importeUnitario * this.cantidad
	}
}
