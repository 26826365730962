import * as moment from "moment"
import { IConRecordatorio } from "../pages/recordatorio/IConRecordatorio"
import { Descriptivo } from "./../common/model/Descriptivo"
import { IConImagen } from "./../common/model/IConImagen"
import { ProfilePic } from "./../common/model/ProfilePic"
import { BloqueoVehiculo } from "./BloqueoVehiculo"
import { Proveedor } from "./Proveedor"
import { Recordatorio } from "./Recordatorio"
import { RestriccionVehiculo } from "./RestriccionVehiculo"
import { VehiculoRequerido } from "./VehiculoRequerido"

export class Vehiculo extends Descriptivo implements IConImagen, IConRecordatorio {
	public get tipoEntidad(): string {
		return "VehiculoVO"
	}
	public estadoHoy: string
	constructor(
		id?: number,
		descripcion?: string,
		public nombre?: string,
		public patente?: string,
		public modelo?: string,
		public capacidad?: number,
		public fechaCompra?: Date,
		public fechaVencimientoHabilitacion?: Date,
		public observaciones?: string,
		public tipoVehiculo?: Descriptivo,
		public estadoVehiculo?: Descriptivo,
		public profilePic: ProfilePic = new ProfilePic(null, "assets/images/default_profile.png"),
		public es4x4: boolean = false,
		public bloqueos: BloqueoVehiculo[] = [],
		public peso?: number,
		public propio: boolean = false,
		public proveedor?: Proveedor,
		public recordatorios: Recordatorio[] = []
	) {
		super(id + "", descripcion || nombre || patente, id)
		if (this.esBloqueado(new Date())) {
			this.estadoHoy = "Bloqueado"
			this["rowClass"] = "bloqueado"
		} else {
			this.estadoHoy = "Sin problemas"
			this["rowClass"] = ""
		}

		this.searchStr = [this.id, this.descripcion, this.tipoVehiculo?.descripcion || "", this.patente || "", this.modelo || ""].join("_")
	}
	public static fromData(data: any): Vehiculo {
		if (!data) return null
		let o: Vehiculo = new Vehiculo(
			data.id,
			data.descripcion || null,
			data.nombre,
			data.patente,
			data.modelo,
			data.capacidad,
			data.fechaCompra ? new Date(data.fechaCompra) : null,
			data.fechaVencimientoHabilitacion ? new Date(data.fechaVencimientoHabilitacion) : null,
			data.observaciones,
			Descriptivo.fromData(data.tipoVehiculo),
			Descriptivo.fromData(data.estadoVehiculo),
			ProfilePic.fromData(data.profilePic),
			data.es4x4,
			data.bloqueos ? data.bloqueos.map((b) => BloqueoVehiculo.fromData(b)) : [],
			data.peso ? data.peso : data.id,
			data.propio
		)

		o.version = data.version
		o.recordatorios = data.recordatorios ? data.recordatorios.map((r) => Recordatorio.fromData(r)) : []
		return o
	}

	public get imagenUrl(): string {
		return this.profilePic?.picPathVersion || "assets/images/default_vehiculo.png"
	}
	public esBloqueado(fecha: Date) {
		return this.bloqueos.some((b) => b.aplica(fecha, null))
	}
	public getMotivoBloqueo(fecha: Date) {
		return this.getBloqueo(fecha)?.motivo || ""
	}
	public getBloqueo(fecha: Date) {
		const b = this.bloqueos.filter((b) => b.aplica(fecha))[0]
		if (b) b.vehiculo = Descriptivo.fromData(this)
		return b
	}
	public get imagen() {
		return this.profilePic
	}
	public cumple(req: VehiculoRequerido | RestriccionVehiculo): boolean {
		return (
			req.capacidadMinima <= this.capacidad &&
			(!req.es4x4 || this.es4x4 == req.es4x4) &&
			(!req.tipoVehiculo || this.tipoVehiculo?.id == req.tipoVehiculo?.id)
		)
	}

	public agregarBloqueo(item: BloqueoVehiculo) {
		if (!this.bloqueos.some((ec) => ec == item)) {
			if (!item.fechaHasta) {
				item.fechaHasta = moment(item.fechaDesde).endOf("date").toDate()
			}
			this.bloqueos = [...this.bloqueos, item]
		}
	}
	public quitarBloqueo(item: BloqueoVehiculo) {
		this.bloqueos = this.bloqueos.filter((i) => i != item)
	}
	newBloqueo = () => {
		const b = new BloqueoVehiculo()
		b.vehiculo = Descriptivo.fromData(this)
		return b
	}
}
