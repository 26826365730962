import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { Ciudad } from 'src/app/model/Ciudad';
@Injectable({
    providedIn: 'root',
})
export class CiudadService extends ServicioAbstract<Ciudad>{
    public baseName(): string {
        return "ciudad";
    }
    public parseToEnt(data: any): Ciudad {
        return Ciudad.fromData(data);
    }
    public newEnt(): Ciudad {
        return new Ciudad();
    }


}