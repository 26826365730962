import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { Subscription } from "rxjs"
import { SessionComponent } from "src/app/common/components/session-component.component"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { CajaService } from "src/app/services/caja.service"
import { ClienteService } from "src/app/services/cliente.service"
import { TagUsuarioService } from "src/app/services/tag-usuario.service"
import { UsuarioAG } from "./../../../authguards/UsuarioAG"
import { ProfilePic } from "./../../../common/model/ProfilePic"
import { Rol } from "./../../../common/model/Rol"
import { Usuario } from "./../../../common/model/Usuario"
import { RolService } from "./../../../common/services/rol.service"
import { UsuarioService } from "./../../../common/services/usuario.service"
import { ProveedorService } from "./../../../services/proveedor.service"

@Component({
	selector: "gestion-user",
	templateUrl: "gestion-user.component.html",
	styleUrls: ["gestion-user.component.less"]
})
export class GestionUserComponent extends SessionComponent implements OnInit {
	mostrarProveedor: boolean = false
	mostrarCliente: boolean = false
	public rolesDependientes: Rol[] = []
	public rolesBase: Rol[] = []
	constructor(
		public userService: UsuarioService,
		private route: ActivatedRoute,
		private router: Router,
		private confService: ConfirmationService,
		public rolesService: RolService,
		private proveedorService: ProveedorService,
		public clienteService: ClienteService,
		public tagUsuarioService: TagUsuarioService,
		public cajaService: CajaService,
		public usuarioAG: UsuarioAG,
		messagesService: MessagesService
	) {
		super(messagesService)
	}

	private _item: Usuario
	private routeSub: Subscription
	public file: File
	@Input()
	public readonly: boolean = false
	public roles: Rol[] = []

	public onPicSelected(file: File) {
		const reader = new FileReader()
		let $this = this
		if (file.size > 1000000) {
			return this.error(this.translateService.get("EL_ARCHIVO_NO_PUEDE_16"))
		}
		this.file = file
		reader.onload = (e: any) => {
			$this.item.profilePic = new ProfilePic(null, e.target.result)
		}
		reader.readAsDataURL(file)
	}

	public onGuardado(item: Usuario) {
		if (!this.item.esAdmin() && !this.usuario.esJefeAdmin()) {
			this.cajaService.getAutorizadosUsuario(this.item).then((c) => {
				item.cajas = c
			})
		}
		this.authService.refreshToken()
	}
	public get item(): Usuario {
		return this._item
	}

	public set item(i: Usuario) {
		this._item = i
	}

	public recuperarContrasena() {
		let $this = this
		this.confService.confirm({
			key: "genConf",
			header: this.translateService.get("RECUPERAR_CONTRASEÑA"),
			message: this.translateService.get("SE_ENVIARA_UN_EMAI_59"),
			accept: () => {
				$this.authService
					.recuperarPass(this.item.email)
					.then((r) => {
						$this.success(this.translateService.get("EL_EMAIL_A_SIDO_ENV_3"))
					})
					.catch(this.errorHandler)
			}
		})
	}

	public isValid() {
		if (this.item.roles?.some((r) => r.codigo == "ROLE_PROVEEDOR") && !this.item?.proveedor) {
			return this.error(this.translateService.get("DEBE_SELECCIONAR_UN_PROVEEDOR"))
		} else if (this.item.roles?.some((r) => r.codigo == "ROLE_CLIENTE") && !this.item?.cliente) {
			return this.error(this.translateService.get("DEBE_SELECCIONAR_UN_CLIENTE"))
		} else {
			return true
		}
	}
	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}
	ngOnInit() {
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
				}
			})
		)

		this.routeSub = this.route.queryParams.subscribe((params) => {
			let id: number = <number>params["id"]
			if (!this.userService) return
			this.rolesService.getActivos().then((r) => {
				this.roles = r.filter((r) => !r.dependeDe && r.codigo !== "ROLE_SOPORTE")
				this.rolesBase = r.filter((r) => r.codigo !== "ROLE_SOPORTE")
			})
			if (id) {
				this.userService.getById(id).then((r) => {
					this.item = this.userService.parseSingle(r)

					this.rolChange()
					if (!this.item.esAdmin() && !this.usuario.esJefeAdmin()) {
						this.cajaService.getAutorizadosUsuario(this.item).then((c) => {
							this.item.cajas = c
						})
					}
				})
			} else {
				this.item = this.userService ? this.userService.newEnt() : null
			}
		})
	}
	public onEditar(event) {
		this.router.navigate(["usuario/edit"], { queryParams: { id: this.item.id } })
	}

	rolChange() {
		if (this.item.tieneRol(["ROLE_PROVEEDOR"])) {
			this.mostrarProveedor = this.usuarioAG.esVisible(this.usuario)
		} else {
			this.mostrarProveedor = false
		}
		if (this.item.tieneRol(["ROLE_CLIENTE"])) {
			this.mostrarCliente = this.usuarioAG.esVisible(this.usuario)
		} else {
			this.mostrarCliente = false
		}
		this.rolesDependientes = this.rolesBase.filter((r) => r.dependeDe && this.item.roles.some((r2) => r2.codigo === r.dependeDe))
	}
}
