import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { BloqueoVehiculo } from 'src/app/model/BloqueoVehiculo';
import { Filtro } from '../common/model/Filtro';
import { Evento } from '../model/Evento';
import { FiltroFecha } from './../common/model/FiltroFecha';
import { IServicioConEventos } from './IServicioConEventos';
@Injectable({
    providedIn: 'root',
})
export class BloqueoVehiculoService extends ServicioAbstract<BloqueoVehiculo> implements IServicioConEventos{
    getByFecha(filtro: FiltroFecha) {
        return this.http.post(this.getBaseURL("byFechas"), filtro.json).toPromise().then((r: any) => {
            return r.map(b => BloqueoVehiculo.fromData(b));
        });
    }
    verficarFechas(b: BloqueoVehiculo): Promise<Boolean> {
        return this.http.post(this.getBaseURL("verificar"), b).toPromise().then((r: boolean) => {
            return r;
        });
    }

    public baseName(): string {
        return "bloqueo-vehiculo";
    }
    public parseToEnt(data: any): BloqueoVehiculo {
        return BloqueoVehiculo.fromData(data);
    }
    public newEnt(): BloqueoVehiculo {
        return new BloqueoVehiculo();
    }

    public getEventos(filtro: FiltroFecha): Promise<Evento[]>  {
        return this.http.post(this.getBaseURL('getEventos'), filtro.json).pipe(share())
        .toPromise()
        .then((r: any[]) => {
            return r.map( event => Evento.fromData(event))
        }, (e) => this.handleError(e));
    }

}