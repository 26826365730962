import  listPlugin from '@fullcalendar/list';
import  interactionPlugin  from '@fullcalendar/interaction';
import { Component, OnInit } from '@angular/core';
import { DashboardConfiguration } from '../../dashboard-element/DashboardConfiguration';
import { IDashboardElement } from '../../dashboard-element/IDashboardElement';

@Component({
  selector: 'calendario-home',
  templateUrl: './calendario-home.component.html',
  styleUrls: ['./../dashboard-element-common.component.less','./calendario-home.component.less']
})
export class CalendarioHomeComponent implements OnInit, IDashboardElement {
  public static defaultConfig: DashboardConfiguration = {
    fixedHeader: true,
    x: null,
    y: null,
    rows: 7,
    cols: 4,
    tipo: "calendario-home",
    params: {}
  }
  public static TIPO: any = 'calendario-home';
  constructor() { }
  config: DashboardConfiguration;
  showParams(event: any) {
  }
  hideParams() {
  }
  onChange = (conf: DashboardConfiguration) => {

  }
  getTitle() {
    return "Calendario"
  }

  ngOnInit() {
  }

}
