import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { FormControl } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import moment from "moment"
import * as objectHash from 'object-hash'
import { Subscription } from "rxjs"
import { ModelGestorComponent } from "src/app/common/components/model-gestor/model-gestor.component"
import { ProfileBadgeComponent } from "src/app/common/components/profile-badge/profile-badge.component"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { FileService } from "src/app/common/services/file.service"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { DisponibilidadDiaria } from "src/app/model/DisponibilidadDiaria"
import { ImagenProducto } from "src/app/model/ImagenProducto"
import { ListaPrecios } from "src/app/model/ListaPrecios"
import { OpcionPublicacion } from "src/app/model/OpcionPublicacion"
import { ProductoPublicado } from "src/app/model/ProductoPublicado"
import { ProductoPublicadoIntegracion } from "src/app/model/ProductoPublicadoIntegracion"
import { ProductoVariante } from "src/app/model/ProductoVariante"
import { Provider } from "src/app/model/Provider"
import { InclusionService } from "src/app/services/inclusion.service"
import { ListaPreciosService } from "src/app/services/lista-precios.service"
import { MonedaService } from "src/app/services/moneda.service"
import { ProductoPublicadoService } from "src/app/services/producto-publicado.service"
import { ProductoService } from "src/app/services/producto.service"
import { ProviderService } from "src/app/services/provider.service"
import { RegionService } from "src/app/services/region.service"
import { TagProductoService } from "src/app/services/tag-producto.service"
import { TipoVarianteService } from "src/app/services/tipoVariante.service"
import { SessionComponent } from "./../../../common/components/session-component.component"

@Component({
	selector: "gestor-producto-publicado",
	templateUrl: "gestor-producto-publicado.component.html",
	styleUrls: ["gestor-producto-publicado.component.less"]
})
export class GestorProductoPublicadoComponent extends SessionComponent implements OnDestroy, OnInit {
	private routeSub: Subscription
	@Input()
	public item: ProductoPublicado
	@Input()
	public goBack: boolean = true
	@Input()
	public isModal: boolean = false

	@Input()
	public readonly: boolean = false

	@Output()
	public onGuardado: EventEmitter<ProductoPublicado> = new EventEmitter<ProductoPublicado>()

	@Output()
	public onCancelado = new EventEmitter()

	private _imagenes: ImagenProducto[] = []
	public filesInput: FormControl = new FormControl()
	@ViewChild("fileInput", { static: false })
	fileInput: ProfileBadgeComponent
	public maxVariantes
	public OchoAm: Date = moment().startOf("date").add(8, "hours").toDate()
	public providers: Descriptivo[]
	public monedas: Descriptivo[]
	public listas: ListaPrecios[]
	public detalleIntegracion: ProductoPublicadoIntegracion
	public isVerDetalleIntegracion: boolean
	public isVerProductoFaltanteEnLista: boolean
	public detalleFaltanteLista: ListaPrecios
	hashInicial: string;
	hashesIntegracion = new Map<number, string>();
	@ViewChild(ModelGestorComponent) modelGestor!: ModelGestorComponent;
	activeIndexes = []

	constructor(
		messagesService: MessagesService,
		public service: ProductoPublicadoService,
		public productoService: ProductoService,
		public tipoVarianteService: TipoVarianteService,
		public inclusionService: InclusionService,
		public fileService: FileService,
		public tagService: TagProductoService,
		public regionService: RegionService,
		private route: ActivatedRoute,
		private router: Router,
		private providerService: ProviderService,
		private monedaService: MonedaService,
		private listaService: ListaPreciosService
	) {
		super(messagesService)
	}

	public handleGuardado(item) {
		this.onGuardado.emit(item)
	}
	public handleCancelar(item) {
		this.onCancelado.emit()
	}

	public async isValid() {
		return true
	}

	public onEdit(event) {
		if (this.item?.id) this.router.navigate(["'producto-publicado/edit"], { queryParams: { id: this.item.id } })
	}

	ngOnInit() {
		this.subs.push(
			this.route.data.subscribe((u) => {
				if (u?.vista) {
					this.readonly = true
				}
			})
		)
		if (this.route.snapshot.url.some((u) => u.path == "producto-publicado")) {
			this.routeSub = this.route.queryParams.subscribe((params) => {
				let id: number = <number>params["id"]
				let productoId: number = <number>params["productoId"]
				if (!this.service) return
				if (id) {
					this.service.getById(id, null, this.translateService.idioma ? this.translateService.idioma : "ES").then((r) => {
						this.item = r
						this.updateImagenes()
						this.hashInicial = objectHash({ ...this.item, integraciones: undefined });
						this.item.integraciones.forEach(integracion =>
							this.hashesIntegracion.set(integracion.id, objectHash(integracion))
						);
					})
				} else if (productoId) {
					this.service.autogenerarByProductoId(productoId, this.translateService.idioma ? this.translateService.idioma : "ES").then((r) => {
						this.item = r
						this.item["codigoIdioma"] = this.translateService.idioma ? this.translateService.idioma : "ES"
						this.updateImagenes()
					})
				} else {
					if (!this.item && this.service) {
						this.item = this.service.newEnt()
					}
					this.item["codigoIdioma"] = this.translateService.idioma ? this.translateService.idioma : "ES"
				}
			})
		}
		this.tipoVarianteService.getAll().then((tv) => {
			this.maxVariantes = tv.length
		})
		this.providerService.getAllPortales().then((data) => (this.providers = data))
		this.listaService.getPublicadas().then((data) => (
			this.listas = data
		))
		this.monedaService.getAll().then((data) => (this.monedas = data))
	}

	public get esEditable() {
		return this.esAdministrador
	}

	ngOnDestroy() {
		if (this.routeSub) this.routeSub.unsubscribe()
	}

	public uploadEnd(data: File) {
		this.fileService.postFile(data, "producto-publicado").then((f) => {
			this.item.agregarImagen(f)
			this.updateImagenes()
			this.filesInput.setValue(this.imagenes)
			this.filesInput.markAsDirty({})
		})
	}

	public setPortada(imagen: ImagenProducto) {
		this.item.imagenes.forEach((i) => {
			i.esPortada = i.profilePic.picPath == imagen.profilePic.picPath
		})
		this.updateImagenes()
	}

	private updateImagenes() {
		this.imagenes = this.item.imagenes
	}

	public eliminarImagen(event, imagen: ImagenProducto) {
		this.item.quitarImagen(imagen)
		this.updateImagenes()
		this.filesInput.markAsDirty({})
		event.stopPropagation()
	}

	public get imagenes(): any[] {
		let _this = this
		let add = {
			clic: () => {
				_this.fileInput.openInput()
			}
		}
		return this.readonly ? this._imagenes : [].concat(this._imagenes, [add])
	}

	public set imagenes(v: ImagenProducto[]) {
		this._imagenes = v
	}

	public agregarOpcion() {
		let newOp = new OpcionPublicacion()
		newOp.descripcion = this.translateService.get("NUEVA_OPCION")
		newOp.selected = true
		newOp.disponibilidad = null
		newOp.heredaDisponibilidad = true;
		this.item.integraciones.forEach(i => {
			i.disponibilidades[newOp.descripcion] = null
			i.heredaDisponibilidad[newOp.descripcion] = true
		})
		this.item.opciones.push(newOp)
	}

	public quitarOpcion(op: OpcionPublicacion) {
		this.item.opciones = this.item.opciones.filter((o) => o != op)
	}

	public agregarVariante(op: OpcionPublicacion) {
		op.variantes.push(new ProductoVariante())
	}

	public quitarVariante(op: OpcionPublicacion, vr: ProductoVariante) {
		op.variantes = op.variantes.filter((v) => v != vr)
	}

	public heradarInclusiones(op: OpcionPublicacion) {
		op.incluidos = op.heredaInclusiones ? [] : this.item.incluidos
		op.noIncluidos = op.heredaInclusiones ? [] : this.item.noIncluidos
	}

	public heredarDisponibilidad(op: OpcionPublicacion) {
		op.disponibilidad = op.heredaDisponibilidad ? null : DisponibilidadDiaria.fromData({ ...this.item.disponibilidad })
	}

	public heredarDisponibilidadIntegracion(i: ProductoPublicadoIntegracion, op: OpcionPublicacion) {
		i.disponibilidades[op.descripcion] = i.heredaDisponibilidad[op.descripcion] ?
			null :
			(op.heredaDisponibilidad ?
				DisponibilidadDiaria.fromData({ ...this.item.disponibilidad }) :
				DisponibilidadDiaria.fromData({ ...op.disponibilidad }))
	}

	public agregarIntegracion() {
		let integracion = new ProductoPublicadoIntegracion(null, this.item.id, null, false, false, [])
		this.item.opciones.forEach(op => {
			integracion.disponibilidades[op.descripcion] = null
			integracion.heredaDisponibilidad[op.descripcion] = true
		})
		return this.item.integraciones.push(integracion)
	}

	public providersDisponibles() {
		return this.providers.filter(p => !this.item.integraciones.some(i => i.provider && i.provider.codigo == p.codigo))
	}

	public integrar(integracion: ProductoPublicadoIntegracion) {
		this.service.integrar(integracion).then((r: ProductoPublicadoIntegracion) => this.hashesIntegracion.set(r.id, objectHash(r)))
	}

	public huboCambiosPP() {
		return objectHash({ ...this.item, integraciones: undefined }) != this.hashInicial;
	}

	public huboCambiosInt(integracion: ProductoPublicadoIntegracion) {
		return objectHash(integracion) != this.hashesIntegracion.get(integracion.id);
	}

	public estaActualizado(integracion: ProductoPublicadoIntegracion) {
		return integracion.idiomas.length > 0 &&
			integracion.idiomas.every(idi => idi.actualizado) &&
			!this.huboCambiosPP() &&
			!this.huboCambiosInt(integracion)
	}

	public estadoIntegracion(integracion: ProductoPublicadoIntegracion) {
		return this.translateService.get(
			integracion.idiomas.length == 0 ? 'SIN_INTEGRAR' :
				integracion.idiomas.every(idi => idi.integrado) ? 'PUBLICADO' :
					integracion.idiomas.some(idi => idi.integrado) ? 'PUBLICADO_PARCIAL' : 'PENDIENTE'
		)
	}

	public puedeIntegrar(integracion: ProductoPublicadoIntegracion) {
		return !this.huboCambiosPP() && !this.estaActualizado(integracion) && integracion.provider
	}

	public verDetalleIntegracion(integracion: ProductoPublicadoIntegracion) {
		this.detalleIntegracion = integracion
		this.isVerDetalleIntegracion = true
	}

	public eliminarIntegracion(integracion: ProductoPublicadoIntegracion) {
		this.item.integraciones = this.item.integraciones.filter(i => i != integracion)
	}

	public listaMoneda(moneda: Descriptivo, provider: Provider) {
		return this.listas.find(l => l.moneda.id == moneda.id && l.integraciones.some(i => provider && i.id == provider.id))
	}

	public productosFaltantesEnLista(lista: ListaPrecios) {
		return this.item.opciones
			.flatMap(op => op.variantes.filter(vr =>
				!lista.productos.some(p => p.producto.id == vr.producto.id)
			).map(vr => vr.producto));
	}

	public verProductoFaltanteEnLista(lista: ListaPrecios) {
		this.detalleFaltanteLista = lista
		this.isVerProductoFaltanteEnLista = true
	}

	guardarPublicar = () => {
		this.item.publicar = true;
		this.modelGestor.guardar().finally(() => this.item.publicar = false)
	}

}
