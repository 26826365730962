import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { LoadingService } from "src/app/common/services/loading-data-service.service"
import { ListaPrecios } from "src/app/model/ListaPrecios"
import { BufferedDescriptivoService } from "src/app/services/BufferedDescriptivoService"
import { Descriptivo } from "../common/model/Descriptivo"
import { MessagesService } from "../common/services/messages-data-service.service"
import { ListaPreciosIntegracion } from "../model/ListaPreciosIntegracion"
import { ListaProducto } from "../model/ListaProducto"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class ListaPreciosService extends BufferedDescriptivoService<ListaPrecios> {
	constructor(protected novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	getCabeceras(f: any, loadingService: LoadingService = new LoadingService()) {
		return this.getData(f, loadingService).then((l) => l.map((lp) => ListaPrecios.cabecera(lp)))
	}

	public baseName(): string {
		return "lista-precios"
	}
	public parseToEnt(data: any): ListaPrecios {
		return ListaPrecios.fromData(data)
	}
	public newEnt(): ListaPrecios {
		return new ListaPrecios()
	}

	public getProductos(): Promise<ListaProducto[]> {
		return lastValueFrom(this.http.get(this.getBaseURL() + "getProductos")).then((res: any) => {
			return res.map((p) => ListaProducto.fromData(p))
		})
	}

	public deshabilitar(id: number, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.put(this.getBaseURL() + "deshabilitar", id))
			.then(async (r) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				const d = await this.getById(id)
				d.habilitado = false
				return d
			})
			.catch((e) => this.handleError(e, customLoading))
	}
	habilitar(id, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()

		return lastValueFrom(this.http.put(this.getBaseURL() + "habilitar", id))
			.then((r) => {
				return this.parseSingle(r)
			})
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}
	public getHabilitados(f, loadingService: LoadingService = new LoadingService()) {
		return this.getAll(f, loadingService)
	}

	guardarEdicionMasiva(
		idLista: number,
		productos: Descriptivo[],
		desdeMasivo: Date,
		hastaMasivo: Date,
		montoMasivo: number,
		edicion: boolean,
		suma: boolean,
		porcentaje: boolean
	) {
		return this.http
			.post(this.getBaseURL() + "guardarEdicionMasiva", {
				idLista: idLista,
				productos: productos,
				desde: desdeMasivo,
				hasta: hastaMasivo,
				monto: montoMasivo,
				edicion: edicion,
				suma: suma,
				porcentaje: porcentaje
			})
			.toPromise()
			.then((r) => this.parseSingle(r), this.handleError)
	}

	copiar(item: ListaPrecios, nombre: string) {
		return this.getByIdForced(item.id).then((r) => {
			const newLista = ListaPrecios.fromData(r)
			newLista.id = null
			newLista.codigo = null
			newLista.descripcion = nombre
			newLista.productos.forEach((p) => (p.id = null))
			return this.guardar(newLista).then((i) => this.parseSingle(i))
		})
	}

	getByCliente(id: number, customLoading?) {
		let $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL("byCliente/" + id)), { defaultValue: [] })
			.then((r) => $this.parse(r))
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}

	public verIntegraciones(id: number, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL("integraciones/" + id)), { defaultValue: [] })
			.then((data: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()

				return data.map((d) => ListaPreciosIntegracion.fromData(d))
			})
			.catch((e) => this.handleError(e, customLoading))
			.finally(() => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
			})
	}

	public integrar(id: number, providerCodigo: string, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		var p = this.http
			.post(this.getBaseURL("integrar"), {
				id: id,
				providerCodigo: providerCodigo
			})
			.toPromise()
		p.then((r) => {
			if (customLoading) {
				customLoading.susLoadingCount()
			} else this.loadingService.susLoadingCount()
		})
		return p
	}

	public getPublicadas(customLoading?: LoadingService) {
		if (customLoading) customLoading.addLoadingCount()
		else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.get(this.getBaseURL("publicadas")))
			.then((r: any) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				return r.map(l => ListaPrecios.fromData(l))
			})
			.catch((e) => this.handleError(e, customLoading))
	}
}
