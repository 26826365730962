<div class="card" *ngIf="nota" (click)="verNota(nota)" [ngStyle]="{'background-color': nota.color? nota.color : '#fff'}">
    <div class="grid nota">
        <div class="col-12 fecha">{{nota.esPermanente ? "Permanente" : mismaFecha() ? "Fecha: " + ( nota?.fechaNota | date:"dd/MM/yyyy" ) : "Desde: " + ( nota?.fechaNota | date:"dd/MM/yyyy" ) + " Hasta: " + ( nota?.fechaHasta | date:"dd/MM/yyyy") }}</div>
        <div class="col-12 contenido" [innerHtml]="nota.cabecera"></div>
    </div>
    <span class="responsable">{{nota?.responsable?.descripcion}} -
      {{nota?.ultimaModificacion | date :'dd/MM/yyyy'}}</span>
</div>
<div *ngIf="!nota" (click)="agregarNota()">
    <div class="card plus">
        <i class="fa fa-plus fa-2" tooltipPosition="bottom" pTooltip="{{'AGREGAR_NOTA' | translate}}"></i>
    </div>
</div>
<p-dialog [(visible)]="mostrarNota" [modal]="true" #dialNotaFecha header="{{'Notas del día' | translate}}" [styleClass]="'gestor-dialog'" (onShow)="onDialogShow($event,dialNotaFecha)" (onHide)="mostrarNota = false; notaEditada = null" appendTo=body [style]="{width: '60vw'}">
    <gestor-nota [isModal]="true" [goBack]="false" [fecha]="filtro.fecha" [hideTitle]="false" [item]="notaEditada" [readonly]="!editandoNota" (onGuardado)="handleGuardado($event)" (onCancelado)="mostrarNota = false; notaEditada = null"
        *ngIf="notaEditada">
    </gestor-nota>
</p-dialog>