
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { Component, OnDestroy, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { ColumnGenerator } from 'src/app/common/utils/ColumnGenerator';
import { JobConfiguration } from 'src/app/model/JobConfiguration';
import { JobConfigurationService } from 'src/app/services/job-configuration.service';
import { LoadingService } from 'src/app/common/services/loading-data-service.service';

@Component({
	selector: 'listado-job-configuration',
	templateUrl: 'listado-job-configuration.component.html',
	styleUrls: ['listado-job-configuration.component.less']
})

export class ListadoJobConfigurationComponent extends SessionComponent implements OnInit, OnDestroy {

	public filtro: Filtro;
	
	@Input()
	public guardarFiltro: boolean = true;	
	@Input()
	public ocultarCabecera: boolean = false;  	
	@Input()
	public editable : boolean = true;
	public listado: JobConfiguration[] = [];
	@Input()
  	public readonly: boolean = false;
  	@Input()
  	public modoSeleccion: 'single' | 'multiple' | 'none' = 'none';
  	
  	@Output()
  	public onSelect: EventEmitter<JobConfiguration[]> = new EventEmitter();
  	
	public columns = [];
	constructor(messagesService : MessagesService,
				public service: JobConfigurationService) {
		super(messagesService)
		console.log(JSON.stringify(this.columns));
	}
	ngOnInit() { 
	this.filtro = new Filtro("job-configuration_lista", {}, 0, 20,'id',1,this.guardarFiltro)
		this.columns = [
  {
    "field": "id",
    "header": "#",
    "class": "id-column",
    "role": "text-tabla",
    "sortable": true,
    "order": 1
  },
  {
    "field": "identificación",
    "header": "Identificacion",
    "class": "text-tabla",
    "role": "text-tabla",
    "sortable": true,
    "order": 40
  },
  {
    "field": "jobId",
    "header": "Job Id",
    "class": "text-tabla",
    "role": "text-tabla",
    "sortable": true,
    "order": 40
  },  
  {
    "field": "horaDeEjecucion",
    "header": "Hora programada",
    "class": "hora-tabla text-center",
    "role": "hora-tabla",
    "sortable": true,
    "esMobile":true,
    "order": 40
  },  
  
  {
    "field": "activo",
    "header": "Activo",
    "class": "switch-tabla",
    "role": "switch-tabla",
    "esMobile": true,
    "sortable": false,
    "order": 40,
	"switchChange":(event,data:JobConfiguration)=> {
		event?.originalEvent?.stopPropagation && event.originalEvent.stopPropagation();
        data.activo = event.checked;
		this.service.guardar(data).then(r=> {
			this.success("Estado actualizado");
		})
	}
  }
];
	}
	
	 public updateSeleccion(seleccion: any) {
	    if (seleccion?.length || seleccion['id']) {
	      this.onSelect.emit(seleccion?.length ? seleccion : [seleccion]);
	    }
	  }
	ngOnDestroy() { }

	
	
}