<div class="edicion-lista-precios content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'ListaPrecios'"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[(readonly)]="readonly"
		[editable]="editable"
		(onCancelar)="handleCancelar($event)"
		[form]="ListaPreciosForm"
		[preSave]="preGuardado"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #ListaPreciosForm="ngForm" id="ListaPreciosform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-6">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="text"
								#descripcion="ngModel"
								name="descripcion"
								required
								[readonly]="readonly"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>

					<div class="col-12 lg:col-3">
						<parametrico-selector
							[service]="monedaService"
							#moneda="ngModel"
							[(ngModel)]="item.moneda"
							[limpiable]="true"
							name="moneda"
							[titulo]="'MONEDA' | translate"
							required
							[readonly]="readonly"
						>
						</parametrico-selector>
					</div>
					<div class="col-12 lg:col-2 wrapper-switch">
						<span class="label-switch">{{ "INCLUYE_IMPUESTOS" | translate }}</span>
						<p-inputSwitch [(ngModel)]="item.impuestosIncluidos" name="impuestosIncluidos" #impuestosIncluidos="ngModel" [disabled]="readonly">
						</p-inputSwitch>
					</div>
					<div class="col-fixed">
						<button pButton icon="fa fa-download" pTooltip="{{ 'ARCHIVO_MODIFICACION' | translate }}" (click)="descargarMuestra($event)"></button>
					</div>
					<div class="col-12 precios">
						<div class="titulo-precios grid align-items-center">
							<div class="col-12 lg:col-7" id="step1edicion">
								<div class="grid m-0">
									<div class="col-fixed w-2">
										<p-checkbox [label]="'Solo en conflicto'" binary="true" [(ngModel)]="soloConflictos" name="soloConflictos">
										</p-checkbox>
									</div>
									<div class="col-fixed w-fecha">
										<p-calendar
											appendTo="body"
											#picker
											[(ngModel)]="fechaPrecios"
											showIcon="true"
											[placeholder]="'FECHA_DESDE' | translate"
											pTooltip="{{ 'FECHA_DESDE' | translate }}"
											tooltipPosition="bottom"
											[inputStyleClass]="'w-100'"
											name="fechaPrecios"
											dateFormat="dd/mm/yy"
										></p-calendar>
									</div>
									<div class="col-fixed w-fecha">
										<p-calendar
											appendTo="body"
											#picker
											[(ngModel)]="fechaPreciosHasta"
											[placeholder]="'FECHA_HASTA' | translate"
											showIcon="true"
											pTooltip="{{ 'FECHA_HASTA' | translate }}"
											[inputStyleClass]="'w-100'"
											tooltipPosition="bottom"
											name="fechaPreciosHasta"
											dateFormat="dd/mm/yy"
										></p-calendar>
									</div>
									<div class="col-12 lg:col buscador">
										<div class="p-inputgroup">
											<input
												pInputText
												[formControl]="buscarControl"
												name="search"
												autocomplete="false"
												tooltipPosition="bottom"
												pTooltip="{{ 'Buscar precio' | translate }}"
												type="text"
											/>
											<span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
										</div>
									</div>
								</div>
							</div>

							<div class="col-12 lg:col-5 p-fluid" *ngIf="!readonly">
								<div class="grid m-0" id="step3edicion">
									<div class="col-fixed align-items-center">
										<p-selectButton
											[style]="{ 'justify-content': 'center' }"
											[options]="opcionesEdicion"
											name="modoEdicion"
											[(ngModel)]="modoEdicion"
											optionValue="value"
										>
											<ng-template let-item>
												<i [class]="item.icon" pTooltip="{{ item.tooltip | translate }}"></i>
											</ng-template>
										</p-selectButton>
									</div>
									<div class="col p-fluid">
										<div class="p-inputgroup">
											<input
												[(ngModel)]="montoEdicion"
												name="montoedicion"
												[readonly]="!selectedProducts?.length"
												type="number"
												pInputText
												placeholder="{{ 'Valor a aplicar' | translate }}"
												class="w-100"
											/>
											<button
												pButton
												label="{{ 'APLICAR' | translate }}"
												(click)="aplicarAjuste()"
												*ngIf="selectedProducts?.length"
											></button>
										</div>
									</div>
									<div class="col-fixed text-right">
										<button
											pButton
											[icon]="'fa fa-cog'"
											class="p-button-secondary"
											(click)="verGestionProductos = true"
											*ngIf="!readonly"
											tooltipPosition="bottom"
											pTooltip="{{ 'GESTIONAR_PRODUCTOS' | translate }}"
										></button>
									</div>
								</div>
							</div>
						</div>

						<div class="precios-lista">
							<p-table
								[value]="productosFiltrados"
								[(selection)]="selectedProducts"
								dataKey="key"
								#tablaPrecios
								rowGroupMode="rowspan"
								groupRowsBy="producto.codigo"
								[scrollHeight]="scrollHeight"
								[virtualScroll]="true"
								[virtualScrollItemSize]="50"
								[tableStyle]="{ 'min-width': '75rem', 'min-heigth': '10rem' }"
							>
								<ng-template pTemplate="header">
									<tr>
										<th>{{ "PRODUCTO" | translate }}</th>
										<th style="width: 3rem" *ngIf="!readonly" id="step2edicion">
											<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
										</th>
										<th class="text-center w-9rem">
											{{ "FECHA_DESDE" | translate }} <small>{{ producto?.nameSort }}</small>
										</th>
										<th class="text-center w-9rem">{{ "FECHA_HASTA" | translate }}</th>
										<th class="w-4rem text-center" pTooltip="{{ 'MIN_PAX' | translate }}">{{ "MIN_P" | translate }}</th>
										<th class="w-4rem text-center" pTooltip="{{ 'MAX_PAX' | translate }}">{{ "MAX_P" | translate }}</th>
										<th class="text-center" style="width: 8rem">{{ "PRECIO_CALCULADO" | translate }}</th>
										<th class="w-14rem">{{ "PRECIO" | translate }}</th>
										<th *ngIf="!readonly" class="w-5rem">
											<button
												pButton
												[disabled]="!selectedProducts?.length"
												icon="pi pi-plus"
												pTooltip="{{ 'Agregar nuevos precios' | translate }}"
												(click)="nuevoPrecioParaSeleccionados()"
											></button>
											<button
												pButton
												[disabled]="!selectedProducts?.length"
												class="ml-2"
												icon="pi pi-copy"
												pTooltip="{{ 'Agregar nuevos precios clonando los elegidos' | translate }}"
												(click)="copiarSeleccionados()"
											></button>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-producto let-rowIndex="rowIndex" let-rowgroup="rowgroup" let-rowspan="rowspan">
									<tr
										[ngClass]="{ 'p-selectable-row': !readonly, superposicion: producto.superpone, modificado: producto.modificado }"
										[pSelectableRowIndex]="rowIndex"
										[pSelectableRow]="producto"
									>
										<td *ngIf="rowgroup" [attr.rowspan]="rowspan" style="width: 30%" class="p-fluid">
											<span class="font-bold ml-2">{{ producto?.descripcion }}</span>
										</td>
										<td *ngIf="!readonly">
											<p-tableCheckbox [value]="producto"></p-tableCheckbox>
										</td>
										<td class="p-fluid">
											<p-calendar
												[showOnFocus]="false"
												appendTo="body"
												[required]="true"
												[disabled]="readonly"
												[(ngModel)]="producto.fechaDesde"
												showIcon="true"
												name="{{ 'fechaDesde_' + producto.key }}"
												placeholder="{{ 'DESDE' | translate }}"
												dateFormat="dd/mm/yy"
												[ngModelOptions]="{ standalone: true }"
												[max]="producto.fechaHasta"
											></p-calendar>
										</td>
										<td class="p-fluid">
											<p-calendar
												appendTo="body"
												[showOnFocus]="false"
												[(ngModel)]="producto.fechaHasta"
												showIcon="true"
												[disabled]="readonly"
												name="{{ 'fechaHasta_' + producto.key }}"
												placeholder="{{ 'HASTA' | translate }}"
												dateFormat="dd/mm/yy"
												[ngModelOptions]="{ standalone: true }"
												[min]="producto.fechaDesde"
											></p-calendar>
										</td>
										<td class="p-fluid text-center">
											<p-inputNumber
												[style]="{ 'text-align': 'center' }"
												tooltipPosition="bottom"
												pTooltip="{{ 'MIN_PAX' | translate }}"
												[disabled]="readonly || producto.esCalculado"
												[ngModelOptions]="{ standalone: true }"
												[max]="producto.maxPax"
												[(ngModel)]="producto.minPax"
												[showButtons]="false"
											>
											</p-inputNumber>
										</td>
										<td class="p-fluid text-center">
											<p-inputNumber
												[style]="{ 'text-align': 'center' }"
												tooltipPosition="bottom"
												pTooltip="{{ 'MAX_PAX' | translate }}"
												[disabled]="readonly || producto.esCalculado"
												[ngModelOptions]="{ standalone: true }"
												[min]="producto.minPax"
												[(ngModel)]="producto.maxPax"
												[showButtons]="false"
											>
											</p-inputNumber>
										</td>
										<td class="text-center">
											<p-selectButton
												[options]="opcionesCalculo"
												[ngModelOptions]="{ standalone: true }"
												[(ngModel)]="producto.tipoCalculo"
												optionValue="value"
												styleClass="small-buttons text-center"
												[disabled]="readonly"
											>
												<ng-template let-item>
													<small class="text-center" pTooltip="{{ item.tooltip | translate }}">{{ item.value || "P" }}</small>
												</ng-template>
											</p-selectButton>
										</td>
										<td class="p-fluid" [ngClass]="{ 'sin-precio': !producto.precio && !producto?.esCalculado }">
											<div class="grid" *ngIf="!producto?.esCalculado">
												<div class="col p-fluid">
													<span-dinero-editable
														[(ngModel)]="producto.precio"
														[label]="'PRECIO' | translate"
														[editable]="!readonly"
														name="{{ 'precio' + producto.key }}"
														#valorPrecio="ngModel"
														[ngModelOptions]="{ standalone: true }"
														[moneda]="item.moneda"
														[required]="true"
														[min]="0"
													>
													</span-dinero-editable>
												</div>
												<div class="col-12 lg:col-6 p-fluid" *ngIf="!producto?.esCalculado">
													<span-dinero-editable
														[(ngModel)]="producto.precioMinimo"
														[label]="'PRECIO_MINIMO' | translate"
														[editable]="!readonly"
														name="{{ 'precioMinimo' + producto.key }}"
														#valorPrecioMInimo="ngModel"
														[moneda]="item.moneda"
														[min]="0"
													>
													</span-dinero-editable>
												</div>
											</div>

											<div class="grid" *ngIf="producto?.esCalculado">
												<div class="col-6" *ngIf="producto?.tipoCalculo === 'D'">
													<p-inputNumber
														[style]="{ 'text-align': 'center' }"
														tooltipPosition="bottom"
														pTooltip="{{ 'PORCENTAJE' | translate }}"
														[disabled]="readonly || !producto.esCalculado"
														[ngModelOptions]="{ standalone: true }"
														[(ngModel)]="producto.porcentual"
														[showButtons]="false"
														suffix="%"
													>
													</p-inputNumber>
												</div>
												<div class="col-12" *ngIf="producto?.tipoCalculo === 'D'">
													<descriptivo-material-selector
														[options]="productosEnListado | excludeById : producto.producto?.id"
														[label]="'PRODUCTO' | translate"
														[limpiable]="false"
														[(ngModel)]="producto.productoDependiente"
														[permiteNuevo]="false"
														[ngModelOptions]="{ standalone: true }"
														[required]="true"
														[readonly]="readonly || !producto.esCalculado"
													>
													</descriptivo-material-selector>
												</div>
												<div class="col-12" *ngIf="producto?.tipoCalculo === 'C'">
													<small class="text-center w-100 block">{{ "PRECIO_CALCULADO_COSTO" | translate }}</small>
												</div>
											</div>
										</td>
										<td *ngIf="!readonly" style="display: flex">
											<button
												pButton
												class="p-button-text"
												icon="pi pi-trash"
												tooltipPosition="bottom"
												pTooltip="{{ 'BORRAR_PRECIO' | translate }}"
												(click)="eliminarPrecio(producto)"
											></button>
											<button
												pButton
												class="p-button-text"
												icon="pi pi-plus"
												tooltipPosition="bottom"
												pTooltip="{{ 'AGREGAR_UN_PRECIO' | translate }}"
												(click)="nuevoPrecioParaSeleccionados(producto)"
											></button>
											<button
												pButton
												class="p-button-text"
												icon="pi pi-copy"
												tooltipPosition="bottom"
												pTooltip="{{ 'COPIAR' | translate }}"
												(click)="copiarPrecio(producto)"
											></button>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</div>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
<p-dialog
	[(visible)]="verGestionProductos"
	[modal]="true"
	#dialDetail
	header="{{ 'GESTIONAR_PRODUCTOS' | translate }}"
	[styleClass]="'gestor-dialog'"
	(onShow)="onDialogShow($event, dialDetail)"
>
	<div class="grid">
		<div class="col-12">
			<p-pickList
				[source]="productoOptionsUnicos"
				[target]="productosUnicos"
				[filterBy]="'searchStr'"
				sourceHeader="{{ 'PRODUCTOS_DISPONIBLES' | translate }}"
				targetHeader="{{ 'PRODUCTOS_OFRECIDOS' | translate }}"
				(onMoveToTarget)="addProducto($event)"
				(onMoveToSource)="removeProducto($event)"
				(onMoveAllToSource)="removeProducto($event)"
				(onMoveAllToTarget)="addProducto($event)"
				[showSourceControls]="false"
				[showTargetControls]="false"
			>
				<ng-template let-p pTemplate="item">
					<div class="p-helper-clearfix">
						<div>{{ p.producto.codigo }} - {{ p.producto.descripcion }}</div>
					</div>
				</ng-template>
			</p-pickList>
		</div>
	</div>
</p-dialog>

<p-dialog [(visible)]="showRedondeo" [modal]="true" #dialogRedondeo>
	<ng-template pTemplate="header">
		<strong>{{ "CONFIRMAR_AJUSTE_PRECIOS" | translate }}</strong>
	</ng-template>
	<p class="w-20rem">{{ "DIGITOS_REDONDEO" | translate }}</p>
	<p-inputNumber pAutoFocus [autofocus]="true" [(ngModel)]="digitosSignificativos" [min]="0" [max]="5" [disabled]="!showRedondeo"></p-inputNumber>
	<ng-template pTemplate="footer">
		<button pButton type="button" label="Yes" (click)="confirmarRedondeo()" icon="pi pi-check" class="ui-button-success"></button>
		<button pButton type="button" label="No" (click)="showRedondeo = false" icon="pi pi-times" class="ui-button-secondary"></button>
	</ng-template>
</p-dialog>

<p-dialog [(visible)]="showNuevosPrecios" [modal]="true" #dialogRedondeo>
	<ng-template pTemplate="header">
		<strong>{{ "AGREGAR_NUEVOS_PRECIOS" | translate }}</strong>
	</ng-template>

	<div class="grid m-0 w-30rem">
		<div class="col-12">
			<p>{{ "COMO_AGREGAR_NUEVOS" | translate }}</p>
		</div>
		<div class="col-fixed align-items-center">
			<p-selectButton
				[style]="{ 'justify-content': 'center' }"
				[options]="opcionesEdicionNuevo"
				name="modoEdicionNuevo"
				[(ngModel)]="modoEdicionNuevo"
				optionValue="value"
			>
				<ng-template let-item>
					<i [class]="item.icon" pTooltip="{{ item.tooltip | translate }}"></i>
				</ng-template>
			</p-selectButton>
		</div>
		<div class="col p-fluid" *ngIf="modoEdicionNuevo != 'E'">
			<div class="p-inputgroup">
				<input
					[(ngModel)]="montoEdicionNuevo"
					name="montoedicionNuevo"
					[readonly]="!selectedProducts?.length"
					type="number"
					pInputText
					placeholder="{{ 'Valor a aplicar' | translate }}"
					class="w-100"
				/>
			</div>
		</div>
		<div class="col-12">
			<p class="w-20rem">{{ "DIGITOS_REDONDEO" | translate }}</p>
		</div>
		<div class="col-8 p-fluid">
			<p-inputNumber
				pAutoFocus
				[autofocus]="true"
				[(ngModel)]="digitosSignificativos"
				[min]="0"
				[max]="5"
				[disabled]="!showNuevosPrecios"
			></p-inputNumber>
		</div>
	</div>
	<ng-template pTemplate="footer">
		<button pButton type="button" label="Cancelar" (click)="showNuevosPrecios = false" icon="pi pi-times" class="ui-button-secondary"></button>
		<button pButton label="{{ 'APLICAR' | translate }}" (click)="aplicarNuevo()" *ngIf="selectedProducts?.length"></button>
	</ng-template>
</p-dialog>
