import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { ItemComprobante } from 'src/app/model/ItemComprobante';
import { FiltroComprobante } from '../model/FiltroComprobantes';
import { ItemComprobanteReporte } from '../model/ItemComprobanteReporte';
import { lastValueFrom } from 'rxjs';
import { Filtro } from '../common/model/Filtro';
import { LoadingService } from '../common/services/loading-data-service.service';
import { Idioma } from '../model/Idioma';
@Injectable({
    providedIn: 'root',
})
export class ItemComprobanteService extends ServicioAbstract<ItemComprobante>{
    public baseName(): string {
        return "item-comprobante";
    }
    public parseToEnt(data: any): ItemComprobante {
        return ItemComprobante.fromData(data);
    }
    public newEnt(): ItemComprobante {
        return new ItemComprobante();
    }

    public getReporte(filtro: FiltroComprobante = new FiltroComprobante('dummy'), customLoading?: LoadingService,idioma:string = Idioma.DEFAULT_CODIGO): Promise<ItemComprobanteReporte[]>{
        const $this = this;
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        if(!filtro['idioma']) filtro['idioma'] = idioma;
        return lastValueFrom(this.http.post(this.getBaseURL('reporte'), filtro.json),{defaultValue:[]})
            .then((r:any) =>  r?.map(result => ItemComprobanteReporte.fromData(result))).catch((e) => this.handleError(e, customLoading)).finally(()=>{
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
            });
    }


}
