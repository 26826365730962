import { Injectable } from "@angular/core"
import { ModelGestorComponent } from "../common/components/model-gestor/model-gestor.component"

@Injectable({
	providedIn: "root"
})
export class StateManagementService {
	public componentRef: ModelGestorComponent

	hasStateChanged(): boolean {
		return this.componentRef?.checkModificado()
	}
}
