<abm-hijo
	class="p-inputwrapper-filled"
	[items]="item.subcategorias"
	(onAgregar)="item.agregarItem($event)"
	(onQuitar)="item.quitarItem($event)"
	[newItem]="newItem(item)"
	[insertOnly]="true"
	[itemTemplate]="itemTemplate"
	[editable]="!readonly"
>
	<ng-template #itemTemplate let-itemRow let-r="index">
		<ng-container *ngTemplateOutlet="itemSubcategoria; context: { $implicit: itemRow, index: r }"> </ng-container>
	</ng-template>
</abm-hijo>

<ng-template #itemSubcategoria let-itemRow let-r="index" let-editable="editable">
	<div class="grid" *ngIf="itemRow">
		<div class="lg:col-3 p-fluid">
			<descriptivo-material-selector
				[(ngModel)]="itemRow.subcategoriaImponible"
				[options]="subcategorias"
				[isLazy]="false"
				[limpiable]="false"
				[label]="'SUBCATEGORIA_IMPONIBLE' | translate"
				[permiteNuevo]="true"
				name="{{ 'puesto' + r }}"
				[gestor]="gestorSubcategoria"
				[required]="true"
				[readonly]="readonly"
				(onSelect)="actualizarTipoIva($event, itemRow)"
			>
				<ng-template #gestorSubcategoria let-handler="handler" let-itemEditado="itemEditado">
					<gestor-subcategoria-imponible
						[item]="itemEditado"
						(onGuardado)="handler.onGuardado($event)"
						(onCancelado)="handler.onCancelado($event)"
						[isModal]="true"
						[goBack]="false"
					>
					</gestor-subcategoria-imponible>
				</ng-template>
			</descriptivo-material-selector>
		</div>
		<div class="col-12 lg:col-3 p-fluid">
			<parametrico-selector
				[allowDefault]="!item?.id"
				[service]="tipoIvaGrabadoService"
				name="{{ 'tipoIVAP' + r }}"
				[readonly]="readonly"
				[(ngModel)]="itemRow.tipoIVA"
				#tipoIVAP="ngModel"
				[limpiable]="true"
				[titulo]="'TIPO_DE_IVA' | translate"
				[required]="true"
			>
			</parametrico-selector>
		</div>

		<div class="col-12 lg:col-fixed lg:w-10rem p-fluid">
			<span class="p-float-label">
				<p-inputNumber
					[required]="true"
					[(ngModel)]="itemRow.proporcion"
					name="{{ 'proporcion' + r }}"
					[min]="1"
					[showButtons]="true"
					[max]="100"
					[step]="10"
					suffix="%"
					[readonly]="readonly"
				>
				</p-inputNumber>

				<label for="">{{ "PROPORCION" | translate }}</label>
			</span>
		</div>
	</div>
</ng-template>
