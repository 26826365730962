<div class="edicion-lista-precios content content-white">
	<div class="gestor-form" model-type="dataContent">
		<form #ListaPreciosForm="ngForm" id="ListaPreciosform">
			<div class="grid" *ngIf="items">
				<div class="col-12 precios">
					<div class="titulo-precios grid align-items-center">
						<div class="col-12 lg:col-7" id="step1edicion">
							<div class="grid m-0">
								<div class="col-fixed flex w-2">
									<p-checkbox [label]="'Solo en conflicto'" binary="true" [(ngModel)]="soloConflictos" name="soloConflictos"> </p-checkbox>
								</div>
								<div class="col-fixed w-fecha">
									<p-calendar
										appendTo="body"
										#picker
										[(ngModel)]="fechaPrecios"
										showIcon="true"
										[placeholder]="'FECHA_DESDE' | translate"
										pTooltip="{{ 'FECHA_DESDE' | translate }}"
										tooltipPosition="bottom"
										[inputStyleClass]="'w-100'"
										name="fechaPrecios"
										dateFormat="dd/mm/yy"
									></p-calendar>
								</div>
								<div class="col-fixed w-fecha">
									<p-calendar
										appendTo="body"
										#picker
										[(ngModel)]="fechaPreciosHasta"
										[placeholder]="'FECHA_HASTA' | translate"
										showIcon="true"
										pTooltip="{{ 'FECHA_HASTA' | translate }}"
										[inputStyleClass]="'w-100'"
										tooltipPosition="bottom"
										name="fechaPreciosHasta"
										dateFormat="dd/mm/yy"
									></p-calendar>
								</div>
								<div class="col-12 lg:col buscador">
									<div class="p-inputgroup">
										<input
											pInputText
											[formControl]="buscarControl"
											name="search"
											autocomplete="false"
											tooltipPosition="bottom"
											pTooltip="{{ 'Buscar precio' | translate }}"
											type="text"
										/>
										<span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
									</div>
								</div>
							</div>
						</div>

						<div class="col-12 lg:col-5 p-fluid" *ngIf="!readonly">
							<div class="grid m-0" id="step3edicion">
								<div class="col-fixed align-items-center">
									<p-selectButton
										[style]="{ 'justify-content': 'center' }"
										[options]="opcionesEdicion"
										name="modoEdicion"
										[(ngModel)]="modoEdicion"
										optionValue="value"
										[disabled]="readonly || esExterno"
									>
										<ng-template let-item>
											<i [class]="item.icon" pTooltip="{{ item.tooltip | translate }}"></i>
										</ng-template>
									</p-selectButton>
								</div>
								<div class="col p-fluid">
									<div class="p-inputgroup">
										<input
											[(ngModel)]="montoEdicion"
											name="montoedicion"
											[readonly]="!selectedProducts?.length"
											type="number"
											pInputText
											placeholder="{{ 'Valor a aplicar' | translate }}"
											class="w-100"
										/>
										<button pButton label="{{ 'APLICAR' | translate }}" (click)="aplicarAjuste()" *ngIf="selectedProducts?.length"></button>
									</div>
								</div>
								<div class="col-fixed text-right">
									<button
										pButton
										[icon]="'fa fa-cog'"
										class="p-button-secondary"
										(click)="verGestioninsumos = true"
										*ngIf="!readonly && !esExterno"
										tooltipPosition="bottom"
										pTooltip="{{ 'GESTIONAR_PRODUCTOS' | translate }}"
									></button>
								</div>
							</div>
						</div>
					</div>

					<div class="precios-lista">
						<p-table
							[value]="insumosFiltrados"
							[(selection)]="selectedProducts"
							dataKey="key"
							#tablaPrecios
							rowGroupMode="rowspan"
							groupRowsBy="insumo.codigo"
							[scrollHeight]="scrollHeight"
							[virtualScroll]="true"
							[virtualScrollItemSize]="46"
							[tableStyle]="{ 'min-width': '75rem', 'min-heigth': '10rem' }"
						>
							<ng-template pTemplate="header">
								<tr>
									<th>{{ "PRODUCTO" | translate }}</th>
									<th *ngIf="!readonly && !esExterno" id="step2edicion">
										<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
									</th>
									<th>
										{{ "FECHA_DESDE" | translate }} <small>{{ producto?.nameSort }}</small>
									</th>
									<th>{{ "FECHA_HASTA" | translate }}</th>
									<th class="w-5rem">{{ "MIN_P" | translate }}</th>
									<th class="w-5rem">{{ "MAX_P" | translate }}</th>
									<th class="w-10rem">{{ "PRECIO" | translate }}</th>
									<th class="w-10rem">{{ "PRECIO_MINIMO" | translate }}</th>
									<th class="w-7rem">{{ "IMP" | translate }}</th>
									<th class="w-7rem">{{ "MONEDA" | translate }}</th>
									<th class="w-6rem" pTooltip="{{ 'TIPO_MARKUP_TOOLTIP' | translate }}">
										{{ "TIPO_MARKUP" | translate }}
										<ng-container *ngIf="selectedProducts?.length">
											<p-selectButton
												[options]="opcionesMarkups"
												[ngModelOptions]="{ standalone: true }"
												[(ngModel)]="tipoMarkupGlobal"
												optionValue="value"
												styleClass="small-buttons"
												[disabled]="readonly || esExterno"
											>
												<ng-template let-item>
													<small pTooltip="{{ item.tooltip | translate }}">{{ item.value }}</small>
												</ng-template>
											</p-selectButton>
										</ng-container>
									</th>
									<th class="w-5rem p-fluid text-center">
										{{ "MARKUP" | translate }}
										<ng-container *ngIf="selectedProducts?.length">
											<p-inputNumber [(ngModel)]="markupGlobal" name="markupGlobal" suffix="%" [disabled]="readonly || esExterno">
											</p-inputNumber>
											<button pButton [label]="'APLICAR' | translate" (click)="aplicarMarkup()" class="p-button-small"></button>
										</ng-container>
									</th>
									<th *ngIf="!readonly">
										<button
											pButton
											[disabled]="!selectedProducts?.length"
											icon="pi pi-plus"
											pTooltip="{{ 'Agregar nuevos precios limitando los elegidos' | translate }}"
											(click)="nuevoPrecioParaSeleccionados()"
										></button>
										<button
											pButton
											class="ml-2"
											[disabled]="!selectedProducts?.length"
											icon="pi pi-copy"
											pTooltip="{{ 'Agregar nuevos precios clonando los elegidos' | translate }}"
											(click)="copiarSeleccionados()"
										></button>
									</th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-producto let-rowIndex="rowIndex" let-rowgroup="rowgroup" let-rowspan="rowspan">
								<tr
									[ngClass]="{ 'p-selectable-row': !readonly, superposicion: producto.superpone, modificado: producto.modificado }"
									[pSelectableRowIndex]="rowIndex"
									[pSelectableRow]="producto"
									*ngIf="producto"
								>
									<td *ngIf="rowgroup" [attr.rowspan]="rowspan" class="p-fluid">
										<span class="font-bold ml-2" [ngClass]="{ clickable: !esExterno }" (click)="goToInsumo($event, producto?.insumo?.id)"
											>{{ producto?.insumo?.descripcion }} <i class="fa fa-external-link" [hideExterno]></i
										></span>
									</td>
									<td *ngIf="!readonly && !esExterno">
										<p-tableCheckbox [value]="producto"></p-tableCheckbox>
									</td>
									<td class="p-fluid">
										<p-calendar
											[showOnFocus]="false"
											appendTo="body"
											[required]="true"
											[(ngModel)]="producto.fechaDesde"
											showIcon="true"
											[disabled]="readonly || esExterno"
											[ngModelOptions]="{ standalone: true }"
											inputStyleClass="w-7rem"
											placeholder="{{ 'DESDE' | translate }}"
											dateFormat="dd/mm/yy"
											[max]="producto.fechaHasta"
										></p-calendar>
									</td>
									<td class="p-fluid">
										<p-calendar
											appendTo="body"
											[showOnFocus]="false"
											[disabled]="readonly || esExterno"
											[(ngModel)]="producto.fechaHasta"
											showIcon="true"
											[ngModelOptions]="{ standalone: true }"
											inputStyleClass="w-7rem"
											placeholder="{{ 'HASTA' | translate }}"
											dateFormat="dd/mm/yy"
											[min]="producto.fechaDesde"
										></p-calendar>
									</td>
									<td class="p-fluid">
										<p-inputNumber
											[style]="{ 'text-align': 'center' }"
											tooltipPosition="bottom"
											pTooltip="{{ 'MIN_PAX' | translate }}"
											[disabled]="readonly || esExterno"
											[max]="producto?.maxPax"
											placeholder="{{ 'MIN_P' | translate }}"
											[ngModelOptions]="{ standalone: true }"
											[(ngModel)]="producto.minPax"
											[showButtons]="false"
										>
										</p-inputNumber>
									</td>
									<td class="p-fluid">
										<p-inputNumber
											[style]="{ 'text-align': 'center' }"
											tooltipPosition="bottom"
											pTooltip="{{ 'MAX_PAX' | translate }}"
											[disabled]="readonly || esExterno"
											[min]="producto?.minPax"
											placeholder="{{ 'MAX_P' | translate }}"
											[ngModelOptions]="{ standalone: true }"
											[(ngModel)]="producto.maxPax"
											[showButtons]="false"
										>
										</p-inputNumber>
									</td>

									<td [ngClass]="{ 'sin-precio': !producto.precio }" class="p-fluid text-right">
										<span-dinero-editable
											[(ngModel)]="producto.precio"
											[label]="'PRECIO' | translate"
											[hideLabel]="true"
											[editable]="!readonly && !esExterno"
											[ngModelOptions]="{ standalone: true }"
											#valorPrecio="ngModel"
											[moneda]="producto.moneda"
											[required]="true"
											[min]="0"
										>
										</span-dinero-editable>
									</td>

									<td class="p-fluid text-right">
										<span-dinero-editable
											[(ngModel)]="producto.precioMinimo"
											[label]="'PRECIO_MINIMO' | translate"
											[hideLabel]="true"
											[editable]="!readonly && !esExterno"
											[ngModelOptions]="{ standalone: true }"
											#valorPrecioMInimo="ngModel"
											[moneda]="producto.moneda"
											[min]="0"
										>
										</span-dinero-editable>
									</td>
									<td class="p-fluid">
										<parametrico-selector
											*ngIf="!producto?.esSubcategorizado"
											[service]="tipoIvaGrabadoService"
											#tipoIVA="ngModel"
											[readonly]="readonly || esExterno"
											[(ngModel)]="producto.tipoIVA"
											[ngModelOptions]="{ standalone: true }"
											titulo=" "
											required
										>
										</parametrico-selector>
										<small class="block" style="padding-top: 0.75rem" *ngIf="producto?.esSubcategorizado"
											>{{ producto.resumenImpuestos }}
										</small>
									</td>
									<td class="p-fluid">
										<parametrico-selector
											[allowDefault]="!producto?.id"
											[service]="monedaService"
											#moneda="ngModel"
											[(ngModel)]="producto.moneda"
											[limpiable]="false"
											[readonly]="readonly || esExterno"
											[ngModelOptions]="{ standalone: true }"
											[field]="'codigo'"
											titulo=" "
											required
										>
										</parametrico-selector>
										<error-tag [model]="moneda"></error-tag>
									</td>
									<td class="p-fluid w-6rem text-center">
										<p-selectButton
											[options]="opcionesMarkups"
											[ngModelOptions]="{ standalone: true }"
											[(ngModel)]="producto.tipoMarkup"
											optionValue="value"
											styleClass="small-buttons"
											[disabled]="readonly || esExterno"
										>
											<ng-template let-item>
												<small pTooltip="{{ item.tooltip | translate }}">{{ item.value }}</small>
											</ng-template>
										</p-selectButton>
									</td>
									<td class="p-fluid w-6rem text-center">
										<p-inputNumber
											[(ngModel)]="producto.markup"
											[ngModelOptions]="{ standalone: true }"
											suffix="%"
											[disabled]="readonly || esExterno"
										>
										</p-inputNumber>
									</td>
									<td *ngIf="!readonly && !esExterno" class="flex p-fluid">
										<button
											[hideExterno]
											pButton
											class="p-button-text"
											icon="pi pi-trash"
											tooltipPosition="bottom"
											pTooltip="{{ 'BORRAR_PRECIO' | translate }}"
											(click)="eliminarPrecio(producto)"
										></button>
										<button
											[hideExterno]
											pButton
											class="p-button-text"
											icon="pi pi-plus"
											tooltipPosition="bottom"
											pTooltip="{{ 'AGREGAR_UN_PRECIO' | translate }}"
											(click)="nuevoPrecioParaSeleccionados(producto)"
										></button>
										<button
											[hideExterno]
											pButton
											class="p-button-text"
											icon="pi pi-copy"
											tooltipPosition="bottom"
											pTooltip="{{ 'COPIAR' | translate }}"
											(click)="copiarPrecio(producto)"
										></button>
									</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
<p-dialog
	[(visible)]="verGestioninsumos"
	[modal]="true"
	#dialDetail
	header="{{ 'GESTIONAR_PRODUCTOS' | translate }}"
	[styleClass]="'gestor-dialog'"
	(onShow)="onDialogShow($event, dialDetail)"
>
	<div class="grid">
		<div class="col-12">
			<p-pickList
				[source]="insumoOptions"
				[target]="insumosUnicos"
				[filterBy]="'searchStr'"
				sourceHeader="{{ 'PRODUCTOS_DISPONIBLES' | translate }}"
				targetHeader="{{ 'PRODUCTOS_OFRECIDOS' | translate }}"
				(onMoveToTarget)="addinsumo($event)"
				(onMoveToSource)="removeinsumo($event)"
				(onMoveAllToTarget)="addinsumo($event)"
				(onMoveAllToSource)="removeinsumo($event)"
				[showSourceControls]="false"
				[showTargetControls]="false"
			>
				<ng-template let-p pTemplate="item">
					<div class="p-helper-clearfix">
						<div>{{ p.codigo }} - {{ p.descripcion }}</div>
					</div>
				</ng-template>
			</p-pickList>
		</div>
		<div class="col-6 text-center" *ngIf="!readonly" allowed="ROLE_FACTURAS">
			<button pButton (click)="nuevoInsumo($event)" label="{{ 'NUEVO_INSUMO' | translate }}"></button>
		</div>
	</div>
</p-dialog>
<p-dialog
	header=""
	[(visible)]="mostrarGestorInsumo"
	[modal]="true"
	#dialGestorInsumo
	header="{{ 'Nuevo Insumo' | translate }}"
	[styleClass]="'gestor-dialog'"
	(onShow)="onDialogShow($event, dialGestorInsumo)"
>
	<div class="grid">
		<div class="col-12">
			<gestor-insumo
				[item]="insumo"
				(onGuardado)="guardarNuevoInsumo($event)"
				(onCancelado)="cancelarNuevoInsumo()"
				[isModal]="true"
				[goBack]="false"
				[readonly]="readonly || esExterno"
			>
			</gestor-insumo>
		</div>
	</div>
</p-dialog>

<p-dialog [(visible)]="showRedondeo" [modal]="true" #dialogRedondeo>
	<ng-template pTemplate="header">
		<strong>{{ "CONFIRMAR_AJUSTE_PRECIOS" | translate }}</strong>
	</ng-template>
	<p class="w-20rem">{{ "DIGITOS_REDONDEO" | translate }}</p>
	<p-inputNumber pAutoFocus [autofocus]="true" [(ngModel)]="digitosSignificativos" [min]="0" [max]="5" [disabled]="!showRedondeo"></p-inputNumber>
	<ng-template pTemplate="footer">
		<button pButton type="button" label="Yes" (click)="confirmarRedondeo()" icon="pi pi-check" class="ui-button-success"></button>
		<button pButton type="button" label="No" (click)="showRedondeo = false" icon="pi pi-times" class="ui-button-secondary"></button>
	</ng-template>
</p-dialog>

<p-dialog [(visible)]="showNuevosPrecios" [modal]="true" #dialogRedondeo>
	<ng-template pTemplate="header">
		<strong>{{ "AGREGAR_NUEVOS_PRECIOS" | translate }}</strong>
	</ng-template>

	<div class="grid m-0 w-30rem">
		<div class="col-12">
			<p>{{ "COMO_AGREGAR_NUEVOS" | translate }}</p>
		</div>
		<div class="col-fixed align-items-center">
			<p-selectButton
				[style]="{ 'justify-content': 'center' }"
				[options]="opcionesEdicionNuevo"
				name="modoEdicionNuevo"
				[(ngModel)]="modoEdicionNuevo"
				optionValue="value"
			>
				<ng-template let-item>
					<i [class]="item.icon" pTooltip="{{ item.tooltip | translate }}"></i>
				</ng-template>
			</p-selectButton>
		</div>
		<div class="col p-fluid" *ngIf="modoEdicionNuevo != 'E'">
			<div class="p-inputgroup">
				<input
					[(ngModel)]="montoEdicionNuevo"
					name="montoedicionNuevo"
					[readonly]="!selectedProducts?.length"
					type="number"
					pInputText
					placeholder="{{ 'Valor a aplicar' | translate }}"
					class="w-100"
				/>
			</div>
		</div>
		<div class="col-12">
			<p class="w-20rem">{{ "DIGITOS_REDONDEO" | translate }}</p>
		</div>
		<div class="col-8 p-fluid">
			<p-inputNumber
				pAutoFocus
				[autofocus]="true"
				[(ngModel)]="digitosSignificativos"
				[min]="0"
				[max]="5"
				[disabled]="!showNuevosPrecios"
			></p-inputNumber>
		</div>
	</div>
	<ng-template pTemplate="footer">
		<button pButton type="button" label="Cancelar" (click)="showNuevosPrecios = false" icon="pi pi-times" class="ui-button-secondary"></button>
		<button pButton label="{{ 'APLICAR' | translate }}" (click)="aplicarNuevo()" *ngIf="selectedProducts?.length"></button>
	</ng-template>
</p-dialog>
