export class Auditable {
	constructor(public lastModifiedBy?: string, public lastModifiedDate?: Date | string, public createdBy?: string, public createdDate?: Date | string) {}

	public static fromData(data: any): Auditable {
		if (!data) return null
		const o: Auditable = new Auditable(
			data.lastModifiedBy,
			data.lastModifiedDate ? new Date(data.lastModifiedDate) : null,
			data.createdBy,
			data.createdDate ? new Date(data.createdDate) : null
		)

		return o
	}
}
