import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import * as moment from "moment"
import { ConfirmationService } from "primeng/api"
import { OverlayPanel } from "primeng/overlaypanel"
import { MonedaEntorno } from "src/app/app.module"
import { Ausencia } from "src/app/model/Ausencia"
import { GrupoPax } from "src/app/model/GrupoPax"
import { Moneda } from "src/app/model/Moneda"
import { Producto } from "src/app/model/Producto"
import { PuestoACubrir } from "src/app/model/PuestoACubrir"
import { Vehiculo } from "src/app/model/Vehiculo"
import { MonedaService } from "src/app/services/moneda.service"
import { ProductoService } from "src/app/services/producto.service"
import { TipoIVAGrabadoService } from "src/app/services/tipo-iva-grabado.service"
import { VehiculoService } from "src/app/services/vehiculo.service"
import { BadgeSize } from "./../../../common-components/puesto-badge/puesto-badge.component"
import { DragDropContext } from "./../../../common/services/drag-drop-context.service"
import { MessagesService } from "./../../../common/services/messages-data-service.service"
import { TranslateService } from "./../../../common/services/translate.service"
import { Delegacion } from "./../../../model/Delegacion"
import { Disponibilidad } from "./../../../model/Disponibilidad"
import { GrupoReserva } from "./../../../model/GrupoReserva"
import { ItemReserva } from "./../../../model/ItemReserva"
import { PuestoInsumo } from "./../../../model/PuestoInsumo"
import { PuestoServicioReserva } from "./../../../model/PuestoServicioReserva"
import { TipoPuesto } from "./../../../model/TipoPuesto"
import { GrupoPaxService } from "./../../../services/grupo-pax.service"
import { InsumoService } from "./../../../services/insumo.service"
import { PuestoServicioReservaService } from "./../../../services/puesto-servicio-reserva.service"
import { TipoPuestoService } from "./../../../services/tipo-puesto.service"
import { MovimientoDragDrop } from "./../model/MovimientoDragDrop"
@Component({
	selector: "grupo",
	templateUrl: "./grupo.component.html",
	styleUrls: ["./grupo.component.less"]
})
export class GrupoComponent implements OnInit {
	@Input()
	public ausencias: Ausencia[] = []

	private _verCliente: boolean = false
	public get verCliente(): boolean {
		return this._verCliente
	}
	@Input()
	public set verCliente(v: boolean) {
		this._verCliente = v
	}
	@Input()
	public verObservaciones: boolean = false
	@Input()
	public verFecha: boolean = false
	@Input()
	public styleClass: string = ""
	@Input()
	public mostrarVehiculo: boolean = false
	@Input()
	public permiteAgregarPuestos: boolean = true
	@Input()
	public cantidadAMover: number = 0
	public verModificarDisponibles: boolean = false
	public nuevaCapacidad: number = 0
	public disponibilidad: Disponibilidad[] = []
	@Input()
	public vehiculoEditable: boolean = false
	@Output()
	public onVehiculoChange: EventEmitter<Vehiculo> = new EventEmitter<Vehiculo>()
	verDelegar: boolean = false
	delegacion: Delegacion
	tipoPuestoElegido: TipoPuesto
	monedaEntorno: Moneda = MonedaEntorno()
	public get ausenciasPuesto(): Ausencia[] {
		return this.ausencias?.filter(
			(a) => moment(a.fechaDesde).isSameOrBefore(this.grupo.fechaActividad) && moment(a.fechaHasta).isSameOrAfter(this.grupo.fechaActividad)
		)
	}
	public seleccionarVehiculo(event, op: OverlayPanel) {
		op.hide()
		this.onVehiculoIntercambiado.emit({ from: this.grupo.vehiculoAsignado, to: event.data, grupo: this.grupo })
	}
	public editarVehiculo = async (event, op: OverlayPanel) => {
		if (!this.vehiculoEditable && !this.producto?.esTercerizado()) return
		event.stopPropagation()
		await this.productoService.getById(this.grupo.producto.id).then((p) => {
			this.producto = p
			this.vehiculoService.getAll().then((v) => {
				this.vehiculos = v.filter(
					(v) =>
						v.id != this.grupo.vehiculoAsignado?.id && !v.esBloqueado(this.grupo.fechaActividad) && this.producto && this.producto.cumpleVehiculo(v)
				)
			})
		})
		op.show(event)
	}
	public get disponibilidadPuesto(): Ausencia[] {
		let d = this.disponibilidad?.filter(
			(a) => moment(a.fechaDesde).isSameOrBefore(this.grupo.fechaActividad) && moment(a.fechaHasta).isSameOrAfter(this.grupo.fechaActividad)
		)
		return d
	}

	private _grupo: GrupoPax
	public get grupo(): GrupoPax {
		return this._grupo
	}
	@Input()
	public set grupo(v: GrupoPax) {
		this._grupo = v
	}

	productoSeleccionado: Producto

	private _item: ItemReserva
	public get item(): ItemReserva {
		return this._item
	}
	@Input()
	public set item(v: ItemReserva) {
		if (v && v?.producto?.id && this._item?.producto?.id != v?.producto?.id) {
			this.productoService.getById(v.producto.id).then((p) => {
				this.productoSeleccionado = p
			})
		}
		this._item = v
	}

	@Input()
	public readonly: boolean = false
	@Input()
	public draggable: boolean = true
	public visualizarDetalle: boolean = false

	@Input()
	public error: string = ""
	@Input()
	public borrable: boolean = false

	@Input()
	public bloqueable: boolean = true

	@Input()
	public porUbicar: number = 0

	@Input()
	public mostrarPuestos: boolean = true

	@Input()
	public itemReservaActual: ItemReserva

	@Input()
	public fechaEditable: boolean = false

	@Output()
	public onBorrar: EventEmitter<GrupoPax> = new EventEmitter<GrupoPax>()

	@Output()
	public onItemClick: EventEmitter<GrupoReserva> = new EventEmitter<GrupoReserva>()
	//Nuevos eventos
	@Output()
	public onBuscaItem: EventEmitter<GrupoReserva> = new EventEmitter<GrupoReserva>()

	@Output()
	public onVehiculoIntercambiado: EventEmitter<any> = new EventEmitter()

	@Output()
	public onGrupoChange: EventEmitter<GrupoPax> = new EventEmitter()

	@Output()
	public onAsignacion: EventEmitter<boolean> = new EventEmitter()

	@Output()
	public onPuestoSeleccionado: EventEmitter<MovimientoDragDrop> = new EventEmitter<MovimientoDragDrop>()
	@Output()
	public onModalCancel: EventEmitter<any> = new EventEmitter()

	@Output() public onFechaChange: EventEmitter<{ original: Date; nueva: Date; duracion: number }> = new EventEmitter(null)

	@Input()
	public permiteGestionCostos: boolean = false

	editarFecha: boolean = false

	fechaEditada: Date
	duracionEditada: number
	@Input()
	public badgeSize: BadgeSize = "big"
	public puestoEditado: PuestoACubrir
	@Input()
	public permiteAsignacion: boolean = true
	public verPuestoAdicional: boolean = false
	@Input()
	public persistirAsignacion: boolean = true
	@Input()
	public verInfoContacto: boolean = false

	public displayModal: boolean = false
	public accepted: boolean = false
	public aMover: number = 1
	public get cantidadMover(): number {
		return this.aMover
	}
	public set cantidadMover(v: number) {
		this.aMover = v
	}
	public dragEvent: DragEvent
	public cancelarOperacion: boolean = false
	public vehiculos: Vehiculo[] = []
	public maximoValor: number
	public producto: Producto
	constructor(
		public dragDropContext: DragDropContext,
		public messagesService: MessagesService,
		public puestoServicioReservaService: PuestoServicioReservaService,
		public translateService: TranslateService,
		public grupoPaxService: GrupoPaxService,
		public confService: ConfirmationService,
		public insumosService: InsumoService,
		public tipoPuestoService: TipoPuestoService,
		public productoService: ProductoService,
		public monedaService: MonedaService,
		public tipoIVAService: TipoIVAGrabadoService,
		public vehiculoService: VehiculoService
	) {}

	@Output()
	public onBloqueoGrupo: EventEmitter<GrupoPax> = new EventEmitter<GrupoPax>()

	//Emite los cambios al seleccionar por grupos
	@Output()
	public onIsIconGroup: EventEmitter<any> = new EventEmitter()
	@Output()
	public onCantidad: EventEmitter<any> = new EventEmitter()

	ngOnInit() {
		if (this.readonly) {
			this.borrable = false
			this.draggable = false
		}

		if (this.grupo?.producto["tipoProducto"]?.codigo == "TER") {
			this.draggable = false
		}
	}
	@Input()
	public full: boolean = false
	public esSeleccionable(item: GrupoReserva) {
		if (!item) return false
		return !this.itemReservaActual || item.tieneItem(this.itemReservaActual)
	}

	public showError(mensaje?: string, titulo?: string) {
		this.messagesService.error(mensaje, titulo)
		return false
	}

	public showsuccess(mensaje?: string, titulo?: string) {
		this.messagesService.success(mensaje, titulo)
		return false
	}
	public handleGrupoDrop(evento) {
		let grupo: GrupoPax = this.dragDropContext.fromContext
		if (grupo?.producto?.id == this.grupo?.producto?.id) {
			let pivote = grupo.vehiculoAsignado
			let fechaPivote = grupo.fechaActividad
			grupo.setVehiculo(this.grupo.vehiculoAsignado)
			grupo.fechaActividad = this.grupo.fechaActividad
			this.grupo.fechaActividad = fechaPivote
			this.grupo.setVehiculo(pivote)
			this.onVehiculoIntercambiado.emit({ from: grupo, to: this.grupo })
			evento.stopPropagation()
			this.dragDropContext.reset()
			evento.target.classList.remove("over")
			evento.target.classList.remove("over-disabled")
		}
	}
	public onFocus(event: any) {
		event.srcElement.select()
	}
	public openModal(dragEvent: DragEvent) {
		this.displayModal = true
		this.dragEvent = dragEvent
	}
	public onConfirm(event) {
		event.stopPropagation()
		this.displayModal = false
		this.dragDropContext.fromContext.cantidad = this.aMover
		this.handlePaxDrop(this.dragEvent)
	}
	public onCancel(evento) {
		this.displayModal = false
		this.aMover = 0
		evento.stopPropagation()
		this.dragDropContext.reset()
	}
	public onModalClose(evento: DragEvent) {
		this.cancelarOperacion = true
	}
	public handlePaxDrop(evento) {
		let movimiento: MovimientoDragDrop = this.dragDropContext.fromContext

		if (this.item && !Producto.esMismaFamilia(this.productoSeleccionado, this.grupo.producto)) {
			this.confService.confirm({
				key: "genConf",
				header: "Confirmar",
				message: this.translateService.get(
					`Estás moviendo un pasajero a un grupo con el servicio ${this.grupo.producto.descripcion}. ¿Deseas continuar?`
				),
				accept: () => {
					this.doPaxDrop(evento, movimiento)
				},
				reject: () => {
					evento.stopPropagation()
					this.dragDropContext.reset()
				}
			})
		} else this.doPaxDrop(evento, movimiento)
	}

	doPaxDrop(evento, movimiento: MovimientoDragDrop) {
		evento.target.classList.remove("over")
		evento.target.classList.remove("over-disabled")
		if (this.grupo.lugaresLibres == 0) {
			evento.stopPropagation()
			this.dragDropContext.reset()
			return this.showError(this.translateService.get("CAPACIDAD_SUPERADA"))
		}

		if (this.grupo.bloqueado) {
			evento.stopPropagation()
			this.dragDropContext.reset()
			return this.showError(this.translateService.get("BLOQUEADO_ERROR"))
		}
		if (movimiento) {
			if (this.dragDropContext.paxSelection) {
				var aMover: number = this.aMover
			} else var aMover = this.grupo.lugaresLibres >= movimiento.cantidad ? movimiento.cantidad : this.grupo.lugaresLibres
			if (movimiento.grupoOrigen && !moment(this.grupo.fechaActividad).isSame(moment(movimiento.grupoOrigen.fechaActividad), "date")) {
				evento.stopPropagation()
				this.dragDropContext.reset()
				return this.showError(this.translateService.get("NO_PUEDE_CAMBIAR_DE_5"))
			}

			if (this.grupo.lugaresLibres - aMover < 0) {
				evento.stopPropagation()
				this.dragDropContext.reset()
				return this.showError(this.translateService.get("CAPACIDAD_SUPERADA"))
			}

			var g = this.grupo.agregarPaxs(aMover, this.itemReservaActual ? this.itemReservaActual : movimiento.grupoReservaOrigen?.itemReserva)
			movimiento.grupoReservaOrigen && movimiento.grupoReservaOrigen.quitarPax(aMover)

			if (movimiento.grupoReservaOrigen?.cantidadPax <= 0) {
				movimiento.grupoOrigen?.quitarGrupoReserva(movimiento.grupoReservaOrigen)
			}
			movimiento.grupoDestino = this.grupo
			movimiento.grupoReservaDestino = g
			this.onPuestoSeleccionado.emit(movimiento)
		}
		this.aMover = 1
		evento.stopPropagation()
		this.dragDropContext.reset()
	}

	public ocuparLugar(evento: DragEvent) {
		let movimiento: MovimientoDragDrop = this.dragDropContext.fromContext
		this.maximoValor = movimiento?.cantidad || 1
		if (!this.checkMoverTercerizado()) return this.messagesService.error("NO_SE_PUEDE_MOVER_TERCERIZADO")

		if (this.dragDropContext.type == "paxs") {
			if (this.dragDropContext.paxSelection) {
				this.openModal(evento)
			} else {
				this.handlePaxDrop(evento)
			}
		} else if (this.dragDropContext.type == "grupos") {
			this.handleGrupoDrop(evento)
		}
	}
	checkMoverTercerizado() {
		const grupoOrigen = this.dragDropContext?.fromContext?.grupoOrigen
		const grupoDestino = this.grupo
		if (grupoOrigen && grupoDestino && (grupoOrigen.producto.esTercerizado() || grupoDestino.producto.esTercerizado())) {
			return grupoOrigen.producto.esTercerizado() && grupoDestino.producto.esTercerizado() && grupoDestino.producto.aplica(grupoOrigen.producto)
		} else {
			return true
		}
	}

	public startPaxDrag(evento: DragEvent, gr: GrupoReserva, cantidad: number, paxSelection: boolean = false) {
		if (this.grupo.bloqueado) {
			evento.stopPropagation()
			return this.showError(this.translateService.get("BLOQUEADO_ERROR"))
		}
		const grupoReserva = gr || this.grupo.gruposReserva.find((f) => f.itemReserva.UUID == this.itemReservaActual.UUID)
		let movimiento = new MovimientoDragDrop(this.grupo, grupoReserva, cantidad)
		this.dragDropContext.type = "paxs"
		this.dragDropContext.fromContext = movimiento
		this.dragDropContext.paxSelection = paxSelection

		evento.stopPropagation()
	}
	public borrar(event) {
		this.onBorrar.emit(this.grupo)
		event.stopPropagation()
	}
	public onDragOver(event, grupo: GrupoPax) {
		if (this.dragDropContext.fromContext == grupo) {
			grupo["hide"] = true
		} else {
			if (this.dragDropContext.type == "grupos" && grupo.producto?.id != this.dragDropContext.fromContext.producto?.id) {
				event.target.classList.add("over-disabled")
			} else {
				event.target.classList.add("over")
			}
		}
		event.preventDefault()
	}
	public onDragEnd(event, data) {
		data["hide"] = false
		event.target.classList.remove("over")
		event.target.classList.remove("over-disabled")
	}
	public onDragLeave(event) {
		event.target.classList.remove("over")
		event.target.classList.remove("over-disabled")
	}
	public startDragGrupo(evento) {
		this.dragDropContext.type = "grupos"
		this.dragDropContext.fromContext = this.grupo
		evento.stopPropagation()
	}

	public intercambiar(evento, grupoReserva: GrupoReserva) {
		let movimiento: MovimientoDragDrop = this.dragDropContext.fromContext
		evento.target.classList.remove("over")
		evento.target.classList.remove("over-disabled")

		evento.stopPropagation()
		if (this.dragDropContext.type != "paxs") return
		if (movimiento.cantidad == 1) {
			return
		}

		if (
			movimiento.grupoOrigen.lugaresLibres >= grupoReserva.cantidadPax - movimiento.grupoReservaOrigen.cantidadPax &&
			this.grupo.lugaresLibres >= movimiento.grupoReservaOrigen.cantidadPax - grupoReserva.cantidadPax
		) {
			movimiento.grupoOrigen.quitarGrupoReserva(movimiento.grupoReservaOrigen)
			movimiento.grupoOrigen.agregarGrupoReserva(grupoReserva)
			this.grupo.quitarGrupoReserva(grupoReserva)
			this.grupo.agregarGrupoReserva(movimiento.grupoReservaOrigen)
		} else {
			evento.stopPropagation()
			this.dragDropContext.reset()
			return this.showError(this.translateService.get("CAPACIDAD_SUPERADA"))
		}
		movimiento.grupoDestino = this.grupo
		movimiento.grupoReservaDestino = grupoReserva
		this.onPuestoSeleccionado.emit(movimiento)
		this.dragDropContext.reset()
	}

	public clickItem(event, item: GrupoReserva) {
		this.onItemClick.emit(item)
		event.stopPropagation()
	}
	public verDetalle() {
		this.visualizarDetalle = true && !this.readonly
	}
	public desasignar(puesto: PuestoServicioReserva) {
		this.puestoServicioReservaService
			.desasignar(puesto)
			.then((r) => {
				this.showsuccess(this.translateService.get("DESASIGNACION_CORRECTA"))
				this.grupoPaxService.getById(this.grupo.id).then((g) => {
					this.grupo = g
				})
			})
			.catch((r) => {
				puesto.personal = null
			})
	}
	public asignar(puesto: PuestoServicioReserva) {
		if (this.persistirAsignacion) {
			this.puestoServicioReservaService
				.asignar(puesto)
				.then((r) => {
					this.showsuccess(this.translateService.get("ASIGNACION_CORRECTA"))
					puesto.gastos = r.gastos
					puesto.personal = r.personal
					puesto.estado = r.estado
					this.onAsignacion.emit(true)
				})
				.catch((r) => {
					puesto.personal = null
				})
		} else {
			this.onAsignacion.emit(true)
		}
	}

	public bloquear() {
		if (this.grupo.bloqueado) {
			if (this.grupo.id) {
				this.grupoPaxService.desbloquearGrupo(this.grupo).then((g) => (this.grupo.bloqueado = g.bloqueado))
			} else {
				this.grupo.bloqueado = !this.grupo.bloqueado
			}
		} else {
			if (this.grupo.id) {
				this.grupoPaxService.bloquearGrupo(this.grupo).then((g) => (this.grupo.bloqueado = g.bloqueado))
			} else {
				this.grupo.bloqueado = !this.grupo.bloqueado
			}
		}
		if (this.grupo.gruposReserva) {
			this.grupo.gruposReserva.forEach((gr) => {
				gr.grupoResumen.bloqueado = this.grupo.bloqueado
			})
		}
	}
	public agregarInsumo(puesto: PuestoACubrir) {
		puesto.insumos.push(new PuestoInsumo(null, null, 1, 1))
	}
	public mostrarPuestoAdicional(puesto?) {
		if (puesto) {
			this.puestoEditado = PuestoACubrir.fromData(puesto.puesto)
			this.puestoEditado.personalDefault = puesto.proveedor
		} else {
			this.puestoEditado = new PuestoACubrir()
		}
		this.verPuestoAdicional = true
	}
	public mostrarDelegar(grupo: GrupoPax) {
		this.grupo = grupo
		this.delegacion = new Delegacion(grupo, new PuestoACubrir())
		this.verDelegar = true
	}
	public cancelarDelegacion() {
		this.verDelegar = false
		this.delegacion = null
	}

	public confirmarDelegacion() {
		if (!this.delegacion?.grupo) return this.showError("Debe seleccionar un grupo")
		if (!this.delegacion?.puesto?.tipoPuesto) return this.showError("Debe seleccionar un tipo de puesto")
		if (!this.delegacion?.puesto?.personalDefault) return this.showError("Debe seleccionar un proveedor a quien delegar")
		this.confService.confirm({
			key: "genConf",
			header: "Confirmar",
			message: this.translateService.get(`La delegación reemplazará todas las asignaciones por la nueva. No se podrá deshacer. ¿Desea continuar?`),
			accept: () => {
				if (this.persistirAsignacion) {
					this.grupoPaxService.delegar(this.delegacion).then((r) => {
						this.showsuccess("Delegación completa")
						this.grupo.puestos = r.puestos
						this.cancelarDelegacion()
						this.visualizarDetalle = false
						this.onGrupoChange.emit(this.grupo)
					})
				} else {
					this.grupo.puestos = [new PuestoServicioReserva(null, this.delegacion.puesto.proveedor, this.delegacion.puesto)]
					this.grupo.vehiculoAsignado = null
					this.grupo.esDelegado = true
					if (this.grupo.esAlquiler) this.grupo.esAlquiler = false
					this.cancelarDelegacion()
					this.visualizarDetalle = false
					this.onGrupoChange.emit(this.grupo)
				}
			}
		})
	}
	public borrarPuesto(puesto: PuestoServicioReserva) {
		this.confService.confirm({
			key: "genConf",
			header: "Confirmar",
			message: this.translateService.get(
				`Está por eliminar un puesto adicional. Esto borrará todas las asignaciones del mismo y notificará al proveedor en caso de haber sido previamente notificado. ¿Desea continuar?`
			),
			accept: () => {
				this.puestoServicioReservaService.eliminar(puesto.id).then((r) => {
					this.showsuccess("Grupo actualizado")
					this.onGrupoChange.emit(this.grupo)
				})
			}
		})
	}

	public guardarPuesto() {
		if (!this.puestoEditado) return
		let sinErrores = true
		this.puestoEditado.insumos
			.filter((i) => !i.insumo)
			.forEach((i) => {
				sinErrores = this.messagesService.error("DEBE_SELECCIONAR_UN_INSUMO")
			})
		if (!sinErrores) return
		const i = this.grupo.puestos.findIndex((p) => p.puesto.uid == this.puestoEditado?.uid)
		if (i >= 0) this.grupo.puestos[i].puesto = this.puestoEditado
		else this.grupo.puestos.push(new PuestoServicioReserva(null, this.puestoEditado.personalDefault, this.puestoEditado))
		if (this.persistirAsignacion) {
			this.grupoPaxService.guardar(this.grupo).then((g) => {
				this.onGrupoChange.emit(this.grupo)
				this.verPuestoAdicional = false
				this.puestoEditado = null
				this.grupo.version = g.version
			})
		} else {
			this.onGrupoChange.emit(this.grupo)
			this.verPuestoAdicional = false
			this.puestoEditado = null
		}
	}
	public agregarAdicional() {
		const nuevoPuesto = new PuestoServicioReserva(null, this.puestoEditado.personalDefault, this.puestoEditado)
		nuevoPuesto.esAdicional = true
		this.grupo.puestos.push(nuevoPuesto)
		this.grupoPaxService.guardar(this.grupo).then((g) => {
			this.onGrupoChange.emit(g)
			this.verPuestoAdicional = false
			this.puestoEditado = null
			this.grupo.version = g.version
		})
	}
	p
	public guardarGrupo = () => {
		this.grupoPaxService.guardar(this.grupo).then((g) => {
			this.messagesService.success(this.translateService.get("Grupo actualizado"))
			this.grupo.version = g.version
		})
	}
	public completar() {
		this.grupoPaxService.completar(this.grupo).then((r) => {
			this.showsuccess("Actividad finalizada")
			this.grupo.actividadCompletada = true
			this.grupo.version = r.version
		})
	}

	habilitarEdicionFecha(event) {
		if (this.fechaEditable && !this.readonly) {
			event.stopPropagation()
			this.editarFecha = true
			this.fechaEditada = moment(this.grupo.fechaActividad).toDate()
			this.duracionEditada = this.grupo.duracion
		}
	}

	guardarFecha() {
		const fechaOriginal = moment(this.grupo.fechaActividad).toDate()
		const fechaActividad = this.grupo.fechaActividad
		fechaActividad.setHours(this.fechaEditada.getHours())
		fechaActividad.setMinutes(this.fechaEditada.getMinutes())
		this.grupo.fechaActividad = moment(fechaActividad).toDate()
		this.editarFecha = false
		this.onFechaChange.emit({ original: fechaOriginal, nueva: fechaActividad, duracion: this.duracionEditada })
	}

	cerrarFecha() {
		this.fechaEditada = null
		this.editarFecha = false
	}

	modificarDisponibles(event) {
		event && event.stopPropagation()
		this.verModificarDisponibles = true
		this.nuevaCapacidad = this.grupo.capacidad
	}
	aplicarCambioCapacidad() {
		if (this.nuevaCapacidad < this.grupo.lugaresOcupados) {
			return this.showError(this.translateService.get("LA_NUEVA_CAPACIDAD__52"))
		}
		if (this.nuevaCapacidad < 0) {
			return this.showError(this.translateService.get("MAYOR_QUE_CERO"))
		}
		this.grupo.capacidad = this.nuevaCapacidad
		if (this.grupo?.id && !this.grupo.gruposReserva.some((gr) => !gr?.id)) {
			this.grupoPaxService.guardar(this.grupo).then((g) => {
				this.grupo.version = g.version
				this.showsuccess("Grupo actualizado")
				this.verModificarDisponibles = false
				this.onGrupoChange.emit(g)
			})
		} else {
			this.verModificarDisponibles = false
			this.grupo.capacidad = this.nuevaCapacidad
		}
	}
}
