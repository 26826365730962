<div class="grid container">
    <div class="col-12">
        <div class="grid content" *ngIf="filtro">
            <div class="col-12">
                <app-nota-card [nota]="null" [filtro]="filtro"></app-nota-card>
            </div>
            <div class="col-12" *ngFor="let nota of notas">
                <app-nota-card [nota]="nota" [filtro]="filtro"></app-nota-card>
            </div>
        </div>
    </div>
</div>
<p-overlayPanel #op [dismissable]="false" appendTo="body" [styleClass]="'options'">
    <span class="close" (click)="$event.stopPropagation(); hideParams()" style="position: absolute; top: 3px; right: 3px; cursor: pointer; padding: 0.25em"><i class="fa fa-times"></i></span>
    <div class="grid no-space-mat-form" style="max-width: 30em">
        <div class="col-11">
            <span class="p-float-label">
                <input pInputNumber type="number" [step]="1" [(ngModel)]="dias" class="p-inputwrapper-filled" />
                <label>{{ 'Días para adelante' | translate }}</label>
            </span>
        </div>
        <div class="col-12 text-center">
            <button pButton (click)="buscar()" label="{{ 'ACTUALIZAR' | translate }}"></button>
        </div>
    </div>
</p-overlayPanel>
