import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "../common/services/translate.service"

@Injectable({
	providedIn: "root"
})
export class HelpService {
	public helpArray = [
		{ name: "proveedor", url: "proveedor" },
		{ name: "vendedor", url: "proveedor" },
		{ name: "usuario", url: "usuarios" },
		{ name: "gestores", url: "gestores" },
		{ name: "listados", url: "listados" },
		{ name: "factura", url: "finanzas/facturas" },
		{ name: "lista-precios", url: "lista-de-precios" },
		{ name: "cliente", url: "cliente" },
		{ name: "vehiculo", url: "vehiculos" },
		{ name: "producto", url: "productos" },
		{ name: "tipo-notificacion", url: "configuracion/tipos-notificaciones" },
		{ name: "emailtemplate", url: "configuracion/plantillas" },
		{ name: "carga-masiva", url: "configuracion/cargas-masivas" },
		{ name: "itinerario", url: "itinerario" },
		{ name: "actividades", url: "actividades" },
		{ name: "reserva", url: "reservas" },
		{ name: "orden-pago", url: "finanzas/ordenes-pago" },
		{ name: "home", url: "home" }
	]
	constructor(private router: Router, private translateService: TranslateService) {}
	public static HELP_URL = "https://help.iterpax.com/"

	public goToHelp(destino: string) {
		const base = destino?.toLowerCase() || ""
		const url = this.helpArray.find((x) => x.name == base)
		const idioma = this.translateService.idioma || "es"
		const newRelativeUrl = HelpService.HELP_URL + idioma.toLowerCase() + "/" + (url?.url || "en-construccion")

		window.open(newRelativeUrl, "_blank")
	}
	public goSpecificHelp(path: string) {
		const idioma = this.translateService.idioma || "es"
		const newRelativeUrl = HelpService.HELP_URL + idioma.toLowerCase() + "/" + (path || "en-construccion")
		window.open(newRelativeUrl, "_blank")
	}
	public hasHelp(destino: string) {
		const low = destino?.toLowerCase() || undefined
		return low && this.helpArray.some((x) => x.name == low)
	}
}
