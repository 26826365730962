import { BehaviorSubject, Subscription, lastValueFrom } from "rxjs"
import { filter } from "rxjs/operators"
import { MessagesService } from "src/app/common/services/messages-data-service.service"
import { Idioma } from "src/app/model/Idioma"
import { BufferedDescriptivoService } from "src/app/services/BufferedDescriptivoService"
import { AuthService } from "./../../services/auth.service"
import { NovedadService } from "./../../services/novedades.service"
import { Filtro } from "./../model/Filtro"
import { Parametrico } from "./../model/Parametrico"
import { IHabilitable } from "./IHabilitable"
import { LoadingService } from "./loading-data-service.service"

export abstract class ParametricoService<E extends Parametrico> extends BufferedDescriptivoService<E> implements IHabilitable<E> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	private dataSus: Subscription

	private _habilitados: BehaviorSubject<E[]>
	public get habilitados(): BehaviorSubject<E[]> {
		if (!this._habilitados) {
			this._habilitados = new BehaviorSubject(null)
			this._dataBS.pipe(filter((d) => d != undefined)).subscribe((d) => {
				this._habilitados.next(d.filter((p) => p["habilidato"]).map((p) => this.parseToEnt(p)))
			})
		}
		return this._habilitados
	}
	public parseToEnt(data: any): any {
		return Parametrico.fromData(data)
	}

	public getHabilitados(filtro?: Filtro, customLoading?: LoadingService, idioma: string = Idioma.DEFAULT_CODIGO): Promise<E[]> {
		return this.getAll(filtro, customLoading, idioma).then((r) => r.filter((p) => p["habilitado"]).map((p) => this.parseToEnt(p)))
	}
	private _default: E

	public async getDefault(idioma: string = Idioma.DEFAULT_CODIGO): Promise<E> {
		return this._default
			? Promise.resolve(this._default)
			: this.getData().then((r) => {
					this._default = r.filter((d) => d.esDefault)[0]
					return this.parseToEnt(this._default)
			  })
	}
	public get default() {
		if (!this._default) this._default = this.getCache() ? this.getCache().filter((d) => d.esDefault)[0] : null
		return this._default
	}

	public habilitar(id: number, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL() + "habilitar", id))
			.then((r) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				this.novedadesService.check()
				const d = this.parseSingle(r)
				this.findAndUpdate(d, d !== undefined)
				return d
			})
			.catch((e) => this.handleError(e, customLoading))
	}

	public deshabilitar(id: number, customLoading?: LoadingService) {
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL() + "deshabilitar", id))
			.then(async (r) => {
				if (customLoading) customLoading.susLoadingCount()
				else this.loadingService.susLoadingCount()
				this.novedadesService.check()
				const d = await this.getById(id)
				d.habilitado = false
				this.findAndUpdate(d, d !== undefined)
				return d
			})
			.catch((e) => this.handleError(e, customLoading))
	}
	public override async eliminar(e: number, customLoading?: LoadingService, idioma: string = Idioma.DEFAULT_CODIGO) {
		return super.eliminar(e, customLoading).then((v) => {
			if (v["fueBorradoLogico"]) this.getByIdForced(e)
			else this.data.next(this.cacheService.eliminar(this.baseName(), idioma, e))
		})
	}
	public override destroy() {
		super.destroy()
		this.dataSus && this.dataSus.unsubscribe()
	}
}
