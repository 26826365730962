import { NgModule } from '@angular/core';
import { ImplementacionAppRoutingModule } from './app-routing.module';
@NgModule({
    imports: [
        ImplementacionAppRoutingModule
    ],
    exports: [

    ],
    providers: [

    ]
})
export class ImplementacionModule { }