
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { Caja } from './Caja';


export class CambioSaldoCaja {
	constructor(
		public id?: number,
		public fecha?: Date,
		public descripcion?: string,
		public responsable?: Descriptivo,
		public caja?: Descriptivo,
		public monto?: number,
		public justificacion?: string,
		public esRetiro?: boolean,
		public formaPago?: Descriptivo,

	) {
	}
	public static fromData(data: any): CambioSaldoCaja {
		if (!data) return null;
		const o: CambioSaldoCaja = new CambioSaldoCaja(
			data.id,
			data.fecha ? new Date(data.fecha) : null,
			data.descripcion,
			Descriptivo.fromData(data.responsable),
			Caja.fromData(data.caja),
			data.monto,
			data.justificacion,
			data.esRetiro,
			Descriptivo.fromData(data.formaPago),

		);
		return o;

	}

	public get tipoIcon() {
		return this.esRetiro ? "fa fa-sign-out" : "fa fa-sign-in";
	}

	public get tipoDesc() {
		return this.esRetiro ? "Retiro" : "Ingreso";
	}

}