import { Descriptivo } from "src/app/common/model/Descriptivo"
import { FormaPago } from "src/app/model/FormaPago"
import { Moneda } from "src/app/model/Moneda"
import { IValor } from "./../common/components/abm-cobros/IConCobros"
import { Auditable } from "./Auditable"
import { FileData } from "./FileData"
import { ListadoDocumentos } from "./ListadoDocumentos"

export class Pago extends Auditable implements IValor {
	constructor(
		public id?: number,
		public formaPago?: FormaPago,
		public caja?: Descriptivo,
		public monto?: number,
		public cotizacion?: number,
		public comprobante?: string,
		public moneda?: Moneda,
		public tipoCobro?: string,
		public adjunto?: FileData,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
		public version?: number,
		public tipoGasto: "V" | "P" = "P",
		public porcentajeGasto?: number,
		public importeGasto?: number,
		public incluyeGasto?: boolean,
		public listadoComprobantes?: ListadoDocumentos,
		public refCobranza?: number,
		public montoBase?: number
	) {
		super(lastModifiedBy, lastModifiedDate)
		if (!this.cotizacion) this.cotizacion = 1
	}
	public get cotizacionSafe() {
		return this.cotizacion || 1
	}
	public static fromData(data: any): Pago {
		if (!data) return null
		const o: Pago = new Pago(
			data.id,
			FormaPago.fromData(data.formaPago),
			Descriptivo.fromData(data.caja),
			data.monto,
			data.cotizacion,
			data.comprobante,
			Moneda.fromData(data.moneda),
			data.formaPago?.codigo == "CS" ? "S" : "A",
			FileData.fromData(data.adjunto),
			data.version,
			data.tipoGasto,
			data.porcentajeGasto,
			data.importeGasto,
			data.incluyeGasto
		)
		o.listadoComprobantes = ListadoDocumentos.fromData(data.listadoComprobantes)
		o.refCobranza = data.refCobranza
		o.montoBase = data.monto
		return o
	}
}
