import { PlantillaPreguntaEncuesta } from './../../../model/PlantillaPreguntaEncuesta';
import { PlantillaPregunta } from './../../../model/PlantillaPregunta'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ConfirmationService } from 'primeng/api'
import { Subscription } from 'rxjs'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { EncuestaActividades } from 'src/app/model/EncuestaActividades'
import { EncuestaActividadesService } from 'src/app/services/encuesta-actividades.service'
import { SessionComponent } from './../../../common/components/session-component.component'

import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { PlantillaPreguntaService } from 'src/app/services/plantilla-pregunta.service'
import { ProfilePic } from 'src/app/common/model/ProfilePic';
import { Idioma } from 'src/app/model/Idioma';

@Component({
    selector: 'gestor-encuesta-actividades',
    templateUrl: 'gestor-encuesta-actividades.component.html',
    styleUrls: ['gestor-encuesta-actividades.component.less'],
})
export class GestorEncuestaActividadesComponent extends SessionComponent implements OnDestroy, OnInit {
    private routeSub: Subscription
    @Input()
    public item: EncuestaActividades
    @Input()
    public goBack: boolean = true
    @Input()
    public isModal: boolean = false

    @Input()
    public readonly: boolean = false

    @Output()
    public onGuardado: EventEmitter<EncuestaActividades> = new EventEmitter<EncuestaActividades>()

    @Output()
    public onCancelado = new EventEmitter()

    public preguntas: Descriptivo[] = [] 

    public preguntasSeleccionadas: PlantillaPreguntaEncuesta[] = []
    startIndex: number;

    constructor(
        messagesService: MessagesService,
        public service: EncuestaActividadesService,
        private route: ActivatedRoute,
        private router: Router,
        private confService: ConfirmationService,
        private plantillaPreguntaService: PlantillaPreguntaService
    ) {
        super(messagesService)
    }

    public handleGuardado(item) {
        this.onGuardado.emit(item)
    }
    public handleCancelar(item) {
        this.onCancelado.emit()
    }

    public async isValid() {
        return !this.item.preguntas.some(p => !p.pregunta)
    }

    public onEdit(event) {
        if (this.item?.id) this.router.navigate(["'encuesta-actividades/edit"], { queryParams: { id: this.item.id } })
    }

    ngOnInit() {
        this.subs.push(
            this.route.data.subscribe((u) => {
                if (u?.vista) {
                    this.readonly = true
                }
            })
        )
        if (this.route.snapshot.url.some((u) => u.path == 'encuesta-actividades')) {
            this.routeSub = this.route.queryParams.subscribe((params) => {
                let id: number = <number>params['id']
                if (!this.service) return
                if (id) {
                    this.service.getById(id, null, this.translateService.idioma ? this.translateService.idioma : 'ES').then((r) => {
                        this.item = r
                        this.preguntasSeleccionadas = [...this.item.preguntas]
                        this.preguntasSeleccionadas.sort((a,b) => a.peso - b.peso)
                    })
                } else {
                    if (!this.item && this.service) {
                        this.item = this.service.newEnt()
                    }
                    this.item['codigoIdioma'] = this.translateService.idioma ? this.translateService.idioma : 'ES'
                }
            })
        }

        this.plantillaPreguntaService.getDescriptivos().then((r) => {
            this.preguntas = r
        })
    }

    public get esEditable() {
        return this.esAdministrador
    }

    ngOnDestroy() {
        if (this.routeSub) this.routeSub.unsubscribe()
    }

    public agregarPregunta() {
        // this.item.preguntas.push(new PlantillaPregunta(null, null))
        const nuevaPregunta = new PlantillaPreguntaEncuesta(null, null, this.item.preguntas.length);
        this.preguntasSeleccionadas.push(nuevaPregunta)
        this.item.preguntas.push(nuevaPregunta)

    }


	onSelectPregunta(event, index) {
		// this.preguntasSeleccionadas[index]={pregunta: event, peso: index}
        this.item.preguntas[index].pregunta = event
        this.item.preguntas[index].peso = index
	}


    onDragStart(index: number) {
        this.startIndex = index
    }

    onDrop(dropIndex: number) {
        let listado = this.preguntasSeleccionadas
        let peso1 = listado[dropIndex].peso
        let peso2 = listado[this.startIndex].peso

        if(peso1 == peso2){ peso2 = peso1 - 1};
        listado[dropIndex].peso = peso2
        listado[this.startIndex].peso = peso1

        listado =  listado.sort((a, b) => a.peso - b.peso)

        this.preguntasSeleccionadas = [...listado]

        const item1 = listado[dropIndex]
        const item2 = listado[this.startIndex]

        this.updateCampo(item1, 'peso')
        this.updateCampo(item2, 'peso')


    }

    updateCampo(item: PlantillaPreguntaEncuesta, campo: string) {
        const i = this.item.preguntas.findIndex((c) => c.pregunta.key == item.pregunta.key)

        if (i >= 0) {
            const i2 = this.item.preguntas.findIndex((i) => i.pregunta.key == item.pregunta.key)
            if (i2 >= 0) {
                this.item.preguntas[i2][campo] = item[campo]
            }
        }
    }

    reordenar(event, index: number, direccion: number) {
        event?.stopPropagation()
        if (index + direccion < 0) return

        let listado = this.preguntasSeleccionadas
        let peso1 = listado[index].peso
        let peso2 = listado[index + direccion].peso
        if(peso1 == peso2){peso2 = peso1 - direccion}
        listado[index].peso = peso2
        listado[index + direccion].peso = peso1

        listado.sort((a, b) => a.peso - b.peso)

        this.preguntasSeleccionadas = [...listado]

        const item1 = listado[index]
        const item2 = listado[index + direccion]

        this.updateCampo(item1, 'peso')
        this.updateCampo(item2, 'peso')
    }

    borrarPregunta(i: number) {
        this.preguntasSeleccionadas.splice(i, 1)
        this.item.preguntas.splice(i, 1)

    }
}
