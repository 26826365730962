import * as moment from "moment"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { EstadoPuesto } from "src/app/model/EstadoPuesto"
import { ItemResumenReserva } from "src/app/model/ItemReservaResumen"
import { ID } from "../app.module"
import { IActividad } from "./../pages/consentimiento-actividades/IActividad"
import { Auditable } from "./Auditable"
import { ConfirmacionReservaPuesto } from "./ConfirmacionReservaPuesto"
import { GrupoPaxResumen } from "./GrupoPaxResumen"
import { Idioma } from "./Idioma"
import { ItemReserva } from "./ItemReserva"
import { ItemReservaConsentimiento } from "./ItemReservaConsentimiento"
import { ProductoAsociadoReserva } from "./ProductoAsociadoReserva"
import { PuestoServicioReserva } from "./PuestoServicioReserva"
import { Restriccion } from "./Restriccion"

export class GrupoReserva extends Auditable implements IActividad {
	public cantidadPaxArray: number[] = []
	public estadoConfirmacion: Descriptivo = new Descriptivo("SD", "Sin definir")
	public actividadIniciada: boolean = false
	constructor(
		public id?: number,
		public cantidadPax?: number,
		public itemReserva?: ItemResumenReserva,
		public grupoResumen?: GrupoPaxResumen,
		public UUID?: string,
		public actividadCompletada: boolean = false,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date,
		public itemsConfirmacion: ConfirmacionReservaPuesto[] = [],
		public encuestaRespondida: boolean = false,
		public tieneEncuesta: boolean = false,
		public version?: number
	) {
		super(lastModifiedBy, lastModifiedDate)
		this.cantidadPaxArray
		if (!UUID) this.UUID = this.id ? this.id + "" : ID()

		this.estadoConfirmacion = this.calcularEstado()
		this.actividadIniciada = moment(grupoResumen?.fechaActividad).isSameOrBefore(new Date(), "date")
	}
	get colorVariante() {
		return this.itemReserva?.colorVariante || "black"
	}
	get descVariante() {
		return this.itemReserva?.variante?.descripcion || ""
	}
	puestoTercerizado?: PuestoServicioReserva

	getRestricciones(): Restriccion[] {
		return this.itemReserva?.restricciones || []
	}
	getProductoAsociados(): ProductoAsociadoReserva[] {
		return this.itemReserva?.productosAsociados || []
	}
	getDescripcion(): string {
		return this.itemReserva?.producto?.nombre || this.itemReserva?.producto?.descripcion
	}
	getItemBase(): ItemReservaConsentimiento {
		return ItemReservaConsentimiento.fromData(this.itemReserva)
	}
	getPuestos(): PuestoServicioReserva[] {
		return this.grupoResumen.puestos
	}
	getId() {
		return this.id
	}
	getVariantes(): { cantidad: number; variante: string }[] {
		return [{ cantidad: this.cantidadPax, variante: this.itemReserva?.producto?.textoVariante || "ADULTO" }]
	}
	getDetalle() {
		return this.itemReserva?.producto?.detalle || ""
	}
	getRequiereConsentimiento() {
		return this.itemReserva?.producto?.requiereConsentimiento
	}
	getReferenciaDireccion(): string {
		return this.itemReserva?.referenciaDireccion
	}
	get fechaActividad(): Date {
		return this.itemReserva?.fechaActividad || null
	}
	get tipoVoucher(): string {
		return "G"
	}
	public static fromData(data: any): GrupoReserva {
		if (!data) return null
		const o: GrupoReserva = new GrupoReserva(
			data.id,
			data.cantidadPax,
			ItemResumenReserva.fromData(data.itemReserva),
			GrupoPaxResumen.fromData(data.grupoResumen),
			data.UUID,
			data.actividadCompletada
		)
		o.itemsConfirmacion = data.itemsConfirmacion?.length ? data.itemsConfirmacion.map((p) => ConfirmacionReservaPuesto.fromData(p)) : []
		o.encuestaRespondida = data.encuestaRespondida
		o.tieneEncuesta = data.tieneEncuesta
		o.version = data.version
		return o
	}

	public tieneItem(item: ItemResumenReserva | ItemReserva) {
		return this.itemReserva && this.itemReserva.id == item.id
	}
	public quitarPax(cant: number) {
		this.cantidadPax = this.cantidadPax - cant
		if (this.cantidadPax < 0) this.cantidadPax = 0
	}
	public get idItemReserva(): number {
		return this.itemReserva ? (this.itemReserva.id ? this.itemReserva.id : -1) : null
	}

	public get descripcion(): string {
		return this.itemReserva?.descripcion || ""
	}

	public get idiomaServicio(): Idioma {
		return this.itemReserva?.idiomaServicio || null
	}
	public get direccion() {
		return this.itemReserva?.direccion
	}
	public get referenciaDireccion() {
		return this.itemReserva?.direccionAlternativa || this.itemReserva?.hotel?.descripcion
	}
	public updateItemReserva(item: ItemReserva) {
		this.itemReserva = ItemResumenReserva.fromData(item)
	}
	calcularEstado = () => {
		if (!this.itemsConfirmacion?.length) {
			return null
		} else {
			if (this.itemsConfirmacion.some((c) => c.estado.codigo == EstadoPuesto.RECHAZADO.codigo)) {
				return EstadoPuesto.RECHAZADO
			} else if (this.itemsConfirmacion.some((c) => c.estado.codigo == "PE" || c.estado.codigo == "PC")) {
				return EstadoPuesto.PENDIENTE_CONFIRMACION
			} else if (this.itemsConfirmacion.every((c) => c.estado.codigo == EstadoPuesto.CONFIRMADO.codigo)) {
				return EstadoPuesto.CONFIRMADO
			} else {
				return EstadoPuesto.SIN_DEFINIR
			}
		}
	}
	public getNombreReferencia() {
		return this.itemReserva?.nombrePax || ""
	}
	public getCantidadBasePaxs() {
		return this.itemReserva?.cantidadPax || 0
	}
}
