<div class="gestor-recordatorio content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'Recordatorio' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="RecordatorioForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
		[customGuardar]="guardar"
		[readonly]="readonly"
		#gestor
	>
		<div class="gestor-form" model-type="dataContent">
			<form #RecordatorioForm="ngForm" id="Recordatorioform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-2">
						<descriptivo-material-selector
							[service]="tipoRecordatorioService"
							[label]="'TIPO' | translate"
							[limpiable]="true"
							[(ngModel)]="item.tipo"
							[permiteNuevo]="false"
							name="{{ 'tipo' }}"
							[required]="true"
							[readonly]="readonly"
						>
						</descriptivo-material-selector>
					</div>
					<div class="col-12 lg:col-3 checkbox-container">
						<p-checkbox [binary]="true" [(ngModel)]="esFechaFija" name="esFechaFija" [label]="'ES_FECHA_FIJA' | translate"></p-checkbox>
					</div>
					<div class="col-6 lg:col-2 p-fluid mr-2" *ngIf="item && !item?.esFechaFija && item?.fechaBase">
						<span class="p-float-label field">
							<p-inputNumber
								[(ngModel)]="item.cantidad"
								mode="decimal"
								name="cantidad"
								#cantidad="ngModel"
								[min]="1"
								[disabled]="readonly"
								required
							>
							</p-inputNumber>
							<label>{{ "NOTIFICAR" | translate }}</label>
						</span>
					</div>

					<div class="col-12 lg:col-3 mt-2 d-flex justify-content-between align-items-baseline" *ngIf="item && !item?.esFechaFija && item?.fechaBase">
						<p-radioButton
							class="radio"
							name="group1"
							value="mm"
							label="Minutos"
							[(ngModel)]="item.unidad"
							inputId="preopt1"
							[disabled]="readonly"
						></p-radioButton>
						<p-radioButton
							class="radio"
							name="group1"
							value="h"
							label="Horas"
							[(ngModel)]="item.unidad"
							inputId="preopt2"
							[disabled]="readonly"
						></p-radioButton>
						<p-radioButton
							class="radio"
							name="group1"
							value="d"
							label="Días"
							[(ngModel)]="item.unidad"
							inputId="preopt3"
							[disabled]="readonly"
						></p-radioButton>
					</div>
					<div class="col-12" *ngIf="item && !item?.esFechaFija && item?.fechaBase">
						<span>{{ "SE_NOTIFICARA" | translate }} {{ fechaProgramada }}</span>
					</div>
					<div class="col-6 lg:col-3 p-fluid" *ngIf="item?.esFechaFija || !item?.fechaBase">
						<span class="p-float-label field">
							<p-calendar
								[(ngModel)]="item.fechaProgramada"
								name="fechaProgramada"
								dateFormat="dd/mm/yy"
								[showTime]="true"
								[stepMinute]="5"
								appendTo="body"
								[showIcon]="true"
								#fechaProgramada="ngModel"
								required
								[readonly]="readonly"
								[disabled]="readonly"
							></p-calendar>
							<label>{{ "FECHA_PROGRAMADA" | translate }}</label>
						</span>
					</div>

					<div class="col-12 lg:col-12 p-fluid">
						<span class="p-float-label field">
							<p-autoComplete
								[(ngModel)]="item.destinatarios"
								[readonly]="readonly"
								name="destinatarios"
								[suggestions]="posiblesDestinatarios"
								(completeMethod)="getDestinatarios($event)"
								[dropdown]="!readonly"
								[multiple]="true"
								[required]="true"
							>
								<ng-template let-item pTemplate="item">
									<div class="flex align-items-center">
										<span
											><i
												class="mr-2 fa"
												[ngClass]="{
													'fa-tag': item.tipo == 'Tag',
													'fa-user': item?.tipo == 'Usuario',
													'fa-at': item?.tipo == 'Email',
													'fa-users': item?.tipo == 'Todos'
												}"
											></i>
											{{ item.descripcion }}</span
										>
									</div>
								</ng-template>
								<ng-template let-item pTemplate="selectedItem">
									<span
										><i
											class="fa"
											[ngClass]="{
												'fa-tag': item.tipo == 'Tag',
												'fa-user': item?.tipo == 'Usuario',
												'fa-at': item?.tipo == 'Email',
												'fa-users': item?.tipo == 'Todos'
											}"
										></i>
										{{ item.descripcion }}</span
									>
								</ng-template>
							</p-autoComplete>
							<label>{{ "DESTINATARIOS" | translate }}</label>
						</span>
					</div>

					<div class="col-12 p-fluid">
						<span class="p-float-label field">
							<input type="text" [(ngModel)]="item.cabecera" pInputText [readonly]="readonly" name="cabecera_recordatorio" />
							<label>{{ "CABECERA_RECORDATORIO" | translate }}</label>
						</span>
					</div>
					<div class="col-12 p-fluid">
						<label>{{ "CUERPO_RECORDATORIO" | translate }}</label>
						<p-editor
							[(ngModel)]="item.body"
							name="cuerpoRecordatorio"
							tabindex="10"
							[disabled]="readonly"
							[readonly]="readonly"
							[style]="{ height: '100%', 'max-height': '40vh', 'min-height': '20vh', overflow: 'auto', 'background-color': 'white' }"
						>
						</p-editor>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
