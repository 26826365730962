import { MonedaService } from './../../../services/moneda.service';
import { CotizacionService } from './../../../services/cotizacion.service';
import { Moneda } from './../../../model/Moneda';
import { Router } from '@angular/router';
import { PuntoDeVentaService } from './../../../services/punto-de-venta.service';
import { ClienteService } from './../../../services/cliente.service';
import { CanalVentaService } from './../../../services/canal-venta.service';
import { EstadoReservaService } from './../../../services/estado-comprobante.service';
import { FilterService } from './../../../common/services/filter-service'
import { FiltroReserva } from './../../reserva/listado-reserva/FiltroReserva'
import { MessagesService } from 'src/app/common/services/messages-data-service.service'
import { Component, OnDestroy, OnInit, Input, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { SessionComponent } from 'src/app/common/components/session-component.component'
import { Filtro } from 'src/app/common/model/Filtro'
import { ColumnGenerator } from 'src/app/common/utils/ColumnGenerator'
import { MargenReporte } from 'src/app/model/MargenReporte'
import { MargenReporteService } from 'src/app/services/margen-reporte.service'
import { LoadingService } from 'src/app/common/services/loading-data-service.service'

@Component({
    selector: 'listado-margen-reporte',
    templateUrl: 'listado-margen-reporte.component.html',
    styleUrls: ['listado-margen-reporte.component.less'],
})
export class ListadoMargenReporteComponent extends SessionComponent implements OnInit, OnDestroy {
    public filtro: FiltroReserva

    public monedaVisualizacion: Moneda = Moneda.PESOS;
    public cotizaciones : any;
    @Input()
    public guardarFiltro: boolean = true
    @Input()
    public ocultarCabecera: boolean = false
    @Input()
    public editable: boolean = true
    public listado: MargenReporte[] = []
    public original: MargenReporte[] = []
    @Input()
    public readonly: boolean = false
    @Input()
    public modoSeleccion: 'single' | 'multiple' | 'none' = 'none'
    @ViewChild('reserva', { static: true })
    public reservaRef: TemplateRef<any>
    @Output()
    public onSelect: EventEmitter<MargenReporte[]> = new EventEmitter()

    public totales ={
      totalCobro:0,
      totalFacturado:0,
      totalVenta:0,
      totalGastos:0
    }

    public columns = []
    constructor(messagesService: MessagesService, 
      public service: MargenReporteService,
      public router:Router,
       public filterService: FilterService,
       public estadosService:EstadoReservaService,
       public canalVentaService:CanalVentaService,
       public monedaService:MonedaService,
       public cotizacionService:CotizacionService,
       public clienteService:ClienteService,
       public puntoVentaService: PuntoDeVentaService) {
        super(messagesService)
    }
    ngOnInit() {
        this.filtro = this.filterService.hasFilter('margen-reservas-listado') ? this.filterService.getFilter('margen-reservas-listado') : new FiltroReserva('margen-reservas-listado',30000)
        this.filtro.dataChange.subscribe((f) => {
            this.filterService.setFilter('margen-reservas-listado', f)
            if (f) {
                this.service.getAll(f).then(async (r) => {
                    this.original = r;
                   
                   
                      this.cotizaciones = {};
                      await this.updateCotizaciones();
                      this.updateValores();
                   
                })
            }
        })

        this.columns = [
            {
                field: 'fechaReserva',
                header: 'Fecha Reserva',
                class: 'date-tabla',
                role: 'date-tabla',
                sortable: false,
                order: 40,
            },
            {
              field: 'idReserva',
              header: 'Reserva',
              class: 'descriptivo-tabla text-center reserva',
              role: 'template',
              template: this.reservaRef,
              esMobile: true,
              order: 40,
          },
            {
                field: 'totalCobrado',
                header: 'Total Cobrado',
                class: 'money-tabla',
                role: 'money-tabla',
                
                monedaField:'monedaVisualizacion',
                sortable: false,
                order: 40,
            },
            {
                field: 'totalFacturado',
                header: 'Total Facturado',
                class: 'money-tabla',
                role: 'money-tabla',
                monedaField:'monedaVisualizacion',
                sortable: false,
                order: 40,
            },
            {
                field: 'totalVenta',
                header: 'Total Venta',
                class: 'money-tabla',
                role: 'money-tabla',
                
                monedaField:'monedaVisualizacion',
                sortable: false,
                order: 40,
            },
            {
                field: 'totalGastos',
                header: 'Total Gastos',
                class: 'money-tabla',
                role: 'money-tabla',
                
                monedaField:'monedaVisualizacion',
                sortable: false,
                order: 40,
            },
           
        ]
    }
    public async updateCotizaciones(moneda: Moneda = Moneda.PESOS){
        const monedas = await this.monedaService.getHabilitados();
        monedas.forEach(async (m:Moneda) =>{
           this.cotizaciones[m.codigo] = (await this.cotizacionService.getCotizacion(moneda,m)).valor;
        })
    }
    public async cambioMoneda(moneda:Moneda){
        await this.updateCotizaciones(moneda);
        this.updateValores();
    }

    public updateValores(){
        this.listado = this.original.map(d => MargenReporte.fromData(d))
        this.totales = {
            totalCobro:0,
            totalFacturado:0,
            totalVenta:0,
            totalGastos:0
          }
        this.listado.forEach( async d =>{
            d.totalCobrado = this.getValor(d.totalCobrado,Moneda.PESOS);
            d.totalVenta = this.getValor(d.totalVenta, d.monedaReserva);
            d.totalFacturado = this.getValor(d.totalFacturado,Moneda.PESOS);
            d.totalGastos = this.getValor(d.totalGastos ,Moneda.PESOS);
            this.totales.totalCobro +=d.totalCobrado;
            this.totales.totalFacturado +=d.totalFacturado;
            this.totales.totalGastos +=d.totalGastos;
            this.totales.totalVenta +=d.totalVenta;
            d['monedaVisualizacion'] = this.monedaVisualizacion;
        })
        this.listado = [...this.listado];
    }
    public updateSeleccion(seleccion: any) {
        if (seleccion?.length || seleccion['id']) {
            this.onSelect.emit(seleccion?.length ? seleccion : [seleccion])
        }
    }
    ngOnDestroy() {}
    public verReserva(idReserva: number) {
      this.router.navigate(['reserva/vista'], { queryParams: { id: idReserva } })
  }
  public getValor(valor: number, moneda: Moneda = Moneda.PESOS) {
    return moneda?.codigo != this.monedaVisualizacion?.codigo ? valor * this.cotizaciones[moneda.codigo] : valor
}
  
}
