<div class="gestor-tipo-notificacion content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'TipoNotificacion' | translate"
		[verAcciones]="true"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		[editable]="esEditable"
		(onCancelar)="handleCancelar($event)"
		[form]="TipoNotificacionForm"
		[multilenguaje]="false"
		(onEditar)="onEdit($event)"
		[baseName]="'tipo-notificacion'"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #TipoNotificacionForm="ngForm" id="TipoNotificacionform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-2">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'CODIGO_PH' | translate }}"
								[(ngModel)]="item.codigo"
								tooltipPosition="bottom"
								pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
								type="$TYPE"
								#codigo="ngModel"
								name="codigo"
								[readonly]="readonly"
							/>
							<label>{{ "CODIGO" | translate }}</label>
						</span>
						<error-tag [model]="codigo"></error-tag>
					</div>

					<div class="col-12 lg:col-6">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="$TYPE"
								#descripcion="ngModel"
								name="descripcion"
								[readonly]="readonly"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>
					<div class="col-12 lg:col-4">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'PESO_PH' | translate }}"
								[(ngModel)]="item.peso"
								tooltipPosition="bottom"
								pTooltip="{{ 'PESO_TOOLTIP' | translate }}"
								type="number"
								#peso="ngModel"
								name="peso"
								[readonly]="readonly"
							/>
							<label>{{ "PESO" | translate }}</label>
						</span>
						<error-tag [model]="peso"></error-tag>
					</div>
					<div class="col-12 lg:col-12">
						<selector-multiple-chips
							[titulo]="'CANALES' | translate"
							[permiteNuevo]="false"
							[opciones]="canalComunicacionOptions"
							[(ngModel)]="item.canales"
							name="canales"
							[readonly]="readonly"
						>
						</selector-multiple-chips>
					</div>

					<div class="col-12">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DESTINATARIO_DEFAULT' | translate }}"
								[(ngModel)]="item.destinatarioDefault"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESTINATARIO_DEFAULT' | translate }}"
								type="mail"
								#destinatarioDefault="ngModel"
								name="destinatarioDefault"
								[readonly]="readonly"
							/>
							<label>{{ "DESTINATARIO_DEFAULT" | translate }}</label>
						</span>
						<error-tag [model]="destinatarioDefault"></error-tag>
					</div>
					<div class="col-12">
						<div class="grid">
							<div class="col-12 lg:col-4 checkbox-container">
								<p-toggleButton
									onLabel="{{ 'Modo prueba ON' | translate }}"
									offLabel="{{ 'Modo prueba OFF' | translate }}"
									iconPos="left"
									name="modoTest"
									onIcon="{{ 'fa fa-check-square-o' }} "
									offIcon="{{ 'fa fa-square-o' }} "
									[(ngModel)]="item.modoTest"
									[disabled]="readonly"
								></p-toggleButton>
							</div>
							<div class="col-12 lg:col-8 checkbox-container" *ngIf="item.modoTest">
								<span class="p-float-label">
									<input
										pInputText
										placeholder="{{ 'Destinatario modo prueba' | translate }}"
										[(ngModel)]="item.destinatarioTest"
										tooltipPosition="bottom"
										pTooltip="{{ 'A este mail llegaran las notificaciones en modo prueba' | translate }}"
										type="mail"
										#destinatarioTest="ngModel"
										name="destinatarioDefault"
										[readonly]="readonly"
									/>
									<label>{{ "Destinatario modo prueba" | translate }}</label>
								</span>
								<error-tag [model]="destinatarioTest"></error-tag>
							</div>
						</div>
					</div>
					<div class="col-12 lg:col-3 checkbox-container">
						<p-toggleButton
							onLabel="{{ 'HABILITADO' | translate }}"
							offLabel="{{ 'HABILITADO' | translate }}"
							iconPos="left"
							name="habilitado"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[(ngModel)]="item.habilitado"
							[disabled]="readonly"
						></p-toggleButton>
					</div>

					<div class="col-12 lg:col-3 checkbox-container">
						<p-toggleButton
							onLabel="{{ 'Es recordatorio Pax' | translate }}"
							offLabel="{{ 'Es recordatorio Pax' | translate }}"
							iconPos="left"
							name="esRecordatorioPax"
							onIcon="{{ 'fa fa-check-square-o' }} "
							offIcon="{{ 'fa fa-square-o' }} "
							[(ngModel)]="item.esRecordatorioPax"
							[disabled]="readonly"
						></p-toggleButton>
					</div>
					<!-- <div class="col-12 lg:col-3 checkbox-container">
                        <p-toggleButton
                            onLabel="{{ 'ESSISTEMA' | translate }}"
                            offLabel="{{ 'ESSISTEMA' | translate }}"
                            iconPos="left"
                            name="esSistema"
                            onIcon="{{ 'fa fa-check-square-o' }} "
                            offIcon="{{ 'fa fa-square-o' }} "
                            [(ngModel)]="item.esSistema"
                        ></p-toggleButton>
                    </div> -->

					<!-- <div class="col-12 lg:col-3 checkbox-container">
                        <p-toggleButton
                            onLabel="{{ 'ESDEFAULT' | translate }}"
                            offLabel="{{ 'ESDEFAULT' | translate }}"
                            iconPos="left"
                            name="esDefault"
                            onIcon="{{ 'fa fa-check-square-o' }} "
                            offIcon="{{ 'fa fa-square-o' }} "
                            [(ngModel)]="item.esDefault"
                        ></p-toggleButton>
                    </div> -->
				</div>
			</form>
		</div>
	</model-gestor>
</div>
