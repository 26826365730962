<div class="grid content card">
    <div class="col-12">
        <div class="grid grid-nogutter align-content-center">
            <i class="delete-button fa fa-trash" (click)="eliminar($event)"> </i>
            <div class="col-12 text-center titulo">
                <strong style="display: block" *ngIf="item?.producto">{{ item.producto.descripcion }}</strong>
            </div>
            <div class="col-2 icon-column">
                <i class="fa fa-user-o" tooltipPosition="bottom" pTooltip="{{ 'PASAJEROS' | translate }}"></i>
            </div>
            <div class="col-5">
                <div class="grid grid-nogutter">
                    <div class="col-12 text-center">
                        <span>{{ 'TOTAL' | translate }} </span>
                    </div>
                    <div class="col-12 text-center">
                        <strong class="valor"> {{ item?.cantidadPax }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-5">
                <div class="grid grid-nogutter" tooltipPosition="bottom" pTooltip="{{ 'PROMEDIO_PASAJEROS_VEHICULO' | translate }}">
                    <div class="col-12 text-center">
                        <span>{{ 'PROMEDIO' | translate }} </span>
                    </div>
                    <div class="col-12 text-center">
                        <strong class="valor"> {{ item?.promedioPax | number: '.0-2' }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-2 icon-column">
                <i class="fa fa-car" tooltipPosition="bottom" pTooltip="{{ 'VEHICULOS' | translate }}"></i>
            </div>
            <div class="col-5">
                <div class="grid grid-nogutter">
                    <div class="col-12 text-center">
                        <span>{{ 'TOTAL' | translate }} </span>
                    </div>
                    <div class="col-12 text-center">
                        <strong class="valor"> {{ item?.cantidadVehiculos }}</strong>
                    </div>
                </div>
            </div>

            <div class="col-5">
                <div class="grid grid-nogutter" tooltipPosition="bottom" pTooltip="{{ 'PROMEDIO_VEHICULOS_DIA' | translate }}">
                    <div class="col-12 text-center">
                        <span>{{ 'PROMEDIO' | translate }} </span>
                    </div>
                    <div class="col-12 text-center">
                        <strong class="valor"> {{ item?.promedioVehiculos | number: '.0-2' }}</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
