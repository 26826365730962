<model-help [title]="'Listado y Gestión de Reservas'">
	<h3>Listado de reservas</h3>
	<img src="assets/images/help/reserva-lista.jpg" style="max-height: 195px" /><br />

	<strong>En la cabecera de la página de listado</strong>, se encuentra el botón para agregar una nueva reserva <i class="pi pi-plus"></i> y a continuación la
	barra de búsqueda (permite buscar por número de reserva, nombre del cliente y por nombre del PAX) y filtro <i class="pi pi-filter"></i>. <br />
	En la línea siguiente se encuentra un resumen de los filtros aplicados y el ícono para borrar estos filtros <i class="pi pi-trash"></i> y obtener el listado
	completo.<br />
	<h4>Filtros en listado</h4>
	<div class="grid">
		<div class="col-12 md:col-6">
			<p>
				Esta ventana emergente de filtros se puede encontrar en diferentes listados a lo largo de todo el sistema y varía según el entorno.<br />
				En este caso en particular se dispone de las siguientes opciones para encontrar la reserva requerida.
			</p>
			<ul>
				<li><help-link [linkFinal]="'punto-de-venta'" [textoLink]="'Punto de Venta'"></help-link></li>
				<li><help-link [linkFinal]="'cliente'" [textoLink]="'Cliente'"></help-link></li>
				<li><help-link [linkFinal]="'canal-de-venta'" [textoLink]="'Canal de venta'"></help-link></li>
				<li><help-link [linkFinal]="'vendedor'" [textoLink]="'Vendedor'"></help-link></li>
				<li>Rango de fechas del registro de misma.</li>
				<li>Rango de fechas de vencimiento de la reserva.</li>
				<li>
					Estados:
					<ul>
						<li>Aprobado: Estado por defecto al crear una nueva reserva. (que no sea una cotización)</li>
						<li>
							Cerrada: Cuando las actividades de una reserva ya fueron realizadas y canceladas en términos de pagos se puede optar por cambiar el
							estado a "cerrada" de forma manual, para evitar que se pueda modificar dicha reserva y generar las comisiones a los
							<help-link [linkFinal]="'vendedor'" [textoLink]="'vendedores'"></help-link>
						</li>
						<li>Cotización: Que no es reserva, solo un presupuesto / cotización de la misma.</li>
						<li>Cancelada</li>
						<li>Finalizada: Sus actividades ya fueron realizadas, pero aún falta el pago de las mismas.</li>
						<li>Solo con saldo: Que falta cobrar un saldo parcial o el total.</li>
						<li>
							Sin comprobantes: Aún no se emitió ningún <help-link [linkFinal]="'comprobante'" [textoLink]="'comprobante'"></help-link> referido a
							la reserva.
						</li>
						<li>Solo Cotizaciones.</li>
					</ul>
				</li>
			</ul>
		</div>
		<div class="col-12 md:col-6">
			<img src="assets/images/help/filtro-2.jpg" style="max-height: 480px" />
		</div>
	</div>

	<h4>Columnas del listado</h4>
	<ul>
		<li>
			<strong>Estado:</strong> se define por los colores establecidos en
			<help-link [linkFinal]="'estado-reserva'" [textoLink]="'estados de reserva'"></help-link>.<br />
			Los estados por defecto en sistema son:<br /><br />
			<ul>
				<li>Aprobado: Estado por defecto al crear una nueva reserva. (que no sea una cotización)</li>
				<li>
					Cerrada: Cuando las actividades de una reserva ya fueron realizadas y canceladas en términos de pagos se puede optar por cambiar el estado a
					"cerrada" de forma manual, para evitar que se pueda modificar dicha reserva y generar las comisiones a los
					<help-link [linkFinal]="'vendedor'" [textoLink]="'vendedores'"></help-link>
				</li>
				<li>Cotización: Que no es reserva, solo un presupuesto / cotización de la misma.</li>
				<li>Cancelada: La reserva no se llevó a cabo.</li>
				<li>Finalizada: Cuando las actividades de la reserva ya fueron realizadas en su totalidad.</li>
			</ul>
			<br />
			(El campo de estado también posee un check <i class="fa fa-square-o"></i> para seleccionar la reserva y de esa manera poder llevar a cabo acciones
			sobre ellas.)<br /><br />
		</li>

		<li><strong>File:</strong> Número único identificador de la reserva.</li>
		<li>
			<strong>Cliente:</strong> Este campo incluye 2 líneas, la primera es el nombre del PAX y la cantidad de pasajeros del grupo; y la segunda es el
			nombre del cliente que incluye un link que al clickear abre el detalle completo de dicho
			<help-link [linkFinal]="'cliente'" [textoLink]="'cliente'"></help-link>.
		</li>
		<li>
			<strong>Icono de estado de</strong> <help-link [linkFinal]="'consentimiento'" [textoLink]="'consentimiento'"></help-link>: tiene tres valores
			posibles:<br /><br />
			<ul>
				<li><i class="fa fa-exclamation" style="color: #ff991e"></i> Pendiente de envío.</li>
				<li><i class="fa fa-envelope" style="color: #03a9f4"></i> Pendiente de respuesta.</li>
				<li><i class="fa fa-check" style="color: green"></i> Completado.</li>
			</ul>
			<br />
		</li>
		<li><strong>Canal: </strong> <help-link [linkFinal]="''" [textoLink]="'Canal de venta'"></help-link></li>
		<li>
			<strong>Fecha vencimiento:</strong> Este campo tiene 2 líneas; la primera es la fecha de vencimiento, que es la fecha donde se espera que la reserva
			se encuentre finalizada y cancelados todos los pagos correspondientes (por defecto la fecha de la última actividad que contiene la reserva); y fecha
			de registro, fecha cuando se cargó la reserva.
		</li>
		<li>
			<help-link [linkFinal]="'comprobante'" [textoLink]="'Comprobante'"></help-link>: Si ya se emitió un comprobante aparecerá el número correspondiente
			con el link que llevará al detalle.
		</li>
		<li><strong>Monto:</strong> monto total de la reserva en la moneda correspondiente.</li>
		<li><strong>Saldo:</strong> Muestra el saldo pendiente a pagar por parte del cliente.</li>
		<li>
			<strong>Acciones:</strong> Desde el ícono <i class="fa fa-cogs"></i> estarán disponibles las acciones para realizar sobre varias reservas a la vez
			(<i class="fa fa-square-o"></i> seleccionadas desde la primera columna mediante el check) <br />
			y desde el ícono <i class="fa fa-cog"></i> ubicado en la última columna en cada línea, estarán disponibles las acciones a realizar sobre una reserva
			en particular.
		</li>
		<li>
			<strong>Icono de descarga </strong><i class="pi pi-file-excel"></i>: Descarga el listado de reservas en formato csv compatible con excel, o
			diferentes programas de hojas de cálculo)
		</li>
		<br />
		<p>
			<strong
				>* Las acciones que estarán disponibles para realizar en varias reservas simultáneamente y también para realizarse sobre una única reserva
				son:</strong
			>
		</p>
		<ul>
			<li>Editar: editar una reserva que aún no ha sido cerrada.</li>
			<li>Cerrar reserva: bloquear cualquier tipo de modificación en la misma. (se puede revertir el estado con Abrir reserva nuevamente).</li>
			<li>Enviar itinerario: enviar un resumen del itinerario al los pasajeros.</li>
			<li>Ver márgenes: En desarrollo.</li>
		</ul>
		<p><strong>* Y las siguientes solo podrán realizarse sobre una única reserva a la vez:</strong></p>
		<ul>
			<li>
				Cancelar: Cancela todas las reservas y los comprobantes relacionados (si los hubiere), dando la opción en una pantalla emergente de realizar o
				no una devolución.
			</li>
			<li>
				Reenviar comprobante: Permite hacer un reenvío de comprobante relacionado a la reserva(si lo hubiese), dando la opción en una pantalla emergente
				de definir un correo electrónico de destino.
			</li>
			<li>Generar cobro: Genera una nueva <help-link [linkFinal]="'cobranza'" [textoLink]="'cobranza'"></help-link> al cliente correspondiente.</li>
			<li>Descargar: descarga el comprobante relacionado. (esta opción solo aparece si hay un comprobante generado.)</li>
			<li>Ver pdf: Permite visualizar el comprobante relacionado. (esta opción solo aparece si hay un comprobante generado.)</li>
			<li>Ver Liquidación: Permite visualizar la liquidación de la reserva.</li>
			<li>Descargar Liquidación: Permite descargar la liquidación de la reserva.</li>
			<li>Notificar proveedores</li>
			<li>
				Generar comprobante: Dará la opción al usuario de crear un comprobante por el saldo total de la reserva. Si ya contaba con adelantos cargados no
				oficiales, dará la opción de reemplazarlos y agruparlos en un comprobante único.
			</li>
			<li>Auditar: Permite conocer la fecha de última modificación de la reserva y el responsable.</li>
		</ul>
	</ul>

	<h3>Gestión de reservas</h3>
	<p>Esta pantalla sirve para crear nuevas reservas, editar las mismas y también crear cotizaciones de servicios, productos y programas.</p>
	<h4>Datos de la reserva</h4>
	<p>
		<strong>Cotización:</strong> Al seleccionar el check correspondiente <i class="pi pi-check"></i>, se creará una cotización, es decir que no se generará
		una reserva real.
	</p>
	<p><strong>Campos destacados: </strong></p>
	<ul>
		<li>Fecha de registro: es la fecha en la cual se registra la reserva.</li>
		<li>Fecha de vencimiento: es la fecha en la que tiene lugar la primera actividad de la reserva.</li>
		<li>Nombre del pax: Es el nombre del pasajero de referencia del grupo de pasajeros (en caso de ser más de uno).</li>
		<li>Cantidad: cantidad de pasajeros totales del grupo.</li>
		<li>Nacionalidad: Este campo es importante para el perfíl de impuestos que se aplicará en una posterior facturación del servicio o producto.</li>
		<li>
			Hotel: Este es el lugar por donde se hará el pickup del pax. Incluye la opción de dirección particular que permitirá ingresar manualmente un punto
			de pickup. Este hotel se designa para toda la reserva y además en cada servicio puede establecerse un hotel diferente, en caso de modificar el hotel
			a nivel de reserva, todos los servicios que tengan el mismo hotel a nivel de servicio serán modificados, no así los servicios que tengan otros
			hoteles asignados.
		</li>
		<li>Observaciones: Estas observaciones son internas o sea no serán visibles para el cliente.</li>
		<li>Notas clientes: Estas notas serán visibles para el cliente.</li>
	</ul>
	<h4>Servicios, programas y productos.</h4>
	<p>
		Una vez completados los datos requeridos en <mark>Datos de la reserva</mark> se habilitará el signo <i class="pi pi-plus"></i> para agregar programas,
		servicios y productos a la reserva mediante la siguiente pantalla emergente.
	</p>
	<img src="assets/images/help/agregar-servicio.jpg" /><br />
	<p>
		El primer campo disponible es el de servicio, una vez selecciónado el mismo, aparecerán todos los campos necesarios a completar, y también si los
		hubiese, productos asociados cargados con anterioridad en dicho <help-link [linkFinal]="'producto'" [textoLink]="'servicio'"></help-link>.
	</p>
	<p><strong>Campos destacados:</strong></p>
	<ul>
		<li>Ajuste: En el caso de necesitar hacer un descuento o recargo con respecto al precio (expresado en porcentaje).</li>
		<li>
			Comisión: Comisión al vendedor (expresado en porcentaje.); este valor podrá ser modificado posteriormente hasta que la reserva se encuentre en
			estado "cerrada".
		</li>
		<li>Restricciones: Si las hubiera, restricciones alimentarias, otras.</li>
	</ul>
	<p>
		En la parte derecha de esta pantalla podremos ver, eliminar o agregar productos relacionados. <br />
		Y también especificar un precio y cantidad por pasajero.
	</p>

	<strong>Disponibilidad: </strong> En esta área de la pantalla podremos asignar a los pasajeros a uno o más grupos y vehículos para realizar la actividad.
	<p>
		Con el botón de "Autoasignar" se podrá realizar de una manera rápida la selección de ubicaciónes en grupo / vehículo para los pasajeros. Los criterios
		que se tendrán encuenta son que el grupo tenga diponibilidad y tenga el mismo servicio o familia de servicio.<br />
		En caso de no existir correspondencia con los criterios anteriores, se procederá a asignar la reserva al primer vehiculo que cumpla con los requisitos
		que no tenga grupos asignados aún.
	</p>
	<p>
		Si se desea asignar manualmente a los pasajeros se puede arrastrar el grupo completo (desde el nombre del PAX) o pasajero por pasajero desde los íconos
		de <i class="fa fa-user"></i> (Ejemplo: en el caso que dos vehículos tenga su capacidad limitada a 2 lugares cada uno y tenemos 4 pasajeros en la
		reserva, podremos, utilizadondo la asignacion pax a pax, ubicar 2 en un grupo / vehículo y otros dos en el otro.)<br />

		Es importante destacar que de la misma manera se puede dividir un grupo en más de un vehículo, arrastrandolos uno a uno desde el ícono mencionado.
	</p>
	<p>
		Area de cambio de fecha: Para cambiar de fecha a un grupo ya asignado a un vehículo (que puede contener varias reservas), se deberá arrastrar hasta este
		area el grupo completo. Esto nos permite cambiar la fecha sin necesidad de tener que modificar las diferentes reservas que lo componen ni cancelar
		gastos o asignaciones ya definidas. (Ejemplo de uso: Cuando el vehículo en cuestión tiene un desperfecto y es necesario reprogramar la actividad de ese
		grupo completo para el día siguiente.)
	</p>
	<br />
	<p><strong>Panel de vehículos / horario.</strong></p>
	<p>
		En la primera columna del panel se encuentran listados los vehículos disponibles para la reserva del servicio. Se observará la siguiente información
		descriptiva del mismo:
	</p>
	<img src="assets/images/help/autito.jpg" /><br />
	<ul>
		<li>Foto</li>
		<li>Capacidad Máxima: Indicada por el número junto al ícono <i class="fa fa-users"></i></li>
		<li>4x4: Si es un <help-link [linkFinal]="'vehiculo'" [textoLink]="'vehículo'"></help-link> marcado como 4x4 aparecera la inscripción.</li>
		<li>Identificación del vehículo: Patente u otro según corresponda.</li>
		<br />
	</ul>
	<p>
		Debajo de los vehículos disponibles (propios o administrados) se pueden agregar vehículos alquilados y a continuación las delegadaciones a otro
		prestador.
	</p>
	<br />
	<p>En la Fila superior del panel encontramos las divisiones por hora (0 a 24) seccionadas cada media hora.</p>
	<img src="assets/images/help/horas.jpg" /><br />
	<p>
		En las interseccion de vehículos y horas se encontrarán las reservas ya existentes para el servicio a las cuales se pueden agregar pasajeros; con la
		siguiente información:
	</p>

	<img src="assets/images/help/card-actividades1.jpg" /><br />

	<p>
		Si no se visualiza el detalle completo como en la imagen de ejemplo, es posible que el espacio en pantalla no lo permita, en ese caso al hacer click en
		el recuadro de la actividad se abrirá una pantalla emergente que contendrá la información completa.
	</p>

	<p>
		Al asignar pasajeros a la línea de tiempo y vehículo, se podrá agregar pasajeros a vehículos que aún tengan capacidad disponible o se prodrá crear un
		nuevo grupo visualizado como una card que contiene la info de la reserva.<br />
	</p>
	<p><strong>Referencia íconos en la card visual: </strong></p>
	<ul>
		<li>Nombre PAX referente del grupo.</li>
		<li>Cantidad de PAX representados por el ícono <i class="fa fa-user"></i></li>
		<li>Hora pickup: <i class="fa fa-clock"></i> Hora de retiro de los pasajeros si lo hubiera en hotel o punto de encuentro.</li>
		<li><help-link [linkFinal]="'idioma'" [textoLink]="'Idioma'"></help-link> de la reserva: Indicado con imagen de Bandera.</li>
		<li>
			Nombre Hotel: <i class="fa fa-building"></i> Hotel por el cual se debe hacer el pickup del PAX (TIP: si se pasa el mouse por encima aparece la
			dirección del hotel).
		</li>
	</ul>
	<br />
	<p>
		<strong>Delegar: </strong>En caso de necesitar tercerizar completamente el servicio se puede delegar haciendo click en la card (se accede al detalle del
		grupo) y se selecciona el botón correspondiente que abre una pantalla para seleccionar: Tipo de puesto a delegar, la opción para elegir el proveedor y
		los costos asociados a la delegación.
	</p>

	<p>
		Se podrán agregar varios servicios por reserva, una vez agregados todos los solicitados se podrá realizar un ajuste de precio del total, complentando
		con un porcentaje el campo "ajuste".
	</p>
	<p>
		<strong>Guardar y generar comprobante:</strong> Este botón nos permite abrir la pantalla emergente de
		<help-link [linkFinal]="'comprobante'" [textoLink]="'Comprobantes'"></help-link> en la misma acción de guardar para acelerar el proceso de facturación
		de la misma.
	</p>

	<h4>Programas</h4>
	<img src="assets/images/help/programas.jpg" /><br />
	<p>
		Los programas son un tipo especial de producto que contienen varios servicios que transcurren en varios días consecutivos.<br />
		Al elegír un programa, y agregarlo a la reserva, se incorporarán todos los servicios y productos que incluya el mismo.<br />
		Se podrán asignar roles y vehículos en forma general para todo el programa (siempre que hayan sido configurado previamente)<br /><br />
		Ejemplo: En el programa se determino que se necesita un vehiculo 4x4 para todas las actividades de varios dias, entonces al insertar el programa en la
		reserva, podremos seleccionar un vehículo 4x4 que se asignará a todos los servicios que contenga el programa que requieran de ese vehículo, siempre y
		cuando ese vehículo tenga disponibilidad en cada uno de esos dias y horarios. El sistema hará una autoasignación total o parcial (parcial en el caso de
		que en algunos dias y horarios si esté disponible el vehículo y en otros no).<br />
		Podremos diferenciar en que días / actividades están los puestos y el vehículo asignados, y en los que no, por el color general de la cabecera del día /
		actividad.
	</p>

	<h4>Edición de reservas</h4>
	<p>Si ingresamos en la pantalla de gestor de reserva en modo de edición, tendremos algunas funciones adicionales disponibles.</p>
	<p><strong>Historial de comprobantes:</strong> Este botón permite ver todos los comprobantes asociados a la reserva (si los hubiese).</p>
	<p><strong>Acciones:</strong> Despliega las acciones que también están disponibles en el listado de reservas.</p>
	<p>
		<strong>Vauchers:</strong> mediante el botón <i class="fa fa-qrcode"></i>que se encuentra en cada servicio se podrá acceder al vaucher de pasajero
		correspondiente.
	</p>
	<strong
		>Para reservas que ya tengan comprobantes emitidos, si se cambia el precio de un servicio en la reserva o se hacen descuentos posteriores o cancelación
		parcial de la reserva (se cancela un servicio pero aún quedan otros activos) se generará una nota de crédito por el total de los comprobantes y un nuevo
		comprobante con los valores actualizados. En caso de existir diferencias se compensará con nota de débito o devolución.</strong
	>

	<h4>Adelantos</h4>
	<p>
		Cuando se requiera generar un comprobante que no sea por el total de la reserva (Ejemplo: Se requiere un pago parcial en concepto de seña) se puede
		recurrir al adelanto.<br />Desde el listado de reservas desde el ícono de <i class="pi pi-cog"></i> <br />
		seleccionando la opcion de correspondiente se abrirá la pantalla emergente para realizar el mismo.
	</p>
	<p>El adelanto será descontado del monto total de la reserva y del comprobante final a emitir.</p>

	<h4>Cancelación de servicios y/o reservas</h4>
	<p>
		Se pueden cancelar servicios pero mantener la reserva si existe al menos un servicio activo.<br />
		En caso de querer cancelar la reserva completa se deberá hacer desde el menú de Acciones -> Cancelar<br />
		Al Cancelar un servicio se deberá elegir un motivo de cancelación o crear uno nuevo <i class="pi pi-plus"></i> y esto plantea varios posibles
		escenarios:
	</p>
	<p>
		<strong>La reserva no tenia ningún comprobante asociado:</strong> en este caso, la reserva se cancela inmediatamente.<br />
		<strong>La reserva tiene uno o más comprobantes asociados pero no se efectuó cobranza:</strong> En este caso se cancelan los comprobantes asociados de
		forma total.<br />
		<strong>La reserva tiene uno o más comprobantes asociados y cobranza, y no se hace devolución: </strong> Se cancela él o los comprobantes y se realiza
		una nota de débito por el mismo monto en concepto de gastos de cancelación. (ejemplo: cuando se abonó una seña y no se devolverá la misma al cliente.)
		<br />
		<strong>La reserva tiene uno o más comprobantes asociados y cobranza, y se realiza una devolución total: </strong> Se cancela él o los comprobantes y se
		genera una nota de crédito a favor del cliente.<br />
		<strong>La reserva tiene uno o más comprobantes asociados y se realiza una devolución parcial:</strong> Se cancela él o los comprobantes y se genera
		nota de crédito a favor del cliente y nota de débito por la diferencia.<br />
	</p>
	<h4>Recordatorios</h4>
	<p>ver <a href="#/help/recordatorio">Recordatorios</a></p>
</model-help>
