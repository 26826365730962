import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { TraspasoDeCaja } from 'src/app/model/TraspasoDeCaja';
import { Filtro } from '../common/model/Filtro';
import { FiltroMovimiento } from '../common/model/FiltroMovimiento';
import { LoadingService } from '../common/services/loading-data-service.service';
@Injectable({
    providedIn: 'root',
})
export class TraspasoDeCajaService extends ServicioAbstract<TraspasoDeCaja>{



    public baseName(): string {
        return "traspaso-de-caja";
    }
    public parseToEnt(data: any): TraspasoDeCaja {
        return TraspasoDeCaja.fromData(data);
    }
    public newEnt(): TraspasoDeCaja {
        return new TraspasoDeCaja();
    }

    getAllPendientes(filtro: FiltroMovimiento = new FiltroMovimiento('dummy'), customLoading?: LoadingService): Promise<TraspasoDeCaja[]> {
        let $this = this;
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return this.http.post(this.getBaseURL('pendientes'), filtro.json).pipe(share())
            .toPromise()
            .then(r => {
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
                return $this.parse(r)
            }, (e) => this.handleError(e, customLoading));
        }

        aprobar(item: TraspasoDeCaja, customLoading?: LoadingService) {
            if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
    
                return this.http.put(this.getBaseURL() + 'aprobar', item)
                    .toPromise()
                    .then((r) => {
                        if (customLoading) { customLoading.susLoadingCount(); }
                        else this.loadingService.susLoadingCount();
                        return this.parseSingle(r)
                    }, (e) => this.handleError(e, customLoading));
    
        }


        rechazar(item: TraspasoDeCaja, customLoading?: LoadingService) {
            if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
    
                return this.http.put(this.getBaseURL() + 'rechazar', item)
                    .toPromise()
                    .then((r) => {
                        if (customLoading) { customLoading.susLoadingCount(); }
                        else this.loadingService.susLoadingCount();
                        return this.parseSingle(r)
                    }, (e) => this.handleError(e, customLoading));
    
        }
}