import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { OverlayPanel } from "primeng/overlaypanel"
import { AuthService } from "src/app/services/auth.service"
import { ConfiguracionUsuarioService } from "src/app/services/configuracion-usuario.service"
import { MessagesService } from "../../services/messages-data-service.service"
import { TranslateService } from "../../services/translate.service"
import { Filtro } from "./../../model/Filtro"

@Component({
	selector: "filtro",
	templateUrl: "filtro.component.html",
	styleUrls: ["filtro.component.less"]
})
export class FiltroCompoenent implements OnInit {
	@Input()
	public isMinimized = false

	private _isFixed: boolean = true
	@Input()
	public useSidebar: boolean = false
	public get isFixed(): boolean {
		return this._isFixed
	}
	@Input()
	public set isFixed(v: boolean) {
		this._isFixed = v
		if (this._isFixed) {
			this.isMinimized = false
		}
	}

	public filterContentCopy: TemplateRef<any>

	public displayFiltros = false
	@Input()
	public filterTemplateContent: TemplateRef<any>
	public toggleDisplay(event) {
		if (this.isMobile() || this.useSidebar) {
			this.displayFiltros = true
		} else if (this.isFixed) {
			this.isMinimized = false
		} else {
			this.isMinimized = !this.isMinimized
		}

		event.stopPropagation()
	}

	isMobile() {
		return window.innerWidth <= 640
	}

	@Input()
	public filterName: string

	private _filter: Filtro

	public get filter(): Filtro {
		return this._filter
	}
	@Input()
	public set filter(v: Filtro) {
		this._filter = v
	}
	@Input()
	public verBuscador: boolean = true
	@Input()
	public idGlobalFiltro = "gf"

	@Input()
	public autoSearch: boolean = true

	@Output()
	public onFilterChange: EventEmitter<Filtro> = new EventEmitter()

	@Output()
	public onFiltroLoaded: EventEmitter<Filtro> = new EventEmitter()

	@Output()
	public onSearch: EventEmitter<Filtro> = new EventEmitter()

	@Output()
	public onClean: EventEmitter<Filtro> = new EventEmitter()

	@Input()
	public table: any
	@Input()
	public permiteGuardado: boolean = true
	private _columns: any[]
	public get columns(): any[] {
		return this._columns
	}
	@Input()
	public set columns(v: any[]) {
		this._columns = v
		if (this.filter && this.filter?.columnasVisibles?.length <= 1 && v) this.filter.columnasVisibles = v.map((c) => c.field)
	}
	@ViewChild("op", { static: true })
	public op: OverlayPanel

	public onFocus(event: any) {
		event.srcElement.select()
	}

	filtroGuardar: {
		nombre: string
		query: string
	} = {
		nombre: "",
		query: ""
	}

	constructor(
		private activatedRoute: ActivatedRoute,
		public configService: ConfiguracionUsuarioService,
		private authService: AuthService,
		private messagesService: MessagesService,
		private translateService: TranslateService,
		private confService: ConfirmationService
	) {}
	route: string
	filtros: any[] = []
	ngOnInit() {
		this.route = this.activatedRoute.routeConfig.path
	}
	public changeState(event) {
		this.isMinimized = !this.isMinimized
		event.stopPropagation()
		event.preventDefault()
	}
	public search() {
		this.onSearch.emit(this.filter)
	}
	public resetFilter() {
		this.filter.clean()
		this.onClean.emit(this.filter)
	}

	cancelar(op) {
		op.hide()
	}
	eliminarFiltro(nombre) {
		this.confService.confirm({
			key: "genConf",
			header: this.translateService.get("ELIMINAR_FILTRO"),
			message: this.translateService.get("DESEA_ELIMINAR_FILTRO"),
			accept: () => {
				this.authService.usuario.configuracion.filtros[this.route] = this.authService?.usuario?.configuracion?.filtros[this.route]?.filter(
					(f) => f.nombre != nombre
				)
				this.configService.deleteFiltro(this.route, nombre).then(() => {
					this.configService.save()
				})
			}
		})
	}
	aplicarFiltro(filtro) {
		this.filter.applyBase64Query(filtro.query)
		this.filtroGuardar.nombre = filtro?.nombre || ""
	}
	get filtrosGuardados() {
		return this.authService?.usuario?.configuracion?.filtros[this.route] || []
	}
	guardar(op) {
		this.filtroGuardar.query = this.filter.toEncodedJson()
		const ruta = this.activatedRoute.routeConfig.path

		const guardar = () => {
			this.configService.filtros = this.authService.usuario.configuracion.filtros

			this.messagesService.success(this.translateService.get("FILTRO_GUARDADO"))
			this.cancelar(op)
		}

		if (this.authService.usuario.configuracion.filtros[ruta]?.length) {
			let i = this.authService.usuario.configuracion.filtros[ruta].findIndex((f) => f.nombre === this.filtroGuardar.nombre)
			if (i > -1) {
				this.confService.confirm({
					key: "genConf",
					header: this.translateService.get("FILTRO_YA_EXISTE"),
					message: this.translateService.get("DESEA_MODIFICAR"),
					accept: () => {
						this.authService.usuario.configuracion.filtros[ruta][i] = this.filtroGuardar
						guardar()
					},
					reject: () => {
						this.cancelar(op)
					}
				})
			} else {
				this.authService.usuario.configuracion.filtros[ruta].push(this.filtroGuardar)
				guardar()
			}
		} else {
			this.authService.usuario.configuracion.filtros[ruta] = [this.filtroGuardar]
			guardar()
		}
	}
}
