import { CuentaCorriente } from './../model/CuentaCorriente'
import { Injectable } from '@angular/core'
import { CobranzaService } from './cobranza.service'
import { ComprobanteService } from './comprobante.service'
import { FacturaService } from './factura.service'
import { OrdenPagoResumenService } from './orden-pago-resumen.service'

@Injectable({
    providedIn: 'root',
})
export class AccionesDocumentoService {
    private serviceArray: any = {}
    constructor(
        public comprobanteService: ComprobanteService,
        public cobranzaService: CobranzaService,
        public ordenPagoService: OrdenPagoResumenService,
        public facturaServiceService: FacturaService
    ) {
        this.serviceArray[CuentaCorriente.VENTA] = comprobanteService
        this.serviceArray[CuentaCorriente.COBRANZA] = cobranzaService
        this.serviceArray[CuentaCorriente.ORDENPAGO] = ordenPagoService
        this.serviceArray[CuentaCorriente.COMPRA] = facturaServiceService
    }
    public getService(type: string): any {
        return this.serviceArray[type]
    }
}
