<div class="model-gestor {{ styleClass }}" [ngClass]="{ modal: isModal }">
	<div class="contenedor-titulo" *ngIf="!hideTitle && !isModal">
		<div style="width: 100%">
			<div class="model-title" #title [hidden]="title.childNodes.length == 0 || hideTitle">
				<ng-content select="[model-type=title]"></ng-content>
			</div>
			<div class="title" *ngIf="title.childNodes.length == 0 && !hideTitle && item">
				<div class="grid justify-content-end">
					<div class="col-12 lg:col title-content">
						<div class="grid justify-content-center align-items-center">
							<div class="col">
								<h2>{{ finalTitle }}</h2>
							</div>
							<div class="col-fixed print-hidden">
								<button pButton icon="fa fa-pencil" (click)="editar()" *ngIf="readonly && editable" class="p-button-primary"></button>
							</div>
							<div class="col-fixed print-hidden">
								<selector-idioma [(ngModel)]="idiomaSeleccionado" name="idioma" *ngIf="multilenguaje" [disabled]="!item?.id"></selector-idioma>
							</div>
						</div>
					</div>

					<div class="col-fixed text-right print-hidden" style="width: 10em" *ngIf="verAcciones">
						<button
							pButton
							[label]="isMobile() ? null : ('ACCIONES' | translate)"
							[icon]="'fa fa-list'"
							(click)="menuAccionesDefault.toggle($event)"
						></button>
					</div>

					<div class="col-12 subheader" *ngIf="subheader">
						<span>{{ subheader }}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="col-fixed text-right actions help-button" *ngIf="hasHelp(modelName)">
			<button
				pButton
				tooltipPosition="bottom"
				pTooltip="{{ 'AYUDA' | translate }}"
				class="p-button-primary"
				icon="fa fa-question"
				(click)="goToHelp(modelName)"
			></button>
		</div>
	</div>

	<div class="model-dataContent" #dataContent *ngIf="item">
		<ng-content select="[model-type=dataContent]"></ng-content>
	</div>
	<div class="botonera" *ngIf="!hideButtons">
		<button pButton label="{{ labelCancelar }}" (click)="cancelar()" class="p-button-danger"></button>
		<button
			class="p-button-success"
			pButton
			label="{{ 'GUARDAR' | translate }}"
			(click)="isSaving = true; guardar()"
			[disabled]="!modificado || isSaving"
			*ngIf="!readonly"
		></button>
		<button
			class="p-button-success"
			pButton
			label="{{ 'GUARDAR_Y_CONTINUAR' | translate }}"
			(click)="isSaving = true; guardar(false)"
			[disabled]="!modificado || isSaving"
			*ngIf="!readonly && !isModal"
		></button>
	</div>
</div>

<p-menu #menuAccionesDefault [popup]="true" [model]="menuItemAcciones" appendTo="body"></p-menu>
<p-dialog *ngIf="mostrarAuditar && auditableItem" header="Auditoría" [(visible)]="mostrarAuditar" [modal]="true" closable="false" [style]="{ width: '50vw' }">
	<div class="grid">
		<div class="col-12">
			<span class="overflow oneline">{{ "MODIFICADO_POR" | translate }}</span>
			<input type="text" disabled [(ngModel)]="auditableItem.lastModifiedBy" />

			<span class="overflow oneline">{{ "FECHA_MODIFICACION" | translate }}</span>
			<input type="text" disabled [(ngModel)]="auditableItem.lastModifiedDate" />
		</div>
	</div>
	<p-footer>
		<button pButton class="boton" (click)="mostrarAuditar = false" label="Cerrar"></button>
	</p-footer>
</p-dialog>
