<messages></messages>

<p-confirmDialog
	[key]="'genConf'"
	[appendTo]="'body'"
	header="Cancelar Edición"
	icon="fa fa-question-circle"
	acceptLabel="Si"
	rejectLabel="No"
></p-confirmDialog>
<p-confirmDialog
	[key]="'genConf2'"
	[appendTo]="'body'"
	header="Cancelar Edición"
	icon="fa fa-question-circle"
	acceptLabel="Si"
	rejectLabel="No"
></p-confirmDialog>
<p-confirmDialog
	[key]="'genConf3'"
	[appendTo]="'body'"
	header="Cancelar Edición"
	icon="fa fa-question-circle"
	acceptLabel="Si"
	rejectLabel="No"
></p-confirmDialog>
<div *ngIf="!showMenu">
	<div [@triggerName]="prepareRoute(outlet)">
		<router-outlet></router-outlet>
	</div>
	<loading-panel #gloading></loading-panel>
</div>
<div
	#layoutContainer
	*ngIf="showMenu"
	class="layout-wrapper"
	[ngClass]="{
		'p-input-outlined': inputStyle === 'outlined',
		'p-ripple-disabled': !ripple,
		'layout-menu-static': isStatic(),
		'layout-menu-overlay': isOverlay(),
		'layout-menu-horizontal': isHorizontal(),
		'layout-menu-slim': isSlim(),
		'layout-menu-active': menuActive,
		'layout-menu-mobile-active': mobileMenuActive,
		'layout-topbar-mobile-active': mobileTopbarActive,
		'layout-rightmenu-active': rightMenuActive,
		'layout-rtl': isRTL
	}"
	[class]="'layout-menu-' + menuTheme + ' layout-topbar-' + topbarTheme"
>
	<loading-panel #gloading></loading-panel>
	<app-topbar></app-topbar>

	<div class="menu-wrapper" (click)="onMenuClick($event)">
		<div class="layout-menu-container">
			<app-inline-profile></app-inline-profile>
			<app-menu></app-menu>
		</div>
	</div>

	<div class="layout-main">
		<!--app-breadcrumb *ngIf="!hideBreadcrumbs"></app-breadcrumb-->
		<div class="layout-content" [@triggerName]="prepareRoute(outlet)">
			<router-outlet #o="outlet"></router-outlet>
			<app-footer></app-footer>
		</div>
	</div>
	<div *ngIf="mobileMenuActive" class="layout-mask modal-in"></div>
</div>
