import { CanalComunicacionFactory } from './CanalComunicacionFactory';
import { CanalComunicacion } from 'src/app/model/CanalComunicacion';
import { Parametrico } from './../common/model/Parametrico';



export class TipoNotificacion extends Parametrico {
    constructor(id?: number, codigo?: string, descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,
        public canales: CanalComunicacion[] = [],
        public destinatarioDefault?: string,
        public modoTest?:boolean,
        public destinatarioTest?:string,
        public esRecordatorioPax:boolean = false

    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso)
    }
    public tipoParametro(): string {
        return "tiponotificacion";
    }
    public static fromData(data: any): TipoNotificacion {
        if (!data) return null;
        let o: TipoNotificacion = new TipoNotificacion(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,
            data.canales?.length ? data.canales.map(c => CanalComunicacionFactory.fromData(c) ) : [],
            data.destinatarioDefault,
            data.modoTest,
            data.destinatarioTest,
            data.esRecordatorioPax
        );

        o.version = data.version; 
return o;

    }


    public get tieneWhatsapp(): boolean {
        return this.canales.some(canal => canal.tipo == 'W')
    }

    public get tieneMail(): boolean {
        return this.canales.some(canal => canal.tipo == 'E')
    }

}