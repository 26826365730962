import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { SubcategoriaImponible } from "src/app/model/SubcategoriaImponible"
import { MessagesService } from "../common/services/messages-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class SubcategoriaImponibleService extends ParametricoService<SubcategoriaImponible> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "subcategoria-imponible"
	}
	public parseToEnt(data: any): SubcategoriaImponible {
		return SubcategoriaImponible.fromData(data)
	}
	public newEnt(): SubcategoriaImponible {
		return new SubcategoriaImponible()
	}
}
