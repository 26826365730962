import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { TipoIngresoBruto } from "src/app/model/TipoIngresoBruto"
import { MessagesService } from "../common/services/messages-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class TipoIngresoBrutoService extends ParametricoService<TipoIngresoBruto> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "tipo-ingreso-bruto"
	}
	public parseToEnt(data: any): TipoIngresoBruto {
		return TipoIngresoBruto.fromData(data)
	}
	public newEnt(): TipoIngresoBruto {
		return new TipoIngresoBruto()
	}
}
