<div class="gestor-estado-reserva content content-white">
	<model-gestor
		[service]="service"
		[(item)]="item"
		[validar]="isValid"
		[modelName]="'ESTADO-RESERVA' | translate"
		[isModal]="isModal"
		[goBack]="goBack"
		(onItemGuardado)="handleGuardado($event)"
		(onCancelar)="handleCancelar($event)"
		[form]="EstadoReservaForm"
	>
		<div class="gestor-form" model-type="dataContent">
			<form #EstadoReservaForm="ngForm" id="EstadoReservaform">
				<div class="grid" *ngIf="item">
					<div class="col-12 lg:col-2">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'CODIGO_PH' | translate }}"
								[(ngModel)]="item.codigo"
								tooltipPosition="bottom"
								pTooltip="{{ 'CODIGO_TOOLTIP' | translate }}"
								type="text"
								#codigo="ngModel"
								name="codigo"
								[readonly]="readonly"
							/>
							<label>{{ "CODIGO" | translate }}</label>
						</span>
						<error-tag [model]="codigo"></error-tag>
					</div>
					<div class="col-12 lg:col-6">
						<span class="p-float-label">
							<input
								pInputText
								placeholder="{{ 'DESCRIPCION_PH' | translate }}"
								[(ngModel)]="item.descripcion"
								tooltipPosition="bottom"
								pTooltip="{{ 'DESCRIPCION_TOOLTIP' | translate }}"
								type="text"
								#descripcion="ngModel"
								name="descripcion"
								[readonly]="readonly"
							/>
							<label>{{ "DESCRIPCION" | translate }}</label>
						</span>
						<error-tag [model]="descripcion"></error-tag>
					</div>
					<div class="col-12 lg:col-2">
						<color-picker [(ngModel)]="item.color" name="color" [readonly]="readonly"> </color-picker>
					</div>
				</div>
			</form>
		</div>
	</model-gestor>
</div>
