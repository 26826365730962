import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Parametrico } from "src/app/common/model/Parametrico"

export class EstadoDocumento extends Parametrico {
	static PENDIENTE_PAGO: string = "PP"
	static ANULADO: string = "AN"
	static CANCELADO: string = "C"
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		habilitado: boolean = true,
		esSistema: boolean = false,
		peso: number = 99,
		public color?: string,
		public icono?: string
	) {
		super(id, codigo, descripcion, habilitado, esSistema, peso)
	}
	public get esTerminado() {
		return this.codigo == "EN" || this.codigo == "F" || this.codigo == "EE" || this.codigo == "D"
	}
	public tipoParametro(): string {
		return "$MODELNAMELOW"
	}
	public static esEditable(estado: Descriptivo) {
		return !estado || estado.codigo == "P" || estado.codigo == "A" || estado.codigo == "N"
	}
	public static esPendienteAprobacion(estado: Descriptivo) {
		return estado && estado.codigo == "P"
	}

	public static fromData(data: any): EstadoDocumento {
		if (!data) return null
		const o: EstadoDocumento = new EstadoDocumento(
			data.id,
			data.codigo,
			data.descripcion,
			data.habilitado,
			data.esSistema,
			data.peso,
			data.color,
			data.icono
		)

		o.version = data.version
		return o
	}
}
