import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { Subscription, filter } from "rxjs"
import { Ausencia } from "src/app/model/Ausencia"
import { Disponibilidad } from "src/app/model/Disponibilidad"
import { OpcionPuesto } from "src/app/model/OpcionPuesto"
import { OpcionPuestoOcupacion } from "src/app/model/OpcionPuestoOcupacion"
import { TipoPuesto } from "src/app/model/TipoPuesto"
import { ProductoService } from "src/app/services/producto.service"
import { PuestoServicioReservaService } from "src/app/services/puesto-servicio-reserva.service"
import { TipoPuestoService } from "./../../services/tipo-puesto.service"

@Component({
	selector: "disponibilidad-puesto",
	templateUrl: "./disponibilidad-puesto.component.html",
	styleUrls: ["./disponibilidad-puesto.component.less"]
})
export class DisponibilidadPuestoComponent implements OnInit, OnDestroy {
	private _puesto: TipoPuesto
	mostrarError: boolean = false
	asignacionSub: Subscription

	PUESTO_DISPONIBILIDAD = "puesto-disponibilidad"
	public get puesto(): TipoPuesto {
		return this._puesto
	}
	@Input()
	public set puesto(v: TipoPuesto) {
		this._puesto = v
		this.puestoChange.emit(this._puesto)
		if (v && this.fecha) {
			this.buscar()
		}
		if (v && !this.ocultarBuscador) {
			localStorage.setItem(this.PUESTO_DISPONIBILIDAD, JSON.stringify(v))
		}
	}

	@Output() length: EventEmitter<number> = new EventEmitter(null)
	@Output() puestoChange: EventEmitter<TipoPuesto> = new EventEmitter(null)

	public buscador: string = ""

	private _fecha: Date
	public get fecha(): Date {
		return this._fecha
	}
	@Input()
	public set fecha(v: Date) {
		this._fecha = v
		if (v && this.puesto) {
			this.buscar()
		}
	}

	ausencias: Ausencia[]

	private _opciones: OpcionPuesto[]
	@Input() ocultarBuscador: boolean = false

	public get opciones(): OpcionPuesto[] {
		return this._opciones
	}

	public set opciones(v: OpcionPuesto[]) {
		this._opciones = v
	}

	public ocupacion: OpcionPuestoOcupacion[] = []

	private _disponibilidad: Disponibilidad[] = []
	public get disponibilidad(): Disponibilidad[] {
		return this._disponibilidad
	}
	public set disponibilidad(v: Disponibilidad[]) {
		this._disponibilidad = v
	}

	constructor(
		public puestoServicioReservaService: PuestoServicioReservaService,
		public tipoPuestoService: TipoPuestoService,
		public productoService: ProductoService
	) {}

	ngOnInit() {
		const p = localStorage.getItem(this.PUESTO_DISPONIBILIDAD)

		this.asignacionSub = this.puestoServicioReservaService.asignacionBs.pipe(filter((v) => v)).subscribe(() => {
			this.buscar()
		})

		if (p) this.puesto = TipoPuesto.fromData(JSON.parse(p))
		if (this.fecha && this.puesto) {
			this.buscar()
		}
	}

	public buscar() {
		if (!this.fecha || !this.puesto) {
			this.opciones = []
			return
		}
		this.puestoServicioReservaService.buscarPuestos(this.fecha, this.puesto).then((o) => {
			this.opciones = o
			const desocupados = this.opciones?.filter((o) => (!o.ocupacion || !o.ocupacion.length) && !o.ausencia)
			this.length.emit(desocupados?.length ? desocupados.length : 0)
			if (!o || !o.length) {
				this.mostrarError = true
			}
		})
	}
	sortOpciones() {
		this._opciones.sort((a, b) => {
			if (a.puntaje > b.puntaje) return -1
			else return 1
		})
	}

	seleccionarOpcion(event) {}

	ngOnDestroy(): void {
		this.asignacionSub && this.asignacionSub.unsubscribe()
	}
}
