<div class="pantalla-ayuda ayuda-itinerario">
    <h2>Ayuda Itinerario</h2>
    <p>En esta pantalla se puede visualizar el itinerario de los servicios prestado por un proveedor determinado a uno o más grupos.</p>
    <p>
        Se dispone de acceso directo "Hoy" y "Mañana" y también se puede utilizar el filtro donde encontrará la opcíon de visualizar otra fecha o rangos de fechas. (Desde / hasta). <br />
        También podrá filtrar por cliente y por servicio / producto brindado.
    </p>
    <h3>Acciones disponibles</h3>
    <ul>
        <li><i class="fa fa-flag-checkered icono-bandera"></i> <strong>Terminar:</strong>  Indicar la finalización del servicio prestado. </li>
        <li><i class="fa fa-phone icono-pax"></i> <strong>Llamar:</strong> Llamado de un toque al teléfono del PAX referente del servicio. </li>
        <li><i class="fa fa-whatsapp icono-pax"></i> <strong>whatsapp: </strong> Abre una conversación directa por whatsaoo con el contacto del PAX referente del servicio. </li>
    </ul>
    

    <h3>Información en pantalla</h3>
    En el <strong>encabezado</strong> de cada grupo podemos encontrar:
    <ul>
        <li>Nombre del servicio.</li>
        <li>Identificación del vehículo afectado si lo hubiese.</li>
        <li>Botón de aviso de finalización del servicio  <i class="icono-bandera fa fa-flag-checkered"></i></li>
        <li>Cantidad de PAX total del grupo. <i class="fa fa-user icono-pax"></i></li>
    </ul>
    <p>Luego en la <strong>parte superior </strong>del grupo encontramos los puestos o roles cubiertos o a cubrir. (ej: Choferes, restaurantes, etc.)</p>
    <p>En la <strong>parte central</strong> del cuerpo aparecerá condicionalmente toda la Información referida a los pasajeros:</p>
        <ul>
            <li>Nombre del PAX referente del subgrupo.</li>
            <li>Cantidad de PAX del subgrupo.</li>
            <li>Idioma (icono de bandera)</li>    
            <li>Datos del pickup: Dirección del hotel, horario, otros datos.</li> 
            <li>Restricciones alimenticias de alguno de los pasajeros.</li> 
            <li>Saldos a cobrar por el guía.</li>
            <li>Link de llamado directo al PAX referente del subgrupo.<i class="fa fa-phone icono-pax"></i></li>
            <li>Link de contacto directo por Whatsapp al PAX referente del subgrupo. <i class="fa fa-whatsapp icono-pax"></i></li>
            <li>Link al Vaucher del subgrupo <i class="fa fa-qrcode icono-pax"></i>(Ej: por si el PAX lo extravió o no tiene acceso)</li>
            <li>Observaciones.</li>   <br>
        </ul>
<p>Por último los adicionales que incluirá este grupo con sus respectivas cantidades.</p>

    <p>
        <strong
            >IMPORTANTE: Esta pantalla se puede ver offline una vez abierta online una vez. Es decir, si no posee conexión de red durante un servicio, puede ver el contenido visualizado
            previamente.</strong
        >
    </p>
</div>
<div class="flex justify-content-end">
    <p-button label="Volver" (click)="volver()"></p-button>
</div>
