import { Injectable } from "@angular/core"
import { ParametricoService } from "src/app/common/services/parametricos.service"
import { EstadoReserva } from "src/app/model/EstadoReserva"
import { MessagesService } from "../common/services/messages-data-service.service"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class EstadoReservaService extends ParametricoService<EstadoReserva> {
	constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "estado-reserva"
	}
	public parseToEnt(data: any): EstadoReserva {
		return EstadoReserva.fromData(data)
	}
	public newEnt(): EstadoReserva {
		return new EstadoReserva()
	}
}
