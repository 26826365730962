import { lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { RespuestaEncuestaActividades } from 'src/app/model/RespuestaEncuestaActividades';
import { LoadingService } from './../common/services/loading-data-service.service';
import { FiltroReporteEncuesta } from '../model/FiltroReporteEncuesta';
import { ReporteEncuesta } from '../model/ReporteEncuesta';
@Injectable({
  providedIn: 'root',
})
export class RespuestaEncuestaActividadesService extends ServicioAbstract<RespuestaEncuestaActividades>{


    public baseName(): string {
        return "respuesta-encuesta-actividades";
    }
    public parseToEnt(data: any): RespuestaEncuestaActividades {
        return RespuestaEncuestaActividades.fromData(data);
    }
    public newEnt(): RespuestaEncuestaActividades {
        return new RespuestaEncuestaActividades();
    }

    guardarRespuesta(respuesta: RespuestaEncuestaActividades, customLoading?: LoadingService) {
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.post(this.getBaseURL('public/guardarEncuesta'), respuesta))
        .then((r) => {
            if (customLoading) { customLoading.susLoadingCount(); }
            else this.loadingService.susLoadingCount(); return this.parseSingle(r)
        }).catch(
            (e) => this.handleError(e, customLoading));
    }

    byIdGrupoReserva(idGrupoReserva: any, customLoading?:LoadingService) {
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.get(this.getBaseURL('byIdGrupoReserva?idGrupoReserva='+idGrupoReserva )))
        .then((r) => {
            if (customLoading) { customLoading.susLoadingCount(); }
            else this.loadingService.susLoadingCount(); return this.parseSingle(r)
        }).catch(
            (e) => this.handleError(e, customLoading));
	}

    byIdItemReserva(idItemReserva: any, customLoading?:LoadingService) {
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.get(this.getBaseURL('byIdItemReserva?idItemReserva='+idItemReserva )))
        .then((r) => {
            if (customLoading) { customLoading.susLoadingCount(); }
            else this.loadingService.susLoadingCount(); return this.parseSingle(r)
        }).catch(
            (e) => this.handleError(e, customLoading));
	}

	getByReserva(idReserva: number, idGrupo: number, idFamilia: number, idProducto: number, customLoading?:LoadingService) {
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.get(this.getBaseURL(`byReserva/${idReserva}/${idGrupo}/${idFamilia}/${idProducto}`)))
        .then((r) => {
            if (customLoading) { customLoading.susLoadingCount(); }
            else this.loadingService.susLoadingCount(); return this.parseSingle(r)
        }).catch(
            (e) => this.handleError(e, customLoading));
	}

    getReporte(f: FiltroReporteEncuesta, customLoading?: LoadingService) {
            if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
            return lastValueFrom(this.http.post(this.getBaseURL('getReporte'), f.json))
            .then((r: any[]) => {
                if (customLoading) { customLoading.susLoadingCount(); }
                else this.loadingService.susLoadingCount(); 
                if (r?.length) {
                    return r.map(v => ReporteEncuesta.fromData(v))
                } else return []
            }).catch(
                (e) => this.handleError(e, customLoading));
            
            
            }
      


}