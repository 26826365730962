<div class="listado-reserva content">
	<model-list
		*ngIf="filtro"
		[service]="service"
		[modelName]="esCotizacion ? 'cotizacion' : 'reserva'"
		[columns]="columns"
		[title]="(esCotizacion ? 'COTIZACIONES' : 'RESERVAS') | translate"
		[isLazy]="true"
		[filtro]="filtro"
		[multilenguaje]="false"
		[acciones]="acciones"
		[filterContent]="filterContent"
		[modoSeleccion]="modoSeleccion"
		(seleccionChange)="updateMenuAcciones($event)"
		[menuItemAcciones]="itemsMenu"
		[footer]="footerTemplate"
		[getCount]="getCount"
		[download]="true"
		[previousContent]="previousContent"
	>
		<ng-template #previousContent>
			<div *ngIf="externasFallidas?.length">
				<p-accordion [multiple]="true">
					<p-accordionTab [selected]="true">
						<ng-template pTemplate="header">
							{{ "RESERVAS_EXTERNAS_FALLIDAS" | translate }}
							<p-badge [value]="externasFallidas.length" severity="danger" style="margin-left: 5px"></p-badge>
						</ng-template>
						<div class="grid externas">
							<div class="col-12 lg:col-4" *ngFor="let item of externasFallidas">
								<div class="card">
									<div class="grid">
										<div class="col-6">
											<div class="bold">Proveedor</div>
											{{ item.provider.descripcion }}
										</div>
										<div class="col-6">
											<div class="bold">Referencia externa</div>
											{{ item.referenciaExterna }}
										</div>
										<div class="col-6">
											<div class="bold">Error</div>
											{{ item.log }}
										</div>
										<div class="col-6">
											<div class="bold">Fecha registro</div>
											{{ item.fechaRegistro | date : "dd/MM/yyyy" }}
										</div>
										<div class="col-4">
											<button
												pButton
												icon="fa fa-eye"
												(click)="verMensaje(item); $event.stopPropagation()"
												class="p-button-text p-button-tercery p-ripple"
											></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</p-accordionTab>
				</p-accordion>
			</div>
		</ng-template>
		<ng-template #filterContent>
			<div class="grid">
				<div class="col-12">
					<div class="p-inputgroup">
						<span class="p-float-label">
							<input [(ngModel)]="filtro.searchStr" pInputText placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}" />
							<label>{{ "BUSCAR" | translate }}...</label>
						</span>
						<button type="button" pButton icon="pi pi-search"></button>
					</div>
				</div>
				<div class="col-12" *ngIf="opcionesPunto?.length > 1 && !esClienteExterno">
					<descriptivo-material-selector
						[label]="'PUNTO_VENTA' | translate"
						[limpiable]="true"
						[(ngModel)]="filtro.puntoDeVenta"
						isLazy="true"
						name="puntoDeVenta"
						[service]="puntoDeVentaService"
						[autohide]="true"
					>
					</descriptivo-material-selector>
				</div>
				<div class="col-12" *ngIf="!esClienteExterno">
					<descriptivo-material-selector
						[label]="'CLIENTE' | translate"
						[limpiable]="true"
						[(ngModel)]="filtro.cliente"
						name="cliente_filtro"
						isLazy="true"
						[service]="clienteService"
					>
					</descriptivo-material-selector>
				</div>
				<div class="col-12" *ngIf="!esClienteExterno">
					<descriptivo-material-selector
						[label]="'CANALVENTA' | translate"
						[limpiable]="true"
						[(ngModel)]="filtro.canalVenta"
						name="canal_filtro"
						isLazy="true"
						[service]="canalService"
					>
					</descriptivo-material-selector>
				</div>
				<div class="col-12" *ngIf="!esClienteExterno">
					<descriptivo-material-selector
						[label]="'VENDEDOR' | translate"
						[limpiable]="true"
						[(ngModel)]="filtro.vendedor"
						name="vendedor_listado"
						isLazy="false"
						[options]="vendedores"
					>
					</descriptivo-material-selector>
				</div>
				<div class="col-12" *ngIf="!esClienteExterno">
					<descriptivo-material-selector
						[label]="'LISTA_PRECIOS' | translate"
						[limpiable]="true"
						[service]="listaPrecioService"
						[(ngModel)]="filtro.listaPrecios"
						[permiteNuevo]="false"
						name="listaPrecios"
					>
					</descriptivo-material-selector>
				</div>
				<div class="col-6 field p-fluid">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="filtro.fechaRegistroDesde"
							dateFormat="dd/mm/yy"
							appendTo="body"
							name="fechaRegistroDesde"
							[showIcon]="true"
						></p-calendar>
						<label>{{ "FECHA_DESDEREGISTRO" | translate }}</label>
					</span>
				</div>
				<div class="col-6 field p-fluid">
					<span class="p-float-label">
						<p-calendar [(ngModel)]="filtro.fechaRegistroHasta" name="rH" dateFormat="dd/mm/yy" appendTo="body" [showIcon]="true"></p-calendar>
						<label>{{ "HASTA" | translate }}</label>
					</span>
				</div>
				<div class="col-6 field p-fluid">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="filtro.fechaVencimientoDesde"
							dateFormat="dd/mm/yy"
							appendTo="body"
							name="fechaVencimientoDesde"
							[showIcon]="true"
						></p-calendar>
						<label>{{ "FECHAVENCIMIENTODESDE" | translate }}</label>
					</span>
				</div>
				<div class="col-6 field p-fluid">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="filtro.fechaVencimientoHasta"
							dateFormat="dd/mm/yy"
							appendTo="body"
							name="fechaVencimientoHasta"
							[showIcon]="true"
						></p-calendar>
						<label>{{ "HASTA" | translate }}</label>
					</span>
				</div>
				<div class="col-6 field p-fluid">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="filtro.fechaActividadDesde"
							dateFormat="dd/mm/yy"
							appendTo="body"
							name="fechaActividadDesde"
							[showIcon]="true"
						></p-calendar>
						<label>{{ "FECHA_PRIMER_ACTIVIDAD_DESDE" | translate }}</label>
					</span>
				</div>
				<div class="col-6 field p-fluid">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="filtro.fechaActividadHasta"
							dateFormat="dd/mm/yy"
							appendTo="body"
							name="fechaActividadHasta"
							[showIcon]="true"
						></p-calendar>
						<label>{{ "HASTA" | translate }}</label>
					</span>
				</div>
				<div class="col-6 field p-fluid">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="filtro.fechaUltimaActivdadDesde"
							dateFormat="dd/mm/yy"
							appendTo="body"
							name="fechaUltimaActivdadDesde"
							[showIcon]="true"
						></p-calendar>
						<label>{{ "FECHA_ULTIMA_ACTIVIDAD_DESDE" | translate }}</label>
					</span>
				</div>
				<div class="col-6 field p-fluid">
					<span class="p-float-label">
						<p-calendar
							[(ngModel)]="filtro.fechaUltimaActivdadHasta"
							dateFormat="dd/mm/yy"
							appendTo="body"
							name="fechaUltimaActivdadHasta"
							[showIcon]="true"
						></p-calendar>
						<label>{{ "HASTA" | translate }}</label>
					</span>
				</div>

				<div class="col-12 botonera">
					<div class="grid">
						<div class="col text-center" *ngFor="let estado of estados">
							<button
								pButton
								[label]="estado?.descripcion"
								[ngClass]="{ 'no-seleccionado': !filtro?.tieneEstado(estado) }"
								[ngStyle]="{ 'border-bottom-color': estado?.color, 'background-color': estado?.color }"
								(click)="filtro.toggleEstado(estado)"
							></button>
						</div>
						<div class="col text-center">
							<button
								[ngClass]="{ 'no-seleccionado': !filtro.soloImpagas }"
								pButton
								tooltipPosition="bottom"
								pTooltip="{{ 'SOLO_PEDIDOS_CON_SA_12' | translate }}"
								[label]="'SOLO_CON_SALDO' | translate"
								class="solo-con-saldo"
								name="soloImpagas"
								(click)="filtro.soloImpagas = !filtro.soloImpagas"
							></button>
						</div>
						<div class="col text-center">
							<button
								[ngClass]="{ 'no-seleccionado': !filtro.soloSinComprobante }"
								pButton
								tooltipPosition="bottom"
								pTooltip="{{ 'SOLO_PEDIDOS_SIN_CO_8' | translate }}"
								[label]="'SIN_COMPROBANTE' | translate"
								class="solo-sin-comprobante"
								name="soloSinComprobante"
								(click)="filtro.soloSinComprobante = !filtro.soloSinComprobante"
							></button>
						</div>
						<div class="col text-center">
							<button
								[ngClass]="{ 'no-seleccionado': !filtro.soloCotizaciones }"
								pButton
								tooltipPosition="bottom"
								pTooltip="{{ 'Solo cotizaciones' | translate }}"
								[label]="'Solo cotizaciones' | translate"
								class="solo-cotizaciones"
								name="cotizaciones"
								(click)="filtro.soloCotizaciones = !filtro.soloCotizaciones"
							></button>
						</div>
					</div>
				</div>
				<!-- <div class="col-12">
						<p-checkbox [binary]="true" [(ngModel)]="verModificacion" label="{{ 'VER_MODIFICACION' | translate }}"></p-checkbox>
					</div>
					<div class="col-12 lg:col-6">
						<p-checkbox [binary]="true" [(ngModel)]="verVendedor" label="{{ 'VER_VENDEDOR' | translate }}"></p-checkbox>
					</div>
					<div class="col-12">
						<p-checkbox [binary]="true" [(ngModel)]="verListaPrecios" label="{{ 'VER_LISTADO_PRECIOS' | translate }}"></p-checkbox>
					</div> -->
			</div>
		</ng-template>

		<ng-template #footerTemplate>
			<tr class="totales" [allowed]="'ROLE_JEFE_ADMIN'">
				<td [attr.colspan]="colSpan">
					<div class="grid align-content-center text-right">
						<div class="col-12">
							<b>{{ (totales?.totalesPorMoneda?.length > 1 ? "TOTALES" : "TOTAL") | translate }}</b>
						</div>
					</div>
				</td>
				<td class="text-right total">
					<div class="grid align-content-center grid-nogutter">
						<div class="col-12 text-right" *ngFor="let tot of totales.totalesPorMoneda">
							<b>{{ tot?.moneda?.descripcion }}</b>
						</div>
					</div>
				</td>
				<td class="text-right total">
					<div class="grid align-content-center grid-nogutter">
						<div class="col-12 text-right" *ngFor="let tot of totales.totalesPorMoneda">
							<b>{{ tot?.monto | currency : "USD" : tot?.moneda?.simbolo + " " || "$ " }}</b>
						</div>
					</div>
				</td>
				<td class="text-right total">
					<div class="grid align-content-center grid-nogutter">
						<div class="col-12 text-right" *ngFor="let tot of totales.totalesPorMoneda">
							<b>{{ tot?.saldo | currency : "USD" : tot?.moneda?.simbolo + " " || "$ " }}</b>
						</div>
					</div>
				</td>
				<td></td>
			</tr>
		</ng-template>
	</model-list>
</div>
<ng-template #cliente let-data>
	<div class="grid grid-nogutter align-content-center" style="flex-wrap: nowrap">
		<div class="col col-cliente">
			<div class="grid grid-nogutter">
				<div class="col-12" *ngIf="!isMobile()">
					<span class="oneline overflow" tooltipPosition="bottom" pTooltip="{{ data.nombrePax }}"
						>{{ data.nombrePax }} x {{ data.cantidadPax }}
					</span>
				</div>
				<div class="col-fixed">
					<span
						class="oneline clickable overflow"
						[ngClass]="{ small: !isMobile() }"
						tooltipPosition="bottom"
						pTooltip="{{ data.cliente?.descripcion }}"
						(click)="goToEnt($event, 'cliente', data.cliente?.id)"
						>{{ data?.cliente?.descripcion }}</span
					>
				</div>
			</div>
		</div>

		<div class="col-fixed text-center" style="width: 2em" [ngClass]="{ link: data.consentimientoAceptado }">
			<i
				tooltipPosition="bottom"
				pTooltip="{{ data.estadoConsentimiento?.descripcion }}"
				(click)="verConcentimiento($event, data)"
				class="fa icon link {{ data?.estadoConsentimiento?.icono }}"
				[ngStyle]="{ color: data?.estadoConsentimiento?.color }"
			></i>
		</div>
	</div>
</ng-template>
<ng-template #fecha let-data let-col="col">
	<div class="grid grid-nogutter" *ngIf="!data.estado?.esCancelada()">
		<div class="col-12 overflow">
			<span class="oneline" tooltipPosition="bottom" pTooltip="{{ data.fechaVencimiento }}">{{ data.fechaVencimiento | date : "dd/MM/yyyy" }}</span>
		</div>
		<div class="col-12 overflow">
			<span class="oneline small" tooltipPosition="bottom" pTooltip="{{ data.fechaRegistro }}"
				>Registro: {{ data.fechaRegistro | date : "dd/MM/yyyy" }}</span
			>
		</div>
	</div>
	<div *ngIf="data.estado?.esCancelada()" class="overflow" [ngStyle]="{ 'max-width': col.width }">
		<span tooltipPosition="bottom" pTooltip="{{ data.motivoCancelacion }}">Motivo Cancelación: {{ data?.motivoCancelacion || "Sin motivo" }}</span>
	</div>
</ng-template>
<ng-template #comprobante let-data>
	<span class="clickable chip comprobante" [ngClass]="{ adelanto: data?.esAdelanto }" *ngIf="data?.comprobante" (click)="verComprobante(data?.comprobante)">
		{{ data?.numeroComprobante || data?.comprobante }}
	</span>
	<small class="block text-center w-100" *ngIf="data?.esAdelanto">{{ "ADELANTO" | translate }}</small>
</ng-template>
<ng-template #ultimaModificacion let-data>
	<span *ngIf="data?.lastModifiedDate">{{ data?.lastModifiedDate | date : "dd/MM/yyyy" }}</span>
	<small class="block" *ngIf="data?.lastModifiedBy">{{ data?.lastModifiedBy }}</small>
</ng-template>

<acciones-reserva (onDataUpdate)="updateFiltros($event)"> </acciones-reserva>

<p-dialog header="Mensaje obtenido" [(visible)]="estructuraFallida" appendTo="body" [modal]="true" [style]="{ width: 'auto', 'min-width': '50vw' }">
	<pre>{{ estructuraFallida | json }}</pre>
</p-dialog>
