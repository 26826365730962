import { Descriptivo } from "src/app/common/model/Descriptivo"
import { MonedaEntorno } from "../app.module"
import { ComprobanteResumen } from "./Comprobante"
import { Moneda } from "./Moneda"

export class ItemCobranza extends Descriptivo {
	public totalEnMonedaOrigen: number = 0
	constructor(
		id?: number,
		codigo?: string,
		descripcion?: string,
		public comprobante?: ComprobanteResumen,
		public importe?: number,
		public importeSinRecargos?: number,
		public moneda?: Moneda,
		public cotizacion: number = 1,
		public lastModifiedBy?: string,
		public lastModifiedDate?: Date
	) {
		super(codigo, descripcion, id)
	}

	public static fromData(data: any): ItemCobranza {
		if (!data) return null
		const o: ItemCobranza = new ItemCobranza(
			data.id,
			data.codigo,
			data.descripcion,
			ComprobanteResumen.fromData(data.comprobante),
			data.importe,
			data.importeSinRecargos,
			null,
			data.cotizacion,
			data.lastModifiedBy,
			data.lastModifiedDate
		)
		if (!o.importeSinRecargos) o.importeSinRecargos = o.importe
		o.version = data.version
		o.totalEnMonedaOrigen =
			o.importe / (o.comprobante?.cotizacionAfip != 1 ? o.comprobante?.cotizacionAfip : o.comprobante?.cotizacion != 1 ? o.comprobante?.cotizacion : 1)
		return o
	}
	get cotizacionSafe() {
		return this.cotizacion || 1
	}
	get descripcionLarga() {
		return this.comprobante?.descripcionLarga || ""
	}
	public montoTransformado(cotizacion?: number, moneda?: Moneda) {
		if (moneda?.codigo == this.moneda?.codigo) {
			return this.importeSinRecargos
		} else if (moneda?.codigo == this.comprobante?.moneda?.codigo) {
			if (moneda?.codigo != MonedaEntorno().codigo) {
				return this.importeSinRecargos * (1 / this.cotizacionSafe)
			} else return this.importeSinRecargos
		} else {
			const importeT = this.importeSinRecargos * (1 / (this.comprobante.cotizacion || 1))
			if (moneda?.codigo != MonedaEntorno().codigo) {
				return importeT * (1 / this.cotizacionSafe)
			} else return importeT
		}
	}
}
