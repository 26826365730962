import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { BilletesCaja } from './BilletesCaja';
import { Caja } from './Caja';



export class CambioEstadoCaja {
	constructor(
		public id?: number,
		public fecha: Date = new Date(),
		public descripcion?: string,
		public responsable?: Descriptivo,
		public caja?: Caja,
		public monto?: number,
		public diferencia: number = 0,
		public justificacion?: string,
		public esCierre?: boolean,
		public valores: BilletesCaja[] = [],

	) {
	}
	public static fromData(data: any): CambioEstadoCaja {
		if (!data) return null;
		const o: CambioEstadoCaja = new CambioEstadoCaja(
			data.id,
			data.fecha ? new Date(data.fecha) : null,
			data.descripcion,
			Descriptivo.fromData(data.responsable),
			Caja.fromData(data.caja),
			data.monto,
			data.diferencia,
			data.justificacion,
			data.esCierre,
			data.valores ? data.valores.map(c => BilletesCaja.fromData(c)) : [],

		);
		return o;

	}

	public get tipoIcono() {
		return this.esCierre ? 'fa fa-lock' : 'fa fa-unlock';
	}

}

