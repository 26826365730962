<div class="filtros prime-materia" [ngClass]="{ maximized: !isMinimized }">
	<button
		*ngIf="isMinimized && !isFixed"
		pButton
		icon="pi pi-filter"
		(click)="toggleDisplay($event)"
		class="mini-button p-button-text"
		tooltipPosition="bottom"
		pTooltip="{{ 'FILTRAR' | translate }}"
	></button>
	<button
		*ngIf="!isMinimized && !isFixed"
		pButton
		icon="pi pi-sort-up"
		(click)="toggleDisplay($event)"
		class="mini-button p-button-text"
		tooltipPosition="bottom"
		pTooltip="{{ 'RETRAER' | translate }}"
	></button>
	<button
		*ngIf="isMinimized && !isFixed"
		pButton
		icon="pi pi-filter"
		(click)="toggleDisplay($event)"
		class="mini-button p-button-text"
		tooltipPosition="bottom"
		pTooltip="{{ 'FILTRAR' | translate }}"
	></button>
	<button
		*ngIf="!isMinimized && !isFixed"
		pButton
		icon="pi pi-sort-up"
		(click)="toggleDisplay($event)"
		class="mini-button p-button-text"
		tooltipPosition="bottom"
		pTooltip="{{ 'RETRAER' | translate }}"
	></button>
	<button
		pButton
		icon="pi pi-refresh"
		(click)="filter.forceUpdate(); $event.stopPropagation()"
		class="refresh-button p-button-text"
		tooltipPosition="bottom"
		pTooltip="{{ 'REFRESCAR' | translate }}"
	></button>

	<button
		pButton
		icon="pi pi-trash"
		class="clean-button p-button-text"
		(click)="resetFilter()"
		tooltipPosition="bottom"
		pTooltip="{{ 'BORRAR_FILTROS' | translate }}"
	></button>
	<button
		*ngIf="permiteGuardado && !isFixed && !isMinimized"
		pButton
		icon="pi pi-save"
		class="p-button-text save-button"
		pTooltip="{{ 'GUARDAR_FILTRO' | translate }}"
		(click)="op.toggle($event)"
	></button>

	<div class="filtro-content" [ngClass]="{ minimized: isMinimized }">
		<ng-content></ng-content>
		<ng-container *ngTemplateOutlet="filterBase"> </ng-container>
	</div>
	<div class="filtro-summary" [ngClass]="{ maximized: !isMinimized }" (click)="toggleDisplay($event)">
		<div class="grid grid-nogutter align-content-center">
			<div class="p-inputgroup" *ngIf="filter">
				<span class="p-inputgroup-addon">
					<i class="pi pi-search"></i>
				</span>
				<input
					(click)="$event.stopPropagation()"
					[id]="idGlobalFiltro"
					[(ngModel)]="filter.searchStr"
					pInputText
					placeholder="{{ 'BUSQUEDA_GENERAL' | translate }}"
					(focusin)="onFocus($event)"
				/>
			</div>

			<div class="lg:col-8 col-12" [ngClass]="{ 'col-12': !verBuscador }">
				<span class="summary-content" *ngIf="filter?.summary?.length == 0">{{ "SIN_FILTROS_SELECCI_5" | translate }}</span>
				<span class="summary-content" *ngFor="let item of filter?.summary"
					>{{ item?.titulo }} <strong tooltipPosition="bottom" pTooltip="{{ item?.valor }}">{{ item?.valor }}</strong></span
				>
			</div>
			<div class="count" *ngIf="filter?.count">
				<!--span
                    >#<strong>{{ filter?.count }}</strong></span
                -->
			</div>
		</div>
	</div>
</div>
<ng-template #filterBase>
	<ng-container *ngIf="filter">
		<p-tabView>
			<p-tabPanel header="{{ 'FILTROS' | translate }}">
				<div class="grid grid-nogutter">
					<div class="col-12">
						<ng-container *ngTemplateOutlet="filterTemplateContent || filterContent"></ng-container>
					</div>
					<div class="col-12 text-center flex">
						<button
							pButton
							icon="pi pi-refresh"
							(click)="filter.forceUpdate(); $event.stopPropagation()"
							class="refresh-button p-button-text"
							tooltipPosition="bottom"
							style="margin-left: auto"
							pTooltip="{{ 'REFRESCAR' | translate }}"
						></button>

						<button
							pButton
							icon="pi pi-trash"
							class="clean-button p-button-text"
							(click)="resetFilter()"
							tooltipPosition="bottom"
							pTooltip="{{ 'BORRAR_FILTROS' | translate }}"
						></button>
						<button
							*ngIf="permiteGuardado"
							pButton
							icon="pi pi-save"
							class="p-button-text save-button"
							pTooltip="{{ 'GUARDAR_FILTRO' | translate }}"
							(click)="op.toggle($event)"
						></button>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="{{ 'COLUMNAS' | translate }}" *ngIf="columns?.length > 0">
				<div class="mx-4">
					<div *ngFor="let col of columns | visible; let i = index">
						<div class="flex justify-content-between mb-2">
							<p-checkbox name="visibleColumns" [(ngModel)]="filter.columnasVisibles" [label]="col.header" [value]="col.field"> </p-checkbox>
						</div>
						<div class="separator"></div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="{{ 'FILTROS_GUARDADOS' | translate }}" *ngIf="permiteGuardado">
				<div class="mx-4">
					<div *ngFor="let filtro of filtrosGuardados">
						<div class="flex justify-content-between align-items-center">
							<div class="fs-normal fw-700">
								{{ filtro.nombre }}
							</div>

							<div class="flex gap-2">
								<button
									pButton
									class="p-button-outlined p-button-danger"
									icon="pi pi-trash"
									pTooltip="{{ 'BORRAR' | translate }}"
									(click)="eliminarFiltro(filtro.nombre)"
								></button>
								<button
									pButton
									class="p-button-outlined"
									icon="pi pi-check"
									pTooltip="{{ 'APLICAR' | translate }}"
									(click)="aplicarFiltro(filtro)"
								></button>
							</div>
						</div>

						<div class="separator my-2"></div>
					</div>
				</div>
			</p-tabPanel>
		</p-tabView>
	</ng-container>
</ng-template>
<ng-template #filterContent>
	<div class="grid">
		<div class="col-12">
			<ng-content></ng-content>
		</div>
		<div class="col-2 sm:col-6" *ngIf="!autoSearch && !isMinimized">
			<button
				pButton
				icon="pi pi-search"
				[disabled]="!filter?.valid()"
				label="{{ 'APLICAR_FILTROS' | translate }}"
				(click)="search()"
				tooltipPosition="bottom"
				pTooltip="{{ 'APLICAR_FILTROS' | translate }}"
			></button>
		</div>
	</div>
</ng-template>

<p-sidebar [(visible)]="displayFiltros" #filterBar position="right" [style]="{ width: isMobile() ? '100vw' : '30vw' }">
	<p-scrollPanel [style]="{ height: 'calc(100vh - 10em);', width: '100%', border: 'none' }">
		<ng-container *ngTemplateOutlet="filterBase"> </ng-container>
	</p-scrollPanel>
</p-sidebar>

<p-overlayPanel #op>
	<div class="flex flex-column justify-content-between gap-3 w-25rem">
		<div>
			<span class="p-float-label field">
				<input pInputText [(ngModel)]="filtroGuardar.nombre" type="text" /><label>{{ "NOMBRE" | translate }}</label>
			</span>
		</div>

		<div class="flex justify-content-end gap-2">
			<button pButton label="{{ 'CANCELAR' | translate }}" (click)="cancelar(op)" class="p-button-danger"></button>
			<button class="p-button-success" pButton label="{{ 'GUARDAR' | translate }}" (click)="guardar(op)" [disabled]="!filtroGuardar.nombre"></button>
		</div>
	</div>
</p-overlayPanel>
