import * as moment from "moment"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { Filtro } from "./../../common/model/Filtro"
export class FiltroRanking extends Filtro {
	constructor(name: string, page?: number, size?: number, sortField: string = "peso", order: number = 1, guardable: boolean = true) {
		super(
			name,
			{
				categorias: "Categorías",
				tipo: "Tipo de producto",
				archivados: "Ver archivados",
				cliente: "Cliente",
				tipoCliente: "Tipo Cliente",
				fechaFacturacionDesde: "Fecha facturación desde",
				fechaFacturacionHasta: "Fecha facturación hasta",
				fechaActividadDesde: "Fecha actividad desde",
				fechaActividadHasta: "Fecha actividad hasta",
				ordenadoPor: "Ordenado por",
				soloRapidos: "Ver solo rápidos",
				locales: "Locales",
				centros: "Centro de costo",
				tipoPrestacion: "Tipo prestación",
				moneda: "Moneda",
				categoriaProducto: "Categoría de producto",
				tipoProductoBuscado: "Tipo producto buscado"
			},
			page,
			size,
			sortField,
			order,
			guardable
		)
	}

	private _categorias: Descriptivo[] = []
	private _fechaDesde: Date
	private _fechaHasta: Date
	private _cliente: Descriptivo
	private _centros: Descriptivo[] = []
	private _tiposProducto: Descriptivo[] = []
	private _soloVenta: boolean = true
	private _propios: boolean
	private _moneda: Descriptivo = new Descriptivo("1", null, 1)
	private _fechaFacturacionDesde: Date
	private _tipoCliente: Descriptivo

	private _tipoProductoBuscado: string = "T"
	public get tipoProductoBuscado(): string {
		return this._tipoProductoBuscado
	}
	public set tipoProductoBuscado(v: string) {
		if (v != this._tipoProductoBuscado) {
			this._tipoProductoBuscado = v
			this.update()
		}
	}

	public get moneda(): Descriptivo {
		return this._moneda
	}

	public set moneda(moneda: Descriptivo) {
		this._moneda = moneda
	}
	public get fechaFacturacionDesde(): Date {
		return this._fechaFacturacionDesde
	}
	public set fechaFacturacionDesde(v: Date) {
		this._fechaFacturacionDesde = v ? moment(v).startOf("day").toDate() : null
		this.update()
	}

	private _fechaFacturacionHasta: Date
	public get fechaFacturacionHasta(): Date {
		return this._fechaFacturacionHasta
	}
	public set fechaFacturacionHasta(v: Date) {
		this._fechaFacturacionHasta = v ? moment(v).endOf("day").toDate() : null
		this.update()
	}

	private _fechaActividadDesde: Date
	public get fechaActividadDesde(): Date {
		return this._fechaActividadDesde
	}
	public set fechaActividadDesde(v: Date) {
		this._fechaActividadDesde = v ? moment(v).startOf("day").toDate() : null
		this.update()
	}

	private _fechaActividadHasta: Date
	public get fechaActividadHasta(): Date {
		return this._fechaActividadHasta
	}
	public set fechaActividadHasta(v: Date) {
		this._fechaActividadHasta = v ? moment(v).endOf("day").toDate() : null
		this.update()
	}

	private tipoPrestacion: string
	public get propios(): boolean {
		return this._propios
	}
	public set propios(v: boolean) {
		if (v != this._propios) {
			this._propios = v
			this.update()
		}
		if (v == true) {
			this.tipoPrestacion = "Propios"
		} else if (v == false) {
			this.tipoPrestacion = "Externos"
		} else {
			this.tipoPrestacion = "Todos"
		}
	}

	private _empresa: boolean
	public get empresa(): boolean {
		return this._empresa
	}
	public set empresa(v: boolean) {
		this._empresa = v

		if (v == true) {
			this.tipoCliente = new Descriptivo("EMP")
		} else if (v == false) {
			this.tipoCliente = new Descriptivo("PART")
		} else {
			this.tipoCliente = null
		}
	}

	public get soloVenta(): boolean {
		return this._soloVenta
	}
	public set soloVenta(v: boolean) {
		if (v != this._soloVenta) {
			this._soloVenta = v
			this.update()
		}
	}
	public get tiposProducto(): Descriptivo[] {
		return this._tiposProducto
	}
	public set tiposProducto(v: Descriptivo[]) {
		this._tiposProducto = v
		this.update()
	}

	public set tipoProducto(v: Descriptivo) {
		this._tiposProducto = v ? [v] : []
		this.update()
	}
	public get tipoProducto() {
		return this._tiposProducto ? this._tiposProducto[0] : null
	}
	public get centros(): Descriptivo[] {
		return this._centros
	}
	public set centros(v: Descriptivo[]) {
		if (v != this._centros) {
			this._centros = v
			this.update()
		}
	}
	private _locales: Descriptivo[]
	public get locales(): Descriptivo[] {
		return this._locales
	}
	public set locales(v: Descriptivo[]) {
		if (v != this._locales) {
			this._locales = v
			this.update()
		}
	}
	private _soloRapidos: boolean
	public get soloRapidos(): boolean {
		return this._soloRapidos
	}
	public set soloRapidos(v: boolean) {
		if (v != this._soloRapidos) {
			this._soloRapidos = v
			this.update()
		}
	}
	private _excluirRapidos: boolean
	public get excluirRapidos(): boolean {
		return this._excluirRapidos
	}
	public set excluirRapidos(v: boolean) {
		this._excluirRapidos = v
		this.update()
	}

	private _ordenadoPor: string = "cantidad"
	public get ordenadoPor(): string {
		return this._ordenadoPor
	}

	public set ordenadoPor(v: string) {
		this._ordenadoPor = v
		this.sortField = v
		this.update()
	}

	private _cantidad: number
	public get cantidad(): number {
		return this.size
	}
	public set cantidad(v: number) {
		this.size = v
		this.update()
	}

	public get categorias(): Descriptivo[] {
		return this._categorias
	}
	public set categorias(v: Descriptivo[]) {
		this._categorias = v
		this.update()
	}

	public get cliente(): Descriptivo {
		return this._cliente
	}
	public set cliente(v: Descriptivo) {
		this._cliente = v
		this.update()
	}

	public get tipo(): Descriptivo {
		return this._tiposProducto ? this._tiposProducto[0] : null
	}
	public set tipo(v: Descriptivo) {
		if (!this._tiposProducto) this._tiposProducto = []
		if (this._tiposProducto[0] != v) {
			this._tiposProducto = [v]
			this.update()
		}
	}

	public get fechaDesde(): Date {
		return this._fechaDesde
	}
	public set fechaDesde(v: Date) {
		this._fechaDesde = v ? moment(v).startOf("day").toDate() : null
		this.update()
	}

	public get fechaHasta(): Date {
		return this._fechaHasta
	}
	public set fechaHasta(v: Date) {
		this._fechaHasta = v ? moment(v).endOf("day").toDate() : null
		this.update()
	}
	public get verArchivados(): boolean {
		return this._verArchivados
	}
	public set verArchivados(v: boolean) {
		if (v != this._verArchivados) {
			this._verArchivados = v
			this.update()
		}
	}

	public get tipoCliente(): Descriptivo {
		return this._tipoCliente
	}
	public set tipoCliente(v: Descriptivo) {
		this._tipoCliente = v
		this.update()
	}

	public clean() {
		super.clean(false)
		let cleanVal = {
			categoria: [],
			verArchivados: false,
			tiposProducto: [],
			cliente: null,
			fechaDesde: moment().subtract(30, "days").toDate(),
			fechaHasta: null,
			ordenadoPro: "cantidad",
			solorapidos: false,
			centros: [],
			locales: [],
			soloVenta: true,
			propios: null,
			tipoCliente: null,
			tipoProductoBuscado: "T"
		}
		this.patchValue(cleanVal)
	}

	public patchValue(v: any) {
		super.patchValue(v, false)
		this._searchStr = v.searchStr
		this._verArchivados = v.verArchivados
		this._tiposProducto = v.tiposProducto ? v.tiposProducto.map((d) => Descriptivo.fromData(d)) : []
		this._categorias = v.categorias ? v.categorias.map((c) => Descriptivo.fromData(c)) : []
		this._cliente = v.cliente ? Descriptivo.fromData(v.cliente) : null
		this._fechaDesde = v.fechaDesde ? new Date(v.fechaDesde) : moment().subtract(30, "days").toDate()
		this._fechaHasta = v.fechaHasta ? new Date(v.fechaHasta) : null
		this._ordenadoPor = v.ordenadoPro ? v.ordenadoPor : "cantidad"
		this._soloRapidos = v.soloRapidos
		this._centros = v.centros ? v.centros.map((c) => Descriptivo.fromData(c)) : []
		this._locales = v.locales ? v.locales.map((c) => Descriptivo.fromData(c)) : []
		this._soloVenta = v.soloVenta
		this._propios = v.propios
		this._tipoCliente = v.tipoCliente
		this._tipoProductoBuscado = v.tipoProductoBuscado || "T"
		this.update()
	}

	public apply(data: any[]): any[] {
		const searchLike = this.searchStr ? this.searchStr.toUpperCase().split(" ") : null
		return data.filter(
			(value) =>
				(!searchLike || !value["descripcion"] || searchLike.every((v) => value["descripcion"].toUpperCase().includes(v))) &&
				(!this.propios || value["esPropio"]) &&
				(this.verArchivados || value["habilitado"]) &&
				(!this.categorias?.length ||
					!value["categorias"] ||
					(value["categorias"].length && value["categorias"].filter((cat) => this.categorias.findIndex((f) => cat.codigo === f.codigo) >= 0))) &&
				(!value["tipoProducto"] || !this.tiposProducto?.length || this.tiposProducto.findIndex((d) => d.codigo === value["tipoProducto"].codigo) >= 0)
		)
	}
}
