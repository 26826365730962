<div class='gestor-nota content  content-white'>


    <model-gestor [service]='service' [(item)]='item' [validar]='isValid' [modelName]="'Nota' | translate"
        [verAcciones]="true" [isModal]="isModal" [goBack]="goBack" (onItemGuardado)="handleGuardado($event)"
        [hideTitle]="hideTitle" [editable]="esEditable" (onCancelar)="handleCancelar($event)" [form]="NotaForm"
        [multilenguaje]="false" (onEditar)="onEdit($event)" >

        <div class='gestor-form' model-type='dataContent'>
            <form #NotaForm="ngForm" id="Notaform">
                <div class="grid" *ngIf="item">
                    <div *ngIf="isModal" class="text-right modal-actions col-12">
                        <button pButton icon="fa fa-pencil" (click)="onEdit($event)" *ngIf="readonly"></button>
                        <button pButton icon="fa fa-trash" (click)="delete($event)" *ngIf="item?.id"></button>
                    </div>
                    <div class="col-12 " [ngClass]="{'lg:col-12': !isModal}">
                        <div class="grid">

                            <div class="col-fixed col-codigo">
                                <color-picker [(ngModel)]="item.color" [readonly]="readonly" name="color">
                                </color-picker>
                            </div>

                            <div class="col-6 lg:col-3 " *ngIf=" !(!esPermanente && item.id)">

                                <p-toggleButton [(ngModel)]="esPermanente" name="Es Permanente"
                                    onLabel="{{'ES_PERMANENTE' | translate}}" offLabel="{{'ES_PERMANENTE' | translate}}"
                                    onIcon="pi pi-check" offIcon="pi pi-times" [disabled]="readonly || item.id"
                                    *ngIf="(!readonly && ( ( esPermanente && item.id ) || (!item.id)) )|| (readonly && esPermanente) "></p-toggleButton>

                            </div>

                            <div class="col-fixed p-fluid p-field w-12rem" *ngIf="!esPermanente">

                                <span class="p-float-label">
                                    <p-calendar [(ngModel)]="item.fechaNota" name="fechaNota" dateFormat="dd/mm/yy"
                                         appendTo="body" [showIcon]="!readonly" [disabled]="readonly"
                                        #fechaNota="ngModel" required></p-calendar>
                                    <label for="">{{ 'FECHANOTA' | translate }}</label>
                                    <small *ngIf="getErrorMessage(fechaNota)" class="p-error block">{{
                                        getErrorMessage(fechaNota) }}</small>
                                </span>


                            </div>

                            <div class="col-fixed p-fluid p-field w-12rem" *ngIf="!esPermanente">


                                <span class="p-float-label">
                                    <p-calendar [(ngModel)]="item.fechaHasta" name="fechaHasta" dateFormat="dd/mm/yy"
                                         appendTo="body" [showIcon]="!readonly" [disabled]="readonly"
                                        #fechaHasta="ngModel" required></p-calendar>
                                    <label for="">{{ 'FECHAHASTA' | translate }}</label>
                                    <small *ngIf="getErrorMessage(fechaHasta)" class="p-error block">{{
                                        getErrorMessage(fechaHasta) }}</small>
                                </span>


                            </div>


                            <div class="col-12 lg:col p-fluid" *ngIf="item?.id">
                                <span class="p-float-label field">
                                    <input pInputText placeholder="{{ 'ULTIMA_MODIFICACIÓN' | translate }}"
                                        [value]="item.ultimaModificacion | date: 'dd/MM/yyyy HH:mm' "
                                        tooltipPosition="bottom" pTooltip="{{ 'ULTIMA_MODIFICACIÓN' | translate }}"
                                        type="text" disabled /><label>{{
                                        'ULTIMA_MODIFICACIÓN' | translate }}</label>
                                </span>
                            </div>

                            
                           
                        </div>
                    </div>

                    <div class="col-12" [ngClass]="{'lg:col-8': !isModal}">
                        <label class="fw-500 mb-2">{{ 'Cabecera' | translate }}</label>
                        <p-editor [(ngModel)]="item.cabecera" name="cabecera" tabindex="10" [readonly]="readonly"
                            required
                            [style]="{'height': '100%', 'max-height': '20vh', 'min-height':'15vh','overflow':'auto', 'background-color':'white'}">
                        </p-editor>

                        <p-header *ngIf="readonly">
                            <span></span>
                        </p-header>
                        <small>
                            {{ getErrorMessage(detalle) }}
                        </small>
                    </div>

                    <div class="col-12 " [ngClass]="{'lg:col-8': !isModal}">
                        <label class="fw-500 mb-2">{{'Nota' | translate}}</label>
                        <p-editor [(ngModel)]="item.contenido" name="nota" tabindex="10" [readonly]="readonly"
                            [style]="{'height': '100%', 'max-height': '40vh', 'min-height':'20vh','overflow':'auto', 'background-color':'white'}">

                        </p-editor>
                        <p-header *ngIf="readonly">
                            <span></span>
                        </p-header>
                        <small>
                            {{ getErrorMessage(contenido) }}
                        </small>
                    </div>

                    <div class="col-12" *ngIf="item.fechaHasta && !item.esPermanente">
                        <span class="fw-500">{{'RECORDATORIOS' | translate}}</span>
                        <abm-recordatorios [itemConRecordatorios]="item" [readonly]="readonly" [fechaBase]="item.fechaNota" [cuerpoRecordatorio]="item.contenido"
                        [cabeceraRecordatorio]="item.cabecera"></abm-recordatorios>
                    </div>
                </div>
            </form>
        </div>
    </model-gestor>
</div>




<ng-template #gestorRecordatorio let-handler="handler" let-itemEditado="itemEditado">
    <gestor-recordatorio [item]="itemEditado" (onGuardado)="handler.onGuardado($event)"
        (onCancelado)="handler.onCancelado($event)" [isModal]="true" [goBack]="false" [embedded]="true"
        [readonly]="readonly">
    </gestor-recordatorio>
</ng-template>