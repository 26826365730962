import * as moment from "moment"
import { Descriptivo } from "src/app/common/model/Descriptivo"
import { ItemResumenReserva } from "src/app/model/ItemReservaResumen"
import { Producto } from "src/app/model/Producto"
import { Auditable } from "./Auditable"
import { GrupoReserva } from "./GrupoReserva"
import { Hotel } from "./Hotel"
import { Idioma } from "./Idioma"
import { ItemReserva } from "./ItemReserva"
import { ProductoAsociadoReserva } from "./ProductoAsociadoReserva"
import { Restriccion } from "./Restriccion"

export class ItemReservaConsentimiento extends Auditable {
	comparar(b: ItemReservaConsentimiento | ItemResumenReserva | ItemReserva): number {
		if (moment(b.fechaActividad).isSame(this.fechaActividad, "date")) {
			return moment(b.pickUp).isAfter(this.pickUp) ? -1 : 1
		} else return this.fechaActividad < b.fechaActividad ? -1 : 1
	}
	public asociadosModificados = false
	constructor(
		public id?: number,
		public idReserva?: number,
		public fechaActividad?: Date,
		public esCancelada?: boolean,
		public aceptadoConsentimiento?: boolean,
		public producto?: Producto,
		public reservas?: GrupoReserva[],
		public tipoProducto?: Descriptivo,
		public idItemReserva?: number,
		public encuestaRespondida?: boolean,
		public tokenItem?: string,
		public pickUp?: Date,
		public cantidadPax?: number,
		public checkIn?: Date,
		public checkOut?: Date,
		public hotel?: Hotel,
		lastModifiedBy?,
		lastModifiedDate?,
		public agrupado: boolean = true,
		public restricciones: Restriccion[] = [],
		public productosAsociados: ProductoAsociadoReserva[] = [],
		public notaCliente?: string,
		public idiomaServicio?: Idioma,
		public tokenAgrupado: string = "",
		public direccionAlternativa?: string
	) {
		super(lastModifiedBy, lastModifiedDate)
	}
	public static fromData(data: any): ItemReservaConsentimiento {
		if (!data) return null
		const o: ItemReservaConsentimiento = new ItemReservaConsentimiento(
			data.id,
			data.idReserva,
			moment(data.fechaActividad).toDate(),
			data.esCancelada,
			data.aceptadoConsentimiento,
			Producto.fromData(data.producto),
			data.reservas ? data.reservas.map((r) => GrupoReserva.fromData(r)) : [],
			Descriptivo.fromData(data.tipoProducto),
			data.idItemReserva,
			data.encuestaRespondida,
			data.tokenItem || data.token,
			data.pickUp ? new Date(data.pickUp) : null,
			data.cantidadPax,
			data.checkIn ? new Date(data.checkIn) : null,
			data.checkOut ? new Date(data.checkOut) : null,
			Hotel.fromData(data.hotel),
			data.lastModifiedBy,
			data.lastModifiedDate,
			data.agrupado,
			data.restricciones ? data.restricciones.map((r) => Restriccion.fromData(r)) : [],
			data.productosAsociados ? data.productosAsociados.map((p) => ProductoAsociadoReserva.fromData(p)) : [],
			data.notaCliente,
			Idioma.fromData(data.idiomaServicio),
			data.tokenAgrupado,
			data.direccionAlternativa
		)

		return o
	}
	public get requiereConsentimiento() {
		return this.producto?.familiaProducto?.requiereConsentimiento || this.producto?.requiereConsentimiento
	}
	public get referenciaDireccion() {
		return this.direccionAlternativa || this.hotel ? this.hotel?.descripcion + " - " + this.hotel?.direccion : "S/D"
	}
	public get json() {
		return JSON.parse(
			JSON.stringify(this, (k, v) => {
				return k != "_reservas" ? v : null
			})
		)
	}
}
