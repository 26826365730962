import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { Usuario } from './../common/model/Usuario';
import { AuthService } from './../services/auth.service';


@Injectable()
export class EdicionPerfilAG implements CanActivate {

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        let id: number = Number(route.queryParamMap.get("id"));
        return this.auth.getUser().pipe(filter(u => u != undefined), map(u => {
            return this.esVisible(id, u);
        }), catchError(e => of(false)));
    }


    constructor(private auth: AuthService) {
        this.auth.getUser().subscribe(u => {
            //this.visible = this.esVisible(u);

        });

    }
    public getRoles(): string[] { return [] };

    public esVisible(id: number, user: Usuario): boolean {
        return user && (user.tieneRol(["ROLE_ADMIN"]) || user.id == id);
    }


}