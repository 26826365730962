import { Pipe, PipeTransform } from '@angular/core';
import { AccionesTabla } from './../model/AccionesTabla';
@Pipe({
    name: 'visibles',
    pure: false
})
export class AccionesVisiblesPipe implements PipeTransform {
    constructor() { }
    transform(acciones: AccionesTabla[], item): any {
        return acciones ? acciones.filter(a => a && a.esVisible(item)) : [];
    }
}