import { Injectable } from "@angular/core"
import { lastValueFrom } from "rxjs"
import { share } from "rxjs/operators"
import { Filtro } from "src/app/common/model/Filtro"
import { Insumo } from "src/app/model/Insumo"
import { MessagesService } from "../common/services/messages-data-service.service"
import { LoadingService } from "./../common/services/loading-data-service.service"
import { InsumoRanking } from "./../model/Insumo"
import { FiltroRankingCompras } from "./../pages/producto/FiltroRankingCompras"
import { BufferedDescriptivoService } from "./BufferedDescriptivoService"
import { AuthService } from "./auth.service"
import { NovedadService } from "./novedades.service"
@Injectable({
	providedIn: "root"
})
export class InsumoService extends BufferedDescriptivoService<Insumo> {
	constructor(protected novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
		super(novedadesService, authService, messServ)
	}
	public baseName(): string {
		return "insumo"
	}
	public parseToEnt(data: any): Insumo {
		return Insumo.fromData(data)
	}
	public newEnt(): Insumo {
		return new Insumo()
	}

	public getHabilitados() {
		let f = new Filtro("", {}, 0, 1000)
		f.habilitados = true
		return this.getData(f).then((r) => r.filter((c) => c.habilitado))
	}

	/* public asociarProveedor(insumo: Descriptivo, proveedor: Descriptivo) {
        return lastValueFrom(this.http.post(this.getBaseURL() + "asociarProveedor", { 'insumo': insumo, 'proveedor': proveedor })
            ).then(r => { return r; });
    }*/

	public getByProveedor(proveedorId: number): Promise<Insumo[]> {
		return lastValueFrom(this.http.get(this.getApiURL() + "insumo/proveedor/" + proveedorId))
			.then((is: any) => is.map((i) => this.parseToEnt(i)))
			.catch(this.handleError)
	}
	getRanking = (
		filtro: FiltroRankingCompras = new FiltroRankingCompras(null, 0, 1000, null, null, false),
		customLoading?: LoadingService
	): Promise<InsumoRanking[]> => {
		let $this = this
		if (customLoading) {
			customLoading.addLoadingCount()
		} else this.loadingService.addLoadingCount()
		return lastValueFrom(this.http.post(this.getBaseURL("ranking"), filtro.json).pipe(share())).then(
			(r: any) => {
				if (customLoading) {
					customLoading.susLoadingCount()
				} else this.loadingService.susLoadingCount()
				return r.map((p) => InsumoRanking.fromData(p))
			},
			(e) => this.handleError(e, customLoading)
		)
	}
}
