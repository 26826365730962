import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { PipesModule } from "src/app/common/pipes/pipes.module"
import { PrimeNgModule } from "src/app/prime-ng.module"
import { HelpActividadesComponent } from "./help-actividades/help-actividades.component"
import { HelpCargaMasivaComponent } from "./help-carga-masiva/help-carga-masiva.component"
import { HelpClienteComponent } from "./help-cliente/help-cliente.component"
import { HelpComprobanteComponent } from "./help-comprobante/help-comprobante.component"
import { HelpConfiguracionComponent } from "./help-configuracion/help-configuracion.component"
import { HelpCuentaCorrienteComponent } from "./help-cuenta-corriente/help-cuenta-corriente.component"
import { HelpEnConstruccionComponent } from "./help-en-construccion/help-en-construccion.component"
import { HelpFacturaComponent } from "./help-factura/help-factura.component"
import { HelpFormaPagoComponent } from "./help-forma-pago/help-forma-pago.component"
import { HelpGestoresComponent } from "./help-gestores/help-gestores.component"
import { HelpIdiomaComponent } from "./help-idioma/help-idioma.component"
import { HelpItinerarioComponent } from "./help-itinerario/help-itinerario.component"
import { HelpLinkComponent } from "./help-link/help-link.component"
import { HelpListaPreciosComponent } from "./help-lista-precios/help-lista-precios.component"
import { HelpListadosComponent } from "./help-listados/help-listados.component"
import { HelpOrdenDePagoComponent } from "./help-orden-de-pago/help-orden-de-pago.component"
import { HelpPlantillasComponent } from "./help-plantillas/help-plantillas.component"
import { HelpProductoComponent } from "./help-producto/help-producto.component"
import { HelpProveedorComponent } from "./help-proveedor/help-proveedor.component"
import { HelpRecordatoriosComponent } from "./help-recordatorios/help-recordatorios.component"
import { HelpReportePrevisionReservasComponent } from "./help-reporte-prevision-reservas/help-reporte-prevision-reservas.component"
import { HelpReservaComponent } from "./help-reserva/help-reserva.component"
import { HelpRoutingModule } from "./help-routing.module"
import { HelpTipoImpuestoComponent } from "./help-tipo-impuesto/help-tipo-impuesto.component"
import { HelpTipoNotificacionComponent } from "./help-tipo-notificacion/help-tipo-notificacion.component"
import { HelpTipoProveedorComponent } from "./help-tipo-proveedor/help-tipo-proveedor.component"
import { HelpTipoPuestoComponent } from "./help-tipo-puesto/help-tipo-puesto.component"
import { HelpUnidadComponent } from "./help-unidad/help-unidad.component"
import { HelpUsuarioComponent } from "./help-usuario/help-usuario.component"
import { HelpVehiculoComponent } from "./help-vehiculo/help-vehiculo.component"
import { ModelHelpComponent } from "./model-help/model-help.component"

const pipe = []
const directives = []
const components = [
	ModelHelpComponent,
	HelpRecordatoriosComponent,
	HelpUsuarioComponent,
	HelpCargaMasivaComponent,
	HelpItinerarioComponent,
	HelpListadosComponent,
	HelpGestoresComponent,
	HelpProveedorComponent,
	HelpConfiguracionComponent,
	HelpComprobanteComponent,
	HelpFacturaComponent,
	HelpOrdenDePagoComponent,
	HelpIdiomaComponent,
	HelpTipoPuestoComponent,
	HelpTipoImpuestoComponent,
	HelpTipoProveedorComponent,
	HelpFormaPagoComponent,
	HelpCuentaCorrienteComponent,
	HelpUnidadComponent,
	HelpLinkComponent,
	HelpEnConstruccionComponent,
	HelpListaPreciosComponent,
	HelpClienteComponent,
	HelpVehiculoComponent,
	HelpProductoComponent,
	HelpPlantillasComponent,
	HelpActividadesComponent,
	HelpTipoNotificacionComponent,
	HelpReservaComponent,
	HelpEnConstruccionComponent,
	HelpLinkComponent,
	HelpReportePrevisionReservasComponent
]

@NgModule({
	imports: [HelpRoutingModule, CommonModule, PipesModule, PrimeNgModule],
	declarations: [...pipe, ...directives, ...components, HelpReportePrevisionReservasComponent],
	exports: [...pipe, ...directives, ...components]
})
export class HelpModule {}
