import { IConSubcategoriasImponibles } from "../common-components/abm-subcategorias-imponibles/IConSubcategoriasImponibles"
import { round } from "./../common/utils/MathUtils"
import { CalculadoraImpuestos, IConImpuestos } from "./CalculadoraImpuestos"
import { SubcategoriaImponibleItem } from "./SubcategoriaImponibleItem"
import { TipoIVAGrabado } from "./TipoIVAGrabado"

import { Descriptivo } from "src/app/common/model/Descriptivo"

export class ProductoAsociadoReserva implements IConSubcategoriasImponibles, IConImpuestos {
	getImpuestos(ajuste: number) {
		return CalculadoraImpuestos.calcularImpuestos(this) * (1 + ajuste / 100)
	}
	public prevKey: string
	constructor(
		public id?: number,
		public producto?: Descriptivo,
		public esAdicional?: boolean,
		public importe: number = 0,
		public cantidad: number = 1,
		public descuento: number = 0,
		public esPorServicio: boolean = false,
		public base: number = 1,
		public tipoIva?: TipoIVAGrabado,
		public conPrecio: boolean = false,
		public impuestosIncluidos: boolean = false,
		public subcategorias: SubcategoriaImponibleItem[] = [],
		public esSubcategorizado: boolean = false,
		public ajuste: number = 0,
		public destacarComoAdicional: boolean = false
	) {}
	get incluyeImpuestos(): boolean {
		return this.impuestosIncluidos
	}
	get impuestosAdicionales(): number {
		return 0
	}
	get multiplicadorIVA(): any {
		return (this.tipoIva?.valorIva || 0) / 100
	}
	public static fromData(data: any): ProductoAsociadoReserva {
		if (!data) return null
		const o: ProductoAsociadoReserva = new ProductoAsociadoReserva(
			data.id,
			Descriptivo.fromData(data.producto),
			data.esAdicional,
			data.importe,
			data.cantidad,
			data.descuento,
			data.esPorServicio
		)
		o.tipoIva = TipoIVAGrabado.fromData(data.tipoIva)
		o.conPrecio = data.conPrecio
		o.impuestosIncluidos = data.impuestosIncluidos
		o.subcategorias = data.subcategorias?.map((s) => SubcategoriaImponibleItem.fromData(s)) || []
		o.esSubcategorizado = data.esSubcategorizado
		o.destacarComoAdicional = data.destacarComoAdicional
		return o
	}

	public get subtotal() {
		return Math.round(this.cantidad * this.importe * (1 + this.descuento / 100) * 100) / 100
	}
	public get total() {
		return this.incluyeImpuestos ? this.subtotal : this.subtotal + this.impuestos
	}
	public get totalNeto() {
		return this.subtotal
	}

	public get impuestos() {
		return round(CalculadoraImpuestos.calcularImpuestos(this))
	}
	public quitarItem(item: any) {
		this.subcategorias = this.subcategorias.filter((i) => i != item)
	}
	public agregarItem(item: any) {
		if (this.subcategorias.indexOf(item) < 0) {
			this.subcategorias = [...this.subcategorias, item]
		}
	}
	public newItem() {
		const s = new SubcategoriaImponibleItem()
		s.proporcion = 100 - this.subcategorias.reduce((a, b) => (a += b.proporcion), 0)
		return s
	}

	public get resumenImpuestos() {
		if (!this.esSubcategorizado) return this.tipoIva?.descripcion || "IVA Sin definir"
		return this.subcategorias.map((s) => s.subcategoriaImponible?.descripcion + " (" + s.tipoIVA?.descripcion + ") " + s.proporcion + "%").join(", ")
	}
}
