import { Filtro } from 'src/app/common/model/Filtro';
import { Descriptivo } from './../../common/model/Descriptivo';
export class FiltroPuesto extends Filtro {


    private _fechaDesde: Date;

    private _fechaHasta: Date;


    private _personal: Descriptivo;

    private _soloNoFacturados: boolean = true;
    public get soloNoFacturados(): boolean {
        return this._soloNoFacturados;
    }
    public set soloNoFacturados(v: boolean) {
        if (v != this._soloNoFacturados) {
            this._soloNoFacturados = v;
            this.update();
        }
    }

    public get personal(): Descriptivo {
        return this._personal;
    }
    public set personal(v: Descriptivo) {
        if (v != this._personal) {
            this._personal = v;
            this.update(true);
        }

    }

    public get fechaHasta(): Date {
        return this._fechaHasta;
    }
    public set fechaHasta(v: Date) {
        if (this._fechaHasta != v) {
            this._fechaHasta = v;
            this.update(true)
        }

    }

    public get fechaDesde(): Date {
        return this._fechaDesde;
    }
    public set fechaDesde(v: Date) {
        if (this._fechaDesde != v) {
            this._fechaDesde = v;
            this.update(true)
        }
    }

    
    private _token : string;
    public get token() : string {
        return this._token;
    }
    public set token(v : string) {
        this._token = v;
    }

    
    private _estados : Descriptivo[];
    public get estados() : Descriptivo[] {
        return this._estados;
    }
    public set estados(v : Descriptivo[]) {
        this._estados = v;
        this.update()
    }
    
    

    public clean() {
        super.clean(false);
        this.patchValue({
            searchStr: "",
            fechaDesde: null,
            fechaHasta: null,
            personal: null,
            guardable: false,
            soloNoFacturados: true,
            estado: null,
            token: null
        })

    }
    public patchValue(v: any) {
        super.patchValue(v, false);
        this._fechaDesde = v.fechaDesde ? new Date(v.fechaDesde) : null;
        this._fechaHasta = v.fechaHasta ? new Date(v.fechaHasta) : null;
        this._personal = v.personal ? Descriptivo.fromData(v.personal) : null;
        this._soloNoFacturados = v.soloNoFacturados;
        this._estados = v.estados? v.estados.map(e => Descriptivo.fromData(e)) : []
        this._token = v.token
        this.update(true);
    }
    public idItemReserva: number;

    constructor(name?, page: number = 0, size: number = 50, fechaDesde: Date = new Date()) {
        super(name,
            {"soloNoFacturados":"Solo no facturados",
            "fechaDesde":"Fecha desde",
            "fechaHasta":"Fecha hasta",
            }, page, size, "id", 1, false);
        this._fechaDesde = fechaDesde;
    }

    public tieneEstado(estado: Descriptivo) {
        return this.estados.some(e => e.codigo == estado.codigo);
    }
    public toggleEstado(estado: Descriptivo) {
        if (this.tieneEstado(estado)) {
            this.estados = this.estados.filter(e => e.codigo != estado.codigo);
        } else {
            this.estados.push(estado);
        }
        this.update(true);
    }
}