<div class="gestor-parametrico content content-white" *ngIf="itemEditado && service">
	<model-gestor
		[service]="service"
		[(item)]="itemEditado"
		[validar]="isValid"
		[file]="file"
		(onItemGuardado)="onGuardado($event)"
		(onCancelar)="onCancelado($event)"
		[goBack]="goBack"
		[isModal]="isModal"
		[title]="title"
		[readonly]="readonly"
		[editable]="editable"
	>
		<div class="gestor-form grid" model-type="dataContent">
			<div class="col-12 lg:col-2">
				<span class="p-float-label">
					<input
						pInputText
						placeholder="{{ 'PH_CODIGO' | translate }}"
						[(ngModel)]="itemEditado.codigo"
						[readonly]="!editable || readonly || item?.esSistema"
					/>
					<label>{{ "CODIGO" | translate }}</label>
				</span>
			</div>
			<div class="col-12 lg:col-8">
				<span class="p-float-label" [ngClass]="{ readonly: readonly }">
					<input pInputText [(ngModel)]="itemEditado.descripcion" required [readonly]="!editable || readonly" class="p-inputwrapper-filled" />
					<label>{{ "DESCRIPCION" | translate }}</label>
				</span>
			</div>
			<div class="col-12 lg:col-2">
				<span class="p-float-label">
					<input
						pInputText
						type="number"
						placeholder="{{ 'PH_PESO' | translate }}"
						[(ngModel)]="itemEditado.peso"
						[readonly]="!editable || readonly || item?.esSistema"
					/>
					<label>{{ "PESO" | translate }}</label>
				</span>
			</div>
		</div>
	</model-gestor>
</div>
